import React from 'react';
import "./Icon.scss";

// position
// tp_down - подсказка снизу
// tp_left - подсказка слева
// tp_right - подсказка справа
// tp_down tp_left - подсказка снизу, слева
// tp_down tp_right -подсказка снизу, справа
// tp_center_left - подсказка слева

const Icon = ({name, tp, position, ...props}) => {

    let tpClass = "";
    if (tp) {
        tpClass += "tp ";
    }

    if (position) {
        tpClass += position;
    }

    return (
        <span 
            className={"cor_icon " + tpClass}
            onClick={props.onClick} 
            style={props.style} 
            ref={props.btnRef}
            {...(tp ? {'data-tp': tp} : {})}
            >
            <i 
                className={"cor_icon_" + name + (props.className ? " " + props.className : "")} 
                title={props.title}
               
                />
            {props.text}
        </span>
    )
};
export default Icon;
