import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Dropdown} from "../index";
import {reloadOption, storageOptions} from "../../../redux/slices/storage";
import DropdownItem from './DropdownItem';
import Icon from "../Icon";
import './Dropdown.scss';

const DropdownList = ({control, sizeList, dropPosition}) => {
    const dispatch = useDispatch();
    const {gridOptions} = useSelector(storageOptions);

    const gridSize = ()=>(gridOptions?.[control]?.gridSize) ?? '';
    const setGridSize = (value) => dispatch(reloadOption('gridOptions', {...gridOptions, [control]: {gridSize: value}}));

    return (
      <Dropdown dropPosition={dropPosition} value={sizeList[gridSize()][0]} icon={sizeList[gridSize()][1]}>
          {Object.entries(sizeList).map(([value, [name,icon]]) =>
            <DropdownItem key={'key' + value}
                          onClick={() => setGridSize(value)}
                          className={value === gridSize() ? 'selected' : ''}>
                <Icon name={icon}/>
                {name}
            </DropdownItem>
          )}
      </Dropdown>
    );
};

export default DropdownList;
