import { setResetModal } from '../../../actions/modal';
import { setSensorImported, resetSensorData } from '../../../actions/sensor';
import { delChartMarkers } from '../../../actions/chart';

export const mapStateToProps = (nstate) => {
    return {
        modal: nstate.nmodal.reset,
        markers: nstate.nchart.marker,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setResetModal: (bool) => dispatch(setResetModal(bool)),
    setSensorImported: (arr) => dispatch(setSensorImported(arr)),
    resetSensorData: () => dispatch(resetSensorData()),
    delChartMarkers: (key) => dispatch(delChartMarkers(key)),
});
