import React from 'react';
import {Modal, Button} from 'rlabui';

const ModalCommentDlg = ({showComment, setShowComment, comment}) => {

    if (!showComment) {
        return null;
    }

    return (
        <Modal visible={showComment} size="xs">
            <Modal.Head modalClose={() => setShowComment(false)} title={comment} />
            <Modal.Footer>
                <Button onClick={() => setShowComment(false)}>Закрыть</Button>
            </Modal.Footer>
        </Modal>
    )
};

export default ModalCommentDlg;
