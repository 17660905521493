import {reloadOption} from "../../../redux/slices/storage";

export const mapStateToProps = (state) => {
    return ({
    });
};

export const mapDispatchToProps = (dispatch) => ({
    setFontSize: (integer) => dispatch(reloadOption('fontSize',integer)),
});
