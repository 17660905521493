import React, {useState, useEffect} from 'react';
import Modal from "../../Components/Modal";
import { Button } from '../ui';
import {getCellText, getCategories, getSystemList, getSiValueByBasic} from './pi_utils';
import {storageClass} from "../../redux/slices/storage";
import {loadPIUnits} from "../../redux/slices/lists";
import {useDispatch, useSelector} from "react-redux";

const PIShowUnitDlg = ({showDlg, setShowDlg, selectedUnitId}) => {
	const [selectedUnit, setSelectedUnit] = useState(undefined);

	const {lists: {pi}} = useSelector(state => state);
	const documentClass = useSelector(storageClass);
	const dispatch = useDispatch();

    useEffect(() => {
		dispatch(loadPIUnits(documentClass._id));
	}, [dispatch, documentClass._id]);

    useEffect(() => {
        if (!showDlg) return;
        const _selectedUnit = pi?.units.find(item => item._id === selectedUnitId);
        setSelectedUnit(_selectedUnit);
    }, [pi, showDlg, selectedUnitId]);

    const getInfo = (item, value) => {
        return (
            <div className="cor-net__row">
                <div className="cor-net__col col-2 mark">{item}</div>
                <div className="cor-net__col col-2 info">{getCellText(value, '', 'ab')}</div>
            </div>
        );
    };

    const getTextWithCapital = text => {
        const arr =  text.split('');
        if (arr.length > 0) arr[0] = arr[0].toUpperCase();
        return arr.join('');
    };

    const setModalContent = () => {
        if (!selectedUnit) return <></>;
        let siValue = '';
        const dependency = pi.dependencies.find(item => item.to === selectedUnit._id);
        if (!!dependency) {
            const fromUnit = pi.units.find(item => item._id === dependency.from);
            siValue = getSiValueByBasic(dependency, fromUnit);
        }

        const categories = getCategories(pi?.quants);
        const categoryName = categories.find(item => item.value === selectedUnit?.quant)?.label;
        const systems = getSystemList(pi?.systems);
        const measureSystem = systems.find(item => item.value === selectedUnit?.system)?.label;

        return (
            <div>
                <div className="modal__close" onClick={() => setShowDlg(false)}></div>
                <div className="modal__message">{getTextWithCapital(selectedUnit?.name)}</div>

                <div className="modal__body">
                    <div className="cor-net">
                        <div className="cor-net__section">
                            {getInfo('Обозначение', selectedUnit?.ruUnit)}
                            {getInfo('Измеряемая величина', categoryName)}
                            {getInfo('Система измерения', measureSystem)} 
                            {siValue && getInfo('Отношение к другим единицам измерения', siValue)} 
                            {getInfo('Описание', selectedUnit?.detail ? selectedUnit?.detail : 'Нет')}
                        </div>
                    </div>
                </div>

                <div className="modal__action">
                    <Button 
                        color="primary" 
                        border={true} 
                        onClick={() => setShowDlg(false)}>
                            Закрыть
                    </Button>
                </div>
            </div>
        )
    }

    if (!showDlg) {
        return false;
    }
    
    return (
        <Modal visible={showDlg} content={setModalContent()} size={'md'} />
    )
};

export default PIShowUnitDlg;
