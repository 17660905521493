export const SCALEPOS = {
  left: 1,
  right: 2,
  top: 3,
  bottom: 4,
  horiz: 5,
  vert: 6
};

const COLOR_SCALE = '#666F7E';
const FONT_SCALE = 'normal 0.975em Inter';

export const drawImageScales = (_ctx, INIT_IMAGE_X, INIT_IMAGE_Y, CANVAS_WIDTH, CANVAS_HEIGHT, scaleUnits) => {
  const WIDTH = CANVAS_WIDTH - INIT_IMAGE_X;
  const HEIGHT = CANVAS_HEIGHT - INIT_IMAGE_Y;

  const largeStrokeSize = 16;
  const smallStrokeSize = 8;
  const smallStrokeNum = 2;

  const horizLargeStrokeNUM = 11;
  const vertLargeStrokeNUM = 11;

  //HORIZONTAL: ============================================================================ 
  const xArr = getScaleData(
      SCALEPOS.horiz, SCALEPOS.left, 
      horizLargeStrokeNUM, 
      0, //min, 
      scaleUnits, //max, 
      INIT_IMAGE_X, //shift, //horiz: xPos0, vert: yPos0 
      INIT_IMAGE_Y, 
      WIDTH, //CANVAS_WIDTH - INIT_IMAGE_X, //size, //horiz: width, vert: height
      1,  //strokeFrom, //horiz: yPos0, vert: xPos0 => horiz: [xPos0, yPos0, xPos0 + (larg/small)strokeSize]
      largeStrokeSize,
      smallStrokeSize, 
      smallStrokeNum
  );

  _ctx.beginPath();
  _ctx.lineWidth = 2;
  _ctx.font = FONT_SCALE;
  _ctx.strokeStyle = COLOR_SCALE;
  _ctx.fillStyle = COLOR_SCALE;

  //1.0. очистка ранее отрисованной шкалы:
  _ctx.clearRect(0, 0, WIDTH + INIT_IMAGE_X, INIT_IMAGE_Y);
  _ctx.clearRect(0, 0, INIT_IMAGE_X, HEIGHT + INIT_IMAGE_Y);

  //1.1. horiz scale:
  _ctx.moveTo(INIT_IMAGE_X, INIT_IMAGE_Y);
   _ctx.lineTo(INIT_IMAGE_X + WIDTH, INIT_IMAGE_Y);

  //1.2. horiz strokes:
  for (let i = 1; i < xArr.length; i ++) { //не показываем первый штрих по горизонтальной шкале
      const elem = xArr[i].strokePos;
      _ctx.moveTo(elem[0], elem[1]);
      _ctx.lineTo(elem[2], elem[3]);
  }
  _ctx.stroke();

  //1.3. horiz values:
  const yPOS = INIT_IMAGE_Y / 2 + 4;
  const xScaleValues = getScaleValues(xArr, SCALEPOS.horiz, SCALEPOS.left, INIT_IMAGE_X, yPOS, scaleUnits>= 100);
      
  for (let i = 0; i < xScaleValues.length; i ++) {
      const elem = xScaleValues[i];
      _ctx.fillText(elem.value, elem.x, elem.y);
  }


  //VERTICAL:  ============================================================================
  let yArr = getScaleData(
      SCALEPOS.vert, SCALEPOS.right, 
      vertLargeStrokeNUM, 
      0, //min, 
      scaleUnits * CANVAS_HEIGHT / CANVAS_WIDTH, //max, 
      INIT_IMAGE_X, //shift, //horiz: xPos0, vert: yPos0 
      INIT_IMAGE_Y, 
      HEIGHT, //CANVAS_HEIGHT - INIT_IMAGE_Y, //size: vert: height
      1,  //strokeFrom, //horiz: yPos0, vert: xPos0 => horiz: [xPos0, yPos0, xPos0 + (larg/small)strokeSize]
      largeStrokeSize, 
      smallStrokeSize, 
      smallStrokeNum
  );
  yArr = yArr.sort((a,b) => a.value - b.value);

  _ctx.beginPath();
  _ctx.font = FONT_SCALE;
  _ctx.strokeStyle = COLOR_SCALE;
  _ctx.fillStyle = COLOR_SCALE;

  //2.1. vert scale:
  _ctx.moveTo(INIT_IMAGE_X, INIT_IMAGE_Y);
  _ctx.lineTo(INIT_IMAGE_X, INIT_IMAGE_Y + HEIGHT);

  //2.2. vert strokes:
  for (let i = 0; i < yArr.length-1; i ++) { //не показываем первый штрих по вертикальной шкале
      const elem = yArr[i].strokePos;
      _ctx.moveTo(elem[0], elem[1]);
      _ctx.lineTo(elem[2], elem[3]);
  }
  _ctx.stroke();

  //2.3. vert values:
  const xPOS = 0; // INIT_IMAGE_X / 2 + 4;
  const yScaleValues = getScaleValues(yArr, SCALEPOS.vert, SCALEPOS.left, xPOS, INIT_IMAGE_Y, scaleUnits>= 100);
      
  for (let i = 0; i < yScaleValues.length; i ++) {
      const elem = yScaleValues[i];
      _ctx.fillText(elem.value, elem.x, elem.y);
  }

  _ctx.closePath();
};

const getScaleData = (
  scaleHorVert, LeftRight, 
  largeStrokeNUM, 
  min, max, 
  shiftX, //horiz: xPos0, vert: yPos0 
  shiftY, 
  size, //horiz: width, vert: height
  strokeFrom, //horiz: yPos0, vert: xPos0 => horiz: [xPos0, yPos0, xPos0 + (larg/small)strokeSize]
  largeStrokeSize, 
  smallStrokeSize, 
  smallStrokeNum
) => {
  const isHorizScale =  scaleHorVert === SCALEPOS.horiz;
  const isLeft = LeftRight === SCALEPOS.left;
  const [scaleList, power, multiplier] = getScale(min, max, largeStrokeNUM);
  let strokeArr = [];

  //1. Add large strokes:
  for (let i = 0; i < scaleList.length; i ++) {
    saveStrokeInfo(scaleList[i], strokeArr, isHorizScale, isLeft, scaleList[i], min, max, 
        shiftX, shiftY, size, strokeFrom, largeStrokeSize, true, power, multiplier);
  }

  if (smallStrokeNum === 0) { // if no small strokes we can complete
    return strokeArr;
  } 

//2. Add small strokes:
  for (let i = 0; i < scaleList.length - 1; i ++) {
    const sz = (scaleList[i+1] - scaleList[i]) / smallStrokeNum;
    const val = (scaleList[i] + scaleList[i+1]) / smallStrokeNum;
    let currPos = scaleList[i];
    //let isReady = false;

    for (let j = 0; j < smallStrokeNum - 1; j ++) {
        currPos += sz;
        saveStrokeInfo(val * (j+1), strokeArr, isHorizScale, isLeft, currPos, min, max, 
            shiftX, shiftY, size, strokeFrom, smallStrokeSize, false, power, multiplier);
    }
  }

  return strokeArr;
};

const saveStrokeInfo = (scaleValue, strokeArr, isHorizScale, isLeft, currValue, min, max, shiftX, shiftY, size, 
  strokeFrom, strokeSize, isLargeStroke, power, multiplier) => {

  const getLine = (x1, y1, x2, y2) =>  'M ' + x1 + ' ' + y1 + ' L ' + x2 + ' ' + y2 + ' ';

  const val = getStrokePos(isHorizScale, currValue, min, max, shiftX, shiftY, size);
  let strokePos;
  const DIRECTION = -1;

  if (isHorizScale) {
    strokePos = [val, shiftY + (strokeFrom-0), val, shiftY + strokeFrom + strokeSize * DIRECTION];
  } else {
    strokePos = [shiftX + strokeFrom, val, shiftX + strokeFrom + (isLeft ? -strokeSize : +strokeSize) * DIRECTION, val];
  }

  strokeArr.push({
    strokePos: strokePos,
    isLargeStroke: isLargeStroke,
    value: scaleValue,
    multipliedValue: isLargeStroke ? scaleValue / multiplier : undefined,
    power: power,
    multiplier: multiplier,
    line: getLine(strokePos[0], strokePos[1], strokePos[2], strokePos[3])
  });
};

const getStrokePos = (isHorizScale, val, min, max, shiftX, shiftY, multiplier) => {
  if (isHorizScale)
    return shiftX + multiplier * (Number(val) - Number(min)) / (max - min);
  else
    return shiftY + multiplier * (Number(max) - Number(val)) / (max - min);
};

const getScale = (minVal, maxVal, minScaleNum) => {
  if (minVal === maxVal) {
    return [[maxVal - 1, maxVal, maxVal + 1], 0];
}

let val1 = 0.0;
let val2 = 0.0;
let power = 0;
let _minVal = minVal;
let _maxVal = maxVal;
let foundInt = false;
let multiplier = 1.0;

if (Math.abs(_minVal) >= 10 || Math.abs(_maxVal) >= 10) {
  while (!foundInt) {
    _minVal *= 0.1;
    _maxVal *= 0.1;
    power ++;
    multiplier *= 10;
    if (Math.abs(_minVal) < 10 && Math.abs(_maxVal) < 10) {
      foundInt = true;
    }
  }
} else if (Math.abs(_minVal) < 1 && Math.abs(_maxVal) <= 1) {
  while (!foundInt) {
    _minVal *= 10;
    _maxVal *= 10;
    power --;
    multiplier *= 0.1;
    if (Math.abs(_minVal) >= 1 || Math.abs(_maxVal) >= 1) {
      foundInt = true;
    }
  }
} 

val1 = Number((Math.ceil(_minVal * 100) / 100.0).toFixed(3));
val2 = Number((Math.floor(_maxVal * 100) / 100.0).toFixed(3));
if (val1 === val2) {
  val1 = Number(_minVal.toFixed(4));
  val2 = Number(_maxVal.toFixed(4));
}

const vals = [val1];
for (let i = 1; i < minScaleNum - 1; i ++) {
  let val = (val1 + i * (val2 - val1) / (minScaleNum - 1));
  if (isNaN(val)) {
    console.log('val=', val, ' xxx');
    debugger;
  }
  val = Number(val.toFixed(2));
  vals.push(val);
}
vals.push(val2);

let finalArr = [];
finalArr = vals.map(item => item * multiplier);
return [finalArr, power, multiplier];
};

const getScaleValues = (strokesArr, hvScale, LeftRight, shiftX, shiftY, supressFloat = false) => {
const isHorizScale = hvScale === SCALEPOS.horiz;
const isLeft = LeftRight === SCALEPOS.left;
const mainStrokes = strokesArr;
const rightShift = 4;
const topShift = 5;

const xArr = [];
const yArr = [];
const valArr = [];

for (let i = 0; i < mainStrokes.length; i ++) {  
    let x, y;
    if (isHorizScale) {
        x = mainStrokes[i].strokePos[0] - shiftX/4;  
        y = shiftY - 7;

        if (i === 0) { //это значение 0 на гориз.шкале. смещаем его, чтобы сделать общим для обеих шкал
            x -= shiftX/4;  
            y += shiftX/4;  
        }

        if (i === Math.floor(mainStrokes.length/2)) //это последняя позиция по горизонтали. смещаем ее влево
          x -= shiftX *0.4; //shiftX/2;
    } else {
        x = mainStrokes[i].strokePos[0] - shiftX;  
        y = mainStrokes[i].strokePos[1]; 

        if (isLeft) {
            //x -= leftShift;
            x = 0;
        } else {
            x += rightShift;
        }

        y += topShift; 

        if (i === 0)  //это последняя позиция по вертикали. смещаем ее вверх
          y -= shiftY/4;
    }
    xArr.push(x);
    yArr.push(y);
}

const arr = mainStrokes.filter(item=> item.multipliedValue !== undefined)
                       .map(item => item.multipliedValue);
const maxVal = Math.max(...arr) * mainStrokes[0].multiplier;

for (let i = 0; i < mainStrokes.length; i ++) {
    const ind = isHorizScale ? i : mainStrokes.length - 1 - i;
    let x = xArr[ind];
    const y = yArr[ind];
    let item;

    const power = mainStrokes[i].power;
    if (power >= -3) {
        //const digits = supressFloat ? 0 : 1 + (power < -1 ? -power-1 : 0);
        let digits = supressFloat ? 0 : maxVal >= 100 ? 0 : maxVal >= 10 ? 1 : maxVal >= 0.1 ? 2 : 3;

        if (i === Math.floor(mainStrokes.length/2) && isHorizScale) {
          //особый случай - последняя позиция по горизонтали
          if (digits > 1 ) { //уменьшаем чосло знаков
            digits = 1;
            x += shiftX/4 + 2;
          } else if (maxVal >= 100 && maxVal <= 999) {
            x += shiftX/4 - 5;
          }
        }

        let value = mainStrokes[i].value;
        const sValue = value.toFixed(digits);
        item = {x: x, y: y, value: sValue};
    } else {
      let value = mainStrokes[i].multipliedValue.toFixed(supressFloat ? 0 : 1);
        item = {x: x, y: y, value: '' + value + ' * 10 ' + power};
    }

    if (isHorizScale || i > 0) //избегаем показывать значение 0 на вертикальной шкале
      valArr.push(item);
}

return valArr;
};

