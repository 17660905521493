import React, { useEffect, useRef, useState } from 'react';
import {toast} from "react-toastify";
import { Watch } from 'react-loader-spinner';
import Icon from '../Icon';
import ss from './PlayPause.module.scss';

const PlayPause = (props) => {
    // console.log('PlayPause props ===', props);
    const { port, played, paused, setPlay, setPaused, sendCommand, setSensorData, writeStatus } = props;
    const [startPlay, setStartPlay] = useState(false);
    const [playedPrev, setPlayedPrev] = useState(played);

    // console.log(port)
    useEffect(() => {
        if (!port) return;
        if (playedPrev === played) return;
        if (played) {
            sendCommand('acq 1');
        } else {
            sendCommand('acq 0');
            toast.info('Запись эксперимента остановлена');
        }
        setPlayedPrev(played);
    }, [played]);

    useEffect(() => {
        if (startPlay) {
            setSensorData([]);
            // setSensorImported([]);
            // setSensorWritedList([]);
            setTimeout(() => {
                setPlay(true);
                setStartPlay(false);
            }, 500);
        }
    }, [startPlay]);

    return (
        <div className={ss.root}>
            <span
                data-learn="7"
                data-learn-border="7"
                data-learn-position="top"
            >
                <button
                    className="cl_icon_stop"
                    title="Стоп"
                    disabled={!port || !played}
                    onClick={() => {
                        setPlay(false);
                        setPaused(false);
                        setStartPlay(false);
                    }}
                ></button>
            </span>
            <span
                data-learn="5,6"
                data-learn-border="5,6"
                data-learn-position="top"
            >
                <Icon
                    className={paused ? ss.playPause + ' active' : ss.playPause}
                    title="Пуск/пауза"
                    tag="button"
                    disabled={!port || writeStatus}
                    onClick={() => {
                        if (played && !paused) {
                            setPaused(true);
                        } else {
                            setPaused(false);
                            !played && setStartPlay(true);
                        }
                    }}
                >
                    <Icon name={played && !paused ? 'pause' : 'play'} />
                    {startPlay && (
                        <div className={ss.loader}>
                            <Watch
                                height="36"
                                width="36"
                                radius="40"
                                color="#b8bccc"
                                ariaLabel=""
                                visible={true}
                            />
                        </div>
                    )}
                </Icon>
            </span>
        </div>
    );
};

export default PlayPause;
