import React, {useState, useEffect} from 'react';
import {printOnlyDate} from "../../helpers/text";
import {userApi, rlsApi, rlsiApi} from "../../api/api";
import {isAuth} from "../../helpers/auth";
import {fullName} from "../../helpers/auth";
import {Modal, Button} from 'rlabui';
import {getDropdown} from "../ui/utils/gen_utils";
import {getScoreCbList, getScoreValue, getScoreName} from '../ui/utils/score_utils';
import resStyles from '../ReviewTest/Results.module.scss';

const ScenarioResultDetailDlg = ({ showDlg, setShowDlg, selectedStudentId, scenarioInfoId, setIsUpdated }) => {
    const [profile, setProfile] = useState(undefined);
    const [scenarioInfo, setScenarioInfo] = useState(undefined);
    const [scenario, setScenario] = useState(undefined);
    const [updateScenarioInfo, setUpdateScenarioInfo] = useState(undefined);
    const [answers, setAnswers] = useState([]);
    const [objVersion, setObjVersion] = useState(undefined);
    
    useEffect(() => {
        if (!selectedStudentId) return;
        userApi.get(selectedStudentId).then((data) => setProfile(data));
    }, [selectedStudentId]);

    useEffect(() => {
        const getScenarioInfo = async () => {
            const result = await rlsiApi.getScenarioInfoById(scenarioInfoId);
            setObjVersion(result.__v);
            setScenarioInfo(result);
        };
        if (scenarioInfoId)
            getScenarioInfo();
    }, [scenarioInfoId, updateScenarioInfo]);

    useEffect(() => {
        const getScenario = async (id) => {
            const result = await rlsApi.getScenarioById(id);
            setScenario(result);
        };
        if (scenarioInfo)
            getScenario(scenarioInfo.scenario);
    }, [scenarioInfo]);

    useEffect(() => {
        const saveData = async () => {
            await rlsiApi.updateScenarioInfoById(scenarioInfo._id, {...updateScenarioInfo, __v: objVersion})
            .then(res => {
                setUpdateScenarioInfo(undefined);
                setIsUpdated(true);
                setObjVersion(res.__v);
            })
            .catch(err => console.log('err=', err))
        };

        if (!updateScenarioInfo) return;
        saveData();
	}, [scenarioInfo, updateScenarioInfo, objVersion, setIsUpdated]);
        
    useEffect(() => {
        if(!profile || !scenario || !scenarioInfo) return;

        const _answers = [];
        for (let i = 0; i < scenario.questions.length; i ++) {
            const answer = scenarioInfo.answers[i];
            const record = {
                questionText: scenario.questions[i],
                answerType: answer.isCorrect !== null ? answer.isCorrect : -1, //it can be +1, 0 (incorrect), -1 (not estimated by teacher), undefined (no answer)
                textAnswer: answer.answer?.trim(),
                hasAnswer: !!answer.answer?.trim(),
                isCorrect: answer.isCorrect,
            }
            _answers.push(record);
        }

        setAnswers(_answers);
	}, [profile, scenarioInfo, scenario]);

    const handleClose = () => {
        setShowDlg(false);
    };

    const handleRB = (ind, value) => {
        const _answers = scenarioInfo.answers;
        _answers[ind].isCorrect = value;

        const infoData = {
            ...scenarioInfo,
            answers: _answers,
        };

        setUpdateScenarioInfo(infoData);
    };

    const handleScore = value => {
        const infoData = {
            ...scenarioInfo,
            score: value ? Number(value) : null,
        };

        setUpdateScenarioInfo(infoData);
    };

    const getScoreCell = () => {
        if (!scenarioInfo || isAuth().role === 0) return <div></div>;
        const value = !!scenarioInfo.score ? scenarioInfo.score : '';
            
		return getDropdown(getScoreCbList(), getScoreValue(value), val => handleScore(val), 
				'', '', 'score01', "top");
	};

    const getSplitText = text => text ? text.split('\n').map(item => <div>{item}</div>) : <></>;

    const hasNoAnswer = (item, ind, key) => <div className={resStyles.answer__text} key={key+ind}><span className='error'>Нет ответа</span></div>;

    const getTopPart = () => {
        return (
            <div className={resStyles.info}>
                <div className={resStyles.row}>
                    <span className={resStyles.col}>Имя</span>
                    <span className={resStyles.col}>{fullName(profile)}</span>    
                </div>
                <div className={resStyles.row}>
                    <span className={resStyles.col}>Название теста</span>
                    <span className={resStyles.col}>{scenario?.name}</span>    
                </div>
                <div className={resStyles.row}>
                    <span className={resStyles.col}>Дата проведения теста</span>
                    <span className={resStyles.col}>{printOnlyDate(scenarioInfo?.createdAt)}</span>
                </div>
                <div className={resStyles.row}>
                    <span className={resStyles.col}>Количество ответов</span>
                    <span className={resStyles.col}>{scenarioInfo?.answers.filter(item => !!item.answer).length } из { scenario?.questions.length}</span>    
                </div>
                <div className={resStyles.row}>
                    <span className={resStyles.col}>Количество правильных ответов</span>
                    <span className={resStyles.col}>{scenarioInfo?.answers?.filter(item => item.isCorrect === 1).length}  из { scenario?.questions.length}</span>
                </div>
                <div className={resStyles.row}>
                    <span className={resStyles.col}>Количество ответов ожидает проверки</span>
                    <span className={resStyles.col}>{scenarioInfo?.answers?.filter(item => !item.isCorrect).length}  из { scenario?.questions.length}</span>
                </div>
                <div className={resStyles.row}>
                    <span className={resStyles.col}>Оценка</span>
                    <span className={resStyles.col}>{scenarioInfo?.score ? getScoreName(scenarioInfo?.score) : 'не выставлена'}</span>
                </div>     
            </div>
        );
    };

    const getAnswerItem = (item, ind) => {
        const question = scenario.questions[ind];
        //const answerType = scenarioInfo.answers[ind].isCorrect !== null ? scenarioInfo.answers[ind].isCorrect : -1; //it can be +1, 0 (incorrect), -1 (not estimated by teacher), undefined (no answer)
        //const isCorrect = scenarioInfo.answers[ind].isCorrect;

        return (
        <div className={resStyles.answer__item + ( item.answerType === -1 ? (' ' + resStyles.answer__item_warning) : ' ')} 
            key={'item01'+ind}>

            <div className={resStyles.answer__number} key={'num01'+ind}>
                Контрольный вопрос № <span></span>
            </div>

            <div className={resStyles.answer__content} key={'cont01'+ind}>
                <div className={resStyles.answer__row} key={'row02'+ind}>
                    <div className={resStyles.answer__text} key={'qanswer01'+ind}>
                        <span>{question}</span> 
                    </div>
                </div>

                <div className={resStyles.answer__row} key={'row03'+ind}>
                    <div className={resStyles.answer__label} key={'row03a1'+ind}>
                        Ответ
                    </div>

                    {!scenarioInfo?.answers[ind]?.answer ? 
                        hasNoAnswer(item, ind, 'empty01')
                        :
                        <div className={resStyles.answer__text}>
                            <span>{getSplitText(scenarioInfo.answers[ind].answer)}</span>
                        </div>
                    }
                </div>

                {scenarioInfo.answers[ind].answer &&
                <div className={resStyles.answer__row} key={'row04'+ind}>
                    {(isAuth().role > 0 || (scenarioInfo.score && item.isCorrect !== null)) &&
                    <div className={resStyles.answer__label} key={'res01'+ind}>
                        Результат
                    </div>
                    }
                    
                    {isAuth().role > 0 &&
                    <div className={resStyles.answer__options}>
                        <label className={resStyles.answer__option}>
                            <input type="radio" name={"test"+ind}
                                defaultChecked={item.isCorrect !== null ?  Number(item.isCorrect === 1) : false} 
                                value={Number(item.isCorrect === 1)} 
                                onClick={() => handleRB(ind, 1)}>
                            </input>
                            <span>Верно</span>
                        </label>
                        <label className={resStyles.answer__option}>
                            <input type="radio" name={"test"+ind}
                                defaultChecked={item.isCorrect !== null ?  Number(item.isCorrect === 0) : false} 
                                value={Number(item.isCorrect === 0)} 
                                onClick={() => handleRB(ind, 0)}>
                            </input>
                            <span>Неверно</span>
                        </label>
                    </div>
                    }
                    {isAuth().role === 0 && scenarioInfo.score && item.isCorrect !== null &&
                    <div className={resStyles.answer__options}>
                        <label className={resStyles.answer__option}>
                            <span>{item.isCorrect === 1 ? 'Верно' : 'Неверно'}</span>
                        </label>
                    </div>
                    }
                </div>
                }
            </div>
        </div>
        );
    };

    if (!showDlg) {
        return null;
    }

    return (
        <Modal className={resStyles.root} visible={showDlg} size="lg">
            <Modal.Head className={resStyles.head} modalClose={handleClose} title='Результаты тестирования сценария'> 
                {getTopPart()}
            </Modal.Head>
            <Modal.Body>
                <div className={resStyles.main}>
                    <div className={resStyles.answer}>
                        <div className={resStyles.label}>Вопросы</div>
                        <div className={resStyles.answer__list}>
                            {<>{answers.map((item, ind) => getAnswerItem(item, ind))}</>}
                        </div>

                        <div className={resStyles.label}>Отчет</div>
                        <div className={resStyles.answer__list}>
                            <div className={resStyles.answer__item}>
                                {getSplitText(scenarioInfo?.report.answer)}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="cor-net__row">
                    <div className="cor-net__col col-2">
                        {getScoreCell()}
                    </div>
                    <div className="cor-net__col col-2">
                        <Button onClick={handleClose}>Закрыть</Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
};

export default ScenarioResultDetailDlg;
