import { setSensorReading } from '../../actions/reader';
import { setSensorList, setSensorRecords, setSensorImported } from '../../actions/sensors';
import {
    setChartMarkerAdd,
    setChartMarkerDelete,
    setChartMarkerDeleteAll,
    setChartMarkers,
    setChartView,
    delChartMarkers,
    toggleChartParametric,
    toggleChartLissague,
    saveParitalModal,
    setSeiesLength,
} from '../../actions/chart';
import { setForceUpdate } from '../../actions/actions';

export const mapStateToProps = (state) => {
    return {
        port: state.port,
        data: state.sensors.data,
        writedData: state.sensors.writedData,
        sensorWrited: state.sensors.writed,
        sensorImported: state.sensors.imported,
        sensorRecords: state.sensors.records,
        frequency: state.sensors.frequency,
        sensorsUnits: state.sensors.units,
        modalPrint: state.sensors.modalPrint,

        isOscilloscope: state.oscilloscope.isOscilloscope,
        oscilloChartSettings: state.oscilloscope.chartSettings,
        oscilloDefaultChartSettings: state.oscilloscope.defaultChartSettings,
        isHiSpeed: state.sensors.isHiSpeed,

        view: state.chart.settings.view,
        markerActions: state.chart.markers,
        markerMode: state.chart.mode,
        rangeActions: state.chart.range,
        chartMarkers: state.chart.marker,
        defaultColors: state.chart.settings.colors,
        chartStyle: state.chart.style,
        chartDisabled: state.chart.disabled,
        deleteAllMarkers: state.chart.markers.deleteAll,
        legendMode: state.chart.legend,
        parametricMode: state.chart.parametric,
        lissague: state.chart.lissague,
        seriesLength: state.chart.seriesLength,

        // time: state.timer.timer,

        devicesList: state.devices.list,

        // sensorRecords: state.sensors.records,
        records: state.actions.statistic,
        played: state.actions.played,
        paused: state.actions.paused,
        theme: state.actions.theme,
        isMobile: state.actions.isMobile,
        mobileView: state.actions.mobileView,

        isReading: state.reader.isReading,
        sensorReading: state.reader.sensorReading,
        abscissa: state.sensors.abscissa,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setSensorList: (list) => dispatch(setSensorList(list)),
    setSensorRecords: (list) => dispatch(setSensorRecords(list)),
    setSensorImported: (list) => dispatch(setSensorImported(list)),
    setChartMarkers: (obj) => dispatch(setChartMarkers(obj)),
    delChartMarkers: (obj) => dispatch(delChartMarkers(obj)),
    setChartMarkerAdd: (bool) => dispatch(setChartMarkerAdd(bool)),
    setChartMarkerDelete: (bool) => dispatch(setChartMarkerDelete(bool)),
    setChartMarkerDeleteAll: (bool) => dispatch(setChartMarkerDeleteAll(bool)),
    setSensorReading: (bool) => dispatch(setSensorReading(bool)),
    setChartView: (str) => dispatch(setChartView(str)),
    setForceUpdate: (bool) => dispatch(setForceUpdate(bool)),
    toggleChartParametric: (list) => dispatch(toggleChartParametric(list)),
    toggleChartLissague: (bool) => dispatch(toggleChartLissague(bool)),
    saveParitalModal: (bool) => dispatch(saveParitalModal(bool)),
    setSeiesLength: (bool) => dispatch(setSeiesLength(bool)),
});
