export const panPlugin = () => {
    return {
        hooks: {
            ready: (u) => {
                u.root.addEventListener('mousedown', drag);
                u.root.addEventListener('touchstart', drag);

                function drag(e) {
                    const {xHandle, yHandle} = u.zoomPan;
                    if (!xHandle && !yHandle) return;

                    const touches = e.changedTouches?.length ? e.changedTouches[0] : null;

                    if (e.button === 0 || touches) {
                        const { target } = e;
                        const x = target.classList.contains('x-axis');
                        const y = target.classList.contains('y-axis');
                        const area = target.classList.contains('u-over');
                        e.preventDefault();

                        let left0 = touches ? touches.clientX : e.clientX;
                        let top0 = touches ? touches.clientY : e.clientY;

                        let scXMin0 = u.scales.x.min;
                        let scXMax0 = u.scales.x.max;
                        let scYMin0 = u.scales.y.min;
                        let scYMax0 = u.scales.y.max;

                        let xUnitsPerPx = u.posToVal(1, 'x') - u.posToVal(0, 'x');
                        let yUnitsPerPx = u.posToVal(1, 'y') - u.posToVal(0, 'y');

                        function onmove(e) {
                            e.preventDefault();
                            document.body.style.cursor = x ? 'col-resize' : y ? 'row-resize' : 'grabbing';

                            let left1 = touches ? touches.clientX : e.clientX;
                            let top1 = touches ? touches.clientY : e.clientY;

                            let dx = xUnitsPerPx * (left1 - left0);
                            let dy = yUnitsPerPx * (top1 - top0);

                            if ((x || area || touches) && xHandle) {
                                u.setScale('x', {min: scXMin0 - dx, max: scXMax0 - dx});
                            }

                            if ((y || area || touches) && yHandle) {
                                u.axes.forEach((a, i) => i > 0 && u.setScale(a.scale, {min: scYMin0 - dy, max: scYMax0 - dy}));
                            }
                        }

                        function onup() {
                            document.body.style.cursor = '';
                            document.removeEventListener('mousemove', onmove);
                            document.removeEventListener('touchmove', onmove);
                            document.removeEventListener('mouseup', onup);
                            document.removeEventListener('touchend', onup);
                        }

                        document.addEventListener('mousemove', onmove);
                        document.addEventListener('touchmove', onmove);
                        document.addEventListener('mouseup', onup);
                        document.addEventListener('touchend', onup);
                    }
                }
            },
        },
    };
};
