import React, { useState, useEffect, useRef, useCallback } from 'react';
import {toast} from "react-toastify";
import moment from 'moment';
import { Dropdown, Input } from 'rlabui';

import ss from './Timer.module.scss';

const updateTimerInMS = 1;

const Timer = (props) => {
    const { setPlay, played, paused, updateTime, isHiSpeed, stopwatch, settings, setTimer, setTimerSettings, setStopwatch } = props;

    const [time, setTime] = useState(0);
    const [timePause, setTimePause] = useState(0);
    const [started, setStarted] = useState(0);
    const pauseRef = useRef(0);

    const options = [
        { label: 'MM:СС.Mс', value: 'mm:ss:SSS' },
        { label: 'MM:СС', value: 'mm:ss' },
        { label: 'ЧЧ:ММ:СС', value: 'HH:mm:ss' },
    ];

    useEffect(() => {
        if (stopwatch !== 0) {
            setTime(stopwatch);
        } else {
            setTime(0);
        }
    }, [stopwatch]);

    useEffect(() => {
        if (!played) {
            clearInterval(pauseRef.current);
            setStarted(0);
            setTimer(0);
            setTimePause(0);
            setStopwatch(0);
        }
        if (!paused) {
            clearInterval(pauseRef.current);
        }
    }, [played, paused, setTimer]);

    useEffect(() => {
        if (stopwatch && time <= 0 && played) {
            setPlay(false);
            setTime(0);
            setStopwatch(0);
            clearInterval(pauseRef.current);
        }
        if (isHiSpeed && time > 1000 * 60 * 2) {
            setPlay(false);
            clearInterval(pauseRef.current);
            toast.error('Превышена максимальная длительность эксперимента для высокочастотного датчика');
        }
    }, [time, isHiSpeed, played, setStopwatch, stopwatch, setPlay]);

    const updatePause = useCallback(() => {
        clearInterval(pauseRef.current);
        let now = new Date().valueOf();

        pauseRef.current = setInterval(() => {
            setTimePause(timePause + (new Date().valueOf() - now));
        }, updateTimerInMS);
    }, [timePause, setTimePause]);

    useEffect(() => {
        let now;
        if (played) {
            if (paused) {
                updatePause();
            } else {
                if (updateTime) {
                    if (!started) {
                        now = new Date().valueOf();
                        setStarted(now);
                    } else {
                        now = started + timePause;
                        // now = started - timePause;
                    }
                    if (stopwatch) {
                        if (time <= 0) {
                            setPlay(false);
                        } else {
                            setTime(stopwatch - (new Date().valueOf() - now));
                            setTimer(new Date().valueOf() - now);
                        }
                    } else {
                        setTime(new Date().valueOf() - now);
                        setTimer(new Date().valueOf() - now);
                    }
                }
            }
        }
        // fix time maximum update
        // eslint-disable-next-line
    }, [updateTime, played, paused, stopwatch, setPlay, setTimer, started, timePause, updatePause]);

    const setTimeFormatted = (t) => {
        if (t >= 3600000 && settings === 'mm:ss:SSS') {
            return moment(t).utcOffset(0).format('H:mm:ss:SSS');
        } else {
            return moment(t).utcOffset(0).format(settings);
        }
    };

    return (
        <div
            title="Формат времени"
            className={ss.root}
        >
            <Input
                value={setTimeFormatted(time)}
                readOnly
            />
            <Dropdown
                value={''}
                dropPosition="top right"
            >
                {options.map((opt, i) => {
                    const { label, value } = opt;
                    return (
                        <Dropdown.Item
                            key={i}
                            onClick={() => setTimerSettings(value)}
                        >
                            {label}
                        </Dropdown.Item>
                    );
                })}
            </Dropdown>
        </div>
    );
};

export default Timer;
