import { combineReducers } from "redux";

import admin from "./slices/admin";
import classs from "./slices/classsline";
import storage from "./slices/storage";
import options from "./slices/options";
import toggle from './slices/toggle';
import lists from './slices/lists';
import cactions from "./slices/cactions";
import tutorialSlice from "./slices/tutorialslice";
import trelloSlice from "./slices/trello";

import devicesReducer from "../rlab/src/reducer/devices";
import portReducer from "../rlab/src/reducer/port";
import sensorsReducer from "../rlab/src/reducer/sensors";
import streamReducer from "../rlab/src/reducer/stream";
import readerReducer from "../rlab/src/reducer/reader";
import timerReducer from "../rlab/src/reducer/timer";
import chartReducer from "../rlab/src/reducer/chart";
import camsReducer from "../rlab/src/reducer/cams";
import scenarioReducer from "../rlab/src/reducer/scenario";
import writerReducer from "../rlab/src/reducer/writer";
import importReducer from "../rlab/src/reducer/import";
import graduationReducer from "../rlab/src/reducer/graduation";
import oscilloscopeReducer from "../rlab/src/reducer/oscilloscope";
import actionsReducer from "../rlab/src/reducer/actions";
import firmwareReducer from "../rlab/src/reducer/firmware";
import videoReducer from "../rlab/src/reducer/videoeditor";
import demoReducer from '../rlab/src/reducer/demo';

import nappReducer from '../rlab-neuro/src/reducer/app';
import nportReducer from '../rlab-neuro/src/reducer/port';
import nwriterReducer from '../rlab-neuro/src/reducer/writer';
import nimportReducer from '../rlab-neuro/src/reducer/import';
import ntimerReducer from '../rlab-neuro/src/reducer/timer';
import ndeviceReducer from '../rlab-neuro/src/reducer/device';
import nchartReducer from '../rlab-neuro/src/reducer/chart';
import nsensorReducer from '../rlab-neuro/src/reducer/sensor';
import ncameraReducer from '../rlab-neuro/src/reducer/cams';
import nmodalReducer from '../rlab-neuro/src/reducer/modal';
import nexportlReducer from '../rlab-neuro/src/reducer/export';

const combineReducer = combineReducers({
  admin,
  classs,
  storage,
  options,
  lists,
  toggle,
  cactions,
  tutorialSlice,
  trelloSlice,

  port: portReducer,
  actions: actionsReducer,
  devices: devicesReducer,
  sensors: sensorsReducer,
  stream: streamReducer,
  reader: readerReducer,
  timer: timerReducer,
  chart: chartReducer,
  cams: camsReducer,
  writer: writerReducer,
  import: importReducer,
  graduation: graduationReducer,
  oscilloscope: oscilloscopeReducer,
  firmware: firmwareReducer,
  videoeditor: videoReducer,
  demo: demoReducer,
  scenario: scenarioReducer,

  napp: nappReducer,
  nport: nportReducer,
  nwriter: nwriterReducer,
  nimport: nimportReducer,
  ntimer: ntimerReducer,
  ndevice: ndeviceReducer,
  nmodal: nmodalReducer,
  nsensor: nsensorReducer,
  ncams: ncameraReducer,
  nchart: nchartReducer,
  nexport: nexportlReducer,
});

const reducers = (state, action) => {
  if (action.type === 'RESET_REDUX_STATE') {
    state.sensors.list = [];
    state.sensors.writed = [];
    state.sensors.imported = [];
    state.sensors.records = [];
    state.port.open = false;

    state.port.data = {};
    state.port.connectionType = null;
    state.port.response = '';

    state.reader.data = {};
    state.writer.data = {};
    state.stream.data = [];

    state.sensors.item = '';
    state.sensors.data = [];
    state.sensors.aside = [];
    state.sensors.markersImport = {};
    state.sensors.tablePrint = [];
    state.sensors.frequency = {};
    state.sensors.units = {};
    state.sensors.gradOffset = {};

    state.devices.count = 0;
    state.devices.battery = {};
    state.devices.connected = {};
    state.devices.list = {};

    state.chart.marker = {};
    state.chart.style = {};
    state.oscilloscope.isOscilloscope = false;
    state.oscilloscope.oscilloscopeSettings = {};
  }
  return combineReducer(state, action);
};

export default reducers;
