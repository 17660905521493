const initialState = {
    list: [],
    scenario: undefined,
    scenarioId: '',
    load: false,
    scenarioInfo: undefined,
    graphFile: undefined,
    showUnitInfo: false,
    unitInfo: undefined,
};

const scenarioReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SCENARIO_LIST':
            return {
                ...state,
                list: action.payload,
            };

        case 'SET_SCENARIO_ID':
            return {
                ...state,
                scenarioId: action.payload,
        };

        case 'SET_LOAD_SCENARIO': //request to load scenario
        return {
                ...state,
                load: true,
        };

        case 'SET_SCENARIO':
            return {
                ...state,
                scenario: action.payload,
                load: false,
        };

        case 'SET_SCENARIO_INFO':
            return {
                ...state,
                scenarioInfo: action.payload,
        };

        
        case 'SET_GRAPH_FILE': //used in ImportExport
        return {
                ...state,
                graphFile: action.payload,
        };

        case 'SET_SHOW_UNIT_INFO':
            return {
                ...state,
                showUnitInfo: action.payload,
        };

        case 'SET_UNIT_INFO':
            return {
                ...state,
                unitInfo: action.payload,
        };

        default:
            return state;
    };
};

export default scenarioReducer;
