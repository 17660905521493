import React from "react";
import Login from "./Components/authentification/Login";
import PrivateRoute from "./Routes/PrivateRoute";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import Page404 from "./Components/home/404";
import HomeAdmin from "./Components/home/HomeAdmin";
import HomeClass from "./Components/home/HomeClass";
import HomeAnon from "./Components/home/HomeAnon";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './scss/styles.scss';

function App() {
    return (<BrowserRouter>
        <ToastContainer theme="colored"/>
        <Switch>
                <PrivateRoute path="/" exact comp={HomeClass} compAdmin={HomeAdmin}/>
                <PrivateRoute path="/firmware" exact comp={Page404} compAdmin={HomeAdmin}/>
                <PrivateRoute path="/news/:type?/:id?" exact comp={Page404} compAdmin={HomeAdmin}/>
                <PrivateRoute path="/members/:id" exact comp={HomeClass}/>
                <PrivateRoute path="/teachers" exact comp={HomeClass}/>
                <PrivateRoute path="/students" exact comp={HomeClass}/>
                <PrivateRoute path="/reviewtest/:opt/:id?" exact comp={HomeClass}/>
                <PrivateRoute path="/practicum/:opt?/:id?" exact comp={HomeClass}/>
                <PrivateRoute path="/ot_create/:id" exact comp={HomeClass}/>
                <PrivateRoute path="/testview/:id" exact comp={HomeClass}/>
                <PrivateRoute path="/ot/:opt?/:id?" exact comp={HomeClass} compAdmin={HomeAdmin} />
                <PrivateRoute path="/lw/:opt?" exact comp={HomeClass} />
                <PrivateRoute path="/pi/:opt/:id?" exact comp={HomeClass} compAdmin={HomeAdmin}/>
                <PrivateRoute path="/cm/:id?/:opt?" exact comp={HomeClass} compAnon={HomeAnon}/>
                <PrivateRoute path="/notebooks/:opt/:id?" exact comp={HomeClass} compAdmin={HomeAdmin}/>
                <PrivateRoute path="/instruments/:id?" exact comp={HomeClass}/>
                <PrivateRoute path="/instrument/:id" exact comp={HomeClass}/>
                <PrivateRoute path="/whiteboard" exact comp={HomeClass}/>
                <PrivateRoute path="/rlabSelect" exact comp={HomeClass}/>
                <PrivateRoute path="/rlab" exact comp={HomeClass}/>
                <PrivateRoute path="/rlab/:id/:opt?" exact comp={HomeClass} compAnon={HomeAnon} />
                <PrivateRoute path="/rlabExport" exact comp={HomeClass}/>
                <PrivateRoute path="/rlabNeuro" exact comp={HomeClass}/>
                <PrivateRoute path="/rlsc/:opt/:id?" exact comp={HomeClass} compAdmin={HomeAdmin}/>
                <PrivateRoute path="/profile" exact comp={HomeClass}/>
                <PrivateRoute path="/profile/:id/:opt?" exact comp={HomeClass}/>
                <PrivateRoute path="/storage/:key?" exact comp={HomeClass}/>
                <PrivateRoute path="/vrlab/:id?" exact comp={HomeClass}/>
                <PrivateRoute path="/ml" exact comp={HomeClass}/>
                <PrivateRoute path="/trello" exact comp={HomeClass}/>
                <PrivateRoute path="/tutorial/:opt?/:sid?/:bid?" exact comp={HomeClass} compAdmin={HomeAdmin}/>
                <Route path="/login" exact render={(props) => <Login {...props} />}/>
                <Route path="/404" exact render={(props) => <Page404 {...props} />}/>
                <Redirect to="/404"/>
            </Switch>
        </BrowserRouter>);
}

export default App;
