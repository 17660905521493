import React, {useState, useEffect} from "react";
import { Button, Modal } from 'rlabui';
import {SCHEMAS, SCHEMA_TYPE } from './sc_utils';
import { SensorViewBiology, SensorViewChemistry, SensorViewOne, SensorViewPhysics, 
    SensorViewUniversal }  from "../../rlab/src/components/Template/SensorsView";
import ss from './Scenarios.module.scss';

const ScenarioSelectSchemaDlg = ({showDlg, setShowDlg, attachmentName, handleYes}) => {
    const [attachmentId, setAttachmentId] = useState(0);

    useEffect(() => {
        const id = attachmentName ? SCHEMAS.find(item => item.value === attachmentName).value : SCHEMAS[0].value;
        setAttachmentId(id);
    }, [attachmentName]);

    if (!showDlg) {
        return false;
    }

    return (
        <Modal visible={showDlg} size={'xl'} className={ss.widthSensoreModal}>
            <Modal.Head modalClose={() => setShowDlg(false)} title={'Выбор схемы'}/>
            <Modal.Body className={ss.selectScheme}>
                <div className={ss.selectScheme__list}>
                    {SCHEMAS.map((item) => 
                        <div 
                            className={attachmentId === item.value ? 
                                ss.selectScheme__itemActive : ss.selectScheme__item } 
                            onClick={() => setAttachmentId(item.value)} key={item.value}>
                            {item.label}
                        </div>
                    )}
                </div>
                <div className={ss.selectScheme__sensore}>
                    {attachmentId === SCHEMA_TYPE.ONE && <SensorViewOne />}
                    {attachmentId === SCHEMA_TYPE.BIOLOGY && <SensorViewBiology />}
                    {attachmentId === SCHEMA_TYPE.CHEMISTRY && <SensorViewChemistry />}
                    {attachmentId === SCHEMA_TYPE.PHYSICS && <SensorViewPhysics />}
                    {attachmentId === SCHEMA_TYPE.UNIVERSAL && <SensorViewUniversal />}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => handleYes(attachmentId)}>
                    Выбрать шаблон
                </Button>
                <Button border={true}  key='sc15' onClick={() => setShowDlg(false)}>
                    Отмена
                </Button>
            </Modal.Footer>

        </Modal>
    )
};

export default ScenarioSelectSchemaDlg;
