import React, {useEffect, useState} from 'react';
import "./Profile.scss";
import {fullName, isAuth, profilePicture} from "../../helpers/auth";
import {userApi} from "../../api/api";
import {formatBytes, printUserRole} from "../../helpers/text";
import {useDispatch, useSelector} from "react-redux";
import {reloadOrg, reloadUser, storageOrg} from "../../redux/slices/storage";
import {fetchOrgs, fetchUsersByOrg, selectclass} from "../../redux/slices/classsline";
import {useFormik} from "formik";
import * as Yup from "yup";
import {toast} from "react-toastify";
import {Icon} from "../ui";
import Avatar from "react-avatar-edit";
import { ContentBody, ContentFooter } from '../template/ContentParts';
import {loadStorage} from "../../redux/slices/lists";

const Profile = (props) => {
    const dispatch = useDispatch();
    const documentOrg = useSelector(storageOrg);
    const [classs] = useSelector(selectclass);
    const [preview, setPreview] = useState(null);
    const {lists: {storage}} = useSelector(state => state);

    const showStorage = () => isAuth().role === 3 && props.profile.role === 2 && props.profile.uid === storage.uid;

    function onCancel() {
        setPreview(null);
        props.setIsEdit(false);
    }

    function onClose() {
        setPreview(null);
    }
    function onCrop(pv) {
        setPreview(pv);
    }

    useEffect(() => {
        if (isAuth().role === 3 && props.profile.role === 2) {
            dispatch(loadStorage(props.profile.uid));
        }
    }, [dispatch, props.profile]);

    useEffect(() => {
        if (storage.error.code === "NoSuchBucket" && props.profile.uid === storage.uid) {
            props.showStorageSettings({showModal: true, isAdd: true, storage: {uid: props.profile.uid}});
        }
    }, [props, storage]);

    let error = {visible: false, message: ""};
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            login: props.profile.login,
            name: props.profile.name,
            name2: props.profile.name2,
            name3: props.profile.name3,
            city: props.profile.city,
            email: props.profile.email,
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Введите Имя"),
            name2: props.profile.role < 2 && Yup.string().required("Введите Фамилию"),
            name3: props.profile.role < 2 && Yup.string().required("Введите Отчество"),
            login: Yup.string().required("Введите Логин"),
            city: Yup.string().required("Введите Город"),
            email: Yup.string().email("Введите валидный e-mail").required("Введите e-mail"),
        }),
        onSubmit: async (formData) => {
            try {
                const data = {
                    login: formData.login,
                    name: formData.name,
                    name2: formData.name2,
                    name3: formData.name3,
                    city: formData.city,
                    email: formData.email,
                    picture: preview,
                };

                await userApi.update(props.profile._id, data)
                .then((updated) => {
                    if (props.profile.role === 2) {
                        dispatch(fetchOrgs());
                        if (props.profile._id === documentOrg._id) {
                            dispatch(reloadOrg(updated));
                        }
                    } else {
                        dispatch(fetchUsersByOrg(documentOrg._id));
                    }
                    if (props.profile._id === isAuth()._id) {
                        dispatch(reloadUser(updated));
                    }
                    props.setProfile(updated);
                    props.setIsEdit(false);
                })
                .catch((err) => {
                    toast.error(err.response.data.errors);
                });
            } catch (err) {
                error = {
                    visible: true,
                    message: JSON.stringify(err.errors, null, 2),
                };
                toast.error(error);
            }
        },
    });

    const renderPicture = (pict, name) => pict ? <img src={profilePicture(pict)} alt={name}/> : <Icon name='user'/>;
    const handleNameChange = (e)=>formik.setFieldValue(e.target.name,
        e.target.value.replace(/\P{Alpha}+/gu,''));

    return (
        props.isEdit ? 
                <form onSubmit={formik.handleSubmit}>
                    <ContentBody scrolled={false} className='pb_1'>
                        <div className="cor_profile_edit">
                            <div className="cor_profile_edit__name input">
                                <label htmlFor="name">{props.profile.role === 2 ? "№ Школы":"Имя"}</label>
                                <div className="form_error">{formik.errors.name}</div>
                                <input
                                    autoComplete="off"
                                    placeholder="Имя"
                                    className={formik.errors.name ? "form_error" : ""}
                                    name="name"
                                    onChange={props.profile.role < 2 ? handleNameChange : formik.handleChange}
                                    readOnly={isAuth().role === 0}
                                    value={formik.values.name}
                                />
                            </div>
                            {props.profile.role < 2 && <div className="cor_profile_edit__name2 input">
                                <label htmlFor="name2">Фамилия</label>
                                <div className="form_error">{formik.errors.name2}</div>
                                <input
                                    autoComplete="off"
                                    placeholder="Фамилия"
                                    className={formik.errors.name2 ? "form_error" : ""}
                                    name="name2"
                                    readOnly={isAuth().role === 0}
                                    onChange={handleNameChange}
                                    value={formik.values.name2}
                                />
                            </div>}
                            {props.profile.role < 2 && <div className="cor_profile_edit__name3 input">
                                <label htmlFor="name3">Отчество</label>
                                <div className="form_error">{formik.errors.name3}</div>
                                <input
                                    autoComplete="off"
                                    placeholder="Отчество"
                                    className={formik.errors.name3 ? "form_error" : ""}
                                    name="name3"
                                    readOnly={isAuth().role === 0}
                                    onChange={handleNameChange}
                                    value={formik.values.name3}
                                />
                            </div>}
                            <div className="cor_profile_edit__login input">
                                <label htmlFor="login">Логин</label>
                                <div className="form_error">{formik.errors.login}</div>
                                <input
                                    autoComplete="off"
                                    placeholder="Логин"
                                    className={formik.errors.login ? "form_error" : ""}
                                    name="login"
                                    onChange={formik.handleChange}
                                    value={formik.values.login}
                                />
                            </div>
                            <div className="cor_profile_edit__email input">
                                <label htmlFor="email">email</label>
                                <div className="form_error">{formik.errors.email}</div>
                                <input
                                    autoComplete="off"
                                    placeholder="email"
                                    name="email"
                                    className={formik.errors.email ? "form_error" : ""}
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                />
                            </div>
                            <div className="cor_profile_edit__city input">
                                <label htmlFor="city">Город</label>
                                <div className="form_error">{formik.errors.city}</div>
                                <input
                                    autoComplete="off"
                                    placeholder="Город"
                                    className={formik.errors.city ? "form_error" : ""}
                                    name="city"
                                    readOnly={isAuth().role === 0}
                                    onChange={formik.handleChange}
                                    value={formik.values.city}
                                />
                            </div>
                            <div className="cor_profile_edit__picture">
                                <img src={preview??profilePicture(props.profile.picture)}
                                    alt={props.profile.name} title={props.profile.name}/>
                            </div>
                            <div className="cor_profile_edit__picture">
                                <Icon name="trash" onClick={()=>setPreview("/person.svg")} title="Удалить"/>
                            </div>
                            <div className="cor_profile_edit__avatar"><Avatar
                                imageHeight={300}
                                width={300}
                                height={300}
                                exportSize={160}
                                exportQuality={1}
                                exportAsSquare={true}
                                onCrop={onCrop}
                                onClose={onClose}
                                label="Выбрать фото"
                                src={null}
                            /></div>
                        </div>
                    </ContentBody>
                    <ContentFooter>
                        <div className="cor_profile_edit__save">
                            <Icon name="back" text="Вернуться без изменений" onClick={onCancel}/>
                            <Icon name="save" text="Сохранить изменения" onClick={formik.submitForm}/>
                        </div>
                    </ContentFooter>
                </form> 
            :
            <div className='cor_profile__wrap'>
                <ContentBody scrolled={false} className='pb_1'>
                    <div className="cor_profile">
                        <div className="cor_profile__name">{props.profile.role === 2 ? "Школа №" + props.profile.name : fullName(props.profile)}</div>
                        <div className="cor_profile__login">Логин: {props.profile.login}</div>
                        <div className="cor_profile__email">e-mail: <a href={"mailto:" + props.profile.email}>{props.profile.email}</a></div>
                        <div className="cor_profile__info">{printUserRole(props.profile.role)} школы №{documentOrg.name}</div>
                        {props.profile.role > 1 ? <div className="cor_profile__class">Город: {props.profile.city}</div> :
                          <div className="cor_profile__class">{props.profile.role > 0 ? "Преподает" : "Учится"} в классах:&nbsp;
                            {classs.filter(cl => cl.classUsers.includes(props.profile._id)).map((cl) => cl.className).join(", ")}
                        </div>}
                        <div className="cor_profile__picture">{renderPicture(props.profile.picture, props.profile.name)}</div>
                    </div>
                </ContentBody>
                {(isAuth().role === 3 || isAuth()._id === props.profile._id) &&
                  <ContentFooter>
                      <div className="cor_profile__edit">
                          <Icon name="edit" text="Редактировать информацию" onClick={() => props.setIsEdit(true)}/>
                      </div>
                      {showStorage() && (storage.error.message?<div>{storage.error.code === "NoSuchBucket"?"Не создан Bucket":storage.error.message} </div>:
                      <div>Место {formatBytes(storage.du.size??0)} из {formatBytes(storage.quota.quota??0)}</div>)}
                      {showStorage() && <div className="cor_profile__edit">
                        {storage.error.code === "NoSuchBucket"?
                          <Icon name="plus" text="Создать Bucket" onClick={()=>
                            props.showStorageSettings({showModal: true, isAdd:true, storage:{uid: props.profile.uid}})}></Icon>:
                          <Icon name="gear" text="Изменить квоту" onClick={()=>
                            props.showStorageSettings({showModal: true, isAdd:false, storage:{uid: props.profile.uid,
                              size: +(storage.quota.quota/1024/1024/1024).toFixed(2)}})}></Icon>}
                      </div>}
                      {isAuth()._id === props.profile._id && <div className="cor_profile__edit">
                          <Icon name="edit" text="Поменять пароль" onClick={() =>
                            props.showUserPassword({showModal: true, user: props.profile})}/>
                      </div>}
                  </ContentFooter>
                }
            </div>
    )
};
export default Profile;
