import React, {useState, useEffect, useRef} from "react";
import { isAuth } from "../../helpers/auth";
import Table, {TBL_ICON_NAME}  from "../ui/Table/Table";
import {printOnlyDate} from "../../helpers/text";
import {initializeTimeCounter} from "../ui/utils/gen_utils";
import {getIsExecutionOutOfLimit, getIsTestTimeStarted, getIsTestTimeCompleted} from "./task_utils";
import {NB_ACT, getAllNotebookInfoByOwner, getNotebookById, getSubjectById, 
    getDifferenceInDays}  from './nb_utils'; 
import NotebooksViewResultByStudentDlg from './NotebooksViewResultByStudentDlg';
import {getScoreName, getScoreValues} from '../ui/utils/score_utils';
import { Content, ContentBody, ContentHead } from "../template/ContentParts";
import "../template/Dashboard/Dashboard.scss";
import nb from "./Notebooks.module.scss";

const SPEC_TEXT = {
    NEW: 'Новое',
    INFO_NO_DATA: 'Нет данных',
    MANUAL_EVAL: 'Требуется ручная проверка',
    NOT_EXECUTED: 'Не выполнено',
    EXECUTED: 'Сдано',
    BAD_MARK: '2 балла',
    GOOD_MARK: 'из 5',
    DAY: 'день',
    DAYS: 'дня',
};

const NotebookStudentHomeWorksOnSubject = ({isLightMode, history, subjectId}) => {
    const [notebookInfoList, setNotebookInfoList] = useState([]);
	const [tableList, setTableList] = useState([]);
	const [showNbiResultDlg, setShowNbiResultDlg] = useState(false);
	const [selectedIds, setSelectedIds] = useState('');
    const [currTime, setCurrTime] = useState();

	const timerIdRef = useRef(undefined);

	useEffect(() => {
		timerIdRef.current = initializeTimeCounter(3, setCurrTime);//refresh in 3 sec
		return () => {clearInterval(timerIdRef.current);};
    }, []);

    useEffect(() => {
        const fetchData = async () => {
			const ownerId = isAuth()._id;
            const result = await getAllNotebookInfoByOwner(ownerId);
            const data = result.data;
            setNotebookInfoList(data);
        };

		fetchData();
    }, [currTime]);

    const getScoreText = (_notebookData, notebookInfo) => {
        const score = notebookInfo.score;
        if (score === 0 || !!score) return getScoreName(score);
        return SPEC_TEXT.INFO_NO_DATA;
    };

    useEffect(() => {
        const getTableList = async () => {
            const _tableList = [];
            const scoreValues = getScoreValues();
            const minValue = Number(scoreValues[scoreValues.length - 2]);

            for (let i = 0; i < notebookInfoList.length; i ++) {
                const notebookInfo = notebookInfoList[i];
                const notebookId =  notebookInfo.notebook; 
    
                const notebookData = await getNotebookById(notebookId);
    
                if (notebookData.titles.subjectId === subjectId) {
                    const timeRun = (new Date(notebookData.run.runFullDate)).getTime();
                    const timeComplete = (new Date(notebookData.run.completeFullDate)).getTime();

                    const isCompleteTest = notebookInfo.isComplete; 
                    const isExecutionOutOfLimit = getIsExecutionOutOfLimit(notebookData, notebookInfo);

                    const result = getScoreText(notebookData, notebookInfo);

                    const isTimeStarted = getIsTestTimeStarted(timeRun); 
                    const isTimeCompleted = getIsTestTimeCompleted(timeComplete);

                    if (isTimeStarted) {
                        let status = '';
                        if (isCompleteTest || isTimeCompleted || isExecutionOutOfLimit) {
                            if (!notebookInfo.score) {
                                status = SPEC_TEXT.MANUAL_EVAL;
                            } else {
                                status = notebookInfo.score <= minValue ? SPEC_TEXT.NOT_EXECUTED : SPEC_TEXT.EXECUTED;
                            }
                        } else {
                            status = SPEC_TEXT.NEW;
                        }
                   
                        const notebookInfoRecord = {
                            name: notebookData.titles.homeWorkName,
                            item: notebookData.titles.homeWorkItem,
                            dateStart: printOnlyDate(timeRun),
                            leftDays: getDifferenceInDays(timeComplete),
                            taskAmount : notebookInfo.tasks.length + ' шт.',
                            result: result,
                            status: status,
                            actions: '', //for action buttons
                            id: notebookId + '|' + notebookInfo._id + '|' + isAuth()._id, //notebook Id, notebookInfo id  and user id
                        };
        
                        _tableList.push(notebookInfoRecord);
                    }
                }
            }

            setTableList(_tableList);
            };

		getTableList();
    }, [notebookInfoList, subjectId]);

    const handleExecuteTest = ids => {
        history.push('/notebooks/' + NB_ACT.RUN_TEST + '/' + ids); //ids is nbId + '|' + nbiId
    };

    const handleShowDetails = ids => {
        setSelectedIds(ids);
        setShowNbiResultDlg(true);
    };

    const specialCellStyle = (item, rowInd, colInd, row) => {
        if (item === SPEC_TEXT.NEW) return {
            color: 'white', backgroundColor: '#00B5FF', padding: '0.5em 1em', borderRadius: '0.5em'};
        if (item === SPEC_TEXT.INFO_NO_DATA) return {color: 'grey'};
        if (item === SPEC_TEXT.MANUAL_EVAL) return {color: '#C96203'};
        if (item === SPEC_TEXT.NOT_EXECUTED) return {color: 'red'};
        if (item.includes(SPEC_TEXT.BAD_MARK)) return {color: 'red'};
        if (item === SPEC_TEXT.EXECUTED) return {color: 'green'};
        if (item.includes(SPEC_TEXT.GOOD_MARK)) return {color: 'green'};
        if (item.includes(SPEC_TEXT.DAY)) return {color: 'red'};
        if (item.includes(SPEC_TEXT.DAYS) && item.split(' ')[0] === '2') return {color: '#C96203'};
        return {};
    };

    const specialActionCriteria = (ids, iconName) => {
        const rec = tableList.find(item => item['id'] === ids);
        const status = rec['status'];
        if (status === SPEC_TEXT.NEW && iconName === TBL_ICON_NAME.EXECUTE) 
            return true;
         else if (status !== SPEC_TEXT.NEW && iconName === TBL_ICON_NAME.SHOW_DETAILS) 
            return true;
        
        return false;
    };

    const getTableHeader = () => {
		const header = [
			{column: 'Название', name: 'name', style: { width: '15%'} }, 
			{column: 'Тема', name: 'item', style: { width: '12%'} }, 
			{column: 'Дата начала', name: 'dateStart', style: { width: '12%'} }, 
			{column: 'Осталось дней', name: 'leftDays', style: { width: '12%'} }, 
			{column: 'Задания', name: 'taskAmount', style: { width: '10%'} }, 
			{column: 'Результат', name: 'leftDays', style: { width: '10%'} }, 
			{column: 'Статус', name: 'status', style: { width: '12%'} }, 
			{column: 'Действия', name: 'actions', style: { width: '17%'} }, 
			{column: 'id', name: 'id', style: { width: '0%'} }, 
        ];
		return header;
	};

    const getHomeWorkTable = () => {
		return (
		<div className={nb.nb__table}>
			<Table 
				table={{
                    header: getTableHeader(), 
					data: tableList,
                    specialCellStyle: specialCellStyle
				}}
				sort={{hasSorting: true, 
                       initSortInd: -3
                }}
				actions={{
					handleExecuteTest: ids => handleExecuteTest(ids),
					textExecuteTest: 'Сделать задание',
                    handleShowDetails: ids => handleShowDetails(ids),
                    textShowDetails: 'Детальный результат',
                    specialActionCriteria: specialActionCriteria
				}}
			/>
		</div>
		);
    };

    return  (
        <>
            <ContentHead title={"Задания по предмету " + getSubjectById(subjectId)} />
            <Content>
                <ContentBody>
                    {getHomeWorkTable()}
                </ContentBody>
                {showNbiResultDlg &&
                <NotebooksViewResultByStudentDlg
                    showDlg={showNbiResultDlg}
                    setShowDlg={setShowNbiResultDlg}
                    selectedIds={selectedIds}
                />
                }
            </Content>
        </>
    );
}

export default NotebookStudentHomeWorksOnSubject;
