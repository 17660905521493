import React, {useState, useEffect} from "react";
import {InputLimit} from '../ui';
import { ConnectionButtons } from "../../rlab/src/components/Connection/index";
import ConnectedSensorsModal from "../../rlab/src/components/ConnectedSensorsModal";
import {NAME_LIMIT, DESC_LIMIT, getSensorNameBySensor, getDescription} from './sc_utils';
import {calcDecriptionSize} from "../ui/utils/gen_utils";
import {isAuth} from "../../helpers/auth";
import htmlParser from "html-react-parser";
import ss from './Scenarios.module.scss';
import preview from '../../rlab/src/components/Scenario/ScenarioRunDlg.module.scss';

const ScenarioPage1Description = ({scenarioId, isTemplate, scName, setScName, scDescription, setScDescription,
        setScSensorId, scSensorName, setScSensorName, devices, sensor, port}) => {

    const [descriptionCounter, setDescriptionCounter] = useState(0);
    const isDisabledTemplate = isTemplate && isAuth().role < 3;

    useEffect(() => {
        if (!sensor || devices.length === 0) return;
        if (scSensorName || !port) return;

        const [id, name] = getSensorNameBySensor(devices, sensor);
        //console.log('sensor id=', id, ' sensor name=', name);

        if (!name || isDisabledTemplate) return;
        setScSensorId(id);
        setScSensorName(name);
        //ConnectedSensorsModal shows the dialog with devices
    }, [devices, sensor, isDisabledTemplate, scSensorName, setScSensorId, setScSensorName, port]);

    useEffect(() => {
        if (scenarioId) return;
        //console.log('===port=', port)
        //new scenario: clean up id and name
        if (!port) {
            setScSensorId('');
            setScSensorName('');
        }
    }, [scenarioId, port, setScSensorId, setScSensorName]);

    const handleScName = v => {
        if (v.length < NAME_LIMIT)
            setScName(v);
    };
    
    const handleScDescription = v => {
        const counter = calcDecriptionSize(v);
        //if (counter > DESC_LIMIT) return;
        setScDescription(v);
        setDescriptionCounter(counter);
    };

    const getDesc = () => {
        return getDescription(descriptionCounter, DESC_LIMIT, 'Описание сценария', 
            scDescription, v => handleScDescription(v), isDisabledTemplate, 0);
    };

    return (
        <div className={ss.root}>

            <div className="cor-net grow">
                <div className="cor-net__section">
                    <div className="cor-net__row">
                        <div className="cor-net__col col-3">
                            <div className="cor-net__label">Название сценария</div>
                                <InputLimit
                                    value={scName} 
                                    placeholder={'Введите название сценария'} 
                                    onInput={e => handleScName(e.target.value)} 
                                    disabled={isDisabledTemplate}
				    			/>
                        </div>
                        
                        {!isDisabledTemplate &&
                        <div className="cor-net__col col-3">
                            {!scSensorName ? 
                                <div className="cor-net__label">{'Подключите датчик '}</div>
                                :
                                <div className="cor-net__label hide"></div>
                            }
                            <div className="cor-net__row">
                                {scSensorName && 
                                    <span className="cor-net__title mb_0">Датчик {scSensorName}</span>
                                }
                                <ConnectionButtons isRlab={false} />
                            </div>
                        </div>
                        }
                    </div>
                </div>

                <div className="cor-net__section">
                    {getDesc()}
                </div>
            </div>

            <div className={ss.preview__sticky}>
                <div className={ss.preview__title}>Предварительный просмотр</div>
                <div className={ss.preview}>
                    <div className={preview.open}>
                        <div className={preview.toggle}>
                            <b>Описание сценария</b>
                        </div>

                        <div className={preview.body}>
                            <div className={preview.content}>
                                <div className={preview.content__scrolled}>
                                    <div className={preview.title}>
                                        {scName}
                                    </div>
                                    <div className={preview.description}>
                                        {htmlParser(scDescription)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ConnectedSensorsModal />
        </div>
    );
}

export default ScenarioPage1Description; 
