import React, { useEffect, useRef, useState } from 'react';
import {toast} from "react-toastify";
import './Firmware.scss';
import Icon from '../Icon';
import { Modal, Dropdown, Button, Textarea } from 'rlabui';
import parse from 'html-react-parser';

// import version from './version.json';
let boot_file = `${process.env.REACT_APP_API_URL}/files/firmware/boot_app0.bin`;
let bootloader_file = `${process.env.REACT_APP_API_URL}/files/firmware/bootloader_qio_80m.bin`;
const savedVer = JSON.parse(localStorage.getItem('firmvareVersions'));

// const modal = false;

const Firmware = (props) => {
    const {
        port,
        modal,
        devices,
        deviceResponse,
        setProgrammMode,
        startProgramm,
        setFirmwareModal,
        setFirmwareTerminal,
        terminal,
        progress,
        error,
        success,
        setFirmwareError,
        connection,
        size,
        setFirmwareNeedUpdate,
        setFirmwareNewVersion,
        sendCommand,
    } = props;
    const { isAndroid } = window;
    const [sensName, setSensName] = useState('');
    const [sensKey, setSensKey] = useState('');
    const [sensUnicKey, setSensUnicKey] = useState('');
    const [unicKey, setUnicKey] = useState('');
    const [firmwareVer, setFirmwareVer] = useState();
    const [firmwareFile, setFirmwareFile] = useState({});
    const [firmwarePreload, setFirmwarePreload] = useState(false);
    const [currentVer, setCurrentVer] = useState('');
    const [term, showTerm] = useState(false);
    const [fileLoaded, setFileLoaded] = useState(null);
    const [fileHandle, setFileHandle] = useState(null);
    const [saved, setSaved] = useState({});
    const [version, setVersion] = useState([]);
    const [currentStorage, setCurrentStorage] = useState(localStorage.getItem('firmvareVersions'));
    const [notFirmaware, setNotFirmware] = useState(true);
    const [infoRezult, setInfoRezult] = useState(null);
    const fileRef = useRef(null);

    useEffect(() => {
        if (port) {
            // console.log('send inf');
            // sendCommand('fen 101-2307-218');
            // sendCommand('inf');
        } else {
            !isAndroid && setFirmwareNeedUpdate(false);
        }
    }, [port]);

    useEffect(() => {
        setFirmwareFile([]);
        setFirmwareTerminal('');
        setFirmwareError(null);
        if (modal) {
            setCurrentStorage(localStorage.getItem('firmvareVersions'));
        }
    }, [modal]);

    // useEffect(() => {
    //     const resp = deviceResponse.split(' ');
    //     // console.log(resp);
    //     if (resp.length > 1 && resp[0] === 'inf') {
    //         if (resp[1] === 'error') {
    //             // Команда не поддерживается
    //         } else {
    //             // console.log(resp[1]);
    //             // Временный фикс для новых прошивок
    //             if (resp[1]) {
    //                 if (resp[1] === 'unknown-command') return;
    //                 const info = JSON?.parse(resp[1]);
    //                 info && setInfoRezult(+info.ver);
    //             }
    //         }
    //     }
    // }, [deviceResponse]);

    useEffect(() => {
        if (savedVer) {
            setFirmwareNeedUpdate(false);

            if (!savedVer[sensUnicKey]) {
                // !isAndroid && setFirmwareNeedUpdate(true);
            }
            if (firmwareVer && savedVer[sensUnicKey] && savedVer[sensUnicKey] !== firmwareVer[0]?.name) {
                // !isAndroid && setFirmwareNeedUpdate(true);
            }
        } else {
            // port && !isAndroid && setFirmwareNeedUpdate(true);
        }
    }, [firmwareVer, sensUnicKey, port]);

    useEffect(() => {
        if (infoRezult) {
            // console.log(+currentVer, infoRezult);
            if (+currentVer !== infoRezult) {
                // setFirmwareNeedUpdate(true);
            } else {
                // setFirmwareNeedUpdate(false);
            }
            saveUpdatedVersioan(infoRezult + '');
        }
    }, [currentVer, infoRezult]);

    useEffect(() => {
        if (success) {
            saveUpdatedVersioan();
        }
    }, [success]);

    useEffect(() => {
        if (devices['usb0']) {
            const {
                attributes: { name, key },
                unicName,
            } = devices['usb0'];
            const unik = unicName.split('-');
            // console.log(devices['usb0']);

            let sName = name.split(' ');
            if (sName.at && !isNaN(+sName.at(-1))) {
                sName.pop();
            }
            setSensName(sName.join(' '));
            setSensKey(key);
            setSensUnicKey(unicName);
            setUnicKey(unik.length > 1 ? unik[1] : 0);
            fetchFirmwareData(key);
        }
    }, [devices, modal]);

    useEffect(() => {
        if (sensUnicKey) {
            // console.log('Firmware name', sensUnicKey);
            fetchFirmwareData(sensUnicKey);
        }
    }, [sensUnicKey]);

    useEffect(() => {
        if (sensUnicKey) {
            const parse = JSON.parse(currentStorage);
            const locVer = parse && parse[sensUnicKey] ? parse[sensUnicKey] : null;
            setSaved(locVer);
        }
    }, [sensUnicKey]);

    useEffect(() => {
        if (currentVer && modal) {
            setFirmwareFile([]);
            setFileLoaded(null);
            getFirmwareFile();
        }
    }, [currentVer, modal]);

    useEffect(() => {
        // console.log(fileLoaded);
        getFirmwareFile();
    }, [fileLoaded, fileHandle]);

    const fetchFirmwareData = async (key) => {
        // console.log(key);
        // const vers = await fetch(`${process.env.REACT_APP_API_URL}/api/device/${key}`)
        //     .then((resp) => resp.json())
        //     .then((json) => {
        //         // console.log(json);
        //         setNotFirmware(false);
        //         setFirmwareFile([]);
        //         setFirmwareVer(json);
        //         setCurrentVer(json[0]?.name);
        //         setFirmwareNewVersion(json[0]?.name);
        //         setVersion(json);
        //         getFirmwareFile();
        //     })
        //     .catch((e) => {
        //         console.log(e);
        //         setNotFirmware(true);
        //     });
    };

    const startUpdate = async () => {
        console.log(firmwareFile);
        setFirmwarePreload(false);
        await setProgrammMode();
        await startProgramm(firmwareFile);
    };

    const resetUpdate = () => {
        setFirmwareModal(false);
    };

    const handleUpload = async (e) => {
        setFirmwareFile([]);
        setFileHandle(e.target.files[0]);
        const file = await setFileData(e.target.files[0]);
        // console.log(e.target.files[0]);
        setFileLoaded(file);
    };

    const getFirmwareFile = async () => {
        if (!modal) return;
        let loadingError = false;
        const newData = [];
        const boot = await fetch(boot_file)
            .then((resp) => {
                if (!resp.ok) {
                    loadingError = true;
                    throw new Error(resp.status);
                }
                return resp.blob();
            })
            .then((blob) => setFileData(blob))
            .catch((err) => {
                loadingError = true;
                console.error(err);
            });

        newData.push({ data: boot, address: '0xe000' });

        const bootloader = await fetch(bootloader_file)
            .then((resp) => {
                if (!resp.ok) {
                    loadingError = true;
                    console.log('resp false');
                    throw new Error(resp.status);
                }
                return resp.blob();
            })
            .then((blob) => setFileData(blob))
            .catch((err) => {
                loadingError = true;
                console.error(err);
            });

        newData.push({ data: bootloader, address: '0x1000' });

        if (loadingError) {
            toast.error('Не удалось получить файл прошивки. Возможная причина: нет подключения к интернету');
            return;
        }

        let file;
        if (fileLoaded) {
            file = fileLoaded;
        } else {
            const filePath = version.filter((item) => item.name === currentVer)[0];
            // console.log(file);
            file =
                filePath?.path &&
                (await fetch(`${process.env.REACT_APP_API_URL}/${filePath.path}`)
                    .then((resp) => {
                        // console.log(resp);
                        if (!resp.ok) {
                            throw new Error(resp.status);
                        }
                        return resp.blob();
                    })
                    .then((blob) => setFileData(blob))
                    .catch((err) => console.error(err)));
        }
        if (file) {
            newData.push({ data: file, address: '0x10000' });
            setFirmwareFile(newData);
        } else {
            toast.error('Не удалось получить файл прошивки');
        }
    };

    const setFileData = async (blob) => {
        let result = await new Promise((resolve) => {
            let reader = new FileReader();
            reader.onload = (e) => resolve(reader.result);
            reader.readAsBinaryString(blob);
        });
        return result;
    };

    const saveUpdatedVersioan = (ver) => {
        const newSaved = { ...JSON.parse(currentStorage) };
        newSaved[sensUnicKey] = fileLoaded ? '0.0' : ver ? ver : currentVer;
        // newSaved[sensUnicKey] = fileLoaded ? '1.0' : currentVer;
        localStorage.setItem('firmvareVersions', JSON.stringify(newSaved));
    };

    const resetLoadedFile = () => {
        const inp = fileRef?.current;
        setFileHandle(null);
        setFileLoaded(null);
        if (inp) inp.value = '';
    };

    const renderStepOne = () => {
        if (notFirmaware) {
        } else {
        }
        if (!success && !connection && !firmwarePreload) {
            return (
                <>
                    <div className="modal__message">
                        Прошивка{' '}
                        <span>
                            {sensName} {unicKey ? 'key ' + unicKey : ''}
                        </span>
                    </div>
                    {notFirmaware ? (
                        <div className="firmware__alert">
                            <span>Для данного датчика прошивка не доступна</span>
                        </div>
                    ) : (
                        <></>
                    )}
                    {currentVer !== saved && (
                        <div className="firmware__alert">
                            <span>
                                Для данного датчика доступна новая прошивка <i>{version[0] ? 'V ' + version[0].name : ''}</i>
                            </span>
                        </div>
                    )}
                    <div className="firmware__old">Версия вашей прошивки — {saved ? 'V' + saved : 'Не известно'} </div>
                    <div className="firmware__swicher">
                        <span>Выбрать версию</span>
                        <div className="firmware__version">
                            <Dropdown value={currentVer ? 'V ' + currentVer : 'Выберите версию прошивки'}>
                                {firmwareVer?.length > 0 &&
                                    firmwareVer?.map((item) => (
                                        <Dropdown.Item
                                            key={item._id}
                                            onClick={() => setCurrentVer(item.name)}
                                        >
                                            V {item.name}
                                        </Dropdown.Item>
                                    ))}
                            </Dropdown>
                        </div>
                    </div>
                    <label className="firmware__upload">
                        <span>Выбрать и установить прошивку вручную (для опытных пользователей)</span>
                        <input
                            type="file"
                            ref={fileRef}
                            onChange={handleUpload}
                        />
                    </label>
                    {fileHandle && (
                        <div className="firmware__uploadFile">
                            <Icon className="documents-file" />
                            <span>{fileHandle.name}</span>
                            <Icon
                                className="cl_icon_close"
                                onClick={resetLoadedFile}
                            />
                        </div>
                    )}
                </>
            );
        }
    };

    const renderPreload = () => {
        if (!success && firmwarePreload) {
            console.log(fileLoaded);
            error && setFirmwareError(false);
            return (
                <div className="firmware__preload">
                    <div className="modal__message">Установка прошивки</div>
                    <b>
                        Вы собираетесь установить {fileLoaded ? 'загруженную вручную прошивку' : 'прошивку ' + currentVer ? 'V' + currentVer : ''} на датчик {sensName}{' '}
                        {unicKey ? ' key ' + unicKey : ''}. После завершения установки прошивки необходимо обновить приложение и переподключить датчик к устройству.
                    </b>
                </div>
            );
        }
    };

    const renderStepThree = () => {
        if (!success && connection && progress >= 0) {
            return (
                <div className="firmware__load">
                    <div className="modal__message">Идет установка прошивки</div>

                    <div className="firmware__progress">
                        <div className="firmware__progressTop">
                            <span style={{ left: progress + '%' }}>{progress}%</span>
                        </div>
                        <progress
                            max={100}
                            value={progress}
                            style={progress >= 0 ? { opacity: 1 } : { opacity: 0 }}
                        >
                            {progress}
                        </progress>
                        <div className="firmware__progressBottom">
                            <span>{(size / 1024 / 1024).toFixed(1)} Мб</span>
                        </div>
                    </div>
                    <b>Не выключайте программу до завершения установки</b>
                </div>
            );
        }
    };

    const renderSuccess = () => {
        if (success) {
            return (
                <div className="firmware__success">
                    <div className="modal__message">
                        Прошивка <span>успешно</span> установлена
                    </div>
                    <b>Для продолжения работы необходимо обновить приложение и переподключить датчик к устройству</b>
                </div>
            );
        }
    };

    const renderError = () => {
        if (error) {
            return (
                <>
                    <div className="firmware__term">
                        <div className="firmware__label">Отладочная информация</div>
                        <div
                            className="firmware__toggle"
                            onClick={() => showTerm(!term)}
                            title={term ? 'Скрыть терминал' : 'Показать терминал'}
                        >
                            <Icon
                                name="caret"
                                className={term && 'inverse'}
                            />
                        </div>
                    </div>
                    <Textarea
                        readOnly={true}
                        className={term ? 'firmware__info open' : 'firmware__info'}
                        value={parse(terminal)}
                        onChange={({ target }) => {
                            const end = target.value.length;
                            target.setSelectionRange(end, end);
                            target.focus();
                        }}
                    />
                </>
            );
        }
    };

    const renderActions = () => {
        if (success || connection) {
            if (success) return <Button onClick={() => window.location.reload(true)}>Перезагрузить</Button>;
            if (connection) return;
        } else {
            if (firmwarePreload) {
                return (
                    <>
                        {/* {!notFirmaware && <Button onClick={startUpdate}>Установить</Button>} */}
                        {<Button onClick={startUpdate}>Установить</Button>}
                        <Button
                            border={true}
                            onClick={() => setFirmwarePreload(false)}
                        >
                            Отмена
                        </Button>
                    </>
                );
            } else {
                return (
                    <>
                        <Button onClick={() => setFirmwarePreload(true)}>Установить прошивку</Button>
                        <Button
                            border={true}
                            onClick={resetUpdate}
                        >
                            Закрыть
                        </Button>
                    </>
                );
            }
        }
    };

    return (
        modal && (
            <Modal visible={modal}>
                <Modal.Head modalClose={resetUpdate}></Modal.Head>
                <div className="firmware">
                    <Modal.Body>
                        {renderStepOne()}
                        {renderPreload()}
                        {renderStepThree()}
                        {renderSuccess()}
                        {renderError()}
                    </Modal.Body>
                    <Modal.Footer>{renderActions()}</Modal.Footer>
                </div>
            </Modal>
        )
    );
};
export default Firmware;
