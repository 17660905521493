const initialState = {
    modal: false,
    reset: false,
    about: false,
    version: false,
};

const exampleReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'TOGGLE_NMODAL':
            return {
                ...state,
                modal: action.payload,
            };

        case 'SET_NRESET_MODAL':
            return {
                ...state,
                reset: action.payload,
            };

        case 'SET_NABOUT_MODAL':
            return {
                ...state,
                about: action.payload,
            };

        case 'SET_NVERSION_MODAL':
            return {
                ...state,
                version: action.payload,
            };

        default:
            return state;
    }
};

export default exampleReducer;
