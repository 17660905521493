import {toggleAudio, toggleScreen, toggleVideo} from "../../actions/toggle";
import {reloadOption} from "../../redux/slices/storage";

export const mapStateToProps = (state) => {
    return ({
        video: state.toggle.video,
        audio: state.toggle.audio,
        screen: state.toggle.screen,
        peers: state.lists.peers,
    });
};

export const mapDispatchToProps = (dispatch) => ({
    toggleVideo: (bool) => dispatch(toggleVideo(bool)),
    toggleAudio: (bool) => dispatch(toggleAudio(bool)),
    toggleScreen: (bool) => dispatch(toggleScreen(bool)),
    setUserVolumes: (dict) => dispatch(reloadOption('userVolumes',dict)),
});
