import React, {useState, useEffect} from 'react';
import Modal from "../../Components/Modal";
import { Button } from '../ui';
import {fullName} from "../../helpers/auth";
import {printOnlyDate} from "../../helpers/text";
import {getScoreName} from '../ui/utils/score_utils';
import NotebooksViewtResultByTeacherDlg from './NotebooksViewtResultByTeacherDlg';
import {getSubjectById, getNotebookById, getNotebookInfoById} from "./nb_utils";
import "../ui/ModalDialogs/PracticumTests.scss";

const NotebooksViewResultByStudentDlg = ({showDlg, setShowDlg, selectedIds}) => {
    const [notebookId, setNotebookId] = useState(undefined);
	const [notebookData, setNotebookData] = useState(undefined);
    const [notebookInfoId, setNotebookInfoId] = useState(undefined);
	const [notebookInfoData, setNotebookInfoData] = useState(undefined);
	const [showDetailsDlg, setShowDetailsDlg] = useState(false);
	const [userNbiIds, setUserNbiIds] = useState(undefined);
    
    //1. get selectedStudentId and notebookInfoId:
    useEffect(() => {
        const _notebookInfoId = selectedIds.split('|')[1];
        setNotebookInfoId(_notebookInfoId);
        const userId = selectedIds.split('|')[2];
        setUserNbiIds(userId + '|' + _notebookInfoId);
    }, [selectedIds]);

    //2. get nbi data notebookInfoId:
    useEffect(() => {
		const getNotebookInfoData = async (_notebookInfoId) => {
			const result = await getNotebookInfoById(_notebookInfoId);
			setNotebookInfoData(result);
            setNotebookId(result.notebook);
		};

        if (!notebookInfoId) return;
        getNotebookInfoData(notebookInfoId);
    }, [notebookInfoId]);

    //4. get nb data and nb data by notebookId:
    useEffect(() => {
        const getNotebookData = async (_notebookId) => {
			const data = await getNotebookById(_notebookId);
            setNotebookData(data);
        };

        if (!notebookId) return;
        getNotebookData(notebookId);
    }, [notebookId]);

    const getInfo = (item, value) => {
        return (
        <div className="initTest_Row">
            <div className="initTest_RowCol1">{item}</div>
            <div className="initTest_RowColInterm"></div>
            <div className="initTest_RowCol2">{value}</div>    
        </div>
        );
    };

    const getScoreText =  () => {
        if (notebookData && notebookInfoData) {
            const score = notebookInfoData.score;
            if (score === 0 || !!score)
    			return getScoreName(score);
        }
        
        return 'Требуется ручная проверка';
    };

    const getTimeSpent = () => {
        if (!notebookInfoData) return '';
        const timeSpentInSec = notebookInfoData.actualSpentTime;
        const hrs = Math.floor(timeSpentInSec / (60 * 60));
        let remTime = timeSpentInSec - hrs * (60 * 60);
        const mins = Math.floor(remTime / 60);
        const secs = remTime - mins * 60;
        const timeSpentText = (hrs !== 0 ?  '' + hrs + ' ч ' : '') + 
                              (mins !== 0 ?  '' + mins + ' мин ' : '') + 
                              (secs !== 0 ?  '' + secs + ' сек' : '');
        return timeSpentText;
    };

    const setModalContent = () => {
        if (!notebookData || !notebookInfoData) return <></>;

        const tasks = notebookInfoData.tasks;
        const correctAnswerNumber = tasks.filter(item => item.isCorrect === 1).length;
        const scoreText = getScoreText(); 
        const timeSpent = getTimeSpent();

        let errors = 0;
        for (let i = 0; i < tasks.length; i ++) {
            const answerHistory = tasks[i].userAnswerHistory.split('|');
            const errorAnswers = answerHistory.filter(item => item !== '0');
            errors += errorAnswers.length;
        }

        //find all nbi by user id and notebookId
        //сохранять всегда затраченное время. и затем показать его здесь.
        //не показывать кнопку пересдачи
        // см мою функциюю - какие параметры еще стоит добавить. 

        return (
            <div>
                <div className="modal__close" onClick={() => setShowDlg(false)}></div>
                <div className="modal__message">Детальный результат задания</div>
                <div className="modal__body">
                    {getInfo('Предмет', notebookData ? getSubjectById(notebookData.titles.subjectId) : '')}
                    {getInfo('Название задания', notebookData && notebookData.titles ? notebookData.titles.homeWorkName : '')}
                    {getInfo('Тема', notebookData && notebookData.titles ? notebookData.titles.homeWorkItem : '')}
                    {getInfo('Дата сдачи задания', notebookInfoData ? printOnlyDate(notebookData.run.runFullDate) : '' )}
                    {getInfo('Кол-во вопросов', tasks.length)}
                    {getInfo('Правильных ответов', correctAnswerNumber)}
                    {getInfo('Результат', scoreText)}
                    {getInfo('Ошибок сделано', errors)}
                    {getInfo('Времени затрачено', timeSpent)}
                    {!!notebookInfoData.score && getInfo('Проверено', 'Преподаватель ' + fullName(notebookData.owner))}
                    {getInfo(' ', ' ')}
                </div>
                <div className="modal__action">
                    <Button color="primary" border={true} onClick={() => {setShowDetailsDlg(true)}} >
                        Просмотр
                    </Button>
                    <Button color="primary" onClick={() => setShowDlg(false)}>
                        Закрыть
                    </Button>
                </div>
                {showDetailsDlg &&
                <NotebooksViewtResultByTeacherDlg   
                    showDlg={showDetailsDlg}
                    setShowDlg={setShowDetailsDlg}
                    selectedIds={userNbiIds}
                    setScoreUpdated={() => {}}
                />
                }
            </div>
        )
    }

    if (!showDlg) {
        return false;
    }

    return (
        <Modal visible={showDlg} content={setModalContent()} size={'md'} />
    )
};

export default NotebooksViewResultByStudentDlg;
