import React from "react";
import "./Dashboard.scss";
import Footer from "../Footer";
import {Content, ContentWrap} from "../ContentParts";
import "tldraw/tldraw.css";
import WhiteBoard from "../../WhiteBoard";

export const DashboardWhite = (props) => {
  return (
    <ContentWrap>
      <Content>
        <WhiteBoard/>
      </Content>
      <Footer {...props}/>
    </ContentWrap>
  );
}
export default DashboardWhite