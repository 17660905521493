import React, { useState, useEffect, useCallback, memo } from 'react';
import parse from 'html-react-parser';
import { HexColorPicker } from 'react-colorful';
import {toast} from "react-toastify";
import { Modal, Button, Dropdown, InputNumber, Range } from 'rlabui';
import './styles.scss';

const interpolationsArr = [
    { label: 'linear', value: 'linear' },
    { label: 'basis', value: 'basis' },
    { label: 'bundle', value: 'bundle' },
    { label: 'cardinal', value: 'cardinal' },
    { label: 'catmull room', value: 'catmull' },
    { label: 'monotone', value: 'monotone' },
    { label: 'natural', value: 'natural' },
    { label: 'step', value: 'step' },
    { label: 'step after', value: 'stepafter' },
    { label: 'step before', value: 'stepbefore' },
];

const calcArr = [
    { label: 'умножение на константу (x*c)', value: '*c' },
    { label: 'деление на константу (x/c)', value: '/c' },
    { label: 'сложение с константой (x+c)', value: '+c' },
    { label: 'вычитание константы (x-c)', value: '-c' },
    { label: 'умножение на первую константу и сложение со второй (x*c+d) ', value: '*c-d' },
    { label: 'возведение в квадрат (x^2)', value: '^2' },
    { label: 'извлечение квадратного корня (sqrt(x)) ', value: 'sqrt(x)' },
    { label: 'экспонента (e^x) ', value: 'e^' },
    { label: 'натуральный логарифм (ln(x)) ', value: 'ln(x)' },
    { label: 'возведение числа 10 в степень (10^x) ', value: '10^' },
    { label: 'десятичный логарифм (log10(x))', value: 'log10(x)' },
];

const ChartStyles = (props) => {
    const { modal, sensorList, devicesList, sensorImported, sensorWrited, setChartSettingsModal, chartColors, setChartStyle, chartStyle, sensorAbscissa, setSensorAbscissa } = props;
    const [chartNames, setChartNames] = useState(null);
    const [lineIndex, setLineIndex] = useState(0);
    const [sensNum, setSensNum] = useState(null);
    const [lineWeight, setLineWeight] = useState(2);
    const [lineColor, setLineColor] = useState();
    const [pointWeight, setPointWeight] = useState(0);
    const [pointColor, setPointColor] = useState();
    const [interpolation, setInterpolation] = useState('basis');
    const [interpolationLabel, setInterpolationLabel] = useState('basis');
    const [abscissa, setAbscissa] = useState(null);
    const [cData, setCData] = useState(0);
    const [dData, setDData] = useState(0);

    useEffect(() => {
        const savedStyles = JSON.parse(window.localStorage.getItem('styles'));
        // console.log('ChartStyles', chartStyle);
        chartsNamesArr();
        let newStyles = savedStyles ? { ...savedStyles } : { ...chartStyle };

        const allSens = [...sensorImported, ...sensorWrited, ...sensorList];
        if (!sensNum) {
            setSensNum(allSens[0]?.sensNum);
        }

        // modal &&
        Object.values(devicesList)?.forEach((device, d) => {
            // console.log(device);
            const channels = device.getElementsByTagName('channels')[0]?.children;
            channels?.forEach((sens, i) => {
                const num = d > 0 ? 20 + i : i;
                setStyles(num, i);
            });
        });

        [...sensorImported, ...sensorWrited].forEach(({ num }, i) => setStyles(num, i));

        function setStyles(num, i) {
            const saved = JSON.parse(window.localStorage.getItem('styles'));
            const style = saved[num] ? { ...saved[num] } : { ...chartStyle[num] };

            const color = style?.color ? style.color : chartColors[i]?.val;
            const weight = style?.weight ? style.weight : 2;
            const pWidth = style?.point?.width ? style.point.width : 0;
            const pColor = style?.point?.color ? style.point.color : chartColors[i]?.val;
            const interpolation = style?.interpolation ? style.interpolation : 'basis';
            if (i === lineIndex) {
                setLineColor(color);
                setLineWeight(weight);
                setPointColor(color);
                setPointWeight(pWidth);
            }
            newStyles[num] = {
                weight,
                color: color ? color : chartColors[0].val,
                point: {
                    width: pWidth,
                    color: pColor ? pColor : chartColors[0].val,
                },
                interpolation,
            };
        }

        setChartStyle(newStyles);
        window.localStorage.setItem('styles', JSON.stringify(newStyles));
    }, [sensorList.length, sensorImported.length, sensorWrited.length, devicesList, lineIndex, chartColors]);

    useEffect(() => {
        if (modal) {
            const saved = JSON.parse(window.localStorage.getItem('styles'));
            if (saved && saved[sensNum]) {
                const {
                    color,
                    weight,
                    point: { width: pWidth, color: pColor },
                } = saved[sensNum];
                console.log();
                setLineColor(color);
                setLineWeight(weight);
                setPointColor(pColor);
                setPointWeight(pWidth);
            }
        }
    }, [modal]);

    useEffect(() => {
        const savedStyles = JSON.parse(window.localStorage.getItem('styles'));
        const sensors = [...sensorList, ...sensorImported, ...sensorWrited];
        const num = sensors[lineIndex]?.num ? sensors[lineIndex].num : 0;
        const sensNum = sensors[lineIndex]?.sensNum ? sensors[lineIndex].sensNum : 0;
        const style = savedStyles[num] ? savedStyles[num] : chartStyle[num];
        setSensNum(sensNum);
        if (style) {
            setLineColor(style.color);
            setLineWeight(style.weight);
            setPointColor(style.point.color);
            setPointWeight(style.point.width);
        }
    }, [lineIndex]);

    useEffect(() => {
        setAbscissa(sensorAbscissa[sensNum]);
        if (sensorAbscissa[sensNum]?.c) {
            setCData(sensorAbscissa[sensNum]?.c);
        } else {
            setCData(0);
        }
        if (sensorAbscissa[sensNum]?.d) {
            setDData(sensorAbscissa[sensNum]?.d);
        } else {
            setDData(0);
        }
    }, [sensNum]);

    useEffect(() => {
        const label = interpolationsArr.filter((item) => item.value === interpolation)[0].label;
        if (label) {
            setInterpolationLabel(label);
        }
    }, [interpolation]);

    useEffect(() => {
        if (abscissa) {
            setSensorAbscissa({ key: sensNum, data: { ...abscissa, c: cData, d: dData } });
        } else {
            setDData(0);
            setCData(0);
            setSensorAbscissa({ key: sensNum, data: null });
        }
    }, [abscissa, cData, dData]);

    const chartsNamesArr = useCallback(() => {
        const arr = [];
        const sensors = [...sensorList, ...sensorImported, ...sensorWrited];
        sensors.map((item, i) => {
            const { num } = item;
            let preffix = '';
            if (typeof num === 'string') {
                if (num.includes('loc')) {
                    preffix = ' (Лог)';
                }
                if (num.includes('imp')) {
                    preffix = ' (Имп)';
                }
            }
            item?.name && arr.push({ label: item.name, value: i, preffix });
        });
        setChartNames(arr);
    }, [sensorList.length, sensorImported.length, sensorWrited.length]);

    const saveLineStyle = (index) => {
        const updated = { ...chartStyle };
        const sensors = [...sensorList, ...sensorImported, ...sensorWrited];
        sensors?.forEach((sens, i) => {
            if (i === index) {
                const { num } = sens;
                updated[num] = {
                    weight: +lineWeight,
                    color: lineColor,
                    point: {
                        width: pointWeight,
                        color: pointColor,
                    },
                    interpolation,
                };
            }
        });
        setChartStyle(updated);
        window.localStorage.setItem('styles', JSON.stringify(updated));
        toast.success('Стили для графиков применены');
    };

    const resetChartStyles = () => {
        const saved = { ...JSON.parse(window.localStorage.getItem('styles')) };
        for (const [i, num] of Object.keys(saved).entries()) {
            const color = chartColors[num]?.val ? chartColors[num].val : chartColors[i]?.val || '#F35151';
            saved[num] = {
                weight: 2,
                color,
                point: {
                    width: 0,
                    color,
                },
                interpolation: 'basis',
            };
        }
        setLineWeight(2);
        setLineColor(chartColors[sensNum]?.val || chartColors[0].val);
        setPointWeight(0);
        setPointColor(chartColors[sensNum]?.val || chartColors[0].val);
        setChartStyle(saved);
        window.localStorage.setItem('styles', JSON.stringify(saved));
        toast.success('Стили для графиков сброшены');
    };

    const setModalContent = () => {
        return (
            <div className="modalSettings">
                <Modal.Head
                    title="Настройки отображения"
                    modalClose={() => setChartSettingsModal(false)}
                ></Modal.Head>
                <Modal.Body>
                    <div className="modalSettings__body">
                        <div className="modalSettings__col modalSettings__col_full">
                            <div className="modalSettings__label">Датчик</div>
                            <Dropdown
                                clicked={true}
                                value={chartNames && chartNames[lineIndex]?.label ? chartNames[lineIndex].label + chartNames[lineIndex].preffix : 'Выберите датчик'}
                            >
                                {chartNames?.map(({ label, value, preffix }) => (
                                    <Dropdown.Item
                                        key={'line' + value}
                                        onClick={() => setLineIndex(value)}
                                    >
                                        {label} {preffix}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown>
                        </div>
                        <div className="modalSettings__col">
                            <div className="modalSettings__label">Цвет линии</div>
                            <Dropdown
                                className="notscroll colors"
                                clicked={true}
                                multy={true}
                                value={lineColor ? parse(`<span class="colors_val" style="color: ` + lineColor + `"></span> ${lineColor}`) : 'Выберите цвет линии'}
                            >
                                <Dropdown.Item>
                                    <HexColorPicker
                                        color={lineColor}
                                        onChange={(color) => setLineColor(color)}
                                    />
                                </Dropdown.Item>
                            </Dropdown>
                        </div>
                        <div className="modalSettings__col">
                            <div className="modalSettings__label">
                                Толщина линии
                                <span className="modalSettings__label_val">{lineWeight}px</span>
                            </div>
                            <div className="modalSettings__range">
                                <Range
                                    min="0"
                                    max="10"
                                    step="1"
                                    value={lineWeight}
                                    onChange={({ target: { value } }) => setLineWeight(+value)}
                                />
                            </div>
                        </div>
                        <div className="modalSettings__col">
                            <div className="modalSettings__label">Цвет точек</div>
                            <Dropdown
                                className="notscroll colors"
                                clicked={true}
                                value={pointColor ? parse(`<span class="colors_val" style="color: ` + pointColor + `"></span> ${pointColor}`) : 'Выберите цвет точек'}
                            >
                                <Dropdown.Item>
                                    <HexColorPicker
                                        color={pointColor}
                                        onChange={(color) => setPointColor(color)}
                                    />
                                </Dropdown.Item>
                            </Dropdown>
                        </div>
                        <div className="modalSettings__col">
                            <div className="modalSettings__label">
                                Толщина точек
                                <span className="modalSettings__label_val">{pointWeight}px</span>
                            </div>
                            <div className="modalSettings__range">
                                <Range
                                    min="0"
                                    max="10"
                                    step="1"
                                    value={pointWeight}
                                    onChange={({ target: { value } }) => setPointWeight(+value)}
                                />
                            </div>
                        </div>
                        {
                            <div className="modalSettings__col">
                                <div className="modalSettings__label">Интерполяция</div>
                                <div className="modalSettings__range">
                                    <Dropdown
                                        value={interpolationLabel ? interpolationLabel : 'Выберите способ интерполяции'}
                                        clicked={true}
                                    >
                                        {interpolationsArr.map(({ label, value }) => {
                                            return (
                                                <Dropdown.Item
                                                    key={value}
                                                    onClick={() => setInterpolation(value)}
                                                >
                                                    {label}
                                                </Dropdown.Item>
                                            );
                                        })}
                                    </Dropdown>
                                </div>
                            </div>
                        }
                        <div className="modalSettings__col"></div>
                        <div className="modalSettings__col">
                            <div className="modalSettings__label">Абсциссы</div>
                            <Dropdown
                                value={abscissa ? abscissa.label : 'Выберите формулу'}
                                clicked={true}
                            >
                                <Dropdown.Item onClick={() => setAbscissa()}>Сбросить</Dropdown.Item>
                                {calcArr.map((calc, i) => {
                                    const { label, value } = calc;
                                    return (
                                        <Dropdown.Item
                                            key={i}
                                            onClick={() => {
                                                if (value !== '*c-d') {
                                                    setDData(0);
                                                }
                                                setAbscissa(calc);
                                            }}
                                        >
                                            {label}
                                        </Dropdown.Item>
                                    );
                                })}
                            </Dropdown>
                        </div>
                        {abscissa?.value.includes('c') && (
                            <div className="modalSettings__col">
                                <div className="modalSettings__label">Данные константы С</div>
                                <InputNumber
                                    value={cData}
                                    onChange={({ target }) => setCData(+target.value)}
                                />
                            </div>
                        )}
                        {abscissa?.value.includes('d') ? (
                            <>
                                <div className="modalSettings__col"></div>
                                <div className="modalSettings__col">
                                    <div className="modalSettings__label">Данные константы D</div>
                                    <InputNumber
                                        value={dData}
                                        onChange={({ target }) => setDData(+target.value)}
                                    />
                                </div>
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer align="left">
                    <Button onClick={() => saveLineStyle(lineIndex)}>Применить</Button>
                    <Button
                        border={true}
                        onClick={() => setChartSettingsModal(false)}
                    >
                        Закрыть
                    </Button>
                    <Button
                        color="danger"
                        link={true}
                        onClick={resetChartStyles}
                    >
                        Сброс
                    </Button>
                </Modal.Footer>
            </div>
        );
    };

    return modal && <Modal visible={modal}>{setModalContent()}</Modal>;
};
export default memo(ChartStyles);
