import {toggleChat} from "../../actions/toggle";

export const mapStateToProps = (state) => {
    return ({
    });
};

export const mapDispatchToProps = (dispatch) => ({
    toggleChat: (bool) => dispatch(toggleChat(bool)),
});
