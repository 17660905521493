const initialState = {
    graduate: false,
};

const graduationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_GRADUATE':
        return {
            ...state,
            graduate: action.payload,
        };

        default:
        return state;
    };
};

export default graduationReducer;
