import React from 'react';
import "../Content.scss";

const ContentTitle = ({children, className = ''}) => {
    return (
        <div className={"cor_content__title " + className}>
            {children}
        </div>
    )
};
export default ContentTitle;
