import React, { useState, useEffect, useCallback } from 'react';
// import Modal from '../../ui/Modal';
import parse from 'html-react-parser';
import { HexColorPicker } from 'react-colorful';
import { Dropdown, Range, Modal, Button } from 'rlabui';
import ss from './ChartSettings.module.scss';

const interpolationsArr = [
    { label: 'linear', value: 'linear' },
    { label: 'basis', value: 'basis' },
    { label: 'bundle', value: 'bundle' },
    { label: 'cardinal', value: 'cardinal' },
    { label: 'catmull room', value: 'catmull' },
    { label: 'monotone', value: 'monotone' },
    { label: 'natural', value: 'natural' },
    { label: 'step', value: 'step' },
    { label: 'step after', value: 'stepafter' },
    { label: 'step before', value: 'stepbefore' },
];

const ChartSettings = (props) => {
    const { modal, sensorList, sensorImported, setChartSettingsModal, chartColors, setChartStyle, chartStyle, visualized, sensorChannels } = props;
    const [sensors, setSensors] = useState([]);
    const [chartNames, setChartNames] = useState(null);
    const [lineIndex, setLineIndex] = useState(0);
    const [lineWeight, setLineWeight] = useState(1);
    const [lineColor, setLineColor] = useState(chartColors[0].val);
    const [pointWeight, setPointWeight] = useState(0);
    const [pointColor, setPointColor] = useState(chartColors[0].val);
    const [interpolation, setInterpolation] = useState('basis');
    const [interpolationLabel, setInterpolationLabel] = useState('basis');

    useEffect(() => {
        let sensors = [...sensorList, ...sensorImported];
        if (visualized) {
            const list = sensors.filter((e) => e.key.includes(visualized))[0];
            if (list?.channels) {
                sensors = [list, ...list.channels.filter((e) => e.name !== 'EEGAB')];
            }
        }
        setSensors(sensors);
    }, [visualized, sensorList, sensorImported]);

    const chartsNamesArr = useCallback(() => {
        const arr = [];
        sensors.forEach((item, i) => {
            const { num, key } = item;
            let preffix = '';
            if (typeof num === 'string') {
                if (num.includes('imp')) {
                    preffix = ' (Имп)';
                }
            }
            item?.name && arr.push({ label: item.name, value: i, preffix, key });
        });
        setChartNames(arr);
    }, [sensors]);

    useEffect(() => {
        // console.log('ChartSettings', chartStyle);
        chartsNamesArr();
        const newStyles = {};

        sensors.forEach((sens, i) => {
            const { key } = sens;
            setStyles(key, i);
        });

        setChartStyle(newStyles);

        function setStyles(key, i) {
            const style = { ...chartStyle[key] };

            const color = style?.color ? style.color : chartColors[i]?.val;
            const weight = style?.weight ? style.weight : 1;
            const pWidth = style?.point?.width ? style.point.width : 0;
            const pColor = style?.point?.color ? style.point.color : chartColors[i]?.val;
            const interpolation = style?.interpolation ? style.interpolation : 'basis';
            newStyles[key] = {
                weight,
                color: color ? color : chartColors[0].val,
                point: {
                    width: pWidth,
                    color: pColor ? pColor : chartColors[0].val,
                },
                interpolation,
            };
        }
        // eslint-disable-next-line
    }, [sensors, sensorChannels, chartColors, chartsNamesArr, setChartStyle]);

    useEffect(() => {
        const key = sensors[lineIndex]?.key ? sensors[lineIndex].key : 0;
        const style = chartStyle[key];
        if (style) {
            setLineColor(style.color);
            setLineWeight(style.weight);
            setPointColor(style.point.color);
            setPointWeight(style.point.width);
        }
    }, [lineIndex, chartStyle, sensors]);

    useEffect(() => {
        const label = interpolationsArr.filter((item) => item.value === interpolation)[0].label;
        if (label) {
            setInterpolationLabel(label);
        }
    }, [interpolation]);

    const saveLineStyle = (index) => {
        const updated = { ...chartStyle };
        sensors?.forEach((sens, i) => {
            if (i === index) {
                const { key } = sens;
                updated[key] = {
                    weight: +lineWeight,
                    color: lineColor,
                    point: {
                        width: pointWeight,
                        color: pointColor,
                    },
                    interpolation,
                };
            }
        });
        setChartStyle(updated);
    };

    return (
        modal && (
            <Modal visible={modal}>
                <Modal.Head
                    title="Настройки отображения"
                    modalClose={() => setChartSettingsModal(false)}
                />
                <Modal.Body className={ss.body}>
                    <div className={ss.col + ' ' + ss.col_full}>
                        <div className={ss.label}>Датчик</div>
                        <Dropdown
                            clicked={true}
                            value={chartNames && chartNames[lineIndex]?.label ? chartNames[lineIndex].label + chartNames[lineIndex].preffix : 'Выберите датчик'}
                        >
                            {chartNames?.map(({ label, value, preffix }) => (
                                <Dropdown.Item
                                    key={'line' + value}
                                    onClick={() => setLineIndex(value)}
                                >
                                    {label} {preffix}
                                </Dropdown.Item>
                            ))}
                        </Dropdown>
                    </div>
                    <div className={ss.col}>
                        <div className={ss.label}>Цвет линии</div>
                        <Dropdown
                            className={'notscroll ' + ss.colors}
                            clicked={true}
                            value={lineColor ? parse(`<span style="color: ` + lineColor + `"></span> ${lineColor}`) : 'Выберите цвет линии'}
                        >
                            <Dropdown.Item>
                                <HexColorPicker
                                    color={lineColor}
                                    onChange={(color) => setLineColor(color)}
                                />
                            </Dropdown.Item>
                        </Dropdown>
                    </div>
                    <div className={ss.col}>
                        <div className={ss.label}>
                            Толщина линии
                            <span className={ss.label_val}>{lineWeight}px</span>
                        </div>
                        <div className={ss.range}>
                            <Range
                                min="0"
                                max="10"
                                step="1"
                                value={lineWeight}
                                onChange={({ target }) => {
                                    const { value } = target;
                                    setLineWeight(+value);
                                }}
                            />
                        </div>
                    </div>
                    <div className={ss.col}>
                        <div className={ss.label}>Цвет точек</div>
                        <Dropdown
                            className={'notscroll ' + ss.colors}
                            clicked={true}
                            value={pointColor ? parse(`<span style="color: ` + pointColor + `"></span> ${pointColor}`) : 'Выберите цвет точек'}
                        >
                            <Dropdown.Item>
                                <HexColorPicker
                                    color={pointColor}
                                    onChange={(color) => setPointColor(color)}
                                />
                            </Dropdown.Item>
                        </Dropdown>
                    </div>
                    <div className={ss.col}>
                        <div className={ss.label}>
                            Толщина точек
                            <span className={ss.label_val}>{pointWeight}px</span>
                        </div>
                        <div className={ss.range}>
                            <Range
                                min="0"
                                max="10"
                                step="1"
                                value={pointWeight}
                                onChange={({ target }) => {
                                    const { value } = target;
                                    setPointWeight(+value);
                                }}
                            />
                        </div>
                    </div>
                    {
                        <div className={ss.col}>
                            <div className={ss.label}>Интерполяция</div>
                            <div className={ss.range}>
                                <Dropdown
                                    value={interpolationLabel ? interpolationLabel : 'Выберите способ интерполяции'}
                                    clicked={true}
                                >
                                    {interpolationsArr.map(({ label, value }) => {
                                        return (
                                            <Dropdown.Item
                                                key={value}
                                                onClick={() => setInterpolation(value)}
                                            >
                                                {label}
                                            </Dropdown.Item>
                                        );
                                    })}
                                </Dropdown>
                            </div>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => saveLineStyle(lineIndex)}>Применить</Button>
                    <Button
                        border={true}
                        onClick={() => setChartSettingsModal(false)}
                    >
                        Закрыть
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    );
};
export default ChartSettings;
