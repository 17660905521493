import React, {useState} from "react";
import {NB_ACT, getSubjectById} from './nb_utils';
import ModalConfirmDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import { Button } from 'rlabui';
import ss from './NotebookStudentHomeWorkExecuteAside.module.scss';

const NotebookStudentHomeWorkExecuteAside = ({currPage, taskPages, notebookData, 
        workErrorLimit, history}) => {
    const [confirmQuestion, setConfirmQuestion] = useState('');
    const [showConfirmDlg, setShowConfirmDlg] = useState('');
    
    const handleStop = () => {
        setConfirmQuestion('Вы действительно хотите завершить выполнение задания?');
        setShowConfirmDlg(true);
    };

    const doComplete = () => {
        setShowConfirmDlg(false);
        history.push('/notebooks/' + NB_ACT.SHOW_ALL_NBS);
    };

    return  (
            <>
                <div className={ss.root}>
                    <div className={ss.header}>
                        <span>Сводка по заданию</span>
                    </div>
                    <div className={ss.body}>
                        <div className={ss.item}>
                            <b>Предмет:</b>
                            <span>{notebookData ? getSubjectById(notebookData.titles.subjectId) : ''}</span>
                        </div>

                        {notebookData && notebookData.titles.homeWorkItem && 
                            <div className={ss.item}>
                                <b>Тема:</b>
                                <span>{notebookData ? notebookData.titles.homeWorkItem : ''}</span>
                            </div>
                        }

                        {notebookData && taskPages && taskPages[currPage] && workErrorLimit !== -1 &&
                            <div className={ss.item}>
                                <div><b>Ошибок осталось: </b></div>
                                <span>{workErrorLimit} из {notebookData.settings.workErrorLimit}</span>
                            </div>
                        }

                        <div className={ss.item}>
                            <b>Задач без ответа: </b>
                            <span>
                                {taskPages.filter(item => item.task.isCorrect === -1).length}
                                {' из '}{taskPages.length}
                            </span>
                        </div>

                        <div className={ss.button}>
                            <Button onClick={handleStop}>Завершить задание</Button>
                        </div>
                    </div>
                </div>

                {showConfirmDlg &&
                    <ModalConfirmDialog
                        showConfirmDlg={showConfirmDlg} 
                        handleNo={() => setShowConfirmDlg(false)}
                        handleYes={doComplete}
                        question={confirmQuestion}
                    />
                }
            </>
    );

}

export default NotebookStudentHomeWorkExecuteAside;
