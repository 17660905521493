import React, { useEffect, useState, useCallback, useRef, forwardRef } from 'react';
import { Chart, LineController, LineElement, PointElement, LinearScale, Title, CategoryScale } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { getRelativePosition } from 'chart.js/helpers';
import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';
import chartColors from '../../styles/charts';
// https://github.com/DuartBreedt/chartjs-plugin-draggable
import ChartjsDraggablePlugin from 'chartjs-plugin-draggable';
import { Switcher } from 'rlabui';
import ChartMini from './ChartMini';
import Icon from '../Icon';
import { showCursorLine, setChartType, showBoxOnHoverChart, setLineAnnotation, deleteBox, resetViewArea } from './functions';
import ss from './Chart.module.scss';

Chart.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, zoomPlugin, annotationPlugin, ChartjsDraggablePlugin);

let isDrag = false;
let yAxisMax;
let yAxisMin;
let saved = [];
let timeoutArea = null;
let inChartArea;

const ChartArea = (props) => {
    const {
        data,
        writedData,
        sensorWrited,
        sensorImported,
        port,
        played,
        paused,
        view,
        legendMode,
        markerMode,
        markerActions,
        rangeActions,
        sensors,
        chartMarkers,
        setChartMarkerAdd,
        setChartMarkerDelete,
        setChartMarkers,
        delChartMarkers,
        setChartView,
        chartStyle,
        defaultColors,
        theme,
        isOscilloscope,
        oscilloChartSettings: { channels: oscilloChannels },
        // oscilloDefaultChartSettings: { channels: oscilloDefaultChannels },
        isHiSpeed,
        // sensorReading,
        // frequency,
        deleteAllMarkers,
        isMobile,
        writed,
        sensorsUnits,
        toggleChartParametric,
        toggleChartLissague,
        parametricMode,
        lissague,
    } = props;

    const [chart, setChart] = useState();
    const [hoveredMarker, setHoveredMarker] = useState();
    const [dragging, setDragging] = useState(false);
    const [draggingMarker, setDraggingMarker] = useState(null);
    const [xMax, setXMax] = useState(null);
    const [xMin, setXMin] = useState(null);
    const [yMax, setYMax] = useState(null);
    const [yMin, setYMin] = useState(null);
    const [labelX, setLabelX] = useState(0);
    const [labelY, setLabelY] = useState(0);
    const [panned, setPanned] = useState(0);
    const [sliceLen, setSliceLen] = useState(50);
    const [isChartArea, togleChartArea] = useState(false);
    const [chanelPrefix, setChannelPrefix] = useState();
    const [showMiniChart, toggleMiniChart] = useState(false);
    const [isZoom, setZoom] = useState(false);
    const [xState, setXstate] = useState(played && !paused ? false : true);
    const [yState, setYstate] = useState(played && !paused ? false : true);
    const [hoverTime, setHoverTime] = useState(0);
    const [hoverValue, setHoverValue] = useState(0);
    const [hoverMinMax, setHoverMinMax] = useState([]);
    const [series, setSeries] = useState([]);
    const [xData, setXdata] = useState(null);
    const [yData, setYdata] = useState(null);
    const [xIndex, setXindex] = useState(null);
    const [yIndex, setYindex] = useState(null);
    const [xColor, setXColor] = useState(null);
    const [yColor, setYColor] = useState(null);
    const [chartColor, setChartColor] = useState(null);
    const [chartWeight, setChartWeight] = useState(null);
    const [chartInterpolation, setChartInterpolation] = useState(null);
    const [isXLoc, setXLoc] = useState(false);
    const [isYLoc, setYLoc] = useState(false);
    const [isXImp, setXImp] = useState(false);
    const [isYImp, setYImp] = useState(false);

    const chartRef = useRef();
    const clearRef = useRef();
    const xRef = useRef(null);
    const yRef = useRef(null);

    // console.log(sensors);

    const { num: xNum, name: xName, unit: xUnit, frequency: xFrequency, roundDigit: xRoundDigit, value: xValue } = sensors[0];
    const { num, name, unit, frequency, roundDigit, value } = sensors[1];

    const {
        xMin: oscXMin,
        xMax: oscXMax,
        xBias: oscXBias,
        yBias: oscYBias,
        yMin: oscYMin,
        yMax: oscYMax,
    } = isOscilloscope ? oscilloChannels[xIndex] : { xMin: 0, xMax: 25, xBias: 0, yBias: 0, yMin: -100, yMax: 100 };

    const xDefaultColor = defaultColors[xIndex];
    const defaultColor = defaultColors[yIndex];

    const { weight, color, point, interpolation } = chartStyle[num]
        ? chartStyle[num]
        : {
              weight: 2,
              color: xDefaultColor?.val ? xDefaultColor.val : '#000',
              point: {
                  width: 0,
                  color: xDefaultColor?.val ? xDefaultColor.val : '#000',
              },
              interpolation: 'basis',
          };

    // const value = { x: 0, y: 0 };
    const dataoffsetIndex = sensorImported.length + sensorWrited.length;
    const savedSeries = typeof num === 'string' ? (num.includes('loc') ? writedData[yIndex] : value) : value;

    const sliced = 50 + frequency;
    let stepped;
    let cubicInterpolationMode;
    let tension;
    switch (chartStyle[num]?.interpolation) {
        case 'linear':
            stepped = false;
            cubicInterpolationMode = 'default';
            tension = 0;
            break;

        case 'natural':
            stepped = false;
            cubicInterpolationMode = 'default';
            tension = 0.1;
            break;

        case 'basis':
            stepped = false;
            cubicInterpolationMode = 'default';
            tension = 0.3;
            break;

        case 'bundle':
            stepped = false;
            cubicInterpolationMode = 'default';
            tension = 0.4;
            break;

        case 'cardinal':
            stepped = false;
            tension = 0.6;
            cubicInterpolationMode = 'default';
            break;

        case 'catmull':
            stepped = false;
            tension = 0.8;
            cubicInterpolationMode = 'default';
            break;

        case 'step':
            stepped = 'middle';
            break;

        case 'stepbefore':
            stepped = 'before';
            break;

        case 'stepafter':
            stepped = 'after';
            break;

        case 'monotone':
            stepped = false;
            cubicInterpolationMode = 'monotone';
            break;

        default:
            stepped = false;
            cubicInterpolationMode = 'default';
            tension = 0.3;
            break;
    }

    const initialOptions = {
        maintainAspectRatio: false,
        responsive: true,
        animation: {
            duration: 0,
            easing: 'linear',
        },
        parsing: false,
        legend: {
            display: false,
        },
        interaction: {
            includeInvisible: false,
        },
        layout: {
            padding: {
                top: 0,
                bottom: 0,
                left: 3,
                right: 5,
            },
        },
        elements: {
            line: {
                // скругление линии
                tension: isOscilloscope ? 0 : isHiSpeed ? 0 : tension,
                borderColor: color ? color : defaultColor,
                borderWidth: weight ? weight : 0,
                spanGaps: true,
                // Interpolation
                cubicInterpolationMode,
                stepped,
            },
            point: {
                pointRadius: point?.width ? point.width : 0,
                pointBackgroundColor: point?.color ? point.color : defaultColor,
            },
        },
        tooltips: {
            enabled: false,
        },
        title: {
            display: false,
        },
        hover: {
            mode: 'xy',
            intersect: false,
        },
        plugins: {
            datalabels: {
                display: false,
            },
            annotation: {
                drawTime: 'afterDatasetsDraw',
                animations: {
                    numbers: {
                        properties: [],
                    },
                },
                annotations: {},
            },
            zoom: {
                pan: {
                    enabled: isOscilloscope ? false : true,
                    mode: played && !paused ? '' : 'xy',
                    onPanStart: (chart) => {
                        setPanned(true);
                    },
                    onPanComplete: (chart) => {
                        setPanned(false);
                        setChartMarkerAdd(false);
                        setChartMarkerDelete(false);
                    },
                    onPan: (chart) => setPanned(true),
                },
                zoom: {
                    overScaleMode: 'xy',
                    mode: played && !paused ? '' : 'xy',
                    // threshold: 0.01,
                    // sensitivity: 0.01,
                    // speed: 0.01,
                    wheel: {
                        enabled: isOscilloscope ? false : true,
                    },
                    pinch: {
                        enabled: true,
                    },
                    drag: {
                        enabled: !played && isZoom ? true : false,
                        modifierKey: 'shift',
                        backgroundColor: theme === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
                        borderColor: theme === 'dark' ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.3)',
                    },
                    rangeMin: {
                        x: null,
                        y: null,
                    },
                    rangeMax: {
                        x: null,
                        y: null,
                    },
                    onZoom: ({ chart }) => {
                        // yAxisMax = chart.scales.max;
                        // yAxisMin = chart.scales.min;
                    },
                    onZoomComplete: function ({ chart }) {},
                },
            },
        },
        scales: {
            xAxis: {
                type: 'linear',
                min: isOscilloscope ? oscXMin : null,
                max: isOscilloscope ? oscXMax : null,
                ticks: {
                    display: isOscilloscope ? false : true,
                    // display: true,
                    count: 10,
                    maxTicksLimit: 10,
                    color: chartColors.tick,
                    // autoSkip: false,
                    font: {
                        size: 10,
                    },
                    major: {
                        enabled: true,
                    },
                    callback: (value, i, values) => {
                        return +value.toFixed(xRoundDigit);
                    },
                },
                grid: {
                    display: true,
                    color: theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light,
                    borderColor: theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light,
                    tickColor: 'transparent',
                    tickLength: 2,
                },
                title: {
                    display: true,
                    text: xName ? `${xName} ${xUnit}` : '',
                    fontWeight: 'bold',
                    color: isOscilloscope ? 'transparent' : xColor ? xColor : defaultColor,
                },
            },
            yAxis: {
                type: 'linear',
                min: isOscilloscope ? oscYMin : null,
                max: isOscilloscope ? oscYMax : null,
                ticks: {
                    maxTicksLimit: 10,
                    color: chartColors.tick,
                    font: {
                        size: 10,
                    },
                    enabled: true,
                    callback: (value) => {
                        return +value.toFixed(roundDigit);
                    },
                },
                grid: {
                    borderColor: theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light,
                    color: theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light,
                    tickColor: 'transparent',
                    tickLength: 0,
                },
                title: {
                    display: true,
                    text: name ? `${name} ${unit}` : '',
                    color: isOscilloscope ? 'transparent' : color ? color : defaultColor,
                    fontWeight: 'bold',
                    padding: {
                        bottom: 13,
                    },
                },
            },
        },
        onHover: (evt, elements, chart) => {
            setHoverEvent(evt, theme);
        },
    };

    const plugins = [
        {
            id: 'eventCatcher',
            beforeEvent(chart, args) {
                const { event, inChartArea } = args;
                if (event.type === 'mouseout') {
                    togleChartArea(false);
                }
                if (event.type === 'mousemove') {
                    togleChartArea(inChartArea);
                }
            },
        },
    ];

    const [options] = useState(initialOptions);

    useEffect(() => {
        const onKeyDown = ({ key }) => {
            if (key === 'Shift') setZoom(true);
        };
        const onKeyUp = ({ key }) => {
            if (key === 'Shift') setZoom(false);
        };
        setSeries([]);
        document.addEventListener('keydown', onKeyDown);
        document.addEventListener('keyup', onKeyUp);
        if (series.length) {
            const valsArr = series.map((s) => s.y);
            const min = Math.min(...valsArr);
            const max = Math.max(...valsArr);
            setHoverMinMax([min, max]);
        }
        return () => {
            document.removeEventListener('keydown', onKeyDown);
            document.removeEventListener('keyup', onKeyUp);
        };
    }, []);

    useEffect(() => {
        let dataArr = data;
        // console.log(sensorWrited);
        // console.log(writedData);
        setXLoc(false);
        setXImp(false);
        setYLoc(false);
        setYImp(false);

        if (typeof xNum === 'string') {
            if (xNum.includes('loc')) {
                setXLoc(true);
                setXImp(false);
                const index = sensorWrited.findIndex((e) => e.num === xNum);
                dataArr = [writedData[index]];
                setXdata(dataArr?.filter((e) => e?.at(-1)?.num === xNum)[0]?.map((e) => e.y));
            } else {
                setXLoc(false);
                setXImp(true);
            }
        }

        if (typeof num === 'string') {
            if (num.includes('loc')) {
                setYLoc(true);
                setYImp(false);
                const index = sensorWrited.findIndex((e) => e.num === num);
                dataArr = [writedData[index]];
                setXdata(dataArr?.filter((e) => e?.at(-1)?.num === xNum)[0]?.map((e) => e.y));
            } else {
                setYLoc(false);
                setYImp(true);
            }
        }

        if (dataArr.length) {
            setXdata(dataArr?.filter((e) => e?.at(-1)?.num === xNum)[0]?.map((e) => e.y));
            setYdata(dataArr?.filter((e) => e?.at(-1)?.num === num)[0]?.map((e) => e.y));
        }
        if (xNum) {
            setXColor(chartStyle[xNum].color);
            // setChartWeight(chartStyle[num].weight);
            setXindex(data?.findIndex((e) => e?.at(-1)?.num === xNum));
        }
        if (num) {
            setYindex(data?.findIndex((e) => e?.at(-1)?.num === num));
            setYColor(chartStyle[num].color);
            setChartInterpolation(chartStyle[num]?.interpolation);
            setChartColor(chartStyle[num]?.color);
            setChartWeight(chartStyle[num]?.weight);
        }
    }, [num, xNum]);

    useEffect(() => {
        if (data.length) {
            if (!played) {
                setSeries([]);
            }
            typeof xNum === 'number' && setXdata(data?.filter((e) => e?.at(-1)?.num === xNum)[0]?.map((e) => e.y));
            typeof num === 'number' && setYdata(data?.filter((e) => e?.at(-1)?.num === num)[0]?.map((e) => e.y));
        } else {
            // setXdata();
            // setYdata();
        }
    }, [data, xNum, num]);

    useEffect(() => {
        if (sensorWrited.length) {
            isXLoc && setXdata(writedData?.filter((e) => e[0].num === xNum)[0]?.map((e) => e.y));
            isYLoc && setYdata(writedData?.filter((e) => e[0].num === num)[0]?.map((e) => e.y));
        }
    }, [sensorWrited, isXLoc, isYLoc]);

    useEffect(() => {
        if (sensorImported.length) {
            isXImp && setXdata(sensorImported?.filter((e) => e?.num === xNum)[0]?.value?.map((e) => e.y));
            isYImp && setYdata(sensorImported?.filter((e) => e?.num === num)[0]?.value?.map((e) => e.y));
        }
    }, [sensorImported, isXImp, isYImp]);

    useEffect(() => {
        if (!chart) return;
        if (xData && yData) {
            // console.log(xData, yData);
            if (played && !paused) {
                setSeries((e) => [...e, { x: xData[xData.length - 1], y: yData[yData.length - 1] }]);
            } else {
                xData?.forEach((x, i) => {
                    if (yData[i]) {
                        setSeries((e) => [...e, { x, y: yData[i] }]);
                    }
                });
            }
        }
        if (!xData && !yData) {
            setSeries([]);
        }
        chart.update();
    }, [xData, yData, yIndex, xIndex, chart]);

    useEffect(() => {
        // console.log(xRef.current);
        if (chart) {
            const zoom = chart.options.plugins.zoom;
            const x = xRef?.current?.checked ? 'x' : '';
            const y = yRef?.current?.checked ? 'y' : '';
            if (isZoom) {
                isDrag = false;
                zoom.pan.enabled = false;
                zoom.zoom.drag.enabled = true;
                zoom.zoom.mode = 'x';
            } else {
                zoom.pan.enabled = true;
                zoom.zoom.drag.enabled = false;
                zoom.zoom.mode = x + y;
            }
        }
    }, [isZoom]);

    useEffect(() => {
        if (chartRef.current) {
            setChart(chartRef.current);
        }
    }, [chartRef]);

    useEffect(() => {
        if (chart && isOscilloscope && oscilloChannels[yIndex]) {
            chart.options.scales.yAxis.min = oscYMin;
            chart.options.scales.yAxis.max = oscYMax;
            chart.options.scales.xAxis.min = oscXMin;
            chart.options.scales.xAxis.max = oscXMax;
            chart.update();
        }
    }, [oscXMax, oscXMin, oscYMax, oscYMin, oscXBias, oscYBias]);

    useEffect(() => {
        // console.log(chartMarkers);
        if (chart && Object.keys(chartMarkers).length && chartMarkers[num]) {
            // console.log(series?.length);
            if (series?.length > 1) {
                const keys = Object.keys(chartMarkers[num]).length;
                const marks = chart.config.options.plugins.annotation.annotations;
                if (keys) {
                    // console.log('chartMarkers', chartMarkers[num]);
                    const markers = chartMarkers[num];
                    for (let key in markers) {
                        const { type, value, id, resizer, backgroundColor, xMin, xMax } = markers[key];
                        if (!marks[id]) {
                            if (type === 'line' && value && !resizer) {
                                // console.log(markers[key]);
                                const index = +id.replace('line', '');
                                setLineAnnotation(chart, index, +value, roundDigit, series, theme, isDrag, setHoveredMarker, setChartMarkers, [sensors]);
                            }

                            if (type === 'box' && xMin && xMax) {
                                // console.log(markers[key]);
                                setBoxAnnotation(id, xMin, xMax, backgroundColor);
                            }
                        }
                    }
                }
            } else {
                // chart.options.plugins.annotation.annotations = {};
                // typeof num !== 'string' && setChartMarkers({ num, data: {} });
            }
        }
    }, [chartMarkers, chart]);

    useEffect(() => {
        if (chart) {
            chart.options.plugins.annotation.annotations = chartMarkers[num] ? chartMarkers[num] : {};
            chart.update();
        }
    }, [chartMarkers]);

    useEffect(() => {
        if (chart && num) {
            if (typeof num === 'number') {
                setChannelPrefix('');
            } else {
                if (num.includes('loc')) {
                    setChannelPrefix('(Лог)');
                } else {
                    setChannelPrefix('(Имп)');
                }
            }
        }
    }, [num, xNum, chart]);

    useEffect(() => {
        if (chart) {
            chart.options.scales.xAxis.title.text = `${xName} ${xUnit} ${chanelPrefix ? chanelPrefix : ''}`;
            chart.options.scales.yAxis.title.text = `${name} ${unit} ${chanelPrefix ? chanelPrefix : ''}`;
            chart.update();
        }
    }, [chanelPrefix, unit]);

    useEffect(() => {
        if (chart) {
            if (view === 'four') {
                chart.options.scales.xAxis.ticks.count = 5;
            } else {
                chart.options.scales.xAxis.ticks.count = 10;
            }
        }
    }, [chart, view]);

    useEffect(() => {
        if (chart) {
            // Grid and ticks
            chart.options.scales.xAxis.grid.borderColor = theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light;
            chart.options.scales.xAxis.grid.color = theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light;

            chart.options.scales.yAxis.grid.borderColor = theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light;
            chart.options.scales.yAxis.grid.color = theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light;

            // Markers
            const markers = chart.config.options.plugins.annotation.annotations;
            for (let key in markers) {
                const mark = markers[key];

                if (mark && mark.type === 'line' && !mark.resizer) {
                    mark.borderColor = theme === 'dark' ? chartColors.marker.dark.line : chartColors.marker.line;
                    mark.label.backgroundColor = theme === 'dark' ? chartColors.marker.dark.background : chartColors.marker.background;
                    mark.label.borderColor = theme === 'dark' ? chartColors.marker.dark.border : chartColors.marker.border;
                    mark.label.color = theme === 'dark' ? chartColors.marker.dark.color : chartColors.marker.color;
                }
            }

            chart.update();
        }
    }, [theme]);

    useEffect(() => {
        if (chart) {
            chart.options.scales.xAxis.title.color = xColor;
            chart.options.scales.yAxis.title.color = yColor;
            chart.options.elements.line.borderColor = chartColor;
            if (point) {
                chart.options.elements.point.pointRadius = point.width > 0 ? point.width : 0;
                chart.options.elements.point.pointBackgroundColor = point.color;
            }
            chart.options.elements.line.borderWidth = weight ? weight : 0;
            if (interpolation) {
                // interpolation
                switch (interpolation) {
                    case 'linear':
                        chart.options.elements.line.stepped = false;
                        chart.options.elements.line.cubicInterpolationMode = 'default';
                        chart.options.elements.line.tension = 0;
                        break;

                    case 'natural':
                        chart.options.elements.line.stepped = false;
                        chart.options.elements.line.cubicInterpolationMode = 'default';
                        chart.options.elements.line.tension = 0.1;
                        break;

                    case 'basis':
                        chart.options.elements.line.stepped = false;
                        chart.options.elements.line.cubicInterpolationMode = 'default';
                        chart.options.elements.line.tension = 0.3;
                        break;

                    case 'bundle':
                        chart.options.elements.line.stepped = false;
                        chart.options.elements.line.cubicInterpolationMode = 'default';
                        chart.options.elements.line.tension = 0.4;
                        break;

                    case 'cardinal':
                        chart.options.elements.line.stepped = false;
                        chart.options.elements.line.tension = 0.6;
                        chart.options.elements.line.cubicInterpolationMode = 'default';
                        break;

                    case 'catmull':
                        chart.options.elements.line.stepped = false;
                        chart.options.elements.line.tension = 0.8;
                        chart.options.elements.line.cubicInterpolationMode = 'default';
                        break;

                    case 'step':
                        chart.options.elements.line.stepped = 'middle';
                        break;

                    case 'stepbefore':
                        chart.options.elements.line.stepped = 'before';
                        break;

                    case 'stepafter':
                        chart.options.elements.line.stepped = 'after';
                        break;

                    case 'monotone':
                        chart.options.elements.line.stepped = false;
                        chart.options.elements.line.cubicInterpolationMode = 'monotone';
                        break;

                    default:
                        chart.options.elements.line.stepped = false;
                        chart.options.elements.line.cubicInterpolationMode = 'default';
                        chart.options.elements.line.tension = 0.3;
                        break;
                }
            }
            chart.update();
        }
    }, [chartStyle, xColor, yColor, chartColor]);

    useEffect(() => {
        if (!port.open) {
            removeAllMarker();
        }
    }, [port]);

    useEffect(() => {
        if (deleteAllMarkers) {
            removeAllMarker();
        }
    }, [deleteAllMarkers]);

    useEffect(() => {
        if (chart) {
            if (!isChartArea) {
                // console.log('leave');
                chart.options.plugins.zoom.zoom.overScaleMode = 'xy';
            } else {
                // console.log('enter');
                chart.options.plugins.zoom.zoom.overScaleMode = null;
            }
            chart.options.onHover = (evt) => setHoverEvent(evt, theme);
            chart.update();
        }
    }, [isChartArea, chart, theme]);

    useEffect(() => {
        if (chart) {
            if (dragging && draggingMarker && !hoveredMarker) {
                chart.options.plugins.zoom.pan.enabled = false;
                setChartMarkerAdd(false);
            } else {
                chart.options.plugins.zoom.pan.enabled = true;
            }
            chart.update();
        }
    }, [dragging, draggingMarker]);

    useEffect(() => {
        if (isOscilloscope) {
            setSliceLen(0);
        } else {
            setSliceLen(-sliced);
        }
    }, [isOscilloscope]);

    useEffect(() => {
        if (isHiSpeed) {
            // console.log(prop);
            setSliceLen(0);
        } else {
            setSliceLen(-300);
        }
    }, [isHiSpeed]);

    useEffect(() => {
        if (chart && played) {
            setXMin(0);
            setXMax(10000);
            if (typeof num !== 'string') {
                removeAllMarker();
            }
            const minMaxArr = [];

            chart.data.datasets[0].data = [];
            chart.options.plugins.annotation.annotations = {};
            if (series?.length) {
                series.forEach((el, i) => {
                    el.y && minMaxArr.push(el.y);
                });
            }

            if (isOscilloscope && minMaxArr && played) {
                yAxisMax = Math.max.apply(null, minMaxArr) + 0.1;
                yAxisMin = Math.min.apply(null, minMaxArr) - 0.1;
            }
            toggleMiniChart(false);
        }
        if (played) {
            setSeries([]);
        }
    }, [played]);

    useEffect(() => {
        if (chart) {
            if (played && !paused) {
                chart.options.scales.yAxis.min = null;
                chart.options.scales.yAxis.max = null;
                setXstate(false);
                setYstate(false);
                chart.options.plugins.zoom.zoom.mode = '';
                chart.options.plugins.zoom.pan.mode = '';
            } else {
                setXstate(true);
                setYstate(true);
                chart.options.plugins.zoom.zoom.mode = 'xy';
                chart.options.plugins.zoom.pan.mode = 'xy';
                if (series?.length) {
                    const valsArr = series?.map((s) => s.y);
                    const min = Math.min(...valsArr);
                    const max = Math.max(...valsArr);
                    setHoverMinMax([min, max]);
                }
            }
            chart.update();
        }
    }, [played, paused]);

    useEffect(() => {
        if (!chart) return;
        if (series?.length) {
            const x = series.map((e) => e.x);
            const y = series.map((e) => e.y);
            if (x) {
                setXMin(Math.min(...x));
                setXMax(Math.max(...x));
            }
            if (y) {
                setYMin(Math.min(...y));
                setYMax(Math.max(...y));
            }
        }
    }, [series]);

    useEffect(() => {
        const scales = chart?.options?.scales;
        if (scales) {
            scales.xAxis.max = xMax;
            scales.xAxis.min = xMin;
            scales.yAxis.max = yMax;
            scales.yAxis.min = yMin;
            chart.update();
        }
    }, [xMin, xMax, yMin, yMax]);

    const setBoxAnnotation = (key, min, max, color) => {
        // if (data[0].value.length <= 1) return;
        const markers = chart.config.options.plugins.annotation.annotations;
        const start = key + 'start';
        const end = key + 'end';
        markers[start] = {
            type: 'line',
            resizer: true,
            borderWidth: 5,
            borderColor: 'transparent',
            scaleID: 'xAxis',
            value: min,
            draggable: true,
            drawTime: 'afterDraw',
            enter: function (context, event) {
                document.body.style.cursor = 'col-resize';
                const { id } = context;
                setHoveredMarker(id);
            },
            leave: function (context, event) {
                document.body.style.cursor = '';
                setHoveredMarker();
            },
            onDragStart: function (event, value) {},
            onDrag: function (event, value) {
                const min = series[0].x;
                const boxMax = chart.options.plugins.annotation.annotations[key].xMax - 500;
                const {
                    subject: {
                        config: { id },
                    },
                } = event;
                chart.options.plugins.zoom.pan.enabled = false;
                if (value < min) {
                    chart.options.plugins.annotation.annotations[id].value = min;
                } else if (value > boxMax) {
                    chart.options.plugins.annotation.annotations[id].value = boxMax;
                } else {
                    chart.options.plugins.annotation.annotations[key].xMin = value < min ? min : value;
                }
            },
            onDragEnd: function (event, value) {
                chart.options.plugins.zoom.pan.enabled = true;
                setChartMarkers({ num, data: chart.config.options.plugins.annotation.annotations });
            },
        };
        markers[key] = {
            type: 'box',
            id: key,
            xScaleID: 'xAxis',
            yScaleID: 'yAxis',
            backgroundColor: color,
            borderWidth: 0,
            xMax: max ? max : series[series.length - 1],
            xMin: min ? min : 0,
            enter: (context, event) => {
                if (!isDrag) {
                    setDraggingMarker(key);
                }
            },
            leave: (context, event) => {
                if (!isDrag) {
                    setDraggingMarker(null);
                }
            },
        };
        markers[end] = {
            type: 'line',
            borderWidth: 5,
            resizer: true,
            borderColor: 'transparent',
            scaleID: 'xAxis',
            value: max,
            draggable: true,
            drawTime: 'afterDraw',
            enter: function (context, event) {
                document.body.style.cursor = 'col-resize';
                const { id } = context;
                setHoveredMarker(id);
            },
            leave: function (context, event) {
                document.body.style.cursor = '';
                setHoveredMarker();
            },
            onDragStart: function (event, value) {
                // const { subject: {config: {id}} } = event;
            },
            onDrag: function (event, value) {
                const max = series[series.length - 1].x;
                const boxMin = chart.options.plugins.annotation.annotations[key].xMin + 0.5;
                const {
                    subject: {
                        config: { id },
                    },
                } = event;
                chart.options.plugins.zoom.pan.enabled = false;
                if (value > max) {
                    chart.options.plugins.annotation.annotations[id].value = max;
                } else if (value < boxMin) {
                    chart.options.plugins.annotation.annotations[id].value = boxMin;
                } else {
                    chart.options.plugins.annotation.annotations[key].xMax = value > max ? max : value;
                }
            },
            onDragEnd: function (event, value) {
                chart.options.plugins.zoom.pan.enabled = true;
                setChartMarkers({ num, data: chart.config.options.plugins.annotation.annotations });
            },
        };
        chart.options.plugins.annotation.annotations = markers;
        setChartMarkers({ num, data: markers });
    };

    const addRemoveMarker = (event, key) => {
        // console.log(event);
        if (series[series.length - 1].x === 0) {
            return;
        }
        if (panned) {
            setPanned(false);
            return;
        }
        // console.log(key);
        const markers = chart.config.options.plugins.annotation.annotations;
        if (markerActions.del && key) {
            if (markers[key] && markers[key].type === 'line' && !markers[key].resizer) {
                delete markers[key];
                setHoveredMarker();
            }
        }
        if (markerActions.add) {
            const points = chart.getElementsAtEventForMode(event, 'index', { intersect: false }, true);
            // console.log(points);
            const i = points[0].index;
            if (markerMode === 'arbitrary' || markerMode === 'cursor') {
                // console.log(i);
                setLineAnnotation(chart, i, null, roundDigit, series, theme, isDrag, setHoveredMarker, setChartMarkers, [sensors]);
            }
        }
        chart.options.plugins.annotation.annotations = markers;
        setChartMarkers({ num, data: markers });
    };

    const removeAllMarker = () => {
        if (chart) {
            chart.options.plugins.annotation.annotations = {};
            setChartMarkers({ num, data: {} });
        }
    };

    const setHoverEvent = (evt, theme) => {
        if (!chart || series?.length < 2) return;
        if (played && !paused) return;

        // console.log(evt.target);

        const markers = chart.config.options.plugins.annotation.annotations;
        // console.log(markers);

        const canvasPosition = getRelativePosition(evt, chart);
        // console.log(canvasPosition);
        const dataX = chart.scales.xAxis.getValueForPixel(canvasPosition.x);
        const dataY = chart.scales.yAxis.getValueForPixel(canvasPosition.y);
        // console.log(dataX);

        setHoverTime((dataX / 1000).toFixed(2));
        setHoverValue(dataY.toFixed(roundDigit));

        if (markerMode === 'cursor') {
            try {
                if (markers['xLine']) {
                    const xLine = markers['xLine'];
                    xLine.display = true;
                    xLine.value = dataX;
                    xLine.label.content = (dataX / 1000).toFixed(2);
                }
                if (markers['yLine']) {
                    const yLine = markers['yLine'];
                    const xLine = markers['xLine'];
                    yLine.display = true;
                    yLine.value = dataY;
                    yLine.label.content = dataY.toFixed(roundDigit);
                    xLine.value = dataX;
                    xLine.label.content = dataX.toFixed(xRoundDigit);
                }
                // chart.update();
            } catch (e) {
                // console.log(e.message);
            } finally {
            }
        }

        setLabelX(dataX.toFixed(xRoundDigit));
        setLabelY(dataY.toFixed(roundDigit));

        try {
            if (markers['hovered']) {
                markers['hovered'].xMin = dataX - 1;
                markers['hovered'].xMax = dataX + 1;
            }
        } catch (e) {
            console.log(e.message);
        }

        switch (markerMode) {
            case 'cursor':
                if (!markers.xLine) {
                    showCursorLine(chart, theme, 'yAxis');
                }
                break;
            case 'range1':
                if (!markers.box1 && !markerActions.del) {
                    showBoxOnHoverChart(chart, evt, 'box1', dataX, chartColors.boxes.chart.box1, series, setBoxAnnotation);
                }
                break;
            case 'range2':
                if (!markers.box2 && !markerActions.del) {
                    showBoxOnHoverChart(chart, evt, 'box2', dataX, chartColors.boxes.chart.box2, series, setBoxAnnotation);
                }
                break;
            case 'range3':
                if (!markers.box3 && !markerActions.del) {
                    showBoxOnHoverChart(chart, evt, 'box3', dataX, chartColors.boxes.chart.box3, series, setBoxAnnotation);
                }
                break;
            case 'range4':
                if (!markers.box4 && !markerActions.del) {
                    showBoxOnHoverChart(chart, evt, 'box4', dataX, chartColors.boxes.chart.box4, series, setBoxAnnotation);
                }
                break;
            default:
        }

        if (dragging && !rangeActions.del) {
            draggingBox(evt, draggingMarker);
        }
    };

    const draggingBox = (e, key) => {
        if (dragging && key) {
            const min = series[0].x;
            const max = series[series.length - 1].x;
            const markers = chart.config.options.plugins.annotation.annotations;
            const canvasPosition = getRelativePosition(e, chart);
            const dataX = chart.scales.xAxis.getValueForPixel(canvasPosition.x);

            if (markers && markers[key] && markers[key].type === 'box') {
                const offset = (markers[key].xMax - markers[key].xMin) / 2;
                const start = key + 'start';
                const end = key + 'end';

                if (!rangeActions.del) {
                    const minValue = dataX - offset < min ? min : dataX - offset;
                    const maxValue = dataX + offset > max ? max : dataX + offset;

                    markers[start].value = minValue;
                    markers[key].xMin = minValue;
                    markers[key].xMax = maxValue;
                    markers[end].value = maxValue;

                    chart.options.plugins.annotation.annotations = markers;
                }
                setChartMarkers({ num, data: markers });
            } else {
                setDragging(false);
                isDrag = false;
                setDraggingMarker(null);
            }
        }
    };

    const setLeaveEvent = () => {
        if (played && !paused && !chart) return;
        const markers = chart.config.options.plugins.annotation.annotations;
        clearTimeout(clearRef.current);
        clearRef.current = setTimeout(function () {
            try {
                delete markers['hovered'];
                delete markers['xLine'];
                delete markers['yLine'];
            } catch (e) {
                console.log(e.message);
            } finally {
                document.body.style.cursor = 'default';
                chart.options.plugins.annotation.annotations = markers;
                chart?.update();
            }
        }, 100);
    };

    const changeCoursor = (key) => {
        if (played && !paused && !isChartArea && !chart && !chart.config) return;
        const markers = chart.config.options.plugins.annotation.annotations;
        if (!dragging) {
            if (key && markers[key]) {
                if (markerActions.del && !dragging) {
                    if (markers[key].type === 'line') {
                        if (markers[key].resizer) {
                            document.body.style.cursor = 'col-resize';
                        } else {
                            document.body.style.cursor = 'no-drop';
                        }
                    }
                } else {
                    if (markers[key].type === 'line') document.body.style.cursor = 'col-resize';
                }
            } else {
                document.body.style.cursor = 'default';
            }
        }
        if (draggingMarker) {
            if (rangeActions.del) {
                document.body.style.cursor = 'no-drop';
            } else {
                document.body.style.cursor = 'grab';
            }
            if (dragging) {
                document.body.style.cursor = 'grabbing';
            }
        }
    };

    const clickMarkersEvents = (e) => {
        if (markerActions.add || markerActions.del) {
            addRemoveMarker(e, hoveredMarker);
        }
        if (draggingMarker && rangeActions.del) {
            deleteBox(chart, draggingMarker, setChartMarkers, num);
            // chart.update();
            setDraggingMarker(null);
        }
        isDrag = false;
    };

    const handleCheckX = (e) => {
        const { checked } = e.target;
        const zoom = chart.options.plugins.zoom;
        if (!checked) {
            if (zoom.zoom.mode === 'xy') {
                zoom.zoom.mode = 'y';
            } else {
                zoom.zoom.mode = '';
                played && chart?.resetZoom();
            }
            if (zoom.pan.mode === 'xy') {
                zoom.pan.mode = 'y';
            } else {
                zoom.pan.mode = '';
                played && chart?.resetZoom();
            }
        } else {
            if (zoom.zoom.mode === 'y') {
                zoom.zoom.mode = 'xy';
            } else {
                zoom.zoom.mode = 'x';
            }
            if (zoom.pan.mode === 'y') {
                zoom.pan.mode = 'xy';
            } else {
                zoom.pan.mode = 'x';
            }
        }
        chart?.update();
    };

    const handleCheckY = (e) => {
        const { checked } = e.target;
        const zoom = chart.options.plugins.zoom;
        if (!checked) {
            if (zoom.zoom.mode === 'xy') {
                zoom.zoom.mode = 'x';
            } else {
                zoom.zoom.mode = '';
                played && chart?.resetZoom();
            }
            if (zoom.pan.mode === 'xy') {
                zoom.pan.mode = 'x';
            } else {
                zoom.pan.mode = '';
                played && chart?.resetZoom();
            }
        } else {
            if (zoom.zoom.mode === 'x') {
                zoom.zoom.mode = 'xy';
            } else {
                zoom.zoom.mode = 'y';
            }
            if (zoom.pan.mode === 'x') {
                zoom.pan.mode = 'xy';
            } else {
                zoom.pan.mode = 'y';
            }
        }
        chart?.update();
    };

    return (
        <div className={ss.root}>
            <div className={ss.head}>
                <div className={ss.left}>
                    {sensors.map((sens, i) => {
                        const { name } = sens;
                        const titleColor = i === 0 ? xColor : yColor;
                        return (
                            name && (
                                <div className={ss.legend}>
                                    <div
                                        className={ss.color}
                                        style={{
                                            background: titleColor ? titleColor : defaultColor,
                                        }}
                                    ></div>
                                    <span>
                                        {name} {chanelPrefix}
                                    </span>
                                </div>
                            )
                        );
                    })}
                </div>
                <div className={ss.action}>
                    {/* <Icon
                        name="lissague"
                        title="Фигуры Лиссажу"
                        onClick={() => toggleChartLissague(true)}
                    /> */}
                    <Icon
                        name="broken"
                        title="Параметрические оси"
                        className={ss.iconActive}
                        onClick={() => toggleChartParametric()}
                    />
                    <Icon
                        tag="button"
                        name="zoom"
                        className={isZoom && ss.iconActive}
                        title="Масштабирование фрагмента. Нажать Shift"
                        disabled={played}
                    />
                    {/* <Icon
                        tag="button"
                        name="chart-mob"
                        className={showMiniChart && 'active'}
                        title="Просмотр фрагмента"
                        disabled={played}
                        onClick={() => toggleMiniChart(!showMiniChart)}
                    /> */}
                    <Icon
                        className="cl_icon_capture"
                        title="Полный масштаб"
                        onClick={() => resetViewArea(chart, series, { xMin, xMax, yMin, yMax })}
                    />
                </div>
            </div>
            <div className={ss.wrap}>
                <div className={ss.big}>
                    <Line
                        ref={chartRef}
                        options={options}
                        data={{ datasets: [{ data: series }] }}
                        plugins={plugins}
                        onMouseDown={(e) => {
                            if (!markerActions.add) {
                                setDragging(true);
                                isDrag = true;
                            }
                        }}
                        onMouseUp={(e) => {
                            setDragging(false);
                            isDrag = false;
                        }}
                        onMouseOut={(e) => {
                            setDragging(false);
                            isDrag = false;
                            setLeaveEvent();
                        }}
                        onMouseMove={(e) => {
                            changeCoursor(hoveredMarker);
                            if (dragging) {
                                draggingBox(e, draggingMarker);
                            }
                        }}
                        onClick={(e) => clickMarkersEvents(e)}
                        onTouchEnd={(e) => clickMarkersEvents(e)}
                    />
                    {(!played || paused) && legendMode && isChartArea && (
                        <div className={ss.tootltip}>
                            <div className={ss.tootltipRow}>
                                <label>Время</label> <span>{hoverTime}c</span>
                            </div>
                            <div className={ss.tootltipRow}>
                                <label>{name}</label>{' '}
                                <span>
                                    {hoverValue}
                                    {unit}
                                </span>
                            </div>
                            <div className={ss.tootltipFoot}>
                                <div className={ss.tootltipCol}>
                                    <label>Мин</label>
                                    <span>
                                        {hoverMinMax[0]} {unit}
                                    </span>
                                </div>
                                <div className={ss.tootltipCol}>
                                    <label>Макс</label>
                                    <span>
                                        {hoverMinMax[1]} {unit}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {showMiniChart && (
                    <ChartMini
                        chart={chart}
                        played={played}
                        paused={paused}
                        series={series}
                        theme={theme}
                        tension={tension}
                        color={color}
                        defaultColor={defaultColor}
                        weight={weight}
                        cubicInterpolationMode={cubicInterpolationMode}
                        stepped={stepped}
                        roundDigit={roundDigit}
                    />
                )}
            </div>
            {!isOscilloscope && (
                <div className={ss.foot}>
                    <label title="Масштабирование по оси Х">
                        <span>X</span>
                        <Switcher
                            itemRef={xRef}
                            checked={xState}
                            onChange={handleCheckX}
                        />
                    </label>
                    <label title="Масштабирование по оси Y">
                        <span>Y</span>
                        <Switcher
                            itemRef={yRef}
                            checked={yState}
                            onChange={handleCheckY}
                        />
                    </label>
                </div>
            )}
        </div>
    );
};

export default ChartArea;
