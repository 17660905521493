import React from "react";
import {getSectionTab} from './db_utils';
import Footer from '../Footer';
import { Content, ContentBody, ContentHead, ContentWrap } from "../ContentParts";
import "../../practicum/TutorialSelectTab.scss";
import "./InitSectionTabs.scss";
import "./Dashboard.scss";

export const DashboardInstruments = (props) => {
    const handleSelectTab = (ind) => {
        props.history.push('/instrument/' + ind);
    };

    return (
        <ContentWrap>
            <ContentHead title="Инструменты" />
            <Content background={false}>
                <ContentBody padding={false}>
                    <div className="InitSectionTabs">
                        {getSectionTab('geometry', 'Иллюстратор объектов >', 'figures', 'Иллюстрация геометрических объектов', handleSelectTab)}
                        {getSectionTab('voltage', 'Генератор напряжения >', 'electricity', 'Генерация звуковых частот', handleSelectTab)}
                        {getSectionTab('video', 'Анализ видеокадров >', 'video', 'Анализ параметров объектов на видео', handleSelectTab)}
                    </div>
                </ContentBody>
            </Content>
            <Footer  {...props} opt={props.match.params.opt} />
        </ContentWrap>
    );
};

export default DashboardInstruments;
