import React, { useState, useEffect } from 'react';
import Styles from './Sensors.module.scss';
import Sensor from '../Sensor';

const Sensors = ({ sensorList, visualized, importList }) => {
    const [sensors, setSensors] = useState([]);

    useEffect(() => {
        if (visualized) {
            if (sensorList.length > 1 || importList.length) {
                const list = [...sensorList, ...importList].filter((e) => !e.key.includes('Button') && e.key === visualized)[0];
                const channels = list?.channels || [];
                setSensors([list, ...channels]);
            }
        } else {
            setSensors([...importList, ...sensorList].filter((e) => !e.key.includes('Button')));
        }
    }, [visualized, sensorList, importList]);

    return (
        <div className={Styles.sensors}>
            {sensors.map((sensor) => (
                <Sensor
                    key={sensor.key}
                    sensor={sensor}
                />
            ))}
        </div>
    );
};
export default Sensors;
