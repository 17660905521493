import React from 'react';
import Icon from '../Icon';
import ss from './Help.module.scss';

const Help = (props) => {
    const { collapsed, port, isMobile, dashboard = true } = props;
    // console.log(props);
    return isMobile && port ? (
        ''
    ) : (
        <a
            href={window.instruction}
            download
            title="Инструкция"
            rel="noreferrer"
            className={collapsed ? ss.collapsed : dashboard ? ss.root : ss.dashboard}
        >
            <Icon name="question" />
        </a>
    );
};
export default Help;
