import { setSensorFrequency, setSensorMode, setSensorUnit, setSensorGradOffset } from '../../actions/sensors';
import { sendCommand } from '../../actions/port';
import { addChartDisabled, deleteChartDisabled } from '../../actions/chart';
import { setGraduationModal } from '../../actions/sensors';
import { setDeviceResponse } from '../../actions/actions';
import { setShowUnitInfo, setUnitInfo } from '../../actions/scenario';
import { setDemoSettings } from '../../actions/demo';

export const mapStateToProps = (state) => {
    return {
        demo: state.demo.demo,
        port: state.port.open,
        played: state.actions.played,
        paused: state.actions.paused,
        isCor: state.actions.isCor,
        styles: state.chart.style,
        defaultColors: state.chart.settings.colors,
        chartDisabled: state.chart.disabled,
        deviceResponse: state.actions.response,
        connectedCount: state.devices.count,
        sensorImported: state.sensors.imported,
        sensorWrited: state.sensors.writed,
        frequency: state.sensors.frequency,
        sensorMode: state.sensors.mode,
        measunits: state.sensors.measunits,
        sensorsUnits: state.sensors.units,
        isOscilloscope: state.oscilloscope.isOscilloscope,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setSensorFrequency: (obj) => dispatch(setSensorFrequency(obj)),
    setSensorUnit: (obj) => dispatch(setSensorUnit(obj)),
    setSensorGradOffset: (obj) => dispatch(setSensorGradOffset(obj)),
    sendCommand: (str) => dispatch(sendCommand(str)),
    setSensorMode: (obj) => dispatch(setSensorMode(obj)),
    addChartDisabled: (id) => dispatch(addChartDisabled(id)),
    deleteChartDisabled: (id) => dispatch(deleteChartDisabled(id)),
    setGraduationModal: (bool) => dispatch(setGraduationModal(bool)),
    setDeviceResponse: (str) => dispatch(setDeviceResponse(str)),
    setShowUnitInfo: (bool) => dispatch(setShowUnitInfo(bool)),
    setUnitInfo: (obj) => dispatch(setUnitInfo(obj)),
    setDemoSettings: (item) => dispatch(setDemoSettings(item)),
});
