import React from 'react';
import './ColorMenuItem.scss';
import { useDispatch } from 'react-redux';
import { updateColumn } from '../../../redux/slices/trello';
import { LIST_COLORS } from './ListColors';

const ColorMenuItem = ({ id, activeColor }) => {
  const dispatch = useDispatch();

  const onClickColor = (color) => () => {
    dispatch(updateColumn(id, 'color', color.listBackgroundColor));
  };

  return (
    <div className="color_menu">
      <div className="color_menu__title">Цвет колонки</div>
      <div className="color_menu__items">
        {LIST_COLORS.map((color) => (
          <div
            key={color.menuColor}
            className={`color_menu__item ${activeColor === color.menuColor ? 'active' : ''}`}
            onClick={onClickColor(color)}
            style={{ backgroundColor: color.menuColor }}
          />
        ))}
      </div>
    </div>
  );
};

export default ColorMenuItem;
