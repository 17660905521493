import React, {useState, useEffect} from "react";
import {SI_UP_PREFIXES, SI_DOWN_PREFIXES} from './physicalConstants';
import Table from "../ui/Table/Table";
import { Switcher } from '../ui';
import { ContentBody, ContentSubHeader } from "../template/ContentParts";

const PIShowSiPrefixes = ({history}) => {
	const [isUpPrefixes, setIsUpPrefixes] = useState(true);
	const [upPrefixes, setUpPrefixes] = useState([]);
	const [downPrefixes, setDownPrefixes] = useState([]);

	useEffect(() => {	
		const getPrefixes = (prefixList) => {
			const list = [];
			for (let i = 0; i < prefixList.length; i ++) {
				const item = prefixList[i];
				const record = {
					multiplier: '10^+' + item.order +  '^+', 
					ruPref: item.rusName, 
					intPref: item.engName,
					ruShort: item.ruUnit,
					intShort: item.enUnit,
					sample: item.sample,
					id: item.id,
				};
				list.push(record);
			}
			return list;
		};

		setUpPrefixes(getPrefixes(SI_UP_PREFIXES));
		setDownPrefixes(getPrefixes(SI_DOWN_PREFIXES));
	}, []);

	//downPrefixes, setDownPrefixes
	const getSwitcher = (isUp, handleClickToggle) => {
		const getToggleOption = (_isGenerateUniqueTasks, isUnique) => {
			const active = (_isGenerateUniqueTasks && isUnique) || (!_isGenerateUniqueTasks && !isUnique);
			return (<label htmlFor="toggleTest" className={active ? 'active' : 'cor_practicumLab__rowToggleNotactve'}>
				{isUnique ? 'Кратные' : 'Дольные'}
			</label>);
		};
		return (
			<span className='cor_practicumLab__rowToggle'>
				{getToggleOption(isUp, true)}
				<Switcher onChange={handleClickToggle} checked={isUp ? false : true} id="toggleTest" className="contrast" />
				{getToggleOption(isUp, false)}
			</span>
		);
	};	
    
    const getTableHeader = () => {
		const header = [
			{column: 'Десятичный множитель', name: 'multiplier', style: { width: '16%'} }, 
			{column: 'Русская приставка', name: 'ruPref', style: { width: '16%'} }, 
			{column: 'Международная приставка', name: 'intPref', style: { width: '17%'} }, 
			{column: 'Русское сокращение', name: 'ruShort', style: { width: '17%'} }, 
			{column: 'Международное сокращение', name: 'intShort', style: { width: '17%'} }, 
			{column: 'Пример', name: 'sample', style: { width: '17%'} }, 
			{column: 'id', name: 'id', style: { width: '0%'} }, 
		];
		return header;
	};

    return ( 
		<>
			<ContentSubHeader>
				<div className="cor-net">
					<div className="cor-net__section">
						<div className="cor-net__title">Приставики СИ</div>
						<div className="cor-net__row">
							<div className="cor-net__col">
								{getSwitcher(isUpPrefixes, () => setIsUpPrefixes(!isUpPrefixes))}
							</div>
						</div>
					</div>
				</div>
			</ContentSubHeader>
			<ContentBody>
				<Table 
					table={{
						header: getTableHeader(), 
						data: isUpPrefixes ? upPrefixes : downPrefixes,
					}}
				/>
			</ContentBody>
		</>
    )
}

export default PIShowSiPrefixes;