const DARK_COLOR = "#282b34";
const LIGHT_COLOR = "white";

export const getCapacitorSchemaOff = (isLightMode) => { 

    const darkCol = isLightMode ? DARK_COLOR : LIGHT_COLOR;
    const lightCol = isLightMode ? LIGHT_COLOR : DARK_COLOR;
    const transparentCol = "none";

    const img =     
<svg viewBox="0, 0, 216, 120" preserveAspectRatio="none" fill="none" xmlns="http://www.w3.org/2000/svg" key='cap01'>
<rect x="0.5" y="0.5" width="215" height="119" rx="5.5" fill={transparentCol}/>
<path d="M78.4688 19.3887 H51.6602 V100.876 H78.4688" stroke={darkCol}/>
<path d="M72.0984 39.5615 L78.4688 35.5296" stroke={darkCol} />
<path d="M78.4688 35.5296 L93.0676 26.2899 H163.938 V100.876 H78.4688 " stroke={darkCol}/>
<path d="M78.4688 35.5296 V100.876" stroke={darkCol}/>
<circle cx="78.2033" cy="19.3889" r="1.88889" fill={lightCol} stroke={darkCol}/>
<circle cx="78.2033" cy="35.8449" r="1.88889" fill={lightCol} stroke={darkCol}/>
<circle cx="78.2033" cy="100.611" r="2.12346" fill={darkCol} stroke={darkCol} strokeWidth="0.530864"/>
<circle cx="93.0686" cy="25.759" r="1.88889" fill={lightCol} stroke={darkCol}/>
<rect width="32.3827" height="9.02441" transform="translate(36 61)" fill={lightCol}/>
<line x1="36" y1="60.5" x2="68.3827" y2="60.5" stroke={darkCol}/>
<line x1="45.5566" y1="69.5244" x2="58.2974" y2="69.5244" stroke={darkCol}/>
<rect width="32.3827" height="9.02441" transform="translate(148.012 61)" fill={lightCol}/>
<line x1="148.012" y1="60.5" x2="180.394" y2="60.5" stroke={darkCol}/>
<line x1="148.012" y1="69.5244" x2="180.394" y2="69.5244" stroke={darkCol}/>
<rect x="114.006" y="20.1543" width="31.3827" height="13.3333" fill={lightCol}/>
<rect x="114.006" y="20.1543" width="31.3827" height="13.3333" stroke={darkCol}/>
<path d="M143.214 64H142.157C142.095 63.696 141.986 63.429 141.829 63.1989C141.676 62.9688 141.488 62.7756 141.267 62.6193C141.048 62.4602 140.805 62.3409 140.538 62.2614C140.271 62.1818 139.993 62.142 139.703 62.142C139.175 62.142 138.696 62.2756 138.267 62.5426C137.841 62.8097 137.501 63.2031 137.248 63.723C136.998 64.2429 136.873 64.8807 136.873 65.6364C136.873 66.392 136.998 67.0298 137.248 67.5497C137.501 68.0696 137.841 68.4631 138.267 68.7301C138.696 68.9972 139.175 69.1307 139.703 69.1307C139.993 69.1307 140.271 69.0909 140.538 69.0114C140.805 68.9318 141.048 68.8139 141.267 68.6577C141.488 68.4986 141.676 68.304 141.829 68.0739C141.986 67.8409 142.095 67.5739 142.157 67.2727H143.214C143.135 67.7187 142.99 68.1179 142.78 68.4702C142.569 68.8224 142.308 69.1222 141.996 69.3693C141.683 69.6136 141.332 69.7997 140.943 69.9276C140.557 70.0554 140.143 70.1193 139.703 70.1193C138.959 70.1193 138.297 69.9375 137.717 69.5739C137.138 69.2102 136.682 68.6932 136.349 68.0227C136.017 67.3523 135.851 66.5568 135.851 65.6364C135.851 64.7159 136.017 63.9205 136.349 63.25C136.682 62.5795 137.138 62.0625 137.717 61.6989C138.297 61.3352 138.959 61.1534 139.703 61.1534C140.143 61.1534 140.557 61.2173 140.943 61.3452C141.332 61.473 141.683 61.6605 141.996 61.9077C142.308 62.152 142.569 62.4503 142.78 62.8026C142.99 63.152 143.135 63.5511 143.214 64Z" fill={darkCol}/>
<path d="M24.8601 61.2727L27.451 68.6193H27.5533L30.1442 61.2727H31.2521L28.0476 70H26.9567L23.7521 61.2727H24.8601Z" fill={darkCol}/>
<path d="M127.219 15V6.27273H130.168C130.85 6.27273 131.409 6.3892 131.847 6.62216C132.284 6.85227 132.608 7.16903 132.818 7.57244C133.029 7.97585 133.134 8.43466 133.134 8.94886C133.134 9.46307 133.029 9.91903 132.818 10.3168C132.608 10.7145 132.286 11.027 131.851 11.2543C131.416 11.4787 130.861 11.5909 130.185 11.5909H127.798V10.6364H130.151C130.617 10.6364 130.992 10.5682 131.276 10.4318C131.563 10.2955 131.77 10.1023 131.898 9.85227C132.029 9.59943 132.094 9.2983 132.094 8.94886C132.094 8.59943 132.029 8.29403 131.898 8.03267C131.767 7.77131 131.558 7.5696 131.271 7.42756C130.985 7.28267 130.605 7.21023 130.134 7.21023H128.276V15H127.219ZM131.327 11.0795L133.475 15H132.247L130.134 11.0795H131.327Z" fill={darkCol}/>
</svg>;
 return img;
};

export const getCapacitorSchemaOn = (isLightMode) => { 
    const darkCol = isLightMode ? DARK_COLOR : LIGHT_COLOR;
    const lightCol = isLightMode ? LIGHT_COLOR : DARK_COLOR;
    const transparentCol = "none";

    const img =     
<svg viewBox="0, 0, 216, 120" preserveAspectRatio="none" fill="none" xmlns="http://www.w3.org/2000/svg" key='cap02'>
<rect x="0.5" y="0.5" width="215" height="119" rx="5.5" fill={transparentCol}/>
<path d="M78.4688 19.3887 H51.6602 V100.876 H78.4688" stroke={darkCol}/>
<path d="M75 18 L93.0676 26.2899 H163.938 V100.876 H78.4688 " stroke={darkCol}/>
<path d="M78.4688 35.5296 V100.876" stroke={darkCol}/>
<circle cx="78.2033" cy="19.3889" r="1.88889" fill={lightCol} stroke={darkCol}/>
<circle cx="78.2033" cy="35.8449" r="1.88889" fill={lightCol} stroke={darkCol}/>
<circle cx="78.2033" cy="100.611" r="2.12346" fill={darkCol} stroke={darkCol} strokeWidth="0.530864"/>
<circle cx="93.0686" cy="25.759" r="1.88889" fill={lightCol} stroke={darkCol}/>
<rect width="32.3827" height="9.02441" transform="translate(36 61)" fill={lightCol}/>
<line x1="36" y1="60.5" x2="68.3827" y2="60.5" stroke={darkCol}/>
<line x1="45.5566" y1="69.5244" x2="58.2974" y2="69.5244" stroke={darkCol}/>
<rect width="32.3827" height="9.02441" transform="translate(148.012 61)" fill={lightCol}/>
<line x1="148.012" y1="60.5" x2="180.394" y2="60.5" stroke={darkCol}/>
<line x1="148.012" y1="69.5244" x2="180.394" y2="69.5244" stroke={darkCol}/>
<rect x="114.006" y="20.1543" width="31.3827" height="13.3333" fill={lightCol}/>
<rect x="114.006" y="20.1543" width="31.3827" height="13.3333" stroke={darkCol}/>
<path d="M143.214 64H142.157C142.095 63.696 141.986 63.429 141.829 63.1989C141.676 62.9688 141.488 62.7756 141.267 62.6193C141.048 62.4602 140.805 62.3409 140.538 62.2614C140.271 62.1818 139.993 62.142 139.703 62.142C139.175 62.142 138.696 62.2756 138.267 62.5426C137.841 62.8097 137.501 63.2031 137.248 63.723C136.998 64.2429 136.873 64.8807 136.873 65.6364C136.873 66.392 136.998 67.0298 137.248 67.5497C137.501 68.0696 137.841 68.4631 138.267 68.7301C138.696 68.9972 139.175 69.1307 139.703 69.1307C139.993 69.1307 140.271 69.0909 140.538 69.0114C140.805 68.9318 141.048 68.8139 141.267 68.6577C141.488 68.4986 141.676 68.304 141.829 68.0739C141.986 67.8409 142.095 67.5739 142.157 67.2727H143.214C143.135 67.7187 142.99 68.1179 142.78 68.4702C142.569 68.8224 142.308 69.1222 141.996 69.3693C141.683 69.6136 141.332 69.7997 140.943 69.9276C140.557 70.0554 140.143 70.1193 139.703 70.1193C138.959 70.1193 138.297 69.9375 137.717 69.5739C137.138 69.2102 136.682 68.6932 136.349 68.0227C136.017 67.3523 135.851 66.5568 135.851 65.6364C135.851 64.7159 136.017 63.9205 136.349 63.25C136.682 62.5795 137.138 62.0625 137.717 61.6989C138.297 61.3352 138.959 61.1534 139.703 61.1534C140.143 61.1534 140.557 61.2173 140.943 61.3452C141.332 61.473 141.683 61.6605 141.996 61.9077C142.308 62.152 142.569 62.4503 142.78 62.8026C142.99 63.152 143.135 63.5511 143.214 64Z" fill={darkCol}/>
<path d="M24.8601 61.2727L27.451 68.6193H27.5533L30.1442 61.2727H31.2521L28.0476 70H26.9567L23.7521 61.2727H24.8601Z" fill={darkCol}/>
<path d="M127.219 15V6.27273H130.168C130.85 6.27273 131.409 6.3892 131.847 6.62216C132.284 6.85227 132.608 7.16903 132.818 7.57244C133.029 7.97585 133.134 8.43466 133.134 8.94886C133.134 9.46307 133.029 9.91903 132.818 10.3168C132.608 10.7145 132.286 11.027 131.851 11.2543C131.416 11.4787 130.861 11.5909 130.185 11.5909H127.798V10.6364H130.151C130.617 10.6364 130.992 10.5682 131.276 10.4318C131.563 10.2955 131.77 10.1023 131.898 9.85227C132.029 9.59943 132.094 9.2983 132.094 8.94886C132.094 8.59943 132.029 8.29403 131.898 8.03267C131.767 7.77131 131.558 7.5696 131.271 7.42756C130.985 7.28267 130.605 7.21023 130.134 7.21023H128.276V15H127.219ZM131.327 11.0795L133.475 15H132.247L130.134 11.0795H131.327Z" fill={darkCol}/>
</svg>;
 return img;
};

export const getInductanceSchemaOff = (isLightMode) => { 
    const darkCol = isLightMode ? DARK_COLOR : LIGHT_COLOR;
    const lightCol = isLightMode ? LIGHT_COLOR : DARK_COLOR;

    const img = 
<svg viewBox="0, 0, 216, 120" preserveAspectRatio="none" fill="none" key="ind1" xmlns="http://www.w3.org/2000/svg"> 
<rect x="0.5" y="0.5" width="215" height="119" rx="5.5" fill={lightCol}/>
<path d="M78.4678 19.3887H51.6592V100.876H78.4678H163.937L163.937 19.3887H99.5" stroke={darkCol}/>
<circle cx="78.2033" cy="19.3889" r="1.88889" fill={lightCol} stroke={darkCol}/>
<g clipPath="url(#clip0_4454_263449)">
<circle cx="162.93" cy="34.5" r="4" fill={lightCol} stroke={darkCol}/>
<circle cx="162.93" cy="42.5" r="4" fill={lightCol} stroke={darkCol}/>
<circle cx="162.93" cy="50.5" r="4" fill={lightCol} stroke={darkCol}/>
</g>
<circle cx="111.889" cy="100.611" r="2.12346" fill={darkCol} stroke={darkCol} strokeWidth="0.530864"/>
<circle cx="111.889" cy="19.3889" r="2.12346" fill={darkCol} stroke={darkCol} strokeWidth="0.530864"/>
<circle cx="100.389" cy="19.3889" r="1.88889" fill={lightCol} stroke={darkCol}/>
<rect width="32.3827" height="9.02441" transform="translate(36 61)" fill={lightCol}/>
<line x1="36" y1="60.5" x2="68.3827" y2="60.5" stroke={darkCol}/>
<line x1="45.5566" y1="69.5244" x2="58.2974" y2="69.5244" stroke={darkCol}/>
<path d="M112 20V100.5" stroke={darkCol}/>
<path d="M147.182 47V38.2727H148.239V46.0625H152.295V47H147.182Z" fill={darkCol}/>
<rect x="118.833" y="45.5" width="31.3827" height="13.3333" transform="rotate(90 118.833 45.5)" fill={lightCol}/>
<rect x="118.833" y="45.5" width="31.3827" height="13.3333" transform="rotate(90 118.833 45.5)" stroke={darkCol}/>
<rect x="170.833" y="61.5" width="31.3827" height="13.3333" transform="rotate(90 170.833 61.5)" fill={lightCol}/>
<rect x="170.833" y="61.5" width="31.3827" height="13.3333" transform="rotate(90 170.833 61.5)" stroke={darkCol}/>
<path d="M89.0861 65V56.2727H92.035C92.7168 56.2727 93.2765 56.3892 93.714 56.6222C94.1515 56.8523 94.4753 57.169 94.6855 57.5724C94.8958 57.9759 95.0009 58.4347 95.0009 58.9489C95.0009 59.4631 94.8958 59.919 94.6855 60.3168C94.4753 60.7145 94.1529 61.027 93.7182 61.2543C93.2836 61.4787 92.7282 61.5909 92.052 61.5909H89.6657V60.6364H92.0179C92.4838 60.6364 92.8588 60.5682 93.1429 60.4318C93.4299 60.2955 93.6373 60.1023 93.7651 59.8523C93.8958 59.5994 93.9611 59.2983 93.9611 58.9489C93.9611 58.5994 93.8958 58.294 93.7651 58.0327C93.6344 57.7713 93.4256 57.5696 93.1387 57.4276C92.8517 57.2827 92.4725 57.2102 92.0009 57.2102H90.1429V65H89.0861ZM93.1941 61.0795L95.3418 65H94.1145L92.0009 61.0795H93.1941ZM96.6085 65V64.233L99.4892 61.0795C99.8272 60.7102 100.106 60.3892 100.324 60.1165C100.543 59.8409 100.705 59.5824 100.81 59.3409C100.918 59.0966 100.972 58.8409 100.972 58.5739C100.972 58.267 100.898 58.0014 100.751 57.777C100.606 57.5526 100.407 57.3793 100.154 57.2571C99.9011 57.1349 99.617 57.0739 99.3017 57.0739C98.9664 57.0739 98.6738 57.1435 98.4238 57.2827C98.1767 57.419 97.9849 57.6108 97.8485 57.858C97.715 58.1051 97.6483 58.3949 97.6483 58.7273H96.6426C96.6426 58.2159 96.7605 57.767 96.9963 57.3807C97.2321 56.9943 97.5531 56.6932 97.9593 56.4773C98.3684 56.2614 98.8272 56.1534 99.3358 56.1534C99.8471 56.1534 100.3 56.2614 100.695 56.4773C101.09 56.6932 101.4 56.9844 101.624 57.3509C101.849 57.7173 101.961 58.125 101.961 58.5739C101.961 58.8949 101.903 59.2088 101.786 59.5156C101.672 59.8196 101.474 60.1591 101.189 60.5341C100.908 60.9062 100.518 61.3608 100.018 61.8977L98.0574 63.9943V64.0625H102.114V65H96.6085Z" fill={darkCol}/>
<path d="M140.43 81V72.2727H143.379C144.061 72.2727 144.62 72.3892 145.058 72.6222C145.495 72.8523 145.819 73.169 146.029 73.5724C146.24 73.9759 146.345 74.4347 146.345 74.9489C146.345 75.4631 146.24 75.919 146.029 76.3168C145.819 76.7145 145.497 77.027 145.062 77.2543C144.627 77.4787 144.072 77.5909 143.396 77.5909H141.009V76.6364H143.362C143.828 76.6364 144.203 76.5682 144.487 76.4318C144.774 76.2955 144.981 76.1023 145.109 75.8523C145.24 75.5994 145.305 75.2983 145.305 74.9489C145.305 74.5994 145.24 74.294 145.109 74.0327C144.978 73.7713 144.769 73.5696 144.482 73.4276C144.195 73.2827 143.816 73.2102 143.345 73.2102H141.487V81H140.43ZM144.538 77.0795L146.686 81H145.458L143.345 77.0795H144.538ZM151.02 72.2727V81H149.964V73.3807H149.912L147.782 74.7955V73.7216L149.964 72.2727H151.02Z" fill={darkCol}/>
<path d="M83 10L98.5 18.5" stroke={darkCol}/>
<path d="M24.8601 61.2727L27.451 68.6193H27.5533L30.1442 61.2727H31.2521L28.0476 70H26.9567L23.7521 61.2727H24.8601Z" fill={darkCol}/>
<defs>
<clipPath id="clip0_4454_263449">
<rect width="4.5" height="25" fill={lightCol} transform="translate(163.43 30)"/>
</clipPath>
</defs>
</svg>;
 return img;
};

export const getInductanceSchemaOn = (isLightMode) => {
    const darkCol = isLightMode ? DARK_COLOR : LIGHT_COLOR;
    const lightCol = isLightMode ? LIGHT_COLOR : DARK_COLOR;

    const img = 
<svg viewBox="0, 0, 216, 120" preserveAspectRatio="none" fill="none" key="ind2" xmlns="http://www.w3.org/2000/svg"> 
<rect x="0.5" y="0.5" width="215" height="119" rx="5.5" fill={lightCol}/>
<path d="M78.4678 19.3887H51.6592V100.876H78.4678H163.937L163.937 19.3887H99.5" stroke={darkCol}/>
<circle cx="78.2033" cy="19.3889" r="1.88889" fill={lightCol} stroke={darkCol}/>
<g clipPath="url(#clip0_4454_263449)">
<circle cx="162.93" cy="34.5" r="4" fill={lightCol} stroke={darkCol}/>
<circle cx="162.93" cy="42.5" r="4" fill={lightCol} stroke={darkCol}/>
<circle cx="162.93" cy="50.5" r="4" fill={lightCol} stroke={darkCol}/>
</g>
<circle cx="111.889" cy="100.611" r="2.12346" fill={darkCol} stroke={darkCol} strokeWidth="0.530864"/>
<circle cx="111.889" cy="19.3889" r="2.12346" fill={darkCol} stroke={darkCol} strokeWidth="0.530864"/>
<circle cx="100.389" cy="19.3889" r="1.88889" fill={lightCol} stroke={darkCol}/>
<rect width="32.3827" height="9.02441" transform="translate(36 61)" fill={lightCol}/>
<line x1="36" y1="60.5" x2="68.3827" y2="60.5" stroke={darkCol}/>
<line x1="45.5566" y1="69.5244" x2="58.2974" y2="69.5244" stroke={darkCol}/>
<path d="M112 20V100.5" stroke={darkCol}/>
<path d="M147.182 47V38.2727H148.239V46.0625H152.295V47H147.182Z" fill={darkCol}/>
<rect x="118.833" y="45.5" width="31.3827" height="13.3333" transform="rotate(90 118.833 45.5)" fill={lightCol}/>
<rect x="118.833" y="45.5" width="31.3827" height="13.3333" transform="rotate(90 118.833 45.5)" stroke={darkCol}/>
<rect x="170.833" y="61.5" width="31.3827" height="13.3333" transform="rotate(90 170.833 61.5)" fill={lightCol}/>
<rect x="170.833" y="61.5" width="31.3827" height="13.3333" transform="rotate(90 170.833 61.5)" stroke={darkCol}/>
<path d="M89.0861 65V56.2727H92.035C92.7168 56.2727 93.2765 56.3892 93.714 56.6222C94.1515 56.8523 94.4753 57.169 94.6855 57.5724C94.8958 57.9759 95.0009 58.4347 95.0009 58.9489C95.0009 59.4631 94.8958 59.919 94.6855 60.3168C94.4753 60.7145 94.1529 61.027 93.7182 61.2543C93.2836 61.4787 92.7282 61.5909 92.052 61.5909H89.6657V60.6364H92.0179C92.4838 60.6364 92.8588 60.5682 93.1429 60.4318C93.4299 60.2955 93.6373 60.1023 93.7651 59.8523C93.8958 59.5994 93.9611 59.2983 93.9611 58.9489C93.9611 58.5994 93.8958 58.294 93.7651 58.0327C93.6344 57.7713 93.4256 57.5696 93.1387 57.4276C92.8517 57.2827 92.4725 57.2102 92.0009 57.2102H90.1429V65H89.0861ZM93.1941 61.0795L95.3418 65H94.1145L92.0009 61.0795H93.1941ZM96.6085 65V64.233L99.4892 61.0795C99.8272 60.7102 100.106 60.3892 100.324 60.1165C100.543 59.8409 100.705 59.5824 100.81 59.3409C100.918 59.0966 100.972 58.8409 100.972 58.5739C100.972 58.267 100.898 58.0014 100.751 57.777C100.606 57.5526 100.407 57.3793 100.154 57.2571C99.9011 57.1349 99.617 57.0739 99.3017 57.0739C98.9664 57.0739 98.6738 57.1435 98.4238 57.2827C98.1767 57.419 97.9849 57.6108 97.8485 57.858C97.715 58.1051 97.6483 58.3949 97.6483 58.7273H96.6426C96.6426 58.2159 96.7605 57.767 96.9963 57.3807C97.2321 56.9943 97.5531 56.6932 97.9593 56.4773C98.3684 56.2614 98.8272 56.1534 99.3358 56.1534C99.8471 56.1534 100.3 56.2614 100.695 56.4773C101.09 56.6932 101.4 56.9844 101.624 57.3509C101.849 57.7173 101.961 58.125 101.961 58.5739C101.961 58.8949 101.903 59.2088 101.786 59.5156C101.672 59.8196 101.474 60.1591 101.189 60.5341C100.908 60.9062 100.518 61.3608 100.018 61.8977L98.0574 63.9943V64.0625H102.114V65H96.6085Z" fill={darkCol}/>
<path d="M140.43 81V72.2727H143.379C144.061 72.2727 144.62 72.3892 145.058 72.6222C145.495 72.8523 145.819 73.169 146.029 73.5724C146.24 73.9759 146.345 74.4347 146.345 74.9489C146.345 75.4631 146.24 75.919 146.029 76.3168C145.819 76.7145 145.497 77.027 145.062 77.2543C144.627 77.4787 144.072 77.5909 143.396 77.5909H141.009V76.6364H143.362C143.828 76.6364 144.203 76.5682 144.487 76.4318C144.774 76.2955 144.981 76.1023 145.109 75.8523C145.24 75.5994 145.305 75.2983 145.305 74.9489C145.305 74.5994 145.24 74.294 145.109 74.0327C144.978 73.7713 144.769 73.5696 144.482 73.4276C144.195 73.2827 143.816 73.2102 143.345 73.2102H141.487V81H140.43ZM144.538 77.0795L146.686 81H145.458L143.345 77.0795H144.538ZM151.02 72.2727V81H149.964V73.3807H149.912L147.782 74.7955V73.7216L149.964 72.2727H151.02Z" fill={darkCol}/>
<path d="M 81 19.0 L 98.5 19.0" stroke={darkCol}/>
<path d="M24.8601 61.2727L27.451 68.6193H27.5533L30.1442 61.2727H31.2521L28.0476 70H26.9567L23.7521 61.2727H24.8601Z" fill={darkCol}/>
<defs>
<clipPath id="clip0_4454_263449">
<rect width="4.5" height="25" fill={lightCol} transform="translate(163.43 30)"/>
</clipPath>
</defs>
</svg>;
 return img;
};

export const getOscCircuitOff = (isLightMode) => {
    const darkCol = isLightMode ? DARK_COLOR : LIGHT_COLOR;
    const lightCol = isLightMode ? LIGHT_COLOR : DARK_COLOR;

    const img = 
<svg viewBox="0, 0, 216, 120" preserveAspectRatio="none" key="oscOff1" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="215" height="119" rx="5.5" fill={lightCol}/>
<path d="M84.4678 14.3887H57.6592V95.8763H84.4678 M78.0975 34.5615L84.4678 30.5296" stroke={darkCol}/>
<path d="M84.4678 30.5296 L99.0666 21.2899 H169.937 V95.8763 H84.4678" stroke={darkCol}/>
<path d="M84.4678 30.5296 V95.8763" stroke={darkCol}/>
<g clipPath="url(#clip0_4456_257808)">
<circle cx="168.93" cy="49.5" r="4" fill={lightCol} stroke={darkCol}/>
<circle cx="168.93" cy="57.5" r="4" fill={lightCol} stroke={darkCol}/>
<circle cx="168.93" cy="65.5" r="4" fill={lightCol} stroke={darkCol}/>
</g>
<circle cx="84.2033" cy="14.3889" r="1.88889" fill={lightCol} stroke={darkCol}/>
<circle cx="84.2033" cy="30.8449" r="1.88889" fill={lightCol} stroke={darkCol}/>
<circle cx="84.2033" cy="95.6106" r="2.12346" fill={darkCol} stroke={darkCol} strokeWidth="0.530864"/>
<circle cx="99.0686" cy="20.759" r="1.88889" fill={lightCol} stroke={darkCol}/>
<rect width="32.3827" height="9.02441" transform="translate(42 56)" fill={lightCol}/>
<line x1="42" y1="55.5" x2="74.3827" y2="55.5" stroke={darkCol}/>
<line x1="51.5566" y1="64.5244" x2="64.2974" y2="64.5244" stroke={darkCol}/>
<rect x="120.007" y="15.1543" width="31.3827" height="13.3333" fill={lightCol}/>
<rect x="120.007" y="15.1543" width="31.3827" height="13.3333" stroke={darkCol}/>
<path d="M154.182 61V52.2727H155.239V60.0625H159.295V61H154.182Z" fill={darkCol}/>
<path d="M132.214 70H131.157C131.095 69.696 130.986 69.429 130.829 69.1989C130.676 68.9688 130.488 68.7756 130.267 68.6193C130.048 68.4602 129.805 68.3409 129.538 68.2614C129.271 68.1818 128.993 68.142 128.703 68.142C128.175 68.142 127.696 68.2756 127.267 68.5426C126.841 68.8097 126.501 69.2031 126.248 69.723C125.998 70.2429 125.873 70.8807 125.873 71.6364C125.873 72.392 125.998 73.0298 126.248 73.5497C126.501 74.0696 126.841 74.4631 127.267 74.7301C127.696 74.9972 128.175 75.1307 128.703 75.1307C128.993 75.1307 129.271 75.0909 129.538 75.0114C129.805 74.9318 130.048 74.8139 130.267 74.6577C130.488 74.4986 130.676 74.304 130.829 74.0739C130.986 73.8409 131.095 73.5739 131.157 73.2727H132.214C132.135 73.7187 131.99 74.1179 131.78 74.4702C131.569 74.8224 131.308 75.1222 130.996 75.3693C130.683 75.6136 130.332 75.7997 129.943 75.9276C129.557 76.0554 129.143 76.1193 128.703 76.1193C127.959 76.1193 127.297 75.9375 126.717 75.5739C126.138 75.2102 125.682 74.6932 125.349 74.0227C125.017 73.3523 124.851 72.5568 124.851 71.6364C124.851 70.7159 125.017 69.9205 125.349 69.25C125.682 68.5795 126.138 68.0625 126.717 67.6989C127.297 67.3352 127.959 67.1534 128.703 67.1534C129.143 67.1534 129.557 67.2173 129.943 67.3452C130.332 67.473 130.683 67.6605 130.996 67.9077C131.308 68.152 131.569 68.4503 131.78 68.8026C131.99 69.152 132.135 69.5511 132.214 70Z" fill={darkCol}/>
<path d="M125.219 42V33.2727H128.168C128.85 33.2727 129.409 33.3892 129.847 33.6222C130.284 33.8523 130.608 34.169 130.818 34.5724C131.029 34.9759 131.134 35.4347 131.134 35.9489C131.134 36.4631 131.029 36.919 130.818 37.3168C130.608 37.7145 130.286 38.027 129.851 38.2543C129.416 38.4787 128.861 38.5909 128.185 38.5909H125.798V37.6364H128.151C128.617 37.6364 128.992 37.5682 129.276 37.4318C129.563 37.2955 129.77 37.1023 129.898 36.8523C130.029 36.5994 130.094 36.2983 130.094 35.9489C130.094 35.5994 130.029 35.294 129.898 35.0327C129.767 34.7713 129.558 34.5696 129.271 34.4276C128.985 34.2827 128.605 34.2102 128.134 34.2102H126.276V42H125.219ZM129.327 38.0795L131.475 42H130.247L128.134 38.0795H129.327Z" fill={darkCol}/>
<rect width="26" height="9" transform="translate(132 83) rotate(90)" fill={lightCol}/>
<line x1="132.5" y1="83" x2="132.5" y2="109" stroke={darkCol}/>
<line x1="123.5" y1="83" x2="123.5" y2="109" stroke={darkCol}/>
<path d="M30.8601 56.2727L33.451 63.6193H33.5533L36.1442 56.2727H37.2521L34.0476 65H32.9567L29.7521 56.2727H30.8601Z" fill={darkCol}/>
<defs>
<clipPath id="clip0_4456_257808">
<rect width="4.5" height="25" fill={lightCol} transform="translate(169.43 45)"/>
</clipPath>
</defs>
</svg>;
 return img;
};

export const getOscCircuitOn = (isLightMode) => {
    const darkCol = isLightMode ? DARK_COLOR : LIGHT_COLOR;
    const lightCol = isLightMode ? LIGHT_COLOR : DARK_COLOR;

    const img = 
<svg viewBox="0, 0, 216, 120" preserveAspectRatio="none" key="oscOff1" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="215" height="119" rx="5.5" fill={lightCol}/>
<path d="M84.4678 14.3887 H57.6592 V95.8763 H84.4678 " stroke={darkCol}/>
<path d="M84.5 15 L99.0666 21.2899 H169.937 V95.8763 H84.4678" stroke={darkCol}  />
<path d="M84.4678 30.5296 V95.8763" stroke={darkCol}/>
<g clipPath="url(#clip0_4456_257808)">
<circle cx="168.93" cy="49.5" r="4" fill={lightCol} stroke={darkCol}/>
<circle cx="168.93" cy="57.5" r="4" fill={lightCol} stroke={darkCol}/>
<circle cx="168.93" cy="65.5" r="4" fill={lightCol} stroke={darkCol}/>
</g>
<circle cx="84.2033" cy="14.3889" r="1.88889" fill={lightCol} stroke={darkCol}/>
<circle cx="84.2033" cy="30.8449" r="1.88889" fill={lightCol} stroke={darkCol}/>
<circle cx="84.2033" cy="95.6106" r="2.12346" fill={darkCol} stroke={darkCol} strokeWidth="0.530864"/>
<circle cx="99.0686" cy="20.759" r="1.88889" fill={lightCol} stroke={darkCol}/>
<rect width="32.3827" height="9.02441" transform="translate(42 56)" fill={lightCol}/>
<line x1="42" y1="55.5" x2="74.3827" y2="55.5" stroke={darkCol}/>
<line x1="51.5566" y1="64.5244" x2="64.2974" y2="64.5244" stroke={darkCol}/>
<rect x="120.007" y="15.1543" width="31.3827" height="13.3333" fill={lightCol}/>
<rect x="120.007" y="15.1543" width="31.3827" height="13.3333" stroke={darkCol}/>
<path d="M154.182 61V52.2727H155.239V60.0625H159.295V61H154.182Z" fill={darkCol}/>
<path d="M132.214 70H131.157C131.095 69.696 130.986 69.429 130.829 69.1989C130.676 68.9688 130.488 68.7756 130.267 68.6193C130.048 68.4602 129.805 68.3409 129.538 68.2614C129.271 68.1818 128.993 68.142 128.703 68.142C128.175 68.142 127.696 68.2756 127.267 68.5426C126.841 68.8097 126.501 69.2031 126.248 69.723C125.998 70.2429 125.873 70.8807 125.873 71.6364C125.873 72.392 125.998 73.0298 126.248 73.5497C126.501 74.0696 126.841 74.4631 127.267 74.7301C127.696 74.9972 128.175 75.1307 128.703 75.1307C128.993 75.1307 129.271 75.0909 129.538 75.0114C129.805 74.9318 130.048 74.8139 130.267 74.6577C130.488 74.4986 130.676 74.304 130.829 74.0739C130.986 73.8409 131.095 73.5739 131.157 73.2727H132.214C132.135 73.7187 131.99 74.1179 131.78 74.4702C131.569 74.8224 131.308 75.1222 130.996 75.3693C130.683 75.6136 130.332 75.7997 129.943 75.9276C129.557 76.0554 129.143 76.1193 128.703 76.1193C127.959 76.1193 127.297 75.9375 126.717 75.5739C126.138 75.2102 125.682 74.6932 125.349 74.0227C125.017 73.3523 124.851 72.5568 124.851 71.6364C124.851 70.7159 125.017 69.9205 125.349 69.25C125.682 68.5795 126.138 68.0625 126.717 67.6989C127.297 67.3352 127.959 67.1534 128.703 67.1534C129.143 67.1534 129.557 67.2173 129.943 67.3452C130.332 67.473 130.683 67.6605 130.996 67.9077C131.308 68.152 131.569 68.4503 131.78 68.8026C131.99 69.152 132.135 69.5511 132.214 70Z" fill={darkCol}/>
<path d="M125.219 42V33.2727H128.168C128.85 33.2727 129.409 33.3892 129.847 33.6222C130.284 33.8523 130.608 34.169 130.818 34.5724C131.029 34.9759 131.134 35.4347 131.134 35.9489C131.134 36.4631 131.029 36.919 130.818 37.3168C130.608 37.7145 130.286 38.027 129.851 38.2543C129.416 38.4787 128.861 38.5909 128.185 38.5909H125.798V37.6364H128.151C128.617 37.6364 128.992 37.5682 129.276 37.4318C129.563 37.2955 129.77 37.1023 129.898 36.8523C130.029 36.5994 130.094 36.2983 130.094 35.9489C130.094 35.5994 130.029 35.294 129.898 35.0327C129.767 34.7713 129.558 34.5696 129.271 34.4276C128.985 34.2827 128.605 34.2102 128.134 34.2102H126.276V42H125.219ZM129.327 38.0795L131.475 42H130.247L128.134 38.0795H129.327Z" fill={darkCol}/>
<rect width="26" height="9" transform="translate(132 83) rotate(90)" fill={lightCol}/>
<line x1="132.5" y1="83" x2="132.5" y2="109" stroke={darkCol}/>
<line x1="123.5" y1="83" x2="123.5" y2="109" stroke={darkCol}/>
<path d="M30.8601 56.2727L33.451 63.6193H33.5533L36.1442 56.2727H37.2521L34.0476 65H32.9567L29.7521 56.2727H30.8601Z" fill={darkCol}/>
<defs>
<clipPath id="clip0_4456_257808">
<rect width="4.5" height="25" fill={lightCol} transform="translate(169.43 45)"/>
</clipPath>
</defs>
</svg>;
 return img;
};

export const getResonance0 = (isLightMode) => {
    const darkCol = isLightMode ? DARK_COLOR : LIGHT_COLOR;
    const lightCol = isLightMode ? LIGHT_COLOR : DARK_COLOR;

    const img = 
<svg viewBox="0, 0, 216, 120" preserveAspectRatio="none" key="imgresonance01" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="215" height="141" rx="5.5" fill={lightCol}/>
<rect width="32.3827" height="9.02441" transform="translate(88.0244 102) rotate(90)" fill={lightCol}/>
<path d="M59.5057 18.0547H15V54.389M50.4284 18.0547H201.395L201.395 120.457H59.5057H15V81.9122" stroke={darkCol} stroke-width="1.15059"/>
<path d="M140.564 42.1084V32.0669H143.957C144.741 32.0669 145.385 32.2009 145.889 32.469C146.392 32.7337 146.765 33.0982 147.006 33.5624C147.248 34.0265 147.369 34.5544 147.369 35.146C147.369 35.7377 147.248 36.2623 147.006 36.7199C146.765 37.1775 146.394 37.5371 145.893 37.7986C145.393 38.0568 144.754 38.1859 143.976 38.1859H141.231V37.0877H143.937C144.473 37.0877 144.905 37.0092 145.232 36.8523C145.562 36.6954 145.8 36.4731 145.947 36.1855C146.098 35.8946 146.173 35.5481 146.173 35.146C146.173 34.744 146.098 34.3926 145.947 34.0919C145.797 33.7912 145.557 33.5591 145.227 33.3956C144.896 33.2289 144.46 33.1456 143.918 33.1456H141.78V42.1084H140.564ZM145.29 37.5976L147.762 42.1084H146.349L143.918 37.5976H145.29Z" fill={darkCol}/>
<path d="M149.362 42.8496V36.1553H150.173V42.1305H153.284V42.8496H149.362Z" fill={darkCol}/>
<path d="M140.564 106.541V96.4995H143.957C144.741 96.4995 145.385 96.6336 145.889 96.9016C146.392 97.1663 146.765 97.5308 147.006 97.995C147.248 98.4591 147.369 98.987 147.369 99.5787C147.369 100.17 147.248 100.695 147.006 101.153C146.765 101.61 146.394 101.97 145.893 102.231C145.393 102.489 144.754 102.619 143.976 102.619H141.231V101.52H143.937C144.473 101.52 144.905 101.442 145.232 101.285C145.562 101.128 145.8 100.906 145.947 100.618C146.098 100.327 146.173 99.9807 146.173 99.5787C146.173 99.1766 146.098 98.8252 145.947 98.5245C145.797 98.2238 145.557 97.9917 145.227 97.8283C144.896 97.6616 144.46 97.5782 143.918 97.5782H141.78V106.541H140.564ZM145.29 102.03L147.762 106.541H146.349L143.918 102.03H145.29Z" fill={darkCol}/>
<path d="M151.315 107.387C150.844 107.387 150.439 107.276 150.099 107.053C149.759 106.831 149.498 106.525 149.315 106.135C149.132 105.745 149.04 105.299 149.04 104.798C149.04 104.288 149.134 103.838 149.321 103.448C149.511 103.056 149.774 102.75 150.112 102.53C150.452 102.307 150.849 102.196 151.302 102.196C151.655 102.196 151.973 102.261 152.256 102.392C152.54 102.523 152.772 102.706 152.953 102.941C153.134 103.177 153.246 103.451 153.289 103.765H152.518C152.459 103.536 152.328 103.334 152.126 103.157C151.925 102.978 151.655 102.889 151.315 102.889C151.014 102.889 150.751 102.968 150.524 103.124C150.3 103.279 150.124 103.498 149.998 103.781C149.874 104.063 149.811 104.393 149.811 104.772C149.811 105.16 149.872 105.498 149.994 105.785C150.119 106.073 150.293 106.296 150.517 106.455C150.744 106.614 151.01 106.694 151.315 106.694C151.515 106.694 151.697 106.659 151.861 106.589C152.024 106.52 152.163 106.419 152.276 106.289C152.389 106.158 152.47 106.001 152.518 105.818H153.289C153.246 106.114 153.138 106.381 152.966 106.619C152.796 106.854 152.57 107.041 152.289 107.181C152.01 107.318 151.685 107.387 151.315 107.387Z" fill={darkCol}/>
<g clipPath="url(#clip0_4734_279816)">
<circle cx="42.038" cy="19.8604" r="4.60235" transform="rotate(-90 42.038 19.8604)" fill={lightCol} stroke={darkCol} stroke-width="1.15059"/>
<circle cx="51.244" cy="19.8604" r="4.60235" transform="rotate(-90 51.244 19.8604)" fill={lightCol} stroke={darkCol} stroke-width="1.15059"/>
<circle cx="60.4481" cy="19.8604" r="4.60235" transform="rotate(-90 60.4481 19.8604)" fill={lightCol} stroke={darkCol} stroke-width="1.15059"/>
</g>
<line x1="85.7882" y1="95.1436" x2="85.7882" y2="132.403" stroke={darkCol} stroke-width="1.15059"/>
<line x1="75.4063" y1="106.14" x2="75.4063" y2="120.799" stroke={darkCol} stroke-width="1.15059"/>
<path d="M48.5486 35.2041V25.1626H49.7645V34.1254H54.4322V35.2041H48.5486Z" fill={darkCol}/>
<path d="M119.121 45.5603H117.905C117.833 45.2106 117.708 44.9033 117.528 44.6386C117.351 44.3738 117.136 44.1515 116.881 43.9718C116.629 43.7887 116.349 43.6514 116.042 43.5599C115.735 43.4684 115.415 43.4226 115.081 43.4226C114.473 43.4226 113.922 43.5762 113.429 43.8835C112.939 44.1908 112.548 44.6435 112.257 45.2416C111.969 45.8398 111.826 46.5736 111.826 47.4431C111.826 48.3126 111.969 49.0464 112.257 49.6446C112.548 50.2428 112.939 50.6955 113.429 51.0028C113.922 51.31 114.473 51.4636 115.081 51.4636C115.415 51.4636 115.735 51.4179 116.042 51.3264C116.349 51.2348 116.629 51.0992 116.881 50.9194C117.136 50.7364 117.351 50.5124 117.528 50.2477C117.708 49.9796 117.833 49.6724 117.905 49.3259H119.121C119.03 49.8391 118.863 50.2983 118.621 50.7037C118.379 51.109 118.079 51.4538 117.719 51.7382C117.359 52.0193 116.956 52.2334 116.508 52.3805C116.063 52.5276 115.588 52.6012 115.081 52.6012C114.225 52.6012 113.463 52.392 112.796 51.9736C112.13 51.5552 111.605 50.9603 111.222 50.1888C110.84 49.4174 110.649 48.5022 110.649 47.4431C110.649 46.3841 110.84 45.4688 111.222 44.6974C111.605 43.926 112.13 43.3311 112.796 42.9127C113.463 42.4943 114.225 42.2851 115.081 42.2851C115.588 42.2851 116.063 42.3586 116.508 42.5057C116.956 42.6528 117.359 42.8686 117.719 43.1529C118.079 43.4341 118.379 43.7773 118.621 44.1826C118.863 44.5846 119.03 45.0439 119.121 45.5603Z" fill={darkCol}/>
<path d="M58.01 108.842V98.8003H64.0701V99.879H59.2259V103.272H63.7563V104.351H59.2259V107.763H64.1486V108.842H58.01Z" fill={darkCol}/>
<rect x="128.332" y="10.5753" width="36.1085" height="15.3412" fill={lightCol}/>
<rect x="128.332" y="10.5753" width="36.1085" height="15.3412" stroke={darkCol} stroke-width="1.15059"/>
<rect x="128.332" y="112.979" width="36.1085" height="15.3412" fill={lightCol}/>
<rect x="128.332" y="112.979" width="36.1085" height="15.3412" stroke={darkCol} stroke-width="1.15059"/>
<path d="M201.395 74.4331H35.1353L15 54.2979" stroke={darkCol} stroke-width="1.15059" stroke-linecap="round"/>
<rect width="37.2591" height="10.3834" transform="translate(118.581 56.0225) rotate(90)" fill={lightCol}/>
<line x1="119.128" y1="60.626" x2="119.128" y2="88.2401" stroke={darkCol} stroke-width="1.15059"/>
<line x1="108.773" y1="60.626" x2="108.773" y2="88.2401" stroke={darkCol} stroke-width="1.15059"/>
<rect x="0.5" y="0.5" width="215" height="141" rx="5.5" stroke="#CBD4DC"/>
<defs>
<clipPath id="clip0_4734_279816">
<rect width="5.06258" height="28.7647" fill={lightCol} transform="translate(36.8604 18.6289) rotate(-90)"/>
</clipPath>
</defs>
</svg>;
 return img;
};

export const getResonance = (isLightMode) => {
const darkCol = isLightMode ? DARK_COLOR : LIGHT_COLOR;

const img = 
<svg viewBox='0, 0, 1156, 636' preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
<g transform="translate(0.000000,636.000000) scale(0.100000,-0.100000)" fill={darkCol} stroke="none">
<path d="M5070 5855 l0 -235 -1105 0 -1105 0 0 -422 0 -423 -30 -17 c-17 -9 -35 -29 -42 -43 l-11 -25 -399 0 -398 0 0 -145 0 -144 -45 -46 -46 -45 -444 0 -445 0 0 -315 0 -315 -260 0 -260 0 0 -520 0 -520 260 0 260 0 0 -285 0 -285 455 0 c392 0 455 -2 455 -15 0 -8 16 -27 35 -42 l35 -28 0 -147 0 -148 808 0
807 0 17 -30 c9 -17 29 -35 43 -42 l25 -11 0 -369 0 -368 285 0 285 0 0 -235 0 -235 520 0 520 0 0 235 0 235 1105 0 1105 0 0 369 c0 361 0 370 20 376 11 3 32 22 46 41 l26 34 829 0 829 0 0 150 0 149 28 15 c15 8 34 26 42 40 l13 26
454 0 453 0 0 285 0 285 258 2 257 3 3 518 2 517 -260 0 -260 0 0 315 0 315 -453 0 -454 0 -11 24 c-6 13 -24 32 -41 42 l-31 18 0 148 0 148 -340 0 -339 0 -15 28 c-8 15 -26 34 -40 42 l-26 13 0 424 0 423 -1190 0 -1190 0 0 235 0 235 
-520 0 -520 0 0 -235z m980 -290 l0 -465 -460 0 -460 0 0 465 0 465 460 0 460 0 0 -465z m-980 -255 l0 -260 520 0 520 0 0 260 0 260 1160 0 1160 0 0 -398 0 -399 -25 -11 c-13 -6 -31 -25 -40 -42 l-16 -30 -272 2 -272 3 -3 123 -3 122
-734 0 -735 0 0 -125 0 -125 -688 0 -688 0 -34 52 c-69 104 -204 139 -317 83 -28 -15 -64 -42 -78 -61 l-27 -36 -29 35 c-44 53 -120 87 -193 87 -76 0 -129 -24 -178 -78 l-38 -42 -36 39 c-49 55 -107 81 -180 81 -97 0 -190 -56 -227
-137 l-10 -23 -306 0 -306 0 -17 30 c-9 17 -29 35 -43 42 l-25 11 0 399 0 398 1080 0 1080 0 0 -260z m2678 -587 l2 -163 -685 0 -685 0 0 158 c0 87 3 162 7 165 3 4 311 6 682 5 l676 -3 3 -162z m-3832 58 c50 -23 90 -65 105 -108 8 -26
16 -33 37 -33 23 0 31 8 50 50 69 150 282 142 347 -12 13 -32 20 -38 44 -38 25 0 31 5 36 31 15 66 107 129 189 129 68 0 176 -87 176 -142 0 -17 26 -18 715 -18 l715 0 0 -70 0 -70 735 0 735 0 0 70 0 70 280 0 c234 0 280 -2 280
-14 0 -27 59 -66 100 -66 41 0 100 39 100 66 0 12 51 14 320 14 l320 0 0 -124 c0 -115 -1 -125 -20 -131 -79 -25 -79 -175 0 -200 19 -6 20 -16 20 -146 l0 -139 -95 0 -95 0 0 -735 0 -734 93 -3 92 -3 3 -112 c2 -104 1 -112 -17 -118
-76 -24 -80 -159 -7 -193 26 -12 26 -12 26 -137 l0 -125 -809 0 c-797 0 -810 0 -816 20 -25 79 -175 79 -200 0 -6 -20 -17 -20 -471 -20 l-464 0 0 260 0 260 -30 0 -30 0 0 -387 c0 -214 3 -459 7 -546 6 -156 6 -157 30 -157 l23 0 0 260
0 260 463 0 463 0 18 -31 c10 -17 29 -35 42 -41 l24 -11 0 -344 0 -343 -1080 0 -1080 0 0 260 0 260 -520 0 -520 0 0 -260 0 -260 -260 0 -260 0 0 343 0 344 25 11 c14 7 34 25 43 42 l17 30 1038 0 1037 0 0 -260 0 -260 30 0 30 0 0 545
0 545 -30 0 -30 0 0 -260 0 -260 -1039 0 c-1026 0 -1040 0 -1046 20 -10 31 -59 60 -103 60 -43 0 -87 -27 -97 -60 -6 -20 -19 -20 -786 -20 l-779 0 0 123 0 122 35 28 c60 48 57 137 -6 174 l-29 17 0 197 c0 187 1 197 19 202 25 6 61
64 61 97 0 42 -35 88 -78 100 -78 24 -151 -39 -138 -118 6 -37 40 -82 63 -82 10 0 13 -40 13 -198 l0 -199 -25 -11 c-14 -7 -30 -23 -37 -37 -6 -14 -21 -28 -32 -31 -12 -2 -203 -3 -426 -2 l-405 3 -3 257 -2 258 232 2 233 3 3 518 2
517 -235 0 -235 0 2 288 3 287 422 3 422 2 17 -29 c10 -16 28 -35 41 -42 23 -12 23 -14 23 -202 l0 -190 -36 -31 c-70 -61 -50 -163 36 -186 116 -31 189 112 95 187 l-35 28 0 188 0 188 41 39 c39 37 41 42 36 82 -4 33 -14 50 -41 74
l-36 31 0 122 0 121 369 0 c359 0 370 -1 376 -20 24 -75 155 -79 195 -6 l13 26 329 0 328 0 15 37 c21 50 52 82 100 104 51 24 99 24 151 0z m6264 -811 l0 -290 -230 0 -230 0 0 -520 0 -520 230 0 230 0 -2 -257 -3 -258 -416 -3 -416
-2 -27 34 c-14 19 -35 38 -46 41 -19 6 -20 13 -18 118 l3 112 93 3 92 3 0 734 0 735 -95 0 -95 0 0 139 c0 131 1 140 20 146 11 3 32 22 46 41 l26 34 419 0 419 0 0 -290z m-792 -807 l2 -683 -165 0 -165 0 0 678 c0 373 3 682 7 686 4 3
77 5 162 4 l156 -3 3 -682z m-7346 560 c20 -18 24 -63 6 -81 -7 -7 -24 -12 -38 -12 -35 0 -50 17 -50 57 0 26 5 36 23 43 32 13 38 12 59 -7z m-582 -558 l0 -465 -465 0 -465 0 0 465 0 465 465 0 465 0 0 -465z m9240 0 l0 -465 -465
0 -465 0 0 465 0 465 465 0 465 0 0 -465z m-8652 -427 c21 -21 14 -66 -13 -84 -23 -15 -27 -15 -50 0 -17 11 -25 26 -25 44 0 35 16 52 50 52 14 0 31 -5 38 -12z m3190 -1815 l2 -463 -465 0 -465 0 0 465 0 465 463 -2 462 -3 3 -462z"/>
<path d="M5300 5670 c0 -136 3 -188 14 -208 55 -106 224 -125 307 -35 42 45 49 83 49 259 l0 164 -30 0 -30 0 0 -169 c0 -158 -1 -170 -23 -202 -15 -22 -38 -39 -65 -49 -37 -13 -47 -13 -84 0 -76 27 -83 46 -86 246 l-3 174 -25 0 -24 0 0 -180z"/>
<path d="M5720 5535 l0 -155 95 0 c78 0 95 3 95 15 0 12 -15 15 -75 15 l-75 0 0 140 c0 133 -1 140 -20 140 -19 0 -20 -7 -20 -155z"/>
<path d="M4100 4140 l0 -240 140 0 140 0 0 25 c0 24 0 24 -112 27 l-113 3 -3 213 -2 212 -25 0 -25 0 0 -240z"/>
<path d="M6940 4190 l0 -180 24 0 c24 0 24 0 27 131 4 129 4 131 32 155 18 15 41 24 63 24 30 0 34 3 34 25 0 22 -4 25 -34 25 -22 0 -45 -9 -65 -26 l-31 -26 0 26 c0 21 -5 26 -25 26 l-25 0 0 -180z"/>
<path d="M8470 3209 l0 -240 28 3 27 3 3 93 3 92 57 0 58 0 49 -92 c46 -85 53 -93 83 -96 17 -2 32 0 32 3 0 3 -23 47 -50 97 l-50 91 36 31 c77 68 62 195 -29 237 -32 14 -66 19 -144 19 l-103 0 0 -241z m221 176 c55 -29 65 -100 20 -146 -27 -27 -35 -29 -105 -29 l-76 0 0 95 0 95 65 0 c40 0 78 -6 96 -15z"/>
<path d="M1843 3254 c-17 -9 -39 -25 -49 -35 -16 -17 -16 -21 -3 -34 13 -13 21 -12 65 12 62 33 86 28 154 -32 73 -64 157 -73 217 -21 l27 23 -23 12 c-18 10 -29 9 -58 -4 -30 -14 -42 -15 -74 -6 -21 7 -43 20 -49 31 -6 11 -30 31 -54 45 -49 29 -105 32 -153 9z"/>
<path d="M6050 2937 c-70 -37 -103 -98 -108 -202 -4 -73 -1 -90 21 -138 14 -30 39 -65 59 -80 60 -46 152 -55 219 -21 27 14 89 78 89 94 0 4 3 15 6 24 5 12 -1 16 -24 16 -23 0 -32 -6 -42 -30 -28 -67 -134 -92 -201 -47 -50 33 -74 88 -73 168 0 112 50 178 141 186 56 5 104 -19 131 -67 13 -22 24 -30 45 -30 25 0 27 3 22 23 -11 36 -32 64 -71 94 -53 40 -150 45 -214 10z"/>
<path d="M926 3311 c-45 -30 -68 -74 -73 -145 -5 -53 -2 -72 17 -111 13 -26 37 -55 54 -66 39 -24 127 -25 166 -3 27 17 60 62 60 83 0 23 -45 10 -71 -21 -23 -28 -33 -33 -69 -32 -52 2 -87 33 -100 90 -18 78 8 146 66 170 29 12 39 12 68 0 19 -8 39 -24 46 -35 12 -23 60 -30 60 -8 -1 23 -26 58 -56 78 -40 26 -128 26 -168 0z"/>
<path d="M9940 3278 c0 -147 3 -179 19 -214 24 -53 60 -80 124 -93 88 -18 157 9 205 79 21 31 22 43 22 216 l0 184 -25 0 -25 0 0 -152 c0 -175 -8 -217 -51 -252 -55 -46 -153 -33 -189 26 -18 30 -20 51 -20 206 l0 172 -30 0 -30 0 0 -172z"/>
<path d="M10389 3181 c-24 -25 -29 -37 -29 -81 0 -88 34 -130 105 -130 28 0 44 8 68 32 28 28 30 33 14 36 -11 2 -29 -6 -41 -17 -56 -52 -128 8 -112 94 11 59 78 86 113 45 10 -11 26 -20 36 -20 15 0 16 3 6 23 -19 35 -41 46 -87 47 -35 0 -49 -6 -73 -29z"/>
<path d="M4480 1030 c0 -159 2 -185 19 -219 25 -47 57 -69 124 -83 95 -20 195 31 217 110 5 20 10 112 10 205 l0 167 -29 0 -30 0 -3 -186 -3 -186 -33 -29 c-54 -49 -160 -38 -199 20 -15 22 -18 55 -21 204 l-4 177 -24 0 -24 0 0 -180z"/>
<path d="M4942 954 c-50 -35 -66 -109 -35 -167 23 -45 41 -56 94 -57 35 0 51 5 67 23 12 13 22 29 22 35 0 19 -34 14 -48 -8 -17 -27 -65 -26 -92 2 -30 32 -28 101 5 133 29 30 58 32 85 5 21 -21 50 -27 50 -10 0 6 -11 22 -25 35 -29 29 -88 34 -123 9z"/>
</g>
</svg>;
    return img;
};
