export const setChartX = (str) => ({ type: 'SET_CHART_X', payload: str });
export const setChartY = (str) => ({ type: 'SET_CHART_Y', payload: str });
// export const setChartColor = (obj) => ({type: 'SET_CHART_COLOR', payload: obj});
// export const setChartLineType = (obj) => ({type: 'SET_CHART_LINE_TYPE', payload: obj});
// export const setChartLineWeight = (obj) => ({type: 'SET_CHART_LINE_WEIGHT', payload: obj});
export const setChartView = (str) => ({ type: 'SET_CHART_VIEW', payload: str });
export const setChartStyle = (obj) => ({ type: 'SET_CHART_STYLE', payload: obj });
export const setChartMode = (str) => ({ type: 'SET_CHART_MODE', payload: str });
export const setChartSettingsModal = (bool) => ({ type: 'SET_CHART_SETTINGS_MODAL', payload: bool });
export const setChartMarkers = (obj) => ({ type: 'SET_CHART_MARKERS', payload: obj });
export const delChartMarkers = (obj) => ({ type: 'DEL_CHART_MARKERS', payload: obj });
export const setChartMarkerAdd = (bool) => ({ type: 'SET_CHART_MARKER_ADD', payload: bool });
export const setChartMarkerDelete = (bool) => ({ type: 'SET_CHART_MARKER_DELETE', payload: bool });
export const clearChartMarkerModal = (bool) => ({ type: 'CLEAR_CHART_MARKER_MOADL', payload: bool });
export const setChartRangeAdd = (bool) => ({ type: 'SET_CHART_RANGE_ADD', payload: bool });
export const toggleChartLegend = (bool) => ({ type: 'TOGGLE_CHART_LEGEND', payload: bool });
export const setChartRangeDelete = (bool) => ({ type: 'SET_CHART_RANGE_DELETE', payload: bool });
export const toggleChartParametric = (list) => ({ type: 'TOGGLE_CHART_PARAMETRIC', payload: list });
export const toggleChartLissague = (bool) => ({ type: 'TOGGLE_CHART_LISSAGUE', payload: bool });
export const setChartMarkerDeleteAll = (bool) => ({ type: 'SET_CHART_MARKER_DELETE_ALL', payload: bool });
export const addChartDisabled = (id) => ({ type: 'ADD_CHART_DISABLED', payload: id });
export const deleteChartDisabled = (id) => ({ type: 'DELETE_CHART_DISABLED', payload: id });
export const setChartComment = (obj) => ({ type: 'SET_CHART_COMMENT', payload: obj });
export const saveParitalModal = (bool) => ({ type: 'SAVE_PARITAL_MODAL', payload: bool });
export const setSeiesLength = (bool) => ({ type: 'SET_SERIES_LENGTH', payload: bool });
export const setCommentImageModal = (str) => ({ type: 'SET_COMMENT_IMAGE_MODAL', payload: str });
