import React, {useEffect} from 'react';
import {Modal, Button} from 'rlabui';
import {useFormik} from "formik";
import * as Yup from "yup";
import "./Settings.scss";

const NameSettings = (props) => {
    const handleClose = () => {
        props.showNameSettings({showModal: false});
    };

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) handleClose();
        };
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!props.showModal) formik.resetForm();
        // eslint-disable-next-line
    }, [props.showModal]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: props.data.name,
            desc: props.data.desc,
        },
        validationSchema: Yup.object({
            name: Yup.string().trim().required("Введите Имя"),
        }),
        onSubmit: async (formData) => {
            props.onSubmit({...props.data, name: formData.name, desc: formData.desc});
        },
    });

    return (
        <>
            <Modal visible={props.showModal} size="sm">
                <Modal.Head modalClose={handleClose} title={props.isAdd ? "Добавить" : "Редактировать"} />
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <div key="name" className="cor_settings_vert">
                            <label htmlFor="name">Название</label>
                            <div className="form_error">{formik.errors.name}</div>
                            <input
                                autoComplete="off"
                                placeholder="Название"
                                className={formik.errors.name?"form_error":""}
                                name="name"
                                onChange={formik.handleChange}
                                value={formik.values.name}
                            />
                        </div>
                        <div key="desc" className="cor_settings_vert">
                            <label htmlFor="desc">Описание</label>
                            <div className="form_error">{formik.errors.desc}</div>
                            <textarea
                                autoComplete="off"
                                placeholder="Описание"
                                name="desc"
                                rows="4"
                                className={formik.errors.desc?"form_error":""}
                                onChange={formik.handleChange}
                                value={formik.values.desc}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={handleClose} border={true}>Отмена</Button>
                        <Button type="submit">{props.isAdd ? "Добавить" : "Сохранить"}</Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
        
    )
};

export default NameSettings;