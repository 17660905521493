import { setChartSettingsModal } from '../../../actions/chart';
import { toggleTimerModal } from '../../../actions/timer';
import { setResetModal } from '../../../actions/modal';

export const mapStateToProps = (nstate) => {
    return {
        port: nstate.nport.open,
        played: nstate.napp.played,
        isMobile: nstate.napp.isMobile,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setChartSettingsModal: (bool) => dispatch(setChartSettingsModal(bool)),
    toggleTimerModal: (bool) => dispatch(toggleTimerModal(bool)),
    setResetModal: (bool) => dispatch(setResetModal(bool)),
});
