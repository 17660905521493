import React, {useState, useEffect, useCallback} from "react";
import { Icon, Button, Tooltip } from '../ui';
import { getNbCheckBox, getDropdown } from "../ui/utils/gen_utils";
import {isAuth} from "../../helpers/auth";
import {toast} from "react-toastify";
import {getTemplatesBySubjectId} from "./templates";
import { NB_STATES, NB_ACTION, NB_COMPLEXITY, getInitialSample, getTemplateTypes, getSubTasks, 
    getTaskComplexities, getTaskSummaries, isDisabled, getFirstTemplateId } from './nb_utils';
import { getRandomCoeffs, getTaskSample, getTaskAnswer, getTemplateWithVarNames, 
    drawFormula, randomIntFromRange } from './task_utils';
import "../template/Dashboard/Dashboard.scss";
import nb from "./Notebooks.module.scss";

const NotebookAddPage = ({currTaskInd, currTask, setNbState, actionType, isEditable,
        isTaskChanged, setIsTaskChanged, forceSaveTask, setForceSaveTask, 
        handleActions, subjectId, isAutoPrompt, setIsAutoPrompt, isDisabledTemplate}) => {
    
    const [templates, setTemplates] = useState([]); //все математические templates
    const [templateTypes, setTemplateTypes] = useState([]); //все группы математических templates, например, 'Сложение чисел', 'Вычитание чисел' и другие
    const [templateTypeId, setTemplateTypeId] = useState(''); //выбранная группа математических templates, например, 'Сложение чисел'
    const [templateId, setTemplateId] = useState(''); //выбранный template из это группы, наппример, 'Сложение целых чисел'
    const [selectedTemplate, setSelectedTemplate] = useState(undefined);
    //
    const [doNextRefresh, setDoNextRefresh] = useState(0);
    const [complexity, setComplexity] = useState(NB_COMPLEXITY.LOW);
    const [groupAmount, setGroupAmount] = useState(1);
    const [teacherDescription, setTeacherDescription] = useState('');
    const [taskSample, setTaskSample] = useState(''); //maybe not needed
    const [taskAnswer, setTaskAnswer] = useState('');

	useEffect(() => {
        if (subjectId === undefined) return;
        const subjectTemplateList = getTemplatesBySubjectId(subjectId);
        const templateTypeList = getTemplateTypes(subjectTemplateList.templateTypes);

        setTemplates(subjectTemplateList.templates);  //save 1st part of template info
        setTemplateTypes(templateTypeList);  //save 2nd part of template info
	},[subjectId]);

	useEffect(() => {
        if (templates.length === 0) return;
        if (actionType !== NB_ACTION.TASK_ADD && actionType !== NB_ACTION.TASK_EDIT) return;
        if (actionType === NB_ACTION.TASK_EDIT && !currTask) return;

        let sample;
        if (actionType === NB_ACTION.TASK_ADD) {
            sample = getInitialSample();
            const _templateTypeId = templateTypes[0].value;
            setTemplateTypeId(_templateTypeId); // при добавлении каждой новой задачи очищаем тип задачи
            setTemplateId(getFirstTemplateId(templates, _templateTypeId));
            toast.info("Установите параметры задачи."); 
        } else if (actionType === NB_ACTION.TASK_EDIT) {
            sample = currTask;
            const template = templates.find(item => item.templateId === sample.templateId);
            setTemplateTypeId(template.templateTypeId); //нашли выбранный template по его Id и оттуда достали templateTypeId (тип задачи)
            setTemplateId(sample.templateId);
        }
        
        setComplexity(sample.complexity);
        setGroupAmount(sample.groupAmount);
        setTeacherDescription(sample.teacherDescription);
	},[actionType, currTask, templates, templateTypes]);

    useEffect(() => {
         setSelectedTemplate(getTemplateWithVarNames(templates, templateId));
    },[templates, templateId]);        

    useEffect(() => {
        if (!selectedTemplate) return;
        if (isAutoPrompt && !teacherDescription)
            setTeacherDescription(selectedTemplate.prompt);
        else if (!isAutoPrompt && teacherDescription === selectedTemplate.prompt)
            setTeacherDescription('');
    },[selectedTemplate, isAutoPrompt, teacherDescription]);

	useEffect(() => {
        if (!selectedTemplate) {
            setTaskSample('');
            setTaskAnswer('');
            return;
        }

        const formulaInd = randomIntFromRange([0, selectedTemplate.formulas.length - 1]); //выбираем случайный номер подзадачи из списка
        const coeffs = getRandomCoeffs(selectedTemplate, formulaInd, complexity);
        const sample = getTaskSample(selectedTemplate, formulaInd, coeffs, true);
        const answer = getTaskAnswer(selectedTemplate, formulaInd, coeffs);

        setTaskSample(sample);
        setTaskAnswer(answer);
	},[selectedTemplate, complexity, doNextRefresh]);
    
	const handleRefresh = () => {
        if (checkTaskAndSubtaskTypes(templateTypeId, templateId))
            setDoNextRefresh(doNextRefresh + 1);
    };

    const checkTaskAndSubtaskTypes = (_templateTypeId, _templateId) => {
        const msg = _templateTypeId === '' ? "Выберите тип задачи." : _templateId === '' ? "Выберите задачу." : '';
        if (msg) {
            toast.warn(msg);
        }
        return !msg;
    };
    
	const handleSaveChanges = useCallback((isAddNewTask) => {
        //1. Проверить тип задачи и задачу
        if (!checkTaskAndSubtaskTypes(templateTypeId, templateId)) return false;

        //2. сохранить текущую задачу
        const sample = {
            templateId: templateId,
            complexity: complexity, 
            groupAmount: groupAmount, 
            teacherDescription: teacherDescription,
        };
    
        const act = isAddNewTask ? NB_ACTION.TASK_SAVE_ADD : NB_ACTION.TASK_SAVE_EDIT;
        handleActions({type: act, payload: sample});
        if (isTaskChanged)
            setIsTaskChanged(false);

        return true;
    }, [templateTypeId, templateId, isTaskChanged, complexity, groupAmount, handleActions,
        teacherDescription, setIsTaskChanged]);

    useEffect(() => {
        if (!forceSaveTask) return;
        //хотим перейти на другую страницу - по возможности сохранить изменения и затем перейти
        handleSaveChanges(false);
        setForceSaveTask(false);
	},[handleSaveChanges, forceSaveTask, setForceSaveTask]);

	const handleNextStep = () => {
        if (!isTaskChanged)
            setNbState(NB_STATES.SETTINGS);
        else 
            toast.warn('Завершите редактирование задачи.');
    };

    const doSetIsSampleChanged = value => {setIsTaskChanged(value);};
    const doSetTemplateTypeId = val => { 
        setTemplateTypeId(val);  
        setTemplateId(getFirstTemplateId(templates, val));
        doSetIsSampleChanged(true);
    };
    const doSetTemplateId = val => {setTemplateId(val); doSetIsSampleChanged(true);};
    const doSetComplexity = val => {setComplexity(val); doSetIsSampleChanged(true);};
    const doSetGroupAmount = val => {setGroupAmount(val); doSetIsSampleChanged(true);};
	const handleChangeDescription = val => { setTeacherDescription(val); doSetIsSampleChanged(true);};

    const handDeleteTask = ()  => {
        if (actionType === NB_ACTION.TASK_ADD) {
            toast.warn(currTaskInd === -1 ? 
                "В задании нет задач." : "Завершите редактирование задания."); 
        } else {
            handleActions({type: NB_ACTION.TASK_DELETE, payload: currTaskInd});
        }
    };

    const handleInitializeNewTask = () => {
        handleActions({type: NB_ACTION.TASK_ADD});
    };

    if (actionType === NB_ACTION.UNDEFINED) {
        return (
            <div className={nb.nb__body}>
                <div className={nb.nb__center}>
                    <div className={nb.nb__info}>
                        <div className={nb.nb__info_title}>
                            Для начала работы добавьте страницу
                            <br></br>
                            {"для " + (isAuth().role < 3 ? "задания" : "шаблона")}.
                        </div>
                        <div className={nb.nb__info_description}>Одна страница - одно задание.</div>
                        <div className={nb.nb__info_button}>
                            <Button type="submit" color="primary" size = "extraSmall" onClick={handleInitializeNewTask}>
                                Добавить страницу
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return  (
        <>
            <div className={nb.nb__body}>
                <div className="cor-net">
                    <div className="cor-net__section">
                        <div className="cor-net__title">Задача N{currTaskInd+(currTaskInd === -1 ? 2 : 1)}</div>

                        <div className={"cor-net__row " + isDisabled(isEditable)}>
                            <div className="cor-net__col col-3">
                                <div className="cor-net__label">Выберите тип задачи</div>
                                {getDropdown(templateTypes, templateTypeId, value => doSetTemplateTypeId(value), '', '', 'tsktt01')}
                            </div>

                            <div className="cor-net__col col-3">
                                <div className="cor-net__label">Выберите подтип задачи</div>
                                {getDropdown(getSubTasks(templates, templateTypeId), templateId, value => doSetTemplateId(value), '', '', 'tsktp01')}
                            </div>
                            <div className="cor-net__col col-6">
                                <div className="cor-net__label">Сложность</div>
                                {getDropdown(getTaskComplexities(), complexity, value => doSetComplexity(value), '', '', 'tskcompl01')}
                            </div>
                            <div className="cor-net__col col-6">
                                <div className="cor-net__label">Количество примеров</div>
                                {getDropdown(getTaskSummaries(), groupAmount, value => doSetGroupAmount(value), '', '', 'tsksum01')}
                            </div>
                        </div>

                        <div className="cor-net__row">
                            <div className="cor-net__col col-flex">
                                <div className={"cor-net__row " + isDisabled(isEditable)}>
                                    {getNbCheckBox(isAutoPrompt, setIsAutoPrompt, 'autoPrompt', 'Автоподсказка')}
                                    <Tooltip position="right">Автоподсказка</Tooltip>
                                </div>
                            </div> 
                        </div>

                        <div className={"cor-net__row " + isDisabled(isEditable)}>
                            <div className="cor-net__col col-1">
                                <div className="cor-net__label">Текстовое описание задания</div>
                                <textarea 
                                    className={nb.nb__taResizeDisable} autoComplete="off" value={teacherDescription} 
                                    onChange={e => handleChangeDescription(e.target.value)}
                                    name="description" rows="4" 
                                    placeholder="Тут текст подсказки (будет появляться автоматически + учитель может ее доработать, если захочет)" 
                                />
                            </div>
                        </div>

                        <div className="cor-net__row">
                            <div className="cor-net__col col-2">
                                <div className="cor-net__label">Пример задания</div>
                                <div className={nb.nb__example}>
                                    {drawFormula(selectedTemplate, taskSample).map(item => item)}
                                </div>
                            </div>

                            <div className="cor-net__col col-2">
                                <div className="cor-net__label">Правильный ответ</div>
                                <div>{!!taskAnswer && taskAnswer.map(item => item)}</div>
                            </div>
                        </div>

                        {!isDisabledTemplate &&
                        <div className={"cor-net__row"}>
                            <div className="cor-net__reload" onClick={handleRefresh}>
                                <Icon name={'reload'}/>
                                <span>Обновить пример задачи</span>  
                            </div>
                        </div>
                        }
                    </div>
                </div>

                {!isDisabledTemplate &&
                <div className={nb.nb__action}>
                    <Button color="primary" disabled={!isEditable} className={nb.nb__action_btnIcon}
                        size = "extraSmall" onClick={() => handleSaveChanges(true)}>
                        <Icon name="plus_bold"/>
                        <span>Сохранить и создать новую задачу</span>
                    </Button>

                    <Button color="secondary" size = "extraSmall" 
                        onClick={() => handleSaveChanges(false)}  disabled={!isEditable}>
                        Сохранить задачу
                    </Button>

                    <Button disabled={!isEditable} size="extraSmall" color="primary" border={true} 
                        onClick={handDeleteTask}>
                        Удалить задачу
                    </Button> 
                </div>
                }
            </div>

            <div className={nb.nb__footer}>
                <div className={nb.nb__footer_row}>
                    <div className={nb.nb__footer_col}></div>
                    <div className={nb.nb__footer_col}>
                        <Button color="primary" size = "medium" onClick={handleNextStep} >
                            {!isDisabledTemplate ? 'Завершить и перейти к настройкам' : 'Перейти к настройкам'}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NotebookAddPage;
