// import {  } from '../../actions/';
import { getDevicesList } from '../../../commands/device';

export const mapStateToProps = (nstate) => {
    return {
        devices: nstate.ndevice.all,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    getDevicesList: (fn) => dispatch(getDevicesList(fn)),
});
