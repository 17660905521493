//ОБРАЗЕЦ ---- https://www.geogebra.org/m/ex7UGVQM
import React, {useState, useEffect, useRef} from "react";
import {CALC_MODEL, SCALEPOS, EXP_STATE, SAVE_DATA_TYPE, GraphicOpts, ELDYN_EXP_TYPE, DEMO_OPTION, 
	capacitanceProcess, inductanceProcess, oscillateProcces, resonanceProcess, getInputRange,
	getSvgWithHeightWidth, getGrid, getMinMax, getTimeUnit, getInnerBorder, getCurve, getSvgRect, hasNan,
	getScaleData, getScaleValues, getStrokes, getCircle, getScaleTitle, getTimeStringByTime,
	getBorder, getColoredInnerBox, getScaleLine, getScreenAreaPictureById, printDiv, getExportCB, 
	getPlayPauseExperiment,	getSvgMouseCrossCursor, checkRange, getHeaderBox, getModeColorOpts, 
	addCalcModelInfo, getCalcModelInfoById, canStartAction, completeAction, clearTimer} from "./cm_utils";
import { getDropdown, getRoundValue } from "../ui/utils/gen_utils";
import {isAuth} from "../../helpers/auth";
import { saveExcel } from './excel_utils';
import SaveXlsFileConfirmDialog from "../ui/ModalDialogs/SaveXlsFileConfirmDialog";
import {storageClass} from "../../redux/slices/storage";
import {useSelector, useDispatch} from "react-redux";
import {reloadOption} from "../../redux/slices/storage";
import {getCapacitorSchemaOff,  getCapacitorSchemaOn, getInductanceSchemaOff, getInductanceSchemaOn,
	getOscCircuitOff, getOscCircuitOn, getResonance} from './schema_utils';
import { Icon } from '../ui';
import {toast} from "react-toastify";
import "./CalcModel.scss";
import { Content, ContentBody, ContentFooter } from "../template/ContentParts";

const TIME_UNIT_MS = 2;
const FREQUENCY_IN_MSEC = 100;
const MAX_EXPERIMENT_ARRAY_SIZE = 500;
const DEBOUNCE_PERIOD = 250;

const VOLTAGE_INPUT_RANGE = [[0, 1, 0.5], [1, 10, 5], [10, 100, 50]]; //the same for all
const CAPACITANCE_INPUT_RANGE = [1, 500, 100, 'мкФ'];  	//the same for all
const RESISTANCE_INPUT_RANGE = [10, 1000, 10, 'Ом'];	//the same for all
const FREQUENCY_INPUT_RANGE = [10, 500, 100, 'кГц']; // Частота 10кГц - 500кГц Значение по умолчанию - 100кГц - only for Resonance
const COIL_RESISTANCE_INPUT_RANGE = [10, 500, 100, 'мОм']; // Сопротивление катушки 10мОм - 500мОм Значение по умолчанию - 100мОм - only for Resonance
const INDUCTANCE_INPUT_RANGE = [0.1, 100, 50, 'мГн']; //default 1
const resINDUCTANCE_INPUT_RANGE = [0.1, 10, 1, 'Гн']; // Индуктивность 0,1Гн - 10Гн Значение по умолчанию - 1Гн

const voltageRangeOptions = [
	{label: 'Диапазон [0-1] вольт', value: 0},
	{label: 'Диапазон [1-10] вольт', value: 1},
	{label: 'Диапазон [10-100] вольт', value: 2},
];

const experimentOptions = [
	{label: 'Конденсатор', value: ELDYN_EXP_TYPE.CAPACITOR},
	{label: 'Индуктивность', value: ELDYN_EXP_TYPE.INDUCTANCE},
	{label: 'Колебательный контур', value: ELDYN_EXP_TYPE.OSC_CIRCUIT},
];

const demoModeOptions = [ 
	{label: 'Реальное время', value: DEMO_OPTION.REAL_TIME},
	{label: 'Показать за 10 сек', value: DEMO_OPTION.SPEED_10SEC},
	{label: 'Показать за 30 сек', value: DEMO_OPTION.SPEED_30SEC},
];

const Electrodynamics = ({history, match, initExperimentType , isInnerCM, isLightMode }) => {
	const [experimentType, setExperimentType] = useState(initExperimentType);
	const [demoMode, setDemoMode] = useState(DEMO_OPTION.REAL_TIME);

	const [voltageRangeOption, setVoltageRangeOption] = useState(1);
	const [isCurrentEnabled, setIsCurrentEnabled] = useState(false);
	const [voltage, setVoltage] = useState(VOLTAGE_INPUT_RANGE[1][2]);
	const [capacitance, setCapacitance] = useState(CAPACITANCE_INPUT_RANGE[2]);
	const [inductance, setInductance] = useState(undefined); //set in useEffect when experimentType changes
	const [resistance, setResistance] = useState(RESISTANCE_INPUT_RANGE[2]);

	const [voltageList, setVoltageList] = useState([]);
	const [currentList, setCurrentList] = useState([]);
	const [timeUnit, setTimeUnit] = useState(1);

	const [scaleRangeData, setScaleRangeData] = useState(undefined);
	const [svgInfo, setSvgInfo] = useState(undefined); //preliminary svg info
	const [svgArr, setSvgArr] = useState([]); //svg to show
	const [svgWidth, setSvgWidth] = useState(undefined);
	const [svgHeight, setSvgHeight] = useState(undefined);
	const [schemaBoxSize, setSchemaBoxSize] = useState([0, 0]);
	const [cursorX, setCursorX] = useState(-1);
	const [cursorY, setCursorY] = useState(-1);
	const [isReadyToSaveExperiment, setIsReadyToSaveExperiment] = useState(false);
	const [experimentResults, setExperimentResults] = useState([]);
	const [svg, setSvg] = useState(undefined);
	const [refreshCount, setRefreshCount] = useState(0);
	const [timerId, setTimerId] = useState(undefined);
	const [currProgressInSec, setCurrProgressInSec] = useState(0);
	const [makePrint, setMakePrint] = useState(false);
	const [makeXls, setMakeXls] = useState(false);
	const [isLightModeChanged, setIsLightModeChanged] = useState(false);
	const [saveDataType, setSaveDataType] = useState(SAVE_DATA_TYPE.XLS);
	//Resonanse only:
	const [coilResistance, setCoilResistance] = useState(50);  //only for Resonance
	const [frequency, setFrequency] = useState(100); //only for Resonance
	const [voltResistorList, setVoltResistorList] = useState([]);
	const [voltInducorList, setVoltInducorList] = useState([]);
	const [voltCapacitorList, setVoltCapacitorList] = useState([]);
	//
	const [resize, setResize] = useState(0);
	const [showConfirmSaveXlsDlg, setShowConfirmSaveXlsDlg] = useState(false);
	//
	const [expState, setExpState] = useState(EXP_STATE.NOT_STARTED);
	const [played, setPlay] = useState(false);
	const [paused, setPaused] = useState(false);
	const [startPlay, setStartPlay] = useState(false);

    const documentClass = useSelector(storageClass);
	const dispatch = useDispatch();
	const refMainBox = useRef();
	const refMainSvg = useRef();
	const voltage0Ref = useRef();
	const voltage1Ref = useRef();
	const voltage2Ref = useRef();
	const resistanceRef = useRef();
	const inductanceRef = useRef();
	const capacitanceRef = useRef();
	const frequencyRef = useRef();
	const coilResistanceRef = useRef();

	const debounceVoltageInProgress = useRef();
	const debounceResistanceInProgress = useRef();
	const debounceInductanceInProgress = useRef();
	const debounceCapacitanceInProgress = useRef();
	const debounceFrequencyInProgress = useRef();
	const debounceCoilResistanceInProgress = useRef();

	const resonOpts = GraphicOpts;
	
	//const handleMouseMoveEvent = (event) => {		setCursorX(event.clientX);		setCursorY(event.clientY);	};
	const handleMouseClickEvent = (event, _scaleRangeData, _cursorX, _cursorY) => {
		const rect = getSvgRect();
		if (!rect || !_scaleRangeData) return;
		if (event.clientX < rect.left + _scaleRangeData.left || event.clientX > rect.right - _scaleRangeData.right || 
			event.clientY < rect.top + _scaleRangeData.top || event.clientY > rect.bottom - _scaleRangeData.bottom) {
				return;
		}
		if (_cursorX !== event.clientX || _cursorY !== event.clientY) {
			setCursorX(event.clientX);
			setCursorY(event.clientY);
		}
	};

	useEffect(() => {
		if (match.params.opt) {
			getCalcModelInfoById(match.params.opt).then((data) => {
				const cmInfoId = data[0].options;
				setVoltageRangeOption(cmInfoId.voltageRangeOption);
				setIsCurrentEnabled(cmInfoId.isCurrentEnabled);
				setVoltage(cmInfoId.voltage);
				setCapacitance(cmInfoId.capacitance);
				setInductance(cmInfoId.inductance);
				setResistance(cmInfoId.resistance);
				setCoilResistance(cmInfoId.coilResistance);
				setFrequency(cmInfoId.frequency);
			});
		}
	}, [match.params.opt]);

	useEffect(() => {
		const handleResize = e => setResize(e[0].contentBoxSize[0].inlineSize);
		const observer = new ResizeObserver(handleResize);
		observer.observe(refMainBox.current);
		return () => {
			observer.disconnect();
		};
	}, []);

	useEffect(() => {
		//set svg box size:
		const svgR = document.getElementById('mainSvg')?.getBoundingClientRect();
		const innerMainR = document.getElementById('idInnerMainBox')?.getBoundingClientRect();
		const commonR = document.getElementById('idCommon')?.getBoundingClientRect();
		const width = (innerMainR.width - (svgR.left - innerMainR.left));
		if (innerMainR.height < commonR.height) return;
		const height = innerMainR.height - commonR.height;
		setSvgWidth(width);
		setSvgHeight(height);

		const comboBoxesR = document.getElementById('idComboBoxes')?.getBoundingClientRect();
		if (!!comboBoxesR) 
			setSchemaBoxSize([comboBoxesR.width, comboBoxesR.height]);
	}, [resize]);

	useEffect(() => {
		setInductance(getInductanceRange(experimentType)[2]);
	}, [experimentType]);

	const getInductanceRange = (_experimentType) => {
		return _experimentType !== ELDYN_EXP_TYPE.RESONANCE ? INDUCTANCE_INPUT_RANGE : resINDUCTANCE_INPUT_RANGE;
	};

	useEffect(() => {
		const svgBox = document.getElementById('mainSvg');
		svgBox?.addEventListener("click", e => handleMouseClickEvent(e, scaleRangeData, cursorX, cursorY));
		return () => {
			const elem = document.getElementById('mainSvg');
			elem?.removeEventListener("click", handleMouseClickEvent);
		};
	}, [cursorX, cursorY, scaleRangeData]);

	useEffect(() => { //get data for expType
		if (!voltage || !capacitance || !inductance || !resistance) return;
		if (experimentType === ELDYN_EXP_TYPE.RESONANCE) return;

		const _voltageList = [];
		const _currentList = [];
		let _timeOfCharge;
		if (experimentType === ELDYN_EXP_TYPE.CAPACITOR) {
			_timeOfCharge = capacitanceProcess(resistance, voltage, capacitance, isCurrentEnabled, _voltageList, _currentList);
		} else if (experimentType === ELDYN_EXP_TYPE.INDUCTANCE) {
			_timeOfCharge = inductanceProcess(resistance, voltage, inductance, isCurrentEnabled, _voltageList, _currentList);
		} else if (experimentType === ELDYN_EXP_TYPE.OSC_CIRCUIT) {
			if (isCurrentEnabled)
				_timeOfCharge = capacitanceProcess(resistance, voltage, capacitance, isCurrentEnabled, _voltageList, _currentList);
			else {
				const res = oscillateProcces(voltage, capacitance, inductance, resistance, _voltageList, _currentList);
				_timeOfCharge = res[0];
			}
		}

		let coef = 1;
		if (_timeOfCharge < 0.1) {coef = 1000; setTimeUnit(TIME_UNIT_MS);}
		//setTimeOfCharge(_timeOfCharge * coef); 
		setVoltageList(_voltageList.map(item => [item[0] * coef, item[1]]));
		setCurrentList(_currentList.map(item => [item[0] * coef, item[1]]));
	}, [experimentType, capacitance, isCurrentEnabled, resistance, voltage, inductance]);

	useEffect(() => {
		if (experimentType !== ELDYN_EXP_TYPE.RESONANCE) return;
		const voltResistor = [];
		const voltInducor = [];
		const voltCapacitor = [];

		resonanceProcess(frequency, resistance, capacitance, inductance, coilResistance, voltage, 
			voltResistor, voltInducor, voltCapacitor);

		setVoltResistorList(voltResistor);
		setVoltInducorList(voltInducor);
		setVoltCapacitorList(voltCapacitor);
	}, [experimentType, capacitance, coilResistance, resistance, frequency, inductance, voltage]);

	const getCoordInfo = (infoType, _svgSize, _scaleRangeData, _cursor) => {
		if (_cursor === -1 || !_scaleRangeData) return 0;

		const rect = getSvgRect();
		if (infoType === 'time') {
			const range = _scaleRangeData.timeRange;
			const innerBoxSize = _svgSize - (_scaleRangeData.left + _scaleRangeData.right);
			const coef = (range[1] - range[0]) / innerBoxSize;
			const diff = rect.left + _scaleRangeData.left;
			return range[0] + (_cursor - diff) * coef;
			}
		else {
			const range = infoType === 'volt' ? _scaleRangeData.voltageRange : _scaleRangeData.currentRange;
			const innerBoxSize = _svgSize - (_scaleRangeData.top + _scaleRangeData.bottom);
			const coef = (range[1] - range[0]) / innerBoxSize;
			const diff = rect.top + _scaleRangeData.top;
			return range[1] - (_cursor - diff) * coef;
		}
	};

	const getCurrentVoltageGraphics = (_svgWidth, _svgHeight, _voltageList, _currentList,
			_timeUnit, edOpts, _isLightMode) => {
		if (_voltageList.length === 0 || _currentList.length === 0) return null;
		const [timeMin, timeMax] = checkRange(getMinMax(_voltageList.map(item => item[0])));
		const [voltMin, voltMax] = checkRange(getMinMax(_voltageList.map(item => item[1])));
		const [currMin, currMax] = checkRange(getMinMax(_currentList.map(item => item[1])));
		const width = _svgWidth - 2 * edOpts.gapHoriz;
		const height = _svgHeight - edOpts.gapTop - edOpts.gapBottom;

		const _scaleRangeData = {
			timeRange: [timeMin, timeMax],
			voltageRange: [voltMin, voltMax],
			currentRange: [currMin, currMax],
			left: edOpts.gapHoriz, 		//contains left gap
			top: edOpts.gapTop, 		//contains top gap
			right: edOpts.gapHoriz, 	//contains right gap
			bottom: edOpts.gapBottom, 	//contains bottom gap
		};
		setScaleRangeData(_scaleRangeData);

		//1 - boxes:
		const bkndColor = getModeColorOpts(_isLightMode).calcModelBknd;
		const background = getColoredInnerBox(edOpts.gapHoriz, edOpts.gapTop, width, height, 
			bkndColor, bkndColor, edOpts.backgroundOpacity, "bknd01");
	    const extBox = getBorder(_svgWidth, _svgHeight, edOpts.extBoxColor, "rect");
		const innerBox = getInnerBorder(edOpts.gapHoriz, edOpts.gapTop, width, height, edOpts.innerBoxColor, "rect");
		
		//2 - curves:
		const timeList = _voltageList.map(item => edOpts.gapHoriz + width * (Number(item[0]) - Number(timeMin)) / (timeMax - timeMin));
		const voltList = _voltageList.map(item => edOpts.gapTop + height * (Number(voltMax) - Number(item[1])) / (voltMax - voltMin));
		const currList = _currentList.map(item => edOpts.gapTop + height * (Number(currMax) - Number(item[1])) / (currMax - currMin));
		const voltLine = getCurve(timeList, voltList, edOpts.voltageColor, "2", "curve01");
		const currLine = getCurve(timeList, currList, edOpts.currentColor, "2", "curve02");

		//3 - legend:
		const legendLEFT = 2 * edOpts.gapHoriz;
		const legendTOP = edOpts.gapTop + 40;
		const LegendLineWIDTH = 12;
		const vert = 15;
		const shiftVert = legendTOP + vert;

		const legendBackground = getColoredInnerBox(legendLEFT-10, legendTOP - 10, 8.5 * LegendLineWIDTH, 2.5 * vert, 
			getModeColorOpts(_isLightMode).elDynLegendBkndColor, edOpts.elDynLegendBorderColor, edOpts.elDynLegendOpacity, "bknd01");
		const voltageLegendLine = getCircle(legendLEFT, legendTOP, edOpts.legendCircleRadius, edOpts.voltageColor, edOpts.voltageColor, 0.9, 1, "circle011");
		const voltageLegendText = getScaleTitle(SCALEPOS.horiz, legendLEFT + LegendLineWIDTH, 
			legendTOP+4, "Напряжение", edOpts.voltageColor, "leftkey11");
		const currentLegendLine = getCircle(legendLEFT, shiftVert, edOpts.legendCircleRadius, edOpts.currentColor, edOpts.currentColor, 0.9, 1, "circle012");
		const currentLegendText = getScaleTitle(SCALEPOS.horiz, legendLEFT + LegendLineWIDTH, 
			shiftVert+4, "Ток", edOpts.currentColor, "rkey11");

		//4 - axis titles:
		const horiz = edOpts.gapHoriz * 0.2;
		const leftAxisTitle = getScaleTitle(SCALEPOS.left, horiz + 2, _svgHeight * 0.6, 
			"Напряжение, В", edOpts.voltageColor, "leftkey1");
		const rightAxisTitle = getScaleTitle(SCALEPOS.right, _svgWidth-horiz+3, _svgHeight * 0.57, 
			"Сила тока, А", edOpts.currentColor, "rightkey1");
		const bottomAxisTitle = getScaleTitle(SCALEPOS.horiz, edOpts.gapHoriz + 0.43 *_svgWidth, _svgHeight-8, 
			"Время, " + getTimeUnit(_timeUnit), edOpts.timeColor, "bottomkey1");
	
		//5 - scales:
		const timeScaleArr = getScaleData(SCALEPOS.horiz, SCALEPOS.left, edOpts.horizStrokeNUM, timeMin, timeMax, edOpts.gapHoriz, 
			width, _svgHeight - edOpts.gapBottom, edOpts.scaleLargeSize, edOpts.scaleSmallSize, edOpts.elDynSmallStrokeNum);
		const timeStrokes = getStrokes(timeScaleArr, edOpts.strokeColor, 1, 0.9, "timekey");
		const timeScaleValues = getScaleValues(timeScaleArr, SCALEPOS.horiz, SCALEPOS.left, edOpts.timeColor, "time1");

		const voltageScaleArr = getScaleData(SCALEPOS.vert, SCALEPOS.left, edOpts.vertStrokeNUM, voltMin, voltMax, 
			edOpts.gapTop, height, edOpts.gapHoriz, edOpts.scaleLargeSize, edOpts.scaleSmallSize, edOpts.elDynSmallStrokeNum);
		const voltageStrokes = getStrokes(voltageScaleArr, edOpts.strokeColor, 1, 0.9, "vkey");
		const voltageScaleValues = getScaleValues(voltageScaleArr, SCALEPOS.vert, SCALEPOS.left, edOpts.voltageColor, "horiz1");

		const currentScaleArr = getScaleData(SCALEPOS.vert, SCALEPOS.right, edOpts.vertStrokeNUM, currMin, currMax, 
			edOpts.gapTop, height, edOpts.gapHoriz+width, edOpts.scaleLargeSize, edOpts.scaleSmallSize, edOpts.elDynSmallStrokeNum);
		const currentStrokes = getStrokes(currentScaleArr, edOpts.strokeColor, 1, 0.9, "ckey");
		const currentScaleValues = getScaleValues(currentScaleArr, SCALEPOS.vert, SCALEPOS.right, edOpts.currentColor, "vert1");
		
		//6 - grid:
		const timeGrid = getGrid(timeScaleArr, SCALEPOS.horiz, edOpts.gapHoriz, edOpts.gapTop, width, height, getModeColorOpts(_isLightMode).gridColor, edOpts.gridWidth, edOpts.gridOpacity);
		const voltageGrid = getGrid(voltageScaleArr, SCALEPOS.vert, edOpts.gapHoriz, edOpts.gapTop, width, height, getModeColorOpts(_isLightMode).gridColor, edOpts.gridWidth, edOpts.gridOpacity);

		const _svgArr = [background, leftAxisTitle, rightAxisTitle, bottomAxisTitle, 
			timeStrokes, voltageStrokes, currentStrokes, 
			...timeScaleValues, ...voltageScaleValues, ...currentScaleValues, 
			timeGrid, voltageGrid, 
			extBox, innerBox];
		const legend = [legendBackground, voltageLegendLine, currentLegendLine, voltageLegendText, currentLegendText];
		const _svgInfo = {
			grid: _svgArr,
			voltLine: voltLine,
			currLine: currLine,
			legend: legend,
			timeList: timeList,
			voltList: voltList,
			currList: currList,
			voltageColor: edOpts.voltageColor,
			currentColor: edOpts.currentColor,
		};
		return _svgInfo;
	};

	const getResonanceVoltageGraphics = (_svgWidth, _svgHeight, _voltResistorList, _voltInducorList,
		_voltCapacitorList, _timeUnit, rOpts, _setScaleRangeData, _isLightMode) => { //resonOpts
		if (_voltResistorList.length === 0) return null;
		if (!hasNan(_voltResistorList.map(item => item[1]))) return null;
		const [timeMin, timeMax] = checkRange(getMinMax(_voltResistorList.map(item => item[0])));
		const [vResMin, vResMax] = checkRange(getMinMax(_voltResistorList.map(item => item[1])));
		const [vIndMin, vIndMax] = checkRange(getMinMax(_voltInducorList.map(item => item[1])));
		const [vCapMin, vCapMax] = checkRange(getMinMax(_voltCapacitorList.map(item => item[1])));
		//const vMin = Math.min(vResMin, vIndMin, vCapMin); const vMax = Math.max(vResMax, vIndMax, vCapMax);
		const [vMin, vMax] = checkRange([Math.min(vResMin, vIndMin, vCapMin), Math.max(vResMax, vIndMax, vCapMax)]);

		const width = _svgWidth - rOpts.gapHoriz - 5;
		const height = _svgHeight - rOpts.gapTop - rOpts.gapBottom;

		const _scaleRangeData = {
			timeRange: [timeMin, timeMax],
			voltageRange: [vMin, vMax],
			left: rOpts.gapHoriz, 		//contains left gap
			top: rOpts.gapTop, 			//contains top gap
			right: rOpts.gapHoriz, 		//contains right gap
			bottom: rOpts.gapBottom, 	//contains bottom gap
		};
		_setScaleRangeData(_scaleRangeData);

		//1 - boxes:
		const bkndColor = getModeColorOpts(_isLightMode).calcModelBknd;
		const background = getColoredInnerBox(rOpts.gapHoriz, rOpts.gapTop, width, height, bkndColor, 
			bkndColor, rOpts.backgroundOpacity, "bknd01");
		const extBox = getBorder(_svgWidth, _svgHeight, rOpts.extBoxColor, "rect");
		const innerBox = getInnerBorder(rOpts.gapHoriz, rOpts.gapTop, width, height, rOpts.innerBoxColor, "rect");

		//2 - curves:
		const timeList = _voltResistorList.map(item => rOpts.gapHoriz + width * (Number(item[0]) - Number(timeMin)) / (timeMax - timeMin));
		const vResList = _voltResistorList.map(item => rOpts.gapTop + height * (Number(vMax) - Number(item[1])) / (vMax - vMin));
		const vIndList = _voltInducorList.map(item => rOpts.gapTop + height * (Number(vMax) - Number(item[1])) / (vMax - vMin));
		const vCapList = _voltCapacitorList.map(item => rOpts.gapTop + height * (Number(vMax) - Number(item[1])) / (vMax - vMin));

		const vResLine = getCurve(timeList, vResList, rOpts.voltResColor, "2", "curve01");
		const vIndLine = getCurve(timeList, vIndList, rOpts.voltIndColor, "2", "curve02");
		const vCapLine = getCurve(timeList, vCapList, rOpts.voltCapColor, "2", "curve03");

		//3 - legend:
		const legendLEFT = 2 * rOpts.gapHoriz;
		const legendTOP = rOpts.gapTop + 40;
		const LegendLineWIDTH = 15;
		const vShift = 10;

		const legendBackground = getColoredInnerBox(legendLEFT-10, legendTOP - 10, 9.5 * LegendLineWIDTH, 4.2 * vShift, 
			getModeColorOpts(_isLightMode).elDynLegendBkndColor, rOpts.elDynLegendBorderColor, rOpts.elDynLegendOpacity, "bknd01");
		const vResLegendLine = getScaleLine(legendLEFT, legendTOP+0*vShift, LegendLineWIDTH, 1, rOpts.voltResColor, "vr1");
		const vResLegendText = getScaleTitle(SCALEPOS.horiz, legendLEFT + LegendLineWIDTH + 3, legendTOP+0*vShift+4, "U резистор", rOpts.voltResColor, "ures1");
		const vIndLegendLine = getScaleLine(legendLEFT, legendTOP+1*vShift, LegendLineWIDTH, 1, rOpts.voltIndColor, "vr2");
		const vIndLegendText = getScaleTitle(SCALEPOS.horiz, legendLEFT + LegendLineWIDTH + 3, legendTOP+1*vShift+4, "U конденсатор", rOpts.voltIndColor, "uind1");
		const vCapLegendLine = getScaleLine(legendLEFT, legendTOP+2*vShift, LegendLineWIDTH, 1, rOpts.voltCapColor, "vr3");
		const vCapLegendText = getScaleTitle(SCALEPOS.horiz, legendLEFT + LegendLineWIDTH + 3, legendTOP+2*vShift+4, "U индуктивность", rOpts.voltCapColor, "ucap1");

		//4 - axis titles:
		const borderTitleLEFT = rOpts.gapHoriz * 0.2;
		const bottomAxisTitle = getScaleTitle(SCALEPOS.horiz, _svgWidth * 0.45, _svgHeight-8, 
			"Время, " + getTimeUnit(_timeUnit), rOpts.timeColor, "bottomkey1");
		const leftAxisTitle = getScaleTitle(SCALEPOS.left, borderTitleLEFT + 2, _svgHeight * 0.7, 
			"Напряжение, В", rOpts.timeColor, "leftkey1");
	
		//5 - scales:
		const timeScaleArr = getScaleData(SCALEPOS.horiz, SCALEPOS.left, rOpts.horizStrokeNUM, timeMin, timeMax, 
			rOpts.gapHoriz, width, 
			_svgHeight - rOpts.gapBottom, rOpts.scaleLargeSize, rOpts.scaleSmallSize, rOpts.elDynSmallStrokeNum);
		const timeStrokes = getStrokes(timeScaleArr, rOpts.timeColor, 1, 0.9, "tkey");
		const timeScaleValues = getScaleValues(timeScaleArr, SCALEPOS.horiz, SCALEPOS.left, rOpts.timeColor, "time2");

		const voltageScaleArr = getScaleData(SCALEPOS.vert, SCALEPOS.left, rOpts.vertStrokeNUM, vMin, vMax, rOpts.gapTop, height, 
			rOpts.gapHoriz, rOpts.scaleLargeSize, rOpts.scaleSmallSize, rOpts.elDynSmallStrokeNum);
		const voltageStrokes = getStrokes(voltageScaleArr, rOpts.timeColor, 1, 0.9, "vkey");
		const voltageScaleValues = getScaleValues(voltageScaleArr, SCALEPOS.vert, SCALEPOS.left, rOpts.timeColor, "vert2");
	
		//6 - grid:
		const timeGrid = getGrid(timeScaleArr, SCALEPOS.horiz, rOpts.gapHoriz, rOpts.gapTop, width, height, getModeColorOpts(_isLightMode).gridColor, rOpts.gridWidth, rOpts.gridOpacity);
		const voltageGrid = getGrid(voltageScaleArr, SCALEPOS.vert, rOpts.gapHoriz, rOpts.gapTop, width, height, getModeColorOpts(_isLightMode).gridColor, rOpts.gridWidth, rOpts.gridOpacity);

		const _svgArr = [background, leftAxisTitle, bottomAxisTitle, 
						timeStrokes, voltageStrokes, 
						...timeScaleValues, ...voltageScaleValues, 
						timeGrid, voltageGrid, 
						extBox, innerBox];
		const legend = [legendBackground, vResLegendLine, vIndLegendLine, vCapLegendLine, 
						vResLegendText, vIndLegendText, vCapLegendText];
		const _svgInfo = {
			grid: _svgArr,
			legend: legend,
			timeList: timeList,
			vResList: vResList,
			vIndList: vIndList,
			vCapList: vCapList,
			voltResColor: rOpts.voltResColor,
			voltIndColor: rOpts.voltIndColor,
			voltCapColor: rOpts.voltCapColor,
			vResLine: vResLine,
			vIndLine: vIndLine,
			vCapLine: vCapLine,
		};
		return _svgInfo;
	};

	useEffect(() => { //prepare preliminary svg info
		//https://www.w3schools.com/graphics/svg_stroking.asp - svg options
		if (!svgWidth || !svgHeight) return;
		let _svgInfo;
	
		if (experimentType !== ELDYN_EXP_TYPE.RESONANCE)
			_svgInfo = getCurrentVoltageGraphics(svgWidth, svgHeight, voltageList, currentList, timeUnit, GraphicOpts, isLightMode);
		else 
			_svgInfo = getResonanceVoltageGraphics(svgWidth, svgHeight, voltResistorList, voltInducorList, 
						voltCapacitorList, timeUnit, resonOpts, setScaleRangeData, isLightMode);

		setSvgInfo(_svgInfo);
	}, [voltageList, currentList, svgHeight, svgWidth, timeUnit, experimentType, 
		voltResistorList, voltInducorList, voltCapacitorList, resonOpts, isLightMode]);

	const getDemoModeStepNumber = _demoMode => {
		return (_demoMode === DEMO_OPTION.SPEED_10SEC ? 100 : 300);
	};
	
	const getDemoModeStep = _demoMode => {
		return 1. / (_demoMode === DEMO_OPTION.SPEED_10SEC ? 100 : 300);
	};
	
	useEffect(() => { //prepare final svg data to show FOR ALL except ELDYN_EXP_TYPE.RESONANCE
		const getLines = (_demoMode, _refreshCount, _expState, _svgInfo) => {
			if (_demoMode === DEMO_OPTION.REAL_TIME) return [_svgInfo.voltLine, _svgInfo.currLine];
			
			const speedStep = getDemoModeStep(demoMode);
			const portion = 0. + _svgInfo.timeList.length * speedStep;
			const currNum = portion * _refreshCount;
			const _timeList = _svgInfo.timeList.filter((item, ind) => ind <= currNum);
			const _voltList = _svgInfo.voltList.filter((item, ind) => ind <= currNum);
			const _currList = _svgInfo.currList.filter((item, ind) => ind <= currNum);
			const voltLine = getCurve(_timeList, _voltList, _svgInfo.voltageColor, "2", "curve01");
			const currLine = getCurve(_timeList, _currList, _svgInfo.currentColor, "2", "curve02");
			return [voltLine, currLine];
		};
		
		if (!svgInfo || experimentType === ELDYN_EXP_TYPE.RESONANCE) return;

		const [voltLine, currLine] = getLines(demoMode, refreshCount, expState, svgInfo);
		const svgData = [].concat(svgInfo.grid, voltLine, currLine, svgInfo.legend, getSvgMouseCrossCursor(cursorX, cursorY));
		setSvgArr(svgData);
	}, [experimentType, svgInfo, refreshCount, cursorX, cursorY, demoMode, expState]);

	useEffect(() => { //prepare final svg data to show ONLY FOR ELDYN_EXP_TYPE.RESONANCE
		const getLines = (_demoMode, _refreshCount, _expState, _svgInfo) => {
			if (_demoMode === DEMO_OPTION.REAL_TIME) return [_svgInfo.vResLine, _svgInfo.vIndLine, _svgInfo.vCapLine];
			
			const speedStep = getDemoModeStep(demoMode);
			const portion = 0. + _svgInfo.timeList.length * speedStep;

			const currNum = portion * _refreshCount;
			const _timeList = _svgInfo.timeList.filter((item, ind) => ind <= currNum);
			const _vResList = _svgInfo.vResList.filter((item, ind) => ind <= currNum);
			const _vIndList = _svgInfo.vIndList.filter((item, ind) => ind <= currNum);
			const _vCapList = _svgInfo.vCapList.filter((item, ind) => ind <= currNum);
			const vResLine = getCurve(_timeList, _vResList, _svgInfo.voltResColor, "2", "curve01");
			const vIndLine = getCurve(_timeList, _vIndList, _svgInfo.voltIndColor, "2", "curve02");
			const vCapLine = getCurve(_timeList, _vCapList, _svgInfo.voltCapColor, "2", "curve03");
			return [vResLine, vIndLine, vCapLine];
		};
		
		if (!svgInfo || experimentType !== ELDYN_EXP_TYPE.RESONANCE) return;

		const [vResLine, vIndLine, vCapLine] = getLines(demoMode, refreshCount, expState, svgInfo);
		const svgData = [].concat(svgInfo.grid, vResLine, vIndLine, vCapLine, svgInfo.legend, getSvgMouseCrossCursor(cursorX, cursorY)); 
		setSvgArr(svgData);
	}, [experimentType, svgInfo, refreshCount, cursorX, cursorY, demoMode, expState]);

	const getSvg = (obj) => {
		return getSvgWithHeightWidth(svgHeight, svgWidth, obj, 'svg1');
	};

	const workProgress = () => { 
		setRefreshCount(refreshCount => refreshCount + 1);
	};

	useEffect(() => {
		setCurrProgressInSec(getTimeStringByTime(FREQUENCY_IN_MSEC / 1000. * refreshCount));

		if (refreshCount >= getDemoModeStepNumber(demoMode)) {
			setExpState(EXP_STATE.FINISHED);
			clearTimer(timerId, setTimerId);
			setPlay(false);
		}
	}, [demoMode, refreshCount, timerId]);

	const getExperimentName = (_experimentType) => {
		if (_experimentType === ELDYN_EXP_TYPE.CAPACITOR) 
			return 'Конденсатор';
		else if (_experimentType === ELDYN_EXP_TYPE.INDUCTANCE) 
			return 'Индуктивность';
		else if (_experimentType === ELDYN_EXP_TYPE.OSC_CIRCUIT) 
			return 'Колебательный контур';
		else //_experimentType === ELDYN_EXP_TYPE.RESONANCE
			return 'Резонанс';
	};

	const getXlsxFileName = (_experimentType) => {
		return (_experimentType !== ELDYN_EXP_TYPE.RESONANCE ? 'Электродинамика. ' + getExperimentName(_experimentType) : 'Резонанс');
	};

	const getFilteredArray = arr => {
		const arrLength = arr.length;
		if (arrLength < MAX_EXPERIMENT_ARRAY_SIZE) return arr;
		const filterStep = Math.floor(arrLength / MAX_EXPERIMENT_ARRAY_SIZE);
		const res = [];
		for (let i = 0; i < MAX_EXPERIMENT_ARRAY_SIZE; i ++) {
			const n = i * filterStep;
			res.push(arr[n]);
		}
		return res;
	};

	const saveExport = (name, description) => {
        if (saveDataType === SAVE_DATA_TYPE.PROFILE) {
            saveProfile(name, description);
        } else {
            saveXLSX(name, description);
        }
    };

	const saveProfile = async (name, description) => {
		const options = {
			voltageRangeOption: voltageRangeOption,
			isCurrentEnabled: isCurrentEnabled, //b
			voltage: voltage, 
			capacitance: capacitance, 
			inductance: inductance, 
			resistance: resistance, 
			coilResistance: coilResistance, 
			frequency: frequency, 
		};
	
		const cmType = experimentType === ELDYN_EXP_TYPE.CAPACITOR ? CALC_MODEL.CAPACITOR : 
					   experimentType === ELDYN_EXP_TYPE.INDUCTANCE ? CALC_MODEL.INDUCTANCE :
					   experimentType === ELDYN_EXP_TYPE.OSC_CIRCUIT ? CALC_MODEL.OSC_CIRCUIT : CALC_MODEL.RESONANCE;

        const profile = {
            owner: isAuth()._id,
			room: documentClass._id,
            cmType: cmType,
            name: name,
            description: description,
			options: options,
        };

        await addCalcModelInfo(profile);
    };

	const saveXLSX = (workBookName, description) => {
		const _experimentResults = experimentResults;
		const getTableColumns = (_experimentType) => {
			if (_experimentType !== ELDYN_EXP_TYPE.RESONANCE)
				return [
					{ header: 'Описание эксперимента', key: 'description' },
					{ header: 'Входное апряжение, В', key: 'voltage' },
					{ header: 'Емкость, мкФ', key: 'capacitance'},
					{ header: 'Индуктивность, мГн', key: 'inductance' },
					{ header: 'Сопротивление, Ом', key: 'resistance' },
					{ header: 'Источник питания', key: 'isCurrentEnabled' },
					{ header: 'Время, сек', key: 'timeList' },
					{ header: 'Напряжение, В', key: 'voltageList' },
					{ header: 'Ток, А ', key: 'currentList' },
				];
			return [
				{ header: 'Описание эксперимента', key: 'description' },
				{ header: 'Входное апряжение, В', key: 'voltage' },
				{ header: 'Емкость, мкФ', key: 'capacitance'},
				{ header: 'Частота, кГц', key: 'frequency'},
				{ header: 'Индуктивность, Гн', key: 'inductance' },
				{ header: 'Сопротивление, Ом', key: 'resistance' },
				{ header: 'Сопротивление катушки, мОм', key: 'coilResistance' },
				{ header: 'Время, сек', key: 'timeList' },
				{ header: 'Резистор, В', key: 'vResList' },
				{ header: 'Индуктивность, В', key: 'vIndList' },
				{ header: 'Конденсатор, В', key: 'vCapList' },
			];
		};
		const getTableData = (experiment) => {
			const tableData = [];

			if (experiment.experimentType !== ELDYN_EXP_TYPE.RESONANCE) {
				for (let i = 0; i < experiment.timeList.length; i++) {
					const currPower = getRoundValue(experiment.currentList[i], 2);
					const record = {
						description: i === 0 ? description : '', 
						voltage: i === 0 ? getRoundValue(experiment.voltage, 2) : '',
						capacitance: i === 0 ? getRoundValue(experiment.capacitance, 2) : '',
						inductance : i === 0 ? getRoundValue(experiment.inductance, 2) : '',
						resistance: i === 0 ? getRoundValue(experiment.resistance, 2) : '',
						isCurrentEnabled: i === 0 ? (experiment.isCurrentEnabled ? 'включен' : 'выключен'): '',
						
						timeList: getRoundValue(experiment.timeList[i], 3),
						voltageList: getRoundValue(experiment.voltageList[i], 2),
						currentList: Math.abs(currPower) > 0.0000001 ? currPower : 0, 
					};
					tableData.push(record);
				}
				return tableData;
			}

			for (let i = 0; i < experiment.timeList.length; i++) {
				const record = {
					description: i === 0 ? description : '', 
					voltage: i === 0 ? getRoundValue(experiment.voltage, 2) : '',
					capacitance: i === 0 ? getRoundValue(experiment.capacitance, 2) : '',
					inductance: i === 0 ? getRoundValue(experiment.inductance, 2) : '',
					frequency:  i === 0 ? getRoundValue(experiment.frequency, 2) : '',
					resistance: i === 0 ? getRoundValue(experiment.resistance, 2) : '',
					coilResistance: i === 0 ? getRoundValue(experiment.coilResistance, 2) : '',

					timeList: getRoundValue(experiment.timeList[i], 4),
					vResList: getRoundValue(experiment.vResList[i], 3),
					vIndList: getRoundValue(experiment.vIndList[i], 3),
					vCapList: getRoundValue(experiment.vCapList[i], 3),
				};
				tableData.push(record);
			}
			return tableData;
		};
		const getGraphicData = (_initExperimentType, experiment) => {
			const colNums = [12, 14, 16, 14];
			return [{
				image: experiment.svg,
				colNum: colNums[_initExperimentType-1],
			}];
		};

		if (_experimentResults.length === 0) return;

		const tableSheets = [];
		for (let i = 0; i < _experimentResults.length; i ++) {
			const expType = _experimentResults[i].experimentType;
			const tableSheet = {
				workSheetName: "" + (i+1) + " - " + getExperimentName(expType),
				columns: getTableColumns(expType),
				tableData: getTableData(_experimentResults[i]),
				graphicData: getGraphicData(expType, _experimentResults[i]),
			};
			tableSheets.push(tableSheet);
		}

		saveExcel(workBookName, tableSheets, []);
		setExperimentResults([]);
	};

	const handleProfile = () => {
		setSaveDataType(SAVE_DATA_TYPE.PROFILE);
        setShowConfirmSaveXlsDlg(true);
	};

	const handleExportExperiment = () => {
		if (expState === EXP_STATE.NOT_STARTED && demoMode !== DEMO_OPTION.REAL_TIME) 
			toast.warn("Начните эксперимент.");
		else {
			setSaveDataType(SAVE_DATA_TYPE.XLS);
			//step 1 of 3: change light mode if needed and request to save svg
			canStartAction(isLightMode, dispatch, reloadOption, setIsLightModeChanged, setMakeXls);
		}
	};

	useEffect(() => {
		//step 2 of 3: save svg and request to calc expResults
		if (!makeXls) return;
		getScreenAreaPictureById("mainSvg", img => {setSvg(img); setIsReadyToSaveExperiment(true);});
		setTimeout(() => completeAction(dispatch, setMakeXls, isLightModeChanged, setIsLightModeChanged, reloadOption), 100);
	}, [dispatch, isLightModeChanged, makeXls]);

	useEffect(() => {
		//step 3 of 3: prepare expResults and run the export dlg
		if (!isReadyToSaveExperiment) return;
		
		let expResult;
		if (experimentType !== ELDYN_EXP_TYPE.RESONANCE)
			expResult = {
				experimentType: experimentType,
				isCurrentEnabled: isCurrentEnabled,
				voltage: voltage,
				capacitance: capacitance, 
				inductance: inductance,
				resistance: resistance,
				timeList: getFilteredArray(voltageList.map(item => item[0])),
				voltageList: getFilteredArray(voltageList.map(item => item[1])),
				currentList: getFilteredArray(currentList.map(item => item[1])),
				svg: svg,
			};
		else {
			expResult = {
				experimentType: experimentType,
				voltage: voltage,
				capacitance: capacitance, 
				inductance: inductance,
				resistance: resistance,
				frequency: frequency,
				coilResistance: coilResistance,
				timeList: getFilteredArray(voltResistorList.map(item => item[0])),
				vResList: getFilteredArray(voltResistorList.map(item => item[1])),
				vIndList: getFilteredArray(voltInducorList.map(item => item[1])),
				vCapList: getFilteredArray(voltCapacitorList.map(item => item[1])),
				svg: svg,
			};
		}
		setIsReadyToSaveExperiment(false);
		const exps = experimentResults.map(item => item);
		exps.push(expResult);
		setExperimentResults(exps);
		setShowConfirmSaveXlsDlg(true);
		toast.success("Текущий эксперимент сохранен."); 
	}, [capacitance, coilResistance, currentList, experimentResults, experimentType, frequency, inductance, 
		isCurrentEnabled, isReadyToSaveExperiment, resistance, voltage, svg, 
		voltCapacitorList, voltInducorList, voltResistorList, voltageList]);

	const handleCancelSave = () => {
		setExperimentResults([]);
		setShowConfirmSaveXlsDlg(false);
	};
	
	const handlePrint = () => {
		canStartAction(isLightMode, dispatch, reloadOption, setIsLightModeChanged, setMakePrint);
	};

	useEffect(() => {
		if (!makePrint) return;

		let modelParams;
		if (experimentType === ELDYN_EXP_TYPE.CAPACITOR) //конденсатор
			modelParams  = [
				['Напряжение: ', 'V=' + getRoundValue(voltage, 1) + ' В'],
				['Сопротивление: ', 'R=' + getRoundValue(coilResistance, 0) + ' Ом'],
				['Емкость: ', 'C=' + getRoundValue(capacitance, 1) + ' мкФ'], 
			];
		else if (experimentType === ELDYN_EXP_TYPE.INDUCTANCE) //индуктивность
			modelParams  = [
				['Напряжение: ', 'V=' + getRoundValue(voltage, 1) + ' В'],
				['Сопротивление: ', 'R=' + getRoundValue(coilResistance, 0) + ' Ом'],
				['Индуктивность: ', 'L=' + getRoundValue(inductance, 2) + ' мГн'], 
			];
		else if (experimentType === ELDYN_EXP_TYPE.OSC_CIRCUIT) //колеб.контур
			modelParams  = [
				['Напряжение: ', 'V=' + getRoundValue(voltage, 1) + ' В'],
				['Сопротивление: ', 'R=' + getRoundValue(coilResistance, 0) + ' Ом'],
				['Индуктивность: ', 'L=' + getRoundValue(inductance, 2) + ' мГн'], 
				['Емкость: ', 'C=' + getRoundValue(capacitance, 1) + ' мкФ'], 
			];
		else //резонанс
			modelParams  = [
				['Напряжение: ', 'V=' + getRoundValue(voltage, 1) + ' В'],
				['Сопротивление: ', 'R=' + getRoundValue(coilResistance, 0) + ' Ом'],
				['Индуктивность: ', 'L=' + getRoundValue(inductance, 2) + ' мГн'], 
				['Емкость: ', 'C=' + getRoundValue(capacitance, 1) + ' мкФ'], 
				['Частота: ', 'F=' + getRoundValue(frequency, 1) + ' кГц'], 
				['Сопротивление катушки: ', 'Rc=' + getRoundValue(coilResistance, 0) + ' мОм'], 
		];

		printDiv(['mainSvg'], getExperimentName(experimentType), modelParams, ["./CalcModel.scss"]);
		setTimeout(() => completeAction(dispatch, setMakePrint, isLightModeChanged, setIsLightModeChanged, reloadOption), 100);
	}, [dispatch, experimentType, makePrint, isLightModeChanged, voltage, coilResistance, capacitance, 
		inductance, frequency]);	

	const handleStartExperiment = () => {
		const next = expState === EXP_STATE.NOT_STARTED ? EXP_STATE.STARTED : 
				(expState === EXP_STATE.STARTED || expState === EXP_STATE.CONTINUED) ? EXP_STATE.STOPPED :  
				expState === EXP_STATE.STOPPED ? EXP_STATE.CONTINUED : EXP_STATE.STOPPED;
		setExpState(next);

		if (next ===  EXP_STATE.STARTED || next === EXP_STATE.CONTINUED) {
			const _timerId = setInterval(workProgress, FREQUENCY_IN_MSEC);
			setTimerId(_timerId);
		} else if (next === EXP_STATE.STOPPED) {
			clearTimer(timerId, setTimerId);
		} 
	};

	const cleanExperimentData = () => {
		setExpState(EXP_STATE.NOT_STARTED);
		clearTimer(timerId, setTimerId);
		setRefreshCount(0);
		setPlay(false);
		setPaused(false);
		setStartPlay(false);
	};

	const isExperimentFinished = () => {return expState === EXP_STATE.FINISHED;};

	const handleFinishExperiment = () => {
		cleanExperimentData();
	};

	const handleIsCurrentEnabled = () => {
		cleanExperimentData();
		setIsCurrentEnabled(!isCurrentEnabled);
	};
	 
	const handleExperimentOptions = value => {
		cleanExperimentData();
		const newExpType = Number(value);
		setExperimentType(newExpType);
	};

	const handleVoltageRangeOption = value => {
		cleanExperimentData();
		const _voltageRangeOption = Number(value);
		setVoltageRangeOption(_voltageRangeOption);
		setVoltage(VOLTAGE_INPUT_RANGE[_voltageRangeOption][2]);
	}; 

	const handleDemoMode = value => {
		cleanExperimentData();
		setDemoMode(Number(value));	
	};

	const isInputRangeEnabled = () => {
		return expState === EXP_STATE.NOT_STARTED;
	};

	const getSchema = (isSvg, _schemaSize) => {
		const [sizeX, sizeY] = _schemaSize;
		if (isSvg) {
			if (experimentType === ELDYN_EXP_TYPE.CAPACITOR)
				return isCurrentEnabled ? getCapacitorSchemaOn(isLightMode, sizeX, sizeY) : getCapacitorSchemaOff(isLightMode, sizeX, sizeY);
			else if (experimentType === ELDYN_EXP_TYPE.INDUCTANCE)
				return isCurrentEnabled ? getInductanceSchemaOn(isLightMode, sizeX, sizeY) : getInductanceSchemaOff(isLightMode, sizeX, sizeY);
			else if (experimentType === ELDYN_EXP_TYPE.OSC_CIRCUIT)
				return isCurrentEnabled ? getOscCircuitOn(isLightMode, sizeX, sizeY) : getOscCircuitOff(isLightMode, sizeX, sizeY);
			else if (experimentType === ELDYN_EXP_TYPE.RESONANCE)
				return getResonance(isLightMode, sizeX, sizeY);
		}

		let pict = "/CalcModels/Png/";
		if (experimentType === ELDYN_EXP_TYPE.CAPACITOR)
			pict += "Kondensator" + (isCurrentEnabled ? "On" : "Off") + ".png";
		else if (experimentType === ELDYN_EXP_TYPE.INDUCTANCE)
			pict += "Katushka" + (isCurrentEnabled ? "On" : "Off") + ".png";
		else if (experimentType === ELDYN_EXP_TYPE.OSC_CIRCUIT)
			pict += "KolebKontur_" + (isCurrentEnabled ? "Charge" : "Koleb") + ".png";
		else if (experimentType === ELDYN_EXP_TYPE.RESONANCE) 
			pict += "Resonance.png";

		if (experimentType === ELDYN_EXP_TYPE.RESONANCE)
			return <img id='idImage' src={pict} alt='' key={'res'}  width={sizeX+'px'} />;
		else
			return <img id='idImage' src={pict} alt='' key={'res'}  height={sizeY+'px'} />;
	};

	const isResonance = () => {
		return experimentType ===  ELDYN_EXP_TYPE.RESONANCE;
	};
	
	const handleVoltage = value => { //throttle and debounce https://dev.to/andreysm/ispolzuiem-throttle-i-debounce-v-react-3cn9
		if(debounceVoltageInProgress.current) clearTimeout(debounceVoltageInProgress.current);
		debounceVoltageInProgress.current = setTimeout(() => {setVoltage(value);}, DEBOUNCE_PERIOD);		
	};
	const handleResistance = value => {
		if(debounceResistanceInProgress.current) clearTimeout(debounceResistanceInProgress.current);
		debounceResistanceInProgress.current = setTimeout(() => {setResistance(value);}, DEBOUNCE_PERIOD);		
	};
	const handleInductance = value => {
		if(debounceInductanceInProgress.current) clearTimeout(debounceInductanceInProgress.current);
		debounceInductanceInProgress.current = setTimeout(() => {setInductance(value);}, DEBOUNCE_PERIOD);		
	};
	const handleCapacitance = value => {
		if(debounceCapacitanceInProgress.current) clearTimeout(debounceCapacitanceInProgress.current);
		debounceCapacitanceInProgress.current = setTimeout(() => {setCapacitance(value);}, DEBOUNCE_PERIOD);		
	};
	const handleFrequency = value => {
		if(debounceFrequencyInProgress.current) clearTimeout(debounceFrequencyInProgress.current);
		debounceFrequencyInProgress.current = setTimeout(() => {setFrequency(value);}, DEBOUNCE_PERIOD);		
	};
	const handleCoilResistance = value => {
		if(debounceCoilResistanceInProgress.current) clearTimeout(debounceCoilResistanceInProgress.current);
		debounceCoilResistanceInProgress.current = setTimeout(() => {setCoilResistance(value);}, DEBOUNCE_PERIOD);		
	};

	const getColumnOpts = () => { 
		const voltageData = (volRangeOpt) => {
			return {
				value: voltage,
				ref: volRangeOpt === 0 ? voltage0Ref : volRangeOpt === 1 ? voltage1Ref : voltage2Ref,
				handleChange: e => handleVoltage(Number(e.target.value)),
				min: VOLTAGE_INPUT_RANGE[volRangeOpt][0],
				max: VOLTAGE_INPUT_RANGE[volRangeOpt][1],
				step: 0.1,
				setValue: setVoltage,
				key: 'volt001' + volRangeOpt,
				disabled: !isInputRangeEnabled(),
			};
		};

		const resistanceData = {
			value: resistance,
			ref: resistanceRef,
			handleChange: e => handleResistance(Number(e.target.value)),
			min: RESISTANCE_INPUT_RANGE[0],
			max: RESISTANCE_INPUT_RANGE[1],
			step: 1,
			setValue: setResistance,
			key: 'resist01',
			disabled: !isInputRangeEnabled(),
		};
		const inductanceData = {
			value: !!inductance ? inductance : getInductanceRange(experimentType)[2],
			ref: inductanceRef,
			handleChange: e => handleInductance(Number(e.target.value)),
			min: getInductanceRange(experimentType)[0],
			max: getInductanceRange(experimentType)[1],
			step: 0.01,
			setValue: setInductance,
			key: 'induc01',
			disabled: !isInputRangeEnabled(),
		};
		const capacitanceData = {
			value: capacitance,
			ref: capacitanceRef,
			handleChange: e => handleCapacitance(Number(e.target.value)),
			min: CAPACITANCE_INPUT_RANGE[0],
			max: CAPACITANCE_INPUT_RANGE[1],
			step: 0.1,
			setValue: setCapacitance,
			key: 'cap01',
			disabled: !isInputRangeEnabled(),
		};
		const frequencyData = {
			value: frequency,
			ref: frequencyRef,
			handleChange: e => handleFrequency(Number(e.target.value)),
			min: FREQUENCY_INPUT_RANGE[0],
			max: FREQUENCY_INPUT_RANGE[1],
			step: 0.1,
			setValue: setFrequency,
			key: 'freq01',
			disabled: !isInputRangeEnabled(),
		};
		const coilResistanceData = {
			value: coilResistance,
			ref: coilResistanceRef,
			handleChange: e => handleCoilResistance(Number(e.target.value)),
			min: COIL_RESISTANCE_INPUT_RANGE[0],
			max: COIL_RESISTANCE_INPUT_RANGE[1],
			step: 1,
			setValue: setCoilResistance,
			key: 'coilres01',
			disabled: !isInputRangeEnabled(),
		};

		return (
			<div className="calcModel__flexColumn calcModel__flexColumnCoil calcModel__eDynamColumnOpts">

				<div className="calcModel__stretch">
					<div className="calcModel__rangeCol">
						<div className="calcModel__range calcModel__rangeCmf"> 
							<div className="calcModel__label">
								Напряжение ({VOLTAGE_INPUT_RANGE[voltageRangeOption][0]}-{VOLTAGE_INPUT_RANGE[voltageRangeOption][1]}) <b>V = {getRoundValue(voltage, 1)} В</b>
							</div>
							<div className={voltageRangeOption === 0 ? '' : 'calcModel__NoDisplay'}>
								{getInputRange(voltageData(0))}
							</div>
							<div className={voltageRangeOption === 1 ? '' : 'calcModel__NoDisplay'}>
								{getInputRange(voltageData(1))}
							</div>
							<div className={voltageRangeOption === 2 ? '' : 'calcModel__NoDisplay'}>
								{getInputRange(voltageData(2))}
							</div>
						</div>		
					</div>

					<div className="calcModel__rangeCol">
						<div className="calcModel__range calcModel__rangeCmf"> 
							<div className="calcModel__label">
								Сопротивление ({RESISTANCE_INPUT_RANGE[0]}-{RESISTANCE_INPUT_RANGE[1]}) <b>R = {getRoundValue(resistance, 0)} Ом</b>
							</div>
							{getInputRange(resistanceData)}
						</div>		
					</div>
				</div>

				<div className="calcModel__stretch">
					{experimentType !== ELDYN_EXP_TYPE.CAPACITOR && (
					<div className="calcModel__rangeCol">
						<div className="calcModel__range calcModel__rangeCmf"> 
							<div className="calcModel__label">
								Индуктивность ({getInductanceRange(experimentType)[0]}-{getInductanceRange(experimentType)[1]}) <b>L = {getRoundValue(inductance, 2)} {getInductanceRange(experimentType)[3]}</b>
							</div>
							{getInputRange(inductanceData)}
						</div>		
					</div>
					)}

					{experimentType !== ELDYN_EXP_TYPE.INDUCTANCE && (
					<div className="calcModel__rangeCol">
						<div className="calcModel__range calcModel__rangeCmf"> 
							<div className="calcModel__label">
								Емкость ({CAPACITANCE_INPUT_RANGE[0]}-{CAPACITANCE_INPUT_RANGE[1]}) <b>C = {getRoundValue(capacitance, 1)} мкФ</b>
							</div>
							{getInputRange(capacitanceData)}
						</div>		
					</div>
					)}
				</div>

				{experimentType === ELDYN_EXP_TYPE.RESONANCE && (
				<div className="calcModel__stretch">
					<div className="calcModel__rangeCol">
						<div className="calcModel__range calcModel__rangeCmf"> 
							<div className="calcModel__label">
								Частота ({FREQUENCY_INPUT_RANGE[0]}-{FREQUENCY_INPUT_RANGE[1]}) <b>F = {getRoundValue(frequency, 1)} кГц</b>
							</div>
							{getInputRange(frequencyData)}
						</div>		
					</div>

					<div className="calcModel__rangeCol">
						<div className="calcModel__range calcModel__rangeCmf"> 
							<div className="calcModel__label">
								Сопротивление катушки ({COIL_RESISTANCE_INPUT_RANGE[0]}-{COIL_RESISTANCE_INPUT_RANGE[1]}) <b>Rc = {getRoundValue(coilResistance, 0)} {COIL_RESISTANCE_INPUT_RANGE[3]}</b>
							</div>
							{getInputRange(coilResistanceData)}
						</div>		
					</div>
				</div>
				)}
			</div>	
		);
	};

	const getSelectOpts = () => {
		return (
			<div id="idComboBoxes" className="calcModel__flexColumn calcModel__eDynam calcModel__eDynamColumnBtns">
				{!isResonance() && (
				<div>
					{getDropdown(experimentOptions, experimentType, handleExperimentOptions, 
						'calcModel__Select calcModel__SelectValues11', '', 'exp01')}
				</div>
				)}

				<div id="idDemoMode">
					{getDropdown(demoModeOptions, demoMode, handleDemoMode, 
						'calcModel__Select calcModel__SelectValues11', '', 'demo01')}
				</div>

				<div className="">
					{getDropdown(voltageRangeOptions, voltageRangeOption, handleVoltageRangeOption, 
						'otCreate__Select otCreate__SelectValues11', 'otCreate__DropDownItem', 'volt01')}
				</div>
			</div>
		);
	};

	const getGraph = () => {
		const sbs = [0.8 * schemaBoxSize[0], 0.8 * schemaBoxSize[1]];
		const isSvg = true;
		return (
			<div className="calcModel__flexColumn calcModel__eDynam calcModel__eDynamColumnGraph">
				{!!schemaBoxSize &&
				<div onClick={() => {cleanExperimentData();setIsCurrentEnabled(!isCurrentEnabled);}}  
					className="calcModel__eDynamGraphBox"
					// style={{width: (schemaBoxSize[0])+'px', height: (schemaBoxSize[1])+'px'}}
					> 
					{getSchema(isSvg, sbs)}
				</div>
				}
			</div>
		);
	};

	const getCapInductOptions = () => {
		return (
		<div className="calcModel__stretch">
			{getColumnOpts()}
			{getSelectOpts()}
			{getGraph()}
		</div>
		);
	};

	const getCoordinates = () => {
		return (
			<div className="calcModel__name calcModel__nameFont calcModel__nameCoord calcModel__Virtual">
				{cursorX !== -1 || cursorY !== -1 ?
				<div>
					<span className="calcModel__cursorPos">T = <b>{getRoundValue(getCoordInfo('time', svgWidth, scaleRangeData, cursorX), 2)} сек</b></span>
					<span className="calcModel__cursorPos">V = <b>{getRoundValue(getCoordInfo('volt', svgHeight, scaleRangeData, cursorY), 2)} В</b></span>
					{experimentType !== ELDYN_EXP_TYPE.RESONANCE && (
					<span className="calcModel__cursorPos">I = <b>{getRoundValue(getCoordInfo('curr', svgHeight, scaleRangeData, cursorY), 2)} А</b></span>
					)}
				</div>
				:
				<span className="calcModel__elDynamInfo">Кликните по графику для получения координат точки</span>
				}
			</div>
		);
	};

    useEffect(() => {
        if (startPlay) {
            setTimeout(() => {setPlay(true); setStartPlay(false);            
				}, 100);
        }
    }, [startPlay]);

	const handlePlayPauseExperiment = () => {
		if (!isExperimentFinished()) {
			if (played && !paused) {
				setPaused(true);
				handleStartExperiment();
			} else {
				setPaused(false);
				!played && setStartPlay(true);
				handleStartExperiment();
			}
		} else { //start again:
			cleanExperimentData();
			setExpState(EXP_STATE.STARTED);
			const _timerId = setInterval(workProgress,  FREQUENCY_IN_MSEC);
			setTimerId(_timerId);
			setPlay(true);
		}
		showPalyOpts('in the end - handlePlayPauseExperiment');
	};

	const showPalyOpts = (func) => {  //can be removed later!!
		//console.log(func + ': ', played, paused, startPlay); 
	};
	const getButtons = () => {
		showPalyOpts('getButtons');
		return (
			<>
				{!isResonance() && 
				<div className="calcModel__leftBottomBtn"> 
					<button onClick={handleIsCurrentEnabled} 
						className={"btn " + (experimentType && expState === EXP_STATE.NOT_STARTED ? "Sel" : "Unsel")}>
							{(!isCurrentEnabled ? 'Включить': 'Выключить') + ' передачу напряжения'}
					</button>
				</div>
				}
				{isResonance() && <div className='calcModel__emptyBtn'></div>}

				{demoMode === DEMO_OPTION.REAL_TIME && (<div></div>)}
				{demoMode !== DEMO_OPTION.REAL_TIME && 
					getPlayPauseExperiment(isLightMode, startPlay, played, paused, currProgressInSec, handleFinishExperiment, handlePlayPauseExperiment)
				}
				
				<div className="calcModel__stretch">
					<div className="calcModel__exportBtnComment">Работа с файлами</div> 
					<div className="calcModel__leftBottomBtn">{getExportCB(handleProfile, handleExportExperiment, isInnerCM)}</div> 
					<div>
						<Icon name="print" className=" calcModel__printBtn" onClick={handlePrint} />
					</div>
				</div>
			</>
		);
	};

    return (
		<>
			{experimentType === ELDYN_EXP_TYPE.CAPACITOR && getHeaderBox(history, experimentType+4, isInnerCM, 'Электродинамика', 'Конденсатор')}
			{experimentType === ELDYN_EXP_TYPE.INDUCTANCE && getHeaderBox(history, experimentType+4, isInnerCM, 'Электродинамика', 'Индуктивность')}
			{experimentType === ELDYN_EXP_TYPE.OSC_CIRCUIT && getHeaderBox(history, experimentType+4, isInnerCM, 'Электродинамика', 'Колебательный контур')}
			{experimentType === ELDYN_EXP_TYPE.RESONANCE && getHeaderBox(history, experimentType+4, isInnerCM, 'Резонанс')}

			<Content>
				<ContentBody scrolled={false}>
					<div id="idInnerMainBox" ref={refMainBox} className="calcModel">
						<div id="mainSvg" ref={refMainSvg} className=""
							style={{width: svgWidth, height: svgHeight}}
							>
							{getSvg(svgArr)}
						</div>
						<div id="idCommon" className="calcModel__opts">
							{getCapInductOptions()}
							{getCoordinates()}
						</div>
					</div>
				</ContentBody>
				<div className="calcModel__indent"></div>
				<ContentFooter className="calcModel__footer">{getButtons()}</ContentFooter>
			</Content>

			{showConfirmSaveXlsDlg &&
			<SaveXlsFileConfirmDialog
				showConfirmSaveXlsDlg={showConfirmSaveXlsDlg}
				setShowConfirmSaveXlsDlg={setShowConfirmSaveXlsDlg}
				saveExport={saveExport}
				fileName={getXlsxFileName(experimentType)}
				handleCancelSave={handleCancelSave}
			/>
			}
		</>
    );
}

export default Electrodynamics;
