import React from 'react';
import UploadCoverInput from '../UploadCoverInput/UploadCoverInput';
import { ReactComponent as Upload } from '../../../assets/icons/card-menu/09_upload.svg';
import './UploadCoverButton.scss';

const UploadCoverButton = ({ id, setIsOpenMenu, activeCard}) => {
  return (
    <div className="upload_cover__button">
      <Upload />
      
      <span>{activeCard?.attachments?.length !== 0 ? 'Заменить обложку' : 'Загрузить обложку'}</span>
     <UploadCoverInput activeTaskId={id} setIsOpenMenu={setIsOpenMenu} isMenuButton />

    </div>
  );
};

export default UploadCoverButton;
