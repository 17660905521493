import React, {useState, useEffect} from "react";
import TextEditor from '../ui/TextEditor/TextEditor';
import PIOneName from './PIOneName';
import {viewComment} from "./pi_utils";

const PIOneComment = ({isEdit, //is edit mode (true = проведение, false - просмотр)
        uid, id,
        workComment, setWorkComment, commentIndex, 
        doDeleteHideSection}) => {
    const [commentName, setCommentName] = useState('');
    const [commentContent, setCommentContent] = useState('');
    const [isComplete, setIsComplete] = useState(false);

    useEffect(() => { 
        if (!workComment) return;
        setCommentName(workComment.title);
        setCommentContent(workComment.text);
    }, [workComment, commentIndex]);
    
    const updateCommentName = (isSave, name) => {
        if (isSave) {
            setWorkComment(commentIndex, {...workComment, title: name});
        }
    };

    const handleFocus = () => {
        setCommentContent(workComment.text);
    };
    
    useEffect(() => {
        const handleComplete = () => {
            setWorkComment(commentIndex, {...workComment, text: commentContent});
        };

        if (!isComplete) return;
        handleComplete();
        setIsComplete(false);

         return () => handleComplete();
      }, [commentContent, commentIndex, isComplete, setWorkComment, workComment]);

    const editComment = () => {
        return (
            <div key={'ct01'+commentIndex}
                onFocus={handleFocus}
                onBlur={() => setIsComplete(true)}
            >
                <div className="cor-net__section">
                    <div className="cor-net__row">
                        <div className="cor-net__col col-grow">
                            <PIOneName
                                isEdit={isEdit} 
                                initName={commentName} 
                                setEditedName={updateCommentName} 
                                uniqueIndex={commentIndex}
                                doDeleteHideSection={doDeleteHideSection} 
                            />

                            <TextEditor 
                                id={'EDITOR'+uid}
                                className="example"
                                value={workComment.text} 
                                setValue={setCommentContent} 
                                maxHeight={'100%'} 
                            />
                        </div>
                    </div>
                </div>                
            </div>
        );
	}

    if (isEdit)
        return editComment();
    else 
        return viewComment(id, commentName, workComment?.text);
}

export default PIOneComment;
