import React, { useEffect, useRef } from 'react';
import { ReactComponent as Arrow } from './arrow.svg';
import './HeaderMenu.scss';

const HeaderMenuItem = ({
  title,
  isOpenMenu,
  handleOpenMenu,
  items,
  onItemClick,
  isUsers,
  selectedItems
}) => {
  const menuRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        handleOpenMenu();
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleOpenMenu]);
  
  return (
    <div className="header_menu__filters_item" onClick={handleOpenMenu}>
      <div className="header_menu__filters_item_button">
        <span className="header_menu__filters_item_button_title">{title}</span>
        <Arrow
          className={isOpenMenu ? '' : 'header_menu__filters_button_arrow'}
        />
      </div>
      {isOpenMenu && (
        <ul
          className="header_menu__filters_item_list"
          onClick={(e) => e.stopPropagation()}
          ref={menuRef}
        >
          {items?.map((item) => (
            <li key={item?.name}>
              <label
                htmlFor={item.name}
                className="header_menu__filters_item_list_label"
              >
                <input
                  type="checkbox"
                  id={item.name}
                  className="header_menu__filters_item_list_checkbox"
                  onChange={() => onItemClick(item)}
                  checked={selectedItems?.includes(item)}
                />
                {isUsers ? (
                  <span className={'header_menu__filters_item_list_title'}>
                    {`${item?.name} ${item?.name2}`}
                  </span>
                ) : (
                  <span className={'header_menu__filters_item_list_title'}>
                    {item.title}
                  </span>
                )}
              </label>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default HeaderMenuItem;
