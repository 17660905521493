import React from 'react';
import Grid from '../ui/Grid';
import ss from './FeaturesGrid.module.scss';

import {FEATURES} from "../../api/config";
import {useSelector} from "react-redux";
import {storageOrg} from "../../redux/slices/storage";
import {printMediumOnlyDate, printNumbered} from "../../helpers/text";


const FeaturesGrid = (props) => {
  const documentOrg = useSelector(storageOrg);

  return (
    <Grid className={ss.root}>
      {Object.entries(FEATURES).filter(([, v]) => v.name?.toLowerCase().includes(props.search.toLowerCase())).map(([k, v]) => (
        <Grid.Col className={ss.item} key={k}>
          <div className={ss.head}>
            <span className={ss.name}>{v.name}</span>
          </div>
          <div className={ss.main}>
            <ul className={ss.list}>
              <li className={ss.list__item}>
                  <span>Дата окончания</span>
                  <span>{documentOrg.features[k] ? printMediumOnlyDate(documentOrg.features[k]) : "нет лицензии"}</span>
              </li>
              <li className={ss.list__item}>
                  <span>Осталось</span>
                  <span>{documentOrg.features[k] ?
                    printNumbered(Math.ceil((new Date(documentOrg.features[k]) - new Date()) / (1000 * 60 * 60 * 24)), ['день', 'дня', 'дней']) 
                    : "-"}
                  </span>
              </li>
            </ul>
          </div>
        </Grid.Col>
      ))}
    </Grid>
  )
};
export default FeaturesGrid;
