import React from 'react';
import { Input } from 'rlabui';
import ss from './FilterByText.module.scss';



const FilterByText = ({
    placeholder,
    value,
    setSearch
}) => {
    return (
        <div className={ss.root}>
            <Input 
                type="text" 
                placeholder={placeholder} 
                value={value} 
                onInput={e => setSearch(e.target.value)} 
            />
        </div>
    )
};
export default FilterByText;

