import React from 'react';

const Instructions = () => {
    // const isLinux = window.navigator.userAgent.includes('Linux');
    // const isWindows = window.navigator.userAgent.includes('Windows');
    // const {isAndroid} = window;

    return (
        <>
            <li>
                {window.isElectron ? (
                    <a
                        href={window.instruction}
                        download
                    >
                        Скачать инструкцию
                    </a>
                ) : (
                    <a
                        href="https://disk.yandex.by/d/G_KxCaAgQ-LwMQ"
                        target="blank"
                    >
                        Скачать инструкцию
                    </a>
                )}
            </li>
            <li>
                <a
                    href={window.drivers}
                    download
                >
                    Скачать драйвер
                </a>
            </li>
        </>
    );
};
export default Instructions;
