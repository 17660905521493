import React, {useEffect} from 'react';
import Modal from '../Modal';
import {storageApi} from "../../api/api";
import {useFormik} from "formik";
import * as Yup from "yup";
import {toast} from "react-toastify";
import "./Settings.scss";
import {useDispatch} from "react-redux";
import {loadStorage} from "../../redux/slices/lists";

const StorageSettings = (props) => {
    const dispatch = useDispatch();

    const handleClose = () => {
        formik.resetForm();
        props.showStorageSettings({showModal: false})
    };

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) handleClose();
        };
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            size: props.storage.size,
        },
        validationSchema: Yup.object({
            size: Yup.number().required("Введите квоту").positive("Введите размер квоты"),
        }),
        onSubmit: async (formData) => {
            try {
                if (props.isAdd) {
                    await storageApi.addBucket(props.storage.uid, formData.size)
                    .then(() => {
                        dispatch(loadStorage(props.storage.uid));
                        handleClose();
                        toast.info("Bucket Добавлен");
                    })
                    .catch((err) => {
                        toast.error(err.response.data.message);
                    });
                } else {
                    await storageApi.setQuota(props.storage.uid, formData.size)
                    .then(() => {
                        dispatch(loadStorage(props.storage.uid));
                        handleClose();
                        toast.info("Квота обновлена");
                    })
                    .catch((err) => {
                        toast.error(err.response.data.message);
                    });
                }
            } catch (err) {
                const error = {
                    visible: true,
                    message: JSON.stringify(err.errors, null, 2),
                };
                toast.error(error);
            }
        },
    });

    return (
        <>
            <Modal visible={props.showModal} content={
                <form onSubmit={formik.handleSubmit}>
                    <div className="modal__close" onClick={handleClose}/>
                    <div className="modal__message">{props.isAdd ? "Создать" : "Редактировать"} Bucket {props.storage.uid}</div>
                    <div className="modal__body">
                        <div key="size" className="cor_settings_vert">
                            <label htmlFor="size">Размер квоты Гб</label>
                            <div className="form_error">{formik.errors.size}</div>
                            <input
                                type="number"
                                autoComplete="off"
                                placeholder="квота"
                                name="size"
                                className={formik.errors.size?"form_error":""}
                                onChange={formik.handleChange}
                                value={formik.values.size}
                            />
                        </div>
                    </div>
                    <div className="modal__action">
                        <button
                            className="cor_btn cor_btn_primary"
                            type="submit"
                        >{props.isAdd ? "Создать" : "Сохранить"}</button>
                    </div>
                </form>
            }/>
        </>
    )
};

export default StorageSettings;