import { toggleTimerModal, setStopwatch } from "../../actions/timer"

export const mapStateToProps = (state) => {
    return {
        modal: state.timer.timerModal,
    }
}

export const mapDispatchToProps = (dispatch) => ({
    toggleTimerModal: (bool) => dispatch(toggleTimerModal(bool)),
    setStopwatch: (millisec) => dispatch(setStopwatch(millisec)),
})
