import React from 'react'
import '../template/Header/Header.scss';

export default function Page_404() {
  return (
      <div className='cor_header'>
          <div className='cor_header__content'>
              <div className='cor_header__left'>
                  <a className='cor_header__logo' href="/">
                    <img src="/logo_w.svg" alt="404"/>
                  </a>
              </div>
              <div className='cor_header__center'>
                  <h1>Указанная страница отсутствует</h1>
              </div>
              <div className='cor_header__right'>
                <div className="cor_header__not_found">
                  <h1>404</h1>
                </div>
              </div>
          </div>
    </div>
  );
};
