import React, { useEffect, useState } from 'react';
import Styles from './AsideFilter.module.scss';
import { Icon } from '../../ui';
import { Button, Dropdown, Range } from 'rlabui';

const filtersDrop = [
    { label: 'сброс', value: null },
    { label: 'нижних частот', value: 'lower', index: 1 },
    { label: 'верхних частот', value: 'upper', index: 2 },
    { label: 'полосовой', value: 'bandpass', index: 3 },
    { label: 'режекторный', value: 'reject', index: 4 },
];

const AsideFilter = ({ sensorsList, sensorsImported, visualized, delSensorsFilters, addSensorsFilters, filters, played }) => {
    const [sensor, setSensor] = useState(sensorsList.find((e) => e.key === 'EEGMeter'));
    const [showFilters, setShowFilters] = useState(false);
    const [selected, setSelected] = useState(null);
    const [maxFreq, setMaxFreq] = useState(30);
    const [minFreq, setMinFreq] = useState(20);
    const [isEEG, setEEG] = useState(sensorsList.find((e) => e.key === 'EEGMeter'));
    const [filterDisabled, setFilterDisabled] = useState(false);

    useEffect(() => {
        if (!Object.keys(filters).length) {
            setSelected(null);
        }
    }, [filters, setSelected]);

    useEffect(() => {
        if (sensorsList?.length || sensorsImported?.length) {
            let EEG = [...sensorsList, ...sensorsImported].find((e) => e.key === 'EEGMeter');
            setSensor(EEG ? EEG : sensorsList[0]);
            if (EEG && !selected && maxFreq !== 16 && minFreq !== 8) {
                setEEG(EEG);
                setSelected(3);
                setMaxFreq(16);
                setMinFreq(8);
                addSensorsFilters({ key: 'EEGMeter', data: { minFreq: 8, maxFreq: 16, filter: 'bandpass' } });
            }
        }
        // Hide sensorsList wraning
        // eslint-disable-next-line
    }, [sensorsList.length, sensorsImported.length, addSensorsFilters, delSensorsFilters, sensorsImported, setSensor]);

    useEffect(() => {
        if (visualized) {
            setSensor([...sensorsList, ...sensorsImported]?.filter((e) => e.key === visualized)[0]);
        } else {
            if (!isEEG) {
                setSensor();
            }
        }
    }, [visualized, sensorsList, sensorsImported, isEEG]);

    useEffect(() => {
        if (isEEG) {
            setSensor(isEEG);
        } else {
            setMaxFreq(30);
            setMinFreq(20);
            setSelected(null);
        }
    }, [isEEG]);

    useEffect(() => {
        if (!played) {
            setFilterDisabled(false);
        }
    }, [played, setFilterDisabled]);

    useEffect(() => {
        if (selected && sensor) {
            setFilterDisabled(false);
        } else {
            setFilterDisabled(true);
        }
    }, [selected, sensor]);

    return (
        visualized !== 'Button' && (
            <div className={showFilters ? Styles.filterShow : Styles.filter}>
                <div className={Styles.head}>
                    <span>
                        Фильтр{' '}
                        {/* {Object.keys(filters)?.filter((e) => e === sensor?.key)[0] ? (
                            <Icon
                                className={Styles.on}
                                name={'turn-on'}
                            />
                        ) : (
                            ''
                        )} */}
                    </span>
                    <Icon
                        className={Styles.caret}
                        name="caret"
                        onClick={() => setShowFilters(!showFilters)}
                    />
                </div>
                <div className={Styles.body}>
                    <div className={Styles.row + ' ' + Styles.rowInline}>
                        <div className={Styles.label}>Канал</div>
                        <Dropdown
                            value={sensor?.imported ? sensor?.name + '(имп)' : sensor?.name || 'Выберите канал'}
                            className={Styles.dropdown}
                        >
                            {[...sensorsImported, ...sensorsList]?.map((sensor) => {
                                if (!sensor) {
                                    return false;
                                } else {
                                    const { key, name, imported } = sensor;
                                    if (!key.includes('Button')) {
                                        return (
                                            <Dropdown.Item
                                                key={key}
                                                onClick={() => setSensor(sensor)}
                                            >
                                                {name} {imported ? '(имп)' : ''}
                                            </Dropdown.Item>
                                        );
                                    } else {
                                        return false;
                                    }
                                }
                            })}
                        </Dropdown>
                    </div>
                    <div className={Styles.row + ' ' + Styles.rowInline}>
                        <div className={Styles.label}>Фильтр</div>
                        <Dropdown
                            value={filtersDrop[selected]?.label || 'Выберите фильтр'}
                            // disabled={filterDisabled}
                            className={Styles.dropdown}
                        >
                            {filtersDrop?.map(({ label, value, index }) => {
                                if (value) {
                                    return (
                                        <Dropdown.Item
                                            key={value}
                                            onClick={() => setSelected(index)}
                                        >
                                            {label}
                                        </Dropdown.Item>
                                    );
                                } else {
                                    return false;
                                }
                            })}
                        </Dropdown>
                    </div>
                    <div className={Styles.row}>
                        <div className={Styles.label}>
                            Минимальная частота: <b>{minFreq}</b>
                        </div>
                        <div className={Styles.range}>
                            <Icon
                                name="minus"
                                onClick={() => setMinFreq((e) => (e <= 0 ? e : --e))}
                            />
                            <Range
                                min={0}
                                max={100}
                                step={1}
                                value={minFreq}
                                onChange={({ target }) => setMinFreq(+target.value)}
                            />
                            <Icon
                                name="plus"
                                onClick={() => setMinFreq((e) => (e >= 100 ? e : ++e))}
                            />
                        </div>
                    </div>
                    <div className={Styles.row}>
                        <div className={Styles.label}>
                            Максимальная частота <b>{maxFreq}</b>
                        </div>
                        <div className={Styles.range}>
                            <Icon
                                name="minus"
                                onClick={() => setMaxFreq((e) => (e <= 0 ? e : --e))}
                            />
                            <Range
                                min={0}
                                max={100}
                                step={1}
                                value={maxFreq}
                                onChange={({ target }) => setMaxFreq(+target.value)}
                            />
                            <Icon
                                name="plus"
                                onClick={() => setMaxFreq((e) => (e >= 100 ? e : ++e))}
                            />
                        </div>
                    </div>
                    <div className={Styles.foot}>
                        <Button
                            className={Styles.btn}
                            size="small"
                            // disabled={Object.keys(filters).filter((e) => e === sensor?.key)[0]}
                            disabled={filterDisabled}
                            onClick={() => sensor?.key && addSensorsFilters({ key: sensor.key, data: { minFreq, maxFreq, filter: filtersDrop[selected]?.value || null } })}
                        >
                            Применить
                        </Button>
                        <Button
                            className={Styles.btn}
                            border={true}
                            size="small"
                            // disabled={filterDisabled}
                            onClick={() => delSensorsFilters(sensor?.key)}
                        >
                            Отмена
                        </Button>
                    </div>
                </div>
            </div>
        )
    );
};
export default AsideFilter;
