import React, {useEffect} from 'react';
// import Modal from "../../../Components/Modal";
import {Modal, Button} from 'rlabui';
import {FEATURES} from "../../../api/config";
import {isAuth} from "../../../helpers/auth";
import {useDispatch} from "react-redux";
import { setTestExecuteData }  from "../../../redux/slices/tutorialslice";
import "./PracticumTests.scss";

const ModalFinishTest = (props) => {
    const { showFinishTest, setShowFinishTest, testInfo, history } = props;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setTestExecuteData(null));
    }, [dispatch]);
    
    const handleOpenProfile = () => {
        const profileTabIOnd = testInfo.testType === 0 ? 0 : testInfo.testType === 1 ? 1 : 5;
        history.push(FEATURES['profile'].to + '/' + isAuth()._id + '/' + profileTabIOnd);
        setShowFinishTest(false);
    };

    const doFinishTest = () => {
        history.push('/');
        setShowFinishTest(false);
    };

    if (!showFinishTest) {
        return null;
    }

    return (
        <Modal visible={showFinishTest} size="xs">
            <Modal.Head modalClose={doFinishTest} title='Работа оценена' />
            <Modal.Body>Учитель оценил ваши результаты, с ними можно ознакомиться в профиле ученика</Modal.Body>
            <Modal.Footer>
                <Button onClick={handleOpenProfile}>Перейти в профиль</Button>
                <Button onClick={doFinishTest} border={true}>Закрыть</Button>
            </Modal.Footer>
        </Modal>
    )
};

export default ModalFinishTest;
