import './DeadlineButton.scss';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/card-menu/14_calendar.svg';

const DeadlineButton = ({ date, setDate, title }) => {
  const newDate = new Date(date);

  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  return (
    <div className="deadline_button">
      <label className="deadline_button__label">
        {!date
          ? title
          : newDate.toLocaleDateString('ru', dateOptions).slice(0, -3)}
        <CalendarIcon className="deadline_button__icon" />
      </label>
      <input
        className="deadline_button__input"
        type="date"
        onChange={(e) => setDate(e.target.value)}
      />
    </div>
  );
};

export default DeadlineButton;
