import React, {useState, useEffect} from "react";
import { Icon, Input } from "../ui";
import {FUNDAMENTAL_CONSTS} from './physicalConstants';
import Table from "../ui/Table/Table";
import {chkItemText} from "../ui/utils/gen_utils";
import { ContentBody, ContentSubHeader } from "../template/ContentParts";

const PIShowFundamentConsts = ({history}) => {
	const [testFilter, setTestFilter] = useState('');
	const [fundConsts, setFundConsts] = useState([]);
	const [filteredFundConsts, setFilteredFundConsts] = useState([]);

	useEffect(() => {	
		const list = [];
		for (let i = 0; i < FUNDAMENTAL_CONSTS.length; i ++) {
			const item = FUNDAMENTAL_CONSTS[i];
			const order = (item.order !== 0 ?  '·10^+' + item.order + '^+' : '');
			const record = {
				name: item.name, 
				char: item.char,
				value: '' + item.value + order, 
				approxValue: '' + item.approxValue + order, 
				unit: item.unit, 
				physicsSection: item.physicsSection, 
				id: i
			};

			list.push(record);
		}

		setFundConsts(list);
	}, []);
    
	useEffect(() => {	
		let list = [];
		const filter = testFilter.trim();
		if (filter) {
			for (let i = 0; i < fundConsts.length; i ++) {	
				let found = false;
				const item = fundConsts[i];
				const row = Object.keys(item);

				for (let k = 0; k < row.length - 1 && !found; k ++) {	
					if (!found && chkItemText(item, row[k], testFilter)) //проверяем k-ый элемент в этом ряду
						found = true;
				}
				if (found) list.push(item);
			}
		}
		
		setFilteredFundConsts(filter ? list : fundConsts);
	}, [fundConsts, testFilter]);

    const getTableHeader = () => {
		const header = [
			{column: 'Наименование', name: 'name', style: { width: '16%'} }, 
			{column: 'Обозначение', name: 'char', style: { width: '16%'} }, 
			{column: 'Значение', name: 'value', style: { width: '16%'} }, 
			{column: 'Приближенное значение', name: 'approxValue', style: { width: '16%'} }, 
			{column: 'Единицы измерения', name: 'unit', style: { width: '16%'} }, 
			{column: 'Раздел физики', name: 'physicsSection', style: { width: '16%'} }, 
			{column: 'id', name: 'id', style: { width: '0%'} }, 
		];
		return header;
	};

    return ( 
		<>
			<ContentSubHeader>
				<div className="cor-net">
					<div className="cor-net__section">
						<div className="cor-net__title">Фундаментальные постоянные</div>
						<div className="cor-net__row">
							<div className="cor-net__col col-2 col-icon">
								<Input className="lwtSelect_input"
									type="text" 
									placeholder={'поиск'} 
									value={testFilter} 
									onInput={e => setTestFilter(e.target.value)} 
								/>
								<Icon name={'search'} />
							</div>
						</div>
					</div>
				</div>
			</ContentSubHeader>
			<ContentBody>
				<Table 
					table={{
						header: getTableHeader(), 
						data: filteredFundConsts,
					}}
					sort={{
						hasSorting: true, 
						initSortInd: 1
					}}
				/>
			</ContentBody>
		</>
    )
}

export default PIShowFundamentConsts;