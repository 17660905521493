import React, {useState, useEffect} from 'react';
import {isAuth, fullName} from "../../helpers/auth";
import {printOnlyDate} from "../../helpers/text";
import {getNotebookById, getNotebookInfoById, updateNotebookInfoScoreById} from "./nb_utils";
import {getTheAnswer} from "./task_utils";
import {userApi} from "../../api/api";
import { getDropdown } from "../ui/utils/gen_utils";
import {getTemplatesBySubjectId} from "./templates";
import {getScoreCbList, getScoreName, getScoreValue} from '../ui/utils/score_utils';
import { Icon } from '../ui';
import { Button, Modal } from 'rlabui';
import resStyles from '../ReviewTest/Results.module.scss';

const NotebooksViewtResultByTeacherDlg = ({ showDlg, setShowDlg, selectedIds, setScoreUpdated }) => {
    const [profile, setProfile] = useState(undefined);
	const [templates, setTemplates] = useState([]);
    const [selectedStudentId, setSelectedStudentId] = useState(undefined);
    const [notebookId, setNotebookId] = useState(undefined);
	const [nbData, setNbData] = useState(undefined);
    const [notebookInfoId, setNotebookInfoId] = useState(undefined);
	const [nbiData, setNbiData] = useState(undefined);
    const [preparedAnswers, setPreparedAnswers] = useState(undefined);
    const [changedScore, setChangedScore] = useState(false);
    const [updated, setUpdated] = useState(false);

    //1. get selectedStudentId and notebookInfoId:
    useEffect(() => {
        const [_selectedSudentId, _notebookInfoId] = selectedIds.split('|');
        setNbiData(undefined);
        setNbData(undefined);
        setNotebookId(undefined);
        setSelectedStudentId(_selectedSudentId);
        setNotebookInfoId(_notebookInfoId);
    }, [selectedIds]);

    //2. get profile by selectedStudentId: WHY IS IT HERE?
    useEffect(() => {
        if (!selectedStudentId) return;
        userApi.get(selectedStudentId).then((data) => setProfile(data));
    }, [selectedStudentId]);

    //3. get nbi data notebookInfoId:
    useEffect(() => {
		const getNotebookInfoData = async (_notebookInfoId) => {
			const result = await getNotebookInfoById(_notebookInfoId);
			setNbiData(result);
            setNotebookId(result.notebook);
            setUpdated(false);
		};

        if (!notebookInfoId) return;
        getNotebookInfoData(notebookInfoId);
    }, [showDlg, notebookInfoId, updated]);

    //4. get nb data and nb data by notebookId:
    useEffect(() => {
        const getNotebookData = async (_notebookId) => {
			const nbData = await getNotebookById(_notebookId);
            setNbData(nbData);
            const subjectId = nbData.titles.subjectId;
            setTemplates(getTemplatesBySubjectId(subjectId).templates);
        };

        if (!notebookId) return;
        getNotebookData(notebookId);
    }, [notebookId]);

    const handleClose = () => {
        setScoreUpdated(true);
        setShowDlg(false);
    };

    const handleScore = value => {
        const doUpdateScore = async  () => {
            const score = value !== '' ? Number(value) : null;
            await updateNotebookInfoScoreById(notebookInfoId, {score: score});
            setScoreUpdated(true);
            setUpdated(true);
            setChangedScore(true);
        };
        doUpdateScore();
    };

    const getScoreCell = () => {
        if (!nbiData) return <></>;
        const value = nbiData.score >= 0 ? nbiData.score : '';
        const score = getScoreValue(value);

		return getDropdown(getScoreCbList(), score, val => handleScore(val), 
				'', '', 'score01', "top");
	};

    const getTopPart = () => {
        if (!nbData || !nbiData || templates.length === 0) return <></>;
        const tasks = nbiData.tasks;
        const answerNum = tasks.filter(item => item.isCorrect !== -1).length; 
        const correctAnswerNum = tasks.filter(item => item.isCorrect === 1).length; 

        return (
            <div className={resStyles.info} key='res01'>
                <div className={resStyles.row} key='res02'>
                    <span className={resStyles.col} key='res03'>Имя ученика</span>
                    <span className={resStyles.col} key='res04'>{fullName(profile)}</span>    
                </div>
                <div className={resStyles.row} key='res05'>
                    <span className={resStyles.col} key='res06'>Название задания</span>
                    <span className={resStyles.col} key='res07'>{nbData?.titles.homeWorkName}</span>    
                </div>
                <div className={resStyles.row} key='res08'>
                    <span className={resStyles.col} key='res09'>Тема</span>
                    <span className={resStyles.col} key='res10'>{nbData.titles.homeWorkItem}</span>    
                </div>
                <div className={resStyles.row} key='res11'>
                    <span className={resStyles.col} key='res12'>Дата начала выполнения задания</span>
                    <span className={resStyles.col} key='res13'>{printOnlyDate(nbData.run.runFullDate)}</span>    
                </div>
                <div className={resStyles.row} key='res14'>
                    <span className={resStyles.col} key='res15'>Дата завершения выполнения задания</span>
                    <span className={resStyles.col} key='res16'>{printOnlyDate(nbData.run.completeFullDate)}</span>    
                </div>
                <div className={resStyles.row} key='res17'>
                    <span className={resStyles.col} key='res18'>Количество ответов</span>
                    <span className={resStyles.col} key='res19'>{answerNum} из {tasks.length}</span>    
                </div>
                <div className={resStyles.row} key='res20'>
                    <span className={resStyles.col} key='res21'>Количество правильных ответов</span>
                    <span className={resStyles.col} key='res22'>{correctAnswerNum}  из {tasks.length}</span>
                </div>
                <div className={resStyles.row} key='res23'>
                    <span className={resStyles.col} key='res24'>Оценка</span>
                    <span className={resStyles.col} key='res25'>{nbiData.score ? getScoreName(nbiData.score) : 'не выставлена'}</span>
                </div>    
            </div>
        );
    };

    useEffect(() => {
        if (!nbData || !nbiData || !nbiData.tasks || changedScore) 
            return;

        const answers =
        <div className={resStyles.answer__list} key='keyanswers'>
            {nbiData.tasks.map((task, ind) => <div className={resStyles.answer__item} key={'tsk'+ind}>
                {getTheAnswer(templates, nbData, task, ind)}
            </div> )}
        </div>;
        setPreparedAnswers(answers);
    }, [templates, nbData, nbiData, changedScore]);

    if (!showDlg) {
        return null;
    }

    return (
        <Modal className={resStyles.root} visible={showDlg} size={'lg'}>
            <Modal.Head className={resStyles.head} modalClose={handleClose} title="Результаты выполнения задания"/>
                {nbiData?.score === null &&
                    <div className={resStyles.top} key={'t12'}>
                            <div className={resStyles.status} key={'t15'}>
                                <Icon name="warning"/>
                                <span key={'t16'}>Необходима проверка!</span>
                            </div>
                    </div>    
                }
                {getTopPart()}
                <div className={resStyles.label} key={'t17'}>Задачи</div>
            <Modal.Body>
                {preparedAnswers && 
                    <div className={resStyles.main} key={'t18'}>
                        <div className={resStyles.answer} key={'t22'}>
                            {preparedAnswers}
                        </div>
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <div className="cor-net__row">
                    {isAuth().role > 0 && 
                        <div className="cor-net__col col-2">
                            {nbiData?.tasks.filter(item => item.isCorrect !== -1).length > 0 ? 
                                getScoreCell() : 'Тест не пройден'
                            }
                        </div>
                    }
                    <div className="cor-net__col col-2">
                        <Button color="primary" onClick={handleClose} key={'t25'}>Закрыть</Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
};

export default NotebooksViewtResultByTeacherDlg;
