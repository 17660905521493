import React from "react";
import Footer from '../Footer';
import {isAuth} from "../../../helpers/auth";
import OnlineTests from '../../OnlineTest/OnlineTests';
import OnlineTestAddEdit from '../../OnlineTest/OnlineTestAddEdit';
import { ContentWrap } from "../ContentParts";

export const DashboardOnlineTest = (props) => { //
    const type = props.type;
    const opt = props.match?.params.opt;
    const id = props.match?.params.id;

    const getSuperAdminDashboard = () => { 
        return (
            <ContentWrap>
                <>
                    {!opt && (<OnlineTests {...props} />)}
                    {opt === 'ot_create' && (<OnlineTestAddEdit onlineTestId={id} {...props} />)}
                </>
                <Footer {...props}/>
            </ContentWrap>
        )};
    
    const getTeacherDashboard = () => { 
        return (
        <ContentWrap>
            {type === 'ot_create' && (<OnlineTestAddEdit onlineTestId={id} {...props} />)}
            <Footer {...props}/>
        </ContentWrap>
    )};

    if (isAuth().role === 3)    
        return getSuperAdminDashboard();
    else 
        return getTeacherDashboard();
}

export default DashboardOnlineTest;
