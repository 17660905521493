// import {  } from '../../actions/';

export const mapStateToProps = (nstate) => {
    return {
        theme: nstate.napp.theme,
        devices: nstate.ndevice.all,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    // setPlay: (bool) => dispatch(setPlay(bool)),
});
