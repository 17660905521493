import { ReactComponent as Create } from '../../../assets/icons/card-menu/01_create.svg';
import { ReactComponent as Check } from '../../../assets/icons/card-menu/02_check.svg';
import { ReactComponent as Rename } from '../../../assets/icons/card-menu/03_rename.svg';
import { ReactComponent as Remove } from '../../../assets/icons/card-menu/04_remove.svg';
import { ReactComponent as Archive } from '../../../assets/icons/card-menu/05_archive.svg';
import { ReactComponent as Copy } from '../../../assets/icons/card-menu/06_copy.svg';
import { ReactComponent as CopyLink } from '../../../assets/icons/card-menu/07_copy-link.svg';
import { ReactComponent as Select } from '../../../assets/icons/card-menu/08_select.svg';
import { ReactComponent as Upload } from '../../../assets/icons/card-menu/09_upload.svg';
import { ReactComponent as Delete } from '../../../assets/icons/card-menu/10_delete.svg';
import UploadCoverButton from '../UploadCoverButton/UploadCoverButton';

export const getCardMenu = () => ({
  /* createTask: {
    title: 'Создать подзадачу',
    icon: <Create />,
  }, */
  markDone: {
    title: 'Отметить выполненной',
    icon: <Check />,
  },
  rename: {
    title: 'Переименовать',
    icon: <Rename />,
  },
  remove: {
    title: 'Переместить',
    icon: <Remove />,
    isDisabled: true,
  },
  archive: {
    title: 'Поместить в архив',
    icon: <Archive />,
  },
  duplicate: {
    title: 'Дублировать',
    icon: <Copy />,
    isDisabled: true,
  },
  copyLink: {
    title: 'Копировать ссылку',
    icon: <CopyLink />,
    isDisabled: true,
  },
  selectTasks: {
    title: 'Выделить несколько задач',
    icon: <Select />,
    isDisabled: true,
  },
  uploadCover: {
    // title: 'Загрузить обложку',
    // icon: <Upload />,
    component: UploadCoverButton,
    // isDisabled: true,
  },
  delete: {
    title: 'Удалить',
    icon: <Delete />,
  },
});