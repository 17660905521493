import React from 'react';
import ss from '../SensorView.module.scss';
import  Icon  from '../../../Icon';
import { logo, list, info, charging, mode, computer, logging } from '../parts';

const SensorViewUniversal = (props) => {
  
    return (
        <div className={ss.root__wrap}>
            <div className={ss.root}>

                {logo()}

                <div className={ss.root__title}>Мультидатчик<br></br>универсальный</div>

                {list()}

                {info()}

                {charging()}

                {mode()}

                {computer()}

                <div className={`${ss.icon} ${ss.rightTop}`}>
                    <div className={ss.icon__wrap}>
                        <div className={ss.icon__list}>
                            <div className={ss.icon__view}>
                                <p>5</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${ss.icon} ${ss.leftBottom}`}>
                    <div className={ss.icon__wrap}>
                        <div className={ss.icon__list}>
                            <div className={ss.icon__view}>
                                <p>1</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${ss.icon} ${ss.leftTop}`}>
                    <div className={ss.icon__wrap}>
                        <div className={ss.icon__list}>
                            <div className={ss.icon__view}>
                                <p>2</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${ss.icon} ${ss.topLeft}`}>
                    <div className={ss.icon__wrap}>
                        <div className={ss.icon__list}>
                            <div className={ss.icon__view}>
                                <p>3</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${ss.icon} ${ss.topRight}`}>
                    <div className={ss.icon__wrap}>
                        <div className={ss.icon__list}>
                            <div className={ss.icon__view}>
                                <p>4</p>
                            </div>
                        </div>
                    </div>
                </div>

                {logging()}


            </div>
            
        </div>
    )
};
export default SensorViewUniversal;

