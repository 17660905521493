import React, {useState, useEffect} from "react";
import {InputLimit, Icon} from '../ui';
import {NAME_LIMIT, SCHEMA_TYPE, ATTACH_TYPE, DESC_LIMIT, uploadRlsFile, deleteRlsFileByName, 
    loadRlsFiles, getFileByName, getDescription} from './sc_utils';
import {useDispatch, useSelector} from "react-redux";
import ImageDlg from "../ui/ModalDialogs/ImageDlg";
import {registerAddNewFile, registerDeleteFile, calcDecriptionSize} from "../ui/utils/gen_utils";
import ScenarioSelectSchemaDlg from './ScenarioSelectSchemaDlg';
import { SensorViewBiology, SensorViewChemistry, SensorViewPhysics, 
    SensorViewUniversal, SensorViewOne }  from "../../rlab/src/components/Template/SensorsView";
import {isAuth} from "../../helpers/auth";
import htmlParser from "html-react-parser";
import ss from './Scenarios.module.scss';
import preview from '../../rlab/src/components/Scenario/ScenarioRunDlg.module.scss';

const ScenarioPage5Composition = ({isTemplate, scComposition, setScComposition, images, setImages}) => {
    const [loadedFileName, setLoadedFileName] = useState('');
    const [showPictureDlg, setShowPictureDlg] = useState(false);
    const [showSchemaDlg, setShowSchemaDlg] = useState(false);
    const [description, setDescription] = useState(scComposition.description);
    const [descriptionCounter, setDescriptionCounter] = useState(0);
    
    const isDisabledTemplate = isTemplate && isAuth().role < 3;
    const dispatch = useDispatch();
    const {lists: {files}} = useSelector(state => state);

    useEffect(() => {
        if (isTemplate) return;
        loadRlsFiles(dispatch);
    }, [dispatch, loadedFileName, isTemplate]);

    useEffect(() => {
        if (!loadedFileName) return;

        if (!isTemplate) {
            const obj = {...scComposition, 
                attachmentType: ATTACH_TYPE.IMAGE, 
                attachmentName: loadedFileName
            };
            setScComposition(obj);
            registerAddNewFile(loadedFileName, images, setImages);
            setLoadedFileName('');
        } else {
            const reader = new FileReader();
            reader.onload = (event) => {
                //DB will keep the file data: name and content
                const fileOpts = {name: loadedFileName.name, content: event.target.result};
                const obj = {...scComposition, 
                    attachmentType: ATTACH_TYPE.IMAGE, 
                    attachmentName: JSON.stringify(fileOpts)
                };
                setScComposition(obj);
                setLoadedFileName('');
            };
            reader.readAsDataURL(loadedFileName); //if isTemplate the param contains the file (not just its name)
        }
    }, [setScComposition, loadedFileName, scComposition, files, images, setImages, isTemplate]);

    const handleName = v => {
        if (v.length > NAME_LIMIT) return;
        setScComposition({...scComposition, name: v});
    };

    useEffect(() => {
        setScComposition({...scComposition, description: description});
    }, [description]); //don't add scComposition

    const handleDescription = v => {
        const counter = calcDecriptionSize(v);
        //if (counter > DESC_LIMIT) return;
        setDescription(v);
        setDescriptionCounter(counter);
    };

    const handleAttachImage = (e) => {
        const file = e.target.files[0];
        if (!isTemplate)
            uploadRlsFile(files, file, setLoadedFileName); //keep file name 
        else
            setLoadedFileName(file); //keep the file name and content
        e.target.value = '';
    };

    const handleDeleteImage = () => {
        if (scComposition.attachmentType === ATTACH_TYPE.IMAGE && !isTemplate) {
            registerDeleteFile(scComposition.attachmentName, images, setImages, dispatch, deleteRlsFileByName);
        }
        setScComposition({...scComposition, attachmentName: ''});
    };

    const getName = () => {
        return (
            <div key={'sind01'}>
                <div className="cor-net__label" key={'sind02'}>Название схемы</div>
                <div key={'sind03'}>
                    <InputLimit
                        value={scComposition.name} 
                        placeholder={'Введите название схемы'}
                        key={'sind04'} 
                        disabled={isDisabledTemplate}
                        onInput={e => handleName(e.target.value)} 
				    />
                </div>
            </div>
        );
    };

    const getAddSchema = () => {
        return (
            <label 
                key={'lbl01'} 
                className={ss.btnFile} disabled={isDisabledTemplate}
                onClick={() => setShowSchemaDlg(true)} >
                Выбрать схему
            </label>
        );
    };

    const getAddImage = () => {
        return (  
            <label key={'lbl01'} className={ss.btnFile} disabled={isDisabledTemplate}>
                Выбрать фото
                <input 
                    type="file" 
                    accept={"image/*"} 
                    key={'lbl03'} 
                    onChange={(e) => handleAttachImage(e)} 
                />
            </label>
        );
    };
    
    const getDesc = () => {
        return getDescription(descriptionCounter, DESC_LIMIT, 'Описание пункта', 
            description, handleDescription, isDisabledTemplate, 0);
    };

    const handleSchema = value => {
        const obj = {...scComposition, 
            attachmentType: ATTACH_TYPE.SCHEMA, 
            attachmentName: value,
        };
        setScComposition(obj);
        setShowSchemaDlg(false);
    };

    return (
        <div className={ss.root}>
            <div className="cor-net grow" key={'sind30'}>
                <div className="cor-net__section" key={'sind32'}>
                    {!scComposition || !scComposition.attachmentName ? 
                        <>
                            <div className={"cor-net__row"} key={'sind34'}>
                                <div className="cor-net__col col-2" key={'sind35'}>
                                    {getName()}
                                </div>
                                <div className="cor-net__col col-8" key={'sind36'}>
                                    <div className="cor-net__label hide"></div>
                                    {getAddSchema()}
                                </div>
                                <div className="cor-net__col col-8" key={'sind37'}>
                                    <div className="cor-net__label hide"></div>
                                    {getAddImage()}
                                </div>
                            </div>
                            <div className={"cor-net__row"}  key={'sind37'}>
                                <div className="cor-net__col col-1" key={'sind38'}>
                                    {getDesc()}
                                </div>
                            </div>
                        </>
                    :
                        <>
                            <div className={"cor-net__row"} key={'sind40'}>
                                <div className="cor-net__col col-2" key={'sind41'}>
                                    {getName()}
                                </div>
                            </div>
                            <div className="cor-net__row">
                            </div>

                            <div className={ss.img_full} key={'sind10'}>
                                {scComposition.attachmentType === ATTACH_TYPE.IMAGE ?
                                    scComposition.attachmentName ? 
                                    <img 
                                        onClick={() => setShowPictureDlg(true)}
                                        src={!isTemplate ? 
                                            getFileByName(files, scComposition?.attachmentName) : 
                                            JSON.parse(scComposition.attachmentName).content}
                                        alt='' key={'sind11'}  
                                    />
                                    : <></>
                                :
                                    <div className={ss.selectScheme__sensore_view}>
                                        {scComposition.attachmentName === SCHEMA_TYPE.BIOLOGY && <SensorViewBiology />}
                                        {scComposition.attachmentName === SCHEMA_TYPE.CHEMISTRY && <SensorViewChemistry />}
                                        {scComposition.attachmentName === SCHEMA_TYPE.PHYSICS && <SensorViewPhysics />}
                                        {scComposition.attachmentName === SCHEMA_TYPE.UNIVERSAL && <SensorViewUniversal />}
                                        {scComposition.attachmentName === SCHEMA_TYPE.ONE && <SensorViewOne />}
                                    </div>
                                }

                                {!isDisabledTemplate && 
                                <div 
                                    className={ss.img__trash} 
                                    key={'sind52'}
                                    disabled={isDisabledTemplate}
                                    onClick={handleDeleteImage}>
                                    <Icon name={'trash'}/>
                                </div>
                                }
                            </div>

                    
                            <div className={"cor-net__row"} key={'sind48'}>
                                <div 
                                    className="cor-net__col col-1" 
                                    key={'sind49'}>
                                    {getDesc()}
                                </div>
                            </div>
                        </>
                    }
                </div>

                {showPictureDlg && scComposition &&
                <ImageDlg
                    showModal={showPictureDlg}
                    setShowModal={setShowPictureDlg}
                    file={{Url: 
                        scComposition.attachmentType === ATTACH_TYPE.SCHEMA ? scComposition.attachmentName :
                        scComposition.attachmentType === ATTACH_TYPE.IMAGE && !isTemplate ? 
                            getFileByName(files, scComposition.attachmentName) : 
                            JSON.parse(scComposition.attachmentName).content
                    }}
                />
                }

                {showSchemaDlg && <ScenarioSelectSchemaDlg 
                    showDlg={showSchemaDlg}
                    setShowDlg={setShowSchemaDlg}
                    attachmentName={scComposition && scComposition?.attachmentType === ATTACH_TYPE.SCHEMA ? scComposition.attachmentName : ''}
                    handleYes={handleSchema}
                />}
            </div>

            <div className={ss.preview__sticky}>
                <div className={ss.preview__title}>Предварительный просмотр</div>
                <div className={ss.preview}>
                    <div className={preview.open}>
                        <div className={preview.toggle}>
                            <b>Схема сборки</b>
                        </div>
                        <div className={preview.body}>
                            <div className={preview.content}>
                                <div className={preview.content__scrolled}>
                                    <div className={preview.title}>
                                        {scComposition.name}
                                    </div>
                                    <div className={preview.description}>
                                        {htmlParser(scComposition.description)}
                                    </div>
                                    {scComposition.attachmentType === ATTACH_TYPE.IMAGE ?
                                        scComposition?.attachmentName ? 
                                        <div className={preview.img}>
                                            <img 
                                                src={!isTemplate ? 
                                                    getFileByName(files, scComposition.attachmentName) : 
                                                    JSON.parse(scComposition.attachmentName).content}
                                                alt='' key={'pr1'}  
                                            />
                                        </div>
                                        : <></>
                                    :
                                        <div className={preview.sensor}>
                                            {scComposition.attachmentName === SCHEMA_TYPE.BIOLOGY && <SensorViewBiology />}
                                            {scComposition.attachmentName === SCHEMA_TYPE.CHEMISTRY && <SensorViewChemistry />}
                                            {scComposition.attachmentName === SCHEMA_TYPE.PHYSICS && <SensorViewPhysics />}
                                            {scComposition.attachmentName === SCHEMA_TYPE.UNIVERSAL && <SensorViewUniversal />}
                                            {scComposition.attachmentName === SCHEMA_TYPE.ONE && <SensorViewOne />}
                                        </div>
                                    }
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default ScenarioPage5Composition; 
