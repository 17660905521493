import React, {useState, useEffect, useRef} from "react";
import { Icon } from '../ui';
import {toast} from "react-toastify";
import ModalConfirmDeleteDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import ModalConfirmHideDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import PISelectGraphDlg from './PISelectGraphDlg';

const PIOneName = ({isEdit,  //is edit mode (true = проведение, false - просмотр)
        initName, setEditedName, uniqueIndex, doDeleteHideSection, 
        isTable, workPlots, tableUid, handleSelectGraph
    }) => {
    const [currName, setCurrName] = useState(undefined);
    const [isProgress, setIsProgress] = useState(false);
    const [confirmQuestion, setConfirmQuestion] = useState('');
	const [showConfirmDeleteDlg, setShowConfirmDeleteDlg] = useState(false);
	const [showConfirmHideDlg, setShowConfirmHideDlg] = useState(false);
	const [showSelectGraphDlg, setShowSelectGraphDlg] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        if (!isProgress) return;
        inputRef.current.focus();
    }, [isProgress]);

    const handleEditNameStart = (name) => {
        setIsProgress(true);
		setCurrName(name);
	};

	const handleEditNameComplete = (isSave) => {
        if (isSave && currName.trim() === '') {
            toast.warn("Введите название.");
            return;
        }

		if (isSave) {
            setEditedName(isSave, currName.trim());
		} 
        setIsProgress(false);
		setCurrName(undefined);
	};

    const handleDeleteSection = () => {
        setConfirmQuestion("Вы действительно хотите удалить раздел '" + (currName ? currName : initName) + "' ?");
        setShowConfirmDeleteDlg(true);
    };
    const handleDeleteSectionYes = () => {
        setShowConfirmDeleteDlg(false);
        doDeleteHideSection(true);
    };

    const handleSelectFraph = () => {
        setShowSelectGraphDlg(true);
    };

    const handleHideSection = () => {
        setConfirmQuestion("Вы действительно хотите скрыть раздел '" + (currName ? currName : initName) + "' ?");
        setShowConfirmHideDlg(true);
    };
    const handleHideSectionYes = () => {
        setShowConfirmHideDlg(false);
        doDeleteHideSection(false);
    };

    const editName = () => {
		let content;

	    if (!currName && !isProgress) {
            //view the name:
			content = (
                <span className="pi-actions__title" key={'etname01'+uniqueIndex}>{initName}</span>
			);
		} else {
            //editing the name in progress:
			content = (
                <span className="pi-actions__input" key={'sp10'+uniqueIndex}>
                    <textarea 
                        ref={inputRef}
                        key={'tx02'+uniqueIndex} 
                        value={currName} 
                        onInput={e => setCurrName(e.target.value)} 
                    />
                </span> 
			);
		}
        const buttons = (
            <div className="pi-actions__options" key={'opt01'+uniqueIndex}>
                {!isProgress && 
                <>
                    <div className="pi-options">
                        <Icon className="pi-options__toggle" name="dots" />
                        <div className="pi-options__content">
                            <Icon tp="Редактировать" key={'ic01'+uniqueIndex} name="brush" onClick={() => handleEditNameStart(initName, uniqueIndex)}/>
                            <Icon tp="Скрыть" key={'ic02'+uniqueIndex} name="eye-hide" onClick={() => handleHideSection()} />
                            <Icon tp="Удалить" key={'ic03'+uniqueIndex} name="trash" onClick={() => handleDeleteSection()}/>
                            {isTable &&
                            <Icon tp="Выбрать график" key={'ic04'+uniqueIndex} name="graph_sm" onClick={() => handleSelectFraph()}/>
                            }
                        </div>
                    </div>
                </>
                }
    
                {isProgress && 
                <>
                    <Icon key={'ic10'+uniqueIndex} name="save" onClick={() => handleEditNameComplete(true)}/>
                    <Icon key={'ic11'+uniqueIndex} name="close" onClick={() => handleEditNameComplete(false)} />
                </>
                }
            </div>
        );

        return (
        <div className="pi-actions">
            {content}
            {buttons}
        </div>);
    };

	const viewName = () => {
		return (
			<div className="cor-net__title" key={'tname01'+uniqueIndex}>{initName}</div>
        );
    };

    const nameInfo = isEdit ? editName() : viewName();

    return (
        <>
            {nameInfo}

            {showConfirmDeleteDlg &&
			<ModalConfirmDeleteDialog
				showConfirmDlg={showConfirmDeleteDlg} 
				handleNo={() => setShowConfirmDeleteDlg(false)}
				handleYes={handleDeleteSectionYes}
				question={confirmQuestion}
			/>
            }
            {showConfirmHideDlg &&
			<ModalConfirmHideDialog
				showConfirmDlg={showConfirmHideDlg} 
				handleNo={() => setShowConfirmHideDlg(false)}
				handleYes={handleHideSectionYes}
				question={confirmQuestion}
			/>
            }

            {isTable && showSelectGraphDlg && <PISelectGraphDlg 
                showDlg={showSelectGraphDlg} 
                setShowDlg={setShowSelectGraphDlg}
                workPlots={workPlots}
                tableUid={tableUid}
                handleSelectGraph={handleSelectGraph}
            />}
        </>
    );
}

export default PIOneName; 