import { connect } from 'react-redux';
import {
    mapRlabStateToProps,
    mapRlabDispatchToProps,
} from './redux';
import RlabNotify from "./RlabNotification";

const RlabNotification = connect(mapRlabStateToProps, mapRlabDispatchToProps)(RlabNotify);

export { RlabNotification }
