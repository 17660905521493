import React, { useState } from 'react';
import Styles from './Aside.module.scss';
import Sensors from '../../Sensors';
import AsideFilter from '../AsideFilter';
import EMGMeter from '../../EMGMeter';
import { Icon } from '../../ui';

const Aside = ({ sensors, imported }) => {
    const [visible, setVisible] = useState(true);
    // console.log(port);
    return (
        (sensors.length > 0 || imported.length > 0) && (
            <div className={visible ? Styles.aside : Styles.asideHidden}>
                <div className={Styles.head}>
                    <div className={Styles.title}>Датчики</div>
                    <div
                        className={visible ? Styles.toggle : Styles.toggleInactive}
                        onClick={() => setVisible(!visible)}
                    >
                        <Icon name={visible ? 'toggler' : 'sensors'} />
                    </div>
                </div>
                <Sensors />
                <AsideFilter />
                <EMGMeter />
            </div>
        )
    );
};
export default Aside;
