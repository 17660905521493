import React, { useState, useEffect, memo } from 'react';
import {toast} from "react-toastify";
import Icon from '../Icon';
import { Dropdown, Switcher, Range } from 'rlabui';
import { OscilloChannel } from '../OscilloSettings';
import SensorValue from './SensorValue';

import ss from './Sensor.module.scss';
import SensorResetButton from './SensorResetButton';

const Sensor = (props) => {
    const {
        sensor,
        index,
        port,
        played,
        // paused,
        styles,
        defaultColors,
        chartDisabled,
        deviceResponse,
        frequency,
        sensorMode,
        // asideData,
        // sensorImported,
        // sensorWrited,
        isOscilloscope,
        setSensorMode,
        sendCommand,
        setSensorFrequency,
        addChartDisabled,
        deleteChartDisabled,
        measunits,
        sensorsUnits,
        setSensorUnit,
        setSensorGradOffset,
        setGraduationModal,
        // setDeviceResponse,
        isCor,
        setShowUnitInfo,
        setUnitInfo,
        demo,
        setDemoSettings,
        connectedCount,
    } = props;

    const { num, name, unit, modes, single, roundDigit, quantity } = sensor;
    // const defaultData = [{ x: 0, y: 0, count: 0 }];
    // const indexOffset = index - (sensorImported.length + sensorWrited.length);
    // const value = asideData[indexOffset] ? asideData[indexOffset] : defaultData;

    const [selected, setSelected] = useState(true);
    const [collapsed, setCollapsed] = useState(false);
    const [options, setOptions] = useState([]);
    const [freq, setFrequency] = useState(10);
    const [isImported, setImported] = useState(false);
    const [isWrited, setWrited] = useState(false);
    const [modeIndex, setModeIndex] = useState(0);
    const [units, setUnits] = useState();

    // console.log('Sensor');

    const color = styles[num]?.color ? styles[num]?.color : defaultColors[index].val;

    useEffect(() => {
        measunits[unit] && setUnits(measunits[unit]);
    }, [measunits, unit]);

    useEffect(() => {
        if (sensorsUnits[num]) {
            setSensorGradOffset({ key: num, data: null });
        }
    }, [sensorsUnits, num, setSensorGradOffset]);

    useEffect(() => {
        if (typeof num === 'string') {
            if (num.charAt(0) === 'l') {
                setWrited(true);
            } else {
                setImported(true);
            }
        } else {
            setImported(false);
            setWrited(false);
        }
    }, [num]);

    useEffect(() => {
        for (var key in sensorMode) {
            if (+key === num) {
                // console.log(sensorMode[+key]);
                setModeIndex(sensorMode[+key]);
                if (demo.length) {
                    const modKey = 'mod' + num;
                    setDemoSettings({ key: demo[0], value: { [modKey]: sensorMode[+key] } });
                }
            }
        }
    }, [sensorMode, demo, setModeIndex, setDemoSettings]);

    useEffect(() => {
        if (port && modes?.length && !options.length) {
            if (!isOscilloscope) {
                if (single) {
                    sendCommand('gm');
                } else {
                    if (modes[0].global) {
                        sendCommand(`gm ${num}`);
                    } else {
                        sendCommand(`gm ch${num}`);
                    }
                }
                const opt = [...options];
                if (modes.length && !options.length) {
                    modes.forEach(({ modeName: label, global }, i) => opt.push({ label, value: i, id: i, global }));
                }
                setOptions(opt);
            }
        }
    }, [modes, isOscilloscope, options]);

    useEffect(() => {
        if (modes?.length) {
            const resp = deviceResponse.split(' ').filter((e) => e);
            const respStatus = +resp.at(-1);
            const commands = ['sm', 'gm'];
            // console.log(resp);

            const checkComands = commands.filter((e) => deviceResponse.includes(e))[0];
            // console.log('deviceResponse', deviceResponse);
            if (checkComands) {
                if (single) {
                    if (resp[1]) {
                        if (resp[1] === 'error') {
                            setSensorMode({ id: 0, data: 0 });
                        }
                        if (typeof +resp[1] === 'number') {
                            setSensorMode({ id: 0, data: +resp[1] });
                        }
                    }
                } else {
                    // console.log(deviceResponse);
                    if (respStatus >= 0) {
                        if (+resp[1].replace('ch', '') === num) {
                            if (checkComands === 'sm') {
                                if (resp[3] < 0) {
                                    toast.warn(`Произошла ошибка при смене режима измерения для ${name}`);
                                } else {
                                    toast.success(`Сменился режим измерения для ${name}`);
                                }
                            }
                            if (checkComands === 'gm') {
                                if (resp[2]) {
                                    if (resp[2] === 'error') {
                                        setSensorMode({ id: num, data: 0 });
                                    }
                                    if (typeof +resp[2] === 'number') {
                                        setSensorMode({ id: num, data: +resp[2] });
                                    }
                                }
                            }
                        }
                        if (!deviceResponse.includes('ch')) {
                            if (checkComands === 'sm') {
                                sendCommand(`gm`);
                                toast.success(`Сменился режим измерения для ${name}`);
                            }
                            if (checkComands === 'gm') {
                                const cOpt = +resp.at(-1);
                                // console.log(options[cOpt]);
                                if (typeof cOpt === 'number') {
                                    setSensorMode({ id: num, data: cOpt });
                                }
                            }
                        }
                    } else {
                        if (+resp[1].replace('ch', '') === num && checkComands === 'sm') {
                            toast.warn(`Произошла ошибка при смене режима измерения`);
                        }
                    }
                }
                if (isOscilloscope) {
                    if (resp[0] === `gm${num}`) {
                        if (resp[1]) {
                            if (resp[1] === 'error') {
                                setSensorMode({ id: num, data: 0 });
                            }
                            if (typeof +resp[1] === 'number') {
                                setSensorMode({ id: num, data: +resp[1] });
                            }
                        }
                    }
                }
                // sendCommand('');
                // setDeviceResponse('');
            } else {
                // console.log(sensorMode[num]);
                if (typeof sensorMode[num] !== 'number') {
                    setSensorMode({ id: num, data: 0 });
                }
            }
        }
    }, [deviceResponse]);

    useEffect(() => {
        const fr = { ...frequency };
        fr[num] = freq;
        setSensorFrequency(fr);
    }, [freq, num, setSensorFrequency]);

    useEffect(() => {
        const disabled = chartDisabled.filter((id) => id === num);
        if (disabled.length) {
            setSelected(false);
        } else {
            setSelected(true);
        }
    }, [chartDisabled, num]);

    const handleShowUnit = () => {
        setShowUnitInfo(true);
        setUnitInfo({ unit: unit, quantity: quantity });
    };

    return (
        <>
            <div
                className={selected ? ss.root : ss.rootDisabled}
                data-learn="19,20,21,22,23"
                data-learn-position="right"
                data-learn-border="19,20,21,22,23"
            >
                <div
                    className={ss.head}
                    style={{ borderLeftColor: selected ? color : '#03C98D' }}
                >
                    <span data-learn-border="20">
                        <Switcher
                            checked={!chartDisabled.find((e) => e === num)}
                            value={name}
                            title="Скрыть датчик"
                            onChange={() => {
                                const disabled = chartDisabled.find((e) => e === num);
                                if (disabled) {
                                    deleteChartDisabled(num);
                                } else {
                                    addChartDisabled(num);
                                }
                                // setSelected(!selected);
                            }}
                        />
                    </span>
                    <div className={ss.name}>
                        {isImported ? `(Имп) ${name}` : isWrited ? `(Лог) ${name}` : name}
                        {/* {quantity && (
                            <i
                                className={ss.info}
                                // title={quantity}
                            >
                                <span>{quantity}</span>
                            </i>
                        )} */}
                    </div>
                    <span data-learn-border="19">
                        <Icon
                            tag="button"
                            name={'caret'}
                            className={collapsed || isImported || isWrited ? ss.toggleClosed : ss.toggle}
                            title="Свернуть"
                            disabled={isImported || isWrited}
                            onClick={() => {
                                setCollapsed(!collapsed);
                            }}
                        />
                    </span>
                </div>
                <div className={collapsed || isImported || isWrited ? ss.bodyClosed : ss.body}>
                    {!isImported && !isWrited && (
                        <div className={ss.values}>
                            <div className={ss.current}>
                                <SensorValue
                                    num={num}
                                    round={roundDigit}
                                />
                            </div>
                            <span data-learn-border="23">
                                {units?.length && !isOscilloscope ? (
                                    <Dropdown
                                        value={sensorsUnits[num]?.name ? sensorsUnits[num].name : units[0].Name}
                                        clicked={true}
                                        disabled={played}
                                        dropPosition="right"
                                        className={ss.unit}
                                    >
                                        {units?.map(({ Name: name, CoeffK: koeff, CoeffB: koeffB }, i) => {
                                            return (
                                                <Dropdown.Item
                                                    key={i}
                                                    onClick={() => setSensorUnit({ key: num, unit: { name, koeff, koeffB } })}
                                                >
                                                    {name}
                                                </Dropdown.Item>
                                            );
                                        })}
                                    </Dropdown>
                                ) : (
                                    <span className={ss.unit}>{unit}</span>
                                )}
                            </span>
                        </div>
                    )}
                    {!isOscilloscope && modes?.length ? (
                        <div className={ss.opt}>
                            <Dropdown
                                value={options[modeIndex] ? options[modeIndex].label : 'Выберите режим'}
                                clicked={true}
                                disabled={!selected || played}
                                // dropPosition="right"
                                className={ss.mode}
                            >
                                {options.length > 0 &&
                                    options.map(({ value, label, global }, i) => (
                                        <Dropdown.Item
                                            key={'modeelem' + i}
                                            onClick={() => {
                                                let command = `sm ch${num} ${value}`;
                                                if (single || global) {
                                                    command = `sm ${value}`;
                                                }
                                                sendCommand(command);
                                                setSensorMode({ id: num, data: value });
                                            }}
                                        >
                                            {label}
                                        </Dropdown.Item>
                                    ))}
                            </Dropdown>
                        </div>
                    ) : (
                        ''
                    )}
                    {!isOscilloscope && !isImported && !isWrited && (
                        <div
                            className={ss.progress}
                            data-learn-border="22"
                        >
                            <div className={ss.freq}>{freq}Гц</div>
                            <Range
                                min={1}
                                max={200}
                                step={0.5}
                                // vertical={true}
                                value={freq}
                                minus={() => setFrequency((value) => (value > 1 ? +value - 0.5 : 1))}
                                plus={() => setFrequency((value) => (value < 200 ? +value + 0.5 : 200))}
                                buttons={true}
                                disabled={played || !selected}
                                onChange={({ target: { value } }) => setFrequency(+value)}
                            />
                        </div>
                    )}
                    {isOscilloscope && <OscilloChannel index={index} />}
                    {!isImported && !isWrited && (
                        <div className={ss.footer}>
                            <div className={ss.reset}>
                                <SensorResetButton
                                    num={num}
                                    round={roundDigit}
                                    unit={unit}
                                    quantity={quantity}
                                />
                            </div>

                            <div className={ss.footer_action}>
                                {isCor && (
                                    <Icon
                                        name="info"
                                        onClick={handleShowUnit}
                                    />
                                )}
                                <Icon
                                    name="gear"
                                    onClick={() =>
                                        !isOscilloscope && port && connectedCount === 1
                                            ? setGraduationModal(num)
                                            : toast.info('Градуировка доступна только для одного подключенного устройства')
                                    }
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default memo(Sensor);
