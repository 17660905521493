import React, { useEffect, useState, useRef } from 'react';
import * as XLSX from 'xlsx';
import { Icon } from '../ui';
import Moment from 'moment';
import chartStyles from '../../styles/charts';
import ss from './ImpotrExport.module.scss';

const ImportExport = (props) => {
    const {
        port,
        records,
        played,
        devices,
        setSensorImported,
        setChartMarkers,
        sendCommand,
        setPrintModal,
        colors,

        writeStatus,
        setWriteStatus,

        file,
        setFile,
        fileExtension,
        setFileExtension,
        setExportModal,
        setEventLoader,
    } = props;

    const [recordTime, setRecordTime] = useState(0);
    const timerRef = useRef();
    const csvRef = useRef();
    const xlsRef = useRef();

    useEffect(() => {
        if (writeStatus) {
            const timer = new Date().valueOf();
            timerRef.current = setInterval(function () {
                setRecordTime(new Date().valueOf() - timer);
            }, 1000);
        } else {
            clearInterval(timerRef.current);
        }
    }, [writeStatus]);

    useEffect(() => {
        const key = Object.keys(records)[0];
        const isData = records[key]?.value[0];
        if (fileExtension && isData?.length) {
            setSensorImported([]);
            setFileExtension();
        }
        // Hide exportToFile linter
        // eslint-disable-next-line
    }, [fileExtension, records, setSensorImported, setFileExtension]);

    useEffect(() => {
        if (file) {
            importFromFile();
        }
        // Hide importFromFile linter
        // eslint-disable-next-line
    }, [file]);

    useEffect(() => {
        if (played) {
            xlsRef.current.value = null;
            csvRef.current.value = null;
            setFile('');
            setFileExtension();
        }
    }, [played, setFile, setFileExtension]);

    const importFromFile = async () => {
        const reader = new FileReader();
        setSensorImported([]);
        setEventLoader('Идет загрузка...');

        reader.onload = async (e) => {
            const data = e.target.result;
            const readedData = XLSX.read(data, { type: 'binary' });
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];

            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });

            dataParse.forEach((row) => {
                for (let i = 0; i < row.length; i++) {
                    const el = row[i];
                    if (!el && el !== 0) {
                        row[i] = '';
                    }
                }
            });

            let ranges = [];
            let markers = [];
            let tables = [];
            let imported = [];
            let importedMarks = {};
            let sensors = [];
            let dataRow = 0;
            let mark = '';

            dataParse.forEach((item, i) => {
                if (i === 1) {
                    let index = 2;
                    let count = 0;
                    item.forEach((sens, k) => {
                        if (k === index) {
                            let current = sens.split('.');
                            let info = current[1].split(',');
                            let device = devices.find((device) => {
                                if (device.attributes.name === info[0]?.trim()) {
                                    return device;
                                } else {
                                    return false;
                                }
                            });

                            if (device) {
                                sensors.push({ value: [], key: 'imp' + device.attributes.key, num: 'imp' + count, name: info[0]?.trim(), unit: info[1], markers: [] });
                            } else {
                                sensors.push({ value: [], key: 'imp' + count, num: 'imp' + count, name: info[0]?.trim(), unit: info[1], markers: [] });
                            }
                            index += 4;
                            count++;
                        }
                    });
                }

                if (item.includes('Диапазоны')) {
                    dataRow = i + 2;
                    mark = 'ranges';
                }

                if (item.includes('Маркеры')) {
                    dataRow = i + 2;
                    mark = 'markers';
                }

                if (item.includes('Таблицы')) {
                    dataRow = i + 2;
                    mark = 'tables';
                }

                if (i === dataRow) {
                    if (item.length !== 4) {
                        item.push(undefined);
                    }
                    switch (mark) {
                        case 'ranges':
                            ranges.push(item);
                            break;
                        case 'markers':
                            markers.push(item);
                            break;
                        case 'tables':
                            tables.push(item);
                            break;
                        default:
                    }
                    dataRow++;
                }
            });

            let roundDigit = 2;
            sensors.forEach((item, i) => {
                const { name, unit, num } = item;
                const isButton = name === 'Сигнал';
                let device = devices.find((device) => {
                    if (device.attributes.name === name) {
                        roundDigit = device.attributes.roundDigit;
                        return device;
                    } else {
                        return false;
                    }
                });

                if (device || isButton) {
                    let channels = [];
                    let checkChannels = device?.getElementsByTagName('channel');
                    const key = isButton ? 'impButton' : 'imp' + (device?.attributes?.key || num);

                    if (checkChannels?.length) {
                        channels = checkChannels.map((channel, num) => {
                            return {
                                color: colors[i]?.val,
                                key: 'imp' + device?.attributes?.key + num,
                                name: channel?.attributes?.name,
                                num,
                                parent: 'imp' + device?.attributes?.key,
                                unit: channel?.attributes?.unit,
                                value: [[], []],
                                imported: true,
                            };
                        });
                    }

                    imported[i] = {
                        channels,
                        color: colors[i].val,
                        key,
                        name,
                        num,
                        quantity: device?.attributes?.quantity + ' (Имп.)' || '',
                        roundDigit,
                        unit,
                        value: [[], []],
                        imported: true,
                    };

                    tables?.forEach((row, k) => {
                        let size = 4,
                            sub = [],
                            x = null,
                            y = null;
                        for (let i = 0; i < Math.ceil(row.length / size); i++) {
                            sub[i] = row.slice(i * size, i * size + size);
                        }
                        if (sub[i]?.length) {
                            x = sub[i][1] !== '' ? +sub[i][1] : null;
                            y = sub[i][2] !== '' ? +sub[i][2] : null;
                        }
                        if (x !== null && y !== null) {
                            imported[i].value[0].push(x);
                            imported[i].value[1].push(y);
                        }
                    });
                }
            });

            const sendAllMarkers = async () => {
                const parsingRows = (arr) => {
                    let semiArr = {};

                    arr.forEach((row, k) => {
                        let paramsCount = 4;
                        let paramsStr = '';
                        for (var i = 0; i < row.length; i++) {
                            paramsStr += row[i] + ',';
                            if (paramsCount === i + 1) {
                                let index = paramsCount / 4 - 1;
                                let key = 'imp' + index;
                                let sensData = paramsStr.split(',');
                                if (!semiArr[key]) {
                                    semiArr[key] = [];
                                }
                                semiArr[key].push(sensData);
                                paramsStr = '';
                                paramsCount += 4;
                            }
                        }
                    });

                    return semiArr;
                };

                const rangeArr = parsingRows(ranges);
                const markersArr = parsingRows(markers);

                // Диапазоны
                for (let i in sensors) {
                    const { name, num } = sensors[i];
                    let device = devices?.find((device) => device.attributes.name === name);
                    if (device) {
                        let key = 'imp' + (device?.attributes?.key || i);

                        if (rangeArr[num]) {
                            let row = {};
                            let index = 1;
                            rangeArr[num].forEach((item, k) => {
                                if (item[0] !== 'undefined') {
                                    let box = 'box1';
                                    let start = 'box' + index + 'start';
                                    let end = 'box' + index + 'end';

                                    if (k % 2 === 0) {
                                        row[start] = {
                                            type: 'line',
                                            id: start,
                                            label: start,
                                            resizer: true,
                                            value: +item[1] * 1000,
                                            yValue: +item[2],
                                        };
                                        row[box] = {
                                            type: 'box',
                                            id: box,
                                            backgroundColor: chartStyles.boxes.chart[box],
                                            borderWidth: 0,
                                            min: +item[1] * 1000,
                                            yMin: +item[2],
                                        };
                                    }

                                    if (k % 2 === 1) {
                                        row[end] = {
                                            type: 'line',
                                            id: end,
                                            resizer: true,
                                            value: +item[1] * 1000,
                                            yValue: +item[2],
                                        };
                                        row[box].max = +item[1] * 1000;
                                        row[box].yMax = +item[2];
                                        index++;
                                    }
                                }
                            });
                            importedMarks[key] = { ...importedMarks[key], ...row };
                        } else {
                            importedMarks[key] = { ...importedMarks[key], ...{} };
                        }
                    }
                }

                // Маркеры
                for (let i in sensors) {
                    const { name, num } = sensors[i];
                    let device = devices?.find((device) => device.attributes.name === name);

                    if (device) {
                        const key = 'imp' + (device?.attributes?.key || i);
                        let row = {};

                        if (markersArr[num]) {
                            markersArr[num].forEach((item, i) => {
                                const time = +item[1].replace('.', '');
                                const value = +item[2];
                                const id = 'line' + time;
                                row[id] = {
                                    id,
                                    label: value,
                                    type: 'line',
                                    yValue: value,
                                    value: time,
                                };
                            });
                            importedMarks[key] = { ...importedMarks[key], ...row };
                        } else {
                            importedMarks[key] = { ...importedMarks[key], ...{} };
                        }
                    }
                }

                for (var key in importedMarks) {
                    setChartMarkers({ key, data: importedMarks[key] });
                }
            };

            if (imported.length) {
                try {
                    await sendAllMarkers();
                    setSensorImported([...imported.filter((e) => e)]);
                    setEventLoader();
                } catch (e) {
                    console.log('Impotr error', e);
                    setEventLoader();
                }
            }
        };
        reader.readAsBinaryString(file);
        xlsRef.current.value = null;
        csvRef.current.value = null;
        setFileExtension();
        setFile('');
    };

    return (
        <div className={ss.docs}>
            {writeStatus ? (
                <button
                    className={ss.toggle + ' ' + ss.item + ' ' + ss.stop}
                    onClick={() => {
                        sendCommand('wlg 0');
                        setWriteStatus(false);
                    }}
                >
                    <Icon name="stop" />
                    <span>
                        Стоп
                        <em>{Moment(recordTime).utcOffset(0).format('H:mm:ss')}</em>
                    </span>
                </button>
            ) : (
                <div className={ss.drop}>
                    <div className={ss.toggle + ' ' + ss.item}>
                        <Icon name="import" />
                        <span>Импорт</span>
                    </div>
                    <div className={ss.list}>
                        {/* <button
                            className={ss.item}
                            disabled={played || !port}
                            onClick={() => {
                                sendCommand('wlg 1');
                                setWriteStatus(true);
                            }}
                        >
                            <Icon name="record"/>
                            <span>Запись</span>
                        </button> */}
                        <label
                            className={ss.item}
                            disabled={played}
                        >
                            <Icon
                                name="xls"
                                className={ss.xls}
                            />
                            <span>XLS</span>
                            <input
                                ref={xlsRef}
                                accept=".xls,.xlsx"
                                type="file"
                                onChange={(e) => setFile(e.target.files[0])}
                            />
                        </label>
                        <label className={ss.item}>
                            <Icon name="csv" />
                            <span>CSV</span>
                            <input
                                ref={csvRef}
                                disabled={played}
                                accept=".csv"
                                type="file"
                                onChange={(e) => setFile(e.target.files[0])}
                            />
                        </label>
                    </div>
                </div>
            )}
            <div className={ss.drop}>
                <div className={ss.toggle + ' ' + ss.item}>
                    <Icon name="export" />
                    <span>Экспорт</span>
                </div>
                <div className={ss.list}>
                    <button
                        className={ss.item}
                        disabled={played || !port}
                        onClick={() => setExportModal('xlsx')}
                    >
                        <Icon
                            name="xls"
                            className={ss.xls}
                        />
                        <span>XLS</span>
                    </button>
                    <button
                        className={ss.item}
                        disabled={played || !port}
                        onClick={() => setExportModal('csv')}
                    >
                        <Icon name="csv" />
                        <span>CSV</span>
                    </button>
                </div>
            </div>
            <Icon
                tag="button"
                name="printer"
                title="Печать"
                disabled={played || !port}
                className="docs__btn"
                onClick={() => setPrintModal(true)}
            />
        </div>
    );
};
export default ImportExport;
