const initialState = {
    modal: false,
    camera: true,
    screen: false,
    record: false,
};

const camsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'TOGGLE_MODAL':
            return {
                ...state,
                modal: action.payload,
            };

        case 'SET_CAM':
            return {
                ...state,
                camera: action.payload,
            };

        case 'SET_SCREEN':
            return {
                ...state,
                screen: action.payload,
            };

        case 'SET_RECORD':
            return {
                ...state,
                record: action.payload,
            };

        default:
            return state;
    };
};

export default camsReducer;
