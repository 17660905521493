import React, {useState} from 'react';
import {connect} from "react-redux";
import {Icon} from "../../ui";
import packageJson from '../../../rlab-neuro/package.json';
import CorVersion from "../Header/CorVersion";
import {newsApi} from "../../../api/api";
import {mapNeuroDispatchToProps, mapNeuroStateToProps} from "./redux";

const VERSION = packageJson.version;

const RlabNeuroNav = (props) => {
    const {
        setResetModal,
        setAboutModal
    } = props;

    const [modal, toggleModal] = useState(false);
    const [versionList, setVersionList] = useState([]);

    return (
        <>
            <div className="cor_footerNav__item">
                <div className="cor_footerNav__label">Файл</div>
                <div className="cor_footerNav__btns">
                    <div className="cor_footerNav__btn cor_footerNav__btn_drop">
                        <Icon name="file"/>
                        <div className="cor_footerNav__drop">
                            <ul>
                                <li onClick={() => setResetModal(true)}>Очистить</li>
                                <li onClick={() =>
                                  newsApi.getByType(2).then((vl) => {
                                      setVersionList(vl);
                                      toggleModal(true);
                                  })}>Версия программы</li>
                                <li onClick={()=> {window.version = VERSION;
                                    setAboutModal(true);}}>О программе</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {modal && <CorVersion modal={modal} toggleModal={toggleModal} versionList={versionList}/>}
        </>
    );
};

export default  connect(mapNeuroStateToProps, mapNeuroDispatchToProps)(RlabNeuroNav);
