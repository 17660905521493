import React, {useContext, useRef} from 'react';
import DateTime from "../../helpers/DateTime";
import { Icon, InputRange } from '../ui';
import "./ClassVoice.scss";
import {useSelector} from "react-redux";
import {storageClass} from "../../redux/slices/storage";
import {VideoContext} from "../Video/VideoContext";

const ClassVoice = (props) => {
    const documentClass = useSelector(storageClass);
    const {
        globalVolume,
    } = useContext(VideoContext);
    const volumeRef = useRef();

    return (
        <div className="classVoice">
            {props.aside &&
            <div className="classVoice__row">
                <Icon name="volume" />
                <div className="classVoice__range">
                    <InputRange min={-0.01} max={2} step={0.01} value={globalVolume.current} ref={volumeRef}
                                onDoubleClick={() => {props.setGlobalVolume(1); volumeRef.current.setValue(1);}}
                                onChange={e => props.setGlobalVolume(e.target.value)} />
                </div>
            </div>
            }
            <div className="classVoice__row">
                <div className="classVoice__col">
                    <Icon name="alarm"/>
                    <span className="classVoice__col_clock">{DateTime()}</span>
                </div>
                
                <div className="classVoice__col">
                    <Icon name="user" />
                    <span>{props.users.filter(u => u.role === 0).length}/{documentClass?.classLevel}</span>
                </div>
                {!props.aside &&
                    <div className="classVoice__col">
                        <Icon name="volume"/>
                    </div>
                }
            </div>
        </div>
    )
};
export default ClassVoice;
