import { createSlice } from "@reduxjs/toolkit";

const loadJson = (key, def={}) => JSON.parse(localStorage.getItem(key)) ?? def;
const saveJson = (key, item) => localStorage.setItem(key, JSON.stringify(item));

let initialState = {
  user: loadJson('user'),
  class: loadJson('class'),
  org: loadJson('org'),
  options: loadJson('options', {theme: 'light', fontSize: 16}),
};

const storageSlice = createSlice({
  name: "storage",
  initialState,
  reducers: {
    getClass: (state, action) => {
      state.class = action.payload;
    },
    getUser: (state, action) => {
      state.user = action.payload;
    },
    getOrg: (state, action) => {
      state.org = action.payload;
    },
    getOptions: (state, action) => {
      state.options = action.payload;
    },
  },
});

export const reloadClass = (item=null) => async (dispatch) => {
  if (item) saveJson('class', item);
  dispatch(getClass(loadJson('class')));
};
export const reloadUser = (item=null) => async (dispatch) => {
  if (item) saveJson('user', item);
  dispatch(getUser(loadJson('user')));
};
export const reloadOrg = (item=null) => async (dispatch) => {
  if (item) saveJson('org', item);
  dispatch(getOrg(loadJson('org')));
};
export const reloadOption = (key, value) => async (dispatch, getState) => {
  saveJson('options', {...getState().storage.options, [key]:value});
  dispatch(getOptions(loadJson('options')));
};

export const storageClass = (state) => {
  return state.storage.class;
};
export const storageUser = (state) => {
  return state.storage.user;
};
export const storageOrg = (state) => {
  return state.storage.org;
};
export const storageOptions = (state) => {
  return state.storage.options;
};

export const {
  getClass,
  getUser,
  getOrg,
  getOptions,
} = storageSlice.actions;
export default storageSlice.reducer;
