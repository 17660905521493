import React from 'react';
import ss from './InputLimit.module.scss';
import { Input } from 'rlabui';

const InputLimit = (props) => {
    const {
        className = '',
        value = '',
        placeholder = '',
        max = 100,
        disabled = false,
        onInput = null
    } = props;

    let inputLimitClass = ss.root;

    if (className) inputLimitClass += ' ' + className;

    return (
        <div className={inputLimitClass}>
			<Input 
                value={value.length > max ? value.slice(0, max - 1) : value} 
                placeholder={placeholder}
			    onInput={onInput}
                disabled={disabled}
            />
            <span>{value.length > max ? max : value.length}/{max}</span>
        </div>
    )
};
export default InputLimit;

{/* <InputLimit
    value={workName}
    onInput={(e) => setWorkName(e.target.value)}
    placeholder={'Введите название задания'}

/ > */}
