import React, {useState, useEffect} from "react";
import { ContentBody, ContentSubHeader } from "../template/ContentParts";
import { Icon, Button, Input } from "../ui";
import PIShowUnitDlg from './PIShowUnitDlg';
import {getCategories, getSystemList, isCustomSystem, getSiValueByBasic, getApproxSiValueByBasic} from './pi_utils';
import Table from "../ui/Table/Table";
import PIShowUnitCreateDlg  from "./PIShowUnitCreateDlg";
import {toast} from "react-toastify";
import {piApi} from "../../api/api";
import {fullName, isAuth} from "../../helpers/auth";
import {getNbCheckBox, chkItemText} from "../ui/utils/gen_utils";
import {storageClass} from "../../redux/slices/storage";
import {loadPIUnits} from "../../redux/slices/lists";
import {useDispatch, useSelector} from "react-redux";
import nb from "../Notebook/Notebooks.module.scss";
import "./PInvest.scss";

const tableHeader = [
	{column: 'Измеряемая величина', name: 'type', style: { width: '15%'} }, 
	{column: 'Обозначение', name: 'char', style: { width: '10%'} }, 
	{column: 'Название ед. измерения', name: 'nameLink', style: { width: '15%'} }, 
	{column: 'Обозначение', name: 'unit', style: { width: '10%'} }, 
	{column: 'Значение в СИ', name: 'value', style: { width: '15%'} }, 
	{column: '~ Значение в СИ', name: 'approxValue', style: { width: '15%'} }, 
	{column: 'Автор', name: 'author', style: { width: '13%'} }, 
	{column: 'Действия', name: 'actions', style: { width: '7%'} }, 
	{column: 'id', name: 'id', style: { width: '0%'} }, 
];

const PIShowUnits = () => {
	const [selectedMsIDs, setSelectedMsIDs] = useState([]);
	const [unitLists, setUnitLists] = useState([]);
	const [filteredSortedUnitLists, setFilteredSortedUnitLists] = useState([]);
	const [testFilter, setTestFilter] = useState('');
	const [searchIndex, setSearchIndex] = useState(0);
	const [showUnitDlg, setShowUnitDlg] = useState(false);
	const [selectedUnitId, setSelectedUnitId] = useState('');
	const [showAddUnitDlg, setShowAddUnitDlg] = useState(false);
	const [boxes, setBoxes] = useState([]);
	const [customUnitIDs, setCustomUnitIDs] = useState([]);
	const [unitTables, setUnitTables] = useState([]);

	const {lists: {pi}} = useSelector(state => state);
	const documentClass = useSelector(storageClass);
	const dispatch = useDispatch();

	useEffect(() => {
		//console.log('ue:01')		
		dispatch(loadPIUnits(documentClass._id));
	}, [dispatch, documentClass._id]);

	useEffect(() => {
		//console.log('ue:02');
		const systems = getSystemList(pi.systems);
		const ids = systems.map(item => item.value);
		setSelectedMsIDs(ids);
	}, [pi.systems]);

	useEffect(() => {
		//console.log('ue:03');
		const list = [];
		const systems = getSystemList(pi?.systems);
		for (let i = 0; i < systems.length; i ++) {
			const ms = systems[i];
			const res = selectedMsIDs.find(item => item === ms.value);
			const line = getNbCheckBox(!!res, () => handleUpdateMs(ms.value, selectedMsIDs, pi.systems), 
				'aa'+i, ms.label);
			list.push(line);
		}
		setBoxes(list);
	}, [pi.systems, selectedMsIDs]);

	useEffect(() => {
		//console.log('ue:04');
		const getUnitsBySystemId = (system) => {
			const units = pi.units.filter(item => item.system === system.value);
			const list = [];

			for (let k = 0; k < units.length; k ++) {
				const unit = units[k];
				let siValue = '';
				let approxSiValue = '';
				const category = pi.quants[unit.quant];
				const dependency = pi.dependencies.find(item => item.to === unit._id);

				if (!!dependency) {
					const fromUnit = pi.units.find(item => item._id === dependency.from);
					siValue = getSiValueByBasic(dependency, fromUnit, 4);
					approxSiValue = getApproxSiValueByBasic(dependency, fromUnit, 2);
					if (siValue === '') siValue = '1' + unit.sign;
				} else {
					siValue = '1' + unit.sign;
				}

				const record = {
					type: category.name, 
					char: category.sign, 
					nameLink: unit.name, 
					unit: unit.sign, 
					value: siValue,
					approxValue: approxSiValue ? approxSiValue : siValue,
					author: fullName(unit.owner, 'Шаблон'),
					actions: '', //actions
					id: unit._id
				};

				list.push(record);
			}
	
			return list;
		};

		const systems = getSystemList(pi?.systems);
		if (pi?.units.length === 0 || getCategories(pi?.quants).length === 0 || 
			systems?.length === 0 || pi?.dependencies.length === 0) {
			setFilteredSortedUnitLists([]);
			return; 
		}	

		const lists = [];
		for (let i = 0; i < systems.length; i ++) {
			const units = getUnitsBySystemId(systems[i]);
			lists.push(units);
		}
		setUnitLists(lists);
	}, [pi, pi?.systems, pi.units, pi.quants, pi.dependencies, documentClass]);
    
	useEffect(() => {	
		//console.log('ue:05');
		const getFilteredList = (filter, list) => {
			const itemName = searchIndex === 0 ? '' : searchIndex === 1 ? 'type' : 'nameLink';

			const filteredList = [];
			for (let k = 0; k < list.length; k ++) {
				const row = list[k];
				if (chkItemText(row, itemName, filter))
					filteredList.push(row);
			}
			return filteredList;
		};

		const sortedLists = [];
		const allIds = getSystemList(pi?.systems).map(item => item.value);

		for (let i = 0; i < unitLists.length; i ++) {
			const systemId = allIds[i];
			const found = selectedMsIDs.find(item => item === systemId);
			if (found) {
				sortedLists.push(unitLists[i]);
			}
		}

		let filteredSortedLists = [];
		const filter = testFilter.trim();
		if (filter) {
			for (let i = 0; i < sortedLists.length; i ++) {	
				const list = getFilteredList(filter, sortedLists[i]);
				filteredSortedLists.push(list);
			}
		}
		
		setFilteredSortedUnitLists(filter ? filteredSortedLists : sortedLists);
	}, [unitLists, testFilter, searchIndex, selectedMsIDs, pi.systems]);

	useEffect(() => {	
		//console.log('ue:06');
		const units = pi.units.filter(item => item.owner || isAuth().role === 3).map(item => item._id);
		setCustomUnitIDs(units);
	}, [pi?.units]);

	useEffect(() => {	
		const handleDeleteEmptyCustomSystem = id => {
			const deleteUnit = async (id) => {
				await piApi.deleteSystemById(id);
				dispatch(loadPIUnits(documentClass._id));
			};
			deleteUnit(id);
		};
	
		const handleDeleteCustomUnit = id => {
			const deleteUnit = async (id) => {
				await piApi.deleteUnitById(id);
				dispatch(loadPIUnits(documentClass._id));
			};
			deleteUnit(id);
		};
	
		const getTable = (_filteredUnitList, ind) => {
			//console.log('getTable');				
			const msId = selectedMsIDs[ind];
			const systems = getSystemList(pi.systems);
			const system = systems.find(item => item.value === msId);
			const msName = system?.label;
			const customSystem = isCustomSystem(pi.systems, msId);
			const units = system ? pi.units?.filter(item => item.system === system.value) : [];
	
			return (
				<div className="cor-net__row" key={'top0'+ind}>
					<div className="cor-net__col col-1" key={'col0'+ind}>
						<div className="cor-net__label" key={'nm0'+ind}>
							{msName}&nbsp;
							{customSystem && units.length === 0 &&
								<Icon name={'trash'} onClick={() => handleDeleteEmptyCustomSystem(msId)} />
							}
						</div>
						<div className={nb.nb__table} key={'tb0'+ind}>
							{_filteredUnitList.length === 0 ? 
								(units.length > 0 || !customSystem ? 'Ничего не найдено' : 'Система измерений не используется')
							:
							<Table 
								table={{
									header: tableHeader, 
									data: _filteredUnitList,
								}}
								link={{
									handleLink: id => handleLink(id),
								}}
								sort={{
									hasSorting: true, 
									initSortInd: 1,
								}}
								actions={{
									handleDelete: id => handleDeleteCustomUnit(id),
									canDeleteIDs: customUnitIDs,
								}}
							/>
							}
						</div>
					</div>
				</div>
			);
		};
	
		//console.log('ue:07');

		const list = filteredSortedUnitLists.map((item, ind) => getTable(item, ind));
		setUnitTables(list);
	}, [customUnitIDs, dispatch, documentClass._id, filteredSortedUnitLists, pi.systems, pi.units, selectedMsIDs]);
		
	const handleCreateNewUnit = () => {	
		if (isAuth().role === 0)
			toast.warn('Только преподаватель может добавить новую единицу измерения.');
		else
			setShowAddUnitDlg(true);
	};

	const handleUpdateMs = (msId, selMsIDs, piSystems) => {
		let _selectedMsIDs = [...selMsIDs];
    	const systems = getSystemList(piSystems);
    
		const res = _selectedMsIDs.find(item => item === msId);

		if (res) {//если id найден в selected, то исключаем, иначе добавляем.
			_selectedMsIDs = _selectedMsIDs.filter(item => item !== msId);
			setSelectedMsIDs(_selectedMsIDs);
		} else {
			//порядок отфильтрованных систем должен быть сохранен, поэтому пробегаемся по списку и отбираем те, которые используются
			const newSelecledIDs = [];
			for (let i = 0; i < systems.length; i ++) {
				const system = systems[i];
				if (_selectedMsIDs.find(item => item === system.value) || msId === system.value)
					newSelecledIDs.push(system.value);
			}
			setSelectedMsIDs(newSelecledIDs);
		}
	};
	const handleSwitchMs = isOn => {
		const ids = getSystemList(pi?.systems).map(item => item.value);
		setSelectedMsIDs(isOn ? ids : []);
	};

	const handleLink = id => {
		setSelectedUnitId(id);
		setShowUnitDlg(true);
	};

    return ( 
		<>
			<ContentSubHeader>
				<div className="cor-net">
					<div className="cor-net__section">
						<div className="cor-net__title">Система единиц измерения величин</div>
						<div className="cor-net__row">
							<div className="cor-net__col col-4 col-icon">
								<Input 
									type="text" 
									placeholder={'Поиск'} 
									value={testFilter} 
									onInput={e => setTestFilter(e.target.value)} 
								/>
								<Icon name={'search'} />
							</div>
							<div className="cor-net__col col-4">
								<Button color="primary" onClick={handleCreateNewUnit}>Создать единицу</Button>
							</div>
						</div>
					</div>

					<div className="cor-net__section">
						<div className="cor-net__row">
							{['По всем столбцам', 'По измеряемой величине', 'По единице измерения']
							.map((item, ind) => 
								<div key={"rbkey"+ind} className={'cor-net__col'}>
									<input type="checkbox" 
										id={ind} 
										value={ind} 
										name={item}
										checked={ind === searchIndex} 
										onClick={(e) => setSearchIndex(Number(e.target.value))} 
										onChange={() => {}}
										>
									</input>
									&nbsp;<label htmlFor={ind}>{' ' + item}</label>
								</div>
							)}
						</div>
					</div>
					<div className="cor-net__section">
						{
						<>
							<div className="cor-net__row" key='ms0'>
								<div className="cor-net__col">
									<Button color="primary" size="extraSmall" onClick={() => handleSwitchMs(true)}>Включить все</Button>
								</div>
								<div className="cor-net__col">
									<Button color="secondary" size="extraSmall" onClick={() => handleSwitchMs(false)}>Выключить все</Button>
								</div>
							</div>
							<div className="cor-net__row">
								{boxes.map(item => item)}
							</div>
						</>
						}
					</div>
				</div>
			</ContentSubHeader>

			<ContentBody>
				{unitTables.map(item => item)}
			</ContentBody>

			{showUnitDlg && <PIShowUnitDlg
				showDlg={showUnitDlg}
				setShowDlg={setShowUnitDlg}
				selectedUnitId={selectedUnitId}
			/>}
			{showAddUnitDlg && <PIShowUnitCreateDlg
				showDlg={showAddUnitDlg}
				setShowDlg={setShowAddUnitDlg}
			/>}
		</>
    )
}
export default PIShowUnits;