import React from "react";
import HeaderBar from "../template/Header";
import Navbar from "../template/Navbar";
import {Route} from "react-router-dom";
import DashboardOrg from "../template/Dashboard/DashboardOrg";
import DashboardFirmware from "../template/Dashboard/DashboardDevice";
import DashboardNews from "../template/Dashboard/DashboardNews";
import DashboardPInvestigations from "../template/Dashboard/DashboardPInvestigations";
import DashboardScenarios from "../template/Dashboard/DashboardScenarios";
import DashboardNotebook from "../template/Dashboard/DashboardNotebook";
import DashboardOnlineTest from "../template/Dashboard/DashboardOnlineTest";
import DashboardTutorial from "../template/Dashboard/DashboardTutorial";

function Home(props) {
    return (<>
            <HeaderBar {...props}/>
            <main className="cor_main">
                <Navbar {...props} />
                <Route exact path="/">
                    <DashboardOrg {...props}/>
                </Route>
                <Route exact path="/firmware">
                    <DashboardFirmware {...props}/>
                </Route>
              <Route exact path="/news/:type?/:id?">
                <DashboardNews {...props}/>
              </Route>
              <Route exact path="/pi/:opt/:id?">
                <DashboardPInvestigations {...props}/>
              </Route>
              <Route exact path="/rlsc/:opt/:id?">
                <DashboardScenarios {...props} />
              </Route>
              <Route exact path="/notebooks/:opt/:id?">
                <DashboardNotebook {...props} />
              </Route>
              <Route exact path="/ot/:opt?/:id?">
                  <DashboardOnlineTest {...props} />
              </Route>
              <Route exact path="/tutorial/:opt?/:sid?/:bid?">
                <DashboardTutorial {...props} />
              </Route>
            </main>
        </>
    );
}

export default Home;
