import React, {useState, useEffect, useCallback} from "react";
import { Icon, Button } from '../ui';
import {toast} from "react-toastify";
import { NB_STATES, NB_HOME_WORK_STATUS, isDisabled, getFullDate, getDateParts } from './nb_utils';
import { updateRunDate, updateCompleteDate } from "../ui/utils/gen_utils";
import DateTimePicker  from "../ui/DatePicker/DateTimePicker";
import "../template/Dashboard/Dashboard.scss";
import nb from "./Notebooks.module.scss";

const NotebookAddRun = ({setNbState, setHomeWorkStatus, isEditable,
        homeWorkRun, setHomeWorkRun, isRunChanged, setIsRunChanged, 
        forceSaveRun, setForceSaveRun, setForceComplete, homeWorkTaskSamples }) => {
    const [runDate, setRunDate] = useState(null);
    const [runHours, setRunHours] = useState(0); //not used
    const [runMinutes, setRunMinutes] = useState(0);  //not used
    const [completeDate, setCompleteDate] = useState(null);
    const [completeHours, setCompleteHours] = useState(0);  //not used
    const [completeMinutes, setCompleteMinutes] = useState(0);  //not used

	useEffect(() => {
        if (!homeWorkRun) return;

        const [rDate, rHours, rMinutesInd] = getDateParts(true, homeWorkRun.runFullDate);
        setRunDate(rDate); 
        setRunHours(rHours);
        setRunMinutes(rMinutesInd);
    
        const [cDate, cHours, cMinutesInd] = getDateParts(false, homeWorkRun.completeFullDate);
        setCompleteDate(cDate); 
        setCompleteHours(cHours);
        setCompleteMinutes(cMinutesInd);
	},[homeWorkRun]);

    const handleBack = () => {
        if (isRunChanged) 
            toast.warn("Отмените или сохраните изменения."); 
        else
            setNbState(NB_STATES.SETTINGS);
    };

    //2 - надо сливать дату, часы и минуты
    const prepareRun = useCallback(() => {
        const MODIFIED_BEHAVIOUR = true;
        const _runHours = !MODIFIED_BEHAVIOUR ? runHours : 0;
        const _runMinutes = !MODIFIED_BEHAVIOUR ? runMinutes : 0;
        const _completeHours = !MODIFIED_BEHAVIOUR ? completeHours : 23;
        const _completeMinutes = !MODIFIED_BEHAVIOUR ? completeMinutes : 55;

        let _runFullDate = getFullDate(runDate, _runHours, _runMinutes);
        const run = {
            runFullDate: _runFullDate,
            completeFullDate: getFullDate(completeDate, _completeHours, _completeMinutes),
        };
        return run;
    }, [completeDate, completeHours, completeMinutes, runDate, runHours, runMinutes]);    

    useEffect(() => {
        if (!forceSaveRun) return;
        setHomeWorkRun(prepareRun());
        setForceSaveRun(false);
        setIsRunChanged(false);
    },[forceSaveRun, prepareRun, setHomeWorkRun, setForceSaveRun, setIsRunChanged]);
    
    const doHandleRun = (status) => {
        setHomeWorkRun(prepareRun());
        setHomeWorkStatus(status);
        setIsRunChanged(false);
        setForceComplete(true); //сохранить изменения и закрыть
    };

    const handleSaveInStore = () => {
        doHandleRun(NB_HOME_WORK_STATUS.CATALOG);
    };

    const handleRunNow = () => {
        doHandleRun(NB_HOME_WORK_STATUS.PROGRESS);
    };

    const doSetRunDate = value => {
        updateRunDate(value, completeDate, setRunDate, setCompleteDate, setIsRunChanged);
    //     const todayDay = new Date();
    //     const runDay = new Date(value);
    //     const endDay = new Date(completeDate);
    //     const today = todayDay.valueOf();
    //     const runDate = runDay.valueOf();
    //     const endDate = endDay.valueOf();

    //     const runValue = runDate > today ? runDay : todayDay;
    //     runValue.setHours(0, 0, 0);
        
    //     setRunDate(runValue); 
    //     const endValue = endDate > Math.max(runDate, endDate) ? endDay : runValue;
    //     setCompleteDate(endValue); 
    //     setIsRunChanged(true);
    };
    const doSetCompleteDate = value => {
        updateCompleteDate(value, runDate, setCompleteDate, setIsRunChanged);
    //     const runDay = new Date(runDate);
    //     const endDay = new Date(value);
    //     const _runDate = runDay.valueOf();
    //     const endDate = endDay.valueOf();
    //     const endValue = endDate > _runDate ? endDay : runDay;
    //     endValue.setHours(23, 55, 0);
    //     setCompleteDate(endValue); 
    //     setIsRunChanged(true);
    };

    return  (
        <>
            <div className={nb.nb__body}>
                <div className="cor-net">
                    <div className="cor-net__section">
                        <div className="cor-net__title">Запуск</div>
                        <div className="cor-net__row">
                            <div className="cor-net__subtitle">Дата запуска</div>
                        </div>
                        <div className="cor-net__row">
                            <div className="cor-net__label">Задание будет запущено в указанную дату</div>
                        </div>
                        <div className={"cor-net__row " + isDisabled(isEditable)}>
                            <div className="cor-net__col col-8">
                                <div className="cor-net__label">День</div>
                                <DateTimePicker
                                    startDate={runDate} 
                                    setStartDate={doSetRunDate} 
                                    id={'RUN_DATE_PICKER_ID'}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="cor-net__section">
                        <div className="cor-net__subtitle">Дата завершения</div>
                        <div className={"cor-net__row " + isDisabled(isEditable)}>
                            <div className="cor-net__col col-8">
                                <div className="cor-net__label">Крайний срок сдачи</div>
                                <div>
                                    <DateTimePicker
                                        startDate={completeDate} 
                                        setStartDate={doSetCompleteDate} 
                                        id={'START_DATE_PICKER_ID'}
                                    />
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>

            </div>

            <div className={nb.nb__footer}>
                <div className={nb.nb__footer_row}>
                    <div className={nb.nb__footer_col}>
                        <Button color="secondary"  size="medium" border={true} onClick={handleBack}>
                            Вернуться к настройкам
                        </Button>
                    </div>

                    <div className={nb.nb__footer_col}>
                        <Button className="cor_btn_icon_left" color="primary" size="medium" 
                            border={true} onClick={handleSaveInStore} disabled={!isEditable}>
                            <Icon name="save"/>
                            {' Сохранить задание в каталоге'}
                        </Button>
                        <Button color="primary" size = "medium" onClick={handleRunNow} disabled={!isEditable}>
                            Запустить задание
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NotebookAddRun;
