import React from 'react';
import { Modal, Button } from 'rlabui';

const AsideBundleModalDel = ({ bundles, delSensorsBundles, bundlesModalDel, setSensorsBundleModalDel }) =>
    bundlesModalDel && (
        <Modal
            visible={bundlesModalDel}
            size="xs"
        >
            <Modal.Head
                modalClose={() => setSensorsBundleModalDel(false)}
                title={'Удалить связку "' + bundles[bundlesModalDel].name + '"?'}
            ></Modal.Head>
            <Modal.Footer>
                <Button
                    onClick={() => {
                        const key = bundlesModalDel;
                        delSensorsBundles(key);
                        setSensorsBundleModalDel(false);
                    }}
                >
                    Удалить
                </Button>
                <Button
                    border={true}
                    onClick={() => setSensorsBundleModalDel(false)}
                >
                    Отмена
                </Button>
            </Modal.Footer>
        </Modal>
    );
export default AsideBundleModalDel;
