import React from 'react';
import CheckInternet from '../CheckInternet';
import Styles from './EmptyScreen.module.scss';
import darkImg from '../../../assets/img/empty-screen-dark.png';
import lightImg from '../../../assets/img/empty-screen.png';

const EmptyScreen = ({ theme, devices }) => {
    return (
        <div className={Styles.wrapper}>
            {devices.length === 0 && <CheckInternet />}

            <img
                className={Screen.img}
                src={theme === 'dark' ? darkImg : lightImg}
                alt=""
            />
            <div className={Styles.text}>Для продолжения работы необходимо подключить датчики к компьютеру</div>
        </div>
    );
};
export default EmptyScreen;
