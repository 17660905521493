import React, { useState, useEffect } from 'react';
import { Button, Input, Modal } from 'rlabui';
import {toast} from "react-toastify";
import './VideoEditor.scss';

const CameraFileNameDlg = ({ showModal, initCameraFileName, doCancelDlg, doSaveCameraFile }) => {
    const [fileName, setFileName] = useState('');

    useEffect(() => {
        setFileName(initCameraFileName);
    }, [initCameraFileName]);

    const handleFileName = () => {
        if (!fileName.trim()) {
            toast.warn('Введите название.');
            return;
        }

        doSaveCameraFile(fileName.trim());
    };

    const handleChangeName = (value) => {
        const MAX_NAME_LENGTH = 80;
        if (value.length < MAX_NAME_LENGTH) setFileName(value);
    };

    return (
        <Modal
            visible={showModal}
            size={'md'}
        >
            <Modal.Head
                title="Введите имя сохраняемого видео файла"
                modalClose={doCancelDlg}
            ></Modal.Head>
            <Modal.Body>
                <div className="modal__row">
                    <div className="modal__label">Название видео файла</div>
                    <div className="videoEditor__cameraSpace" />
                    <Input
                        className="videoEditor__cameraInput"
                        value={fileName}
                        onInput={(e) => handleChangeName(e.target.value)}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    color="primary"
                    size="extraSmall"
                    onClick={handleFileName}
                >
                    Сохранить видео файл
                </Button>
                <Button
                    color="primary"
                    size="extraSmall"
                    border={true}
                    onClick={doCancelDlg}
                >
                    Отменить
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CameraFileNameDlg;
