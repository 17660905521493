import React, { useState } from 'react';
import './HeaderMenu.scss';
import HeaderMenuItem from './HeaderMenuItem';
import Sticker from '../Sticker/Sticker';
import { PRIORITY_DATA, TYPE_DATA } from '../Sticker/StickersData';
import { ReactComponent as Chart } from '../../../assets/icons/chart.svg';
import { ReactComponent as Homework } from '../../../assets/icons/house.svg';
import { ReactComponent as LabWork } from '../../../assets/icons/lab.svg';
import DeadlineButton from '../DeadlineButton/DeadlineButton';
import { useDispatch, useSelector } from 'react-redux';
import { selectusers } from '../../../redux/slices/classsline';
import { filterItems } from '../../../redux/slices/trello';

const PRIORITY_STICKERS_DATA = [
  {
    name: 'important',
    title: <Sticker sticker={PRIORITY_DATA[0]} />,
    icon: <Chart style={{ stroke: '#F35151' }} />,
    group: 'priority',
    priority: 1,
  },
  {
    name: 'urgently',
    title: <Sticker sticker={PRIORITY_DATA[1]} />,
    icon: <Chart style={{ stroke: '#F3C651' }} />,
    group: 'priority',
    priority: 2,
  },
  {
    name: 'normal',
    title: <Sticker sticker={PRIORITY_DATA[2]} />,
    icon: <Chart style={{ stroke: '#41cb47' }} />,
    group: 'priority',
    priority: 3,
  },
  {
    name: 'notImportant',
    title: <Sticker sticker={PRIORITY_DATA[3]} />,
    icon: <Chart style={{ stroke: '#a7b0b8' }} />,
    group: 'priority',
    priority: 4,
  },
  {
    name: 'all',
    title: <span style={{ fontSize: '0.75em' }}>Все</span>,
    group: 'all',
  },
];

export const TYPE_STICKERS_DATA = [
  {
    name: 'homework',
    title: <Sticker sticker={TYPE_DATA[0]} />,
    icon: <Homework style={{ stroke: '#ce5a92' }} />,
    group: 'type',
  },
  {
    name: 'labWork',
    title: <Sticker sticker={TYPE_DATA[1]} />,
    icon: <LabWork />,
    group: 'type',
  },
  {
    name: 'all',
    title: <span style={{ fontSize: '0.75em' }}>Все</span>,
    group: 'all',
  },
];

const HeaderMenu = () => {
  const dispatch = useDispatch();

  const [usersList] = useSelector(selectusers);
  const USERS_DATA = usersList;

  const [isOpenExecutor, setIsOpenExecutor] = useState(false);
  const [isOpenTask, setIsOpenTask] = useState(false);
  const [isOpenImportance, setIsOpenImportance] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const onItemsClick = (item) => {
    const isItemSelected = selectedItems.some(
      (user) => user.name === item.name
    );

    let filteredSelectedItems;

    if (isItemSelected) {
      filteredSelectedItems = selectedItems.filter(
        (user) => user.name !== item.name
      );
    } else {
      filteredSelectedItems = [...selectedItems, item];
    }

    dispatch(filterItems(filteredSelectedItems));
    setSelectedItems(filteredSelectedItems);
  };

  return (
    <div className="header_menu">
      <div className="header_menu__view">
        <span className="header_menu__title">Вид:</span>
        <div className="header_menu__view_button">Колонки</div>
      </div>
      <div className="header_menu__filters">
        <span className="header_menu__title">Фильтры</span>
        <div className="header_menu__filters_wrapper">
          <HeaderMenuItem
            title="По исполнителю"
            isOpenMenu={isOpenExecutor}
            handleOpenMenu={() => {
              setIsOpenExecutor(!isOpenExecutor);
              setIsOpenTask(false);
              setIsOpenImportance(false);
            }}
            items={USERS_DATA}
            onItemClick={onItemsClick}
            isUsers
            selectedItems={selectedItems}
          />
          <>
            <DeadlineButton date={''} setDate={''} title="Дедлайн до" />
          </>
          <HeaderMenuItem
            title="По типу заданий"
            isOpenMenu={isOpenTask}
            handleOpenMenu={() => {
              setIsOpenTask(!isOpenTask);
              setIsOpenExecutor(false);
              setIsOpenImportance(false);
            }}
            items={TYPE_STICKERS_DATA}
            onItemClick={onItemsClick}
            selectedItems={selectedItems}
          />

          <HeaderMenuItem
            title="По важности"
            isOpenMenu={isOpenImportance}
            handleOpenMenu={() => {
              setIsOpenImportance(!isOpenImportance);
              setIsOpenExecutor(false);
              setIsOpenTask(false);
            }}
            items={PRIORITY_STICKERS_DATA}
            onItemClick={onItemsClick}
            selectedItems={selectedItems}
          />

          <div className="header_menu__filters_item header_menu__filters_item_button_more">
            <div className="header_menu__filters_item_button">
              <span className="header_menu__filters_item_button_title header_menu__filters_item_button_title_more">
                + Ещё 15
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderMenu;
