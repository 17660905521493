import {reloadOption} from "../../redux/slices/storage";

export const mapStateToProps = (state) => {
    return ({
        users: state.options.users,
    });
};

export const mapDispatchToProps = (dispatch) => ({
    setGlobalVolume: (number) => dispatch(reloadOption('globalVolume',number)),
});
