import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { getTutorialSubjectId, getTutorialSectionId, getTutorialLabId }  from "../../redux/slices/tutorialslice";
import { Icon } from '../ui';
import Lottie from 'react-lottie-player';
import ModalPrint from '../ui/ModalDialogs/ModalPrint';
import ModalCongratsDlg from './ModalCongratsDlg';
import {storageClass, storageOptions} from "../../redux/slices/storage";
import labsList from './subjectList';
import {isAuth} from "../../helpers/auth";
import {labWorkInfoApi} from "../../api/api";
import {showCurrTime, initializeTimeCounter} from "../ui/utils/gen_utils";
import {formLabWorkId, getLabWorkArraysAndNames, getTeacherWorkInfo, setTeacherWorkInfo, 
	hasTeacherWorkInfo, removeTeacherWorkInfo} from "./lw_utils";
import * as XLSX from 'xlsx';
import {getPracticumTestInfo, hasPracticumTestInfo, removePracticumTestInfo} from "../ui/utils/ServerInfo";
import "./LabWorkShow.scss";
import { Content } from "../template/ContentParts";

const LabWorkShow = ({history}) => {
    const [laboratoryList, setLaboratoryList] = useState([]);
    const [labWork, setLabWork] = useState(undefined);
    const [lottieStart, setLottieStart] = useState(undefined);
    const [lottieHeight, setLottieHeight] = useState(0);
    const [tableIndexes, setTableIndexes] = useState([]);
	const [percentage, setPercentage] = useState(0);
	const [showPrint, setShowPrint] = useState(false);
    const [showModalCongrats, setShowModalCongrats] = useState(false);
	const [studentLabWorkInfoId, setStudentLabWorkInfoId] = useState(undefined);
    const [isRefresh, setIsRefresh] = useState(false);
    const [currPage, setCurrPage] = useState(0);
    const [subjectId, setSubjectId] = useState('');
    const [sectionId, setSectionId] = useState('');
    const [labId, setLabId] = useState('');
    const [startTime, setStartTime] = useState(undefined);
    const [investigatedPage, setInvestigatedPage] = useState(-1);
    const [isComplete, setIsComplete] = useState(false);
    const [currTestTime, setCurrTestTime] = useState();
    const [checkParams, setCheckParams] = useState(0);
	
	const progressBarRef = useRef(null);
	const timerIdRef = useRef();
	const userId = useRef(isAuth()._id);
	const userRole = useRef(isAuth().role);

	const _subjectId = useSelector(getTutorialSubjectId);
	const _sectionId = useSelector(getTutorialSectionId);
	const _labId = useSelector(getTutorialLabId);
    const documentClass = useSelector(storageClass);
    const {theme} = useSelector(storageOptions);
	const labWorkId = useRef('');
	const subjectName = useRef('');
	const sectionName = useRef('');
	const labWorkIndex = useRef(undefined);

	useEffect(() => {
		timerIdRef.current = initializeTimeCounter(1, setCurrTestTime); //refresh each sec
		return () => {clearInterval(timerIdRef.current);};
    }, []);

	useEffect(() => {
		if (checkParams !== 0 || !isTeacher()) return; //use it when page reloaded
		const wi = getTeacherWorkInfo();
		if (!wi) return;
		setSubjectId(wi.subjectId);
		setSectionId(wi.sectionId);
		setLabId(wi.labId);
		setCheckParams(1);
	}, [checkParams]);

	useEffect(() => {
		setLaboratoryList(labsList.subjectList);
		
		if (isTeacher()) {
			if (checkParams > 0) return;
			if (hasTeacherWorkInfo()) return;

			setSubjectId(_subjectId);
			setSectionId(_sectionId);
			setLabId(_labId);
			setTeacherWorkInfo(_subjectId, _sectionId, _labId);
			setCheckParams(1);
		} else {
			if (!hasPracticumTestInfo()) return;
			const lwi = getPracticumTestInfo();
			const ids = lwi.labWorkId.split('|');
			setSubjectId(ids[0]);
			setSectionId(ids[1]);
			setLabId(ids[2]);
			setStartTime(lwi.startTime);
		}
	}, [_labId, _sectionId, _subjectId, checkParams]);

	useEffect(() => {
		const getLwiId = async (owner, room, lwRunId) => {
			const result = await labWorkInfoApi.getLabWorkInfoByRunId(owner, room, lwRunId);
			if (!!result && result.data.length > 0) {
 				setStudentLabWorkInfoId(result.data[0]._id);
			}
		};
	
		if (!isTeacher() && hasPracticumTestInfo()) {
			const lwi = getPracticumTestInfo();
			getLwiId(userId.current, documentClass._id, lwi.testRunId);
		}
	}, [documentClass._id]);

	useEffect(() => {
		if (laboratoryList.length === 0 || !subjectId || !sectionId || !labId) return;
		labWorkId.current = formLabWorkId(subjectId, sectionId, labId);

		const [subj, sect, lab] = getLabWorkArraysAndNames(laboratoryList, subjectId, sectionId, labId);
		subjectName.current = subj.subjectName;
		sectionName.current = sect.sectionName;
		setLabWork(lab);
		labWorkIndex.current = sect.labList.indexOf(lab);
		setCurrPage(0);
	}, [laboratoryList, subjectId, sectionId, labId]);

    useEffect(() => {
		if (!labWork) return;

		const tableIndexList = [];
		for (let i =  0; i < labWork.labPages.length; i ++) {
			const table = labWork.labPages[i].table;
			if (table && table.length > 0) {
				tableIndexList.push(i);
			}
		}
		setTableIndexes(tableIndexList);

		if (isTeacher()) return;
		//The function below allows defining number of pages in the lab was studied by the student.
		//getStudentLabWorkDataByTest(userId.current, documentClass._id, labWorkId.current, setLabWorkTestData);    
	},[documentClass._id, labWork]);

	useEffect(() => {
		if (!labWork || isTeacher()) return;

        if (investigatedPage + 1 < currPage) {
            setInvestigatedPage(investigatedPage + 1);
        }
    },[investigatedPage, currPage, labWork]);

    useEffect(() => {
		if (!labWork || isTeacher() || investigatedPage === -1) return;

		const saveData = async () => {
			const result = await labWorkInfoApi.getLabWorkInfoById(studentLabWorkInfoId);
			const info = {
				...result,
				pageInd: investigatedPage,
				isComplete: investigatedPage >= currPage,
				score: investigatedPage === 0 ? 1 : parseInt(investigatedPage / currPage * 100)
			};
			
			await labWorkInfoApi.updateLabWorkInfoById(studentLabWorkInfoId, info);
		};
	
		if (labWork && (isComplete || (investigatedPage < currPage))) { 
			saveData();
        }	
	},[labWork, currPage, investigatedPage, isComplete, documentClass._id, subjectId, sectionId, labId, studentLabWorkInfoId]);

    useEffect(() => {
        if (isRefresh) setIsRefresh(0);
    },[isRefresh]);

	const handleRefreshPicture = () => {
		if (!lottieStart) 
			setIsRefresh(!isRefresh);
	};

    const handleBack = () => {
		removeTeacherWorkInfo();
		if (isTeacher()) history.push('/practicum/lw');
    };

	const handlePrevPicture = (e, src) => {
		doCompleteLottie();
		e.currentTarget.src = src;
		if (currPage > 0) {
			setCurrPage(currPage - 1);
		} else {
			handleBack();
		}
	};

	const handleNextPicture = (e, src) => {
		doCompleteLottie();
		e.currentTarget.src = src;
        if (currPage < labWork.labPages.length - 1) {
			setCurrPage(currPage + 1);
		}
	};

	const handleSaveXlxs = () => {
		//https://www.npmjs.com/package/xlsx
		//https://www.npmjs.com/package/xlsx-js-style
		//https://codesandbox.io/s/project-p0p2w3?file=/xlsx_js_style.js 
		const fitToColumn = (arrayOfArray) => {
			// get maximum character of each column:
			return arrayOfArray[0].map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => a2[i] ? a2[i].toString().length + 5 : 0)) }));
		};

		const workbook = XLSX.utils.book_new();
		const wsrows =  []; //{hpt: 18}, // row 1 sets to the height of 12 in points. {hpx: 32}, // row 2 sets to the height of 16 in pixels

		for (let i = 0; i < tableIndexes.length; i ++) {
			const tableData = getTableData(tableIndexes[i]);
			const tableDataArr = [];
			for (let j = 0; j < tableData.length; j ++) {
				tableDataArr.push(tableData[j].replaceAll("^^", "").replaceAll("^_", "").replaceAll("++", "").split('|'));
				wsrows.push(j === 0 ? {hpt: 18} : {hpt: 16});
			}

			const worksheet = XLSX.utils.aoa_to_sheet(tableDataArr); //, { header : ["A", "B", "C"], skipHeader : true } );

			worksheet['!cols'] = fitToColumn(tableDataArr); //column width 
			worksheet['!rows'] = wsrows; // //row height

			workbook.SheetNames.push("Таблица " + (i+1));
			workbook.Sheets["Таблица " + (i+1)] = worksheet;
		}

		const fileName =  subjectName.current + '. ' + sectionName.current + '. ' + (labWorkIndex.current + 1) + '. ' +labWork.labName + '.xlsx';
		XLSX.writeFile(workbook, fileName);
	};

	const handleStudentCompleted = () => {
		setInvestigatedPage(currPage);
		setIsComplete(true); //LabWork is completed by Student
		setShowModalCongrats(true); //Show Congrats dialog
	};

	const handleLeaveLabWork = () => {
		clearInterval(timerIdRef.current);
		setShowModalCongrats(false);
		removePracticumTestInfo();
		history.push(isTeacher() ? '/practicum/lw' : '/');
	};

    useEffect(() => {
		if (labWork && labWork.labPages.length > 0) {
			const _picture = getPicture(currPage);
        	if (_picture && _picture.includes('.json')) {
				const elem = document.getElementById('bodyBox');
				if (elem) {
					const height = elem.clientHeight;
					setLottieHeight(height);
				}
        	}
		}	
    },[currPage, labWork]); //don't add getPicture
	
	const isTeacher = () => userRole.current > 0;
	const isStudent = () => userRole.current === 0;
	const getImageSrc = page => "/practicum/" + labWork.labPictureFolder + getPicture(page);
	const getPage = (page) => labWork?.labPages[page];
	const getTableData = (page) => getPage(page).table;
	const hasPicture = (page) => !!getPicture(page);
	const getPicture = (page) => {
		const pg = getPage(page);
		return pg && (theme !== "dark" || !pg.pictureDark ? pg.picture : pg.pictureDark);
	};
	const hasOrdinaryPicture = (page) => {
		const _picture = getPicture(page);
		return _picture && (_picture.includes('.jpg') || _picture.includes('.png'));
	};
	const getOrdinaryPicture = (page) => <div><img src={getImageSrc(page)} height='330px' alt='' /></div>;
	const getFormulaPicture = (fileName) => <div><img src={"/practicum/" + labWork.labPictureFolder + fileName} alt='' /></div>;
	const hasTable = (page) => getPage(page).table?.length > 0;
	const hasComment = () => getPage(currPage).comment?.length > 0;

	const getTitle = (pageNum) => {
		const title = getPage(pageNum).title;
		const cn = pageNum <= 1 || (pageNum === 2 && title.toLowerCase() === 'оборудование') ?  'labWorkShow__firstLegend2' : 'labWorkShow__firstLegend3';
		return title ? <div className={cn} key={'ttl0'}>{title}</div> : <></>;
	};

	const progressBar = (time) => {
		const nextStep = () => {
			width ++;
			setPercentage(width);
		};
		const time100 = time / 100;
		let width = 1;
	  	setPercentage(width);
	  	progressBarRef.current = setInterval(showProgress, time100);

		function showProgress() {
			if (width >= 100) {
				clearInterval(progressBarRef.current);
			} else {
				setTimeout(nextStep, 100);
			}
		}
  	};

	const getLottieTime = () => {
		if (!lottieStart) return '';
		const initSecs = (new Date(lottieStart)).getSeconds();
		const currSecs = (new Date()).getSeconds();
		//const mSec = Math.floor((new Date()).getMilliseconds() /100);
		let diffSec = currSecs - initSecs;
		if (diffSec < 0) diffSec += 60;
		const sSec = diffSec.toString();
		return sSec + ' сек'; //mSec + '0';
		//return '00:' + diffSec + mSec;
	};

	const handleStartLottie = () => {
		const DEFAULT_PROGRESS_TIME = 4000;
		if (!lottieStart) {
			setLottieStart(new Date().getTime());
			progressBar(DEFAULT_PROGRESS_TIME);
		}
	};

	const doCompleteLottie = () => {
		setLottieStart(undefined);
		clearInterval(progressBarRef.current);
	};

	const hasLottiePicture = (page) => {
		const _picture = getPicture(page);
		return _picture && _picture.includes('.json');
	}

	const getLottiePicture = (page) => {
		const height = lottieHeight * 0.87;
		return (
			<div>
    	        <Lottie
            		path={getImageSrc(page)}
                    play
                    loop={isRefresh ? 1 : 0}
                    onEnterFrame={handleStartLottie}
                    onComplete={doCompleteLottie}
					style={{maxHeight: '100%', maxWidth: '100%', height: `${height}px`}}
                />
            </div>
	)};

	const getCommentLine = (pageNum, item, ind) => {
		let outputItem = item;
		const isEquipment = getPage(pageNum).title.toLowerCase() === 'оборудование';

		//see SAMPLE in sect 2, lab 1, page 4:
		const isSelectedText = item.startsWith('^r') || item.startsWith('^b');
		if (isSelectedText) {
			const isSelectRed = item.startsWith('^r');
			outputItem = outputItem.substring(2);
			const cname= isSelectRed ? 'labWorkShow__comments_sel labWorkShow__comments_selRed' :
						'labWorkShow__comments_sel labWorkShow__comments_selBlue';
			return (
				<div className={cname} key={'cl0'+ind}>
					{outputItem}
				</div>
			);
		}

		const isCentered = outputItem.startsWith('##');
		if (isCentered) {
			outputItem = outputItem.substring(2);
		}
		const hasBold = outputItem.includes('@@');
		const cn = isCentered ? 'labWorkShow__comments__center' : '';

		if (!hasBold) {
			if (pageNum === 2 && isEquipment) { //the list of equipment
				return (
				<div key={'bld'+ind} className='labWorkShow__comments__equipment'>
					<p>
						<span><img src={"/practicum/tick.jpg"} alt='' className='labWorkShow__comments__tick' /></span>
						<span>&nbsp;{checkUp(outputItem)} ({ind+1})</span>
					</p>
				</div>);
			} else if (outputItem.startsWith('**')) {
				const formulaFileName = outputItem = outputItem.substring(2);
				return (<div className='labWorkShow__comments__center'>
					{getFormulaPicture(formulaFileName)}
				</div>);
			} else {
				return <p className={cn} key={'cl1'+ind}>{checkUp(outputItem)}</p>;
			}
		}

		//show bold text
		let arr = outputItem.split('@@'); //show Bold text
		if ((arr.length % 2) === 0) arr.push('');

		return (<span key={'cl10'+ind}>
			<p className={cn} key={'cl11'+ind}>
				{arr.map((item, i) => 
					<span key={'cl12'+ind + ''+ i}>
						{(i % 2) === 0 ? checkUp(item) : <b>{checkUp(item)}</b>}
					</span>
				)}
			</p>
		</span>);
	};

	const getPageComment = (pageNum) => {
		const page = getPage(pageNum);
		const comment = page.comment && page.comment.length > 0 ? page.comment : [];

		return (
			<div  key={'pc0'}>
				{getTitle(pageNum)}
				<div className="labWorkShow__comments" key={'pc1'}>
					{comment.map((item, ind) => getCommentLine(pageNum, item, ind))}
				</div>
			</div>
		);
	};

	const checkUp = str => checkComb(str, '^^', 1);
	
	const checkComb = (str, comb, key) => {
		if (!str) return <></>;
		const arr = str.split(comb);
		const cn = comb === '^^' ? 'labWorkShow__commentsUp' : 'labWorkShow__commentsDown';
	
		const x = arr.map((item, ind) => ind % 2 === 0 ? 
			<span key={50*key + ind}>{comb === "^^" ? <>{checkComb(item, "^_", 100)}</> : item}</span> 
				: 
			<span className={cn} key={key + ind}>{item}</span>);
	
		return <>{x}</>;
	};	

	const getTableRow = (showPrint, row, rowInd) => {
		let currRow = row;
		let hasSpace = false;
		if (currRow.startsWith('++')) {
			 currRow = currRow.substring(2);
			 hasSpace = showPrint ? true : false;
		}
		const rowList = currRow.split('|');
		const pct = 100 / (rowList.length + 0.2);
		const pct1 = '' + (100 - pct * (rowList.length - 1)) + '%';
		const pcts = '' + pct +  '%';

		if (rowInd === 0) {
			return (
				<tr key={'tb0'+rowInd} className='labWorkShow__tableRow0'>
					{rowList.map((item, ind) =>
						<th className='labWorkShow__tableCol' 
							key={'tbl1'+rowInd+ind} 
							style={{width: ind === 0 ? pct1 : pcts}}>
							{checkUp(item)}
						</th>)}
				</tr>
				);
		} else {
			return (
				<tr key={'tb22'+rowInd} className='labWorkShow__tableRow'>
					{rowList.map((item, ind) =>
						<td className='labWorkShow__tableCol' style={{width: ind === 0 ? pct1 : pcts}}
							key={'tb20'+rowInd + ind}>
							{checkUp(item)} {hasSpace && (<span><br/>&nbsp;<br/>&nbsp;</span>)}
						</td>)}
				</tr>
			);
		}
	};

	const getTable = (showPrint, tableList) => {
		const table = tableList ? 
			(
				<div>
					<table className='labWorkShow__table'>
						<tbody>
							{tableList.map((row, rowInd) => getTableRow(showPrint, row, rowInd))}
						</tbody>
					</table>
					<div>&nbsp;</div>
				</div>
			) 
				: 
			<div></div>;
		return table;
	};

	const getPrintTable = currPage => {
		return (
			<div className='lws__TablePagePrint'>
				<div className='lws__TablePagePrintLine'><b>Предмет: {subjectName.current}. Раздел: {sectionName.current}</b></div>
				<div className='lws__TablePagePrintLine'><b>{getPage(0).title}</b></div>
				<div className='lws__TablePagePrintLine'><b>{labWork.labName}</b></div>
				<div>{getTable(showPrint, getTableData(currPage))}</div>
			</div>
		);
	};

	const getHeader = () => {
		return (
            <div className="labWorkShow__head">
				{isTeacher() && (
				<div>
            	    <div className="labWorkShow__headLeave" onClick={handleBack}>
                	    <span className="labWorkShow__iconExit"><Icon name="arrow-prev" /></span> 
						<span>Выйти</span>
                	</div>
				</div>
				)}

				<div className="labWorkShow__headTitle">{ labWorkIndex.current + 1 }. { labWork.labName }</div>

				{isStudent() && (
				<div className="labWorkShow__headTime">
					{showCurrTime(currTestTime, startTime)} 
				</div>
				)}

                <div className="labWorkShow__headCount">
					Страница { currPage+1 }/{ labWork.labPages.length }
				</div>
            </div>
		);
	};

	const getActionButtons = (page) => {
		const isLastPage = labWork?.labPages ? (page + 1) === labWork.labPages.length : 0;
		const refreshStyle = lottieStart ? {color: '#666F7E'} : {};

		const buttons = (
			<>
				<div className="labWorkShow__footerMaterials">
					{getLottieTime()}
				</div>

				<div className="labWorkShow__footerBtns">
					{page > 0 && (<button onClick={handlePrevPicture}>
						<Icon name={'arrow-round-prev'}/> Назад
					</button>)}

					<button onClick={handleRefreshPicture} className='btnInactive' 
						style={refreshStyle} disabled={!hasLottiePicture(page)}>
						<Icon name={'reload'}/> Повторить 
					</button>

					{!!labWork && hasTable(page) && (
						<button onClick={handleSaveXlxs} className="labWorkShow__footer__button">
							<Icon name={'delivery'}/>&nbsp;Материалы для работы
						</button> 
					)}
					
					<button onClick={handleNextPicture} disabled={isLastPage}>
						Вперед <Icon name={'arrow-round-next'}/>
					</button>
					{/*<button onClick={() => setShowPrint(true)} disabled={isLastPage}>ПЕЧАТЬ</button> */}
				</div>

				<div className="labWorkShow__footerComplete">
				
					{isLastPage && <button onClick={handleStudentCompleted}>
						Завершить <Icon name={'check-round'}/>
					</button>}
				</div>
			
				<div className='labWorkShow__footerProgressBar'>
					{hasLottiePicture(page) && (
						<progress id="myBar" value={percentage} max="100">{percentage}</progress>
					)}
				</div>
 			</>
		);
		return buttons; 
	};

    const renderPages = () => {
        //1. IT IS INITIAL PAGE:
        if (currPage === 0) {
            return (
                <div className="labWorkShow__first">
                    <div className="labWorkShow__firstLegend2">
                        {getPage(currPage).title ? getPage(currPage).title : ' '}
                    </div>
                    <div className="labWorkShow__firstName">
                        <span>{getPage(currPage).comment ? getPage(currPage).comment.join(' ') : ' '}</span>
                    </div>
                </div>
            );
        }

        //2. IT IS WORK GOAL page:
        if (currPage === 1) {
            return (
                <div className="labWorkShow__first">
                    <div className="labWorkShow__firstLegend2">
                        {getPage(currPage).title ? getPage(currPage).title : ' '}
                    </div>
                    <div className="labWorkShow__firstName labWorkShow__firstNameGoal">
                        <span>{getPage(currPage).comment ? getPage(currPage).comment.join(' ') : ' '}</span>
                    </div>
                </div>
            );
        }

        //3. HAS ONLY COMMENTS:
        if (!getPicture(currPage) && !getTableData(currPage)) {
			return (
				<div className="labWorkShow__first">
             		{getPageComment(currPage)}
				</div>
			);
        }

        //4. HAS COMMENTS and PICTURE:
        if (hasPicture(currPage) && hasComment()) {
			return (
				<>
					<div className="labWorkShow__cols">
						<div className="labWorkShow__col">
							{getPageComment(currPage)}
						</div>
						<div className="labWorkShow__col">
							{hasLottiePicture(currPage) && getLottiePicture(currPage)}
							{hasOrdinaryPicture(currPage) && getOrdinaryPicture(currPage)}
						</div>
					</div>
				</>
			);
		}

        //5. HAS ONLY PICTURE (we will show only the title):
        if (hasPicture(currPage) && !hasComment()) {
			return (
				<>
					{getTitle(currPage)}
					{hasLottiePicture(currPage) && getLottiePicture(currPage)}
					{hasOrdinaryPicture(currPage) && getOrdinaryPicture(currPage)}
				</>
			);
		}

        //6. HAS TABLE:
        if (hasTable(currPage)) {
			return (
                <div className='labWorkShow__TablePage'>
					{getPageComment(currPage)}
                    {getTable(showPrint, getPage(currPage).table)}
                </div>
			);
		}

		return (<></>);
    }

	//0. NO DATA YET:
	if (!labWork) {
		if (isStudent()) {
            return (
                <div className="labWorkShow__first">
                    <div className="labWorkShow__firstName">
                        <span>Преподаватель не определил задание.</span>
                    </div>
                </div>
            );
		}

		return (
			<div className="labWorkShow__first">
				<div>Дополнительная информация отсутствует</div>
				{getActionButtons(currPage)}
			</div>
		);
	}

    return (
		<Content>
			<div className="labWorkShow">
				{getHeader()}

				<div className="labWorkShow__body labWorkShow__bodyLw" id='bodyBox'>
					{renderPages()}
				</div>

				<div className="labWorkShow__footer">
					{getActionButtons(currPage)}
				</div>

				{showModalCongrats &&
				<ModalCongratsDlg
					showModal={showModalCongrats} 
					isComplete={isComplete}
					workType={1}
					handleLeaveLabWork={handleLeaveLabWork}
					printTable={getPrintTable(currPage)}
				/>
				}

				{showPrint &&
					<ModalPrint
						showPrint={showPrint} 
						printData={getPrintTable(currPage)}
						closePrint={() => setShowPrint(false)}
					/>
				}
			</div>
		</Content>
    )
}

export default LabWorkShow;

//Lottie player in React: //https://www.npmjs.com/package/react-lottie-player
//Lottie player in React N2 (main): //https://lottiefiles.com/blog/working-with-lottie/how-to-use-lottie-in-react-app
