import React from 'react';
import "../Content.scss";

const ContentHeader = ({
    children,
    className = '',
    flex = false,

    }) => {

    let headerClass = "cor_content__header ";
    if (flex) {
        headerClass += "flex ";
    }
    return (
        <div className={headerClass + className}>
            { children }
        </div>
    )
};
export default ContentHeader;
