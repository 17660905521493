// import * as jsttf from 'jsfft';

// console.log(self);
export const connectWebWorker = () => {
    let count = 0, // Использется для принудительно обновления redux
        data = {}, // Храним данные записанные с датчиков
        buttonPressed = false; // Отслеживание нажатия датчика Кнопка для расчета показаний в выбранном режиме

    /* eslint-disable-next-line no-restricted-globals */
    // importScripts('jsfft');
    /* eslint-disable-next-line no-restricted-globals */
    // self.jsttf = jsttf;

    /* eslint-disable-next-line no-restricted-globals */
    self.addEventListener('message', (e) => {
        if (!e && !e.data) return;
        const { timer, played, paused, value, list, signalMode, isHightSpeed, cpu } = e.data;
        let response; // Переменная для записи ответов на команды

        // Очищаем сохраненный массив
        if (list.length && played && timer === 0) {
            list.forEach(({ key }) => (data[key] = [[], []]));
        }

        if (played && !paused) {
            // Преобразование строки в массив значений сигнала
            const arr = value.replace(/\r\n/, '').split(' ');

            // Сохраняем новый массив значений
            list.forEach(({ key }, i) => {
                const val = +arr[i];
                if (isNaN(val)) return;

                if (key !== 'Button') {
                    // Если не датчик Кнопка - записываем значение
                    data[key][1].push(val);
                } else {
                    // Обновляем сигнал кнопки
                    if (val === 1) {
                        if (!buttonPressed) {
                            buttonPressed = true;
                            updateSignalData(signalMode, 1);
                            return;
                        }
                    } else {
                        if (buttonPressed) {
                            buttonPressed = false;
                            updateSignalData(signalMode, 0);
                            return;
                        }
                    }
                    updateSignalData(signalMode, 0);
                }

                data[key][0].push(timer);
                // Выравнивание временного массива
                data[key][0] = range(0, timer, data[key][0].length);
            });
            count++;
        } else {
            if (paused) {
                // Отправляем данные для корректного обновления паузы
                response = 'paused ' + count++;
            } else {
                // Формируем ответ от датчика на команды
                response = value.replace(/\r\n/, '');
                count = 0;
            }
        }

        if (played) {
            if (isHightSpeed) {
                // Снижаем частоту обновления приложения для высокочастотных датчиков с зависимостью от количества ядер процессора
                const frCount = cpu > 4 ? 10 : 16;
                if (count > frCount) {
                    // Отправляем сохраненные данные при записи эксперимента
                    postMessage({ data, response, played });
                    if (!paused) {
                        count = 0;
                    }
                } else {
                    // Обновляем response для более точных показаний таймера
                    postMessage({ data: null, response, played });
                }
            } else {
                // Отправляем сохраненные данные при записи эксперимента
                postMessage({ data, response, played });
            }
        } else {
            // Отправляем данные
            postMessage({ data, response, played });
        }

        // Рассчет текущего значения кнопки в зависимости от выбранного режима
        function updateSignalData(signalMode, press) {
            const vals = data?.Button[1];
            const signalPrev = vals[vals.length - 1] || 0;
            let val = 0;

            switch (signalMode) {
                case 1:
                    // Одиночный
                    if (press && !signalPrev) {
                        val = 1;
                    } else {
                        val = 0;
                    }
                    data.Button[1].push(val);
                    break;

                case 2:
                    // Двойной
                    const last = vals?.findLast((e) => e !== 0);
                    if (press && last === -1) {
                        val = 1;
                    }
                    if (press && last === 1) {
                        val = -1;
                    }
                    data.Button[1].push(val);
                    break;

                default:
                    // Временной
                    if (press && signalPrev) {
                        data.Button[1].push(0);
                    } else if (press && !signalPrev) {
                        data.Button[1].push(1);
                    } else {
                        data.Button[1].push(signalPrev);
                    }
                    break;
            }
        }

        // Выравнивание временного массива
        function range(start, end, len) {
            const step = Math.floor((end - start) / len);
            return Array(len)
                .fill()
                .map((_, idx) => Math.floor(start + idx * step));
        }
    });
};
