import React, { useState, useEffect } from 'react';
import { Switcher, Range } from 'rlabui';
import './style.scss';

const OscilloChannel = (props) => {
    const { chartSettings, defaultChartSettings, setOscilloscopeChartSettings, index, isOscilloscope } = props;

    // console.log(props);

    const { yMin, yMax } = chartSettings;

    const [scanCollapsed, toggleScan] = useState(true);
    const [voltageCollapsed, toggleVoltage] = useState(true);

    const [scanFirst, setScanFirst] = useState(25);
    const [scanFirstPos, setScanFirstPos] = useState(3);
    const scanFirstValues = [2.5, 5, 10, 25, 50, 100, 250, 500];

    const [scanSeccond, setScanSeccond] = useState(25);
    const [scanSeccondPos, setScanSeccondPos] = useState(3);
    const scanSeccondValues = [2.5, 5, 10, 25, 50, 100, 250, 500, 1000, 2500, 5000, 10000, 25000, 50000, 100000];

    const [scanEnabled, setScanEnabled] = useState(0);

    const [voltageFirst, setVoltageFirst] = useState(200);
    const [voltageFirstPos, setVoltageFirstPos] = useState(0);
    const voltageFirstValues = [0.2, 0.5];

    const [voltageSeccond, setVoltageSeccond] = useState(1);
    const [voltageSeccondPos, setVoltageSeccondPos] = useState(0);
    const voltageSeccondValues = [1, 2, 5, 10];

    const [voltageEnabled, setVoltageEnabled] = useState(0);

    const [xBias, setXBias] = useState(0);
    const [yBias, setYBias] = useState(0);

    useEffect(() => {
        isOscilloscope && setScanFirst(scanFirstValues[scanFirstPos]);
    }, [scanFirstPos]);

    useEffect(() => {
        isOscilloscope && setScanSeccond(scanSeccondValues[scanSeccondPos]);
    }, [scanSeccondPos]);

    useEffect(() => {
        isOscilloscope && setVoltageFirst(voltageFirstValues[voltageFirstPos]);
    }, [voltageFirstPos]);

    useEffect(() => {
        isOscilloscope && setVoltageSeccond(voltageSeccondValues[voltageSeccondPos]);
    }, [voltageSeccondPos]);

    // useEffect(() => {
    //     console.log(chartSettings.channels[index]);
    // },[chartSettings]);

    useEffect(() => {
        // const obj = defaultChartSettings;
        const newArr = chartSettings.channels ? chartSettings.channels : [];
        const newData = chartSettings.channels ? chartSettings.channels[index] : {};

        // console.log(defaultChartSettings.channels[index]);
        // console.log(chartSettings.channels);

        for (let key in defaultChartSettings.channels[index]) {
            // console.log(index, key);oscilloChartSettings
            switch (key) {
                case 'xMin':
                    newData[key] = 0 + xBias;
                    break;
                case 'xMax':
                    newData[key] = (scanEnabled ? scanSeccond : scanFirst) + xBias;
                    break;
                case 'yMin':
                    newData[key] = yMin - (voltageEnabled ? voltageSeccond : voltageFirst) + yBias;
                    break;
                case 'yMax':
                    newData[key] = yMax + (voltageEnabled ? voltageSeccond : voltageFirst) + yBias;
                    break;
                case 'xBias':
                    newData[key] = xBias;
                    break;
                case 'yBias':
                    newData[key] = yBias;
                    break;
                default:
            }
        }
        // console.log(newData);
        newArr[index] = newData;
        // console.log(newArr);
        isOscilloscope && setOscilloscopeChartSettings({ id: 'channels', data: newArr });
    }, [scanFirst, scanSeccond, voltageFirst, voltageSeccond, xBias, yBias, scanEnabled, voltageEnabled, yMin, yMax]);

    return (
        <>
            <div
                className={scanCollapsed ? 'oscilloSetts__toggle active' : 'oscilloSetts__toggle'}
                onClick={() => toggleScan(!scanCollapsed)}
            >
                Развертка
            </div>
            {!scanCollapsed && (
                <div className="oscilloSetts__list">
                    {/* Scan row 1 */}
                    <div className="oscilloSetts__row">
                        <div className="oscilloSetts__label">
                            <label className="checkbox">
                                <span>Масштаб ряд 1</span>
                                <Switcher
                                    checked={scanEnabled === 0}
                                    // disabled={scanEnabled === 1}
                                    onChange={() => setScanEnabled(0)}
                                />
                            </label>
                        </div>
                        <div className="oscilloSetts__unit">
                            <span>{scanFirst}</span>
                            <span> мкс/дел</span>
                        </div>
                        <div className="oscilloSetts__range">
                            <div className="range">
                                <Range
                                    disabled={scanEnabled === 1}
                                    min={0}
                                    max={7}
                                    step={1}
                                    value={scanFirstPos}
                                    plus={() => setScanFirstPos((val) => (val < 7 ? ++val : val))}
                                    minus={() => setScanFirstPos((val) => (val > 0 ? --val : val))}
                                    onChange={({ target: { value } }) => setScanFirstPos(value)}
                                />
                            </div>
                        </div>
                    </div>
                    {/* Scan row 2 */}
                    <div className="oscilloSetts__row">
                        <div className="oscilloSetts__label">
                            <label className="checkbox">
                                <span>Масштаб ряд 2</span>
                                <Switcher
                                    checked={scanEnabled === 1}
                                    // disabled={scanEnabled === 0}
                                    onChange={() => setScanEnabled(1)}
                                />
                            </label>
                        </div>
                        <div className="oscilloSetts__unit">
                            <span>{scanSeccond >= 1000 ? scanSeccond / 1000 : scanSeccond}</span>
                            <span> {scanSeccond >= 1000 ? 'мс/дел' : 'мкс/дел'}</span>
                        </div>
                        <div className="oscilloSetts__range">
                            <div className="range">
                                <Range
                                    disabled={scanEnabled === 0}
                                    min={0}
                                    max={14}
                                    step={1}
                                    value={scanSeccondPos}
                                    plus={() => setScanSeccondPos((val) => (val < 14 ? ++val : val))}
                                    minus={() => setScanSeccondPos((val) => (val > 0 ? --val : val))}
                                    onChange={({ target: { value } }) => setScanSeccondPos(value)}
                                />
                            </div>
                        </div>
                    </div>
                    {/* Scan row 3 */}
                    <div className="oscilloSetts__row">
                        <div className="oscilloSetts__label">
                            <label className="checkbox">
                                <span>Смещение</span>
                            </label>
                        </div>
                        <div className="oscilloSetts__unit">
                            <span>{Math.round(xBias * 100) / 100}</span>
                            <span> мс</span>
                        </div>
                        <div className="oscilloSetts__range">
                            <div className="range">
                                <Range
                                    min={-100}
                                    max={100}
                                    step={0.1}
                                    value={xBias}
                                    plus={() => setXBias((val) => (val < 100 ? +val + 0.1 : val))}
                                    minus={() => setXBias((val) => (val > -100 ? +val - 0.1 : val))}
                                    onChange={({ target: { value } }) => setXBias(+value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div
                className={voltageCollapsed ? 'oscilloSetts__toggle active' : 'oscilloSetts__toggle'}
                onClick={() => toggleVoltage(!voltageCollapsed)}
            >
                Напряжение
            </div>
            {!voltageCollapsed && (
                <div className="oscilloSetts__list">
                    {/* Voltage row 1 */}
                    <div className="oscilloSetts__row">
                        <div className="oscilloSetts__label">
                            <label className="checkbox">
                                <span>Масштаб ряд 1</span>
                                <Switcher
                                    checked={voltageEnabled === 0}
                                    // disabled={voltageEnabled === 0}
                                    onChange={() => setVoltageEnabled(0)}
                                />
                            </label>
                        </div>
                        <div className="oscilloSetts__unit">
                            <span>{voltageFirst * 1000}</span>
                            <span> мВ/дел</span>
                        </div>
                        <div className="oscilloSetts__range">
                            <div className="range">
                                <Range
                                    disabled={voltageEnabled === 1}
                                    min={0}
                                    max={1}
                                    step={1}
                                    plus={() => setVoltageFirstPos((val) => (val < 1 ? ++val : val))}
                                    minus={() => setVoltageFirstPos((val) => (val > 0 ? --val : val))}
                                    value={voltageFirstPos}
                                    onChange={({ target: { value } }) => setVoltageFirstPos(value)}
                                />
                            </div>
                        </div>
                    </div>
                    {/* Voltage row 2 */}
                    <div className="oscilloSetts__row">
                        <div className="oscilloSetts__label">
                            <label className="checkbox">
                                <span>Масштаб ряд 2</span>
                                <Switcher
                                    checked={voltageEnabled === 1}
                                    // disabled={voltageEnabled === 1}
                                    onChange={() => setVoltageEnabled(1)}
                                />
                            </label>
                        </div>
                        <div className="oscilloSetts__unit">
                            <span>{voltageSeccond}</span>
                            <span> В/дел</span>
                        </div>
                        <div className="oscilloSetts__range">
                            <div className="range">
                                <Range
                                    disabled={voltageEnabled === 0}
                                    min={0}
                                    max={3}
                                    step={1}
                                    plus={() => setVoltageSeccondPos((val) => (val < 3 ? ++val : val))}
                                    minus={() => setVoltageSeccondPos((val) => (val > 0 ? --val : val))}
                                    value={voltageSeccondPos}
                                    onChange={({ target: { value } }) => setVoltageSeccondPos(value)}
                                />
                            </div>
                        </div>
                    </div>
                    {/* Voltage row 3 */}
                    <div className="oscilloSetts__row">
                        <div className="oscilloSetts__label">
                            <label className="checkbox">
                                <span>Смещение</span>
                            </label>
                        </div>
                        <div className="oscilloSetts__unit">
                            <span>{Math.round(yBias * 100) / 100}</span>
                            <span> В</span>
                        </div>
                        <div className="oscilloSetts__range">
                            <div className="range">
                                <Range
                                    min={-100}
                                    max={100}
                                    step={0.1}
                                    value={yBias}
                                    plus={() => setYBias((val) => (val < 100 ? +val + 0.1 : val))}
                                    minus={() => setYBias((val) => (val > -100 ? +val - 0.1 : val))}
                                    onChange={({ target: { value } }) => setYBias(+value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
export default OscilloChannel;
