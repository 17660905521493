import React, {useEffect, useState} from 'react';
import "./Navbar.scss";
import Icon from '../../ui/Icon';
import {hasFeature, isAuth, profilePicture, signout} from "../../../helpers/auth";
import Modal from '../../Modal';
import socket from "../../../socket";
import {storageOrg, storageUser, storageClass} from "../../../redux/slices/storage";
import {useSelector, useDispatch} from "react-redux";
import {FEATURES} from "../../../api/config";
import {WS_TYPE} from "../../PhysicalInvest/pi_utils";
import {getPrInfoCounter, getNbInfoCounter} from "../Dashboard/db_utils";
import {loadPIResearches} from "../../../redux/slices/lists";
import {toast} from "react-toastify";
import Notify from '../../ui/Notify';

const Navbar = ({match, history}) => {
    const [showConfirm, setShowConfirm] = useState(false);
    const [currPath, setCurrPath] = useState('');
    const [buttons, setButtons] = useState([]);
    const [refreshPiCounter, setRefreshPiCounter] = useState(false);

    const documentUser = useSelector(storageUser);
    const documentOrg = useSelector(storageOrg);
    const documentClass = useSelector(storageClass);

    const dispatch = useDispatch();
	const {lists: {pi}} = useSelector(state => state);

    useEffect(() => {
        socket.on("FE-new-login", () => {
            signout(() => {
                history.push("/login");
                toast.info("Вход с другого устройства");
            });
        });
        return () => {
            socket.off("FE-new-login");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

	useEffect(() => {
		//dispatch(loadPIResearches(documentClass._id, WS_TYPE.ALL));
        if (refreshPiCounter) setRefreshPiCounter(false);
	}, [dispatch, documentClass._id, refreshPiCounter]);

    useEffect(() => {
        socket.on("FE-change-pi", ({ownerId}) => {
            //if (ownerId === isAuth()._id) {
                setRefreshPiCounter(true);
            //}
        });
        return () => {socket.off("FE-change-pi");}
    }, []);

    const handleExit = () => {
        if (isAuth()) {
            socket.emit("BE-leave-room", {leaver: isAuth()});
        }

        signout(() => {
            history.push("/login");
            toast.info("Выход");
        });
    };

    const handleItemClick = (to) => {
        setCurrPath(to);
        if (to === "/login") {
            setShowConfirm(true);
        } else if (currPath !== "/vrlab") {
            history.push(to);
        }
    };

    useEffect(() => {
        const isVisible = (f) => (f.role.includes(isAuth().role) && (f.name || 
            (f.sub ? f.sub.some(s => hasFeature(documentOrg, s)) : true)));
        const isActive = (f) => [f.to, ...(f.alt ?? [])].includes(match.path) ||
            (f.sub && f.sub.some(s => [FEATURES[s].to, ...(FEATURES[s].alt ?? [])].includes(match.path)));

        const initButtons = async () => {
            const _buttons = Object.entries(FEATURES)
            .filter(([k, f]) => (f.name && isAuth().role < 3) ? hasFeature(documentOrg, k) : true)
            .map(([, f]) => ({
                name: f.icon, 
                to: f.to, 
                title: f.title,
                isActive: isActive(f), 
                visible: isVisible(f),
            }));
            _buttons.splice(-1, 0, {grow: true, visible: true});
            _buttons.push({name: 'log-out', to: '/login', alt: [], visible: true, title: "Выйти"});
    
            if (!_buttons[0].visible && !isAuth()) {
                 signout(() => history.push("/login"));
            } else {
                if (isAuth().role < 3) {
                    const pr = _buttons.find(item => item.name === 'microscope');
                    //if (pr) pr.notify = await getPrInfoCounter(documentClass._id, pi.researches);
                    const nb = _buttons.find(item => item.name === 'notes');
                    //if (nb) nb.notify = await getNbInfoCounter(documentClass._id);
                }
                setButtons(_buttons);
            }
        };
    
        initButtons();
    }, [documentClass._id, documentOrg, history, match, pi]);

    const exitCancel = () => {
        setShowConfirm(false);
    };

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) exitCancel();
        };
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="cor_navbar">
            {buttons.filter(b => b.visible).map((b, i) => (
              b.grow ? <div key={i} className="cor_navbar_grow"/> : (b.to === '/profile') ?
                <div className="profile__wrap" key={i}>
                    <i className={"profile" + (b.isActive ? " active" : "")}
                            onClick={() => handleItemClick(b.to)}>
                        <div className="profile__img">
                            {b.to === '/profile' && isAuth().picture ?
                                <img src={profilePicture(documentUser.picture)} alt={documentUser.name}/> 
                            :
                                <Icon name={b.name} className={b.isActive ? "active" : ""}/>
                            }
                        </div>
                        <div className="profile__content">
                            <span className="profile__name">{documentUser.name}</span>
                            <span className="profile__link">Перейти в профиль</span>
                        </div>
                    </i>
                    <Notify value={b.notify} className="cor_navbar__notify" />
                </div>
                :
                <span key={i} className="tp tp_center_right" data-tp={b.title}>
                    <Icon name={b.name} className={b.isActive ? "active" : ""}
                        onClick={() => handleItemClick(b.to)}/>
                    <Notify value={b.notify} className="cor_navbar__notify" />
                </span>
            ))}
            <Modal visible={showConfirm} content={<>
                <div className="modal__message">Вы действительно хотите выйти?</div>
                <div className="modal__action">
                    <button className="cor_btn cor_btn_primary" onClick={handleExit}>Выход</button>
                    <button className="cor_btn" onClick={exitCancel}>Отмена</button>
                </div>
            </>} size={'xs'}/>
        </div>
    );
};
export default Navbar;
