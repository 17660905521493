import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './List.scss';
import AddInput from '../AddInput/AddInput';
import AddButton from '../AddButton/AddButton';
import Menu from '../Menu/Menu';
import { getListMenu } from './ListMenuData';
import OptionsButton from '../OptionsButton/OptionsButton';
import {
  addNewCard,
  archiveAllTasks,
  deleteColumn,
  sortColumnCards,
  updateColumn,
  updateColumns,
} from '../../../redux/slices/trello';
import { useDispatch, useSelector } from 'react-redux';
import socket from '../../../socket';
import { ReactComponent as Rename } from '../../../assets/icons/card-menu/03_rename.svg';
import { ReactComponent as Arrow } from '../HeaderMenu/arrow.svg';
import Card from '../Card/Card';
import { storageOptions } from '../../../redux/slices/storage';
import { LIST_COLORS } from './ListColors';

const List = ({ item, children, scrolledContainer, archiveCards }) => {
  const LIST_MENU = getListMenu();
  const [addCardTitle, setAddCardTitle] = useState('');
  const [name, setName] = useState(item.name);
  const [isVisibleTextarea, setIsVisibleTextarea] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isVisibleRenameIcon, setIsVisibleRenameIcon] = useState(false);
  const [isOpenRenamingInput, setIsOpenRenamingInput] = useState(false);
  const [isOpenArchive, setIsOpenArchive] = useState(false);

  const cardsData = useSelector((state) => state.trelloSlice.cards);

  const menuRef = useRef(null);
  const btnMenuRef = useRef(null);
  const renamingInputRef = useRef(null);
  const nameRef = useRef(null);
  nameRef.current = name;

  const dispatch = useDispatch();
  const documentClass = useSelector((state) => state.classs.class);

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideInput);
    document.addEventListener('click', handleClickOutsideMenu);

    return () => {
      document.removeEventListener('click', handleClickOutsideInput);
      document.removeEventListener('click', handleClickOutsideMenu);
    };
  }, [menuRef, btnMenuRef, renamingInputRef]);

  const handleClickOutsideInput = (event) => {
    if (
      renamingInputRef.current &&
      !renamingInputRef.current.contains(event.target)
    ) {
      renameList(item?._id);
      setIsOpenRenamingInput(false);
      setIsVisibleRenameIcon(false);
      setIsOpenMenu(false);
    }
  };

  const handleClickOutsideMenu = (event) => {
    if (
      menuRef.current &&
      !btnMenuRef.current.contains(event.target) &&
      !menuRef.current.contains(event.target)
    ) {
      setIsOpenMenu(false);
    }
  };

  const handleShowTextarea = () => {
    setIsVisibleTextarea(!isVisibleTextarea);

    if (isVisibleTextarea) {
      setAddCardTitle('');
    }
  };

  const handleOpenMenu = (event) => {
    setIsOpenMenu((prevState) => !prevState);
  };

  const handleAddNewCard = () => {
    const sourceCards = cardsData.filter((card) => card.group === item?._id);
    setIsVisibleTextarea(true);
    const newCard = {
      name: addCardTitle,
      order: sourceCards.length,
      room: documentClass[0]?._id,
      group: item?._id,
      // stickers: [],
      // users: [],
      // subtasks: [],
    };

    if (addCardTitle) {
      dispatch(addNewCard(newCard));
      setAddCardTitle('');
      setIsVisibleTextarea(false);
    }
  };

  const renameList = (columnId) => {
    const name = nameRef.current;
    const newName = {
      name,
    };
    if (name) {
      dispatch(updateColumn(columnId, 'name', name));
      socket.emit('BE-refresh-db', {
        roomId: documentClass[0]?._id,
        type: 'trackerGroup',
        id: columnId,
        data: newName,
      });
      setName('');
    }
  };

  useEffect(() => {
    socket.on('BE-refresh-db', ({ type, id, data }) => {
      if (type === 'trackerGroup') dispatch(updateColumns(id, data));
    });
    return () => {
      socket.off('BE-refresh-db');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteList = (columnId) => {
    dispatch(deleteColumn(columnId));
  };

  LIST_MENU.delete.function = () => {
    deleteList(item?._id);
  };

  const handleSortBy = (field) => () => {
    if (!item.sortGroup || item.sortGroup.direction !== 1) {
      dispatch(sortColumnCards(item._id, field, 1));
    } else {
      dispatch(sortColumnCards(item._id, field, -1));
    }
  };

  Object.keys(LIST_MENU.sortBy.subitems).map((key) => {
    const subitem = LIST_MENU.sortBy.subitems[key];

    if (subitem.field) {
      subitem.function = handleSortBy(subitem.field);
    }
    return subitem;
  });

  LIST_MENU.archiveAll.function = () => {
    const columnCards = cardsData.filter((card) => card.group === item?._id);
    const archiveCardsIds = columnCards.map((card) => card._id);
    dispatch(archiveAllTasks(archiveCardsIds));
  };

  LIST_MENU.rename.function = () => {
    setName(item.name);
    setIsOpenRenamingInput(true);
    setIsOpenMenu(false);
  };

  const listCards = cardsData.filter(
    (card) => card.group === item?._id
    // && card.status === 0
  );
  const archiveCardss = archiveCards?.filter(
    (card) => card?.group === item?._id && card?.status < 0
  );

  const { fontSize } = useSelector(storageOptions);
  const { theme } = useSelector(storageOptions);
  const isLightMode = theme !== 'dark';

  function addAlpha(color, opacity) {
    const _opacity = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }

  const listCurrentColor = LIST_COLORS.find(
    (color) => color.listBackgroundColor === item?.color
  );

  return (
    <article
      className="list"
      style={{
        maxHeight:
          scrolledContainer?.current?.clientHeight - fontSize * 1.4 - 6 + 'px',
      }}
    >
      <header
        className="list__header"
        style={{
          backgroundColor: isLightMode
            ? listCurrentColor
              ? item?.color
              : LIST_COLORS[0].listBackgroundColor
            : listCurrentColor
            ? addAlpha(listCurrentColor.menuColor, 0.5)
            : addAlpha(LIST_COLORS[0]?.menuColor, 0.5),
        }}
      >
        {isOpenRenamingInput ? (
          <input
            ref={renamingInputRef}
            className="list__title__input"
            type="text"
            autoFocus
            value={name}
            onChange={(e) => setName(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                renameList(item?._id, name);
                setIsOpenRenamingInput(false);
                setIsOpenMenu(false);
              }
            }}
          />
        ) : (
          <div
            className="list__title__container"
            onMouseEnter={() => setIsVisibleRenameIcon(true)}
            onMouseLeave={() => setIsVisibleRenameIcon(false)}
          >
            <h2 className="list__title">{item?.name}</h2>
            {isVisibleRenameIcon && (
              <Rename
                onClick={(e) => {
                  e.stopPropagation();
                  setName(item.name);
                  setIsOpenRenamingInput(true);
                }}
                className="list__title__rename_icon"
              />
            )}
          </div>
        )}
        <div className={`list__options id-${item?._id}`}>
          <OptionsButton
            handleOpenMenu={handleOpenMenu}
            isOpenMenu={isOpenMenu}
            item={item}
            btnMenuRef={btnMenuRef}
          />

          <Menu
            items={{ ...LIST_MENU }}
            menuRef={menuRef}
            isOpenMenu={isOpenMenu}
            setIsOpenMenu={setIsOpenMenu}
            id={item._id}
            activeColor={item?.color}
          />
        </div>
      </header>
      <div className="list__body">
        <div
          className={
            listCards?.length !== 0 || isVisibleTextarea === true
              ? 'list__container'
              : 'list__container_empty'
          }
        >
          {
            <ul className="list__items">
              {children}
              {isVisibleTextarea && (
                <AddInput
                  newData={addCardTitle}
                  setNewData={setAddCardTitle}
                  placeholder="Введите название карточки"
                  handleAdd={handleAddNewCard}
                />
              )}
            </ul>
          }
        </div>

        <div className="list__footer">
          <AddButton
            isVisible={isVisibleTextarea}
            handleShow={handleShowTextarea}
            handleAdd={handleAddNewCard}
            title="Добавить карточку"
          />
        </div>
        {archiveCardss?.length !== 0 && (
          <div className={isOpenArchive ? 'list__archive' : ''}>
            <div
              className={
                isOpenArchive
                  ? 'list__archive__title open'
                  : 'list__archive__title'
              }
              onClick={() => setIsOpenArchive(!isOpenArchive)}
            >
              <span>Архив задач ({archiveCardss?.length})</span>
              <Arrow className={!isOpenArchive ? 'list__archive__icon' : ''} />
            </div>
            {isOpenArchive && (
              <div className={'list__container'}>
                {archiveCardss?.map((archiveCard) => (
                  <ul className="list__items" key={archiveCard?._id}>
                    <li className="card">
                      <Card card={archiveCard} />
                    </li>
                  </ul>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </article>
  );
};

export default List;
