export const chartColors = {
    marker: {
        line: 'rgba(0,0,0,0.5)',
        color: '#000000',
        background: '#FFFFFF',
        border: '#BEC9D3',
        dark: {
            line: 'rgba(255,255,255,1)',
            color: '#FFFFFF',
            background: '#363A45',
            border: '#4A4F5B',
        },
        table: {
            color: '#F75656',
        },
        cursor: {
            line: '#F75656',
            color: '#000000',
            background: '#FFFFFF',
            border: '#F75656',
            dark: {
                line: '#F75656',
                color: '#FFFFFF',
                background: '#363A45',
                border: '#F75656',
            },
        },
    },
    line: {},
    boxes: {
        chart: {
            box1: 'rgba(129, 184, 249, 0.2)',
            box2: 'rgba(128, 249, 212, 0.2)',
            box3: 'rgba(225, 128, 249, 0.2)',
            box4: 'rgba(249, 222, 128, 0.2)',
        },
        table: {
            box1: '#81B8F9',
            box2: '#80F9D4',
            box3: '#E180F9',
            box4: '#F9DE80',
        },
    },
    grid: {
        dark: '#343640',
        light: '#E8E8E8',
    },
    tick: '#8C8F9B',
    bg: {
        dark: '#282B33',
        light: '#FFFFFF',
    },
};

export const defaultColors = [
    { val: '#F35151', name: '#F35151' },
    { val: '#EF884E', name: '#EF884E' },
    { val: '#F9DE80', name: '#F9DE80' },
    { val: '#C2D83F', name: '#C2D83F' },
    { val: '#4EE4AE', name: '#4EE4AE' },
    { val: '#81F2F9', name: '#81F2F9' },
    { val: '#81B8F9', name: '#81B8F9' },
    { val: '#8B96FA', name: '#8B96FA' },
    { val: '#E180F9', name: '#E180F9' },
    { val: '#CE5A92', name: '#CE5A92' },
    { val: '#F35151', name: '#F35151' },
    { val: '#EF884E', name: '#EF884E' },
    { val: '#F9DE80', name: '#F9DE80' },
    { val: '#C2D83F', name: '#C2D83F' },
    { val: '#4EE4AE', name: '#4EE4AE' },
    { val: '#81F2F9', name: '#81F2F9' },
    { val: '#81B8F9', name: '#81B8F9' },
    { val: '#8B96FA', name: '#8B96FA' },
    { val: '#E180F9', name: '#E180F9' },
    { val: '#CE5A92', name: '#CE5A92' },
    { val: '#F35151', name: '#F35151' },
    { val: '#EF884E', name: '#EF884E' },
    { val: '#F9DE80', name: '#F9DE80' },
    { val: '#C2D83F', name: '#C2D83F' },
    { val: '#4EE4AE', name: '#4EE4AE' },
    { val: '#81F2F9', name: '#81F2F9' },
    { val: '#81B8F9', name: '#81B8F9' },
    { val: '#8B96FA', name: '#8B96FA' },
    { val: '#E180F9', name: '#E180F9' },
    { val: '#CE5A92', name: '#CE5A92' },
];

