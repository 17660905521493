import { setTheme } from '../../actions/actions';
import { setPrintModal } from '../../actions/sensors';

export const mapStateToProps = (state) => {
    return {
        theme: state.actions.theme,
        markers: state.chart.marker,
        tablePrint: state.sensors.tablePrint,
        modal: state.sensors.modalPrint,
        isOscilloscope: state.oscilloscope.isOscilloscope,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setPrintModal: (bool) => dispatch(setPrintModal(bool)),
    setTheme: (theme) => dispatch(setTheme(theme)),
});
