import React, { useRef } from 'react';
import { Chart, LineController, LineElement, PointElement, LinearScale, Title, CategoryScale } from 'chart.js';
import { Line } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';
import chartColors from '../../styles/charts';
// https://github.com/DuartBreedt/chartjs-plugin-draggable
import ChartjsDraggablePlugin from 'chartjs-plugin-draggable';
import ss from './Chart.module.scss';

Chart.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, zoomPlugin, annotationPlugin, ChartjsDraggablePlugin);

const ChartMini = (props) => {
    const { chart, played, paused, theme, series, tension = 0.3, color = 'red', defaultColor = 'red', weight = 1, cubicInterpolationMode = 'default', stepped = false, roundDigit = 0 } = props;
    const chartRef = useRef();

    const initialMiniOptions = {
        maintainAspectRatio: false,
        responsive: true,
        animation: false,
        parsing: false,
        legend: {
            display: false,
        },
        interaction: {
            includeInvisible: false,
        },
        layout: {
            padding: {
                top: 0,
                bottom: 0,
                left: 35,
                right: 15,
            },
        },
        elements: {
            line: {
                borderColor: color ? color : defaultColor,
                borderWidth: weight ? weight : 0,
                spanGaps: true,
                // скругление линии
                tension: tension,
                // Interpolation
                cubicInterpolationMode,
                stepped,
            },
            point: {
                pointRadius: 0,
                pointBackgroundColor: 'transparent',
            },
        },
        tooltips: {
            enabled: false,
        },
        title: {
            display: false,
        },
        hover: {
            mode: 'xy',
            intersect: false,
        },
        plugins: {
            decimation: {
                enabled: true,
                samples: 100,
                threshold: 1000,
                algorithm: 'lttb',
            },
            datalabels: {
                display: false,
            },
            annotation: {
                drawTime: 'afterDatasetsDraw',
                animations: {
                    numbers: {
                        properties: [],
                    },
                },
                annotations: setChartRange(),
            },
        },
        scales: {
            xAxis: {
                type: 'linear',
                min: series?.[0]?.x ? series[0].x : 0,
                max: series?.[series.length - 1]?.x ? series[series.length - 1].x : 10000,
                ticks: {
                    display: true,
                    count: 10,
                    maxTicksLimit: 10,
                    color: chartColors.tick,
                    font: {
                        size: 10,
                    },
                    major: {
                        enabled: true,
                    },
                    callback: (value) => {
                        return +(value / 1000).toFixed(2);
                    },
                },
                grid: {
                    display: true,
                    color: theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light,
                    borderColor: theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light,
                    tickColor: 'transparent',
                    tickLength: 2,
                },
                title: {
                    display: false,
                    text: 'время, сек',
                    color: color ? color : defaultColor,
                },
            },
            yAxis: {
                type: 'linear',
                min: null,
                max: null,
                ticks: {
                    display: true,
                    // count: 5,
                    maxTicksLimit: 5,
                    color: chartColors.tick,
                    font: {
                        size: 10,
                    },
                    enabled: true,
                    callback: (value) => {
                        return +value.toFixed(roundDigit);
                    },
                },
                grid: {
                    borderColor: theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light,
                    color: theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light,
                    tickColor: 'transparent',
                    tickLength: 0,
                },
                title: {
                    display: false,
                },
            },
        },
        onHover: (evt, elements, chart) => {},
    };

    function setChartRange() {
        let min, max;
        if (!chart || !series) {
            min = null;
            max = null;
        } else {
            min = chart.scales.xAxis.start < series[0]?.x ? series[0].x : chart.scales.xAxis.start;
            max = chart.scales.xAxis.end > series[series.length - 1]?.x ? series[series.length - 1].x : chart.scales.xAxis.end;
        }

        return {
            zoomStart: {
                type: 'line',
                resizer: true,
                borderWidth: 2,
                borderColor: theme === 'dark' ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.3)',
                scaleID: 'xAxis',
                value: min,
                draggable: true,
                drawTime: 'afterDraw',
                enter: function (context, event) {
                    document.body.style.cursor = 'col-resize';
                    const { id } = context;
                },
                leave: function (context, event) {
                    document.body.style.cursor = '';
                },
                onDragStart: function (event, value) {},
                onDrag: function (event, value) {
                    const box = chartRef.current.config.options.plugins.annotation.annotations.zoom;
                    const max = chartRef.current.config.options.plugins.annotation.annotations.zoomEnd.value;
                    box.xMax = max;
                    box.xMin = value;
                    chart.options.scales.xAxis.min = value;
                    chart.update();
                },
                onDragEnd: function (event, value) {},
            },
            zoom: {
                type: 'box',
                id: 'zoom',
                xScaleID: 'xAxis',
                yScaleID: 'yAxis',
                backgroundColor: theme === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
                borderWidth: 0,
                xMax: min,
                xMin: max,
                enter: (context, event) => {
                    // document.body.style.cursor = 'grab';
                },
                leave: (context, event) => {
                    // document.body.style.cursor = '';
                },
            },
            zoomEnd: {
                type: 'line',
                borderWidth: 2,
                resizer: true,
                borderColor: theme === 'dark' ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.3)',
                scaleID: 'xAxis',
                value: max,
                draggable: true,
                drawTime: 'afterDraw',
                enter: function (context, event) {
                    document.body.style.cursor = 'col-resize';
                    const { id } = context;
                },
                leave: function (context, event) {
                    document.body.style.cursor = '';
                },
                onDragStart: function (event, value) {
                    // const { subject: {config: {id}} } = event;
                },
                onDrag: function (event, value) {
                    const box = chartRef.current.config.options.plugins.annotation.annotations.zoom;
                    const min = chartRef.current.config.options.plugins.annotation.annotations.zoomStart.value;
                    box.xMin = min;
                    box.xMax = value;
                    chart.options.scales.xAxis.max = value;
                    chart.update();
                },
                onDragEnd: function (event, value) {},
            },
        };
    }

    return (
        <div className={!played || paused ? ss.mini + ' active' : ss.mini}>
            <div className={ss.mini_title}>Полный график</div>
            <Line
                ref={chartRef}
                options={initialMiniOptions}
                data={{ datasets: [{ data: series }] }}
            />
        </div>
    );
};
export default ChartMini;
