import { setSensorImported, setPrintModal, addSensorsBundles } from '../../actions/sensors';
import { setChartView, setChartMarkers } from '../../actions/chart';
import { sendCommand } from '../../actions/port';
import { setWriteStatus } from '../../actions/writer';
import { setFile, setFileExtension } from '../../actions/import';
import { setGraphFile } from '../../actions/scenario';

export const mapStateToProps = (state) => {
    return {
        port: state.port.open,
        theme: state.actions.theme,
        played: state.actions.played,
        deviceResponse: state.actions.response,
        records: state.sensors.records,
        sensorsImport: state.sensors.imported,
        chartView: state.chart.settings.view,
        colors: state.chart.settings.colors,
        isReading: state.reader.sensorReading,
        writeStatus: state.writer.writeStatus,
        file: state.import.file,
        fileExtension: state.import.fileExtension,
        isCor: state.actions.isCor,
        graphFile: state.scenario.graphFile,
        isOscilloscope: state.oscilloscope.isOscilloscope,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setSensorImported: (arr) => dispatch(setSensorImported(arr)),
    addSensorsBundles: (obj) => dispatch(addSensorsBundles(obj)),
    setChartMarkers: (obj) => dispatch(setChartMarkers(obj)),
    setChartView: (str) => dispatch(setChartView(str)),
    sendCommand: (str) => dispatch(sendCommand(str)),
    setPrintModal: (bool) => dispatch(setPrintModal(bool)),
    setWriteStatus: (bool) => dispatch(setWriteStatus(bool)),
    setFile: (str) => dispatch(setFile(str)),
    setFileExtension: (str) => dispatch(setFileExtension(str)),
    setGraphFile: (info) => dispatch(setGraphFile(info)),
});
