import React, {useState}  from 'react';
import Modal from "../../../Components/Modal";
import {Icon} from '../';

const ImageDlg = ({ showModal, setShowModal, file }) => {
    const [previewModalSize, setPreviewModalSize] = useState("lg");

    const getFileContent = () => {
        let content;

        if (file) {
            if (file.Url)
                content = file.Url;
            else {
                const fileBody = JSON.parse(file);
                content = fileBody.content;
            }
        }
        return content;
    };

    const setModalContent = () => {
        return (
            <div className="browsing">
                <div className="browsing__header">
                    <div className="browsing__title">
                        Просмотр
                    </div>
                    <div className="browsing__action">
                        <Icon name={previewModalSize === "lg" ? "grid_xl" : "minimize"}
                            onClick={() => setPreviewModalSize(previewModalSize === "lg" ? "browsing" : "lg")}/>
                        <Icon name="close" onClick={() => setShowModal(false)}/>
                    </div>
                </div>
                <div className="browsing__main">
                    <div className="browsing__img">
                        <img src={getFileContent()} alt=""/>
                    </div>
                </div>
            </div>
        )
    };

    if (!showModal || !file) {
        return false;
    }

    return (
        <Modal visible={showModal} content={setModalContent()} size={previewModalSize} />
    )

};

export default ImageDlg;

