import React, {useEffect, useState} from 'react';
import * as Yup from "yup";
import {toast} from "react-toastify";
import {useFormik} from "formik";
import {useDispatch} from "react-redux";
import Modal from '../Modal';
import {deviceApi} from "../../api/api";
import {loadDevices} from "../../redux/slices/admin";
import "./Settings.scss";

const DeviceSettings = (props) => {
    const dispatch = useDispatch();
    const [showConfirm, setShowConfirm] = useState(false);

    const deleteDevice = async () => {
        try {
            await deviceApi.delete(props.device._id);
            dispatch(loadDevices());
            handleClose();
        } catch (err) {
            console.log(err);
        }
    };

    const deleteCancel = () => setShowConfirm(false);
    const deleteConfirm = async () => {
        await deleteDevice();
        setShowConfirm(false);
    };

    const handleClose = () => {
        formik.resetForm();
        props.showDeviceSettings({showModal: false});
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) showConfirm ? deleteCancel() : handleClose();
        };
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showConfirm]);

    let error = {visible: false, message: ""};
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            key: props.device?.key,
            name: props.device?.name,
            firmwares: props.device?.firmwares,
        }, validationSchema: Yup.object({
            key: Yup.string().required("Введите ключ"),
            name: Yup.string().required("Введите имя"),
        }), onSubmit: async (formData) => {
            try {
                if (props.isAdd) {
                    const data = {
                        key: formData.key,
                        name: formData.name,
                        firmwares: [],
                    };

                    await deviceApi.add(data).then(() => {
                        dispatch(loadDevices());
                        handleClose();
                    }).catch((err) => {
                        toast.error(err.response.data.errors);
                    });
                } else {
                    const data = {
                        key: formData.key,
                        name: formData.name,
                    };

                    await deviceApi.update(props.device._id, data).then((updated) => {
                        dispatch(loadDevices());
                        handleClose();
                    }).catch((err) => {
                        toast.error(err.response.data.errors);
                    });
                }
            } catch (err) {
                error = {
                    visible: true, message: JSON.stringify(err.errors, null, 2),
                };
                console.log(error);
            }
        },
    });

    return (
        <>
            <Modal visible={props.showModal} content={
                <form onSubmit={formik.handleSubmit}>
                    <div className="modal__close" onClick={handleClose}/>
                    <div className="modal__message">{props.isAdd ? "Добавить" : "Редактировать"} Устройство</div>
                    <div className="modal__body">
                        <div key="email" className="cor_settings">
                            <label htmlFor="email">Ключ</label>
                            <input
                                autoComplete="off"
                                placeholder="Ключ"
                                name="key"
                                className={formik.errors.key?"form_error":""}
                                onChange={formik.handleChange}
                                value={formik.values.key}
                            />
                            <div className="form_error">{formik.errors.key}</div>
                        </div>
                        <div key="name" className="cor_settings">
                            <label htmlFor="name">Имя</label>
                            <input
                                autoComplete="off"
                                placeholder="Имя"
                                className={formik.errors.name?"form_error":""}
                                name="name"
                                onChange={formik.handleChange}
                                value={formik.values.name}
                            />
                            <div className="form_error">{formik.errors.name}</div>
                        </div>
                    </div>
                    <div className="modal__action">
                        {props.allowDelete && <button
                            className="cor_btn cor_btn_danger"
                            type="button"
                            onClick={() => setShowConfirm(true)}
                        >Удалить</button>}
                        <div style={{width: "50%"}}/>
                        <button
                            className="cor_btn cor_btn_primary"
                            type="submit"
                        >{props.isAdd ? "Добавить" : "Сохранить"}</button>
                    </div>
                </form>
            }/>
            <Modal visible={showConfirm} content={
                <>
                    <div className="modal__message">Точно удалить?</div>
                    <div className="modal__action">
                        <button
                            className="cor_btn"
                            onClick={deleteCancel}
                        >Отменить
                        </button>
                        <button
                            className="cor_btn cor_btn_danger"
                            onClick={deleteConfirm}
                        >Удалить
                        </button>
                    </div>
                </>
            } size={'xs'}/>
        </>
    )
};

export default DeviceSettings;