const initialState = {
    data: {},
    writeStatus: false,
    writerSelected: 0,
};

const writerReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_WRITER':
            let add = { ...state.data };
            add[action.payload.key] = action.payload.data;
            return {
                ...state,
                data: add,
            };

        case 'REMOVE_WRITER':
            let remove = { ...state.data };
            delete remove[action.payload.key];
            return {
                ...state,
                data: remove,
            };

        case 'SET_WRITE_STATUS':
            return {
                ...state,
                writeStatus: action.payload,
            };

        case 'TOGGLE_WRITER':
            return {
                ...state,
                writerSelected: action.payload,
            };
        default:
            return state;
    }
};

export default writerReducer;
