import React, {useEffect} from 'react';
import Modal from "../../Modal";
import CorVersionItem from "./CorVersionItem";
import ss from '../../../rlab/src/components/Template/VersionModal/VersionModal.module.scss';

const CorVersion = (props) => {
  const {modal, toggleModal, versionList} = props;

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) toggleModal(false);
    };
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal visible={modal} content={
      <>
        <div className="modal__message">Список изменений</div>
        <div className="modal__close" onClick={() => toggleModal(false)}></div>
        <div className="modal__body">
          <div className={ss.modal + " scrolledBox"}>
            {modal && versionList?.map((item, i) => {
              return (
                <CorVersionItem key={i} item={item} isOpen={i === 0}/>
              );
            })}
          </div>
        </div>
      </>
    } size='lg'/>
  )
};
export default CorVersion;
