const initialState = {
    response: '',
    data: {},
    values: {},
    filters: {},
    intervals: [],
    list: [],
    records: {},
    imported: [],
    enabled: {},
    channels: {},
    visualized: null,
    isArtPressure: false,
};

const sensorReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_NSENSOR_RESP':
            return {
                ...state,
                response: action.payload,
            };
        case 'SET_NSENSOR_DATA':
            return {
                ...state,
                data: action.payload,
            };
        case 'RESET_NSENSOR_DATA':
            const cleanData = {};
            state.list.forEach(({ key }) => (cleanData[key] = []));
            return {
                ...state,
                data: cleanData,
                records: [],
                imported: [],
            };
        case 'SET_NSENSOR_VALUE':
            return {
                ...state,
                values: action.payload,
            };
        case 'SET_NSENSOR_INTERVALS':
            return {
                ...state,
                intervals: action.payload,
            };
        case 'SET_NSENSOR_LIST':
            return {
                ...state,
                list: action.payload,
            };

        case 'SET_NART_PRESSURE':
            return {
                ...state,
                isArtPressure: action.payload,
            };

        case 'SET_NSENSOR_RECORDS':
            let records = { ...state.records };
            let key = action.payload.key;
            if (key) {
                records[key] = action.payload.data;
            } else {
                records = {};
            }
            return {
                ...state,
                records,
            };

        case 'SET_NSENSORS_CHANNELS':
            return {
                ...state,
                channels: action.payload,
            };

        case 'SET_NSENSOR_IMPORTED':
            return {
                ...state,
                imported: action.payload,
            };

        case 'SET_NSENSOR_VISUALIZED':
            return {
                ...state,
                visualized: action.payload,
            };

        case 'ADD_NSENSORS_ENABLED':
            let enabled = { ...state.enabled };
            enabled[action.payload.key] = action.payload.count;
            // console.log(state.item);
            return {
                ...state,
                enabled,
            };

        case 'DEL_NSENSORS_ENABLED':
            let enabledDel = { ...state.enabled };
            delete enabledDel[action.payload.key];
            return {
                ...state,
                enabled: enabledDel,
            };

        case 'ADD_NSENSORS_FILTERS':
            let filters = { ...state.filters };
            filters[action.payload.key] = action.payload.data;
            // console.log(state.item);
            return {
                ...state,
                filters,
            };

        case 'DEL_NSENSORS_FILTERS':
            let filtersdDel = { ...state.filters };
            delete filtersdDel[action.payload];
            return {
                ...state,
                filters: filtersdDel,
            };

        default:
            return state;
    }
};

export default sensorReducer;
