//https://github.com/apache/echarts
//official site: https://echarts.apache.org/en/download.html
//https://echarts.apache.org/examples/en/#chart-type-pie
//options --- https://echarts.apache.org/en/option.html#title.textAlign
//example --- https://echarts.apache.org/examples/en/editor.html?c=pie-borderRadius

import React, {useState, useEffect} from 'react';
import * as echarts from 'echarts';
import ss from './EChart.module.scss';

const EChart = ({option, width, height}) => {
    const [myChart, setMyChart] = useState(undefined);
    
    useEffect(() => {
        const chartDom = document.getElementById('idMainBox');
        const chart = echarts.init(chartDom);
		    setMyChart(chart);
	  }, []);

    useEffect(() => {
        if (myChart) 
          myChart.setOption(option);
	}, [myChart, option]);
    
    return (
        <div className={ss.root__wrap}>
            <div className={ss.root} id='idMainBox' style={{width: width + 'em', height: height + 'em'}} />
        </div>
    )
};

export default EChart;
