export const showBoxOnHoverChart = (chart, evt, key, val, color, arr, setBoxAnnotation, multy) => {
    if (!chart) return;
    // console.log(chart);
    const markers = chart.config.options.plugins.annotation.annotations;
    let min = arr[0].x;
    let max = arr[arr.length - 1].x;

    if (multy) {
        min = arr[0].data[0].x;
        max = arr[0].data[arr[0].data.length - 1].x;
    }

    markers['hovered'] = {
        type: 'box',
        xScaleID: 'xAxis',
        yScaleID: 'yAxis',
        adjustScaleRange: false,
        backgroundColor: color,
        borderWidth: 0,
        xMax: val + 1000 > max ? max : val + 1000,
        xMin: val - 1000 < min ? min : val - 1000,
        click: (context) => {
            const {
                element: {
                    options: { xMin, xMax },
                },
            } = context;
            if (!markers[key]) {
                delete markers['hovered'];
                setBoxAnnotation(key, xMin, xMax, color);
            }
        },
    };

    if (val < min || val > max) delete markers['hovered'];

    chart.update();
};
