import './Card.scss';
import { ReactComponent as AddCardButton } from '../../../assets/icons/add-card-button.svg';
import { ReactComponent as AddUserButton } from '../../../assets/icons/user.svg';
import { ReactComponent as Rename } from '../../../assets/icons/card-menu/03_rename.svg';
// import { ReactComponent as DropDownMenu } from '../../../assets/icons/dropdown-menu.svg';
import { ReactComponent as DropDownMenu } from '../../../assets/icons/card-menu/dd.svg';
import Sticker from '../Sticker/Sticker';
import Stickers from '../Sticker/Stickers';
import { useCallback, useEffect, useRef, useState } from 'react';
import AddButton from '../AddButton/AddButton';
import AddInput from '../AddInput/AddInput';
import OptionsButton from '../OptionsButton/OptionsButton';
import Menu from '../Menu/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { storageClass, storageOptions } from '../../../redux/slices/storage';
import {
  archiveAllTasks,
  deleteCard,
  updateCard,
} from '../../../redux/slices/trello';
import { PRIORITY_DATA, STICKERS_DATA } from '../Sticker/StickersData';
import { getCardMenu } from './CardMenuData';
import { selectusers } from '../../../redux/slices/classsline';
import AddCardUsers from '../AddCardUsers/AddCardUsers';
import { LIST_COLORS } from '../List/ListColors';
import CardUsersList from '../CardUsersList/CardUsersList';
import UploadCoverInput from '../UploadCoverInput/UploadCoverInput';

const Card = ({ card, setColumns, column, isSubtask, openModal, u }) => {
  const [isOpenStickers, setIsOpenStickers] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isVisibleSubtasks, setIsVisibleSubtasks] = useState(false);
  const [isVisibleRenameIcon, setIsVisibleRenameIcon] = useState(false);
  const [isOpenAddUsers, setIsOpenAddUsers] = useState(false);
  const [subtasks, setSubtasks] = useState(card.subtasks);
  const [newSubtaskData, setNewSubtaskData] = useState('');
  const [isVisibleTextarea, setIsVisibleTextarea] = useState(false);
  const [name, setName] = useState(card.name);
  const [isVisibleChangeName, setIsVisibleChangeName] = useState(false);

  const dispatch = useDispatch();

  const { fontSize } = useSelector(storageOptions);
  const [usersList] = useSelector(selectusers);
  const USERS_DATA = usersList;
  const cardUsers = USERS_DATA.filter((user) => card.users.includes(user._id));
  const slicedUsers = cardUsers.slice(0, 2);

  const CARD_MENU_DATA = getCardMenu();

  const stickersRef = useRef(null);
  const menuRef = useRef(null);
  const addUsersMenuRef = useRef(null);
  const btnMenuRef = useRef(null);
  const btnAddUsersRef = useRef(null);
  const menuInputRef = useRef(null);
  
  const btnPosition = btnMenuRef?.current?.getBoundingClientRect();

  const listCurrentColor = LIST_COLORS.find(
    (color) => color.listBackgroundColor === column?.color
  );

  let priorityData = [];

  const updatedPriorityData = [
    ...priorityData,
    PRIORITY_DATA.find((data) => data.priority === card.priority),
  ];

  const toggleStickers = () => {
    setIsOpenStickers(!isOpenStickers);
  };

  const handleOpenAddUsers = (e) => {
    setIsOpenAddUsers(!isOpenAddUsers);
  };

  const handleShowTextarea = (e) => {
    setIsVisibleTextarea(!isVisibleTextarea);

    if (isVisibleTextarea) {
      setNewSubtaskData('');
    }
  };

  const handleOpenMenu = () => {
    setIsOpenMenu((prevState) => !prevState);
  };

  const renameCard = useCallback(() => {
    if (name) {
      dispatch(updateCard(card._id, 'name', name));
      setIsVisibleChangeName(false);
      setIsVisibleRenameIcon(false);
      setName('');
    }
  }, [card._id, dispatch, name]);

  const handleAddNewSubtask = () => {
    setIsVisibleTextarea(true);

    if (newSubtaskData.trim() === '') return;
    const newSubtask = {
      _id: `subtask${subtasks.length + 1}`,
      stickers: [],
      users: [],
      title: newSubtaskData,
      subtasks: [],
    };
    if (newSubtaskData) {
      setSubtasks((prevSubtasks) => [...prevSubtasks, newSubtask]);
    }

    setNewSubtaskData('');
    setIsVisibleTextarea(false);
  };

  const handleClickOutsideMenu = (event) => {
    if (
      menuRef.current &&
      !btnMenuRef.current.contains(event.target) &&
      !menuRef.current.contains(event.target)
    ) {
      setIsOpenMenu(false);
    }
  };
  const handleClickOutsideAddUsers = (event) => {
    if (
      addUsersMenuRef.current &&
      !btnAddUsersRef.current.contains(event.target) &&
      !addUsersMenuRef.current.contains(event.target)
    ) {
      setIsOpenAddUsers(false);
    }
  };

  const handleClickOutside = (event) => {
    if (
      stickersRef.current &&
      !stickersRef.current.contains(event.target) &&
      !event.target.classList.contains('sticker__title')
    ) {
      setIsOpenStickers(false);
    }
  };

  const onClickCard = (event) => {
    if (
      !event.target.classList.contains('options__button_svg') &&
      !event.target.classList.contains('add__button') &&
      !event.target.classList.contains('card_users__item') &&
      !event.target.classList.contains('add_users_button__svg') &&
      !(event.target instanceof SVGPathElement)
    ) {
      openModal();
    }
  };

  const handleMarkDown = () => {
    if (Math.abs(card?.status) === card?.status && card?.status > 0) {
      if (card?.status === 2) {
        dispatch(updateCard(card._id, 'status', 1));
      } else {
        dispatch(updateCard(card._id, 'status', 2));
      }
    } else {
      if (card?.status === -2) {
        dispatch(updateCard(card._id, 'status', -1));
      } else {
        dispatch(updateCard(card._id, 'status', -2));
      }
    }
  };

  useEffect(() => {
    const handleClickOutsideRenameList = (event) => {
      if (
        menuInputRef.current &&
        !menuInputRef.current.contains(event.target)
      ) {
        if (isVisibleChangeName) {
          renameCard();
          setIsVisibleChangeName(false);
        }
        setIsOpenMenu(false);
      }
    };

    document.addEventListener('click', handleClickOutsideRenameList);
    return () => {
      document.removeEventListener('click', handleClickOutsideRenameList);
    };
  }, [isVisibleChangeName, renameCard]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    document.addEventListener('click', handleClickOutsideMenu);
    document.addEventListener('click', handleClickOutsideAddUsers);
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('click', handleClickOutsideMenu);
      document.removeEventListener('click', handleClickOutsideAddUsers);
    };
  }, [menuRef, btnMenuRef, addUsersMenuRef, btnAddUsersRef]);

  CARD_MENU_DATA.delete.function = () => {
    dispatch(deleteCard(card._id, card.status));
  };

  CARD_MENU_DATA.rename.function = () => {
    setIsVisibleChangeName(true);
    setIsOpenMenu(false);
    setName(card.name);
  };

  if (Math.abs(card.status) === 2) {
    CARD_MENU_DATA.markDone.title = 'Отметить невыполненной';
  }
  if (card.status < 0) {
    CARD_MENU_DATA.archive.title = 'Убрать из архива';
  }
  if (card.status === 0) {
    CARD_MENU_DATA.archive.isDisabled = true;
  }

  CARD_MENU_DATA.markDone.function = () => {
    handleMarkDown();
    setIsOpenMenu(false);
  };

  CARD_MENU_DATA.archive.function = () => {
    if (card.status > 0) {
      dispatch(archiveAllTasks(card._id));
    } else {
      dispatch(updateCard(card._id, 'status', card?.status * -1));
    }
    setIsOpenMenu(false);
  };

  return (
    <article className="card__wrapper">
     {/* <UploadCoverInput activeTaskId={card?._id} /> */}
      <div
        className={
          isSubtask
            ? ' card__container card__border_subtask'
            : 'card__container'
        }
        onClick={onClickCard}
      >
        <div className="card__info">
          {card?.attachments?.length !== 0 && (
            <img className="card__image" src={card?.attachments[0]} alt="" />
          )}
          <div className="card__head">
            <div
              className="card__title"
              onMouseEnter={() => {
                if (!isVisibleChangeName) {
                  setIsVisibleRenameIcon(true);
                }
              }}
              onMouseLeave={() => setIsVisibleRenameIcon(false)}
            >
              <label
                className="card__label__container"
                onClick={(e) => e.stopPropagation()}
              >
                <input
                  type="checkbox"
                  name="title"
                  id={card?._id}
                  className="card__checkbox"
                  checked={
                    card?.status === 2 || card?.status === -2 ? true : false
                  }
                  onChange={handleMarkDown}
                />
                {isVisibleChangeName ? (
                  <input
                    ref={menuInputRef}
                    className="card__title__input"
                    type="text"
                    autoFocus
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        renameCard(card._id, name);
                      }
                    }}
                  />
                ) : (
                  <span className="card__label__title">{card?.name}</span>
                )}
              </label>

              {isVisibleRenameIcon && (
                <Rename
                  onClick={(e) => {
                    e.stopPropagation();
                    setName(card?.name);
                    setIsVisibleChangeName(true);
                    setIsVisibleRenameIcon(false);
                  }}
                  className="card__label__rename_icon"
                />
              )}
            </div>
            <div className={`card__options id-${card?._id}`}>
              <OptionsButton
                handleOpenMenu={handleOpenMenu}
                isOpenMenu={isOpenMenu}
                item={card}
                btnMenuRef={btnMenuRef}
              />
            </div>
          </div>
          <div className="card__badges">
            <div
              className="card__stickers"
              ref={stickersRef}
              onClick={(e) => e.stopPropagation()}
            >
              {
                <button
                  className={
                    isOpenStickers
                      ? 'card__badge_button active'
                      : 'card__badge_button'
                  }
                  onClick={toggleStickers}
                >
                  <AddCardButton className="add_button__svg" />
                  <span className="card__badge__title">Стикер</span>
                </button>
              }
              {updatedPriorityData?.map((sticker, index) => (
                <Sticker key={index} sticker={sticker} />
              ))}
            </div>
            <div
              ref={btnAddUsersRef}
              className={`add__button id-${card?._id}`}
              id={card?._id}
              onClick={handleOpenAddUsers}
            >
              {cardUsers?.length === 0 ? (
                <AddUserButton className="add_users_button__svg" />
              ) : (
                <CardUsersList
                  btnAddUsersRef={btnAddUsersRef}
                  card={card}
                  handleClick={handleOpenAddUsers}
                  cardUsers={cardUsers}
                  listCurrentColor={listCurrentColor}
                  LIST_COLORS={LIST_COLORS}
                  slicedUsers={slicedUsers}
                  cardUsersLength={3}
                />
              )}
            </div>
          </div>
        </div>

        {card?.subtasks && (
          <div onClick={(e) => e.stopPropagation()}>
            <div
              className={
                isVisibleSubtasks
                  ? 'subtasks__button subtasks_branch__button'
                  : 'subtasks__button__close subtasks__button'
              }
              onClick={() => setIsVisibleSubtasks(!isVisibleSubtasks)}
            >
              <div className="progress">
                <div
                  style={{
                    width: '50%',
                    height: '100%',
                    backgroundColor: '#00B5FF',
                    borderRadius: '6px',
                  }}
                ></div>
              </div>
              <span className="progress__title">0/{subtasks?.length}</span>
              <DropDownMenu />
            </div>
            <div
              className={
                isVisibleSubtasks
                  ? 'subtasks__content_open'
                  : 'subtasks__content_closed'
              }
            >
              <>
                <div
                  className={
                    subtasks?.length !== 0
                      ? 'subtasks_branch__add_visible'
                      : 'subtasks_branch__add_hideable'
                  }
                >
                  <div className={isVisibleTextarea ? 'add__form_visible' : ''}>
                    <AddButton
                      title="Создать подзадачу"
                      className="card__add_button"
                      textClassName="list__add_button_title"
                      isVisible={isVisibleTextarea}
                      handleAdd={handleAddNewSubtask}
                      handleShow={handleShowTextarea}
                    />

                    {isVisibleTextarea && (
                      <AddInput
                        newData={newSubtaskData}
                        setNewData={setNewSubtaskData}
                        handleAdd={handleAddNewSubtask}
                        placeholder="Введите название подзадачи"
                        className="add_input__subtask"
                      />
                    )}
                  </div>
                </div>
                <div>
                  {subtasks?.map((task) => (
                    <div className="subtasks_branch__card">
                      <div style={{ paddingLeft: '10px' }}>
                        <Card
                          card={task}
                          isSubtask
                          setColumns={setColumns}
                          handleAdd={handleAddNewSubtask}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </>
            </div>
          </div>
        )}
      </div>
      {isOpenMenu && (
        <Menu
          id={card?._id}
          activeCard={card}
          items={{ ...CARD_MENU_DATA }}
          menuRef={menuRef}
          isOpenMenu={isOpenMenu}
          setIsOpenMenu={setIsOpenMenu}
          style={{
            right: '-8.75em',
            top: (btnPosition.top / fontSize) * 16 - (100 / fontSize) * 16, // чем больше шрифт тем меньше должен быть этот коэффициент 16 и наоборот
          }}
        />
      )}
      {isOpenAddUsers && (
        <AddCardUsers
          users={USERS_DATA}
          card={card}
          onClose={() => setIsOpenAddUsers(false)}
          addUsersMenuRef={addUsersMenuRef}
        />
      )}

      {isOpenStickers && <Stickers card={card} stickersData={STICKERS_DATA} />}
    </article>
  );
};

export default Card;
