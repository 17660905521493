import { createSlice } from '@reduxjs/toolkit';
import { trackerApi } from '../../api/api';
import socket from '../../socket';

let initialState = {
  isLoading: false,
  error: null,
  columns: [],
  cards: [],
  archiveCards: [],
  filteredItems: [],
  activeTask: {},
  activeGroup: {},
};

const trelloSlice = createSlice({
  name: 'trello',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    setColumns: (state, action) => {
      state.columns = action.payload;
    },
    setCards: (state, action) => {
      state.cards = action.payload;
    },
    setArchiveCards: (state, action) => {
      state.archiveCards = action.payload;
    },
    setFilteredItems: (state, action) => {
      state.filteredItems = action.payload;
    },
    setActiveTask: (state, action) => {
      state.activeTask = action.payload;
    },
    setActiveGroup: (state, action) => {
      state.activeGroup = action.payload;
    },
    addColumn: (state, action) => {
      state.columns.push(action.payload);
    },
    addCard: (state, action) => {
      state.cards.push(action.payload);
    },
    removeColumn: (state, action) => {
      state.columns = state.columns.filter(
        (column) => column._id !== action.payload
      );
    },
    removeCard: (state, action) => {
      state.cards = state.cards.filter((card) => card._id !== action.payload);
    },
    removeArchiveCard: (state, action) => {
      state.archiveCards = state.archiveCards.filter(
        (card) => card._id !== action.payload
      );
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const reorderColumns = (columns) => {
  return async (dispatch) => {
    dispatch(trelloSlice.actions.startLoading());
    try {
      // const response = await trackerApi.getGroupsByRoom(room);

      dispatch(trelloSlice.actions.setColumns(columns));
    } catch (error) {
      dispatch(trelloSlice.actions.hasError(error));
    }
  };
};
export const reorderCards = (cards) => {
  return async (dispatch) => {
    dispatch(trelloSlice.actions.startLoading());
    try {
      // const response = await trackerApi.getGroupsByRoom(room);

      dispatch(trelloSlice.actions.setCards(cards));
    } catch (error) {
      dispatch(trelloSlice.actions.hasError(error));
    }
  };
};
export const filterItems = (items) => {
  return async (dispatch) => {
    dispatch(trelloSlice.actions.startLoading());
    try {
      dispatch(trelloSlice.actions.setFilteredItems(items));
    } catch (error) {
      dispatch(trelloSlice.actions.hasError(error));
    }
  };
};

export const getColumns = (room) => {
  return async (dispatch) => {
    dispatch(trelloSlice.actions.startLoading());
    try {
      const response = await trackerApi.getGroupsByRoom(room);
      response.sort((a, b) => a.order - b.order);

      dispatch(trelloSlice.actions.setColumns(response));
    } catch (error) {
      dispatch(trelloSlice.actions.hasError(error));
    }
  };
};

export const getActiveTask = (taskId) => {
  return async (dispatch) => {
    dispatch(trelloSlice.actions.startLoading());
    try {
      const response = await trackerApi.getTaskById(taskId);

      dispatch(trelloSlice.actions.setActiveTask(response));
    } catch (error) {
      dispatch(trelloSlice.actions.hasError(error));
    }
  };
};
export const updateActiveGroup = (group) => {
  return async (dispatch) => {
    dispatch(trelloSlice.actions.startLoading());
    try {
      dispatch(trelloSlice.actions.setActiveGroup(group));
    } catch (error) {
      dispatch(trelloSlice.actions.hasError(error));
    }
  };
};

export const getCards = (room, status = 0) => {
  return async (dispatch) => {
    dispatch(trelloSlice.actions.startLoading());
    try {
      const response = await trackerApi.getTasksByRoom(room, status); // -1 , 0 , 1
      response.sort((a, b) => a.order - b.order);

      dispatch(trelloSlice.actions.setCards(response));
    } catch (error) {
      dispatch(trelloSlice.actions.hasError(error));
    }
  };
};

export const getArchiveCards = (room, status = -1) => {
  return async (dispatch) => {
    dispatch(trelloSlice.actions.startLoading());
    try {
      const response = await trackerApi.getTasksByRoom(room, status);
      response.sort((a, b) => a.order - b.order);

      dispatch(trelloSlice.actions.setArchiveCards(response));
    } catch (error) {
      dispatch(trelloSlice.actions.hasError(error));
    }
  };
};

export const addNewColumn = (columnData) => {
  return async (dispatch, getState) => {
    dispatch(trelloSlice.actions.startLoading());
    try {
      const documentClassId = getState().classs.class[0]._id;
      const response = await trackerApi.addGroup(columnData);
      socket.emit('BE-refresh-db', {
        type: 'trackerGroup',
        roomId: documentClassId,
      });
      dispatch(trelloSlice.actions.addColumn(response));
    } catch (error) {
      dispatch(trelloSlice.actions.hasError(error));
    }
  };
};

export const addNewCard = (cardData) => {
  return async (dispatch, getState) => {
    dispatch(trelloSlice.actions.startLoading());
    try {
      const response = await trackerApi.addTask(cardData);
      const documentClassId = getState().classs.class[0]._id;
      socket.emit('BE-refresh-db', {
        type: 'trackerTask',
        roomId: documentClassId,
      });
      dispatch(trelloSlice.actions.addCard(response));
    } catch (error) {
      dispatch(trelloSlice.actions.hasError(error));
    }
  };
};

export const updateColumns = (updatedColumnId, newData) => {
  return async (dispatch, getState) => {
    dispatch(trelloSlice.actions.startLoading());
    try {
      const existingColumns = getState().trelloSlice.columns;
      const updatedColumns = existingColumns.map((column) =>
        column._id === updatedColumnId ? { ...column, ...newData } : column
      );

      dispatch(trelloSlice.actions.setColumns(updatedColumns));
    } catch (error) {
      dispatch(trelloSlice.actions.hasError(error));
    }
  };
};

export const updateColumn = (columnId, field, value) => {
  return async (dispatch, getState) => {
    dispatch(trelloSlice.actions.startLoading());
    try {
      const existingColumn = getState().trelloSlice.columns.find(
        (column) => column._id === columnId
      );

      const updatedColumn = {
        ...existingColumn,
        [field]: value,
      };
      const documentClassId = getState().classs.class[0]._id;
      await trackerApi.updateGroup(columnId, updatedColumn);
      socket.emit('BE-refresh-db', {
        type: 'trackerGroup',
        roomId: documentClassId,
      });

      const existingColumns = getState().trelloSlice.columns;
      const updatedColumns = existingColumns.map((column) =>
        column._id === columnId ? updatedColumn : column
      );

      const response = await trackerApi.getGroupsByRoom(documentClassId);

      dispatch(trelloSlice.actions.setColumns(response));
      dispatch(getColumns(documentClassId));
      dispatch(trelloSlice.actions.setColumns(updatedColumns));
    } catch (error) {
      dispatch(trelloSlice.actions.hasError(error));
    }
  };
};

export const updateCard = (cardId, field, value, condition) => {
  return async (dispatch, getState) => {
    dispatch(trelloSlice.actions.startLoading());
    try {
      const existingCard = getState().trelloSlice.cards.find(
        (card) => card._id === cardId
      );

      const updatedCard = {
        ...existingCard,
        [field]: value,
      };

      await trackerApi.updateTask(cardId, updatedCard);
      const documentClassId = getState().classs.class[0]._id;
      socket.emit('BE-refresh-db', {
        type: 'trackerTask',
        roomId: documentClassId,
      });

      dispatch(getCards(documentClassId));

      if (condition === 'activeTask') {
        dispatch(getActiveTask(cardId));
      }
      
    } catch (error) {
      dispatch(trelloSlice.actions.hasError(error));
    }
  };
};

export const archiveAllTasks = (ids) => {
  return async (dispatch, getState) => {
    dispatch(trelloSlice.actions.startLoading());
    try {
      await trackerApi.archiveTasks(ids);
      const documentClassId = getState().classs.class[0]._id;
      socket.emit('BE-refresh-db', {
        type: 'trackerGroup',
        roomId: documentClassId,
      });

      dispatch(getCards(documentClassId));
      // dispatch(getArchiveCards(documentClassId));
    } catch (error) {
      dispatch(trelloSlice.actions.hasError(error));
    }
  };
};

export const sortColumnCards = (columnId, field, direction) => {
  return async (dispatch, getState) => {
    dispatch(trelloSlice.actions.startLoading());
    try {
      const response = await trackerApi.sortGroup(columnId, field, direction);
      const documentClassId = getState().classs.class[0]._id;
      socket.emit('BE-refresh-db', {
        type: 'trackerGroup',
        roomId: documentClassId,
      });

      dispatch(getColumns(documentClassId));
    } catch (error) {
      dispatch(trelloSlice.actions.hasError(error));
    }
  };
};

export const deleteColumn = (columnId) => {
  return async (dispatch, getState) => {
    dispatch(trelloSlice.actions.startLoading());
    try {
      const response = await trackerApi.deleteGroup(columnId);
      const documentClassId = getState().classs.class[0]._id;
      socket.emit('BE-refresh-db', {
        type: 'trackerGroup',
        roomId: documentClassId,
      });
      dispatch(trelloSlice.actions.removeColumn(columnId));
    } catch (error) {
      dispatch(trelloSlice.actions.hasError(error));
    }
  };
};

export const deleteCard = (cardId, cardStatus) => {
  return async (dispatch, getState) => {
    dispatch(trelloSlice.actions.startLoading());
    try {
      trackerApi.deleteTask(cardId);
      // if (cardStatus > 0) {
        dispatch(trelloSlice.actions.removeCard(cardId));
      // } else {
        // dispatch(trelloSlice.actions.removeArchiveCard(cardId));
      // }
      const documentClassId = getState().classs.class[0]._id;
      socket.emit('BE-refresh-db', {
        type: 'trackerTask',
        roomId: documentClassId,
      });
    } catch (error) {
      dispatch(trelloSlice.actions.hasError(error));
    }
  };
};

export const { setColumns, removeColumn } = trelloSlice.actions;
export default trelloSlice.reducer;
