import React, {useEffect, useState, useRef} from 'react';
import {chartColors} from './ChartsColors';
import ChartOptions from "./ChartOptions";
import Icon from '../../rlab/src/components/Icon';
import { wheelZoomPlugin, panPlugin } from './plugins';
import * as XLSX from 'xlsx';
import {useDropzone} from 'react-dropzone'
import UplotReact from 'uplot-react';
import 'uplot/dist/uPlot.min.css';
import ss from './charts.module.scss'
import {Switcher} from "rlabui";
import {useSelector} from "react-redux";
import {storageOptions} from "../../redux/slices/storage";
import {defaultStyles, getAxes, getSeries, paths} from "./ChartHelper";

const fnOrSelf = (v) => typeof v == "function" ? v : () => v;

const Chart = (props) => {
    const options = useSelector(storageOptions);
    const {index, workPlot, setWorkPlot, editData, setEditData, isEdit} = props;
    const [ranges, setRanges] = useState({x: {min: 0, max: 1}, y: {min: 0, max: 1}});
    const [xHandle, setXHandle] = useState(true);
    const [yHandle, setYHandle] = useState(true);
    const [showGrid, setShowGrid] = useState(true);
    const [showOptions, setShowOptions] = useState(false);

    const wrapRef = useRef(null);
    const plotRef = useRef([]);

    const {getRootProps, getInputProps} = useDropzone({
        noClick: true, noKeyboard: true, disabled: !isEdit,
        accept: {'application/vnd.ms-excel' : ['.xlsx', '.csv']},
        onDrop: files => parseFile(files[0]),
    });


    const [opts, setOpts] = useState({
        title: false,
        padding: [5, 10, 10, 10],
        width: 0,
        height: 0,
        legend: {
            show: false,
        },
        cursor: {
            show: true,
            drag: {
                y: false,
                x: false,
            },
            x: isEdit,
            y: isEdit,
        },
        plugins: isEdit?[wheelZoomPlugin({factor: 0.9}), panPlugin()]:[],
        scales: {
            x: {
                time: false,
            },
            y: {
                time: false,
            },
        },
    });

    useEffect(() => {
        const current = wrapRef?.current;
        if (!current) return;

        const observer = new ResizeObserver(updateChartSize);
        observer.observe(current);
        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        if (plotRef.current[index]?.series.length === workPlot.styles.length) {
            plotRef.current[index].series.forEach((s,i)=> {
              if (i===0) return;
              plotRef.current[index].series[i] = {
                  ...s,
                  stroke: fnOrSelf(workPlot.styles[i].color),
                  width: workPlot.styles[i].weight,
                  paths: paths(workPlot.styles[i].interpolation, workPlot.styles[i].point.width, workPlot.styles[i].point.color),
                  points: {
                      ...plotRef.current[index].series[i].points,
                      show: fnOrSelf(workPlot.styles[i].interpolation === 'bars' ? false : workPlot.styles[i].point.width),
                      size: workPlot.styles[i].point.width ?? 0,
                      fill: fnOrSelf(workPlot.styles[i].point.color),
                      stroke: fnOrSelf(workPlot.styles[i].point.color),
                  },
              }});
            plotRef.current[index].redraw(false, false);
        }
        // eslint-disable-next-line
    }, [workPlot.styles]);

    useEffect(() => {
        if (plotRef.current[index]) {
            plotRef.current[index].axes.forEach((a, i) => a.grid.show = showGrid && i < 2);
            plotRef.current[index].redraw(false, true);
        }
    }, [index, showGrid]);

    useEffect(() => {
        if (plotRef.current[index]) {
            plotRef.current[index].axes.forEach(a =>
              a.grid.stroke = fnOrSelf(options.theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light)
            );
            plotRef.current[index].redraw(false, false);
        }
    }, [index, options.theme]);

    useEffect(() => {
        if (plotRef.current[index]) {
            plotRef.current[index].series.forEach((a, i) => {
                i === 0 ?
                  plotRef.current[index].setScale(a.scale, {min: ranges.x.min, max: ranges.x.max})
                  : plotRef.current[index].setScale(a.scale, {min: ranges.y.min, max: ranges.y.max});
            })
        }
    }, [index, ranges]);

    useEffect(() => {
        if (plotRef.current[index])
            plotRef.current[index].zoomPan.xHandle = xHandle;
    }, [index, xHandle]);

    useEffect(() => {
        if (plotRef.current[index])
            plotRef.current[index].zoomPan.yHandle = yHandle;
    }, [index, yHandle]);

    useEffect(() => {
        setRanges(getRanges(workPlot.data));
    }, [workPlot.data]);

    useEffect(() => {
        setOpts(opt => (
          {
            ...opt,
            series: getSeries(workPlot, index, options.theme),
            axes: getAxes(workPlot, index, options.theme)
        }));
        // eslint-disable-next-line
    }, [index,workPlot.data.length]);

    const updateChartSize = () => {
        const width = wrapRef.current?.getBoundingClientRect()?.width;
        const height = wrapRef.current?.getBoundingClientRect()?.height;

        if (width && height) {
            setOpts(
                (opt) =>
                    ({
                        ...opt,
                        width,
                        height,
                    })
            );
        }
    };

    const getRanges = (data) => {
        let [xMin, xMax, yMin, yMax] = [0, 1, 0, 1];
        data?.forEach((r, i) => {
            if (r.length) {
                if (i === 0) {
                    xMin = Math.min(...r);
                    xMax = Math.max(...r);
                } else if (i === 1) {
                    yMin = Math.min(...r);
                    yMax = Math.max(...r);
                } else {
                    yMin = Math.min(...r, yMin);
                    yMax = Math.max(...r, yMax);
                }
            }
        })

        if (xMin === xMax) xMax += 1;
        if (yMin === yMax) yMax += 1;
        return {x: {min: xMin, max: xMax}, y: {min: yMin, max: yMax}};
    }

    const parseFile= (file)=>{
        const reader = new FileReader();
        reader.onload = async (e) => {
            const readedData = XLSX.read(e.target.result, {type: 'binary'});
            const dataParse = XLSX.utils.sheet_to_json(readedData.Sheets[readedData.SheetNames[0]],
              {header: 1});
            if ((dataParse?.[0]?.length ?? 0) < 2) return;

            const styles = dataParse[0].length === workPlot.styles.length ? workPlot.styles : defaultStyles(dataParse[0].length, index);
            const data = Array.from(Array(dataParse[0].length), _ => []);
            dataParse.forEach((row, i) => {
                const isHeader = (i === 0) && isNaN(+row[0]);
                row.forEach((d, j) => {
                    if (isHeader) {
                        styles[j].axe = d;
                    } else {
                        data[j].push(d);
                    }
                });
            });

            setWorkPlot({...workPlot, data,styles});
        }
        reader.readAsArrayBuffer(file);
    }

    return (
        <div className={ss.chart} {...getRootProps()}>
        <div className={ss.head}>
              <div className={ss.left}>
                  {workPlot.styles.slice(1).map((s, i) =>
                    <div className={ss.legend} key={'legend_' + i}>
                        <div className={ss.color} style={{background: s.color}}/>
                        <span>{s.axe}</span>
                    </div>
                  )}
              </div>
            {isEdit && <div className={ss.action}>
                <Icon tag="button" name="capture"
                        title="Полный масштаб" onClick={() => setRanges(getRanges(workPlot.data))}/>
                  <Icon tag="button" name="plus" className={showGrid && ss.active}
                        title="Сетка" onClick={() => setShowGrid(!showGrid)}/>
                  <Icon tag="button" name="gear" className={showOptions && ss.active}
                        title="Настройки отображения" onClick={() => setShowOptions(true)}/>
                  <label className={ss.file}>
                      <input type="file" accept=".csv,.xls,.xlsx" {...getInputProps()}/>
                  </label>
                  <Icon tag="button" name="bullet-list" className={editData && ss.active}
                        title="Данные" onClick={() => setEditData(!editData)}/>
              </div>}
          </div>
          <div className={ss.wrap}>
              <div className={ss.name} style={{color:workPlot.styles[1].color}}>{workPlot.styles[1].scale}</div>
              <div className={ss.big} ref={wrapRef}>
                  <UplotReact
                    options={opts}
                    data={workPlot.data}
                    target={wrapRef.current}
                    resetScales={true}
                    onCreate={(u) => {
                        plotRef.current[index] = u;
                        u.zoomPan = {xHandle, yHandle};
                    }}
                  />
              </div>
          </div>
            <div className={ss.foot}>
                {isEdit && <>
                  <div className={ss.axeSwitcher}>
                    <label title="Масштабирование по оси Х">
                        <span>X</span>
                        <Switcher checked={xHandle} onChange={() => setXHandle(!xHandle)}/>
                    </label>
                    <label title="Масштабирование по оси Y">
                        <span>Y</span>
                        <Switcher checked={yHandle} onChange={() => setYHandle(!yHandle)}/>
                    </label>
                  </div>
                </>}
                <div className={ss.xLabel}>{workPlot.styles[0].axe}</div>
            </div>
            {isEdit && showOptions &&
              <ChartOptions index={index} modal={showOptions} setModal={setShowOptions}
                            styles={workPlot.styles}
                            setStyles={(styles) => setWorkPlot({...workPlot, styles})}/>}
        </div>
    );
}

export default Chart;
