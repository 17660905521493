import React from "react";
import ScenarioOneQuestion from './ScenarioOneQuestion';
import { Button, Textarea } from 'rlabui';
import {isAuth} from "../../helpers/auth";
import ss from './Scenarios.module.scss';
import preview from '../../rlab/src/components/Scenario/ScenarioRunDlg.module.scss';

const ScenarioPage3Questions = ({isTemplate, scQuestions, setScQuestions}) => {
    const isDisabledTemplate = isTemplate && isAuth().role < 3;
    
    const handleItemChange = (qInd, questionItem) => {
        const items = scQuestions.map((item, ind) => ind !== qInd ? item : questionItem);
        setScQuestions(items);
    };

    const handleAddItem = () => {
        const items = [...scQuestions];
        items.push('');
        setScQuestions(items);
    };

    const handleDeleteItem = (itemIndex) => {
        const items = scQuestions.filter((item, ind) => ind !== itemIndex);
        setScQuestions(items);
    };

    return (
        <div className={ss.root}>
            <div className={"cor-net grow " + ss.steps } key='si01'>
                {scQuestions.map((item, ind) => 
                    <ScenarioOneQuestion 
                        key={'si02'+ind}
                        itemIndex={ind}
                        scQuestion={item}
                        handleItemChange={handleItemChange}
                        handleDeleteItem={handleDeleteItem}
                        isTemplate={isTemplate}
                    />
                )}

                {!isDisabledTemplate && 
                <div className="cor-net__add" key='si03'>
                    <Button
                        onClick={handleAddItem}
                        border={true} 
                        disabled={isTemplate && isAuth().role < 3}
                    >
                        Добавить вопрос
                    </Button>
                </div>
                }
            </div>
            <div className={ss.preview__sticky}>
                <div className={ss.preview__title}>Предварительный просмотр</div>
                <div className={ss.preview}>
                    <div className={preview.open}>
                        <div className={preview.toggle}>
                            <b>Контрольные вопросы</b>
                        </div>
                        <div className={preview.body}>
                            <div className={preview.content}>
                                <div className={preview.content__scrolled}>
                                    <div className={preview.questions}>
                                    {
                                    scQuestions.map((item, ind) => 
                                        <div className={preview.questions__item} key={'q1'+ind}>
                                            <div className={preview.questions__title} key={'q2'+ind}>
                                                {ind+1}. {item} 
                                            </div>
                                            <div className={preview.questions__input} key={'q3'+ind}>
                                                <span className={preview.questions__label}>Ваш ответ</span>
                                                <Textarea disabled={true} placeholder="Впишите ваш ответ в это поле" /> 
                                            </div>
                                        </div>
                                    )}
                                    </div>
                                </div>
                            </div>               
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ScenarioPage3Questions; 
