import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from './redux';
import Editor from './VideoEditor';
import VideoModal from './VideoEditorModal';
import ConfirmDlg from './ConfirmDlg';
import VideoEditorXlsxExport from './VideoEditorXlsxExport';
import VideoEditorItem from './VideoEditorItem';

const VideoEditor = connect(mapStateToProps, mapDispatchToProps)(Editor);
const VideoEditorModal = connect(mapStateToProps, mapDispatchToProps)(VideoModal);
const ConfirmationDlg = connect(mapStateToProps, mapDispatchToProps)(ConfirmDlg);

export { VideoEditor, VideoEditorModal, ConfirmationDlg, VideoEditorXlsxExport, VideoEditorItem };
