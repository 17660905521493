import React, { useEffect, useState } from 'react';
import { Switcher } from 'rlabui';
import Styles from './Sensor.module.scss';

const Sensor = ({ sensor, styles, deleteChartDisabled, addChartDisabled, values, chartDisabled, theme }) => {
    const { name, color: sensColor, key, unit, roundDigit } = sensor;
    const [disabled, setDisabled] = useState(chartDisabled?.includes(key));
    const [color, setColor] = useState(false);
    const [val, setVal] = useState();

    useEffect(() => {
        if (disabled) {
            addChartDisabled(key);
        } else {
            deleteChartDisabled(key);
        }
    }, [disabled, key, addChartDisabled, deleteChartDisabled]);

    useEffect(() => {
        setColor(styles[key]?.color ? styles[key]?.color : sensColor);
        // eslint-disable-next-line
    }, [styles, key]);

    useEffect(() => {
        if (values[key] || values[key] === 0) {
            setVal(values[key].toFixed(roundDigit));
        }
    }, [values, key, roundDigit]);

    return (
        <div
            className={disabled ? Styles.disabled : Styles.sensor}
            key={key}
        >
            <div
                className={Styles.color}
                style={{ background: color }}
            ></div>
            <div className={Styles.name}>{key.includes('imp') ? name + ' (имп.)' : name}</div>
            <div className={Styles.val}>{val ? val + ' ' + unit : ''}</div>
            {/* {!visualized && ( */}
            <div className={Styles.toggle}>
                <Switcher
                    checked={!disabled}
                    onChange={() => setDisabled(!disabled)}
                />
            </div>
            {/* )} */}
        </div>
    );
};
export default Sensor;
