import React from 'react';
import Icon from '../Icon';
import "./Tooltip.scss";


/*
position: bottom, top, left, right, custom
*/

const Tooltip = ({
    children,
    className = '',
    position = 'bottom',
    name = 'info',
}) => {
    let tooltipClass = 'cor_tooltip';
    if (className) {
        tooltipClass += " " + className;
    }
    return (
        <div className={tooltipClass}>
            <Icon name={name} onClick={e => e.stopPropagation()} />
            <div className={"cor_tooltip__content " + position}>{children}</div>
        </div>
    )
};
export default Tooltip;

