import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Icon, FilterByText} from "../";
import {printShortDate, formatBytes} from "../../../helpers/text";
import {loadFiles} from "../../../redux/slices/lists";
import {Modal, Button} from 'rlabui';
import ss from './VideoChoiceDlg.module.scss';

const VideoChoiceDlg = ({ showModal, setShowModal, handleVideo }) => {
    const dispatch = useDispatch();
    const {lists: {files, dirs}} = useSelector(state => state);
    const [videoFiles, setVideoFiles] = useState([]);
    const [search, setSearch] = useState('');
    const [prefix, setPrefix] = useState('%2F');

    const currentDir = () => decodeURIComponent(prefix);
    const upDir = () => currentDir().replace(/[^/]+\/$/, '');
    const printFile = (name) => name.split('/').pop();
    const printDir = (name) => name.split('/').slice(-2);

    const navigate = (dir, abs) => {
        const _prefix = dir === '' ? '%2F' : dir.substring(0, dir.length - 1) + '%2F';
        setPrefix(_prefix);
    }

    useEffect(() => {
        dispatch(loadFiles(prefix));
    }, [dispatch, prefix]);

    useEffect(() => {
        const getExtension = (name) => {
            return name.lastIndexOf('.') > -1 ? name.split('.').pop().toLowerCase() : '';
        };
    
        const _files = files.filter(item => getExtension(item.Key) === 'mp4');
        setVideoFiles(_files);
    }, [files]);

    const itemClick = (e, f) => {
        handleVideo(f);
        //window.open(f.Url, "_blank");
        //setShowModal(false);
    }

    if (!showModal) {
        return false;
    }

    return (

        <Modal className={ss.root} visible={showModal} size="xl">
            <Modal.Head className={ss.head} modalClose={() => setShowModal(false)} title='Выбор видео файла'>
                <div className={ss.head__search}>
                    <FilterByText 
                        value={search}
                        placeholder={'Введите название'}
                        setSearch={setSearch}
                    />
                </div>
            </Modal.Head>
            <Modal.Body>
                <div className={ss.main}>
                    <div className={ss.grid}>
                        {currentDir().length > 1 &&
                        <div className={ss.grid__item + ' back'} onClick={() => navigate(upDir(), true)} key={0}>
                            <div className={ss.grid__icon}>
                                <Icon name="file-folder"/>
                            </div>
                            <div className={ss.grid__content}>
                                <span className={ss.grid__dots}>..</span>
                            </div>
                        </div>}

                        {dirs.map((d, i) => (
                            <div className={ss.grid__item +  ' folder'} onClick={() => navigate(d.Prefix, true)} key={i}>
                                <div className={ss.grid__icon}>
                                    <Icon name="file-folder"/>
                                </div>
                                <div className={ss.grid__content}>
                                    <span className={ss.grid__caption}>{printDir(d.Prefix)}</span>
                                </div>
                            </div>
                        ))}

                        {videoFiles.filter(s => printFile(s.Key).toLowerCase().includes(search.toLowerCase())).map((f, i) => (
                            <div className={ss.grid__item + ' file'} onClick={(e) => itemClick(e, f)} key={dirs.length + i} >
                                <div className={ss.grid__icon}>
                                    <Icon name={'film'}/>
                                </div>
                                <div className={ss.grid__content}>
                                    <span className={ss.grid__caption}>{printFile(f.Key)}</span>
                                    <span className={ss.grid__info}>{printShortDate(f.LastModified)}</span>
                                    <span className={ss.grid__info}>{formatBytes(f.Size)}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer align="right">
                <Button onClick={() => setShowModal(false)}>Отменить</Button>
            </Modal.Footer>
        </Modal>
    )
};

export default VideoChoiceDlg;

