import Excel from 'exceljs';
import { saveAs } from 'file-saver';

const COLUMN_WIDTH = 80; // for graphic
const ROW_HEIGHT = 200;

const updateWorksheetBorders = worksheet => {
  if (!worksheet) return;
    // loop through all of the rows and set the outline style.
    worksheet.eachRow({ includeEmpty: false }, row => {
      // store each cell to currentCell
      const currentCell = row._cells;

      // loop through currentCell to apply border only for the non-empty cell of excel
      currentCell.forEach(singleCell => {
        // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
        const cellAddress = singleCell._address;

        // apply border
        worksheet.getCell(cellAddress).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      });
    });
  };

  const addWorksheetColumns = (worksheet, columns) => {
    //add worksheet columns. each columns contains header and its mapping key from data
    worksheet.columns = columns;

    // updated the font for first row.
    worksheet.getRow(1).font = { bold: true };

    // loop through all of the columns and set the alignment with width.
    worksheet.columns.forEach(column => {
      column.width = column.header.length + 5; //we can increase a header width by adding spaces, i.e.: 'aaa' => '   aaa   '
      column.alignment = { horizontal: 'center' };
    });
  };

  const addTableWorksheetTableData = (worksheet, tableSheetData) => {
    // loop through data and add each one to worksheet
    tableSheetData.forEach((row, ind) => {
      worksheet.addRow(row);
    });
  };

  const addGraphicWorksheetGraphicData = (workbook, worksheet, graphicsSheetData, colName) => {
  // loop through data and add each one to worksheet
    if (!graphicsSheetData) return;

    graphicsSheetData.forEach((item, ind) => {
        const title = item.title;
        const image = item.image;

        const imageId = workbook.addImage({base64: image, extension: 'png'});
        
        //1 - title:
        //3*ind + 1 - 
        worksheet.addRow({graphics: title});

        //2 - image:
        const currInd = 3*ind+3;
        worksheet.getRow(currInd).height = ROW_HEIGHT;// _png.height;
        worksheet.addImage(imageId,   `${colName}${currInd}:${colName}${currInd}`  ); 

        //3 - empty row:
        worksheet.addRow({});
    });
};

const adjustColumnWidth = worksheet => {
  worksheet.columns.forEach(column => {
      column.width = COLUMN_WIDTH;
    });
};

const addTableWorksheetGraphicData = (workbook, worksheet, tableSheet) => {
  const graphicsSheetData = tableSheet.graphicData;
  const tableDataSize = tableSheet.columns.length;

  // loop through data and add each one to worksheet
    const defaultColNum = 7;
    let defaultRowNum = 12;
    let accRowNum = 2;
    const initTableColInd = "A".charCodeAt(0);
    const zColInd = "Z".charCodeAt(0);
    let initGraphicColInd = initTableColInd + tableDataSize + 1;
    let initGraphicColChar = String.fromCharCode(initGraphicColInd);

    for (let i = 0; i < graphicsSheetData.length; i ++) {
        //const title = item.title;
        const item = graphicsSheetData[i];
        const initRow = item.initRow;
        if (initRow !== undefined) {
          accRowNum = initRow;
          initGraphicColInd = initTableColInd;
          initGraphicColChar = String.fromCharCode(initGraphicColInd);
        }
        const image = item.image;
        const rowNum = item.rowNum ? item.rowNum : defaultRowNum;
        const colNum = item.colNum ? item.colNum : defaultColNum;
        let endColumn = initGraphicColInd + colNum;
        let endGraphicColChar;
        if (endColumn <= zColInd) {
          endGraphicColChar = String.fromCharCode(endColumn);
        } else {
          endColumn -= 26;
          endGraphicColChar = "A" + String.fromCharCode(endColumn);
        }

        const imageId = workbook.addImage({base64: image, extension: 'png'});
        const initGraphicRowInd = accRowNum;
        const endGraphicRowInd = initGraphicRowInd + rowNum;
        accRowNum += (rowNum + 1); //+2 // 1: no gap between images, 2: 1 row gap between them
        worksheet.addImage(imageId, `${initGraphicColChar}${initGraphicRowInd}:${endGraphicColChar}${endGraphicRowInd}`); 
    }
};

export const saveExcel = async (workBookName, tableSheetList, graphicsSheetList) => {
    const workbook = new Excel.Workbook();

  try {
      for (let i = 0; i < tableSheetList.length; i ++) {
          const _tableSheet = tableSheetList[i];
          const tableWorksheet = workbook.addWorksheet(_tableSheet.workSheetName);
          addWorksheetColumns(tableWorksheet, _tableSheet.columns);
          addTableWorksheetTableData(tableWorksheet, _tableSheet.tableData);
          addTableWorksheetGraphicData(workbook, tableWorksheet, _tableSheet);
          updateWorksheetBorders(tableWorksheet);
      }

      for (let i = 0; i < graphicsSheetList.length; i ++) {
          const _graphicsSheet = graphicsSheetList[i];
          const graphicsWorksheet = workbook.addWorksheet(_graphicsSheet.workSheetName);
          addWorksheetColumns(graphicsWorksheet, _graphicsSheet.columns);
          addGraphicWorksheetGraphicData(workbook, graphicsWorksheet, _graphicsSheet.graphicData, 'A');
          updateWorksheetBorders(graphicsWorksheet);
          adjustColumnWidth(graphicsWorksheet);
      }

      // write the content using writeBuffer and download the processed file
      const buf = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buf], { type: "application/octet-stream" }), `${workBookName}.xlsx`);
  } catch (error) {
        console.error('<<<ERRROR>>>', error, ' Something Went Wrong', error.message);
  }
};
