import React, { useRef, useEffect, useState, memo } from 'react';
import { Chart } from 'chart.js';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import chartColors from '../../styles/charts';
Chart.register(ChartDataLabels);

const GraduateChart = (props) => {
    const {
        props: { saved, name, presaved },
        theme,
    } = props;

    const [datasets, setDatasets] = useState([]);
    const chart = useRef();
    const options = {
        maintainAspectRatio: false,
        responsive: true,
        animation: {
            duration: 0,
            easing: 'linear',
        },
        parsing: false,
        legend: {
            display: false,
        },
        interaction: {
            includeInvisible: false,
        },
        layout: {
            padding: 0,
        },
        elements: {
            line: {
                // скругление линии
                tension: 0,
                borderColor: 'red',
                borderWidth: 2,
                spanGaps: true,
            },
            point: {
                radius: 3,
                backgroundColor: theme === 'dark' ? '#fff' : 'rgba(0,0,0,0.5)',
            },
        },
        title: {
            display: false,
        },
        plugins: {
            datalabels: {
                color: theme === 'dark' ? '#fff' : 'rgba(0,0,0,0.8)',
                backgroundColor: 'transparent',
                font: {
                    size: 10,
                },
                clamp: false,
                align: function (ctx) {
                    let idx = ctx.dataIndex;
                    let { x, y } = ctx.dataset.data[idx];
                    let { min, max } = ctx.chart.scales.xAxis;
                    let { min: yMin, max: yMax } = ctx.chart.scales.yAxis;
                    let pos = 'start';

                    if (y <= yMin - 0.1) {
                        pos = 'end';
                    }
                    if (min === x) {
                        pos = 'right';
                    }
                    if (max === x) {
                        pos = 'left';
                    }
                    return pos;
                },
                padding: 0,
                formatter: (value) => value.y.toFixed(2),
            },
        },
        scales: {
            xAxis: {
                type: 'linear',
                min: null,
                max: null,
                ticks: {
                    display: true,
                    count: 6,
                    color: chartColors.tick,
                    font: {
                        size: 10,
                    },
                    major: {
                        enabled: true,
                    },
                },
                grid: {
                    display: true,
                    color: theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light,
                    borderColor: theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light,
                    tickColor: 'transparent',
                    tickLength: 2,
                },
                title: {
                    display: false,
                },
            },
            yAxis: {
                type: 'linear',
                ticks: {
                    count: 5,
                    color: chartColors.tick,
                    font: {
                        size: 10,
                    },
                    enabled: true,
                    callback: (value) => {
                        return +value.toFixed(2);
                    },
                },
                grid: {
                    borderColor: theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light,
                    color: theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light,
                    tickColor: 'transparent',
                    tickLength: 0,
                },
                title: {
                    display: true,
                    text: `${name}`,
                    color: chartColors.tick,
                    fontWeight: 'bold',
                    font: {
                        size: 12,
                    },
                    padding: {
                        bottom: 6,
                    },
                },
            },
        },
    };

    useEffect(() => {
        calcData();
    }, [saved, presaved]);

    const calcData = () => {
        const copy = [saved, presaved];
        const colors = ['red', 'yellow'];
        const datasets = [];
        if (saved?.length) {
            let dataArr = [
                {
                    data: [],
                    yAxisID: 'yAxisPoint',
                    borderColor: 'transparent',
                },
                {
                    data: [],
                    yAxisID: 'yAxisLine',
                    pointRadius: 0,
                    datalabels: {
                        display: false,
                    },
                },
            ];

            copy.forEach((e, c) => {
                let data = [];
                let ind = 0;
                let xMin, xMax;

                if (e.length) {
                    e?.forEach((elem, i) => {
                        if (!xMin) xMin = +elem;
                        if (!xMax) xMax = +elem;
                        if (!data[ind]) {
                            data[ind] = {};
                        }
                        if (i % 2) {
                            data[ind] = { ...data[ind], x: +elem };
                            const min = data.filter((e) => e.x < +elem);
                            const max = data.filter((e) => e.x > +elem);
                            if (min[0]) {
                                xMin = min[0].x;
                            }
                            if (max[0]) {
                                xMax = max[0].x;
                            }
                            ind++;
                        } else {
                            data[ind] = { ...data[ind], y: +elem };
                        }
                    });
                }
                datasets.push({ data: data, borderColor: colors[c] });
            });

            setDatasets(datasets);
        } else {
            setDatasets(datasets);
        }
    };

    return saved?.length > 0 ? (
        <div className="graduationChart">
            <div className="graduation__title">График калибровки</div>
            <div className="graduationChart__wrap">
                <Line
                    ref={chart}
                    options={options}
                    data={{ datasets }}
                />
            </div>
        </div>
    ) : (
        ''
    );
};
export default memo(GraduateChart);
