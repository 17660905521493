import React from 'react';
import "../Content.scss";

const Content = ({
    children, 
    className = '',
    background = true,
}) => {
    let contentClass = 'cor_content';
    if (!background) {
        contentClass += ' hide_bg'
    }
    if (className) {
        contentClass += " " + className;
    }
    return (
        <div className={contentClass}>
            {children}
        </div>
    )
};
export default Content;
