import React, { useEffect, useRef, useState } from 'react';
import { LinearGauge } from 'react-canvas-gauges';
import ss from '../AnalogDevice.module.scss';

let ticksLinearHorizontalCount = 24;

const LinearHorizontal = ({ props: { theme, width, height, value, max, min, avg, roundDigit, color, unit, ticks: tCount } }) => {
    const [ticks, setTicks] = useState([]);
    const [minor, setMinor] = useState(0);
    // const [valSize] = useState(height * 0.0045 > 1.5 ? 1.5 + 'em' : height * 0.0045 + 'em');
    const borderSize = height * 0.0015 > 0.8 ? 0.8 + 'em' : height * 0.0015 + 'em';

    const wrapRef = useRef();

    useEffect(() => {
        if (typeof min === 'number' && typeof max === 'number') {
            const ticksArr = [];
            const count = Math.round(ticksLinearHorizontalCount / max.toFixed(roundDigit).length);
            if (!tCount) {
                // console.log((min / max) * 100);
                const step = (max - min) / count;
                // console.log(min, max, step);
                for (let i = 0; i <= count; i++) {
                    ticksArr.push(+(min + i * step).toFixed(roundDigit));
                }
            } else {
                const step = (max - min) / tCount;
                // console.log(min, max, step);
                for (let i = 0; i <= tCount; i++) {
                    ticksArr.push(+(min + i * step).toFixed(roundDigit));
                }
            }
            setTicks(ticksArr);
            setMinor(count);
        }
    }, [min, max, tCount, roundDigit]);

    return (
        <div
            className={ss.linearH}
            style={{ borderWidth: borderSize, borderColor: color, height: height * 0.9, width: width * 0.85 }}
            ref={wrapRef}
        >
            <div
                className={ss.linearH__val}
                style={{ fontSize: height * 0.0045 + 'em' }}
            >
                {(value || value === 0 ? value : min)?.toFixed(roundDigit)} {unit}
            </div>

            <div className={ss.linearH__gauge}>
                <LinearGauge
                    // width={width * 0.75}
                    width={width * 0.85}
                    height={height * 0.4}
                    value={value || value === 0 ? value : min}
                    units={false}
                    minValue={min}
                    maxValue={max}
                    // Plate
                    colorPlate="transparent"
                    // Ticks
                    majorTicksDec={roundDigit}
                    majorTicks={ticks}
                    colorMajorTicks={color}
                    colorMinorTicks={color}
                    minorTicks={minor}
                    strokeTicks={false}
                    highlights={false}
                    numbersMargin={2}
                    tickSide="right"
                    // Numbers
                    colorNumbers={theme === 'dark' ? '#fff' : '#000'}
                    fontNumbers="Inter"
                    fontNumbersWeight="bold"
                    fontNumbersSize={16}
                    numberSide="right"
                    // Border
                    borderOuterWidth={0}
                    borderMiddleWidth={0}
                    borderInnerWidth={0}
                    borderShadowWidth={0}
                    // ValueBox
                    valueBox={false}
                    // Bar
                    barBeginCircle={false}
                    colorBar={theme === 'dark' ? '#373940' : '#ddd'}
                    colorBarEnd={theme === 'dark' ? '#373940' : '#ddd'}
                    colorBarProgress={color}
                    colorBarProgressEnd={color}
                    // Needle
                    needle={false}
                />
            </div>
        </div>
    );
};
export default LinearHorizontal;
