import { resetReduxState, setDeviceResponse } from '../actions/actions';
import { setDemo, setDemoSettings } from '../actions/demo';
import { addDevicesConnected, setDeviceConnectedCount } from '../actions/devices';
import { setPort, togglePort } from '../actions/port';
import { setReading } from '../actions/reader';
import { addWriter } from '../actions/writer';
import { setSensor } from '../actions/sensors';

let demoRefresh = 0;
let demoChannels = {};

export const enabledDemoMode = () => async (dispatch, getState) => {
    const {
        demo: { demo, settings },
        devices: { all },
        port: { open },
    } = getState();

    demo.forEach((key) => {
        const current = all.find((s) => s.attributes.key === key);
        // console.log(current);

        const channels = current?.getElementsByTagName('channel');

        if (channels && !settings.rcc) {
            demoChannels[key] = channels;
            const value = new Array(channels.length).fill(1).join(' ');
            dispatch(setDemoSettings({ key, value: { rcc: value } }));
        }

        if (!open) {
            dispatch(togglePort(true));
            dispatch(setPort(demo));
            dispatch(setReading(true));
            dispatch(addWriter({ key, data: true }));
            dispatch(addDevicesConnected({ key, data: current }));
            dispatch(setDeviceConnectedCount(demo.length));
        }
    });
};

export const readDemoSensors = () => async (dispatch, getState) => {
    const {
        demo: { demo, settings },
        port: { open },
    } = getState();

    if (open) {
        demo.forEach((key, i) => {
            // console.log(key, settings[key]);
            const { rcc } = settings[key];
            let rccArr = rcc ? rcc.split(' ')?.map(Number) : new Array(demoChannels[key].length).fill(1);

            let data = '';
            let str = '';
            // Проверяем взаимосключающие каналы для датчика по физике
            if (!rccArr?.length) return;

            if (key === 'PhysAccel202108') {
                if (rccArr[0] === 1 && rccArr[2] === 1) rccArr[2] = 0;
                if (rccArr[1] === 1 && rccArr[3] === 1) rccArr[3] = 0;

                dispatch(setDemoSettings({ key, value: { rcc: rccArr.join(' ') } }));
            }
            dispatch(setDeviceResponse('rcc ' + rccArr.join(' ')));

            rccArr.forEach((ch, i) => {
                const channel = demoChannels[key][i];
                const {
                    attributes: { name },
                } = channel;

                // Переключаем режим
                let min, max;
                const mode = channel?.getElementsByTagName('mode');
                if (mode.length) {
                    const modInd = settings['mod' + i];
                    if (modInd >= 0) {
                        const {
                            attributes: { minValue, maxValue },
                        } = mode[modInd];
                        min = +minValue;
                        max = +maxValue;
                    }
                }

                // Формируем каналы и их значение
                if (ch) {
                    if (name.includes('Температура')) {
                        const temp = 36.6 + getRandomArbitrary(-1, 1);
                        str += `ch${i} ${temp} `;
                    } else {
                        if (min && max) {
                            str += `ch${i} ${getRandomArbitrary(min, max)} `;
                        } else {
                            str += `ch${i} ${getRandomArbitrary(-1, 1)} `;
                        }
                    }
                }
            });

            const charge = 33 * i;

            str += 'btl +' + charge;

            data = `${key} ${str} ${++demoRefresh}`;

            dispatch(setSensor(data));
        });
    } else {
        dispatch(togglePort(false));
        dispatch(setDemo(false));
        dispatch(resetReduxState());
    }
};

export const disableDemoMode = () => async (dispatch) => {
    // clearInterval(demoSendInterval);
    dispatch(togglePort(false));
    dispatch(setDemo(false));
    dispatch(resetReduxState());
};

function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}
