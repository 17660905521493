// import {  } from '../../actions/';

export const mapStateToProps = (nstate) => {
    return {
        timer: nstate.ntimer.timer,
        value: nstate.nsensor.data?.EMGMeter ? nstate.nsensor.data?.EMGMeter[1] : null,
        visualized: nstate.nsensor.visualized,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    // setPlay: (bool) => dispatch(setPlay(bool)),
});
