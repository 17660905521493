import React, {useEffect, useRef} from 'react';

const VideoCard = ({stream, showStream, muted, volume = 0}) => {
  const video = useRef(stream);

  useEffect(() => {
    if (video.current && stream) {
      try {
        video.current.srcObject = stream;
      } catch (e) {
        console.log(e)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video.current, stream]);

  useEffect(() => {
    if (video.current) {
      video.current.style = showStream ? "display:visible" : "display:none";
    }
  }, [showStream]);

  useEffect(() => {
    if (video.current) {
      video.current.volume = volume;
    }
  }, [volume]);


  return (
    <video
      playsInline
      autoPlay
      ref={video}
      muted={muted}
    />
  );
};

export default VideoCard;
