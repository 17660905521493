import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {storageOptions} from "../../../redux/slices/storage";
import "./Dashboard.scss";
import {toast} from "react-toastify";

const appPath = {
  sensor: "/vr-lab/PlaneBuildDatchik",
  recognition: "/app_ml",
  plane: "/app_ml",
}

export const DashboardMl = (props) => {
  const options = useSelector(storageOptions);
  const frameRef = useRef({});
  const [mlSrc] = useState(process.env.REACT_APP_ML_URL + appPath[props.match.params.id] +
    "/?screen=" + props.match.params.id + "&theme=" + options.theme + "&fontSize=" + options.fontSize);

  const processMessage = (me)=> {
    switch (me.data.type) {
      case "info":
        toast.info(me.data.message);
        break;
      case "warn":
        toast.warn(me.data.message);
        break;
      case "success":
        toast.success(me.data.message);
        break;
      case "error":
        toast.error(me.data.message);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    frameRef.current?.contentWindow.postMessage({type: "theme", data: options.theme}, "*");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.theme]);

  useEffect(() => {
    frameRef.current?.contentWindow.postMessage({type: "fontSize", data: options.fontSize}, "*");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.fontSize]);

  useEffect(() => {
    window.addEventListener("message", processMessage);
    return () => {
      window.removeEventListener("message", processMessage);
    }
  },[]);

  return (
    <iframe className="whiteboard" id="ml-frame" title="ИИ"
            allow="camera; microphone; bltuetooth; usb"
            allowTransparency="true"
            ref={(el) => frameRef.current = el}
            src={mlSrc}/>
  );
}
export default DashboardMl