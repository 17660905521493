import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {storageClass} from "../../redux/slices/storage";
import {isAuth} from "../../helpers/auth";
import {getTableScoreList} from '../ui/utils/score_utils';
import {rlsApi, rlsiApi} from "../../api/api";
import {enabledEditScore} from "../practicum/lw_utils";
import ModalConfirmDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import ScenarioResultDetailDlg from "../RlabScenarios/ScenarioResultDetailDlg";
import Table from "../ui/Table/Table";
import {printOnlyDate} from "../../helpers/text";

const ProfileScenarioTable = ({profile, setScores}) => {
	const [idBeDeleted, setIdBeDeleted] = useState(undefined);
	const [deletionConfirmed, setDeletionConfirmed] = useState(false);
    const [showConfirmDlg, setShowConfirmDlg] = useState(false);
    const [resultData, setResultData] = useState([]);
	const [isUpdated, setIsUpdated] = useState(false);
	const [canEditScore, setCanEditScore] = useState(false);
    const [showTestDetails, setShowTestDetails] = useState(false);
    const [testScenarioInfoId, setTestScenarioInfoId] = useState(undefined);

    const documentClass = useSelector(storageClass);

	useEffect(() => {
		setCanEditScore(enabledEditScore(isAuth(), documentClass.classUsers));
	},[documentClass.classUsers]);

    useEffect(() => {
		const loadScenarioInfoData = async () => {
			const infoResult = await rlsiApi.getAllRlScenarioInfosByStudent(profile._id);
			const infoData = infoResult.data;
			const scenarioData = await rlsApi.getScenarios(documentClass._id);
			let scores = [0, 0, 0, 0, 0];

			const tableList = [];
			for (let i = 0; i < infoData.length; i ++) {
				const item = infoData[i];
				const score = !!item.score ? item.score : '';

				tableList.push({
					scenarioLink: scenarioData.find(elem => elem._id === item.scenario)?.name,
					date: printOnlyDate(item.updatedAt), 
					score: item.score,
					reportArea: item.report?.answer ? item.report.answer : 'Нет отчета',
					actions: '', 
					id: item._id,
					fullDate: item.updatedAt,
				});

				scores = getTableScoreList(scores, score);

			}
			setScores(scores);
			setResultData(tableList);
			if (isUpdated) setIsUpdated(false);
		};

		if (profile)
			loadScenarioInfoData();
    }, [canEditScore, profile, documentClass._id, setScores, isUpdated, showTestDetails]);

	useEffect(() => {
		if (!deletionConfirmed) return;
		rlsiApi.deleteScenarioInfoById(idBeDeleted)
		.then(res => {
			setDeletionConfirmed(false);
			setIsUpdated(true);
			setIdBeDeleted(undefined);
		});
	}, [deletionConfirmed, idBeDeleted]);
	const handleDelete = id => {
		setIdBeDeleted(id);
		setShowConfirmDlg(true);
	};
	const handleDeleteNo = () => {
		setShowConfirmDlg(false);
		setIdBeDeleted(undefined);
	};
	const handleDeleteYes = () => {
		setShowConfirmDlg(false);
		setDeletionConfirmed(true);
	};

	const handleScenarioLink = (id) => {
		setTestScenarioInfoId(id);
		setShowTestDetails(true);
	};

	const handleScoreUpdate = async (score, id) => {
		const scoreVal = score !== '' ? Number(score) : null;
		await rlsiApi.updateRlScenarioInfoScoreById(id, {score: scoreVal});
		setIsUpdated(true);
	};

	const getHeader = () => {
		const headerTeacher = [
			{column: 'Сценарий', name: 'scenarioLink', style: { width: '20%'} }, 
			{column: 'Отчет', name: 'reportArea', style: { width: '20%'} }, 
			{column: 'Дата сдачи', name: 'date', style: { width: '20%'} }, 
			{column: 'Оценка', name: 'score', style: { width: '20%'} }, 
			{column: 'Действия', name: 'actions', style: { width: '20%'} }, 
			{column: 'id', name: 'id', style: { width: '0%'} }, 
		];

		const headerStudent = [
			{column: 'Сценарий', name: 'scenarioLink', style: { width: '25%'} }, 
			{column: 'Отчет', name: 'reportArea', style: { width: '25%'} }, 
			{column: 'Дата сдачи', name: 'date', style: { width: '25%'} }, 
			{column: 'Оценка', name: 'score', style: { width: '25%'} }, 
			{column: 'id', name: 'id', style: { width: '0%'} }, 
		];

		return isAuth().role === 0 ? headerStudent : headerTeacher;
	};

	return (
		<div>
			<Table 
				table={{
					header: getHeader(), 
					data: resultData,
					handleTableDataUpdate: handleScoreUpdate, 
				}}
				link={{
					handleLink: id => handleScenarioLink(id),
				}}
				sort={{
					hasSorting: true, 
					initSortInd: -3,
				}}
				actions={{
					handleDelete: ids => handleDelete(ids),
				}}
				canEditScore={canEditScore}
			/>

			{showTestDetails &&
			<ScenarioResultDetailDlg 
				showDlg={showTestDetails}
				setShowDlg={setShowTestDetails}
				selectedStudentId={profile._id}
				scenarioInfoId={testScenarioInfoId}
				setIsUpdated={setIsUpdated}
			/>
			}

			{showConfirmDlg &&
			<ModalConfirmDialog
				showConfirmDlg={showConfirmDlg} 
				handleNo={handleDeleteNo}
				handleYes={handleDeleteYes}
				question={'Вы действительно хотите удалить эту работу?'}
			/>
			}
		</div>
	);
}

export default ProfileScenarioTable;