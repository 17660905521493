import { setOscilloscopeChartSettings } from '../../actions/oscilloscope';
import { sendCommand } from '../../actions/port';
import { setForceUpdate, setPlay } from '../../actions/actions';

export const mapStateToProps = (state) => {
    return {
        played: state.actions.played,

        defaultChartSettings: state.oscilloscope.defaultChartSettings,
        chartSettings: state.oscilloscope.chartSettings,
        oscilloscopeSettings: state.oscilloscope.oscilloscopeSettings,
        isOscilloscope: state.oscilloscope.isOscilloscope,
        response: state.actions.response,

        sensorList: state.sensors.list,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setOscilloscopeChartSettings: (obj) => dispatch(setOscilloscopeChartSettings(obj)),
    sendCommand: (bool) => dispatch(sendCommand(bool)),
    setForceUpdate: (bool) => dispatch(setForceUpdate(bool)),
    setPlay: (bool) => dispatch(setPlay(bool)),
});
