import React, { useEffect, useState } from 'react';
import './NewModals.scss';
import '../List/ColorMenuItem.scss';
import '../Modal/ModalTask.scss';
import Sticker from '../Sticker/Sticker';
import { ReactComponent as Chart } from '../../../assets/icons/chart.svg';
import { ReactComponent as Homework } from '../../../assets/icons/house.svg';
import NewModalsButton from './NewModalsButton';

export const STICKER_COLORS = [
  { color: '#f35151', backgroundColor: '#f351511f', isDisabled: true },
  { color: '#FF7089', backgroundColor: '#FDD7DE' },
  { color: '#FF984E', backgroundColor: '#FFE9D9' },
  { color: '#FFF94A', backgroundColor: '#FDFCDD', isDisabled: true },
  { color: '#97EC55', backgroundColor: '#E4F0DA', isDisabled: true },
  { color: '#49EFF6', backgroundColor: '#E3FAFB' },
  { color: '#33BEDD', backgroundColor: '#DBF6FC' },
  { color: '#4A96EB', backgroundColor: '#E8F0F9' },
  { color: '#5942E9', backgroundColor: '#EFECFE' },
  { color: '#a7b0b8', backgroundColor: '#bec9d326', isDisabled: true },
];

export const STICKER_CATEGORIES = [
  { name: 'importance', title: 'По важности' },
  { name: 'type', title: 'По типу заданий' },
];

const NewStickerModal = ({ onCloseModal }) => {
  const [stickerName, setStickerName] = useState('');
  const [isOpenCategoryMenu, setIsOpenCategoryMenu] = useState(false);
  const [isOpenNewCategory, setIsOpenNewCategory] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [stickerCategory, setStickerCategory] = useState(STICKER_CATEGORIES[0]);
  const [activeColor, setActiveColor] = useState(STICKER_COLORS[1]);
  const [stickerData, setStickerData] = useState({});

  const onClickCategoryMenu = () => {
    if (isOpenNewCategory) {
      return;
    } else {
      setIsOpenCategoryMenu(!isOpenCategoryMenu);
    }
  };

  useEffect(() => {
    setStickerData({
      title: stickerName || 'Название стикера',
      name: stickerName,
      icon: isOpenNewCategory ? (
        <></>
      ) : stickerCategory?.name === 'importance' ? (
        <Chart style={{ stroke: activeColor.color }} />
      ) : (
        <Homework style={{ stroke: activeColor.color }} />
      ),

      color: activeColor,
    });
  }, [activeColor, isOpenNewCategory, stickerCategory?.name, stickerName]);

  useEffect(() => {
    if (!isOpenNewCategory && activeColor.isDisabled) {
      setActiveColor(STICKER_COLORS[1]);
    }
  }, [activeColor.isDisabled, isOpenNewCategory]);

  return (
    <div className="new_modals">
      <h2 className="new_modals__title">Добавить новый стикер</h2>
      <div className="new_modals__content">
        <div className="new_modals__section new_modals__section_inputs">
          <div className="new_modals__section_item">
            <div className="new_modals__section_title">Категория</div>

            <div
              className={
                isOpenNewCategory
                  ? 'new_modals__section_input disabled'
                  : 'new_modals__section_input'
              }
              onClick={() => onClickCategoryMenu()}
              style={{ position: 'relative', cursor: 'pointer' }}
            >
              {stickerCategory?.title}
              {isOpenCategoryMenu && (
                <ul className="new_modals__section_menu">
                  {STICKER_CATEGORIES.map((category) => (
                    <li
                      className="new_modals__section_menu_item"
                      key={category.name}
                      onClick={() => setStickerCategory(category)}
                    >
                      {category.title}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          {isOpenNewCategory && (
            <div className="new_modals__section_item">
              <div className="new_modals__section_title">
                Название новой категории
              </div>
              <input
                type="text"
                value={newCategoryName}
                placeholder="Введите название категории"
                onChange={(e) => setNewCategoryName(e.target.value)}
                className="new_modals__section_input"
              />
            </div>
          )}
          <div className="new_modals__section_item">
            {isOpenNewCategory ? (
              <div
                className="new_modals__section_button cancel"
                onClick={() => setIsOpenNewCategory(false)}
              >
                Отменить создание новой категории
              </div>
            ) : (
              <div
                className="new_modals__section_button"
                onClick={() => setIsOpenNewCategory(true)}
              >
                Добавить категорию
              </div>
            )}
          </div>
        </div>
        <div className="new_modals__section">
          <div className="new_modals__section_title">Название стикера</div>
          <input
            type="text"
            value={stickerName}
            placeholder="Введите название стикера"
            onChange={(e) => setStickerName(e.target.value)}
            className="new_modals__section_input"
          />
        </div>
        <div className="new_modals__section">
          <div className="new_modals__section_title">Цвет стикера</div>
          <div className="new_modals__section_colors">
            {STICKER_COLORS.map((color) => (
              <div
                key={color.color}
                className={`color_menu__item ${
                  activeColor.color === color.color ? 'active' : ''
                } ${!isOpenNewCategory && color.isDisabled ? 'disabled' : ''}`}
                onClick={() => {
                  if (!isOpenNewCategory && color.isDisabled) {
                    return;
                  } else {
                    setActiveColor(color);
                  }
                }}
                style={{ backgroundColor: color.color }}
              />
            ))}
          </div>
        </div>
        <div className="new_modals__section">
          <div className="new_modals__section_title">Пример вашего стикера</div>

          <Sticker sticker={stickerData} />
        </div>
        <div className="new_modals__section">
          <div className="new_modals__section_buttons">
            <NewModalsButton
              type="add"
              title="Создать стикер"
              onClick={() => console.log()}
            />
            <NewModalsButton
              type="cancel"
              title="Отмена"
              onClick={() => onCloseModal()}
            />
          </div>
        </div>
      </div>
      <span className="modal_task__close" style={{top: 0, right: 0}} onClick={() => onCloseModal()} />
    </div>
  );
};

export default NewStickerModal;
