import React, {useEffect, useRef} from 'react';
import ss from "./Checkbox3.module.scss";

const Checkbox3 = ({className, value, onChange}) => {
    const checkRef = useRef();

    useEffect(() => {
    checkRef.current.checked = value === 1;
    checkRef.current.indeterminate = value === 2;
  }, [value]);

  return (<input type="checkbox"
                 ref={checkRef}
                 className={ss.check + (className ? " " + className : "")}
                 value={value}
                 onChange={() => onChange({target: {value: (value + 1) % 3}})}
  />)
};
export default Checkbox3;
