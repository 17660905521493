import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Dropdown } from 'rlabui';

import ss from './Timer.module.scss';

const Timer = (props) => {
    // console.log(props);
    const {
        setPlay,
        played,
        paused,
        response,
        timer: { stopwatch, settings },
        setTimer,
        setTimerSettings,
        setStopwatch,
    } = props;

    const [prevTime, setPrevTime] = useState(0);
    const [time, setTime] = useState(0);
    const [timePause, setTimePause] = useState(0);
    const [started, setStarted] = useState(0);

    const options = [
        { label: 'MM:СС.Mс', value: 'mm:ss:SSS' },
        { label: 'MM:СС', value: 'mm:ss' },
        { label: 'ЧЧ:ММ:СС', value: 'HH:mm:ss' },
    ];

    useEffect(() => {
        if (!played) {
            setStopwatch(0);
        }
    }, [played, setStopwatch]);

    useEffect(() => {
        if (!played) {
            setStarted(0);
            setTimer(0);
            setTimePause(0);
            setPrevTime(0);
        }
    }, [played, setTimer, setStarted, setTimePause, setPrevTime]);

    useEffect(() => {
        if (started && stopwatch === 0) {
            setTimer(0);
            setTime(0);
        }
    }, [started, stopwatch, setTimer, setTime]);

    useEffect(() => {
        if (stopwatch > 0) {
            setTime(stopwatch);
        } else {
            setTime(0);
            setPrevTime(0);
        }
    }, [stopwatch, setTime, setPrevTime]);

    useEffect(() => {
        if (played) {
            const interval = prevTime ? new Date().valueOf() - prevTime : 0;
            if (paused) {
                if (typeof response === 'string' && response?.includes('paused')) {
                    setTimePause((p) => (p += interval));
                }
            } else {
                if (response) {
                    if (started === 0) {
                        setStarted(new Date().valueOf());
                    }

                    const currentTime = started > 0 ? new Date().valueOf() - started - timePause : 0;

                    if (stopwatch > 0) {
                        if (time <= 0) {
                            setPlay(false);
                        } else {
                            setTime(time - interval);
                            setTimer(currentTime);
                        }
                    } else {
                        setTime(currentTime);
                        setTimer(currentTime);
                    }
                }
            }
            setPrevTime(new Date().valueOf());
        } // eslint-disable-next-line
    }, [response, played, paused, setPlay, setTimer]);

    const setTimeFormatted = (t) => {
        if (t >= 3600000 && settings === 'mm:ss:SSS') {
            return moment(t).utcOffset(0).format('H:mm:ss:SSS');
        } else {
            return moment(t).utcOffset(0).format(settings);
        }
    };

    return (
        <div
            title="Формат времени"
            className={ss.timer}
        >
            <input
                className={ss.input}
                type="text"
                value={setTimeFormatted(time)}
                readOnly
            />
            <Dropdown
                className={ss.dropdown}
                value={''}
                dropPosition="top right"
            >
                {options.map((opt, i) => {
                    const { label, value } = opt;
                    return (
                        <Dropdown.Item
                            key={i}
                            onClick={() => setTimerSettings(value)}
                        >
                            {label}
                        </Dropdown.Item>
                    );
                })}
            </Dropdown>
        </div>
    );
};

export default Timer;
