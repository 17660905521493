import { connect } from 'react-redux';
import {mapDispatchAdminToProps, mapDispatchToProps, mapStateToProps} from './redux';
import MembersG from './MembersGrid';
import ClassG from './ClassGrid'
import DeviceG from "./DeviceGrid";
import StorageG from "./StorageGrid";
import OrgG from "./OrgGrid";
import FeaturesG from "./FeaturesGrid";

const MembersGrid = connect(mapStateToProps,mapDispatchToProps)(MembersG);
const ClassGrid = connect(mapStateToProps,mapDispatchToProps)(ClassG);
const StorageGrid = connect(mapStateToProps,mapDispatchToProps)(StorageG);
const DeviceGrid = connect(mapStateToProps,mapDispatchAdminToProps)(DeviceG);
const OrgGrid = connect(mapStateToProps,mapDispatchAdminToProps)(OrgG);
const FeaturesGrid = connect(mapStateToProps,mapDispatchAdminToProps)(FeaturesG);

export { MembersGrid, ClassGrid, DeviceGrid, StorageGrid, OrgGrid, FeaturesGrid }
