import { addSensorsFilters, delSensorsFilters } from '../../../actions/sensor';

export const mapStateToProps = (nstate) => {
    return {
        played: nstate.napp.played,
        sensorsList: nstate.nsensor.list,
        sensorsImported: nstate.nsensor.imported,
        filters: nstate.nsensor.filters,
        visualized: nstate.nsensor.visualized,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    addSensorsFilters: (obj) => dispatch(addSensorsFilters(obj)),
    delSensorsFilters: (key) => dispatch(delSensorsFilters(key)),
});
