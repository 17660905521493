const initialState = {
    modal: false,
    demo: [],
    settings: {},
    sensors: [
        { name: 'Физика', key: 'PhysAccel202108' },
        { name: 'Биология', key: 'BioEnv202201' },
    ],
    learn: false,
    step: 0,
};

const demoReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_DEMO':
            return {
                ...state,
                demo: action.payload,
            };

        case 'SET_DEMO_MODAL':
            return {
                ...state,
                modal: action.payload,
            };

        case 'SET_DEMO_SENSORS':
            return {
                ...state,
                sensors: action.payload,
            };

        case 'SET_DEMO_SETTINGS':
            let settings = { ...state.settings };
            const key = action.payload.key;
            settings[key] = action.payload.value;
            return {
                ...state,
                settings,
            };

        case 'SET_DEMO_LEARN':
            return {
                ...state,
                learn: action.payload,
            };

        case 'SET_DEMO_STEP':
            return {
                ...state,
                step: action.payload,
            };

        default:
            return state;
    }
};

export default demoReducer;
