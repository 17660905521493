import React, { useEffect } from 'react';
import "./FontResize.scss";
import {useSelector} from "react-redux";
import {storageOptions} from "../../../redux/slices/storage";

const FontResize = (props) => {
    const defaultFontSize = 16;
    const {fontSize} = useSelector(storageOptions);
    const {setFontSize} = props;

    useEffect(() => {
        const wrapper = document.getElementById('root');
        if (fontSize && fontSize !== defaultFontSize) {
            wrapper.style.fontSize = fontSize + 'px';
        } else {
            wrapper.style.fontSize = '';
        }

      const iframe = document.getElementById('board-frame');
      if (iframe) {
        const data = { type: 'fontResize', data: fontSize };
        iframe.contentWindow.postMessage(data, '*');
      }
    }, [fontSize]);


    return (
        <div className="cor_fontResize">
            <div className="cor_fontResize__btn tp tp_down"
                data-tp="Отдалить"
                onClick={() => setFontSize((fontSize??defaultFontSize) - 1)}
            ><span>A-</span></div>
            <div className="cor_fontResize__btn tp tp_down"
                data-tp="Стандартный размер"
                onClick={() => setFontSize(defaultFontSize)}
            ><span>A</span></div>
            <div className="cor_fontResize__btn tp tp_down"
                data-tp="Приблизить"
                onClick={() => setFontSize((fontSize??defaultFontSize) + 1)}
            ><span>A+</span></div>
        </div>
    )
};
export default FontResize;
