import {toggleAudio, toggleScreen, toggleSensor, toggleVideo} from "../../../actions/toggle";
import {showRlabSensors, showRlabSettings} from "../../../actions/actions";
import {
    setGraduationModal,
    setPrintModal, setSensorData, setSensorImported,
    setSensorsModal,
    setSensorTableSorting, setSensorWritedData, setSensorWritedList
} from "../../../rlab/src/actions/sensors";
import {setChartMarkers, setChartSettingsModal, setChartView, toggleChartLegend} from "../../../rlab/src/actions/chart";
import {checkOscilloscope} from "../../../rlab/src/actions/oscilloscope";
import {setWriteStatus} from "../../../rlab/src/actions/writer";
import {setSensorReading} from "../../../rlab/src/actions/reader";
import {sendCommand} from "../../../rlab/src/actions/port";
import {setFile, setFileExtension} from "../../../rlab/src/actions/import";
import {connectDisconnect} from "../../../rlab/src/commands/port";
import {btConnect, btDisconnect} from "../../../rlab/src/commands/bluetooth";
import {toggleTimerModal} from "../../../rlab/src/actions/timer";
import {reloadOption} from "../../../redux/slices/storage";
import {showModalInfo} from "../../../rlab/src/actions/actions";
import {setAboutModal, setResetModal} from "../../../rlab-neuro/src/actions/modal";
import {setDemoModal} from "../../../rlab/src/actions/demo";

export const mapStateToProps = (state) => {
    return ({
        video: state.toggle.video,
        audio: state.toggle.audio,
        screen: state.toggle.screen,
        sensor: state.toggle.sensor,
        markers: state.chart.marker,
        records: state.sensors.records,
        played: state.actions.played,
        port: state.port.open,
        connectionType: state.port.connectionType,
        devicesList: state.devices.list,
        sensorsList: state.sensors.list,
        legendMode: state.chart.legend,
        view: state.chart.settings.view,
        tableSorting: state.sensors.tableSorting,
        writeStatus: state.writer.writeStatus,
        file: state.import.file,
        fileExtension: state.import.fileExtension,
        isReading: state.reader.sensorReading,
    });
};

export const mapNeuroStateToProps = (state) => {
    return ({
    });
};

export const mapDispatchToProps = (dispatch) => ({
    toggleVideo: (bool) => dispatch(toggleVideo(bool)),
    toggleAudio: (bool) => dispatch(toggleAudio(bool)),
    toggleScreen: (bool) => dispatch(toggleScreen(bool)),
    toggleSensor: (bool) => dispatch(toggleSensor(bool)),
    showRlabSettings: (obj) => dispatch(showRlabSettings(obj)),
    showRlabSensors: (obj) => dispatch(showRlabSensors(obj)),
});

export const mapRlabDispatchToProps = (dispatch) => ({
    setTheme: (str) => dispatch(reloadOption('theme',str)),
    setSensorTableSorting: (bool) => dispatch(setSensorTableSorting(bool)),
    setChartView: (bool) => dispatch(setChartView(bool)),
    setChartMarkers: (bool) => dispatch(setChartMarkers(bool)),
    setChartSettingsModal: (bool) => dispatch(setChartSettingsModal(bool)),
    setSensorsModal: (bool) => dispatch(setSensorsModal(bool)),
    setGraduationModal: (bool) => dispatch(setGraduationModal(bool)),
    checkOscilloscope: (bool) => dispatch(checkOscilloscope(bool)),
    setWriteStatus: (bool) => dispatch(setWriteStatus(bool)),
    setSensorReading: (bool) => dispatch(setSensorReading(bool)),
    setPrintModal: (bool) => dispatch(setPrintModal(bool)),
    sendCommand: (str) => dispatch(sendCommand(str)),
    setFile: (str) => dispatch(setFile(str)),
    setFileExtension: (str) => dispatch(setFileExtension(str)),
    connectDisconnect: (fn) => dispatch(connectDisconnect(fn)),
    btConnect: (fn) => dispatch(btConnect(fn)),
    btDisconnect: (fn) => dispatch(btDisconnect(fn)),
    toggleTimerModal: (bool) => dispatch(toggleTimerModal(bool)),
    toggleChartLegend: (bool) => dispatch(toggleChartLegend(bool)),
    showModalInfo: (bool) => dispatch(showModalInfo(bool)),
    setSensorData: (arr) => dispatch(setSensorData(arr)),
    setSensorWritedList: (arr) => dispatch(setSensorWritedList(arr)),
    setSensorWritedData: (arr) => dispatch(setSensorWritedData(arr)),
    setSensorImported: (arr) => dispatch(setSensorImported(arr)),
    setDemoModal: (bool) => dispatch(setDemoModal(bool)),
});

export const mapNeuroDispatchToProps = (dispatch) => ({
    setResetModal: (bool) => dispatch(setResetModal(bool)),
    setAboutModal: (bool) => dispatch(setAboutModal(bool)),
});
