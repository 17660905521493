import React, {useEffect, useRef, useState} from 'react';
import Linkify from 'react-linkify';
import "./ClassChatList.scss";
import {Tabs, Input, Icon} from '../ui';
import {fullName, isAuth, profilePicture} from "../../helpers/auth";
import {printLongDate, printListDate} from "../../helpers/text";
import ChangeClass from "../ChangeClass";

const ClassChatList = (props) => {
    const [text,setText] = useState('');
    const chatList = useRef(null)
    const scrollToBottom = () => {
        chatList.current?.scrollIntoView({block: "end", behavior: "smooth"})
    }

    useEffect(() => {
        scrollToBottom()
    }, [props.messages]);

    return (<div className="classChatList">
            <ChangeClass/>
            <div className="classChatList__head">
                <Tabs>
                    <Tabs.Item onClick={() => props.toggleChat(false)}>События</Tabs.Item>
                    <Tabs.Item className="active">Сообщения</Tabs.Item>
                </Tabs>
            </div>
            <div className="classChatList__scroll">
                <div className="classChatList__list" ref={chatList}>
                    {props.messages.map((msg, i) => (
                        <div key={i} className={"classChatList__item" +
                            (msg.author._id===isAuth()._id?" author":msg.author.role>0?" teacher":"")}>
                            {msg.author._id!==isAuth()._id && <div className="classChatList__profile">
                            {msg.author.picture?
                            <img src={profilePicture(msg.author.picture)} alt={msg.author.name}/>:
                              msg.author._id?<Icon name="user" />:<Icon name="delete" />}
                            </div>}
                            <div className="classChatList__name" title={fullName(msg.author)}>{fullName(msg.author)}</div>
                            <div className="classChatList__text" title={msg.text}>
                              <Linkify componentDecorator={(href, text, key) => (
                                <a target="blank" href={href} key={key}>{text}</a>)}>{msg.text}</Linkify>
                            </div>
                            <div className="classChatList__time" title={printLongDate(msg.timestamp)}>{printListDate(msg.timestamp)}</div>
                        </div>))}
                </div>
            </div>
            <div className="classChatList__send">
                <div className="classChatList__input">
                    <Input placeholder="Написать сообщение..."
                        value={text} onInput={e => setText(e.target.value)}
                        onKeyPress={e => { if (e.key === 'Enter') if(props.onSendMessage(text)) setText('')}} />
                </div>
                <Icon name="send" onClick={()=> {if(props.onSendMessage(text)) setText('');}}/>
            </div>
        </div>)
};
export default ClassChatList;
