import { sendCommand } from '../../actions/port';
import { setDeviceResponse, setForceUpdate, setPaused } from '../../actions/actions';

import { setAllDevices, addDevicesList, removeDevicesList, setDeviceConnectedCount, setDevicesBattery } from '../../actions/devices';

import {
    setSensorList,
    setSensorWritedList,
    setSensorFrequency,
    checkHiSpeed,
    clearSensorsEnabled,
    delSensorsEnabled,
    setSensorData,
    setSensorAsideData,
    setSensorWritedData,
} from '../../actions/sensors';

import { checkOscilloscope, setOscilloscopeSettings } from '../../actions/oscilloscope';
import { getDevicesList, getUnitsList } from '../../commands/device';
import { removeDisconnectedDevice } from '../../commands/bluetooth';
import { readDemoSensors, enabledDemoMode } from '../../commands/demo';
import { setSensorReading } from '../../actions/reader';
import { setDemoSettings } from '../../actions/demo';

export const mapStateToProps = (state) => {
    return {
        port: state.port.open,
        portData: state.port.data,
        connectionType: state.port.connectionType,
        connectionLoss: state.port.connectionLoss,
        devices: state.devices.all,
        devicesList: state.devices.list,
        devicesConnected: state.devices.connected,
        devicesCount: state.devices.count,
        played: state.actions.played,
        paused: state.actions.paused,
        sensor: state.sensors.item,
        sensorList: state.sensors.list,
        sensorsEnabled: state.sensors.enabled,
        frequency: state.sensors.frequency,
        writer: state.writer.data,
        writerSelected: state.writer.writerSelected,
        command: state.port.command,
        sensorReading: state.reader.sensorReading,
        timer: state.timer.timer,
        graduate: state.graduation.graduate,
        responsesArray: state.port.responses,
        isHiSpeed: state.sensors.isHiSpeed,
        isOscilloscope: state.oscilloscope.isOscilloscope,
        oscilloscopeSettings: state.oscilloscope.oscilloscopeSettings,
        forceUpdate: state.actions.forceUpdate,
        deviceResponse: state.actions.response,
        sensorsUnits: state.sensors.units,
        gradOffset: state.sensors.gradOffset,
        abscissa: state.sensors.abscissa,
        demo: state.demo.demo,
        demoSettings: state.demo.settings,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    addDevicesList: (obj) => dispatch(addDevicesList(obj)),
    removeDevicesList: (obj) => dispatch(removeDevicesList(obj)),
    setDevicesBattery: (obj) => dispatch(setDevicesBattery(obj)),
    setDeviceConnectedCount: (num) => dispatch(setDeviceConnectedCount(num)),
    setAllDevices: (arr) => dispatch(setAllDevices(arr)),
    getDevicesList: () => dispatch(getDevicesList()),
    getUnitsList: () => dispatch(getUnitsList()),
    setSensorFrequency: (freqArr) => dispatch(setSensorFrequency(freqArr)),
    setSensorList: (list) => dispatch(setSensorList(list)),
    setSensorData: (data) => dispatch(setSensorData(data)),
    setSensorAsideData: (data) => dispatch(setSensorAsideData(data)),
    setSensorWritedData: (data) => dispatch(setSensorWritedData(data)),
    setSensorWritedList: (list) => dispatch(setSensorWritedList(list)),
    delSensorsEnabled: (item) => dispatch(delSensorsEnabled(item)),
    clearSensorsEnabled: (arr) => dispatch(clearSensorsEnabled(arr)),
    setSensorReading: (bool) => dispatch(setSensorReading(bool)),
    sendCommand: (str) => dispatch(sendCommand(str)),
    setDeviceResponse: (str) => dispatch(setDeviceResponse(str)),
    checkOscilloscope: (bool) => dispatch(checkOscilloscope(bool)),
    setOscilloscopeSettings: (obj) => dispatch(setOscilloscopeSettings(obj)),
    setPaused: (bool) => dispatch(setPaused(bool)),
    checkHiSpeed: (bool) => dispatch(checkHiSpeed(bool)),
    setForceUpdate: (bool) => dispatch(setForceUpdate(bool)),

    removeDisconnectedDevice: (device) => dispatch(removeDisconnectedDevice(device)),
    enabledDemoMode: () => dispatch(enabledDemoMode()),
    readDemoSensors: () => dispatch(readDemoSensors()),
    setDemoSettings: (item) => dispatch(setDemoSettings(item)),
});
