import React from "react";
import SunEditor from 'suneditor-react';
import KaTeX from 'katex';
import "suneditor/dist/css/suneditor.min.css";
import 'katex/dist/katex.min.css';
import "./TextEditor.scss";

export const BUTTON_LIST = {
  DEFAULT: 0,
  CUSTOM_SCENARIO: 1,
};

const defaultFonts = [
  "Arial",
  "Courier New",
  "Impact",
  "Inter",
  "Tahoma",
  "Trebuchet MS",
  "Verdana",
  "Logical",
  "Salesforce Sans",
  "Sans-Serif",
  "Serif",
  "Times New Roman",
  "Helvetica",
].sort();

const getButtonList = (btListType, video) => {
  const defaultButtonList = [
    ["undo", "redo"],
    ["font", "fontSize"],
    [
      "bold",
      "underline",
      "italic",
      "strike",
      "subscript",
      "superscript"
    ],
    ["fontColor", "hiliteColor"],
    ["align", "list", "lineHeight"],
    ["outdent", "indent"],
    ["table", "horizontalRule"],
    (video ? ["link", "image", "video"]:["link", "image"]),
    ['math'],
    ["showBlocks"],
    ["removeFormat"]
  ];

  if (btListType === BUTTON_LIST.DEFAULT)
      return defaultButtonList;

  const customScenarioButtonList = [
    ["undo", "redo"],
    [
      "bold",
      "underline",
      "italic",
      "strike",
      "subscript",
      "superscript"
    ],
    ["align", "list", "lineHeight"],
    ["outdent", "indent"],
    ["removeFormat"]
  ];
  return customScenarioButtonList;
};

const TextEditor = ({
  value, 
  setValue, 
  maxWidth = '100%', 
  height = '100%', 
  minHeight = '15em', 
  video = false, 
  btListType = BUTTON_LIST.DEFAULT,
  maxCharCount = false
  }) => {

  return (
    <SunEditor
      setContents={value}
      onChange={setValue}
      disabled={true}
      lang='ru'
      setOptions={{
        katex: KaTeX,
        buttonList: getButtonList(btListType, video),
        defaultTag: "div",
        height: height,
        minHeight: minHeight,
        maxWidth: maxWidth,
        showPathLabel: false,
        font: defaultFonts,
        fontSize: [ 0.5, 0.75, 1, 1.15, 1.5, 1.75, 2, 2.5, 3, 3.5, 4],
        fontSizeUnit: 'em',
        defaultStyle: "font-size:1.15em; font-family: 'Inter'; line-height: 1.5;",
        maxCharCount: maxCharCount,
      }}
    />
  )
};

export default TextEditor;
// Sample of setings
// http://suneditor.com/sample/html/options.html