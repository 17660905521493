import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import PIOneName from './PIOneName';
import Chart from "../Charts";
import {Modal, Button} from 'rlabui';
import PIOneTable from "./PIOneTable";
import {defaultStyle} from "../Charts/ChartHelper";

export const convertTableToPlot = (table, styles, index) => {
  const data = Array.from(Array(table.rows[0].length - 1), _ => []);
  table.rows.forEach((row, i) => row.forEach((d, j) => (j > 0) && data[j - 1].push(d)));
  table.properties.slice(1).forEach((p, i) => {
    if (styles.length <= i) {
      const newStyle = (i > 0) ? defaultStyle(i, index) : {};
      newStyle.axe = p.title;
      styles.push(newStyle);
    } else {
      styles[i].axe = p.title;
    }
  });
  if (styles.length >= table.properties.length) {
    styles = styles.slice(0, table.properties.length - 1);
  }
  return [data, styles];
}


const PIOneGraphics = ({
                         isEdit, //is edit mode (true = проведение, false - просмотр)
                         uid,
                         id,
                         workPlot, //содержимое графика
                         setWorkPlot, //update графика - написана в компоненте PInvestigationsOne
                         plotIndex, //порядковый номер графика
                         doDeleteHideSection
                       }) => {
  const dispatch = useDispatch();
  const [graphicsName, setGraphicsName] = useState(undefined);
  const [tableData, setTableData] = useState({});
  const [editData, setEditData] = useState(false);

  useEffect(() => {
    if (!workPlot) return;
    setGraphicsName(workPlot.title);
  }, [dispatch, workPlot, uid]);

  const updateGraphicsName = (isSave, name) => {
    if (isSave) {
      setWorkPlot({...workPlot, title: name})
    }
  };

  const loadData = () => {
    const rows = workPlot.data[0].map((_, i)=>[i+1,...workPlot.data.map(d=>d[i])]);
    setTableData({
      rows,
      properties:  [
        {title: 'N', type: 'order'},
        ...workPlot.styles.map(s=>({title: s.axe, type: 'number'})),
      ]
    });
    setEditData(true);
  }

  const convertData = () => {
    const [data, styles] = convertTableToPlot(tableData, workPlot.styles, plotIndex);
    setWorkPlot({...workPlot, data, styles});
  }

  const doSaveTheTableColumns = (_columns, rows, tableIndex) => {
    setTableData({...tableData, rows: rows, properties: _columns});
  };

  return (<>
      <div id={id}>
        {isEdit ? <PIOneName
            isEdit={isEdit}
            initName={graphicsName}
            setEditedName={updateGraphicsName}
            uniqueIndex={plotIndex}
            doDeleteHideSection={doDeleteHideSection}/> :
          <div className="cor-net__title" key={'pname01' + plotIndex}>{graphicsName}</div>}
        <div className="pi-plots">
          <Chart isEdit={isEdit} index={plotIndex} workPlot={workPlot} setWorkPlot={setWorkPlot}
                 editData={editData} setEditData={loadData}/>
        </div>
      </div>
      {editData && 
      <>
        <Modal visible={editData} size="xl">
            <Modal.Head modalClose={() => setEditData(false)} />
            <Modal.Body>
              <PIOneTable
                isEdit={true}
                workMeasurement={tableData}
                setWorkMeasurement={(ti, data) => setTableData(data)}
                doSaveTheTableColumns={doSaveTheTableColumns}
                tableIndex={0}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => {
                convertData();
                setEditData(false);
              }}>
                Применить
              </Button>
              <Button onClick={() => setEditData(false)} border={true}>Закрыть</Button>
            </Modal.Footer>
        </Modal>
      </>
      }
  </>);
}

export default PIOneGraphics;