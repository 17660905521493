import { setChartMode, setChartMarkerAdd, setChartMarkerDelete, setChartMarkerDeleteAll, setChartRangeAdd, setChartRangeDelete, clearChartMarkerModal } from '../../actions/chart';

export const mapStateToProps = (state) => {
    return {
        port: state.port.open,
        settings: state.chart,
        imported: state.sensors.imported,
        isOscilloscope: state.oscilloscope.isOscilloscope,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setChartMode: (str) => dispatch(setChartMode(str)),
    setChartMarkerAdd: (bool) => dispatch(setChartMarkerAdd(bool)),
    setChartMarkerDelete: (bool) => dispatch(setChartMarkerDelete(bool)),
    setChartRangeAdd: (bool) => dispatch(setChartRangeAdd(bool)),
    setChartRangeDelete: (bool) => dispatch(setChartRangeDelete(bool)),
    setChartMarkerDeleteAll: (bool) => dispatch(setChartMarkerDeleteAll(bool)),
    clearChartMarkerModal: (bool) => dispatch(clearChartMarkerModal(bool)),
});
