import React, { useEffect, useRef, useState } from 'react';
import Modal from '../Modal';
import './Settings.scss';
import {useSelector} from "react-redux";
import {storageOptions} from "../../redux/slices/storage";
import {Dropdown} from "../ui";
import DropdownItem from "../ui/Dropdown/DropdownItem";

const VIDEO_SIZE = [
  ["По Умолчанию", {}],
  ["POOR (160x120)", {width: {max: 160}, height: {max: 120}}],
  ["LOW (320x240)", {width: {max: 320}, height: {max: 240}}],
  ["TV (768x576)", {width: {max: 768}, height: {max: 576}}],
  ["HD (1280x720)", {width: {max: 1280}, height: {max: 720}}],
  ["FullHD (1920x1080)", {width: {max: 1920}, height: {max: 1080}}],
  ["4K (4096x2160)", {width: {max: 4096}, height: {max: 2160}}],
];

const VideoSettings = (props) => {
    const defaultDevice = {value: null, label: 'По умолчанию'};
    const {video:videoOptions} = useSelector(storageOptions);
    const [camsList, setCamsList] = useState([defaultDevice]);
    const [micsList, setMicsList] = useState([defaultDevice]);
    const [localStream, setLocalStream] = useState();
    const [selectedCam, setSelectedCam] = useState(null);
    const [selectedMic, setSelectedMic] = useState(null);
    const [selectedSize, setSelectedSize] = useState(videoOptions?.size??{});
    const videoRef = useRef();

    useEffect(() => {
        if (props.showModal) {
            navigator.mediaDevices.getUserMedia({video: true}).finally(() => {
                navigator.mediaDevices.getUserMedia({audio: true}).finally(enumerateDevices)
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.showModal]);

    useEffect(() => {
        if (localStream && videoRef.current) {
            videoRef.current.srcObject = localStream;
        }
    }, [localStream]);

    useEffect(() => {
      if (camsList.length > 0 && props.showModal) {
        videoRef.current?.srcObject?.getTracks().forEach(track => track.stop());
        const video = Object.assign({}, selectedSize, selectedCam ? {deviceId: selectedCam} : {});
        navigator.mediaDevices.getUserMedia({video}).then(stream => setLocalStream(stream))
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [camsList, selectedCam, selectedSize]);

    const enumerateDevices = () => {
        navigator.mediaDevices.enumerateDevices().then(function (devices) {
            const cams = [defaultDevice];
            const mics = [defaultDevice];
            devices.filter(d => d.label.length > 0).forEach(device=> {
                if (device.kind === 'videoinput') {
                    cams.push({value: device.deviceId, label: device.label});
                }
                if (device.kind === 'audioinput') {
                    mics.push({value: device.deviceId, label: device.label});
                }
            });
            setCamsList(cams);
            setMicsList(mics);
            setSelectedCam(videoOptions?.cam.deviceId);
            setSelectedMic(videoOptions?.mic.deviceId);
        });
    }

    const handleSave = () => {
        props.setVideoOptions({
            cam: selectedCam?{deviceId: selectedCam}: {},
            mic: selectedMic?{deviceId: selectedMic}: {},
            size: selectedSize,
        });
        handleClose();
    };

    const handleClose = () => {
        videoRef.current?.srcObject?.getTracks().forEach(track => {track.stop();});
        props.showVideoSettings({showModal: false});
    };

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) handleClose();
        };
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <Modal visible={props.showModal} content={
            <>
                <div className="modal__close" onClick={handleClose}/>
                <div className="modal__message">Выбор устройств</div>
                <div className="modal__body cor_settings_hor2">
                    <div className="cor_settings_vert">
                        <label htmlFor="cam">Видео</label>
                        <div/>
                        <Dropdown value={camsList.find(c=>c.value === selectedCam)?.label}>
                            {camsList.map(c =>
                              <DropdownItem key={c.value} onClick={e => setSelectedCam(c.value)}>{c.label}</DropdownItem>)}
                        </Dropdown>
                    </div>
                    <div className="cor_settings_vert">
                        <label htmlFor="mic">Микрофон</label>
                        <div/>
                        <Dropdown value={micsList.find(c=>c.value === selectedMic)?.label}>
                            {micsList.map(c =>
                              <DropdownItem key={c.value} onClick={e => setSelectedMic(c.value)}>{c.label}</DropdownItem>)}
                        </Dropdown>
                    </div>
                    <div key="features" className="cor_settings_hor1_2">
                        <div className="cor_settings_hor1_2_title">Качество видео</div>
                        {VIDEO_SIZE.map(([k,v])=>
                          <label key={k}>
                              <input
                                type="radio"
                                name="size"
                                onChange={() => setSelectedSize(v)}
                                checked={JSON.stringify(v) === JSON.stringify(selectedSize)}
                                value={v}
                              />{k}</label>)}
                    </div>
                </div>
                <div className="modal__body cor_settings_video">
                    <video ref={videoRef} src={localStream} autoPlay muted></video>
                </div>
                <div className="modal__action">
                    <button className="cor_btn cor_btn_primary" onClick={handleSave}>Выбрать</button>
                </div>
            </>
        }/>
    )
};
export default VideoSettings;
