import {toast} from "react-toastify";
import { setConnectionType, sendCommand, setPortOpen, setPortWriter, setPortReader } from '../actions/port';
import { sendSensorResponse } from './port';

const textDecoder = new TextDecoder('utf-8');
let respBuffer = '';
// Кэш объекта выбранного устройства
let deviceCache = {};
let devicesValues = {};

// Кэш объекта характеристики
let characteristicRead = {};
// Кэш объекта характеристики для записи
let characteristicWrite = {};

let serviceCache = {};
let serverCache = {};

// Запустить выбор Bluetooth устройства и подключиться к выбранному
let btDispatch = () => null;
const HM10_SERIAL_UUID = '0000ffe0-0000-1000-8000-00805f9b34fb';
const HM10_READ_UUID = '0000ffe1-0000-1000-8000-00805f9b34fb';
const HM10_WRITE_UUID = '0000ffe1-0000-1000-8000-00805f9b34fb';

const ESP32_SERIAL_UUID = '6e400001-b5a3-f393-e0a9-e50e24dcca9e';
const ESP32_READ_UUID = '6e400003-b5a3-f393-e0a9-e50e24dcca9e';
const ESP32_WRITE_UUID = '6e400002-b5a3-f393-e0a9-e50e24dcca9e';

export const btConnect = () => async (dispatch, getState) => {
    // const {
    //     // nport: { open: port, responses: respNameArr },
    // } = getState();
    // const state = getState();
    // console.log(state);
    let connected;
    btDispatch = dispatch;

    return requestBluetoothDevice()
        .then((device) => {
            connected = device.name;
            return connectDeviceAndCacheCharacteristic(device);
        })
        .then((characteristic) => {
            btStartNotifications(characteristic);
        })
        .catch((error) => {
            console.log(error);
            switch (error.message) {
                case 'Bluetooth adapter not available.':
                    toast.warn('Bluetooth-адаптер недоступен');
                    break;
                case 'User cancelled the requestDevice() chooser.':
                    toast.warn('Не выбрано устройство');
                    // removeDisconnectedDevice(connected);
                    break;
                default:
                    if (connected?.name) {
                        toast.error('Соединение с ' + connected.name + ' прервано');
                        // removeDisconnectedDevice(connected);
                    } else {
                        toast.error('Соединение прервано');
                    }
            }
        });
};

// Запрос выбора Bluetooth устройства
const requestBluetoothDevice = () => {
    if (!navigator.bluetooth) {
        toast.warn(
            'Включите экспериментальные возможности. Подробности "https://github.com/WebBluetoothCG/web-bluetooth/blob/main/implementation-status.md"',
            'Ваш браузер не поддерживает Bluetooth API.'
        );
        btDispatch(setPortOpen(false));
        btDispatch(setConnectionType(null));
        return false;
    }

    return navigator.bluetooth
        .requestDevice({
            acceptAllDevices: true,
            optionalServices: [HM10_SERIAL_UUID, ESP32_SERIAL_UUID],
        })
        .then((device) => {
            const deviceName = device.name;
            const key = device.id;
            deviceCache[key] = device;
            toast.info(`Подключаемся к ${deviceName}`);
            device.addEventListener('gattserverdisconnected', handleDisconnection);
            return device;
        });
};

// Подключение к определенному устройству, получение сервиса и характеристики
const connectDeviceAndCacheCharacteristic = (device) => {
    let key = device.id;

    if (!key) {
        toast.error('Ошибка соединения');
        return false;
    }

    return device.gatt
        .connect()
        .then((server) => {
            serverCache[key] = server;
            return server.getPrimaryService(HM10_SERIAL_UUID);
        })
        .catch((err) => {
            return serverCache[key].getPrimaryService(ESP32_SERIAL_UUID);
        })
        .then((service) => {
            serviceCache[key] = service;
            return serviceCache[key].getCharacteristic(HM10_READ_UUID);
        })
        .catch((err) => {
            return serviceCache[key].getCharacteristic(ESP32_READ_UUID);
        })
        .then((readCharacteristic) => {
            characteristicRead[key] = readCharacteristic;
            btDispatch(setPortReader(characteristicRead));
            return serviceCache[key].getCharacteristic(HM10_WRITE_UUID);
        })
        .catch((err) => {
            return serviceCache[key].getCharacteristic(ESP32_WRITE_UUID);
        })
        .then((writeCharacteristic) => {
            characteristicWrite[key] = writeCharacteristic;
            btDispatch(setPortWriter(writeCharacteristic));
            btDispatch(sendCommand('rcc'));
            return characteristicRead[key];
        });
};

// Включение получения уведомлений об изменении характеристики
const btStartNotifications = (characteristic) => {
    const {
        service: { device },
    } = characteristic;
    const key = device.id;
    try {
        return characteristic
            .startNotifications()
            .then(() => {
                try {
                    characteristic.addEventListener('characteristicvaluechanged', (e) => btDispatch(btCharacteristicValueChanged(e)));
                    // btDispatch(addDevicesConnected({ key: key, data: device }));
                    // btDispatch(setDeviceConnectedCount(Object.keys(deviceCache).length));
                    btDispatch(setPortOpen(true));
                    btDispatch(setConnectionType('bt'));
                    btDispatch(sendCommand('key'));
                    // btDispatch(sendCommand('acq 0'));
                    // btDispatch(setDeviceConnectedLoader(false));
                    toast.success(`Соединение c ${device.name} установлено`);
                } catch (e) {
                    console.log('startNotifications error', e);
                }
            })
            .catch((err) => {
                // console.log('btStartNotifications characteristic', characteristic);
                console.log('btStartNotifications error', err);
                toast.error(`Ошибка соединения c ${device.name}`);
                delete deviceCache[key];
                // btDispatch(removeDevicesConnected({ key: key, data: device }));
                // btDispatch(setDeviceConnectedCount(Object.keys(deviceCache).length));
                // btDispatch(setDeviceConnectedLoader(false));
                // btDisconnect();
            });
    } catch (e) {
        console.log(e);
        if (e.message.includes('GATT Server is disconnected')) {
            connectDeviceAndCacheCharacteristic(device);
        }
        // return e;
    }
};

// Получение данных
const btCharacteristicValueChanged =
    ({ target }) =>
    async (dispatch, getState) => {
        // const {
        //     service: { device },
        // } = target;

        try {
            // btDispatch(setConnectionLoss(false));
            const str = textDecoder.decode(target.value);
            respBuffer += str;
            if (str.includes('\n')) {
                const arr = respBuffer.split('\r\n').filter((e) => e);
                // console.log('');
                // console.log(respBuffer);
                // console.log(1, arr);
                dispatch(sendSensorResponse(arr));
                respBuffer = str.substring(str.lastIndexOf('\n') + 1);
            }

            // parceBTData(value);
        } catch (e) {
            console.log('Bluetooth expectation error', e);
        }
    };

// Обработчик разъединения
const handleDisconnection = (event) => {
    let device = event.target;
    // console.log(device);
    // if (device.gatt.connected) {
    // toast.warn('Соединение потеряно с '+device.name+'. Переподключите устройство');
    try {
        removeDisconnectedDevice(device);
    } catch (e) {
        console.log('handleDisconnection', e);
    }
    // }
};

export const removeDisconnectedDevice = (device) => {
    // console.log('removeDisconnectedDevice', device);
    if (device) {
        const key = device.id;
        // const deviceLenght = Object.keys(deviceCache).length

        if (key) {
            btDispatch(setPortWriter());
            // btDispatch(removeDevicesConnected(key));

            // Object.keys(characteristicRead).forEach((removed) => {
            //     characteristicRead[removed]?.removeEventListener('characteristicvaluechanged', btCharacteristicValueChanged);
            //     if (key !== removed) {
            //         // btStartNotifications(characteristicRead[removed]);
            //         characteristicRead[removed].addEventListener('characteristicvaluechanged', btCharacteristicValueChanged);
            //     }
            // });

            delete characteristicRead[key];
            delete deviceCache[key];
            delete devicesValues[key];
            delete serverCache[key];
            delete serviceCache[key];
            delete characteristicWrite[key];

            btDispatch(setPortOpen(characteristicRead));
            // btDispatch(setDeviceConnected(deviceCache));
            // btDispatch(setDeviceConnectedCount(Object.keys(deviceCache).length));
            device.gatt.connected && device.gatt.disconnect();
        }

        // btDispatch(setDeviceConnectedLoader(false));
        if (!Object.keys(deviceCache).length) {
            // btDispatch(togglePort(false));
            btDispatch(setConnectionType(null));
            // btDispatch(setDeviceConnectedCount(0));
            btDispatch(setPortReader({}));
            // btDispatch(resetReduxState());
        }
    }
};
