import { setSensorList, setArtPressure } from '../../actions/sensor';
import { serialPortWrite } from '../../commands/port';
import { sendCommand } from '../../actions/port';
import { setChartXRanges } from '../../actions/chart';
import { setDevicesNames, setDevicesConnected } from '../../actions/device';

export const mapStateToProps = (nstate) => {
    return {
        played: nstate.napp.played,
        // paused: nstate.napp.paused,
        response: nstate.nsensor.response,
        filteredSensor: nstate.nsensor.filteredSensor,
        // timer: nstate.ntimer.timer,
        command: nstate.nport.command,
        writer: nstate.nport.writer,
        port: nstate.nport.open,
        devices: nstate.ndevice.all,
        devicesNames: nstate.ndevice.names,
        connected: nstate.ndevice.connected,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setChartXRanges: (obj) => dispatch(setChartXRanges(obj)),
    // setSensorValues: (obj) => dispatch(setSensorValues(obj)),
    sendCommand: (str) => dispatch(sendCommand(str)),
    serialPortWrite: (str) => dispatch(serialPortWrite(str)),
    setDevicesNames: (list) => dispatch(setDevicesNames(list)),
    setDevicesConnected: (list) => dispatch(setDevicesConnected(list)),
    setSensorList: (list) => dispatch(setSensorList(list)),
    setArtPressure: (bool) => dispatch(setArtPressure(bool)),
    // setSensorIntervals: (arr) => dispatch(setSensorIntervals(arr)),
});
