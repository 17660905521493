export const listStateToProps = (state) => {
    return {
        forceUpdate: state.actions.forceUpdate,
        sensorList: state.sensors.list,
        sensorsBundles: state.sensors.bundles,
        sensorImported: state.sensors.imported,
        sorting: state.sensors.sorting,
        sensorWrited: state.sensors.writed,
        sensorRecords: state.sensors.records,
        isOscilloscope: state.oscilloscope.isOscilloscope,
        chartDisabled: state.chart.disabled,
    };
};

export const listDispatchToProps = (dispatch) => ({});
