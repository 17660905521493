import React from 'react';
import "./Warning.scss";
import Icon from "../Icon";

const Warning = (props) => {
    const {
        children,
        className = "",
    } = props;

    return (
        <div 
            className={"cor-warning " + className}
            >
            <Icon name="warning"/>
            <p>{children}</p>
        </div>
    )
};
export default Warning;
