import React, { useState } from 'react';
import { Icon } from '../../ui';
import "./Fullscreen.scss";

const Fullscreen = () => {
    const [fullscreen, setFullscreen] = useState(false);

    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            setFullscreen(true);
            document.documentElement.requestFullscreen();
        } else {
            if (document.exitFullscreen) {
                setFullscreen(false);
                document.exitFullscreen();
            }
        }
    };

    return (
        <div className={fullscreen ? "cor_fullscreen active tp tp_down tp_left" : "cor_fullscreen tp tp_down tp_left"}
            onClick={toggleFullScreen}
            data-tp={fullscreen ? "Стандартный экран" : "Во весь экран"}
            >
            <Icon name="full-screen" />
        </div>
    )
};
export default Fullscreen;
