import React, {useState} from "react";
import {getDescriptionEditor, calcDecriptionSize} from "../ui/utils/gen_utils";
import ss from './OneOption.module.scss';
import { Icon } from "../ui";

const OneOption = ({itemIndex, title, description, handleDescription, handleDeleteDescription, descLimit}) => {
    const [descriptionCounter, setDescriptionCounter] = useState(0);

    const handleDescriptionChange = v => {
        const counter = calcDecriptionSize(v);
        //if (counter > descLimit) return;
        handleDescription(v, itemIndex);
        setDescriptionCounter(counter);
    };

    return (
        <div className={ss.root}>
            <div className={ss.label}>
                <span>
                    {title}
                    {handleDeleteDescription !== null &&
                    <Icon 
                        className={ss.trash} 
                        name="trash" 
                        onClick={() => handleDeleteDescription(itemIndex)} 
                        key={'itm'+(itemIndex+1)} 
                    />
                    }
                </span>
                {/* <span>{descriptionCounter}/{descLimit}</span> */}
            </div>
            <div className={ss.subEditor}>
                {getDescriptionEditor(description, handleDescriptionChange, false, itemIndex, descLimit)}
            </div>

        </div>
    );
}

export default OneOption; 