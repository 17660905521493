import {loadFiles} from "../../redux/slices/lists";
import {Icon, Warning, InputLimit} from '../ui';
import {Checkbox} from 'rlabui';
import {onlineTestApi, onlineTestInfoApi} from "../../api/api";
import {getDropdown, uploadFile, doDeleteFileByName, getFiles} from '../ui/utils/gen_utils';
import {toast} from "react-toastify";
import OneOption from "./OneOption";
import htmlParser from "html-react-parser";
import "../Grids/Grid.scss";
import ss from './OnlineTestCreateQuestions.module.scss';

export const UNDEFINED_QUESTION_TYPE = -1;
export const QUESTION_TYPES = {
	AUDIO: 0,
	CORRECT_WORD_SEQUENCE: 1,
	CORRECT_ANSWER_ONE: 2,
	CORRECT_ANSWER_MANY: 3,
	CORRECT_IMAGE_SEQUENCE: 4,
	NON_VERBAL: 5,
	COMPLETE_SENTENCE: 6,
	FREE_WRITING: 7,
	VIDEO: 8
};

export const FORMAT_TYPE = {
	IMAGE: 1,
    VIDEO: 2, 
    AUDIO: 3
};

export const STATUS = {
    INIT: 0,
    FILL: 1,
    USE: 2
};

export const OT_LIST = 1;
export const OT_RESULTS = 2;
export const OT_TAB_STATE = [
    {status: OT_LIST, name: 'Тесты', path: '/practicum/ot'},
    {status: OT_RESULTS, name: 'История тестов', path: '/reviewtest/ot'},
];

export const MAX_ANSWER_LENGTH = 75;
export const MAX_DESC_LENGTH = 1000;

const ONLINETEST_PREFIX = 'OnlineTests%2F';
export const ONLINETEST_SLASH = 'OnlineTests/';

export const QUESTIONS = [
    {name: 'Звуковой', id: QUESTION_TYPES.AUDIO},
    {name: 'Восстановление последовательности', id: QUESTION_TYPES.CORRECT_WORD_SEQUENCE},
    {name: 'С одним правильным ответом', id: QUESTION_TYPES.CORRECT_ANSWER_ONE},
    {name: 'С несколькими правильными ответами', id: QUESTION_TYPES.CORRECT_ANSWER_MANY},
    {name: 'Перетаскивание элементов и рисунков', id: QUESTION_TYPES.CORRECT_IMAGE_SEQUENCE},
    {name: 'Невербальные, без словесного описания (картинки, слайды, схемы)', id: QUESTION_TYPES.NON_VERBAL},
    {name: 'На завершение предложений', id: QUESTION_TYPES.COMPLETE_SENTENCE},
    {name: 'Свободное изложение', id: QUESTION_TYPES.FREE_WRITING},
    {name: 'Видео', id: QUESTION_TYPES.VIDEO},
];

export const clearAllQuestionAttachments = async (question, _otId, _dispatch) => {
    if (question.illustration) {
        const illustration = JSON.parse(question.illustration);
        deleteOTFileByName(_dispatch, illustration.name);
    }

    if (isQuestionWithVideo(question)) {
        const videoFileName = question.correctOptions.split('|')[1];
        deleteOTFileByName(_dispatch, videoFileName);
    } else if (isQuestionWithAttachments(question)) {
        const attachmentList = question.answerOptions;
        for (let i = 0; i < attachmentList.length; i ++) {
            await deleteOTFileByName(_dispatch, attachmentList[i]);
        }
    }
};

export const getQuestionTypes = (qtState) => {
    const defState = '111111111';
    const states = !!qtState ? qtState.split('').map(item => item === '1') : '';
    const defStates = defState.split('').map(item => item === '1');
    return QUESTIONS.map((item, ind) => ({name: item.name, id: item.id, value: !!states ? states[ind] : defStates[ind]}));
};

export const getMaxOfArray = numArray => Math.max.apply(null, numArray);

export const getOptionFieldList = (list, handleChangeAnswer, handleDeleteOption, handleAddOption, isEditable) => {
    return (
        <div className="cor-net__section border mt_lg">
            <div className="cor-net__title">Варианты ответа</div>
            <>
                {list.map((item, ind) => (
                    <div className="cor-net__row" key={200 * (ind+1)}>
                        <div className="cor-net__col col-2">
                            <div className="cor-net__label" key={ind+1}>Вариант {ind+1}</div>
                            <InputLimit 
                                id={ind} 
                                value={item.name} 
                                key={-ind-1} 
                                disabled={!isEditable}
                                onInput={e => handleChangeAnswer(e.target.value, ind)} 
                                placeholder={'Введите ответ'}
                                max={MAX_ANSWER_LENGTH}
                            />
                        </div>

                        {isEditable && 
                        <div className="cor-net__col">
                            <div className="cor-net__label hide"></div>
                            <Icon className={ss.trash} name="trash" onClick={() => handleDeleteOption(ind)} key={300 * (ind+1)} />
                        </div>}              
                    </div>
                ))}
            </>

            {isEditable && 
            <div className="cor-net__row">
                <div onClick={handleAddOption} className={ss.addOption}>
                    <Icon name="plus"/>
                    Добавить вариант
                </div>
            </div>}
        </div>
    );
};

export const getOneCorrectAnswer = (list, opt, setOpt, isEditable) => {
    const getList = list => {
        const _list = [];
        for (let i = 0; i < list.length; i ++)
            _list.push({label: 'Вариант ' + (i+1), value: '' + i});
        return _list;
    };
    const optList = getList(list);

    return (
        <div className="cor-net__section border">
            <div className="cor-net__title">Правильный ответ</div>
            <div className="cor-net__row">
                <div className="cor-net__col col-6">
                    {
                    isEditable ? 
                    getDropdown(optList, opt, setOpt, '', '', 'opts01', 'top')
                    : 
                    optList.length > 0 ? optList[Number(opt)].label : <></>
                    }
                </div>
            </div>
        </div>
    );
};

export const getMultipleCorrectAnswers = (list, handleCorrectAnswers) => {
    return (
        <div className="cor-net__section border" key={'mcs0'}>
            <div className="cor-net__title" key={'mcs1'}>Правильные ответы</div>
            <div className="cor-net__row" key={'mcs2'}>
                {list.map((item, ind) => 
                    <div key={'mcs3'+ind}>
                        <Checkbox
                            className={ss.checkbox}
                            label={"Вариант" + (ind + 1)} 
                            checked={item.correct ? "checked" : ""} 
                            onChange={() => handleCorrectAnswers(ind) }
                        />
                        {/* <span key={'sp' + ind} className="otCreate__standardPadRight otCreate__nowrap otCreate__standardPadVert">
                            <input 
                                key={'mcs4'+ind}
                                type="checkbox" 
                                key={'cb'+ind} 
                                id={ind} 
                                checked={item.correct ? "checked" : ""} onChange={() => handleCorrectAnswers(ind) } />
                                &nbsp;Вариант {ind + 1}
                        </span> */}
                    </div>
                )}
            </div>
        </div>
    );
};

export const isQuestionWithAttachments = question => {
    const qt = Number(question.questionType);
    return (qt === QUESTION_TYPES.AUDIO || qt === QUESTION_TYPES.CORRECT_IMAGE_SEQUENCE ||
        qt === QUESTION_TYPES.NON_VERBAL || qt === QUESTION_TYPES.VIDEO);
};

export const isQuestionWithVideo = question => Number(question.questionType) === QUESTION_TYPES.VIDEO;

export const uploadOTFile = (_files, file, setLoadedFileName) => {
    const setUploadProgress = () => {};
    uploadFile(ONLINETEST_PREFIX, _files, file, setLoadedFileName, setUploadProgress);
}
export const deleteOTFileByName = async (dispatch, fileName) => {
    //console.log('deleted file: ', fileName);
    doDeleteFileByName(ONLINETEST_PREFIX, dispatch, fileName);
}; 
export const loadOTFiles = dispatch => {
    dispatch(loadFiles(ONLINETEST_PREFIX));
}

export const getExistingRefs = (answers, _files) => {
    let removedIndeces = [];

    for (let k = 0; k < answers.length; k ++) {
        const answerFileName = answers[k].name;
        let found = false;

        for (let i = 0; i < _files.length; i ++) 
            if (answerFileName === _files[i].Key.split('/')[1]) 
                found = true;

        if (!found)
            removedIndeces.unshift(k);
    }

    let cleanedAnswers =  [...answers];
    if (removedIndeces.length > 0) { //Exclude refs to lost files
        for (let i = 0; i < removedIndeces.length; i ++)
            cleanedAnswers = cleanedAnswers.filter((map, ind) => ind !== removedIndeces[i]);
    } 

    return cleanedAnswers;
};

export const getContentByFile = (isTemplate, f) => {
    if (!isTemplate) {
        return f.Url;
    } else {
        const fileBody = JSON.parse(f.name);
        return fileBody.content;
    }
};

export const getNonVerbalOptionFieldList = (isTemplate, _files, _lostRefNumber, 
        _answerList, _setAnswerList, _minAnswerNum, 
        _dispatch, _acceptableFileFormat, 
        _handleAddImageAnswerOption, _handleDeleteImageAnswerOption, _handleShowPicture) => {
    const foundFiles = !isTemplate ? getFiles(_files, _answerList) : _answerList;

    return (
        <div className="cor-net__section border mt_lg">
            <div className="cor-net__title">Варианты ответа (вопрос должен содержать {_minAnswerNum} и более вариантов)</div>
            
            {_lostRefNumber > 0 && 
            <div className="cor-net__row">
                <div className="cor-net__col col-3">
                    <Warning>Добавьте еще {_lostRefNumber === 1 ? '1 вариант': _lostRefNumber + ' варианта'}</Warning>
                </div>
            </div>}

            <div className="cor-net__row">
                <div className={ss.grid}  key='grid01'>
                    {foundFiles.map((f, i) => (
                        <div className={ss.grid__item} key={'cnt01'+i}>
                            <div className={ss.grid__label} key={'top01'+i}>
                                <p key={'top02'+i}>Вариант {i+1}</p>
                                <Icon 
                                    name="trash"
                                    onClick={() => _handleDeleteImageAnswerOption( 
                                    _answerList, _setAnswerList, _dispatch, i)} 
                                    key={'top03'+i} 
                                />
                            </div>

                            <div className={ss.grid__img} key={'mid01'+i}>
                                <img 
                                    src={getContentByFile(isTemplate, f)} 
                                    alt='' 
                                    key={'mid02'+i} 
                                    onClick={() => _handleShowPicture(f)} 
                                />
                                <div className={ss.grid__fullScreen}>
                                    <Icon name="full-screen" />
                                </div>
                            </div>
                        
                        </div>
                    ))}
                </div>
            </div>

            <div className="cor-net__row" key='cnt3'>
                <label className={ss.addOption} key='lbl01'>
                    <Icon name="plus" key='lbl02' />Добавить вариант
                    <input type="file" accept={_acceptableFileFormat} 
                        onChange={(e) => _handleAddImageAnswerOption(e, _files, e.target.files[0])} 
                        key='lbl03' 
                    />
                </label>
            </div>

        </div>
    );
};

export const getDurationList = () => {
    const arr = [];
    for (let i = 1; i < 37; i ++) 
        arr.push({label: '' + (5 * i) + ' минут', value: (5 * i).toString()});
    return arr;
};

export const getYesNoOpts = () => {
    const list = [];
    list.push({label: 'Да', value: 'yes'});
    list.push({label: 'Нет', value: 'no'});
    return list;
};

export const getListElementNameById = (list, id) => {
    const elem = list.find(item => item.value.toString() === id?.toString());
    return elem?.label;
};
  
export const getNonCheckAnswerList = (_onlineTest, _onlineTestStudentInfo) => {
    if (!_onlineTest) return [];
    const nonCheckedAnswers = [];
    
    for (let i = 0; i < _onlineTest.questions.length; i ++) {
        const quesiton = _onlineTest.questions[i];
        if (Number(quesiton.questionType) === QUESTION_TYPES.FREE_WRITING) 
            if (i < _onlineTestStudentInfo.answers.length && _onlineTestStudentInfo.answers[i].isCorrect === -1)
                nonCheckedAnswers.push(i); //save indexes of unchecked free write answers
    }
    return nonCheckedAnswers;
};

export const isAnswersChanged = (existAnswers, newAnswers) => {
    let changed = false;
    if (newAnswers.length !== existAnswers.length)
        changed = true;
    else {
        for (let i = 0; i < newAnswers.length && !changed; i ++)
            if (newAnswers[i].name !== existAnswers[i].name || newAnswers[i].correct !== existAnswers[i].correct)
                changed = true;
    }
    return changed;
};

export const unpackAutoEstimatesAsDoubleArray = autoEstimates => {
    return autoEstimates.split('|').map(item => item.split('/'));
};

export const packAutoEstimateArrayAsString = list => {
    return list.map(item => item[0] + '/' + item[1]).join('|');
};

export const addQuestion = (questionList, question, ind) => {
    let questions = [];
    if (questionList.length === 0) {
        questions.push(question);
    } else if (ind === questionList.length - 1) {
        questions = questionList.map(item => item);
        questions.push(question);
    } else {
        for (let i = 0; i < questionList.length; i ++) {
            questions.push(questionList[i]);
            if (i === ind) questions.push(question);
        }
    }
    return questions;
};

export const replaceQuestion = (questionList, question, index) => {
    const questions = questionList.map((item, ind) => ind === Number(index) ? question : item);
    return questions;
};

export const getQuestionTypeList = (isTemplate, qTypes) => {
    const list = []; 
    list.push({label: 'Выберите тип вопроса', value: UNDEFINED_QUESTION_TYPE});
    for (let i = 0; i < qTypes.length; i ++) {
        const q = qTypes[i];
        if (q.id !== QUESTION_TYPES.VIDEO || !isTemplate)
            list.push({label: q.name, value: q.id});
    }
    return list;
};

export const hasNoErrors = (__isNewQuestion, _questionName, _currQuestionType, _dataError) => {
    const errMsg = getErrorMsg(__isNewQuestion, _questionName, _currQuestionType, _dataError);
    if (!errMsg) return true;
    toast.warn(errMsg);
    return false;
};

export const getErrorMsg = (_isNewQuestion, _questionName, _currQuestionType, _dataError) => {
    let commonErr = '';
    if (!_questionName)
        commonErr = 'Введите вопрос.';
    else if (_isNewQuestion && _currQuestionType === UNDEFINED_QUESTION_TYPE) 
        commonErr = 'Выберите тип вопроса.';

    if (!commonErr && !_dataError) return '';
    return commonErr ? commonErr : _dataError;
};

export const getCurrentQuestion = (questionList, isNewQuestion, ind) => {
    if (questionList.length > 0 && !isNewQuestion)
        return questionList[ind];
    return undefined;
};

export const getOneOption = (itemIndex, title, description, handleDescription, 
        handleDeleteDescription, descLimit) => {
    return (
        <OneOption 
            itemIndex={itemIndex}
            title={title}
            description={description}
            handleDescription={handleDescription} 
            handleDeleteDescription={handleDeleteDescription}
            descLimit={descLimit}
    />
    );
};

export const getTextEditorOptionFieldList = (list, _handleChangeAnswer, handleDeleteOption, 
        handleAddOption, isEditable) => {
    return (
        <div className="cor-net__section mt_lg border">
            <div className="cor-net__title">Варианты ответа</div>
            <>
                {list.map((item, ind) => (
                    <div className="cor-net__row" key={'ca'+(ind+1)}>
                        <div className="cor-net__col col-2" key={'ca2'+(ind+1)}>
                            {isEditable ? 
                                getOneOption(ind, 'Вариант ' + (ind+1), item.name, 
                                    _handleChangeAnswer, handleDeleteOption, MAX_DESC_LENGTH)
                            :
                                htmlParser(item.name)
                            }
                        </div>
                    </div>
                ))}
            </>

            {isEditable && 
            <div className="cor-net__row">
                <div onClick={handleAddOption} className={ss.addOption}>
                    <Icon name="plus"/>
                    Добавить вариант
                </div>
            </div>}
        </div>
    );
};

///// --- onlineTestApi ---------------------------------------------------------------------------
export const getOnlineTestById = async (onlineTestId) => {
    return await onlineTestApi.getOnlineTestById(onlineTestId)
        .then (res => res)
        .catch(err => console.log('err=', err));
}

///// --- onlineTestInfoApi ---------------------------------------------------------------------------
export const getOnlineTestInfoByRunId = async (owner, otRunId) => {
    return await onlineTestInfoApi.getOnlineTestInfoByRunId(owner, otRunId)
        .then (res => res.data)
        .catch(err => {console.log('err=', err);}
    );
};

export const updateOnlineTestInfoById = async (id, onlineTestData) => {
    const result = await onlineTestInfoApi.updateOnlineTestInfoById(id, onlineTestData)
        .then (res => res)
        .catch(err => console.log('err=', err));
    return result;
}

export const updateOnlineTestInfoScoreById = async (id, score) => {
    const result = await onlineTestInfoApi.updateOnlineTestInfoScoreById(id, {score: score})
        .then (res => res)
        .catch(err => console.log('err=', err));
    return result;
}

export const isDisabled = isEditable => !isEditable ? 'disabled' : '';
