import React from 'react';
import { connect } from 'react-redux';
import { setCommentImageModal } from '../../actions/chart';
import { Modal } from 'rlabui';

const ChartCommentImageModal = ({ src, setCommentImageModal }) => {
    return (
        <Modal
            visible={src}
            size="fullscreen"
        >
            <Modal.Head modalClose={() => setCommentImageModal()}></Modal.Head>
            <Modal.Body className="chartComment__fullcover">
                <img
                    src={src}
                    alt=""
                />
            </Modal.Body>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
        src: state.chart.commentImageModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCommentImageModal: (src) => dispatch(setCommentImageModal(src)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ChartCommentImageModal);
