import React, { useCallback, useEffect, useRef, useState } from 'react';
import parse from 'html-react-parser';
import steps from './steps.json';
import { Button, Modal } from 'rlabui';
import ss from './DemoLearn.module.scss';

const DemoLearn = ({ setDemoStep, setDemoLearn, demo, learn, modal, step, view, port }) => {
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [pan, setPan] = useState(false);
    const [stopModal, setStopModal] = useState(false);
    const [resized, setResized] = useState(false);
    const boxRef = useRef();
    const arrowRef = useRef();

    const updatePosition = useCallback(() => {
        if (!demo.length && !port) return;
        // console.log('updatePosition');

        let target = document.querySelectorAll('[data-learn="' + step + '"]')[0];
        let border = document.querySelectorAll('[data-learn-border="' + step + '"]')[0];
        const root = document.querySelector('#root');
        const allBorders = document.querySelectorAll('[data-learn-border]');
        const all = document.querySelectorAll('[data-learn]');
        const box = boxRef.current;
        const arrow = arrowRef.current;

        resetBorders();

        !border &&
            allBorders.forEach((el) => {
                const dataStep = el.dataset.learnBorder.split(',').map(Number);
                if (dataStep.length > 1 && dataStep.find((e) => e === step) && !border) {
                    border = el;
                }
            });

        !target &&
            all.forEach((el) => {
                const dataStep = el.dataset.learn.split(',').map(Number);
                if (dataStep.length > 1 && dataStep.find((e) => e === step) && !target) {
                    target = el;
                }
            });

        if (target && box && arrow) {
            const { learnPosition } = target.dataset;
            const { top, left, height, width } = target.getBoundingClientRect();
            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;
            const arrowWidth = arrow.offsetWidth;
            const boxWidth = box.offsetWidth;
            const boxHeight = box.offsetHeight;
            let offsetLeft = 0;
            let offsetTop = 0;
            let arrowPos = 0;

            border.style.outline = '1px solid #00B5FF';
            border.style.boxShadow = '0em 0em 1.5625em 0em #00B4FDBF';
            border.style.zIndex = 1;

            arrow.className = ss.arrow;
            arrow.className = ss.arrow + ' ' + learnPosition;

            switch (learnPosition) {
                case 'top':
                    offsetLeft = screenWidth - (left + boxWidth / 2);
                    if (offsetLeft < 0) {
                        arrowPos = boxWidth / 2 - offsetLeft + arrowWidth / 2;
                        console.log(arrowPos);

                        arrow.style.left = arrowPos + 'px';
                        box.style.left = left - boxWidth / 2 + offsetLeft - arrowWidth + 'px';
                    } else {
                        box.style.left = left + width / 2 - boxWidth / 2 + 'px';
                    }
                    box.style.top = top - boxHeight - arrowWidth + 'px';
                    break;

                case 'right':
                    box.style.left = left + width + arrowWidth / 1.3 + 'px';
                    box.style.top = top + height / 2 - boxHeight / 2 + 'px';
                    break;

                case 'bottom':
                    offsetLeft = screenWidth - (left + boxWidth / 2);
                    // arrowPos = boxWidth - arrowWidth - 10 < arrowWidth ? boxWidth / 2 + width / 2 + arrowWidth / 2 - offsetLeft : boxWidth - arrowWidth - 10;
                    arrowPos = boxWidth / 2 + width / 2 + arrowWidth / 2 - offsetLeft - 6;

                    if (arrowPos > boxWidth - arrowWidth - 15) {
                        arrowPos = boxWidth - arrowWidth - 10;
                    }

                    if (offsetLeft < 0) {
                        arrow.style.left = arrowPos + 'px';
                        box.style.left = left - boxWidth / 2 + offsetLeft - 10 + 'px';
                    } else {
                        box.style.left = left + width / 2 - boxWidth / 2 + 'px';
                    }
                    box.style.top = top + height + arrowWidth + 'px';

                    break;

                case 'left':
                    offsetTop = screenHeight - (top + boxHeight / 2);

                    if (offsetTop < 0) {
                        arrow.style.top = boxHeight - arrowWidth - height / 2 + 'px';
                        box.style.top = screenHeight - boxHeight - arrowWidth + 'px';
                    } else {
                        box.style.top = top + height / 2 - boxHeight / 2 + 'px';
                    }
                    box.style.left = left - boxWidth - arrowWidth + 'px';
                    break;

                case 'center':
                    box.style.left = left + width / 2 - boxWidth / 2 + 'px';
                    box.style.top = top + height / 2 - boxHeight / 2 + 'px';
                    break;

                default:
                    box.style.left = left + width / 2 - window.innerWidth / 2 + 'px';
                    box.style.top = top + height / 2 - window.innerHeight / 2 + 'px';
                    break;
            }
        }
        setResized(false);
    }, [step, demo]);

    useEffect(() => {
        let observer;
        if (learn) {
            const root = document.querySelector('#root');
            window.removeEventListener('resize', updatePosition);
            observer?.disconnect();
            window.addEventListener('resize', updatePosition);
            observer = new MutationObserver((e) => {
                // console.log(step, e);
                setTimeout(() => {
                    updatePosition();
                }, 3);
            });
            observer.observe(root, { attributes: true });
        } else {
            observer?.disconnect();
            window.removeEventListener('resize', updatePosition);
        }
    }, [learn, step]);

    useEffect(() => {
        if (learn) {
            //     setDemoStep(0);
            // } else {
            !modal && updatePosition();
        }
    }, [learn, modal, setDemoStep, resized, view]);

    useEffect(() => {
        if (step >= 0 && learn) {
            let allSteps = document.querySelectorAll('[data-learn]');
            let detectStep = [...allSteps].find((e) => e.dataset?.learn?.split(',').find((s) => +s === step));

            if (detectStep) {
                const { title, text } = steps[step];
                setTitle(title);
                setText(text);
                setTimeout(() => {
                    updatePosition();
                }, 3);
            } else {
                setDemoStep(step + 1);
            }
        }
    }, [step, learn]);

    const resetBorders = useCallback(() => {
        document.querySelectorAll('[data-learn-border]').forEach((el) => {
            el.style.boxShadow = '';
            el.style.outline = '';
            el.style.zIndex = '';
            if (arrowRef?.current) {
                arrowRef.current.style.left = '';
                arrowRef.current.style.top = '';
            }
        });
    }, []);

    useEffect(() => {
        if (!learn) {
            resetBorders();
            // setDemoStep(0);
        }
    }, [learn, resetBorders, setDemoStep]);

    function dragPlate(e) {
        const box = boxRef.current;
        if (pan && box && e.target.nodeName.toLowerCase() !== 'button') {
            box.style.left = e.pageX - box.offsetWidth / 2 + 'px';
            box.style.top = e.pageY - box.offsetHeight / 2 + 'px';
        }
    }

    const handleStopLearning = () => {
        setStopModal(false);
        setDemoLearn(false);
        setDemoStep(0);
    };

    return (
        demo?.length > 0 &&
        learn &&
        !modal && (
            <>
                <div
                    className={ss.root}
                    ref={boxRef}
                    onMouseDown={() => setPan(true)}
                    onMouseUp={() => setPan(false)}
                    onMouseMove={dragPlate}
                >
                    <div
                        className={ss.arrow}
                        ref={arrowRef}
                    ></div>
                    <div
                        className={ss.close}
                        onClick={() => setStopModal(true)}
                    ></div>
                    <div className={ss.body}>
                        <div className={ss.title}>{title}</div>
                        <div className={ss.text}>{parse('' + text)}</div>
                    </div>
                    <div className={ss.foot}>
                        <span>
                            {step > 0 && <Button onClick={() => setDemoStep(step - 1)}>Назад</Button>}{' '}
                            {steps.length === step + 1 ? (
                                <Button
                                    color="danger"
                                    onClick={() => setStopModal(true)}
                                >
                                    Закончить
                                </Button>
                            ) : (
                                <Button
                                    border={true}
                                    onClick={() => setDemoStep(step + 1)}
                                >
                                    Далее
                                </Button>
                            )}
                        </span>
                        <div className={ss.steps}>
                            Шаг {step + 1} <span>из {steps.length}</span>
                        </div>
                    </div>
                </div>
                <Modal
                    visible={stopModal}
                    size="xs"
                >
                    <Modal.Head
                        title="Завершить обучение?"
                        modalClose={() => setStopModal(false)}
                    />
                    <Modal.Footer>
                        <Button
                            border={true}
                            onClick={handleStopLearning}
                        >
                            Завершить
                        </Button>
                        <Button onClick={() => setStopModal(false)}>Отмена</Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    );
};
export default DemoLearn;
