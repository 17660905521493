import { setOscilloscopeChartSettings } from '../../actions/oscilloscope';
import { sendCommand } from '../../actions/port';
import { setForceUpdate } from '../../actions/actions';

export const channelStateToProps = (state) => {
    return {
        defaultChartSettings: state.oscilloscope.defaultChartSettings,
        chartSettings: state.oscilloscope.chartSettings,
        oscilloscopeSettings: state.oscilloscope.oscilloscopeSettings,
        isOscilloscope: state.oscilloscope.isOscilloscope,

        sensorList: state.sensors.list,
    };
};

export const channelDispatchToProps = (dispatch) => ({
    setOscilloscopeChartSettings: (obj) => dispatch(setOscilloscopeChartSettings(obj)),
    sendCommand: (bool) => dispatch(sendCommand(bool)),
    setForceUpdate: (bool) => dispatch(setForceUpdate(bool)),
});
