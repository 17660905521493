import React from 'react';

import ss from './Back.module.scss';
import { Icon } from '../../../ui';

const Back = (props) => {
    const {
        type = "button",
        icon = "arrow-prev",
        disabled = false,
        onClick = null,
        text = "Назад",
        margin = '',
     
    } = props;

    let btnClass = ss.root;
    if (margin) {
        switch (margin) {
            case 'top':
                btnClass += ' ' + ss.mt;
                break;

            case 'bottom':
                btnClass += ' ' + ss.mb;
                break;

            default:
                break;
        }
    }


    return (
     
        <button
            className={btnClass}
            onClick={onClick}
            type={type}
            disabled={disabled}
        >
            <Icon name={icon} />
            <p>{text}</p>
        </button>
    )
};
export default Back;

