import React, { useEffect, useState } from 'react';
import { Watch } from 'react-loader-spinner';
import { Icon } from '../ui';
import ss from './PlayPause.module.scss';

const PlayPause = (props) => {
    // console.log('PlayPause props ===', props);
    const { port, resetSensorData, played, paused, setPlay, setPaused, sendCommand, writeStatus } = props;
    const [startPlay, setStartPlay] = useState(false);

    useEffect(() => {
        !port && setPlay(false);
    }, [port, setPlay]);

    useEffect(() => {
        if (startPlay) {
            resetSensorData();
            setTimeout(() => {
                sendCommand('acq 0');
                setPlay(true);
                setStartPlay(false);
            }, 10);
        } else {
            sendCommand('acq 1');
        }
    }, [startPlay, setPlay, resetSensorData, sendCommand]);

    return (
        <div className={ss.root}>
            <Icon
                tag="button"
                name="stop"
                title="Стоп"
                disabled={!port || !played}
                onClick={() => {
                    setPlay(false);
                    setPaused(false);
                    setStartPlay(false);
                    sendCommand('acq 0');
                }}
            />
            <button
                className={paused ? ss.btn + ' ' + ss.btnActive : ss.btn}
                title="Пуск/пауза"
                type="button"
                disabled={!port || writeStatus}
                onClick={() => {
                    if (played && !paused) {
                        setPaused(true);
                    } else {
                        setPaused(false);
                        !played && setStartPlay(true);
                    }
                }}
            >
                <Icon name={played && !paused ? 'pause' : 'play'} />
                {startPlay && (
                    <div className={ss.loader}>
                        <Watch
                            height="36"
                            width="36"
                            radius="40"
                            color="#b8bccc"
                            ariaLabel=""
                            visible={true}
                        />
                    </div>
                )}
            </button>
        </div>
    );
};

export default PlayPause;
