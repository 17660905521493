import { getRelativePositionLeft, getRelativePositionTop } from '../utils';
import { drawRange } from './annotationsPlugin';

export const wheelZoomPlugin = (props) => {
    const { factor = 0.9, setXRanges, setYMin, setYMax } = props;

    // let xUMin, xUMax, yUMin, yUMax, xRange, yRange;
    let xMin, xMax, yMin, yMax, xRange, yRange;

    function clamp(nRange, nMin, nMax, fRange, fMin, fMax) {
        if (nRange > fRange) {
            nMin = fMin;
            nMax = fMax;
        } else if (nMin < fMin) {
            nMin = fMin;
            nMax = fMin + nRange;
        } else if (nMax > fMax) {
            nMax = fMax;
            nMin = fMax - nRange;
        }

        // console.log(nMin, nMax);
        return [nMin, nMax];
    }

    return {
        hooks: {
            ready: (u) => {
                const { key, isChildren, over, root } = u;
                const axes = root.querySelectorAll('.u-axis');

                axes.forEach((axe, i) => {
                    if (i === axes.length - 1) {
                        axe.classList.add('y-axis');
                    } else {
                        axe.classList.add('x-axis');
                    }
                });

                const { played, paused } = u.actions;

                // Масштабирование колесом мыши
                if (!played || paused) {
                    root?.addEventListener('wheel', (e) => wheel(e, u));
                } else {
                    root?.removeEventListener('wheel', wheel);
                }

                function wheel(e, u) {
                    e.preventDefault();
                    let rect = over.getBoundingClientRect();
                    const { target } = e;

                    const {
                        zoomPan: { xHandle, yHandle },
                    } = u;

                    if (!xHandle && !yHandle) return;

                    const x = target.classList.contains('x-axis');
                    const y = target.classList.contains('y-axis');
                    const area = target.classList.contains('u-over') || target.classList.contains('u-range-x') || target.classList.contains('u-marker-x');

                    let left = getRelativePositionLeft(u, e);
                    let top = getRelativePositionTop(u, e);
                    let xZoomEnabled = (x || area) && xHandle && !isChildren;
                    let yZoomEnabled = (y || area) && yHandle;

                    let leftPct = left / rect.width;
                    let btmPct = 1 - top / rect.height;
                    let xVal = u.posToVal(left, 'x');
                    let yVal = u.posToVal(top, 'y');
                    let oxRange = u.scales.x.max - u.scales.x.min;
                    let oyRange = u.scales.y.max - u.scales.y.min;

                    let nxRange = e.deltaY < 0 ? oxRange * factor : oxRange / factor;
                    let nxMin = xZoomEnabled ? xVal - leftPct * nxRange : u.scales.x.min;
                    let nxMax = xZoomEnabled ? nxMin + nxRange : u.scales.x.max;
                    [nxMin, nxMax] = clamp(nxRange, nxMin, nxMax, xRange, xMin, xMax);

                    let nyRange = e.deltaY < 0 ? oyRange * factor : oyRange / factor;
                    let nyMin = yZoomEnabled ? yVal - btmPct * nyRange : u.scales.y.min;
                    let nyMax = yZoomEnabled ? nyMin + nyRange : u.scales.y.max;
                    [nyMin, nyMax] = clamp(nyRange, nyMin, nyMax, yRange, yMin, yMax);

                    u.batch(() => {
                        u.setScale('y', {
                            min: nyMin,
                            max: nyMax,
                        });
                        u.setScale('x', {
                            min: nxMin,
                            max: nxMax,
                        });
                        if (!isChildren) {
                            setXRanges({ key, data: [nxMin, nxMax] });
                            setYMin(nyMin);
                            setYMax(nyMax);
                        }
                    });
                    drawRange(u);
                }
            },
        },
    };
};
