import React, {useEffect, useRef} from 'react';
import "./ClassEventList.scss";
import { Tabs } from '../ui';
import {fullName, isAuth} from "../../helpers/auth";
import {printListDate} from "../../helpers/text";
import ChangeClass from "../ChangeClass";

const ClassEventList = (props) => {
    const eventList = useRef(null)
    const scrollToBottom = () => {
        eventList.current?.scrollIntoView({block: "end", behavior: "smooth"})
    }

    useEffect(() => {
        scrollToBottom()
    }, [props.messages]);

    return (
        <div className="classEventList">
            <ChangeClass/>
            <div className="classEventList__head">
                <Tabs>
                    <Tabs.Item className="active">События</Tabs.Item>
                    <Tabs.Item onClick={()=>props.toggleChat(true)}>Сообщения</Tabs.Item>
                </Tabs>
            </div>
            <div className="classEventList__scroll">
                <div className="classEventList__list" ref={eventList}>
                    {props.messages.map( (msg, i) =>(
                        <div className={"classEventList__item"+(msg.author._id===isAuth()._id?" author":"")} key={i}>
                            <div className="classEventList__name">{fullName(msg.author)}</div>
                            <div className="classEventList__text">{msg.text}</div>
                            <div className="classEventList__time">{printListDate(msg.timestamp)}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
};
export default ClassEventList;
