import React from "react";
import {Icon} from '../../ui';
import DropdownList from "../../ui/Dropdown/DropdownList";
import "./Dashboard.scss";

export const DashboardFooter = (props) => {
  return (
    <>
      <div className="videoGridFooter__item">
        <div className="videoGridFooter__label">Сортировка:</div>
        <div className="videoGridFooter__sort">
          <Icon name="sort-text"/>
        </div>
      </div>
      <div className="videoGridFooter__item">
        <div className="videoGridFooter__label">Вид сетки:</div>
        <div className="videoGridFooter__dropdown">
          <DropdownList dropPosition="top" control={props.control} sizeList={props.sizeList}/>
        </div>
      </div>
    </>
  );
}
export default DashboardFooter