import React, {useEffect, useState} from 'react';
import { Modal, Button, Input } from 'rlabui';
import {useDispatch, useSelector} from "react-redux";
import {storageClass} from "../../redux/slices/storage";
import {AddclassApi} from "../../api/api";
import {fetchclass, selectclass, selectusers} from "../../redux/slices/classsline";
import {useFormik} from "formik";
import {toast} from "react-toastify";
import {isAuth} from "../../helpers/auth";
import {Icon} from "../ui";
import {MembersGrid} from "../Grids";
import ss from './UserSelect.module.scss';

const UserSelect = (props) => {
    const dispatch = useDispatch();
    const documentClass = useSelector(storageClass);
    const [classs] = useSelector(selectclass);
    const [usersList] = useSelector(selectusers);
    const [search, setSearch] = useState('');

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            users: [],
        },
        onSubmit: async (formData) => {
            try {
                AddclassApi.addUserToClass(documentClass._id, formData.users.join('|'))
                .then(() => {
                    dispatch(fetchclass(isAuth()._id));
                    handleClose();
                })
                .catch((err) => toast.error(err.response.data.message));
            } catch (error) {
                console.log(error);
            }
        }
    });

    const handleClose = () => {
        formik.resetForm();
        props.showUserSelect({showModal: false});
    };

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) handleClose();
        };
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const quantityUsers = (formik.values.users.length);

    return (
        <Modal visible={props.showModal} className={ss.root}>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Head 
                    className={ss.head}
                    modalClose={handleClose} 
                    title={`Добавление ${props.role > 0 ? "учителей" : "учеников"} в ${documentClass.className}`} 
                >
                    <div className={ss.filter}>
                        <Input
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder={`Введите имя или фамилию ${props.role > 0 ? "учителя" : "ученика"}`}
                        />
                        <Icon name="search"/>
                    </div>
                </Modal.Head>

                <Modal.Body className={ss.scrolled}>
                    <MembersGrid 
                        disabled="true" 
                        select={formik} 
                        usersList={usersList.filter(u => u.role === props.role && u.status !== -1 &&
                        ([u.name, u.name2, u.name3, u.login, u.email]).some(s => s?.toLowerCase().includes(search.toLowerCase())) &&
                        !classs.filter(c => c._id === documentClass._id)[0]?.classUsers.includes(u._id))}
                    />
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={handleClose} border={true}>Отмена</Button>
                    <Button 
                        type="submit"
                        disabled={quantityUsers === 0}
                    >
                        Добавить {(quantityUsers !== 0) && ` (${quantityUsers})`}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
};

export default UserSelect;