import React, { useState, useRef, useEffect } from 'react';
import parse from 'html-react-parser';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
// import { Printer } from '';
import { Modal, Button, Checkbox, InputNumber } from 'rlabui';
import ss from './ModalPrint.module.scss';

const ModalPrint = ({ modal, tablePrint, markers, setPrintModal, setTheme, theme, isOscilloscope }) => {
    const [printTable, setPrintTable] = useState(true);
    // const [htmlTable, setHtmlTable] = useState();
    const [printRange, setPrintRange] = useState(true);
    const [printMarkers, setPrintMarkers] = useState(true);
    const [printImage, setPrintImage] = useState(false);
    const [imgDisable, setImgDisable] = useState(false);
    const [chartImg, setChartImg] = useState(null);
    const [font, setFont] = useState(16);
    const [chartId, setChartId] = useState(null);
    const [currentTheme, setCurrentTheme] = useState(theme);
    const printRef = useRef();
    const { isAndroid, cordova } = window;

    useEffect(() => {
        if (tablePrint?.length) {
            const { deviceKey, num } = tablePrint[0];
            setChartId([deviceKey + num]);
        }
    }, [tablePrint.length, modal]);

    useEffect(() => {
        if (!modal) {
            setCurrentTheme(theme);
            setChartImg(null);
            setPrintTable(true);
            setPrintImage(false);
        }
    }, [modal, theme]);

    useEffect(() => {
        if (modal) {
            const charts = isOscilloscope ? document.querySelectorAll('.chart__big') : [document.getElementById(chartId[0])];
            setImgDisable(!charts);
            if (chartId && printImage && charts.length) {
                const images = [];
                charts.forEach((chart) => {
                    if (!charts) return;
                    if (currentTheme === 'dark') {
                        setTheme('light');

                        setTimeout(() => {
                            setTheme('dark');
                        }, 10);
                    }
                    setTimeout(() => {
                        const canvas = chart.querySelector('canvas');
                        images.push(canvas.toDataURL('image/png'));
                    }, 4);
                });
                setChartImg(images);
            }
        }
    }, [chartId, printImage, modal, setChartImg, setImgDisable]);

    useEffect(() => {
        if (imgDisable) {
            setPrintImage(false);
        }
    }, [imgDisable]);

    useEffect(() => {
        if (modal && isOscilloscope) {
            setPrintImage(true);
            setChartId(['usb00', 'usb01']);
        }
    }, [isOscilloscope, modal]);

    const setModalContent = () => {
        return (
            <>
                <Modal.Head
                    modalClose={() => setPrintModal(false)}
                    title={`Печать датчика ${tablePrint[0]?.name ? '"' + tablePrint[0]?.name + '"' : ''}`}
                ></Modal.Head>
                <Modal.Body>
                    <div className={ss.head}>
                        <div className={ss.settings}>
                            <div className={ss.item}>
                                <label className="legend">Шрифт: </label>
                                <div className={ss.number}>
                                    <InputNumber
                                        minus={() => setFont(font - 1)}
                                        plus={() => setFont(font + 1)}
                                        step="1"
                                        min="0"
                                        max="24"
                                        value={font}
                                        onChange={() => setFont(font)}
                                        readOnly={true}
                                    />
                                    <div className="number__btn">
                                        <div
                                            className="plus"
                                            onClick={() => setFont((font) => ++font)}
                                        ></div>
                                        <div
                                            className="minus"
                                            onClick={() => setFont((font) => --font)}
                                        ></div>
                                    </div>
                                </div>
                            </div>

                            {!isOscilloscope && (
                                <>
                                    <div className={ss.item}>
                                        <Checkbox
                                            label="Таблица"
                                            checked={printTable}
                                            onChange={() => {
                                                setPrintImage(false);
                                                setPrintTable(!printTable);
                                                setChartImg();
                                            }}
                                        />
                                    </div>

                                    <div className={ss.item}>
                                        <Checkbox
                                            label="Диапазоны"
                                            checked={printRange}
                                            onChange={() => setPrintRange(!printRange)}
                                        />
                                    </div>

                                    <div className={ss.item}>
                                        <Checkbox
                                            label="Маркеры"
                                            checked={printMarkers}
                                            onChange={() => setPrintMarkers(!printMarkers)}
                                        />
                                    </div>
                                </>
                            )}

                            <div className={ss.item}>
                                <Checkbox
                                    label="График"
                                    checked={printImage}
                                    onChange={() => {
                                        setPrintTable(false);
                                        setPrintImage(!printImage);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        ref={printRef}
                        className={ss.print}
                    >
                        <div className={ss.width}></div>
                        {printImage &&
                            chartImg?.map((img, i) => (
                                <div
                                    className={ss.img}
                                    key={i}
                                >
                                    <img
                                        src={img}
                                        alt=""
                                    />
                                </div>
                            ))}

                        {!isOscilloscope && parse(renderPrintTable())}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {isAndroid ? (
                        <Button
                            onClick={() => {
                                const html = renderPrintTable();
                                var blob = new Blob([html], { type: 'application/pdf' });
                                const pdfName = 'На печать - ' + tablePrint[0].name + '.pdf';
                                cordova.plugins.saveDialog
                                    .saveFile(blob, pdfName)
                                    .then((uri) => {
                                        console.info('The file has been successfully saved to', uri);
                                    })
                                    .catch((reason) => {
                                        console.warn(reason);
                                    });
                            }}
                        >
                            Печать
                        </Button>
                    ) : (
                        <ReactToPrint content={() => printRef.current}>
                            <PrintContextConsumer>{({ handlePrint }) => <Button onClick={handlePrint}>Печать</Button>}</PrintContextConsumer>
                        </ReactToPrint>
                    )}
                    <Button
                        border={true}
                        onClick={() => setPrintModal(false)}
                    >
                        Отменить
                    </Button>
                </Modal.Footer>
            </>
        );
    };

    const renderPrintTable = () => {
        if (tablePrint.length) {
            const { name, unit, num } = tablePrint[0];
            let index = num;
            const markArr = [];
            const rangArr = [];
            // console.log('tablePrint ===', tablePrint);
            const marks = markers[index];

            let table = '<div class="' + ss.body + ' scrolledBox" style="font-size: ' + font + 'px"><table>';

            if (typeof num === 'string') {
                index = +num.replace('imp');
            }

            for (var key in marks) {
                if (key !== 'xLine' && key !== 'yLine' && !marks[key].resizer) {
                    if (marks[key].type === 'line') {
                        tablePrint[0]?.value?.forEach((item) => {
                            const { y: prop, x: timer } = item;
                            if (marks[key].value === timer) {
                                markArr.push({ timer, prop });
                            }
                        });
                    }
                    if (marks[key].type === 'box') {
                        const data = tablePrint[0].value.filter((item) => item.x >= marks[key].xMin && item.x <= marks[key].xMax);
                        const last = data.length - 1;

                        rangArr.push({ timer: data[0].x, prop: data[0].y });
                        rangArr.push({ timer: data[last].x, prop: data[last].y });
                    }
                }
            }

            if (markArr.length && printMarkers) {
                // console.log('markArr ===', markArr);
                table += `<tr>
                    <td>Маркеры</td>
                    <td></td>
                    <td></td>
                </tr>`;

                table += `<tr>
                    <td>№</td>
                    <td>Время,сек</td>
                    <td>${name}, ${unit}</td>
                </tr>`;

                markArr.forEach((item, i) => {
                    const { timer, prop } = item;
                    table += `<tr>
                        <td>${i + 1}</td>
                        <td>${(timer / 1000).toFixed(3)}</td>
                        <td>${prop}</td>
                    </tr>`;
                });
            }

            if (rangArr.length && printRange) {
                // console.log('rangArr ===', rangArr);
                table += `<tr>
                    <td>Диапазоны</td>
                    <td></td>
                    <td></td>
                </tr>`;

                table += `<tr>
                    <td>№</td>
                    <td>Время,сек</td>
                    <td>${name}, ${unit}</td>
                </tr>`;

                rangArr.forEach((item, i) => {
                    const { timer, prop } = item;
                    table += `<tr>
                        <td>${i + 1}</td>
                        <td>${(timer / 1000).toFixed(3)}</td>
                        <td>${prop}</td>
                    </tr>`;
                });
            }

            if (printTable && !printImage) {
                table += `<tr>
                    <td>Таблица</td>
                    <td></td>
                    <td></td>
                </tr>`;

                table += `<tr>
                    <td>№</td>
                    <td>Время,сек</td>
                    <td>${name}, ${unit}</td>
                </tr>`;

                tablePrint[0].value.forEach((item, i) => {
                    const { x: timer, y: prop, count } = item;
                    table += `<tr>
                        <td>${count}</td>
                        <td>${(timer / 1000).toFixed(3)}</td>
                        <td>${prop}</td>
                    </tr>`;
                });
            }

            table += '</table></div>';
            if (printTable || (printImage && (printMarkers || printRange))) {
                return table;
            } else {
                return '';
            }
        } else {
            return 'Нет дaнных';
        }
    };

    return (
        modal && (
            <Modal
                visible={modal}
                size={'md'}
            >
                {setModalContent()}
            </Modal>
        )
    );
};

export default ModalPrint;
