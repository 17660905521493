import React from 'react';
import { Modal, Button } from 'rlabui';

const ModalConfirmDialog = ({ showConfirmDlg, handleNo, question, handleYes, btnTextYes='Да', btnTextNo='Нет', redWarning=false}) => {
    if (!showConfirmDlg) {
        return false;
    }

    const style = redWarning ? {color: 'red'} : {};

    return (
        <Modal visible={showConfirmDlg} size="xs">
            <Modal.Head modalClose={handleNo} title={question} />
            <Modal.Footer>
                <Button onClick={handleYes} style={style}>
                    {btnTextYes}
                </Button>
                <Button onClick={handleNo} border={true}>
                    {btnTextNo}
                </Button>
            </Modal.Footer>
        </Modal>
    )
};

export default ModalConfirmDialog;
