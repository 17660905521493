import React, { useState, useEffect } from 'react';
import PlayPause from '../../PlayPause';
import Timer from '../../Timer';
import MarkersSettings from '../../MarkersSettings';
import ImportExport from '../../ImportExport';
import ConnectionButtons from '../../ConnectionButtons';
import { Icon } from '../../ui';

import ss from './Footer.module.scss';

const Footer = (props) => {
    // console.log(props);
    const { port, played, setChartSettingsModal, toggleTimerModal, isMobile, imported, setResetModal } = props;
    const [stylesDisabled, setStylesDisabled] = useState(true);

    useEffect(() => {
        if (!port && !imported?.length) {
            setStylesDisabled(true);
        }
        if (imported?.length || port) {
            setStylesDisabled(false);
        }
    }, [imported, port]);

    return (
        <>
            <footer className={ss.footer}>
                <ConnectionButtons />
                {isMobile !== 'portrait' && (
                    <div className={ss.group + ' landscape'}>
                        <div className={ss.label}>Доп. действия</div>
                        <div className={ss.btns}>
                            <Icon
                                tag="button"
                                name={'pulse'}
                                title="Стили графиков"
                                disabled={stylesDisabled}
                                onClick={() => setChartSettingsModal(true)}
                            />
                            {/* <Icon
                                tag="button"
                                name={'settings'}
                                title="Настройки"
                                onClick={() => {}}
                                disabled={!port || played}
                            /> */}
                            <Icon
                                tag="button"
                                name={'update'}
                                title="Очистить графики"
                                disabled={!port}
                                onClick={() => setResetModal(true)}
                            />
                        </div>
                    </div>
                )}
                <div className={ss.group + ' ' + ss.groupCenter}>
                    <div className={ss.btns}>
                        <Icon
                            tag="button"
                            name={'timer'}
                            title="Таймер"
                            disabled={!port || played}
                            onClick={() => toggleTimerModal(true)}
                        />
                        <PlayPause />
                    </div>
                    <Timer />
                </div>
                {isMobile !== 'portrait' && (
                    <div className={ss.group + ' landscape'}>
                        <div className={ss.label}>
                            Точки <br />и диап.
                        </div>
                        <div className={ss.btns + ' ' + ss.btns + '_group'}>
                            <MarkersSettings />
                        </div>
                    </div>
                )}
                {!isMobile && (
                    <div className={ss.group}>
                        <div className={ss.label}>
                            Работа <br />с файлами
                        </div>
                        <div className={ss.btns}>
                            <ImportExport />
                        </div>
                    </div>
                )}
            </footer>
        </>
    );
};

export default Footer;
