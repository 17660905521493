import React, { useState, useEffect } from "react";
import {getFiles} from '../ui/utils/gen_utils';
import {QUESTION_TYPES, STATUS, getTextEditorOptionFieldList,
    getMaxOfArray, getOneCorrectAnswer, getOptionFieldList, getMultipleCorrectAnswers, uploadOTFile, 
    loadOTFiles, deleteOTFileByName } from "./ot_utils";
import {useDispatch, useSelector} from "react-redux";
import { Icon, Trash, Warning } from '../ui';
import "./OnlineTestCreate.scss";
import ss from './OnlineTestCreateQuestions.module.scss';

const Constructor2OneAnswer = ({
        currQuestionType, questionList, setChangedData, isTemplate, isEditable, currQuestionNum,
        isNewQuestion, reinitQuestion, setReinitQuestion,
        dataError, setDataError, 
        setCurrAnswerOptions, setCurrCorrectOptions}) => {
    const [correctOption, setCorrectOption] = useState('0');
    const [answerList, setAnswerList] = useState([]);
    const [currQuestion, setCurrQuestion] = useState(undefined);
    const [minAnswerNum, setMinAnswerNum] = useState(1);
    const [video, setVideo] = useState('');
    const [status, setStatus] = useState(0);
    const [loadedFileName, setLoadedFileName] = useState('');

    const dispatch = useDispatch();
    const {lists: {files}} = useSelector(state => state);

    const isOneAnswer = qType => qType === QUESTION_TYPES.CORRECT_ANSWER_ONE;
    const isMultipleAnswers = qType => qType === QUESTION_TYPES.CORRECT_ANSWER_MANY;
    const isCompleteSentence = qType => qType === QUESTION_TYPES.COMPLETE_SENTENCE;
    const isFreeWriting = qType => qType === QUESTION_TYPES.FREE_WRITING;    
    const isVideo = qType => qType === QUESTION_TYPES.VIDEO;

    useEffect(() => {
        if (!isTemplate)
            loadOTFiles(dispatch);
    }, [dispatch, video, isTemplate]);

    const doSetAnswerList = v => {
        setAnswerList(v);
    };

    useEffect(() => {
        const question = isNewQuestion ? undefined : {...questionList[currQuestionNum]};
        // if (reinitQuestion && question) { //при замене сохраненного вопроса
        //     question.answerOptions = [];
        //     question.correctOptions = '';
        //     setReinitQuestion(false);
        // }

        setCurrQuestion(question);
        setStatus(STATUS.INIT);
    }, [questionList, currQuestionNum, isNewQuestion, reinitQuestion, setReinitQuestion]);

    useEffect(() => {
        if (status !== STATUS.INIT) return;
        if (isNewQuestion && !isFreeWriting(currQuestionType)) {
            //find minAnswerNum
            const qType = currQuestionType;
            const _minAnswerNum =  (isOneAnswer(qType) || isCompleteSentence(qType) || isVideo(qType)) ? 2 : 
                isMultipleAnswers(qType) ? 3 : 0;
            setMinAnswerNum(_minAnswerNum);
            //find initial answer list
            const answers = [];
            for (let i = 0; i < _minAnswerNum; i ++) 
                answers.push({name: '', id: i, correct: false}); 
            doSetAnswerList(answers);

            //find initial correct opts
            setCorrectOption('0');
        }
        setStatus(STATUS.FILL);
    }, [status, currQuestion, currQuestionType, isNewQuestion]);

    // === 1 of 2 useEffects:
    useEffect(() => {
        if (status !== STATUS.FILL) return;

        if (!currQuestion || isFreeWriting(currQuestionType)) {
            setStatus(STATUS.USE);
            return;
        }
        let answerOptions = [...currQuestion.answerOptions];
        if (answerOptions.length === 0)
            for (let i = 0; i < minAnswerNum - 1; i++) 
                answerOptions.push('');

        const answers = answerOptions.map((item, ind) => ({name: item, id: ind, correct: false}));

        if (isOneAnswer(currQuestionType) || isCompleteSentence(currQuestionType)) {
            setCorrectOption(currQuestion.correctOptions.toString());
        } else if (isVideo(currQuestionType)) {
            const [opt, videoName] = currQuestion.correctOptions.toString().split('|');
            setCorrectOption(opt); //contains correct option
            setVideo(videoName); //contains video name
        } else if (isMultipleAnswers(currQuestionType)) {
            if (currQuestion.correctOptions) {
                const options = currQuestion.correctOptions.split('|'); //contains the list of correct options
                for (let i = 0; i < options.length; i ++) {
                    answers[Number(options[i])].correct = true;
                }
            }
        }

        doSetAnswerList(answers);
        setStatus(STATUS.USE);
    }, [currQuestionNum, currQuestion, setDataError, currQuestionType, 
        minAnswerNum, correctOption, status]);

    // === 2 of 2 useEffects:
    useEffect(() => {
        if (status !== STATUS.USE) return;
        
        if (isFreeWriting(currQuestionType)) {
            if (dataError !== '') setDataError('');
            return;
        }

        //======== 1. check results: =============
        const hasEmptyAnswers = answerList.filter(item => !item.name || !item.name.trim()).length > 0;
        const isNotEnoughAnswers = answerList.length < minAnswerNum; 
        let errorMsg = '';
        if (hasEmptyAnswers) 
            errorMsg = 'Заполните все варианты ответов.';
        else if (isNotEnoughAnswers) 
            errorMsg = 'Количество предлагаемых ответов должно быть не меньше ' + minAnswerNum + '.';

        if (!errorMsg) {
            if (!isMultipleAnswers(currQuestionType)) {
                if (isVideo(currQuestionType) && !video) 
                    errorMsg = 'Добавьте видео.';
            } else  {
                const isNotEnoughCorrectOptions = answerList.filter(item => item.correct).length < minAnswerNum - 1;
                const isTooManyCorrectOptions = answerList.filter(item => item.correct).length === answerList.length;
                if (isNotEnoughCorrectOptions) 
                    errorMsg = 'Количество вариантов ответа должно быть не меньше ' + (minAnswerNum - 1) + '.';
                else if (isTooManyCorrectOptions) 
                    errorMsg = 'Нельзя указать все варианты как правильные.';
            } 
        }

        if (dataError !== errorMsg)
            setDataError(errorMsg);
        if (!!errorMsg) return;

        //======== 2. save results: =============
        //2.1 - correct answers:
        let optionsToSave = '';
        if (!isMultipleAnswers(currQuestionType)) {
            if (isVideo(currQuestionType)) { //видео держит инфу о файле в correctOption
                optionsToSave = correctOption + '|' + video; //merge correctOption and video name
            } else {
                optionsToSave = correctOption;
            }
        } else {
            optionsToSave = answerList.map((item, ind) => item.correct ? ind : -1)
                .filter(item => item >= 0)
                .map(item => item).join('|');
        }

        let changed = false;
        if (!currQuestion || currQuestion.correctOptions !== optionsToSave) {
            setCurrCorrectOptions(optionsToSave);
            changed = true;
        }

        //2.2. - answer list:
        const aOpts = answerList.map(item => item.name);
        if (!currQuestion || aOpts.length !== currQuestion.answerOptions.length || 
            aOpts.filter((item, ind) => item !== currQuestion.answerOptions[ind]).length > 0) {
                setCurrAnswerOptions(aOpts);
                changed = true;
        }

        if (changed && !isNewQuestion) {
            setChangedData(true);
        }
        //console.log('2ue2/2 - ok')
    }, [answerList, correctOption, currQuestion, status,
        setCurrAnswerOptions, setCurrCorrectOptions, isNewQuestion,
        setChangedData, setDataError, currQuestionType, minAnswerNum, video]); //don't add dataError

    const handleChangeAnswer = (value, index) => {
        const answers = [...answerList];
        answers[index].name = value;
        doSetAnswerList(answers);
    };

    const handleDeleteAnswerOption = (ind) => {
        const answers = answerList.filter((item, index) => index !== ind);
        doSetAnswerList(answers);
    };

    const handleAddAnswerOption = () => {
        const answers = [...answerList];
        const ids = answers.map((item) => item.id);
        const maxId = getMaxOfArray(ids);
        answers.push({name: '', id: maxId + 1, correct: false});
        doSetAnswerList(answers);
    };

    const handleToggleCorrectAnswers = (ind) => {
        const answers = [...answerList];
        answers[ind].correct = !answers[ind].correct;
        doSetAnswerList(answers);
    };

    //step 1 of 2: get file
    const handleAddVideo = (event, _files, _setVideo, _dispatch, _video) => {
        if (!isTemplate) {
            uploadOTFile(_files, _video, _setVideo);
        } else {
            setLoadedFileName(_video); //keep the file name and content
        }

        event.target.value = '';
    };
    //step 2 of 2: save file (SA) name or file (teacher)
    useEffect(() => {
        if (!loadedFileName || !isTemplate) return;

        const reader = new FileReader();

        reader.onload = (event) => {
            //DB will keep the file data: name and content
            const fileOpts = {name: loadedFileName.name, content: event.target.result};
            setLoadedFileName('');
            const fileData = JSON.stringify(fileOpts);
            setCorrectOption(correctOption.split('|')[0] + '|' + fileData);
            setVideo(fileData);
        };

        reader.readAsDataURL(loadedFileName);
    }, [loadedFileName, correctOption, isTemplate]); //answerList, ??

    const handleDeleteVideo = async () => {
        if (!isTemplate)
            deleteOTFileByName(dispatch, video);
        setVideo('');
    };

    const getFreeWriting = () => {
        return (
            <div className="cor-net__section border">
                <div className="cor-net__title">Варианты ответа</div>
                <div className="cor-net__row row-content">Пользователь должен написать текст в поле для текста.</div>
            </div>
        );
    };
    
    const getVideoQuestion = () => {
        if (!!video) {
            const _files = !isTemplate ? getFiles(files, [{name: video}]) : [JSON.parse(video).content];

            if (_files.length > 0) {
                const f = _files[0];
                return (
                    <div className={ss.video} key='v01'>
                        <video src={!isTemplate ? f.Url : f} controls={true} key={"v03"}></video>
                        <Trash 
                            className={ss.video__trash} 
                            onClick={handleDeleteVideo} 
                            bg={true}
                        />
                    </div>
                );
            }
        };

        return (
            <div className='cor-net__section border'>
                {currQuestion && 
                <div className="cor-net__row">
                    <div className="cor-net__col col-3">
                        <Warning>Добавьте видео</Warning>
                    </div>
                </div>}

                <div className="cor-net__row">

                    <label className={ss.addOption} key='lbl01'>
                        <Icon name="plus" key='lbl02' />
                        Добавить видео
                        <input type="file" accept={'video/*'} 
                        onChange={(e) => handleAddVideo(e, files, setVideo, dispatch, e.target.files[0])} 
                        key='lbl03' />
                    </label>

                </div>
            </div>
        );
    };
   
    return (
        <>
            {isVideo(currQuestionType) && getVideoQuestion()}

            {/* Top part: answer options */}
            {(isOneAnswer(currQuestionType) || isMultipleAnswers(currQuestionType) || 
              isVideo(currQuestionType)) && status === STATUS.USE &&
                getTextEditorOptionFieldList(answerList, handleChangeAnswer, handleDeleteAnswerOption, 
                    handleAddAnswerOption, isEditable)
            }
            {isCompleteSentence(currQuestionType) &&
                getOptionFieldList(answerList, handleChangeAnswer, handleDeleteAnswerOption, 
                    handleAddAnswerOption, isEditable)
            }

            {/* Bottom part: select correct answer(s) */}
            {(isOneAnswer(currQuestionType) || isCompleteSentence(currQuestionType) || (isVideo(currQuestionType))) && 
                getOneCorrectAnswer(answerList, 
                    isVideo(currQuestionType) && isTemplate ? correctOption.split('|')[0] : correctOption, 
                    setCorrectOption, isEditable)}

            {isMultipleAnswers(currQuestionType) && 
                getMultipleCorrectAnswers(answerList, handleToggleCorrectAnswers)}
           
            {isFreeWriting(currQuestionType) && getFreeWriting()}
        </>
    )
}

export default Constructor2OneAnswer;
