import React, {useState, useEffect} from 'react';
import {toast} from "react-toastify";
import { Modal, Button, Input, Textarea } from 'rlabui';
import "../../CalcModels/CalcModel.scss";

const YEAR_MONTH_DAY = 'ymd';
const YEAR_MONTH_DAY_HH_MM = 'ymd_hm';

const SaveXlsFileConfirmDialog = ({ showConfirmSaveXlsDlg, setShowConfirmSaveXlsDlg, fileName, saveExport, handleCancelSave}) => {
    const [title, setTitle] = useState(fileName);
    const [description, setDescription] = useState('');

	useEffect(() => {
        setTitle(fileName);
	}, [fileName]);

    const confirmYes = () => {
        const fullFileName = (!!title ? title : fileName) + " " + getFormattedDate(new Date(), 'ymd_hm');
        saveExport(fullFileName, description);
        toast.success("Результаты сохранены в " + fullFileName +  " .");
        setShowConfirmSaveXlsDlg(false);        
    };

    const handleNo = () => {
        if (!!handleCancelSave)
            handleCancelSave();
        else 
            setShowConfirmSaveXlsDlg(false);
    };

	const handleChangeName = (value) => { 
        if (value.length < 70)
		    setTitle(value);
	};

	const handleChangeDescription = (value) => { 
        if (value.length < 200)
		    setDescription(value);
	};

    if (!showConfirmSaveXlsDlg) {
        return null;
    }

    const getFormattedDate = (date, dateType = YEAR_MONTH_DAY) => {
        //https://stackoverflow.com/questions/3552461/how-do-i-format-a-date-in-javascript
        let dateOptions = {
          year: 'numeric',
          month: '2-digit', // 'long',
          day: '2-digit', // 'numeric',
          };
      
          if (dateType === YEAR_MONTH_DAY_HH_MM) {
            dateOptions = {
              year: 'numeric',
              month: '2-digit', // 'long',
              day: '2-digit', // 'numeric',
              hour: 'numeric',
              minute: 'numeric'
            };
          }
      
          const res = date ? date.toLocaleString("ru", dateOptions) : '';
          return res;
      };
      

    return (
        <Modal visible={showConfirmSaveXlsDlg} size={'sm'}>
            <Modal.Head modalClose={handleNo} title="Сохранить эксперимент" />
            <Modal.Body>
                <div className="cor-net__row">
                    <div className="cor-net__col col-grow">
                        <div className="cor-net__label">Название</div>
                        <Input value={title} onInput={e => handleChangeName(e.target.value)} />
                    </div>
                </div>
                <div className="cor-net__row">
                    <div className="cor-net__col col-grow">
                        <div className="cor-net__label">Описание</div>
                        <Textarea 
                            placeholder="Опишите эксперимент" 
                            autoComplete="off"
                            value={description}
                            onChange={e => handleChangeDescription(e.target.value)}
                            rows={4}
                        
                        />
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={confirmYes}>Да</Button>
                <Button onClick={handleNo} border={true}>Нет</Button>
            </Modal.Footer>
        </Modal>
    )
};

export default SaveXlsFileConfirmDialog;
