import React, { useState, useEffect, useRef } from "react";
//https://socket.dev/npm/package/react-audio-visualize
//https://github.com/samhirtarif/react-audio-visualize/blob/master/src/AudioVisualizer/AudioVisualizer.tsx
//import { AudioVisualizer } from 'react-audio-visualize'; 
import { Icon } from '../ui'; //, Sound
import {SCORE_MANUAL_CHECK, calcScoreValue} from '../ui/utils/score_utils';
import ModalFinishStudentTestDlg from "../ui/ModalDialogs/ModalFinishStudentTestDlg";
import ImageDlg from "../ui/ModalDialogs/ImageDlg";
import {storageClass} from "../../redux/slices/storage";
import htmlParser from "html-react-parser";
import {getTestExecuteData, setTestExecuteData}  from "../../redux/slices/tutorialslice";
import {isAuth} from "../../helpers/auth";
import {useDispatch, useSelector} from "react-redux";
import {showCurrTime, showRemainingTime, getRemainingTimeInMSec, initializeTimeCounter, 
	getFiles} from "../ui/utils/gen_utils";
import {removePracticumTestInfo} from "../ui/utils/ServerInfo";
import { QUESTION_TYPES, FORMAT_TYPE, loadOTFiles, getContentByFile,
	getOnlineTestById, updateOnlineTestInfoById } from "./ot_utils";
import {Button, Checkbox, Textarea} from 'rlabui';
import { Content, ContentBody, ContentDesc, ContentFooter, ContentHead, ContentHeader } from "../template/ContentParts";
import ss from './OnlineTestExecute.module.scss';

const MAX_DESC_LENGTH = 1000;

const OnlineTestExecute = ({ otSettings, history }) => {
	const [onlineTestId, setOnlineTestId] = useState(undefined);
	const [onlineTestInfoId, setOnlineTestInfoId] = useState(undefined);
	const [onlineTestData, setOnlineTestData] = useState(undefined);
	const [isTemplate, setIsTemplate] = useState(false);
	const [isAutoEstimateType, setIsAutoEstimateType] = useState(false);
    const [questionList, setQuestionList] = useState([]);
    const [resultList, setResultList] = useState([]);
	const [isNextStep, setIsNextStep] = useState(false);
    const [answerList, setAnswerList] = useState([]);
    const [sequenceRemList, setSequenceRemList] = useState([]);
    const [sequenceMovedList, setSequenceMovedList] = useState([]);
    const [isSequencePage, setIsSequencePage] = useState(false);
    const [showCompleteTestDlg, setShowCompleteTestDlg] = useState(false);
    const [showPictureDlg, setShowPictureDlg] = useState(false);
    const [fileUrl, setFileUrl] = useState(false);
	//const [soundVolume, setSoundVolume] = useState(20);
	const [currPage, setCurrPage] = useState(0);
    const [isComplete, setIsComplete] = useState(false);
    const [forceClear, setForceClear] = useState(false);
    const [timeLimitInMinutes, setTimeLimitInMinutes] = useState(0);
    const [timeLimitEnabled, setTimeLimitEnabled] = useState(false);
    const [startTime, setStartTime] = useState(undefined);
    const [currTestTime, setCurrTestTime] = useState();
    const [isTestTimeFinished, setIsTestTimeFinished] = useState(false);
    const [allowPrevStep, setAllowPrevStep] = useState(false);
	const [blobList, setBlobList] = useState([]);
    
	const timerIdRef = useRef();
	const savedTestExecuteData = useSelector(getTestExecuteData);
    const {lists: {files}} = useSelector(state => state);
    const documentClass = useSelector(storageClass);
    const dispatch = useDispatch();
	const isTeacher = () => isAuth().role > 0;

	useEffect(() => {
		timerIdRef.current = initializeTimeCounter(1, setCurrTestTime); //refresh each sec
		return () => {clearInterval(timerIdRef.current);};
    }, []);

    useEffect(() => {
        loadOTFiles(dispatch);
    }, [dispatch]);

	useEffect(() => {
		if (isTeacher() && !onlineTestId)
			dispatch(setTestExecuteData(null));
  }, [dispatch, onlineTestId]);

  	useEffect(() => {
		const fetchData = async (otId, _isAutoEstimateType, _isRandomOrder) => {
			setOnlineTestId(otId);
			const data = await getOnlineTestById(otId);
			setIsTemplate(!data.owner);
			setOnlineTestData(data);

			//1. get general info
			if (_isAutoEstimateType) { 
				setIsAutoEstimateType(_isAutoEstimateType);
			}

			for (let i = 0; i < data.questions.length; i ++) 
				data.questions[i].orderInd = i;
		
			if (_isRandomOrder) {
				data.questions = shuffle(data.questions);
			}
			setQuestionList(data.questions);

			//2. set initial empty answers
			const emptyAnswers = data.questions.map(item => "");
			setAnswerList(emptyAnswers);

			const initData = prepareTestExecuteData(onlineTestInfoId, data, 0, data.questions, emptyAnswers, []);
			dispatch(setTestExecuteData(initData));
		};

		if (!otSettings) return;

		if (isTeacher()) { //for teacher preview:
			if (!savedTestExecuteData) {
				fetchData(otSettings.onlineTestId, false, false);
			}
		} else { //for student test:
			if (!savedTestExecuteData) {
				if (questionList.length > 0) return; //=> come here only once

				setOnlineTestInfoId(otSettings.onlineTestInfoId);
				setStartTime(otSettings.startTime);
				setTimeLimitInMinutes(otSettings.timeLimitInMinutes);
				setTimeLimitEnabled(otSettings.timeLimitEnabled);
				fetchData(otSettings.onlineTestId, otSettings.isAutoEstimateType, otSettings.isRandomOrder);
			}
			else if (answerList.length === 0) { //1st page, no answers yet
				unpackTestExecuteData(savedTestExecuteData);
			}
		}
	}, [dispatch, onlineTestInfoId, otSettings, answerList, savedTestExecuteData, questionList]);

	const unpackTestExecuteData = ted => {
		setCurrPage(ted.currPage);
		setQuestionList(ted.questionList);
		setAnswerList(ted.answerList);
		setResultList(ted.resultList);
		setOnlineTestData(ted.onlineTestData);
	};

	useEffect(() => { //otInfoId and test data are received, so let's unpack current data storing redux
		if (!savedTestExecuteData || !onlineTestInfoId || isTeacher()) return;
		if (onlineTestInfoId !== savedTestExecuteData.onlineTestInfoId) { //it means a previous data is used
			dispatch(setTestExecuteData(null));
			return;
		}

		if (currPage >= savedTestExecuteData.currPage) return; //it means we didn't leave the page
		if (allowPrevStep) { //we do step back
			setAllowPrevStep(false);
			return;
		}

		unpackTestExecuteData(savedTestExecuteData);
	}, [dispatch, currPage, onlineTestInfoId, onlineTestData, savedTestExecuteData]); //don't add: allowPrevStep!!

	useEffect(() => {
		//only for 2 sequence pages:
		if (!questionList || questionList.length === 0 || currPage >= questionList.length || isSequencePage) return;
		if (!savedTestExecuteData) return; //данные еще не сохранены и будут меняться
		const question = questionList[currPage];
		const qType = Number(question.questionType);
		if (qType !== QUESTION_TYPES.CORRECT_WORD_SEQUENCE && qType !== QUESTION_TYPES.CORRECT_IMAGE_SEQUENCE) return;

		let rems = [];
		const moves = [];
		let allOptions = [...question.answerOptions];
			
		const answerIds = !!answerList[currPage] ? answerList[currPage].split('|') : [];

		for (let i = 0; i < answerIds.length; i ++) {
			const ind = Number(answerIds[i]);
			const move = { name: allOptions[ind], id: ind }
			moves.push(move);
			allOptions[ind] = undefined;
		}

		for (let i = 0; i < allOptions.length; i ++) { //do not use filter and map
			if (allOptions[i]) {
				rems.push({name: allOptions[i], id: i});
			}
		}

		setSequenceRemList(rems);
		setSequenceMovedList(moves);
		setIsSequencePage(true);
	}, [answerList, currPage, isSequencePage, questionList, savedTestExecuteData]);

	useEffect(() => {
		if (!timeLimitEnabled) return;
		const remTime = getRemainingTimeInMSec(currTestTime, startTime, 60 * timeLimitInMinutes);
		setIsTestTimeFinished(remTime <= 0);
    }, [currTestTime, startTime, timeLimitEnabled, timeLimitInMinutes]);

	const prepareTestExecuteData = (otiId, otData, _currPage, _questionList, _answerList, _resultList) => {
		return {
			onlineTestInfoId: otiId,
			onlineTestData: otData,
			currPage: _currPage,
			questionList: _questionList, 
			answerList: _answerList,
			resultList: _resultList,
		};
	};

    useEffect(() => {
		const saveData = async () => {
			const saveResultList = resultList.map(item => ({answer: item.answer, isCorrect: item.isCorrect}));
			const correctAnswerNumber = resultList.filter(item => item.isCorrect).length;
			const nonCheckedAnswerNumber = resultList.filter(item => item.isCorrect === -1).length;
			const score = isAutoEstimateType && nonCheckedAnswerNumber === 0 ? 
				calcScoreValue(correctAnswerNumber, resultList.length) : SCORE_MANUAL_CHECK;

			const newOnlineTestData = {
				answers: saveResultList,
				isComplete: isComplete,
				score: score,
			};

			await updateOnlineTestInfoById(onlineTestInfoId, newOnlineTestData);
		};

		if (!isNextStep && !isComplete && !isTestTimeFinished) return; 

		if (currPage < questionList.length && !isTeacher()) {
			saveData();
			const data = prepareTestExecuteData(onlineTestInfoId, onlineTestData, currPage, questionList, answerList, resultList);
			dispatch(setTestExecuteData(data));
		}

		setIsNextStep(false);
		
		if (isComplete || isTestTimeFinished) {
			if (currPage === questionList.length - 1) {
				dispatch(setTestExecuteData(null));
			}

			if (isTestTimeFinished) setCurrPage(questionList.length - 1);
			setForceClear(true);
		}
	},[documentClass._id, currPage, isComplete, onlineTestId, resultList, onlineTestData, 
		isTestTimeFinished, onlineTestInfoId, isNextStep, questionList.length, questionList, answerList, startTime, 
		currTestTime, dispatch, isAutoEstimateType]);
		
	useEffect(() => {
		if (!forceClear) return;
		dispatch(setTestExecuteData(null));		
		const path = isTeacher() ? '/practicum/ot' : '/';
		history.push(path);
	}, [dispatch, forceClear, history]);

	useEffect(() => {
		const fetchBlob = async (urlList) => {
			const _blobList = [];
			for (let i = 0; i < urlList.length; i ++) {
				const blob = await fetch(urlList[i])
					.then(response => {
					  if(response.ok) return response.blob();
					  throw new Error('Network response was not ok.');
				}).then(bl => bl);
				_blobList.push(blob);
			}
			setBlobList(_blobList);
		};
		
		if (questionList.length === 0 || currPage >= questionList.length) return;
	
		const question = questionList[currPage]; 
		const qType = Number(question.questionType); 
	
		if (qType === QUESTION_TYPES.AUDIO) {
			//const answerOptions = question.answerOptions;
			//const urlList = getFiles(files, answerOptions.map(item => ({name: item}))).map(item => item.Url);
			//fetchBlob(urlList);
		}
	}, [questionList, currPage, files]);
	
	const shuffle = array => {
		let currentIndex = array.length,  randomIndex;
	  
		// While there remain elements to shuffle.
		while (currentIndex !== 0) {
		  // Pick a remaining element.
		  randomIndex = Math.floor(Math.random() * currentIndex);
		  currentIndex--;
	  
		  // And swap it with the current element.
		  [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
		}
	  
		return array;
	};

	const prepareDataToSave = pageNum => {
		const checkCorrectAnswer = (item, ind) => {
			const qType =  Number(item.questionType);
			const answerOpt = answerList[ind].toString();

			//for Writing question, it's enough to have any text written:
			if (qType === QUESTION_TYPES.FREE_WRITING) return !!answerList[ind].trim() ? -1 : 0; // -1 means that the answer should be checked by teacher later

			//video: correctOptions contain the correct answer and the video file name
			if (qType === QUESTION_TYPES.VIDEO) return Number(answerOpt === item.correctOptions.split('|')[0]);

			//for others: compare the receved answer and the correct answer:
			return Number(answerOpt === item.correctOptions);
		};

		if (pageNum < questionList.length) {
			setIsSequencePage(false);

			//prepare results of the test:
			let results = questionList.map((item, ind) => ({
				answer: answerList[ind].toString(),
				orderInd: item.orderInd,
				isCorrect: checkCorrectAnswer(item, ind),
			}));

			results = results.sort((a,b) => a.orderInd - b.orderInd);
			setResultList(results);
		}
	};

	const clearSeqLists = () => {
		if (sequenceRemList.length > 0) setSequenceRemList([]);
		if (sequenceMovedList.length > 0) setSequenceMovedList([]);
	};

	const handlePrevQuestion = pageNum => {
		if (pageNum > 0 && pageNum < questionList.length) {
			clearSeqLists();
			setAllowPrevStep(true);
			setCurrPage(pageNum - 1);
			setIsSequencePage(false);
		}
	};

	const handleNextQuestion = pageNum => {
        if (pageNum < questionList.length) {
			clearSeqLists();
			prepareDataToSave(pageNum);

			if (pageNum < questionList.length - 1) {
				setCurrPage(pageNum + 1);
				setIsNextStep(true);
			}
		}
	};

	const handleStudentIsReadyToComplete = () => {
		prepareDataToSave(questionList.length - 1);
		setShowCompleteTestDlg(true);
	};

	const handleConfirmCompleteTestNo = () => { 
 		setShowCompleteTestDlg(false);
	};	

	const handleConfirmCompleteTestYes = () => { 
		setShowCompleteTestDlg(false);
		clearInterval(timerIdRef.current);
		removePracticumTestInfo();
		setIsComplete(true); //online test is completed by Student
		dispatch(setTestExecuteData(null));
	};	

	const getIsStudentAnswered = (pageNum) => {
		const qType = Number(questionList[pageNum].questionType);
		const isStudentAnswered = (answerList[pageNum] !== "" && (
			sequenceRemList.length === 0 || 
				(qType  !== QUESTION_TYPES.CORRECT_WORD_SEQUENCE && qType  !== QUESTION_TYPES.CORRECT_IMAGE_SEQUENCE)
		)) || isTeacher();

		return isStudentAnswered;
	};

	const getNextQuestion = pageNum => {
		const isLastPage = pageNum >= questionList.length - 1;
		const isStudentAnswered = getIsStudentAnswered(pageNum);

		if (!isStudentAnswered) return (<></>);

		return (
			<>
				{!isLastPage ? 
					<Button onClick={() => handleNextQuestion(pageNum)}>
						Следующий вопрос
					</Button>
				:
					<Button onClick={handleStudentIsReadyToComplete}>
						Завершить тест
					</Button>
				}
			</>
		);		
	};

	const getActionButtons = (pageNum) => {
		//lock Next button if the student is not answered yet:
		const isLastPage = pageNum >= questionList.length - 1;
		const isStudentAnswered = getIsStudentAnswered(pageNum);
		
		const buttons = (
			<>
				<div></div>
				<div>
					<button
						type="button"
						className={ss.btn}
						onClick={() => handlePrevQuestion(pageNum)}
						disabled={!(pageNum > 0 && pageNum < questionList.length)}
					>
						<Icon name="arrow-round-prev" />
						Предыдущий вопрос
					</button>
				</div>
				<div>
					<button 
						type="button"
						className={ss.btn}
						onClick={() =>handleNextQuestion(pageNum)}
						disabled={isLastPage || !isStudentAnswered } 
					>
						{pageNum < questionList.length-1 ? 'Следующий вопрос' : 'Результаты теста'}
						<Icon name="arrow-round-next" />
					</button>
				</div>
				<div>
					{isLastPage && isStudentAnswered && 
					<button 
						type="button"
						className={ss.btn}
						onClick={handleStudentIsReadyToComplete}>
						<Icon name="success"/>
						Завершить
					</button>}
				</div>
								
 			</>
		);
		return buttons; 
	};

	const getCBAnswerByInd = (pageNum, ind) => {
		if (!answerList || !answerList[pageNum]) return "";

		const answers = answerList[pageNum].split('|');
		for (let i = 0; i < answers.length; i ++) {
			if (Number(answers[i]) === ind)
				return ind;
			}

		return "";
	};

	const savePreparedAnswer = (pageNum, answer) => {
		let answers = [];
		if (pageNum >= answerList.length) {
			answers = [...answerList];
			answers.push(answer);
		} else {
			answers = answerList.map((item, ind) => pageNum !== ind ? item : answer);
		}
		setAnswerList(answers);
	};

    const handleChangeСB = (e, pageNum) => {
        const cbAnswers = [];
        const checkboxes = document.querySelectorAll('input[type=checkbox]:checked');
        for (let i = 0; i < checkboxes.length; i++) {
			const val = checkboxes[i].value;
			if (val !== 'on') {
            	cbAnswers.push(val);
			}
        }
		const answers = cbAnswers.join('|');
		savePreparedAnswer(pageNum, answers);
    };

	const getUserText = pageNum => pageNum < questionList.length ? answerList[pageNum] : '';

	const handleChangeText = (value, pageNum) => { 
		if (value.length > MAX_DESC_LENGTH) return;
		savePreparedAnswer(pageNum, value);
	};

	const getRbAnswerByInd = (pageNum, _answerList) => pageNum < _answerList.length ? _answerList[pageNum] : '';

	const getRbBox = (pageNum, answerOptions, isTextEditorView, adding = '') => {
		const selectedInd = getRbAnswerByInd(pageNum, answerList);

		return (
			answerOptions.map((item, ind) => 
				<div key={"rbkey"+ind} className={ss.row}>
					<Checkbox
						id={ind} 
						value={ind} 
						name={item}
						checked={ind === selectedInd} 
						onChange={(e) => savePreparedAnswer(pageNum, Number(e.target.value))}
						label={isTextEditorView ? htmlParser(item) : adding + item}
						square={true}
					/>
				</div>)			
		);
	};

	const handleItemMove = (pageNum, item, isShiftToMoved) => {
		let rems = [...sequenceRemList];
		let moved = [...sequenceMovedList];
		
		if (isShiftToMoved) {
				const elem = rems.find((obj, i) => obj.id === item.id);
				rems = rems.filter((obj, i) => obj.id !== item.id);
				moved.push(elem);
		} else {
			const elem = moved.find((obj, i) => obj.id === item.id);
			moved = moved.filter((obj, i) => obj.id !== item.id);
			rems.push(elem);
		}

		setSequenceRemList(rems);
		setSequenceMovedList(moved);
		const answer = moved.map(obj => obj.id).join('|');
		savePreparedAnswer(pageNum, answer);
	};

	// useEffect(() => {
	// 	if (questionList.length === 0) return;
	// 	const question = questionList[currPage]; 
	// 	const qType = Number(question.questionType); 
	// 	if (qType !== QUESTION_TYPES.AUDIO) return;

	// 	const answerOptions = question.answerOptions;
	// 	for (let i = 0; i < answerOptions.length; i ++) {
	// 		const audioEl = document.getElementById("isAudio"+i);
	// 		const audioDivEl = document.getElementById("isAudioDiv"+i);
	// 		const d = audioDivEl.disabled;

	// 		if (!audioEl.paused) {
	// 		}
	// 	}
    // }, [currPage, questionList, currTestTime]);


	const getRbAudio = (pageNum, answerOptions, filesList) => {
		const selectedInd = getRbAnswerByInd(pageNum, answerList);

		const options = (
			answerOptions.map((item, ind) => 
				<div key={"rbkey"+ind} className={ss.row}>
					<Checkbox 
						id={ind} 
						value={ind} 
						name={item}
						checked={ind === selectedInd} 
						onChange={(e) => savePreparedAnswer(pageNum, Number(e.target.value))}
					/>
					
					{/* <Sound id={"isAudio"+ind} volume={0} src={filesList[ind] ? filesList[ind].Url : ''} key={"audio"+ind} /> */}
					<div key={'mid01'+(ind+1)}>
						<audio id={"isAudio"+ind} volume={0} src={filesList[ind] ? filesList[ind].Url : ''} controls key={"audio"+ind} />
					</div>
				</div>
			)			
		);

		return options;
	};

	const getIllustration = (pageNum) => {
		const ils = questionList[pageNum].illustration; 
		if (!ils) return <></>;
		const illustration = JSON.parse(ils);

        const foundFiles = getFiles(files, [{name: illustration.name}]);
		const content = foundFiles?.length > 0 ? foundFiles[0].Url : '';
		if (!content) return <></>;
		const ifType = illustration.illFileType;

		return (
		<div className={ss.section}>

			{ifType === FORMAT_TYPE.IMAGE && 
				<div className={ss.questionFile}>
					<img src={content} alt='' />
				</div>
			}

			{ifType === FORMAT_TYPE.VIDEO && 
				<div className={ss.questionFile}>
					<video src={content} controls={true}></video>
				</div>
			}
			{ifType === FORMAT_TYPE.AUDIO && 
				<div className={ss.questionFile_audio}>
					<audio src={content} volume={0} controls={true} width="100" height="30" />
				</div>
			}
		</div>
		);

	};

	const getAudioQuestion = pageNum => {
		const question = questionList[pageNum]; 
		const answerOptions = question.answerOptions;
		const filesList = getFiles(files, answerOptions.map(item => ({name: item})));

		const options = filesList.length > 0 ? getRbAudio(pageNum, answerOptions, filesList) : <></>;

		return (
			<div className={ss.section}>
				<div className={ss.row}>
					<div className={ss.info}>
						<Icon name="info" />
						Прослушайте все ответы и выберите один из них: 
					</div>
				</div>

				{options}

				<div className={ss.row}>
					{getNextQuestion(pageNum)}
				</div>
			</div>
		);
	};

	const getCorrectWordSequence = pageNum => {
		const getSequence = (pageNum, list, isRem) => {
			if (list.length === 0) return (
				<div className={ss.sequence__wrap}></div>
			);

			return (
				<div className={ss.sequence__wrap}>
					<div className={ss.sequence__list}>
						{list.map((item, ind) => 
							<button 
								type="button"
								className={ss.sequence__btn}
								key={(item.id + 1) * (isRem ? 1 : -1)}
								onClick={() => handleItemMove(pageNum, item, isRem)}>
								{item.name}
							</button>
						)}
					</div>
					
				</div>
			);
		};

		return (
		<div className={ss.section}>
			<div className={ss.row}>
				<div className={ss.info}>
					<Icon name="info" />
					Поставьте слова в правильном порядке: 
				</div>
			</div>
			<div className={ss.row}>
				<div className={ss.sequence}>
					{getSequence(pageNum, sequenceRemList, true)}
					{getSequence(pageNum, sequenceMovedList, false)}
				</div>
			</div>
			<div className={ss.row}>
				{getNextQuestion(pageNum)}
			</div>
		</div>
		);
	};

	const getChooseOneAnswer = pageNum => {
		return (
			<div className={ss.section}>
				<div className={ss.row}>
					<div className={ss.info}>
						<Icon name="info" />
						Выберите один из ответов: 
					</div>
				</div>
				
				{getRbBox(pageNum, questionList[pageNum].answerOptions, true)}
				
				<div className={ss.row}>
					{getNextQuestion(pageNum)}
				</div>
			</div>			
		);
	};

	const getChooseManyAnswers = pageNum => {
		return (
			<div className={ss.section}>
				<div className={ss.row}>
					<div className={ss.info}>
						<Icon name="info" />
						Выберите все правильные ответы среди перечисленных:
					</div>
				</div>
				
				{questionList[pageNum].answerOptions.map((item, ind) => 
				<div className={ss.row} key={'cbelem'+ind}>
					<Checkbox 
						value={ind}  
						name={item}
						checked={ind === getCBAnswerByInd(pageNum, ind)} 
						onChange={ e=> handleChangeСB(e, pageNum)}
						label={htmlParser(item)}
						square={true}
					/>
				</div>
				)}
			
				<div className={ss.row}>
					{getNextQuestion(pageNum)}
				</div>
			</div>
		);
	};

	const handleShowPicture = (_fileUrl) => {
		setFileUrl(_fileUrl);
		setShowPictureDlg(true);
	};

	const getCorrectImageSequence = pageNum => {
		const getImage = (pageNum, i, file, fileItem, vRem) => {
			return (
				<div className={ss.grid__img} key={'dir'+i}>
					<img 
						src={getContentByFile(isTemplate, file)} 
						onClick={() => handleItemMove(pageNum, fileItem, vRem === 1)}  
						alt='' 
						key={'mid03'+(i+1)*vRem} 
					/>
					<div className={ss.grid__fullScreen} 
						onClick={() => handleShowPicture(!isTemplate ? file : file.name)}
					>
						<Icon name="full-screen" />
					</div>
				</div>
			);
		};
	
		const getImageGridList = (pageNum, fileList, nameList, vRem) => {
			if (fileList.length === 0) return (
				<div className={ss.grid__empty}></div>
			);
	
			return (
				<div className={ss.grid__full}>
					<div className={ss.grid}  key='grid01'>
						{fileList.map((f, i) => 
							<button className={ss.grid__btn} key={'btn'+(i+1)*vRem} >
								{getImage(pageNum, i, f, nameList[i], vRem)}
							</button>
						)}
					</div>									
				</div>
			);
		};

		const remFiles = !isTemplate ? getFiles(files, sequenceRemList) : sequenceRemList;
		const movedFiles = !isTemplate ? getFiles(files, sequenceMovedList) : sequenceMovedList;

		return (
			<div className={ss.section}>
				<div className={ss.row}>
					<div className={ss.info}>
						<Icon name="info" />
						Расположите картинки в правильном порядке: 
					</div>
				</div>

				<div className={ss.row}>
					<div className={ss.grid__wrap}>
						{getImageGridList(pageNum, remFiles, sequenceRemList, 1)}
						{getImageGridList(pageNum, movedFiles, sequenceMovedList, -1)}
					</div>
				</div>

				<div className={ss.row}>
					{getNextQuestion(pageNum)}
				</div>
			</div>
		);
	};

	const getNonVerbalQuestion = pageNum => {
		const getRbImages = (pageNum, answerOptions, filesList) => {
			const selectedInd = getRbAnswerByInd(pageNum, answerList);
	
			const options = (
				answerOptions.map((item, ind) => 
					<div key={"rbkey"+ind} className={ss.grid__checkbox}>
						<Checkbox
							value={ind} 
							name={item}
							checked={ind === selectedInd} 
							onChange={(e) => savePreparedAnswer(pageNum, Number(e.target.value))}
							square={true}
							label={
							<>
								<div className={ss.grid__img}>
									<img 
										src={getContentByFile(isTemplate, filesList[ind])} 
										alt='' 
										key={'mid03'+(ind+1)} 
									/>
								</div>
							</>}
						></Checkbox>
	
						<div className={ss.grid__fullScreen}  
							 onClick={() => handleShowPicture(!isTemplate ? filesList[ind] : filesList[ind].name)}
						>
							<Icon name="full-screen" />
						</div>
					</div>
				)
			);
	
			return options;
		};

		const question = questionList[pageNum]; 
		const answerOptions = question.answerOptions;
		const filesList = !isTemplate ? 
			getFiles(files, answerOptions.map(item => ({name: item}))) 
			: 
			answerOptions.map(item => ({name: item}));

		const options = filesList.length > 0 ? getRbImages(pageNum, answerOptions, filesList) : <></>;

		return (
			<div className={ss.section}>
				<div className={ss.row}>
					<div className={ss.info}>
						<Icon name="info" />
						Выберите один из ответов:
					</div>
				</div>

				<div className={ss.row}>
					<div className={ss.grid}>
						{options}
					</div>
				</div>

				<div className={ss.row}>
					{getNextQuestion(pageNum)}
				</div>

			</div>);
	};

	const getCompleteSentence = pageNum => {
		const question = questionList[pageNum]; 
		return (
			<div className={ss.section}>
				<div className={ss.row}>
					<div className={ss.info}>
						<Icon name="info" />
						Завершите предложение:
					</div>
				</div>

				{getRbBox(pageNum, question.answerOptions, false, '...')}

				<div className={ss.row}>
					{getNextQuestion(pageNum)}
				</div>
			</div>
		);	
	};

	const getFreeWriting = pageNum => {
		return (
			<div className={ss.section}>
				<div className={ss.row}>
					<div className={ss.info}>
						<Icon name="info" />
						Напишите текст:
					</div>
				</div>
				<div className={ss.row}>
					<div className={ss.textarea}>
						<span className="">{getUserText(pageNum)?.length}/{MAX_DESC_LENGTH}</span>
						<Textarea 
							autoComplete="off" 
							value={getUserText(pageNum)} 
							name="description" 
							rows="10" 
							onChange={e => handleChangeText(e.target.value, pageNum)} 
						/>
					</div>
				</div>
				<div className={ss.row}>
					{getNextQuestion(pageNum)}
				</div>
			</div>
		);	
	};

	const getVideoQuestion = pageNum => {
		const question = questionList[pageNum];
		//correct option contains video name!!
		const arr = question.correctOptions.split('|');
		const fileName = arr[1];
        const foundFiles = getFiles(files, [{name: fileName}]);
		if (foundFiles.length === 0) return <></>;
		const video = foundFiles[0];
  
		return (
			<div className={ss.section}>
				<div className={ss.row}>
					<div className={ss.info}>
						<Icon name="info" />
						Посмотрите видео и выберите один из ответов:
					</div>
				</div>

				{!!video && (
				<div className={ss.row +" "+ ss.row_center}>
					<div className={ss.col}>
						<div className={ss.video}>
							<video src={video.Url} controls={true} width="600" height="400" key={"v03"} ></video>
						</div>
					</div>
					<div className={ss.col}>
						{getRbBox(pageNum, question.answerOptions, true)}

						{/* <div className="otCreate__nextBtnQuestChooseOne">
								{getNextQuestion(pageNum)}
							</div> */}
					</div>
				</div>
				)}
				<div className={ss.row}>
					{getNextQuestion(pageNum)}
				</div>
			</div>			
		);
	};

    const getQuestionPage = pageNum => {
		const getQuestionName = pageNum => {
			const question = questionList[pageNum]; 
			const qType = Number(question.questionType); 
			let qName = question.questionName;
			let qAnswer = '';
			if (qType === QUESTION_TYPES.COMPLETE_SENTENCE) {
				qName += '... ';
				const answerId = answerList[pageNum];
				if (answerId !== '') {
					qAnswer = question.answerOptions[Number(answerId)];
				}
			}
			return [qName, qAnswer];
		};
	
		const qType = Number(questionList[pageNum].questionType); 
		const [qName, qAnswer] = getQuestionName(pageNum);
	
		return (
			<>
				<div className={ss.question}>
					<div className={ss.question__name}>
						{htmlParser(qName)} <span>{qAnswer}</span>
					</div>
				</div>

				{getIllustration(pageNum)}

				{(qType === QUESTION_TYPES.AUDIO) && getAudioQuestion(pageNum)}
				{(qType === QUESTION_TYPES.CORRECT_WORD_SEQUENCE) && getCorrectWordSequence(pageNum)}
				{(qType === QUESTION_TYPES.CORRECT_ANSWER_ONE) && getChooseOneAnswer(pageNum)}
				{(qType === QUESTION_TYPES.CORRECT_ANSWER_MANY) && getChooseManyAnswers(pageNum)}
				{(qType === QUESTION_TYPES.CORRECT_IMAGE_SEQUENCE) && getCorrectImageSequence(pageNum)}
				{(qType === QUESTION_TYPES.NON_VERBAL) && getNonVerbalQuestion(pageNum)}
				{(qType === QUESTION_TYPES.COMPLETE_SENTENCE) && getCompleteSentence(pageNum)}
				{(qType === QUESTION_TYPES.FREE_WRITING) && getFreeWriting(pageNum)}
				{(qType === QUESTION_TYPES.VIDEO) && getVideoQuestion(pageNum)}
			</>
		);
    }

	const getResultPage = () => {
		const studentName = isAuth().name;
		const questionNumber = questionList.length;
		const correctAnswerNumber = resultList.filter(item => item.isCorrect === 1).length;
		const nonCheckedAnswerNumber = resultList.filter(item => item.isCorrect === -1).length;

		return (
		<div className="labWorkShow">
			<div className="labWorkShow__head">
				<div className="otCreate__horizBorder">
					<span className="otCreate__titleGeneral"><b>Результаты тестирования</b></span>
					<span className="otCreate__titleGeneral otCreate__titleGeneralLight">&nbsp;{studentName}</span>
				</div>
			</div>

			<div className="otCreate__result">
                <div className="otCreate__result_Row">
                    <span className="otCreate__result_RowCol1">Название теста</span>
                    <span className="otCreate__result_RowCol2">{onlineTestData?.title}</span>    
                </div>
                <div className="otCreate__result_Row">
                    <span className="otCreate__result_RowCol1">Количество вопросов</span>
                    <span className="otCreate__result_RowCol2">{questionNumber}</span>    
                </div>
                <div className="otCreate__result_Row">
                    <span className="otCreate__result_RowCol1">Количество ответов</span>
                    <span className="otCreate__result_RowCol2">{resultList.filter(item => !!item.answer).length}</span>    
                </div>
                <div className="otCreate__result_Row">
                    <span className="otCreate__result_RowCol1">Количество правильных ответов</span>
                    <span className="otCreate__result_RowCol2">{correctAnswerNumber}</span>    
                </div>
				{!!nonCheckedAnswerNumber && (
                <div className="otCreate__result_Row">
                    <span className="otCreate__result_RowCol1">Количество ответов, требующих проверки вручную</span>
                    <span className="otCreate__result_RowCol2">{nonCheckedAnswerNumber}</span>    
                </div>
				)}
				{isAutoEstimateType && (
                <div className="otCreate__result_Row">
					<span className="otCreate__result_RowCol1">Оценка</span>
					<span className="otCreate__result_RowCol2">{calcScoreValue(correctAnswerNumber, questionNumber)}</span>    
				</div>
				)}
			</div>
		</div>);
	};

	const getInitState = () => {
		if (isTeacher()) return <></>;
		return (
			<div className="labWorkShow__first">
				<div className="labWorkShow__firstName">
					<span>Преподаватель не определил задание.</span>
				</div>
			</div>
		);
	};

	if (questionList.length === 0) {
		return getInitState();
	}

	if (currPage === questionList.length)
		return getResultPage();

    return (
		<>
		<ContentHead title={"Название теста: " + onlineTestData?.title} />
		<ContentDesc>Описание теста: {onlineTestData?.description}</ContentDesc>
		<Content>
			<ContentHeader flex={true} className={ss.header}>
				<div className={ss.header__nav}>
					{currPage < questionList.length ? 
						<>Вопрос { currPage+1 } / {questionList.length}</>
					: 
					<>Результаты онлайн теста</>
					}
				</div>
				<div className={ss.header__time}>
					{timeLimitEnabled ? showRemainingTime(currTestTime, startTime, 60 * timeLimitInMinutes, true) : 
					showCurrTime(currTestTime, startTime)}
				</div>
			</ContentHeader>

			<ContentBody>
				{getQuestionPage(currPage)}
			</ContentBody>

			<ContentFooter className={ss.footer}>
				{getActionButtons(currPage)}
			</ContentFooter>
			
			{showCompleteTestDlg &&
			<ModalFinishStudentTestDlg
				showConfirmDlg={showCompleteTestDlg} 
				handleNo={handleConfirmCompleteTestNo}
				handleYes={handleConfirmCompleteTestYes}
			/>
			}
			
			{showPictureDlg &&
			<ImageDlg
				showModal={showPictureDlg}
				setShowModal={setShowPictureDlg}
				file={fileUrl}
			/>
			}
		</Content>
		</>
    )
}

export default OnlineTestExecute;
