import React from 'react';
import Grid from '../ui/Grid';
import ss from './ClassGrid.module.scss';
import {selectclass, selectusers} from "../../redux/slices/classsline";
import {useSelector} from "react-redux";
import {fullName, isAuth} from "../../helpers/auth";
import {Icon} from "../ui";
import {storageClass} from "../../redux/slices/storage";
import {printNumbered} from "../../helpers/text";

const ClassGrid = (props) => {
    const documentClass = useSelector(storageClass);
    const [classs] = useSelector(selectclass);
    const [usersList] = useSelector(selectusers);

    const navigateClass = (id) => props.history.push("/members/" + id);
    const showTeachers = isAuth().role < 3;

    return (
        <Grid className={ss.root}>
            {classs.filter(c=>c.className.toLowerCase().includes(props.search.toLowerCase())).map((cl, i) => (
                <Grid.Col className={ss.item} key={i}>
                    <div className={(cl._id === documentClass._id ? ss.head_active : ss.head)}>
                        <span className={ss.name}>{cl.className}</span>
                        <Icon 
                            className={ss.gear}
                            name="gear" 
                            tp="Настройка"
                            onClick={() => props.showClassSettings({showModal: true, class: cl, allowDelete: isAuth().role === 3, isAdmin: isAuth().role === 3})}
                        />
                    </div>
                    <div className={ss.main}>
                        <ul className={ss.list}>
                            <li className={ss.list__item}>
                                <span>Количество</span>
                                <span>{printNumbered(cl.classLevel,['место','местa','мест'])}</span>
                            </li>
                            <li className={ss.list__item}>
                                <span>Описание</span>
                                <span>{cl.classDescription}</span>
                            </li>
                            {showTeachers && 
                            <li className={ss.list__item}>
                                <span>Учителя <b>({usersList.filter(u => u.role === 1 &&  u.status !==-1 && cl.classUsers.includes(u._id)).length})</b></span>
                                <span>{usersList.filter(u => u.role === 1 &&  u.status !==-1 && cl.classUsers.includes(u._id)).sort((a, b) => a.name.localeCompare(b.name)).map((u) => (fullName(u))).join(",")}</span>
                            </li>}
                        </ul>
                    </div>
                    {showTeachers && 
                    <div className={ss.bottom}>
                        <span className={ss.bottom__link} onClick={()=>navigateClass(cl._id)}>Редактировать состав класса</span>
                    </div>
                    }
                </Grid.Col>
            ))}
        </Grid>
    )
};
export default ClassGrid;
