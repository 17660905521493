import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from './redux';
import { listStateToProps, listDispatchToProps } from './reduxList';
import ChartsList from './Charts';
import ChartsSingle from './ChartArea';
import ChartsMulty from './ChartAreaMulty';
import ChartsParametric from './ChartAreaParametric';
import ChartLissague from './ChartAreaLissague';

const Charts = connect(listStateToProps, listDispatchToProps)(ChartsList);
const ChartArea = connect(mapStateToProps, mapDispatchToProps)(ChartsSingle);
const ChartAreaMulty = connect(mapStateToProps, mapDispatchToProps)(ChartsMulty);
const ChartAreaParametric = connect(mapStateToProps, mapDispatchToProps)(ChartsParametric);
const ChartAreaLissague = connect(mapStateToProps, mapDispatchToProps)(ChartLissague);

export { Charts, ChartArea, ChartAreaMulty, ChartAreaParametric, ChartAreaLissague };
