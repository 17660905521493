import { showModalInfo } from '../../../actions/actions';

export const mapStateToProps = (state) => {
    return {
        modal: state.actions.modalInfo,
        source: state.actions.source,
        needUpdate: state.actions.needUpdate,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    showModalInfo: (bool) => dispatch(showModalInfo(bool)),
});
