import React, { useEffect, useState } from 'react';
import { YMaps, Map, Placemark, GeolocationControl } from '@pbe/react-yandex-maps';
// Yandex Maps V3 эксперимент со стилями.
// import { YMap, YMapComponentsProvider, YMapDefaultSchemeLayer, YMapDefaultFeaturesLayer, YMapDefaultMarker } from 'ymap3-components';
// import mapStyles from './mapstyle.json';
// import { MapContainer, TileLayer, useMap, Marker } from 'react-leaflet';
// import 'leaflet/src/map/';
import ss from './MapView.module.scss';
const apikey = '3d583143-6c79-4df0-bbd3-7c34d3b00cd0';

const MapView = () => {
    const [location, setLocation] = useState();

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((location) => {
            const { latitude, longitude } = location.coords;
            setLocation([latitude, longitude]);
        });
        return () => setLocation(null);
    }, []);

    return (
        <div className={ss.root}>
            {location && (
                // <MapContainer
                //     center={location}
                //     zoom={13}
                //     scrollWheelZoom={false}
                // >
                //     <TileLayer
                //         attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                //         url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                //     />
                //     <Marker position={location}></Marker>
                // </MapContainer>
                <YMaps
                    query={{
                        apikey,
                    }}
                >
                    <Map
                        defaultState={{
                            center: location,
                            zoom: 10,
                            type: 'yandex#satellite',
                            controls: [],
                        }}
                        options={{
                            autoFitToViewport: 'always',
                            suppressMapOpenBlock: true,
                        }}
                    >
                        <GeolocationControl options={{ float: 'left' }} />
                        <Placemark
                            modules={['geoObject.addon.balloon']}
                            geometry={location}
                            properties={{
                                balloonContentBody: `Широта: ${location[0]} <br>Долгота: ${location[1]}`,
                            }}
                        />
                    </Map>
                </YMaps>
            )}
        </div>
    );
};
export default MapView;
