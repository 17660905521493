import {reloadOption} from "../../../redux/slices/storage";

export const mapStateToProps = (state) => {
    return ({
    });
};

export const mapDispatchToProps = (dispatch) => ({
    setTheme: (str) => dispatch(reloadOption('theme',str)),
});
