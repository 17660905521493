const initialState = {
    all: [],
    names: [],
    connected: [],
};

const deviceReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ALL_NDEVICES':
            return {
                ...state,
                all: action.payload,
            };

        case 'SET_NDEVICES_NAMES':
            return {
                ...state,
                names: action.payload,
            };

        case 'SET_NDEVICES_CONNECTED':
            return {
                ...state,
                connected: action.payload,
            };

        default:
            return state;
    }
};

export default deviceReducer;
