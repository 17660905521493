import React, {useState, useEffect, useRef} from "react";
import {
	CALC_MODEL, SCALEPOS, SAVE_DATA_TYPE, GraphicOpts, burdenOptions, getCurrentParams,
	getInputRange, getLine, getArrow, 
	getCurveValues, getXYByTime, getFlightTimeWithoutAir, getFlightTimeWithtAir, getParabolaTangent,
	getSvgWithHeightWidth, getGrid, getInnerBorder, getDashedCurve,
	getScaleData, getScaleValues, getStrokes, getCircle, getScaleTitle,
	getColoredInnerBox, getScreenAreaPictureById, printDiv,
	getHeaderBox, getModeColorOpts, getExportCB, addCalcModelInfo, getCalcModelInfoById,
	canStartAction, completeAction} from "./cm_utils";
import {getRoundValue, getDropdown} from "../ui/utils/gen_utils";
import {isAuth} from "../../helpers/auth";
import { saveExcel } from './excel_utils';
import SaveXlsFileConfirmDialog from "../ui/ModalDialogs/SaveXlsFileConfirmDialog";
import {storageClass} from "../../redux/slices/storage";
import {useSelector, useDispatch} from "react-redux";
import {reloadOption} from "../../redux/slices/storage";
import { Button, Icon } from '../ui';
import { Content, ContentBody, ContentFooter } from "../template/ContentParts";
import {toast} from "react-toastify";
import "./CalcModel.scss";
import Styles from './MechanicMove.module.scss';

const DEBOUNCE_PERIOD = 250;
const CURVE_NUMBER = 3;

const VELOCITY_INPUT_RANGE = [1, 315, 10, 'м/сек'];
const ANGLE_INPUT_RANGE = [1, 89, 45, '°'];
const HEIGHT_INPUT_RANGE = [0, 10, 0, 'м']; //0, 10, 0, 

const calculationOptions = [
	{label: 'С учетом сопротивления воздуха', value: 1},
	{label: 'Без учета сопротивления воздуха', value: 2},
];

const EXPERIMENT_NAME = 'Механические явления';

const MechanicMove = ({history, match, isInnerCM, isLightMode }) => {
	const [duration, setDuration] = useState(0.5);
	const [velocity, setVelocity] = useState(VELOCITY_INPUT_RANGE[2]);
	const [angle, setAngle] = useState(ANGLE_INPUT_RANGE[2]);
	const [height, setHeight] = useState(HEIGHT_INPUT_RANGE[2]);
	const [calcOptionId, setCalcOptionId] = useState(1);
	const [burdenOptionId, setBurdenOptionId] = useState(1);

	const [curveParams, setCurveParams] = useState([]);
	const [isCurveInitiated, setIsCurveInitiated] = useState(false);
	const [currCurveInd, setCurrCurveInd] = useState(0);

	const [isParamChanged, setIsParamChanged] = useState(false);
	const [curveData, setCurveData] = useState(undefined);
	const [timePeriod, setTimePeriod] = useState(1);

	const [svgInfo, setSvgInfo] = useState(undefined); //preliminary svg info
	const [svgArr, setSvgArr] = useState([]); //svg to show
	const [svgWidth, setSvgWidth] = useState(undefined);
	const [svgHeight, setSvgHeight] = useState(undefined);
	const [isReadyToSaveExperiment, setIsReadyToSaveExperiment] = useState(false);
	const [experimentResults, setExperimentResults] = useState([]);
	const [svg, setSvg] = useState(undefined);
	const [makePrint, setMakePrint] = useState(false);
	const [makeXls, setMakeXls] = useState(false);
	const [isLightModeChanged, setIsLightModeChanged] = useState(false);
	const [saveDataType, setSaveDataType] = useState(SAVE_DATA_TYPE.XLS);

	const [resize, setResize] = useState(0);
	const [showConfirmSaveXlsDlg, setShowConfirmSaveXlsDlg] = useState(false);

    const documentClass = useSelector(storageClass);
	const dispatch = useDispatch();
	const refMainBox = useRef();
	const refMainSvg = useRef();
	const durationRef = useRef();
	const angleRef = useRef();
	const velocityRef = useRef();
	const heightRef = useRef();

	const debounceDurationInProgress = useRef();
	const debounceAngleInProgress = useRef();
	const debounceVelocityProgress = useRef();
	const debounceHeightProgress = useRef();

	useEffect(() => {
		if (match.params.opt) {
			getCalcModelInfoById(match.params.opt).then((data) => { //unpack saved data
				const arr = data[0].optionArr;
				const cpList = [];
				for (let i = 0; i < arr.length; i ++) {
					const opt = arr[i];
					const _curve = getCurrentParams(opt.velocity, opt.angle, opt.height, opt.calcOptionId, opt.burdenOptionId);
					cpList.push(_curve);
				}
				setCurveParams(cpList);
				const c = cpList[0];
				setVelocity(c.velocity);
				setAngle(c.angle);
				setHeight(c.height);
				setCalcOptionId(c.calcOptionId);
				setBurdenOptionId(c.burdenOptionId);

				setIsCurveInitiated(true);
				setIsParamChanged(true);
			});
		}
	}, [match.params.opt]);

	useEffect(() => {
		const handleResize = e => setResize(e[0].contentBoxSize[0].inlineSize);
		const observer = new ResizeObserver(handleResize);
		observer.observe(refMainBox.current);
		return () => {
			observer.disconnect();
		};
	}, []);

	useEffect(() => {
		//set svg box size:
		const innerMainR = document.getElementById('idInnerMainBox')?.getBoundingClientRect();
		const svgR = document.getElementById('mainSvg')?.getBoundingClientRect();
		const width = (innerMainR.width - (svgR.left - innerMainR.left));
		//
		const commonR = document.getElementById('idCommon')?.getBoundingClientRect();
		const buttonsR = document.getElementById('idButtons')?.getBoundingClientRect();
		
		const height = innerMainR.height - commonR.height - buttonsR.height;
		setSvgWidth(width);
		setSvgHeight(height);
	}, [resize]);

	useEffect(() => { //инициализируем первую кривую
		if (isCurveInitiated) return;
		const _curve = getCurrentParams(velocity, angle, height, calcOptionId, burdenOptionId);
		setCurveParams([_curve]);
		setIsCurveInitiated(true);
	},[angle, burdenOptionId, calcOptionId, height, isCurveInitiated, velocity]);

	useEffect(() => { //меняем параметры текущей кривой
		if (!isParamChanged) return;
		const _curve = getCurrentParams(velocity, angle, height, calcOptionId, burdenOptionId)
		const _curveParams = curveParams.map((item, ind) => ind !== currCurveInd ? item : _curve);
		setCurveParams(_curveParams);
		setIsParamChanged(false);
	},[isParamChanged, angle, burdenOptionId, calcOptionId, curveParams, height, velocity, currCurveInd]);

	useEffect(() => { //prepare preliminary svg info
		const getMovementGraphics = (_svgWidth, _svgHeight, _curveParams, _curveData, _duration, _isLightMode) => {
			const getScreenX = (x, _width, xSize) => aLeft + _width * x / xSize;
			const getScreenY = (y, _height, ySize) => aTop + _height * (ySize - y) / ySize;
			const getXList = (_curve, _width, xSize) => _curve.map(item => getScreenX(Number(item[0]), _width, xSize));
			const getYList = (_curve, _height, ySize) => _curve.map(item => getScreenY(Number(item[1]), _height, ySize));
			const getCurveLineBallTangent = (curveInd, _curveParams, _curveData, _curveColors, 
				_duration, _width, _height, _xSize, _ySize) => {
				const ARROW_SIZE_ALONG = 15;
				const ARROW_SIZE_OPPOSITE = 7;
				const curve = _curveData.curveCoords[curveInd];
				const color = go.curveColors[curveInd];
				const cv = getCurveValues(_curveParams[curveInd]);
	
				//1 - line
				const curveLine = getDashedCurve(
					getXList(curve, _width, _xSize), 
					getYList(curve, _height, _ySize), 
					_curveColors[curveInd], "2", "curve0"+curveInd);
				
				const [ballX, ballY] = getXYByTime(_curveParams[curveInd], cv, _duration);
				let curveCircle = undefined;
				let curveTangent = undefined;
				let curveArrow = undefined;
			
				if (ballY >= 0) { //ballX <= width && 
					const [ballXPos, ballYPos] = [getScreenX(ballX, _width, _xSize), getScreenY(ballY, _height, _ySize)];
	
					//2 - ball
					curveCircle = getCircle(ballXPos, ballYPos, 
						go.curveCircleRadius, _curveColors[curveInd], _curveColors[curveInd], 0.9, 1, "ccircle"+curveInd);
						
					//3 - tangent
					const [tangentX, tangentY] = getParabolaTangent(_curveParams[curveInd], cv, _duration, ballX, ballY, _xSize, _ySize);
					const [tangentXPos, tangentYPos] = [getScreenX(tangentX, _width, _xSize), getScreenY(tangentY, _height, _ySize)];
					curveTangent = getLine(ballXPos, ballYPos, tangentXPos, tangentYPos, color, 3, 'tangent'+curveInd);
	
					//4 - arrow
					const arrow = getArrow(ARROW_SIZE_ALONG, ARROW_SIZE_OPPOSITE, [ballXPos, ballYPos], [tangentXPos, tangentYPos]); 
					curveArrow = <path key={"linearr"+curveInd} d={arrow} fill={color} stroke={color} strokeWidth={2}/>
				}
	
				//5 - initTangent
				const [initPointX, initPointY] = getXYByTime(_curveParams[curveInd], cv, 0);
				const [initPointXPos, initPointYPos] = [getScreenX(initPointX, _width, _xSize), getScreenY(initPointY, _height, _ySize)];
				const [initTangentX, initTangentY] = getParabolaTangent(_curveParams[curveInd], cv, 0, initPointX, initPointY, _xSize, _ySize);
				const [initTangentXPos, initTangentYPos] = [getScreenX(initTangentX, _width, _xSize), getScreenY(initTangentY, _height, _ySize)];
				const curveInitTangent = getLine(initPointXPos, initPointYPos, initTangentXPos, initTangentYPos, color, 3, 'tangentInit'+curveInd);
	
				//6 - init arrow
				const initArrow = getArrow(ARROW_SIZE_ALONG, ARROW_SIZE_OPPOSITE, [initPointXPos, initPointYPos], [initTangentXPos, initTangentYPos]); 
				const curveInitArrow = <path key={"initlinearr"+curveInd} d={initArrow} fill={color} stroke={color} strokeWidth={2}/>
	
				//7 - result
				return [curveLine, curveCircle, curveTangent, curveArrow, curveInitTangent, curveInitArrow];
			};
	
		if (_curveData.curveCoords.length === 0) return;
			const go = GraphicOpts;
			const [dX, dY] = _curveData.maxValues;
	
			//1 - axis titles:
			const leftYAxisTitle = getScaleTitle(SCALEPOS.left, go.gapHoriz * 0.2 + 2, _svgHeight * 0.6, 
				"Высота y (метры)", go.heightScaleColor, "xaxis");
			const bottomAxisTitle = getScaleTitle(SCALEPOS.horiz, go.gapHoriz + 0.43 *_svgWidth, _svgHeight-8, 
				"Расстояние x  (метры)", go.distanceScaleColor, "yaxis");
	
			const aLeft = go.gapHoriz+10;		//contains left gap
			const aTop = go.gapTop; 			//contains top gap
			const aRight = go.gapHoriz; 		//contains right gap
			const aBottom = go.gapBottom+10; 	//contains bottom gap
			const aWidth = _svgWidth - aLeft - aRight;
			const aHeight = _svgHeight - aTop - aBottom;
	
			//2 - boxes:
			const bkndColor = getModeColorOpts(_isLightMode).calcModelBknd;
			const background = getColoredInnerBox(aLeft, aTop, aWidth, aHeight, bkndColor, bkndColor, 
				go.backgroundOpacity, "bknd01");
			const innerBox = getInnerBorder(aLeft, aTop, aWidth, aHeight, go.innerBoxColor, "rect");
			
			//3 - curves:
			const [curve1Line, curve1Circle, curve1Tangent, curve1Arrow, curve1InitTangent, curve1InitArrow] = 
				getCurveLineBallTangent(0, _curveParams, _curveData, go.curveColors, _duration, aWidth, aHeight, 
					dX, dY);
	
			const [curve2Line, curve2Circle, curve2Tangent, curve2Arrow, curve2InitTangent, curve2InitArrow] = 
				_curveData.curveCoords.length > 1 ? getCurveLineBallTangent(1, 
					_curveParams, _curveData, go.curveColors, _duration, aWidth, aHeight, dX, dY) :
					[undefined, undefined, undefined, undefined, undefined, undefined];
	
			const [curve3Line, curve3Circle, curve3Tangent, curve3Arrow, curve3InitTangent, curve3InitArrow] = 
				_curveData.curveCoords.length > 2 ? getCurveLineBallTangent(2, 
					_curveParams, _curveData, go.curveColors, _duration, aWidth, aHeight, dX, dY) :
					[undefined, undefined, undefined, undefined, undefined, undefined];
		
			//4 - scales:
			const xScaleArr = getScaleData(SCALEPOS.horiz, SCALEPOS.left, go.horizStrokeNUM, 0, dX, 
				aLeft, aWidth, _svgHeight - aBottom, 
				go.scaleLargeSize, go.scaleSmallSize, go.elDynSmallStrokeNum);
			const xStrokes = getStrokes(xScaleArr, go.strokeColor, 1, 0.9, "xscale");
			const xScaleValues = getScaleValues(xScaleArr, SCALEPOS.horiz, SCALEPOS.left, go.timeColor, "time1");
	
			const yScaleArr = getScaleData(SCALEPOS.vert, SCALEPOS.left, go.vertStrokeNUM, 0, dY, 
				aTop, aHeight, aLeft,
				go.scaleLargeSize, go.scaleSmallSize, go.elDynSmallStrokeNum);
			const yStrokes = getStrokes(yScaleArr, go.strokeColor, 1, 0.9, "yscale");
			const yScaleValues = getScaleValues(yScaleArr, SCALEPOS.vert, SCALEPOS.left, go.heightScaleColor, "horiz1");
	
			//5 - grid:
			const xGrid = getGrid(xScaleArr, SCALEPOS.horiz, aLeft, aTop, aWidth, aHeight, 
				getModeColorOpts(_isLightMode).gridColor, go.gridWidth, go.gridOpacity);
			const yGrid = getGrid(yScaleArr, SCALEPOS.vert, aLeft, aTop, aWidth, aHeight, 
				getModeColorOpts(_isLightMode).gridColor, go.gridWidth, go.gridOpacity);
	
			const curves = [curve1Line, curve2Line, curve3Line, 
				curve1Circle, curve2Circle, curve3Circle, 
				curve1Tangent, curve2Tangent, curve3Tangent, 
				curve1Arrow, curve2Arrow, curve3Arrow, 
				curve1InitTangent, curve2InitTangent, curve3InitTangent, 
				curve1InitArrow, curve2InitArrow, curve3InitArrow];
			const grid = [
				leftYAxisTitle, bottomAxisTitle, 
				background, 
				yStrokes, xStrokes, 
				...yScaleValues, ...xScaleValues, 
				yGrid, xGrid, 
				innerBox
			];
			const _svgInfo = {
				curves: curves,
				grid: grid,
			};
			return _svgInfo;
		};

		if (!svgWidth || !svgHeight) return;
		const _curveData = getCurveData(curveParams);
		setCurveData(_curveData);

		const _svgInfo = getMovementGraphics(svgWidth, svgHeight, curveParams, _curveData, duration, isLightMode);
		setSvgInfo(_svgInfo);
	}, [curveParams, duration, svgHeight, svgWidth, isLightMode]);

	useEffect(() => { //prepare final svg data to show
		if (!svgInfo) return;
		const svgData = [].concat(svgInfo.grid, svgInfo.curves); //, svgInfo.legend); 
		setSvgArr(svgData);
	}, [svgInfo]);

	//Расчетная модель Мехянические явления
	const getCurveData = (_curveParams) => {
		let maxTime = -1;

		for (let i = 0; i < _curveParams.length; i ++) {
			const time = !_curveParams[i].isWithAir ? 
				getFlightTimeWithoutAir(_curveParams[i]) : 
				getFlightTimeWithtAir(_curveParams[i]);

			if (maxTime < time) {
				maxTime = time;
			}
		}

		const MAX_SIZE = 200;
		const timeStep = maxTime / MAX_SIZE;
		
		const xyArrs = [];
		const maxCurveValues = [];
		let xAbsMax = -1;
		let yAbsMax = -1;
		let correctedMaxTime = -1;

		for (let i = 0; i < _curveParams.length; i ++) {
			const cv = getCurveValues(_curveParams[i]);
			const xyArr = [];
			let xCurveMax = -1;
			let yCurveMax = -1;
			let stop = false;
			let k = 0;

			//for (let k = 0; k <= MAX_SIZE && !stop; k ++) {
			while (!stop) {
				const time = k * timeStep;

				const [x, y] = getXYByTime(_curveParams[i], cv, time);

				if (y >= 0) {
					xyArr.push([x, y]);
					if (xCurveMax < x) xCurveMax = x;
					if (yCurveMax < y) yCurveMax = y;
				} else {
					if (correctedMaxTime < time) correctedMaxTime = time;
					xyArr.push([x, 0]);
					stop = true;
				}
				k ++;
			}

			if (xAbsMax < xCurveMax) xAbsMax = xCurveMax;
			if (yAbsMax < yCurveMax) yAbsMax = yCurveMax;
			xyArrs.push(xyArr);

			maxCurveValues.push([xCurveMax, yCurveMax]);
		}

		const data = {
			curveCoords: xyArrs,
			maxValues: [Math.ceil(xAbsMax), Math.ceil(yAbsMax)],
			maxCurveValues: maxCurveValues,
		};
		//console.log('correctedMaxTime=', correctedMaxTime, ' maxTime=', maxTime);
		setTimePeriod(correctedMaxTime);
		return data;
	};

	//-------------------------------------------------
	const getSvg = (obj) => getSvgWithHeightWidth(svgHeight, svgWidth, obj, 'svg1');

	const saveExport = (name, description) => {
        if (saveDataType === SAVE_DATA_TYPE.PROFILE) {
            saveProfile(name, description);
        } else {
            saveXLSX(name, description);
        }
    };

	const saveProfile = async (name, description) => {
		const list = [];
		for (let i = 0; i < curveParams.length; i ++) {
			const c = curveParams[i];
			list.push({
				duration: duration, 
				velocity: c.velocity, 
				angle: c.angle, 
				height: c.height, 
				calcOptionId: c.calcOptionId,
				burdenOptionId: c.burdenOptionId
			});
		}
	
        const profile = {
            owner: isAuth()._id,
			room: documentClass._id,
            cmType: CALC_MODEL.MECHANIC_MOVE,
            name: name,
            description: description,
			optionArr: list,
        };
        await addCalcModelInfo(profile);
    };

	const saveXLSX = (workBookName, description) => {
		const getTableColumns = () => {
			return [
				{ header: 'Номер кривой', key: 'curvenumber' },
				{ header: 'Описание эксперимента', key: 'description' },
				{ header: 'Время, сек', key: 'duration' },
				{ header: 'Название предмета', key: 'name' },
				{ header: 'Скорость, м/сек', key: 'velocity' },
				{ header: 'Угол, °', key: 'angle' },
				{ header: 'Начальная высота, м', key: 'height'},
				{ header: 'С учетом сопротивления воздуха', key: 'air' },
				{ header: 'Максимальная высота, м', key: 'maxheight'},
				{ header: 'Расстояние, м', key: 'maxlength'},
			];
		};
		const getTableData = (experiment) => {
			const tableData = [];
			for (let i = 0; i < experiment.curveParams.length; i++) {
				const curve = experiment.curveParams[i];
				const maxCurveValues = experiment.curveData.maxCurveValues[i];
				const record = {
					curvenumber: (i + 1),
					description: i === 0 ? description : '',
					duration: i === 0 ? getRoundValue(experiment.duration, 2) : '',
					name: curve.name,
					velocity : getRoundValue(curve.velocity, 2),
					angle: getRoundValue(curve.angle, 2),
					height: getRoundValue(curve.height, 2),
					air: curve.isWithAir ? 'Да' : 'Нет',
					maxheight: getRoundValue(maxCurveValues[1], 2),
					maxlength: getRoundValue(maxCurveValues[0], 2),
				};
				tableData.push(record);
			}
			return tableData;
		};
		const getGraphicData = (experiment) => {
			return [{
				image: experiment.svg,
				colNum: 10,
				rowNum: 20,
				initRow: 6, //it means we will draw the image just after the table on the row 4
			}];
		};

		if (experimentResults.length === 0) return;
		const sheets = [];
		for (let i = 0; i < experimentResults.length; i ++) {
			const tableSheet = {
				workSheetName: "" + (i+1) + " - " + EXPERIMENT_NAME,
				columns: getTableColumns(),
				tableData: getTableData(experimentResults[i]),
				graphicData: getGraphicData(experimentResults[i]),
			};
			sheets.push(tableSheet);
		}

		saveExcel(workBookName, sheets, []);
		setExperimentResults([]);
	};

	const handleProfile = () => {
		setSaveDataType(SAVE_DATA_TYPE.PROFILE);
        setShowConfirmSaveXlsDlg(true);
	};

	const handleExportExperiment = () => {
		setSaveDataType(SAVE_DATA_TYPE.XLS);
		//step 1 of 3: change light mode if needed and request to save svg
		canStartAction(isLightMode, dispatch, reloadOption, setIsLightModeChanged, setMakeXls);
	};

	useEffect(() => {
		if (!makeXls) return;
		//step 2 of 3: save svg and request to calc expResults
		getScreenAreaPictureById("mainSvg", img => {setSvg(img); setIsReadyToSaveExperiment(true);});
		//change bknd mode back:
		setTimeout(() => completeAction(dispatch, setMakeXls, isLightModeChanged, setIsLightModeChanged, reloadOption), 100);
	}, [dispatch, isLightModeChanged, makeXls]);


	useEffect(() => {
		//step 3 of 3: prepare expResult and run the export dlg
		if (!isReadyToSaveExperiment) return;
		
		const expResult = {
			curveParams: curveParams,
			curveData: curveData,
			duration: duration,
			svg: svg,
		};
		setIsReadyToSaveExperiment(false);
		const exps = experimentResults.map(item => item);
		exps.push(expResult);
		setExperimentResults(exps);
		toast.success("Текущий эксперимент сохранен."); 
		setShowConfirmSaveXlsDlg(true);
	}, [curveParams, curveData, experimentResults, isReadyToSaveExperiment, svg, duration]);

	const handleCancelSave = () => {
		setExperimentResults([]);
		setShowConfirmSaveXlsDlg(false);
	};

	const handlePrint = () => {
		canStartAction(isLightMode, dispatch, reloadOption, setIsLightModeChanged, setMakePrint);
	};

    useEffect(() => {
		if (!makePrint) return;

		const fillPrintData = (ind, curve, maxCurveValues, arr) => {
			arr.push(['Номер кривой: ', '' + (ind + 1)]);
			arr.push(['Название предмета: ', curve.name]);

			arr.push(['Скорость, м/сек: V = ', getRoundValue(curve.velocity, 2)]);
			arr.push(['Угол, °: R = ', getRoundValue(curve.angle, 2)]);
			arr.push(['Начальная высота, м: H = ', getRoundValue(curve.height, 2)]);

			arr.push(['Максимальная высота, м = ', getRoundValue(maxCurveValues[1], 2)]);
			arr.push(['Расстояние, м = ', getRoundValue(maxCurveValues[0], 2)]);
			arr.push(['&nbsp;', '&nbsp;']);
		};

		const printData = [
			['Время, сек: ', 't = ' + getRoundValue(duration, 1)],
			['С учетом сопротивления воздуха: ', curveParams[0].isWithAir ? 'Да' : 'Нет'],
			['&nbsp;', '&nbsp;'],
		];

		const curveData = getCurveData(curveParams);
		for (let i = 0; i < curveParams.length; i ++) {
			fillPrintData(i, curveParams[i], curveData.maxCurveValues[i], printData);
		}

		printDiv(['mainSvg'], EXPERIMENT_NAME, printData, ["./CalcModel.scss"]);
		setTimeout(() => completeAction(dispatch, setMakePrint, isLightModeChanged, setIsLightModeChanged, reloadOption), 100);
	}, [dispatch, makePrint, isLightModeChanged, duration, velocity, angle, height, curveParams]);

	const handleAddTrace = () => {
		const _curve = getCurrentParams(velocity, angle, height, calcOptionId, burdenOptionId);
		const curves = [...curveParams];
		curves.push(_curve);
		setCurveParams(curves);
		setCurrCurveInd(curves.length - 1);
	};

	const handleDeleteTrace = () => {
		setCurveParams(curveParams.filter((item, ind) => ind !== currCurveInd));
		setCurrCurveInd(currCurveInd > 1 ? currCurveInd - 1 : 0);
	};

	const handleCalculationOption = value => {
		setCalcOptionId(value);
		setIsParamChanged(true);
	}; 

	const handleBurdenOption = value => {
		setBurdenOptionId(value);
		if (value === 2 || value === 3) {
			setVelocity(20);
		}
		setIsParamChanged(true);
	};

	const handleDuration = value => { //throttle and debounce https://dev.to/andreysm/ispolzuiem-throttle-i-debounce-v-react-3cn9
		if(debounceDurationInProgress.current) clearTimeout(debounceDurationInProgress.current);
		debounceDurationInProgress.current = setTimeout(() => {setDuration(value); setIsParamChanged(true);}, DEBOUNCE_PERIOD);		
	};
	const handleAngle = value => {
		if(debounceAngleInProgress.current) clearTimeout(debounceAngleInProgress.current);
		debounceAngleInProgress.current = setTimeout(() => {setAngle(value); setIsParamChanged(true);}, DEBOUNCE_PERIOD);		
	};
	const handleVelocity = value => {
		if(debounceVelocityProgress.current) clearTimeout(debounceVelocityProgress.current);
		debounceVelocityProgress.current = setTimeout(() => {setVelocity(value); setIsParamChanged(true);}, DEBOUNCE_PERIOD);
	};
	const handleHeight = value => {
		if(debounceHeightProgress.current) clearTimeout(debounceHeightProgress.current);
		debounceHeightProgress.current = setTimeout(() => {setHeight(value); setIsParamChanged(true);}, DEBOUNCE_PERIOD);		
	};

	const getColumnOpts = () => { 
		const durationData = (durRangeOpt) => {
			return {
				value: duration,
				ref: durationRef,
				handleChange: e => handleDuration(Number(e.target.value)),
				min: 0,
				max: timePeriod,
				step: 0.01 * timePeriod,
				setValue: setDuration,
				key: 'dur001' + durRangeOpt,
				disabled: false,
			};
		};

		const getMaxVelocity = () => {
			return (curveParams.length >= currCurveInd+1) && (curveParams[currCurveInd].burdenOptionId  === 2 || curveParams[currCurveInd].burdenOptionId  === 3) ? 40 : 315;
		}
		const angleData = {
			value: angle,
			ref: angleRef,
			handleChange: e => handleAngle(Number(e.target.value)),
			min: ANGLE_INPUT_RANGE[0],
			max: ANGLE_INPUT_RANGE[1],
			step: 0.5,
			setValue: v => handleAngle(Number(v)),
			key: 'angle01',
			disabled: false,
		};
		const velocityData = {
			value: !!velocity ? velocity : VELOCITY_INPUT_RANGE[2],
			ref: velocityRef,
			handleChange: e => handleVelocity(Number(e.target.value)),
			min: VELOCITY_INPUT_RANGE[0],
			max:  getMaxVelocity(),
			step: 0.5,
			setValue: v => handleVelocity(Number(v)),
			key: 'vel01',
			disabled: false,
		};
		const heightData = {
			value: height,
			ref: heightRef,
			handleChange: e => handleHeight(Number(e.target.value)),
			min: HEIGHT_INPUT_RANGE[0],
			max: HEIGHT_INPUT_RANGE[1],
			step: 0.5,
			setValue: v => handleHeight(Number(v)),
			key: 'hght01',
			disabled: false,
		};

		return (
			<div className={Styles.ranges}>
				<div className={Styles.range}>
					<div className={Styles.label}>
						Время <b>t = {getRoundValue(duration, 1)} сек</b>
					</div>
					{getInputRange(durationData(0))}
				</div>
				<div className={Styles.range}>
					<div className={Styles.label}>
						Угол ({ANGLE_INPUT_RANGE[0]}-{ANGLE_INPUT_RANGE[1]}) <b>R = {getRoundValue(angle, 0)} °</b>
					</div>
					{getInputRange(angleData)}
				</div>
				<div className={Styles.range}>
					<div className={Styles.label}>
						Скорость ({VELOCITY_INPUT_RANGE[0]}-{getMaxVelocity()}) <b>V = {getRoundValue(velocity, 2)} {VELOCITY_INPUT_RANGE[3]}</b>
					</div>
					{getInputRange(velocityData)}
				</div>
				<div className={Styles.range}>
					<div className={Styles.label}>
						Высота ({HEIGHT_INPUT_RANGE[0]}-{HEIGHT_INPUT_RANGE[1]}) <b>H = {getRoundValue(height, 1)} м</b>
					</div>
					{getInputRange(heightData)}
				</div>
			</div>	
		);
	};

	const getSelectOpts = () => {
		const opt = burdenOptions.find(item => item.value === burdenOptionId);

		return (
			<div className={Styles.chooses}>
				<div className={Styles.choose}>
					<div className={Styles.label}>Объект движения</div>
					{getDropdown(burdenOptions, burdenOptionId, handleBurdenOption, '', '', 'burd01')}
				</div>
				<div className={Styles.chooseInfo}>
					<div className={Styles.label}><span>m</span><b>{opt.weight}кг</b></div>
					<div className={Styles.label}><span>d</span><b>{opt.diameter}м</b></div>
				</div>

				<div className={Styles.choose}>
					<div className={Styles.label}>Режим</div>
					{getDropdown(calculationOptions, calcOptionId, handleCalculationOption, ' ', '', 'air01')}
				</div>
			</div>
		);
	};

	const getEquations = () => {
		const isWithAir = calcOptionId === 1;
		return (
			<div className={Styles.equations}>
				<div className={Styles.equation}>
					<div className={Styles.label}>Уравнение для горизонтальной оси</div>
					{
					!isWithAir ?
						<span>
							x(t) = U<sub>0x</sub>t
						</span>
					:
						<span>
							x(t) = U<sub>0x</sub>t - |a<sub>x</sub>t<sup>2</sup>/2|
						</span>
					}
				</div>
				<div className={Styles.equation}>
					<div className={Styles.label}>Уравнение для вертикальной оси</div>
					{
					!isWithAir ?
						<span>
							y(t) = h<sub>0</sub> + U<sub>0y</sub>t - gt<sup>2</sup>/2
						</span>
					:
						<span>
							y(t) = h<sub>0</sub> + U<sub>0y</sub>t - gt<sup>2</sup>/2 - |a<sub>y</sub>t<sup>2</sup>/2|
						</span>
					}
				</div>
			</div>
		);
	};

	const getLegendText = (_curveParams, _time, ind) => {
		const xy = _curveParams.length > ind ? getXYByTime(_curveParams[ind], getCurveValues(_curveParams[ind]), _time) : 0;
		const [rX, rY] = [getRoundValue(xy[0], 1), getRoundValue(xy[1], 1)];
		return 'y = ' + (Number(rY) >= 0 ? rY : '0') + ' м  ' + 
		'x = ' + (Number(rX) >= 0 ? rX : '0') + ' м';
    }

	const handleSelectCurve = ind => {
		if (currCurveInd === ind) return;
		setCurrCurveInd(ind);

		const cp = curveParams[ind];
		setVelocity(cp.velocity);
		setAngle(cp.angle);
		setHeight(cp.height);
		setCalcOptionId(cp.calcOptionId);
		setBurdenOptionId(cp.burdenOptionId);
	};

	const colorCircle = (index) => {
        switch (index) {
            case 0: return "#F75656";
            case 1: return "#00DA98";
            case 2: return "#9647FB";
            default: return "";
        }
    };

	const getCurveControls = () => { //текст в левом нижнем углк
		if (!curveData) return <></>;

		return (
			<>
				<div className={Styles.coord}>
					{curveData.curveCoords.map((item, ind) => 
					<label key={"rbkey"+ind} >
						<input type="checkbox" 
							className='checkbox_square'
							id={ind} 
							value={ind}   
							key={"inp"+ind}
							name={getLegendText(curveParams, duration, ind)}
							checked={ind === currCurveInd} 
							onClick={(e) => handleSelectCurve(Number(e.target.value))} 
							onChange={() => {}}
							>
						</input>
						<span className={Styles.sircle} style={{background: colorCircle(ind)}} 
							key={"sp1"+ind}
						/>
						<span className={Styles.coordText} key={"sp2"+ind}>
							{getLegendText(curveParams, duration, ind)}
						</span>
					</label>
					)}
				</div>
				<div className={Styles.coord__list}>
					{curveData.curveCoords.map((item, ind) => 
						<div className={Styles.coord__item} key={"cd1"+ind}>
							<span className={Styles.sircle} style={{background: colorCircle(ind)}}
								key={"cd2"+ind}
							/>
							<span className={Styles.coordText} key={"cd3"+ind}>
								{getLegendText(curveParams, duration, ind)}
							</span>
						</div>
					)}
				</div>
			</>
		);
	};

	const getControlOptions = () => {
		return (
			<div className={Styles.content}>
				{getColumnOpts()}
				{getSelectOpts()}
				{getEquations()}
			</div>
		);
	};

	const getButtons = () => {
		return (
			<>
				<div className={Styles.action}>
					{curveParams.length < CURVE_NUMBER &&
						<Button onClick={handleAddTrace}
							className="cor_btn_add"
							size={'extraSmall'}
						>
							<Icon name="plus_bold"/>
							Добавить траекторию
						</Button>
					}

					{curveParams.length > 1 &&
						<Button onClick={handleDeleteTrace}
							className="cor_btn_add"
							size={'extraSmall'}
						>
							<Icon name="minus"/>
							Удалить траекторию
						</Button>
					}
				</div>

				<div className="calcModel__stretch">
					<div className="calcModel__exportBtnComment">Работа с файлами</div>
					<div className="calcModel__leftBottomBtn">
						{getExportCB(handleProfile, handleExportExperiment, isInnerCM)}
					</div>
					<div>
						<Icon name="print" className=" calcModel__printBtn" onClick={handlePrint}/>
					</div>
				</div>
			</>
		);
	};

	return (
		<>
			{getHeaderBox(history, match.params.id, isInnerCM, '', EXPERIMENT_NAME)}

			<Content>
				<ContentBody scrolled={false}>
					<div 
						id="idInnerMainBox"
						ref={refMainBox}
						className={Styles.wrap}
					>
						<div id="mainSvg"
								 ref={refMainSvg}
								 className={Styles.svg}
								 style={{width: svgWidth, height: svgHeight}}>
							{getSvg(svgArr)}
						</div>
						<div id="idCommon">
							{getCurveControls()}
							{getControlOptions()}
						</div>
					</div>
				</ContentBody>

				<ContentFooter id="idButtons">
					{getButtons()}
				</ContentFooter>
			</Content>

			{showConfirmSaveXlsDlg && 
			<SaveXlsFileConfirmDialog
				showConfirmSaveXlsDlg={showConfirmSaveXlsDlg}
				setShowConfirmSaveXlsDlg={setShowConfirmSaveXlsDlg}
				saveExport={saveExport}
				fileName={EXPERIMENT_NAME}
				handleCancelSave={handleCancelSave}
			/>
			}
		</>
    );
}

export default MechanicMove;
