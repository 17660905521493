import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from './redux';
import { listDispatchToProps, listStateToProps } from './reduxList';
import SensorList from './Sensors';
import SensorItem from './Sensor';

const Sensors = connect(listStateToProps, listDispatchToProps)(SensorList);
const Sensor = connect(mapStateToProps, mapDispatchToProps)(SensorItem);

export { Sensors, Sensor };
