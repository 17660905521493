import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'rlabui';
import { getDropdown, getCheckBox } from './utils';
import './VideoEditor.scss';

const CameraChoiceDlg = ({ showModal, setShowModal, isCor, cameraList, 
        useAdvanceFeatures, handleCameraChoice }) => {
    const [selectedCameraId, setSelectedCameraId] = useState('');
    const [cameras, setCameras] = useState([]);
    const [isSaveCameraFiles, setIsSaveCameraFiles] = useState(false);

    useEffect(() => {
        if (cameraList && cameraList.length > 0) {
            setSelectedCameraId(cameraList[0].id);
            const _cameras = cameraList.map(item => ({value: item.id, label: item.label}));
            setCameras(_cameras);
        }
    }, [cameraList]);

    const getTitle = () => cameras.length <= 1 ? 'Камера микроскопа' : 'Выберите камеру микроскопа';

    const showCameras = () => {
        if (!cameraList || cameraList.length === 0) return <>Подключите камеру микроскопа.</>;
        if (cameras.length === 1) return <>{cameras[0].label}</>;

        return (
            <>
                {getDropdown(cameras, selectedCameraId, setSelectedCameraId, '', '', 'camera01')}
                <div className="videoEditor__cameraSpace" />
                {useAdvanceFeatures && getCheckBox(isSaveCameraFiles, setIsSaveCameraFiles, 
                    'save', 'Сохранять записанные на камеру видео')}
            </>
        );
    };

    return (
        <Modal visible={showModal} size={'xs'} className="print">
            <Modal.Head modalClose={() => setShowModal(false)} title={getTitle()}></Modal.Head>
            <Modal.Body>
                {showCameras()}
            </Modal.Body>
            <Modal.Footer>
            <div className="modal__action videoEditor__stretch">
                <Button
                    color="primary"
                    onClick={() => handleCameraChoice(selectedCameraId, isSaveCameraFiles)}
                    disabled={!cameras || cameras.length === 0}
                >
                    Начать
                </Button>
                {!isCor && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                <Button
                    color="primary"
                    border={true}
                    onClick={() => setShowModal(false)}
                >
                    Отменить
                </Button>
            </div>
            </Modal.Footer>
        </Modal>
    );
};

export default CameraChoiceDlg;
