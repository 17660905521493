import React from 'react';
import ss from '../SensorView.module.scss';
import  Icon  from '../../../Icon';
import { logo, list, info, charging, mode, computer, logging } from '../parts';

const SensorViewOne = (props) => {
  
    return (
        <div className={ss.root__wrap}>
            <div className={`${ss.root} ${ss.single}` }>
                {logo()}
                <div className={ss.root__title}>Одиночный<br></br>датчик</div>
                {list()}
                {charging()}

                <div className={`${ss.icon} ${ss.top}`}>
                    <div className={ss.icon__wrap}>
                        <div className={ss.icon__list}>
                            <div className={ss.icon__view}>
                                <Icon name="screwdriver" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${ss.icon} ${ss.topRightLine}`}>
                    <div className={ss.icon__wrap}>
                        <div className={ss.icon__list}>
                            <div className={ss.icon__view}>
                                <Icon name="mouse-signal" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
};
export default SensorViewOne;

