import chartColors from '../../../styles/charts';

export const showCursorLine = (chart, theme, yAxis) => {
    const markers = chart.config.options.plugins.annotation.annotations;

    markers['xLine'] = {
        display: false,
        type: 'line',
        adjustScaleRange: false,
        borderWidth: 1,
        borderColor: theme === 'dark' ? chartColors.marker.cursor.dark.border : chartColors.marker.cursor.border,
        borderDash: [20, 5],
        borderDashOffset: 10,
        arrowHeads: {
            start: {
                enabled: true,
                length: 4,
                borderDash: [],
                borderDashOffset: 0,
                borderColor: theme === 'dark' ? chartColors.marker.cursor.dark.border : chartColors.marker.cursor.border,
                backgroundColor: theme === 'dark' ? chartColors.marker.cursor.dark.background : chartColors.marker.cursor.background,
                fill: false,
                width: 4
            },
            end: {
                enabled: true,
                length: 4,
                borderDash: [],
                borderDashOffset: 0,
                borderColor: theme === 'dark' ? chartColors.marker.cursor.dark.border : chartColors.marker.cursor.border,
                backgroundColor: theme === 'dark' ? chartColors.marker.cursor.dark.background : chartColors.marker.cursor.background,
                fill: false,
                width: 4
            },
        },
        label: {
            enabled: true,
            content: 0,
            position: 'end',
            backgroundColor: theme === 'dark' ? chartColors.marker.cursor.dark.background : chartColors.marker.cursor.background,
            borderColor: theme === 'dark' ? chartColors.marker.cursor.dark.border : chartColors.marker.cursor.border,
            color: theme === 'dark' ? chartColors.marker.cursor.dark.color : chartColors.marker.cursor.color,
            borderWidth: 1,
            yAdjust: -6,
            borderRadius: 2,
            padding: {
                x: 9,
                y: 6,
            },
            font: {
                size: 10,
                weight: 400,
                lineHeight: 1.2
            },
        },
        scaleID: 'xAxis',
        value: 0,
    };
    markers['yLine'] = {
        display: false,
        type: 'line',
        adjustScaleRange: false,
        borderWidth: 1,
        borderColor: theme === 'dark' ? chartColors.marker.cursor.dark.border : chartColors.marker.cursor.border,
        borderDash: [20, 5],
        borderDashOffset: 0,
        arrowHeads: {
            start: {
                enabled: true,
                length: 4,
                borderDash: [],
                borderDashOffset: 0,
                borderColor: theme === 'dark' ? chartColors.marker.cursor.dark.border : chartColors.marker.cursor.border,
                backgroundColor: theme === 'dark' ? chartColors.marker.cursor.dark.background : chartColors.marker.cursor.background,
                fill: false,
                width: 4
            },
            end: {
                enabled: true,
                length: 4,
                borderDash: [],
                borderDashOffset: 0,
                borderColor: theme === 'dark' ? chartColors.marker.cursor.dark.border : chartColors.marker.cursor.border,
                backgroundColor: theme === 'dark' ? chartColors.marker.cursor.dark.background : chartColors.marker.cursor.background,
                fill: false,
                width: 4
            },
        },
        label: {
            enabled: true,
            content: 0,
            position: 'start',
            backgroundColor: theme === 'dark' ? chartColors.marker.cursor.dark.background : chartColors.marker.cursor.background,
            borderColor: theme === 'dark' ? chartColors.marker.cursor.dark.border : chartColors.marker.cursor.border,
            color: theme === 'dark' ? chartColors.marker.cursor.dark.color : chartColors.marker.cursor.color,
            borderWidth: 1,
            xAdjust: 4,
            borderRadius: 2,
            padding: {
                x: 9,
                y: 6,
            },
            font: {
                size: 10,
                weight: 400,
                lineHeight: 1.2
            },
        },
        scaleID: yAxis,
        value: 0,
    }

    chart.update();
}
