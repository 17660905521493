import './Sticker.scss';

const Sticker = ({ sticker, onClick }) => {
  return (
    <div className={`sticker ${sticker?.name}`} style={{ backgroundColor: sticker?.color?.backgroundColor, color: sticker?.color?.color }} onClick={onClick}>
      {sticker?.icon}
      <span className="sticker__title">{sticker?.title}</span>
    </div>
  );
};

export default Sticker;
