import React, { useState, useEffect, useCallback, memo, useRef } from 'react';
import { Table, Column } from 'react-virtualized';
// import worker from '../../webWorkers/tableWorker.js';
// import WebWorker from '../../webWorkers/WebWorker';
import markersColors from '../../styles/charts';
import Icon from '../Icon';
import { Dropdown } from 'rlabui';
import ss from './TableData.module.scss';

const defaultRangeSorts = [false, false, false, false];

const TableData = (props) => {
    const {
        played,
        paused,
        bundlesList,
        sensorList,
        sensorData,
        writedData,
        sensorImported,
        markers,
        tableSorting,
        setSensorTablePrint,
        setSensorRecords,
        sensorsWrited,
        chartStyle,
        defaultColors,
        chartDisabled,
        setSensorTableSorting,
        setSensorsSummaryModal,
    } = props;

    const [channel, selectChannel] = useState();
    const [sensors, setSensors] = useState([]);
    const [allData, setAllData] = useState([]);
    const [currentSensor, setCurrentSensor] = useState(null);
    const [currentColor, setCurrentColor] = useState(null);
    const [sensDrop, setSensDrop] = useState([]);
    const [impoprtDrop, setImportDrop] = useState([]);
    const [writingDrop, setWritingDrop] = useState([]);
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);
    const [sortingData, toggleSortingData] = useState(false);
    const [sortingMarkers, toggleSortingMarkers] = useState(false);
    const [sortingRange, toggleSortingRange] = useState(defaultRangeSorts);
    const [width, setWidth] = useState(800);
    const [height, setHeight] = useState(800);
    const tableRef = useRef();

    useEffect(() => {
        const updateSize = ([entries]) => {
            const { width, height } = entries.contentRect;
            setWidth(width);
            setHeight(height);
        };
        const wrap = tableRef.current;
        if (!wrap) return;
        const observer = new ResizeObserver(updateSize);
        setWidth(wrap.offsetWidth);
        setHeight(wrap.offsetHeight);
        observer.observe(wrap);
        return () => observer.disconnect();
    }, [tableRef.current]);

    const setPrinTable = useCallback(() => {
        let print = [];
        const index = sensors?.findIndex((item) => item.num === channel);

        if (index !== -1 && allData[index]) {
            print = { ...sensors[index] };
            print.value = [...allData[index]];
            setSensorTablePrint([print]);
        }
        if (!allData[index]) {
            print = { ...sensors[index] };
            print.value = [];
            setSensorTablePrint([print]);
        }
    }, [sensors, allData, channel]);

    useEffect(() => {
        if (played) {
            setMin(0);
            setMax(0);
            setSensorTablePrint([]);
            setSensorTableSorting(false);
            toggleSortingData(false);
            toggleSortingMarkers(false);
            toggleSortingRange([...defaultRangeSorts]);
        } else {
            setPrinTable();
            recordData();
        }
        // eslint-disable-next-line
    }, [played]);

    useEffect(() => {
        if (tableSorting) {
            toggleSortingData(false);
            toggleSortingMarkers(false);
            toggleSortingRange([...defaultRangeSorts]);
        }
    }, [tableSorting]);

    useEffect(() => {
        if (sortingData) {
            setSensorTableSorting(false);
            toggleSortingMarkers(false);
            toggleSortingRange([...defaultRangeSorts]);
        }
    }, [sortingData, setSensorTableSorting]);

    useEffect(() => {
        if (sortingMarkers) {
            setSensorTableSorting(false);
            toggleSortingData(false);
            toggleSortingRange([...defaultRangeSorts]);
        }
    }, [sortingMarkers, setSensorTableSorting]);

    useEffect(() => {
        let a = sortingRange.toString();
        let b = defaultRangeSorts.toString();
        if (a !== b) {
            toggleSortingData(false);
            toggleSortingMarkers(false);
            setSensorTableSorting(false);
        }
    }, [sortingRange, setSensorTableSorting]);

    useEffect(() => {
        setSensors([...sensorsWrited, ...sensorImported, ...sensorList]);
    }, [sensorList, sensorImported, sensorsWrited]);

    useEffect(() => {
        const importData = sensorImported?.length ? sensorImported.map((el) => el.value) : [];
        setAllData([...writedData, ...importData, ...sensorData]);
    }, [sensorData, sensorImported, writedData]);

    useEffect(() => {
        if (!played) {
            setPrinTable();
            recordData();
            calculateMinMax();
        }
        // eslint-disable-next-line
    }, [allData, played]);

    useEffect(() => {
        const current = sensors.filter((item) => item.num === channel)[0];
        const defaultColor = sensors.indexOf(current) > 0 ? defaultColors[sensors.indexOf(current)] : defaultColors[0];
        setCurrentColor(chartStyle[channel]?.color ? chartStyle[channel].color : defaultColor.val);

        if (current) {
            setCurrentSensor(current);
            setPrinTable();
            calculateMinMax();
        }
        // eslint-disable-next-line
    }, [channel, chartStyle, sensors, setPrinTable, defaultColors]);

    useEffect(() => {
        if (!played) {
            recordData();
        }
        calculateMinMax();
        // eslint-disable-next-line
    }, [sensorData, writedData]);

    useEffect(() => {
        const newArr = sensorList?.length ? setNewTablesList([...sensorList]) : [];
        setSensDrop(newArr);
        // eslint-disable-next-line
    }, [sensorList, chartDisabled]);

    useEffect(() => {
        const newArr = sensorsWrited?.length ? setNewTablesList([...sensorsWrited]) : [];
        setWritingDrop(newArr);
        // eslint-disable-next-line
    }, [sensorsWrited, chartDisabled]);

    useEffect(() => {
        const newArr = sensorImported?.length ? setNewTablesList([...sensorImported]) : [];
        setImportDrop(newArr);
        // eslint-disable-next-line
    }, [sensorImported, chartDisabled]);

    useEffect(() => {
        const sensorsArr = [...writingDrop, ...impoprtDrop, ...sensDrop];
        if (sensorsArr.length) {
            selectChannel(sensorsArr[0].value);
        }
        // eslint-disable-next-line
    }, [sensDrop.length, writingDrop.length, impoprtDrop.length]);

    useEffect(() => {
        setPrinTable();
        recordData();
        // eslint-disable-next-line
    }, [markers, sensors]);

    const setNewTablesList = (arr) => {
        let index = 0;
        const newArr = [];
        arr.forEach((item, i) => {
            const disabled = chartDisabled.filter((disCh) => disCh === item.num);
            if (!disabled.length) {
                newArr[index] = { label: item.name, value: item.num };
                index++;
            }
        });

        return newArr;
    };

    const calculateMinMax = () => {
        let minMax = [];
        let data = [];
        const index = sensors.findIndex((el) => el.num === channel);

        if (sensors[index]) {
            const ch = sensors[index].num;
            if (typeof ch === 'number') {
                data = allData[index];
            } else {
                data = sensors[index].value;
            }

            if (data?.length) {
                data.forEach(({ y }) => {
                    minMax.push(y);
                });
                if (minMax?.length) {
                    const min = Math.min(...minMax);
                    const max = Math.max(...minMax);
                    setMin(min);
                    setMax(max);
                }
            }
        }
    };

    function recordData() {
        const rec = [...sensors, bundlesList];

        rec?.forEach((sens, i) => {
            if (sens.name) {
                sens.markers = { ...markers[sens.num] };
                sens.value = allData[i] || [];
            } else {
                for (const key in sens) {
                    const item = sens[key];
                    const { list } = item;
                    item.markers = { ...markers[key] };

                    list.forEach((el) => {
                        const curData = allData?.filter((data) => data[0]?.num === el?.num)[0];
                        if (curData) {
                            el.value = curData;
                        }
                    });
                }
            }
        });

        setSensorRecords(rec);
    }

    const renderMarkers = (markers) => {
        const defaultMark = <div className={ss.marker}></div>;
        if (markers?.length) {
            return (
                <div className={ss.markers}>
                    {markers.includes('line') ? (
                        <div
                            className={ss.marker}
                            style={{
                                background: markersColors?.marker?.table?.color,
                            }}
                        ></div>
                    ) : (
                        defaultMark
                    )}
                    {markers.includes('box1') ? (
                        <div
                            className={ss.marker}
                            style={{ background: markersColors?.boxes?.table?.box1 }}
                        ></div>
                    ) : (
                        defaultMark
                    )}
                    {markers.includes('box2') ? (
                        <div
                            className={ss.marker}
                            style={{ background: markersColors?.boxes?.table?.box2 }}
                        ></div>
                    ) : (
                        defaultMark
                    )}
                    {markers.includes('box3') ? (
                        <div
                            className={ss.marker}
                            style={{ background: markersColors?.boxes?.table?.box3 }}
                        ></div>
                    ) : (
                        defaultMark
                    )}
                    {markers.includes('box4') ? (
                        <div
                            className={ss.marker}
                            style={{ background: markersColors?.boxes?.table?.box4 }}
                        ></div>
                    ) : (
                        defaultMark
                    )}
                </div>
            );
        }
    };

    const sensorsValueTable = useCallback(() => {
        const data = [...allData];
        if (data[0]?.length) {
            const index = allData.findIndex((el) => el[0]?.num === channel);
            if (!data[index] || index === -1) return;

            let vals = played && !paused ? [...data[index]].slice(-18) : [...data[index]];

            vals?.forEach((val) => {
                val.markers = [];
                for (var key in markers[channel]) {
                    const m = markers[channel][key];
                    // console.log(m);
                    if (m.type === 'line' && !m.resizer) {
                        if (val.x === m.value) {
                            val.markers.push('line');
                        }
                    }
                    if (m.type === 'box') {
                        if (val.x >= m.xMin && val.x <= m.xMax) {
                            val.markers.push(key);
                        }
                    }
                }
            });

            const rangeSorted = sortingRange.filter((item) => item).length;
            if (!played || paused) {
                if (tableSorting) {
                    vals.reverse();
                }
                if (sortingData) {
                    vals.sort((a, b) => +a.y - +b.y);
                }
                if (sortingMarkers) {
                    vals.sort((a, b) => {
                        const isLine = a.markers.filter((e) => e === 'line')[0];
                        if (isLine) {
                            return -1;
                        } else {
                            return 1;
                        }
                    });
                }
                if (rangeSorted) {
                    sortingRange.forEach((range, i) => {
                        if (range) {
                            const key = 'box' + (i + 1);
                            vals.sort((a, b) => {
                                const c = a.markers.filter((r) => r === key);
                                const d = b.markers.filter((r) => r === key);
                                return d.length - c.length;
                            });
                        }
                    });
                }
            }

            return (
                vals?.length > 1 && (
                    <Table
                        width={width}
                        height={height}
                        headerHeight={0}
                        rowHeight={33}
                        rowCount={vals?.length ? vals.length : 0}
                        rowGetter={({ index }) => vals[index]}
                    >
                        <Column
                            label="№"
                            dataKey="count"
                            cellRenderer={({ cellData }) => cellData}
                            width={50}
                        />
                        <Column
                            label="сек."
                            dataKey="x"
                            width={50}
                            cellRenderer={({ cellData }) => (cellData / 1000).toFixed(3)}
                        />
                        <Column
                            label="данные"
                            dataKey="y"
                            width={50}
                        />
                        <Column
                            label="м д1 д2 д3 д4"
                            dataKey="markers"
                            cellRenderer={({ cellData }) => renderMarkers(cellData)}
                            width={50}
                        />
                    </Table>
                )
            );
        }
    }, [allData, markers, sortingRange, tableSorting, sortingData, sortingMarkers, channel, tableRef, played, paused, width, height]);

    const handleRangeSort = (index) => {
        const arr = sortingRange;
        arr.forEach((item, i) => {
            if (i === index) {
                arr[i] = !item;
            } else {
                arr[i] = false;
            }
        });
        toggleSortingRange([...arr]);
    };

    return (
        sensors?.length > 0 && (
            <div className={ss.root}>
                <div
                    className={ss.tabs}
                    data-learn="16"
                    data-learn-border="16"
                    data-learn-position="left"
                >
                    <Dropdown
                        checkbox={true}
                        value="Данные"
                        className={!sensDrop.length ? '' : 'active'}
                        disabled={!sensDrop.length}
                    >
                        {sensDrop?.length > 0 &&
                            sensDrop.map(({ label, value }, i) => (
                                <Dropdown.Item
                                    key={i}
                                    className={value === channel ? 'selected' : ''}
                                    onClick={() => {
                                        // console.log(value);
                                        selectChannel(value);
                                        // setCurrentIndex(i);
                                    }}
                                >
                                    {label}
                                </Dropdown.Item>
                            ))}
                    </Dropdown>
                    <Dropdown
                        checkbox={true}
                        value="Импорт"
                        disabled={!impoprtDrop.length}
                        dropPosition="right"
                        className={!impoprtDrop.length ? '' : 'active'}
                    >
                        {impoprtDrop?.length > 0 &&
                            impoprtDrop.map(({ label, value }, i) => (
                                <Dropdown.Item
                                    key={'sens' + value}
                                    className={value === channel ? 'selected' : ''}
                                    onClick={(_) => {
                                        // console.log(e);
                                        selectChannel(value);
                                        // setCurrentIndex(i);
                                    }}
                                >
                                    {label}
                                </Dropdown.Item>
                            ))}
                    </Dropdown>
                    <Dropdown
                        checkbox={true}
                        value="Логи"
                        disabled={!writingDrop.length}
                        className={!writingDrop.length ? '' : 'active'}
                        dropPosition="right"
                    >
                        {writingDrop?.length > 0 &&
                            writingDrop.map(({ label, value }, i) => (
                                <Dropdown.Item
                                    key={'sens' + value}
                                    className={value === channel ? 'selected' : ''}
                                    onClick={(e) => {
                                        // console.log(e);
                                        selectChannel(value);
                                        // setCurrentIndex(i);
                                    }}
                                >
                                    {label}
                                </Dropdown.Item>
                            ))}
                    </Dropdown>
                </div>
                {sensors?.length > 0 && (
                    <div className={ss.current}>
                        <span
                            className={ss.color}
                            style={currentColor && { background: currentColor }}
                        ></span>
                        <div className={ss.name}>{currentSensor && currentSensor.name}</div>
                    </div>
                )}
                <div className={ss.wrap}>
                    {sensors?.length > 0 ? (
                        <div className={ss.dtable}>
                            <div
                                className={ss.dtableHead}
                                data-learn="17"
                                data-learn-border="17"
                                data-learn-position="left"
                            >
                                <div className={ss.dtableRow}>
                                    <div className={ss.dtableCol}>
                                        <span
                                            className={tableSorting ? ss.dtableSort + ' sorted' : ss.dtableSort}
                                            onClick={() => setSensorTableSorting(!tableSorting)}
                                        >
                                            №&nbsp;
                                            <Icon name="arrow-sort" />
                                        </span>
                                    </div>
                                    <div className={ss.dtableCol}>сек.</div>
                                    <div className={ss.dtableCol}>
                                        <span
                                            className={sortingData ? ss.dtableSort + ' sorted' : ss.dtableSort}
                                            onClick={() => toggleSortingData(!sortingData)}
                                        >
                                            данные&nbsp;
                                            <Icon name="arrow-sort" />
                                        </span>
                                    </div>
                                    <div className={ss.dtableCol}>
                                        <span
                                            className={sortingMarkers ? ss.dtableSort + ' sorted' : ss.dtableSort}
                                            onClick={() => toggleSortingMarkers(!sortingMarkers)}
                                        >
                                            м&nbsp;
                                        </span>
                                        <span
                                            className={sortingRange[0] ? ss.dtableSort + ' sorted' : ss.dtableSort}
                                            onClick={() => handleRangeSort(0)}
                                        >
                                            д1&nbsp;
                                        </span>
                                        <span
                                            className={sortingRange[1] ? ss.dtableSort + ' sorted' : ss.dtableSort}
                                            onClick={() => handleRangeSort(1)}
                                        >
                                            д2&nbsp;
                                        </span>
                                        <span
                                            className={sortingRange[2] ? ss.dtableSort + ' sorted' : ss.dtableSort}
                                            onClick={() => handleRangeSort(2)}
                                        >
                                            д3&nbsp;
                                        </span>
                                        <span
                                            className={sortingRange[3] ? ss.dtableSort + ' sorted' : ss.dtableSort}
                                            onClick={() => handleRangeSort(3)}
                                        >
                                            д4&nbsp;
                                            <Icon name="arrow-sort" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={ss.dtableBody}
                                ref={tableRef}
                            >
                                {sensorsValueTable()}
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                {sensors?.length > 0 ? (
                    <div className={ss.rez}>
                        <div className={ss.rezItem}>
                            <span title="Минимальное значение">Мин: {min}</span>
                            <span title="Максимальное значение">Макс: {max}</span>
                        </div>
                        <Icon
                            name="dotts"
                            onClick={() => setSensorsSummaryModal(channel)}
                        />
                    </div>
                ) : (
                    ''
                )}
            </div>
        )
    );
};

export default memo(TableData);
