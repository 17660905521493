import React, { useEffect, useState } from "react";
import "./NewModals.scss";
import NewModalsButton from "./NewModalsButton";
import { useDispatch, useSelector } from "react-redux";
import { addNewColumn, updateColumn } from "../../../redux/slices/trello";
import { storageClass } from "../../../redux/slices/storage";

const UsersListModal = ({ onCloseModal, activeSection }) => {
  const dispatch = useDispatch();

  const documentClass = useSelector(storageClass);

  const [searchName, setSearchName] = useState("");

  const usersRole = (role) => {
    switch (role) {
      case 0:
        return "Ученик";
      case 1:
        return "Учитель";
      case 2:
        return "Староста";
      default:
        return "Админ";
    }
  };

  const filteredUsers = documentClass?.classUsers?.filter(
    (user) =>
      user.name.toLowerCase().includes(searchName.toLowerCase()) ||
      user.name2.toLowerCase().includes(searchName.toLowerCase())
  );

  return (
    <div className="new_modals">
      <h2 className="new_modals__title">Класс: {documentClass?.className}</h2>
      <div className="new_modals__content">
        <div className="new_modals__section">
          <div className="new_modals__section_item">
            {/* <div className="new_modals__section_title">Название раздела</div> */}
            <input
              autoFocus
              type="text"
              value={searchName}
              placeholder="Введите фамилию или имя"
              onChange={(e) => setSearchName(e.target.value)}
              className="new_modals__section_input users"
            />
          </div>
        </div>
        <div className="new_modals__section">
          <div className="new_modals__section_table">
            <div className="new_modals__section_table_header">
              <div className="new_modals__section_table_column">Имя</div>
              <div className="new_modals__section_table_column">Статус</div>
              <div className="new_modals__section_table_column">Действия</div>
            </div>
            <div className="new_modals__section_table_items">
              {filteredUsers?.map((user) => (
                <div className="new_modals__section_table_item" key={user._id}>
                  <div className="new_modals__section_table_column">
                    <div className="new_modals__section_table_column_img">
                    {user.name.slice(0, 1).toUpperCase()}
                    </div>
                    <div className="new_modals__section_table_column_name">
                      {`${user.name} ${user.name2}`}
                    </div>
                  </div>
                  <div className="new_modals__section_table_column">
                    {usersRole(user.role)}
                  </div>
                  <div className="new_modals__section_table_column"></div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="new_modals__section">
          <div className="new_modals__section_buttons users">
            <NewModalsButton
              type="cancel"
              title="Закрыть"
              className='users'
              onClick={() => onCloseModal()}
            />
          </div>
        </div>
      </div>
      <span
        className="modal_task__close"
        style={{ top: 0, right: 0 }}
        onClick={() => onCloseModal()}
      />
    </div>
  );
};

export default UsersListModal;
