import React, { useEffect, useState } from 'react';
import ss from './Visualizer.module.scss';

const Visualizer = ({ sensors, disabled, visualized, setSensorVisualized, imported }) => {
    const [allSens, setAllSens] = useState([]);
    useEffect(() => {
        // Отключем визуализатор если отключены датчики от коммутатора
        if (sensors.length === 1) {
            setSensorVisualized();
        }
    }, [sensors, setSensorVisualized]);
    useEffect(() => {
        if (imported) {
            const impMain = imported.filter((e) => e) || [];
            setAllSens([...sensors, ...impMain]);
        } else {
            setAllSens([...sensors]);
        }
    }, [sensors, imported]);
    return (
        allSens?.length > 0 && (
            <div className={ss.list}>
                <div
                    className={!visualized ? ss.active : ss.item}
                    onClick={() => setSensorVisualized()}
                >
                    Визуализатор
                </div>
                {allSens
                    ?.filter((e) => !e.key.includes('Button'))
                    .map((item) => {
                        const { name, key, num, imported } = item;
                        const checkDisabled = disabled.filter((item) => item === num);
                        let currentClass = ss.item;

                        if (checkDisabled.length) {
                            currentClass += ' ' + ss.disabled;
                        }

                        if (visualized === key) {
                            currentClass += ' ' + ss.active;
                        }

                        return (
                            <div
                                key={key}
                                className={currentClass}
                                onClick={() => !checkDisabled.length && setSensorVisualized(key)}
                            >
                                {name} {imported ? '(имп)' : ''}
                            </div>
                        );
                    })}
            </div>
        )
    );
};
export default Visualizer;
