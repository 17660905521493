import { toast } from 'react-toastify';
import { setAllDevices, setDeviceConnectedCount, addDevicesConnected, removeDevicesConnected, setDeviceConnectedLoader } from '../actions/devices';
import { togglePort, setConnectionType, setPort, sendCommand } from '../actions/port';
import { setReader, setReading } from '../actions/reader';
import { setSensor, setSensorList, setMeasunits } from '../actions/sensors';
import { resetReduxState, setSource } from '../actions/actions';
import XMLParser from 'react-xml-parser';
import { reading } from './port';
import { addWriter } from '../actions/writer';

const sourceFile = process.env.PUBLIC_URL + '/source.xml';
const units = process.env.PUBLIC_URL + '/json/units.json';
const sourceFilePath = process.env.REACT_APP_API_URL + '/api/source';
let usbDispatch = () => null;
const baudRate = 115200;
let usbConnectionAttempts = 0;

export const disconnect = () => async (dispatch, getState) => {
    const { isAndroid } = window;
    const {
        reader: { data: readerData },
        stream: { data: streamData, closed: readableStreamClosed },
        port: { data: portData },
        writer: { data: writter },
        sensors: { list },
    } = getState();

    const port = portData[0];
    const reader = readerData['usb0'];
    const stream = streamData[0];

    try {
        if (isAndroid) {
            await port.removeAllListeners();
            await port
                .closeSerial()
                .then((e) => {
                    // console.log(e);
                    fin();
                    // return;
                })
                .catch((err) => {
                    // console.log(err.message);
                    toast.error('Переподключите устройство для повторного использования', 'Произошла ошибка при отключении.');
                });
        } else {
            try {
                await stream?.cancel();
            } catch (e) {}

            if (readableStreamClosed) {
                // console.log(readableStreamClosed);
                try {
                    await readableStreamClosed?.catch(() => {});
                } catch (err) {}
            }

            if (reader?.locked) {
                // await reader.releaseLock();
                await reader.cancel();
            }

            if (port) {
                await port
                    .close()
                    .then((e) => console.log('port closed'))
                    .catch((e) => {
                        console.log(e.message);
                        if (!e.message?.includes('The port is already closed')) {
                            toast.error('Переподключите устройство для повторного использования', 'Произошла ошибка при отключении.');
                        }
                    });
            }

            fin();
        }
    } catch (e) {
        console.log('usb disconnected error', e);
        // if (e.message?.includes('Already opened')) {
        //     toast.error('Переподключите устройство для повторного использования', 'Произошла ошибка при подключении.');
        // }
    }

    function fin() {
        list?.length && toast.info('Датчик отключен');
        dispatch(togglePort(false));
        dispatch(setConnectionType(null));
        dispatch(removeDevicesConnected('usb0'));
        dispatch(resetReduxState());
        navigator.serial.removeEventListener('disconnect', showDisconnectMessage);
    }
};

export const connect =
    (serial, connected = null) =>
    async (dispatch, getState) => {
        usbDispatch = dispatch;
        const {
            port: { data },
        } = getState();
        let device = data[0];
        if (serial) {
            device = serial;
        }
        dispatch(setDeviceConnectedLoader(true));
        try {
            dispatch(serailPortOpen(device, connected));
        } catch (e) {
            console.log(e);
            dispatch(setDeviceConnectedLoader(false));
            dispatch(disconnect(connected));
        } finally {
            dispatch(setDeviceConnectedLoader(false));
        }
    };

let checkTimeout = null;
let checkInterval = 0;

const serailPortOpen = (device, connected) => async (dispatch, getState) => {
    const {
        reader: { data: readerData },
        stream: { data: streamData, closed: readableStreamClosed },
        port: { data: portData },
        // writer: { data: writter },
        // sensors: { list },
    } = getState();

    const port = portData[0];
    const reader = readerData['usb0'];
    const stream = streamData[0];

    // console.log('serailPortOpen', device?.getInfo());/
    // const key = 'usb' + (list?.length ? 1 : 0);
    const serial_success = () => {
        // console.log('serailPortOpen serial_success', device);
        usbConnectionAttempts === 0 && toast.info('Подключаемся к датчику', null, 3000);
        dispatch(setPort([device]));
        dispatch(setDeviceConnectedLoader(false));
        dispatch(togglePort(true));
        dispatch(setReading(true));
        // dispatch(setReader({ key, data: device.readable }));
        dispatch(setReader({ key: 'usb0', data: device.readable }));
        // dispatch(addWriter({ key, data: device.writable }));
        dispatch(addWriter({ key: 'usb0', data: device.writable }));
        // dispatch(addDevicesConnected({ key, data: device }));
        dispatch(addDevicesConnected({ key: 'usb0', data: device }));
        dispatch(setDeviceConnectedCount(1));
        dispatch(setConnectionType('usb'));
        dispatch(reading());

        checkTimeout = setInterval(() => {
            dispatch(checkConnection());
        }, 100);
    };

    const serial_error = async (err) => {
        // console.log('connect error === ', err);
        // console.log('connect error === ', err.message);
        if (err.message.includes('The port is already open')) {
            try {
                await stream.cancel();
            } catch (err) {}

            await readableStreamClosed?.catch(() => {});

            if (reader?.locked) {
                await reader.cancel();
            }

            if (port) {
                await port
                    .close()
                    .then((e) => {
                        // console.log('port closed');
                        if (usbConnectionAttempts < 5) {
                            device.open({ baudRate }).then(serial_success).catch(serial_error);
                            usbConnectionAttempts++;
                        } else {
                            toast.error('Переподключите устройство для повторного использования или обновите страницу', 'Произошла ошибка при подключении.');
                            usbConnectionAttempts = 0;
                            dispatch(setDeviceConnectedLoader(false));
                            dispatch(disconnect(device));
                        }
                    })
                    .catch((err) => {
                        console.log(err.message);
                        toast.error('Переподключите устройство для повторного использования', 'Произошла ошибка при отключении.');
                    });
            }
        } else {
            dispatch(setDeviceConnectedLoader(false));
            dispatch(disconnect(device));
        }
    };

    if (connected) {
        await device.openSerial({ baudRate, deviceId: connected.device.deviceId, portNum: connected.port }).then(serial_success).catch(serial_error);
    } else {
        await device.open({ baudRate }).then(serial_success).catch(serial_error);
    }
};

export const checkConnection = () => async (dispatch, getState) => {
    const {
        sensors: { list },
        port: { data },
        // devices: { list: devices },
    } = getState();

    // console.log(devices);
    // Устанавливаем интервал в 3 секунды для проверки подключения
    if (list?.length) {
        checkInterval = 0;
        clearTimeout(checkTimeout);
        return;
    }

    if (data?.length) {
        if (checkInterval === 25) {
            checkInterval = 0;
            clearTimeout(checkTimeout);
            if (list.length === 0) {
                dispatch(autoconnectReconnectSerialPort);
            } else {
                data?.forEach((port) => {
                    dispatch(disconnect(port));
                });
                toast.error('Переподключите устройство для повторного использования', 'Произошла ошибка при подключении', 8000);
            }
        } else {
            if (list?.length > 0) {
                toast.success('Датчик подключен');
                clearTimeout(checkTimeout);
            }
            checkInterval++;
        }
    } else {
        clearTimeout(checkTimeout);
    }
};

export const getDevicesList = () => async (dispatch, getState) => {
    await fetch(sourceFilePath)
        .then((e) => {
            if (!e.ok) {
                return dispatch(getDevicesListLocal());
            }
            return e.json();
        })
        .then((data) => {
            fetch(process.env.REACT_APP_API_URL + '/' + data.path)
                .then((e) => {
                    if (!e.ok) {
                        return dispatch(getDevicesListLocal());
                    }
                    return e.text();
                })
                .then((e) => dispatch(parseSourceList(e)))
                .catch((err) => console.log('get source web err', err));
        })
        .catch((err) => {
            console.log('get source web err', err);
            dispatch(getDevicesListLocal());
        });
};

export const getDevicesListLocal = () => async (dispatch, getState) => {
    await fetch(sourceFile, { mode: 'no-cors' })
        .then((res) => res.text())
        .then((data) => dispatch(parseSourceList(data)))
        .catch((err) => console.log('get source local err', err));
};

const parseSourceList = (data) => async (dispatch) => {
    const xml = new XMLParser().parseFromString(data);
    const arr = xml.getElementsByTagName('device');
    const code = xml.getElementsByTagName('code')[0];
    const date = xml.getElementsByTagName('date')[0];
    // console.log(code, date);
    dispatch(setSource({ version: code.value, date: date.value }));
    arr.forEach((item) => {
        let single = false;

        const channels = item.getElementsByTagName('channels');

        if (!channels.length) {
            single = true;
        }

        item.single = single;
    });
    dispatch(setAllDevices(arr));
};

export const getUnitsList = () => async (dispatch, getState) => {
    await fetch(units, { mode: 'no-cors' })
        .then((res) => res.json())
        .then((data) => {
            dispatch(setMeasunits(data));
        })
        .catch((err) => console.log(err));
};

export const autoconnectReconnectSerialPort = async (dispatch, getState) => {
    // const {
    //     sensors: { list },
    // } = getState();

    usbDispatch = dispatch;
    if (!window.isAndroid) {
        // } else {
        navigator.serial.getPorts().then((ports) => {
            // console.log('autoconnectReconnectSerialPort', ports);
            // console.log(ports[0]?.getInfo());
            if (ports.length) {
                // navigator.serial.removeEventListener('connect', (e) => dispatch(connectionCallback(e)));

                const port = ports[0];
                dispatch(serailPortOpen(port));
                // const port = ports[0];
                // dispatch(serailPortOpen(port));

                // port.addEventListener('disconnect', showDisconnectMessage);

                // port.addEventListener('connect', (e) => {
                //     // Connect to `e.target` or add it to a list of available ports.
                //     let connectCount = 0;
                //     ports.forEach((port) => {
                //         // console.log(port);
                //         if (port.readable !== null) {
                //             connectCount++;
                //         }
                //     });
                //     if (connectCount === 0) {
                //         dispatch(serailPortOpen(port));
                //         toast.success('Соединение с датчиком восстановлено');
                //     }
                // });
            }
        });
        navigator.serial.addEventListener('connect', connectionCallback);

        navigator.serial.addEventListener('disconnect', showDisconnectMessage);
    }
};

const connectionCallback = (e) => {
    // Connect to `e.target` or add it to a list of available ports.
    let connectCount = 0;
    navigator.serial.getPorts().then((ports) => {
        ports.forEach((port) => {
            // console.log(port);
            if (port.readable !== null) {
                connectCount++;
            }
        });
    });
    if (connectCount === 0) {
        usbDispatch(serailPortOpen(e.target));
        toast.success('Соединение с датчиком восстановлено');
    }
};

const showDisconnectMessage = () => {
    clearTimeout(checkTimeout);
    toast.error('Потеряно соединение с датчиком');
    navigator.serial.removeEventListener('disconnect', showDisconnectMessage);
};

/// <FAKE>CONNECTION</FAKE>

export const fakeConnectDisconnect = () => async (dispatch, getState) => {
    const {
        port: { open },
    } = getState();
    let fakeCounter = 0;

    // console.log(allDevices);

    // console.log('fake port open ===', open);

    let fakeBuffer = '';
    // Одинчный датчик
    let subString = 'luminobright 0.920 ';
    let subString2 = 'luminobright 0.936 ';
    let subString3 = 'luminobright 0.926 ';

    // Мульти датчик
    // let subString = 'PhysAccel202108 ch0 !0.066 ch1 !0.066 ch2 !0.066 ch3 !0.066 ch4 !0.066 ch5 !0.066 ch6 !0.066 ch7 !0.066 ch8 !0.066 ch9 !1.018 ch10 !1.018 \r\n';
    // let subString2 = 'PhysAccel202108 ch0 !0.076 ch1 !0.066 ch2 !0.066 ch3 !0.066 ch4 !0.066 ch5 !0.066 ch6 !0.066 ch7 !0.066 ch8 !0.066 ch9 !1.018 ch10 !1.018  \r\n';
    // let subString3 = 'PhysAccel202108 ch0 !0.046 ch1 !0.066 ch2 !0.066 ch3 !0.066 ch4 !0.066 ch5 !0.066 ch6 !0.066 ch7 !0.066 ch8 !0.066 ch9 !1.018 ch10 !1.018  \r\n';
    // let subString2 = 'PhysAccel202108 ch8 !0.086 ch9 !1.090 ';
    // let subString3 = 'PhysAccel202108 ch8 !0.076 ch9 !1.020 ';
    // let subString = 'Cardio 4.42';
    // let subString2 = 'Cardio 8.42';
    // let subString3 = 'Cardio 4.42';

    dispatch(setConnectionType('usb'));
    dispatch(addDevicesConnected({ key: 'fake', data: {} }));
    dispatch(setDeviceConnectedCount(1));

    if (!open) {
        subString = '';
        subString2 = '';
        subString3 = '';
    }

    try {
        // console.log('connect try');
        while (subString.length) {
            await new Promise((r) => setTimeout(r, 100));
            if (!subString.length) break;
            else {
                if (fakeCounter % 2) {
                    fakeBuffer += subString;
                } else if (fakeCounter % 3) {
                    fakeBuffer += subString3;
                } else {
                    fakeBuffer += subString2;
                }
                // sub.replace(/\n/g, '}, {').replace(/\r/g, '');

                fakeCounter++;
                // console.log(fakeBuffer);
                dispatch(setSensor(fakeBuffer));
                fakeBuffer = '';
            }
        }
    } catch (e) {
        // console.log('disconnect ', e);
        dispatch(setSensor(''));
        dispatch(setSensorList([]));
        dispatch(setConnectionType(''));
    } finally {
        // console.log('finally ');
        // fakeBuffer = '';
    }
};
