import React from 'react';
import "./Button.scss";
/*
type: button, submit
border: bool
color: none, primary, secondary, success, danger
size: default, small, extraSmall, medium
*/
const Button = (props) => {
    const {
        children,
        type = "button",
        disabled = false,
        border = false,
        color = "",
        size = "",
        className = "",
        onClick = null,
        back = false
    } = props;

    let btnClass = "";
    if (size) {
        btnClass += " cor_btn_" + size;
    }
    if (border) {
        btnClass += " cor_btn_border";
    }
    if (color) {
        btnClass += " cor_btn_" + color;
    }
    if (back) {
        btnClass += " cor_btn_back";
    }
    if (className) {
        btnClass += " " + className;
    }
    return (
        <button
            className={"cor_btn" + btnClass}
            onClick={onClick}
            type={type}
            disabled={disabled}
            >{children}</button>
    )
};
export default Button;
