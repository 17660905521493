import {showClassSettings, showDeviceSettings, showFileSettings, showUserSettings} from "../../actions/actions";

export const mapStateToProps = (state) => {
    return ({
    });
};

export const mapDispatchToProps = (dispatch) => ({
    showUserSettings: (obj) => dispatch(showUserSettings(obj)),
    showClassSettings: (obj) => dispatch(showClassSettings(obj)),
    showFileSettings: (obj) => dispatch(showFileSettings(obj)),
});

export const mapDispatchAdminToProps = (dispatch) => ({
    showDeviceSettings: (obj) => dispatch(showDeviceSettings(obj)),
});

