// import {  } from '../../actions/';
import { addChartDisabled, deleteChartDisabled } from '../../actions/chart';

export const mapStateToProps = (nstate) => {
    return {
        theme: nstate.napp.theme,
        styles: nstate.nchart.style,
        chartDisabled: nstate.nchart.disabled,
        defaultColors: nstate.nchart.settings.colors,
        values: nstate.nsensor.values,
        visualized: nstate.nsensor.visualized,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    addChartDisabled: (id) => dispatch(addChartDisabled(id)),
    deleteChartDisabled: (id) => dispatch(deleteChartDisabled(id)),
});
