import React from "react";
import {NB_STATES} from './nb_utils';
import { isAuth } from '../../helpers/auth';
import "./Notebooks.scss";

const ProgressBar = ({progress, handleProgress, isDisabledTemplate}) => {
    const getStepState = (step, progress) => {
        if (progress > step) return 'done';
        if (progress === step) return 'current';
        return '';
    };

    return  (
        <div className={'nb__stroke'}>
            <div className="stroke counter-wrap">
                <div className={"stroke__item counter-item " + getStepState(NB_STATES.PAGE, progress)}>
                    <div className="stroke__number"  onClick={() => handleProgress(NB_STATES.PAGE)}>
                        <span className="counter-el"></span>
                    </div>
                    <div className="stroke__label">
                        <span>Шаг </span>
                        <span className="counter-el"></span>
                        <span>. {"Создание " + (isAuth().role < 3 ? "задания" : "шаблона")}</span>
                    </div>
                </div>
                <div className={"stroke__item counter-item " +  getStepState(NB_STATES.SETTINGS, progress)}>
                    <div className="stroke__number" onClick={() => handleProgress(NB_STATES.SETTINGS)}>
                        <span className="counter-el"></span>
                    </div>
                    <div className="stroke__label">
                        <span>Шаг </span>
                        <span className="counter-el"></span>
                        <span>. Настройка</span>
                    </div>
                </div>
                {isAuth().role < 3 && !isDisabledTemplate && 
                <div className={"stroke__item counter-item " + getStepState(NB_STATES.RUN, progress)}>
                    <div className="stroke__number" onClick={() => handleProgress(NB_STATES.RUN)}>
                        <span className="counter-el"></span>
                    </div>
                    <div className="stroke__label">
                        <span>Шаг </span>
                        <span className="counter-el"></span>
                        <span>. Запуск</span>
                    </div>
                </div>
                }
            </div>
        </div>
    );
}

export default ProgressBar;
