const initialState = {
    all: [],
    count: 0,
    connected: {},
    battery: {},
    batteryModal: false,
    list: {},
    loader: false,
    reconnect: false,
    history: {},
    historyModal: false,
};

const devicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ALL_DEVICES':
            let all = action.payload ? action.payload : [];
            return {
                ...state,
                all,
            };

        case 'ADD_DEVICES_LIST':
            let added = { ...state.list };
            added[action.payload.key] = action.payload.data;
            return {
                ...state,
                list: added,
            };

        case 'REMOVE_DEVICES_LIST':
            let remove = { ...state.list };
            if (action.payload?.key) {
                delete remove[action.payload.key];
            } else {
                remove = {};
            }
            return {
                ...state,
                list: remove,
            };

        case 'ADD_DEVICES_CONNECTED':
            let addConnect = { ...state.connected };
            addConnect[action.payload.key] = action.payload.data;
            return {
                ...state,
                connected: addConnect,
            };

        case 'REMOVE_DEVICES_CONNECTED':
            let removeConnect = { ...state.connected };
            delete removeConnect[action.payload];
            return {
                ...state,
                connected: removeConnect,
            };

        case 'SET_DEVICES_BATTERY':
            return {
                ...state,
                battery: action.payload,
            };

        case 'SET_DEVICE_BATTERY_MODAL':
            return {
                ...state,
                batteryModal: action.payload,
            };

        case 'SET_DEVICE_CONNECTED_COUNT':
            return {
                ...state,
                count: action.payload,
            };

        case 'SET_DEVICE_CONNECTED_LOADER':
            return {
                ...state,
                loader: action.payload,
            };

        case 'SET_DEVICE_RECONNECT':
            return {
                ...state,
                reconnect: action.payload,
            };

        case 'ADD_DEVICE_HISTORY':
            let history = { ...state.history };
            history[action.payload.key] = action.payload.data;
            return {
                ...state,
                history,
            };

        case 'REMOVE_DEVICE_HISTORY':
            let historyDel = { ...state.history };
            delete historyDel[action.payload];
            return {
                ...state,
                history: historyDel,
            };

        case 'SET_DEVICE_HISTORY_MODAL':
            return {
                ...state,
                historyModal: action.payload,
            };

        default:
            return state;
    }
};

export default devicesReducer;
