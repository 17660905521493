const initialState = {
    file: null,
    print: false,
};

const importReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_NFILE':
            return {
                ...state,
                file: action.payload,
            };

        case 'SET_NPRINT_MODAL':
            return {
                ...state,
                print: action.payload,
            };
        default:
            return state;
    }
};

export default importReducer;
