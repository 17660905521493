import {changeClass} from "../../actions/actions";

export const mapStateToProps = (state) => {
    return (
      state.cactions.changeClass
    );
};

export const mapDispatchToProps = (dispatch) => ({
    changeClass: (obj) => dispatch(changeClass(obj)),
});
