import { setDeviceBatteryModal } from '../../actions/devices';

export const mapStateToProps = (state) => {
    return {
        modal: state.devices.batteryModal,
        battery: state.devices.battery,
        deviceList: state.devices.list,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setDeviceBatteryModal: (bool) => dispatch(setDeviceBatteryModal(bool)),
});
