// import {  } from '../../actions/';

export const mapStateToProps = (nstate) => {
    return {
        ranges: nstate.nchart.ranges,
        isArtPressure: nstate.nsensor.isArtPressure,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    // setPlay: (bool) => dispatch(setPlay(bool)),
});
