const initialState = {
    timer: 0,
    stopwatch: 0,
    settings: 'mm:ss:SSS',
    timerModal: false,
    timeUpdate: 0,
};

const timerReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_NTIMER':
            return {
                ...state,
                timer: action.payload,
            };

        case 'UPDATE_NTIME':
            return {
                ...state,
                timeUpdate: action.payload,
            };

        case 'SET_NSTOPWATCH':
            return {
                ...state,
                stopwatch: action.payload,
            };

        case 'SET_NTIMER_SETTINGS':
            return {
                ...state,
                settings: action.payload,
            };

        case 'TOGGLE_NTIMER_MODAL':
            return {
                ...state,
                timerModal: action.payload,
            };

        default:
            return state;
    }
};

export default timerReducer;
