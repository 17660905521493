// import {  } from '../../actions/';

import { setDeviceHistoryModal } from '../../actions/devices';
import { disconnect } from '../../commands/device';
import { btConnect, btDisconnect } from '../../commands/bluetooth';
import { usbConnectReconnect } from '../../commands/port';

export const mapStateToProps = (state) => {
    return {
        modal: state.devices.historyModal,
        battery: state.devices.battery,
        devicesList: state.devices.list,
        connectionType: state.port.connectionType,
        devicesCount: state.devices.count,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setDeviceHistoryModal: (bool) => dispatch(setDeviceHistoryModal(bool)),
    disconnectUsb: () => dispatch(disconnect()),
    connectBt: (device) => dispatch(btConnect(device)),
    disconnectBt: (device) => dispatch(btDisconnect(device)),
    connectUsb: (device) => dispatch(usbConnectReconnect(device)),
});
