import React, { useState, useEffect } from 'react';
import PlayPause from '../../PlayPause';
import Timer from '../../Timer';
import MarkersSettings from '../../MarkersSettings';
import ImportExport from '../../ImportExport';
import { ConnectionButtons } from '../../Connection';
import Icon from '../../Icon';

import ss from './Footer.module.scss';
import {toast} from "react-toastify";

const Footer = (props) => {
    // console.log(props);
    const { port, played, setChartSettingsModal, setSensorsModal, setGraduationModal, toggleTimerModal, isMobile, setDeviceBatteryModal, imported, isOscilloscope, connectionCount } = props;
    const [btnsDisabled, setBtnsDisabled] = useState(true);
    useEffect(() => {
        if (!port && !imported.length) {
            setBtnsDisabled(true);
        }
        if (imported.length || port) {
            setBtnsDisabled(false);
        }
    }, [imported, port]);

    return (
        <>
            <footer className={ss.root}>
                <ConnectionButtons isRlab={true} />
                {isMobile !== 'portrait' && (
                    <div className={ss.group + ' landscape'}>
                        <div className={ss.label}>
                            Доп. <br />
                            действия
                        </div>
                        <div className={ss.btns}>
                            <span
                                data-learn="10"
                                data-learn-border="10"
                                data-learn-position="top"
                            >
                                <Icon
                                    tag="button"
                                    name="pulse"
                                    title="Стили графиков"
                                    disabled={btnsDisabled}
                                    onClick={() => setChartSettingsModal(true)}
                                />
                            </span>
                            <Icon
                                tag="button"
                                name="speedometer"
                                title="Градуировка датчика"
                                onClick={() => (port && connectionCount === 1 ? setGraduationModal(true) : toast.info('Градуировка доступна только для одного подключенного устройства'))}
                                disabled={!port || played || isOscilloscope}
                            />
                            <span
                                data-learn="3"
                                data-learn-border="3"
                                data-learn-position="top"
                            >
                                <Icon
                                    tag="button"
                                    name="remote-control"
                                    disabled={!port || played}
                                    title="Установить каналы"
                                    onClick={() => setSensorsModal(true)}
                                />
                            </span>
                            <Icon
                                tag="button"
                                name="battery-charge"
                                disabled={!port}
                                title="Заряд батареи"
                                onClick={() => setDeviceBatteryModal(true)}
                            />
                            {/* {connectionType === 'usb' && (
                            <Icon
                                tag="button"
                                    name="record"
                                    disabled={!port}
                                    title="Обновление прошивки"
                                    onClick={() => setFirmwareModal(true)}
                                />
                            )} */}
                            {/*<Icon name="dotts"/>*/}
                        </div>
                    </div>
                )}
                <div className={ss.group + ' ' + ss.group_center}>
                    <div className={ss.btns}>
                        <span
                            data-learn="8"
                            data-learn-border="8"
                            data-learn-position="top"
                        >
                            <Icon
                                tag="button"
                                disabled={!port || played}
                                title="Таймер"
                                name="timer"
                                onClick={() => toggleTimerModal(true)}
                            />
                        </span>
                        <PlayPause />
                    </div>
                    <Timer />
                </div>
                {isMobile !== 'portrait' && (
                    <div className={ss.group + ' landscape'}>
                        <div className={ss.label}>
                            Точки <br />и диап.
                        </div>
                        <div className={ss.btns + ' ' + ss.btns_group}>
                            <MarkersSettings />
                        </div>
                    </div>
                )}
                {!isMobile && (
                    <div className={ss.group}>
                        <div className={ss.label}>
                            Работа <br />с файлами
                        </div>
                        <ImportExport />
                    </div>
                )}
            </footer>
        </>
    );
};

export default Footer;
