import React, {useState, useEffect, useRef} from "react";
import { isAuth } from "../../helpers/auth";
import Table from "../ui/Table/Table";
import {printOnlyDate} from "../../helpers/text";
import {STUDY_SUBJECTS, NB_ACT, getSubjectById, getDifferenceInDays, 
    getAllNotebookInfoByOwner, getNotebookById} from './nb_utils';
import {isNbTaskStarted} from "./task_utils";
import {initializeTimeCounter} from "../ui/utils/gen_utils";
import NotebooksStudentInitDlg from './NotebooksStudentInitDlg';
import { Content, ContentBody, ContentDesc, ContentHead } from "../template/ContentParts";
import SectionTabs from "../ui/SectionTabs";
import "../template/Dashboard/Dashboard.scss";
import "./Notebooks.scss";
import nb from "./Notebooks.module.scss";

const NotebookStudentHomeWorks = ({history}) => {
	const [tableList, setTableList] = useState([]);
	const [showInitDlg, setShowInitDlg] = useState(false);
	const [selectedIds, setSelectedIds] = useState(undefined);
    const [currTime, setCurrTime] = useState();

	const timerIdRef = useRef(undefined);

	useEffect(() => {
		timerIdRef.current = initializeTimeCounter(3, setCurrTime);//refresh in 3 sec
		return () => {clearInterval(timerIdRef.current);};
    }, []);

    useEffect(() => {
        const getTableList = async () => {
            const result = await getAllNotebookInfoByOwner(isAuth()._id);
            const nbis = result.data;

            const list = [];

            for (let i = 0; i < nbis.length; i ++) {
                const nbi = nbis[i];
                const nb = await getNotebookById(nbi.notebook);
                const [isStarted, timeRun, timeComplete] = isNbTaskStarted(nb, nbi);
                
                if (isStarted) {
                    const rec = {
                        subject: getSubjectById(nb.titles.subjectId),
                        name: nb.titles.homeWorkName,
                        item: nb.titles.homeWorkItem,
                        dateStart: printOnlyDate(timeRun),
                        leftDays: getDifferenceInDays(timeComplete),
                        taskAmount: nbi.tasks.length + ' шт.',
                        actions: '',
                        id: nbi.notebook + '|' + nbi._id, //notebook Id and notebookInfo id
                    };
                    
                    list.push(rec);
                }
            }

            setTableList(list);
        };

		getTableList();
    }, [currTime]);

    const handleOpenInitDlg = ids => {
        setSelectedIds(ids);
        setShowInitDlg(true);
    };

    const handleExecuteTest = () => {
        setShowInitDlg(false);
        //возможно, здесь еще раз надо проверить дом.задание. если оно завершено, то вызывать handleSubjectTab.
        history.push('/notebooks/' + NB_ACT.RUN_TEST + '/' + selectedIds); //ids is nbId + '|' + nbiId
    };

    const specialCellStyle = (item, rowInd, colInd, row) => {
        const SPEC_TEXT = {
            DAY: 'день',
            DAYS: 'дня',
        };
        if (item.includes(SPEC_TEXT.DAY)) return {color: 'red'};
        if (item.includes(SPEC_TEXT.DAYS) && item.split(' ')[0] === '2') 
            return {color: '#C96203'};
        return {};
    };

    const getTableHeader = () => {
		const header = [
			{column: 'Предмет', name: 'subject', style: { width: '15%'} }, 
			{column: 'Название', name: 'name', style: { width: '15%'} }, 
			{column: 'Тема', name: 'item', style: { width: '15%'} }, 
			{column: 'Дата начала', name: 'dateStart', style: { width: '15%'} }, 
			{column: 'Осталось дней', name: 'leftDays', style: { width: '12%'} }, 
			{column: 'Задания', name: 'taskAmount', style: { width: '13%'} }, 
			{column: 'Действия', name: 'actions', style: { width: '15%'} }, 
			{column: 'id', name: 'id', style: { width: '0%'} }, 
        ];
		return header;
	};

    const getHomeWorkTable = () => {
		return (
		<div className={nb.nb__table}>
			<Table 
				table={{
                    header: getTableHeader(), 
					data: tableList,
                    specialCellStyle: specialCellStyle
				}}
				sort={{
                    hasSorting: true, 
                    initSortInd: -4
                }}
				actions={{
					handleExecuteTest: ids => handleOpenInitDlg(ids),
					textExecuteTest: 'Сделать задание',
				}}
			/>
		</div>
		);
    };

    return  (
        <>
            <ContentHead title="Рабочая тетрадь" />
            <ContentDesc desc="Вы можете просмотреть здесь задания по выбранным предметам" />
            <Content>
                <ContentBody>
                    <div className="cor-net__section">
                        <SectionTabs history={history} tabs={STUDY_SUBJECTS}/>
                    </div>
                    <div className="cor-net__section">
                        <div className="cor-net__title">Невыполненные работы</div>
                        {getHomeWorkTable()}
                    </div>
                </ContentBody>

                {showInitDlg && 
                <NotebooksStudentInitDlg
                    showDlg={showInitDlg}
                    setShowDlg={setShowInitDlg}
                    selectedIds={selectedIds}
                    handleExecuteTest={handleExecuteTest}
                />
                }
            </Content>
        </>

    );

}

export default NotebookStudentHomeWorks;
