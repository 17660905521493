export const setAllDevices = (arr) => ({
    type: 'SET_ALL_DEVICES',
    payload: arr,
});
export const addDevicesList = (obj) => ({
    type: 'ADD_DEVICES_LIST',
    payload: obj,
});
export const removeDevicesList = (obj) => ({
    type: 'REMOVE_DEVICES_LIST',
    payload: obj,
});
export const addDevicesConnected = (obj) => ({
    type: 'ADD_DEVICES_CONNECTED',
    payload: obj,
});
export const removeDevicesConnected = (obj) => ({
    type: 'REMOVE_DEVICES_CONNECTED',
    payload: obj,
});
export const setDevicesBattery = (obj) => ({
    type: 'SET_DEVICES_BATTERY',
    payload: obj,
});
export const setDeviceBatteryModal = (bool) => ({
    type: 'SET_DEVICE_BATTERY_MODAL',
    payload: bool,
});
export const setDeviceConnectedCount = (num) => ({
    type: 'SET_DEVICE_CONNECTED_COUNT',
    payload: num,
});
export const setDeviceConnectedLoader = (bool) => ({
    type: 'SET_DEVICE_CONNECTED_LOADER',
    payload: bool,
});
export const setDeviceReconnect = (bool) => ({
    type: 'SET_DEVICE_RECONNECT',
    payload: bool,
});
export const addDeviceHistory = (obj) => ({
    type: 'ADD_DEVICE_HISTORY',
    payload: obj,
});
export const removeDeviceHistory = (obj) => ({
    type: 'REMOVE_DEVICE_HISTORY',
    payload: obj,
});
export const setDeviceHistoryModal = (bool) => ({
    type: 'SET_DEVICE_HISTORY_MODAL',
    payload: bool,
});
