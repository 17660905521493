import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

const SensorValue = ({ data, num, round, gradOffset }) => {
    const [value, setValue] = useState();
    const [notValue, setNotValue] = useState(false);

    useEffect(() => {
        if (typeof num === 'string') {
            setNotValue(true);
        } else {
            setNotValue(false);
        }
    }, [num, setNotValue]);

    useEffect(() => {
        if (data?.length && !notValue) {
            const vals = data?.filter((e) => e[0]?.num === num)[0];
            if (vals?.length) {
                const val = vals?.length ? vals[0].y : null;
                if (typeof val === 'number') {
                    setValue(val);
                }
            }
        }
    }, [data, notValue, setValue, gradOffset]);

    return +value?.toFixed(round) || 0;
};

const valStateToProps = (state) => {
    return {
        gradOffset: state.sensors.gradOffset,
        data: state.sensors.asideData,
        isOscilloscope: state.oscilloscope.isOscilloscope,
    };
};

const valDispatchToProps = (dispatch) => ({});

export default connect(valStateToProps, valDispatchToProps)(SensorValue);
