export const changeClass = (obj) => ({type: 'CHANGE_CLASS', payload: obj});
export const showClassSettings = (obj) => ({type: 'SHOW_CLASS_SETTINGS', payload: obj});
export const showUserSettings = (obj) => ({type: 'SHOW_USER_SETTINGS', payload: obj});
export const showUserSelect = (obj) => ({type: 'SHOW_USER_SELECT', payload: obj});
export const showUserPassword = (obj) => ({type: 'SHOW_USER_PASSWORD', payload: obj});
export const showRlabSettings = (obj) => ({type: 'SHOW_RLAB_SETTINGS', payload: obj});
export const showVideoSettings = (obj) => ({type: 'SHOW_VIDEO_SETTINGS', payload: obj});
export const showRlabSensors = (obj) => ({type: 'SHOW_RLAB_SENSORS', payload: obj});
export const showRlabNotification = (obj) => ({type: 'SHOW_RLAB_NOTIFICATION', payload: obj});
export const showDeviceSettings = (obj) => ({type: 'SHOW_DEVICE_SETTINGS', payload: obj});
export const showFileSettings = (obj) => ({type: 'SHOW_FILE_SETTINGS', payload: obj});
export const showStorageSettings = (obj) => ({type: 'SHOW_STORAGE_SETTINGS', payload: obj});
export const showNameSettings = (obj) => ({type: 'SHOW_NAME_SETTINGS', payload: obj});
