import React, { useState, useEffect } from "react";
import Table from "../ui/Table/Table";
import {getLabWorkArraysAndNames} from "../practicum/lw_utils";
import labsList from '../practicum/subjectList';
import {printOnlyDate} from "../../helpers/text";
import {vrLabInfoApi} from "../../api/api";

const ProfileVirtLabTable = ({profile}) => {
	const [vrLabData, setVrLabData] = useState([]);

	useEffect(() => {
		const loadData = async () => {
            const result = await vrLabInfoApi.getVrLabInfoByOwnerId(profile._id);
			if (!result) return;
            const [,sect,] = getLabWorkArraysAndNames(labsList.subjectList, '01-physics', '04-optics', undefined);
            const sectionLabList = sect.labList;
            
            const list = [];
            for (let i = 0; i < result.data.length; i ++) {
                const item = result.data[i];
                list.push({
                    gameInd: item.vrLabInd, 
                    name: sectionLabList[item.vrLabInd-1].labName,
					result: item.step + ' из ' + item.stepAmount,
					progress: item.step === 0 ? 'Не начата' : item.isComplete ? 'Готово' : 'Начата',
					date: printOnlyDate(item.updatedAt), 
                    id: item._id
                });
            }
			setVrLabData(list);
    	};

		loadData();
    }, [profile._id]);

  const getTableHeader = () => {
	  	const header = [
			{column: 'Игра N', name: 'gameInd', style: { width: '10%'} }, 
			{column: 'Название', name: 'name', style: { width: '35%'} }, 
			{column: 'Результаты', name: 'result', style: { width: '20%'}}, 
			{column: 'Прогресс выполнения', name: 'progress', style: { width: '20%'}},
			{column: 'Дата', name: 'date', style: { width: '15%'}}, 
			{column: 'id', name: 'id', style: { width: '0%'} }, 
        ];
	  	return header;
    };

    return ( 
		<div>
            <Table
                table={{
                    header: getTableHeader(), 
                    data: vrLabData,
                }}
                sort={{
                    hasSorting: true, 
                    initSortInd: 1,
                }}
            />
		</div>
    )
}

export default ProfileVirtLabTable;
