import React, { useState, useEffect } from 'react';
import { Charts } from '../../Charts';
import Table from '../../Table';
// import Help from '../../Help';

import emptyDark from '../../../assets/img/no-connect-dark.png';
import emptyLight from '../../../assets/img/no-connect.png';

import ass from '../Aside/Aside.module.scss';
import ss from './Dashboard.module.scss';

const Dashboard = (props) => {
    // console.log(props);
    const {
        port,
        isOscilloscope,
        sensorImported,
        theme,
        // setForceUpdate,
        isMobile,
        mobileView,
    } = props;
    const [tables, toggleTables] = useState(false);
    const [isData, setData] = useState(false);
    const [initialPos, setInitialPos] = useState(null);
    const [initialSize, setInitialSize] = useState(null);
    const [isResized, setResized] = useState(false);

    const initial = (e) => {
        let resizable = document.getElementById('resizable');
        setInitialPos(e.clientX);
        setInitialSize(resizable.offsetWidth);
    };

    const resize = (e) => {
        let resizable = document.getElementById('resizable');
        resizable.style.width = `${+initialSize + +(initialPos - e.clientX)}px`;
    };

    useEffect(() => {
        if (isOscilloscope) {
            toggleTables(false);
        }
        if (!port) {
            setData(false);
        }
    }, [port, isOscilloscope]);

    useEffect(() => {
        if (sensorImported.length > 0) {
            setData(true);
        } else {
            !port && setData(false);
        }
    }, [sensorImported, port, setData]);

    useEffect(() => {
        if (port) {
            setData(true);
        } else {
            setData(false);
        }
    }, [port]);

    useEffect(() => {
        if (isData) {
            toggleTables(true);
        } else {
            toggleTables(false);
        }
    }, [isData]);

    const renderTableAside = () => {
        if (isOscilloscope) return false;
        return (
            <>
                {!isMobile && (
                    <div
                        className={tables ? ass.resize : ''}
                        draggable={true}
                        onDragStart={(e) => {
                            setResized(true);
                            initial(e);
                        }}
                        onDragEnd={(e) => {
                            resize(e);
                            setTimeout(function () {
                                setResized(false);
                            }, 300);
                        }}
                        onDrag={(e) => {
                            resize(e);
                        }}
                    ></div>
                )}
                <div
                    className={!isMobile ? (tables ? (isResized ? ass.root + ' resized' : ass.root) : ass.collapse) : mobileView === 'table' ? ass.root : ass.collapse}
                    id={'resizable'}
                >
                    {!isMobile && (
                        <div
                            title={tables ? 'Свернуть' : 'Открыть'}
                            className={ass.actions + ' ' + ass.table}
                            onClick={() => toggleTables(!tables)}
                        >
                            <div className={tables ? ass.toggle + ' cl_icon_toggler' : ass.toggleInactive + ' cl_icon_bullet-list'}></div>
                            <div className={ass.title}>Значения</div>
                        </div>
                    )}
                    <div className={tables && !isOscilloscope ? ss.wrapper : ss.hidden}>
                        <Table />
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className={ss.root}>
            {isData ? (
                <>
                    <Charts />
                    {renderTableAside()}
                    {/* <Help collapsed={!tables} /> */}
                </>
            ) : (
                <div className="emptyScreen">
                    <div className="emptyScreen__img">
                        {theme && theme === 'dark' ? (
                            <img
                                src={emptyDark}
                                alt=""
                            />
                        ) : (
                            <img
                                src={emptyLight}
                                alt=""
                            />
                        )}
                    </div>
                    <div className="emptyScreen__text">Для продолжения работы необходимо выбрать источник сигнала</div>
                </div>
            )}
        </div>
    );
};

export default Dashboard;
