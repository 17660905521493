import React, {useEffect} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {Content, ContentWrap} from "../ContentParts";
import {storageOptions} from "../../../redux/slices/storage";
import CorFooter from '../Footer';
import Dashboard from '../../../rlab-neuro/src/components/template/Dashboard';
import Footer from '../../../rlab-neuro/src/components/template/Footer';
import ChartSettingsModal from "../../../rlab-neuro/src/components/Modals/ChartSettings";
import ModalPrint from "../../../rlab-neuro/src/components/Modals/ModalPrint";
import ExportSettingsModal from "../../../rlab-neuro/src/components/ExportSettingsModal";
import TimerModal from "../../../rlab-neuro/src/components/Modals/TimerModal";
import Connection from "../../../rlab-neuro/src/components/Connection";
import {resetReduxState, setTheme} from "../../../rlab-neuro/src/actions/app";
import {autoConnectPort} from "../../../rlab-neuro/src/commands/port";
import "./Dashboard.scss";
import ResetConfirmModal from "../../../rlab-neuro/src/components/Modals/ResetConfirmModal";
import AboutModal from "../../../rlab-neuro/src/components/Modals/AboutModal";
import ss from "./DashboardRlab.module.scss";

export const DashboardRlabNeuro = (props) => {
  const dispatch = useDispatch();
  const options = useSelector(storageOptions);

  useEffect(() => {
    dispatch(setTheme(options.theme ?? 'light'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.theme]);

  return (
      <ContentWrap className={(options.theme==="dark"?" dark":"")}>
          <Content background={false}>
            <main className={ss.main}>
                <Dashboard />
            </main>
            <Footer />
            <Connection />
            <TimerModal />
            <ChartSettingsModal />
            <ModalPrint />
            <ResetConfirmModal />
            <AboutModal />
            <ExportSettingsModal />
            <div className="modal" id="devicepicker">
                <div className="modal__dialog">
                    <div className="modal__close"></div>
                    <div className="modal__message">Выберите устройство</div>
                    <div className="modal__body"></div>
                </div>
            </div>
          </Content>
          <CorFooter {...props}/>
      </ContentWrap>
);
}

const nmapStateToProps = (nstate) => {
  return {
    devices: nstate.ndevice.all,
    port: nstate.nport.open,
  };
};

export const nmapDispatchToProps = (dispatch) => ({
  resetReduxState: (item) => dispatch(resetReduxState(item)),
  autoConnectPort: () => dispatch(autoConnectPort()),
});

export default connect(nmapStateToProps, nmapDispatchToProps)(DashboardRlabNeuro)
