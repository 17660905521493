import React, {useState, useEffect} from 'react';
// import Modal from "../../Components/Modal";
import { Modal, Button } from 'rlabui';
import Table from "../ui/Table/Table";
import {getScoreValues, getScoreNames, getTraditionalScoreNames} from '../ui/utils/score_utils';
import "../ui/ModalDialogs/PracticumTests.scss";

const ScoreCalcTableDlg = ({ showHowToCalcDlg, setShowHowToCalcDlg }) => {
	const [tableData, setTableData] = useState([]);

	useEffect(() => {
        const table = [];
        const values = getScoreValues(); 
        const names = getScoreNames();
        const traditionalNames = getTraditionalScoreNames();

        for (let i = 0; i < values.length; i ++) {
            const item = {
                pct: names[i],
                score: '' + (i === values.length - 1?  0 :  values[i]) + ' - ' + (i === 0 ? 100 : values[i-1] - 1),
                traditional: traditionalNames[i]
            };
            table.push(item);
        }

        setTableData(table);
	},[]);

	const getTableHeader = () => {
		return [ 
			{column: 'Оценка по пятибальной системе', name: 'pct', style: { width: '34%'} }, 
			{column: '% правильных ответов', name: 'score', style: { width: '33%'} }, 
			{column: 'Традиционная оценка', name: 'traditional', style: { width: '33%'} }, 
		];
	};
    const handleRowColor = rowInd => {
        const bkndColors = ['#F9F0F0', '#F0D9D9', '#F3F9F0', '#E2F0D9',  '#F3F9F0', 
                            '#d9e4f0', '#ffffff', '#F0F4F9', '#E2E2E2'];
        return {
            backgroundColor: bkndColors[rowInd]
        }
    };

    if (!showHowToCalcDlg) {
        return null;
    }

    return (
        <Modal visible={showHowToCalcDlg} size="md">
            <Modal.Head modalClose={() => setShowHowToCalcDlg(false)} title='Таблица оценки' />
            <Modal.Body>
                <Table 
                    table={{
                        header: getTableHeader(), 
                        data: tableData,
                        handleRowStyle: handleRowColor, 
                        rowClassPrefix: 'Score'
                    }}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setShowHowToCalcDlg(false)} border={true}>Закрыть</Button>
            </Modal.Footer>
        </Modal>
    )
};

export default ScoreCalcTableDlg;
