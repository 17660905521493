import React from "react";
import Footer from '../Footer';
import "../../practicum/TutorialSelectTab.scss";
import "./Dashboard.scss";
import {ContentHead, ContentWrap} from "../ContentParts";
import PInvestUnits from "../../PhysicalInvest/PInvestUnits";
import {Tabs} from "../../ui";
import PInvestigationsOne from "../../PhysicalInvest/PInvestigationsOne";
import PInvestigationsList from "../../PhysicalInvest/PInvestigationsList";
import PInvestObjectsOne from "../../PhysicalInvest/PInvestObjectsOne";
import PInvestObjectsList from "../../PhysicalInvest/PInvestObjectsList";
import {hasFeature, isAuth} from "../../../helpers/auth";
import {useSelector} from "react-redux";
import {storageOrg} from "../../../redux/slices/storage";
import DashboardLicense from "./DashboardLicense";

const tabs = {
  res: {name: 'Работы', to: '/pi/res'},
  obj: {name: 'Объекты', to: '/pi/obj'},
  unit: {name: 'Единицы измерения', to: '/pi/unit'},
};

export const DashboardPInvestigations = (props) => {
  const documentOrg = useSelector(storageOrg);
  if (isAuth().role < 3 && !hasFeature(documentOrg, 'pi')) return <DashboardLicense {...props}/>;

  return (
    <ContentWrap>
      <ContentHead>
        <div className="cor-net">
          <div className="cor-net__title">Исследования</div>
          <div className="cor-net__row">
            <Tabs border={false}>
              {Object.entries(tabs).map(([id, tab]) =>
                <Tabs.Item key={id} active={props.match.params.opt === id}
                           onClick={() => props.history.push(tab.to)}>{tab.name}</Tabs.Item>)}
            </Tabs>
          </div>
        </div>
      </ContentHead>
        {{
          res: props.match.params.id ? <PInvestigationsOne {...props}/> : <PInvestigationsList {...props}/>,
          obj: props.match.params.id ? <PInvestObjectsOne {...props} />: <PInvestObjectsList {...props} />,
          unit: <PInvestUnits {...props} />,
        }[props.match.params.opt]}
      <Footer  {...props}/>
    </ContentWrap>
  );
}

export default DashboardPInvestigations;
