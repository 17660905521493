import {toggleChat} from "../../../actions/toggle";
import {changeClass} from "../../../actions/actions";
import {reloadOption} from "../../../redux/slices/storage";

export const mapStateToProps = (state) => {
    return ({
        showChat: state.toggle.showChat,
    });
};

export const mapDispatchToProps = (dispatch) => ({
    toggleChat: (bool) => dispatch(toggleChat(bool)),
    changeClass: (obj) => dispatch(changeClass(obj)),
    setAside: (bool) => dispatch(reloadOption('aside', bool)),
});
