import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'rlabui';
import { setSensorGradOffset } from '../../actions/sensors';
import ss from './Sensor.module.scss';

const SensorResetButton = ({ played, data, num, round, unit, setSensorGradOffset, gradOffset, quantity, isCor, setShowUnitInfo, setUnitInfo }) => {
    const [sensOffset, setSensOffset] = useState(null);
    const [value, setValue] = useState();

    useEffect(() => {
        setSensOffset(gradOffset[num]);
    }, [gradOffset, num]);

    useEffect(() => {
        const vals = data?.filter((e) => e[0]?.num === num)[0];
        if (vals?.length) {
            vals[0]?.y && setValue(vals[0].y || 0);
        }
    }, [data]);

    useEffect(() => {
        setSensorGradOffset({ key: num, data: sensOffset });
    }, [sensOffset, num]);

    return (
        <>
            <span data-learn-border="21">
                <Button
                    disabled={played}
                    border={true}
                    size={true}
                    title="Сброс к нулевым показателям"
                    onClick={() => setSensOffset(sensOffset !== null ? null : value || 0)}
                >
                    Сброс
                </Button>
            </span>
            {sensOffset !== null && !isNaN(sensOffset) && (
                <div className={ss.grade}>
                    {+sensOffset?.toFixed(round)} {unit}
                </div>
            )}{' '}
        </>
    );
};

const valStateToProps = (state) => {
    return {
        played: state.actions.played,
        data: state.sensors.asideData,
        gradOffset: state.sensors.gradOffset,
    };
};

const valDispatchToProps = (dispatch) => ({
    setSensorGradOffset: (obj) => dispatch(setSensorGradOffset(obj)),
});

export default connect(valStateToProps, valDispatchToProps)(SensorResetButton);
