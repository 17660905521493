import * as esptooljs from 'esptool-js';
import {toast} from "react-toastify";
import CryptoJS from 'crypto-js';
import { setFirmwareConnection, setFirmwareTerminal, setFirmwareProgress, setFirmwareSuccess, setFirmwareError, setFirmwareSize } from '../actions/firmware';
let ESPLoader = esptooljs.ESPLoader;
let Transport = esptooljs.Transport;
let device = null;
let transport;
let chip = null;
let esploader;
let connected = false;
let fwDispatch = () => null;
let debug = '';
let uploadCount = 0;
let espLoaderTerminal;

export const setProgrammMode = () => async (dispatch, setState) => {
    console.log('setProgrammMode');
    const {
        reader: { data: reader },
        stream: { data: stream, closed: readableStreamClosed },
        devices: { connected: connectedDevices },
    } = setState();

    fwDispatch = dispatch;
    debug = '';
    fwDispatch(setFirmwareTerminal(debug));

    fwDispatch(setFirmwareConnection(true));
    fwDispatch(setFirmwareSuccess(null));
    fwDispatch(setFirmwareError(null));

    espLoaderTerminal = {
        clean() {
            // console.log('clear');
            debug = '';
            uploadCount = 0;
            fwDispatch(setFirmwareTerminal(debug));
        },
        writeLine(data) {
            // console.log('writeLine', data);
            if (data !== 'esptool.js') {
                debug = debug + data + '\r\n';
                fwDispatch(setFirmwareTerminal(debug));

                if (data.includes("reading 'BOOTLOADER_FLASH_OFFSET'")) {
                    toast.error(
                        'Попробуйте обновить прошивку еще раз, или уточните возможность прошивки вашего устройства в службе поддержки',
                        'Ошибка подключения в режим прошивки',
                        5000
                    );
                }

                if (data.includes('Error')) {
                    fwDispatch(setFirmwareError(data));
                    fwDispatch(setFirmwareConnection(false));
                    fwDispatch(setFirmwareSuccess(null));
                }
            }
        },
        write(data) {
            // console.log('write', data);
            debug = debug + data;
            fwDispatch(setFirmwareTerminal(debug));
        },
        progress(perc) {
            // console.log(perc);
            if (perc === 100) {
                uploadCount++;
            }
            if (uploadCount === 3) {
                fwDispatch(setFirmwareConnection(false));
                fwDispatch(setFirmwareSuccess('success'));
            }
            fwDispatch(setFirmwareProgress(+perc.toFixed()));
        },
    };

    device = connectedDevices['usb0'];

    if (device) {
        try {
            if (stream[0]) {
                try {
                    await stream[0].cancel();
                } catch (err) {}
            }

            await readableStreamClosed?.catch(() => {});

            if (reader?.length && reader[0].locked) {
                // await reader.releaseLock();
                await reader[0].cancel();
                // console.log(2, reader);
            }

            await device.close();
        } catch (e) {
            console.log(e);
        }

        transport = new Transport(device);

        try {
            esploader = new ESPLoader(transport, 115200, espLoaderTerminal);
            chip = await esploader.main_fn();
            connected = true;
            // Temporarily broken
            // await esploader.flash_id();
        } catch (e) {
            fwDispatch(setFirmwareError(e.message));
        }
    }
};

export const startProgramm = (fileArray) => async (dispatch, setState) => {
    try {
        // console.log('fileArray', fileArray);
        await esploader.write_flash(
            fileArray,
            'keep',
            undefined,
            undefined,
            false,
            true,
            (fileIndex, written, total) => {
                // console.log(written);
                // console.log(total);
                dispatch(setFirmwareSize(total));
                espLoaderTerminal.progress((written / total) * 100);
            },
            // null
            (image) => CryptoJS.MD5(CryptoJS.enc.Latin1.parse(image))
        );
    } catch (e) {
        // console.error(e);
        espLoaderTerminal.writeLine(`Error: ${e.message}`);
    } finally {
        // Hide progress bars and show erase buttons
    }
};

const cleanUp = () => {
    device = null;
    transport = null;
    chip = null;
    connected = false;
    // espLoaderTerminal.clean();
    // espLoaderTerminal = null;
    // window.removeEventListener('load', takeOverConsole, false);
};
