import React from "react";
import {Content, ContentBody, ContentDesc, ContentHead} from "../template/ContentParts";
import SectionTabs from "../ui/SectionTabs";
import {TUTORIAL_SUBJECTS} from "./TutorialConfig";
import {FEATURES} from "../../api/config";
import "../template/Dashboard/Dashboard.scss";

const TutorialMain = ({history}) => {
  return (
    <>
      <ContentHead title="Интерактивные пособия"/>
      <ContentDesc desc="Выберите предмет, для которого хотите создать интерактивные пособие"/>
      <Content background={false}>
        <ContentBody padding={false}>
          <SectionTabs history={history}
                       tabs={TUTORIAL_SUBJECTS.map((s, id) => ({...s, to: FEATURES.tutorial.to + '/' + id}))}/>
        </ContentBody>
      </Content>
    </>
  );
}

export default TutorialMain;
