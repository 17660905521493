import { setChartX, setChartY, setChartMode, setChartMarkerAdd, setChartMarkerDelete, setChartMarkerDeleteAll, clearChartMarkerModal } from '../../actions/chart';

export const mapStateToProps = (nstate) => {
    return {
        port: nstate.nport.open,
        imported: nstate.nsensor.imported.length,
        settings: nstate.nchart,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setChartX: (str) => dispatch(setChartX(str)),
    setChartY: (str) => dispatch(setChartY(str)),
    setChartMode: (str) => dispatch(setChartMode(str)),
    setChartMarkerAdd: (bool) => dispatch(setChartMarkerAdd(bool)),
    setChartMarkerDelete: (bool) => dispatch(setChartMarkerDelete(bool)),
    setChartMarkerDeleteAll: (bool) => dispatch(setChartMarkerDeleteAll(bool)),
    clearChartMarkerModal: (bool) => dispatch(clearChartMarkerModal(bool)),
});
