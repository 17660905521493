import { useEffect, useRef } from 'react';
import {toast} from "react-toastify";

const Connection = ({
    port,
    response,
    played,
    setSensorList,
    command,
    writer,
    sendCommand,
    serialPortWrite,
    devices,
    setDevicesNames,
    devicesNames,
    connected,
    setDevicesConnected,
    setArtPressure,
    setChartXRanges,
}) => {
    const checkInterval = useRef(null);

    useEffect(() => {
        !port && clearInterval(checkInterval.current);
    }, [port]);

    useEffect(() => {
        if (command) {
            serialPortWrite(command);
            sendCommand('');
        }
    }, [command, serialPortWrite, sendCommand]);

    useEffect(() => {
        if (!devices.length) return;
        let arr = [];
        devices.forEach((device) => {
            const {
                attributes: { key },
            } = device;
            arr = [...arr, key];
        });
        setDevicesNames(arr);
    }, [devices, port, setDevicesNames]);

    useEffect(() => {
        if (connected.length) {
            let hiCount = 0;
            const list = [];
            connected?.forEach((elem, i) => {
                let channels = [];
                // Проверяем наличие подключенных высокочастотных датчиков
                const hightFreq = devices.filter((device) => device.attributes.key === elem && device.attributes.hightFreq)[0];
                if (hightFreq) {
                    hiCount++;
                }

                const sensor = devices.filter((device) => device.attributes.key === elem)[0];
                const sensChannels = sensor.getElementsByTagName('channel');
                sensChannels?.forEach((channel, k) => {
                    const { color, key, name, quantity, roundDigit, unit } = channel.attributes;
                    channels[k] = {
                        name,
                        color,
                        key,
                        num: i,
                        unit,
                        quantity,
                        roundDigit,
                        value: [],
                        parent: elem,
                    };
                });
                if (sensor?.attributes) {
                    const { color, key, name, quantity, roundDigit, unit } = sensor.attributes;
                    list[i] = {
                        name,
                        color,
                        key,
                        num: i,
                        unit,
                        quantity,
                        roundDigit,
                        value: [],
                        channels,
                    };
                }
            });

            if (list.length > 2 && hiCount) {
                toast.warn(
                    'У вас подключено несколько датчиков часть из которых высокочастотные. Это может вызывать не корректный вывод показаний всех датчиков',
                    'Возможны не точные показания'
                );
            }

            // list.forEach(({ key }) => {
            //     setChartXRanges({ key, data: [0, 10000] });
            // });

            setSensorList(list);

            if (connected.filter((e) => e === 'ABPMeter').length) {
                setArtPressure(true);
            } else {
                setArtPressure(false);
            }
        }
    }, [connected, devices, setSensorList, setArtPressure, setChartXRanges]);

    useEffect(() => {
        if (writer) {
            if (played) {
                clearInterval(checkInterval.current);
            } else {
                checkInterval.current = setInterval(() => {
                    sendCommand('chs');
                }, 3000);
            }
        }
    }, [played, writer, sendCommand]);

    useEffect(() => {
        if (!played) {
            if (response === 'NeuroMeter') {
                sendCommand('chs');
            } else {
                const getConnected = (str) => {
                    if (played) return;
                    // console.log(played);
                    let skipUpdate = false;
                    if (str?.length) {
                        const arr = str.split(' ');

                        // Еслш в строке есть error
                        if (str.includes('error')) {
                            skipUpdate = true;
                        }

                        // Проверяем корректность ключей в строке
                        const checkName = devicesNames.filter((e) => str.includes(e));
                        if (checkName.length && checkName.length !== arr.length) skipUpdate = true;
                        // console.log(checkName, arr, str);

                        if (!skipUpdate) {
                            // Обновляем список подключенных датчиков
                            const devices = arr?.filter((e) => devicesNames.includes(e));
                            devices.length && setDevicesConnected(devices);
                        }
                    }
                };
                getConnected(response);
            }
        }
    }, [response, played, devicesNames, sendCommand, setDevicesConnected]);

    return false;
};
export default Connection;
