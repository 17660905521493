import { NB_TEMPLATE_TYPE, NB_COMPLEXITY, NB_SAMPLE_TYPE} from './nb_utils';

/*
СПЕЦИАЛЬНРЫЕ СИМВОЛЫ:
@0, @1, ...  - параметры
@+0, ...    параметры, которые писать обязательно
$0, $1, ... - знаки
$-0, $-1, ... - знаки, которые игнорируются, если число после него положительное

^+   - поднять текст наполовину до ^. или до конца строки
^-   - опустить текст наполовину до ^. или до конца строки

Пример:
formulas: ['@0x0^+2^+ $1@1x1 $2@2 = 0'], //$1 means sign + or -
2x2 + 7x + 3 = 0  => @0x^+2^+ $1  @1x   @2 = 0 (например @0 = +2, @1 = +7, @2 = +3")
task: Найти корни квадратного уравнения.
find: x0, x1;
params: 3
complexity: 0       x = a 'simple'        a = [-10, 10]
complexity: 1       x = a / b             a = [-10, 10]       b = [-4, 4]
complexity: 2       x = 'ratio' (a / b)   a = [-20, 20]       b = [-20, 20]
*/

const DEFAULT_EQUATION_RANGES = [
    {id: NB_COMPLEXITY.LOW,    topRange: [1, 10], bottomRange: [1, 1]}, 
    {id: NB_COMPLEXITY.MEDIUM, topRange: [1, 10], bottomRange: [1, 8]},
    {id: NB_COMPLEXITY.HIGH,   topRange: [1, 20], bottomRange: [1, 14]},
];
const A0 = [0, 0], A1 = [0, 1], A2 = [0, 2], A3 = [0, 3];
const B0 = [1, 0], B1 = [1, 1], B2 = [1, 2], B3 = [1, 3];

//
//==============================================================
const number_nod_01 = {
    //берем 2 разных случайных числа из topRange 
    //берем multiplierNum чисел из bottomRange и умножаем их на 1-е и 2-е числа
    templateTypeId: NB_TEMPLATE_TYPE.MATH_NODNOK,
    sampleTypeId: NB_SAMPLE_TYPE.NOD,
    templateId: 'js3m66vhbw',
    name: 'Наибольший общий делитель',
    task: 'Найти НОД.',
    prompt: '',
    formulas: [
        {
            formula: 'НОД(@0, @1)',
            answerName: 'НОД',
            coefRanges: [
                {id: NB_COMPLEXITY.LOW,    topRange: [2, 9], bottomRange: [2, 2, 2, 3, 3, 5, 7], multiplierNum: 2}, 
                {id: NB_COMPLEXITY.MEDIUM, topRange: [2, 12], bottomRange: [2, 2, 2, 3, 3, 3, 5, 5, 7], multiplierNum: 3},
                {id: NB_COMPLEXITY.HIGH,   topRange: [2, 15], bottomRange: [2, 2, 2, 2, 3, 3, 3, 5, 5, 7, 11], multiplierNum: 3},
            ],
        },
    ],
};

//==============================================================
const number_nok_01 = {
    //берем 2 разных случайных числа из topRange 
    //Раскладываем их на простые числа, убираем повторяющиеся, перемножаем и тем самым находим НОК
    templateTypeId: NB_TEMPLATE_TYPE.MATH_NODNOK,
    sampleTypeId: NB_SAMPLE_TYPE.NOK,
    templateId: '7xpp4bhg8k',
    name: 'Наименьшее общее кратное',
    task: 'Найти НОК.',
    prompt: '',
    formulas: [
        {
            formula: 'НОК(@0, @1)',
            answerName: 'НОК',
            coefRanges: [
                {id: NB_COMPLEXITY.LOW,    topRange: [2, 15], bottomRange: [2, 5]}, 
                {id: NB_COMPLEXITY.MEDIUM, topRange: [6, 25], bottomRange: [2, 5]},
                {id: NB_COMPLEXITY.HIGH,   topRange: [8, 35], bottomRange: [2, 5]},
            ],
        },
    ],
};

//==============================================================
const number_calc_plus_01 = {
    templateTypeId: NB_TEMPLATE_TYPE.MATH_NUMBER_PLUS,
    sampleTypeId: NB_SAMPLE_TYPE.CALC_NUMBERS,
    templateId: '567f2f6g99',
    name: 'Сложение целых чисел',
    task: 'Найти сумму двух целых чисел.',
    isAnySign: false, //значения коээфициентов только +
    coefNumber: 2,
    prompt: '',
    formulas: [
        {
            formula: '$-0@0 $1 @1',
            answerName: 'Сумма',
            coefRanges: [
                {id: NB_COMPLEXITY.LOW,    topRange: [1, 25], bottomRange: [1, 1]}, 
                {id: NB_COMPLEXITY.MEDIUM, topRange: [2, 99], bottomRange: [1, 1]},
                {id: NB_COMPLEXITY.HIGH,   topRange: [2, 300], bottomRange: [1, 1]},
            ],
            coefMultipliers: [1, 1],
        },
    ],
    calcSolutionRules: [ //размер массива равен числу решений уравнения. Зададим решения с помощью коэф-тов a0/b0, a1,b1, a2/b2..
        { action: '+', coeffIndexes: [0, 1]},  //the solution is sum [a0/b0 + a1/b1]
    ],
};

const number_calc_plus_02 = {
    templateTypeId: NB_TEMPLATE_TYPE.MATH_NUMBER_PLUS,
    sampleTypeId: NB_SAMPLE_TYPE.CALC_NUMBERS,
    templateId: 'kkkjhsdmo3',
    name: 'Сложение десятичных чисел',
    task: 'Найти сумму двух десятичных чисел.',
    isAnySign: false, //значения коээфициентов только +
    coefNumber: 2,
    decimal: true,
    prompt: '',
    formulas: [
        {
            formula: '$-0@0 $1 @1',
            answerName: 'Сумма',
            coefRanges: [
                {id: NB_COMPLEXITY.LOW,    topRange: [1, 100], bottomRange: [10, 10], decimalNumber: 1}, 
                {id: NB_COMPLEXITY.MEDIUM, topRange: [2, 2000], bottomRange: [10, 10], decimalNumber: 2},
                {id: NB_COMPLEXITY.HIGH,   topRange: [2, 5000], bottomRange: [10, 10], decimalNumber: 3},
            ],
            
            coefMultipliers: [1, 1],
        },
    ],
    calcSolutionRules: [ //размер массива равен числу решений уравнения. Зададим решения с помощью коэф-тов a0/b0, a1,b1, a2/b2..
        { action: '+', coeffIndexes: [0, 1]},  //the solution is sum [a0/b0 + a1/b1]
    ],
};

const number_calc_plus_03 = {
    templateTypeId: NB_TEMPLATE_TYPE.MATH_NUMBER_PLUS,
    sampleTypeId: NB_SAMPLE_TYPE.CALC_NUMBERS,
    templateId: 'rl4d15vtod',
    name: 'Сложение рациональных чисел',
    task: 'Найти сумму двух рациональных чисел.',
    isAnySign: false, //значения коээфициентов только +
    coefNumber: 2,
    prompt: '',
    formulas: [
        {
            formula: '$-0@0 $1 @1',
            answerName: 'Сумма',
            coefRanges: [
                {id: NB_COMPLEXITY.LOW,    topRange: [1, 25], bottomRange: [2, 5]}, 
                {id: NB_COMPLEXITY.MEDIUM, topRange: [2, 199], bottomRange: [2, 10]},
                {id: NB_COMPLEXITY.HIGH,   topRange: [2, 600], bottomRange: [2, 20]},
            ],
            coefMultipliers: [1, 1],
        },
    ],
    calcSolutionRules: [ //размер массива равен числу решений уравнения. Зададим решения с помощью коэф-тов a0/b0, a1,b1, a2/b2..
        { action: '+', coeffIndexes: [0, 1]},  //the solution is sum [a0/b0 + a1/b1]
    ],
};

//==============================================================
const number_calc_minus_01 = {
    templateTypeId: NB_TEMPLATE_TYPE.MATH_NUMBER_MINUS,
    sampleTypeId: NB_SAMPLE_TYPE.CALC_NUMBERS,
    templateId: 'shfkfdcxjz',
    name: 'Вычитание целых чисел',
    task: 'Найти разность целых чисел.',
    isAnySign: false,
    coefNumber: 2,
    prompt: '',
    formulas: [
        {
            formula: '$-0@0 $1 @1',
            answerName: 'Разность',
            coefRanges: [
                {id: NB_COMPLEXITY.LOW,    topRange: [1, 25], bottomRange: [1, 1]},
                {id: NB_COMPLEXITY.MEDIUM, topRange: [2, 99], bottomRange: [1, 1]},
                {id: NB_COMPLEXITY.HIGH,   topRange: [2, 300], bottomRange: [1, 1]},
            ],
            coefMultipliers: [1, -1],
            exceptions: [{exceptType: '>', ind0: 0, ind1: 1},],
        },
    ],
    calcSolutionRules: [ //размер массива равен числу решений уравнения. Зададим решения с помощью коэф-тов a0/b0, a1,b1, a2/b2..
        { action: '+', coeffIndexes: [0, 1]},  //the solution is sum [a0/b0 + (- a1/b1)]
    ],
};

const number_calc_minus_02 = {
    templateTypeId: NB_TEMPLATE_TYPE.MATH_NUMBER_MINUS,
    sampleTypeId: NB_SAMPLE_TYPE.CALC_NUMBERS,
    templateId: 'xm787djk5m',
    name: 'Вычитание десятичных чисел',
    task: 'Найти разность десятичных чисел.',
    isAnySign: false, //значения коээфициентов только +
    coefNumber: 2,
    decimal: true,
    prompt: '',
    formulas: [
        {
            formula: '$-0@0 $1 @1',
            answerName: 'Разность',
            coefRanges: [
                {id: NB_COMPLEXITY.LOW,    topRange: [1, 100], bottomRange: [10, 10], decimalNumber: 1}, 
                {id: NB_COMPLEXITY.MEDIUM, topRange: [2, 2000], bottomRange: [10, 10], decimalNumber: 2},
                {id: NB_COMPLEXITY.HIGH,   topRange: [2, 5000], bottomRange: [10, 10], decimalNumber: 3},
            ],
            coefMultipliers: [1, -1],
        },
    ],
    calcSolutionRules: [ //размер массива равен числу решений уравнения. Зададим решения с помощью коэф-тов a0/b0, a1,b1, a2/b2..
        { action: '+', coeffIndexes: [0, 1]},  //the solution is sum [a0/b0 + (- a1/b1)]
    ],
};

const number_calc_minus_03 = {
    templateTypeId: NB_TEMPLATE_TYPE.MATH_NUMBER_MINUS,
    sampleTypeId: NB_SAMPLE_TYPE.CALC_NUMBERS,
    templateId: 'ns6pv8u9ew',
    name: 'Вычитание рациональных чисел',
    task: 'Найти разность рациональных чисел.',
    isAnySign: false, //значения коээфициентов только +
    coefNumber: 2,
    prompt: '',
    formulas: [
        {
            formula: '$-0@0 $1 @1',
            answerName: 'Разность',
            coefRanges: [
                {id: NB_COMPLEXITY.LOW,    topRange: [1, 25], bottomRange: [2, 5]}, 
                {id: NB_COMPLEXITY.MEDIUM, topRange: [2, 199], bottomRange: [2, 10]},
                {id: NB_COMPLEXITY.HIGH,   topRange: [2, 600], bottomRange: [2, 20]},
            ],
            coefMultipliers: [1, -1],
        },
    ],
    calcSolutionRules: [ //размер массива равен числу решений уравнения. Зададим решения с помощью коэф-тов a0/b0, a1,b1, a2/b2..
        { action: '+', coeffIndexes: [0, 1]},  //the solution is sum [a0/b0 + (- a1/b1)]
    ],
};

//==============================================================
const number_calc_multiply_01 = {
    templateTypeId: NB_TEMPLATE_TYPE.MATH_NUMBER_MULTIPLY,
    sampleTypeId: NB_SAMPLE_TYPE.CALC_NUMBERS,
    templateId: 'n4w3ood5n1',
    name: 'Умножение целых чисел',
    task: 'Найти произведение двух целых чисел.',
    isAnySign: false, //значения коээфициентов только +
    coefNumber: 2,
    prompt: '',
    formulas: [
        {
            formula: '$-0@0 $1 @1',
            answerName: 'Произведение',
            coefRanges: [
                {id: NB_COMPLEXITY.LOW,    topRange: [2, 15], bottomRange: [1, 1]}, 
                {id: NB_COMPLEXITY.MEDIUM, topRange: [10, 30], bottomRange: [1, 1]},
                {id: NB_COMPLEXITY.HIGH,   topRange: [15, 60], bottomRange: [1, 1]},
            ],
            coefMultipliers: [1, 1],
        },
    ],
    calcSolutionRules: [ //размер массива равен числу решений уравнения. Зададим решения с помощью коэф-тов a0/b0, a1,b1, a2/b2..
        { action: '*', coeffIndexes: [0, 1]},  //the solution is [a0/b0 * a1/b1]
    ],
};

const number_calc_multiply_02 = {
    templateTypeId: NB_TEMPLATE_TYPE.MATH_NUMBER_MULTIPLY,
    sampleTypeId: NB_SAMPLE_TYPE.CALC_NUMBERS,
    templateId: 'wf8hsb9om0',
    name: 'Умножение десятичных чисел',
    task: 'Найти произведение двух десятичных чисел.',
    isAnySign: false, //значения коээфициентов только +
    coefNumber: 2,
    decimal: true,
    prompt: '',
    formulas: [
        {
            formula: '$-0@0 $1 @1',
            answerName: 'Произведение',
            coefRanges: [
                {id: NB_COMPLEXITY.LOW,    topRange: [1, 100], bottomRange: [10, 10], decimalNumber: 1}, 
                {id: NB_COMPLEXITY.MEDIUM, topRange: [2, 500], bottomRange: [10, 10], decimalNumber: 1},
                {id: NB_COMPLEXITY.HIGH,   topRange: [2, 500], bottomRange: [10, 10], decimalNumber: 2},
            ],
            coefMultipliers: [1, 1],
        },
    ],
    calcSolutionRules: [ //размер массива равен числу решений уравнения. Зададим решения с помощью коэф-тов a0/b0, a1,b1, a2/b2..
        { action: '*', coeffIndexes: [0, 1]},  //the solution is [a0/b0 * a1/b1]
    ],
};

const number_calc_multiply_03 = {
    templateTypeId: NB_TEMPLATE_TYPE.MATH_NUMBER_MULTIPLY,
    sampleTypeId: NB_SAMPLE_TYPE.CALC_NUMBERS,
    templateId: 'fps3n4ay2t',
    name: 'Умножение рациональных чисел',
    task: 'Найти произведение двух рациональных чисел.',
    isAnySign: false, //значения коээфициентов только +
    coefNumber: 2,
    prompt: '',
    formulas: [
        {
            formula: '$-0@0 $1 @1',
            answerName: 'Произведение',
            coefRanges: [
                {id: NB_COMPLEXITY.LOW,    topRange: [4, 15], bottomRange: [2, 5]}, 
                {id: NB_COMPLEXITY.MEDIUM, topRange: [20, 100], bottomRange: [5, 12]},
                {id: NB_COMPLEXITY.HIGH,   topRange: [30, 200], bottomRange: [5, 20]},
            ],
            coefMultipliers: [1, 1],
        },
    ],
    calcSolutionRules: [ //размер массива равен числу решений уравнения. Зададим решения с помощью коэф-тов a0/b0, a1,b1, a2/b2..
        { action: '*', coeffIndexes: [0, 1]},  //the solution is [a0/b0 * a1/b1]
    ],
};

//==============================================================
const number_calc_divide_01 = {
    templateTypeId: NB_TEMPLATE_TYPE.MATH_NUMBER_DIVIDE,
    sampleTypeId: NB_SAMPLE_TYPE.CALC_NUMBERS,
    templateId: 'ezv5c6goux',
    name: 'Деление целых чисел',
    task: 'Найти частное двух целых чисел.',
    isAnySign: false, //значения коээфициентов только +
    coefNumber: 2,
    prompt: '',
    formulas: [
        {
            formula: '$-0@0 $1 @1',
            answerName: 'Частное',
            coefRanges: [
                {id: NB_COMPLEXITY.LOW,    topRange: [2, 25], bottomRange: [1, 1]}, 
                {id: NB_COMPLEXITY.MEDIUM, topRange: [5, 50], bottomRange: [1, 1]},
                {id: NB_COMPLEXITY.HIGH,   topRange: [5, 100], bottomRange: [1, 1]},
            ],
            coefMultipliers: [1, 1],
        },
    ],
    calcSolutionRules: [ //размер массива равен числу решений уравнения. Зададим решения с помощью коэф-тов a0/b0, a1,b1, a2/b2..
        { action: ':', coeffIndexes: [0, 1]},  //the solution is [a0/b0 : a1/b1 => (a0 * b1) / (b0 * a1) ]
    ],
};

const number_calc_divide_02 = {
    templateTypeId: NB_TEMPLATE_TYPE.MATH_NUMBER_DIVIDE,
    sampleTypeId: NB_SAMPLE_TYPE.CALC_NUMBERS,
    templateId: '632n1cs0jv',
    name: 'Деление рациональных чисел',
    task: 'Найти частное двух рациональных чисел.',
    isAnySign: false, //значения коээфициентов только +
    coefNumber: 2,
    prompt: '',
    formulas: [
        {
            formula: '$-0@0 $1 @1',
            answerName: 'Частное',
            coefRanges: [
                {id: NB_COMPLEXITY.LOW,    topRange: [2, 20], bottomRange: [2, 5]}, 
                {id: NB_COMPLEXITY.MEDIUM, topRange: [5, 80], bottomRange: [2, 15]},
                {id: NB_COMPLEXITY.HIGH,   topRange: [5, 160], bottomRange: [2, 25]},
            ],
            coefMultipliers: [1, 1],
        },
    ],
    calcSolutionRules: [ //размер массива равен числу решений уравнения. Зададим решения с помощью коэф-тов a0/b0, a1,b1, a2/b2..
        { action: ':', coeffIndexes: [0, 1]},  //the solution is [a0/b0 : a1/b1 => (a0 * b1) / (b0 * a1) ]
    ],
};

//==============================================================
//(x - a0/b0) * (x - a1/b1) = 0 => 
//b0 * b1 * x^2 - ((b1 * a0) + (b0 * a1)) * x + a0 * a1 = 0
const equation_square_01 = {
    templateTypeId: NB_TEMPLATE_TYPE.MATH_EQUATION_SQUARE,
    sampleTypeId: NB_SAMPLE_TYPE.EQUATION,
    templateId: 'm5rckdh5k789nlknnl',
    name: 'Квадратное уравнение a * x**2 + b * x + c = 0',
    task: 'Найти корни квадратного уравнения.',
    prompt: '',
    formulas: [
            {formula: '$-0@0x0^+2^+ $1 @1x1 $2 @2 = 0'},
            {formula: '$-0@0x0^+2^+ $2 @2 $1 @1x1 = 0'},
            {formula: '$-1@1x1 $0 @0x0^+2^+ $2 @2 = 0'},
            {formula: '$-1@1x1 $2 @2 $0 @0x0^+2^+ = 0'},
            {formula: '$-2@2 $1 @1x1 $0 @0x0^+2^+ = 0'},
            {formula: '$-2@2 $0 @0x0^+2^+ $1 @1x1 = 0'},
        ],
    varNameNumber: 1,
    isAnySign: true, //значения коээфициентов могут быть любого знака
    coefNumber: 2, //кол-во используемых коэффициентов a0/b0, a1/b1
    calcCoeffRules: [  //here each rational number can be presented as a/b, so:
             // [0, 0] is a[0], [0, 1] is a[1], [1, 0] is b[0], [1,1] is b[1]
        { //@0
            action: '*', 
            values: [B0, B1],  //it means b0 * b1
        },

        { //@1
            action: '+',  //it means sum: (b1 * a0) + (b0 * a1)  
            next: [
                {
                    action: '*',  //it means a0 * b1
                    values: [A0, B1],
                    multiply: -1, //it means -1 * a0 * b1
                },
                {
                    action: '*', //it means a1 * b0
                    values: [A1, B0],
                    multiply: -1, //it means -1 * a1 * b0
                }
            ], 
        },

        { //@2
            action: '*',  //it means a0 * a1
            values: [A0, A1],
        },
    ],
    calcSolutionRules: [ //размер массива равен числу решений уравнения. Зададим решения с помощью коэф-тов a0/b0, a1,b1, a2/b2..
        { action: 'equal', coeffIndexes: [0] },  //1st solution is a0 / b0
        { action: 'equal', coeffIndexes: [1] },  //2nd solution is a1 / b1
    ],
    coefRanges: DEFAULT_EQUATION_RANGES,
};

//===================================
//(x - a0/b0) * (x - (a1/b1 + a2/b2)) = 0 =>
//b0 b1 b2 * x**2 - (b0 b2 a1 + b0 b1 a2 + a0 b1 b2) * x + (a0 a1 b2 + a0 a2 b1) = 0 - based on it we can create many equations
//here we use the following:  b0 b1 b2 * x**2 - (b0 b2 a1  + b0 b1 a2 + a0 b1 b2) * x + (a0 a1 b2) = - (a0 a2 b1)
const equation_square_02 = {
    templateTypeId: NB_TEMPLATE_TYPE.MATH_EQUATION_SQUARE,
    sampleTypeId: NB_SAMPLE_TYPE.EQUATION,
    templateId: 'kn2lk3sbsh2b570mmj',
    name: 'Квадратное уравнение a * x**2 + b * x + c = d',
    task: 'Найти корни квадратного уравнения.',
    prompt: '',
    formulas: [
        {formula: '$-0@0x0^+2^+ $1 @1x1 $2 @2 = $-3@+3'},
        {formula: '$-0@0x0^+2^+ $2 @2 $1 @1x1 = $-3@+3'},
        {formula: '$-1@1x1 $0 @0x0^+2^+ $2 @2 = $-3@+3'},
        {formula: '$-1@1x1 $2 @2 $0 @0x0^+2^+ = $-3@+3'},
        {formula: '$-2@2 $1 @1x1 $0 @0x0^+2^+ = $-3@+3'},
        {formula: '$-2@2 $0 @0x0^+2^+ $1 @1x1 = $-3@+3'},

        {formula: '$-3@+3 = $-0@0x0^+2^+ $1 @1x1 $2 @2'},
        {formula: '$-3@+3 = $-0@0x0^+2^+ $2 @2 $1 @1x1'},
        {formula: '$-3@+3 = $-1@1x1 $0 @0x0^+2^+ $2 @2'},
        {formula: '$-3@+3 = $-1@1x1 $2 @2 $0 @0x0^+2^+'},
        {formula: '$-3@+3 = $-2@2 $1 @1x1 $0 @0x0^+2^+'},
        {formula: '$-3@+3 = $-2@2 $0 @0x0^+2^+ $1 @1x1'},
    ],
    varNameNumber: 1,
    isAnySign: true, //значения коээфициентов могут быть любого знака
    coefNumber: 3, //кол-во используемых коэффициентов a0/b0, a1/b1, a2/b2
    calcCoeffRules: [  //here each rational number can be presewnted as a/b, so [0, 0] is a[0], [0, 1] is a[1], [1, 0] is b[0], [1,1] is b[1]
            //b0 * b1 * x^2 - ((b1 * a0) + (b0 * a1)) * x + a0 * a1 = 0
        { //@0 - coef near x2:
            action: '*', 
            values: [B0, B1, B2],  //it means b0 * b1 * b2
        },

        { //@1 - coef near x:
            action: '+',  //it means sum: (b0 * b2 * a1 ) + (b0 * b1 * a2)  + (a0 * b1 * b2)
            next: [
                {
                    action: '*',  //it means b0 * b2 * a1 
                    values: [B0, B2, A1],
                    multiply: -1, //it means: -1 * b0 * b2 * a1 
                },
                {
                    action: '*', //it means b0 * b1 * a2
                    values: [B0, B1, A2],
                    multiply: -1, //it means -1 * b0 * b1 * a2 
                },
                {
                    action: '*',
                    values: [A0, B1, B2],
                    multiply: -1, 
                },
            ], 
        },

        { //@2 - coef N3 (before = ): (a0 a1 b2 + a0 a2 b1)
            action: '*',
            values: [A0, A1, B2],
        },
        { //@3 - coef N4 (after =)
            action: '*',
            values: [A0, A2, B1],
            multiply: -1,
        },
    ],
    calcSolutionRules: [ //размер массива равен числу решений уравнения. Зададим решения с помощью коэф-тов a0/b0, a1,b1, a2/b2..
        { action: 'equal', coeffIndexes: [0]},  //1st solution is a0/b0
        { action: '+', coeffIndexes: [1, 2]},  //2nd solution is sum [a1/b1 + a2/b2]
    ],
    coefRanges: DEFAULT_EQUATION_RANGES,
};

//============================================================================
//x - a0 / b0 = 0 => b0 * x - a0 = 0
const equation_linear_01 = {
    templateTypeId: NB_TEMPLATE_TYPE.MATH_EQUATION_LINEAR,
    sampleTypeId: NB_SAMPLE_TYPE.EQUATION,
    templateId: '4jn4kkebtgl255m6m7',
    name: 'Линейное уравнение a * x + b = 0',
    task: 'Найти корень линейного уравнения.',
    prompt: '',
    formulas: [
        {formula: '$-0@0x0 $1 @1 = 0'},
        {formula: '$-1@1 $0 @0x0 = 0'},
    ],
    varNameNumber: 1,
    isAnySign: true, //значения коээфициентов могут быть любого знака
    coefNumber: 1, //кол-во используемых коэффициентов a0/b0
    calcCoeffRules: [  //here each rational number can be presented as a/b
        { //@0
            action: 'equal', 
            values: [B0],  //it means the 1st coefficient is equal to b0
        },

        { //@1
            action: 'equal',   //it means the 2nd coefficient is equal to a0
            values: [A0],
            multiply: -1, //it means -1 * a0
        },
    ],
    calcSolutionRules: [ //размер массива равен числу решений уравнения. Зададим решения с помощью коэф-тов a0/b0, a1,b1, a2/b2..
        { action: 'equal', coeffIndexes: [0] },  //the only solution is a0 / b0
    ],
    coefRanges: DEFAULT_EQUATION_RANGES,
};

const equation_linear_02 = {
    //x - (a0/b0 + a1/b1) = 0 => b0 * b1 * x - a0 * b1 = a1 * b0
    templateTypeId: NB_TEMPLATE_TYPE.MATH_EQUATION_LINEAR,
    sampleTypeId: NB_SAMPLE_TYPE.EQUATION,
    templateId: 'cdmlkvf77yhhy89i1w',
    name: 'Линейное уравнение  a * x + b = c',
    task: 'Найти корень линейного уравнения.',
    prompt: '',
    formulas: [
        {formula: '$-0@0x0 $1 @1 = $-2@2'},
        {formula: '$-1@1 $0 @0x0 = $-2@2'},

        {formula: '$-2@2 = $-0@0x0 $1 @1'},
        {formula: '$-2@2 = $-1@1 $0 @0x0'},
    ],
    varNameNumber: 1,
    isAnySign: true, //значения коээфициентов могут быть любого знака
    coefNumber: 2, //кол-во используемых коэффициентов a0/b0, a1/b1
    calcCoeffRules: [  //here each rational number can be presewnted as a/b, so: [0, 0] is a[0], [0, 1] is a[1], [1, 0] is b[0], [1,1] is b[1]
        { //@0
            action: '*', 
            values: [B0, B1],  //it means b0 * b1
        },
        { //@1
            action: '*', 
            values: [A0, B1],
            multiply: -1,     //it means: -1 * a0 * b1
        },
        { //@2
            action: '*', 
            values: [B0, A1], //it means    b0 * a1
        },
    ],
    coefRanges: DEFAULT_EQUATION_RANGES,
    calcSolutionRules: [ //размер массива равен числу решений уравнения. Зададим решения с помощью коэф-тов a0/b0, a1,b1, a2/b2..
        {action: '+', coeffIndexes: [0, 1]},  //the only solution is sum [a0/b0 + a1/b1]
    ],
};

const equation_linear_03 = {
    //(a0/b0 + a1/b1) * x - a2/b2 = 0 => a0 b1 b2 x - a2 b0 b1 = - a1 b0 b2 x => x = a2 b0 b1 / (a0 b1 b2 + a1 b0 b2)
    templateTypeId: NB_TEMPLATE_TYPE.MATH_EQUATION_LINEAR,
    sampleTypeId: NB_SAMPLE_TYPE.EQUATION,
    templateId: 'ssscxdd4dedd3e67jk',
    name: 'Линейное уравнение a * x + b = c * x',
    task: 'Найти корень линейного уравнения.',
    prompt: '',
    formulas: [
        {formula: '$-0@0x0 $1 @1 = $-2@2x0'},
        {formula: '$-1@1 $0 @0x0 = $-2@2x0'},

        {formula: '$-2@2x0 = $-0 @0x0 $1 @1'},
        {formula: '$-2@2x0 = $-1 @1 $0 @0x0'},
    ],
    varNameNumber: 1,
    isAnySign: true, //значения коээфициентов могут быть любого знака
    coefNumber: 3, //кол-во используемых коэффициентов a0/b0, a1/b1,...
    calcCoeffRules: [  //here each rational number can be presented as a/b, so [0, 0] is a[0], [0, 1] is a[1], [1, 0] is b[0], [1,1] is b[1]
        { //@0
            action: '*', values: [A0, B1, B2]},
        { //@1
            action: '*', values: [A2, B0, B1], multiply: -1},
        { //@2
            action: '*', values: [A1, B0, B2], multiply: -1 }, //it means: -1 * a1 * b0 * b2
    ],
    coefRanges: DEFAULT_EQUATION_RANGES,
    calcSolutionRules: [ //размер массива равен числу решений уравнения. Зададим решения с помощью коэф-тов a0/b0, a1,b1, a2/b2..
        {
            factorMethod: 'values',  //special case: we use not coeffs a0/b0,... but a0, b0, b1, ...
            factor: {
                topValue: { action: '*', values: [A2, B0, B1]},
                bottomValue: { 
                    action: '+', 
                    next: [
                        {action: '*', values: [A0, B1, B2]},
                        {action: '*', values: [A1, B0, B2],},
                    ],
                },
            }
        },
    ],
};

//==============================================================
const number_grouping_01 = {
    templateTypeId: NB_TEMPLATE_TYPE.MATH_GROUPING,
    sampleTypeId: NB_SAMPLE_TYPE.GROUPING,
    templateId: '8s9shvewco',
    name: 'Группировка с целыми коэффициентами', //Группировка
    task: 'Найти группировку.',
    prompt: '',
    formulas: [
        {formula: '$-0@0x0y0 $1 @1x1 $2 @2y2 $3 @3'},
        {formula: '$-1@1x1 $0 @0x0y0 $2 @2y2 $3 @3'},
        {formula: '$-3@3 $1 @1x1 $0 @0x0y0 $2 @2y2'},
        {formula: '$-3@3 $0 @0x0y0 $1 @1x1 $2 @2y2'},
        {formula: '$-2@2y2 $3 @3 $0 @0x0y0 $1 @1x1'},
    ],
    //получить (a0 / b0 x + a1 /b2) (a2/b2 y + a3/b3) =>
    //задание a0 a2 b1 b3  * x * y + a0 a3 b1 b2 x + a1 a2 b0 b3 y + a1 a3 b0 b2
    //ответ (a0 b1 b2 b3 x + a1 b0 b2 b3) (a2 b0 b1 b3 y + a3 b0 b1 b2)
    varNameNumber: 2,
    isAnySign: true, //значения коээфициентов могут быть любого знака
    coefNumber: 4, //кол-во используемых коэффициентов a0/b0, a1/b1,...
    calcCoeffRules: [  //here each rational number can be presented as a/b, so [0, 0] is a[0], [0, 1] is a[1], [1, 0] is b[0], [1,1] is b[1]
        { //@0
            action: '*', values: [A0, A2, B1, B3]}, //здесь Biможно не испольовать
        { //@1
            action: '*', values: [A0, A3, B1, B2]},
        { //@2
            action: '*', values: [A1, A2, B0, B3]},
        { //@2
            action: '*', values: [A1, A3, B0, B2]},
    ],
    coefRanges: [
        {id: NB_COMPLEXITY.LOW,    topRange: [3, 12], bottomRange: [1, 1]}, 
        {id: NB_COMPLEXITY.MEDIUM, topRange: [6, 20], bottomRange: [1, 1]},
        {id: NB_COMPLEXITY.HIGH,   topRange: [8, 40], bottomRange: [1, 1]},
    ],
    calcSolutionRules: [
        {  //для этого factorMethod у нас специальный случай формирования коэффициентов в решении: (Ax + B) (Cy + D)
            factorMethod: 'grouping', //not used
            solutionFormula: '($-0@0x0 $1 @1) ($-2@2y0 $3 @3)',
            factor: {
                groupingCoeffs: [ 
                    {action: '*', values: [A0, B1, B2, B3]},
                    {action: '*', values: [A1, B0, B2, B3]},
                    {action: '*', values: [A2, B0, B1, B3]},
                    {action: '*', values: [A3, B0, B1, B2]},
                ],
            }
        },
    ],
};

//============================================================================
const text_linear_01 = {
    templateTypeId: NB_TEMPLATE_TYPE.MATH_EQUATION_LINEAR,
    sampleTypeId: NB_SAMPLE_TYPE.TEXT_TASK,
    templateId: 'efgyef58hlewsxdsfh',
    name: 'Задачи на линейное уравнение x = a / b',
    task: 'Решите задачу.',
    prompt: '',
    isAnySign: false, //значения коээфициентов могут быть только положительными
    coefNumber: 2, //кол-во используемых коэффициентов a0/b0
    calcSolutionRules: [ //размер массива равен числу решений уравнения. Зададим решения с помощью коэф-тов a0/b0, a1,b1, a2/b2..
        { //action: 'equal', coeffIndexes: [0] },  //the only solution is a0 / b0
            factorMethod: 'values',  //special case: we use not coeffs a0/b0,... but a0, b0, b1, ...
            factor: {
                topValue: { action: '*', values: [A1, B0]},
                bottomValue: { action: '*', values: [A0, B1]},
            },
        }
    ],
    coefRanges: [
        {id: NB_COMPLEXITY.LOW,    topRange: [8, 25], bottomRange: [1, 1]}, 
        {id: NB_COMPLEXITY.MEDIUM, topRange: [12, 60], bottomRange: [2, 5]},
        {id: NB_COMPLEXITY.HIGH,   topRange: [15, 80], bottomRange: [2, 6]},
    ],
    formulas: [
        {
            formula: 'Велосипедисту нужно проехать @1 км за @0 |час,@0|. С какой скоростью он должен двигаться?', 
            units: 'км/ч',
            answerName: 'Скорость велосипедиста',
            uniqueCoefSets: [   //'3/23|2/3' - это означает 3/2ч и 23/3 км. Ответ будет 23/3 : 3/2 = 23/3 * 2/3 = 46/9 км/ч
                {id: NB_COMPLEXITY.LOW,    set: ['2/24|1/1', '2/18|1/1', '3/24|1/1', '3/33|1/1', '2/22|1/1', '4/36|2/3', '3/27|1/1', '3/36|1/1', '4/32|1/1', '3/45|1/1', '3/45|1/1', '3/18|1/1', ]}, 
                {id: NB_COMPLEXITY.MEDIUM, set: ['6/60|1/2', '5/35|1/2', '5/90|1/1', '6/120|1/2', '7/154|1/1', '6/132|1/1', '5/65|1/1', '3/24|1/2', '6/72|2/1', '1/11|2/1']},
                {id: NB_COMPLEXITY.HIGH,   set: ['5/25|2/1', '3/24|2/3', '12/132|2/1', '9/99|2/1', '17/204|2/1', '7/21|5/1', '1/11|2/1', '3/201|1/3', '1/10|2/1', '4/25|5/1',]},
        ],
        },
        {
            formula: 'Дачник для полива сада с помощью насоса выкачал @1 |кубометр,@1| воды за @0 |час,@0|. '+
                    'С какой скоростью работает насос?', 
            units: 'м^+3^+/ч',
            answerName: 'Скорость работы насоса',
            uniqueCoefSets: [
                {id: NB_COMPLEXITY.LOW,    set: ['2/12|1/1', '2/20|1/1', '3/18|2/1', '3/33|1/1', '2/28|1/1', '3/36|1/1', '3/27|1/1', '9/45|1/1', '3/39|1/1', '3/54|1/1', '2/10|1/1', ]}, 
                {id: NB_COMPLEXITY.MEDIUM, set: ['2/76|1/2', '5/55|2/1', '5/95|1/1', '3/51|2/1', '7/154|1/1', '6/138|1/1', '5/65|1/1', '3/51|1/1', '6/72|1/1', '1/13|2/1']},
                {id: NB_COMPLEXITY.HIGH,   set: ['5/65|2/1', '3/36|2/3', '12/132|2/1', '9/108|2/1', '17/204|2/1', '7/28|6/1', '1/11|2/1', '4/24|5/1', '1/10|2/1', '20/5|2/8',]},
            ],
        },
        {
            formula: 'Мама в магазине купила @0 |килограмм,@0| бананов за @1 |рубль,@1|. Сколько стоит один килограмм бананов?', 
            units: 'р/кг',
            answerName: 'Стоимость килограмма бананов',
            uniqueCoefSets: [
                {id: NB_COMPLEXITY.LOW,    set: ['2/120|1/1', '3/99|1/1', '5/125|1/1', '7/210|1/1', '3/60|1/1', '4/176|1/1', '10/620|1/1', '8/720|1/1', '7/154|1/1', '4/128|1/1', '2/88|1/1', '4/320|1/1', ]}, 
                {id: NB_COMPLEXITY.MEDIUM, set: ['2/76|1/2', '5/75|2/1', '5/95|1/1', '3/64|4/1', '7/154|2/1', '6/132|1/1', '5/185|1/1', '3/39|2/1', '6/72|1/1', '1/27|2/1']},
                {id: NB_COMPLEXITY.HIGH,   set: ['5/65|2/1', '2/36|3/1', '12/132|2/1', '7/126|2/1', '15/165|2/1', '7/28|6/1', '1/120|2/3', '4/24|5/1', '1/22|2/1', '5/126|7/1',]},
            ],
        },
        {
            formula: 'У @0 мальчиков поровну яблок, а всего @1 |яблоко,@1|. Сколько яблок у каждого мальчика? ',
            units: 'яблоко',
            answerName: 'У каждого мальчика по ',
            uniqueCoefSets: [
                {id: NB_COMPLEXITY.LOW,    set: ['5/10|1/1', '6/12|1/1', '6/18|1/1', '5/25|1/1', '11/33|1/1', '14/42|1/1', '9/36|1/1', '7/14|1/1', '9/27|1/1', '7/14|1/1','7/35|1/1', '5/30|1/1', '4/28|1/1', '4/32|1/1', ]},
                {id: NB_COMPLEXITY.MEDIUM, set: ['16/48|1/1', '26/78|1/1', '18/72|1/1', '16/64|1/1', '25/125|1/1', '16/96|1/1', '7/56|1/1', '13/39|1/1', '12/144|1/1', '8/72|1/1']},
                {id: NB_COMPLEXITY.HIGH,   set: ['15/225|1/1', '16/160|1/1', '12/132|1/1', '18/126|1/1', '15/165|1/1', '24/168|1/1', '16/224|1/1', '18/216|1/1', '13/182|1/1', '5/125|1/1',]},
            ],
        },
        {
            formula: 'Автобус проехал @1 |километр,@1| за @0 |час,@0|. Каждый час автобус проезжал одинаковое расстояние. '+
                    'С какой скоростью едет автобус?', 
            units: 'км/ч',
            answerName: 'Скорость автобуса',
            uniqueCoefSets: [
                {id: NB_COMPLEXITY.LOW,    set: ['2/52|1/1', '2/38|1/1', '3/69|1/1', '3/81|1/1', '2/82|1/1', '4/68|1/1', '3/27|1/1', '3/48|1/1', '4/72|1/1', '3/45|1/1',]}, 
                {id: NB_COMPLEXITY.MEDIUM, set: ['2/70|1/1', '4/160|1/1', '3/189|1/1', '4/132|1/1', '4/188|1/1', '2/134|1/1', '5/225|1/1', '7/301|1/1', '4/124|2/1', '1/25|2/1']},
                {id: NB_COMPLEXITY.HIGH,   set: ['5/75|2/1', '2/24|3/1', '3/132|1/1', '9/207|2/1', '13/234|2/1', '7/133|5/1', '1/48|2/1', '3/201|1/3', '1/49|2/1', '4/32|5/1',]},
            ],
        },
        {
            formula: 'Школа заказала учебные парты в количестве @1 |парта,@1|. Парты будут доставлены @0 равными партиями. '+
                    'Сколько парт будет в каждой партии?', 
            units: 'парта',
            answerName: 'Размер партии',
            uniqueCoefSets: [
                {id: NB_COMPLEXITY.LOW,    set: ['3/15|1/1', '2/20|1/1', '4/20|1/1', '3/18|1/1', '4/24|1/1', '2/26|1/1', '3/30|1/1', '4/28|1/1', '3/27|1/1', '4/36|1/1', '4/44|1/1', ]}, 
                {id: NB_COMPLEXITY.MEDIUM, set: ['3/45|1/1', '6/54|1/1', '11/121|1/1', '3/120|1/1', '5/125|1/1', '6/114|1/1', '12/144|1/1', '8/72|1/1', '13/169|1/1', '11/99|1/1']},
                {id: NB_COMPLEXITY.HIGH,   set: ['25/225|1/1', '9/189|1/1', '5/135|1/1', '6/366|1/1', '4/164|1/1', '7/287|1/1', '3/201|1/1', '8/256|1/1', '7/196|1/1', '9/234|1/1',]},
            ],
        },
        {
            formula: 'В кинотеатре всего @1 |кресло,@1|. Все кресла поровну расставлены в @0 |ряд,@0|. '+
                    'Сколько кресел в каждом ряду?', 
            units: 'кресло',
            answerName: 'В каждом ряду',
            uniqueCoefSets: [
                {id: NB_COMPLEXITY.LOW,    set: ['3/15|1/1', '2/22|1/1', '4/24|1/1', '3/21|1/1', '4/28|1/1', '2/26|1/1', '3/33|1/1', '4/32|1/1', '3/39|1/1', '4/48|1/1', '5/70|1/1', ]}, 
                {id: NB_COMPLEXITY.MEDIUM, set: ['3/45|1/1', '6/72|1/1', '11/121|1/1', '3/132|1/1', '5/135|1/1', '6/102|1/1', '12/144|1/1', '8/96|1/1', '13/169|1/1', '11/132|1/1']},
                {id: NB_COMPLEXITY.HIGH,   set: ['25/225|1/1', '9/198|1/1', '15/210|1/1', '14/224|1/1', '8/168|1/1', '7/287|1/1', '12/228|1/1', '8/256|1/1', '21/294|1/1', '9/234|1/1',]},
            ],
        },
        {
            formula: 'Принтер распечатал @1 |лист,@1| за @0 |минута,@0|. '+
                    'Сколько листов печатает принтер за 1 минуту?', 
            units: 'лист/мин',
            answerName: 'Скорость печати принтера',
            uniqueCoefSets: [
                {id: NB_COMPLEXITY.LOW,    set: ['3/24|1/1', '4/16|1/1', '3/27|1/1', '4/36|1/1', '6/54|1/1', '7/49|1/1', '8/32|1/1', '2/16|1/1', '2/42|1/1', '3/45|1/1', '5/65|1/1', ]}, 
                {id: NB_COMPLEXITY.MEDIUM, set: ['3/48|1/1', '15/60|1/1', '12/144|1/1', '13/169|1/1', '8/96|1/1', '5/110|1/1', '8/136|1/1', '9/108|1/1', '28/196|1/1', '3/156|1/1']},
                {id: NB_COMPLEXITY.HIGH,   set: ['9/126|1/1', '18/432|1/1', '13/234|1/1', '19/266|1/1', '16/432|1/1', '18/324|1/1', '22/374|1/1', '1/8|2/1', '1/6|3/1', '16/464|1/1',]},
            ],
        },
        {
            formula: 'В мешке @1 |конфета,@1| конфет. Необходимо разделить конфеты поровну между учениками класса. В классе учится @0 |ученик,@0|. '+
                    'Сколько конфет получит каждый ученик?', 
            units: 'конфета',
            answerName: 'Каждый ученик получит',
            uniqueCoefSets: [ 
                {id: NB_COMPLEXITY.LOW,    set: ['5/25|1/1', '4/36|1/1', '16/48|1/1', '19/38|1/1', '17/34|1/1', '11/55|1/1', '8/64|1/1', '7/63|1/1', '9/81|1/1', '7/49|1/1', '7/77|1/1', '8/96|1/1',  ]}, 
                {id: NB_COMPLEXITY.MEDIUM, set: ['12/144|1/1', '22/132|1/1', '7/126|1/1', '13/169|1/1', '12/96|1/1', '15/225|1/1', '11/121|1/1', '13/117|1/1', '14/126|1/1', '23/115|1/1']},
                {id: NB_COMPLEXITY.HIGH,   set: ['12/156|1/1', '25/400|1/1', '14/266|1/1', '25/425|1/1', '19/342|1/1', '12/204|1/1', '36/252|1/1', '42/294|1/1', '25/450|1/1', '27/216|1/1',]},
            ],
        },
        {
            formula: 'Мотоцикл едет со средней скоростью @0 км/ч. Ему необходимо проехать @1 км. '+
                    'Сколько часов будет ехать мотоцикл?', 
            units: 'час',
            answerName: 'Время в пути ',
            uniqueCoefSets: [
                {id: NB_COMPLEXITY.LOW,    set: ['9/27|1/1', '11/55|1/1', '16/64|1/1', '19/95|1/1', '18/72|1/1', '22/132|1/1', '12/144|1/1', '25/150|1/1', '17/68|1/1', '18/108|1/1', '24/216|1/1', ]}, 
                {id: NB_COMPLEXITY.MEDIUM, set: ['35/245|1/1', '36/216|1/1', '48/192|1/1', '47/235|1/1', '47/376|1/1', '56/392|1/1', '18/216|1/1', '29/174|1/1', '59/472|1/1', '61/305|1/1']},
                {id: NB_COMPLEXITY.HIGH,   set: ['47/94|2/1', '78/273|1/1', '79/316|2/1', '169/507|2/1', '92/552|1/1', '78/468|1/1', '48/576|1/1', '135/540|2/1', '88/704|1/1', '60/780|1/1',]},
            ],
        },
    ],
};

const text_linear_02 = {
    //...equation_linear_03,
    templateId: 'vg72f306hkvfdolew4',
    templateTypeId: NB_TEMPLATE_TYPE.MATH_EQUATION_LINEAR,
    sampleTypeId: NB_SAMPLE_TYPE.TEXT_TASK,
    name: 'Задачи на линейное уравнение a * x + b = c * x',
    task: 'Решите задачу.',
    isAnySign: false, //значения коээфициентов только +
    coefNumber: 3,
    prompt: '',
    formulas: [ 
        {   //t = S / (Vл + Vр)
            formula: 'Найти время движения лодки между пристанями A и B по течению, если расстояние AB равно @2 км, '+
                     'скорость лодки - @0 км/ч, а скорость течения реки - @1 км/ч.', 
            units: 'час',
            answerName: 'Время движения лодки',
            uniqueCoefSets: [
                {id: NB_COMPLEXITY.LOW,    set: ['2/2/28|1/1/1', '1/2/12|1/1/1', '2/1/18|1/1/1', '6/2/16|1/1/1', '2/3/15|1/1/1', '2/3/25|1/1/1', '11/6/17|1/1/1', '4/2/48|1/1/1', '5/1/54|1/1/1', '5/3/80|1/1/1',]}, 
                {id: NB_COMPLEXITY.MEDIUM, set: ['8/3/55|1/1/1', '8/5/78|1/1/1', '7/2/45|1/1/1', '6/2/104|1/1/1', '8/7/120|1/1/1', '13/5/126|1/1/1', '13/3/144|1/1/1', '8/4/168|1/1/1', '7/6/195|1/1/1', '8/2/125|1/1/1',  ]}, 
                {id: NB_COMPLEXITY.HIGH,   set: ['8/7/240|1/1/1', '14/4/306|1/1/1', '18/2/8|1/1/1', '20/4/6|1/1/1', '15/7/33|1/1/1', '18/8/65|1/1/1', '19/5/126|1/1/1', '8/4/44|1/1/1', '29/7/207|1/1/1', '25/13/171|1/1/1',  ]}, 
            ],
            calcSolutionRules: [ //размер массива равен числу решений уравнения. Зададим решения с помощью коэф-тов a0/b0, a1,b1, a2/b2..
                {
                factorMethod: 'values',  //special case: we use not coeffs a0/b0,... but a0, b0, b1, ...
                factor: {
                    topValue: { action: '*', values: [A2, B0, B1]},
                    bottomValue: { 
                        action: '+', 
                        next: [
                            {action: '*', values: [A0, B1, B2]},
                            {action: '*', values: [A1, B0, B2]},
                        ],
                    },
                }
            },
            ],
        },
        {   //t = S / (Vл - Vр)
            formula: 'Найти время движения лодки между пристанями A и B против течения, если расстояние AB равно @2 км, '+
                     'скорость лодки - @0 км/ч, а скорость течения реки - @1 км/ч.', 
            units: 'час',
            answerName: 'Время движения лодки',
            uniqueCoefSets: [ 
                {id: NB_COMPLEXITY.LOW,    set: ['6/2/8|1/1/1', '4/1/9|1/1/1', '5/2/12|1/1/1', '7/3/20|1/1/1', '8/2/36|1/1/1', '13/2/11|1/1/1', '9/5/32|1/1/1', '13/5/72|1/1/1','14/4/50|1/1/1', '14/5/63|1/1/1', ]}, 
                {id: NB_COMPLEXITY.MEDIUM, set: ['8/3/88|1/1/1', '7/5/72|1/1/1', '9/4/130|1/1/1', '7/5/108|1/1/1', '7/6/91|1/1/1', '11/5/176|1/1/1', '37/8/225|1/1/1', '7/2/135|1/1/1', '6/5/143|1/1/1', '13/5/252|1/1/1', ]}, 
                {id: NB_COMPLEXITY.HIGH,   set: ['47/9/95|1/1/1', '41/22/323|1/1/1', '78/13/26|1/1/1', '69/25/11|1/1/1', '53/5/60|1/1/1', '63/18/99|1/1/1', '74/38/192|1/1/1', '52/19/154|1/1/1', '65/47/81|1/1/1', '43/31/216|1/1/1', ]}, 
            ],
            calcSolutionRules: [ //размер массива равен числу решений уравнения. Зададим решения с помощью коэф-тов a0/b0, a1,b1, a2/b2..
                {
                factorMethod: 'values',  //special case: we use not coeffs a0/b0,... but a0, b0, b1, ...
                factor: {
                    topValue: { action: '*', values: [A2, B0, B1]},
                    bottomValue: { 
                        action: '+', 
                        next: [
                            {action: '*', values: [A0, B1, B2]},
                            {action: '*', values: [A1, B0, B2], multiply: -1},
                        ],
                    },
                }
            },
            ],
        },

        {   //1) S = Vрека * Tплот, 2) S = (Vтепл - Vрека) * Tтепл =>  Vрека * Tплот = (Vтеплоход - Vрека ) * Tтеплоход =>
            //Vтеплоход * Tтеплоход = Vрека * Tплот + Vрека * Tтеплоход => 
            //Vтеплоход * Tтеплоход = Vрека (Tплот + Vрека * Tтеплоход) => 
            //Vтеплоход = Vрека (Tплот + Tтеплоход) / Tтеплоход => 
            //Vтепл = a0/b0 * (a1/b1 + a2/b2) * b2/a2  => 
            //Vтепл = a0/b0 (a1/b1 + a2/b2) * b2/a2 => Vтепл = (a0 a2 b1 + a0 a1 b2) / (b0 b1 a2)
            //здесь - a0/b0 - Vрека, a1/b1 - Tплот, a2/b2 - Ттеплоход
            formula: 'Путешественник спустился вниз по течению реки за @1 |час,@1| на плоту, ' + 
                    'а обратно вернулся теплоходом за @2 |час,@2|. ' +
                    'Найдите собственную скорость теплохода, если скорость течения реки равна @0 км/ч.', 
            units: 'км/ч',
            answerName: 'Собственная скорость теплохода',
            uniqueCoefSets: [                   //Vрека=4/Tплот=6/Tтепл=2
                {id: NB_COMPLEXITY.LOW,    set: ['2/6/4|1/1/1', '2/6/3|1/1/1', '2/5/2|1/1/1', '5/3/5|1/1/1', '6/3/6|1/1/1', '8/1/4|1/1/1', '3/8/3|1/1/1', '3/20/6|1/1/1', '8/2/4|1/1/1', '7/5/5|1/1/1',  ]}, 
                {id: NB_COMPLEXITY.MEDIUM, set: ['5/12/6|1/1/1', '6/10/6|1/1/1', '6/11/6|1/1/1', '12/3/4|1/1/1', '4/30/8|1/1/1', '4/38/8|1/1/1', '10/12/8|1/1/1', '14/6/7|1/1/1', '9/10/5|1/1/1', '10/14/7|1/1/1',  ]}, 
                {id: NB_COMPLEXITY.HIGH,   set: ['8/46/16|1/1/1', '18/10/12|1/1/1', '3/58/16|1/1/1', '9/68/18|1/1/1', '14/66/28|1/1/1', '15/114/45|1/1/1', '24/66/48|1/1/1', '15/132/45|1/1/1', '30/62/60|1/1/1', '30/74/60|1/1/1', ]}, 
            ],
            calcSolutionRules: [ //размер массива равен числу решений уравнения. Зададим решения с помощью коэф-тов a0/b0, a1,b1, a2/b2..
            {
                factorMethod: 'values',  //special case: we use not coeffs a0/b0,... but a0, b0, b1, ...
                factor: {
                    topValue: { 
                        action: '+', 
                        next: [
                            {action: '*', values: [A0, A1, B2]},
                            {action: '*', values: [A0, A2, B1],},
                        ],
                    },
                    bottomValue: { action: '*', values: [A2, B0, B1]},
                    }
                },
            ],
        },
    ],
};

export const mathTemplateList = { 
    templates: [
        number_calc_plus_01, number_calc_plus_02, number_calc_plus_03,
        number_calc_minus_01, number_calc_minus_02, number_calc_minus_03,
        number_calc_multiply_01, number_calc_multiply_02, number_calc_multiply_03,
        number_calc_divide_01, number_calc_divide_02,
        number_nod_01, number_nok_01,
        equation_linear_01, equation_linear_02,
        equation_square_01, equation_square_02, equation_linear_03,
        number_grouping_01,
        text_linear_01, text_linear_02,
    ],
    templateTypes: [
        {templateTypeId: NB_TEMPLATE_TYPE.MATH_NUMBER_PLUS, templateTypeName: 'Сложение чисел'},
        {templateTypeId: NB_TEMPLATE_TYPE.MATH_NUMBER_MINUS, templateTypeName: 'Вычитание чисел'},
        {templateTypeId: NB_TEMPLATE_TYPE.MATH_NUMBER_MULTIPLY, templateTypeName: 'Умножение чисел'},
        {templateTypeId: NB_TEMPLATE_TYPE.MATH_NUMBER_DIVIDE, templateTypeName: 'Деление чисел'},
        {templateTypeId: NB_TEMPLATE_TYPE.MATH_NODNOK, templateTypeName: 'НОД и НОК'},
        {templateTypeId: NB_TEMPLATE_TYPE.MATH_EQUATION_LINEAR, templateTypeName: 'Линейные уравнения и задачи'},
        {templateTypeId: NB_TEMPLATE_TYPE.MATH_EQUATION_SQUARE, templateTypeName: 'Квадратные уравнения'},
        {templateTypeId: NB_TEMPLATE_TYPE.MATH_GROUPING, templateTypeName: 'Метод группировки'},
    ]
}; 

//http://mateshanov.ru/generator.php - Матешанов
//http://www.itmathrepetitor.ru/7-klass-lineynye-uravneniya-i-tekstovye/
