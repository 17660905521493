const initialState = {
    settings: {
        x: 'tracking',
        y: 'autofit',
        colors: [
            { val: '#F35151', name: '#F35151' },
            { val: '#EF884E', name: '#EF884E' },
            { val: '#F9DE80', name: '#F9DE80' },
            { val: '#C2D83F', name: '#C2D83F' },
            { val: '#4EE4AE', name: '#4EE4AE' },
            { val: '#81F2F9', name: '#81F2F9' },
            { val: '#81B8F9', name: '#81B8F9' },
            { val: '#8B96FA', name: '#8B96FA' },
            { val: '#E180F9', name: '#E180F9' },
            { val: '#CE5A92', name: '#CE5A92' },
            { val: '#F35151', name: '#F35151' },
            { val: '#EF884E', name: '#EF884E' },
            { val: '#F9DE80', name: '#F9DE80' },
            { val: '#C2D83F', name: '#C2D83F' },
            { val: '#4EE4AE', name: '#4EE4AE' },
            { val: '#81F2F9', name: '#81F2F9' },
            { val: '#81B8F9', name: '#81B8F9' },
            { val: '#8B96FA', name: '#8B96FA' },
            { val: '#E180F9', name: '#E180F9' },
            { val: '#CE5A92', name: '#CE5A92' },
            { val: '#F35151', name: '#F35151' },
            { val: '#EF884E', name: '#EF884E' },
            { val: '#F9DE80', name: '#F9DE80' },
            { val: '#C2D83F', name: '#C2D83F' },
            { val: '#4EE4AE', name: '#4EE4AE' },
            { val: '#81F2F9', name: '#81F2F9' },
            { val: '#81B8F9', name: '#81B8F9' },
            { val: '#8B96FA', name: '#8B96FA' },
            { val: '#E180F9', name: '#E180F9' },
            { val: '#CE5A92', name: '#CE5A92' },
        ],
        line: {
            type: [],
            weight: [],
        },
        points: {
            weight: 0,
            color: 'transparent',
        },
        view: 'four', // one, two, three, four, multy, mono
        modal: false,
        interpolation: 'basis', // linear, basis, bundle, cardinal, catmull, monotonex, monotoney, natural, step, stepafter, stepbefore
    },
    disabled: [],
    style: {},
    mode: 'arbitrary',
    marker: {},
    legend: false,
    parametric: false,
    lissague: false,
    markers: {
        add: false,
        del: false,
        deleteAll: false,
        clearModal: false,
    },
    range: {
        add: false,
        del: false,
    },
    comment: {},
    saveParitalModal: false,
    seriesLength: {},
    commentImageModal: false,
};

const devicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_CHART_X':
            return {
                ...state,
                settings: {
                    ...state.settings,
                    x: action.payload,
                },
            };

        case 'SET_CHART_Y':
            return {
                ...state,
                settings: {
                    ...state.settings,
                    y: action.payload,
                },
            };

        case 'SET_CHART_SETTINGS_MODAL':
            return {
                ...state,
                settings: {
                    ...state.settings,
                    modal: action.payload,
                },
            };

        case 'SET_CHART_COLOR':
            const indexColor = action.payload.id;
            const colors = [...state.settings.colors];
            colors[indexColor] = action.payload.data;
            return {
                ...state,
                settings: {
                    ...state.settings,
                    colors,
                },
            };

        case 'SET_CHART_LINE_TYPE':
            const indexType = action.payload.id;
            const type = [...state.settings.line.type];
            type[indexType] = action.payload.data;
            return {
                ...state,
                settings: {
                    ...state.settings,
                    line: {
                        ...state.settings.line,
                        type,
                    },
                },
            };

        case 'SET_CHART_LINE_WEIGHT':
            const indexWeight = action.payload.id;
            const weight = [...state.settings.line.weight];
            weight[indexWeight] = action.payload.data;
            return {
                ...state,
                settings: {
                    ...state.settings,
                    line: {
                        ...state.settings.line,
                        weight,
                    },
                },
            };

        case 'SET_CHART_VIEW':
            return {
                ...state,
                settings: {
                    ...state.settings,
                    view: action.payload,
                },
            };

        case 'SET_CHART_MODE':
            return {
                ...state,
                mode: action.payload,
            };

        case 'SET_CHART_COMMENT':
            let key = action.payload.num;
            let comment = { ...state.comment };
            comment[key] = action.payload.data;
            return {
                ...state,
                comment,
            };

        case 'SET_CHART_MARKERS':
            let num = action.payload.num;
            let marker = { ...state.marker };
            marker[num] = action.payload.data;
            return {
                ...state,
                marker,
            };

        case 'DEL_CHART_MARKERS':
            let deletet = { ...state.marker };
            delete deletet[action.payload];
            return {
                ...state,
                marker: deletet,
            };

        case 'SET_CHART_STYLE':
            return {
                ...state,
                style: action.payload,
            };

        // case 'UPDATE_CHART_STYLE':
        //     let keyStyle = action.payload.id;
        //     const style = { ...state.style };
        //     style[keyStyle] = action.payload.data;
        //     console.log(state.style);
        //     return {
        //         ...state,
        //         style,
        //     };

        case 'SET_CHART_MARKER_ADD':
            return {
                ...state,
                markers: {
                    ...state.markers,
                    add: action.payload,
                },
            };

        case 'SET_CHART_MARKER_DELETE':
            return {
                ...state,
                markers: {
                    ...state.markers,
                    del: action.payload,
                },
            };

        case 'SET_CHART_MARKER_DELETE_ALL':
            return {
                ...state,
                markers: {
                    ...state.markers,
                    deleteAll: action.payload,
                },
            };

        case 'CLEAR_CHART_MARKER_MOADL':
            return {
                ...state,
                markers: {
                    ...state.markers,
                    clearModal: action.payload,
                },
            };

        case 'SET_CHART_RANGE_ADD':
            return {
                ...state,
                range: {
                    ...state.range,
                    add: action.payload,
                },
            };

        case 'SET_CHART_RANGE_DELETE':
            return {
                ...state,
                range: {
                    ...state.range,
                    del: action.payload,
                },
            };

        case 'ADD_CHART_DISABLED':
            let disabled = [...state.disabled, action.payload];
            return {
                ...state,
                disabled: [...new Set(disabled)],
            };

        case 'DELETE_CHART_DISABLED':
            return {
                ...state,
                disabled: state.disabled.filter((ch) => ch !== action.payload),
            };

        case 'TOGGLE_CHART_LEGEND':
            return {
                ...state,
                legend: action.payload,
            };

        case 'TOGGLE_CHART_PARAMETRIC':
            return {
                ...state,
                parametric: action.payload,
            };

        case 'TOGGLE_CHART_LISSAGUE':
            return {
                ...state,
                lissague: action.payload,
            };

        case 'SAVE_PARITAL_MODAL':
            return {
                ...state,
                saveParitalModal: action.payload,
            };

        case 'SET_SERIES_LENGTH':
            let seriesLength = { ...state.seriesLength };
            seriesLength[action.payload.num] = action.payload.data;
            return {
                ...state,
                seriesLength,
            };

        case 'SET_COMMENT_IMAGE_MODAL':
            return {
                ...state,
                commentImageModal: action.payload,
            };

        default:
            return state;
    }
};

export default devicesReducer;
