const initialState = {
    theme: '',
    played: false,
    paused: false,
    isMobile: false,
    eventLoader: null,
};

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_NTHEME':
            return {
                ...state,
                theme: action.payload,
            };

        case 'SET_NPLAY':
            return {
                ...state,
                played: action.payload,
            };

        case 'SET_NPAUSE':
            return {
                ...state,
                paused: action.payload,
            };

        case 'SET_NMOBILE':
            return {
                ...state,
                isMobile: action.payload,
            };

        case 'SET_NEVENT_LOADER':
            return {
                ...state,
                eventLoader: action.payload,
            };

        default:
            return state;
    }
};

export default appReducer;
