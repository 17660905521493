import React from 'react';
import { Icon } from '../../../ui';

const FooterButton = ({   
        title,
        icon, 
        iconActive, 
        prop, 
        onClick, 
        tooltip = true,
    }) => {
      
    let defaltClassBtn = tooltip ? 'cor_footerNav__btn  cor_footerNav__btn_tooltip' : 'cor_footerNav__btn';
    return (
        <div 
            className={ prop ? defaltClassBtn + ' active' : defaltClassBtn }
            onClick={onClick} 
            >
            {iconActive ? <Icon name={prop ? icon : iconActive}/> : <Icon name={icon}/> }
            { tooltip && <div className="cor_footerNav__tooltip">{title}</div> }
        </div>
    )
}

export default FooterButton;
