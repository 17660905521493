import React, {useState, useEffect} from "react";
import {printOnlyDate} from "../../helpers/text";
import {getCalcModelNameByType} from "../CalcModels/cm_utils";
import Table from "../ui/Table/Table";
import {calcModelInfoApi} from "../../api/api";

const ProfileCalcModelInfoTable = ({profile, history}) => {
  const [calcModelData, setCalcModelData] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const result = await calcModelInfoApi.getAllCalcModelInfosByOwner(profile._id);
			const list = [];
			if (result && result.data.length > 0) {
        const cmis = result.data;

        for (let i = 0; i < cmis.length; i++) {
          const cmi = cmis[i];
          const row = {
            calcModeName: getCalcModelNameByType(cmi.cmType),
            cmType: cmi.cmType,
            expName: cmi.name,
            expDescription: cmi.description,
            date: printOnlyDate(cmi.updatedAt),
            actions: '',
            id: cmi._id,
          };
          list.push(row);
        }
      }

      setCalcModelData(list);
      if (isUpdated) setIsUpdated(false);
    };
    fetchData();
  }, [profile._id, isUpdated]);

  const handleOpenCalcModel = async (optId) => {
    const cmType = calcModelData.find((cm) => cm.id === optId).cmType;
    history.push('/cm/' + cmType + '/' + optId);
  };

  const handleRequestDeleteTest = id => {
    const deleteCmi = async () => {
      await calcModelInfoApi.deleteCalcModelInfoById(id);
      setIsUpdated(true);
    };

    deleteCmi();
  };

  const getTableHeader = () => {
    return [
      {column: 'Название расчетной модели', name: 'calcModeName', style: {width: '25%'}},
      {column: 'Название эксперимента', name: 'expName', style: {width: '20%'}},
      {column: 'Описание эксперимента', name: 'expDescription', style: {width: '30%'}},
      {column: 'Дата', name: 'date', style: {width: '15%'}},
      {column: 'Действия', name: 'actions', style: {width: '10%'}},
      {column: 'id', name: 'id', style: {width: '0%'}},
    ];
  };

  return (
    <div>
      <Table
        table={{
          header: getTableHeader(),
          data: calcModelData,
        }}
        person={{
          handlePerson: handleOpenCalcModel,
          isCellLink: true,
        }}
        sort={{
          hasSorting: true,
          initSortInd: -4,
        }}
        actions={{
          handleDelete: id => handleRequestDeleteTest(id),
        }}
      />
    </div>
  )
}

export default ProfileCalcModelInfoTable;
