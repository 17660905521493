import { showRlabNotification } from "../../actions/actions";

export const mapRlabStateToProps = (state) => {
    return (
        state.cactions.rlabNotification
    );
};

export const mapRlabDispatchToProps = (dispatch) => ({
    showRlabNotification: (obj) => dispatch(showRlabNotification(obj)),
});
