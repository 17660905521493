import React, { useState } from 'react';
import './AddCardUsers.scss';
import { useDispatch } from 'react-redux';
import { updateCard } from '../../../redux/slices/trello';

const AddCardUsers = ({ users, card, addUsersMenuRef, activeTask, isModal }) => {
  const [selectedUsers, setSelectedUsers] = useState(card?.users);
  const [searchInput, setSearchInput] = useState('');

  const dispatch = useDispatch();

  const handleSearch = (event) => {
    setSearchInput(event.target.value);
  };

  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleCheckboxChange = (event, userId) => {
    let updatedSelectedUsers;

    if (event.target.checked) {
      updatedSelectedUsers = [...selectedUsers, userId];
    } else {
      updatedSelectedUsers = selectedUsers.filter((id) => id !== userId);
    }

    if (activeTask) {
      dispatch(
        updateCard(card._id, 'users', updatedSelectedUsers, 'activeTask')
      );
    } else {
      dispatch(updateCard(card._id, 'users', updatedSelectedUsers));
    }
    setSelectedUsers(updatedSelectedUsers);
  };

  return (
    <div className={isModal ? "add_card_users add_card_users__modal" : "add_card_users"} ref={addUsersMenuRef}>
      <header className="add_card_users__header">
        <input
          className="add_card_users__input"
          placeholder="Введите имя"
          onChange={handleSearch}
        />
      </header>
      <div className="add_card_users__body">
        <ul className="add_card_users__list">
          {filteredUsers?.map((user) => (
            <li className="add_card_users__list_item" key={user._id}>
              <label htmlFor={user._id} className="add_card_users__list_label">
                <input
                  type="checkbox"
                  id={user._id}
                  className="add_card_users__list_checkbox"
                  checked={card?.users?.includes(user._id)}
                  onChange={(event) => handleCheckboxChange(event, user._id)}
                />
                <span className="add_card_users__list_title">{`${user.name} ${user.name2}`}</span>
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AddCardUsers;
