import React from 'react';
import "../Content.scss";

const ContentFooter = ({children, className = '', id = ''}) => {
    return (
        <div 
            className={"cor_content__footer " + className}
            {...(id ? {id: id} : {})}
        >
            { children }
        </div>
    )
};
export default ContentFooter;
