// import {  } from '../../actions/';

export const mapStateToProps = (state) => {
    return {
        theme: state.actions.theme,
        played: state.actions.played,
        data: state.sensors.data,
        chartStyle: state.chart.style,
        defaultColors: state.chart.settings.colors,
        measunits: state.sensors.measunits,
    };
};

export const mapDispatchToProps = (dispatch) => ({});
