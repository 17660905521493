import { applyMiddleware, createStore } from 'redux';
import reducers from './reducers';

import thunkMiddleware from 'redux-thunk';

const middleware = applyMiddleware(thunkMiddleware);

const persistedState = {};
const store = createStore(reducers, persistedState, middleware);

export default store;
