import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import StickersButton from '../StickersButton.js/StickersButton';
import { ReactComponent as Pencil } from '../../../assets/icons/card-menu/pencil.svg';
import { ReactComponent as ChangeIcon } from './change-icon.svg';
import './ModalTask.scss';
import { updateCard } from '../../../redux/slices/trello';
import Stickers from '../Sticker/Stickers';
import { PRIORITY_DATA, STICKERS_MODAL_DATA } from '../Sticker/StickersData';
import CardUsersList from '../CardUsersList/CardUsersList';
import { selectusers } from '../../../redux/slices/classsline';
import AddCardUsers from '../AddCardUsers/AddCardUsers';
import Sticker from '../Sticker/Sticker';
import { storageOptions } from '../../../redux/slices/storage';
import { storageApi } from '../../../api/api';
import axios from 'axios';
import { toast } from 'react-toastify';
import { loadFiles } from '../../../redux/slices/lists';
import UploadCoverInput from '../UploadCoverInput/UploadCoverInput';

const ModalTask = (props) => {
  const dispatch = useDispatch();

  const onCloseModal = props.onCloseModal;
  const activeTask = useSelector((state) => state.trelloSlice.activeTask);
  const [usersList] = useSelector(selectusers);
  const USERS_DATA = usersList;

  const [date, setDate] = useState('');
  const [newTitleData, setNewTitleData] = useState(activeTask.name);
  const [newDescriptionData, setNewDescriptionData] = useState(activeTask.desk);
  const [isVisibleChangeName, setIsVisibleChangeName] = useState(false);
  const [isVisibleDescription, setIsVisibleDescription] = useState(false);
  const [isVisibleModalUsers, setIsVisibleModalUsers] = useState(false);
  const [isVisibleModalStickers, setIsVisibleModalStickers] = useState(false);

  const [uploadFile, setUploadFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const {
    lists: { files },
  } = useSelector((state) => state);

  const prefix = props?.match?.params.key ?? '%2F';

  const addUsersMenuRef = useRef(null);
  const btnAddUsersRef = useRef(null);
  const stickersRef = useRef(null);

  let priorityData = [];

  const newDate = new Date(date);

  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const cardUsers = USERS_DATA?.filter((user) =>
    activeTask?.users?.includes(user?._id)
  );

  const slicedUsers = cardUsers.slice(0, 4);

  const updatedPriorityData = [
    ...priorityData,
    PRIORITY_DATA.find((data) => data.priority === activeTask.priority),
  ];

  const printFile = (name) => name.split('/').pop();
  const checkName = (name) => files.some((f) => printFile(f.Key) === name);
  function getName(name) {
    let newName = name;
    const ext = name.lastIndexOf('.');
    let i = 0;
    while (checkName(newName)) {
      i = i + 1;
      newName = name.slice(0, ext) + ' (' + i + ')' + name.slice(ext);
    }
    return newName;
  }

  const upload = async () => {
    const uploadUrl = await storageApi.getUploadUrl(
      prefix + getName(uploadFile.name)
    );
    axios
      .put(uploadUrl, uploadFile, {
        headers: { 'Content-Type': uploadFile.type },
        onUploadProgress: (e) =>
          setUploadProgress(
            Math.min(Math.round((e.loaded * 100) / e.total), 100)
          ),
      })
      .then(() => {
        dispatch(loadFiles(prefix));
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleClickOutsideAddUsers = (event) => {
    if (
      addUsersMenuRef.current &&
      !btnAddUsersRef.current.contains(event.target) &&
      !addUsersMenuRef.current.contains(event.target)
    ) {
      setIsVisibleModalUsers(false);
    }
  };

  const handleClickOutside = (event) => {
    if (
      stickersRef.current &&
      !stickersRef.current.contains(event.target) &&
      !event.target.classList.contains('sticker__title')
    ) {
      setIsVisibleModalStickers(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideAddUsers);
    return () => {
      document.removeEventListener('click', handleClickOutsideAddUsers);
    };
  }, [addUsersMenuRef, btnAddUsersRef]);

  useEffect(() => {
    if (activeTask?.deadline) {
      setDate(new Date(activeTask?.deadline));
    }
  }, [activeTask?.deadline]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    dispatch(loadFiles(prefix));
  }, [dispatch, prefix]);

  useEffect(() => {
    if (uploadFile) {
      upload();
    }
  }, [uploadFile]);

  useEffect(() => {
    const currentUploadFile = files?.find((f) => f?.Key === uploadFile?.name);
    if (currentUploadFile && uploadFile) {
      dispatch(
        updateCard(
          activeTask._id,
          'attachments',
          currentUploadFile?.Url,
          'activeTask'
        )
      );
      setUploadFile(null);
    }
  }, [activeTask._id, files, uploadFile?.name]);

  return (
    <div className="modal_task">
      <header className="modal_task__header">
        <div className="modal_task__title_wrapper">
          {!isVisibleChangeName ? (
            <>
              <h2 className="modal_task__title">{activeTask?.name}</h2>
              <Pencil
                className="modal_task__title_button"
                onClick={() => setIsVisibleChangeName(true)}
              />
            </>
          ) : (
            <input
              className="card__title__input"
              type="text"
              autoFocus
              value={newTitleData}
              onChange={(e) => setNewTitleData(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  dispatch(
                    updateCard(
                      activeTask._id,
                      'name',
                      newTitleData,
                      'activeTask'
                    )
                  );
                  setIsVisibleChangeName(false);
                }
              }}
            />
          )}
        </div>
        <div className="modal_task__desc">
          <p className="modal_task__desc_title">В колонке:</p>
          <p className="modal_task__desc_text">Задачи</p>
        </div>
      </header>
      <div className="modal_task__body">
        <div className="modal_task__body_description">
          <section className="modal_task__body_section">
            <h3 className="modal_task__section_title">Описание</h3>
            {!isVisibleDescription && activeTask?.desk ? (
              <>
                <p className="modal_task__description_title">
                  {activeTask?.desk}
                </p>
                <button
                  className="modal_task__description_button"
                  onClick={() => setIsVisibleDescription(true)}
                >
                  <Pencil className="modal_task__description_button_icon" />
                  <span>Редактировать описание</span>
                </button>
              </>
            ) : (
              <textarea
                className="modal_task__section_input"
                type="text"
                placeholder="Опишите задачу"
                value={newDescriptionData}
                autoFocus
                onChange={(e) => setNewDescriptionData(e.target.value)}
                onSubmit={() => {
                  dispatch(
                    updateCard(
                      activeTask._id,
                      'desk',
                      newDescriptionData,
                      'activeTask'
                    )
                  );
                  setIsVisibleDescription(false);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    dispatch(
                      updateCard(
                        activeTask._id,
                        'desk',
                        newDescriptionData,
                        'activeTask'
                      )
                    );
                    setIsVisibleDescription(false);
                  }
                }}
              />
            )}
          </section>
          <section className="modal_task__body_section">
            <h3 className="modal_task__section_title">Участники</h3>
            <div className="modal_task__section_stickers modal_task__section_users">
              <div
                className="modal_task__section_users_button"
                onClick={() => setIsVisibleModalUsers(!isVisibleModalUsers)}
                ref={btnAddUsersRef}
              >
                {activeTask?.users?.length === 0 ? (
                  <StickersButton title="Добавить участников" />
                ) : (
                  <CardUsersList
                    cardUsers={cardUsers}
                    slicedUsers={slicedUsers}
                    cardUsersLength={5}
                    isModal
                  />
                )}
              </div>

              {isVisibleModalUsers && (
                <AddCardUsers
                  users={USERS_DATA}
                  card={activeTask}
                  activeTask
                  addUsersMenuRef={addUsersMenuRef}
                  isModal
                />
              )}
            </div>
          </section>
          <section className="modal_task__body_section">
            <h3 className="modal_task__section_title">Стикеры</h3>
            <div
              className="modal_task__section_stickers modal_task__section_users"
              style={{ zIndex: 0 }}
              ref={stickersRef}
            >
              {updatedPriorityData?.map((sticker, index) => (
                <Sticker key={index} sticker={sticker} />
              ))}
              <div className="modal_task__section_users_menu">
                <StickersButton
                  title="Стикер"
                  onShowStickers={() =>
                    setIsVisibleModalStickers(!isVisibleModalStickers)
                  }
                />
                {isVisibleModalStickers && (
                  <Stickers
                    stickersData={STICKERS_MODAL_DATA}
                    isModal
                    card={activeTask}
                  />
                )}
              </div>
            </div>
          </section>
          <section className="modal_task__body_section">
            <h3 className="modal_task__section_title">Дедлайн</h3>
            <div className="modal_task__section_date">
              <label className="modal_task__section_date_label">
                {!date
                  ? 'Указать дедлайн'
                  : newDate.toLocaleDateString('ru', dateOptions).slice(0, -3)}
              </label>
              <div className="modal_task__section_date_toggle">
                <div className="modal_task__section_date_toggle_button" />
                <input
                  className="modal_task__section_date_input"
                  type="date"
                  onChange={(e) => {
                    setDate(e.target.value);
                    dispatch(
                      updateCard(
                        activeTask._id,
                        'deadline',
                        e.target.value,
                        'activeTask'
                      )
                    );
                  }}
                />
              </div>
            </div>
          </section>
          <section className="modal_task__body_section">
            <h3 className="modal_task__section_title">Фото обложки</h3>
            {activeTask?.attachments &&
              activeTask?.attachments?.length !== 0 && (
                <img
                  src={activeTask?.attachments[0]}
                  alt=""
                  className="modal_task__img"
                />
              )}
            <StickersButton
              title={
                activeTask?.attachments?.length !== 0
                  ? 'Заменить фото'
                  : 'Добавить фото'
              }
            >
              {/* <input
                style={{ cursor: 'pointer' }}
                id="upload"
                type="file"
                className="modal_task__upload"
                onChange={(e) => {
                  setUploadFile(e.target.files[0]);
                  setUploadProgress(0);
                  e.target.value = '';
                }}
              /> */}

              <UploadCoverInput activeTaskId={activeTask?._id} isActiveTask />

            </StickersButton>
          </section>
        </div>
        <div className="modal_task__body_chat"></div>
      </div>
      <span className="modal_task__close" onClick={() => onCloseModal()} />
    </div>
  );
};

export default ModalTask;
