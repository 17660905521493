import React, {useEffect} from 'react';
import Modal from "../../Modal";
import {FEATURES} from "../../../api/config";
import {useSelector} from "react-redux";
import {storageOrg} from "../../../redux/slices/storage";
import {printOnlyDate} from "../../../helpers/text";

const FeaturesModal = (props) => {
  const documentOrg = useSelector(storageOrg);
  const {modal, toggleModal} = props;

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) toggleModal(false);
    };
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal visible={modal} content={
      <>
        <div className="modal__message">Лицензии школы</div>
        <div className="modal__close" onClick={() => toggleModal(false)}></div>
        <div className="modal__body">
          <div className="scrolledBox">
            <div className="cor_settings cor_settings_hor1_2">
              {Object.entries(FEATURES).filter(([k, v]) => v.name)
              .sort((a, b) => a[1].order - b[1].order)
              .map(([k, v]) =>
                <div key={k} className="versionModal__title">
                  <h2>{v.name}</h2>
                  {documentOrg.features[k] ?
                    <div>Действует до {printOnlyDate(new Date(documentOrg.features[k]))}</div>
                    :<div><b>Не куплено</b></div>}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    } size='md'/>
  )
};
export default FeaturesModal;
