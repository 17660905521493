const initialState = {
    defaultChartSettings: {
        yMin: -100,
        yMax: 100,
        channels: [
            { xMin: 0, xMax: 25, xBias: 0, yBias: 0, yMin: -100, yMax: 100 },
            { xMin: 0, xMax: 25, xBias: 0, yBias: 0, yMin: -100, yMax: 100 },
        ],
    },
    chartSettings: {
        yMin: -100,
        yMax: 100,
        channels: [
            { xMin: 0, xMax: 25, xBias: 0, yBias: 0, yMin: -100, yMax: 100 },
            { xMin: 0, xMax: 25, xBias: 0, yBias: 0, yMin: -100, yMax: 100 },
        ],
    },
    isOscilloscope: false,
    oscilloscopeSettings: {}, // {} 25,25,200,1
};

const oscilloscopeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_OSCILLOSCOPE_CHART_SETTINGS':
            // console.log(action.payload);
            const index = action.payload.id;
            return {
                ...state,
                chartSettings: {
                    ...state.chartSettings,
                    [index]: action.payload.data,
                },
            };

        case 'CHECK_OSCILLOSCOPE':
            return {
                ...state,
                isOscilloscope: action.payload,
            };

        case 'SET_OSCILLOSCOPE_SETTINGS':
            // console.log(action.payload);
            return {
                ...state,
                oscilloscopeSettings: action.payload,
            };

        default:
            return state;
    }
};

export default oscilloscopeReducer;
