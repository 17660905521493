const initialState = {
    data: [],
    closed: {},
};

const streamReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_STREAM':
            return {
                ...state,
                data: action.payload,
            };
        case 'SET_STREAM_CLOSED':
            return {
                ...state,
                closed: action.payload,
            };

        default:
            return state;
    }
};

export default streamReducer;
