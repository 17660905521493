import { setChartMarkerDeleteAll, setChartRanges, setChartMarkers, delChartMarkers } from '../../actions/chart';

export const mapStateToProps = (nstate) => {
    return {
        markers: nstate.nchart.marker,
        sensorsList: nstate.nsensor.list,
        importList: nstate.nsensor.imported,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setChartMarkerDeleteAll: (bool) => dispatch(setChartMarkerDeleteAll(bool)),
    setChartRanges: (bool) => dispatch(setChartRanges(bool)),
    setChartMarkers: (key) => dispatch(setChartMarkers(key)),
    delChartMarkers: (key) => dispatch(delChartMarkers(key)),
});
