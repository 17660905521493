import { setDemoStep, setDemoLearn } from '../../../actions/demo';

export const mapStateToProps = (state) => {
    return {
        demo: state.demo.demo,
        learn: state.demo.learn,
        step: state.demo.step,
        modal: state.demo.modal,
        view: state.chart.settings.view,
        port: state.port.open,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setDemoStep: (step) => dispatch(setDemoStep(step)),
    setDemoLearn: (bool) => dispatch(setDemoLearn(bool)),
});
