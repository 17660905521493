import { showModalDisconnect } from '../../actions/actions';
import { btConnect, btDisconnect } from '../../commands/bluetooth';
import { connectDisconnect } from '../../commands/port';
import { fakeConnectDisconnect } from '../../commands/device';
import { togglePort } from '../../actions/port';
import { setDeviceHistoryModal } from '../../actions/devices';
import { setDemoModal } from '../../actions/demo';

export const btnStateToProps = (state) => {
    return {
        port: state.port.open,
        connectionType: state.port.connectionType,
        isMobile: state.actions.isMobile,
        played: state.actions.played,
        modalDisconnect: state.actions.modalDisconnect,
        devicesCount: state.devices.count,
        loader: state.devices.loader,
        demo: state.demo.demo,
    };
};

export const btnDispatchToProps = (dispatch) => ({
    connectDisconnect: (fn) => dispatch(connectDisconnect(fn)),
    fakeConnectDisconnect: (str) => dispatch(fakeConnectDisconnect(str)),
    btConnect: (fn) => dispatch(btConnect(fn)),
    btDisconnect: (fn) => dispatch(btDisconnect(fn)),
    togglePort: (isOpen) => dispatch(togglePort(isOpen)),
    showModalDisconnect: (bool) => dispatch(showModalDisconnect(bool)),
    setDeviceHistoryModal: (bool) => dispatch(setDeviceHistoryModal(bool)),
    setDemoModal: (bool) => dispatch(setDemoModal(bool)),
});
