import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from './redux';
import { chartDispatchToProps, chartStateToProps } from './reduxChart';
import { valueDispatchToProps, valueStateToProps } from './reduxValue';
import Graduat from './Graduation';
import Value from './CurrentValue';
import GradChart from './GraduateChart';

const Graduation = connect(mapStateToProps, mapDispatchToProps)(Graduat);
const GraduateChart = connect(chartStateToProps, chartDispatchToProps)(GradChart);
const CurrentValue = connect(valueStateToProps, valueDispatchToProps)(Value);

export { Graduation, GraduateChart, CurrentValue };
