import React, { useEffect, useState } from 'react';
import Icon from '../../Icon';
import { Dropdown, Button } from 'rlabui';
import ss from './SensorsBundles.module.scss';

const SensorsBundles = ({ sensorList, bundles, addSensorsBundles, setSensorsSorting }) => {
    const [sort, setSort] = useState([
        { label: 'Все датчики и связки', selected: true, value: 'all' },
        { label: 'Только датчики', selected: false, value: 'sensors' },
        { label: 'Только связки', selected: false, value: 'bundles' },
    ]);
    const [list, setList] = useState([]);

    useEffect(() => {
        if (sensorList.length) {
            updateList();
        }
    }, [sensorList.length]);

    useEffect(() => {
        if (!list.length) {
            updateList();
        }
    }, [list]);

    const setNewBundles = () => {
        const keys = Object.keys(bundles).map((e) => +e.replace('bundle', '').replace('i', ''));
        const max = Math.max(...keys);
        const count = keys.length ? max + 1 : 1;
        const key = 'bundle' + count;
        const filtered = list.filter((e) => e.selected);
        const newBundle = [];

        sensorList.forEach((e) => {
            const check = filtered.find((f) => f.num === e.sensNum && f.deviceKey === e.deviceKey);
            if (check) {
                newBundle.push(e);
            }
        });
        // console.log(count, newBundle);
        newBundle.length && addSensorsBundles({ key, data: { name: 'Канал ' + count, key, list: newBundle } });
        setList([]);
    };

    const updateList = () => {
        const sens = sensorList.map((e) => {
            const { name, sensNum, deviceKey } = e;
            const checkSelected = list.find((k) => k.name === name)?.selected;
            return { name, num: sensNum, deviceKey, selected: checkSelected ? true : false };
        });
        sens?.length && setList(sens);
    };

    return (
        // devices <= 1 && (
        <div className={ss.root}>
            <div
                className={ss.item}
                data-learn-border="34"
            >
                <Dropdown
                    value={<Icon name="chain" />}
                    dropPosition="right"
                    onClick={() => null}
                    clicked={true}
                    checkbox={true}
                    className={ss.list}
                    title="Связать датчики"
                    multy={true}
                >
                    {list.map(({ name, selected, num, deviceKey }, i) => (
                        <Dropdown.Item
                            key={deviceKey + num}
                            className={selected && 'selected'}
                            onClick={() => {
                                const copy = [...list];
                                copy[i].selected = !list[i].selected;
                                setList(copy);
                            }}
                        >
                            {name}
                        </Dropdown.Item>
                    ))}
                    {/* <Dropdown.Item className={ss.btn}> */}
                    <Button onClick={setNewBundles}>Создать связку</Button>
                    {/* </Dropdown.Item> */}
                </Dropdown>
            </div>
            <div
                className={ss.item}
                data-learn-border="35"
            >
                <Dropdown
                    value={<Icon name="sort" />}
                    dropPosition="right"
                    onClick={() => null}
                    clicked={true}
                    checkbox={true}
                    className={ss.sort}
                    title="Фильтр отображения"
                >
                    {sort.map(({ label, selected, value }, i) => (
                        <Dropdown.Item
                            key={i}
                            className={selected && 'selected'}
                            onClick={() => {
                                const copy = [...sort];
                                copy.forEach((e, k) => {
                                    copy[k].selected = k === i ? true : false;
                                });
                                setSort(copy);
                                setSensorsSorting(value);
                            }}
                        >
                            {label}
                        </Dropdown.Item>
                    ))}
                </Dropdown>
            </div>
        </div>
        // )
    );
};
export default SensorsBundles;
