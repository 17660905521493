import { setChartComment, setCommentImageModal } from '../../actions/chart';

export const mapStateToProps = (state) => {
    return {
        comment: state.chart.comment,
        seriesLength: state.chart.seriesLength,
        played: state.actions.played,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setChartComment: (obj) => dispatch(setChartComment(obj)),
    setCommentImageModal: (bool) => dispatch(setCommentImageModal(bool)),
});
