import React from 'react';
import ss from '../SensorView.module.scss';
import  Icon  from '../../../Icon';
import { logo, list, info, charging, mode, computer, logging } from '../parts';

const SensorViewPhysics = (props) => {
  
    return (
        <div className={ss.root__wrap}>
            <div className={ss.root}>

                <div className={`${ss.line} ${ss.line4}`}>
                    <div className={`${ss.line__item} ${ss.line__item_leftBottom}`}>
                        <div className={ss.line__title}>АМПЕРМЕТР</div>
                    </div>
                    <div className={`${ss.line__item} ${ss.line__item_left}`}>
                        <div className={ss.line__title}>ВОЛЬТМЕТР</div>
                    </div>
                    <div className={`${ss.line__item} ${ss.line__item_btl}`}></div>
                    <div className={`${ss.line__item}`}>
                        <div className={ss.line__title}>ТЕМПЕРАТУРА</div>
                    </div>
                    <div className={`${ss.line__item}`}>
                        <div className={ss.line__title}>АБСЛЮТНОЕ<br></br>ДАВЛЕНИЕ</div>
                    </div>
                    <div className={`${ss.line__item} ${ss.line__item_btr}`}></div>
                </div>

                {logo()}

                <div className={ss.root__title}>Мультидатчик<br></br> по физике</div>

                {list()}

                {info()}

                {charging()}

                {mode()}

                {computer()}

                <div className={`${ss.icon} ${ss.rightTop}`}>
                    <div className={ss.icon__title}>УСКОРЕНИЕ/<br></br>ТЕСЛАМЕТР</div>
                    <div className={ss.icon__wrap}>
                        <div className={ss.icon__list}>
                            <div className={ss.icon__view}>
                                <p>A</p>
                            </div>
                            <div className={ss.icon__view}>
                                <p>B</p>
                            </div>
                            <div className={ss.icon__view}>
                                <Icon name="temperature" />
                            </div>
                            <div className={ss.icon__view}>
                                <Icon name="analog" />
                            </div>
                            <div className={ss.icon__view}>
                                <Icon name="a-pressure" />
                            </div>
                        </div>
                        <div className={ss.icon__label}>Датчики</div>
                    </div>
                </div>

                {logging()}


            </div>
            
        </div>
    )
};
export default SensorViewPhysics;

