import React from 'react';
import Icon from '../../Icon';
import ss from './MobileAreaSwicher.module.scss';

const MobileAreaSwicher = (props) => {
    const { port, mobileView, setMobileView } = props;
    if (!port) {
        return false;
    }
    return (
        <div className={ss.root}>
            <div
                className={mobileView === 'chart' ? ss.item + ' active' : ss.item}
                onClick={() => setMobileView('chart')}
            >
                <Icon name="chart-mob" />
                Графики
            </div>
            <div className={ss.sep}>
                <Icon name="change" />
            </div>
            <div
                className={mobileView === 'table' ? ss.item + ' active' : ss.item}
                onClick={() => setMobileView('table')}
            >
                <Icon name="table-mob" />
                Таблицы
            </div>
        </div>
    );
};
export default MobileAreaSwicher;
