export const UNIT_DETAIL_LIST = [
    {quantity: 'Время', unit: 'с', system: 'СИ', rel: '', desc: 'Физическая величина, одна из семи основных величин Международной системы величин.'},
    {quantity: 'Дата и время', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Звёздное время', unit: '', system: 'СИ', rel: '', desc: 'Звёздное время — часовой угол точки весеннего равноденствия. Звёздное время используется астрономами, чтобы определить, куда надо направить телескоп, чтобы увидеть нужный объект.'},
    {quantity: 'Расстояние', unit: 'мм', system: 'СИ', rel: '1 мм = 0.001 м', desc: 'Расстояние — степень (мера) удалённости объектов друг от друга.'},
    {quantity: 'Расстояние', unit: 'м', system: 'СИ', rel: '', desc: 'Расстояние — степень (мера) удалённости объектов друг от друга.'},
    {quantity: 'Расстояние', unit: 'см', system: 'СИ', rel: '1 см = 0.01 м', desc: 'Расстояние — степень (мера) удалённости объектов друг от друга.'},
    {quantity: 'Угловая скорость', unit: 'об/с', system: 'СИ', rel: '', desc: 'Угловая скорость — физическая величина, характеризующая быстроту и направление вращения материальной точки или абсолютно твёрдого тела относительно оси вращения.'},
    {quantity: 'Угловая скорость', unit: '°/с', system: 'СИ', rel: '', desc: 'Угловая скорость — физическая величина, характеризующая быстроту и направление вращения материальной точки или абсолютно твёрдого тела относительно оси вращения.'},
    {quantity: 'Угловая скорость', unit: 'рад/с', system: 'СИ', rel: '', desc: 'Угловая скорость — физическая величина, характеризующая быстроту и направление вращения материальной точки или абсолютно твёрдого тела относительно оси вращения.'},
    {quantity: 'Угол поворота', unit: '°', system: 'СИ', rel: '', desc: 'Угол поворота – координата точки при вращательном движении'},
    {quantity: 'Вращение', unit: '°', system: 'СИ', rel: '', desc: 'При вращательном движении материальная точка описывает окружность. '},
    {quantity: 'Угол вращения', unit: '°', system: 'СИ', rel: '', desc: 'Угoл вpaщeния – мepa удaлeннocти oбъeктa.'},
    {quantity: 'Скорость вращения', unit: 'об/с', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Скорость вращения', unit: '°/с', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Скорость вращения', unit: 'рад/с', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Просвет', unit: '', system: 'СИ', rel: '', desc: 'Абсолютное минимальное расстояние в свету между любым препятствием и осевой линией, измеренное под углом 90° к осевой линии.'},
    {quantity: 'Движение', unit: '', system: 'СИ', rel: '', desc: 'Движением называют изменение пространственного положения тела или его частей относительно других тел с течением времени.'},
    {quantity: 'Сила', unit: 'Н', system: 'СИ', rel: '', desc: 'Сила — физическая векторная величина, являющаяся мерой воздействия на данное тело со стороны других тел или внешнего поля.'},
    {quantity: 'Масса', unit: 'г', system: 'СИ', rel: '', desc: 'Скалярная физическая величина, определяющая инертные и гравитационные свойства тел в ситуациях, когда их скорость намного меньше скорости света. В обыденной жизни и в физике XIX века масса синонимична весу.'},
    {quantity: 'Ускорение', unit: 'м/с²', system: 'СИ', rel: '', desc: 'Векторная физическая величина, характеризующая быстроту изменения скорости.'},
    {quantity: 'Положение', unit: '°', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Ускорение, угловая скорость', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Сила, ускорение', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Сила, ускорение, угловая скорость', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Температура', unit: '°C', system: 'СИ', rel: '', desc: 'Физическая величина, которая характеризует степень нагретости тела, среды или вещества. '},
    {quantity: 'Давление газа', unit: 'кПа', system: 'СИ', rel: '', desc: 'Сила, с которой давит газ, стремясь к расширению под действием теплового движения его молекул.'},
    {quantity: 'Давление', unit: 'кПа', system: 'СИ', rel: '', desc: 'Физическая величина, численно равная силе, действующей на единицу площади поверхности, перпендикулярно к этой поверхности. '},
    {quantity: 'Разность давлений', unit: 'кПа', system: 'СИ', rel: '', desc: 'Величина изменения давления.'},
    {quantity: 'Напряжение', unit: 'В', system: 'СИ', rel: '', desc: 'Физическая величина, равная отношению работы по перемещению заряда, выполненной электрическим полем, к величине заряда. '},
    {quantity: 'Напряжение', unit: 'мВ', system: 'СИ', rel: '1 мВ = 0.001', desc: 'Физическая величина, равная отношению работы по перемещению заряда, выполненной электрическим полем, к величине заряда.'},
    {quantity: 'Сигнал', unit: 'В', system: 'СИ', rel: '', desc: 'Сигнал — это изменяющаяся во времени физическая величина, описываемая функцией времени.'},
    {quantity: 'Ток', unit: 'А', system: 'СИ', rel: '', desc: 'Физическая величина, которая показывает, какой заряд прошел через проводник за единицу времени. '},
    {quantity: 'Ток', unit: 'мА', system: 'СИ', rel: '1 мА = 0.001 А', desc: 'Физическая величина, которая показывает, какой заряд прошел через проводник за единицу времени.'},
    {quantity: 'Мощность тока', unit: 'Вт', system: 'СИ', rel: '', desc: 'Физическая величина, характеризующая скорость передачи или преобразования электрической энергии.'},
    {quantity: 'Мощность тока', unit: 'кВт', system: 'СИ', rel: '1 кВт = 1000 Вт', desc: 'Физическая величина, характеризующая скорость передачи или преобразования электрической энергии.'},
    {quantity: 'Ток', unit: 'мкА', system: 'СИ', rel: '1 мкА = 0.000001', desc: 'Физическая величина, которая показывает, какой заряд прошел через проводник за единицу времени.'},
    {quantity: 'Сопротивление', unit: 'Ом', system: 'СИ', rel: '', desc: 'Физическая величина, характеризующая свойство проводника препятствовать прохождению электрического тока.'},
    {quantity: 'Сопротивление', unit: 'кОм', system: 'СИ', rel: '1 кОм = 1000 Ом', desc: 'Физическая величина, характеризующая свойство проводника препятствовать прохождению электрического тока.'},
    {quantity: 'Магнитное поле', unit: 'Гс', system: 'СИ', rel: '1 Гс = 0.0001 Тл', desc: 'Магнитное поле — поле, действующее на движущиеся электрические заряды и на тела, обладающие магнитным моментом, независимо от состояния их движения.'},
    {quantity: 'Магнитное поле', unit: 'мТл', system: 'СИ', rel: '1 мТл = 0.001 Тл', desc: 'Магнитное поле — поле, действующее на движущиеся электрические заряды и на тела, обладающие магнитным моментом, независимо от состояния их движения.'},
    {quantity: 'Электромагнитное излучение', unit: 'Вт/м²', system: 'СИ', rel: '', desc: 'Распространяющееся в пространстве возмущение (изменение состояния) электромагнитного поля.'},
    {quantity: 'Электромагнитное излучение', unit: 'мкВт/см²', system: 'СИ', rel: '1 мкВт/см² = 0.01 Вт/м²', desc: 'Распространяющееся в пространстве возмущение (изменение состояния) электромагнитного поля.'},
    {quantity: 'Электромагнитное излучение', unit: 'кВт/м²', system: 'СИ', rel: '1 кВт/м² = 1000 Вт/м²', desc: 'Распространяющееся в пространстве возмущение (изменение состояния) электромагнитного поля.'},
    {quantity: 'Прохождение', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Заряд', unit: 'нКл', system: 'СИ', rel: '', desc: 'Электрический заряд — физическая скалярная величина, показывающая способность тел быть источником электромагнитных полей и принимать участие в электромагнитном взаимодействии.'},
    {quantity: 'Ионизирующее излучение', unit: 'мкЗв/час', system: 'СИ', rel: '', desc: 'Ионизирующее излучение — это поток частиц или квантов электромагнитного поля, взаимодействие которого с веществом приводит к ионизации его атомов и молекул.'},
    {quantity: 'Активность источника', unit: 'Бк', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Ионизирующее излучение', unit: 'мкР/час', system: 'СИ', rel: '1 мкР/час = 0.0087 мкЗв/час', desc: 'Ионизирующее излучение — это поток частиц или квантов электромагнитного поля, взаимодействие которого с веществом приводит к ионизации его атомов и молекул.'},
    {quantity: 'Звуковой сигнал', unit: 'мВ', system: 'СИ', rel: '', desc: 'Сигнал, передаваемый от аудиоусилителя к громкоговорителю.'},
    {quantity: 'Громкость звука', unit: 'Дб', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Уровень звука', unit: 'Дб', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Уровень шума', unit: 'Дб', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Частота звука', unit: 'Гц', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Звуковой сигнал', unit: 'мВ', system: 'СИ', rel: '1 мВ = 0.001 В', desc: 'Сигнал, передаваемый от аудиоусилителя к громкоговорителю.'},
    {quantity: 'Звук', unit: '', system: 'СИ', rel: '', desc: 'Физическое явление, представляющее собой распространение упругих волн в газообразной, жидкой или твёрдой среде.'},
    {quantity: 'Амплитуда звука', unit: 'мВ', system: 'СИ', rel: '', desc: 'Амплитуда звука – это максимальное отклонение звуковой волны от ее среднего значения. '},
    {quantity: 'Звуковое давление', unit: 'Па', system: 'СИ', rel: '', desc: 'Звуковое давление — это переменное избыточное давление, которое возникает в упругой среде при прохождении через неё звуковой волны.'},
    {quantity: 'Звуковой сигнал', unit: '', system: 'СИ', rel: '', desc: 'Сигнал, передаваемый от аудиоусилителя к громкоговорителю.'},
    {quantity: 'Спектр', unit: '', system: 'СИ', rel: '', desc: 'Полное распределение значений физической величины'},
    {quantity: 'Электропроводность', unit: 'мСм/см', system: 'СИ', rel: '1 мСм/см = 0.1 См/м', desc: 'Физическая величина, которая описывает насколько хорошо определенный материал проводит электричество.'},
    {quantity: 'Электропроводность', unit: 'мкСм/см', system: 'СИ', rel: '1 мкСм/см = 0.0001 См/м', desc: 'Физическая величина, которая описывает насколько хорошо определенный материал проводит электричество.'},
    {quantity: 'Кислотность', unit: 'pH', system: 'СИ', rel: '', desc: 'Кислотность ― это концентрация ионов водорода, выраженная в единицах (от 1 до 14) по шкале pH.'},
    {quantity: 'Концентрация ионов', unit: 'моль/л', system: 'СИ', rel: '', desc: 'Отношение числа ионов к объёму, в котором они находятся.'},
    {quantity: 'Концентрация нитрат-ионов', unit: 'моль/л', system: 'СИ', rel: '', desc: 'Отношение числа ионов к объёму, в котором они находятся.'},
    {quantity: 'Концентрация ионов хлора', unit: 'моль/л', system: 'СИ', rel: '', desc: 'Отношение числа ионов к объёму, в котором они находятся.'},
    {quantity: 'Концентрация сульфат-ионов', unit: 'моль/л', system: 'СИ', rel: '', desc: 'Отношение числа ионов к объёму, в котором они находятся.'},
    {quantity: 'Концентрация ионов аммония', unit: 'моль/л', system: 'СИ', rel: '', desc: 'Отношение числа ионов к объёму, в котором они находятся.'},
    {quantity: 'Концентрация ионов натрия', unit: 'моль/л', system: 'СИ', rel: '', desc: 'Отношение числа ионов к объёму, в котором они находятся.'},
    {quantity: 'Концентрация ионов калия', unit: 'моль/л', system: 'СИ', rel: '', desc: 'Отношение числа ионов к объёму, в котором они находятся.'},
    {quantity: 'Концентрация ионов кальция', unit: 'моль/л', system: 'СИ', rel: '', desc: 'Отношение числа ионов к объёму, в котором они находятся.'},
    {quantity: 'Концентрация ионов магния', unit: 'моль/л', system: 'СИ', rel: '', desc: 'Отношение числа ионов к объёму, в котором они находятся.'},
    {quantity: 'Концентрация ионов', unit: '', system: 'СИ', rel: '', desc: 'Отношение числа ионов к объёму, в котором они находятся.'},
    {quantity: 'Электрохимический потенциал', unit: 'В', system: 'СИ', rel: '', desc: 'Химический потенциал электрически заряженных частиц и квазичастиц (ионы, электроны, дырки) в электрическом поле.'},
    {quantity: 'Электрохимический потенциал', unit: 'мВ', system: 'СИ', rel: '1 мВ = 0.001', desc: 'Химический потенциал электрически заряженных частиц и квазичастиц (ионы, электроны, дырки) в электрическом поле.'},
    {quantity: 'ОВП', unit: 'мВ', system: 'СИ', rel: '', desc: 'Мера способности химического вещества присоединять электроны'},
    {quantity: 'Объём реагента', unit: 'мл', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Объём реагента', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Оптическая плотность', unit: 'D', system: 'СИ', rel: '', desc: 'Мера ослабления света прозрачными объектами или отражения света непрозрачными объектами.'},
    {quantity: 'Уровень сигнала', unit: 'мВ', system: 'СИ', rel: '1 мВ = 0.001 В', desc: 'Количественная характеристика сигнала, рассматриваемая относительно выбранного опорного значения.'},
    {quantity: 'Уровень сигнала', unit: '', system: 'СИ', rel: '', desc: 'Количественная характеристика сигнала, рассматриваемая относительно выбранного опорного значения.'},
    {quantity: 'Пропускание среды', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Мутность', unit: 'ЕМФ', system: 'СИ', rel: '1 ЕМФ = 1 ЕМ/литр = 1 FTU = 1 NTU', desc: 'Помутнение жидкости, вызванное большим количеством отдельных частиц.'},
    {quantity: 'Мутность', unit: 'NTU', system: 'СИ', rel: '1 ЕМФ = 1 ЕМ/литр = 1 FTU = 1 NTU', desc: 'Помутнение жидкости, вызванное большим количеством отдельных частиц.'},
    {quantity: 'Мутность', unit: 'ЕМ/литр', system: 'СИ', rel: '1 ЕМФ = 1 ЕМ/литр = 1 FTU = 1 NTU', desc: 'Помутнение жидкости, вызванное большим количеством отдельных частиц.'},
    {quantity: 'Мутность', unit: 'FTU', system: 'СИ', rel: '1 ЕМФ = 1 ЕМ/литр = 1 FTU = 1 NTU', desc: 'Помутнение жидкости, вызванное большим количеством отдельных частиц.'},
    {quantity: 'Уровень сигнала', unit: 'В', system: 'СИ', rel: '', desc: 'Количественная характеристика сигнала, рассматриваемая относительно выбранного опорного значения.'},
    {quantity: 'Коэффициент пропускания', unit: '', system: 'СИ', rel: '', desc: 'Безразмерная физическая величина, равная отношению потока излучения, прошедшего через среду, к потоку излучения, упавшего на её поверхность.'},
    {quantity: 'Коэффициент пропускания', unit: '%', system: 'СИ', rel: '', desc: 'Безразмерная физическая величина, равная отношению потока излучения, прошедшего через среду, к потоку излучения, упавшего на её поверхность.'},
    {quantity: 'Температура окр. среды', unit: '°C', system: 'СИ', rel: '', desc: 'Один из термодинамических параметров состояния атмосферы.'},
    {quantity: 'Температура иссл. среды', unit: '°C', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Температура поверхности', unit: '°C', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Освещённость', unit: 'лк', system: 'СИ', rel: '', desc: 'Физическая величина, численно равная световому потоку, падающему на единицу площади освещаемой поверхности.'},
    {quantity: 'Свет', unit: '', system: 'СИ', rel: '', desc: 'Электромагнитное излучение, воспринимаемое человеческим глазом. '},
    {quantity: 'Свет', unit: '%', system: 'СИ', rel: '', desc: 'Электромагнитное излучение, воспринимаемое человеческим глазом.'},
    {quantity: '', unit: 'мкмоль/м²·с', system: 'СИ', rel: '', desc: ''},
    {quantity: '', unit: 'Вт/м²', system: 'СИ', rel: '', desc: ''},
    {quantity: 'УФ-излучение', unit: 'мВт/см²', system: 'СИ', rel: '1 мВт/см² = 10 Вт/м²', desc: 'Электромагнитное излучение, занимающее спектральный диапазон между видимым и рентгеновским излучениями.'},
    {quantity: 'УФ-излучение', unit: 'мВт/м²', system: 'СИ', rel: '1 мВт/м² = 0.001 Вт/м²', desc: 'Электромагнитное излучение, занимающее спектральный диапазон между видимым и рентгеновским излучениями.'},
    {quantity: 'УФ-излучение спектра A', unit: 'мВт/м²', system: 'СИ', rel: '1 мВт/м² = 0.001 Вт/м²', desc: 'Электромагнитное излучение, занимающее спектральный диапазон между видимым и рентгеновским излучениями.'},
    {quantity: 'УФ-излучение спектра B', unit: 'мВт/м²', system: 'СИ', rel: '1 мВт/м² = 0.001 Вт/м²', desc: 'Электромагнитное излучение, занимающее спектральный диапазон между видимым и рентгеновским излучениями.'},
    {quantity: 'ИК-излучение', unit: '', system: 'СИ', rel: '', desc: 'Инфракрасное излучение — это электромагнитное излучение с длинами волн от около 0,74 мкм до около 1–2 мм.'},
    {quantity: 'Концентрация O2', unit: '%', system: 'СИ', rel: '', desc: 'Концентрация кислорода в воздухе.'},
    {quantity: 'Концентрация CO2', unit: 'ppm', system: 'СИ', rel: '', desc: 'Концентрация углекислого газа в воздухе.'},
    {quantity: 'Концентрация CO', unit: 'ppm', system: 'СИ', rel: '', desc: 'Концентрация угарного газа в воздухе.'},
    {quantity: 'Растворённый кислород', unit: 'мг/л', system: 'СИ', rel: '', desc: 'Растворенный кислород относится к количеству свободного кислорода, растворенного в воде.'},
    {quantity: 'Солёность', unit: '‰', system: 'СИ', rel: '', desc: 'Солёность — количество солей, растворённых в литре воды.'},
    {quantity: 'Солёность', unit: '%', system: 'СИ', rel: '1 % = 10 ‰', desc: 'Солёность — количество солей, растворённых в литре воды.'},
    {quantity: 'Солёность', unit: 'мг/л', system: 'СИ', rel: '1 мг/л = 2.1 ‰', desc: 'Солёность — количество солей, растворённых в литре воды.'},
    {quantity: 'Концентрация пыли', unit: 'мкг/м³', system: 'СИ', rel: '', desc: 'Концентрацией пыли в газах называется весовое содержание твердых частиц в одном кубическом метре газов.'},
    {quantity: 'Влажность почвы', unit: '%', system: 'СИ', rel: '', desc: 'Содержание воды в почве.'},
    {quantity: 'Температура почвы', unit: '°C', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Азимут', unit: '°', system: 'СИ', rel: '', desc: 'Угол, измеряемый на горизонтальной плоскости между вертикальными плоскостями меридиана и линии визирования. Азимут имеет счет от северного конца меридиана вправо, по ходу часовой стрелки, и измеряется от 0 до 360°. '},
    {quantity: 'Координаты', unit: '°', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Температура воздуха', unit: '°C', system: 'СИ', rel: '', desc: 'Физическая величина, отражающая степень нагревания воздуха.'},
    {quantity: 'Относительная влажность', unit: '%', system: 'СИ', rel: '', desc: 'Относительная влажность воздуха — физическая величина, равная отношению абсолютной влажности к плотности насыщенного водяного пара при данной температуре.'},
    {quantity: 'Атмосферное давление', unit: 'мм рт.ст.', system: 'СИ', rel: '1 мм рт.ст. = 133.3 Па', desc: 'Давление атмосферы, действующее на все находящиеся в ней предметы и на земную поверхность.'},
    {quantity: 'Атмосферное давление', unit: 'гПа', system: 'СИ', rel: '1 гПа = 100 Па', desc: 'Давление атмосферы, действующее на все находящиеся в ней предметы и на земную поверхность.'},
    {quantity: 'Атмосферное давление', unit: 'кПа', system: 'СИ', rel: '1 кПа = 1000 Па', desc: 'Давление атмосферы, действующее на все находящиеся в ней предметы и на земную поверхность.'},
    {quantity: 'Атмосферное давление', unit: '', system: 'СИ', rel: '', desc: 'Давление атмосферы, действующее на все находящиеся в ней предметы и на земную поверхность.'},
    {quantity: 'Высота над уровнем моря', unit: 'м', system: 'СИ', rel: '', desc: 'Линейная мера разности потенциалов в точке земной поверхности и в начале счёта высот.'},
    {quantity: 'Высота', unit: 'м', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Параметры погоды', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Скорость потока', unit: 'м/с', system: 'СИ', rel: '', desc: 'Физическая величина, которая определяет изменение положения объекта в единицу времени.'},
    {quantity: 'Направление ветра', unit: '°', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Скорость ветра', unit: 'м/с', system: 'СИ', rel: '', desc: 'Количество метров, проходимое воздухом в секунду.'},
    {quantity: 'Параметры ветра', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Температура тела', unit: '°C', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Кардиограмма', unit: 'мВ', system: 'СИ', rel: '', desc: 'Исследование электрической активности сердца.'},
    {quantity: 'ЭКГ', unit: 'В', system: 'СИ', rel: '', desc: 'Методика регистрации и исследования электрических полей, образующихся при работе сердца.'},
    {quantity: 'ФПГ', unit: 'В', system: 'СИ', rel: '', desc: 'Фотоплетизмография — это методика, которая позволяет фиксировать периодические колебания различных частот в дистальном сосудистом русле.'},
    {quantity: 'ЭМГ', unit: 'В', system: 'СИ', rel: '', desc: 'Электромиография - метод исследования биоэлектрических потенциалов, возникающих в скелетных мышцах человека и животных при возбуждении мышечных волокон; регистрация электрической активности мышц. '},
    {quantity: 'КГР', unit: 'В', system: 'СИ', rel: '', desc: 'Кожно-гальваническая реакция — биоэлектрическая реакция, которая регистрируется с поверхности кожи, показатель активности вегетативной нервной системы, широко применяемый в психофизиологии.'},
    {quantity: 'Дыхание', unit: 'В', system: 'СИ', rel: '', desc: ''},
    {quantity: 'ЭЭГ', unit: 'В', system: 'СИ', rel: '', desc: 'Электроэнцефалография – метод исследования электрической активности мозга путем размещения электродов в определенных зонах на поверхности головы.'},
    {quantity: 'Сила', unit: 'кгс', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Усилие', unit: 'Н', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Частота дыхания', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Дыхание', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Скорость потока воздуха', unit: 'л/с', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Скорость потока воздуха', unit: 'мл/с', system: 'СИ', rel: '1 мл/с = 0.001 л/с', desc: ''},
    {quantity: 'Объём лёгких', unit: '', system: 'СИ', rel: '', desc: 'Объём воздуха, проходящего через лёгкие при различных фазах дыхательного цикла.'},
    {quantity: 'Объём лёгких', unit: 'мл', system: 'СИ', rel: '1 мл = 0.001 л', desc: 'Объём воздуха, проходящего через лёгкие при различных фазах дыхательного цикла.'},
    {quantity: 'Объём лёгких', unit: 'л', system: 'СИ', rel: '', desc: 'Объём воздуха, проходящего через лёгкие при различных фазах дыхательного цикла.'},
    {quantity: 'Пульс', unit: 'удар/мин', system: 'СИ', rel: '', desc: 'Толчкообразные колебания стенок артерий, связанные с сердечными циклами. '},
    {quantity: 'Пульсовые колебания', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Пульс, уровень O2', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Артериальное давление', unit: 'мм рт.ст.', system: 'СИ', rel: '', desc: 'Давление, которое кровь оказывает на стенки кровеносных сосудов. '},
    {quantity: 'Измерение', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Параметры погоды, освещённость, звук', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Параметры погоды, магнитное поле, температура, давление', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Электрические параметры, освещённость, звук', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Физиологические параметры', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Параметры окружающей среды', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Температура, электропроводность, ОВП, капли', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Кислотность, концентрация ионов, ОВП, температура', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Кислотность, ОВП, температура', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Параметры', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Электрические параметры, освещённость, магнитное поле', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Параметры погоды, температура, давление', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Температура, кислотность, ОВП, концентрация ионов', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Температура, электропроводность, капли', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Электрические параметры, освещённость, магнитное поле, параметры погоды', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Положение, ориентация, параметры окружающей среды', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Химические параметры, параметры окружающей среды', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Физические параметры', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Химические параметры', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Биологические параметры', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Химические и биологические параметры', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Экологические параметры', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Биологические и экологические параметры', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Метеорологические параметры', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Экологические и химические параметры', unit: '', system: 'СИ', rel: '', desc: ''},
    {quantity: 'Физиологические и экологические параметры', unit: '', system: 'СИ', rel: '', desc: ''},
];