import React, { useState, useEffect, useRef } from "react";
import {useSelector, useDispatch} from "react-redux";
import {storageClass, storageOrg} from "../../redux/slices/storage";
import {isAuth} from "../../helpers/auth";
import {fetchUsersByOrg, fetchclass} from "../../redux/slices/classsline";
import ModalConfirmDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import Table from "../ui/Table/Table";
import {getRemainingTimeInMSec, getClassStudents, initializeTimeCounter} from "../ui/utils/gen_utils";
import {optionsUsers} from "../../redux/slices/options";
import {onlineTestInfoApi} from "../../api/api";
import socket from "../../socket";
import {PRACTICUM_SECTION}  from "../template/Dashboard/DashboardProfile";
import {FEATURES} from "../../api/config";
import {SCORE_BE_EVALUATED_LATER} from '../ui/utils/score_utils';
import {getTopHeader, getHeader, getExceptionCellStyle, stopActiveTest} from "./rt_utils";
import {toast} from "react-toastify";
import {PRACTICUM_TEST_TYPE, getPracticumTestInfo, hasPracticumTestInfo} from "../ui/utils/ServerInfo";
import {QUESTION_TYPES, getOnlineTestById} from "../OnlineTest/ot_utils";
import { Content, ContentBody, ContentHead } from "../template/ContentParts";
import "./ReviewActiveLabWork.scss";
import "../practicum/LabWorkShow.scss";

const ReviewActiveOnlineTest = (props) => {
    const [startTime, setStartTime] = useState(undefined);
    const [currTestTime, setCurrTestTime] = useState();
    const [counter, setCounter] = useState(0);
    const [cnt, setCnt] = useState(0);
    const [timeLimitInMinutes, setTimeLimitInMinutes] = useState(0);
    const [timeLimitEnabled, setTimeLimitEnabled] = useState(false);
	const [onlineTestId, setOnlineTestId] = useState(undefined);
	const [otRunId, setOtRunId] = useState(undefined);
	const [students, setStudents] = useState([]);
	const [studentData, setStudentData] = useState([]);
    const [showStopTestDlg, setShowStopTestDlg] = useState(false);
	const [stopTest, setStopTest] = useState(false);
	const [hasNonCheckedAnswers, setHasNonCheckedAnswers] = useState(false);
	const [onlineTestTitle, setOnlineTestTitle] = useState('');
	const [questionNumber, setQuestionNumber] = useState(0);
    const [isAutoEstimateType, setIsAutoEstimateType] = useState(true);

	const documentOrg = useSelector(storageOrg);
	const documentClass = useSelector(storageClass);
	const onlineUsers = useSelector(optionsUsers);
    const dispatch = useDispatch();
	const timerIdRef = useRef(undefined);
	const REFRESH_FREQ = 5;

    useEffect(() => {
        dispatch(fetchUsersByOrg(documentOrg._id));
        dispatch(fetchclass(isAuth()._id));
    }, [dispatch, documentOrg._id]);

	useEffect(() => {
        socket.on("FE-refresh-db", ({type}) => {
            if (type === 'OnlineTestInfo') {
				setCounter(counter => counter + 1);
			}
        });
        return () => {
            socket.off("FE-refresh-db");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

	useEffect(() => {
		const fetchGeneralData = async (otId) => {
			const otData = await getOnlineTestById(otId);
			setOnlineTestTitle(otData?.title);
			setQuestionNumber(otData.questions.length);
			if (otData.questions.find(item => Number(item.questionType) === QUESTION_TYPES.FREE_WRITING)) 
				setHasNonCheckedAnswers(true);
		};

		if (hasPracticumTestInfo()) {
			const ot = getPracticumTestInfo();
			setOnlineTestId(ot.labWorkId);
			setOtRunId(ot.testRunId);
			setStartTime(ot.startTime);
			setTimeLimitInMinutes(ot.timeLimitInMinutes);
			setTimeLimitEnabled(ot.timeLimitEnabled);
			setIsAutoEstimateType(ot.isAutoEstimateType);

			fetchGeneralData(ot.labWorkId);
			setStudents(getClassStudents(documentClass.classUsers));
		}
	}, [documentClass]);

	useEffect(() => {
		timerIdRef.current = initializeTimeCounter(1, setCurrTestTime); //refresh each sec
		const doStop = () => { setStopTest(true); };
		setTimeout(doStop, 2 * 60 * 60 * 1000); //stop the test in 2 hours
		return () => {clearInterval(timerIdRef.current);};
    }, []);
	useEffect(() => {
		setCnt(cnt => cnt + 1);
	}, [currTestTime]);

	useEffect(() => {
		if (!timeLimitEnabled || stopTest) return;
		const remTime = getRemainingTimeInMSec(currTestTime, startTime, 60 * timeLimitInMinutes);
		if (remTime <= 0) {
			setStopTest(true);
		}
    }, [currTestTime, startTime, timeLimitEnabled, timeLimitInMinutes, stopTest]);

    useEffect(() => {
        const fetchData = async () => {
			await onlineTestInfoApi.getAllClassUserOnlineTestInfosByRunId(otRunId)
				.then((result) => {
					const otiData = result.data;

					const list = [];
					const onlineStudents = onlineUsers.filter(item => item.role === 0);

					for (let i = 0; i < students.length; i ++) {
						const student = students[i];
						//toast.info('student.owner: ' + student.owner);
						const oti = otiData?.find(item => item.owner === student.owner);
		
						const correctAnswerNumber = oti ? oti.answers.filter(item => item.isCorrect).length : 0;
						const answerNumber = oti ? oti.answers.filter(item => !!item.answer).length : 0;
						const score = !isAutoEstimateType ? SCORE_BE_EVALUATED_LATER : 
							oti?.score !== null ? oti?.score : '';
			
						const obj = {
								name: student.name,
								online: onlineStudents.find(item => item._id === student.owner) ? 'Онлайн' : 'Офлайн',
								progress: answerNumber === questionNumber ? 'Готово' : ''+answerNumber+' из '+questionNumber,
								result: '' + (correctAnswerNumber ? correctAnswerNumber : '0') + ' из ' + questionNumber,
								score: score,
								id: student.owner,
								personPict: student.personPict
							}
						list.push(obj);
					}
		
					setStudentData(list);
            	}).catch((err) => {
                	toast.error(err.response.data.errors);
            	});
        };

		if (!otRunId) return;
		//if (cnt > REFRESH_FREQ && cnt % REFRESH_FREQ !== 0) return;
		fetchData();
    },[documentClass._id, isAutoEstimateType, otRunId, onlineUsers, cnt,
		questionNumber, startTime, students]);

	useEffect(() => {
		if (!stopTest) return;
		stopActiveTest(documentClass._id);
		toast.warn("Время тестирования закончилось.");
		props.history.push('/reviewtest/ot/' + otRunId);
	},[stopTest, documentClass._id, props.history, otRunId]);

	const handleStopWork = () => {
		setShowStopTestDlg(true);
	};

    const handleFinishTestYes = () => {
		setShowStopTestDlg(false);
		setStopTest(true);
	};
	
	const specialCellStyle = (item, rowInd, colInd, row) => getExceptionCellStyle(item);

	const handlePerson = (userId) => { //click to open personal data
		props.history.push(FEATURES['profile'].to + '/' + userId + '/' + PRACTICUM_SECTION.ONLINE_TESTS);
	};

	const getTableHeader = () => {
		const header = [
			{column: 'Имя ученика', name: 'name', style: { width: '20%'}},
			{column: 'В сети', name: 'online', style: { width: '20%'}},
			{column: 'Прогресс выполнения', name: 'progress', style: { width: '20%'}},
			{column: 'Правильные ответы', name: 'result', style: { width: '20%'}},
			{column: 'Оценка', name: 'score', style: { width: '20%'}},
			{column: 'id', name: 'id', style: { width: '0%'}},
		];
		return header;
	};

	if (!onlineTestId) {
		return (
			<>
				<ContentHead title="Тест для класса не установлен." /> 
				<Content background={false} />
			</>
		);
	}

    return (
        <>
			{getTopHeader('', handleStopWork, PRACTICUM_TEST_TYPE.ONLINE_TEST, hasNonCheckedAnswers)}
			
			<Content>
				{getHeader(onlineTestTitle, '', timeLimitEnabled, timeLimitInMinutes, currTestTime, startTime)}
				<ContentBody>
					<Table 
						table={{
							header: getTableHeader(), 
							data: studentData, 
							specialCellStyle: specialCellStyle
						}}
						sort={{
							hasSorting: true, 
							initSortInd: 1
						}}
						person={{handlePerson: handlePerson}}
						actions={undefined}
					/>
				</ContentBody>
			</Content>

			{showStopTestDlg &&
			<ModalConfirmDialog
				showConfirmDlg={showStopTestDlg} 
				handleNo={() => setShowStopTestDlg(false)}
				question={'Вы действительно хотите завершить онлайн тест?'}
				handleYes={handleFinishTestYes}
			/>
			}
        </>
    );
};

export default ReviewActiveOnlineTest;
