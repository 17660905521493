import React, { useState } from 'react';
import './NewModals.scss';
import NewModalsButton from './NewModalsButton';
import { useDispatch, useSelector } from 'react-redux';
import { addNewColumn } from '../../../redux/slices/trello';
import { storageClass } from '../../../redux/slices/storage';

const NewSectionModal = ({ onCloseModal }) => {
  const dispatch = useDispatch();

  const documentClass = useSelector(storageClass);
  const columns = useSelector((state) => state.trelloSlice.columns);
  const groups = columns.filter((column) => !column.parent);
  
  const [newSectionName, setNewSectionName] = useState('');

  const addNewSection = () => {
    if (!newSectionName) return;
    dispatch(
      addNewColumn({
        name: newSectionName,
        room: documentClass?._id,
        order: groups.length,
      })
    );
    onCloseModal();
    setNewSectionName('');
  };

  return (
    <div className="new_modals">
      <h2 className="new_modals__title">Создание нового раздела</h2>
      <div className="new_modals__content">
        <div className="new_modals__section">
          <div className="new_modals__section_item">
            <div className="new_modals__section_title">Название раздела</div>
            <input
              autoFocus
              type="text"
              value={newSectionName}
              placeholder="Придумайте название для раздела"
              onChange={(e) => setNewSectionName(e.target.value)}
              className="new_modals__section_input"
            />
          </div>
        </div>
        <div className="new_modals__section">
          <div className="new_modals__section_buttons">
            <NewModalsButton
              type="add"
              title="Создать раздел"
              onClick={addNewSection}
            />
            <NewModalsButton
              type="cancel"
              title="Отмена"
              onClick={() => onCloseModal()}
            />
          </div>
        </div>
      </div>
      <span
        className="modal_task__close"
        style={{ top: 0, right: 0 }}
        onClick={() => onCloseModal()}
      />
    </div>
  );
};

export default NewSectionModal;
