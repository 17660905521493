import {toast} from "react-toastify";
import axios from "axios";
import {storageApi} from "../api/api";
import {AssetRecordType, getHashForString, TLAssetStore, TLBookmarkAsset, uniqueId} from "tldraw";

const STORAGE_PATH = 'whiteboard%2F'

export const assets: TLAssetStore = {
  async upload(_asset, file) {
    const objectName = encodeURIComponent(uniqueId() + '.' + file.name.split('.').pop())
    const uploadUrl = await storageApi.getUploadUrl(STORAGE_PATH + objectName);
    await axios.put(uploadUrl, file, {
      headers: {"Content-Type": file.type},
    }).catch((err) => {
      toast.error(err)
      return ''
    });

    return await storageApi.getUrl(STORAGE_PATH + objectName)
  },
  async resolve(asset) {
    return await storageApi.getUrl(STORAGE_PATH + asset.props.src?.split('/').pop())
  },
}

export async function unfurlBookmarkUrl({url}: { url: string }): Promise<TLBookmarkAsset> {
  const asset: TLBookmarkAsset = {
    id: AssetRecordType.createId(getHashForString(url)),
    typeName: 'asset',
    type: 'bookmark',
    meta: {},
    props: {
      src: url,
      description: '',
      image: '',
      favicon: '',
      title: '',
    },
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_TLDRAWBOARD_URL}/unfurl?url=${encodeURIComponent(url)}`)
    const data = await response.json()

    asset.props.description = data?.description ?? ''
    asset.props.image = data?.image ?? ''
    asset.props.favicon = data?.favicon ?? ''
    asset.props.title = data?.title ?? ''
  } catch (e) {
    console.error(e)
  }

  return asset
}
