import React, { useEffect, useState, memo } from 'react';
import Icon from '../Icon';
import { Modal, Button } from 'rlabui';
import { Radio } from 'react-loader-spinner';
// Android
import ss from './Connection.module.scss';
import footerSS from '../Template/Footer/Footer.module.scss';
import { UsbSerial } from 'usb-serial-plugin';

// const serial = require('cordovarduino');

const ConnectionButtons = (props) => {
    // console.log(props);
    const {
        port,
        demo,
        btConnect,
        btDisconnect,
        connectDisconnect,
        connectionType,
        isMobile,
        played,
        showModalDisconnect,
        modalDisconnect,
        devicesCount,
        loader,
        setDeviceHistoryModal,
        isRlab,
        setDemoModal,
    } = props;
    const { isAndroid } = window;
    // const { togglePort,  fakeConnectDisconnect } = props;

    const [btConnection, setBtConnected] = useState(port && connectionType === 'bt');
    const [usbConnection, setUsbConnected] = useState(port && connectionType === 'usb');
    const [androidUsbConnection, setAndroidUsbConnected] = useState(port && connectionType === 'usb');
    // const [fakePortOpen, setFakePortOpen] = useState(false);

    useEffect(() => {
        if (btConnection) {
            !port && btConnect();
        } else {
            port && connectionType === 'bt' && !played && showModalDisconnect(true);
            port && !connectionType && btConnect();
        }
    }, [btConnection]);

    useEffect(() => {
        if (usbConnection) {
            (!port || !connectionType) && connectDisconnect();
        } else {
            port && connectionType === 'usb' && !played && showModalDisconnect(true);
            port && !connectionType && connectDisconnect();
        }
    }, [usbConnection]);

    useEffect(() => {
        if (!connectionType) {
            setBtConnected(false);
            setUsbConnected(false);
        }
    }, [connectionType]);

    useEffect(() => {
        if (androidUsbConnection) {
            (!port || !connectionType) && connectDisconnect(UsbSerial);
        } else {
            port && connectionType === 'usb' && !played && showModalDisconnect(true);
            port && !connectionType && connectDisconnect(UsbSerial);
        }
    }, [androidUsbConnection]);

    useEffect(() => {
        if (!port) {
            setBtConnected(false);
            setUsbConnected(false);
        }
    }, [port]);

    useEffect(() => {
        if (!loader && !port) {
            setBtConnected(false);
            setUsbConnected(false);
            setAndroidUsbConnected(false);
        }
    }, [loader]);

    const hadleDisconnect = () => {
        connectionType === 'bt' && btDisconnect();
        connectionType === 'usb' && connectDisconnect();
        showModalDisconnect(false);
        setBtConnected(false);
        setUsbConnected(false);
        setAndroidUsbConnected(false);
    };

    const hadleNotDisconnect = () => {
        port && connectionType === 'bt' ? setBtConnected(true) : setBtConnected(false);
        port && connectionType === 'usb' ? setUsbConnected(true) : setUsbConnected(false);
        showModalDisconnect(false);
    };

    const handleConnectBt = () => {
        if (isAndroid) {
            if (connectionType === 'bt') {
                btDisconnect();
            } else {
                btConnect();
            }
        } else {
            setDeviceHistoryModal(true);
        }
    };

    const toggleLoader = (
        <Radio
            visible={loader}
            wrapperClass={ss.loader}
            colors={connectionType?.length ? ['#ffffff', '#ffffff', '#ffffff'] : ['#00b5ff', '#00b5ff', '#00b5ff']}
        />
    );

    return (
        <>
            <Modal
                visible={modalDisconnect}
                size={'xs'}
            >
                <Modal.Head
                    modalClose={hadleNotDisconnect}
                    title={`Вы хотите отключить ${connectionType === 'bt' ? 'bluetooth' : 'usb'} устройства?`}
                ></Modal.Head>
                <Modal.Footer>
                    <Button onClick={hadleDisconnect}>Отключить</Button>
                    <Button
                        border={true}
                        onClick={hadleNotDisconnect}
                    >
                        Отмена
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className={footerSS.group}>
                {isRlab && (
                    <div className={footerSS.label}>
                        Источник <br />
                        cигнала
                    </div>
                )}
                <div className={footerSS.btns}>
                    <div className={ss.root}>
                        <Icon
                            tag="button"
                            title="Bluetooth соединение"
                            name={'bluetooth'}
                            className={connectionType === 'bt' ? 'active' : ''}
                            disabled={(connectionType && connectionType !== 'bt') || demo.length}
                            onClick={handleConnectBt}
                        >
                            {devicesCount > 0 && connectionType === 'bt' && !isAndroid && !loader && <span>{devicesCount} / 7</span>}
                            {btConnection && toggleLoader}
                        </Icon>
                        {/* {port && connectionType === 'bt' && !played && !isAndroid && isRlab && (
                            <div className="btDrop__list">
                                {devicesCount < 7 && (
                                    <div
                                        className="btDrop__item"
                                        onClick={() => btConnect()}
                                    >
                                        <span>
                                            <Icon name="plus" /> Добавить устройство
                                        </span>
                                    </div>
                                )}
                                <div
                                    className="btDrop__item"
                                    onClick={() => setBtConnected(!btConnection)}
                                >
                                    <span>
                                        <Icon name="turn-on" /> Отключить все ({devicesCount}шт)
                                    </span>
                                </div>
                            </div>
                        )} */}
                    </div>
                    {isAndroid ? (
                        <div className={ss.root}>
                            <Icon
                                tag="button"
                                name="usb"
                                title="USB соединение"
                                className={connectionType === 'usb' ? 'active' : ''}
                                disabled={(connectionType && connectionType !== 'usb') || demo.length}
                                onClick={() => {
                                    setAndroidUsbConnected(!androidUsbConnection);
                                }}
                            >
                                {androidUsbConnection && toggleLoader}
                            </Icon>
                        </div>
                    ) : (
                        !isMobile && (
                            <div className={ss.root}>
                                <Icon
                                    tag="button"
                                    name="usb"
                                    title="USB соединение"
                                    className={connectionType === 'usb' ? 'active' : ''}
                                    disabled={(connectionType && connectionType !== 'usb') || demo.length}
                                    onClick={() => {
                                        setUsbConnected(!usbConnection);
                                    }}
                                >
                                    {usbConnection && toggleLoader}
                                </Icon>
                            </div>
                        )
                    )}
                    {!isAndroid && isRlab && (
                        <Icon
                            tag="button"
                            name="demo"
                            disabled={connectionType}
                            className={demo?.length > 0 && 'active'}
                            title={'Включить демо-режим'}
                            onClick={() => setDemoModal(true)}
                        ></Icon>
                    )}
                </div>
            </div>
        </>
    );
};
export default memo(ConnectionButtons);
