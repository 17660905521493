//all sound params:
//https://recsquare.ru/blog/kakie-nastroyki-vliyayut-na-kachestvo-zvuka/
//ModularRouting - 
//https://webaudio.github.io/web-audio-api/#ModularRouting
//звуки - https://www.ee.columbia.edu/~dpwe/sounds/music/
//
//github sample - 
//https://github.com/mdn/webaudio-examples?tab=readme-ov-file#readme
//run the app -   https://mdn.github.io/webaudio-examples/voice-change-o-matic/
import React, { useState, useEffect, useRef } from 'react';
import { Icon, Warning } from '../ui';
import { getConvolver, getEffects, getEffectById, createEchoDelayEffect, makeDistortionCurve,
  getEffectShowMethods, getEffectShowMethodById, getNoises, getNoiseById } from './instrument_utils';
import { Dropdown } from '../ui';
import DropdownItem from '../ui/Dropdown/DropdownItem';
import { saveExcel } from '../CalcModels/excel_utils';
import { getAudioContextData, setAudioContextData }  from "../../redux/slices/tutorialslice";
import { EXP_STATE, FREQUENCY_IN_MSEC, getInputRange, getTimeStringByTime, getPlayPauseExperiment, 
  clearTimer, workProgress, doNextProgressStep } from "../CalcModels/cm_utils";
import SaveXlsFileConfirmDialog from "../ui/ModalDialogs/SaveXlsFileConfirmDialog";
import {getRoundValue} from '../ui/utils/gen_utils';
import {useDispatch, useSelector} from "react-redux";
import "../CalcModels/CalcModel.scss";
import "./Instruments.scss";
import ss from './VoltageGenerator.module.scss';

const DEFAULT_SOUND_FORM = 'sine';
const DEFAULT_SOUND_VOLUME = 5; //%
const FREQUENCY_RANGE = [20, 20000, 1, 200];
const GAIN_RANGE = [0, 10];

const waveFormList = [  
  {label: 'Синусоида', value: 'sine', icon: 'sinusoid'},
  {label: 'Меандр', value: 'square', icon: 'rectangle'},
  {label: 'Треугольник', value: 'triangle', icon: 'triangle'},
  {label: 'Пила', value: 'sawtooth', icon: 'chainsaw'},
];

const VoltageGenerator = ({isLightMode}) => {
  const [audioCtx, setAudioCtx] = useState(null);
  //
  const [waveFormId, setWaveFormId] = useState(DEFAULT_SOUND_FORM);
  const [frequency, setFrequency] = useState(FREQUENCY_RANGE[3]);
  const [soundVolume, setSoundVolume] = useState(DEFAULT_SOUND_VOLUME);
  const [isValueChanged, setIsValueChanged] = useState(false);
  const [effectId, setEffectId] = useState('');
  //
  const [convolver, setConvolver] = useState(null);
  const [analyser, setAnalyser] = useState(null);
  const [noiseId, setNoiseId] = useState('');
  const [showMethodId, setShowMethodId] = useState('frequencybars'); //sinewave, frequencybars, off
  const [animationFrameId, setAnimationFrameId] = useState(0); 
  //
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [isSoundRestarted, setIsSoundRestarted] = useState(false);
  const [expState, setExpState] = useState(EXP_STATE.NOT_STARTED);
  const [prevExpState, setPrevExpState] = useState(EXP_STATE.NOT_STARTED);
	const [initTimeInSec, setInitTimeInSec] = useState(0);
	const [currProgressInSec, setCurrProgressInSec] = useState(0);
	const [refreshCount, setRefreshCount] = useState(0);
	const [timerId, setTimerId] = useState(undefined);
  const [played, setPlay] = useState(false);
  const [paused, setPaused] = useState(false);
  const [startPlay, setStartPlay] = useState(false);
  const [showSaveDlg, setShowSaveDlg] = useState(false);

  const frequencyRef = useRef();
  const soundVolumeRef = useRef();
  const canvasRef = useRef(null);

  const dispatch = useDispatch();
  const savedAudioContexData = useSelector(getAudioContextData);

  useEffect(() => {
    if (savedAudioContexData && !isPageLoaded) {
      if (frequencyRef?.current) frequencyRef.current.setValue(savedAudioContexData.frequency);
      if (soundVolumeRef?.current) soundVolumeRef.current?.setValue(savedAudioContexData.soundVolume);
      setFrequency(savedAudioContexData.frequency);
      setWaveFormId(savedAudioContexData.waveFormId);
      setSoundVolume(savedAudioContexData.soundVolume);
      setNoiseId(savedAudioContexData.noiseId);
      setEffectId(savedAudioContexData.effectId);
      setIsValueChanged(true);
      setInitTimeInSec(((new Date()) - savedAudioContexData.startDate) / 1000);
      setIsSoundRestarted(true);
    }
    if (!isPageLoaded) setIsPageLoaded(true);
  },[savedAudioContexData, isPageLoaded]);

  useEffect(() => {
    if (audioCtx) return;
    navigator.mediaDevices.getUserMedia({ audio: true }) //see https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
      .then((stream) => {
        const _audioCtx = new AudioContext();
        setAudioCtx(_audioCtx);
        //console.log('generate audioCtx');
    })
  }, [audioCtx]);  

  useEffect(() => {
     if (!audioCtx) return;
     if (convolver) return;
     if (effectId !== "convolver") return;
     //console.log('generate Convolver');
     getConvolver(audioCtx, noiseId, setConvolver, setIsValueChanged);
  },[audioCtx, convolver, effectId, noiseId]);

  useEffect(() => {
    const playSound = (_audioCtx) => {
      // Create an Oscillator and aGain node
      const oscillator = new OscillatorNode(_audioCtx, {
          type: waveFormId,
          frequency: frequency, // Value in Herz
        });

        const gainNode = new GainNode(_audioCtx, { 
          gain: soundVolume / 100 * GAIN_RANGE[1]
        });

        if (effectId !== '')  {
          const _analyser = _audioCtx.createAnalyser();
          setAnalyser(_analyser);
          _analyser.minDecibels = -90;
          _analyser.maxDecibels = -30; //-10;
          _analyser.smoothingTimeConstant = 0.85;
        
          const distortion = audioCtx.createWaveShaper();
          const _gainNode = gainNode; //convolver ? gainNode : audioCtx.createGain();
          const biquadFilter = audioCtx.createBiquadFilter();
          const _convolver = audioCtx.createConvolver(); //convolver ? convolver : audioCtx.createConvolver();
          const echoDelay = createEchoDelayEffect(audioCtx, frequency);

          oscillator.connect(distortion);
          distortion.connect(biquadFilter);
          biquadFilter.connect(_gainNode);
          _convolver.connect(_gainNode);
          echoDelay.placeBetween(_gainNode, _analyser);
          _analyser.connect(audioCtx.destination);

          distortion.oversample = "4x";
          biquadFilter.gain.setTargetAtTime(0, audioCtx.currentTime, 0);
     
          if (echoDelay.isApplied()) {
            echoDelay.discard();
          }
      
          // When convolver is selected it is connected back into the audio path
          if (effectId === "convolver") {
            biquadFilter.disconnect(0);
            biquadFilter.connect(_convolver);
          } else {
            biquadFilter.disconnect(0);
            biquadFilter.connect(_gainNode);
      
            if (effectId === "distortion") {
              distortion.curve = makeDistortionCurve(400);
            } else if (effectId === "biquad") {
              biquadFilter.type = "lowshelf";
              biquadFilter.frequency.setTargetAtTime(1000, audioCtx.currentTime, 0);
              biquadFilter.gain.setTargetAtTime(25, audioCtx.currentTime, 0);
            } else if (effectId === "delay") {
              echoDelay.apply();
            }
          }
        } else {
          oscillator.connect(gainNode);
          gainNode.connect(_audioCtx.destination);
          setAnalyser(null);
        }
  
        // Now that everything is connected, starts the sound
        oscillator.start(0); //start sound generation

        let audioContextData = null;
        if (_audioCtx) 
          audioContextData = {
            frequency: frequency, 
            waveFormId: waveFormId, 
            soundVolume: soundVolume,
            startDate: new Date(),
            initTimeInSec: currProgressInSec,
            noiseId: noiseId,
            effectId: effectId,
          };
    
        dispatch(setAudioContextData(audioContextData));
    };
    
    if (!audioCtx) return;
    if (!isValueChanged && prevExpState === expState) return;

    if ((expState === EXP_STATE.STARTED)) {
      playSound(audioCtx);
      setIsValueChanged(false);
    } else if ((expState === EXP_STATE.STOPPED)) {
      if (!isValueChanged) {
        audioCtx.suspend();
      }
    } else if ((expState === EXP_STATE.CONTINUED)) {
      if (isValueChanged) {
        playSound(audioCtx);
        setIsValueChanged(false);
      } else if (prevExpState !== expState) {
        audioCtx.resume();
      }
    } else if ((expState === EXP_STATE.FINISHED && prevExpState !== expState)) {
        audioCtx.close();
        setAudioCtx(null);
        if (convolver) setConvolver(null);
        dispatch(setAudioContextData(null));
        setIsValueChanged(false);
    }

    if (prevExpState !== expState)
      setPrevExpState(expState);
  }, [audioCtx, savedAudioContexData, expState, frequency, waveFormId, effectId, noiseId, convolver, 
    soundVolume, currProgressInSec, isValueChanged, prevExpState, dispatch]);

useEffect(() => {
  const draw = () => {
    const _animationFrameId = requestAnimationFrame(draw);
    //console.log('draw=', _animationFrameId);
    setAnimationFrameId(_animationFrameId);
    analyser.getByteTimeDomainData(dataArray);
    canvasCtx.fillStyle = "rgb(200, 200, 200)";
    canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);
    canvasCtx.lineWidth = 2;
    canvasCtx.strokeStyle = "rgb(0, 0, 0)";
    canvasCtx.beginPath();
    const sliceWidth = (WIDTH * 1.0) / bufferLength;

    let x = 0;
    for (let i = 0; i < bufferLength; i++) {
      const v = dataArray[i] / 128.0;
      const y = (v * HEIGHT) / 2;
      if (i === 0) {
        canvasCtx.moveTo(x, y);
      } else {
        canvasCtx.lineTo(x, y);
      }
      x += sliceWidth;
    }
    canvasCtx.lineTo(WIDTH, HEIGHT / 2);
    canvasCtx.stroke();
  };  
  const drawAlt = () => {
    const _animationFrameId = requestAnimationFrame(drawAlt);
    //console.log('drawAlt=', _animationFrameId);
    setAnimationFrameId(_animationFrameId);
    analyser.getByteFrequencyData(dataArrayAlt);
    canvasCtx.fillStyle = "rgb(0, 0, 0)";
    canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);
    const barWidth = (WIDTH / bufferLengthAlt) * 2.5;
    let x = 0;
    for (let i = 0; i < bufferLengthAlt; i++) {
      const barHeight = dataArrayAlt[i];
      canvasCtx.fillStyle = "rgb(" + (barHeight + 100) + ",50,50)";
      canvasCtx.fillRect(
        x,
        HEIGHT - barHeight / 2,
        barWidth,
        barHeight / 2
      );
      x += barWidth + 1;
    }
  };

  if (!audioCtx || !analyser || effectId === ''  || !canvasRef.current) return;
    const canvas = canvasRef.current;
    const canvasCtx = canvas.getContext("2d");
    const WIDTH = canvas.width;
    const HEIGHT = canvas.height;
    let dataArray, bufferLength, bufferLengthAlt, dataArrayAlt;

    //console.log('showMethodId=', showMethodId);

    if (showMethodId === "sinewave") {
      analyser.fftSize = 2048;
      bufferLength = analyser.fftSize;

      // We can use Float32Array instead of Uint8Array if we want higher precision
      dataArray = new Uint8Array(bufferLength); // const dataArray = new Float32Array(bufferLength);
      canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);
      draw();
    } else if (showMethodId === "frequencybars") {
      analyser.fftSize = 256;
      bufferLengthAlt = analyser.frequencyBinCount;
      // See comment above for Float32Array()
      dataArrayAlt = new Uint8Array(bufferLengthAlt);
      canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);
      drawAlt();
    } else if (showMethodId === "off") {
      canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);
      canvasCtx.fillStyle = "red";
      canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);
    }
},[audioCtx, analyser, showMethodId, effectId, played, paused]);

const cleanAnimationFrame = (_animationFrameId) => {
  if (_animationFrameId === 0) return;
  cancelAnimationFrame(_animationFrameId);
  setAnimationFrameId(0);
}

useEffect(() => {
  if (!played || paused)
    cleanAnimationFrame(animationFrameId);
}, [played, paused, animationFrameId]);

const cleanAudioCtx = () => {
  if (!!audioCtx) {
    audioCtx.close();
  }
  setAudioCtx(null);
  if (convolver) setConvolver(null);
  dispatch(setAudioContextData(null));
};
useEffect(() => {
  if (!isSoundRestarted) return;
  setIsSoundRestarted(false);
  runExperiment();
}, [isSoundRestarted]);

	useEffect(() => {
    const time = initTimeInSec + FREQUENCY_IN_MSEC / 1000. * refreshCount;
		setCurrProgressInSec(time);
		if (expState === EXP_STATE.FINISHED) {
		 	clearTimer(timerId, setTimerId);
		}
	}, [refreshCount, timerId, expState, initTimeInSec]);

  useEffect(() => {
    if (startPlay) {
      setTimeout(() => {setPlay(true); setStartPlay(false);            
		  }, 500);
    }
  }, [startPlay]);

  const getWaveFormById = id => waveFormList.find(item => item.value === id);
  
	const handlePlayPause = () => {
		if (expState !== EXP_STATE.FINISHED) { //stop or continue
			doNextProgressStep(played, setStartPlay, paused, setPaused, expState, setExpState, refreshCount, setRefreshCount, timerId, setTimerId);
		} else { //prepare to new experiment:
      if (expState !== EXP_STATE.NOT_STARTED) {
        setExpState(EXP_STATE.NOT_STARTED);
        clearTimer(timerId, setTimerId);
        setRefreshCount(0);
      }
      runExperiment();
		}
	};

  const runExperiment = () => {
    setExpState(EXP_STATE.STARTED);
    const _timerId = setInterval(() => workProgress(setRefreshCount), FREQUENCY_IN_MSEC);
    setTimerId(_timerId);
    setStartPlay(true);
  };

	const handleFinish = () => {
    setPlay(false);
    setPaused(false);
    setStartPlay(false);
    setExpState(EXP_STATE.FINISHED);
    clearTimer(timerId, setTimerId);
 
    cleanAudioCtx();
    setInitTimeInSec(0);
    setCurrProgressInSec(0);
    setRefreshCount(0);
	};

  const getFrequencyData = () => {
    return ({
        value: frequency,
        ref: frequencyRef,
        handleChange: e => handleSetFrequency(e.target.value),
        min: FREQUENCY_RANGE[0],
        max: FREQUENCY_RANGE[1],
        step: 1,
        setValue: v => handleSetFrequency(v),
        key: 'freq01',
        disabled: false
    });  
  };

  const getSoundVolumeData = () => {
    return ({
        value: soundVolume,
        ref: soundVolumeRef,
        handleChange: e => handleSoundVolume(e.target.value),
        min: 0,
        max: 100,
        step: 1,
        setValue: v => handleSoundVolume(v),
        key: 'sound01',
        disabled: false
    });  
  };

  const handleSetFrequency = value => {
    changeFrequency(Number(value));
  };

  const handleChangeFrequency = (direct) => {
    let shift = 0;
    if (direct === 1 && frequency < FREQUENCY_RANGE[1]) shift = + 1;
    else if (direct === -1 && frequency > FREQUENCY_RANGE[0])  shift = - 1;
    changeFrequency(frequency + shift);
  };

  const doClean = () => {
    cleanAnimationFrame(animationFrameId);
    cleanAudioCtx();
    setIsValueChanged(true);
  };
  
  const changeFrequency = val => {
    setFrequency(val);
    doClean();
  };

  const handleSoundVolume = val => {
    setSoundVolume(Number(val));
    doClean();
  };

  const handleWaveForm = id => {
    setWaveFormId(id);
    doClean();
  };
  
  const handleVisualMethod = id => {
    setShowMethodId(id);
    doClean();
  };

  const handleNoise = id => {
    setNoiseId(id);
    doClean();
  };
  const handleEffect = id => {
    setEffectId(id);
    doClean();
  };

	const saveXLSX = (workBookName, description) => { 
		const getTableData = () => {
			return [{
				description: description,
				waveForm: getWaveFormById(waveFormId).label,
				frequency: frequency,
				soundVolume: soundVolume,
			}];
		};

		const tableColumns = [
			{ header: 'Описание эксперимента', key: 'description' },
			{ header: 'Форма волны', key: 'waveForm' },
			{ header: 'Частота, Гц', key: 'frequency' },
			{ header: 'Громкость, %', key: 'soundVolume' },
		];

		const tableSheets = [];
		const tableSheet = {
			workSheetName: "Параметры звука",
			columns: tableColumns,
			tableData: getTableData(),
      graphicData: []
		};
		tableSheets.push(tableSheet);

		saveExcel(workBookName, tableSheets, []);
	};

  const handleExportXslx = () => {
    setShowSaveDlg(true);
  };
  
  const handleCancelSave = () => {
		setShowSaveDlg(false);
  };

  const getExportCB = () => {
    return (
      <div className={ss.export__wrap}>
        <div className={ss.export__label}>Работа с файлом</div>
        <div className={ss.export}>
          <div className={ss.export__toggle}>
            <span>Экспорт</span>
          </div>
          <div className={ss.export__list}>
            <div className={ss.export__item} onClick={handleExportXslx}>
              <Icon name="volume" />
              <p>Сохранить звук</p>
            </div>
          </div>
        </div>
      </div>
    );		
  };

  return (
      <div className={ss.root}>
        <div className={ss.main}>
          <Warning>Защитите свои уши. Перед воспроизведением проверьте уровень громкости!<br></br>Постепенно увеличивайте громкость, чтобы получить надлежащий уровень громкости.</Warning>
          <div>
            {getInputRange(getFrequencyData())}
            <div className={ss.current__wrap}>
              <p>{FREQUENCY_RANGE[0]}</p>
              <div className={ss.current}>
                <Icon name="prev" onClick={() => handleChangeFrequency(-1)} />
                <p>{frequency} ГЦ</p>
                <Icon name="next"  onClick={() => handleChangeFrequency(+1)} />
              </div>
              <p>{FREQUENCY_RANGE[1]}</p>
            </div>
          </div>

          <div className={ss.settings}>
            <div className="cor-net__row">
              <div className="cor-net__col col-4">
                <div className="cor-net__label">Форма волны</div>
                <Dropdown value={getWaveFormById(waveFormId).label} icon={getWaveFormById(waveFormId).icon} dropPosition="top">
                  {waveFormList.map((item, ind) => 
                    <DropdownItem 
                        key={'wave'+ind} 
                        onClick={() => handleWaveForm(item.value)}
                        className={item.value === getWaveFormById(waveFormId).value ? 'selected' : ''}
                    >
                      {<Icon name={item.icon}/> } {item.label} 
                    </DropdownItem>                  
                  )}
                </Dropdown>
              </div>
              
              <div className="cor-net__col col-4">
                <div className="cor-net__label">Изменение звука</div>
                <Dropdown value={getEffectById(effectId).label} dropPosition="top">
                  {getEffects().map((item, ind) => 
                    <DropdownItem 
                        key={'vg'+ind} 
                        onClick={() => handleEffect(item.value)}
                        className={item.value === effectId ? 'selected' : ''}
                    >
                      {<Icon name={item.icon}/> } {item.label} 
                    </DropdownItem>                  
                  )}
                </Dropdown>
              </div>
              <div className="cor-net__col col-grow">
                <div className="cor-net__label">Напряжение {getRoundValue(soundVolume, 0)}%</div>
                {getInputRange(getSoundVolumeData())}
              </div>
            </div>
          </div>
        </div>

        <div className={ss.footer}>
          {getPlayPauseExperiment(isLightMode, startPlay, played, paused, getTimeStringByTime(currProgressInSec), handleFinish, handlePlayPause)}
          {getExportCB()}
        </div>

        {showSaveDlg &&
        <SaveXlsFileConfirmDialog
          showConfirmSaveXlsDlg={showSaveDlg}
          setShowConfirmSaveXlsDlg={setShowSaveDlg}
          fileName={'Параметры звука'}
          saveExport={saveXLSX}
          handleCancelSave={handleCancelSave}
        />
        }

      </div>
  );
};

export default VoltageGenerator;

//https://www.npmjs.com/package/tonegenerator
//https://www.cirruslabs.io/blog1/modernized-technology/quick-start-to-generate-tones-in-javascript
//Web Audio API - https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API

//Example and tutorial: Simple synth keyboard - 
//This article presents the code and working demo of a video keyboard you can play using the mouse. The keyboard 
//allows you to switch among the standard waveforms as well as one custom waveform, and you can control the main ain using a volume slider beneath the keyboard. This example makes use o
//  https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Simple_synth

//Using IIR filters -  The IIRFilterNode interface of the Web Audio API is an AudioNode processor that implements 
//a general infinite impulse response (IIR) filter; this type of filter can be used to implement tone control devices 
//and graphic equalizers, and the filter response parameters can be specified, so that it can be tuned as needed. This article looks at how to implement one, and use it in a simple example.
//  https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Using_IIR_filters

//Using the Web Audio API //   https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Using_Web_Audio_API

//used examples from: //https://github.com/mdn/webaudio-examples
