import { drawRange } from './annotationsPlugin';

export const panPlugin = (props) => {
    const { setXRanges, setYMin, setYMax } = props;
    return {
        hooks: {
            ready: (u) => {
                const {
                    key,
                    isChildren,
                    actions: { played, paused },
                } = u;
                let root = u.root;
                const axes = root.querySelectorAll('.u-axis');

                axes.forEach((axe, i) => {
                    if (i === axes.length - 1) {
                        axe.classList.add('y-axis');
                    } else {
                        axe.classList.add('x-axis');
                    }
                });

                if (!played || paused) {
                    root.addEventListener('mousedown', drag);
                    root.addEventListener('touchstart', drag);
                } else {
                    root.removeEventListener('mousedown', drag);
                    root.removeEventListener('touchstart', drag);
                }

                function drag(e) {
                    const { xHandle, yHandle } = u.zoomPan;
                    if (!xHandle && !yHandle) return;

                    const touches = e.changedTouches?.length ? e.changedTouches[0] : null;

                    if (e.button === 1 || touches) {
                        const { target } = e;
                        const x = target.classList.contains('x-axis');
                        const y = target.classList.contains('y-axis');
                        const area = target.classList.contains('u-over') || target.classList.contains('u-range-x') || target.classList.contains('u-marker-x');
                        e.preventDefault();

                        let left0 = touches ? touches.clientX : e.clientX;
                        let top0 = touches ? touches.clientY : e.clientY;

                        let scXMin0 = u.scales.x.min;
                        let scXMax0 = u.scales.x.max;
                        let scYMin0 = u.scales.y.min;
                        let scYMax0 = u.scales.y.max;

                        let xUnitsPerPx = u.posToVal(1, 'x') - u.posToVal(0, 'x');
                        let yUnitsPerPx = u.posToVal(1, 'y') - u.posToVal(0, 'y');

                        function onmove(e) {
                            e.preventDefault();
                            document.body.style.cursor = x ? 'col-resize' : y ? 'row-resize' : 'grabbing';

                            setTimeout(() => {
                                let left1 = touches ? touches.clientX : e.clientX;
                                let top1 = touches ? touches.clientY : e.clientY;

                                let dx = xUnitsPerPx * (left1 - left0);
                                let dy = yUnitsPerPx * (top1 - top0);
                                let xnMin = (x || area || touches) && xHandle && !isChildren ? scXMin0 - dx : scXMin0;
                                let xnMax = (x || area || touches) && xHandle && !isChildren ? scXMax0 - dx : scXMax0;
                                let ynMin = (y || area || touches) && yHandle ? scYMin0 - dy : scYMin0;
                                let ynMax = (y || area || touches) && yHandle ? scYMax0 - dy : scYMax0;

                                u.batch(() => {
                                    u.setScale('x', {
                                        min: xnMin,
                                        max: xnMax,
                                    });
                                    u.axes.forEach((a, i) => i > 0 && u.setScale(a.scale, { min: ynMin, max: ynMax }));
                                    if (!isChildren) {
                                        setXRanges({ key, data: [xnMin, xnMax] });
                                        setYMin(ynMin);
                                        setYMax(ynMax);
                                    }
                                });
                                drawRange(u);
                            }, 0);
                        }

                        function onup(e) {
                            document.body.style.cursor = '';
                            document.removeEventListener('mousemove', onmove);
                            document.removeEventListener('touchmove', onmove);
                            document.removeEventListener('mouseup', onup);
                            document.removeEventListener('touchend', onup);
                        }

                        if (played && !paused) {
                            document.removeEventListener('mousemove', onmove);
                            document.removeEventListener('touchmove', onmove);
                            document.removeEventListener('mouseup', onup);
                            document.removeEventListener('touchend', onup);
                        } else {
                            document.addEventListener('mousemove', onmove);
                            document.addEventListener('touchmove', onmove);
                            document.addEventListener('mouseup', onup);
                            document.addEventListener('touchend', onup);
                        }
                    }
                }
            },
        },
    };
};
