import React from "react";
import Footer from "../Footer";
import {ContentWrap} from "../ContentParts";
import TutorialMain from "../../Tutorial/TutorialMain";
import {TutorialSections, TutorialBoardGrid, TutorialBoardEdit} from "../../Tutorial/";
import "./Dashboard.scss";

export const DashboardTutorial = (props) => {
  const {opt, sid, bid} = props.match.params;

  return (
    <ContentWrap>
      {bid ? <TutorialBoardEdit {...props} /> :
        sid ? <TutorialBoardGrid {...props} />
          : opt ? <TutorialSections {...props} />
            : <TutorialMain {...props} />}
      <Footer {...props}/>
    </ContentWrap>
  );
}
export default DashboardTutorial;

