import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Icon from '../Icon';
import { Modal, Textarea } from 'rlabui';

function ChartCommentItem({ props: { item, current, setCurrent, setCommentImageModal } }) {
    const [edit, setEdit] = useState(false);
    const { date, message, img } = item;
    const data = moment(date).format('DD.MM.YY HH:mm');
    const [editedText, setEditedText] = useState({});
    const [cover, setCover] = useState();
    const [showCover, setShowCover] = useState(false);

    useEffect(() => {
        if (img) {
            const reader = new FileReader();
            reader.readAsDataURL(img);
            reader.onload = () => setCover(reader.result);
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        }
    }, [img]);

    useEffect(() => {
        if (editedText?.value) {
            const index = current.findIndex((e) => e.date === date);
            if (index >= 0) {
                const copy = [...current];
                copy[index].message = editedText.value;
                setCurrent((e) => (e = copy));
            }
        }
    }, [editedText]);

    const removeCover = () => {
        setCover();
        const index = current.findIndex((e) => e.date === date);
        if (index >= 0) {
            const copy = [...current];
            copy[index].img = null;
            setCurrent((e) => (e = copy));
        }
    };

    return (
        <>
            <div
                className="chartComment__item"
                key={data}
            >
                <div className="chartComment__content">
                    <div className="chartComment__top">
                        <div className="chartComment__date">{data}</div>
                    </div>
                    <div className="chartComment__text">
                        {cover && (
                            <div className="chartComment__img">
                                <Icon
                                    name="close"
                                    onClick={removeCover}
                                />
                                <img
                                    src={cover}
                                    alt=""
                                    onClick={() => setCommentImageModal(cover)}
                                />
                            </div>
                        )}
                        {edit ? (
                            <Textarea
                                name="EditComment"
                                value={editedText?.value ? editedText.value : message}
                                onChange={({ target: { value } }) => setEditedText({ date, value })}
                                onKeyDown={({ key }) => key === 'Enter' && setEdit(false)}
                            />
                        ) : (
                            message
                        )}
                    </div>
                </div>
                <div className="chartComment__edit">
                    <Icon
                        name="trash"
                        onClick={() => setCurrent((e) => (e = e.filter((it) => it.date !== date)))}
                    />
                    {edit ? (
                        <Icon
                            name="check"
                            onClick={() => setEdit(false)}
                        />
                    ) : (
                        <Icon
                            name="edit"
                            onClick={() => setEdit(true)}
                        />
                    )}
                </div>
            </div>
            <Modal
                visible={showCover}
                size="fullscreen"
            >
                <Modal.Head modalClose={() => setShowCover(false)}></Modal.Head>
                <Modal.Body className="chartComment__fullcover">
                    <img
                        src={cover}
                        alt=""
                    />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ChartCommentItem;
