import {createContext, useEffect, useRef, useState} from "react";
import socket from '../../socket';
import peer from '../../peer';
import hark from "hark";
import {useDispatch, useSelector} from "react-redux";
import {storageClass, storageOptions, storageOrg} from "../../redux/slices/storage";
import {toggleAudio, toggleScreen, toggleVideo} from "../../actions/toggle";
import {fullName, isAuth} from "../../helpers/auth";
import {toast} from "react-toastify";
import {setUsers as setGlobalUsers} from "../../redux/slices/options";
import {FEATURES} from "../../api/config";
import {streamApi} from "../../api/api";

const VideoContext = createContext(null);
const MAX_VIDEO_STREAMS = 30;
const MAX_VIDEO_NETWORK = 4096;              
const MAX_STREAM_NETWORK = 8912;    

const VideoContextProvider = ({children}) => {
  const dispatch = useDispatch();
  const documentClass = useSelector(storageClass);
  const documentOrg = useSelector(storageOrg);
  const {toggle} = useSelector(state => state);
  const {video:videoOptions, globalVolume, userVolumes} = useSelector(storageOptions);
  const [videoStreams, setVideoStreams] = useState({});
  const [peersId, setPeersId] = useState(peer.id);
  const [peers, setPeers] = useState({});
  const [users, setUsers] = useState([]);
  const [isTalking, setIsTalking] = useState({});
  const [gainStreams, setGainStreams] = useState({});
  const [contextInit, setContextInit] = useState(false);
  const [audioReady, setAudioReady] = useState(false);
  const [hasPeerError, setPeerError] = useState(false);
  const [hasSocketError, setSocketError] = useState(false);
  const [showLocal, setShowLocal] = useState(false);
  const [showRemote, setShowRemote] = useState(0);

  const streamRef = useRef(null);
  const videoRefs = useRef(videoStreams);
  const peersRefs = useRef(peers);
  const usersRefs = useRef(users);
  const streamVideoRef = useRef(null);
  const streamAudioRef = useRef(null);
  const shareScreenRef = useRef(null);
  const remoteScreenRef = useRef(null);
  const remotePeerRef = useRef(null);
  const audioContext = useRef(null);
  const hasMic = useRef(false);
  const hasWebcam = useRef(false);
  const callList = useRef({})
  const volumeRef = useRef(globalVolume ?? 1);

  const hasStreamServer = documentOrg.custom && documentOrg.custom.videoUrl && documentOrg.custom.videoKey
  const maxBitrate = () => Math.floor((toggle.Screen ? MAX_STREAM_NETWORK : MAX_VIDEO_NETWORK)
      / usersRefs.current.length);
  const limitBitrate = () => usersRefs.current.length > 2;

  const resumeAudio = ()=> {
    if (!audioContext.current) return;
    if (audioContext.current.state === "suspended") {
      audioContext.current.resume();
    } else {
      document.removeEventListener("click", resumeAudio);
    }
  };

  const getUserVolume=(peerId)=>{
    const user = Object.values(usersRefs.current).filter(u => u.peerId === peerId)[0]?.user?._id;
    let result = volumeRef.current;
    if (user && userVolumes && userVolumes[user]) {
      result = Math.min(Math.max(result * userVolumes[user], 0), 2);
    }
    return result;
  }

  const setUpVolumeControl = (stream, peerId) => {
    try {
      if (!audioContext.current) return;
      const source = audioContext.current.createMediaStreamSource(stream);
      const gainNode = audioContext.current.createGain();
      gainNode.gain.value = getUserVolume(peerId);
      setGainStreams(streams => ({...streams, [stream.id]: {gainNode}}));
      source.connect(gainNode);
      gainNode.connect(audioContext.current.destination);
      return speechListeners(stream, audioContext.current, gainNode);
    } catch (e) {
      console.log(e)
      return null;
    }
  };

  const speechListeners = (stream, audioCtx, gainNode) => {
    const options = {threshold: -55, audioContext: audioCtx};
    const speechEvents = hark(stream, options, gainNode);

    speechEvents.on("speaking", () => {
      setIsTalking(streams => ({...streams, [stream.id]: true}));
    });

    speechEvents.on("stopped_speaking", () => {
      setIsTalking(streams => ({...streams, [stream.id]: false}));
    });

    speechEvents.on("volume_change", () => {
      if (audioCtx.state === "suspended") audioCtx.resume();
    });

    return speechEvents;
  };

  function setAudio(sdp) {
    return sdp.replace("a=fmtp:111 minptime=10;useinbandfec=1","a=fmtp:111 ptime=5;useinbandfec=1;stereo=0;maxplaybackrate=48000;maxaveragebitrate=128000;sprop-stereo=0");
  }

  function setBandwidth(sdp, bandwidth) {
    const isFirefox = typeof InstallTrigger !== 'undefined';
    let modifier = 'AS';
    if (isFirefox) {
      bandwidth = (bandwidth >>> 0) * 1000;
      modifier = 'TIAS';
    }
    //console.log(modifier + "---" + bandwidth);
    if (sdp.indexOf('b=' + modifier + ':') === -1) {
      sdp = sdp.replace(/c=IN (.*)\r\n/, 'c=IN $1\r\nb=' + modifier + ':' + bandwidth + '\r\n');
    } else {
      sdp = sdp.replace(new RegExp('b=' + modifier + ':.*\r\n'), 'b=' + modifier + ':' + bandwidth + '\r\n');
    }
    return sdp;
  }

  const setUserConnection = (userId, stream) => {
    const options = {
      "constraints": {
        "mandatory": {
          "OfferToReceiveAudio": true,
          "OfferToReceiveVideo": true
        },
        offerToReceiveAudio: 1,
        offerToReceiveVideo: 1,
      },
      "sdpTransform": (sdp) => {
        if (limitBitrate()) {
          sdp = setBandwidth(sdp, maxBitrate());
        }
        return setAudio(sdp);
      }
    };
    const call = peer.call(userId, stream, options);
    if (!call) {
      return;
    }
    call.on("stream", newUserStream => {
      if(!callList.current[call.peer]) {
        const harkListeners = setUpVolumeControl(newUserStream, call.peer);
        callList.current[call.peer] = call;
        addVideo(userId, newUserStream, call, harkListeners);
        for(const connection of call.peerConnection?.getSenders()) {
          if (connection.track.kind === "video") {
            changeBitRate(connection);
          }
        }
      }
      else {
        delete callList.current[call.peer];
      }
    });
    call.on("close", () => {
      removeVideo(call.peer);
    });
    call.on("error", (e) => {
      console.log(e);
    });
  };

  const setPeerListeners = () => {
    peer.on("call", call => {
      call.answer(streamRef.current);
      call.on("stream", userVideoStream => {
        if(!callList.current[call.peer]) {
          const harkListeners = setUpVolumeControl(userVideoStream, call.peer);
          addVideo(call.peer, userVideoStream, call, harkListeners);
          callList.current[call.peer] = call;
          for(const connection of call.peerConnection?.getSenders()) {
            if (connection.track.kind === "video") {
              changeBitRate(connection);
            }
          }
        }
        else {
          delete callList.current[call.peer];
        }
      });
      call.on("close", () => {
        removeVideo(call.peer);
      });
      call.on("error", (e) => {
        console.log(e);
      });
    });
  };

  const initStream =()=> {
    const currentStream = fakeAudio();
    const video = fakeVideo();
    currentStream.addTrack(video);
    streamRef.current = currentStream;
  }

  const initVideo =(replace) => {
    if (!navigator.mediaDevices) {
      toast.error("Нет доступа к устройствам");
      hasWebcam.current = false;
      dispatch(toggleVideo(false));
      return;
    }

    const video = Object.assign({}, videoOptions?.cam??{}, videoOptions?.size??{});
    navigator.mediaDevices.getUserMedia({video})
    .then(currentStream => {
      currentStream.getVideoTracks()[0].enabled = toggle.video;
      hasWebcam.current = video;
      streamVideoRef.current = currentStream;
      if (replace) replaceVideoTrack(streamRef.current, currentStream.getVideoTracks()[0], peer);
      socket.emit("BE-toggle-stream", {roomId: documentClass._id,
        options: {video: toggle.video, audio: toggle.audio, screen: toggle.screen}
      });
    }).catch(e => {
      toast.error("Ошибка камеры");
      console.log(e.message);
      hasWebcam.current = false;
      dispatch(toggleVideo(false));
    });
  };

  const createLoopback= (stream)=> {
    if (streamAudioRef.current) return;
    streamAudioRef.current = stream;
    const source = audioContext.current.createMediaStreamSource(stream);
    const gainNode = audioContext.current.createGain();
    source.connect(gainNode);
  }

  const initAudio =() => {
    if (!navigator.mediaDevices) {
      toast.error("Нет доступа к устройствам");
      hasMic.current = false;
      dispatch(toggleAudio(false));
      return;
    }

    navigator.mediaDevices.getUserMedia({audio: (videoOptions?.mic??true)})
    .then(currentStream => {
      createLoopback(currentStream);
      currentStream.getAudioTracks()[0].enabled = toggle.audio;
      hasMic.current = videoOptions?.mic??true;
      replaceAudioTrack(streamRef.current, currentStream.getAudioTracks()[0], peer);
      socket.emit("BE-toggle-stream", {roomId: documentClass._id,
        options: {video: toggle.video, audio: toggle.audio, screen: toggle.screen}
      });
    }).catch(e => {
      toast.error("Ошибка микрофона");
      console.log(e.message);
      hasMic.current = false;
      dispatch(toggleAudio(false));
    });
  };

  const createStreamPeer = () => {
    remotePeerRef.current?.close();
    remotePeerRef.current = new RTCPeerConnection({iceServers: [{urls: "stun:stun.stunprotocol.org"}]})
  };

  function createBroadcast(roomId) {
    createStreamPeer();
    remotePeerRef.current.onnegotiationneeded = () => handleNegotiationNeededEvent(roomId,true);
    const dc = remotePeerRef.current.createDataChannel("", { negotiated: true, id: 0 });
    dc.onclose = () => {
      remotePeerRef.current = null;
      dispatch(toggleScreen(false));
      setShowLocal(false);
    };
    dc.onopen = () => {
      socket.emit("BE-start-broadcast", { roomId: documentClass._id, owner: isAuth()});
    };
    remotePeerRef.current.oniceconnectionstatechange = () => {
      if (["disconnected"].includes(remotePeerRef.current?.iceConnectionState)) {
        remotePeerRef.current = null;
        dispatch(toggleScreen(false));
        setShowLocal(false);
      }
    };
    setShowLocal(true);
  }

  const handleTrackEvent = (e) => {
    remoteScreenRef.current = e.streams[0];
    setShowRemote(s => s + 1);
  };

  function createListener(roomId) {
    createStreamPeer();
    remotePeerRef.current.addTransceiver("video", {direction: "recvonly"});
    remotePeerRef.current.addTransceiver("audio", {direction: "recvonly"});
    remotePeerRef.current.onnegotiationneeded = () => handleNegotiationNeededEvent(roomId, false);
    remotePeerRef.current.ontrack = handleTrackEvent;
    const dc = remotePeerRef.current.createDataChannel("");
    dc.onclose = () => {
      remotePeerRef.current = null;
      setShowRemote(0);
    }
    remotePeerRef.current.oniceconnectionstatechange = () => {
      if (["disconnected"].includes(remotePeerRef.current?.iceConnectionState)) {
        remotePeerRef.current = null;
        setShowRemote(0);
      }
    };
  }

  async function handleNegotiationNeededEvent(roomId, isBroadcast) {
    if (!remotePeerRef.current) return;

    const offer = await remotePeerRef.current.createOffer();
    await remotePeerRef.current.setLocalDescription(offer);
    const payload = {sdp: remotePeerRef.current.localDescription};

    try {
      const data = isBroadcast ? await streamApi.broadcast(roomId, payload, documentOrg.custom)
        : await streamApi.consumer(roomId, payload, documentOrg.custom);
      if (["disconnected", "closed"].includes(remotePeerRef.current?.iceConnectionState)) {
        dispatch(toggleScreen(false));
        remotePeerRef.current.close();
        remotePeerRef.current = null;
        isBroadcast ? setShowLocal(false) : setShowRemote(0);
      } else {
        const desc = new RTCSessionDescription(data.sdp);
        remotePeerRef.current.setRemoteDescription(desc).catch(e => console.log(e));
      }
    } catch (err) {
      toast.error("Видео сервер: " + (err.response?.data?.errors ?? err));
      dispatch(toggleScreen(false));
    }
  }


  useEffect(() => {
    if (videoOptions) {
      if (toggle.video) {
        const video = Object.assign({}, videoOptions?.cam??{}, videoOptions?.size??{});
        if (JSON.stringify(hasWebcam.current) !== JSON.stringify(video)) {
          initVideo(true);
        }
      }
      if (toggle.audio && hasMic.current !== videoOptions.mic) {
        initAudio();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoOptions]);

  useEffect(() => {
    if(!peersId) {
      dispatch(toggleVideo(false));
      return;
    }

    if (toggle.video) {
      if (toggle.screen) {
        toast.warn("Трансляция экрана уже ведется");
        dispatch(toggleVideo(false));
        return;
      }

      if (isAuth().role < 1 && usersRefs.current.filter(u => u.user.options.screen || u.user.options.video).length >= MAX_VIDEO_STREAMS) {
        toast.warn("Максимальное ограничение потоков: " + MAX_VIDEO_STREAMS);
        dispatch(toggleVideo(false));
        return;
      }

      initVideo(true);
      return;
    }

    if (streamVideoRef.current) {
        streamVideoRef.current.getTracks()[0].stop();
        streamVideoRef.current = null;
        replaceVideoTrack(streamRef.current, fakeVideo(), peer);

        socket.emit("BE-toggle-stream", {
            roomId: documentClass._id,
            options: {video: false, audio: toggle.audio, screen: false}
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peersId, toggle.video]);

  useEffect(() => {
    if(!peersId) {
      dispatch(toggleAudio(false));
      return;
    }

    if (toggle.audio) {
      initAudio();
      return;
    }

    streamAudioRef.current?.getTracks()[0].stop();
    streamAudioRef.current = null;
    replaceAudioTrack(streamRef.current, fakeAudio().getAudioTracks()[0], peer);

    socket.emit("BE-toggle-stream", { roomId:documentClass._id,
      options:{video: toggle.video, audio: false, screen: toggle.screen} });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peersId, toggle.audio]);

  useEffect(() => {
    if(!peersId) {
      dispatch(toggleScreen(false));
      return;
    }

    if (toggle.screen === (shareScreenRef.current!==null)) {
      return;
    }

    if (toggle.screen) {
      if (toggle.sensor){
        toast.warn("Идет стрим датчиков.");
        dispatch(toggleScreen(false));
        return;
      }

      if (!navigator.mediaDevices) {
        toast.error("Нет доступа к устройствам");
        dispatch(toggleScreen(false));
        return;
      }

      if (isAuth().role < 1 && usersRefs.current.some(u => u.user.options.screen)) {
        toast.warn("Трансляция экрана уже ведется");
        dispatch(toggleScreen(false));
        return;
      }

      socket.emit("BE-stop-broadcast", { roomId: documentClass._id});
      dispatch(toggleVideo(false));

      navigator.mediaDevices.getDisplayMedia({ video: true, audio: true })
      .then(currentStream => {
        shareScreenRef.current = currentStream;
        currentStream.getVideoTracks()[0].addEventListener("ended", () => {
          dispatch(toggleScreen(false));
        });

        if (hasStreamServer) {
          createBroadcast(documentClass._id);
          currentStream.getTracks().forEach(track => remotePeerRef.current.addTrack(track, currentStream));
        } else {
          replaceVideoTrack(streamRef.current, currentStream.getVideoTracks()[0], peer);
          setShowLocal(true);
          socket.emit("BE-start-broadcast", { roomId: documentClass._id, owner: isAuth()});
        }

        socket.emit("BE-toggle-stream", { roomId: documentClass._id,
          options: {video: false, audio: toggle.audio, screen: true}
        });
      })
      .catch(e => {
        toast.error("Ошибка захвата экрана");
        console.log(e.message);
        shareScreenRef.current = null;
        remoteScreenRef.current = null;
        remotePeerRef.current?.close();
        remotePeerRef.current = null;
        setShowLocal(false);

        dispatch(toggleScreen(false));
        socket.emit("BE-toggle-stream", { roomId: documentClass._id,
          options: {video: toggle.video, audio: toggle.audio, screen: false}
        });
      });
    } else {
      if (!hasStreamServer) {
        if (streamVideoRef.current) {
          replaceVideoTrack(streamRef.current, streamVideoRef.current.getVideoTracks()[0], peer);
        } else {
          replaceVideoTrack(streamRef.current, fakeVideo(), peer);
        }
      }
      shareScreenRef.current?.getTracks()[0].stop();
      shareScreenRef.current = null;
      remotePeerRef.current?.close();
      remotePeerRef.current = null;
      setShowLocal(false);

      socket.emit("BE-toggle-stream", {
        roomId: documentClass._id,
        peerId: peer.id,
        options: {video: toggle.video, audio: toggle.audio, screen: toggle.screen}
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peersId, toggle.screen]);

  useEffect(() => {
    socket.on("FE-user-join", (users) => {
      setUsers(users);
    });

    socket.on("FE-start-video", ({peerId, users}) => {
      setUsers(users);
      setUserConnection(peerId, streamRef.current);
      changeBandwitdh();
    });

    socket.on("FE-start-broadcast", ({owner ,roomId}) => {
      toast.info(fullName(owner) + " начал стрим экрана");
      if (hasStreamServer) {
        createListener(roomId);
      } else {

      }
    });

    socket.on("FE-stop-broadcast", () => {
      dispatch(toggleScreen(false));
    });

    socket.on("FE-user-leave", ({ userId, peerId }) => {
      if (peerId) {
        peersRefs.current[peerId]?.call.close();
        setPeers(({[peerId]: t, ...p}) => p);
      }
      setUsers(p => p.filter(u => u.userId !== userId ))
      changeBandwitdh();
    });

    socket.on("FE-toggle-stream", ({ peerId, options }) => {
      if (videoRefs.current[peerId])
      setVideoStreams(videos => ({...videos, [peerId]:
            {...videos[peerId], showStream:(options.video)}}));
      setUsers(us => us.map(u=>u.peerId === peerId?{...u, user:{...u.user, options: options}}:u));
      if (options.screen && videoRefs.current[peerId]) {
        remoteScreenRef.current = videoRefs.current[peerId].streamID;
        setShowRemote(peerId);
      } else if (usersRefs.current.some(u => u.peerId === peerId && u.user.options.screen)) {
        remoteScreenRef.current = null;
        setShowRemote(0);
      }
    });

    socket.on("FE-room-options", ({roomId, options}) => {
      if (options?.broadcastOwner) {
        if (options.broadcastOwner._id !== isAuth()._id) {
          if (hasStreamServer) {
            streamApi.get(roomId, documentOrg.custom).then(res => {
              if (res.data) {
                createListener(roomId);
              }
            }).catch(err => {
              console.log(err.response.data.errors);
            });
          }
        } else {
          dispatch(toggleScreen(false));
        }
      } else {
          dispatch(toggleScreen(false));
          remotePeerRef.current?.close();
          remotePeerRef.current = null;
          setShowRemote(0);
      }
    });

    return () => {
      socket.off("FE-user-join");
      socket.off("FE-start-video");
      socket.off("FE-start-broadcast");
      socket.off("FE-stop-broadcast");
      socket.off("FE-receive-call");
      socket.off("FE-call-accepted");
      socket.off("FE-user-leave");
      socket.off("FE-toggle-stream");
      socket.off("FE-room-options");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (contextInit && audioReady && peersId && documentClass) {
      socket.emit("BE-start-video", {roomId: documentClass._id, peerId:peersId, user: isAuth()});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextInit, audioReady, peersId, documentClass]);

  useEffect(() => {videoRefs.current = videoStreams;},[videoStreams]);
  useEffect(() => {peersRefs.current = peers;},[peers]);
  useEffect(() => {
    usersRefs.current = users;
    dispatch(setGlobalUsers(Object.values(Object.fromEntries(users.map(u=>[u.user._id, u.user])))));
  },[dispatch, users]);

  useEffect(() => {
      if (globalVolume !== undefined) {
        volumeRef.current = globalVolume;
        for (const [peerId, vStream] of Object.entries(videoStreams)) {
          gainStreams[vStream.streamID?.id].gainNode.gain.value = getUserVolume(peerId);
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[globalVolume]);

  useEffect(() => {
    if (!FEATURES["video"].role.includes(isAuth().role)) return;

    initStream();
    setPeerListeners();
    setContextInit(true);
    const audioStateChanged=()=>{
      setAudioReady(true);
      audioContext.current.removeEventListener("statechange", audioStateChanged);
      toast.dismiss();
    }
    if (audioContext.current.state === 'suspended'){
      audioContext.current.addEventListener("statechange", audioStateChanged);
      document.addEventListener("click", resumeAudio);
      toast.warn("Браузер в фоне кликните мышкой в окне", {autoClose:false, closeButton:false})
    } else {
      setAudioReady(true);
    }

    peer.on("open", id => {
      setPeersId(id);
      setPeerError(false);
    });

    peer.on("error", (e) => {
      setPeerError(e);
      if(peer.disconnected && !peer.destroyed) peer.reconnect();
    });


    return () => {
      hasWebcam.current = false;
      hasMic.current = false;

      streamRef.current?.getTracks().forEach(track => track.stop());
      streamRef.current = null;
      streamVideoRef.current?.getTracks().forEach(track => track.stop());
      streamVideoRef.current = null;
      shareScreenRef.current?.getTracks().forEach(track => track.stop());
      shareScreenRef.current = null;
      streamAudioRef.current?.getTracks().forEach(track => track.stop());
      streamAudioRef.current = null;

      for (const video of Object.values(videoRefs.current)) { video.hark?.stop();}
      setVideoStreams({});
      setPeers({});
      setIsTalking({});
      setGainStreams({});
      setPeerError(false);
      setSocketError(false);
      audioContext.current?.removeEventListener("statechange", audioStateChanged);
      document.removeEventListener("click", resumeAudio);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addVideo = (id, stream, call, harkListeners) => {
    const user = Object.values(usersRefs.current).filter(u=>u.peerId === id)[0];
    if (!user) return;

    videoRefs.current[id]?.hark.stop();
    setVideoStreams(videoStreams => ({
      ...videoStreams,
      [id]: {
        streamID: stream,
        user: user.user,
        hark: harkListeners,
        showStream: user.user.options?.video,
      }
    }));
    setPeers(peers => ({...peers, [id]: {call}}));
    if (!hasStreamServer && user.user.options?.screen) {
      remoteScreenRef.current = stream;
      setShowRemote(id);
    }
  };

  const removeVideo = (peerId) => {
    for(const [id, video] of Object.entries(videoRefs.current)) {
      if(!video.streamID.active || id === peerId) {
        video.hark.stop();
        setGainStreams(({[video.streamID.id]: t, ...p})=>p)
        setVideoStreams(({[id]: t, ...p})=>p)
      }
    }
  };

  const replaceVideoTrack = (stream, video, peer) => {
    if(stream?.getVideoTracks().length) {
      stream.removeTrack(stream.getVideoTracks()[0]);
    }
    stream?.addTrack(video);
    if (peer) {
      for (const [, value] of peer._connections) {
        if (value.length) {
          for (const connection of value[0].peerConnection?.getSenders()) {
            if (connection.track === null || connection.track.kind === "video") {
              connection.replaceTrack(video);
              changeBitRate(connection);
            }
          }
        }
      }
    }
  };

  const replaceAudioTrack = (stream, audio, peer) => {
    if(stream?.getAudioTracks().length) {
      stream.getAudioTracks()[0].stop();
      stream.removeTrack(stream.getAudioTracks()[0]);
    }
    stream?.addTrack(audio);
    for(const [, value] of peer._connections) {
      if(value.length) {
        for(const connection of value[0].peerConnection?.getSenders()) {
          if(connection.track === null || connection.track.kind === "audio") {
            connection.replaceTrack(audio);
          }
        }
      }
    }
  };

  function changeBitRate(connection) {
    const parameters = connection.getParameters();
    //parameters.degradationPreference = "maintain-framerate";
    if (!parameters.encodings || !parameters.encodings[0]) {
      return;
    }

    if (limitBitrate()) {
      parameters.encodings[0].maxBitrate = maxBitrate(true) * 1000;
    } else {
      delete parameters.encodings[0].maxBitrate;
    }
    //console.log(parameters.encodings[0].maxBitrate);
    connection.setParameters(parameters);
  }

  const changeBandwitdh = () => {
    for(const [, value] of peer._connections) {
      if(value.length) {
        for(const connection of value[0].peerConnection?.getSenders()) {
          if(connection.track.kind === "video") {
            changeBitRate(connection);
          }
        }
      }
    }
  };

  const fakeVideo = ({width = 320, height = 240} = {}) => {
    const canvas = Object.assign(document.createElement("canvas"), {width, height});
    canvas.getContext("2d").fillRect(0, 0, width, height);
    const stream = canvas.captureStream();
    return Object.assign(stream.getVideoTracks()[0], {enabled: false});
  };

  const fakeAudio = () => {
    if (!audioContext.current) {
      audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
    }
    const fakeAudio = audioContext.current.createMediaStreamDestination();
    return new MediaStream(fakeAudio.stream);
  }

  return (
    <VideoContext.Provider value={{
      peer,
      stream: streamRef.current,
      remoteStream: remoteScreenRef.current,
      localStream: shareScreenRef.current,
      globalVolume: volumeRef,
      showLocal,
      showRemote,
      hasStreamServer,
      videoStreams,
      setVideoStreams,
      isTalking,
      gainStreams,
      setGainStreams,
      users,
      hasPeerError,
      hasSocketError,
    }}>
      {children}
    </VideoContext.Provider>
  );
};

export {VideoContext, VideoContextProvider};