import { setPlay } from '../../actions/app';
import { setTimer, setTimerSettings, setStopwatch } from '../../actions/timer';

export const mapStateToProps = (nstate) => {
    return {
        played: nstate.napp.played,
        paused: nstate.napp.paused,
        timer: nstate.ntimer,
        response: nstate.nsensor.response,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setPlay: (bool) => dispatch(setPlay(bool)),
    setTimer: (miliseconds) => dispatch(setTimer(miliseconds)),
    setStopwatch: (miliseconds) => dispatch(setStopwatch(miliseconds)),
    setTimerSettings: (format) => dispatch(setTimerSettings(format)),
});
