import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from './redux';
import { btnDispatchToProps, btnStateToProps } from './reduxBtn';
import Connect from './Connection';
import ConnectButtons from './ConnectionButtons';

const Connection = connect(mapStateToProps, mapDispatchToProps)(Connect);
const ConnectionButtons = connect(btnStateToProps, btnDispatchToProps)(ConnectButtons);

export { Connection, ConnectionButtons };
