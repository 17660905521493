//ОБРАЗЕЦ ---- https://www.geogebra.org/m/ex7UGVQM
import React, {useState, useEffect, useRef} from "react";
import {CALC_MODEL, SCALEPOS, SAVE_DATA_TYPE, GraphicOpts, getSvgWithHeightWidth, 
	interferenceProcessBothSlots, interferenceProcessOneSlot, getMinMax,
	getScaleData, getScaleValues, getStrokes, getScaleTitle,
	getScreenAreaPictureById, getGrid, printDiv, getExportCB,
	getColoredInnerBox, getRGBFromArrayByWaveLength, getInnerBorder, getCurve, getHeaderBox, 
	getInputRange, getSvgRect, getSvgMouseCrossCursor, getModeColorOpts, 
	addCalcModelInfo, getCalcModelInfoById, canStartAction, completeAction} from "./cm_utils";
import { saveExcel } from './excel_utils';
import {getRoundValue} from '../ui/utils/gen_utils';
import {isAuth} from "../../helpers/auth";
import { Icon } from '../ui';
import {storageClass} from "../../redux/slices/storage";
import {useSelector, useDispatch} from "react-redux";
import {reloadOption} from "../../redux/slices/storage";
import SaveXlsFileConfirmDialog from "../ui/ModalDialogs/SaveXlsFileConfirmDialog";
import {toast} from "react-toastify";
import "./CalcModel.scss";
import { Content, ContentBody, ContentFooter } from "../template/ContentParts";

const WAVE_LENGTH_RANGE = [380, 740, 494]; //min, max, default
const SLOT_WIDTH_RANGE = [1, 30, 20];
const SLOT_SPACING_RANGE = [10, 100, 40];

const Interference = ({ history, match, isInnerCM, isLightMode }) => {
	const [waveLength, setWaveLength] = useState(WAVE_LENGTH_RANGE[2]); //сила тока (380нм - 740нм)
	const [slotWidth, setSlotWidth] = useState(Number(SLOT_WIDTH_RANGE[2])); //Период решетки:  1мкм - 30мкм.
	const [slotSpacing, setSlotSpacing] = useState(SLOT_SPACING_RANGE[2]); //Количество щелей: 10шт-100шт.
	const [isLeftSlotOn, setIsLeftSlotOn] = useState(true);
	const [isRightSlotOn, setIsRightSlotOn] = useState(true);
	const [currExpData, setCurrExpData] = useState(undefined);
	const [scaleRangeData, setScaleRangeData] = useState(undefined);
	const [svgWidth, setSvgWidth] = useState(undefined);
	const [svg1Height, setSvg1Height] = useState(undefined);
	const [svg2Height, setSvg2Height] = useState(undefined);
	const [cursorX, setCursorX] = useState(-1);
	const [cursorY, setCursorY] = useState(-1);
	const [experimentResults, setExperimentResults] = useState([]);
	const [svgArr2, setSvgArr2] = useState(undefined);
	const [svg, setSvg] = useState(undefined);
	const [svg2, setSvg2] = useState(undefined);
	const [resize, setResize] = useState(0);
	const [showConfirmSaveXlsDlg, setShowConfirmSaveXlsDlg] = useState(false);
	const [makePrint, setMakePrint] = useState(false);
	const [makeXls, setMakeXls] = useState(false);
	const [isLightModeChanged, setIsLightModeChanged] = useState(false);
	const [saveDataType, setSaveDataType] = useState(SAVE_DATA_TYPE.XLS);

    const documentClass = useSelector(storageClass);
	const dispatch = useDispatch();
	const refMainBox = useRef();
	const refMainSvg1 = useRef();
	const refMainSvg2 = useRef();
	const waveLengthRef = useRef();
	const slotWidthRef = useRef();
	const slotSpacingRef = useRef();

	const dfrOpts = GraphicOpts;

	useEffect(() => {
		if (match.params.opt) {
			getCalcModelInfoById(match.params.opt).then((data) => {
				const cmInfoId = data[0].options;
				setWaveLength(cmInfoId.waveLength);
				setSlotWidth(cmInfoId.slotWidth);
				setSlotSpacing(cmInfoId.slotSpacing);
				setIsLeftSlotOn(cmInfoId.isLeftSlotOn);
				setIsRightSlotOn(cmInfoId.isRightSlotOn);
			});
		}
	}, [match.params.opt]);

	useEffect(() => {
		const handleResize = e => setResize(e[0].contentBoxSize[0].inlineSize);
		const observer = new ResizeObserver(handleResize);
		observer.observe(refMainBox.current);
		return () => {
			observer.disconnect();
		};
	}, []);

	useEffect(() => {
		//set svg box size:
		const innerMainR = document.getElementById('idInnerMainBox')?.getBoundingClientRect();
		const optsR = document.getElementById('idOpts')?.getBoundingClientRect();
		if (innerMainR.height <= optsR.height) return;
		const svgR = document.getElementById('mainSvg')?.getBoundingClientRect();
		const width = (innerMainR.width - (svgR.left - innerMainR.left));
		const height = innerMainR.height - optsR.height;

		const heightRatio = 0.12;
		setSvgWidth(width);
		setSvg1Height(height * heightRatio);
		setSvg2Height(height * (1 - heightRatio));
	}, [resize]);


	const handleMouseClickEvent = (event, _scaleRangeData, _cursorX, _cursorY) => {
		const rect = getSvgRect('mainSvg2');
		if (!rect || !_scaleRangeData) return;
	
		if (event.clientX < rect.left + _scaleRangeData.left || event.clientX > rect.right - _scaleRangeData.right || 
			event.clientY < rect.top + _scaleRangeData.top || event.clientY > rect.bottom - _scaleRangeData.bottom) {
				return;
		}
		if (_cursorX !== event.clientX || _cursorY !== event.clientY) {
			setCursorX(event.clientX);
			setCursorY(event.clientY);
		}
	};

	const getCoordInfo = (infoType, _svgSize, _scaleRangeData, _cursor) => {
		if (_cursor === -1 || !_scaleRangeData) return 0;
		const rect = getSvgRect('mainSvg2');
		if (infoType === 'horiz') {
			const range = _scaleRangeData.horizRange;
			const innerBoxSize = _svgSize - (_scaleRangeData.left + _scaleRangeData.right);
			const coef = (range[1] - range[0]) / innerBoxSize;
			const diff = rect.left + _scaleRangeData.left;
			return range[0] + (_cursor - diff) * coef;
			}
		else {
			const range = _scaleRangeData.vertRange;
			const innerBoxSize = _svgSize - (_scaleRangeData.top + _scaleRangeData.bottom);
			const coef = (range[1] - range[0]) / innerBoxSize;
			const diff = rect.top + _scaleRangeData.top;
			return range[1] - (_cursor - diff) * coef;
		}
	};

	useEffect(() => {
		const svgBox = document.getElementById('mainSvg2');
		svgBox?.addEventListener("click", e => handleMouseClickEvent(e, scaleRangeData, cursorX, cursorY));
		return () => {
			const elem = document.getElementById('mainSvg2');
			elem?.removeEventListener("click", handleMouseClickEvent);
		};
	}, [cursorX, cursorY, scaleRangeData]);

	useEffect(() => {
		let [interferenceLines, graphData] = [[], []];
		if (isLeftSlotOn && isRightSlotOn)
			[interferenceLines, graphData] = interferenceProcessBothSlots(waveLength, slotSpacing);
		else if (isLeftSlotOn || isRightSlotOn)
			[interferenceLines, graphData] = interferenceProcessOneSlot(waveLength, slotWidth, 
				isLeftSlotOn ? slotSpacing : -slotSpacing);

		const expData = {
			waveLength: waveLength,	
			slotWidth: slotWidth,
			slotSpacing: slotSpacing,
			graphData: graphData,
			interferenceLines: interferenceLines,
		}; 

		setCurrExpData(expData);
	}, [waveLength, slotSpacing, slotWidth, isLeftSlotOn, isRightSlotOn]);

	useEffect(() => {
		if (!svg || !svg2) return;

		const expData = {
			waveLength: waveLength,
			slotWidth: slotWidth,
			slotSpacing: slotSpacing,
			graphData: currExpData.graphData,
			interferenceLines: currExpData.interferenceLines,
			svg: svg,
			svg2: svg2,
		};
		const exps = experimentResults.map(item => item);
		exps.push(expData);
		setExperimentResults(exps);
		setSvg(undefined);
		setSvg2(undefined);
	
		toast.success("Текущий эксперимент сохранен в памяти."); 
	}, [experimentResults, slotSpacing, waveLength, slotWidth, svg, svg2, currExpData]);

	const getCurrentSlotWidthGraphics = () => {
		const getSvg = (obj) => {return getSvgWithHeightWidth(svg1Height, svgWidth, obj, 'svg1');
		};
	
		if (!currExpData) return;

		const width = svgWidth - dfrOpts.gapHoriz; // - dfrOpts.gapRight;
		const height = svg1Height - dfrOpts.gapTop;

		const posList = currExpData.graphData.map(item => item[0]);
		const [posMin, posMax] = getMinMax(posList);
		
		//1 - boxes:
		const innerBox = getColoredInnerBox(dfrOpts.gapHoriz, dfrOpts.gapTop, width, height, 
			dfrOpts.diffractionInnerBoxColor, dfrOpts.diffractionInnerBoxBorderColor, dfrOpts.diffractionInnerBoxOpacity, "rect02");
			  
		//2 - vertical lines:
		const showLinesList = [];
		const color = getRGBFromArrayByWaveLength(waveLength); //getRGBByWaveLength(waveLength);

		for (let i = 0; i < currExpData.interferenceLines.length; i ++) {
			const opacity = i/100.;
			const lineArr = currExpData.interferenceLines[i];
			if (lineArr.length > 0) {
				const posList = lineArr.map(item => dfrOpts.gapHoriz + width * (item - posMin) / (posMax - posMin));
				const showPosList = posList.map(item => 'M ' + item + ' ' + dfrOpts.gapTop + ' L ' + item + ' ' + (dfrOpts.gapTop+height) + ' ');
				const showLines = <path key={"lines01"+i} d={showPosList.join(' ')} 
					fill="none" stroke={color} strokeWidth="1" opacity={opacity} />;
				showLinesList.push(showLines);
			}
		}

		return getSvg([innerBox, ...showLinesList]);
	};
	
	useEffect(() => {
		const getExperimentResultGraphics = (_currExpData, _svg2Height, _svgWidth, _waveLength, _cursorX, _cursorY, _dfrOpts) => {
			if (!_currExpData) return;
			const width = _svgWidth - _dfrOpts.gapHoriz;
			const height = _svg2Height - _dfrOpts.gapTop - _dfrOpts.gapBottom;
		  
			const posList = _currExpData.graphData.map(item => item[0]);
			const valueList = _currExpData.graphData.map(item => item[1]);
		  
			const [posMin, posMax] = posList.length > 0 ? getMinMax(posList) : [0, 1];
			const [valueMin, valueMax] = [0, 100];
			const rgbColor = getRGBFromArrayByWaveLength(_waveLength);

			const _scaleRangeData = {
				horizRange: [posMin, posMax],
				vertRange: [valueMin, valueMax],
				left: _dfrOpts.gapHoriz, 	//contains left gap
				top: _dfrOpts.gapTop, 		//contains top gap
				right: 0, 					//contains right gap
				bottom: _dfrOpts.gapBottom, //contains bottom gap
			};
			setScaleRangeData(_scaleRangeData);
		  
			//0 - background:
			const bkndColor = getModeColorOpts(isLightMode).calcModelBknd;
			const background = getColoredInnerBox(_dfrOpts.gapHoriz, _dfrOpts.gapTop, width, height, bkndColor, 
				bkndColor, _dfrOpts.backgroundOpacity, "bknd01");

			//1 - boxes:
			const innerBox = getInnerBorder(_dfrOpts.gapHoriz, _dfrOpts.gapTop, width, height, _dfrOpts.innerBoxColor, "rect");
		  
			//2 - axis titles:
			const borderTitleLEFT = _dfrOpts.gapHoriz * 0.2;
			const bottomAxisTitle = getScaleTitle(SCALEPOS.horiz, _svgWidth * 0.45, _svg2Height-8, 
			  "Расстояние, мм", _dfrOpts.timeColor, "bottomkey1");
			const leftAxisTitle = getScaleTitle(SCALEPOS.left, borderTitleLEFT + 2, _svg2Height * 0.6, 
			  "Интенсивность света, Ед.", _dfrOpts.timeColor, "leftkey1");
		  
			//3 - scales:
			const posScaleArr = getScaleData(SCALEPOS.horiz, SCALEPOS.left, _dfrOpts.horizStrokeNUM, posMin, posMax, _dfrOpts.gapHoriz, 
			  width, _svg2Height - _dfrOpts.gapBottom, _dfrOpts.scaleLargeSize, _dfrOpts.scaleSmallSize, _dfrOpts.smallStrokeNum);
			const posStrokes = getStrokes(posScaleArr, _dfrOpts.timeColor, 1, 0.9, "vskey");
			const posScaleValues = getScaleValues(posScaleArr, SCALEPOS.horiz, SCALEPOS.left, _dfrOpts.timeColor, "horiz1");
		  
			const valueScaleArr = getScaleData(SCALEPOS.vert, SCALEPOS.left, _dfrOpts.vertStrokeNUM, valueMin, valueMax, 
			  _dfrOpts.gapTop, height, _dfrOpts.gapHoriz, _dfrOpts.scaleLargeSize, _dfrOpts.scaleSmallSize, _dfrOpts.smallStrokeNum);
			const valueStrokes = getStrokes(valueScaleArr, _dfrOpts.timeColor, 1, 0.9, "tkey");

			const valueScaleValues = getScaleValues(valueScaleArr, SCALEPOS.vert, SCALEPOS.left, _dfrOpts.timeColor, "vert1");
		  
			//4 - grid:
			const hGrid = getGrid(posScaleArr, SCALEPOS.horiz, _dfrOpts.gapHoriz, _dfrOpts.gapTop, width, height, getModeColorOpts(isLightMode).gridColor, _dfrOpts.gridWidth, _dfrOpts.gridOpacity);
			const vGrid = getGrid(valueScaleArr, SCALEPOS.vert, _dfrOpts.gapHoriz, _dfrOpts.gapTop, width, height, getModeColorOpts(isLightMode).gridColor, _dfrOpts.gridWidth, _dfrOpts.gridOpacity);
		  
			//5 - experiment lines:
			const showPosList = posList.map(item => _dfrOpts.gapHoriz + width * (Number(item) - Number(posMin)) / (posMax - posMin));
			const showValueList = valueList.map(item => _dfrOpts.gapTop + height * (Number(valueMax) - Number(item)) / (valueMax - valueMin));
			const expLine = getCurve(showPosList, showValueList, rgbColor, "2", "curve03", 0.5);

			//6 - legend:
			const legendLEFT = 2 * _dfrOpts.gapHoriz;
			const legendTOP = _dfrOpts.gapTop + 40;
			const LegendLineWIDTH = 16;
			const vert = 15;
			const shiftVert = legendTOP + vert;
			const xValue = getRoundValue(getCoordInfo('horiz', svgWidth, _scaleRangeData, _cursorX), 2);
			const yValue = getRoundValue(getCoordInfo('vert', svg2Height, _scaleRangeData, _cursorY), 2);
	
			const clicked = _cursorX !== -1 && _cursorY !== -1;

			const legendBackground = clicked ? getColoredInnerBox(legendLEFT-10, legendTOP - 10, 7.2 * LegendLineWIDTH, 2.5 * vert, 
				getModeColorOpts(isLightMode).diffrBkndLegendColor, _dfrOpts.diffractionLegendBorderColor, _dfrOpts.diffractionLegendOpacity, "bknd01") : undefined;
			const xCoordText = clicked ? getScaleTitle(SCALEPOS.horiz, legendLEFT + LegendLineWIDTH, 
				legendTOP+4, "X = " + xValue + ' мм', getModeColorOpts(isLightMode).diffrTextLegendColor, "leftkey11") : undefined;
			const yCoordText = clicked ? getScaleTitle(SCALEPOS.horiz, legendLEFT + LegendLineWIDTH, 
				shiftVert+4, "Y = " + yValue + ' ед.', getModeColorOpts(isLightMode).diffrTextLegendColor, "rkey11") : undefined;
			const mouseCursor = clicked ? getSvgMouseCrossCursor(_cursorX, _cursorY, 'mainSvg2') : undefined;

			return [
				background,
			  	valueStrokes, 
			  	valueScaleValues, 
			  	leftAxisTitle,
			  	posStrokes, posScaleValues, bottomAxisTitle,
			  	hGrid, vGrid, expLine, innerBox, 
			  	legendBackground, xCoordText, yCoordText, 
			  	mouseCursor
			];
		  };
		  
		const _svgArr2 = getExperimentResultGraphics(currExpData, svg2Height, svgWidth, waveLength, cursorX, cursorY, dfrOpts);
		setSvgArr2(_svgArr2);
	}, [currExpData, svg2Height, svgWidth, waveLength, dfrOpts, cursorX, cursorY, isLightMode]);

	const getSvg2 = (obj) => {
		return getSvgWithHeightWidth(svg2Height, svgWidth, obj, 'svg2');
	};

	const handleSlotWidth = e => {
		let value = Number(e.target.value);
		setSlotWidth(value);
		e.target.style.setProperty('--val', value);
	}
	
	const  handleSlotSpacing = e => {
		let value = Number(e.target.value);
		setSlotSpacing(value);
	};
	const  handleWaveLength = e => {
		let value = Number(e.target.value);
		setWaveLength(value);
	};

	const saveExport = (name, description) => {
        if (saveDataType === SAVE_DATA_TYPE.PROFILE) {
            saveProfile(name, description);
        } else {
            saveXLSX(name, description);
        }
    };

	const saveProfile = async (name, description) => {
		const options = {
			waveLength: waveLength,
			slotWidth: slotWidth, 
			slotSpacing: slotSpacing, 
			isLeftSlotOn: isLeftSlotOn, 
			isRightSlotOn: isRightSlotOn, 
		};

        const profile = {
            owner: isAuth()._id,
			room: documentClass._id,
            cmType: CALC_MODEL.INTERFERENCE,
            name: name,
            description: description,
			options: options,
        };

        await addCalcModelInfo(profile);
    };

	const saveXLSX = (workBookName, description) => {
		const getTableData = (experiment) => {
			return [{
				description: description,
				waveLength: getRoundValue(experiment.waveLength, 2),
				slotWidth : getRoundValue(experiment.slotWidth, 2),
				slotSpacing: getRoundValue(experiment.slotSpacing, 2),
			}];
		};
		const getGraphicData = (experiment) => {
			return [
				{image: experiment.svg, rowNum: 2}, 
				{image: experiment.svg2}, 
			];
		};

		if (experimentResults.length === 0) return;

		const tableColumns = [
			{ header: 'Описание эксперимента', key: 'description' },
			{ header: 'Длина волны, нм', key: 'waveLength' },
			{ header: 'Ширина щелей, мкм', key: 'slotWidth' },
			{ header: 'Расстояние между центрами щелей, мкм', key: 'slotSpacing' },
		];

		const tableSheets = [];
		for (let i = 0; i < experimentResults.length; i ++) {
			const tableSheet = {
				workSheetName: "Таблица " + (i+1),
				columns: tableColumns,
				tableData: getTableData(experimentResults[i]),
				graphicData: getGraphicData(experimentResults[i]),
			};
			tableSheets.push(tableSheet);
		}

		saveExcel(workBookName, tableSheets, []);
		setExperimentResults([]);
	};
	
	const handleSlotState = slotInd => {
		if (slotInd === 0) {
			setIsLeftSlotOn(!isLeftSlotOn);
		} else {
			setIsRightSlotOn(!isRightSlotOn);
		}
	};

	const handleProfile = () => {
        setSaveDataType(SAVE_DATA_TYPE.PROFILE);
        setShowConfirmSaveXlsDlg(true);
	};

	const handleExportExperiment = () => {
		setSaveDataType(SAVE_DATA_TYPE.XLS);
		canStartAction(isLightMode, dispatch, reloadOption, setIsLightModeChanged, setMakeXls);
	};

	useEffect(() => {
		if (!makeXls) return;
		getScreenAreaPictureById("mainSvg", img => setSvg(img)); //save top image
		getScreenAreaPictureById("mainSvg2", img => setSvg2(img)); //save bottom image
		setTimeout(() => completeAction(dispatch, setMakeXls, isLightModeChanged, setIsLightModeChanged, reloadOption), 100);
		setShowConfirmSaveXlsDlg(true);
	}, [dispatch, isLightModeChanged, makeXls]);

	const handleCancelSave = () => {
		setExperimentResults([]);
		setShowConfirmSaveXlsDlg(false);
	};

	const handlePrint = () => {
		canStartAction(isLightMode, dispatch, reloadOption, setIsLightModeChanged, setMakePrint);
	};

    useEffect(() => {
		const modelParams = [  //Длина волны падающего света <b>{getRoundValue(waveLength, 0)} нм
			['Длина волны падающего света: ', getRoundValue(waveLength, 0) + ' нм'],
			['Ширина щелей: ', getRoundValue(slotWidth, 2) + ' мкм'],
			['Расстояние между центрами щелей: ', getRoundValue(slotSpacing, 0) + ' мкм'],
			['Левая щель: ', isLeftSlotOn ? 'открыта' : 'закрыта'],
			['Правая щель: ', isRightSlotOn ? 'открыта' : 'закрыта'],
		];		
		if (!makePrint) return;
		printDiv(["mainSvg", "mainSvg2"], 'Интерференция в схеме Юнга', modelParams, ["./CalcModel.scss"]);
		setTimeout(() => completeAction(dispatch, setMakePrint, isLightModeChanged, setIsLightModeChanged, reloadOption), 100);
	}, [dispatch, isLeftSlotOn, isLightModeChanged, isRightSlotOn, makePrint, slotSpacing, slotWidth, waveLength]);

	const getOpts = () => {
		const waveData = {
			value: waveLength,
			ref: waveLengthRef,
			handleChange: handleWaveLength,
			min: WAVE_LENGTH_RANGE[0],
			max: WAVE_LENGTH_RANGE[1],
			step: 1,
			setValue: setWaveLength,
			className: "calcModel__inputRange1 calcModel__rainbowStripeUp",
			key: 'light01',
		};
		const slotWidthData = {
			value: slotWidth,
			ref: slotWidthRef,
			handleChange: handleSlotWidth,
			min: SLOT_WIDTH_RANGE[0],
			max: SLOT_WIDTH_RANGE[1],
			step: 0.01,
			setValue: setSlotWidth,
			key: 'ref2',
		};
		const slotSpacingData = {
			value: slotSpacing,
			ref: slotSpacingRef,
			handleChange: handleSlotSpacing,
			min: SLOT_SPACING_RANGE[0],
			max: SLOT_SPACING_RANGE[1],
			step: 1,
			setValue: setSlotSpacing,
			key: 'ref2',
		};

		return (
		<div id="idOpts" className="calcModel__opts calcModel__stretch">
			<div className="calcModel__rangeCol">
				<div className="calcModel__range calcModel__rangeCmf"> 
					<div className="calcModel__label">Длина волны падающего света <b>{getRoundValue(waveLength, 0)} нм</b></div>
					{getInputRange(waveData)}
				</div>								

				<div className="calcModel__interfChkBoxes calcModel__rangeCmf"> 
					<span>
						<input type="checkbox" key={'ltslot01'} 
							checked={isLeftSlotOn ? "checked" : ""} onChange={() => handleSlotState(0)} />
						&nbsp;Левая щель
					</span>
					<span>&nbsp;&nbsp;&nbsp;</span>
					<span>
						<input type="checkbox" key={'rtslot01'} 
							checked={isRightSlotOn ? "checked" : ""} onChange={() => handleSlotState(1)} />
						&nbsp;Правая щель
					</span>
				</div>								
			</div>

			<div className="calcModel__rangeCol">
				<div className="calcModel__range calcModel__rangeCmf"> 
					<div className="calcModel__label">Ширина щелей <b>{getRoundValue(slotWidth, 2)} мкм</b></div>
					{getInputRange(slotWidthData)}
				</div>
				<div className="calcModel__range calcModel__rangeCmf"> 
					<div className="calcModel__label">Расстояние между центрами щелей <b>{getRoundValue(slotSpacing, 0)} мкм</b></div>
					{getInputRange(slotSpacingData)}
				</div>
			</div> 
		</div>
		); 
	};

	const getButtons = () => {
		return (
			<> 
				<div className="calcModel__stretch">
					<div className="calcModel__exportBtnComment">Работа с файлами</div> 
					<div className="calcModel__leftBottomBtn">{getExportCB(handleProfile, handleExportExperiment, isInnerCM)}</div> 
					<div>
						<Icon name="print" className=" calcModel__printBtn" onClick={handlePrint} />
					</div>
				</div>
			</>
		);
	};

    return (
		<>
			{getHeaderBox(history, match.params.id, isInnerCM, 'Интерференция в схеме Юнга')}

			<Content>
				<ContentBody scrolled={false}>
					<div id="idInnerMainBox" ref={refMainBox} className="calcModel">
						<div id="mainSvg" ref={refMainSvg1} className="calcModel__coil"
							style={{width: svgWidth, height: svg1Height}}>
							{getCurrentSlotWidthGraphics()}
						</div>
							<div id="mainSvg2" ref={refMainSvg2} className="calcModel__coil"
							style={{width: svgWidth, height: svg2Height}}>
								{getSvg2(svgArr2)}
						</div>
						{getOpts()}
					</div> 
				</ContentBody>
				<div className="calcModel__indent"></div>
				<ContentFooter className="calcModel__footer">{getButtons()}</ContentFooter>
			</Content>

			{showConfirmSaveXlsDlg &&
			<SaveXlsFileConfirmDialog
				showConfirmSaveXlsDlg={showConfirmSaveXlsDlg}
				setShowConfirmSaveXlsDlg={setShowConfirmSaveXlsDlg}
				saveExport={saveExport}
				fileName={'Интерференция'}
				handleCancelSave={handleCancelSave}
			/>
			}
		</>
    );
}

export default Interference;
