import {PRACTICUM_TEST_TYPE} from "../utils/ServerInfo";

import {Button} from 'rlabui';
import ss from './ModalBegin.module.scss';

export const _isOnlineTest =(_testType) => _testType === PRACTICUM_TEST_TYPE.ONLINE_TEST;
export const _isLabWork = _testType => _testType === PRACTICUM_TEST_TYPE.LAB_WORK;
export const _isVirtLab = _testType => _testType === PRACTICUM_TEST_TYPE.LW_OPTIC_GAME;

const getNumText = num => {
    if (num === 12 || num === 13) return "человек";
    const dn = num - Math.floor(num/10) * 10;
    if (dn === 2 || dn === 3) return "человека";
    return "человек";
};

export const getClassInfo = (documentClassName, studentNumber) => { //documentClass.className
    return (
        <>  
            <div className={ss.row}>
                <span>Класс</span>
                <span>{documentClassName}</span>
            </div>
            <div className={ss.row}>
                <span>Кол-во учеников в классе</span>
                <span>{studentNumber}&nbsp;{getNumText(studentNumber)}</span>
            </div>      
        </>
    );
};

export const getLabInfo = (contentList) => {
    if (!contentList) return <></>;
    return (
        <>
            {contentList[0] && (
                <div className={ss.row}>
                    <span>Предмет</span>
                    <span>{contentList[0]}</span>
                </div>
            )}
            {contentList[1] && (
                <div className={ss.row}>
                    <span>Раздел</span>
                    <span>{contentList[1]}</span>
                </div>
            )}
            {contentList[2] && (
                <div className={ss.row}>
                   <span>Лабораторная работа</span>
                   <span>{contentList[2]}</span>
               </div>
            )}
        </>
    );
};

export const getOnlineTestInfo = (onlineTestTitle, onlineTestDescr) => {
    return (
        <>
            <div className={ss.row}>
                <span>Название онлайн теста</span>
                <span>{onlineTestTitle}</span>
            </div>
            <div className={ss.row}>
                <span>Описание онлайн теста</span>
                <span>{onlineTestDescr}</span>
            </div>
        </>
    );
};

export const getButtons = (handleStartTest, setShowModal, isTeacher, testType, isOtRun) => {
    return (
        <> 
            <Button onClick={handleStartTest}>
                {testType === PRACTICUM_TEST_TYPE.LW_OPTIC_GAME ? 'Начать игру' : 
                    isOtRun ? 'Начать тест' : 'Начать предпросмотр'}
            </Button>
            {isTeacher && <Button border={true} onClick={() => setShowModal(false)}>Отменить</Button>}
        </>
    );
};