export const deleteBox = (chart, key, setChartMarkers, num) => {
    // console.log(key);
    try {
        if (key) {
            const markers = chart.config.options.plugins.annotation.annotations;
            // console.log(1, Object.keys(markers));
            const start = key + 'start';
            const end = key + 'end';
            // console.log('delete range');
            delete markers[start];
            delete markers[end];
            delete markers[key];

            // console.log(2, Object.keys(markers));
            // console.log(2, markers);

            chart.options.plugins.annotation.annotations = markers;
            setChartMarkers({ num, data: markers });
            chart.update();

            key = null;
        }
    } catch (e) {
        console.log(e);
    }
};
