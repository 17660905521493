import { connect } from 'react-redux';
import {
    mapClassStateToProps, mapClassDispatchToProps,
    mapUserSettingsStateToProps, mapUserSettingsDispatchToProps,
    mapUserSelectStateToProps, mapUserSelectDispatchToProps,
    mapUserPasswordStateToProps, mapUserPasswordDispatchToProps,
    mapRlabStateToProps, mapRlabDispatchToProps,
    mapDeviceStateToProps, mapDeviceDispatchToProps,
    mapRlabSensorsStateToProps, mapRlabSensorsDispatchToProps,
    mapVideoStateToProps, mapVideoDispatchToProps,
    mapFileStateToProps, mapFileDispatchToProps,
    mapStorageStateToProps, mapStorageDispatchToProps,
    mapNameStateToProps, mapNameDispatchToProps,
} from './redux';
import UserSet from "./UserSettings";
import UserSel from "./UserSelect";
import UserPass from "./UserPassword";
import ClassSet from "./ClassSettings";
import Rlab from "./RlabSettings";
import Device from "./DeviceSettings";
import Video from "./VideoSettings";
import RlabS from "./RlabSensors";
import FileS from "./FileSettings"
import StorageS from "./StorageSettings"
import NameS from "./NameSettings"

const UserSettings = connect(mapUserSettingsStateToProps, mapUserSettingsDispatchToProps)(UserSet);
const UserSelect = connect(mapUserSelectStateToProps, mapUserSelectDispatchToProps)(UserSel);
const UserPassword = connect(mapUserPasswordStateToProps, mapUserPasswordDispatchToProps)(UserPass);
const ClassSettings = connect(mapClassStateToProps, mapClassDispatchToProps)(ClassSet);
const RlabSettings = connect(mapRlabStateToProps, mapRlabDispatchToProps)(Rlab);
const DeviceSettings = connect(mapDeviceStateToProps, mapDeviceDispatchToProps)(Device);
const VideoSettings = connect(mapVideoStateToProps, mapVideoDispatchToProps)(Video);
const RlabSensors = connect(mapRlabSensorsStateToProps, mapRlabSensorsDispatchToProps)(RlabS);
const FileSettings = connect(mapFileStateToProps, mapFileDispatchToProps)(FileS);
const StorageSettings = connect(mapStorageStateToProps, mapStorageDispatchToProps)(StorageS);
const NameSettings = connect(mapNameStateToProps, mapNameDispatchToProps)(NameS);

export { UserSettings, UserSelect, UserPassword, ClassSettings, RlabSettings, DeviceSettings, VideoSettings,
    RlabSensors, FileSettings, StorageSettings, NameSettings }
