import React, {useEffect, useState} from 'react';
import { Icon } from '../../ui';
import {RlabSensors, RlabSettings, VideoSettings} from "../../Settings";
import {useSelector} from "react-redux";
import {storageClass, storageOrg} from "../../../redux/slices/storage";
import {FEATURES} from "../../../api/config";
import RlabNav from "./RlabNav";
import {RlabNotification} from "../../Notifications";
import {hasFeature, isAuth} from "../../../helpers/auth";
import socket from "../../../socket";
import {optionsSensors} from "../../../redux/slices/options";
import {PRACTICUM_TEST_OPT} from "../../ui/utils/ServerInfo";
import {FooterButton, FooterButtonBack} from './footerUi/';
import Modal from "../../Modal";
import Visualizer from "../../../rlab-neuro/src/components/template/Visualizer";
import RlabNeuroNav from "./RlabNeuroNav";
import './Footer.scss';
import {toast} from "react-toastify";

const Footer = (props) => {
    const documentOrg = useSelector(storageOrg);
    const documentClass = useSelector(storageClass);
    const sensorStream = useSelector(optionsSensors);

    const {sensors: {item: sensorItem}} = useSelector(state => state);
    const [startedAt, setStartedAt] = useState();
    const [stoppedAt, setStoppedAt] = useState();
    const [showShareWarning, setShowShareWarning] = useState(false);

    useEffect(() => {
        if (props.played) {
            setStartedAt(new Date());
        } else {
            setStoppedAt(new Date());
        }
    },[props.played]);

    const toggleSensor = () => {
        if (!props.sensor && (!props.port || props.connectionType === "net" || props.sensorsList.length < 1 || 
                Object.keys(props.devicesList ?? {}).length < 1)) {
            toast.error("Нет датчиков");
            return;
        }

        if (props.screen && !props.sensor) {
            props.toggleScreen(false);
        }

        props.toggleSensor(!props.sensor);
    }

    const showSensors = () => {
        if (!props.port || props.connectionType === "net" || props.sensorsList.length < 1 || 
                Object.keys(props.devicesList??{}).length < 1) {
            toast.error("Нет датчиков");
            return;
        }

        props.showRlabSensors({showModal: true, devicesList: props.devicesList, sensorsList: props.sensorsList});
    }

    useEffect(() => {
        if (sensorStream && sensorItem.length > 0) {
            socket.emit("BE-sensor-item", {roomId:documentClass._id, sensorItem});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[sensorItem]);

    const showExport = () => {
        if (!startedAt){
            toast.error("Эксперимент не начат");
            return;
        }
        if (props.played){
            toast.error("Эксперимент не завершен");
            return;
        }

        const rlab = {
            room: documentClass._id,
            name: "",
            desc: "",
            startedAt,
            stoppedAt,
            data: {
                records: props.records.filter(rec => ('num' in rec)),
                markers: props.markers,
            }
        }
        props.showRlabSettings({showModal:true, isAdd: true, rlab });
    }

    const isChecked = (props, content) => props.match?.url?.includes(content);
    const isPracticumView = props => 
        isChecked(props, '/practicum') || isChecked(props, '/reviewtest') || isChecked(props, '/pi') ||
        isChecked(props, '/ot_create') || isChecked(props, '/cm') || isChecked(props, '/lw') ||
        isChecked(props, '/testview') || isChecked(props, '/ot');
        
    const getDocByInd = ind => {
        const docs = [
            {ind: '/ot', name: 'Описание и инструкция к онлайн тестам'},
            {ind: '/lw', name: 'Описание и инструкция к лабораторным работам'},
            {ind: '/cm', name: 'Описание и инструкция к расчетным моделям'},
            {ind: '/pi', name: 'Описание и инструкция к исследованиям'},
            {ind: '/notebooks', name: 'Описание и инструкция к рабочей тетради'},
            {ind: '/rlsc', name: 'Описание и инструкция к сценариям'},
            //
            {ind: 'geometry', name: 'Описание и инструкция к иллюстратору объектов'},
            {ind: 'voltage', name: 'Описание и инструкции к генератору напряжений'},
            {ind: 'video', name: 'Описание и инструкция к анализу видеокадров'},
            {ind: 'videofile', name: 'Описание и инструкция к анализу видеокадров'},
            {ind: 'videocamera', name: 'Описание и инструкция к анализу видеокадров'},
            {ind: 'videolib', name: 'Описание и инструкция к анализу видеокадров'},
          ];

        const doc = docs.find(item => item.ind === ind);
        return "/UserDocumentation/" + doc.name + ".docx";
    };

    const getDocPath = () => {
        const docs = ['/ot', '/lw', '/cm', '/pi', '/notebooks', '/rlsc'];
        for (let i = 0; i < docs.length; i ++) {
            if (isChecked(props, docs[i]))
                return getDocByInd(docs[i]);
        }
        //инструкция не найдена
    };

    const getInstrumentDocPath = () => {
        const ind = props.match?.params?.id;
        return getDocByInd(ind);
    };

    const practicumTabs = FEATURES.practicum.sub.map((s) => ({
        title: FEATURES[s].title, icon: FEATURES[s].icon, tooltip: FEATURES[s].tooltip,
        to: (([PRACTICUM_TEST_OPT.OT,PRACTICUM_TEST_OPT.LW].includes(s) && isAuth().role === 1) ? '/practicum/' + s : FEATURES[s].to),
        disabled: !hasFeature(documentOrg, s),
        active: [FEATURES[s].to, ...(FEATURES[s].alt ?? [])].includes(props.match.path) ||
          (FEATURES.practicum.to + '/' + s === props.match.url || '/reviewtest/' + s === props.match.url),
    }));

    return (isAuth().role > 1 ? <></> :
        <footer className="cor_footer">
            <div className="cor_footerNav">
                <div className="cor_footerNav__content">

                    {isChecked(props, '/instrument/') && ( <></>)}

                    <div className="cor_footerNav__item">
                        <div className="cor_footerNav__label">Действия<br/>с экраном</div>
                        <div className="cor_footerNav__btns">
                            <FooterButton
                                title = "Экран"
                                icon = "monitor"
                                iconActive = "monitor-off"
                                prop = {props.screen}
                                onClick = {() => props.toggleScreen(!props.screen)}
                            />
                            <FooterButton
                                title = "Камера"
                                icon = "video"
                                iconActive = "video-off"
                                prop = {props.video}
                                onClick = {()=>props.toggleVideo(!props.video)}
                            />
                            <FooterButton
                                title = "Микрофон"
                                icon = "microphone"
                                iconActive = "microphone-mute"
                                prop = {props.audio}
                                onClick = {()=>props.toggleAudio(!props.audio)}
                            />
                        </div>
                    </div>
                    {[FEATURES.profile.to, ...FEATURES.profile.alt].includes(props.match?.path) && (
                        <FooterButtonBack onClick={()=> props.history.goBack()} />
                    )}

                    {FEATURES.rlab.alt.slice(3, 4).includes(props.match?.path) && 
                        <RlabNeuroNav history={props.history}/>}
                    {FEATURES.rlab.alt.slice(3, 4).includes(props.match?.path) &&
                        <div className="cor_footerNav__item"><Visualizer/></div>}
                    {FEATURES.rlab.alt.slice(0, 2).includes(props.match?.path) && 
                        <RlabNav history={props.history}/>}
                    {FEATURES.rlab.alt.slice(0, 3).includes(props.match?.path) &&
                      <div className="cor_footerNav__item">
                        <div className="cor_footerNav__label">Сохраненные<br/>эксперименты</div>
                        <div className="cor_footerNav__btns">
                            <div className={"cor_footerNav__btn"+(props.match?.path==="/rlabExport"?" active":"")}
                                 onClick={()=>props.history.push("/rlabExport")}>
                                <Icon name="window" />
                            </div>

                            {props.match?.path==="/rlabExport" &&
                                <FooterButtonBack onClick={()=>props.history.push("/rlab")} />
                            }
                        </div>
                    </div>}
                    {FEATURES.rlab.alt.slice(0,2).includes(props.match?.path) &&
                    <div className="cor_footerNav__item">
                        <div className="cor_footerNav__label">Удаленная <br/>работа</div>
                        <div className="cor_footerNav__btns">
                            <div className="cor_footerNav__btn tp" onClick={()=>showSensors()}
                                data-tp="создать ссылку">
                                <Icon name="attachment" />
                            </div>
                            <div className="cor_footerNav__btn tp" onClick={()=>showExport()}
                                data-tp="сохранить эксперимент">
                                <Icon name="save" />
                            </div>
                            <div className={"cor_footerNav__btn tp"+ (props.sensor?" active":"")}
                                 onClick={() => (props.screen && !props.sensor) ? 
                                    setShowShareWarning(true) : toggleSensor()}
                                 data-tp="раздача данных датчика для учеников">
                                <Icon name="share"/>
                            </div>
                        </div>
                        <Modal visible={showShareWarning} content={
                            <>
                                <div className="modal__message">Стрим датчиков приведет к остановке стрима экрана.</div>
                                <div className="modal__action">
                                    <button className="cor_btn" onClick={() => setShowShareWarning(false)}>
                                        Отменить
                                    </button>
                                    <button className="cor_btn cor_btn_danger" onClick={() => {
                                        setShowShareWarning(false);
                                        toggleSensor();
                                    }}>Начать
                                    </button>
                                </div>
                            </>
                        } size={'xs'}/>
                    </div>}

                    {isPracticumView(props) &&
                    <>
                        {isAuth().role < 2 &&
                        <div className="cor_footerNav__item">
                            <div className="cor_footerNav__label">Выбор вида <br/>тестирования</div>
                            <div className="cor_footerNav__btns">
                                { practicumTabs.filter(t=>!t.disabled).map((tab, key) =>
                                <FooterButton key={key}
                                    title = {tab.title} icon ={tab.icon}
                                    prop = {tab.active} onClick = {() => !tab.disabled && props.history.push(tab.to)}
                                />)}
                            </div>
                        </div>
                        }
                    </>}
                    
                    {(isChecked(props, '/ot') || isChecked(props, '/lw') || 
                      isChecked(props, '/cm') || isChecked(props, '/pi') || 
                      isChecked(props, '/notebooks') || isChecked(props, '/rlsc')) && (
                        <>
                            <div className="cor_footerNav__item">
                                <div className="cor_footerNav__btns">
                                    <div className={"cor_footerNav__btn"}>
                                        <a href={getDocPath()} 
                                            download 
                                            onClick={() => toast.success("Документация пользователя загружена.") }>
                                            <Icon name="download"  
                                            className='cor_footer__btn active cor_footerNav__adoc' />
                                        </a>
                                    </div>
                                </div>
                                <div className="cor_footerNav__label">Документация <br/>пользователя</div>
                            </div>
                               
                            <FooterButtonBack onClick={()=> props.history.goBack()} />
                        </>
                    )}

                    {isChecked(props, '/instrument/') && (
                        <>
                            <div className="cor_footerNav__item">
                                <div className="cor_footerNav__btns">
                                    <div className={"cor_footerNav__btn"}>
                                        <a href={getInstrumentDocPath()} download 
                                            onClick={() => toast.success("Документация пользователя загружена.") }>
                                            <Icon name="download"  className='cor_footer__btn active cor_footerNav__adoc' />
                                        </a>
                                    </div>
                                </div>
                                <div className="cor_footerNav__label">Документация <br/>пользователя</div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <RlabSettings/>
            <VideoSettings/>
            <RlabSensors/>
            <RlabNotification {...props}/>
        </footer>
    )
};
export default Footer;
