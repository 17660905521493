import { setAboutModal } from '../../../actions/modal';

export const mapStateToProps = (state) => {
    return {
        modal: state.nmodal.about,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setAboutModal: (bool) => dispatch(setAboutModal(bool)),
});
