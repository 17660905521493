import React, {useEffect, useState} from 'react';
import * as Yup from "yup";
import {toast} from "react-toastify";
import {useFormik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import Modal from '../Modal';
import {storageApi} from "../../api/api";
import {loadFiles} from "../../redux/slices/lists";
import "./Settings.scss";

const FileSettings = (props) => {
    const dispatch = useDispatch();
    const {lists:files} = useSelector(state => state);
    const [showConfirm, setShowConfirm] = useState(false);

    const deleteFile = async () => {
        try {
            await storageApi.delete(props.file.key);
            dispatch(loadFiles(props.file.prefix));
            handleClose();
        } catch (err) {
            console.log(err);
        }
    };

    const deleteCancel = () => setShowConfirm(false);
    const deleteConfirm = async () => {
        await deleteFile();
        setShowConfirm(false);
    };

    const handleClose = () => {
        formik.resetForm();
        props.showFileSettings({showModal: false});
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) showConfirm ? deleteCancel() : handleClose();
        };
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showConfirm]);

    Yup.addMethod(Yup.string, "checkName", function (errorMessage) {
        return this.test(`checkName`, errorMessage, function (value) {
            const { path, createError } = this;
            return (
              props.file.name === value || !files.files.some(f => f.Key.split('/').pop() === value) ||
              createError({ path, message: errorMessage })
            );
        });
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: props.file.name,
        }, validationSchema: Yup.object({
            name: Yup.string().required("Введите имя").checkName("Такое имя уже есть"),
        }), onSubmit: async (formData) => {
            try {
                if (formData.name === props.file.name) {
                    handleClose();
                    return;
                }

                const newKey = props.file.key.replace(/[^/]+$/,'')  + formData.name;
                storageApi.rename(props.file.key, newKey).then(() => {
                    dispatch(loadFiles(props.file.prefix));
                    handleClose();
                }).catch((err) => {
                    toast.error(err.response.data.errors);
                });
            } catch (err) {
                console.log(err);
            }
        },
    });

    return (
        <>
            <Modal visible={props.showModal} content={
                <form onSubmit={formik.handleSubmit}>
                    <div className="modal__close" onClick={handleClose}/>
                    <div className="modal__message">Редактировать Файл</div>
                    <div className="modal__body">
                        <div key="email" className="cor_settings">
                            <label htmlFor="name">Имя Файла</label>
                            <input
                                autoComplete="off"
                                placeholder="Имя"
                                name="name"
                                className={formik.errors.name?"form_error":""}
                                onChange={formik.handleChange}
                                value={formik.values.name}
                            />
                            <div className="form_error">{formik.errors.name}</div>
                        </div>
                    </div>
                    <div className="modal__action">
                        <button
                            className="cor_btn cor_btn_primary"
                            type="submit"
                        >{props.isAdd ? "Добавить" : "Сохранить"}</button>
                        <div style={{width: "50%"}}/>
                        {props.allowDelete && <button
                            className="cor_btn cor_btn_danger"
                            type="button"
                            onClick={() => setShowConfirm(true)}
                        >Удалить</button>}
                    </div>
                </form>
            }/>
            <Modal visible={showConfirm} content={
                <>
                    <div className="modal__message">Точно удалить?</div>
                    <div className="modal__action">
                        <button
                            className="cor_btn"
                            onClick={deleteCancel}
                        >Отменить
                        </button>
                        <button
                            className="cor_btn cor_btn_danger"
                            onClick={deleteConfirm}
                        >Удалить
                        </button>
                    </div>
                </>
            } size={'xs'}/>
        </>
    )
};

export default FileSettings;