export const PI_TAB_TYPE = {
    INVESTIGATIONS: 1,
    OBJECTS: 2,
    UNITS: 3,
};

const MEASURE_SYSTEM = {
    SI: 1,
    SGS: 2,
    OLD_RUS: 3,
    ENG: 4,
    FRENCH: 5,
    MISC: 6,
    GRAPH: 7,
    MINE: 8,
    REMOVED: 9
};

const MEASURE_SYSTEM_TYPES = [	
    {sType: MEASURE_SYSTEM.SI, sName: 'СИ'},
    {sType: MEASURE_SYSTEM.SGS, sName: 'СГС'},
    {sType: MEASURE_SYSTEM.OLD_RUS, sName: 'Старорусская система мер'},
    {sType: MEASURE_SYSTEM.ENG, sName: 'Английская система мер'},
    {sType: MEASURE_SYSTEM.FRENCH, sName: 'Французская система мер'},
    {sType: MEASURE_SYSTEM.MISC, sName: ' Другие системы мер'},
    {sType: MEASURE_SYSTEM.GRAPH, sName: 'Компьютерная графика'},
    {sType: MEASURE_SYSTEM.MINE, sName: 'Мои единицы'},
    {sType: MEASURE_SYSTEM.REMOVED, sName: 'Удаленные'},
];

const PHYS_CATEGORY = {
    NUCLIDE_ACT: 1,         //Активность нуклида в радиоактивном источнике
    WAVE_NUMBER: 2,         //Волновое число
    TIME: 3,                //Время
    PRESSURE: 4,            //Давление
    DYN_VISCOSITY: 5,       //Динамическая вязкость
    KIN_VISCOSITY: 6,       //Кинематическая вязкость
    DIELEC_CONST: 7,        //Диэлектрическая проницаемость
    LENGTH: 8,              //Длина, расстояние
    FREQUENCY: 9,           //Частота, частота вращения
    VOLUME_ENERGY: 10,      //Объемная плотность потока энергии
    ENERGY: 11,             //Мощность
    SPECIFIC_ENERGY: 12,    //Удельная энергия
    QUANTITY_HEAT: 13,      //Энергия, работа, количество теплоты
    AMOUNT_HEAT: 14,        //Количество теплоты
    MOMENT_POWER: 15,       //Момент силы
    POWER: 16,              //Сила, вес
    ANGULAR_ACC: 17,        //Угловое ускорение
    ANGULAR_VELOCITY: 18,   //Угловая скорость
    DENSITY: 19,            //Плотность
    SPECIFIC_VOLUME: 20,    //Удельный объем
    VOLUME: 21,             //Объем
    MASS: 22,               //Масса
    SQUARE: 23,             //Площадь
    SOLID_ANGLE: 24,        //Телесный угол
    FLAT_ANGLE: 25,         //Плоский угол
    POWER_LIGHT: 26,        //Сила света
    QUANTITY_SUBST: 27,     //Количество вещества
    THERMO_TEMP: 28,        //Термодинамическая температура
    SURFACE_ENERGY: 29,     //Поверхностная плотность потока энергии
    HEAT_SYSTEM: 30,        //Теплоемкость системы, энтропия системы
    THERMAL_COND: 31,       //Теплопроводность
    SPECIFIC_HEAT: 32,      //Удельная теплоемкость, удельная энтропия
    MOLAR_CONC: 33,         //Молярная концентрация компонента
    MOLAR_ENTROPY: 34,      //Молярная энтропия, молярная теплоемкость
    SURFACE_TENSION: 35,    //Поверхностное натяжение
    ELECTRIC_CHARGE: 36,    //Электрический заряд, количество электричества
    CHARGE_DENSITY: 37,     //Поверхностная плотность электрического заряда
    VOLUME_DENSITY: 38,     //Объемная плотность электрического заряда
    FIELD_STRENGTH: 39,     //Напряженность электрического поля
    ELECTRICAL_VOLTAGE: 40, //Электрическое напряжение, электрический потенциал, разность электрических потенциалов, электродвижущая сила
    ELECTRICAL_RESIST: 41,  //Электрическое сопротивление
    RESISTIVITY: 42,        //Удельное электрическое сопротивление
    ELECTRICAL_COND: 43,    //Электрическая проводимость
    EXPOSURE_DOSE: 44,      //Экспозиционная доза фотонного излучения (экспозиционная доза гамма- и рентгеновского излучения)
    ACCELERAT: 45,          //Ускорение (линейное)
    SPEED: 46,              //Скорость (линейная)
    MAGNETIC_IND: 47,       //Магнитная индукция (индукция магнитного поля), плотность магнитного потока
    PULSE: 48,              //Импульс (количество движения)
    DOSE_RATE: 49,          //Мощность поглощенной дозы
    TEMP: 50,               //Температура
    BRIGHTNESS: 51,         //Яркость
    ENERGY_BRIGHT: 52,      //Энергетическая яркость
    RADIATION_STRENGTH: 53, //Сила излучения
    LIGHT_FLOW: 54,         //Световой поток
    ILLUMINATION: 55,       //Освещенность
    INDUCTANCE: 56,         //Индуктивность, взаимная индуктивность
    ELECTRIC_STRENGHT: 57,  //Сила электрического тока
    MAGNETIC_FLUX:58,       //Поток магнитной индукции, магнитный поток
    MAGNETIC_FIELD:59,      //Напряженность магнитного поля
    MAGNETIC_PER:60,        //Магнитная проницаемость
    ELECTRIC_DEN:61,        //Плотность электрического тока
    ELECTRICAL_CAP:62,      //Электрическая емкость
    TEMP_COEF:63,           //Температурный коэффициент линейного и объемного расширения
    HEAT_MELT:64,           //Теплота плавления, теплота испарения
};

const CATEGORY_TYPES = [	//obsolete
    {cType: PHYS_CATEGORY.NUCLIDE_ACT, cName: 'Активность нуклида в радиоактивном источнике'},
    {cType: PHYS_CATEGORY.WAVE_NUMBER, cName: 'Волновое число'},
    {cType: PHYS_CATEGORY.TIME, cName: 'Время'},
    {cType: PHYS_CATEGORY.PRESSURE, cName: 'Давление'},
    {cType: PHYS_CATEGORY.DYN_VISCOSITY, cName: 'Динамическая вязкость'},
    {cType: PHYS_CATEGORY.KIN_VISCOSITY, cName: 'Кинематическая вязкость'},
    {cType: PHYS_CATEGORY.DIELEC_CONST, cName: 'Диэлектрическая проницаемость'},
    {cType: PHYS_CATEGORY.LENGTH, cName: 'Длина, расстояние'},
    {cType: PHYS_CATEGORY.FREQUENCY, cName: 'Частота, частота вращения'},
    {cType: PHYS_CATEGORY.VOLUME_ENERGY, cName: 'Объемная плотность потока энергии'},
    {cType: PHYS_CATEGORY.ENERGY, cName: 'Мощность'},
    {cType: PHYS_CATEGORY.SPECIFIC_ENERGY, cName: 'Удельная энергия'},
    {cType: PHYS_CATEGORY.QUANTITY_HEAT, cName: 'Энергия, работа, количество теплоты'},
    {cType: PHYS_CATEGORY.AMOUNT_HEAT, cName: 'Количество теплоты'},
    {cType: PHYS_CATEGORY.MOMENT_POWER, cName: 'Момент силы'},
    {cType: PHYS_CATEGORY.POWER, cName: 'Сила, вес'},
    {cType: PHYS_CATEGORY.ANGULAR_ACC, cName: 'Угловое ускорение'},
    {cType: PHYS_CATEGORY.ANGULAR_VELOCITY, cName: 'Угловая скорость'},
    {cType: PHYS_CATEGORY.DENSITY, cName: 'Плотность'},
    {cType: PHYS_CATEGORY.SPECIFIC_VOLUME, cName: 'Удельный объем'},
    {cType: PHYS_CATEGORY.VOLUME, cName: 'Объем'},
    {cType: PHYS_CATEGORY.SQUARE, cName: 'Площадь'},
    {cType: PHYS_CATEGORY.SOLID_ANGLE, cName: 'Телесный угол'},
    {cType: PHYS_CATEGORY.FLAT_ANGLE, cName: 'Плоский угол'},
    {cType: PHYS_CATEGORY.POWER_LIGHT, cName: 'Сила света'},
    {cType: PHYS_CATEGORY.QUANTITY_SUBST, cName: 'Количество вещества'},
    {cType: PHYS_CATEGORY.THERMO_TEMP, cName: 'Термодинамическая температура'},
    {cType: PHYS_CATEGORY.MASS, cName: 'Масса'},
    {cType: PHYS_CATEGORY.SURFACE_ENERGY, cName: 'Поверхностная плотность потока энергии'},
    {cType: PHYS_CATEGORY.HEAT_SYSTEM, cName: 'Теплоемкость системы, энтропия системы'},
    {cType: PHYS_CATEGORY.THERMAL_COND, cName: 'Теплопроводность'},
    {cType: PHYS_CATEGORY.SPECIFIC_HEAT, cName: 'Удельная теплоемкость, удельная энтропия'},
    {cType: PHYS_CATEGORY.MOLAR_CONC, cName: 'Молярная концентрация компонента'},
    {cType: PHYS_CATEGORY.MOLAR_ENTROPY, cName: 'Молярная энтропия, молярная теплоемкость'},
    {cType: PHYS_CATEGORY.SURFACE_TENSION, cName: 'Поверхностное натяжение'},
    {cType: PHYS_CATEGORY.ELECTRIC_CHARGE, cName: 'Электрический заряд, количество электричества'},
    {cType: PHYS_CATEGORY.CHARGE_DENSITY, cName: 'Поверхностная плотность электрического заряда'},
    {cType: PHYS_CATEGORY.VOLUME_DENSITY, cName: 'Объемная плотность электрического заряда'},
    {cType: PHYS_CATEGORY.FIELD_STRENGTH, cName: 'Напряженность электрического поля'},
    {cType: PHYS_CATEGORY.ELECTRICAL_VOLTAGE, cName: 'Электрическое напряжение, электрический потенциал, разность электрических потенциалов, электродвижущая сила'},
    {cType: PHYS_CATEGORY.ELECTRICAL_RESIST, cName: 'Электрическое сопротивление'},
    {cType: PHYS_CATEGORY.RESISTIVITY, cName: 'Удельное электрическое сопротивление'},
    {cType: PHYS_CATEGORY.ELECTRICAL_COND, cName: 'Электрическая проводимость'},
    {cType: PHYS_CATEGORY.EXPOSURE_DOSE, cName: 'Экспозиционная доза фотонного излучения (экспозиционная доза гамма- и рентгеновского излучения)'},
    {cType: PHYS_CATEGORY.ACCELERAT, cName: 'Ускорение (линейное)'},
    {cType: PHYS_CATEGORY.SPEED, cName: 'Скорость (линейная)'},
    {cType: PHYS_CATEGORY.MAGNETIC_IND, cName: 'Магнитная индукция (индукция магнитного поля), плотность магнитного потока'},
    {cType: PHYS_CATEGORY.PULSE, cName: 'Импульс (количество движения)'},
    {cType: PHYS_CATEGORY.DOSE_RATE, cName: 'Мощность поглощенной дозы'},
    {cType: PHYS_CATEGORY.TEMP, cName: 'Температура'},
    {cType: PHYS_CATEGORY.BRIGHTNESS, cName: 'Яркость'},
    {cType: PHYS_CATEGORY.ENERGY_BRIGHT, cName: 'Энергетическая яркость'},
    {cType: PHYS_CATEGORY.RADIATION_STRENGTH, cName: 'Сила излучения'},
    {cType: PHYS_CATEGORY.LIGHT_FLOW, cName: 'Световой поток'},
    {cType: PHYS_CATEGORY.ILLUMINATION, cName: 'Освещенность'},
    {cType: PHYS_CATEGORY.INDUCTANCE, cName: 'Индуктивность, взаимная индуктивность'},
    {cType: PHYS_CATEGORY.ELECTRIC_STRENGHT, cName: 'Сила электрического тока'},
    {cType: PHYS_CATEGORY.MAGNETIC_FLUX, cName: 'Поток магнитной индукции, магнитный поток'},
    {cType: PHYS_CATEGORY.MAGNETIC_FIELD, cName: 'Напряженность магнитного поля'},
    {cType: PHYS_CATEGORY.MAGNETIC_PER, cName: 'Магнитная проницаемость'},
    {cType: PHYS_CATEGORY.ELECTRIC_DEN, cName: 'Плотность электрического тока'},
    {cType: PHYS_CATEGORY.ELECTRICAL_CAP, cName: 'Электрическая емкость'},
    {cType: PHYS_CATEGORY.TEMP_COEF, cName: 'Температурный коэффициент линейного и объемного расширения'},
    {cType: PHYS_CATEGORY.HEAT_MELT, cName: 'Теплота плавления, теплота испарения'},
];


const UNIT_IDS = {
    //time ids:
    TM_SC: 1,      //секунда
    TM_ML: 2,      //миллисекунда
    TM_MK: 3,      //микросекунда
    TM_NC: 4,      //наносекунда
    TM_MN: 5,      //минута 
    TM_HR: 6,      //час 
    TM_CT: 7,      //сутки

    //surface_energy ids:
    SF_VM: 10,       // ватт на квадратный метр

    //length ids:
    LN_MT: 50,     //метр
    LN_NM: 51,     //нанометр
    LN_KM: 52,     //километр
    LN_SM: 53,     //сантиметр
    LN_MM: 54,     //миллиметр
    LN_MKM: 55,    //микрон
    LN_DM: 56,     //дециметр
    LN_A: 57,      //ангстрем
    LN_AE: 58,     //астрономическая единица
    LN_LY: 59,     //световой год
    LN_PK: 60,     //парсек
    //
    LN_KR: 70,     //круг
    LN_PD: 71,     //пядь
    LN_LK: 72,     //локоть
    LN_AR: 73,     //аршин
    LN_SH: 74,     //шаг
    LN_MR: 75,     //мера, полсаженя
    LN_LB: 76,     //лоб
    LN_ST: 77,     //столбец
    LN_PS: 78,     //посох
    LN_VP: 79,     //витой посох
    LN_RD: 80,     //род
    LN_LG: 81,     //лига
    LN_KB: 82,     //кабельтов
    LN_UM: 83,     //уставная миля
    LN_FL: 84,     //фарлонг
    LN_CH: 85,     //чейн
    LN_SL: 86,     //морская миля
    LN_RR: 87,     //ярд
    LN_FT: 88,     //фут
    LN_HN: 89,     //хэнд
    LN_DS: 90,     //дюйм
    LN_TH: 91,     //точка
    //
    LN_FS: 92, // французская сухопутная миля
    //
    LN_GM: 93, // географическая, геометрическая миля
    LN_DR: 94, // древнеримская миля 
    LN_DN: 95, // немецкая миля
    LN_NV: 96, // норвежская (шведская) миля 
    //
    LN_PC: 97, // пиксель
    //
    LN_SJ: 100,    //сажень
    LN_TP: 101,    //стопа
    LN_KS: 102,    //косая сажень
    LN_PT: 103,    //пясть
    LN_VR: 104,    //вершок
    LN_NK: 105,    //нокоть
    LN_LN: 106,    //линия
    LN_VL: 107,    //волос
    LN_VS: 108,    //волосок
    LN_WS: 109,    //верста
    LN_SW: 110,    //столбовая верста
    LN_MW: 111,    //мерная верста
    LN_CM: 112,    //старорусская миля

    //mass ids:
    MS_KG: 120,    //килограмм
    MS_GR: 121,    //грамм
    MS_TN: 122,    //тонна
    MS_CT: 123,    //центнер
    //
    MS_BR: 126, // берковец 
    MS_PD: 127, // пуд 
    MS_LT: 128, // лот 
    MS_GL: 129, // золотник 
    MS_DL: 130, // доля 
    //
    MS_ST: 131, // стоун 
    MS_FN: 132, // фунт 
    MS_NC: 133, // унция 
    MS_DR: 134, // драхма 
    //
    MS_AE: 135, // Атомная единица массы 
    MS_KR: 136, // Карат 

    //thermotemp ids:
    TT_KL: 150, // кельвин

    //subst ids:
    SB_ML: 160, // моль

    //power_light ids:
    PW_KN: 170, // кандела

    //flat_angle ids:
    FL_RD: 180, // радиан
    FL_GR: 181, // градус

    //solid_angle ids:
    SL_TL: 190, // телесный угол

    //square ids:
    SQ_MT: 200, // квадратный метр 
    SQ_GA: 201, // гектар 
    SQ_AR: 202, // ар 
    //
    SQ_KV: 203, // квадратная верста 
    SQ_TN: 204, // десятина 
    SQ_KP: 205, // копна 
    SQ_KS: 206, // квадратная сажень 
    //
    SQ_KR: 207, // акр 
    SQ_RD: 208, // руд 
    SQ_KT: 209, // квадратный род 
    SQ_KD: 210, // квадратный ярд 
    SQ_KF: 211, // квадратный фут 
    SQ_CD: 212, // квадратный дюйм 

    //volume ids:
    VL_MT: 220, // кубический метр
    VL_LT: 221, // литр

    //specific_volume ids:
    SV_MK: 230,  // кубический метр на килограмм

    //density ids:
    DN_KM: 240, // килограмм на кубический метр

    //angular_velocity ids:
    AS_KM: 250, // радиан в секунду

    //pressure ids:
    PR_PA: 260, // Паскаль
    PR_KP: 261, // килопаскаль
    PR_MP: 262, // мегапаскаль
    PR_GP: 263, // гигапаскаль
    //7
    PR_BR: 264, // бар
    PR_KS: 265, // килограмм-сила на квадратный сантиметр
    PR_KM: 266, // килограмм-сила на квадратный миллиметр
    PR_MV: 267, // миллиметр водяного столба
    PR_MR: 268, // миллиметр ртутного столба
    PR_TR: 269, // торр

    //angular_acc ids:
    AA_RS: 280, // радиан на секунду в квадрате

    //power ids:
    FR_NT: 290, // ньютон
    //
    FR_DN: 291, // дина
    //
    FR_GS: 292, // грамм-сила
    FR_KS: 293, // килограмм-сила
    FR_TS: 294, // тонна-сила

    //moment_power ids:
    MP_NM: 300, // ньютон-метр

    //quantity_heat ids:
    WR_DJ: 310, // джоуль
    WR_KD: 311, // килоджоуль
    WR_MD: 312, // мегаджоуль
    //
    WR_ER: 313, // эрг
    WR_EV: 314, // электрон-вольт
    WR_EC: 315, // киловатт-час

    //amount_heat ids:
    AH_KL: 316, //калория

    //specific_energy ids:
    SE_DK: 320, // джоуль на килограмм

    //const energy ids:
    PW_WT: 330, // ватт
    PW_KW: 331, // киловатт
    PW_MW: 332, // мегаватт
    //
    PW_VA: 333, // вольт-ампер
    PW_LS: 334, // лошадиная сила

    //volume_energy ids:
    FL_VT: 340, // ватт на кубический метр

    //frequency ids:
    FR_GC: 350, // герц
    //
    FR_OS: 351, // оборот в секунду
    FR_OM: 352, // оборот в минуту

    //wave_number
    WV_MT: 360, // метр в минус первой степени

    //dyn_viscosity ids:
    DV_PS: 370, // паскаль-секунда
    //
    DV_PZ: 371, // пуаз

    //kin_viscosity ids:
    KV_ST: 372, // стокс

    //heat_system ids:
    HS_DK: 380, // джоуль на кельвин

    //thermal_cond ids:
    TC_MT: 390, // ватт на метр - кельвин

    //specific_heat ids:
    SH_DK: 400, // джоуль на килограмм-кельвин

    //molar_conc ids:
    MC_ML: 410, // моль на кубический метр

    //molar_entropy ids:
    ME_DM: 420, // джоуль на моль-кельвин

    //surface_tension ids:
    ST_NM: 430, // ньютон на метр

    //electric_charge ids:
    EC_KL: 440, // кулон
    //
    EC_AC: 450, // ампер-час

    //charge_density ids:
    CD_KL: 460, // кулон на квадратный метр

    //volume_density ids:
    VD_KL: 470, // кулон на кубический метр

    //field_strength ids:
    FS_NK: 480, // ньютон на кулон
    FS_VM: 481, // вольт на метр

    //electrical_voltage ids:
    EV_VL: 490, // вольт
    EV_KV: 491, // киловольт

    //electrical_resist ids:
    ER_OM: 500, // Ом
    ER_KM: 501, // килоом

    //resistivity ids:
    RS_OM: 510, // ом на метр
    //
    RS_MM: 511, // ом-квадратный миллиметр на метр

    //electrical_cond ids:
    EC_SM: 520, // сименс

    //electrical_cap ids:
    EP_FR: 530, // фарад
    EP_PF: 531, // пикофарад
    EP_NF: 532, // нанофарад

    //electric_den ids:
    ED_AM: 540, // ампер на квадратный метр

    //dielec_const ids:
    DC_FM: 550, // фарад на метр

    //magnetic_per ids:
    MP_GN: 560, // генри на метр

    //magnetic_field ids:
    MF_AM: 570, // ампер на метр
    MF_ST: 571, // эрстед

    //magnetic_flux ids:
    MX_VB: 580, // вебер
    MX_MX: 581, // максвелл

    //electric_strenght ids:
    EL_AM: 590, // ампер
    EL_MA: 591, // миллиампер

    //inductance ids:
    ND_GN: 600, // генри

    //illumination ids:
    IL_LK: 610, // люкс

    //light_flow ids:
    LF_LM: 620, // люмен

    //radiation_strength ids:
    RD_VS: 630, // ватт на стерадиан

    //energy_bright ids:
    EB_VS: 640, // ватт на стерадиан - квадратный метр

    //brightness ids:
    BN_KD: 650, // кандела на квадратный метр
    //
    BN_NT: 660, // нит

    //nuclide_act ids:
    NA_BR: 670, // беккерель
    //
    NA_KR: 680, // кюри

    //temp ids:
    TM_CL: 690, // градус Цельсия
    TM_KL: 691, // градус Кельвина
    //
    TM_FR: 692, // градус Фаренгейта

    //dose_rate ids:
    DR_GS: 700, // грэй в секунду

    //pulse ids:
    PL_KM: 710, // килограмм на метр в секунду

    //magnetic_ind ids:
    MI_TS: 720, // тесла
    MI_GS: 721, // гаусс


    //speed ids:
    SP_MS: 730, // метр в секунду
    //
    SP_KM: 740, // километр в час
    SP_UZ: 741, // узел

    //accelerat ids:
    AC_MS: 750, // метр на секунду в квадрате

    //exposure_dose ids:
    EX_KK: 760, // кулон на килограмм

    //temp_coef ids:
    TF_KL: 770, // обратный кельвин

    //heat_melt ids:
    HM_KL: 780, //джоуль на моль

};

const MST = MEASURE_SYSTEM;
const PC = PHYS_CATEGORY;

const timeUnits = [
    //MST.SI
    {uid: UNIT_IDS.TM_SC, category: PC.TIME, mSystem: MST.SI, chars: ['t'], name: 'секунда', ruUnit: 'с', enUnit: 's', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
    {uid: UNIT_IDS.TM_ML, category: PC.TIME, mSystem: MST.SI, chars: ['t'], name: 'миллисекунда', ruUnit: 'мс', enUnit: 'ms', isBasic: false, ref: UNIT_IDS.TM_SC, relRef: null, value: 0.001, order: 0},
    {uid: UNIT_IDS.TM_MK, category: PC.TIME, mSystem: MST.SI, chars: ['t'], name: 'микросекунда', ruUnit: 'мкс', enUnit: 'mks', isBasic: false, ref: UNIT_IDS.TM_SC, relRef: null, value: 1, order: -6},
    {uid: UNIT_IDS.TM_NC, category: PC.TIME, mSystem: MST.SI, chars: ['t'], name: 'наносекунда', ruUnit: 'нс', enUnit: 'ns', isBasic: false, ref: UNIT_IDS.TM_SC, relRef: null, value: 1, order: -9},
    //MST.MISC
    {uid: UNIT_IDS.TM_MN, category: PC.TIME, mSystem: MST.MISC, chars: ['t'], name: 'минута', ruUnit: 'мин', enUnit: 's', isBasic: false, ref: UNIT_IDS.TM_SC, relRef: null, value: 60, order: 0},
    {uid: UNIT_IDS.TM_HR, category: PC.TIME, mSystem: MST.MISC, chars: ['t'], name: 'час', ruUnit: 'ч', enUnit: 'ms', isBasic: false, ref: UNIT_IDS.TM_SC, relRef: null, value: 3600, order: 0},
    {uid: UNIT_IDS.TM_CT, category: PC.TIME, mSystem: MST.MISC, chars: ['t'], name: 'сутки', ruUnit: 'сут', enUnit: 'mks', isBasic: false, ref: UNIT_IDS.TM_SC, relRef: null, value: 86400, order: 0},
];

const surface_energyUnits = [
    {uid: UNIT_IDS.SF_VM, category: PC.SURFACE_ENERGY, mSystem: MST.SI, chars: ['w'], name: 'Ватт на квадратный метр', ruUnit: 'Вт/м^+2^+', enUnit: 's', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const lengthUnits = [
    //MST.SI
    {uid: UNIT_IDS.LN_MT, category: PC.LENGTH, mSystem: MST.SI, chars: ['S','L','r'], name: 'метр', ruUnit: 'м', enUnit: 'м', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
    {uid: UNIT_IDS.LN_NM, category: PC.LENGTH, mSystem: MST.SI, chars: ['S','L','r'], name: 'нанометр', ruUnit: 'нм', enUnit: 'нм', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 1, order: -9},
    {uid: UNIT_IDS.LN_KM, category: PC.LENGTH, mSystem: MST.SI, chars: ['S','L','r'], name: 'километр', ruUnit: 'км', enUnit: 'kм', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 1000, order: 0},
    {uid: UNIT_IDS.LN_SM, category: PC.LENGTH, mSystem: MST.SI, chars: ['S','L','r'], name: 'сантиметр', ruUnit: 'см', enUnit: 'sм', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 0.01, order: 0},
    {uid: UNIT_IDS.LN_MM, category: PC.LENGTH, mSystem: MST.SI, chars: ['S','L','r'], name: 'миллиметр', ruUnit: 'мм', enUnit: 'sм', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 0.001, order: 0},
    {uid: UNIT_IDS.LN_MKM, category: PC.LENGTH, mSystem: MST.SI, chars: ['S','L','r'], name: 'микрон', ruUnit: 'мкм', enUnit: 'sм', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 1, order: -6},
    {uid: UNIT_IDS.LN_DM, category: PC.LENGTH, mSystem: MST.SI, chars: ['S','L','r'], name: 'дециметр', ruUnit: 'дм', enUnit: 'sм', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 0.1, order: 0},
    {uid: UNIT_IDS.LN_A, category: PC.LENGTH, mSystem: MST.SI, chars: ['S','L','r'], name: 'ангстрем', ruUnit: 'А', enUnit: 'sм', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 1, order: -10},
    {uid: UNIT_IDS.LN_AE, category: PC.LENGTH, mSystem: MST.SI, chars: ['S','L','r'], name: 'астрономическая единица', ruUnit: 'а.е.', enUnit: 'au', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 150, order: 9},
    {uid: UNIT_IDS.LN_LY, category: PC.LENGTH, mSystem: MST.SI, chars: ['S','L','r'], name: 'световой год', ruUnit: 'св.год', enUnit: 'ly', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 9.46, order: 15},
    {uid: UNIT_IDS.LN_PK, category: PC.LENGTH, mSystem: MST.SI, chars: ['S','L','r'], name: 'парсек', ruUnit: 'пк', enUnit: 'pc', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: UNIT_IDS.LN_LY, value: 3.087, order: 16, relValue: 3.2616, relOrder: 0},
    //MST.OLD_RUS
    {uid: UNIT_IDS.LN_KR, category: PC.LENGTH, mSystem: MST.OLD_RUS, chars: ['S','L','r'], name: 'круг', ruUnit: 'круг', enUnit: 'круг', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 2.8448, order: 0},
    {uid: UNIT_IDS.LN_PD, category: PC.LENGTH, mSystem: MST.OLD_RUS, chars: ['S','L','r'], name: 'пядь', ruUnit: 'пядь', enUnit: 'пядь', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 0.1778, order: 0},
    {uid: UNIT_IDS.LN_LK, category: PC.LENGTH, mSystem: MST.OLD_RUS, chars: ['S','L','r'], name: 'локоть', ruUnit: 'локоть', enUnit: 'локоть', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 0.5334, order: 0},
    {uid: UNIT_IDS.LN_AR, category: PC.LENGTH, mSystem: MST.OLD_RUS, chars: ['S','L','r'], name: 'аршин', ruUnit: 'аршин', enUnit: 'аршин', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 0.7112, order: 0},
    {uid: UNIT_IDS.LN_SH, category: PC.LENGTH, mSystem: MST.OLD_RUS, chars: ['S','L','r'], name: 'шаг', ruUnit: 'шаг', enUnit: 'шаг', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 0.889, order: 0},
    {uid: UNIT_IDS.LN_MR, category: PC.LENGTH, mSystem: MST.OLD_RUS, chars: ['S','L','r'], name: 'мера, полсаженя', ruUnit: 'мера', enUnit: 'мера', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 1.0668, order: 0},
    {uid: UNIT_IDS.LN_LB, category: PC.LENGTH, mSystem: MST.OLD_RUS, chars: ['S','L','r'], name: 'лоб', ruUnit: 'лоб', enUnit: 'лоб', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 1.2446, order: 0},
    {uid: UNIT_IDS.LN_ST, category: PC.LENGTH, mSystem: MST.OLD_RUS, chars: ['S','L','r'], name: 'столбец', ruUnit: 'столбец', enUnit: 'столбец', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 1.4224, order: 0},
    {uid: UNIT_IDS.LN_PS, category: PC.LENGTH, mSystem: MST.OLD_RUS, chars: ['S','L','r'], name: 'посох', ruUnit: 'посох', enUnit: 'посох', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 1.6002, order: 0},
    {uid: UNIT_IDS.LN_VP, category: PC.LENGTH, mSystem: MST.OLD_RUS, chars: ['S','L','r'], name: 'витой посох', ruUnit: 'витой посох', enUnit: 'витой посох', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 1.778, order: 0},
    {uid: UNIT_IDS.LN_SJ, category: PC.LENGTH, mSystem: MST.OLD_RUS, chars: ['S','L','r'], name: 'сажень', ruUnit: 'сажень', enUnit: 'сажень', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 2.1336, order: 0},
    {uid: UNIT_IDS.LN_TP, category: PC.LENGTH, mSystem: MST.OLD_RUS, chars: ['S','L','r'], name: 'стопа', ruUnit: 'стопа', enUnit: 'стопа', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 0.3556, order: 0},
    {uid: UNIT_IDS.LN_KS, category: PC.LENGTH, mSystem: MST.OLD_RUS, chars: ['S','L','r'], name: 'косая сажень', ruUnit: 'косая сажень', enUnit: 'косая сажень', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 3.0226, order: 0},
    {uid: UNIT_IDS.LN_PT, category: PC.LENGTH, mSystem: MST.OLD_RUS, chars: ['S','L','r'], name: 'пясть', ruUnit: 'пясть', enUnit: 'пясть', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 0.0889, order: 0},
    {uid: UNIT_IDS.LN_VR, category: PC.LENGTH, mSystem: MST.OLD_RUS, chars: ['S','L','r'], name: 'вершок', ruUnit: 'вершок', enUnit: 'вершок', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 0.0445, order: 0},
    {uid: UNIT_IDS.LN_NK, category: PC.LENGTH, mSystem: MST.OLD_RUS, chars: ['S','L','r'], name: 'нокоть', ruUnit: 'нокоть', enUnit: 'нокоть', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 0.0111, order: 0},
    {uid: UNIT_IDS.LN_LN, category: PC.LENGTH, mSystem: MST.OLD_RUS, chars: ['S','L','r'], name: 'линия', ruUnit: 'линия', enUnit: 'линия', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 0.695, order: -3},
    {uid: UNIT_IDS.LN_VL, category: PC.LENGTH, mSystem: MST.OLD_RUS, chars: ['S','L','r'], name: 'волос', ruUnit: 'волос', enUnit: 'волос', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 0.434, order: -4},
    {uid: UNIT_IDS.LN_VS, category: PC.LENGTH, mSystem: MST.OLD_RUS, chars: ['S','L','r'], name: 'волосок', ruUnit: 'волосок', enUnit: 'волосок', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 0.271, order: -5},
    {uid: UNIT_IDS.LN_WS, category: PC.LENGTH, mSystem: MST.OLD_RUS, chars: ['S','L','r'], name: 'верста', ruUnit: 'верста', enUnit: 'верста', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 1066.8, order: 0},
    {uid: UNIT_IDS.LN_SW, category: PC.LENGTH, mSystem: MST.OLD_RUS, chars: ['S','L','r'], name: 'столбовая верста', ruUnit: 'столбовая верста', enUnit: 'столбовая верста', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 1517.41632, order: 0},
    {uid: UNIT_IDS.LN_MW, category: PC.LENGTH, mSystem: MST.OLD_RUS, chars: ['S','L','r'], name: 'мерная верста', ruUnit: 'мерная верста', enUnit: 'мерная верста', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 2, order: 0},
    {uid: UNIT_IDS.LN_CM, category: PC.LENGTH, mSystem: MST.OLD_RUS, chars: ['S','L','r'], name: 'старорусская миля', ruUnit: 'старорус. миля', enUnit: 'старорус. миля', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 7, order: 0},
    //MST.ENG
    {uid: UNIT_IDS.LN_RD, category: PC.LENGTH, mSystem: MST.ENG, chars: ['S','L','r'], name: 'род', ruUnit: 'род', enUnit: 'род', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 5.0292, order: 0},
    {uid: UNIT_IDS.LN_LG, category: PC.LENGTH, mSystem: MST.ENG, chars: ['S','L','r'], name: 'лига', ruUnit: 'лига', enUnit: 'лига', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 4, order: 0},
    {uid: UNIT_IDS.LN_KB, category: PC.LENGTH, mSystem: MST.ENG, chars: ['S','L','r'], name: 'кабельтов', ruUnit: 'кабельтов', enUnit: 'кабельтов', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 185.3182, order: 0},
    {uid: UNIT_IDS.LN_UM, category: PC.LENGTH, mSystem: MST.ENG, chars: ['S','L','r'], name: 'уставная миля', ruUnit: 'уставная миля', enUnit: 'уставная миля', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 1609.344, order: 0},
    {uid: UNIT_IDS.LN_FL, category: PC.LENGTH, mSystem: MST.ENG, chars: ['S','L','r'], name: 'фарлонг', ruUnit: 'фарлонг', enUnit: 'фарлонг', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 201.168, order: 0},
    {uid: UNIT_IDS.LN_CH, category: PC.LENGTH, mSystem: MST.ENG, chars: ['S','L','r'], name: 'чейн', ruUnit: 'чейн', enUnit: 'чейн', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 20.1168, order: 0},
    {uid: UNIT_IDS.LN_SL, category: PC.LENGTH, mSystem: MST.ENG, chars: ['S','L','r'], name: 'морская миля', ruUnit: 'морская миля', enUnit: 'морская миля', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 1852, order: 0},
    {uid: UNIT_IDS.LN_RR, category: PC.LENGTH, mSystem: MST.ENG, chars: ['S','L','r'], name: 'ярд', ruUnit: 'ярд', enUnit: 'ярд', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 0.9144, order: 0},
    {uid: UNIT_IDS.LN_FT, category: PC.LENGTH, mSystem: MST.ENG, chars: ['S','L','r'], name: 'фут', ruUnit: 'фут', enUnit: 'фут', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 0.3048, order: 0},
    {uid: UNIT_IDS.LN_HN, category: PC.LENGTH, mSystem: MST.ENG, chars: ['S','L','r'], name: 'хэнд', ruUnit: 'хэнд', enUnit: 'хэнд', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 0.1016, order: 0},
    {uid: UNIT_IDS.LN_DS, category: PC.LENGTH, mSystem: MST.ENG, chars: ['S','L','r'], name: 'дюйм', ruUnit: 'дюйм', enUnit: 'дюйм', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 0.0254, order: 0},
    {uid: UNIT_IDS.LN_TH, category: PC.LENGTH, mSystem: MST.ENG, chars: ['S','L','r'], name: 'точка', ruUnit: 'точка', enUnit: 'точка', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 0.353, order: -4},
    //MST.FRENCH
    {uid: UNIT_IDS.LN_FS, category: PC.LENGTH, mSystem: MST.FRENCH, chars: ['S','L','r'], name: 'французская сухопутная миля ', ruUnit: 'фр. сух. миля', enUnit: 'фр. сух. миля', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 4444.4, order: 0},
    //MST.MISC
    {uid: UNIT_IDS.LN_GM, category: PC.LENGTH, mSystem: MST.MISC, chars: ['S','L','r'], name: 'географическая, геометрическая миля', ruUnit: 'геогр., геом. миля', enUnit: 'геогр., геом. миля', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 1853.25, order: 0},
    {uid: UNIT_IDS.LN_DR, category: PC.LENGTH, mSystem: MST.MISC, chars: ['S','L','r'], name: 'древнеримская миля ', ruUnit: 'миллиатрий', enUnit: 'миллиатрий', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 1598 , order: 0},
    {uid: UNIT_IDS.LN_DN, category: PC.LENGTH, mSystem: MST.MISC, chars: ['S','L','r'], name: 'немецкая миля', ruUnit: 'нем. миля', enUnit: 'нем. миля', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 7, order: 0},
    {uid: UNIT_IDS.LN_NV, category: PC.LENGTH, mSystem: MST.MISC, chars: ['S','L','r'], name: 'норвежская (шведская) миля ', ruUnit: 'норв. (швед.) миля', enUnit: 'норв. (швед.) миля', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 10, order: 0},
    //MST.GRAPH
    {uid: UNIT_IDS.LN_PC, category: PC.LENGTH, mSystem: MST.GRAPH, chars: ['S','L','r'], name: 'пиксель', ruUnit: 'px', enUnit: 'px', isBasic: false, ref: UNIT_IDS.LN_MT, relRef: null, value: 0.00026358723, order: 0},
];

const massUnits = [
    //MST.SI
    {uid: UNIT_IDS.MS_KG, category: PC.MASS, mSystem: MST.SI, chars: ['m'], name: 'килограмм', ruUnit: 'кг', enUnit: 'kg', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
    {uid: UNIT_IDS.MS_GR, category: PC.MASS, mSystem: MST.SI, chars: ['m'], name: 'грамм', ruUnit: 'г', enUnit: 'g', isBasic: false, ref: UNIT_IDS.MS_KG, relRef: null, value: 0.001, order: 0},
    {uid: UNIT_IDS.MS_TN, category: PC.MASS, mSystem: MST.SI, chars: ['m'], name: 'тонна', ruUnit: 'т', enUnit: 't', isBasic: false, ref: UNIT_IDS.MS_KG, relRef: null, value: 1000, order: 0},
    {uid: UNIT_IDS.MS_CT, category: PC.MASS, mSystem: MST.SI, chars: ['m'], name: 'центнер', ruUnit: 'ц', enUnit: 'c', isBasic: false, ref: UNIT_IDS.MS_KG, relRef: null, value: 100, order: 0},
    //MST.OLD_RUS
    {uid: UNIT_IDS.MS_BR, category: PC.MASS, mSystem: MST.OLD_RUS, chars: ['m'], name: 'берковец', ruUnit: 'берковец', enUnit: 'берковец', isBasic: false, ref: UNIT_IDS.MS_KG, relRef: null, value: 164, order: 0},
    {uid: UNIT_IDS.MS_PD, category: PC.MASS, mSystem: MST.OLD_RUS, chars: ['m'], name: 'пуд', ruUnit: 'пуд', enUnit: 'пуд', isBasic: false, ref: UNIT_IDS.MS_KG, relRef: null, value: 16.3804815, order: 0},
    {uid: UNIT_IDS.MS_LT, category: PC.MASS, mSystem: MST.OLD_RUS, chars: ['m'], name: 'лот', ruUnit: 'лот', enUnit: 'лот', isBasic: false, ref: UNIT_IDS.MS_KG, relRef: null, value: 0.012797251, order: 0},
    {uid: UNIT_IDS.MS_GL, category: PC.MASS, mSystem: MST.OLD_RUS, chars: ['m'], name: 'золотник', ruUnit: 'золотник', enUnit: 'золотник', isBasic: false, ref: UNIT_IDS.MS_KG, relRef: null, value: 0.004265754, order: 0},
    {uid: UNIT_IDS.MS_DL, category: PC.MASS, mSystem: MST.OLD_RUS, chars: ['m'], name: 'доля', ruUnit: 'доля', enUnit: 'доля', isBasic: false, ref: UNIT_IDS.MS_KG, relRef: null, value: 0.00000444, order: 0},
    //MST.ENG
    {uid: UNIT_IDS.MS_ST, category: PC.MASS, mSystem: MST.ENG, chars: ['m'], name: 'стоун', ruUnit: 'стоун', enUnit: 'стоун', isBasic: false, ref: UNIT_IDS.MS_KG, relRef: null, value: 6.35029318, order: 0},
    {uid: UNIT_IDS.MS_FN, category: PC.MASS, mSystem: MST.ENG, chars: ['m'], name: 'фунт', ruUnit: 'фунт', enUnit: 'фунт', isBasic: false, ref: UNIT_IDS.MS_KG, relRef: null, value: 0.45359237, order: 0},
    {uid: UNIT_IDS.MS_NC, category: PC.MASS, mSystem: MST.ENG, chars: ['m'], name: 'унция', ruUnit: 'унция', enUnit: 'унция', isBasic: false, ref: UNIT_IDS.MS_KG, relRef: null, value: 0.028349523, order: 0},
    {uid: UNIT_IDS.MS_DR, category: PC.MASS, mSystem: MST.ENG, chars: ['m'], name: 'драхма', ruUnit: 'драхма', enUnit: 'драхма', isBasic: false, ref: UNIT_IDS.MS_KG, relRef: null, value: 0.001771845, order: 0},
    //MST.MISC
    {uid: UNIT_IDS.MS_AE, category: PC.MASS, mSystem: MST.MISC, chars: ['m'], name: 'Атомная единица массы', ruUnit: 'а.е.м.', enUnit: 'а.е.м.', isBasic: false, ref: UNIT_IDS.MS_KG, relRef: null, value: 1.66, order: -28},
    {uid: UNIT_IDS.MS_KR, category: PC.MASS, mSystem: MST.MISC, chars: ['m'], name: 'Карат', ruUnit: 'Кар', enUnit: 'Кар', isBasic: false, ref: UNIT_IDS.MS_KG, relRef: null, value: 0.0002, order: 0},
];

const thermo_tempUnits = [
    //MST.SI
    {uid: UNIT_IDS.TT_KL, category: PC.THERMO_TEMP, mSystem: MST.SI, chars: ['Т'], name: 'кельвин', ruUnit: 'К', enUnit: 'К', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const quantity_substUnits = [
    //MST.SI
    {uid: UNIT_IDS.SB_ML, category: PC.QUANTITY_SUBST, mSystem: MST.SI, chars: ['м'], name: 'моль', ruUnit: 'моль', enUnit: 'моль', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const power_lightUnits = [
    //MST.SI
    {uid: UNIT_IDS.PW_KN, category: PC.POWER_LIGHT, mSystem: MST.SI, chars: ['J'], name: 'кандела', ruUnit: 'кд', enUnit: 'кд', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const flat_angleUnits = [
    //MST.SI
    {uid: UNIT_IDS.FL_RD, category: PC.FLAT_ANGLE, mSystem: MST.SI, chars: ['∠'], name: 'радиан', ruUnit: 'рад', enUnit: 'рад', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
    {uid: UNIT_IDS.FL_GR, category: PC.FLAT_ANGLE, mSystem: MST.SI, chars: ['∠'], name: 'градус', ruUnit: 'град', enUnit: 'град', isBasic: false, ref: UNIT_IDS.FL_RD, relRef: null, value: 3.1415, order: 0},
];

const solid_angleUnits = [
    //MST.SI
    {uid: UNIT_IDS.SL_TL, category: PC.SOLID_ANGLE, mSystem: MST.SI, chars: ['∠'], name: 'стерадиан', ruUnit: 'ср', enUnit: 'ср', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const squareUnits = [
    //MST.SI
    {uid: UNIT_IDS.SQ_MT, category: PC.SQUARE, mSystem: MST.SI, chars: ['S'], name: 'квадратный метр', ruUnit: 'м^+2^+', enUnit: 'м^+2^+', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
    {uid: UNIT_IDS.SQ_GA, category: PC.SQUARE, mSystem: MST.SI, chars: ['S'], name: 'гектар', ruUnit: 'га', enUnit: 'га', isBasic: false, ref: UNIT_IDS.SQ_MT, relRef: null, value: 10000, order: 0},
    {uid: UNIT_IDS.SQ_AR, category: PC.SQUARE, mSystem: MST.SI, chars: ['S'], name: 'ар', ruUnit: 'а', enUnit: 'а', isBasic: false, ref: UNIT_IDS.SQ_MT, relRef: null, value: 100, order: 0},
    //MST.OLD_RUS
    {uid: UNIT_IDS.SQ_KV, category: PC.SQUARE, mSystem: MST.OLD_RUS, chars: ['S'], name: 'квадратная верста', ruUnit: 'квадратная верста', enUnit: 'квадратная верста', isBasic: false, ref: UNIT_IDS.SQ_MT, relRef: null, value: 114, order: 4},
    {uid: UNIT_IDS.SQ_TN, category: PC.SQUARE, mSystem: MST.OLD_RUS, chars: ['S'], name: 'десятина', ruUnit: 'десятина', enUnit: 'десятина', isBasic: false, ref: UNIT_IDS.SQ_MT, relRef: null, value: 10, order: 0},
    {uid: UNIT_IDS.SQ_KP, category: PC.SQUARE, mSystem: MST.OLD_RUS, chars: ['S'], name: 'копна', ruUnit: 'копна', enUnit: 'копна', isBasic: false, ref: UNIT_IDS.SQ_MT, relRef: null, value: 1092.54, order: 0},
    {uid: UNIT_IDS.SQ_KS, category: PC.SQUARE, mSystem: MST.OLD_RUS, chars: ['S'], name: 'квадратная сажень', ruUnit: 'квадратная сажень', enUnit: 'квадратная сажень', isBasic: false, ref: UNIT_IDS.SQ_MT, relRef: null, value: 4.55224, order: 0},
    //MST.ENG
    {uid: UNIT_IDS.SQ_KR, category: PC.SQUARE, mSystem: MST.ENG, chars: ['S'], name: 'акр', ruUnit: 'акр', enUnit: 'акр', isBasic: false, ref: UNIT_IDS.SQ_MT, relRef: null, value: 4, order: 0},
    {uid: UNIT_IDS.SQ_RD, category: PC.SQUARE, mSystem: MST.ENG, chars: ['S'], name: 'руд', ruUnit: 'руд', enUnit: 'руд', isBasic: false, ref: UNIT_IDS.SQ_MT, relRef: null, value: 1011.71, order: 0},
    {uid: UNIT_IDS.SQ_KT, category: PC.SQUARE, mSystem: MST.ENG, chars: ['S'], name: 'квадратный род', ruUnit: 'род^+2^+', enUnit: 'род^+2^+', isBasic: false, ref: UNIT_IDS.SQ_MT, relRef: null, value: 25.293, order: 0},
    {uid: UNIT_IDS.SQ_KD, category: PC.SQUARE, mSystem: MST.ENG, chars: ['S'], name: 'квадратный ярд', ruUnit: 'ярд^+2^+', enUnit: 'ярд^+2^+', isBasic: false, ref: UNIT_IDS.SQ_MT, relRef: null, value: 0.8313, order: 0},
    {uid: UNIT_IDS.SQ_KF, category: PC.SQUARE, mSystem: MST.ENG, chars: ['S'], name: 'квадратный фут', ruUnit: 'фут^+2^+', enUnit: 'фут^+2^+', isBasic: false, ref: UNIT_IDS.SQ_MT, relRef: null, value: 0.092903, order: 0},
    {uid: UNIT_IDS.SQ_CD, category: PC.SQUARE, mSystem: MST.ENG, chars: ['S'], name: 'квадратный дюйм', ruUnit: 'дюйм^+2^+', enUnit: 'дюйм^+2^+', isBasic: false, ref: UNIT_IDS.SQ_MT, relRef: null, value: 0.000645, order: 0},
];

const volumeUnits = [
    //MST.SI
    {uid: UNIT_IDS.VL_MT, category: PC.VOLUME, mSystem: MST.SI, chars: ['V'], name: 'кубический метр', ruUnit: 'м^+3^+', enUnit: 'м^+3^+', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
    //MST.MISC
    {uid: UNIT_IDS.VL_LT, category: PC.VOLUME, mSystem: MST.MISC, chars: ['V'], name: 'литр', ruUnit: 'л', enUnit: 'л', isBasic: true, ref: null, relRef: null, value: 1, order: -3},
];

const specific_volumeUnits = [
    //MST.SI
    {uid: UNIT_IDS.SV_MK, category: PC.SPECIFIC_VOLUME, mSystem: MST.SI, chars: ['v'], name: 'кубический метр на килограмм', ruUnit: 'м^+3^+', enUnit: 'м^+3^+/кг', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const densityUnits = [
    //MST.SI
    {uid: UNIT_IDS.DN_KM, category: PC.DENSITY, mSystem: MST.SI, chars: ['ρ'], name: 'килограмм на кубический метр', ruUnit: 'кг/м^+3^+', enUnit: 'кг/м^+3^+', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const angular_velocityUnits = [
    //MST.SI
    {uid: UNIT_IDS.AS_KM, category: PC.ANGULAR_VELOCITY, mSystem: MST.SI, chars: ['ω'], name: 'радиан в секунду', ruUnit: 'рад/с', enUnit: 'рад/с', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const pressureUnits = [
    //MST.SI
    {uid: UNIT_IDS.PR_PA, category: PC.PRESSURE, mSystem: MST.SI, chars: ['P'], name: 'Паскаль', ruUnit: 'Па', enUnit: 'Па', isBasic: true, ref: null, relRef: null, value: 1, order: 0, calc: '1 Па = 1 Н/м^+2^+ = 1 кг/(м·с^+2^+)', detail: 'Давление'},
    {uid: UNIT_IDS.PR_KP, category: PC.PRESSURE, mSystem: MST.SI, chars: ['P'], name: 'килопаскаль', ruUnit: 'кПа', enUnit: 'кПа', isBasic: false, ref: UNIT_IDS.PR_PA, relRef: null, value: 1, order: 3},
    {uid: UNIT_IDS.PR_MP, category: PC.PRESSURE, mSystem: MST.SI, chars: ['P'], name: 'мегапаскаль', ruUnit: 'МПа', enUnit: 'МПа', isBasic: false, ref: UNIT_IDS.PR_PA, relRef: null, value: 1, order: 6},
    {uid: UNIT_IDS.PR_GP, category: PC.PRESSURE, mSystem: MST.SI, chars: ['P'], name: 'гигапаскаль', ruUnit: 'ГПа', enUnit: 'ГПа', isBasic: false, ref: UNIT_IDS.PR_PA, relRef: null, value: 1, order: 9},
    //MST.MISC
    {uid: UNIT_IDS.PR_BR, category: PC.PRESSURE, mSystem: MST.MISC, chars: ['P'], name: 'бар', ruUnit: 'бар', enUnit: 'бар', isBasic: false, ref: UNIT_IDS.PR_PA, relRef: null, value: 1, order: 5},
    {uid: UNIT_IDS.PR_KS, category: PC.PRESSURE, mSystem: MST.MISC, chars: ['P'], name: 'килограмм-сила на квадратный сантиметр', ruUnit: 'кгс/см^+2^+', enUnit: 'кгс/см^+2^+', isBasic: false, ref: UNIT_IDS.PR_PA, relRef: null, value: 9.81, order: 4},
    {uid: UNIT_IDS.PR_KM, category: PC.PRESSURE, mSystem: MST.MISC, chars: ['P'], name: 'килограмм-сила на квадратный миллиметр', ruUnit: 'кгс/мм^+2^+', enUnit: 'кгс/мм^+2^+', isBasic: false, ref: UNIT_IDS.PR_PA, relRef: null, value: 9.81, order: 6},
    {uid: UNIT_IDS.PR_MV, category: PC.PRESSURE, mSystem: MST.MISC, chars: ['P'], name: 'миллиметр водяного столба', ruUnit: 'мм вод. ст.', enUnit: 'мм вод. ст.', isBasic: false, ref: UNIT_IDS.PR_PA, relRef: null, value: 98100, order: 0},
    {uid: UNIT_IDS.PR_MR, category: PC.PRESSURE, mSystem: MST.MISC, chars: ['P'], name: 'миллиметр ртутного столба', ruUnit: 'мм рт. ст.', enUnit: 'мм рт. ст.', isBasic: false, ref: UNIT_IDS.PR_PA, relRef: null, value: 133, order: 0},
    {uid: UNIT_IDS.PR_TR, category: PC.PRESSURE, mSystem: MST.MISC, chars: ['P'], name: 'торр', ruUnit: 'торр', enUnit: 'торр', isBasic: false, ref: UNIT_IDS.PR_PA, relRef: null, value: 133, order: 0},
];

const angular_accUnits = [
    //MST.SI
    {uid: UNIT_IDS.AA_RS, category: PC.ANGULAR_ACC, mSystem: MST.SI, chars: ['ε'], name: 'радиан на секунду в квадрате', ruUnit: 'рад/с^+2^+', enUnit: 'рад/с^+2^+', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const powerUnits = [
    //MST.SI
    {uid: UNIT_IDS.FR_NT, category: PC.POWER, mSystem: MST.SI, chars: ['F'], name: 'ньютон', ruUnit: 'Н', enUnit: 'Н', isBasic: true, ref: null, relRef: null, value: 1, order: 0, calc: '1 Н = 1 кг·м/c^+2^+', detail: 'Сила, вес'},
    //MST.SGS
    {uid: UNIT_IDS.FR_DN, category: PC.POWER, mSystem: MST.SGS, chars: ['F'], name: 'дина', ruUnit: 'дин', enUnit: 'дин', isBasic: false, ref: UNIT_IDS.FR_NT, relRef: null, value: 1, order: -5},
    //MST.MISC
    {uid: UNIT_IDS.FR_GS, category: PC.POWER, mSystem: MST.MISC, chars: ['F'], name: 'грамм-сила', ruUnit: 'гс', enUnit: 'гс', isBasic: false, ref: UNIT_IDS.FR_NT, relRef: null, value: 9.81, order: -3},
    {uid: UNIT_IDS.FR_KS, category: PC.POWER, mSystem: MST.MISC, chars: ['F'], name: 'килограмм-сила', ruUnit: 'кгс', enUnit: 'кгс', isBasic: false, ref: UNIT_IDS.FR_NT, relRef: null, value: 9.81, order: 0},
    {uid: UNIT_IDS.FR_TS, category: PC.POWER, mSystem: MST.MISC, chars: ['F'], name: 'тонна-сила', ruUnit: 'тс', enUnit: 'тс', isBasic: false, ref: UNIT_IDS.FR_NT, relRef: null, value: 9.810, order: 3},
];

const moment_powerUnits = [
    //MST.SI
    {uid: UNIT_IDS.MP_NM, category: PC.MOMENT_POWER, mSystem: MST.SI, chars: ['M'], name: 'ньютон-метр', ruUnit: 'Н·м', enUnit: 'Н·м', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const quantity_heatUnits = [
    //MST.SI
    {uid: UNIT_IDS.WR_DJ, category: PC.QUANTITY_HEAT, mSystem: MST.SI, chars: ['E','W'], name: 'джоуль', ruUnit: 'Дж', enUnit: 'Дж', isBasic: true, ref: null, relRef: null, value: 1, order: 0, calc: '1 Дж = 1 кг·м/с^+2^+', detail: 'Энергия, работа, количество теплоты'},
    {uid: UNIT_IDS.WR_KD, category: PC.QUANTITY_HEAT, mSystem: MST.SI, chars: ['E','W'], name: 'килоджоуль', ruUnit: 'кДж', enUnit: 'кДж', isBasic: false, ref: UNIT_IDS.WR_DJ, relRef: null, value: 1000, order: 0},
    {uid: UNIT_IDS.WR_MD, category: PC.QUANTITY_HEAT, mSystem: MST.SI, chars: ['E','W'], name: 'мегаджоуль', ruUnit: 'МДж', enUnit: 'МДж', isBasic: false, ref: UNIT_IDS.WR_DJ, relRef: null, value: 1, order: 6},
    //MST.MISC
    {uid: UNIT_IDS.WR_ER, category: PC.QUANTITY_HEAT, mSystem: MST.MISC, chars: ['E','W'], name: 'эрг', ruUnit: 'эрг', enUnit: 'эрг', isBasic: false, ref: UNIT_IDS.WR_DJ, relRef: null, value: 1, order: -7},
    {uid: UNIT_IDS.WR_EV, category: PC.QUANTITY_HEAT, mSystem: MST.MISC, chars: ['E','W'], name: 'электрон-вольт', ruUnit: 'эВ', enUnit: 'эВ', isBasic: false, ref: UNIT_IDS.WR_DJ, relRef: null, value: 1.6, order: -19},
    {uid: UNIT_IDS.WR_EC, category: PC.QUANTITY_HEAT, mSystem: MST.MISC, chars: ['E','W'], name: 'киловатт-час', ruUnit: 'кВТ·ч', enUnit: 'кВТ·ч', isBasic: false, ref: UNIT_IDS.WR_DJ, relRef: null, value: 3600000, order: 0},
];

const amount_heatUnits = [
    //MST.MISC
    {uid: UNIT_IDS.AH_KL, category: PC.AMOUNT_HEAT, mSystem: MST.MISC, chars: ['Q'], name: 'калория', ruUnit: 'кал', enUnit: 'кал', isBasic: false, ref: UNIT_IDS.WR_DJ, relRef: null, value:  4.1868, order: 0},
];

const specific_energyUnits = [
    //MST.SI
    {uid: UNIT_IDS.SE_DK, category: PC.SPECIFIC_ENERGY, mSystem: MST.SI, chars: ['w'], name: 'джоуль на килограмм', ruUnit: 'Дж/кг', enUnit: 'Дж/кг', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const energyUnits = [
    //MST.SI
    {uid: UNIT_IDS.PW_WT, category: PC.ENERGY, mSystem: MST.SI, chars: ['P','N'], name: 'ватт', ruUnit: 'Вт', enUnit: 'Вт', isBasic: true, ref: null, relRef: null, value: 1, order: 0, calc: '1 Вт = 1 Дж/с = 1 кг·м/с^+3^+', detail: 'Мощность'},
    {uid: UNIT_IDS.PW_KW, category: PC.ENERGY, mSystem: MST.SI, chars: ['P','N'], name: 'киловатт', ruUnit: 'кВт', enUnit: 'кВт', isBasic: false, ref: UNIT_IDS.PW_WT, relRef: null, value: 1000, order: 0},
    {uid: UNIT_IDS.PW_MW, category: PC.ENERGY, mSystem: MST.SI, chars: ['P','N'], name: 'мегаватт', ruUnit: 'МВт', enUnit: 'МВт', isBasic: false, ref: UNIT_IDS.PW_WT, relRef: null, value: 1, order: 6},
    //MST.MISC
    {uid: UNIT_IDS.PW_VA, category: PC.ENERGY, mSystem: MST.MISC, chars: ['P','N'], name: 'вольт-ампер', ruUnit: 'В·А', enUnit: 'В·А', isBasic: false, ref: UNIT_IDS.PW_WT, relRef: null, value: 1, order: 0},
    {uid: UNIT_IDS.PW_LS, category: PC.ENERGY, mSystem: MST.MISC, chars: ['P','N'], name: 'лошадиная сила', ruUnit: 'л. с.', enUnit: 'л. с.', isBasic: false, ref: UNIT_IDS.PW_WT, relRef: null, value: 735.499, order: 0},
];

const volume_energyUnits = [
    //MST.SI
    {uid: UNIT_IDS.FL_VT, category: PC.VOLUME_ENERGY, mSystem: MST.SI, chars: ['w'], name: 'ватт на кубический метр', ruUnit: 'Вт/м^+3^+', enUnit: 'Вт/м^+3^+', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const frequencyUnits = [
    //MST.SI
    {uid: UNIT_IDS.FR_GC, category: PC.FREQUENCY, mSystem: MST.SI, chars: ['n','f'], name: 'герц', ruUnit: 'Гц', enUnit: 'Гц', isBasic: true, ref: null, relRef: null, value: 1, order: 0, calc: '1 Гц = 1 с^-1^+', detail: 'Частота, частота вращения'},
    //MST.MISC
    {uid: UNIT_IDS.FR_OS, category: PC.FREQUENCY, mSystem: MST.MISC, chars: ['n','f'], name: 'оборот в секунду', ruUnit: 'об/с', enUnit: 'об/с', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
    {uid: UNIT_IDS.FR_OM, category: PC.FREQUENCY, mSystem: MST.MISC, chars: ['n','f'], name: 'оборот в минуту', ruUnit: 'об/мин', enUnit: 'об/мин', isBasic: false, ref: UNIT_IDS.FR_OS, relRef: null, value: 0.016666667, order: 0},
];

const wave_numberUnits = [
    //MST.SI
    {uid: UNIT_IDS.WV_MT, category: PC.WAVE_NUMBER, mSystem: MST.SI, chars: ['ω'], name: 'метр в минус первой степени', ruUnit: 'м^+-1^+', enUnit: 'м^+-1^+', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const dyn_viscosityUnits = [
    //MST.SI
    {uid: UNIT_IDS.DV_PS, category: PC.DYN_VISCOSITY, mSystem: MST.SI, chars: ['η'], name: 'паскаль-секунда', ruUnit: 'Па·с', enUnit: 'Па·с', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
    //MST.MISC
    {uid: UNIT_IDS.DV_PZ, category: PC.DYN_VISCOSITY, mSystem: MST.MISC, chars: ['П'], name: 'пуаз', ruUnit: 'П', enUnit: 'П', isBasic: false, ref: UNIT_IDS.DV_PS, relRef: null, value: 0.1, order: 0},
];

const kin_viscosityUnits = [
    //MST.MISC
    {uid: UNIT_IDS.KV_ST, category: PC.KIN_VISCOSITY, mSystem: MST.MISC, chars: ['v'], name: 'стокс', ruUnit: 'Ст', enUnit: 'Ст', isBasic: false, ref: UNIT_IDS.DV_PS, relRef: null, value: 0.0001, order: 0},
];

const heat_systemUnits = [
    //MST.SI
    {uid: UNIT_IDS.HS_DK, category: PC.HEAT_SYSTEM, mSystem: MST.SI, chars: ['S'], name: 'джоуль на кельвин', ruUnit: 'Дж/К', enUnit: 'Дж/К', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const thermal_condUnits = [
    //MST.SI
    {uid: UNIT_IDS.TC_MT, category: PC.THERMAL_COND, mSystem: MST.SI, chars: ['C'], name: 'ватт на метр - кельвин', ruUnit: 'Вт/(м·К)', enUnit: 'Вт/(м·К)', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const specific_heatUnits = [
    //MST.SI
    {uid: UNIT_IDS.SH_DK, category: PC.SPECIFIC_HEAT, mSystem: MST.SI, chars: ['c'], name: 'джоуль на килограмм-кельвин', ruUnit: 'Дж/(кг·К)', enUnit: 'Дж/(кг·К)', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const molar_concUnits = [
    //MST.SI
    {uid: UNIT_IDS.MC_ML, category: PC.MOLAR_CONC, mSystem: MST.SI, chars: ['C'], name: 'моль на кубический метр', ruUnit: 'моль/м^+3^+', enUnit: 'моль/м^+3^+', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const molar_entropyUnits = [
    //MST.SI
    {uid: UNIT_IDS.ME_DM, category: PC.MOLAR_ENTROPY, mSystem: MST.SI, chars: ['s'], name: 'джоуль на моль-кельвин', ruUnit: 'Дж/(моль·К)', enUnit: 'Дж/(моль·К)', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const surface_tensionUnits = [
    //MST.SI
    {uid: UNIT_IDS.ST_NM, category: PC.SURFACE_TENSION, mSystem: MST.SI, chars: ['P'], name: 'ньютон на метр', ruUnit: 'H/м', enUnit: 'H/м', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const electric_chargeUnits = [
    //MST.SI
    {uid: UNIT_IDS.EC_KL, category: PC.ELECTRIC_CHARGE, mSystem: MST.SI, chars: ['q'], name: 'кулон', ruUnit: 'Кл', enUnit: 'Кл', isBasic: true, ref: null, relRef: null, value: 1, order: 0, calc: '1 Кл = 1 А·с', detail: 'Электрический заряд, количество электричества'},
    //MST.MISC
    {uid: UNIT_IDS.EC_AC, category: PC.ELECTRIC_CHARGE, mSystem: MST.MISC, chars: ['q'], name: 'ампер-час', ruUnit: 'А·ч', enUnit: 'А·ч', isBasic: false, ref: UNIT_IDS.EC_KL, relRef: null, value: 3600000, order: 0},
];

const charge_densityUnits = [
    //MST.SI
    {uid: UNIT_IDS.CD_KL, category: PC.CHARGE_DENSITY, mSystem: MST.SI, chars: ['p'], name: 'кулон на квадратный метр', ruUnit: 'Кл/м^+2^+', enUnit: 'Кл/м^+2^+', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const volume_densityUnits = [
    //MST.SI
    {uid: UNIT_IDS.VD_KL, category: PC.VOLUME_DENSITY, mSystem: MST.SI, chars: ['w'], name: 'кулон на кубический метр', ruUnit: 'Кл/м^+3^+', enUnit: 'Кл/м^+3^+', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const field_strengthUnits = [
    //MST.SI
    {uid: UNIT_IDS.FS_NK, category: PC.FIELD_STRENGTH, mSystem: MST.SI, chars: ['E'], name: 'ньютон на кулон', ruUnit: 'Н/Кл', enUnit: 'Н/Кл', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
    {uid: UNIT_IDS.FS_VM, category: PC.FIELD_STRENGTH, mSystem: MST.SI, chars: ['E'], name: 'вольт на метр', ruUnit: 'В/м', enUnit: 'В/м', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const electrical_voltageUnits = [
    //MST.SI
    {uid: UNIT_IDS.EV_VL, category: PC.ELECTRICAL_VOLTAGE, mSystem: MST.SI, chars: ['U'], name: 'вольт', ruUnit: 'В', enUnit: 'В', isBasic: true, ref: null, relRef: null, value: 1, order: 0, calc: '1В = 1м^+3^+·кг/(А·с^+3^+)', detail: 'Электрическое напряжение, электрический потенциал, разность электрических потенциалов, электродвижущая сила'},
    {uid: UNIT_IDS.EV_KV, category: PC.ELECTRICAL_VOLTAGE, mSystem: MST.SI, chars: ['U'], name: 'киловольт', ruUnit: 'кВ', enUnit: 'кВ', isBasic: false, ref: UNIT_IDS.EV_VL, relRef: null, value: 1000, order: 0},
];

const electrical_resistUnits = [
    //MST.SI
    {uid: UNIT_IDS.ER_OM, category: PC.ELECTRICAL_RESIST, mSystem: MST.SI, chars: ['R'], name: 'ом', ruUnit: 'Ом', enUnit: 'Ом', isBasic: true, ref: null, relRef: null, value: 1, order: 0, calc: '1 Ом = 1 (кг·м^+2^+)/(А^+2^+·с^+3^+)', detail: 'Электрическое сопротивление'},
    {uid: UNIT_IDS.ER_KM, category: PC.ELECTRICAL_RESIST, mSystem: MST.SI, chars: ['R'], name: 'килоом', ruUnit: 'кОм', enUnit: 'кОм', isBasic: false, ref: UNIT_IDS.ER_OM, relRef: null, value: 1000, order: 0},
];

const resistivityUnits = [
    //MST.SI
    {uid: UNIT_IDS.RS_OM, category: PC.RESISTIVITY, mSystem: MST.SI, chars: ['p'], name: 'ом на метр', ruUnit: 'Ом·м', enUnit: 'Ом·м', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
    //MST.MISC
    {uid: UNIT_IDS.RS_MM, category: PC.RESISTIVITY, mSystem: MST.MISC, chars: ['p'], name: 'ом-квадратный миллиметр на метр', ruUnit: 'Ом·мм^+2^+/м', enUnit: 'Ом·мм^+2^+/м', isBasic: false, ref: UNIT_IDS.RS_OM, relRef: null, value: 0.000001, order: 0},
];

const electrical_condUnits = [
    //MST.SI
    {uid: UNIT_IDS.EC_SM, category: PC.ELECTRICAL_COND, mSystem: MST.SI, chars: ['j'], name: 'сименс', ruUnit: 'См', enUnit: 'См', isBasic: true, ref: null, relRef: null, value: 1, order: 0, calc: '1 См = 1 А^+2^+·с^+3^+/(кг·м^+2^+)', detail: 'Электрическая проводимость'},
];

const electrical_capUnits = [
    //MST.SI 
    {uid: UNIT_IDS.EP_FR, category: PC.ELECTRICAL_CAP, mSystem: MST.SI, chars: ['С'], name: 'фарад', ruUnit: 'Ф', enUnit: 'пФ', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
    {uid: UNIT_IDS.EP_PF, category: PC.ELECTRICAL_CAP, mSystem: MST.SI, chars: ['С'], name: 'пикофарад', ruUnit: 'пФ', enUnit: 'пФ', isBasic: false, ref: UNIT_IDS.EP_FR, relRef: null, value: 1, order: -9},
    {uid: UNIT_IDS.EP_NF, category: PC.ELECTRICAL_CAP, mSystem: MST.SI, chars: ['С'], name: 'нанофарад', ruUnit: 'нФ', enUnit: 'нФ', isBasic: false, ref: UNIT_IDS.EP_FR, relRef: null, value: 1, order: -12},
];

const electric_denUnits = [
    //MST.SI
    {uid: UNIT_IDS.ED_AM, category: PC.ELECTRIC_DEN, mSystem: MST.SI, chars: ['j'], name: 'ампер на квадратный метр', ruUnit: 'А/м^+2^+', enUnit: 'А//м^+2^+', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const dielec_constUnits = [
    //MST.SI
    {uid: UNIT_IDS.DC_FM, category: PC.DIELEC_CONST, mSystem: MST.SI, chars: ['ε'], name: 'фарад на метр', ruUnit: 'Ф/м', enUnit: 'Ф/м', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const magnetic_perUnits = [
    //MST.SI
    {uid: UNIT_IDS.MP_GN, category: PC.MAGNETIC_PER, mSystem: MST.SI, chars: ['m'], name: 'генри на метр', ruUnit: 'Гн/м', enUnit: 'Гн/м', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const magnetic_fieldUnits = [
    //MST.SI
    {uid: UNIT_IDS.MF_AM, category: PC.MAGNETIC_FIELD, mSystem: MST.SI, chars: ['H'], name: 'ампер на метр', ruUnit: 'А/м', enUnit: 'А/м', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
    //MST.SGS
    {uid: UNIT_IDS.MF_ST, category: PC.MAGNETIC_FIELD, mSystem: MST.SGS, chars: ['H'], name: 'эрстед', ruUnit: 'Э', enUnit: 'Э', isBasic: false, ref: UNIT_IDS.MF_AM, relRef: null, value: 79.5775, order: 0},
];

const magnetic_fluxUnits = [
    //MST.SI
    {uid: UNIT_IDS.MX_VB, category: PC.MAGNETIC_FLUX, mSystem: MST.SI, chars: ['Ф'], name: 'вебер', ruUnit: 'Вб', enUnit: 'Вб', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
    //MST.MISC
    {uid: UNIT_IDS.MX_MX, category: PC.MAGNETIC_FLUX, mSystem: MST.MISC, chars: ['Ф'], name: 'максвелл', ruUnit: 'Мкс', enUnit: 'Мкс', isBasic: false, ref: UNIT_IDS.MX_VB, relRef: null, value: 1, order: -8},
];

const electric_strenghtUnits = [
    //MST.SI
    {uid: UNIT_IDS.EL_AM, category: PC.ELECTRIC_STRENGHT, mSystem: MST.SI, chars: ['I'], name: 'ампер', ruUnit: 'А', enUnit: 'А', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
    {uid: UNIT_IDS.EL_MA, category: PC.ELECTRIC_STRENGHT, mSystem: MST.SI, chars: ['I'], name: 'миллиампер', ruUnit: 'мА', enUnit: 'мА', isBasic: false, ref: UNIT_IDS.EL_AM, relRef: null, value: 0.001, order: 0},
];

const inductanceUnits = [
    //MST.SI
    {uid: UNIT_IDS.ND_GN, category: PC.INDUCTANCE, mSystem: MST.SI, chars: ['L'], name: 'генри', ruUnit: 'Гн', enUnit: 'Гн', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const illuminationUnits = [
    //MST.SI
    {uid: UNIT_IDS.IL_LK, category: PC.ILLUMINATION, mSystem: MST.SI, chars: ['E'], name: 'люкс', ruUnit: 'лк', enUnit: 'лк', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const light_flowUnits = [
    //MST.SI
    {uid: UNIT_IDS.LF_LM, category: PC.LIGHT_FLOW, mSystem: MST.SI, chars: ['J'], name: 'люмен', ruUnit: 'лм', enUnit: 'лм', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const radiation_strengthUnits = [
    //MST.SI
    {uid: UNIT_IDS.RD_VS, category: PC.RADIATION_STRENGTH, mSystem: MST.SI, chars: ['J'], name: 'ватт на стерадиан', ruUnit: 'Вт/ср', enUnit: 'Вт/ср', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const energy_brightUnits = [
    //MST.SI
    {uid: UNIT_IDS.EB_VS, category: PC.ENERGY_BRIGHT, mSystem: MST.SI, chars: ['R'], name: 'ватт на стерадиан - квадратный метр', ruUnit: 'Вт/(м^+2^+ср)', enUnit: 'Вт/(м^+2^+ср)', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const brightnessUnits = [
    //MST.SI
    {uid: UNIT_IDS.BN_KD, category: PC.BRIGHTNESS, mSystem: MST.SI, chars: ['R'], name: 'кандела на квадратный метр', ruUnit: 'кд/м^+2^+', enUnit: 'кд/м^+2^+', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
    //MST.MISC
    {uid: UNIT_IDS.BN_NT, category: PC.BRIGHTNESS, mSystem: MST.MISC, chars: ['R'], name: 'нит', ruUnit: 'нт', enUnit: 'нт', isBasic: false, ref: UNIT_IDS.BN_KD, relRef: null, value: 1, order: 0},
];

const nuclide_actUnits = [
    //MST.SI
    {uid: UNIT_IDS.NA_BR, category: PC.NUCLIDE_ACT, mSystem: MST.SI, chars: ['А'], name: 'беккерель', ruUnit: 'Бк', enUnit: 'Бк', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
    //MST.MISC
    {uid: UNIT_IDS.NA_KR, category: PC.NUCLIDE_ACT, mSystem: MST.MISC, chars: ['А'], name: 'кюри', ruUnit: 'Ки', enUnit: 'Ки', isBasic: false, ref: UNIT_IDS.NA_BR, relRef: null, value: 37, order: 9},
];

const tempUnits = [
    //MST.SI
    {uid: UNIT_IDS.TM_CL, category: PC.TEMP, mSystem: MST.SI, chars: ['t'], name: 'градус Цельсия', ruUnit: '°С', enUnit: '°С', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
    {uid: UNIT_IDS.TM_KL, category: PC.TEMP, mSystem: MST.SI, chars: ['t'], name: 'градус Кельвина', ruUnit: '°К', enUnit: '°К', isBasic: false, ref: UNIT_IDS.TM_CL, relRef: null, value: -272.15, order: 0},
    //MST.MISC
    {uid: UNIT_IDS.TM_FR, category: PC.TEMP, mSystem: MST.MISC, chars: ['t'], name: 'градус Фаренгейта', ruUnit: '°Ф', enUnit: '°Ф', isBasic: false, ref: UNIT_IDS.TM_CL, relRef: null, value: -17.22, order: 0},
];

const dose_rateUnits = [
    //MST.SI
    {uid: UNIT_IDS.DR_GS, category: PC.DOSE_RATE, mSystem: MST.SI, chars: ['P'], name: 'грэй в секунду', ruUnit: 'Гр/с', enUnit: 'Гр/с', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const pulseUnits = [
    //MST.SI
    {uid: UNIT_IDS.PL_KM, category: PC.PULSE, mSystem: MST.SI, chars: ['p'], name: 'килограмм на метр в секунду', ruUnit: 'кг·м/с', enUnit: 'кг·м/с', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const magnetic_indUnits = [
    //MST.SI
    {uid: UNIT_IDS.MI_TS, category: PC.MAGNETIC_IND, mSystem: MST.SI, chars: ['B'], name: 'тесла', ruUnit: 'Тл', enUnit: 'Тл', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
    //MST.SGS
    {uid: UNIT_IDS.MI_GS, category: PC.MAGNETIC_IND, mSystem: MST.SGS, chars: ['B'], name: 'гаусс', ruUnit: 'Гс', enUnit: 'Гс', isBasic: false, ref: UNIT_IDS.MI_TS, relRef: null, value: 0.0001, order: 0},
];

const speedUnits = [
    //MST.SI
    {uid: UNIT_IDS.SP_MS, category: PC.SPEED, mSystem: MST.SI, chars: ['V'], name: 'метр в секунду', ruUnit: 'м/с', enUnit: 'м/с', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
    //MST.MISC
    {uid: UNIT_IDS.SP_KM, category: PC.SPEED, mSystem: MST.MISC, chars: ['V'], name: 'километр в час', ruUnit: 'км/ч', enUnit: 'км/ч', isBasic: false, ref: UNIT_IDS.SP_MS, relRef: null, value: 0.0278, order: 0},
    {uid: UNIT_IDS.SP_UZ, category: PC.SPEED, mSystem: MST.MISC, chars: ['V'], name: 'узел', ruUnit: 'уз', enUnit: 'уз', isBasic: false, ref: UNIT_IDS.SP_MS, relRef: null, value: 0.51444444, order: 0},
];

const acceleratUnits = [
    //MST.SI
    {uid: UNIT_IDS.AC_MS, category: PC.ACCELERAT, mSystem: MST.SI, chars: ['a'], name: 'метр на секунду в квадрате', ruUnit: 'м/c^+2^+', enUnit: 'м/c^+2^+', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const exposure_doseUnits = [
    //MST.SI
    {uid: UNIT_IDS.EX_KK, category: PC.EXPOSURE_DOSE, mSystem: MST.SI, chars: ['D'], name: 'кулон на килограмм', ruUnit: 'Кл/кг', enUnit: 'Кл/кг', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const temp_coefUnits = [
    //MST.MISC
    {uid: UNIT_IDS.TF_KL, category: PC.TEMP_COEF, mSystem: MST.MISC, chars: ['α'], name: 'обратный кельвин', ruUnit: 'К^+-1^+', enUnit: 'К^+-1^+', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const heat_meltUnits = [
    //MST.MISC
    {uid: UNIT_IDS.HM_KL, category: PC.HEAT_MELT, mSystem: MST.MISC, chars: ['λ'], name: 'джоуль на моль', ruUnit: 'Дж/моль', enUnit: 'Дж/моль', isBasic: true, ref: null, relRef: null, value: 1, order: 0},
];

const PHYS_UNITS = [ //obsolete
    ...timeUnits,
    ...lengthUnits,
    ...massUnits,
    ...surface_energyUnits,
    ...thermo_tempUnits, 
    ...quantity_substUnits, 
    ...power_lightUnits, 
    ...flat_angleUnits, 
    ...solid_angleUnits, 
    ...squareUnits,
    ...volumeUnits, 
    ...specific_volumeUnits, 
    ...densityUnits, 
    ...angular_velocityUnits, 
    ...pressureUnits, 
    ...angular_accUnits, 
    ...powerUnits,
    ...moment_powerUnits, 
    ...quantity_heatUnits, 
    ...specific_energyUnits, 
    ...energyUnits, 
    ...volume_energyUnits, 
    ...frequencyUnits,
    ...wave_numberUnits, 
    ...dyn_viscosityUnits, 
    ...heat_systemUnits, 
    ...thermal_condUnits, 
    ...specific_heatUnits, 
    ...molar_concUnits, 
    ...molar_entropyUnits, 
    ...surface_tensionUnits,
    ...electric_chargeUnits, 
    ...charge_densityUnits, 
    ...volume_densityUnits, 
    ...field_strengthUnits, 
    ...electrical_voltageUnits,
    ...electrical_resistUnits, 
    ...resistivityUnits, 
    ...electrical_condUnits, 
    ...electrical_capUnits, 
    ...electric_denUnits, 
    ...dielec_constUnits, 
    ...magnetic_perUnits, 
    ...magnetic_fieldUnits, 
    ...magnetic_fluxUnits, 
    ...electric_strenghtUnits, 
    ...inductanceUnits, 
    ...illuminationUnits, 
    ...light_flowUnits, 
    ...radiation_strengthUnits, 
    ...energy_brightUnits, 
    ...brightnessUnits, 
    ...nuclide_actUnits, 
    ...tempUnits, 
    ...dose_rateUnits, 
    ...pulseUnits, 
    ...magnetic_indUnits, 
    ...speedUnits, 
    ...acceleratUnits, 
    ...exposure_doseUnits, 
    ...amount_heatUnits, 
    ...kin_viscosityUnits, 
    ...temp_coefUnits, 
    ...heat_meltUnits
];

//============================================================================

const SI_IDS = {
    DEKA:  1, HEKTO:  2, KILO:  3,  MEGA:   6, GIGA:  9, TERA:  12, PETA:   15,  EXA:   18, ZETTA:  21, YOTTA: 24,
    DECI: -1, CENTI: -2, MILLI: -3, MICRO: -6, NANO: -9, PICO: -12, FEMTO: -15,  ATTO: -18, ZEPTO: -21, YOCTO: -24,
};

export const SI_UP_PREFIXES = [
    {id: SI_IDS.DEKA,  rusName: 'дека',  engName: 'deca',  ruUnit: 'да', enUnit: 'da', order: 1,  sample: 'дал - декалитр'},
    {id: SI_IDS.HEKTO, rusName: 'гекто', engName: 'hecto', ruUnit: 'г',  enUnit: 'h',  order: 2,  sample: 'гПа - гектопаскаль'},
    {id: SI_IDS.KILO,  rusName: 'кило',  engName: 'kilo',  ruUnit: 'к',  enUnit: 'k',  order: 3,  sample: 'кН - килоньютон'},
    {id: SI_IDS.MEGA,  rusName: 'мега',  engName: 'Mega',  ruUnit: 'М',  enUnit: 'M',  order: 6,  sample: 'МПа - мегапаскаль'},
    {id: SI_IDS.GIGA,  rusName: 'гига',  engName: 'Giga',  ruUnit: 'Г',  enUnit: 'G',  order: 9,  sample: 'ГГЦ - гигагерц'},
    {id: SI_IDS.TERA,  rusName: 'тера',  engName: 'Tera',  ruUnit: 'Т',  enUnit: 'T',  order: 12, sample: 'ТВ - теравольт'},
    {id: SI_IDS.PETA,  rusName: 'пета',  engName: 'Peta',  ruUnit: 'П',  enUnit: 'P',  order: 15, sample: 'Пг - петаграмм'},
    {id: SI_IDS.EXA,   rusName: 'экса',  engName: 'Exa',   ruUnit: 'Э',  enUnit: 'E',  order: 18, sample: 'ЭБ - эксабайт'},
    {id: SI_IDS.ZETTA, rusName: 'зетта', engName: 'Zetta', ruUnit: 'З',  enUnit: 'Z',  order: 21, sample: 'ЗэВ - зеттаэлектронвольт'},
    {id: SI_IDS.YOTTA, rusName: 'иотта', engName: 'Yotta', ruUnit: 'И',  enUnit: 'Y',  order: 24, sample: 'Иг - иоттограмм'},
];

export const SI_DOWN_PREFIXES = [
    {id: SI_IDS.DECI,  rusName: 'дека',  engName: 'deca',  ruUnit: 'д',  enUnit: 'd',  order: -1, sample: 'дм - дециметр'},
    {id: SI_IDS.CENTI, rusName: 'санти', engName: 'centi', ruUnit: 'с',  enUnit: 'c',  order: -2, sample: 'см - сантиметр'},
    {id: SI_IDS.MILLI, rusName: 'милли', engName: 'milli', ruUnit: 'м',  enUnit: 'm',  order: -3, sample: 'мН - миллиньютон'},
    {id: SI_IDS.MICRO, rusName: 'микро', engName: 'micro', ruUnit: 'мк', enUnit: 'μ',  order: -6, sample: 'мкм -микрометр, микрон'},
    {id: SI_IDS.NANO,  rusName: 'нано',  engName: 'nano',  ruUnit: 'н',  enUnit: 'n',  order: -9, sample: 'нМ - нанометр'},
    {id: SI_IDS.PICO,  rusName: 'пико',  engName: 'pico',  ruUnit: 'п',  enUnit: 'p',  order: -12, sample: 'пФ - пикофарад'},
    {id: SI_IDS.FEMTO, rusName: 'фемто', engName: 'femto', ruUnit: 'ф',  enUnit: 'f',  order: -15, sample: 'фс - фемтосекунда'},
    {id: SI_IDS.ATTO,  rusName: 'атто',  engName: 'atto',  ruUnit: 'а',  enUnit: 'a',  order: -18, sample: 'ас - аттосекунда'},
    {id: SI_IDS.ZEPTO, rusName: 'запто', engName: 'zepto', ruUnit: 'з',  enUnit: 'z',  order: -21, sample: 'зКл - зептокулон'},
    {id: SI_IDS.YOCTO, rusName: 'иокто', engName: 'yocto', ruUnit: 'и',  enUnit: 'y',  order: -24, sample: 'иГ - иоктограмм'},
];

export const FUNDAMENTAL_CONSTS = [
    {name: 'первая космическая скорость', char: 'V', value: 7.91, approxValue: 7.91, order: 0, unit: 'км/c', physicsSection: 'астрономические величины'},
    {name: 'вторая космическая скорость', char: 'V', value: 11.186, approxValue: 11.186, order: 0, unit: 'км/c', physicsSection: 'астрономические величины'},
    {name: 'полярный радиус Земли', char: 'R', value: 6356.8, approxValue: 6356.8, order: 0, unit: 'км', physicsSection: 'астрономические величины'},
    {name: 'экваториальная скорость вращения Земли', char: 'V', value: 465.10987, approxValue: 465.1, order: 0, unit: 'м/с', physicsSection: 'астрономические величины'},
    {name: 'экваториальный радиус Земли', char: 'R', value: 6378.245, approxValue: 6378.2, order: 0, unit: 'км', physicsSection: 'астрономические величины'},
    {name: 'постоянная Планка', char: 'ħ', value: 6.62607015, approxValue: 6.62, order: -34, unit: 'Дж·с', physicsSection: 'квантовая физика'},
    {name: 'масса нейрона', char: 'm^-n^-', value: 1.67492721, approxValue: 1.67, order: -27, unit: 'кг', physicsSection: 'квантовая физика'},
    {name: 'масса протона', char: 'm^-p^-', value: 1.6762163, approxValue: 1.67, order: -27, unit: 'кг', physicsSection: 'квантовая физика'},
    {name: 'масса электрона', char: 'm^-e^-', value: 9.109382, approxValue: 9.10, order: -31, unit: 'кг', physicsSection: 'квантовая физика'},
    {name: 'постоянная Вина', char: 'b', value: 2.8977685, approxValue: 2.9, order: -3, unit: 'м·К', physicsSection: 'квантовая физика'},
    {name: 'боровский радиус', char: 'R', value: 5.291772, approxValue: 5.29, order: -11, unit: 'м', physicsSection: 'квантовая физика'},
    {name: 'постоянная Ридбергера', char: 'R', value: 1.097373, approxValue: 1.10, order: 7, unit: '1/м', physicsSection: 'квантовая физика'},
    {name: 'постоянная Дирака (приведенная постоянная Планка)', char: 'ħ=h/2π', value: 1.0545717, approxValue: 1.05, order: 34, unit: 'Дж·с', physicsSection: 'квантовая физика'},
    {name: 'постоянная Стефана-Больцмана', char: 'σ', value: 5.67040, approxValue: 5.67, order: -8, unit: 'Вт/м^+2^+·К^+4^+', physicsSection: 'квантовая физика'},
    {name: 'постоянная Авогадро', char: 'N^-A^-', value: 6.02214129, approxValue: 6.02, order: 23, unit: '1/моль', physicsSection: 'молекулярная физика'},
    {name: 'постоянная Больцмана', char: 'k', value: 1.3806488, approxValue: 1.38, order: -23, unit: 'Дж/К', physicsSection: 'молекулярная физика'},
    {name: 'постоянная тройной точки воды', char: 'T^-0^-', value: 273.16, approxValue: 273.16, order: 0, unit: 'K', physicsSection: 'молекулярная физика'},
    {name: 'удельный молярный объем идеального газа', char: 'V^-m^-', value: 2.2413996, approxValue: 2.24, order: -2, unit: 'м^+3^+·моль', physicsSection: 'молекулярная физика'},
    {name: 'универсальная газовая постоянная', char: 'R=kN^-A^-', value: 8.314472, approxValue: 8.31, order: 0, unit: 'Дж/К·моль', physicsSection: 'молекулярная физика'},
    {name: 'магнитная постоянная', char: 'μ^-0^-', value: 1.25663706, approxValue: 1.25, order: -6, unit: 'Гн/м', physicsSection: 'электродинамика'},
    {name: 'постоянная Фарадея', char: 'F=N^+A^+e', value: 98485.3399, approxValue: 1, order: 0, unit: 'Кл/моль', physicsSection: 'электродинамика'},
    {name: 'скорость света в вакууме', char: 'c', value: 2.99792459, approxValue: 3, order: 8, unit: 'м/с', physicsSection: 'электродинамика'},
    {name: 'электрическая постоянная', char: 'ε^-0^-', value: 8.8541878, approxValue: 8.85, order: -12, unit: 'Ф/м', physicsSection: 'электродинамика'},
    {name: 'элементарный электрический заряд', char: 'q', value: 1.602176, approxValue: 1.60, order: -19, unit: 'Кл', physicsSection: 'электродинамика'},
    {name: 'ускорение свободного падения', char: 'g', value: 9.80665, approxValue: 9.8, order: 0, unit: 'м/с^+2^', physicsSection: 'механика'},
    {name: 'гравитационная постоянная', char: 'G', value: 6.67384, approxValue: 6.67, order: -11, unit: 'м^+3^+·кг/с^+2^+', physicsSection: 'механика'},
];