const initialState = {
    file: null,
    fileExtension: null
};

const importReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_FILE':
            return {
                ...state,
                file: action.payload,
            };

        case 'SET_FILE_EXTENSION':
            return {
                ...state,
                fileExtension: action.payload,
            };
        default:
            return state;
    };
};

export default importReducer;
