import React, {useRef, useState, useEffect} from "react";
import {COL_TYPE, loadPIFiles, uploadPIFile, deletePIFileByName} from "./pi_utils";
import {useDispatch, useSelector} from "react-redux";
import "./PInvest.scss";
import { Icon, Tooltip } from "../ui";

const PIOneImageCell = ({cellFile, width, height, uniqueInd, saveFileName, 
            setShowImageDlg, setShownFile}) => {
    const [loadedImageName, setLoadedImageName] = useState('');
    const [loadedImage, setLoadedImage] = useState(undefined);
    const [isUploadFile, setIsUploadFile] = useState(false);

    const canvasRef = useRef(null);
	const {lists: {files}} = useSelector(state => state);
	const dispatch = useDispatch();

    useEffect(() => {
        loadPIFiles(dispatch);
    }, [dispatch, loadedImageName]);

    useEffect(() => { //загрузить картинку
        if (isUploadFile || !cellFile) return;
        const loadedFile = files.find(f => f.Key.split('/')[1] === cellFile);
        if (!!loadedFile) {
            setLoadedImageName(cellFile);
            setLoadedImage(loadedFile);
            setIsUploadFile(true);
        }
    }, [cellFile, isUploadFile, files]);

    useEffect(() => {
        if (!loadedImageName) return;
        const loadedFile = files.find(f => f.Key.split('/')[1] === loadedImageName);
        if (!loadedFile) return;
        setLoadedImage(loadedFile);
        const url = loadedFile.Url;

        if (cellFile !== loadedImageName) {
           //сохрянем имя файла
           saveFileName(COL_TYPE.IMAGE, loadedImageName, uniqueInd);
        }

        setLoadedImageName('');
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        const image = new Image();
        image.src = url;

        image.onload = () => {
            context.drawImage(image, 0, 0, width, height);
        };
    },[loadedImageName, files, cellFile, width, height, saveFileName, uniqueInd]);

    const handleAddFile = (event) => {
        uploadPIFile(files, event.target.files[0], setLoadedImageName, () => {});
        event.target.value = '';
    };

    const handleShowFile = () => {
        if (!loadedImage) return;
        setShowImageDlg(true);
        setShownFile(loadedImage);
    };

    const handleClearImage = async () => {
        //1 - delete loadedImageName
        await deletePIFileByName(dispatch, loadedImageName);
        //2 - save changes
        saveFileName(COL_TYPE.IMAGE, '', uniqueInd);
        //3 - change state
        setIsUploadFile(false);
    };

    return (
        <>  
            {!loadedImageName && !isUploadFile ? 
            <label onChange={(e) => handleAddFile(e)} key='lbl01'>
                <Tooltip position="top" name="picture">Выберите файл</Tooltip>
                <input type="file" accept={'image/*'} key='lbl01' />
            </label>
            :
            <>
                <div className="pi-table__img_wrap" onClick={() => {handleShowFile()}}>
                    <canvas
                        ref={canvasRef}
                        width={width}
                        height={height}
                    />
                </div>
                <div className="pi-table__img_trash" onClick={() => {handleClearImage()}}>
                    <Icon name="trash" />
                </div>
            </>
            }
        </>
        
    );
}

export default PIOneImageCell; 
