import uPlot from "uplot";
import {chartColors, defaultColors} from "./ChartsColors";

const barOpt =(size, color) => ({
  size: size,
  radius: 0.05,
  disp: {
    y0: {
      values: (u) => u.data[1],
    },
    fill: {
      values: (u) => u.data[1].map(() => color),
    },
    stroke: {
      values: (u) => u.data[1].map(() => color),
    },
  },
});

export const paths = (inter, size, color)=> {
  switch (inter) {
    case 'bars':
      return uPlot.paths.bars(barOpt([1, size], color));
    case 'linear':
      return uPlot.paths.linear({alignGaps: -1});
    case 'bundle':
      return uPlot.paths.spline({alignGaps: 2});
    case 'cardinal':
      return uPlot.paths.spline({alignGaps: -1});
    case 'step':
      return uPlot.paths.stepped({alignGaps: 0});
    case 'stepafter':
      return uPlot.paths.stepped({alignGaps: -1});
    case 'stepbefore':
      return uPlot.paths.stepped({alignGaps: 1});
    default:
      return uPlot.paths.spline();
  }
};

const defaultColor = (i) => defaultColors[i % defaultColors.length].val;
export const defaultStyle = (i, index) => (
  (i === 0) ? {axe: "Ось X"} :
    {
      axe: "Ось Y" + (i > 1 ? i : ''),
      weight: 2,
      color: defaultColor(index + i - 1),
      point: {
        width: 0,
        color: defaultColor(index + i - 1),
      },
      interpolation: 'basis',
    });
export const defaultStyles = (count, index) => Array.from(Array(count), (_,i) => defaultStyle(i, index));

export const getStyles = (workPlot, index) => workPlot.styles?.length === workPlot.data.length ? workPlot.styles : defaultStyles(workPlot.data.length, index);

export const getSeries = (workPlot) =>
  workPlot.data.map((_, i) => {
    if (i === 0) return {scale: 'x'}
    return {
      scale: 'y' + (i > 1 ? i : ''),
      paths: paths(workPlot.styles[i].interpolation, workPlot.styles[i].point.width, workPlot.styles[i].point.color),
      width: workPlot.styles[i].weight,
      stroke: workPlot.styles[i].color,
      points: {
        show: workPlot.styles[i].interpolation === 'bars' ? false : workPlot.styles[i].point.width,
        size: workPlot.styles[i].point.width ?? 0,
        fill: workPlot.styles[i].point.color,
        stroke: workPlot.styles[i].point.color,
      }
    }});

export const getAxes = (workPlot, index, theme) =>
  workPlot.data.map((d, i) => i === 0 ?
    {
      scale: 'x',
      font: '8px Inter',
      size: 20,
      stroke: chartColors.tick,
      grid: {
        stroke: theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light,
        width: 1,
      },
      ticks: {
        width: 1,
        size: 4,
      },
    } :
    {
      scale: 'y' + (i > 1 ? i : ''),
      font: '8px Inter',
      size: 30,
      stroke: defaultColor(index + i - 1),
      grid: {
        stroke: theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light,
        width: 1,
        show: i === 1,
      },
      ticks: {
        width: 1,
        size: 4,
      },
    });




