import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import labsList from '../practicum/subjectList';
import {printOnlyDate} from "../../helpers/text";
import {storageClass} from "../../redux/slices/storage";
import {getLabWorkArraysAndNames, enabledEditScore} from "../practicum/lw_utils";
import {isAuth} from "../../helpers/auth";
import Table from "../ui/Table/Table";
import {getTableScoreList} from '../ui/utils/score_utils';
import {labWorkInfoApi} from "../../api/api";

const ProfileLabWorksTable = ({profile, setScores}) => {
    const [laboratoryList, setLaboratoryList] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [scoreUpdated, setScoreUpdated] = useState(false);
	const [canEditScore, setCanEditScore] = useState(false);

	const documentClass = useSelector(storageClass);

	useEffect(() => {
		setLaboratoryList(labsList.subjectList);
    }, []);

	useEffect(() => {
		setCanEditScore(enabledEditScore(isAuth(), documentClass.classUsers));
	},[documentClass.classUsers]);

    useEffect(() => {
		const handleServerData = (labWorks) => {
			const labWorkList = [];
			let scores = [0, 0, 0, 0, 0];

			for (let i = 0; i < labWorks.length; i ++) {
				const lw = labWorks[i];
				const [subj, sect, lab] = getLabWorkArraysAndNames(laboratoryList, lw.subjectId, lw.sectionId, lw.labId);

				labWorkList.push({
					subject: !!subj && subj.subjectName,
					section: !!sect && sect.sectionName,
					lab: '' + (!!sect && (sect.labList.indexOf(lab) + 1)) + '. ' + (!!lab && lab.labName),
					date: printOnlyDate(lw.createdAt),
					result: '' + (lw.pageInd + 1) + ' из ' + (!!lab && lab.labPages.length),
					score: lw.score,
					id: lw._id
				});

				scores = getTableScoreList(scores, lw.score);
			}

			setScores(scores);
			return labWorkList;
		};

        const fetchData = async () => {
			const result = await labWorkInfoApi.getLabWorkInfosByOwner(profile._id, documentClass._id);
            if (result) {
				setTableData(handleServerData(result.data));
				if (scoreUpdated)
					setScoreUpdated(false);
            } 
        }
		if (!laboratoryList || laboratoryList.length === 0) return;
		fetchData();
    },[documentClass._id, laboratoryList, profile._id, scoreUpdated, setScores, canEditScore]);

	const handleStudentTableDataUpdate = async (score, id) => {
		const scoreVal = score !== '' ? Number(score) : null;
		await labWorkInfoApi.updateLabWorkInfoScoreById(id, {score: scoreVal});
		setScoreUpdated(true);
	};

	const getTableHeader = () => {
		return [
			{column: 'Предмет', name: 'subject', style: { width: '10%'} }, 
			{column: 'Раздел', name: 'section', style: { width: '15%'} }, 
			{column: 'Название теста', name: 'lab', style: { width: '30%'} }, 
			{column: 'Дата', name: 'date', style: { width: '15%'} }, 
			{column: 'Прогресс выполнения', name: 'result', style: { width: '10%'} }, 
			{column: 'Оценка учителя', name: 'score', style: { width: '20%'} }, 
			{column: 'id', name: 'id', style: { width: '0%'} }, 
		];
	};

    return ( 
		<div>
			<Table
				table={{
					header: getTableHeader(), 
					data: tableData, 
					handleTableDataUpdate: handleStudentTableDataUpdate, 
				}}
				sort={{
					hasSorting: true, 
					initSortInd: -4,
				}}
				canEditScore={canEditScore}
			/>
		</div>
    )
}

export default ProfileLabWorksTable;
