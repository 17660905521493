import React, {useState, useEffect} from "react";
import {fullName, isAuth} from "../../helpers/auth";
import {useSelector, useDispatch} from "react-redux";
import {storageClass} from "../../redux/slices/storage";
import ModalConfirmDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import {toast} from "react-toastify";
import {Tabs} from '../ui';
import Table from "../ui/Table/Table";
import {printOnlyDate} from "../../helpers/text";
import {setNbStorage} from "./NotebookStorage";
import NotebookSelectHomeWorkDlg from './NotebookSelectHomeWorkDlg';
import NotebooksStudentInitDlg from './NotebooksStudentInitDlg';
import {getNbView, setNbView}  from "../../redux/slices/tutorialslice";
import {NB_HOME_WORK_STATUS, NB_ACT, STUDY_SUBJECTS, TAB_STATE, getSubjectById, addNotebook, 
	getTaskNumberByTaskSamples, getNotebooks, deleteNotebookAndAllDataByNbId, getNbDeleteMessage,
	subjName, getNbCopy} from "./nb_utils";
import {notebookApi} from "../../api/api";
import { Content, ContentBody, ContentDesc, ContentHead } from "../template/ContentParts";
import SectionTabs from "../ui/SectionTabs";
import "../template/Dashboard/Dashboard.scss";
import nb from "./Notebooks.module.scss";

const Notebooks = ({history}) => {
	const [notebookData, setNotebookData] = useState([]);
	const [notebookTableData, setNotebookTableData] = useState([]);
	const [isShowNotebookCatalog, setIsShowNotebookCatalog] = useState(false);
	const [idBeDeleted, setIdBeDeleted] = useState(undefined);
    const [confirmQuestion, setConfirmQuestion] = useState('');
	const [deletionConfirmed, setDeletionConfirmed] = useState(false);
    const [checkTestInUse, setCheckTestInUse] = useState(false);
    const [isTestinUse, setIsTestinUse] = useState(false);
	const [showConfirmDlg, setShowConfirmDlg] = useState(false);
	const [isUpdate, setIsUpdate] = useState(false);
	const [showInitDlg, setShowInitDlg] = useState(false);
	const [selectedIds, setSelectedIds] = useState(undefined);
    const [showCopiedNameDlg, setShowCopiedNameDlg] = useState(false);
    const [copiedNb, setCopiedNb] = useState(null);

    const documentClass = useSelector(storageClass);
	const nbView = useSelector(getNbView);
    const dispatch = useDispatch();

    useEffect(() => {
		const isCatalog = nbView.find(item => item.ind === -1).isCatalog;
		setIsShowNotebookCatalog(isCatalog);
    }, [nbView]);

    useEffect(() => {
		const getBeCompletedTasks = (data) => {
			//можем менять статус только у ассигнованных заданий, а не у шаблонов заданий
			if (isShowNotebookCatalog) return [];
			const list = [];
			for (let i = 0; i < data.length; i ++) {
				const nb = data[i];
				const currDate = new Date();
				const completeFullDate = new Date(nb.run.completeFullDate);
				if (currDate > completeFullDate && nb.homeWorkStatus !== NB_HOME_WORK_STATUS.COMPLETE)
					list.push(nb._id);
			}
			return list;
		};
		const loadNotebookData = async () => {
            let data = await getNotebooks(isAuth()._id, documentClass._id);
			if (!data) return;

            if (isShowNotebookCatalog) {
				//оставляем только новые задания
                data = data.filter(item => item.homeWorkStatus === NB_HOME_WORK_STATUS.CATALOG);
			} else {
				//оставляем только запущенные или завершенные задания
                data = data.filter(item => item.homeWorkStatus !== NB_HOME_WORK_STATUS.CATALOG);
				const beCompletedList = isShowNotebookCatalog ? [] : getBeCompletedTasks(data);
				if (beCompletedList.length > 0) 
					await notebookApi.updateAllNotebookStatusesBeCompletedByIds(beCompletedList.join('|'));
			}

			setNotebookData(data);
		};

		loadNotebookData();
		if (isUpdate) setIsUpdate(false);
    }, [documentClass._id, isShowNotebookCatalog, isUpdate]);

	useEffect(() => {
		if (!checkTestInUse) return;
        setShowConfirmDlg(true);
    }, [checkTestInUse, idBeDeleted]);

	useEffect(() => {
        const getStatus = status => {
            return (status === NB_HOME_WORK_STATUS.CATALOG ? 'Отложен' : status === NB_HOME_WORK_STATUS.PROGRESS ? 'Запущен' : 'Завершен');
        };
        const getTableData = () => {
			let tableList = [];
			if (notebookData.length > 0) {
				for (let i = 0; i < notebookData.length; i ++) {
					const item = notebookData[i];

					const record = {
						subjectLink: item.titles?.subjectId !== undefined ? getSubjectById(item.titles?.subjectId) : 'УДАЛИТЕ ЭТО ЗАДАНИЕ',
						name: item.titles ? item.titles.homeWorkName : '',
						item: item.titles?.homeWorkItem ? item.titles.homeWorkItem : '',
						author: fullName(item.owner, 'Шаблон'),
						status: getStatus(item.homeWorkStatus),
						dateStart: item.run?.runFullDate ? printOnlyDate(item.run.runFullDate) : '',
						dateFinish: item.run?.completeFullDate ? printOnlyDate(item.run.completeFullDate) : '',
						dateCreate: printOnlyDate(item.updatedAt),
						tasks: getTaskNumberByTaskSamples(item.taskSamples)  + ' шт',
						actions: '',
						id: item._id
					};
					
					tableList.push(record);
				}
			}

			setNotebookTableData(tableList);
        }

		getTableData();
	},[notebookData, isShowNotebookCatalog]);

	useEffect(() => {
		if (!deletionConfirmed) return;

        deleteNotebookAndAllDataByNbId(idBeDeleted, () => false); 

		setDeletionConfirmed(false);
		setIdBeDeleted(undefined);
		setIsTestinUse(false);
		setCheckTestInUse(false);
		setTimeout(() => setIsUpdate(true), 1500);
	}, [deletionConfirmed, idBeDeleted, isTestinUse, dispatch]);

	const handleEditTest = id => {
		setNbStorage({});
        history.push('/notebooks/' + NB_ACT.ADD_EDIT_NB + '/' + id);
	};
	const handleSetTestStartTime = id => { //переход к последнему табу
        history.push('/notebooks/' + NB_ACT.SET_RUN_TIME + '/' + id);
	};

	const canEditIDs = () => notebookTableData.filter(item => isAuth().role === 3 || item.author !== 'Шаблон').map(item => item.id);

    //copying: step 1 - create a new nb
	const handleStartCopyNb = id => {
        getNbCopy(id, documentClass._id, setCopiedNb);
	};
    //copying: step 2 - open the dlg to update name and item
	useEffect(() => {
		if (!copiedNb) return;
        setShowCopiedNameDlg(true);
	}, [copiedNb]);
    //copying: step 3 - save the updated name and item
    const handleCompleteCopyNb = (name, item) => {
        const doCopy = async () => {
            setShowCopiedNameDlg(false);
            copiedNb.titles.homeWorkName = name;
            copiedNb.titles.homeWorkItem = item;
            //save the nb copy
            await addNotebook(copiedNb);
            setIsUpdate(true);
            setCopiedNb(null);
            toast.success("Рабочая тетрадь скопирована."); 
        };
        doCopy();
    };
    //copying: step 4 - close the dlg if cancel
    const doCloseCopiedNameDlg = () => {
        setCopiedNb(null);
        setShowCopiedNameDlg(false);
    };

    const handleLink = id => {
        if (!isShowNotebookCatalog)
            history.push('/notebooks/' + NB_ACT.REVIEW_NB_RUN + '/' + id);
        else 
            handleEditTest(id);
    };

	const handleOpenInitDlg = nbId => { //teacher preview
		setSelectedIds('' + nbId + '|'); //2й параметр не передаем
		setShowInitDlg(true);
	};

	const handleExecutePreview = nbId => {
	    history.push('/notebooks/' + NB_ACT.RUN_TEST + '/' + selectedIds);
	};	

	const handleRequestDeleteTest = id => {
		const getMsg = async () => {
			const msg = await getNbDeleteMessage(id);
			setIdBeDeleted(id);
			setConfirmQuestion(msg);
			setCheckTestInUse(true);
		};
		getMsg();
	};

	const handleDeleteTestNo = id => {
		setShowConfirmDlg(false);
		setDeletionConfirmed(false);
		setIdBeDeleted(undefined);
		setCheckTestInUse(false);
	};

	const handleDeleteTestYes = () => {
		setShowConfirmDlg(false);
		setDeletionConfirmed(true);
	};

	const handleTab = (isCatalog) => {
		setIsShowNotebookCatalog(isCatalog);
		const view = nbView.map(item => item.ind !== -1 ? item : {ind: -1, isCatalog: isCatalog});
		dispatch(setNbView(view));
	};

    const getHomeWorkTable = () => {
		const headerRunning = [
			{column: 'Предмет', name: 'subjectLink', style: { width: '12%'} }, 
			{column: 'Название', name: 'name', style: { width: '15%'} }, 
			{column: 'Тема', name: 'item', style: { width: '13%'} }, 
			{column: 'Автор', name: 'author', style: { width: '10%'} }, 
			{column: 'Статус', name: 'status', style: { width: '8%'} }, 
			{column: 'Дата начала', name: 'dateStart', style: { width: '10%'} }, 
			{column: 'Дата окончания', name: 'dateFinish', style: { width: '10%'} }, 
			{column: 'Количество заданий', name: 'tasks', style: { width: '10%'} }, 
			{column: 'Действия', name: 'actions', style: { width: '12%'} }, 
			{column: 'id', name: 'id', style: { width: '0%'} }
		];

		const headerCatalog = [
			{column: 'Предмет', name: 'subjectLink', style: { width: '10%'} }, 
			{column: 'Название', name: 'name', style: { width: '20%'} }, 
			{column: 'Тема', name: 'item', style: { width: '15%'} }, 
			{column: 'Автор', name: 'author', style: { width: '15%'} }, 
			{column: 'Дата создания', name: 'dateCreate', style: { width: '15%'} }, 
			{column: 'Количество заданий', name: 'tasks', style: { width: '15%'} }, 
			{column: 'Действия', name: 'actions', style: { width: '10%'} }, 
			{column: 'id', name: 'id', style: { width: '0%'} }
		];

		const actionRunning = {
			handleEdit: id => handleEditTest(id),
			canEditIDs: canEditIDs(),
			handleCopy: id => handleStartCopyNb(id),
			handleDelete: id => handleRequestDeleteTest(id),
			canDeleteIDs: canEditIDs(),
		};

        let actionCatalog = actionRunning;
        if (isAuth().role < 3) {
    		actionCatalog =  {
                ...actionRunning,
                handleRun: id => handleSetTestStartTime(id),
                tooltipRun: 'Установите время задания и запустите его',
                canRunIDs: canEditIDs(),
				handlePreview: id => handleOpenInitDlg(id),
            };
        }

		return (
		<div className={nb.nb__table} >
			<Table 
				table={{
					header: !isShowNotebookCatalog ? headerRunning : headerCatalog, 
					data: notebookTableData,
				}}
				link={{
					handleLink: id => handleLink(id),
				}}
				sort={{
					hasSorting: true, 
					initSortInd: !isShowNotebookCatalog ? -6 : -5,// -10 : -8,
				}}
				actions={!isShowNotebookCatalog ? actionRunning : actionCatalog}
				canEditScore={() => false}
			/>
		</div>
		);
    };

    return  (
        <>
			<ContentHead title={!isShowNotebookCatalog ? "Рабочая тетрадь" : 'Каталог заданий по всем предметам'} />
			<ContentDesc desc={"Выберите предмет, для которого хотите создать или просмотреть " + subjName()} />
			
			<Content background={false}>
				<ContentBody padding={false}>
					<div className="cor-net__section">
						<SectionTabs history={history} tabs={STUDY_SUBJECTS}/>
					</div>

					{isAuth().role < 3 &&
					<div className="cor-net__section">
						<Tabs>
							{TAB_STATE.map(tab => (
								<Tabs.Item 
									active={Number(isShowNotebookCatalog) === tab.status}
									onClick={() => handleTab(!!tab.status)}
									key={tab.status}
								>
									{tab.name}
								</Tabs.Item>
							))}
						</Tabs>
					</div>
					}

					{isAuth().role < 3 &&
					<div className="cor-net__section">
						{getHomeWorkTable()}
					</div>
					}
				</ContentBody>
			</Content>
			
			{showConfirmDlg && 
			<ModalConfirmDialog
				showConfirmDlg={showConfirmDlg} 
				handleNo={handleDeleteTestNo}
				handleYes={handleDeleteTestYes}
				question={confirmQuestion}
				btnTextYes={'Точно удалить'}
				btnTextNo={'Оставить'}
				redWarning={true}
			/>
			}

			{showInitDlg && 
                <NotebooksStudentInitDlg
                    showDlg={showInitDlg}
                    setShowDlg={setShowInitDlg}
                    selectedIds={selectedIds}
                    handleExecuteTest={handleExecutePreview}
                />
            }

			{showCopiedNameDlg && 
				<NotebookSelectHomeWorkDlg 
					showModal={showCopiedNameDlg}
					initName={copiedNb?.titles?.homeWorkName} 
					initItem={copiedNb?.titles?.homeWorkItem}
					doSetShowSelectHomeWorkDlg={handleCompleteCopyNb}
					doCancelDlg={doCloseCopiedNameDlg}
				/>
            }
        </>
    );
}

export default Notebooks;
