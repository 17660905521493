import React from 'react';
import ss from '../SensorView.module.scss';
import  Icon  from '../../../Icon';
import { logo, list, info, charging, mode, computer, logging } from '../parts';

const SensorViewBiology = (props) => {
  
    return (
        <div className={ss.root__wrap}>
            <div className={ss.root}>

                <div className={`${ss.line} ${ss.line4}`}>
                    <div className={`${ss.line__item} ${ss.line__item_leftBottom}`}>
                        <div className={ss.line__title}>ОСВЕЩЁННОСТЬ</div>
                    </div>
                    <div className={`${ss.line__item} ${ss.line__item_left}`}>
                        <div className={ss.line__title}>ВЛАЖНОСТЬ/<br></br>&#176;tОКР.СРЕДЫ</div>
                    </div>
                    <div className={`${ss.line__item} ${ss.line__item_btl}`}></div>
           
                    <div className={`${ss.line__item}`}>
                        <div className={ss.line__title}>Ph</div>
                    </div>
                    <div className={`${ss.line__item} ${ss.line__item_btr}`}></div>
                </div>

     

                {logo()}

                <div className={ss.root__title}>Мультидатчик<br></br> по биологии</div>

                {list()}

                {info()}

                {charging()}

                {mode()}

                {computer()}

                <div className={`${ss.icon} ${ss.rightTop}`}>
                    <div className={ss.icon__title}>ТЕМПЕРАТУРА</div>
                    <div className={ss.icon__wrap}>
                        <div className={ss.icon__list}>
                            <div className={ss.icon__view}>
                                <Icon name="lighting" />
                            </div>
                            <div className={ss.icon__view}>
                                <Icon name="blob" />
                            </div>
                            <div className={ss.icon__view}>
                                <Icon name="bulb" />
                            </div>
                            <div className={ss.icon__view}>
                                <Icon name="temperature" />
                            </div>
                        </div>
                        <div className={ss.icon__label}>Датчики</div>
                    </div>
                </div>

                {logging()}


            </div>
            
        </div>
    )
};
export default SensorViewBiology;

