import {printOnlyDate} from "../../helpers/text";
import { notebookApi, notebookInfoApi} from "../../api/api";
import {isAuth} from "../../helpers/auth";
import {getDayTitle, getHourTitle} from '../ui/utils/gen_utils';

export const NB_STATES = {
    PAGE: 0, 
    SETTINGS: 1,
    RUN: 2
}

export const NB_COMPLEXITY = {
    LOW: 0, 
    MEDIUM: 1,
    HIGH: 2
}

//export const NB_AUTO_ESTIMATES = '0/25|26/50|51/75|76/100';
//export const NB_AUTO_ESTIMATE100_THRESHOLD = 25;
// export const NB_SCORE_SCALE_TYPES = {
//     SCORE_SCALE_5: 0, 
//     SCORE_SCALE_100: 1, 
// }

// export const NB_SCORING_TYPES = {
//     SCORING_CORRECT_NUM: 0, 
// }

export const NB_ACT = {
    SHOW_ALL_NBS: 0, 
    SHOW_ALL_SUBJECT_NBS: 1, 
    ADD_EDIT_NB: 2,
    REVIEW_NB_RUN: 3, 
    SET_RUN_TIME: 4,
    RUN_TEST: 5,
}

export const NB_HOME_WORK_STATUS = {
    CATALOG: 0, 
    PROGRESS: 1, 
    COMPLETE: 2
}

export const NB_ACTION = {
    UNDEFINED: 0,
    TASK_ADD: 1, 
    TASK_EDIT: 2,
    TASK_COPY: 3, 
    TASK_DELETE: 4, 
    TASK_SAVE_ADD: 5, 
    TASK_SAVE_EDIT: 6,
}

export const NB_TEMPLATE_TYPE = {
    MATH_NUMBER_PLUS: 10,
    MATH_NUMBER_MINUS: 11,
    MATH_NUMBER_MULTIPLY: 12,
    MATH_NUMBER_DIVIDE: 13,
    MATH_NODNOK: 20,
    MATH_EQUATION_LINEAR: 50,
    MATH_EQUATION_SQUARE: 51,
    MATH_GROUPING: 60,
    //
    PHYSICS_MECHANICS: 100, 
    PHYSICS_MOLECULAR_TERMODYNAMICS: 110, 
    PHYSICS_ELECTRODYNAMICS: 120, 
};

export const NB_SAMPLE_TYPE = {
    EQUATION: 0,
    TEXT_TASK: 1,
    CALC_NUMBERS: 2,
    NOD: 3,
    NOK: 4,
    GROUPING: 5,
    //
    CALC_PHYSICS_TASK: 20,
}

export const NB_WORK_TAB = {
    ACTIVE: 0,
    COMPLETED: 1,
    DELAYED: 2, 
};

export const NB_REVIEW_TAB = {
    RUNNING: 0,
    HISTORY: 1,
    BE_CHECKED: 2, 
};

export const NB_NAME_CHG = {
    TYPE1: 0,
    TYPE234: 1,
    TYPE_MISC: 2, 
};

export const NB_SUBJECT = {
    MATH: 0,
    PHYSICS: 1,
    CHEMISTRY: 2,
    GEOGRAPHY: 3,
    NATURE: 4,
    ECOLOGY: 5,
}

const EXECUTED = 0;
const CATALOG = 1;
export const TAB_STATE = [
    {status: EXECUTED, name: 'Выполненные задания'},
    {status: CATALOG, name: 'Каталог заданий'},
];

export const STUDY_SUBJECTS = [
    {name: 'Математика', relName: 'математике', icon: 'mathematics', tooltip: 'Математика', to:"/notebooks/1/" + NB_SUBJECT.MATH},
    {name: 'Физика', relName: 'физике', icon: 'phisic', tooltip: 'Физика', to:"/notebooks/1/" + NB_SUBJECT.PHYSICS},
    {name: 'Химия', relName: 'химии', icon: 'chemistry', tooltip: 'Химия', to:"/notebooks/1/" + NB_SUBJECT.CHEMISTRY, disabled: true},
    {name: 'География', relName: 'географии', icon: 'geography', tooltip: 'География', to:"/notebooks/1/" + NB_SUBJECT.GEOGRAPHY, disabled: true},
    {name: 'Естествознание', relName: 'естествознанию', icon: 'natural', tooltip: 'Естествознание', to:"/notebooks/1/" + NB_SUBJECT.NATURE, disabled: true},
    {name: 'Экология', relName: 'экологии', icon: 'ecology', tooltip: 'Экология', to:"/notebooks/1/" + NB_SUBJECT.ECOLOGY, disabled: true},
];

export const getSubjectById = (id) => id !== undefined ? STUDY_SUBJECTS[id].name : 'empty';

export const getSubjectRPNameById = (id) => STUDY_SUBJECTS[id].relName;

export const getTemplateTypes = (_templateTypes) => { //read the info from my JSON
    if (!_templateTypes) return [];
    //выберите тип задачи
    const list = [];
    //list.push({label: 'Выберите тип задачи', value: ''});

    for (let i = 0; i < _templateTypes.length; i ++)
        list.push({label: _templateTypes[i].templateTypeName, value: _templateTypes[i].templateTypeId});
    return list;
};

export const getSubTasks = (templates, templateTypeId) => { //read the info from my JSON
    const list = [];
    //list.push({label: 'Выберите задачу', value: ''});
    for (let i = 0; i < templates.length; i ++)
        if (templates[i].templateTypeId === templateTypeId)
            list.push({label: templates[i].name, value: templates[i].templateId});
    return list;
};

export const getFirstTemplateId = (_templates, _templateTypeId) => {
    const selTemplates = _templates.filter(item => item.templateTypeId === _templateTypeId);
    return selTemplates[0].templateId;
};

export const getPageNames = () => { 
    const list = [];
    list.push({label: 'Вводная часть', value: 0});
    return list;
};

export const getPagePlacements = () => {
    const list = [];
    list.push({label: 'В начало задания', value: 0});
    return list;
};

export const getTaskComplexities = () => {
    const list = [];
    list.push({label: 'Низкая', value: NB_COMPLEXITY.LOW});
    list.push({label: 'Средняя', value: NB_COMPLEXITY.MEDIUM});
    list.push({label: 'Высокая', value: NB_COMPLEXITY.HIGH});
    return list;
};

export const getTaskSummaries = () => {
    const list = [];
    for (let i = 1; i <= 10; i ++)
        list.push({label: i.toString(), value: i});
    return list;
};

export const getDifferenceInDays = (timeComplete) => {
    const timeNow = (new Date()).getTime();
    const difference_In_Time = timeComplete > timeNow ? timeComplete - timeNow : 0;
    const difference_In_Days = Math.round(difference_In_Time / (1000 * 3600 * 24));
    return '' + difference_In_Days + ' ' + getDayTitle(difference_In_Days);
}

export const getHourList = () => {
    const list = [];
    for (let i = 0; i < 24; i ++) {
        const label = i.toString() + ' ';
        const nm = getHourTitle(i);
        list.push({label: label + nm, value: i});
    }
    return list;
};

export const getMinuteList = () => {
    const list = [];
    for (let i = 0; i < 12; i ++) {
        const label = (5*i).toString() + ' минут';
        list.push({label: label, value: 5*i});
    }
    return list;
};

export const getEstimateThresholdList = () => {
    const list = [];
    for (let i = 1; i < 99; i ++)
        list.push({label: i.toString(), value: i});
    return list;
};

export const getSelectedDate = (date, hours, minutes) => { //use in DB when analysis of start and complete date
    const dt = new Date(date);
    const currTime = dt.getTime();
    const timeInMSecs = (hours * 3600 + minutes * 60) * 1000;
    const selectedTime = currTime + timeInMSecs;
    return selectedTime;
};

export const getErrorLimits = (isLong) => {
    const list = [];
    const num = isLong ? 20 : 10;
    for (let i = 1; i < num; i ++) {
        const res = i % 10;
        const units = (res === 0 || res >= 5) ? 'ошибок' : res === 1 ? 'ошибка' : 'ошибки';
        const label = i.toString() + ' ' + units;

        list.push({label: label, value: i});
    }
    return list;
};

// export const getScoreScaleTypes = () => {
//     const list = [];
//     list.push({label: 'Пятибальная', value: NB_SCORE_SCALE_TYPES.SCORE_SCALE_5});
//     list.push({label: 'Стобальная', value: NB_SCORE_SCALE_TYPES.SCORE_SCALE_100});
//     return list;
// };

// export const getScoringTypes = () => {
//     const list = [];
//     list.push({label: 'Количество правильных ответов', value: NB_SCORING_TYPES.SCORING_CORRECT_NUM});
//     return list;
// };


export const getNbRbBoxes = (selectedIndex, handle, options) => {
    return (
        <div className='cor-net__checkbox'>
        {options.split('|').map((item, ind) => 
            <div key={"rbkey"+ind} className={''}>
                <input type="checkbox" 
                    id={ind} 
                    value={ind} 
                    name={item}
                    checked={ind === selectedIndex} 
                    onClick={(e) => handle(Number(e.target.value))} 
                    onChange={() => {}}
                >
                </input>
                &nbsp;<label htmlFor={ind}>{item}</label>
            </div>
        )}
        </div>
    );
};


export const getInitialSample = () => {
    const sample = {
        templateId: '',
        complexity: NB_COMPLEXITY.LOW,
        groupAmount: 1,
        teacherDescription: '',
        taskSample: '',
        taskAnswer: '',
    };
    return sample;
};

export const getInitialSettings = () => {
    const settings = {
        isGenerateUniqueTasks: true, 
        isTimeLimited: true,
        timeLimitMinutes: 180,
        isRandomOrder: false, //true,
        isErrorNumberLimit: false, //true,
        taskErrorLimit: 3,
        workErrorLimit: 10,
        isAutoScore: true,
        //scoreScaleType: NB_SCORE_SCALE_TYPES.SCORE_SCALE_5,
        //scoringType: NB_SCORING_TYPES.SCORING_CORRECT_NUM,
        //autoEstimateList: NB_AUTO_ESTIMATES,
        //autoEstimate100Threshold: NB_AUTO_ESTIMATE100_THRESHOLD,
    };
    return settings;
};

export const getInitialRun = (runData = null) => {
    const runFullDate = runData ? runData.runFullDate : null;
    const completeFullDate = runData ? runData.completeFullDate : null;

    const [_runDate, _runHours, _runMinutes] =  getDateParts(true, runFullDate, false);
    const [_completeDate, _completeHours, _completeMinutes] = getDateParts(false, completeFullDate, false);

    let _runFullDate = runFullDate;
    if (_runFullDate === null) {
        _runFullDate = new Date(_runDate);
        _runFullDate.setHours(_runHours);
        _runFullDate.setMinutes(_runMinutes);
    }

    let _completeFullDate = completeFullDate;
    if (_completeFullDate === null) {
        _completeFullDate = new Date(_completeDate);
        _completeFullDate.setHours(_completeHours);
        _completeFullDate.setMinutes(_completeMinutes);
    }

    const run = {
        runFullDate: _runFullDate,
        runDate: _runDate,
        runHours: _runHours,
        runMinutes: _runMinutes,

        completeFullDate: _completeFullDate,
        completeDate: _completeDate,
        completeHours: _completeHours,
        completeMinutes: _completeMinutes,
    };

    return run;
};

export const getFinalWarning = (_homeWorkRun) => {
    if (!_homeWorkRun) return <></>;
    const isNowLater = isNowLaterThanDate(_homeWorkRun.runDate, _homeWorkRun.runHours, _homeWorkRun.runMinutes);
    const timeStart = printOnlyDate(_homeWorkRun.runDate);
    const timeFinish = printOnlyDate(_homeWorkRun.completeDate);
    return (
        !isNowLater ? 
        <>Задание будет запущено {timeStart}. Задание не может быть отредактировано. </>
        : 
        <>Задание начато {timeStart} {_homeWorkRun.completeDate && (<> и будет завершено &nbsp; {timeFinish}</>)}. Задание не может быть отредактировано.</>
    );
};

export const getDateParts = (isRun, date, isMinutes = false) => {
    if (date !== null) { // && currDate.getTime() < date.getTime()
        const _date = new Date(date);
        const hours = _date.getHours();
        const minutesInd = Math.floor(_date.getMinutes() / 5) * 5;
        _date.setHours(0, 0, 0);
        const res = [_date, hours, !isMinutes ? minutesInd : minutesInd];
        return res;
    } else {
        const currDate = new Date();
        const hours = currDate.getHours();
        currDate.setHours(hours + (isRun ? 3 : 24 + 3));
        const newHours = currDate.getHours();
        currDate.setHours(0);
        currDate.setMinutes(0);
        currDate.setSeconds(0);
        const res = [currDate, newHours, 0];
        return res;
    }
};

export const isDisabled = isEditable => !isEditable ? 'disabled' : '';

export const isNowLaterThanDate = (_date, _hours = 23, _minutes = 55) => {
    const runTime = getSelectedDate(_date, _hours, _minutes);
    const nowTime = (new Date()).getTime();
    return nowTime >= runTime;
};

export const getDateText = (date, hours, minutes, isMinutes = false) => {
    let hr = hours.toString();
    hr = hr.length === 1 ? '0' + hr : hr;
    let min = isMinutes ? minutes : minutes.toString();
    min = min < 10 ? '0' + min : min;
    const text = printOnlyDate(date) + ' в ' + hr + ':' + min;
    return text;
};

export const getFullDate = (date, hours, minutes) => {
    if (date === null) return null;
    const fullDate = new Date(date);
    fullDate.setHours(hours, minutes, 0);
    return fullDate;
};

export const getTaskNumberByTaskSamples = taskSamples => {
    let num = 0;
    for (let i = 0; i < taskSamples.length; i ++)
        num += taskSamples[i].groupAmount;
    return num;
};

export const getNbCopy = async (_id, dcId, saveCopiedNb) => {
    let notebook = await getNotebookById(_id);

    notebook.titles.homeWorkName = 'Копия - ' + notebook.titles.homeWorkName;
    notebook.homeWorkStatus = NB_HOME_WORK_STATUS.CATALOG;
    if (isAuth().role < 3) {
        notebook.room = dcId;
        notebook = updateRunDates(notebook);
    }

    saveCopiedNb(notebook);
};

export const updateRunDates = (_notebook) => {
    const notebook = {..._notebook};
    if (isAuth().role === 3) return notebook;
    const nowDate = new Date();
    const runFullDate = notebook.run ? new Date(notebook.run.runFullDate) : undefined;
    const completeFullDate = notebook.run ? new Date(notebook.run.completeFullDate) : undefined;
    
    if (!runFullDate || !completeFullDate || runFullDate < nowDate || completeFullDate < nowDate) {
        const newRunFullDate = new Date();
        newRunFullDate.setHours(0);
        newRunFullDate.setMinutes(0);
        const newCompleteFullDate = new Date();
        newCompleteFullDate.setDate(newCompleteFullDate.getDate() + 1);
        newCompleteFullDate.setHours(23);
        newCompleteFullDate.setMinutes(55);
        const run = {
            runFullDate: newRunFullDate,
            completeFullDate: newCompleteFullDate
        };
        notebook.run = run;
    }

    return notebook;
};

export const updateWords = form => {
    const arr = form.split('|');
    for (let i = 1; i < arr.length; i += 2) {
        const elem = arr[i];
        const newWord = getChangedWords(elem);
        arr[i] = newWord;
    }
    return arr.join('');
};

export const getChangedWords = (str) => {
    const WORD_MALE_TYPE = 0;
    const WORD_MALE_SOFT_TYPE = 1;
    const WORD_FEMALE_TYPE = 2;
    const WORD_MIDDLE_TYPE = 3;

    const dictionary = [
        {word: 'час', type: WORD_MALE_TYPE},
        {word: 'балл', type: WORD_MALE_TYPE},
        {word: 'кубометр', type: WORD_MALE_TYPE},
        {word: 'килограмм', type: WORD_MALE_TYPE},
        {word: 'километр', type: WORD_MALE_TYPE},
        {word: 'ряд', type: WORD_MALE_TYPE},
        {word: 'лист', type: WORD_MALE_TYPE},
        {word: 'ученик', type: WORD_MALE_TYPE},
        //        
        {word: 'рубль', type: WORD_MALE_SOFT_TYPE, short: 'рубл'},
        //        
        {word: 'минута', type: WORD_FEMALE_TYPE, short: 'минут'},
        {word: 'конфета', type: WORD_FEMALE_TYPE, short: 'конфет'},
        {word: 'штука', type: WORD_FEMALE_TYPE, short: 'штук'},
        {word: 'парта', type: WORD_FEMALE_TYPE, short: 'парт'},
        //
        {word: 'яблоко', type: WORD_MIDDLE_TYPE, short: 'яблок'},
        {word: 'кресло', type: WORD_MIDDLE_TYPE, short: 'кресл', multiple: 'кресел'},
        //
    ];

    let [word, value] = str.split(',');

    if (value.includes('/')) {
        value = value.replaceAll('#', '');
        const arr = value.split('/');
        const res = Number(arr[0]) / Number(arr[1]);
        if (res === 1) value = 1;
        else if (res > 0 && res < 1) value = 2; //to get word 'часа'
        else value = Math.floor(res);
    }

    for (let i = 0; i < dictionary.length; i ++) {
        const content = dictionary[i];
        const type = content.type;
        const changeType = getNameChangeType(value);
        
        if (word.toLowerCase() === content.word) {
            if (type === WORD_MALE_TYPE) {
                const updatedWord = getMaleChangeTypeEnding(changeType, content);
                return updatedWord;
            } else if (type === WORD_FEMALE_TYPE) {
                const updatedWord = getFemaleChangeTypeEnding(changeType, content);
                return updatedWord;
            } else if (type === WORD_MALE_SOFT_TYPE) {
                const updatedWord = getMaleSoftChangeTypeEnding(changeType, content);
                return updatedWord;
            } else if (type === WORD_MIDDLE_TYPE) {
                const updatedWord = getMiddleChangeTypeEnding(changeType, content);
                return updatedWord;
            }
        }
    }
    return word;
};

//const isANumber = (str) => !/\D/.test(str);

const getMaleChangeTypeEnding = (changeType, content) => {
    return content.word + (changeType === NB_NAME_CHG.TYPE1 ? '' : changeType === NB_NAME_CHG.TYPE234 ? 'а': 'ов');
};
const getFemaleChangeTypeEnding = (changeType, content) => {
    return content.short + (changeType === NB_NAME_CHG.TYPE1 ? 'а' : changeType === NB_NAME_CHG.TYPE234 ? 'ы': '');
};
const getMaleSoftChangeTypeEnding = (changeType, content) => {
    return content.short + (changeType === NB_NAME_CHG.TYPE1 ? 'ь' : changeType === NB_NAME_CHG.TYPE234 ? 'я': 'ей');
};
const getMiddleChangeTypeEnding = (changeType, content) => {
    if (changeType === NB_NAME_CHG.TYPE1) return content.short + 'о';
    if (changeType === NB_NAME_CHG.TYPE234) return content.short + 'а';
    return (!content.multiple ? content.short : content.multiple);
};

export const getNameChangeType = value => {
    if (value >= 5 && value <= 20)
        return NB_NAME_CHG.TYPE_MISC;

    const n = Math.floor(value / 10);
    const val = value - 10 * n;

    if (val === 1) return NB_NAME_CHG.TYPE1;
    if (val >= 2 && val <= 4) return NB_NAME_CHG.TYPE234;
    return NB_NAME_CHG.TYPE_MISC;
};

export const getBallChangeType = value => {
    const changeType = getNameChangeType(value);
    return getMaleChangeTypeEnding(changeType, {word: 'балл'});
};

///// --- notebookApi ---------------------------------------------------------------------------
export const addNotebook = async (notebookData) => {
    const result = await notebookApi.addNotebook(notebookData)
        .then (res => res)
        .catch(err => console.log('err=', err));
    return result;
};

export const getNotebookById = async (notebookId) => {
    const result = await notebookApi.getNotebookById(notebookId)
        .then (res => res)
        .catch(err => console.log('err=', err));
    return result;
}

export const getNotebooks = async (owner, room) => {
    const result = await notebookApi.getNotebooks(owner, room)
        .then (res => res)
        .catch(err => console.log('err=', err));
    return result;
}

export const getNotebooksByIds = async (nbIds) => {
    const result = await notebookApi.getNotebooksByIds(nbIds)
        .then (res => res)
        .catch(err => console.log('err=', err));
    return result;
}

export const deleteNotebookAndAllDataByNbId = async (notebookId, setTableChanged) => {
    await await notebookInfoApi.deleteNotebookInfoByNotebookId(notebookId);

    await notebookApi.deleteNotebookById(notebookId);
    setTableChanged(true);
};

///// --- notebookInfoApi ---------------------------------------------------------------------------
export const getNotebookInfoById = async (id) => { //id is notebookInfo id
    const result = await notebookInfoApi.getNotebookInfoById(id)
        .then (res => res)
        .catch(err => console.log('err=', err));
    return result;
};

export const getAllNotebookInfoByOwner = async (owner) => { //owner is student id
    const result = await notebookInfoApi.getAllNotebookInfoByOwner(owner)
        .then (res => res)
        .catch(err => console.log('err=', err));
    return result;
};

export const getAllOwnersNotebookInfoByNotebookId = async (notebookId) => { //notebook is notebook id
    const result = await notebookInfoApi.getAllOwnersNotebookInfoByNotebookId(notebookId)
        .then (res => res)
        .catch(err => console.log('err=', err));
    return result;
};

export const updateNotebookInfoScoreById = async (id, score) => { //id is NotebookInfo id
    const result = await notebookInfoApi.updateNotebookInfoScoreById(id, score)
        .then (res => res)
        .catch(err => console.log('err=', err));
    return result;
};

export const getNbDeleteMessage = async (id) => {
    const result = await getAllOwnersNotebookInfoByNotebookId(id);
    const found = result.data.length > 0;
    const msg = !found ? 'Вы действительно хотите удалить это задание?' : 
    'Удаление этого задания приведет к удалению ранее проведенных тестирований учеников и сотрет их оценки из базы. Не рекомендуется удалять.';
    return msg;
};

export const getNotifMsg = (forceComplete, homeWorkStatus) => {
    let msg = "Изменения сохранены. ";

    if (forceComplete) {
        if (isAuth().role < 3)   
            msg += homeWorkStatus === NB_HOME_WORK_STATUS.PROGRESS ? 
                "Задание запущено." : "Задание сохранено в хранилище.";
        else 
            msg += "Шаблон сохранен в хранилище.";
    }
    return msg;
};

export const subjName = () => isAuth().role < 3 ? "задание" : "шаблон";

export const getInfoMessage = async (owner) => { //use in Aside 
    let result = await getAllNotebookInfoByOwner(owner);
    let data = result.data.filter(item => !item.isComplete);
    if (data.length === 0) return '';
    const nbIds = data.map(item => item.notebook).join('|');
    result = await getNotebooksByIds(nbIds);
    let num = 0;
    data = result.data;
    for (let i = 0; i < data.length; i ++) {
        const run = data[i].run;
        const todayDate = new Date();
        const today = todayDate.valueOf();
        const nbStartDate = new Date(run.runFullDate);
        const start = nbStartDate.valueOf();
        const nbFinishDate = new Date(run.completeFullDate);
        const finish = nbFinishDate.valueOf();

        if (today >= start && today <= finish) {
            num ++;
        }
    }
    if (num === 0) return '';

    const msg = 'Вы должны выполнить ' +  (num === 1 ? '1 новое задание ' : 'новые задания') + ' в Рабочей тетради.';
    return msg;
};