// import {  } from '../../actions/';

export const mapStateToProps = (nstate) => {
    return {
        sensors: nstate.nsensor.list,
        imported: nstate.nsensor.imported,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    // setPlay: (bool) => dispatch(setPlay(bool)),
});
