import React, { useState, useEffect, useCallback, memo } from 'react';
import { ChartArea, ChartAreaMulty, ChartAreaParametric, ChartAreaLissague } from './';
import MapView from '../MapView';
import { Watch } from 'react-loader-spinner';
import ScenarioChoiceDlg from '../Scenario/ScenarioChoiceDlg';
import Icon from '../Icon';
import {toast} from "react-toastify";
import ScenarioRunDlg from '../Scenario/ScenarioRunDlg';
import AnalogDevice from '../AnalogDevice';
import scales_def from '../AnalogDevice/scales_def';
import { Modal, Dropdown, Button } from 'rlabui';
import ss from './Charts.module.scss';

const chartsView = [
    { label: 'Один график', value: 'one' },
    { label: 'Два графика', value: 'two' },
    { label: 'Три графика', value: 'three' },
    { label: 'Четыре графика', value: 'four' },
    { label: 'Мульти-ось', value: 'multy' },
    { label: 'Моно-ось', value: 'mono' },
    { label: 'Аналоговый', value: 'analog' },
    { label: 'На карте', value: 'map' },
];

let pendingView;

const Charts = (props) => {
    // console.log(props)
    const {
        port,
        played,
        view,
        mobileView,
        isMobile,
        sensorList,
        sensorWrited,
        sensorImported,
        sensorsBundles,
        chartMarkers,
        deleteAllMarkers,
        clearChartMarkerModal,
        clearModal,
        chartDisabled,
        sensorReading,
        sorting,
        setChartView,
        setChartMarkerDeleteAll,
        setChartMarkers,
        // sensorsUnits,
        parametricMode,
        toggleChartParametric,
        lissague,
        isCor,
        scenarioList,
        scenarioId,
        setScenarioId,
        loadScenario,
        scenario,
        setScenarioInfo,
        setGraphFile,
    } = props;

    const [isAlert, setAlert] = useState(false);
    const [alertReaded, setAlertReaded] = useState(false);
    const [parametricModalShow, setParametricModalShow] = useState(false);
    const [markersCount, setMarkersCount] = useState(0);
    const [currentView, setCurrentView] = useState(view);
    const [sensors, setSensors] = useState([]);
    const [showSelectScenarioDlg, setShowSelectScenarioDlg] = useState(false);
    const [showRunScenarioDlg, setShowRunScenarioDlg] = useState(false);
    const [testPage, setTestPage] = useState(0);
    const [showRunDlg, setShowRunDlg] = useState(false);
    const [chartsTitle, setChartsTitle] = useState('Графики значений с датчиков');

    const isBundles = sorting === 'all' || sorting === 'bundles';
    const isSensors = sorting === 'all' || sorting === 'sensors';

    useEffect(() => {
        if (window.isAndroid) {
            setChartView('two');
        }
    }, []);

    useEffect(() => {
        if (played || port) {
            setAlert(false);
            setAlertReaded(false);
        }
    }, [played, port]);

    useEffect(() => {
        setSensors([...sensorWrited, ...sensorImported, ...sensorList]);
    }, [sensorList, sensorImported, sensorWrited]);

    useEffect(() => {
        if (!scenario) {
            setShowRunScenarioDlg(false);
            setShowRunDlg(false);
            setScenarioId('');
            setTestPage(0);
        }
    }, [scenario, setScenarioId]);

    useEffect(() => {
        if (Object.keys(chartMarkers).length) {
            let count = 0;
            for (var key in chartMarkers) {
                const leng = Object.keys(chartMarkers[key]).length;
                count += leng;
            }
            setMarkersCount(count);
        }
    }, [chartMarkers]);

    useEffect(() => {
        if (deleteAllMarkers) {
            for (const num in chartMarkers) {
                setChartMarkers({ num, data: {} });
            }
        }
    }, [deleteAllMarkers, setChartMarkers, chartMarkers]);

    useEffect(() => {
        if (!played) {
            const isSingle = sensorList[0]?.single;
            if (view === 'multy') {
                setChartView(currentView);
            }
            if (isSingle && !sensorImported.length) {
                view !== 'map' && view !== 'analog' && setChartView('one');
            }
        }
    }, [sensorList, sensorImported, setChartView, currentView, played, view]);

    useEffect(() => {
        if (!clearModal) {
            setChartMarkerDeleteAll(false);
        }
    }, [clearModal, setChartMarkerDeleteAll]);

    useEffect(() => {
        switch (view) {
            case 'analog':
                setChartsTitle('Значения аналоговых датчиков');
                break;

            case 'map':
                setChartsTitle('Карта');
                break;

            default:
                setChartsTitle('Графики значений с датчиков');
                break;
        }
    }, [view, setChartsTitle]);

    //
    const handleScenario = (id) => {
        setScenarioId(id); //scenario selected
        setShowSelectScenarioDlg(false);
    };
    useEffect(() => {
        if (scenarioId === '') return;
        loadScenario(); //load scenario data
    }, [loadScenario, scenarioId]);
    useEffect(() => {
        if (!scenario) return;
        if (showRunScenarioDlg) return;
        setShowRunScenarioDlg(true); //run the scenario test
        setShowRunDlg(true); //show the scenario test
        if (testPage === 0) setTestPage(1);
    }, [scenario, testPage, showRunScenarioDlg]);
    const handleScenarioInfo = (report, answers) => {
        //save scenario test info
        setScenarioInfo({ report: report, answers: answers, scenario: scenario._id });
        setShowRunScenarioDlg(false);
    };

    useEffect(() => {
        if (parametricMode) {
            if (parametricMode[1].name === '') {
                setParametricModalShow(true);
            }
        }
    }, [parametricMode]);

    const renderCharts = useCallback(() => {
        if (parametricMode) {
            // console.log(parametricMode);
            if (lissague) {
                return (
                    parametricMode.length === 2 && (
                        <ChartAreaLissague
                            key="lissague"
                            sensors={parametricMode}
                        />
                    )
                );
            } else {
                return (
                    parametricMode.length === 2 && (
                        <ChartAreaParametric
                            key="parametric"
                            sensors={parametricMode}
                        />
                    )
                );
            }
        } else {
            if (sensors.length) {
                // console.log(sensors);
                if (!isAlert && (view === 'multy' || view === 'mono')) {
                    return (
                        <ChartAreaMulty
                            key={view}
                            sensors={sensors}
                        />
                    );
                } else {
                    let singles = [];
                    let bundles = [];
                    if (view === 'map') {
                        return <MapView />;
                    }
                    if (view === 'analog') {
                        singles = sensors.map((item) => {
                            const disabled = chartDisabled.filter((e) => e === item.num);
                            const digital = Object.keys(scales_def).filter((e) => e === item.name)[0];
                            if (!disabled.length && digital) {
                                return (
                                    <AnalogDevice
                                        key={item.deviceKey + item.unicName + item.num}
                                        sensor={item}
                                    />
                                );
                            } else {
                                return null;
                            }
                        });
                    } else {
                        if (isSensors) {
                            singles = sensors.map((item, i) => {
                                const disabled = chartDisabled.filter((ch) => ch === item.num);
                                // console.log(item);
                                if (item && !disabled.length) {
                                    return (
                                        <ChartArea
                                            index={i}
                                            key={item.deviceKey + item.unicName + item.num}
                                            sensor={item}
                                        />
                                    );
                                } else {
                                    return null;
                                }
                            });
                        }
                        if (Object.keys(sensorsBundles)?.length && isBundles) {
                            // console.log(sensorsBundles);
                            bundles = Object.values(sensorsBundles)?.map(({ list, key, name }) => {
                                const bundlesDisabled = chartDisabled.filter((channel) => channel === key);
                                // console.log(list);
                                if (!bundlesDisabled.length) {
                                    return (
                                        <ChartAreaMulty
                                            key={key}
                                            sensors={list}
                                            bundles={key}
                                            bundlesTitle={name}
                                        />
                                    );
                                } else {
                                    return null;
                                }
                            });
                        }
                    }
                    return [...singles, ...bundles];
                }
            }
        }
    }, [sensors, chartDisabled, view, parametricMode, sensorsBundles, isAlert, isBundles, isSensors, lissague]);

    const setModalAlert = () => (
        <>
            <Modal.Head
                className="clearChrt"
                title="Переход в режим мульти/моно оси удалит все диапазоны и маркеры, кроме первого графика."
            />
            <Modal.Footer>
                <Button
                    onClick={() => {
                        setAlert(false);
                        setAlertReaded(true);
                        setCurrentView(view);
                        setChartView(pendingView);
                        pendingView = null;
                    }}
                >
                    Хорошо
                </Button>
                <Button
                    border={true}
                    onClick={() => {
                        setChartView(currentView);
                        setAlert(false);
                    }}
                >
                    Отмена
                </Button>
            </Modal.Footer>
        </>
    );

    const setModalContent = () => (
        <>
            <Modal.Head
                className="clearChart"
                modalClose={() => {
                    setChartMarkerDeleteAll(false);
                    clearChartMarkerModal(false);
                }}
                title="Вы действительно хотите удалить все метки?"
            ></Modal.Head>
            <Modal.Footer>
                <Button
                    onClick={() => {
                        setChartMarkerDeleteAll(true);
                        clearChartMarkerModal(false);
                    }}
                >
                    Удалить
                </Button>
                <Button
                    border={true}
                    onClick={() => {
                        setChartMarkerDeleteAll(false);
                        clearChartMarkerModal(false);
                    }}
                >
                    Отмена
                </Button>
            </Modal.Footer>
        </>
    );

    const dropParametricMode = () => {
        if (parametricMode[1]?.name === '') {
            setParametricModalShow(false);
            toggleChartParametric();
        } else {
            setParametricModalShow(false);
        }
    };

    const parametricModal = () => {
        return (
            <>
                <Modal.Head
                    className="parametric"
                    modalClose={dropParametricMode}
                    title="Запустить параметрические оси"
                />
                <Modal.Body>
                    {parametricMode &&
                        parametricMode.map((item, i) => {
                            const { name, num } = item;
                            const preffix = typeof num === 'string' ? (num.includes('loc') ? 'Лог' : 'Имп') : num;
                            return (
                                <div
                                    className="parametric__row"
                                    key={num + '' + i}
                                >
                                    <label className="parametric__label">{i === 0 ? 'Ось Х' : 'Ось Y'}</label>
                                    <Dropdown
                                        value={num || num === 0 ? preffix + '. ' + name : 'Выберите устройство'}
                                        clicked={true}
                                    >
                                        {sensors.map((sens) => {
                                            const { name, num } = sens;
                                            const preffix = typeof num === 'string' ? (num.includes('loc') ? 'Лог' : 'Имп') : num;
                                            return (
                                                <Dropdown.Item
                                                    key={num + '' + i}
                                                    onClick={() => {
                                                        const arr = [...parametricMode];
                                                        toggleChartParametric([...arr.slice(0, i), sens, ...arr.slice(i + 1)]);
                                                    }}
                                                >
                                                    {preffix + '. ' + name}
                                                </Dropdown.Item>
                                            );
                                        })}
                                    </Dropdown>
                                </div>
                            );
                        })}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setParametricModalShow(false)}>Принять</Button>
                    <Button
                        border={true}
                        onClick={dropParametricMode}
                    >
                        Отмена
                    </Button>
                </Modal.Footer>
            </>
        );
    };

    const swichChartView = (value) => {
        const single = currentView !== 'mono' || currentView !== 'multy';
        const multy = value === 'mono' || value === 'multy';
        const isWarning = single && multy;
        if (markersCount > 0 && isWarning && !alertReaded) {
            setAlert(true);
            pendingView = value;
        } else {
            setChartView(value);
            setCurrentView(value);
        }
    };

    const renderHeader = () => {
        return (
            sensors.length > 0 &&
            !isMobile && (
                <div className={ss.head}>
                    {parametricMode ? (
                        <div className={ss.title}>
                            {lissague ? 'Фигуры Лиссажу' : 'Параметрические оси'}
                            <Icon
                                name="edit"
                                onClick={() => setParametricModalShow(true)}
                            />
                        </div>
                    ) : (
                        <div className={ss.title}>{chartsTitle}</div>
                    )}
                    <div className={ss.settings}>
                        <label>Режим отображения</label>
                        <div
                            data-learn="4"
                            data-learn-border="4"
                            data-learn-position="left"
                        >
                            <Dropdown
                                checkbox={false}
                                value={chartsView.filter((item) => item.value === view)[0]?.label}
                                dropPosition="right"
                            >
                                {chartsView.map(({ label, value }, i) => {
                                    if (value === 'map' && window.isElectron) return;
                                    return (
                                        <Dropdown.Item
                                            key={i}
                                            className={value === view ? 'selected' : ''}
                                            onClick={() => swichChartView(value)}
                                        >
                                            {label}
                                        </Dropdown.Item>
                                    );
                                })}
                            </Dropdown>
                        </div>
                    </div>
                    {isCor && scenarioList.length > 0 && (
                        <>
                            <div className={ss.showScenario__wrap}>
                                <Icon
                                    className={ss.showScenario}
                                    tag="button"
                                    name="script"
                                    disabled={showRunScenarioDlg}
                                    onClick={testPage === 0 ? () => setShowSelectScenarioDlg(true) : () => {}}
                                />
                                <div className={ss.showScenario__tooltip}>Доступны сценарии для подключенного датчика</div>
                            </div>

                            {showRunScenarioDlg && (
                                <ScenarioRunDlg
                                    showModal={showRunScenarioDlg}
                                    isOpen={showRunDlg}
                                    setIsOpen={setShowRunDlg}
                                    scenario={scenario}
                                    testPage={testPage}
                                    setTestPage={setTestPage}
                                    handleScenarioInfo={handleScenarioInfo}
                                    setGraphFile={setGraphFile}
                                />
                            )}
                        </>
                    )}
                </div>
            )
        );
    };

    return (
        <div className={isMobile ? (mobileView === 'chart' ? ss.wrap : ss.wrap + ' ' + ss.wrap_collapsed) : ss.wrap}>
            {renderHeader()}
            <div className={`${ss.root} ${!parametricMode ? ss[view] : ''}`}>
                {renderCharts()}
                {sensorReading ? (
                    <div className="watch-loading">
                        <Watch
                            height="50"
                            width="50"
                            radius="48"
                            color="#3FA9F5"
                            ariaLabel="watch-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : (
                    ''
                )}
                <Modal
                    visible={clearModal}
                    size={'xs'}
                >
                    {setModalContent()}
                </Modal>
                <Modal visible={isAlert}>{setModalAlert()}</Modal>
                <Modal
                    visible={parametricModalShow}
                    size={'xs'}
                >
                    {parametricModal()}
                </Modal>
                <ScenarioChoiceDlg
                    showModal={showSelectScenarioDlg}
                    setShowModal={setShowSelectScenarioDlg}
                    scenarioList={scenarioList}
                    handleScenario={handleScenario}
                />
            </div>
        </div>
    );
};

export default memo(Charts);
