import React, {useEffect, useRef, useState} from 'react';
import socket from "../../socket";
import * as Yup from "yup";
import Modal from '../Modal';
import {FEATURES} from "../../api/config";
import {useDispatch, useSelector} from "react-redux";
import {reloadClass, reloadOrg, reloadUser, storageClass, storageOrg} from "../../redux/slices/storage";
import {AddclassApi, classApi, userApi} from "../../api/api";
import {fetchclass, fetchOrgs, fetchUsersByOrg, selectclass} from "../../redux/slices/classsline";
import {useFormik} from "formik";
import {toast} from "react-toastify";
import {fullName, generatePassword, isAuth} from "../../helpers/auth";
import {printNumbered, printUserRoleGen} from "../../helpers/text";
import "./Settings.scss";
import Password from "../ui/Password";

const nextYear = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split('T')[0];

const UserSettings = (props) => {
    const dispatch = useDispatch();
    const documentOrg = useSelector(storageOrg);
    const documentClass = useSelector(storageClass);
    const [classs] = useSelector(selectclass);
    const [showConfirm, setShowConfirm] = useState(false);
    const [silenceOpen, setSilenceOpen] = useState(false);
    const [raiseDisabled, setRaiseDisabled] = useState(0);
    const passwordRef = useRef();

    const printName = (role) => {return role === 2?"№ Школы":"Имя"};

    const raiseDisabledTimer = (timer) => {
        if (timer > 0) {
            setRaiseDisabled(--timer);
            if (timer > 0) setTimeout(() => raiseDisabledTimer(timer), 1000);
        }
    }
    const raiseHand = () => {
        if (raiseDisabled === 0) {
            raiseDisabledTimer(10);
            socket.emit('BE-raise-hand', {roomId: documentClass._id, user: isAuth()});
            toast.info(fullName(isAuth()) + " поднял руку");
        }
    };

    useEffect(() => {
        socket.on("FE-room-options", ({options}) => {
            props.toggleSilence(options?.silence);
            if (options?.silence && isAuth().role === 0)
                setSilenceOpen(true);
        });
        socket.on('FE-toggle-silence', (mode) => {
            props.toggleSilence(mode);
            if (isAuth().role === 0)
                setSilenceOpen(mode);
        });
        socket.on('FE-raise-hand', (user) => {
            if (isAuth().role === 1)
                toast.info(fullName(user) + " поднял руку");
        });
        socket.on('FE-refresh-db', ({type, id}) => {
            switch (type){
                case 'user':
                    if (isAuth()._id === id )
                        userApi.get(id).then(i => dispatch(reloadUser(i)));
                    if (documentOrg._id === id )
                        userApi.get(id).then(i => dispatch(reloadOrg(i)));
                    return;
                case 'class':
                    if (documentClass._id === id )
                        classApi.getclassById(id).then((i) =>
                        {dispatch(reloadClass(i)); dispatch(fetchclass(documentOrg._id));});
                    return;
                default:
                    return;
            }
        });
        return () => {
            socket.off('FE-room-options');
            socket.off('FE-toggle-silence');
            socket.off('FE-raise-hand');
            socket.off('FE-refresh-db');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.isAdd && props.role === 2){
            passwordRef.current.setEye(true);
        }
    }, [props.isAdd, props.role]);

    const deleteUser = async (force=false) => {
        try {
            if (props.user.role < 2) {
                const removeClasses = classs.filter(c => c.classUsers?.includes(props.user._id)).map(c => c._id);
                await AddclassApi.removeUserFromClass(removeClasses.join('|'), props.user._id)
            }
            if (force) {
                if (props.user.role === 2) {
                    await Promise.all(classs.map(async (cl) => await classApi.deleteClass(cl._id)));
                }
                await userApi.delete(props.user._id);
            } else {
                await userApi.update(props.user._id, {status: props.user.status ? -props.user.status : -1});
            }

            if (props.role === 2) {
                dispatch(fetchOrgs());
            } else {
                dispatch(fetchUsersByOrg(documentOrg._id))
                dispatch(fetchclass(isAuth()._id));
            }

            handleClose();
        } catch (error) {
            console.log(error);
        }
    };

    const deleteCancel = () => setShowConfirm(false);
    const deleteConfirm = async () => {
        await deleteUser(true);
        setShowConfirm(false);
    };
    const deleteClick = async () => {
        if (props.user.status < 0) {
            setShowConfirm(true);
        } else {
            await deleteUser();
        }
    };

    const handleClose = (cancel = false) => {
        if (cancel && props.isReg) return;
        formik.resetForm();
        props.showUserSettings({showModal: false});
    };


    useEffect(() => {
        if (props.showModal) {
            const close = (e) => {
                if (e.keyCode === 27) showConfirm ? deleteCancel() : handleClose(true);
            };
            window.addEventListener('keydown', close)
            return () => window.removeEventListener('keydown', close)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showConfirm, props.showModal]);

    let error = {visible: false, message: ""};
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            uid: props.user.uid,
            login: props.user.login,
            name: props.user.name,
            name2: props.user.name2,
            name3: props.user.name3,
            city: props.user.city,
            features: Object.assign({}, ...Object.keys(FEATURES).map((k) => ({[k]: props.user.features[k]??''}))),
            email: props.user.email,
            password: (props.isAdd && props.user.uid)?props.user.password:'',
        },
        validationSchema: Yup.object({
            uid: Yup.number("Введите число").integer("Уникальное число школы"),
            name: Yup.string().required("Введите "+printName(props.role)),
            name2: props.role < 2 && Yup.string().required("Введите Фамилию"),
            name3: props.role < 2 && Yup.string().required("Введите Отчество"),
            login: Yup.string().required("Введите Логин"),
            email: Yup.string().email("Введите валидный e-mail").required("Введите e-mail"),
            city: Yup.string().required("Введите Город"),
            password: ( (props.isAdd || props.isReg) ? Yup.string().required("Введите пароль"):Yup.string()).min(8, "Минимум 8 символов"),
        }),
        onSubmit: async (formData) => {
            try {
                const data = {
                    login: formData.login,
                    name: formData.name,
                    city: formData.city,
                    email: formData.email,
                    password: formData.password,
                };

                if (props.role < 2) {
                    data.name2 = formData.name2;
                    data.name3 = formData.name3;
                } else if (props.role === 2) {
                    if (isAuth().role === 3) {
                        data.uid = formData.uid
                        data.features = Object.fromEntries(Object.entries(formData.features).filter(([,v]) => v));
                    } else {
                        data.status = 2;
                    }
                }

                if (props.isAdd) {
                    data.role = props.role;
                    data.owner = props.owner;

                    await userApi.register(data)
                    .then(async (newUser) => {
                        if (props.role < 2) {
                            if (newUser?._id)
                                AddclassApi.addUserToClass(documentClass._id, newUser._id)
                                .then(() => {
                                    dispatch(fetchUsersByOrg(documentOrg._id));
                                    dispatch(fetchclass(isAuth()._id));
                                });
                        } else {
                            if (formData.addCl) {
                                for (let i = 1; i < 6; i++) {
                                    await AddclassApi.addClass({
                                        className: i,
                                        classLevel: 30,
                                        classDescription: "",
                                        classOwner: newUser._id,
                                    })
                                }
                            }
                            dispatch(fetchOrgs());
                        }
                        handleClose();
                    })
                    .catch((err) => {
                        toast.error(err.response.data.errors);
                    });
                } else {
                    await userApi.update(props.user._id, data)
                    .then((updated) => {
                        if (props.role === 2) {
                            dispatch(fetchOrgs());
                            if (props.user._id === documentOrg._id) {
                                dispatch(reloadOrg(updated));
                            }
                        } else {
                            dispatch(fetchUsersByOrg(documentOrg._id));
                        }
                        if (props.user._id === isAuth()._id) {
                            dispatch(reloadUser(updated));
                        }
                        socket.emit('BE-refresh-db', {roomId:documentClass._id, type:'user', id:props.user._id});
                        handleClose();
                    })
                    .catch((err) => {
                        toast.error(err.response.data.errors);
                    });
                }
            } catch (err) {
                error = {
                    visible: true,
                    message: JSON.stringify(err.errors, null, 2),
                };
                toast.error(error);
            }
        },
    });

    const handleNameChange = (e)=>formik.setFieldValue(e.target.name,
      e.target.value.replace(/\P{Alpha}+/gu,''));

    return (
        <>
            <Modal visible={props.showModal} content={
                <form onSubmit={formik.handleSubmit}>
                    {!props.isReg && <div className="modal__close" onClick={handleClose}/>}
                    {props.isReg ?
                      <div className="modal__message cor_settings_title">
                          <div className="warning">Внимание!</div>
                          <p>Если Вы ранее уже приобрели программное обеспечение COR. ROBICLAB и зарегистрировались в
                          системе, Вам необходимо связаться с нами для объединения данных и расширения функционала
                          системы:</p>
                          <a href="mailto:cor@robiclab.ru">cor@robiclab.ru</a>
                          <p>Для завершения регистрации необходимо ввести данные школы и создать новый пароль для входа в систему.</p>
                      </div> :
                      <div className="modal__message">
                      {props.isAdd ? "Добавить " : "Редактировать "}
                          {printUserRoleGen(props.role).toLowerCase()}</div>}
                    <div className="modal__body">
                        {props.role < 2 && <div key="name2" className="cor_settings_vert">
                            <label htmlFor="name2">Фамилия {printUserRoleGen(props.role).toLowerCase()}</label>
                            <div className="form_error">{formik.errors.name2}</div>
                            <input
                              autoComplete="off"
                              placeholder="Фамилия"
                              className={formik.errors.name2 ? "form_error" : ""}
                              name="name2"
                              onChange={handleNameChange}
                              value={formik.values.name2}
                            />
                        </div>}
                          <div className={(props.role === 2 && isAuth().role === 3)?"cor_settings_hor1_2":""}>
                              <div key="name" className="cor_settings_vert">
                                <label htmlFor="name">{printName(props.role)} {props.role < 2 && printUserRoleGen(props.role).toLowerCase()}</label>
                                <div className="form_error">{formik.errors.name}</div>
                                <input
                                    autoComplete="off"
                                    placeholder={printName(props.role)}
                                    className={formik.errors.name ? "form_error" : ""}
                                    name="name"
                                    onChange={props.role < 2 ? handleNameChange : formik.handleChange}
                                    value={formik.values.name}
                                />
                            </div>
                              {props.role === 2 && isAuth().role === 3 &&
                                <div key="uid" className="cor_settings_vert">
                                    <label htmlFor="uid">UID</label>
                                    <div className="form_error">{formik.errors.uid}</div>
                                    <input
                                      name="uid"
                                      autoComplete="off"
                                      placeholder="UID"
                                      className={formik.errors.uid ? "form_error" : ""}
                                      type="number"
                                      onChange={formik.handleChange}
                                      value={formik.values.uid}
                                    />
                                </div>
                              }
                          </div>
                        {props.role < 2 && <div key="name3" className="cor_settings_vert">
                            <label htmlFor="name3">Отчество {printUserRoleGen(props.role).toLowerCase()}</label>
                            <div className="form_error">{formik.errors.name3}</div>
                            <input
                              autoComplete="off"
                              placeholder="Отчество"
                              className={formik.errors.name3 ? "form_error" : ""}
                              name="name3"
                              onChange={handleNameChange}
                              value={formik.values.name3}
                            />
                        </div>}
                        {props.role === 2 && isAuth().role === 3 && <div key="features" className="cor_settings_hor1_2">
                            <div className="cor_settings_hor1_2_title">Лицензии школы</div>
                            {Object.entries(FEATURES).filter(([,v])=>v.name)
                            .map(([k,v])=>
                                <label key={k}>
                                    <input
                                      type="checkbox"
                                      autoComplete="off"
                                      disabled={isAuth().role < 3}
                                      checked={formik.values.features[k]}
                                      onChange={()=>formik.handleChange(`features[${k}]`)
                                      (formik.values.features[k]?'':
                                        (props.user.features?.[k]?props.user.features[k]:nextYear))}
                                    /><input
                                        type="date"
                                        autoComplete="off"
                                        name={`features[${k}]`}
                                        disabled={isAuth().role < 3}
                                        onChange={formik.handleChange}
                                        value={formik.values.features[k]}
                                    />{v.name}</label>)}
                        </div>}
                        <div key="city" className="cor_settings_vert">
                            <label htmlFor="city">Город</label>
                            <div className="form_error">{formik.errors.city}</div>
                            <input
                              autoComplete="off"
                              placeholder="Город"
                              className={formik.errors.city ? "form_error" : ""}
                              name="city"
                              onChange={formik.handleChange}
                              value={formik.values.city}
                            />
                        </div>
                        <div key="email" className="cor_settings_vert">
                            <label htmlFor="email">e-mail</label>
                            <div className="form_error">{formik.errors.email}</div>
                            <input
                              autoComplete="off"
                              placeholder="e-mail"
                              name="email"
                              className={formik.errors.email ? "form_error" : ""}
                              onChange={formik.handleChange}
                              value={formik.values.email}
                            />
                        </div>
                        <div key="login" className="cor_settings_vert">
                            <label htmlFor="login">Логин</label>
                            <div className="form_error">{formik.errors.login}</div>
                            <input
                                autoComplete="off"
                                placeholder="Логин"
                                className={formik.errors.login ? "form_error" : ""}
                                name="login"
                                onChange={formik.handleChange}
                                value={formik.values.login}
                            />
                        </div>
                        <div className="cor_settings_hor1_2">
                            <div key="password" className="cor_settings_vert">
                                <label htmlFor="password">Пароль</label>
                                <div className="form_error">{formik.errors.password}</div>
                                <Password name="password" ref={passwordRef}
                                    placeholder="Пароль"
                                    className={formik.errors.password ? "form_error" : ""}
                                    onChange={formik.handleChange}
                                    value={formik.values.password}
                                />
                            </div>
                            <div key="genarate" className="cor_settings_vert">
                                <label htmlFor="password"></label>
                                <div className="form_error"></div>
                                <div className="link" onClick={()=> {
                                  formik.setFieldValue("password", generatePassword());
                                  passwordRef.current.setEye(true);
                                }}>Сгенерировать пароль</div>
                            </div>
                        </div>
                    </div>
                    <div className="modal__action">
                        {props.allowDelete && (props.user.status > -1 || isAuth().role > 1) && <button
                            className="cor_btn cor_btn_danger"
                            type="button"
                            onClick={deleteClick}
                        >{props.user.status < 0 ? "Удалить Навсегда" : "Заморозить"}</button>}
                        {props.isAdd && props.role === 2 && isAuth().role === 3 ?
                          <label style={{width: "50%"}}><input
                            type="checkbox"
                            name="addCl"
                            autoComplete="off"
                            onChange={formik.handleChange}
                            value={formik.values.addCl}
                          /> Добавить 5 классов</label>
                        :<div style={{width: "50%"}}/>
                        }
                        <button
                          className="cor_btn cor_btn_primary"
                          type="submit"
                        >{props.isAdd ? "Добавить" : (props.user.status <= -1 ? "Разморозить" : "Сохранить")}</button>
                    </div>
                </form>
            } size={props.role === 2 ? 'md' : ''}/>
            <Modal visible={showConfirm} content={
                <>
                    <div className="modal__message">Точно удалить школу
                        {classs.length ? " и " + printNumbered(classs.length, ['класс', 'классa', 'классов'])
                          : ""}?</div>
                    <div className="modal__action">
                        <button className="cor_btn" onClick={deleteCancel}>Отменить</button>
                        <button className="cor_btn cor_btn_danger" onClick={deleteConfirm}>Удалить Навсегда</button>
                    </div>
                </>
            } size={'xs'}/>
            <Modal visible={silenceOpen} content={
                <>
                    <div className="modal__message">РЕЖИМ ТИШИНЫ</div>
                    <div className="modal__action">
                        <button
                            disabled={raiseDisabled > 0}
                            className="cor_btn cor_btn_primary"
                            onClick={raiseHand}
                        >Поднять руку {raiseDisabled > 0 ? "(" + raiseDisabled + " сек)" : ""}
                        </button>
                    </div>
                </>
            }/>
        </>
    )
};

export default UserSettings;