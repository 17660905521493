import React, {useRef, useState, useEffect} from "react";
import PIOneImageCell from './PIOneImageCell';
import { USE_FORMULA, COL_TYPE, getFormulaValue } from "./pi_utils";
import DateTimePicker  from "../ui/DatePicker/DateTimePicker";
  
import "./PInvest.scss";

const PIOneInputCell = ({cellUniqueInd, cellType, cellValue,
        handleSaveValue, setShowImageDlg, setShownFile, cols, rows}) => {
	const [currValue, setCurrValue] = useState(cellValue !== null ? cellValue : '');
    const inputRef = useRef(null);

    useEffect(() => {
        setCurrValue(cellValue !== null ? cellValue : '');
    }, [cellValue]);

    const handleChangeValue = (value) => {
        if (cellType === COL_TYPE.NUMBER) {
            if (value.trim() === '') setCurrValue('');
            else if (!isNaN(Number(value))) setCurrValue(Number(value));
        } else if (cellType === COL_TYPE.TEXT) {
            setCurrValue(value);
        }
    };

    const handleBlurCell = (value) => {
        handleSaveValue(cellType, currValue, cellUniqueInd);
    };

    const doHandleSelectCell = (ind) => {
        if (cellType !== COL_TYPE.NUMBER && cellType !== COL_TYPE.TEXT) return;
        inputRef.current.focus();
    };

    if (cellType === COL_TYPE.ORDER) {
        return (<div key={'cl01'+cellUniqueInd}>{cellValue}</div>);
    }

    if (cellType === COL_TYPE.IMAGE) {
        return (
            <div key={'img01'+cellUniqueInd} className='pi-table__img'> 
                <PIOneImageCell 
                    cellFile={cellValue}
                    width={100}
                    height={100}
                    uniqueInd={cellUniqueInd}
                    saveFileName={handleSaveValue}
                    setShowImageDlg={setShowImageDlg}
                    setShownFile={setShownFile}
                />
            </div>
        );
    }

    const [_tblInd, rowIndex, cellIndex] = cellUniqueInd.split('|').map(item => Number(item));

    return (
        <div key={'cl01'+cellUniqueInd} className='pi-table__cell' 
             onClick={() => doHandleSelectCell(cellUniqueInd)} >
                {(cellType === COL_TYPE.NUMBER || cellType === COL_TYPE.TEXT) && 
                <input 
                    ref={inputRef}
                    key={'inpnumber'+cellUniqueInd} 
                    type={cellType === COL_TYPE.NUMBER ? 'number' : 'text'}
                    value={currValue} 
                    onInput={e => handleChangeValue(e.target.value)}
                    onBlur={handleBlurCell}
                /> 
                }
                {cellType === COL_TYPE.DATE_TIME &&
                    <div key={'dt01'+cellUniqueInd}>
                        <DateTimePicker 
                            startDate={cellValue !== '' ? new Date(cellValue) : ''} 
                            setStartDate={date => handleSaveValue(cellType, date, cellUniqueInd)} 
                            id={'DATE_'+cellUniqueInd}
                        />
                    </div>
                }
                {cellType === COL_TYPE.FORMULA && USE_FORMULA &&
                    <input 
                        key={'cl01'+cellUniqueInd} 
                        type='text'
                        value={getFormulaValue(rows, cols, rowIndex, cellIndex)} 
                        onInput={() => {}}
                        disabled={true}
                        placeholder={getFormulaValue(rows, cols, rowIndex, cellIndex)}
                    />  
                }
		</div>
    );
}

export default PIOneInputCell; 