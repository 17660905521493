import React, { useEffect, useState } from 'react';
import Icon from '../Icon/';
import ss from './MarkersSettings.module.scss';

const MarkersSettings = (props) => {
    const {
        port,
        imported,
        isOscilloscope,
        setChartMode,
        setChartMarkerAdd,
        setChartMarkerDelete,
        setChartRangeAdd,
        setChartRangeDelete,
        clearChartMarkerModal,
        settings: {
            markers: { add, del, deleteAll },
            range: {
                // add: addRange,
                del: delRange,
            },
            mode,
        },
    } = props;

    const btnsEnabled = !imported.length && !port;

    const modeOptions = [
        { value: 1, type: 'range1', color: '#81B8F9' },
        { value: 2, type: 'range2', color: '#80F9D4' },
        { value: 3, type: 'range3', color: '#E180F9' },
        { value: 4, type: 'range4', color: '#F9DE80' },
    ];

    const [modeVal, setModeVal] = useState(1);
    const [modeArr, setModeArr] = useState(modeOptions);

    useEffect(() => {
        if (deleteAll) {
            setChartMarkerAdd(false);
            setChartMarkerDelete(false);
            setChartRangeAdd(false);
            setChartRangeDelete(false);
        }
        if (add || del || deleteAll) {
            setModeArr([]);
            setModeVal(0);
            setChartMode('arbitrary');
        }
    }, [deleteAll, add, del]);

    useEffect(() => {
        if (!modeVal && !modeArr.length) {
            setModeArr(modeOptions);
            setModeVal(1);
        }
    }, [modeVal, modeArr]);

    // useEffect(() => {
    //     console.log(mode);
    // },[mode]);

    // console.log(port);

    // if (!port) {
    //     return false;
    // }

    return (
        <>
            <button
                className={mode.includes('range') ? ss.root + ' active' : ss.root}
                disabled={btnsEnabled || isOscilloscope}
                onClick={() => {}}
                data-learn="13"
                data-learn-border="13"
                data-learn-position="left"
            >
                <Icon
                    title="Установить диапазоны"
                    name="range-plus"
                    onClick={() => {
                        setChartMarkerAdd(false);
                        setChartMarkerDelete(false);
                        if (mode !== 'arbitrary') {
                            setChartMode('arbitrary');
                        }
                    }}
                />
                <div className={ss.list}>
                    {!btnsEnabled &&
                        modeOptions.map((item, i) => {
                            const { type, color } = item;
                            return (
                                <div
                                    key={i + 'range'}
                                    className={ss.item}
                                    onClick={() => {
                                        setChartMarkerAdd(false);
                                        setChartMarkerDelete(false);
                                        setChartRangeDelete(false);
                                        setChartMode(type);
                                    }}
                                >
                                    <div
                                        className={ss.color}
                                        style={{ background: color }}
                                    ></div>
                                </div>
                            );
                        })}
                </div>
            </button>
            <span
                data-learn="14"
                data-learn-border="14"
                data-learn-position="top"
            >
                <Icon
                    tag="button"
                    name="range-minus"
                    className={delRange ? 'active' : ''}
                    title="Удалить диапазоны"
                    disabled={btnsEnabled || isOscilloscope}
                    onClick={() => {
                        setChartMarkerAdd(false);
                        setChartMarkerDelete(false);
                        setChartRangeAdd(false);
                        setChartRangeDelete(!delRange);
                        setChartMode('arbitrary');
                    }}
                />
            </span>
            <span
                data-learn="11"
                data-learn-border="11"
                data-learn-position="top"
            >
                <Icon
                    tag="button"
                    name={'marker-plus'}
                    className={add ? 'active' : ''}
                    disabled={btnsEnabled || isOscilloscope}
                    title="Установить маркеры"
                    onClick={() => {
                        setChartMarkerAdd(!add);
                        setChartMarkerDelete(false);
                        setChartRangeDelete(false);
                        setChartMode('arbitrary');
                    }}
                />
            </span>
            <span
                data-learn="12"
                data-learn-border="12"
                data-learn-position="top"
            >
                <Icon
                    tag="button"
                    name={'marker-minus'}
                    className={del ? 'active' : ''}
                    title="Удалить маркеры"
                    disabled={btnsEnabled || isOscilloscope}
                    onClick={() => {
                        setChartMarkerDelete(!del);
                        setChartMarkerAdd(false);
                        setChartRangeDelete(false);
                        setChartMode('arbitrary');
                    }}
                />
            </span>
            <span
                data-learn="15"
                data-learn-border="15"
                data-learn-position="top"
            >
                <Icon
                    tag="button"
                    name={'cursor-mode'}
                    className={mode === 'cursor' ? 'active' : ''}
                    disabled={btnsEnabled}
                    title="Курсор"
                    onClick={() => {
                        if (mode !== 'cursor') {
                            setChartMode('cursor');
                        } else {
                            setChartMode('arbitrary');
                        }
                    }}
                />
            </span>
            <span
                data-learn="18"
                data-learn-border="18"
                data-learn-position="top"
            >
                <Icon
                    tag="button"
                    name="trash"
                    title="Очистить график"
                    disabled={btnsEnabled || isOscilloscope}
                    onClick={() => clearChartMarkerModal(true)}
                />
            </span>
        </>
    );
};

export default MarkersSettings;
