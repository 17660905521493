/*
СПЕЦИАЛЬНРЫЕ СИМВОЛЫ:
table: 
  1) ++ ставится в начале строки таблицы, если надо увеличить ее высоту данной строки
comment:
  1) ## text: ставим ## в начале строки. В результате эта строка будет центрирована. 
  2) @@ text @@: текст внутри строки, который должен печататься жирным, выделяется с 2-х сторон знаками @@
  смотреть примеры в части 2, лаб.работа 3
  3) ^r text: в начале строки ставим комбинацию ^r . В этом случае вся строка будет иметь слева вертикальную полосу красного цвета
     ^b text: то же самое с голубым цветом
  4) ^^ text: поднять текст наполовину вверх, например см2 (степень 2 надо поднять вверх). С 2-х сторон - см^^2^^
     ^_ text: опустить текст наполовину вниз, например А2 (индекс 2 надо опустить вниз). С 2-х сторон - А^_2^_
  5) ** - строка комментария может начинаться с **. В этом случае дальше идет название имени файла с формулой.
*/

/*
СДЕЛАНЫ:
ФИЗИКА: 
1.1. Механика:
  сделано - текст и картинки
  ОШИБКИ: Порядок_выполнения_лаб_раб_8.json Порядок_выполнения_лаб_раб_8_2.json 
1.2. Молекулярная физика и термодинамика:
  сделано - текст и картинки
1.3. Электродинамика:
  сделано - текст и картинки
1.4. Оптика: 
  сделано - текст и картинки - 1,2
  текст 3 - 21
1.5. Квантовые явления:
  сделано - текст и картинки
  
ХИМИЯ:
2.1. Микролаборатория по химии:
2.2. Для начального обучения по химии:
БИОЛОГИЯ:
3.1. Биологическая микролаборатория:
3.2. Окружающий мир (естествознание):
*/

//ВАЖНО: видео файлы не должны содержать пробелы в имени!!!

const labsList = {
  "subjectList": 
      [ 
      {
          "subjectName": "Физика",
          "subjectId": "01-physics",
          "sectionList": [
                  {
                  "sectionName": "Механика",
                  "sectionId": "01-mechanics",
                  "labList": [
                      {
                          "labId": "lab01",
                          "labName": "Определение цены деления измерительного прибора",
                          "labPictureFolder": "01-physics/01-mechanics/lab01/",
                          "labPages": [
                              {
                                  "title": "ЛАБОРАТОРНАЯ РАБОТА N1",
                                  "comment": [
                                      "Определение цены деления",
                                      "измерительного прибора"
                                  ]
                              },
                              {
                                "title": "ЦЕЛЬ РАБОТЫ",
                                "comment": [
                                      "Приобретение практических знаний и умений по использованию ", 
                                      "измерительных приборов в физических экспериментах"
                                  ]
                              },
                              {
                                "title": "ОБОРУДОВАНИЕ",
                                "comment": [
                                      "Рулетка", "Шкала прямолинейного желоба", "Мерный цилиндр", 
                                      "Динамометр", "Электронные весы", "Секундомер"
                                      ],
                                  "picture": "Оборудование_лаб_раб_1.json",
                                  "pictureDark": "oborudovanie_01_d.json"
                                },
                              {
                                "title": "ПОДГОТОВКА К РАБОТЕ",
                                "comment": [
                                      "Для измерения различных физических величин служат многочисленные приборы, снабженные шкалами, которые позволяют произвести отсчет измеряемого параметра.",
                                      "@@Шкалы представляют собой нанесенные в определенной последовательности штрихи.@@",
                                      "Некоторые из них (обычно один из пяти или десяти) оцифрованы в единицах измеряемых величин или более крупных их производных. Эти штрихи выполняются укрупненными для лучшего восприятия отсчета, полученного в результате измерения."
                                  ],
                                  "picture": "Подготовка_к_работе_шкалы_лаб_1.json"
                              },                            
                              {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                      "В таблице собраны метрологические параметры приборов, которые будут использоваться при выполнении лабораторных работ.", 
                                      "Приборы для измерения линейных размеров рулетки и желоба обычно имеют расстояние между ближайшими штрихами 1 мм, что соответствует минимальному размеру, который может быть измерен с достаточной точностью. Цена деления этих шкал равна 1 мм.",
                                      "Для удобства измерений служат штрихи, расположенные через 5 или 10 делений - 0.5 см или 1 см. Максимальный размер, который можно получить с помощью рулетки, равен 150 см или 1500 мм."
                                  ],
                                  "table": [
                                      "Прибор|Цена деления|Большие деления|Пределы измерения",
                                      "Рулетка|1 мм|1 см|0...150 см",
                                      "Шкала желоба|1 мм|0,5 см, 1 см|0...46 см",
                                      "Мерный цилиндр|1 мл|10 мл|20...100 мл",
                                      "Динамометр|0,1 Н|0,5 Н|0...5 Н",
                                      "Весы|0,01 г|1 г|0...200 г",
                                      "Секундомер|0,01 с|1 с|... с"
                                  ]
                              },
                              {
                                  "title": "ОБОРУДОВАНИЕ",
                                  "comment": [
                                      "Мерный цилиндр служит для измерения объема жидкости.", 
                                      "Он проградуирован в миллилитрах (1 мл = 1 см^^3^^ = 0,001 л).", 
                                      "", 
                                      "Мелкие штрихи не оцифрованы, крупные оцифрованы через 10 мл." 
                                  ],
                                  "picture": "Мерный_цилиндр_лаб_раб_1.json"
                              },                            
                              {
                                "title": "ОБОРУДОВАНИЕ",
                                "comment": [
                                      "Весы предназначены для измерения массы тел. Они не содержат шкалы в обычном понимании, результат измерения массы отображается на цифровом дисплее последовательно вместе с изменением взвешиваемой массы. Цена деления условной шкалы 0,01 г.", 
                                      "При этом у цифры после запятой, полученной как результат измерения конкретной массы, точность ниже, чем у цифры до запятой. Пределы измерения массы от 0 до 200 г.", 
                                      "Аналогично выглядит шкала цифрового секундомера - цифры отсчета до запятой точны, после запятой точность ниже, но цена деления цифровой шкалы все же 0,01 с." 
                                  ],
                                  "picture": "Весы_лаб_раб_1.json"
                              },                            
                              {
                                "title": "ЗАДАНИЕ",
                                "comment": [
                                      "Ознакомьтесь с приборами, упомянутыми в таблице.",
                                      "Зарисуйте фрагменты их шкал и поместите фрагменты в отчет по проделанной работе.",
                                      "Зарисуйте также таблицу. Ее содержание понадобится в дальнейшем."
                                  ]
                              }                            
                          ]
                      },
                      {
                          "labId": "lab02",
                          "labName": "Измерение объема твердого тела",
                          "labPictureFolder": "01-physics/01-mechanics/lab02/",
                          "labPages": [
                              {
                                "title": "ЛАБОРАТОРНАЯ РАБОТА N2",
                                "comment": [
                                  "Измерение объема твердого тела"
                                ]
                              },
                              {
                                "title": "ЦЕЛЬ РАБОТЫ",
                                 "comment": [
                                  "Измерение объема твердого тела с помощью ", 
                                  "мерного цилиндра"
                                ]
                              },
                              {
                                "title": "ОБОРУДОВАНИЕ",
                                 "comment": [
                                  "Мерный цилиндр", "Тело цилиндрической формы", "Тело произвольной формы", "Емкость с водой", "Нить"
                                  ],
                                  "picture": "Оборудование_лаб_раб_2.json",
                                  "pictureDark": "oborudovanie_02_d.json"
                              },
                              {
                                "title": "ПОДГОТОВКА К РАБОТЕ",
                                "comment": [
                                  "Подготовьте для измерений тело цилиндрической и произвольной формы, вылепленной из пластилина.", "",
                                  "Для этого присоедините к ним нити, как показано на рисунке.", "",
                                  "Емкость для воды наполните приблизительно на половину водой и приготовьте мерный цилиндр."
                                  ],
                                  "picture": "Подготовка_к_работе_шкалы_лаб_2.json"
                              },
                              {
                                "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                "comment": [
                                  "Налейте воды из емкости в мерный цилиндр до начального уровня V^_нач^_, как показано на первом рисунке.", "",
                                  "Опустите в мерный цилиндр тело цилиндрической формы на нити.", "",
                                  "Определите по шкале мерного цилиндра конечный уровень воды V^_кон^_.", "",
                                  "Разность конечного и начального уровня воды в мерном цилиндре равна объему V^_т^_ тела цилиндрической формы:", "",
                                  "##@@V^_т^_ = V^_кон^_ - V^_нач^_"
                                  ],
                                  "picture": "Порядок_выполнения_лаб_раб_2.json",
                                  "pictureDark": "mech_02_01_d.json"
                              },
                              {
                                "title": "ЗАДАНИЕ",
                                "comment": [
                                  "Аналогично определите объем тела произвольной формы.", "",
                                  "Результат расчетов и измерений, а также рисунки постановки лабораторной работы поместите в отчет."
                                  ]
                              }
                          ]
                      },
                      {
                          "labId": "lab03",
                          "labName": "Измерение объема жидкости",
                          "labPictureFolder": "01-physics/01-mechanics/lab03/",
                          "labPages": [
                              {
                                  "title": "ЛАБОРАТОРНАЯ РАБОТА N3",
                                  "comment": [
                                    "Измерение объема жидкости"
                                  ]
                                },
                                {
                                  "title": "ЦЕЛЬ РАБОТЫ",
                                  "comment": [
                                    "Измерение объема жидкости с помощью ", 
                                    "мерного цилиндра"
                                  ]
                                },
                                {
                                  "title": "ОБОРУДОВАНИЕ",
                                  "comment": [
                                    "Мерный цилиндр", "Мерный стакан с водой"
                                    ],
                                    "picture": "Оборудование_лаб_раб_3.json",
                                    "pictureDark": "oborudovanie_03_d.json"
                                  },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Для измерения объема жидкости достаточно некоторого количества воды в мерном стакане  и пустого мерного цилиндра."
                                    ],
                                    "picture": "Подготовка_к_работе_шкалы_лаб_3.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Залейте в мерный стакан то количество воды, которое вы хотите измерить.",
                                    "Отсчитайте по шкале мерного цилиндра уровень воды, выраженный в миллилитрах.",
                                    "Один миллилитр объема жидкости равен 1 см^^3^^."
                                    ],
                                    "picture": "Порядок_выполнения_лаб_раб_3.json",
                                    "pictureDark": "mech_03_01_d.json"
                                  },
                                {
                                  "title": "ЗАДАНИЕ",
                                  "comment": [
                                    "Зарисуйте в отчете постановку опыта и результаты измерений."
                                    ]
                                }
                            ]
                      },
                      {
                          "labId": "lab04",
                          "labName": "Измерение размеров плоских тел и расчет их площади",
                          "labPictureFolder": "01-physics/01-mechanics/lab04/",
                          "labPages": [
                              {
                                  "title": "ЛАБОРАТОРНАЯ РАБОТА N4",
                                  "comment": [
                                    "Измерение размеров плоских тел и ",
                                    "расчет их площади"
                                  ]
                                },
                                {
                                  "title": "ЦЕЛЬ РАБОТЫ",
                                  "comment": [
                                    "Приобретение навыков измерений размеров больших и ", 
                                    "малых плоских тел и вычисление их площади ",
                                    "по результатам измерений"
                                  ]
                                },
                                {
                                  "title": "ОБОРУДОВАНИЕ",
                                  "comment": [
                                    "Рулетка", "Мерный цилиндр", "Латунный цилиндр", "Желоб прямолинейный", 
                                    "Столешница ученического стола",  "Пенал лабораторного комплекта"
                                    ],
                                    "picture": "Оборудование_лаб_раб_4.json",
                                    "pictureDark": "oborudovanie_04_d.json"
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Подготовьте таблицу для записи результатов измерений."
                                    ],
                                    "table": [
                                      "Измеряемый объект|Длина, см|Ширина, см|Площадь, см^^2^^",
                                      "Желоб прямолинейный| | | ",
                                      "Шкала желоба| | | ",
                                      "Мерный цилиндр| | | ",
                                      "Латунный цилиндр| | | ",
                                      "Столешница стола| | | ",
                                      "Пенал для упаковки| | | "
                                  ]
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "С помощью рулетки измерьте размеры желоба и рассчитайте площадь заготовки.",
                                    "Измерьте размеры мерного цилиндра и рассчитайте площадь его наружной поверхности. Основание цилиндра из измерений и расчетов предлагается исключить."
                                    ],
                                    "picture": "Порядок_выполнения_лаб_раб_4.json"
                                },
                                {
                                  "title": "ЗАДАНИЕ",
                                  "comment": [
                                    "Запишите результаты измерений в таблицу."
                                    ]
                                }
                            ]
                      },
                      {
                          "labId": "lab05",
                          "labName": "Измерение массы твердого тела",
                          "labPictureFolder": "01-physics/01-mechanics/lab05/",
                          "labPages": [
                              {
                                  "title": "ЛАБОРАТОРНАЯ РАБОТА N5",
                                  "comment": [
                                    "Измерение массы твердого тела"
                                  ]
                                },
                                {
                                  "title": "ЦЕЛЬ РАБОТЫ",
                                  "comment": [
                                    "Приобретение навыков измерения массы тел с помощью весов"
                                  ]
                                },
                                {
                                  "title": "ОБОРУДОВАНИЕ",
                                  "comment": [
                                    "Весы", "Стальной цилиндр", "Грузы (4 шт.)"
                                  ],
                                  "picture": "Оборудование_лаб_раб_5.json",
                                  "pictureDark": "oborudovanie_05_d.json"
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Масса измеряется в килограммах (кг) или граммах (1 кг = 1000 г) и представляет собой меру инерции любого тела. Еще одно определение массы – количество материи в теле.", "",
                                    "Масса величина скалярная. Она не зависит от внешних причин – места, в котором измеряется масса, скорости движения тела, обладающего массой, если скорость тела не приближается к световой.",  "",
                                    "Иногда массу путают с весом, который является силой и, следовательно, величиной векторной. Происходит эта путаница потому, что массу измеряют на весах, заранее пересчитав ее из силы, с которой взвешиваемое тело давит на рабочую поверхность весов.",  "",
                                    "Получается, что измеритель силы, под действием которой платформа весов приходит в движение, проградуирован в единицах массы – килограммах или граммах. "
                                  ]
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Масса определяется из уравнения второго закона Ньютона:",
                                    "",
                                    "##@@F = mg,",
                                    "",
                                    "где: F – сила, с которой тело давит на плоскость, на которой свободно лежит, или натягивает подвес, на котором висит в состоянии покоя; сила измеряется в ньютонах (Н);",
                                    "",
                                    "g – ускорение свободного падения, равное 9,8 м/с²;",
                                    "m – масса тела в кг.",
                                    "",
                                    "Тело массой 100 г создает в поле земного тяготения силу в 1 Н.  "
                                    ]
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Приготовьте весы для взвешивания. Дождитесь окончания автотестирования.",
                                    "Взвесьте сначала один груз. Его масса должна быть равна 100 г. Сила давления такого груза на рабочую платформу весов равна 1 Н."
                                    ],
                                    "picture": "Порядок_выполнения_лаб_раб_5.json"
                                },
                                {
                                  "title": "ЗАДАНИЕ",
                                  "comment": [
                                    "Взвесьте по очереди остальные грузы и определите общую массу четырех грузов.", 
                                    "Взвесьте стальной цилиндр.", 
                                    "Результаты измерений запишите в отчет по проделанной работе."
                                    ]
                                }
                            ]
                      },
                      {
                          "labId": "lab06",
                          "labName": "Измерение плотности твердого тела",
                          "labPictureFolder": "01-physics/01-mechanics/lab06/",
                          "labPages": [
                            {
                                  "title": "ЛАБОРАТОРНАЯ РАБОТА N6",
                                  "comment": [
                                    "Измерение плотности твердого тела"
                                  ]
                                },
                                {
                                  "title": "ЦЕЛЬ РАБОТЫ",
                                  "comment": [
                                    "Формирование навыков определения плотности ", 
                                    "твердого тела по его объему и массе"
                                  ]
                                },
                                {
                                  "title": "ОБОРУДОВАНИЕ",
                                  "comment": [
                                    "Весы", "Латунный цилиндр ", "Тело произвольной формы", 
                                    "Стальной цилиндр", "Мерный цилиндр с водой", "Нить"
                                    ],
                                    "picture": "Оборудование_лаб_раб_6.json",
                                    "pictureDark": "oborudovanie_06_d.json"
                                  },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Важным параметром любого вещества является его плотность – масса единичного объема.", "",
                                    "В системе СИ плотность измеряется в кг/м³. Иногда удобнее измерять плотность в производных единицах – в г/см³."
                                    ],
                                    "picture": "Подготовка_к_работе_6.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Поочередно измерьте массу m тел, представленных на рисунке на весах.",
                                    "",
                                    "Результаты измерений запишите в таблицу."
                                    ],
                                    "picture": "Порядок_выполнения_лаб_6.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    ],
                                    "table": [
                                      "Тело|Масса m, г|Объем V^_т^_, см^^3^^|Плотность ρ, г / см^^3^^",
                                      "Стальной цилиндр|m^_1^_ |V^_т1^_ |ρ^_1^_ ",
                                      "Тело произвольной формы|m^_2^_ |V^_т2^_ |ρ^_2^_ ",
                                      "Латунный цилиндр|m^_3^_ |V^_т3^_ |ρ^_3^_ "
                                  ]
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Определите объем тел, плотность которых нужно измерить.", "",
                                    "Для этого поочередно опустите их в мерный цилиндр, начальный объем воды в котором равен V^_нач^_.",
                                    "В результате уровень воды в цилиндре поднимется, ее объем увеличится до конечного значения V^_кон^_, а объем воды, вытесненной каждым телом, и, следовательно, собственные объемы тел V^_т^_ определятся по формуле как разность:",
                                    "##@@V^_т^_ = V^_кон^_ - V^_нач^_."
                                    ],
                                    "picture": "Порядок_выполнения_лаб_раб_6.json",
                                    "pictureDark": "mech_06_02_d.json"
                                },
                                {
                                  "title": "ЗАДАНИЕ",
                                  "comment": [
                                    "Теперь, зная объемы и массы тел, определите их плотность по формуле:",
                                    "##@@ρ = m / V", "",
                                    "Результаты измерений и расчетов запишите в отчет о проделанной работе."
                                    ]
                                }
                            ]
                      },
                      {
                          "labId": "lab07",
                          "labName": "Измерение массы жидкости",
                          "labPictureFolder": "01-physics/01-mechanics/lab07/",
                          "labPages": [
                              {
                                  "title": "ЛАБОРАТОРНАЯ РАБОТА N7",
                                  "comment": [
                                    "Измерение массы жидкости"
                                  ]
                                },
                                {
                                  "title": "ЦЕЛЬ РАБОТЫ",
                                  "comment": [
                                    "Изучение способа определения массы жидкости"
                                  ]
                                },
                                {
                                  "title": "ОБОРУДОВАНИЕ",
                                  "comment": [
                                    "Мерный стакан с водой", "Весы", "Мерный цилиндр"
                                    ],
                                    "picture": "Оборудование_лаб_раб_7.json",
                                    "pictureDark": "oborudovanie_07_d.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Измерьте массу пустого стакана.", "",
                                    "Налейте в стакан воду, массу которой нужно измерить, и поставьте его на платформу весов.", "",
                                    "Определите массу стакана с водой."
                                  ],
                                  "picture": "Порядок_выполнения_лаб_раб_7_.json"
                                },
                                {
                                  "title": "ЗАДАНИЕ",
                                  "comment": [
                                    "Для получения массы воды из массы стакана с водой вычтите массу пустого стакана.", "",
                                    "Результаты измерений запишите в отчет о проделанной работе."
                                    ]
                                }
                            ]
                      },
                      {
                        "labId": "lab08",
                        "labName": "Измерение плотности жидкости",
                        "labPictureFolder": "01-physics/01-mechanics/lab08/",
                        "labPages": [
                          {
                            "title": "ЛАБОРАТОРНАЯ РАБОТА N8",
                            "comment": [
                              "Измерение плотности жидкости"
                            ]
                          },
                          {
                            "title": "ЦЕЛЬ РАБОТЫ",
                            "comment": [
                             "Подтвердить в ходе эксперимента утверждение, что плотность ", 
                             "вещества не зависит от его объема и массы"
                           ]
                         },
                         {
                          "title": "ОБОРУДОВАНИЕ",
                          "comment": [
                            "Мерный стакан с водой", "Весы", "Мерный цилиндр"
                           ],
                          "picture": "Оборудование_лаб_раб_8.json",
                          "pictureDark": "oborudovanie_08_d.json"
                       },
                       {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Измерьте массу m^_0^_ пустого мерного цилиндра.", "",
                          "Налейте в мерный цилиндр 30 мл воды, ориентируясь по шкале.", "",
                          "Установите цилиндр с водой на платформу весов и измерьте полную массу m^_1^_."
                          ],
                          "picture": "Порядок_выполнения_лаб_раб_8.json",
                          "pictureDark": "mech_08_01_d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Измерьте массу m^_0^_ пустого мерного цилиндра.", "",
                          "Налейте в мерный цилиндр 30 мл воды, ориентируясь по шкале.", "",
                          "Установите цилиндр с водой на платформу весов и измерьте полную массу m^_1^_.", "",
                          "Повторите опыт, налив в цилиндр сначала 60 мл, затем 90 мл."
                          ],
                          "picture": "Порядок_выполнения_лаб_раб_8_2.json",
                          "pictureDark": "mech_08_02_d.json"
                      },
                      {
                        "title": "ЗАДАНИЕ",
                        "comment": [
                            "Зарисуйте в отчете постановку опыта и результаты измерений."
                          ],
                          "table" : [
                            "Опыт|Масса m, г|Объем V, см^^3^^|Плотность ρ, г/см^^3^^",
                            "1|m^_1^_|V^_1^_|ρ^_1^_ ",
                            "2|m^_2^_|V^_2^_|ρ^_2^_ ",
                            "3|m^_3^_|V^_3^_|ρ^_3^_ "
                          ]
                          },
                          {
                            "title": "ЗАДАНИЕ",
                            "comment": [
                              "Рассчитайте плотность воды для всех измеренных объемов и сравните полученные значения.", "", 
                              "Для подтверждения независимости плотности вещества от его объема и массы полученные значения плотности должны быть одинаковыми."
                           ]
                          }
                        ]
                      },
                      {
                          "labId": "lab09",
                          "labName": "Измерение скорости равномерного движения",
                          "labPictureFolder": "01-physics/01-mechanics/lab09/",
                          "labPages": [
                            {
                              "title": "ЛАБОРАТОРНАЯ РАБОТА N9",
                              "comment": [
                                "Измерение скорости равномерного движения"
                              ]
                            },
                            {
                              "title": "ЦЕЛЬ РАБОТЫ",
                              "comment": [
                                "Экспериментальная проверка утверждения, что скорость ", 
                                "равномерного прямолинейного движения остается ", 
                                "постоянной на всем пути"
                              ]
                            },
                            {
                              "title": "ОБОРУДОВАНИЕ",
                              "comment": [
                                "Прямолинейный желоб", "Шкала желоба", "Опора желоба (2 шт.)",
                                "Стальной шарик", "Латунный цилиндр"
                                ],
                                "picture": "Оборудование_лаб_раб_9.json",
                                  "pictureDark": "oborudovanie_09_d.json"
                            },
                            {
                              "title": "ПОДГОТОВКА К РАБОТЕ",
                              "comment": [
                                "Установите желоб на подставки на ровной горизонтальной поверхности так, чтобы шарик, помещенный в любую его точку, оставался в покое, то есть находился в состоянии безразличного равновесия.", "",
                                "В этом состоянии силы, действующие на шарик, взаимно уравновешены, а их равнодействующая равна нулю. Следовательно, можно считать, что движение шарика происходит в инерциальной системе отсчета."
                                ],
                                "picture": "Подготовка_к_работе_9.json"
                            },
                            {
                              "title": "ПОДГОТОВКА К РАБОТЕ",
                              "comment": [
                                "Равномерное прямолинейное движение удастся получить, если шарику сообщить начальную порцию энергии, одинаковую для нескольких следующих один за другим пусков.","",
                                "Для этого предлагается прижать шарик рукой к выступу стартовой горки, установленной в начале желоба, как показано на рисунке. При этом шарик будет стартовать с высоты h, приобретя по отношению к уровню, на котором он будет катиться, начальную потенциальную энергию @@mgh@@.",
                                "Скатившись на желоб с высоты h, потенциальная энергия превратится в кинетическую энергию движения в соответствии с формулой:",
                                "##@@mgh = mv^^2^^ / 2@@."
                                ],
                                "picture": "Подготовка_к_работе_9_2.json"
                            },
                            {
                              "title": "ПОДГОТОВКА К РАБОТЕ",
                              "comment": [
                                "В этой формуле: m – масса шарика;",
                                "g – ускорение свободного падения;",
                                "h – высота, с которой шарик скатывается на желоб;",
                                "V – скорость, которую шарик приобретет после скатывания с горки.", "", 
                                "Скорость, вызванная приобретенной кинетической энергией, будет постоянной и одинаковой в каждом очередном пуске, поскольку движению шарика ничто не препятствует, кроме небольшого трения.", "", 
                                "Подготовьте секундомер."
                              ],
                              "picture":"Подготовка_к_работе_9_3.json"
                            },
                            {
                              "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                              "comment":[
                                "Удерживая шарик пальцами, опустите его на желоб и прижмите к выступу горки. Сдвиньте шкалу так, чтобы ее нулевая отметка совпала с центром шарика.", "", 
                                "Осторожно освободите шарик. Скатившись с горки, он разгонится и покатится дальше с постоянной скоростью до удара о препятствие в виде латунного цилиндра, помещенного в желоб на таком расстоянии, чтобы центр тяжести шарика прокатился по желобу до остановки 180 мм от точки старта."
                              ],
                              "picture":"Подготовка_к_работе_9_4.json"
                            },
                            {
                              "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                              "comment":[
                                "В момент перехода шарика с горки на желоб включите секундомер и остановите его по звуку удара шарика о препятствие.", "", 
                                "Полученный результат покажет время t, за которое шарик пройдет 18 см длины желоба.", "", 
                                "Отсюда можно определить реальную скорость шарика на первом участке пути.", 
                                "Проведите серию пусков, и в каждом вычислите скорость V шарика по формуле:",
                                "##@@V = S / t."
                              ],
                              "picture":"Подготовка_к_работе_9_5.json"
                            },
                            {
                              "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                              "comment":[
                                "Результаты измерений и расчетов поместите в таблицу."
                              ],
                              "table":[
                                "Опыт 1|Время t1, с|Путь S, см|Скорость V, см/с|Опыт 2|Время t2, с|Путь S, см|Скорость V, см/с",
                                "1||18||||36|",
                                "2||18||||36|",
                                "3||18||||36|",
                                "Средн.||18||Средн.||36|"
                              ]
                            },
                            {
                              "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                              "comment":[
                                "Вторую серию пусков выполните для расстояния 36 см до ограничительного цилиндра.", "",
                                "Результаты измерений и расчетов поместите в таблицу."
                              ],
                              "picture":"Подготовка_к_работе_9_6.json"
                            },
                            {
                              "title": "ЗАДАНИЕ",
                              "comment":[
                                "Определите средние значения скорости шарика в двух группах опытов и убедитесь, что они одинаковы, то есть движение шарика по горизонтальному желобу можно считать постоянным."
                              ]
                            }                          
                          ]
                      },
                      {
                          "labId": "lab10",
                          "labName": "Изучение зависимости пути от времени при равноускоренном движении",
                          "labPictureFolder": "01-physics/01-mechanics/lab10/",
                          "labPages": [
                              {
                                  "title": "ЛАБОРАТОРНАЯ РАБОТА N10",
                                  "comment": [
                                    "Изучение зависимости пути от времени", 
                                    "при равноускоренном движении"
                                  ]
                                },
                                {
                                  "title": "ЦЕЛЬ РАБОТЫ",
                                  "comment": [
                                    "Исследование ряда последовательных перемещений тела ", 
                                    "при равноускоренном прямолинейном движении"
                                  ]
                                },
                                {
                                  "title": "ОБОРУДОВАНИЕ",
                                  "comment": [
                                    "Прямолинейный желоб", "Шкала желоба", "Опоры желоба (2 шт.)",
                                    "Стальной шарик", "Секундомер", "Подставка 0,6 см"
                                    ],
                                    "picture": "Оборудование_лаб_раб_10.json",
                                    "pictureDark": "oborudovanie_10_d.json"
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                      "Равноускоренное прямолинейное движение характеризуется следующими основными свойствами:", "",
                                      "-скорость тела за любые равные промежутки времени изменяется одинаково, т. е. это движение с постоянным по модулю и направлению ускорением.",
                                      "-ускорение есть отношение изменения скорости к интервалу времени, в течение которого эта скорость изменилась.",
                                      "-ускорение движения постоянно во все время движения.", "", 
                                      "Для получения равноускоренного прямолинейного движения шарик пускают по наклонной плоскости или желобу, установленному под углом  к горизонтали.", "",
                                      "Шарик при этом скатывается равноускорено под действием постоянной силы- проекции силы тяжести на направление вектора скорости."
                                    ],
                                    "picture": "Подготовка_к_работе_10_1.json",
                                    "pictureDark": "mech_10_01_d.json"
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Установите желоб под таким углом, при котором время полного прохождения всего желоба шариком было бы равно 3 с.  Для этого желоб поместите в две одинаковые опоры.", "",
                                    "Под левую опору подложите подставку 2 высотой 0,6 см, чтобы высота возвышения начала желоба над его концом была равна 0,6 см."
                                    ],
                                    "picture": "Подготовка_к_работе_10_2.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Отпустите шарик и одновременно включите секундомер. Шарик начнет скатываться по желобу и, в конце концов, покинет его. В момент завершения скатывания остановите секундомер и определите время, за которое шарик проходит всю длину желоба. ", "",
                                    "Это время должно быть в пределах 3,1…3,3 с. Если результат измерения окажется меньше 3 с, под основание правой опоры подложите небольшой листок бумаги и повторите измерение. ", "", 
                                    "Добейтесь, чтобы время скатывания было больше 3 с – в обозначенных выше пределах. Выполненная настройка должна оставаться неизменной до завершения всех измерений."
                                    ],
                                    "picture":"Подготовка_к_работе_10_3.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment":[
                                    "Поскольку измерение пути шарика за первую и вторую секунды движения при старте с нулевой отметки шкалы затруднительно, следует перенести это измерение в нижнюю часть желоба, когда положение старта и финиша становятся более определенными.", "",
                                    "Сначала, удерживая шарик пальцами, установите его на расстоянии 10 см от нижнего конца желоба. (Это значение приблизительное, его следует уточнить в процессе дальнейших измерений).", "", 
                                    "Освободите шарик, чтобы он начал скатываться, и одновременно включите секундомер.", "",
                                    "Остановите секундомер в момент, когда шарик покинет желоб. Измерьте время скатывания t,с. Это время наверняка окажется больше 1 с."
                                  ],
                                  "picture":"Подготовка_к_работе_10_4.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment":[
                                    "Повторите опыт, уменьшив расстояние от точки старта до финиша.", "",
                                    "Проделайте эту операцию несколько раз и добейтесь, чтобы время скатывания было как можно ближе к 1 с. Зафиксируйте в таблице  уточненное время скатывания t^_1.1^_ и расстояние S^_1.1^_ – путь шарика за одну секунду. Измеренное время должно находиться в пределах t^_с^_ = 0,9…1,1 с. Если t^_с^_ меньше 1 с, нужно увеличить расстояние от точки старта до нижнего конца желоба, если же больше, это расстояние нужно уменьшить.", "",
                                    "Повторите опыт несколько раз. Этот опыт позволит измерить путь шарика по желобу за первую секунду скатывания.", "", 
                                    "Результаты измерений занесите в таблицу."
                                  ],
                                  "table":[
                                    "Номер|Время скатывания, с|Путь за первую секунду|Путь за вторую секунду|Путь за третью секунду",
                                    "1|t^_1.1^_=|S^_1.1^_=|S^_2.1^_=|S^_3.1^_=",
                                    "2|t^_1.2^_=|S^_1.2^_=|S^_2.2^_=|S^_3.2^_=",
                                    "3|t^_1.3^_=|S^_1.3^_=|S^_2.3^_=|S^_3.3^_=",
                                    "++Средн.|(t^_1.1^_+t^_1.2^_+t^_1.3^_):3=t^_ср^_|(S^_1.1^_+S^_1.2^_+S^_1.3^_)=S^_1ср^_|(S^_2.1^_+S^_2.2^_+S^_2.3^_)=S^_2ср^_|(S^_3.1^_+S^_3.2^_+S^_3.3^_)=S^_3ср^_"
                                  ]
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment":[
                                    "Следующую группу измерений выполните при стартовом положении шарика на расстоянии 20 см от конца желоба.", "", 
                                    "Изменяя расстояние от точки старта до финиша в ряде попыток добейтесь, чтобы время скатывания шарика было равно 2 с и запишите расстояние S^_2^_ от стартовой позиции шарика до нижнего конца желоба.", "", 
                                    "Определите расстояние s^_2^_, которое шарик преодолеет за вторую секунду скатывания", "",
                                    "##@@s^_2^_ = S^_2^_ – S^_1^_.", "",
                                    "Аналогично выполните опыт для времени скатывания шарика 3 с."
                                  ],
                                  "picture":"Подготовка_к_работе_10_5.json" 
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment":[
                                    "Путь, пройденный шариком за третью секунду, будет равен ", "",
                                    "##@@s^_3^_ = S^_3^_ – S^_2^_.", "", 
                                    "Данные, полученные в результате измерений, занесите в таблицу."
                                  ],
                                  "picture":"Подготовка_к_работе_10_6.json"
                                },
                                {
                                  "title": "ЗАДАНИЕ",
                                  "comment":[
                                    "Движение, которое наблюдалось при выполнении работы, оказалось ускоренным, поскольку расстояние, пройденное шариком за вторую секунду, больше расстояния, пройденного за первую секунду, а расстояние, пройденное за третью секунду больше расстояния, пройденного за вторую секунду.",  "",
                                    "Вычислите прирост расстояний, пройденных за вторую секунду: @@S2^_cp^_-S1^_cp^_ = ΔS^_2^_@@", "",
                                    "Определите прирост скорости за вторую секунду по формуле: @@ΔV^_2^_ = ΔS^_2^_/1c@@", "", 
                                    "Рассчитайте ускорение движения, разделив прирост скорости, который произошел за вторую секунду: @@a^_2^_ = ΔV^_2^_/1c", "", 
                                    "Аналогично определите ускорение а3 за третью секунду.", "", 
                                    "Рассмотренное ускорение можно считать равноускоренным, если выполняется равенство @@a^_2^_ = а^_3^_."
                                  ]
                                }
                            ]
                    },
                    {
                      "labId": "lab11",
                      "labName": "Исследование скоростей равноускоренного движения",
                      "labPictureFolder": "01-physics/01-mechanics/lab11/",
                      "labPages": [
                          {
                              "title": "ЛАБОРАТОРНАЯ РАБОТА N11",
                              "comment": [
                                "Исследование скоростей равноускоренного движения"
                              ]
                            },
                            {
                              "title": "ЦЕЛЬ РАБОТЫ",
                              "comment": [
                                "Расчет скоростей при равноускоренном движении"
                              ]
                            },
                            {
                              "title": "ОБОРУДОВАНИЕ",
                              "comment": [
                                "Прямолинейный желоб", "Шкала желоба", "Опора желоба (2 шт.)", "Стальной шарик", "Секундомер", "Подставка 0,6 см"
                                ],
                                "picture": "Оборудование_лаб_раб_11.json",
                                    "pictureDark": "oborudovanie_11_d.json"
                            },
                            {
                              "title": "ПОДГОТОВКА К РАБОТЕ",
                              "comment": [
                                "Равноускоренное прямолинейное движение — это движение, при котором скорость тела за любые равные промежутки времени изменяется одинаково, т. е. это движение с постоянным по модулю и направлению ускорением: @@а = const@@. ", 
                                "По определению ускорение есть отношение изменения скорости к интервалу времени, в течение которого эта скорость изменилась:",
                                "##@@a = Δv / Δ t.",
                                "В работе используется постановка из лабораторной работы №10 и данные ее таблицы."
                                ],
                                "picture": "Подготовка_к_работе_11.json"
                            },
                            {
                              "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                              "comment": [
                                "Пользуясь данными таблицы вычислите среднюю скорость шарика на первой секунде скатывания по формуле: ",
                                "##@@V^_1^_ = S^_1ср^_: 1 с.", "", 
                                "Определите среднюю скорость шарика на второй секунде скатывания по формуле: ",
                                "##@@V^_2^_ = S^_2ср^_: 1 с.", "", 
                                "Определите среднюю скорость шарика на третьей секунде скатывания по формуле: ",
                                "##@@V^_3^_ = S^_3ср^_: 1 с.", "", 
                                "Определите приращение скорости шарика на второй секунде скатывания: ",
                                "##@@ΔV^_2^_ = V^_2^_ – V^_1^_.", "", 
                                "Определите приращение скорости шарика на третьей секунде скатывания: ",
                                "##@@ΔV^_3^_ = V^_3^_ – V^_2^_.", "", 
                                "Результаты измерений и расчетов запишите в таблицу."
                                ]
                            },
                            {
                              "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                              "comment": [
                                ],
                                "table":[
                                  "Опыт|V^_1^_|V^_2^_|V^_3^_|ΔV^_2^_|ΔV^_3^_",
                                  "1|V^_1.1^_=|V^_2.1^_=|V^_3.1^_=|V^_2.1^_-V^_1.1^_=|V^_3.1^_-V^_2.1^_=",
                                  "2|V^_1.2^_=|V^_2.2^_=|V^_3.2^_=|V^_2.2^_-V^_1.2^_=|V^_3.2^_-V^_2.2^_=",
                                  "3|V^_1.3^_=|V^_2.3^_=|V^_3.3^_=|V^_2.3^_-V^_1.3^_=|V^_3.3^_-V^_2.3^_=",
                                  "++Средн.|V^_1cp^_=|V^_2cp^_=|V^_3cp^_=|ΔV^_2cp^_=|ΔV^_3cp^_="
                                ]
                            },
                            {
                              "title": "ЗАДАНИЕ",
                              "comment": [
                                "Убедитесь в том, что расчеты подтвердили, что движение, которое наблюдалось при выполнении работы, оказалось равноускоренным, поскольку скорость шарика на второй секунде движения выросла по сравнению со скоростью на первой секунде и на третьей секунде по сравнению со скоростью на второй секунде на одно и то же значение."
                                ]
                            }
                        ]
                    },
                    {
                        "labId": "lab12",
                        "labName": "Ускорение тела при равноускоренном движении",
                        "labPictureFolder": "01-physics/01-mechanics/lab12/",
                        "labPages": [
                            {
                                "title": "ЛАБОРАТОРНАЯ РАБОТА N12",
                                "comment": [
                                  "Ускорение тела при равноускоренном движении"
                                ]
                              },
                              {
                                "title": "ЦЕЛЬ РАБОТЫ",
                                 "comment": [
                                  "Определение ускорения тела, движущегося равноускорено"
                                ]
                              },
                              {
                                "title": "ОБОРУДОВАНИЕ",
                                "comment": [
                                  "Прямолинейный желоб", "Шкала желоба", "Опоры желоба (2 шт.)", "Металлический шарик", "Секундомер", "Подставка 0,6 см"
                                  ],
                                  "picture": "Оборудование_лаб_раб_12.json",
                                    "pictureDark": "oborudovanie_12_d.json"
                              },
                              {
                                "title": "ПОДГОТОВКА К РАБОТЕ",
                                "comment": [
                                  "Ускорение движения определяется как отношение изменения скорости ко времени, за которое это изменение произошло. ", "", 
                                  "Данные, собранные в таблице из лабораторной работы №10 позволяют вычислить ускорение скатывания шарика за три секунды движения."
                                  ],
                                  "picture": "Подготовка_к_работе_12.json"
                              },
                              {
                                "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                "comment": [
                                  "Определите приращение скорости шарика на второй секунде скатывания по данным таблицы из лабораторной работы №11.", "",
                                  "##@@Δv^_2^_ = v^_2ср^_ – v^_1ср^_", "", 
                                  "Определите приращение скорости шарика на третьей секунде скатывания ", "",
                                  "##@@Δv^_3^_ = v^_3ср^_ – v2^_ср^_."
                                  ]
                              },
                              {
                                "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                "comment": [
                                  ],
                                  "table":[
                                    "Опыт|V^_1^_|V^_2^_|V^_3^_|ΔV^_2^_|ΔV^_3^_",
                                    "1|V^_1.1^_=|V^_2.1^_=|V^_3.1^_=|V^_2.1^_-V^_1.1^_=|V^_3.1^_-V^_2.1^_=",
                                    "2|V^_1.2^_=|V^_2.2^_=|V^_3.2^_=|V^_2.2^_-V^_1.2^_=|V^_3.2^_-V^_2.2^_",
                                    "3|V^_1.3^_=|V^_2.3^_=|V^_3.3^_=|V^_2.3^_-V^_1.3^_=|V^_3.3^_-V^_2.3^_=",
                                    "++Средн.|V^_1cp^_=|V2^_cp^_=|V3^_cp^_=|ΔV^_2cp^_=|ΔV^_3cp^_="
                                  ]
                              },
                              {
                                "title": "ЗАДАНИЕ",
                                "comment": [
                                  "Результаты измерений и расчетов подтвердили, что движение, которое наблюдалось при выполнении работы, оказалось равноускоренным, поскольку скорость на второй секунде движения выросла по сравнению со скоростью на первой секунде и на третьей секунде по сравнению со скоростью на второй секунде на одно и то же значение.", "", 
                                  "Полученные приращения скорости на второй и третьей секундах, произошедшие за 1 с, численно равны ускорению движения. ", "", 
                                  "Если эти приращения одинаковы, то скатывание шарика по прямолинейному желобу является равноускоренным."
                                  ]
                              }
                          ]
                    },
                    {
                        "labId": "lab13",
                        "labName": "Измерение силы динамометром",
                        "labPictureFolder": "01-physics/01-mechanics/lab13/",
                        "labPages": [
                            {
                                "title": "ЛАБОРАТОРНАЯ РАБОТА N13",
                                "comment": [
                                  "Измерение силы динамометром"
                                ]
                              },
                              {
                                "title": "ЦЕЛЬ РАБОТЫ",
                                 "comment": [
                                  "Изучение устройства динамометра, отработка навыков измерения силы с помощью динамометра"
                                ]
                              },
                              {
                                "title": "ОБОРУДОВАНИЕ",
                                "comment": [
                                  "Штатив с муфтой и лапкой", "Динамометр", "Грузы (4 шт.)" 
                                  ],
                                  "picture": "Оборудование_лаб_раб_13.json",
                                  "pictureDark": "oborudovanie_13_d.json"
                              },
                              {
                                "title": "ПОДГОТОВКА К РАБОТЕ",
                                "comment": [
                                  "Динамометр предназначен для измерения веса или силы земного тяготения @@F = mg@@. ",
                                  "Следует знать, что градуировка прибора выполняется в том месте земного шара, где будут выполняться измерения."
                                  ],
                                  "picture": "Подготовка_к_работе_лаб_13.json"
                              },
                              {
                                "title": "ПОДГОТОВКА К РАБОТЕ",
                                "comment": [
                                  "Соберите штатив. Зафиксируйте в его лапке верхний выступ корпуса динамометра так, чтобы корпус сохранял вертикальное положение. Это условие выполняется для того, чтобы при нагрузке прибора тяга, связывающая пружину с крючком для навешивания груза, не испытывала избыточного трения при проходе сквозь нижнее отверстие корпуса. Убедитесь, что визир динамометра без нагрузки находится на делениях 0 Н его шкал.",
                                  "Единица измерения силы – ньютон. Пределы измерения динамометра – 0…5 Н. Шкала динамометра линейная. Цена малого деления шкалы 0,1 Н. Тело массой 100 г, находящееся в поле земного тяготения, создает усилие в 1 Н."
                                  ],
                                  "picture": "Подготовка_к_работе_лаб_13_2.json"
                              },
                              {
                                "title": "ПОДГОТОВКА К РАБОТЕ",
                                "comment": [
                                  "Примечание. Полную сборку для лабораторной работы как на рисунке выполнить не удастся из-за ограниченной длины штатива. Избежать этого несоответствия можно, если к крючку динамометра подвешивать не один груз, а сразу несколько. В этом случае полная длина постановки уменьшится и ее можно будет осуществить. "
                                  ],
                                  "picture":"Подготовка_к_работе_лаб_13_3.json"
                              },
                              {
                                "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                "comment": [
                                  "Нагрузите динамометр одним грузом. Из-за растяжения пружины визир и тяга сместятся вниз до первого большого деления, обозначенного как 1 Н."
                                  ],
                                  "picture":"Подготовка_к_работе_лаб_13_4.json"
                              },
                              {
                                "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                "comment": [
                                  "Последовательно нагружая динамометр двумя, тремя и четырьмя грузами, отметьте, что с каждым новым грузом визир опускается вниз на одно большое деление, то есть на 1 Н."
                                  ],
                                  "picture":"Подготовка_к_работе_лаб_13_5.json"
                              },
                              {
                                "title": "ЗАДАНИЕ",
                                "comment": [
                                  "Опишите в отчете последовательность выполнения работы и зарисуйте динамометр."
                                  ]
                              }
                          ]
                    },
                    {
                        "labId": "lab14",
                        "labName": "Сложение сил, направленных вдоль одной прямой",
                        "labPictureFolder": "01-physics/01-mechanics/lab14/",
                        "labPages": [
                            {
                                "title": "ЛАБОРАТОРНАЯ РАБОТА N14",
                                "comment": [
                                  "Сложение сил, направленных вдоль одной прямой"
                                ]
                              },
                              {
                                "title": "ЦЕЛЬ РАБОТЫ",
                                 "comment": [
                                  "Экспериментальная проверка правила сложения сил, направленных вдоль одной прямой"
                                ]
                              },
                              {
                                "title": "ОБОРУДОВАНИЕ",
                                "comment": [
                                  "Штатив", "Динамометр", "Тело цилиндрической формы", "Груз 2 шт."
                                  ],
                                  "picture": "Оборудование_лаб_раб_14.json",
                                  "pictureDark": "oborudovanie_14_d.json"
                              },
                              {
                                "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                "comment": [
                                  "Нагрузите динамометр одним грузом. Из-за растяжения пружины визир и тяга сместятся вниз до первого большого деления, обозначенного как 1 Н."
                                  ]
                              },
                              {
                                "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                "comment": [
                                  "Последовательно нагружая динамометр двумя, тремя и четырьмя грузами, отметьте, что с каждым новым грузом визир опускается на одно большое деление, то есть на 1 Н."
                                  ],
                                  "picture": "Подготовка_к_работе_лаб_14.json"
                              },
                              {
                                "title": "ЗАДАНИЕ",
                                "comment": [
                                  "Зарисуйте в отчете постановку опыта и результаты измерений.Опишите в отчете последовательность выполнения работы и зарисуйте динамометр.", "", 
                                  "При выполнении лабораторной работы было показано, что несколько сил, действующих вдоль одной прямой, складываются, то есть результирующая сила равна сумме отдельных сил."
                                  ]
                              }
                          ]
                    },
                    {
                        "labId": "lab15",
                        "labName": "Сложение сил, направленных под углом",
                        "labPictureFolder": "01-physics/01-mechanics/lab15/",
                        "labPages": [
                            {
                                "title": "ЛАБОРАТОРНАЯ РАБОТА N15",
                                "comment": [
                                  "Сложение сил, направленных под углом"
                                ]
                              },
                              {
                                "title": "ЦЕЛЬ РАБОТЫ",
                                 "comment": [
                                  "Экспериментальное доказательство утверждения о том, что равнодействующая двух сил, приложенных к телу, равна геометрической сумме этих сил "
                                ]
                              },
                              {
                                "title": "ОБОРУДОВАНИЕ",
                                "comment": [
                                  "Штатив", "Транспортир", "Динамометр", "Укладочный пенал", "Груз 2 шт.", "Нить"
                                  ],
                                  "picture": "Оборудование_лаб_раб_15.json",
                                  "pictureDark": "oborudovanie_15_d.json"
                              },
                              {
                                "title": "ПОДГОТОВКА К РАБОТЕ",
                                "comment": [
                                  "Постановка опыта представлена на рисунке.", 
                                  "Динамометр лучше закрепить в лапке штатива через канцелярскую скрепку, так как при этом он сможет свободно повернуться вокруг своей оси и занять удобное положение, исключающее перекосы и избыточное трение.", 
                                  "Для закрепления левого конца нити используйте укладочный пенал. "
                                  ],
                                  "picture": "Подготовка_к_работе_лаб_15.json",
                                  "pictureDark": "mech_15_04_d.json"
                              },
                              {
                                "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                "comment": [
                                  "Сначала измерьте динамометром силу тяготения, приложенную к цилиндру - F^_m^_.",
                                  "Результат измерения запишите в таблицу."
                                  ],
                                  "picture": "Подготовка_к_работе_лаб_15_1.json"
                              },
                              {
                                "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                "comment": [
                                  ],
                                  "table":[
                                    "Опыт|F, H|F^_m^_, H|α, град|α/2, град|cos(α/2)|2Fꞏcos(α/2), H",
                                    "1||||||",
                                    "3||||||"
                                  ]
                              },
                              {
                                "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                "comment": [
                                  "Постарайтесь в трех опытах при сборке установить угол α между нитями примерно 60, 90 и 120 град, измерив их с помощью транспортира. Результаты измерений запишите в таблицу."
                                  ],
                                  "picture": "Порядок_лаб_15.json",
                                  "pictureDark": "mech_15_07_d.json"
                              },
                              {
                                "title": "ЗАДАНИЕ",
                                "comment": [
                                  "Измерьте силу натяжения нити F по показаниям динамометра.",
                                  "По таблицам определите cosα/2. ",
                                  "Рассчитайте силу F^_0^_ и убедитесь в равенстве F^_0^_ = F^_m^_."
                                  ]
                              }
                          ]
                    },
                    {
                        "labId": "lab16",
                        "labName": "Исследование зависимости силы тяготения от массы тела",
                        "labPictureFolder": "01-physics/01-mechanics/lab16/",
                        "labPages": [
                            {
                                "title": "ЛАБОРАТОРНАЯ РАБОТА N16",
                                "comment": [
                                  "Исследование зависимости силы тяготения от массы тела"
                                ]
                              },
                              {
                                "title": "ЦЕЛЬ РАБОТЫ",
                                 "comment": [
                                  "Подтверждение пропорциональности силы тяжести, действующей на тело, его массе"
                                ]
                              },
                              {
                                "title": "ОБОРУДОВАНИЕ",
                                "comment": [
                                  "Штатив", "Динамометр", "Грузы (4 шт.)" 
                                  ],
                                  "picture": "Оборудование_лаб_раб_16.json",
                                  "pictureDark": "oborudovanie_16_d.json"
                              },
                              {
                                "title": "ПОДГОТОВКА К РАБОТЕ",
                                "comment": [
                                  "Соберите штатив. Зафиксируйте в его лапке верхний выступ корпуса динамометра. ","", 
                                  "Добейтесь, чтобы корпус динамометра находился в вертикальном положении. Это условие выполняется для того, чтобы при нагрузке прибора тяга, связывающая пружину с крючком для навешивания груза, не перекашивалась и не испытывала избыточного трения при проходе сквозь нижнее отверстие корпуса.", "", 
                                  "Убедитесь, что визир динамометра без нагрузки находится на делениях шкал 0 Н."
                                  ],
                                  "picture": "Подготовка_к_работе_лаб_16.json"
                              },
                              {
                                "title": "ПОДГОТОВКА К РАБОТЕ",
                                "comment": [
                                  "Примечание. Полную сборку для лабораторной работы, как на рисунке, выполнить не удастся из-за ограниченной высоты штатива. Избежать этого несоответствия можно, если к крючку динамометра подвешивать не один груз, а сразу несколько. В этом случае полная длина постановки уменьшится и ее можно будет осуществить."
                                  ],
                                  "picture": "Подготовка_к_работе_лаб_16_2.json"
                              },
                              {
                                "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                "comment": [
                                  "Нагрузите динамометр одним грузом. Из-за растяжения пружины визир и тяга сместятся вниз до первого большого деления, обозначенного как 1 Н.", "", 
                                  "Затем нагрузите динамометр двумя грузами.", ""
                                  ],
                                  "picture":"Порядок_лаб_16_3.json"
                              },
                              {
                                "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                "comment": [
                                  "Нагрузите динамометр тремя грузами и посмотрите насколько опустился визир динамометра."
                                  ],
                                  "picture":"Порядок_лаб_16_4.json"
                              },
                              {
                                "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                "comment": [
                                  "Нагрузите динамометр четырьмя грузами. Отметьте, что с каждым новым грузом визир опускается вниз на одно большое деление, то есть на 1 Н.", "", 
                                  "Следовательно, соблюдается пропорциональность массы груза и растяжения пружины динамометра."
                                  ],
                                  "picture":"Порядок_лаб_16_5.json"
                              },
                              {
                                "title": "ЗАДАНИЕ",
                                "comment": [
                                  "Опишите в отчете последовательность выполнения работы и зарисуйте динамометр."
                                  ]
                              }
                          ]
                    },
                    {
                        "labId": "lab17",
                        "labName": "Исследование зависимости силы упругости от удлинения пружины",
                        "labPictureFolder": "01-physics/01-mechanics/lab17/",
                        "labPages": [
                            {
                                "title": "ЛАБОРАТОРНАЯ РАБОТА N17",
                                "comment": [
                                  "Исследование зависимости силы упругости от удлинения пружины"
                                ]
                              },
                              {
                                "title": "ЦЕЛЬ РАБОТЫ",
                                 "comment": [
                                  "Экспериментальная проверка утверждения, что сила упругости прямо пропорциональна величине деформации тела"
                                ]
                              },
                              {
                                "title": "ОБОРУДОВАНИЕ",
                                "comment": [
                                  "Штатив", "Динамометр", "Линейка со шкалой", "Грузы (4 шт.)", "Пружина"
                                  ],
                                  "picture": "Оборудование_лаб_раб_17.json",
                                  "pictureDark": "oborudovanie_17_d.json"
                              },
                              {
                                "title": "ПОДГОТОВКА К РАБОТЕ",
                                "comment": [
                                  "Деформация тел бывает двух видов – упругая и пластическая. ", "", 
                                  "Тело, испытавшее упругую деформацию, после снятия нагрузки полностью восстанавливает исходную форму и размеры. В результате пластической деформации тело сохраняет вновь приобретенную форму и размеры.", "", 
                                  "В настоящей лабораторной работе исследуется упругая деформация цилиндрической пружины."
                                  ]
                              },
                              {
                                "title": "ПОДГОТОВКА К РАБОТЕ",
                                "comment": [
                                  "Соберите штатив. Зафиксируйте в его лапке верхний выступ корпуса динамометра. Добейтесь, чтобы корпус динамометра находился в вертикальном положении.",
                                  "Это условие выполняется для того, чтобы при нагрузке прибора тяга, связывающая пружину с крючком для навешивания груза, не испытывала избыточного трения при проходе сквозь нижнее отверстие корпуса, что может вызвать дополнительное трение и неверный отсчет при измерении. ",
                                  "Убедитесь, что визир динамометра без нагрузки находится на делениях шкал 0 Н. Запишите значение высоты, на которой на которой находится нижний виток исследуемой пружины как показано на рисунке."
                                  ],
                                  "picture": "Подготовка_к_работе_лаб_17_1.json",
                                  "pictureDark": "mech_podgotovka_17_01_d.json"
                              },
                              {
                                "title": "ПОДГОТОВКА К РАБОТЕ",
                                "comment": [
                                  "Примечание. Полную сборку лабораторной работы выполнить не удастся из-за ограниченной высоты штатива. Избежать этого несоответствия можно, если к крючку динамометра подвешивать не один груз, а сразу несколько. В этом случае полная длина постановки уменьшится и ее можно будет осуществить."
                                  ],
                                  "picture":"Подготовка_к_работе_лаб_17_2.json",
                                  "pictureDark": "mech_podgotovka_17_02_d.json"
                              },
                              {
                                "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                "comment": [
                                  "Нагрузите динамометр одним грузом. Убедитесь, что визир сместится на отметку 1 Н. ", "", 
                                  "По шкале линейки определите смещение L^_1^_ нижнего витка пружины и запишите его значение в таблицу."
                                  ],
                                  "picture":"Порядок_лаб_17.json",
                                  "pictureDark": "mech_17_00_d.json"
                              },
                              {
                                "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                "comment": [
                                  ],
                                  "table":[
                                    "Нагрузка F, г|0|100|200|300|400",
                                    "Деформация L, мм|0|L^_1^_|L^_2^_|L^_3^_|L^_4^_"
                                  ]
                              },
                              {
                                "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                "comment": [
                                  "Измерьте деформацию пружины при нагрузке двумя, тремя и четырьмя грузами. Занесите полученные данные в таблицу.", "", 
                                  "Убедитесь, что сила упругости прямо пропорциональна деформации пружины. Для проверки этого утверждения составьте ряд отношений силы упругости к значению деформации.", "", 
                                  "##@@100/L^_1^_ = 200 / L^_2^_ = 300 / L^_3^_ = 400 / L^_4^_", "", 
                                  "Если эти отношения после вычислений окажутся одинаковыми, можно считать, что между силой упругости и деформацией пружины соблюдается пропорциональность, то есть исследуемая пружина соответствует закону Гука."
                                  ],
                                  "picture":"Порядок_лаб_17_1.json",
                                  "pictureDark": "mech_17_01_d.json"
                              },
                              {
                                "title": "ЗАДАНИЕ",
                                "comment": [
                                  "Пользуясь данными таблицы, постройте график зависимости удлинения пружины от значения нагрузки. Для идеальной пружины такой график должен представлять собой прямую линию. ", "", 
                                  "В результате опыта может оказаться, что эта линия отклоняется от прямой особенно на начальном участке при малой нагрузке.", "", 
                                  "Попробуйте объяснить это явление."
                                  ],
                                  "picture":"задание_лаб_раб_17.json",
                                  "pictureDark": "mech_zadanie_17_01_d.json"
                              }
                          ]
                    },
                    {
                        "labId": "lab18",
                        "labName": "Измерение жесткости пружины",
                        "labPictureFolder": "01-physics/01-mechanics/lab18/",
                        "labPages": [
                            {
                                "title": "ЛАБОРАТОРНАЯ РАБОТА N18",
                                "comment": [
                                  "Измерение жесткости пружины"
                                ]
                              },
                              {
                                "title": "ЦЕЛЬ РАБОТЫ",
                                 "comment": [
                                  "Приобретение навыков измерения жесткости цилиндрической пружины"
                                ]
                              },
                              {
                                "title": "ОБОРУДОВАНИЕ",
                                "comment": [
                                  "Пружина", "Динамометр", "Плоскость-трибометр", "Линейка со шкалой"
                                  ],
                                  "picture": "Оборудование_лаб_раб_18.json",
                                  "pictureDark": "oborudovanie_18_d.json"
                              },
                              {
                                "title": "ПОДГОТОВКА К РАБОТЕ",
                                "comment": [
                                  "Прикрепите исследуемую пружину оформленным концом к штырю, установленному на краю наклонной плоскости-трибометра. Динамометр положите на плоскость и сцепите с вторым концом пружины. Внешнее усилие F, которое прикладывается к корпусу динамометра по стрелке, растягивает пружину, а динамометр при этом измеряет приложенную силу. Рядом с динамометром находится линейка, по шкале которой измеряется упругая деформация пружины. ",
                                  "Установите нулевую отметку линейки напротив правого последнего витка исследуемой пружины и не меняйте ее положение на протяжении всей работы."
                                  ],
                                  "picture": "Подготовка_к_работе_лаб_18.json"
                              },
                              {
                                "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                "comment": [
                                  "Потяните динамометр вправо по стрелке с усилием 1 Н. При этом визир динамометра должен сместиться по шкале влево до отметки 1 Н. Одновременно произойдет удлинение пружины. ",
                                  "Определите удлинение пружины. Полученное значение деформации запишите в таблицу."
                                  ],
                                  "picture": "Подготовка_к_работе_лаб_18_1.json"
                              },
                              {
                                "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                "comment": [
                                  ],
                                  "table":[
                                    "Сила упругости F, Н|1 Н|2 Н|3 Н",
                                    "Деформация ΔL, мм|||",
                                    "Жесткость, k=F/ΔL, Н/м|||"
                                  ]
                              },
                              {
                                "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                "comment": [
                                  "Доведите растягивающее усилие сначала до 2 Н, а затем до 3 Н.",
                                  "Измерьте деформацию пружины при усилии на растяжение 2 Н и 3 Н. Результаты измерений запишите в таблицу.", "", 
                                  "Рассчитайте коэффициент жесткости пружины для положений 2, 3 и 4 для сил упругости 1, 2 и 3 Н.", "", 
                                  "При расчете жесткости пружины полученные значения удлинения нужно выразить в м. Тогда размерность результата будет получена в Н/м."
                                  ],
                                  "picture": "Подготовка_к_работе_лаб_18_2.json",
                                  "pictureDark": "mech_18_02_d.json"
                              },
                              {
                                "title": "ЗАДАНИЕ",
                                "comment": [
                                  "Доведите растягивающее усилие сначала до 2 Н, а затем до 3 Н."
                                  ],
                                  "picture": "задание_лаб_раб_18.json"
                              }
                          ]
                    },
                    {
                        "labId": "lab19",
                        "labName": "Исследование силы трения скольжения.  Определение коэффициента трения скольжения",
                        "labPictureFolder": "01-physics/01-mechanics/lab19/",
                        "labPages": [
                            {
                                "title": "ЛАБОРАТОРНАЯ РАБОТА N19",
                                "comment": [
                                  "Исследование силы трения скольжения.  Определение коэффициента трения скольжения"
                                ]
                              },
                              {
                                "title": "ЦЕЛЬ РАБОТЫ",
                                 "comment": [
                                  "Установить зависимость силы трения скольжения от значения силы нормального давления, ознакомиться с приемом определения коэффициента трения скольжения"
                                ]
                              },
                              {
                                "title": "ОБОРУДОВАНИЕ",
                                "comment": [
                                  "Динамометр", "Деревянный брусок", "Груз 3 шт.", "Плоскость-трибометр"
                                  ],
                                  "picture": "Оборудование_лаб_раб_19.json",
                                  "pictureDark": "oborudovanie_19_d.json"
                              },
                              {
                                "title": "ПОДГОТОВКА К РАБОТЕ",
                                "comment": [
                                  "На наклонную плоскость-трибометр, установленную горизонтально, поместите деревянный брусок с магнитами широкой стороной к плоскости, и динамометр, сцепив их крючками.", "", 
                                  "Взвесьте брусок. В среднее отверстие бруска поместите груз. Вес бруска с грузом будет равен Р^_бр^_ + 100 г. "
                                  ],
                                  "picture": "Подготовка_к_работе_лаб_19.json",
                                  "pictureDark": "mech_19_01_d.json"
                              },
                              {
                                "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                "comment": [
                                  "На наклонную плоскость-трибометр, установленную горизонтально, поместите деревянный брусок с магнитами широкой стороной к плоскости и динамометр, сцепив их крючками, как на рисунке.",
                                  "Плавно потяните брусок влево с силой F^_Д^_ и определите значение этой силы по показанию динамометра. Результат измерения занесите в таблицу."
                                  ],
                                  "picture": "Порядок_лаб_19.json"
                              },
                              {
                                "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                "comment": [
                                  ],
                                  "table":[
                                    "Положение бруска|Сила тяжести бруска с грузами, F^_т^_, Н|Сила тяги F^_д^_, Н|Сила трения F^_тр^_, Н|К^_тр^_=F^_тр^_/F^_т^_",
                                    "Широкая сторона|Р^_бр^_+0,1|-|-|-",
                                    "Широкая сторона|Р^_бр^_+0,2|-|-|-",
                                    "Широкая сторона|Р^_бр^_+0,3|-|-|-",
                                    "Узкая сторона|Р^_бр^_+0,1|-|-|-",
                                    "Узкая сторона|Р^_бр^_+0,2|-|-|-",
                                    "Узкая сторона|Р^_бр^_+0,3|-|-|-"
                                  ]
                              },
                              {
                                "title": "ЗАДАНИЕ",
                                "comment": [
                                  "Аналогично повторите измерения для двух и трех грузов. Результаты измерений занесите в таблицу.",
                                  "Те же измерения выполните для варианта, когда деревянный брусок будет лежать на плоскости-трибометре узкой стороной.", "", 
                                  "Рассчитайте коэффициенты трения для всех вариантов исследования и определите, что и как влияет на значение коэффициента трения."
                                  ]
                              }
                          ]
                    },
                    {
                        "labId": "lab20",
                        "labName": "Исследование условий равновесия рычага первого рода",
                        "labPictureFolder": "01-physics/01-mechanics/lab20/",
                        "labPages": [
                            {
                                "title": "ЛАБОРАТОРНАЯ РАБОТА N20",
                                "comment": [
                                  "Исследование условий равновесия рычага первого рода"
                                ]
                              },
                              {
                                "title": "ЦЕЛЬ РАБОТЫ",
                                 "comment": [
                                  "Опытным путем установить соотношение между силами, действующими на рычаг, и плечами этих сил. Исследовать условия равновесия рычага первого рода"
                                ]
                              },
                              {
                                 "title": "ОБОРУДОВАНИЕ",
                                 "comment": [
                                  "Штатив", "Рычаг-линейка", "Грузы (4 шт.)"
                                  ],
                                  "picture": "Оборудование_лаб_раб_20.json",
                                  "pictureDark": "oborudovanie_20_d.json"
                              },
                              {
                                "title": "ПОДГОТОВКА К РАБОТЕ",
                                "comment": [
                                  "Рычаг относится к простым механизмам, преобразующим силу как по направлению, так и по значению. Коэффициент полезного действия простых механизмов, в соответствии с золотым правилом механики, меньше единицы. Это означает, что при выигрыше в силе все простые механизмы теряют в расстоянии, на котором эта сила действует, то есть в работе силы, и наоборот."
                                  ]
                              },
                              {
                                "title": "ПОДГОТОВКА К РАБОТЕ",
                                "comment": [
                                  "Особенностью рычага первого рода является расположение сил, действующих на него, по обе стороны от его оси, то есть сила или группа сил, поворачивающих рычаг по часовой стрелке, может быть уравновешена силой или группой сил, поворачивающих рычаг против часовой стрелки. ", "", 
                                  "Постановка лабораторной работы показана на рисунке."
                                  ],
                                  "picture": "Порядок_лаб_20_1.json",
                                  "pictureDark": "mech_20_01_d.json"
                              },
                              {
                                "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                "comment": [
                                  "Закрепите в муфте штатива лапку так, чтобы ее стержень был обращен к наблюдателю. ",
                                  "Навесьте рычаг центральным отверстием на стержень и убедитесь, что он легко с малым трением вращается вокруг оси. Закрепите стержень в лапке штатива.",
                                  "Обратите внимание на равновесие ненагруженного рычага. Если равновесие нарушается, попробуйте восстановить его, смещая по резьбе грузики на торцах корпуса рычага.", 
                                  "Нагрузите рычаг двумя одинаковыми грузами по 100 г каждый.",
                                  "Смещая один из грузов, установите равновесие и убедитесь, что расстояния грузов от нулевой линии одинаковы, то есть П^_1^_ = П^_2^_."
                                ],
                                "picture":"Порядок_лаб_20_2.json"
                              },
                              {
                                "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                "comment": [
                                  "Повторите опыт, сместив один из грузов и, следовательно, нарушив равновесие. Вновь восстановите равновесие, смещая второй груз.",
                                  "Опыт позволяет определить первое условие равновесия рычага первого рода. ", "", 
                                  "Равновесие устанавливается при соблюдении уравнения:", "", 
                                  "##@@Р^_1^_П^_1^_ = Р^_2^_П^_2^_.", "",
                                  "Произведение веса груза на его плечо (расстояние от местоположения груза до оси рычага) называется моментом силы. Ясно, что момент Р^_1^_П^_1^_ пытается повернуть рычаг против часовой стрелки, а момент Р^_2^_П^_2^_ – по часовой стрелке. Очевидно, что равновесие наступает при равенстве этих моментов. Если же грузы одинаковы по весу, то и плечи грузов при условии выполнения равновесия будут одинаковыми."
                                ],
                                "picture":"Порядок_лаб_20_3.json",
                                "pictureDark": "mech_20_03_d.json"
                              },
                              {
                                "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                "comment": [
                                  "Удерживая рычаг рукой, нагрузите его четырьмя грузами, расположив их на произвольном удалении от нулевой линии, – двумя грузами с одной стороны и двумя – с другой.", "", 
                                  "Попробуйте добиться равновесия, смещая грузы в направлении нулевой линии с той стороны рычага, которая перевешивает.  ", "", 
                                  "Добившись равновесия, измерьте плечи сил П^_1^_…П^_4^_ и рассчитайте суммы моментов, вращающих рычаг против часовой стрелки и по часовой стрелке.", "",
                                  "##@@Р^_1^_П^_1^_ + Р^_2^_П^_2^_ = Р^_3^_П^_3^_ + Р^_4^_П^_4^_."
                                  ]
                              },
                              {
                                "title": "ЗАДАНИЕ",
                                "comment": [
                                  "Подставьте полученные данные в уравнение равновесия и убедитесь в его справедливости.", "", 
                                  "Отсюда можно сделать важный вывод: равновесие любого тела наступает тогда, когда суммы моментов, действующих на него противоположным образом, одинаковы. ", "", 
                                  "Проверьте правильность вывода, используя тела неизвестной массы, имеющиеся в лабораторном комплекте."
                                  ]
                              }
                          ]
                    },
                    {
                      "labId": "lab21",
                      "labName": "Исследование условий равновесия рычага второго рода",
                      "labPictureFolder": "01-physics/01-mechanics/lab21/",
                      "labPages": [
                          {
                              "title": "ЛАБОРАТОРНАЯ РАБОТА N21",
                              "comment": [
                                "Исследование условий равновесия рычага второго рода"
                              ]
                            },
                            {
                              "title": "ЦЕЛЬ РАБОТЫ",
                              "comment": [
                                "Исследование рычага второго рода"
                              ]
                            },
                            {
                              "title": "ОБОРУДОВАНИЕ",
                              "comment": [
                                "Штатив с муфтой и лапкой", "Динамометр", "Линейка со шкалой", "Грузы (4 шт.)", "Дополнительная муфта"
                                ],
                                "picture": "Оборудование_лаб_раб_21.json",
                                "pictureDark": "oborudovanie_21_d.json"
                            },
                            {
                              "title": "ПОДГОТОВКА К РАБОТЕ",
                              "comment": [
                                "Особенность рычага второго рода состоит в том, что все силы, действующие на него, расположены по одну сторону от оси. ", "", 
                                "Ясно, что для достижения равновесия моменту силы, поворачивающей рычаг по часовой стрелке, должен противодействовать момент силы, поворачивающей рычаг против часовой стрелки.", "", 
                                "Постановка работы показана на рисунке. "
                                ],
                                "picture":"Порядок_лаб_21_1.json",
                                "pictureDark": "mech_21_01_d.json"
                            },
                            {
                              "title": "ПОДГОТОВКА К РАБОТЕ",
                              "comment": [
                                "Особенностью рычага первого рода является расположение сил, действующих на него, по обе стороны от его оси, то есть сила или группа сил, поворачивающих рычаг по часовой стрелке, может быть уравновешена силой или группой сил, поворачивающих рычаг против часовой стрелки. ", "", 
                                "Постановка лабораторной работы показана на рисунке."
                                ],
                                "picture": "Порядок_лаб_21_2.json"
                            },
                            {
                              "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                              "comment": [
                                ],
                                "table": [
                                  "Плечо L^_1^_, см|Плечо L^_2^_, см|Вес Р, Н|Сила F, Н|Момент М^_1^_, Н/м^_0^_|Момент М^_2^_, Н/м^_0^_",
                                  "5|15|2,94|||",
                                  "15|5|0.98|||"
                                ]
                            },
                            {
                              "title": "ЗАДАНИЕ",
                              "comment": [
                                "По шкале динамометра определите силу, действующую на рычаг против часовой стрелки.", "", 
                                "Рассчитайте моменты, поворачивающие рычаг по часовой стрелке и против часовой стрелки и убедитесь, что они одинаковы.", "", 
                                "Перестройте лабораторную установку в соответствии с требованиями таблицы и выполните расчеты  моментов. ",
                                "Убедитесь, что и в этом случае моменты одинаковы."
                                ]
                            }
                        ]
                    },
                    {
                      "labId": "lab22",
                      "labName": "Исследование работы неподвижного блока",
                      "labPictureFolder": "01-physics/01-mechanics/lab22/",
                      "labPages": [
                        {
                            "title": "ЛАБОРАТОРНАЯ РАБОТА N22",
                            "comment": [
                              "Исследование работы неподвижного блока"
                            ]
                          },
                          {
                            "title": "ЦЕЛЬ РАБОТЫ",
                            "comment": [
                              "Изучение работы неподвижного блока"
                            ]
                          },
                          {
                            "title": "ОБОРУДОВАНИЕ",
                            "comment": [
                              "Штатив", "Динамометр", "Блок", "Грузы (2 шт.)", "Нить"
                              ],
                              "picture": "Оборудование_лаб_раб_22.json",
                              "pictureDark": "oborudovanie_22_d.json"
                          },
                          {
                            "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                            "comment": [
                              "Соберите установку, представленную на рисунке. Крючок блока зафиксируйте винтом в муфте штатива. ", "", 
                              "Нить с двумя петлями на концах пропустите через блок. К одной петле присоедините груз, состоящий из двух грузов по 100 г каждый, ко второй – динамометр (положение 2 на рисунке).", "", 
                              "Потяните динамометр вправо. Приложенное усилие приведет к тому, что грузы поднимутся, а динамометр покажет общий вес двух грузов равный тому весу, который был измерен в положении 2."
                              ], 
                              "picture":"Подготовка_к_работе_лаб_22.json",
                              "pictureDark": "mech_22_01_d.json"
                          },
                          {
                            "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                            "comment": [
                              "Повторите измерения для положений динамометра 1 и 3. Убедитесь, что при изменении направления действия силы ее значение не изменилось.",
                              "Таким образом, удалось изменить направление силы, вызванной приложенным грузом.", "", 
                              "Поднимая груз на некоторую высоту, можно измерить совершенную работу как произведение силы на путь ее действия. ", "", 
                              "Считая, что нить в процессе эксперимента не изменяет своей длины, можно измерить расстояние, на которое сместился динамометр, и показать, что это расстояние равно высоте, на которую поднялся груз."
                              ],
                              "picture": "Подготовка_к_работе_лаб_22_2.json",
                              "pictureDark": "mech_22_02_d.json"
                          },
                          {
                            "title": "ВЫВОД",
                            "comment": [
                              "Выполненный эксперимент показал, что неподвижный блок позволяет изменить направление силы с сохранением ее значения и пути действия. "                              ]
                          }
                      ]
                    },
                    {
                      "labId": "lab23",
                  "labName": "Исследование работы подвижного блока",
                  "labPictureFolder": "01-physics/01-mechanics/lab23/",
                  "labPages": [
                      {
                          "title": "ЛАБОРАТОРНАЯ РАБОТА N23 ",
                          "comment": [
                            "Исследование работы подвижного блока"
                          ]
                        },
                        {
                          "title": "ЦЕЛЬ РАБОТЫ",
                          "comment": [
                            "Изучение работы подвижного блока"
                          ]
                        },
                        {
                          "title": "ОБОРУДОВАНИЕ",
                          "comment": [
                            "Штатив с муфтой и лапкой", "Динамометр", "Блок", "Грузы (2 шт.)", "Нить"
                            ],
                            "picture": "Оборудование_лаб_раб_23.json",
                            "pictureDark": "oborudovanie_23_d.json"
                        },
                        {
                          "title": "ПОДГОТОВКА К РАБОТЕ",
                          "comment": [
                            "Постановка работы показана на рисунке. Нить связана с динамометром через блок, висящий свободно.", 
                            "Один конец нити закреплен в лапке штатива, второй связан с крючком динамометра. Груз навешен на крючок блока. ", 
                            "Динамометр нужно будет держать в руке."
                            ],
                            "picture":"Подготовка_к_работе_лаб_23.json",
                            "pictureDark": "mech_23_01_d.json"
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            "Обратите внимание на показания динамометра. При нагрузке, равной 2 Н, динамометр показывает только половину этого веса – 1 Н.", "", 
                            "Следовательно, подвижный блок уменьшает силу в два раза – делит ее пополам между двумя участками нити, идущей с одной стороны к лапке штатива, а с другой к динамометру.", "", 
                            "Поднимите динамометр на высоту 2h и убедитесь, что груз поднялся только на высоту h, то есть на высоту, вдвое меньшую."
                            ],
                            "picture": "Порядок_выполнения_лаб_раб_23.json",
                            "pictureDark": "mech_23_02_d.json"
                        },
                        {
                          "title": "ВЫВОД",
                          "comment": [
                            "Таким образом установлено, что при использовании подвижного блока наблюдается двойной выигрыш в силе и двойной проигрыш в расстоянии, на котором действует эта сила."
                            ]
                        }
                     ]
                   },
                   {
                     "labId": "lab24",
                     "labName": "Нахождение центра тяжести плоского тела произвольной формы",
                     "labPictureFolder": "01-physics/01-mechanics/lab24/",
                     "labPages": [
                       {
                         "title": "ЛАБОРАТОРНАЯ РАБОТА N24",
                         "comment": [
                           "Нахождение центра тяжести плоского тела произвольной формы"
                         ]
                       },
                       {
                         "title": "ЦЕЛЬ РАБОТЫ",
                         "comment": [
                           "Определение центра тяжести плоского тела произвольной формы"
                         ]
                       },
                       {
                         "title": "ОБОРУДОВАНИЕ",
                         "comment": [
                           "Штатив с муфтой и лапкой", "Плоское тело", "Груз 100 г", "Нить"
                           ],
                          "picture": "Оборудование_лаб_раб_24.json",
                          "pictureDark": "oborudovanie_24_d.json"
                       },
                       {
                         "title": "ПОДГОТОВКА К РАБОТЕ",
                         "comment": [
                           "Соберите штатив с лапкой, закрепив ее в верхней части стойки и развернув так, чтобы стержень лапки был обращен к наблюдателю.",
                           "Навесьте пластину неправильной формы одним из отверстий на стержень, как показано на рисунке, и убедитесь, что пластина легко вращается относительно стержня без значительного трения и после выведения из равновесия возвращается в исходное состояние. ",
                           "На тот же стержень в качестве отвеса навесьте груз так, чтобы нить отступила от поверхности пластины на 2–3 мм и не касалась его, а сам груз находился ниже пластины. ",
                           "Осторожно карандашом нанесите на поверхность пластины вертикальную линию, совпадающую с нитью отвеса."
                           ],
                           "picture":"Порядок_лаб_раб_24_1.json"
                       },
                       {
                         "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                         "comment": [
                           "Снимите пластину со стержня и навесьте его так, чтобы линия отвеса и линия, нанесенная на поверхность пластины, составляли угол близкий к 90º.", "", 
                           "Нанесите на поверхность пластины линию, совпадающую с новым положением нити отвеса относительно пластины.", "",
                           "Объясните, почему пересечение этих линий определяет положение центра тяжести. "
                           ],
                           "picture": "Порядок_лаб_раб_24_2.json"
                       },
                       {
                         "title": "ЗАДАНИЕ",
                         "comment": [
                           "Проверьте это утверждение, навесив пластину на стержень штатива другим отверстием и проведите третью линию, совпадающую с нитью отвеса.",
                           "При тщательном выполнении работы удастся добиться совпадения и третьей линии с уже найденным центром тяжести."
                          ],
                          "picture":"Порядок_лаб_раб_24_4.json",
                          "pictureDark": "mech_24_04_d.json"
                       }
                   ]
             },
             {
               "labId": "lab25",
               "labName": "Определение КПД наклонной плоскости",
               "labPictureFolder": "01-physics/01-mechanics/lab25/",
               "labPages": [
                   {
                       "title": "ЛАБОРАТОРНАЯ РАБОТА N25",
                       "comment": [
                         "Определение КПД наклонной плоскости"
                       ]
                     },
                     {
                       "title": "ЦЕЛЬ РАБОТЫ",
                       "comment": [
                         "Отработка приемов расчета КПД простого механизма"
                       ]
                     },
                     {
                       "title": "ОБОРУДОВАНИЕ",
                       "comment": [
                         "Штатив с муфтой и лапкой", "Наклонная плоскость-трибометр", "Грузы (3 шт.)", 
                         "Брусок деревянный с магнитами", "Линейка со шкалой", "Транспортир", "Весы", 
                         "Динамометр"
                         ],
                        "picture": "Оборудование_лаб_раб_25.json",
                        "pictureDark": "oborudovanie_25_d.json"
                     },
                     {
                       "title": "ПОДГОТОВКА К РАБОТЕ",
                       "comment": [
                         "Зафиксируйте наклонную плоскость в лапке штатива, как показано на рисунке. Угол α установите по транспортиру равным 30º.",
                         "Установите деревянный брусок с магнитами широкой стороной на наклонную плоскость, предварительно взвесив его и записав результат взвешивания в таблицу.",
                         "Вставьте в среднее отверстие бруска груз, также взвесив его. Присоедините к бруску динамометр."
                       ],
                       "picture":"Подготовка_к_работе_лаб_25.json",
                       "pictureDark": "mech_podgotovka_25_01_d.json"
                     },
                     {
                       "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                       "comment": [
                         ],
                         "table": [
                           "Грузы|h^_1^_, см|h^_2^_, см|m^_0^_, г|F^_т^_, Н|S, см|А^_п^_, Дж|А^_з^_, Дж|ŋ, %",
                           "1||||||||",
                           "2||||||||",
                           "3||||||||"
                         ]
                     },    
                     {
                       "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                       "comment": [
                         "Добейтесь, чтобы в исходном состоянии груз занимал нижнее положение, как показано на рисунке",
                         "Измерьте линейкой высоту h^_1^_ , на которой находится центр тяжести груза и запишите результат в таблицу.", "",
                         "Потяните динамометр вверх по наклонной плоскости в направлении стрелки с постоянной скоростью и поднимите его до верхнего торца, считывая при этом со шкалы динамометра значение усилия тяги F^_д^_. ", "",
                         "Постарайтесь, чтобы это усилие сохранялось неизменным на всем протяжении пути S."
                        ],
                        "picture":"Порядок_лаб_25_1.json",
                        "pictureDark": "mech_25_01_d.json"
                     },
                     {
                       "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                       "comment": [
                         "Измерьте высоту h^_2^_, на которую поднялся центр тяжести груза. Все полученные данные занесите в таблицу.",
                         "Рассчитайте работу А^_п^_ по подъему бруска с грузом на высоту h^_2^_ – h^_1^_, предварительно взвесив их по отдельности и рассчитав суммарную массу m^_0^_:", "", 
                         "##@@А^_п^_ = m^_0^_ g (h^_2^_ – h^_1^_).", "", 
                         "Рассчитайте работу А^_з^_, затраченную на подъем груза по наклонной плоскости:", "",
                         "##@@Аз = F^_Т^_  S. ", "", 
                         "КПД наклонной плоскости вычисляется как отношение полезной работы А^_п^_ к затраченной работе А^_з^_, выраженное процентах, то есть:", "", 
                         "##@@КПД = (m^_0^_g (h^_2^_ – h^_1^_) : F^_Т^_ꞏS) ꞏ 100% = А^_п^_ / А^_з^_ ꞏ 100%"
                         ],
                         "picture":"Порядок_лаб_25_2.json",
                        "pictureDark": "mech_25_02_d.json"
                     },
                     {
                       "title": "ЗАДАНИЕ",
                       "comment": [
                         "Повторите измерения для двух и трех грузов. Результаты измерений занесите в таблицу.", "", 
                         "Переставьте деревянный брусок с магнитами узкой стороной к плоскости и повторите измерения для одного, двух и трех грузов.", "", 
                         "Объясните, почему произошли изменения в результатах измерений."
                         ]
                     }
                 ]
             },
             {
             "labId": "lab26",
             "labName": "Измерение кинетической энергии тела",
             "labPictureFolder": "01-physics/01-mechanics/lab26/",
             "labPages": [
                 {
                     "title": "ЛАБОРАТОРНАЯ РАБОТА N26",
                     "comment": [
                       "Измерение кинетической энергии тела"
                     ]
                   },
                   {
                     "title": "ЦЕЛЬ РАБОТЫ",
                     "comment": [
                       "Наблюдение зависимости кинетической энергии тела от его скорости"
                     ]
                   },
                   {
                     "title": "ОБОРУДОВАНИЕ",
                     "comment": [
                       "Прямолинейный желоб", "Шкала желоба", "Опоры желоба (2 шт.)", "Стальной шарик", "Латунный цилиндр", "Подставки 1 см (2 шт.)"
                       ],
                      "picture": "Оборудование_лаб_раб_26.json",
                      "pictureDark": "oborudovanie_26_d.json"
                   },
                   {
                     "title": "ПОДГОТОВКА К РАБОТЕ",
                     "comment": [
                       "Желоб крепится в опорах. Левый его конец поднимается выше правого на 20 мм, для чего используются две подкладки, каждая толщиной 10 мм. ",
                       "В желоб вкладывают линейку со шкалой, как показано на рисунке. В нижней части желоба устанавливают латунный цилиндр так, чтобы его торец без крючка приходился на отметку 350 мм шкалы линейки. "
                     ],
                     "picture":"Подготовка_к_работе_26.json",
                     "pictureDark": "mech_podgotovka_26_01_d.json"
                   },
                   {
                     "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                     "comment": [
                         "Установите стальной шарик на отметке шкалы 250 мм и отпустите его. Скатываясь с высоты h^_1^_, шарик разгонится и ударит в торец цилиндра. Произойдет его сдвиг.",
                         "Значение сдвига определяется кинетической энергией, которую шарик накопит за путь скатывания. Кинетическая энергия шарика определяется его потенциальной энергией в стартовой позиции.", "",
                         "Получившийся в результате соударения сдвиг нужно измерить и поместить его значение в таблицу."
                       ],
                       "picture":"Порядок_26.json"
                   },
                   {
                     "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                     "comment": [
                       "Выполните опыты 2 и 3 для других начальных позиций шарика (с позиции 150 мм и 50 мм). Результаты измерений занесите в таблицу."
                       ],
                       "picture":"Порядок_26_1.json"
                   },    
                   {
                     "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                     "comment": [
                     ],
                     "table":[
                       "Опыт|Стартовая позиция шарика, мм|Потенциальная энергия на старте|Сдвиг, мм",
                       "1|250|mgh^_1^_|",
                       "2|150|mgh^_2^_|",
                       "3|50|mgh^_3^_|"
                     ]
                   },
                   {
                     "title": "ВЫВОД",
                     "comment": [
                       "Кинетическая энергия тела проявляется при его соударении с преградой. Ее значение тем больше, чем выше скорость к моменту соударения."
                       ]
                   }
               ]
             },
             {
               "labId": "lab27",
               "labName": "Расчет потенциальной энергии тела в зависимости от его положения",
               "labPictureFolder": "01-physics/01-mechanics/lab27/",
               "labPages": [
                   {
                       "title": "ЛАБОРАТОРНАЯ РАБОТА N27 ",
                       "comment": [
                         "Расчет потенциальной энергии тела в зависимости от его положения"
                       ]
                     },
                     {
                       "title": "ЦЕЛЬ РАБОТЫ",
                       "comment": [
                         "Расчет потенциальной энергии тела"
                       ]
                     },
                     {
                       "title": "ОБОРУДОВАНИЕ",
                       "comment": [
                         "Латунный цилиндр", "Стальной шарик"
                         ],
                        "picture": "Оборудование_лаб_раб_27.json",
                        "pictureDark": "oborudovanie_27_d.json"
                     },
                     {
                       "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                       "comment": [
                         "Значения потенциальной энергии латунного цилиндра, лежащего на боковой поверхности (рис. 1) и стоящего вертикально (рис. 2), существенно отличаются.", "",
                         "Действительно, потенциальная энергия лежащего тела равна @@mgh^_1^_@@, а потенциальная энергия стоящего цилиндра равна @@mgh^_2^_@@, но h^_2^_ больше h^_1^_. ", "", 
                         "Следовательно, потенциальная энергия цилиндра, стоящего вертикально, больше потенциальной энергии того же цилиндра, лежащего на боку."
                       ],
                       "picture":"Порядок_27.json",
                       "pictureDark": "mech_27_01_d.json"
                     },
                     {
                       "title": "ЗАДАНИЕ",
                       "comment": [
                         "Объясните, почему потенциальная энергия шарика не зависит от его положения."
                         ]
                     }
                 ]
               },
               {
                 "labId": "lab28",
                 "labName": "Измерение мощности",
                 "labPictureFolder": "01-physics/01-mechanics/lab28/",
                 "labPages": [
                     {
                         "title": "ЛАБОРАТОРНАЯ РАБОТА N28 ",
                         "comment": [
                           "Измерение мощности"
                         ]
                       },
                       {
                         "title": "ЦЕЛЬ РАБОТЫ",
                         "comment": [
                           "Отработать прием вычисления механической мощности при подъеме тела"
                         ]
                       },
                       {
                         "title": "ОБОРУДОВАНИЕ",
                         "comment": [
                           "Штатив с муфтой и лапкой", "Латунный цилиндр ", "Весы", "Секундомер", "Линейка со шкалой"
                           ],
                          "picture": "Оборудование_лаб_раб_28.json",
                          "pictureDark": "oborudovanie_28_d.json"
                       },
                       {
                         "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                         "comment": [
                           "Соберите штатив с муфтой и лапкой. Зафиксируйте в лапке линейку со шкалой как на рисунке. Латунный цилиндр взвесьте на весах и определите его массу в граммах. Подготовьте секундомер.", "",
                           "Начните плавно поднимать латунный цилиндр на высоту @@h@@ и одновременно включите секундомер.", "", 
                           "Измерьте время подъема @@t@@ и рассчитайте работу, затраченную на подъем груза на высоту @@h@@ по формуле: ", "",
                           "##@@А = mgh",
                           "а мощность, которую пришлось развить во время подъема:",
                           "@@N = mgh/t."
                         ],
                         "picture":"Порядок_28.json",
                          "pictureDark": "mech_28_01_d.json"
                       }
                   ]
                 },
                 {
                   "labId": "lab29",
                   "labName": "Измерение архимедовой силы",
                   "labPictureFolder": "01-physics/01-mechanics/lab29/",
                   "labPages": [
                       {
                           "title": "ЛАБОРАТОРНАЯ РАБОТА N29 ",
                           "comment": [
                             "Измерение архимедовой силы"
                           ]
                         },
                         {
                           "title": "ЦЕЛЬ РАБОТЫ",
                           "comment": [
                             "Экспериментально показать, что на тело, погруженное в воду, действует выталкивающая сила равная весу воды, вытесненной этим телом "
                           ]
                         },
                         {
                           "title": "ОБОРУДОВАНИЕ",
                           "comment": [
                             "Штатив с муфтой и лапкой", "Весы", "Отливной сосуд", "Динамометр", "Стальной цилиндр", "Мерный стакан", "Нить", "Мерный цилиндр"
                             ],
                            "picture": "Оборудование_лаб_раб_29.json",
                            "pictureDark": "oborudovanie_29_d.json"
                         },
                         {
                           "title": "ПОДГОТОВКА К РАБОТЕ",
                           "comment": [
                             "Закрепите отливной сосуд в лапке штатива как показано на рисунке. Под сосуд поставьте мерный стакан, предварительно взвесив его пустым. Запишите вес пустого стакана @@Р^_с^_@@.",
                             "Налейте воду в отливной стакан и дождитесь пока ее избытки полностью не сольются в мерный стакан и не установится начальный уровень воды. Это означает, что вода установилась на красном уровне, а ее излишки слились в стакан. ", "", 
                             "Вылейте воду из мерного стакана в бутыль для воды.",
                             "Измерьте силу тяжести латунного цилиндра @@F^_ц^_@@ с помощью динамометра. "
                           ],
                           "picture":"Подготовка_29_1.json"
                         },
                         {
                           "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                           "comment": [
                               "Опустите тело на нити в отливной сосуд. Вытесненная телом вода @@Р^_вв^_@@ начнет сливаться в мерный стакан.",
                               "По шкале динамометра зафиксируйте силу тяготения @@Р^_в^_@@ тела, опущенного в воду. Определите выталкивающую силу, действующую на тело со стороны воды, как разность между весом тела на воздухе и в воде:", 
                               "##@@∆F = F^_ц^_  - F^_в^_ ",
                               "Дождитесь, когда вытесненная телом вода полностью сольется в мерный стакан.",
                               "Взвесьте на весах мерный стакан с вытесненной водой @@Р^_мс^_@@ и определите вес вытесненной воды @@Р^_вв^_@@ как разность между весом стакана с водой @@Р^_мс^_@@ и весом пустого стакана @@Р^_с^_@@:", "",
                               "##@@Р^_вв^_ = Р^_мс^_ - Р^_с^_."
                             ],
                             "picture":"Порядок_29_2.json"
                         },    
                         {
                           "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                           "comment": [
                             "Переведите вес вытесненной воды в силу тяжести вытесненной воды, умножив ее вес Рвв в килограммах на ускорение свободного падения - g и получите значение ее силы тяжести F^_вв^_:", "",
                             "##@@F^_вв^_ = gР^_вв^_.", 
                             "Убедитесь, что полученное значение силы тяжести F^_вв^_ равно выталкивающей силе ∆Р, действующей со стороны воды на погруженное в нее тело.",
                             "Объем вытесненной воды можно измерить также, перелив ее из мерного стакана в мерный цилиндр. В этом случае вес вытесненной воды в граммах будет численно равен ее объему в миллилитрах, так как вес одного миллилитра воды равен одному грамму или 0,01 Н."
                           ],
                           "picture":"Порядок_29_3.json"
                         },
                         {
                           "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                           "comment": [
                             "Таблица для заполнения."
                             ],
                             "table": [
                               "Р^_с^_, Н|Р^_ц^_, Н|Р^_вв^_, Н|Р^_в^_, Н|Р^_мс^_, Н|∆Р, Н",
                               "++|||||"
                             ]
                         },
                         {
                           "title": "ЗАДАНИЕ",
                           "comment": [
                             "Зафиксируйте полученные данные в таблице."
                             ]
                         }
                     ]
                   },
                   {
                     "labId": "lab30",
                     "labName": "Изучение зависимости периода колебаний маятника от длины нити подвеса",
                     "labPictureFolder": "01-physics/01-mechanics/lab30/",
                     "labPages": [
                         {
                             "title": "ЛАБОРАТОРНАЯ РАБОТА N30 ",
                             "comment": [
                               "Изучение зависимости периода колебаний маятника от длины нити подвеса"
                             ]
                           },
                           {
                             "title": "ЦЕЛЬ РАБОТЫ",
                             "comment": [
                               "Обнаружение зависимости периода колебаний маятника от длины нити подвеса"
                             ]
                           },
                           {
                             "title": "ОБОРУДОВАНИЕ",
                             "comment": [
                               "Штатив с муфтой и лапкой", "Секундомер", "Рулетка", "Транспортир", "Линейка со шкалой", "Груз 100 г.", "Нить"
                               ],
                              "picture": "Оборудование_лаб_раб_30.json",
                              "pictureDark": "oborudovanie_30_d.json"
                           },
                           {
                             "title": "ПОДГОТОВКА К РАБОТЕ",
                             "comment": [
                               "Установите штатив на краю стола так, чтобы груз, подвешенный на максимальную высоту, не касался пола. Установите длину подвеса нити 30 см.", 
                               "Приготовьте секундомер."
                             ],
                             "picture":"Порядок_лаб_раб_30.json"
                           },
                           {
                             "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                             "comment": [
                                 "Отведите груз из состояния равновесия на небольшой угол – 10–15 градусов (воспользуйтесь транспортиром).",
                                 "Отпустите груз и включите секундомер. ", "",
                                 "Отключите секундомер через 25 полных колебаний, измерив полное время t. Результат измерения занесите в таблицу."
                               ],
                               "picture":"Порядок_лаб_раб_30_2.json"
                           },    
                           {
                             "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                             "comment": [
                             ],
                             "table":[
                               "Длина нити L, см|Число колебаний, n|Полное время t, с|Т^_изм^_=t/n, с|Т^_расч^_=2π√(l/g), с",
                               "30|25|||",
                               "30|50|||",
                               "30|75|||",
                               "60|25|||", 
                               "60|50|||", 
                               "60|75|||", 
                               "90|25|||", 
                               "90|50|||", 
                               "90|75|||"
                             ]
                           },
                           {
                             "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                             "comment": [
                               "Рассчитайте измеренный период колебаний Тизм по формуле:", "",
                               "##@@Т^_изм^_ = t/n, с",
                               "Результат расчета занесите в таблицу.", "",
                               "Рассчитайте период колебаний по формуле:", "",
                               "##@@Т^_расч^_ = 2π √(l/g), с", 
                               "где: l – длина нити, м;",
                               "g – ускорение свободного падения, g = 9,8 м/с2.", "",
                               "В той же последовательности выполните остальные измерения для заполнения таблицы."
                               ],
                               "picture":"Порядок_лаб_раб_30_3.json"
                           },
                           {
                             "title": "ЗАДАНИЕ",
                             "comment": [
                               "Самостоятельно сформулируйте закономерности, выявленные в результате эксперимента и расчета:", "", 
                               "-Как изменяется период колебаний маятника при изменении длины подвеса;", "", 
                               "-Зависит ли период колебаний от массы груза."
                               ]
                           }
                       ]
                     },
                     {
                      "labId": "lab31",
                      "labName": "Измерение ускорения свободного падения с помощью маятника",
                      "labPictureFolder": "01-physics/01-mechanics/lab31/",
                      "labPages": [
                          {
                              "title": "ЛАБОРАТОРНАЯ РАБОТА N31 ",
                              "comment": [
                                "Измерение ускорения свободного падения с помощью маятника"
                              ]
                            },
                            {
                              "title": "ЦЕЛЬ РАБОТЫ",
                              "comment": [
                                "Приобретение навыка измерения одного из важнейших физических параметров – ускорения свободного падения"
                              ]
                            },
                            {
                              "title": "ОБОРУДОВАНИЕ",
                              "comment": [
                                "Штатив с муфтой и лапкой", "Рулетка", "Транспортир", "Линейка со шкалой", "Груз (1 шт.)", "Нить", "Секундомер"
                              ],
                              "picture": "Оборудование_лаб_раб_31.json",
                              "pictureDark": "oborudovanie_31_d.json"
                            },
                            {
                              "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                              "comment": [
                                "Соберите штатив и установите его так, чтобы фиксирующие губки лапки, закрепленной в верхней части стойки, выступали за пределы стола.", 
                                "Закрепите нить груза в лапке, установив расстояние от центра тяжести груза до точки фиксации нити, равное 0,5 м.", "", 
                                "Выведите груз из положения равновесия на угол, равный примерно 15°.", 
                                "Отпустите груз и включите секундомер. Отсчитайте 25 полных колебаний и остановите секундомер. Запишите полное время колебаний в таблицу."
                              ],
                              "picture":"Порядок_лаб_раб_31.json"
                            },
                            {
                              "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                              "comment": [
                                ],
                                "table": [
                                  "Длина нити l, м|Число колебаний n|Полное время t, c|Т^_изм^_ = t / n, с|g = 4π^^2^^ꞏl/T^^2^^, м/с^^2^^",
                                  "0,5|25|-|-|-",
                                  "0.75|50|-|-|-",
                                  "1|75|-|-|-"
                                ]
                            },    
                            {
                              "title": "ЗАДАНИЕ",
                              "comment": [
                                "Рассчитайте измеренный период Т^_изм^_ одного полного колебания и запишите результат в таблицу.", "", 
                                "Рассчитайте ускорение свободного падения по формуле, приведенной в таблице и запишите результат.", "", 
                                "В той же последовательности выполните измерения для длины нити 0,75 и 1м. Рассчитайте период колебаний и значение ускорения свободного падения."
                              ]
                            }
                        ]
                      },
                      {
                        "labId": "lab32",
                        "labName": "Изучение колебаний пружинного маятника",
                        "labPictureFolder": "01-physics/01-mechanics/lab32/",
                        "labPages": [
                            {
                                "title": "ЛАБОРАТОРНАЯ РАБОТА N32 ",
                                "comment": [
                                  "Изучение колебаний пружинного маятника"
                                ]
                              },
                              {
                                "title": "ЦЕЛЬ РАБОТЫ",
                                "comment": [
                                  "Исследование зависимости собственной частоты колебаний пружинного маятника от массы груза"
                                ]
                              },
                              {
                                "title": "ОБОРУДОВАНИЕ",
                                "comment": [
                                  "Штатив с муфтой и лапкой", "Дополнительная муфта", "Дополнительная лапка", "Линейка со шкалой", "Грузы (3 шт.)", "Пружина", "Секундомер"
                                ],
                                "picture": "Оборудование_лаб_раб_32.json",
                                "pictureDark": "oborudovanie_32_d.json"
                              },
                              {
                                "title": "ПОДГОТОВКА К РАБОТЕ",
                                "comment": [
                                  "Соберите штатив с лапкой как на рисунке.", 
                                  "Закрепите в лапке из другого комплекта линейку со шкалой.",
                                  "Закрепите в лапке верхний виток исследуемой пружины и измерьте по шкале линейки высоту @@h^_0^_@@, на которой находится нижний ее виток. ",
                                  "Запишите в отчет этот параметр. Он пригодится в дальнейшем. "
                                ],
                                "picture":"Подготовка_лаб_раб_32.json",
                                "pictureDark": "mech_podgotovka_32_01_d.json"
                              },    
                              {
                                "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                "comment": [
                                ],
                                "table":[
                                  "Масса, г|∆L|k, Н/м|Время, с|n|Т^_изм^_, с|Т^_расч^_, с",
                                  "m^_1^_=100 г|∆L^_1^_=|k^_1^_=||25||",
                                  "m^_2^_=200 г|∆L^_2^_=|k^_2^_=||25||",
                                  "m^_3^_=300 г|∆L^_3^_=|k^_3^_=||25||"
                                ]
                              },
                              {
                                "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                "comment": [
                                  "Навесьте на пружину груз массой 100 г, что соответствует усилию 1 Н.", "",
                                  "Определите удлинение ∆L^_1^_ как разность между высотой h^_0^_ и высотой, на которой находится нижний виток пружины под нагрузкой. Рассчитайте коэффициент жесткости k^_1^_ по формуле: ",
                                  "##@@k^_1^_ = m^_1^_ / ∆L^_1^_", "",
                                  "Аналогично определите удлинения ∆L^_2^_ и ∆L^_3^_, последовательно нагрузив пружину двумя и тремя грузами. Рассчитайте коэффициенты жесткости k^_2^_ и k^_3^_. "
                                ],
                                "picture":"Порядок_лаб_раб_32_1.json",
                                "pictureDark": "mech_32_01_d.json"
                              },
                              {
                                "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                "comment": [
                                  "Навесьте на пружину груз массой 100 г. Отведите его строго вниз примерно на 30 мм и отпустите, одновременно включив секундомер.", "", 
                                  "Определите время t двадцати пяти полных колебаний и рассчитайте период Т^_изм^_ одного полного колебания по формуле: ", 
                                  "##@@Т^_изм^_ = t / n.", "", 
                                  "Рассчитайте тот же параметр по формуле:",
                                  "##@@Т^_расч^_ = 2π√(m/k)", 
                                  "Полученные результаты занесите в таблицу."
                                  ],
                                  "picture":"Порядок_лаб_раб_32_2.json"
                              },
                              {
                                "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                "comment": [
                                  "Последовательно нагрузите пружину сначала двумя, а затем тремя грузами, каждый раз приводя маятник в колебательное движение и выполняя измерения временных параметров. ", "", 
                                  "Измерьте и расcчитайте Т^_изм^_ и Т^_расч^_ для новых условий и запишите получившиеся данные в таблицу."
                                  ],
                                  "picture":"Порядок_лаб_раб_32_3.json"
                              },
                              {
                                "title": "ЗАДАНИЕ",
                                "comment": [
                                  "Убедитесь, что временные параметры колебаний пружинного маятника зависят только от массы груза. "
                                  ]
                              }
                          ]
                        },
                        {
                          "labId": "lab33",
                          "labName": "Исследование движения тела под действием постоянной силы",
                          "labPictureFolder": "01-physics/01-mechanics/lab33/",
                          "labPages": [
                              {
                                  "title": "ЛАБОРАТОРНАЯ РАБОТА N33 ",
                                  "comment": [
                                    "Исследование движения тела под действием постоянной силы"
                                  ]
                                },
                                {
                                  "title": "ЦЕЛЬ РАБОТЫ",
                                  "comment": [
                                    "Выяснение зависимости дальности полета тела от",
                                    "начального уровня его потенциальной энергии"
                                  ]
                                },
                                {
                                  "title": "ОБОРУДОВАНИЕ",
                                  "comment": [
                                    "Штатив с муфтой и лапкой", "Желоб криволинейный", "Линейка со шкалой", "Стальной цилиндр", "Стальной шарик", "Подкладка пористая"
                                  ],
                                  "picture": "Оборудование_лаб_раб_33.json",
                                  "pictureDark": "oborudovanie_33_d.json"
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Зафиксируете в штативе желоб криволинейный так, чтобы его прямолинейный участок был закреплен в лапке штатива, как показано на рисунке.", 
                                    "В качестве вкладыша используйте латунный цилиндр. До фиксации в лапке штатива желоб следует повернуть по часовой стрелке на небольшой угол, что позволит осуществить легкое касание шарика к правой стенке и обеспечит устойчивое скатывание."
                                  ],
                                  "picture":"Подготовка_лаб_раб_33_1_00.json",
                                  "pictureDark": "mech_podgotovka_33_02_d.json"
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                      "Добившись надежного крепления желоба в лапке необходимо сместить лапку в муфте таким образом, чтобы криволинейная часть желоба оказалась в горизонтальном положении. "
                                    ],
                                    "picture":"Подготовка_лаб_раб_33_1.json",
                                  "pictureDark": "mech_podgotovka_33_01_d.json"
                                },    
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Для исследования движения тела под действием постоянной силы используется стальной шарик, который устанавливается и придерживается рукой на прямой части криволинейного желоба на высоте h^_1^_ (позиция 1).",
                                    "Затем шарик отпускают. Он скатывается до высоты h^_2^_ (позиция 2) и оказывается в крайней точке горизонтального участка желоба. Его скорость достигает максимального значения для такой высоты старта. Ее значение можно вычислить из уравнения: @@mg(h^_1^_ – h^_2^_) = mV^_2^_/2@@.",
                                    "Эта скорость будет горизонтальной составляющей дальнейшего полета шарика. Вертикальная составляющая его скорости в крайней точке желоба, когда шарик окажется в позиции 2, равна нулю."
                                  ],
                                  "picture":"Подготовка_лаб_раб_33_2.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Затем шарик продолжает полет в горизонтальном направлении с постоянной скоростью и одновременно падает под действием силы тяжести в вертикальном направлении. ",
                                    "В конце концов, пролетев расстояние S, шарик ударяется о поверхность стола – позиция 3." 
                                    ],
                                    "picture":"Подготовка_лаб_раб_33_3.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Установите шарик на расстоянии S0 = 75 мм от начала криволинейного участка желоба. ",
                                    "Измерьте высоты h^_1^_ и h^_2^_ и занесите их значения в таблицу.", "", 
                                    "Положите лист белой бумаги, покрыв им участок стола, на который будет падать шарик, и поместите на него лист копировальной бумаги красящей стороной вниз. Теперь любое падение шарика будет сопровождаться отметкой места первого касания стола. " , "", 
                                    "Определите путь S^_изм^_, который пролетел шарик в горизонтальном направлении. ", 
                                    "Результаты измерения занесите в таблицу."
                                    ],
                                    "picture":"Порядок_лаб_раб_33_1.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    ],
                                    "table":[
                                      "S^_0^_, см|h^_1^_-h^_2^_, см|t, c|V, см/с|S^_изм^_, см|S^_расч^_, см",
                                      "7,5|-|-|-|-|-",
                                      "15|-|-|-|-|-",
                                      "22,5|-|-|-|-|-"
                                    ]
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Рассчитайте время полета шарика по формуле:",
                                    "##@@h^_1^_ – h^_2^_ = √gt^^2^^/2", "", 
                                    "Отсюда: ", 
                                    "##@@t = √2(h^_1^_ – h^_2^_)/g" , "", 
                                    "Рассчитайте скорость V полета шарика по формуле преобразования потенциальной энергии шарика, находящегося в позиции 1, в его кинетическую энергию в позиции 2:", 
                                    "##@@mg(h^_1^_ – h^_2^_) = mV^_2^_/2.", "", 
                                    "Отсюда формула для расчета горизонтальной составляющей скорости шарика V:", 
                                    "##@@V = √2g(h^_1^_ – h^_2^_)"
                                    ],
                                    "picture":"Порядок_лаб_раб_33_2.json"
                                },
                                {
                                  "title": "ЗАДАНИЕ",
                                  "comment": [
                                    "Повторите наблюдения и расчеты для S^_0^_ = 15 и 22,5 см. Результаты измерений и расчетов занесите в таблицу."
                                    ]
                                }
                            ]
                          },
                          {
                            "labId": "lab34",
                            "labName": "Изучение движения тела по окружности под действием силы тяжести и упругости",
                            "labPictureFolder": "01-physics/01-mechanics/lab34/",
                            "labPages": [
                                {
                                    "title": "ЛАБОРАТОРНАЯ РАБОТА N34 ",
                                    "comment": [
                                      "Изучение движения тела по окружности",
                                      "под действием силы тяжести и упругости"
                                    ]
                                  },
                                  {
                                    "title": "ЦЕЛЬ РАБОТЫ",
                                    "comment": [
                                      "Установить связь между равнодействующей всех сил, действующих на тело,",
                                      " с ускорением, которое тело приобретает в результате их действия"
                                    ]
                                  },
                                  {
                                    "title": "ОБОРУДОВАНИЕ",
                                    "comment": [
                                      "Штатив с муфтой и лапкой", "Линейка со шкалой", "Грузы (3 шт.)", "Нить", "Секундомер", "Циркуль", "Лист бумаги", "Динамометр"
                                      ],
                                    "picture": "Оборудование_лаб_раб_34.json",
                                    "pictureDark": "oborudovanie_34_d.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Установка для выполнения работы показана на рисунке.", 
                                      "На листе бумаги нарисуйте окружность радиусом 6–8  см с центром, соосным с нитью отвеса, образованным грузом массой 100 г. Конец нити закрепите в лапке штатива так, как показано на рисунке. Расстояние от нижней поверхности груза до стола должно составлять 1 см. "
                                    ],
                                    "picture":"Подготовка_лаб_раб_34.json",
                                    "pictureDark": "mech_podgotovka_34_01_d.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                        "Толкните груз вдоль кольцевой линии по круговой траектории и включите секундомер.",
                                        "Измерьте время t, за которое груз совершит 15–20 оборотов по кольцевой траектории и запишите показатель в таблицу. "
                                      ],
                                      "picture":"Порядок_лаб_раб_34_1.json",
                                    "pictureDark": "mech_34_01_d.json"
                                  },    
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                    ],
                                    "table":[
                                      "Опыт|Время t, с|Обороты N|Период Т, с|Скорость V, см/с|Ускорение а^_ц^_, см/с^^2^^|Сила F, Н|Масса m, г",
                                      "1|-|-|-|-|-|-|-",
                                      "2|-|-|-|-|-|-|-",
                                      "3|-|-|-|-|-|-|-"
                                    ]
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Рассчитайте период процесса – время, за которое совершается один оборот:", 
                                      "##@@Т = t / N,",
                                      " где N - число оборотов. Запишите показатель в таблицу.", "",
                                      "Зная время одного оборота Т и радиус окружности R, рассчитайте линейную скорость вращения:",
                                      "##@@V = 2πR / T@@",
                                      "и центростремительное ускорение: ",
                                      "##@@ац = V^^2^^ / R@@.",
                                      "Полученные данные запишите в таблицу."
                                      ],
                                      "picture":"Порядок_лаб_раб_34_2.json",
                                    "pictureDark": "mech_34_02_d.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Измерьте динамометром силу F, удерживающую груз над кольцевой линией, как показано на рисунке и, пользуясь вторым законом Ньютона, рассчитайте массу груза m. Результаты измерений и расчетов занесите в таблицу."
                                    ],
                                    "picture":"Порядок_лаб_раб_34_3.json",
                                    "pictureDark": "mech_34_03_d.json"
                                  },
                                  {
                                    "title": "ЗАДАНИЕ",
                                    "comment": [
                                      "Повторите опыт несколько раз и определите среднее значение массы груза."
                                      ]
                                  }
                              ]
                            },
                            {
                              "labId": "lab35",
                              "labName": "Сохранение механической энергии при движении тела под действием сил тяжести и упругости",
                              "labPictureFolder": "01-physics/01-mechanics/lab35/",
                              "labPages": [
                                  {
                                      "title": "ЛАБОРАТОРНАЯ РАБОТА N35 ",
                                      "comment": [
                                        "Сохранение механической энергии при движении",
                                        "тела под действием сил тяжести и упругости"
                                      ]
                                    },
                                    {
                                      "title": "ЦЕЛЬ РАБОТЫ",
                                      "comment": [
                                        "Сравнить изменение потенциальной энергии пружины с изменением ",
                                        "потенциальной энергии тела, которое вызывает деформацию пружины"
                                      ]
                                    },
                                    {
                                      "title": "ОБОРУДОВАНИЕ",
                                      "comment": [
                                        "Штатив с муфтой и лапкой", "Дополнительные муфта и лапка", "Линейка со шкалой", "Грузы (2 шт.)", "Пружина"
                                        ],
                                      "picture": "Оборудование_лаб_раб_35.json",
                                      "pictureDark": "oborudovanie_35_d.json"
                                    },
                                    {
                                      "title": "ПОДГОТОВКА К РАБОТЕ",
                                      "comment": [
                                        "Соберите штатив с лапкой, как показано на рисунке.", 
                                        "Закрепите в дополнительной лапке линейку со шкалой.", 
                                        "Измерьте по линейке со шкалой уровень, на котором находится нижний виток пружины."
                                      ],
                                      "picture":"Подготовка_лаб_раб_35.json",
                                      "pictureDark": "mech_podgotovka_35_01_d.json"
                                    },
                                    {
                                      "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                      "comment": [
                                          "Навесьте на пружину два груза массой по 100 г.",
                                          "Дождитесь, когда колебательный процесс завершится, и измерьте новое положение нижнего витка пружины. ", 
                                          "Рассчитайте удлинение пружины L при нагрузке."
                                      ],
                                      "picture":"Порядок_лаб_раб_35_1.json",
                                      "pictureDark": "mech_35_01_d.json"
                                    },    
                                    {
                                      "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                      "comment": [
                                        "Возьмите рукой верхний груз и поднимите его до уровня, на котором деформация пружины прекратится.", "",
                                        "Отпустите груз и заметьте, до какого уровня растянется пружина под действием падающего груза. ", "",
                                        "Теоретически деформация пружины в нижней точке падения достигнет 2L."
                                      ],
                                      "picture":"Порядок_лаб_раб_35_2.json",
                                      "pictureDark": "mech_35_02_d.json"
                                    },
                                    {
                                      "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                      "comment": [
                                        "Рассмотрим момент 0 колебательного процесса (позиция 3). В этот момент груз опускается на расстояние 2L относительно уровня 2, с которого началось его падение. ", "",
                                        "Будем считать, что на этом уровне потенциальная энергия груза равна нулю, поскольку ниже этой точки груз не опустится. Следовательно, теперь в системе “пружина-груз” вся энергия сосредоточена в максимально деформированной пружине.", "",
                                        "Теоретически деформация пружины в нижней точке падения достигнет 2L.", "", 
                                        "Эта энергия определяется по формуле:",
                                        "##@@Е^_пр^_ = к(2L)^^2^^/2"
                                      ],
                                      "picture":"Порядок_лаб_раб_35_3.json",
                                      "pictureDark": "mech_35_03_d.json"
                                    },
                                    {
                                      "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                      "comment": [
                                        "В момент 2 колебательного процесса пружина примет исходную форму, ее деформация будет равна нулю, следовательно, энергия, заключенная в ней, также будет равна нулю.", "",
                                        "Теперь вся энергия системы будет состоять из потенциальной энергии груза, поднятого относительно нулевого уровня на высоту 2L. Значение этой энергии можно определить по формуле:", "",
                                        "##@@Е^_гр^_ = 2mgL"
                                      ],
                                      "picture":"Порядок_лаб_раб_35_4.json",
                                      "pictureDark": "mech_35_04_d.json"
                                    },
                                    {
                                      "title": "ЗАДАНИЕ",
                                      "comment": [
                                        "Самостоятельно докажите равенство Е^_пр^_ = Е^_гр^_. Доказательство этого равенства свидетельствует о том, что энергия замкнутой механической системы, которая не обменивается энергией с внешней средой, теоретически постоянна. ", "", 
                                        "На самом деле она убывает из-за потерь, в первую очередь из-за трения в верхнем подвесе пружины, постепенно уходя из системы и превращаясь в теплоту. "
                                        ]
                                    }
                                ]
                              },
                              {
                                "labId": "lab36",
                                "labName": "Сравнение работы силы с изменением кинетической энергии тела",
                                "labPictureFolder": "01-physics/01-mechanics/lab36/",
                                "labPages": [
                                    {
                                        "title": "ЛАБОРАТОРНАЯ РАБОТА N36",
                                        "comment": [
                                          "Сравнение работы силы с изменением кинетической энергии тела"
                                        ]
                                      },
                                      {
                                        "title": "ЦЕЛЬ РАБОТЫ",
                                        "comment": [
                                          "Нахождение связи между запасом механической энергии системы",
                                          "и механической работой, совершенной телами",
                                          "системы за счет этой энергии "
                                        ]
                                      },
                                      {
                                        "title": "ОБОРУДОВАНИЕ",
                                        "comment": [
                                          "Динамометр", "Пружина", "Деревянный брусок с магнитами", "Грузы (3 шт.)", "Наклонная плоскость-трибометр", "Линейка со шкалой", "Нить"
                                        ],
                                        "picture": "Оборудование_лаб_раб_36.json",
                                        "pictureDark": "oborudovanie_36_d.json"
                                      },
                                      {
                                        "title": "ПОДГОТОВКА К РАБОТЕ",
                                        "comment": [
                                          "В работе наблюдается превращение механической энергии двух тел: деревянного бруска с магнитами, нагруженного грузами, с динамометром, сцепленным с бруском нитью длиной 15 см.", 
                                          "Установку собирают в соответствии с рисунком."
                                        ],
                                        "picture":"Подготовка_к_работе_лаб_36.json",
                                        "pictureDark": "mech_podgotovka_36_01_d.json"
                                      },
                                      {
                                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                        "comment": [
                                            "Определите жесткость пружины k (k = F / ∆L, Н/м) и значение силы трения скольжения бруска по наклонной плоскости F^_ТР^_, пользуясь методикой, изложенной в лабораторной работе № 18. ",
                                            "Придерживая рукой брусок с одним грузом в положении, показанном на рисунке, отведите динамометр влево, растянув пружину. Энергия, запасенная в пружине, определяется по формуле:", "",
                                            "##@@Е = kX^_2^_/2,", 
                                            "где k – жесткость пружины, а Х – ее удлинение, измеренное линейкой со шкалой."
                                        ],
                                        "picture":"Порядок_лаб_36_1.json",
                                        "pictureDark": "mech_36_01_d.json"
                                      },    
                                      {
                                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                        "comment": [
                                          "Удерживая динамометр, отпустите брусок.  Брусок начнет перемещаться влево под действием силы упругости пружины F^_Д^_. ", 
                                          "В момент, когда растяжение пружины станет равным нулю, запас механической энергии системы полностью превратится в кинетическую энергию бруска К^_1^_. ", "",
                                          "Брусок будет продолжать движение по инерции до остановки, расходуя кинетическую энергию на трение."
                                          ],
                                          "picture":"Порядок_лаб_36_2.json",
                                        "pictureDark": "mech_36_02_d.json"
                                      },
                                      {
                                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                        "comment": [
                                          "Таким образом, потенциальная энергия, запасенная в системе в начальный момент процесса Е будет израсходована в соответствии с уравнением:", 
                                          "##@@Е = К^_1^_ + А^_1^_,", 
                                          "где А^_1^_ = F^_ТР^_, S^_1^_ – работа силы трения о поверхность плоскости при движении от положения пуска до места, где удлинение пружины станет равным нулю.", "", 
                                          "К моменту остановки вся кинетическая энергия бруска будет израсходована на работу по преодолению силы трения, то есть:",
                                          "##@@К^_1^_ = А^_2^_ = F^_ТР^_ S^_2^_,",
                                          "где S^_2^_ – перемещение бруска от положения, в котором удлинение пружины станет равным нулю, до остановки.", "", 
                                          "В конечном счете все преобразования энергии сводятся к уравнению:",
                                          "##@@kX^_2^_/2 = F^_1^_ = F^_2^_ = F^_тр^_S^_2^_ + F^_ТР^_S^_^_1 = F^_ТР^_ (S^_1^_ + S^_2^_) = FS."
                                        ],
                                        "picture":"Порядок_лаб_36_3.json",
                                        "pictureDark": "mech_36_03_d.json"
                                      },
                                      {
                                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                        "comment": [
                                          "Результат опыта, выполненный тщательно и точно, позволит убедиться в справедливости превращения энергии, запасенной в системе, и работы, выполненной за счет этой энергии, то есть равенства:", 
                                          "##@@kX^_2^_/2 = FS."
                                        ],
                                        "picture":"Порядок_лаб_36_4.json",
                                        "pictureDark": "mech_36_04_d.json"
                                      },
                                      {
                                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                        "comment": [
                                          "На рисунке представлены три фазы выполнения работы:", 
                                          "1. – исходная, координаты левого витка пружины Х^_1^_ и левого края бруска Х^_бр0^_;",
                                          "2. – состояние перед пуском, брусок удерживается рукой, левый крайний виток пружины сместился до уровня Х^_2^_, удлинение: @@Х = Х^_2^_ – Х^_1^_@@;",
                                          "3. – отпущенный брусок сместился влево до уровня Х^_бр^_, путь бруска составил: @@S = Х^_бр^_ – Х^_бр0^_@@."
                                        ],
                                        "picture":"Порядок_лаб_36_5.json",
                                        "pictureDark": "mech_36_05_d.json"
                                      },
                                      {
                                        "title": "ЗАДАНИЕ",
                                        "comment": [
                                          "Рассчитайте и сравните энергию kX^_2^_/2, сосредоточенную в пружине, и работу F^_ТР^_S, выполненную системой за счет этой энергии.", "", 
                                          "Повторите опыт с двумя и тремя грузами."
                                          ]
                                      }
                                  ]
                                },
                                {
                                  "labId": "lab37",
                                  "labName": "Исследование зависимости упругих свойств цилиндрической пружины от ее конструктивных параметров",
                                  "labPictureFolder": "01-physics/01-mechanics/lab37/",
                                  "labPages": [
                                      {
                                          "title": "ЛАБОРАТОРНАЯ РАБОТА N37 ",
                                          "comment": [
                                            "Исследование зависимости упругих свойств цилиндрической",
                                            "пружины от ее конструктивных параметров"
                                          ]
                                        },
                                        {
                                          "title": "ЦЕЛЬ РАБОТЫ",
                                          "comment": [
                                            "Исследование зависимости жесткости пружины от диаметра ",
                                            "витка, числа витков и диаметра проволоки"
                                          ]
                                        },
                                        {
                                          "title": "ОБОРУДОВАНИЕ",
                                          "comment": [
                                            "Штатив с муфтой и лапкой", "Дополнительная лапка", "Линейка со шкалой", "Грузы (2 шт.)", "Пружина", "Динамометр"
                                          ],
                                          "picture": "Оборудование_лаб_раб_37.json",
                                          "pictureDark": "oborudovanie_37_d.json"
                                        },
                                        {
                                          "title": "ПОДГОТОВКА К РАБОТЕ",
                                          "comment": [
                                            "Жесткость цилиндрической пружины зависит от ряда ее конструктивных параметров: от диаметра проволоки, из которой она выполнена, от диаметра витка намотки, от числа витков, от качества термической обработки готовой пружины, от способности пружины возвращаться к первоначальной форме после снятия нагрузки.", 
                                            "При высококачественной термической обработке пружина при снятии многократной нагрузки возвращается к исходной форме. ", "", 
                                            "Низкое качество термической обработки или ее отсутствие приводит к остаточной деформации пружины после снятия нагрузки."
                                          ],
                                          "picture":"Подготовка_лаб_раб_37_1.json"
                                        },
                                        {
                                          "title": "ПОДГОТОВКА К РАБОТЕ",
                                          "comment": [
                                              "В лабораторной работе исследуется длинная эластичная пружина и для сравнения пружина динамометра, рассчитанного на максимальную нагрузку 5Н.",
                                              "Обе пружины выполнены из проволоки диаметром 1 мм, но из-за существенного различия диаметров намотки и числа витков, они обладают разной жесткостью. "
                                            ],
                                            "picture":"Подготовка_лаб_раб_37_2.json"
                                        },    
                                        {
                                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                          "comment": [
                                            "Сначала измерьте упругую деформацию пружины при различных нагрузках и занесите полученные данные в таблицу.", 
                                            "В таблице: ∆L^_пр^_ - деформация исследуемой пружины; ", 
                                            "∆L^_дин^_ - деформация пружины динамометра;",
                                            "∆L^_ук^_ - деформация исследуемой пружины, укороченной на треть, ",
                                            "К-коэффициент деформации соответственно.",
                                            "Измерение этих параметров следует проводить без нагрузки и при трех нагрузках."
                                            ],
                                            "table":[
                                              "Нагрузка, Н|∆L^_пр^_, мм|∆L^_дин^_, мм|∆L^_укор^_, мм|К^_пр^_|К^_дин^_|К^_укор^_",
                                              "0|-|-|-|-|-|-",
                                              "1|-|-|-|-|-|-",
                                              "2|-|-|-|-|-|-",
                                              "3|-|-|-|-|-|-"
                                            ]
                                        },
                                        {
                                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                          "comment": [
                                            "Сборка установки показана на рисунке.", 
                                            "Пружину закрепите в лапке, как показано на рисунке. Линейку зафиксируйте вертикально, на расстоянии 2–3 мм от пружины."
                                            ],
                                            "picture":"Подготовка_лаб_раб_37_3.json"
                                        },
                                        {
                                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                          "comment": [
                                            "Сборка установки показана на рисунке.", 
                                            "Динамометр закрепите в лапке, как показано на рисунке. Линейку зафиксируйте во второй лапке, вертикально на расстоянии 2–3 мм от пружины. "
                                            ],
                                            "picture":"Подготовка_лаб_раб_37_4.json"
                                        },
                                        {
                                          "title": "ВЫВОД",
                                          "comment": [
                                            "Результатом работы являются следующие выводы:",
                                            "при увеличении диаметра проволоки жесткость пружины увеличивается;",
                                            "при увеличении диаметра витка намотки жесткость уменьшается;",
                                            "при увеличении числа витков пружины жесткость уменьшается."
                                            ]
                                        }
                                    ]
                                  },
                                  {
                                    "labId": "lab38",
                                    "labName": "Изучение равновесия тела на наклонной плоскости",
                                    "labPictureFolder": "01-physics/01-mechanics/lab38/",
                                    "labPages": [
                                        {
                                            "title": "ЛАБОРАТОРНАЯ РАБОТА N38",
                                            "comment": [
                                              "Изучение равновесия тела на наклонной плоскости"
                                            ]
                                          },
                                          {
                                            "title": "ЦЕЛЬ РАБОТЫ",
                                            "comment": [
                                              "Опытная проверка равномерности движения ",
                                              "тела на наклонной плоскости при",
                                              "компенсации действующих на него сил "
                                            ]
                                          },
                                          {
                                            "title": "ОБОРУДОВАНИЕ",
                                            "comment": [
                                              "Штатив с муфтой и лапкой", "Брусок деревянный с магнитами", "Наклонная плоскость-трибометр", "Грузы (3 шт.)", "Линейка со шкалой", "Динамометр", "Транспортир"
                                            ],
                                            "picture": "Оборудование_лаб_раб_38.json",
                                            "pictureDark": "oborudovanie_38_d.json"
                                          },
                                          {
                                            "title": "ПОДГОТОВКА К РАБОТЕ",
                                            "comment": [
                                              "Наклонную плоскость устанавливают горизонтально, на нее помещают деревянный брусок с магнитами с одним из грузов, как показано на рисунке."
                                            ],
                                            "picture":"Подготовка_к_работе_лаб_38.json",
                                            "pictureDark": "mech_podgotovka_38_01_d.json"
                                          },
                                          {
                                            "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                            "comment": [
                                                "Брусок с грузом с постоянной скоростью перемещают вправо, измеряя при этом силу тяги динамометра @@F^_Д^_@@.", "",
                                                "Отношение этой силы весу тела называется коэффициентом трения скольжения @@μ@@."
                                            ],
                                            "picture":"Порядок_лаб_38_0.json",
                                            "pictureDark": "mech_38_00_d.json"
                                          },    
                                          {
                                            "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                            "comment": [
                                              "В лапке штатива крепят линейку со шкалой, чтобы фиксировать высоту @@h@@, при которой брусок плавно скатывается с наклонной плоскости. Для того, чтобы скорость скатывания бруска была постоянной необходимо, чтобы сила, вектор которой параллелен плоскости и равен @@mgꞏsinα@@, был равен силе трения @@μmg ꞏ cosα@@. ", 
                                              "Отсюда равенство:",
                                              "##@@mg ꞏ sinα = μmg ꞏ cosα@@",
                                              "##или @@μ = h/L = tgα@@."
                                              ],
                                              "picture":"Порядок_лаб_38_1.json",
                                            "pictureDark": "mech_38_01_d.json"
                                          },
                                          {
                                            "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                            "comment": [
                                              "Установите деревянный брусок с магнитами и одним грузом на наклонную плоскость, находящуюся в горизонтальном положении, и определите с помощью динамометра коэффициент трения.", 
                                              "Результат измерения запишите в таблицу."
                                              ],
                                              "picture":"Порядок_лаб_38_2.json",
                                            "pictureDark": "mech_38_02_d.json"
                                          },
                                          {
                                            "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                            "comment": [
                                              ],
                                              "table":[
                                                "Нагрузка, г|mgh, H|F^_тр^_, Н|μ|h, см|L, см|tgα",
                                                "100|-|-|-|-|-|-",
                                                "200|-|-|-|-|-|-",
                                                "300|-|-|-|-|-|-"
                                              ]
                                          },
                                          {
                                            "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                            "comment": [
                                              "Поместите брусок с грузом в средней части наклонной плоскости, как показано на рисунке.", 
                                              "Плавно поднимая вверх левую сторону сборки найдите положение, при котором груз начнет скользить вниз по наклонной плоскости с постоянной скоростью. Измерьте высоту h.",
                                              "По полученным данным вычислите tgα и убедитесь, что этот параметр численно равен коэффициенту μ."
                                              ],
                                              "picture":"Порядок_лаб_38_3.json",
                                            "pictureDark": "mech_38_03_d.json"
                                          },
                                          {
                                            "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                            "comment": [
                                              "Повторите измерения и расчеты для двух грузов.", 
                                              "Результаты измерений занесите в таблицу."
                                              ],
                                              "picture":"Порядок_лаб_38_4.json",
                                            "pictureDark": "mech_38_04_d.json"
                                          },
                                          {
                                            "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                            "comment": [
                                              "Повторите измерения и расчеты для трех грузов.", 
                                              "Результаты измерений занесите в таблицу."
                                              ],
                                              "picture":"Порядок_лаб_38_5.json",
                                            "pictureDark": "mech_38_05_d.json"
                                          },
                                          {
                                            "title": "ЗАДАНИЕ",
                                            "comment": [
                                              "По полученным данным вычислите tg α и убедитесь, что этот параметр численно равен коэффициенту трения μ."
                                              ]
                                          }
                                      ]
                                    },
                                    {
                                      "labId": "lab39",
                                      "labName": "Исследование изменения веса тела при его движении с ускорением",
                                      "labPictureFolder": "01-physics/01-mechanics/lab39/",
                                      "labPages": [
                                          {
                                              "title": "ЛАБОРАТОРНАЯ РАБОТА N39",
                                              "comment": [
                                                "Исследование изменения веса тела при его движении с ускорением"
                                              ]
                                            },
                                            {
                                              "title": "ЦЕЛЬ РАБОТЫ",
                                              "comment": [
                                                "Показать, что при движении тела по криволинейной",
                                                "траектории его вес изменяется в зависимости",
                                                "от направления вектора ускорения"
                                              ]
                                            },
                                            {
                                              "title": "ОБОРУДОВАНИЕ",
                                              "comment": [
                                                "Штатив с муфтой и лапкой", "Линейка со шкалой", "Груз 100 г", "Нить", "Динамометр"
                                              ],
                                              "picture": "Оборудование_лаб_раб_39.json",
                                              "pictureDark": "oborudovanie_39_d.json"
                                            },
                                            {
                                              "title": "ПОДГОТОВКА К РАБОТЕ",
                                              "comment": [
                                                "Динамометр закрепляют в лапке штатива, как показано на рисунке. На крючке динамометра крепят нить, на которой висит груз массой 100 г на расстоянии 1 см от поверхности стола.", 
                                                "Вес груза, находящегося в покое:",
                                                "##@@Р^_0^_ = mg.",
                                                "Если груз отвести в положение 1, при этом натягивая нить так, чтобы она не провисала, он поднимется на высоту R и приобретет потенциальную энергию:",
                                                "##@@Е^_п^_ = mgR."
                                              ],
                                              "picture":"Подготовка_лаб_раб_39_1.json",
                                              "pictureDark": "mech_podgotovka_39_01_d.json"
                                            },
                                            {
                                              "title": "ПОДГОТОВКА К РАБОТЕ",
                                              "comment": [
                                                  "При освобождении груза он начнет движение по круговой траектории радиусом R. Проходя через точку равновесия, груз приобретет максимальную скорость, а его энергия из потенциальной превратится в кинетическую в соответствии с формулой:",
                                                  "##@@mgR = mV^_2^_/2", "",
                                                  "Отсюда скорость груза в точке 3:", 
                                                  "##@@V^_2^_ = 2gR,",
                                                  "а центростремительное ускорение определится из выражения:",
                                                  "##@@а^_ц^_ = V^_2^_/R = 2g.", "", 
                                                  "Тогда, вес груза в точке 3 по сравнению с весом того же груза, находящегося в покое, увеличится втрое:",
                                                  "##@@Р^_3^_ = m(g + 2g) = 3mg,",
                                                  "так как к ускорению свободного падения прибавилось вдвое большее центростремительное ускорение за счет движения груза по криволинейной траектории."
                                              ],
                                              "picture":"Подготовка_лаб_раб_39_2.json",
                                              "pictureDark": "mech_podgotovka_39_02_d.json"
                                            },    
                                            {
                                              "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                              "comment": [
                                                "Проделайте опыт несколько раз, отпуская груз из все больших углов отклонения. Наблюдая за показаниями динамометра, убедитесь, что при прохождении положения 3, вес груза увеличивается.", 
                                                "Отведите груз в положение 2 и отпустите. Наблюдайте заметное увеличение его веса при прохождении положения 3.", "",
                                                "Пускать груз из положения 1 можно при условии надежной фиксации рукой вертикального стержня штатива."
                                              ],
                                              "picture": "Порядок_лаб_раб_39.json",
                                              "pictureDark": "mech_39_01_d.json"
                                            }
                                        ]
                                      },
                                      {
                                        "labId": "lab40",
                                        "labName": "Изучение третьего закона Ньютона",
                                        "labPictureFolder": "01-physics/01-mechanics/lab40/",
                                        "labPages": [
                                            {
                                                "title": "ЛАБОРАТОРНАЯ РАБОТА N40 ",
                                                "comment": [
                                                  "Изучение третьего закона Ньютона"
                                                ]
                                              },
                                              {
                                                "title": "ЦЕЛЬ РАБОТЫ",
                                                "comment": [
                                                  "Подтвердить положение о том, что силы  ",
                                                  "взаимодействия тел одинаковы по значению",
                                                  "и противоположны по направлению"
                                                ]
                                              },
                                              {
                                                "title": "ОБОРУДОВАНИЕ",
                                                "comment": [
                                                  "Пружина", "Динамометр 2 шт.", "Линейка со шкалой", "Плоскость-трибометр"
                                                ],
                                                "picture": "Оборудование_лаб_раб_40.json",
                                                "pictureDark": "oborudovanie_40_d.json"
                                              },
                                              {
                                                "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                                "comment": [
                                                  "Разместите на наклонной плоскости-трибометре пружину, динамометр и линейку со шкалой, как показано на рисунке.", 
                                                  "Возьмитесь рукой за правое полукольцо пружины. Отведите динамометр влево, растянув пружину до усилия в 2Н, измеренного по его шкале.",
                                                  "Измерьте по шкале линейки длину деформированной пружины – L."
                                                ],
                                                "picture":"Подготовка_к_работе_лаб_40_1.json",
                                                "pictureDark": "mech_podgotovka_40_01_d.json"
                                              },
                                              {
                                                "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                                "comment": [
                                                    "Соберите установку, показанную на рисунке.",
                                                    "Удерживая правый динамометр рукой, левый динамометр отведите влево, добившись его показания 2Н.", "",
                                                    "Убедитесь, что деформация пружины будет равна L, а показание правого динамометра будет равно 2Н."
                                                  ],
                                                  "picture":"Подготовка_к_работе_лаб_40_2.json",
                                                "pictureDark": "mech_podgotovka_40_02_d.json"
                                              },    
                                              {
                                                "title": "ВЫВОД",
                                                "comment": [
                                                  "Выполнив работу удалось подтвердить, что силы взаимодействия пружины и двух динамометров оказались одинаковыми, то есть цель работы нашла подтверждение."
                                                  ]
                                              }
                                          ]
                                        },
                                        {
                                          "labId": "lab41",
                                          "labName": "Изучение закона сохранения импульса",
                                          "labPictureFolder": "01-physics/01-mechanics/lab41/",
                                          "labPages": [
                                              {
                                                  "title": "ЛАБОРАТОРНАЯ РАБОТА N41 ",
                                                  "comment": [
                                                    "Изучение закона сохранения импульса"
                                                  ]
                                                },
                                                {
                                                  "title": "ЦЕЛЬ РАБОТЫ",
                                                  "comment": [
                                                    "Экспериментальное подтверждение закона",
                                                    "сохранения импульса для шаров разной",
                                                    "массы при их центральном соударении"
                                                  ]
                                                },
                                                {
                                                  "title": "ОБОРУДОВАНИЕ",
                                                  "comment": [
                                                    "Штатив с муфтой и лапкой", "Желоб криволинейный", "Линейка со шкалой", "Стальной цилиндр", "Стальной шарик", "Пластмассовый шарик", "Подкладка пористая", "Бумага белая и копировальная", "Весы", "Транспортир"
                                                    ],
                                                  "picture": "Оборудование_лаб_раб_41.json",
                                                  "pictureDark": "oborudovanie_41_d.json"
                                                },
                                                {
                                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                                  "comment": [
                                                    "Зафиксируйте желоб криволинейный так, чтобы его прямолинейный участок был закреплен в лапке штатива так, как показано на рисунке.", 
                                                    "В качестве вкладыша используйте стальной цилиндр. До фиксации в лапке штатива желоб следует повернуть против часовой стрелки на небольшой угол, что позволит осуществить легкое касание шарика к левой стенке и обеспечит устойчивое скатывание."
                                                  ],
                                                  "picture":"Подготовка_лаб_раб_41_1_00.json",
                                                  "pictureDark": "mech_podgotovka_41_01_01_d.json"
                                                },
                                                {
                                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                                  "comment": [
                                                      "Закрепите желоб криволинейный в лапке штатива, как показано на рисунке, на высоте H = 10 см под углом примерно 30º. ",
                                                      "Выполните цветным скотчем или карандашом отметки стартовых позиций стального шарика перед пуском в соответствии с рисунком.", "",
                                                      "Это необходимо для того, чтобы каждый старт в серии пусков происходил с одного и того же уровня h^_1^_, h^_2^_ или h^_3^_."
                                                  ],
                                                  "picture":"Подготовка_лаб_раб_41_1.json",
                                                  "pictureDark": "mech_podgotovka_41_01_d.json"
                                                },    
                                                {
                                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                                  "comment": [
                                                    "Измерьте массу стального шарика m. Аналогично измерьте массу пластмассового шарика.", "",
                                                    "Измерьте высоты h^_1^_, h^_2^_ и h^_3^_ расположения центра тяжести стального шарика на стартовых позициях в соответствии с рисунком.", "",
                                                    "Результаты измерений занесите в таблицу 1."
                                                  ],
                                                  "picture":"Подготовка_лаб_раб_41_2.json",
                                                  "pictureDark": "mech_podgotovka_41_02_d.json"
                                                },
                                                {
                                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                                  "comment": [
                                                    "Таблица 1"
                                                    ],
                                                    "table":[
                                                      "Высота h, см|Время t, c|Масса m, г|Путь S, см|Скорость V, см/с|Импульс Р, гсм/с",
                                                      "h^_1^_=|t^_1^_=|-|S^_1^_=|V^_1^_=|P^_1^_=",
                                                      "h^_2^_=|t^_2^_=|-|S^_2^_=|V^_2^_=|P^_2^_=",
                                                      "h^_3^_=|t^_3^_=|-|S^_3^_=|V^_3^_=|P^_3^_="
                                                    ]
                                                },
                                                {
                                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                                  "comment": [
                                                    "Установите, придерживая рукой, стальной шарик на позиции 20 и отпустите его. Определите место его падения на стол, предварительно покрытый пористой подкладкой, служащей для амортизации удара и защиты поверхности стола от повреждений.", 
                                                    "Выполните еще два старта из позиций 15 и 10", "", 
                                                    "Положите на стол лист белой бумаги так, чтобы точки пробных падений шарика находилась в его пределах. Зафиксируйте скотчем его положение.",
                                                    "Накройте лист копировальной бумагой, обращенной красящей поверхностью к белой бумаге, и выполните три пуска с разных высот h в соответствии с таблицей."
                                                  ],
                                                  "picture":"Порядок_лаб_раб_41_2_01.json",
                                                  "pictureDark": "mech_41_02_01_d.json"
                                                },
                                                {
                                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                                  "comment": [
                                                    "Удалите копирку. На листе бумаги появится отчетливые отпечатки ударов шарика по столу - 1, 2 и 3.", 
                                                    "Измерьте расстояния S^_1^_, S^_2^_ и S^_3^_, которые пролетел шарик по горизонтали при трех пусках с высот h^_1^_, h^_2^_ и h^_3^_.",
                                                    "Рассчитайте время падения шарика по формуле:",
                                                    "##@@ t = √(2H/g)", "", 
                                                    "Оно должно иметь одно и то же значение для трех стартов.",
                                                    "Рассчитайте значения скорости для трех стартов с высот h^_1^_, h^_2^_ и h^_3^_ и импульсы шарика Р^_1^_, Р^_2^_ и Р^_3^_:",
                                                    "##@@V = S / t^_ср^_, P = m V",
                                                    "Результаты измерений и расчетов занесите в таблицу 1."
                                                  ],
                                                  "picture":"Порядок_лаб_раб_41_2_02.json",
                                                  "pictureDark": "mech_41_02_02_d.json"
                                                },
                                                {
                                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                                  "comment": [
                                                    "Пластмассовый шарик приклейте кусочком пластилина к горизонтальному участку желоба. Положите копирку на лист бумаги красящей стороной вниз.", 
                                                    "Выполните старт стального шарика из позиции 20. ", "", 
                                                    "Отметьте на листе бумаги точки падения двух шариков и измерьте их пути по горизонтали S^_1^_ и S^_2^_ (см. рисунок.)"
                                                  ],
                                                  "picture":"Порядок_лаб_раб_41_3.json",
                                                  "pictureDark": "mech_41_03_d.json"
                                                },
                                                {
                                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                                  "comment": [
                                                    "Повторите пуски для стартовых позиций 10 и 15.", "", 
                                                    "Рассчитайте скорости шариков на пути свободного падения считая время падения для всех одинаковым.", "", 
                                                    "Рассчитайте импульсы шариков Р^_1^_ и Р^_2^_.", 
                                                    "Результаты измерений и расчетов занесите в таблицу 2."
                                                  ],
                                                  "picture":"Порядок_лаб_раб_41_4.json",
                                                  "pictureDark": "mech_41_04_d.json"
                                                },
                                                {
                                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                                  "comment": [
                                                    "Таблица 2"
                                                    ],
                                                    "table":[
                                                      "++Высота h, см|Путь 1, см|Путь 2, см|Скорость V^_1^_, см/с|Скорость V^_2^_, см/с|Импульс Р^_1^_, гсм/с|Импульс Р^_2^_, гсм/с|Сумма импульсов Р^_1^_+Р^_2^_, гсм/с",
                                                      "h^_1^_=|S^_1^_=|S^_2^_=|-|-|-|-|-",
                                                      "h^_2^_=|S^_3^_=|S^_4^_=|-|-|-|-|-",
                                                      "h^_3^_=|S^_5^_=|S^_6^_=|-|-|-|-|-"
                                                    ]
                                                },
                                                {
                                                  "title": "ЗАДАНИЕ",
                                                  "comment": [
                                                    "Сравните импульс одного шарика из таблицы 1 и сумму импульсов двух шариков из таблицы 2. Значения импульсов должны быть одинаковыми."
                                                    ]
                                                }
                                            ]
                                          }
                  ]
              }, {
                  "sectionName": "Молекулярная физика и термодинамика",
                  "sectionId": "02-molecule_termodyn", 
                  "labList": [
                    {
                      "labId":"lab01",
                      "labName": "Исследование связи массы вещества с его объемом",
                      "labPictureFolder": "01-physics/02-molecule_termodyn/lab01/",
                          "labPages": [
                              {
                                  "title": "ЛАБОРАТОРНАЯ РАБОТА N1 ",
                                  "comment": [
                                    "Исследование связи массы",
                                    "вещества с его объемом"
                                  ]
                                },
                                {
                                  "title": "ЦЕЛЬ РАБОТЫ",
                                  "comment": [
                                    "Экспериментально подтвердить утверждение о прямой пропорциональной ", 
                                    "зависимости между массой тела и его объемом"
                                  ]
                                },
                                {
                                  "title": "ОБОРУДОВАНИЕ",
                                  "comment": [
                                    "Емкость для воды", "Капельница", "Весы", "Мерный цилиндр"
                                    ],
                                    "picture": "Оборудование_мол_физ_лаб_01.json",
                                    "pictureDark": "oborudovanie_mf_01_d.json"
                                },
                                {
                                  "title": "КРАТКОЕ ОПИСАНИЕ",
                                  "comment": [
                                    "В ходе лабораторной работы ученики должны убедиться в том, что масса тела связана прямой пропорциональной зависимостью с его объемом. Простейшим телом, массу и объем которого можно легко изменять, является вода, налитая в мерный цилиндр.", "",
                                    "Для этого пустой цилиндр предварительно взвешивают на весах и определяют его массу @@m^_0^_@@. Затем наливают в него воду и повторяют взвешивание. Из полученной массы @@m@@ цилиндра с водой вычитают массу @@m^_0^_@@ пустого цилиндра. Так определяют массу воды. Одновременно по шкале, нанесенной на боковую поверхность цилиндра, находят объем воды.", "",
                                    "Используя данные, полученные экспериментальным путем для ряда масс и соответствующих объемов, строят график зависимости массы воды от ее объема. В результате получится прямая линия, наглядно свидетельствующая о том, что между массой тела и его объемом существует прямая пропорциональная зависимость, которая аналитически выражается формулой: ", 
                                    "##@@m = ρ V@@,",
                                    "где ρ - коэффициент пропорциональности.", "", 
                                    "Графически формула представляет собой прямую наклонную линию. Коэффициент @@ρ@@ позволяет вычислить массу воды по известному объему, или объем воды по известной массе."
                                    ]
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Подготовьте весы к взвешиванию.", "",
                                    "Установите на измерительную платформу весов пустой мерный цилиндр и определите его массу m^_0^_.", "",
                                    "Запишите значение этого параметра."
                                    ],
                                    "picture": "mf_01_01.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Сняв мерный цилиндр с платформы весов налейте в него 10 мл воды по шкале цилиндра с помощью капельницы.", "", 
                                    "Взвесьте мерный цилиндр с водой m + m^_0^_. Результат занесите в таблицу.", "", 
                                    "При добавлении воды обязательно снимайте цилиндр с весов."
                                    ],
                                    "picture": "mf_01_02.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    ],
                                    "table": [
                                      "V, мл|10|20|30|40|50|60|70|80|90",
                                      "m+m^_0^_, г|||||||||",
                                      "m, г|||||||||",
                                      "ρ, г/мл|||||||||"
                                    ]
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Налейте в цилиндр еще 10 мл воды и вновь определите его массу. Повторяйте эти действия до тех пор, пока объем воды в мерном цилиндре не достигнет 90 мл.", "", 
                                    "Полученные данные запишите во вторую строку таблицы.", "", 
                                    "Вычтите из каждого значения массы мерного цилиндра с водой m + m^_0^_ массу пустого цилиндра m^_0^_ и поместите полученные данные в третью строку таблицы.", "", 
                                    "Условно считая, что измерение последовательных объемов выполнено без погрешности, поместите на графике ряд значений массы воды, соответствующих ряду значений объема из таблицы."
                                    ],
                                    "picture": "mf_01_03.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Эти точки, несмотря на отклонения от теоретической линии (см. рисунок), будут близки к ней, что свидетельствует о линейной зависимости массы воды от ее объема.", "", 
                                    "Для каждого случая вычислите измеренный коэффициент пропорциональности @@ρ^_изм^_ = m / V@@ и занесите полученные значения в таблицу.", "", 
                                    "Не забудьте перевести значения объема V из мл (миллилитры) в м^^3^^ (кубические метры), а массы m из г (граммы) в кг (килограммы). Для этого умножьте значения объема, выраженные в миллилитрах, на 0,000001, а значения массы, выраженные в граммах, на 0,001.", "", 
                                    "Для получения более достоверного значения параметра повторите измерения несколько раз и, если результаты будут отличаться, вычислите средние значения m и ρ^_изм^_ для серии."
                                    ],
                                    "picture": "Порядок_мол_физ_лаб_01_04.json",
                                    "pictureDark": "mf_01_04_d.json"
                                }   
                            ]
                    },
                    {
                      "labId":"lab02",
                      "labName": "Измерение плотности вещества",
                      "labPictureFolder": "01-physics/02-molecule_termodyn/lab02/",
                          "labPages": [
                              {
                                  "title": "ЛАБОРАТОРНАЯ РАБОТА N2 ",
                                  "comment": [
                                    "Измерение плотности вещества"
                                  ]
                                },
                                {
                                  "title": "ЦЕЛЬ РАБОТЫ",
                                  "comment": [
                                    "Экспериментальное определение плотности тела ", 
                                    "по измеренным массе и объему"
                                  ]
                                },
                                {
                                  "title": "ОБОРУДОВАНИЕ",
                                  "comment": [
                                    "Стальной цилиндр", "Алюминиевый цилиндр", "Мерный цилиндр", "Нить", "Рулетка", 
                                    "Пластилин", "Весы", "Емкость с водой"
                                    ],
                                    "picture": "Оборудование_мол_физ_лаб_02.json",
                                    "pictureDark": "oborudovanie_mf_02_d.json"
                                },
                                {
                                  "title": "КРАТКОЕ ОПИСАНИЕ",
                                  "comment": [
                                    "Количество вещества в единичном объеме тела называется его плотностью. Плотность вещества обозначается буквой @@ρ@@ и определяется по формуле:",
                                    "##@@ρ = m / V,",
                                    "где m - масса тела в граммах (килограммах), а V — его объем миллилитрах (литрах).", "", 
                                    "Из формулы следует, что для определения плотности необходимо вычислить или измерить массу и объем тела. Для измерения массы используются весы, а для измерения объема мерный цилиндр с водой. Если тело имеет простую геометрическую форму, его объем можно вычислить расчетом, измерив его поперечное сечение и длину.", "",
                                    "Тело произвольной формы выполняется из пластилина.",
                                    "Его размер должен быть таким, чтобы оно, подвешенное на нити, могло свободно опускаться в мерный цилиндр. Ясно, что измерить объем такого тела расчетом сложно. Однако достаточно точный результат можно получить, если это тело опустить в мерный цилиндр с водой и вычислить разность конечного и начального уровней воды. Полученная разность определит вытесненный телом объем воды, который равен объему тела."
                                    ]
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Подготовьте весы к взвешиванию.", "",
                                    "Взвесьте тела. Значения их масс m^_1^_, m^_2^_ и m^_3^_ занесите в таблицу."
                                    ],
                                    "picture": "Порядок_мол_физ_лаб_02_01.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    ],
                                    "table": [
                                      "Тело|m, г|d, см|l, см|V, см3|ρ, г/см^^3^^",
                                      "Стальной цилиндр|m^_1^_=|d^_1^_=|l^_1^_=|V^_1^_=|ρ^_1^_=",
                                      "Алюминиевый цилиндр|m^_2^_=|d^_2^_=|l^_2^_=|V^_2^_=|ρ^_2^_=",
                                      "Тело произвольной формы|m^_3^_=|||V^_3^_=|ρ^_3^_="
                                    ]
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "С помощью рулетки измерьте диаметр @@d^_1^_@@ и длину @@l^_1^_@@ стального цилиндра. Рассчитайте его объем @@V^_1^_@@ по формуле:", "",
                                    "##@@V^_1^_ = πd^_1^_^^2^^ ꞏ l^_1^_ / 4,",
                                    "где @@V^_1^_@@ - объем стального цилиндра в см^^3^^, а ", 
                                    "d^_1^_ и l^_1^_ - его диаметр и длина в см.", 
                                    "", 
                                    "Повторите измерения размеров d^_2^_ и l^_2^_ для цилиндра из алюминия и рассчитайте его объем @@V^_2^_@@."
                                    ],
                                    "picture": "Порядок_мол_физ_лаб_02_02.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Для определения объема тела произвольной формы V^_3^_ навесьте брусок пластилина на нить и погрузите его в мерный цилиндр с водой. Определить разность между конечным V^_кон^_ и начальным V^_нач^_ уровнями воды в цилиндре:", "", 
                                    "##@@V^_3^_ = V^_кон^_ - V^_нач^_.", "", 
                                    "Результатом расчета будет объем воды, вытесненной телом при погружении, равный его объему V^_3^_. Полученные данные занесите в таблицу."
                                    ],
                                    "picture": "Порядок_мол_физ_лаб_02_03.json"
                                },
                                {
                                  "title": "ЗАДАНИЕ",
                                  "comment": [
                                    "Аналогичным способом определите объемы стального и алюминиевого цилиндров.", "", 
                                    "Сравните полученные значения со значениями, полученными с помощью рулетки.", "", 
                                    "Определите значения плотности тел по формуле.", "", 
                                    "Результаты измерений и расчетов занесите в таблицу."
                                    ],
                                    "picture": "Порядок_мол_физ_лаб_02_04.json"
                                }      
                            ]
                      },
                      {
                        "labId":"lab03",
                        "labName": "Измерение температуры остывающей воды",
                        "labPictureFolder": "01-physics/02-molecule_termodyn/lab03/",
                            "labPages": [
                                {
                                    "title": "ЛАБОРАТОРНАЯ РАБОТА N3 ",
                                    "comment": [
                                      "Измерение температуры остывающей воды"
                                    ]
                                  },
                                  {
                                    "title": "ЦЕЛЬ РАБОТЫ",
                                    "comment": [
                                      "Получение экспериментальной зависимости от времени ", 
                                      "скорости теплообмена тела с внешней средой"
                                    ]
                                  },
                                  {
                                    "title": "ОБОРУДОВАНИЕ",
                                    "comment": [
                                      "Термометр", "Калориметр", "Штатив с муфтой и лапкой", "Стакан мерный", "Секундомер"
                                      ],
                                      "picture": "Оборудование_мол_физ_лаб_03.json",
                                      "pictureDark": "oborudovanie_mf_03_d.json"
                                  },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "Основной характеристикой любого материального тела, с точки зрения термодинамики, является его внутренняя энергия U. Изменить внутреннюю энергию тела можно двумя путями: совершив работу:",
                                      "##@@±∆U = ±∆A@@",
                                      "или сообщив телу некоторое количество теплоты:", 
                                      "##@@±∆U = ±∆Q.", "", 
                                      "Учитывая, что эти два процесса обычно происходят одновременно, можно записать:", "", 
                                      "##@@±∆U = ±∆Q = ±∆A", "", 
                                      "Это уравнение представляет собой закон сохранения в самом общем виде или первый закон термодинамики."
                                      ]
                                  },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "Физически этот закон означает, что любое изменение внутренней энергии тела сводится к увеличению или уменьшению содержащейся в нем теплоты, а также количеству работы, совершенной телом над внешними телами или работы, совершенной внешними телами над телом. Этим переходам энергии условно приписывают знак плюс или минус, показывая тем самым направление перехода энергии от тела к внешним телам или к телу от внешних тел.", "", 
                                      "При этом внутренняя энергия тела увеличивается или уменьшается. Других причин, вызывающих изменение внутренней энергии тела, не существует.", "", 
                                      "Рассмотрим подробнее воздействие на тело теплоты. Этот процесс осуществляется в виде трех явлений: теплопроводности, конвекции и излучения."
                                    ]
                                  },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "Воздействие излучения ускоряет движение атомов и молекул вещества из которого состоит тело. Внутренняя энергия тела увеличивается - оно нагревается. Особенностью этого способа передачи энергии является отсутствие посредников твердых, жидких или газообразных тел. Излучение передается в том случае, когда никаких посредников нет, то есть в полном вакууме, где совершенно невозможны первые два вида теплопередачи. Примером служит солнечное излучение.", "", 
                                      "В нашем случае в стакане находится горячая вода, а окружающий воздух имеет более низкую температуру - комнатную. Стенки стакана приобретают температуру воды и нагревают близкий к стеклу или металлу слой воздуха. Возникает конвекция - подъем вверх нагретого воздуха.", "", 
                                      "Вода в стакане потеряв теплоту охлаждается."
                                      ]
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Остывание горячей воды в воздухе.", "", 
                                      "Достаньте алюминиевый стакан из калориметра и налейте в него воду комнатной температуры до половины по высоте.", "",  
                                      "В лапке штатива закрепите термометр, чтобы он опустился в воду, не касаясь дна. Зафиксируйте положение термометра.",
                                      "где V^_1^_- объем стального цилиндра в см^^3^^, а d^_1^_ и l^_1^_ - его диаметр и длина в см.", "", 
                                      "Повторите измерения размеров d^_2^_ и l^_2^_ для цилиндра из алюминия и рассчитайте его объем V^_2^_."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_03_01.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Подождите две минуты, чтобы термометр пришел в тепловое равновесие с водой.", "", 
                                      "Измерьте температуру воды и запишите ее значение.", "", 
                                      "На рисунке эта температура представлена в виде горизонтальной линии, ниже которой температура остывающей воды опуститься не может."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_03_02.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_03_03.json",
                                      "pictureDark": "mf_03_03_d.json"
                                    },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Вылейте прохладную воду и налейте примерно такое же количество кипятка. Подождите две минуты и в таблицу запишите температуру Т^_о^_."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_03_04.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Таблица 1"
                                      ],
                                      "table": [
                                        "1|t, мин|0|2|4|6|8|10|12|14",
                                        "2|T, °C|T^_0^_=|||||||"
                                      ]
                                  },  
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Одновременно включите секундомер и, отсчитав 2 минуты, измерьте температуру.", "", 
                                      "Продолжая измерение остывающей воды в соответствии с данными температуры из таблицы 1, дождитесь, когда температура снизится примерно до 40 °C и остановите наблюдение, убедившись, что в дальнейшем температура будет продолжать уменьшаться, но очень медленно.", "", 
                                      "В пределе вода остынет только до комнатной температуры.", "", 
                                      "Полученные данные занесите во вторую строку таблицы 1."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_03_05.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Постройте график зависимости температуры Т от времени t (см. рисунок) по экспериментальным данным из таблицы 1.", "", 
                                      "График свидетельствует о том, что температура воды в стакане сначала быстро уменьшается, затем процесс замедляется.", "", 
                                      "Теоретически температура уменьшается по экспоненциальному закону."
                                      ]
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Таблица 2"
                                      ],
                                      "table": [
                                        "1|t, мин|0|4|8|12|16|20|24|28|32|36",
                                        "2|T, °C|T^_0^_=|||||||||"
                                      ]
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Остывание горячей воды в калориметре.", "", 
                                      "Вылив остывшую воду, поместите стакан в калориметр и налейте в него то же количество кипятка.", "",
                                      "Опустите в воду термометр (не касаясь дна), и закройте крышку.", "",
                                      "Повторите измерения, но теперь выполняйте отсчеты температуры через вдвое большие интервалы времени - через четыре минуты (см. таблица 2), так как остывание будет происходить намного медленнее.", "",
                                      "Причина замедления процесса- резкое уменьшение теплопроводности внешнего пространства- на пути тепла добавился корпус калориметра, затрудняющий теплопроводность."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_03_07.json"
                                  },
                                  {
                                    "title": "ЗАДАНИЕ",
                                    "comment": [
                                      "Таблица 3."
                                    ],
                                      "table": [
                                        "Интервал времени|Стакан (Скорость остывания, °C/мин)|Калориметр (Скорость остывания, °C/мин)",
                                        "4-6 мин||",
                                        "8-12 мин||",
                                        "10-12 мин||",
                                        "20-24 мин||"
                                      ]
                                  }, 
                                  {
                                    "title": "ЗАДАНИЕ",
                                    "comment": [
                                      "Постройте оба графика в координатных осях, приведенных на рисунке, на котором изображены теоретические кривые процесса остывания.", "", 
                                      "Рассчитайте скорость остывания воды в свободном пространстве- в стакане и в калориметре, пользуясь данными таблиц 1 и 2.", "",
                                      "Результаты расчетов поместите в таблицу 3."
                                      ]
                                  }
                              ]
                      },
                      {
                        "labId":"lab04",
                        "labName": "Измерение удельной теплоемкости вещества",
                        "labPictureFolder": "01-physics/02-molecule_termodyn/lab04/",
                            "labPages": [
                                {
                                    "title": "ЛАБОРАТОРНАЯ РАБОТА N4 ",
                                    "comment": [
                                      "Измерение удельной теплоемкости вещества"
                                    ]
                                  },
                                  {
                                    "title": "ЦЕЛЬ РАБОТЫ",
                                    "comment": [
                                      "Экспериментальное определение теплоемкости",
                                      "тел при теплообмене"
                                    ]
                                  },
                                  {
                                    "title": "ОБОРУДОВАНИЕ",
                                    "comment": [
                                      "Термометр", "Калориметр", "Штатив с муфтой и лапкой", 
                                      "Cтакан мерный", "Емкость с водой", "Металлические цилиндры", 
                                      "Весы", "Нить", "Экстрактор"
                                      ],
                                      "picture": "Оборудование_мол_физ_лаб_04.json",
                                      "pictureDark": "oborudovanie_mf_04_d.json"
                                  },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "Количество теплоты обозначают буквой @@Q@@ и измеряют в джоулях (Дж), так же как работу и энергию. Для нагревания на одну и ту же величину нескольких тел, исходная температура которых одинакова, требуется разное количество теплоты.", "", 
                                      "Физическая величина, численно равная количеству теплоты, которое необходимо сообщить телу массой 1 кг для увеличения его температуры на 1 °С, называется удельной теплоёмкостью вещества. Удельная теплоемкость обозначается буквой @@с@@ и измеряется в Дж (кгꞏ°С).", "", 
                                      "Выведем формулу для расчета количества теплоты, необходимого для нагревания тела массой m от начальной температуры @@Т^_нач^_@@ до конечной температуры @@Т^_кон^_@@.", "",
                                      "Для нагревания тела с удельной теплоемкостью @@с@@ и массой @@m@@ на один градус необходимо затратить количество теплоты @@Q = cm@@, а для увеличения его температуры на @@∆Т = Т^_кон^_ - Т^_нач^_@@ - понадобится в @@∆Т@@ раз большее количество теплоты.",
                                      "Отсюда: ", "",
                                      "##@@Q = cm∆T = cm (Т^_кон^_ - Т^_нач^_)@@."
                                      ]
                                  },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "Таким образом, количество теплоты Q, полученное телом при нагревании, прямо пропорционально удельной теплоемкости с вещества, из которого изготовлено тело, его массе m и разности температур Т^_кон^_ - Т^_нач^_, в пределах которой изменилась температура тела.", "",
                                      "Мы не можем измерить текущую температуру металлического цилиндра непосредственно, но мы можем опустить цилиндр в калориметр с водой. Между водой и цилиндром начнется теплообмен.", "", 
                                      "Через некоторое время в калориметре установится тепловое равновесие - температура цилиндра сравняется с температурой воды. ", "", 
                                      "Для того, чтобы узнать температуру цилиндра, достаточно измерить температуру воды.", "", 
                                      "Этот метод измерения температуры тела в условиях теплового баланса между ним и внешней средой будет использован в работе."
                                      ]
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Взвесьте на весах мерный стакан."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_04_01.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Налейте в него немногим более 100 мл воды комнатной температуры из емкости с водой.", "", 
                                      "Взвесьте на весах мерный стакан с водой и определите массу m^_в^_ воды комнатной температуры."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_04_02.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Определите массу одного из цилиндров m^_ц^_, взвесив его на весах."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_04_03.json"
                                  },         
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Цилиндр, подвешенный на нити, опустите в стакан калориметра.", "", 
                                      "Налейте в калориметр такое количество кипятка, при котором груз будет полностью погружен.", "", 
                                      "Подождите несколько минут, чтобы установилось тепловое равновесие."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_04_04.json"
                                  },      
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Опустите в воду термометр и запишите значение температуры воды.", "", 
                                      "Это будет температура Т^_1^_ нагревшегося цилиндра, равная температуре воды."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_04_05.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Быстро извлеките цилиндр, установите на алюминиевый стакан калориметра экстрактор, как показано на рисунке." 
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_04_06.json"
                                  },   
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Слейте горячую воду из стакана калориметра. Перелейте воду комнатной температуры из мерного стакана в стакан калориметра.", 
                                      "Опустите горячий цилиндр в стакан калориметра с водой комнатной температуры. Спустя несколько минут измерьте температуру установившегося теплового равновесия. Это будет температура Т^_2^_.",
                                      "Запишите уравнение для количества теплоты, переданного цилиндром воде.", 
                                      "Действительно, горячий цилиндр, опущенный в калориметр с водой комнатной температуры, начнет остывать, отдавая теплоту воде. В конечном счете цилиндр потеряет некоторое количество теплоты. Эту теплоту назовем отданной:", "", 
                                      "##@@Q^_отд^_ = с^_ц^_ ꞏ m^_ц^_ (T^_2^_-T^_1^_),", "", 
                                      "где с^_ц^_ - теплоемкость цилиндра, а m^_ц^_ его масса.",
                                      "Величина Q^_отд^_ < 0, так как T^_2^_ ˂ T^_1^_.",
                                      "Это неравенство означает, что цилиндр отдал тепло воде, вследствие чего его собственная температура понизилась."
                                      ]
                                  },   
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Для воды комнатной температуры, получившей тепло, запишите уравнение, определяющее полученное в результате теплопередачи количество тепла:", "", 
                                      "##@@Q^_получ^_ = с^_в^_ ꞏ m^_в^_ (Т^_2^_ - Т^_ком^_),",
                                      "где @@с^_в^_@@- теплоемкость воды, а @@m^_в^_@@ его масса.",
                                      "Это количество теплоты цилиндр передал воде, его температура снизилась и сравнялась с температурой, до которой нагрелась вода. Следовательно: @@Q^_отд^_ = Q^_получ^_@@.","", 
                                      "Приравняйте отданное и полученное количества теплоты:",
                                      "##@@c^_ц^_ ꞏ m^_ц^_ (T^_2^_-T^_1^_) = с^_в^_ ꞏ m^_в^_ (T^_2^_ - T^_ком^_).","", 
                                      "Вычислите теплоемкость материала цилиндра:",
                                      "##@@с^_ц^_ = с^_в^_ ꞏ m^_в^_ (T^_2^_-Т^_ком^_) / (m^_ц^_ (T^_2^_ - T^_1^_))", "",
                                      "Повторите эти опыты для двух других цилиндров и вычислите теплоемкости."
                                      ]
                                  },
                                  {
                                    "title": "ЗАДАНИЕ",
                                    "comment": [
                                      "Определите по справочнику из каких материалов изготовлены цилиндры." 
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_04_09.json"
                                  }
                              ]
                      },
                      {
                        "labId":"lab05",
                        "labName": "Исследование изотермического процесса",
                        "labPictureFolder": "01-physics/02-molecule_termodyn/lab05/",
                            "labPages": [
                                {
                                    "title": "ЛАБОРАТОРНАЯ РАБОТА N5",
                                    "comment": [
                                      "Исследование изотермического процесса"
                                    ]
                                  },
                                  {
                                    "title": "ЦЕЛЬ РАБОТЫ",
                                    "comment": [
                                      "Экспериментальное подтверждение закона",
                                      "Бойля-Мариотта"
                                    ]
                                  },
                                  {
                                    "title": "ОБОРУДОВАНИЕ",
                                    "comment": [
                                      "Трубка-резервуар с двумя кранами", "Мерный цилиндр", "Рулетка", 
                                      "Штатив с муфтой и лапкой", "Емкость с водой"
                                      ],
                                      "picture": "Оборудование_мол_физ_лаб_05.json",
                                      "pictureDark": "oborudovanie_mf_05_d.json"
                                  },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "Из уравнения состояния идеального газа следует, что при постоянной температуре и неизменном количестве вещества V в изолированном объеме произведение давления р на его объем V постоянно, то есть:", "", 
                                      "##@@pV = const = A.", "", 
                                      "Этот процесс называют изотермическим.", "",
                                      "В системе координат р-V изотермические процессы изображаются при различных значениях температуры Т семейством гипербол:", "",
                                      "##@@p = A / V,", "",
                                      "которые называются изотермами. ", "",
                                      "Так как коэффициент пропорциональности А в этом соотношении увеличивается с ростом температуры, изотермы, соответствующие более высоким значениям температуры, располагаются на графике выше изотерм, соответствующих меньшим значениям температуры."
                                      ]
                                  },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "Семейство изотерм на плоскости (p-V) Т^_3^_ ˃ Т^_2^_ ˃ Т^_1^_"
                                      ],
                                      "picture": "График_физ_лаб_05_01.json",
                                      "pictureDark": "mf_graph_05_01_d.json"
                                    },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "Уравнение изотермического процесса было экспериментально получено английским физиком Р.Бойлем (1662 г.) и независимо от него французским физиком Э.Мариоттом (1676 г.).", "", 
                                      "Поэтому данное уравнение назвали законом Бойля-Мариотта."
                                      ]
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Газом, который будет использоваться в данной работе, является воздух, находящийся внутри прозрачной эластичной трубки-резервуара. ", "", 
                                      "Так как в ходе работы мы не будем менять температуру воздуха в трубке, то запишем закон Бойля-Мариотта для воздуха:", "",
                                      "##@@p^_1^_V^_1^_ = p^_2^_V^_2^_@@", "",
                                      "В исходном состоянии воздух имеет следующие параметры: давление @@p^_1^_@@ равно атмосферному давлению @@р^_атм^_@@, объем @@V^_1^_@@ равен объему внутренней полости:",
                                      "##@@V = l S@@,", "",
                                      "где, @@l@@ - длина трубки, @@S@@ – площадь её поперечного сечения, температура соответствует температуре окружающего воздуха."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_05_01.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Второе состояние можно получить, если изотермически сжать воздух в трубке.", "", 
                                      "Для этого кран на одном конце трубки необходимо закрыть. Второй кран остается открытым. ", "",
                                      "Конец трубки с открытым краном необходимо погрузить в мерный цилиндр, в который предварительно налили воду комнатной температуры (вода должна быть именно комнатной температуры, в противном случае воздух будет обмениваться теплом с водой, и процесс нельзя будет считать изотермическим)."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_05_02.json"
                                  },   
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Через открытый кран в трубку заходит вода и сжимает воздух внутри до тех пор, пока гидростатическое давление столба жидкости р^_ж^_ не сравняется с внутренним давлением р^_2^_. ",  "", 
                                      "Получается, что во втором состоянии параметры газа внутри трубки будут следующими: давление возрастет до величины давления столба жидкости р^_ж^_, объем уменьшится на величину объема столба жидкости:", "", 
                                      "##@@∆V = h S, ", "", 
                                      "где h – высота столба, S – площадь её поперечного сечения. ", "",
                                      "Температура при этом не изменится. "
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_05_03.json",
                                      "pictureDark": "mf_05_03_d.json"
                                  },      
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Перепишите закон Бойля-Мариотта для воздуха внутри трубки через параметры, которые можно измерить:", "", 
                                      "##@@р^_атм^_ l S = p^_ж^_ (V^_1^_ - ∆V).", "", 
                                      "Давление в жидкости определяется по формуле:","", 
                                      "##@@р^_ж^_ = ρgh ", "", 
                                      "где ρ - плотность воды, g - ускорение свободного падения, h - высота столба.", "", 
                                      "Перепишите равенство, используя ранее измеренные l и h, и сократив его левую и правую части на S:", "", 
                                      "##@@р^_атм^_ l = ρgh (l - h)"
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_05_04.json",
                                      "pictureDark": "mf_05_04_d.json"
                                  },      
                                  {
                                    "title": "ЗАДАНИЕ",
                                    "comment": [
                                      "Параметры @@ρ@@ и @@g@@ возьмите из справочных таблиц, подставьте в это уравнение и вычислите значение атмосферного давления.", "", 
                                      "Сравнив полученное значение @@р^_атм^_@@ с показаниями барометра, убедитесь в равенстве этих параметров, что подтверждает справедливость закона Бойля-Мариотта." 
                                      ]
                                  }
                              ]
                      },
                      {
                        "labId":"lab06",
                        "labName": "Исследование изобарного процесса",
                        "labPictureFolder": "01-physics/02-molecule_termodyn/lab06/",
                            "labPages": [
                                {
                                    "title": "ЛАБОРАТОРНАЯ РАБОТА N6 ",
                                    "comment": [
                                      "Исследование изобарного процесса"
                                    ]
                                  },
                                  {
                                    "title": "ЦЕЛЬ РАБОТЫ",
                                    "comment": [
                                      "Экспериментальное подтверждение закона",
                                      "Гей-Люссака"
                                    ]
                                  },
                                  {
                                    "title": "ОБОРУДОВАНИЕ",
                                    "comment": [
                                      "Трубка-резервуар с двумя кранами", "Калориметр", "Экстрактор", "Рулетка", "Штатив с муфтой и лапкой", "Термометр", "Пинцет"
                                      ],
                                      "picture": "Оборудование_мол_физ_лаб_06.json",
                                      "pictureDark": "oborudovanie_mf_06_d.json"
                                  },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "Изобарным процессом называют квазистатический процесс, протекающий при неизменном давлении р. ", "", 
                                      "Уравнение изобарного процесса для некоторого неизменного количества вещества ν имеет вид:", "", 
                                      "##@@V / T = const",
                                      "##или",
                                      "##@@V = V^_0^_ ꞏ αТ ", "",
                                      "где V^_0^_ – объем газа при температуре 0°C. Коэффициент α равен 1 / 273,15 К^^-1^^.", "",
                                      "Его называют температурным коэффициентом объемного расширения газов.", "",
                                      "На плоскости (V,T) изобарные процессы при разных значениях давления p изображаются семейством прямых линий, которые называются изобарами. "
                                      ]
                                  },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "Семейство изобар на плоскости (V-Т) р^_3^_ ˃ р^_2^_ ˃ р^_1^_"
                                      ],
                                      "picture": "График_физ_лаб_06_01.json",
                                      "pictureDark": "mf_graph_06_01_d.json"
                                  },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "Зависимость объема газа от температуры при неизменном давлении была экспериментально исследована французским физиком Ж. Гей-Люссаком (1862 г.). ", "",
                                      "Поэтому уравнение изобарного процесса называют законом Гей-Люссака. "
                                      ]
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Газом, который будет использоваться в данной работе, будет являться воздух, находящийся внутри прозрачной эластичной трубки-резервуара.", "", 
                                      "Так как в ходе работы мы не будем менять давление воздуха в трубке, то запишем закон Гей-Люссака для воздуха: ", "", 
                                      "##@@V^_1^_ / T^_1^_ = V^_2^_ / T^_2^_", "", 
                                      "Для начала работы закройте один конец трубки и сверните её в несколько витков так, чтобы она полностью помещалась в калориметр (закрытый конец трубки должен быть внизу, открытый конец трубки оставьте свободным, чтобы он немного выходил наверх, как показано на рисунке)", "", 
                                      "Давление воздуха в трубке равно атмосферному давлению."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_06_01.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Налейте внутрь калориметра горячую воду (~70°С) так, чтобы поверхность воды была выше открытого конца трубки на 5 мм. ", 
                                      "Воздух внутри трубки будет нагреваться и расширяться, и из открытого крана начнут выходить пузыри газа.", "", 
                                      "Окончание выхода газа означает, что наступило тепловое равновесие между водой и воздухом внутри трубки. Тогда температура воздуха Т^_1^_ равна температуре воды в калориметре.", "", 
                                      "Измерьте температуру Т^_1^_ с помощью термометра."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_06_02.json"
                                  },   
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Теперь объем воздуха V^_1^_ будет равен объему внутренней полости @@V = l ꞏ S@@, а давление внутри трубки будет по-прежнему равно атмосферному давлению.",
                                      "Таково исходное состояние газа.", "", 
                                      "Второе состояние можно получить, если изобарически сжать воздух в трубке. ", "", 
                                      "Для этого кран на верхнем конце трубки необходимо закрыть. Воду из калориметра нужно вылить, взамен налить холодную воду (~20°С) так, чтобы поверхность воды была выше открытого конца трубки на 5 мм. ", "", 
                                      "Теперь нужно открыть верхний конец трубки с помощью пинцета, удерживая его под водой. Через открытый кран в трубку заходит вода и сжимает воздух внутри до тех пор, пока не установится тепловое равновесие между воздухом внутри и водой в калориметре (для этого потребуется несколько минут)."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_06_03.json"
                                  },      
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Во втором состоянии параметры газа внутри трубки будут следующие: объем уменьшится на величину объема столба попавшей внутрь жидкости", "", 
                                      "##@@∆V = h S",  "", 
                                      "где h – высота столба, S – площадь поперечного сечения трубки.",  "", 
                                      "Температура T^_2^_ станет равной температуре воды внутри калориметра (которую нужно измерить с помощью термометра). ", "", 
                                      "Теперь перепишем закон Гей-Люссака для воздуха внутри трубки: ", "", 
                                      "##@@V^_1^_ : T^_1^_ = (V^_1^_ : ∆V) : T^_2^_", "", 
                                      "Или в параметрах l,S,h, которые нужно измерить и посчитать:", "", 
                                      "##@@(l ꞏ S):T^_1^_ = (l S - h S) : T^_2^_."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_06_04.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Разделив почленно левую и правую стороны уравнения на l и S, а Т^_2^_ перенеся в левую часть получите уравнение, все параметры которого могут быть измерены:", "", 
                                      "##@@Т^_2^_ : Т^_1^_ = 1 - (h - l).",  "", 
                                      "Закройте пинцетом открытый кран трубки-резервуара, удерживая его под водой.", "", 
                                      "Извлеките трубку-резервуар из калориметра." 
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_06_06.json"
                                  },            
                                  {
                                    "title": "ЗАДАНИЕ",
                                    "comment": [
                                      "Измерьте длину трубки резервуара l и высоту столба жидкости h с помощью рулетки. На основе ранее измеренных значений Т^_1^_ и Т^_2^_ с помощью формулы вычислите по отдельности левую и правую части этого равенства.", "", 
                                      "Изобарный процесс можно считать осуществленным, если после подстановки измеренных параметров Т^_1^_, Т^_2^_, h и l равенство подтвердится." 
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_06_07.json",
                                      "pictureDark": "mf_06_07_d.json"
                                  }
                              ]
                      },
                      {
                        "labId":"lab07",
                        "labName": "Исследование изохорного процесса",
                        "labPictureFolder": "01-physics/02-molecule_termodyn/lab07/",
                            "labPages": [
                                {
                                    "title": "ЛАБОРАТОРНАЯ РАБОТА N7",
                                    "comment": [
                                      "Исследование изохорного процесса"
                                    ]
                                  },
                                  {
                                    "title": "ЦЕЛЬ РАБОТЫ",
                                    "comment": [
                                      "Экспериментальное подтверждение закона Шарля"
                                    ]
                                  },
                                  {
                                    "title": "ОБОРУДОВАНИЕ",
                                    "comment": [
                                      "Трубка-резервуар с двумя кранами", "Калориметр", "Экстрактор", "Рулетка", 
                                      "Штатив с муфтой и лапкой", "Мерный стакан", "Стеклянная трубка", 
                                      "Патрубок", "Пинцет", "Термометр"
                                      ],
                                      "picture": "Оборудование_мол_физ_лаб_07.json",
                                      "pictureDark": "oborudovanie_mf_07_d.json"
                                  },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "Изохорный процесс – это процесс квазистатического нагревания или охлаждения газа при постоянном объеме V и при условии, что количество вещества v в сосуде остается неизменным. ", "", 
                                      "Как следует из уравнения состояния идеального газа, при этих условиях давление газа p изменяется прямо пропорционально его абсолютной температуре: ", "", 
                                      "##@@p~T или p ꞏ T = const.", "",
                                      "На плоскости (p, T) изохорные процессы для заданного количества вещества v при различных значениях объема V изображаются семейством прямых линий, которые называются изохорами. "
                                      ]
                                  },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "Семейство изохор на плоскости (р-Т) V^_3^_ ˃ V^_2^_ ˃ V^_1^_"
                                      ],
                                      "picture": "График_физ_лаб_07_01.json",
                                      "pictureDark": "mf_graph_07_01_d.json"
                                  },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "Большим значениям объема соответствуют изохоры с меньшим наклоном по отношению к оси температур.", "", 
                                      "Экспериментально зависимость давления газа от температуры исследовал французский физик Ж. Шарль (1787 г.).", "", 
                                      "Поэтому уравнение изохорного процесса называется законом Шарля."
                                      ]
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Газом, который будет использоваться в данной работе, является воздух, находящийся внутри прозрачной эластичной трубки-резервуара.", "", 
                                      "Так как в ходе работы мы не будем менять объем газа в трубке, то запишем закон Шарля для воздуха внутри: ", "", 
                                      "##@@р^_1^_ / T^_1^_ = р^_2^_ / T^_2^_", "", 
                                      "Для начала работы закройте один конец трубки и сверните её в несколько витков так, чтобы она полностью помещалась в калориметр (закрытый конец трубки должен быть внизу, открытый конец трубки оставьте свободным, чтобы он немного выходил наверх."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_07_01.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Налейте внутрь калориметра горячую воду (~50°С) так, чтобы поверхность воды была выше открытого конца трубки на 5 мм.",  "", 
                                      "Воздух внутри трубки будет нагреваться и расширяться, и из открытого крана начнут выходить пузыри газа.", "", 
                                      "Окончание выхода газа означает то, что наступило тепловое равновесие между водой и воздухом внутри трубки. Тогда температура воздуха Т^_1^_ равна температуре воды в калориметре (которую нужно измерить с помощью термометра)."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_07_02.json"
                                  },   
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Давление внутри р^_1^_ равно атмосферному давлению р^_атм^_ (давлением столба жидкости высотой можно пренебречь).", "", 
                                      "Таково исходное состояние газа.", "", 
                                      "Второе состояние можно получить, если изохорически охладить воздух в трубке.",  "", 
                                      "Для этого кран на верхнем конце трубки необходимо закрыть, удерживая его под водой. Саму трубку извлечь из калориметра и подвесить на лапку штатива."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_07_03.json"
                                  },      
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Газ внутри трубки начнет охлаждаться из-за теплообмена с окружающим воздухом. Этот процесс займет 5–6 минут. Теперь температура воздуха Т^_2^_ внутри трубки равна температуре окружающей среды. ", "", 
                                      "Далее необходимо строго соблюдать следующую последовательность действий: под лапку штатива поставьте мерный стакан, в которой налито 20 мл воды комнатной температуры.",  "", 
                                      "Один из концов трубки соедините с манометрической трубкой через патрубок (манометрическая трубка должна находиться в вертикальном положении). Свободный конец манометрической трубки опустите в колбу с водой.", "",  
                                      "Плавно откройте кран, где соединяются манометрическая трубка и трубка с воздухом."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_07_04.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Так как давление воздуха р^_2^_ внутри меньше атмосферного давления, то в манометрическую трубку начнет втягиваться вода из колбы, пока давление столба воды р^_в^_ и давление воздуха внутри р^_2^_ не скомпенсируют внешнее атмосферное давление р^_атм^_.", "", 
                                      "Получается, что во втором состоянии параметры газа внутри трубки будут следующие:",  "", 
                                      "р^_2^_ = р^_атм^_ - р^_в^_ = р^_атм^_ - ρgh (h = h^_в^_ - высота столба воды, ρ - плотность воды).", "", 
                                      "Температура Т^_2^_ будет равна температуре окружающего воздуха.", "", 
                                      "Перепишите закон Шарля для воздуха внутри трубки:", "", 
                                      '##@@р^_1^_ / T^_1^_ =  р^_2^_ / T^_2^_',
                                      "##@@р^_атм^_ / Т^_1^_ = (р^_атм^_ - ρgh^_в^_) / Т^_2^_", "", 
                                      'Отсюда: ',
                                      "##@@р^_атм^_ = (ρ g h^_в^_ ꞏ Т^_1^_) / (Т^_1^_ - Т^_2^_)" 
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_07_05.json"
                                  },            
                                  {
                                    "title": "ЗАДАНИЕ",
                                    "comment": [
                                      "Измерьте высоту столба жидкости @@h@@ с помощью рулетки. ", "", 
                                      "На основе полученных из эксперимента данных по формуле вычислите @@p^_атм^_@@ и сравните его с показаниями барометра. " , "", 
                                      "При совпадении значений атмосферного давления, полученного в результате эксперимента, и измеренного барометром следует признать выполнение закона Шарля."
                                      ],
                                      "picture":"Порядок_мол_физ_лаб_07_06.json"
                                  }
                              ]
                      },
                      {
                        "labId":"lab08",
                        "labName": "Исследование уравнения состояния идеального газа",
                        "labPictureFolder": "01-physics/02-molecule_termodyn/lab08/",
                            "labPages": [
                                {
                                    "title": "ЛАБОРАТОРНАЯ РАБОТА N8",
                                    "comment": [
                                      "Исследование уравнения состояния", "", 
                                      "идеального газа"
                                    ]
                                  },
                                  {
                                    "title": "ЦЕЛЬ РАБОТЫ",
                                    "comment": [
                                      "Исследование взаимосвязи объемов, давлений", 
                                      "и температур газа в двух его состояниях"
                                    ]
                                  },
                                  {
                                    "title": "ОБОРУДОВАНИЕ",
                                    "comment": [
                                      "Трубка-резервуар с двумя кранами", "Калориметр", "Экстрактор", "Рулетка", "Штатив с муфтой и лапкой", "Термометр", "Пинцет"
                                      ],
                                      "picture": "Оборудование_мол_физ_лаб_08.json",
                                      "pictureDark": "oborudovanie_mf_08_d.json"
                                  },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "Уравнение, устанавливающее связь между давлением, объемом и температурой газа было получено в середине XIX века французским физиком Б. Клапейроном, в форме: ", "", 
                                      "##@@p V = n R T = R T (m / M)", "", 
                                      "Оно было впервые записано Д. И. Менделеевым. Поэтому уравнение состояния газа называется уравнением Клапейрона–Менделеева. ", "",
                                      "В работе экспериментально проверяется утверждение о том, что для неизменного количества газа v в двух его состояниях: ", "", 
                                      "##@@p V / T = const."
                                      ]
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Газом, который будет использоваться в данной работе, является воздух, находящийся внутри прозрачной эластичной трубки-резервуара.", "", 
                                      "Для начала работы закройте один конец трубки и сверните её в несколько витков так, чтобы она полностью помещалась в калориметр (закрытый конец трубки должен быть внизу, открытый конец трубки оставьте свободным, чтобы он немного выходил наверх, как показано на рисунке)."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_08_01.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Налейте внутрь калориметра горячую воду (~60°C) так, чтобы поверхность воды была выше открытого конца трубки на 5 мм.", "", 
                                      "Воздух внутри трубки будет нагреваться и расширяться, и из открытого крана начнут выходить пузыри газа. Окончание выхода газа означает, что наступило тепловое равновесие между водой и воздухом внутри трубки.", "", 
                                      "Тогда температура воздуха Т^_1^_ равна температуре воды в калориметре (которую нужно измерить с помощью термометра)."
                                      ],
                                      "picture":"Порядок_мол_физ_лаб_08_02.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Давление внутри р^_1^_ равно атмосферному давлению р^_атм^_ (давлением столба жидкости высотой 5 мм можно пренебречь), объем воздуха внутри V^_1^_ равен объему внутренней полости:", "", 
                                      "##@@V^_1^_ = l S ", "", 
                                      "где l – длина трубки, S – площадь её поперечного сечения.", "", 
                                      "Это есть исходное состояние газа.", "", 
                                      "С помощью пинцета закройте верхний кран, удерживая его под водой, установите экстрактор на алюминиевый стакан калориметра."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_08_03.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Слейте воду из калориметра. Налейте в калориметр воду комнатной температуры таким образом, чтобы ее поверхность была на 5 мм выше открытого конца трубки.", "", 
                                      "Откройте верхний кран. Из-за охлаждения объем воздуха в трубке уменьшится и в нее начнет поступать вода.", "", 
                                      "После того, как газ внутри придет в тепловое равновесие с водой в калориметре, поступление воды в трубку прекратится."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_08_04.json"
                                  },   
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Теперь конец трубки, в котором есть столб воды, закрепите вертикально в лапке штатива, как показано на рисунке.", "", 
                                      "Следите за тем, чтобы трубка нигде не пережималась.", "", 
                                      "Откройте верхний кран. Воздух внутри трубки начнет сжиматься под действием атмосферного давления и давления столба жидкости."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_08_05.json",
                                      "pictureDark": "mf_08_05_d.json"
                                  },      
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Когда наступит равновесие параметры второго состояния газа будут следующими:", "", 
                                      "Давление воздуха: р^_2^_ = р^_атм^_ + ρgh, (h - высота столба воды, ρ - плотность воды);", "", 
                                      "Температура Т^_2^_ равна температуре окружающего воздуха",  "", 
                                      "Объем @@V^_2^_ = V^_1^_ - h S - L S@@, где L - занятое воздухом расстояние от верхнего конца трубки до верхнего конца столба жидкости."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_08_06.json",
                                      "pictureDark": "mf_08_06_d.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Теперь с помощью уравнения состояния можно записать:", "", 
                                      "##@@p^_1^_ ꞏ V^_1^_ / T^_1^_ = p^_2^_ ꞏ V^_2^_ / T^_2^_", "",  
                                      "или", "", 
                                      "##@@р^_атм^_ ꞏ (l ꞏ S) / T^_1^_ = [(р^_атм^_ + ρgh) ꞏ (l S - h S - L S)] / T^_2^_", "", 
                                      "откуда после упрощения:", 
                                      "##@@р^_атм^_ = ρgh ꞏ a / (1 - a),",
                                      "##@@a = T^_1^_ / T^_2^_ ꞏ [1 - (h + l) / l].", "", 
                                      "Измерьте высоту столба жидкости h и высоту L столба воздуха с помощью рулетки. "
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_08_07.json",
                                      "pictureDark": "mf_08_07_d.json"
                                  },
                                  {
                                    "title": "ЗАДАНИЕ",
                                    "comment": [
                                      "Таблица для занесения результатов."
                                      ],
                                      "table": [
                                        "Параметр|T^_1^_, °C|T^_2^_, °C|h, м|l, м|L, м",
                                        "Значение|||||"
                                      ]
                                  },
                                  {
                                    "title": "ЗАДАНИЕ",
                                    "comment": [
                                      "На основе данных, полученных из эксперимента и занесенных в таблицу, вычислите по формуле @@р^_атм^_@@ и сравните полученное значение с показаниями барометра.",  "", 
                                      "При совпадении результатов измерений и расчетов можно считать, что уравнение состояния идеального газа верно."
                                      ]
                                  }
                              ]
                      },
                      {
                        "labId":"lab09",
                        "labName": "Определение относительной влажности воздуха с помощью термометра и психрометрической таблицы",
                        "labPictureFolder": "01-physics/02-molecule_termodyn/lab09/",
                            "labPages": [
                                {
                                    "title": "ЛАБОРАТОРНАЯ РАБОТА N9",
                                    "comment": [
                                      "Определение относительной влажности воздуха с помощью", "", 
                                      "термометра и психрометрической таблицы"
                                    ]
                                  },
                                  {
                                    "title": "ЦЕЛЬ РАБОТЫ",
                                    "comment": [
                                      "Определить относительную влажность воздуха с помощью ",
                                      "термометра и психрометрической таблицы"
                                    ]
                                  },
                                  {
                                    "title": "ОБОРУДОВАНИЕ",
                                    "comment": [
                                      "Термометр", "Мерный стакан", "Штатив с муфтой и лапкой", "Марля", "Емкость для воды", "Психрометрическая таблица"
                                      ],
                                      "picture": "Оборудование_мол_физ_лаб_09.json",
                                      "pictureDark": "mf_oborudovanie_09_d.json"
                                  },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "Для определения влажности воздуха используются приборы, которые называются психрометрами и гигрометрами. Психрометр Августа состоит из двух термометров - сухого и влажного.", "",
                                      "Влажный термометр показывает температуру ниже, чем сухой, так как его резервуар обмотан тканью, смоченной в воде, которая, испаряясь, охлаждает его. Интенсивность испарения зависит от относительной влажности воздуха. По показаниям сухого и влажного термометров находят относительную влажность воздуха по психрометрическим таблицам.", "", 
                                      "Для проверки приборов для измерения влажности применяют специальные установки-гигростаты."
                                      ]
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Закрепите термометр в лапке штатива и подождите несколько минут, чтобы термометр пришел в тепловое равновесие с окружающим воздухом.", "", 
                                      "Снимите показание термометра в сухом состоянии Т^_сух^_ и занесите его в таблицу."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_09_01.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      ],
                                      "table": [
                                        "Температура сухого термометра Т^_сух^_, °С|Температура мокрого термометра Т^_мок^_, °С|Относительная влажность φ^_отн^_, %",
                                        "++||"
                                      ]
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "В мерный стакан налейте 30-40 мл воды.",  "",
                                      "Небольшой кусочек марли обмакните в мерном стакане с водой.", "",
                                      "После этого нижний конец термометра обмотайте мокрой марлей.", "",
                                      "Подождите около 10 минут, чтобы показания мокрого термометра перестали колебаться и запишите показания @@Т^_мок^_@@ в таблицу.", "",
                                      "Теперь, зная Т^_мок^_ и Т^_сух^_, с помощью психометрической таблицы определите относительную влажность @@φ^_отн^_@@.",  "",
                                      "Занесите в таблицу значение относительной влажности @@φ^_отн^_@@, %."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_09_02.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      ],
                                      "picture": "Психрометрическая_таблица_09_02.json"
                                  }
                              ]
                      },
                      {
                        "labId":"lab10",
                        "labName": "Измерение скорости таяния льда в зависимости от теплопроводности среды",
                        "labPictureFolder": "01-physics/02-molecule_termodyn/lab10/",
                            "labPages": [
                                {
                                    "title": "ЛАБОРАТОРНАЯ РАБОТА N10",
                                    "comment": [
                                      "Измерение скорости таяния льда в",
                                      "зависимости от теплопроводности среды"
                                    ]
                                  },
                                  {
                                    "title": "ЦЕЛЬ РАБОТЫ",
                                    "comment": [
                                      "Наблюдение таяния двух одинаковых объемов льда,", 
                                      "помещенных на одинаковые по геометрии поверхности,",
                                      "выполненные из материалов с существенно разными",
                                      "значениями теплопроводности"
                                    ]
                                  },
                                  {
                                    "title": "ОБОРУДОВАНИЕ",
                                    "comment": [
                                      "Стакан мерный пластиковый", "Стакан калориметра алюминиевый", "Кювета для замораживания воды", "Кубики льда", "Чашка Петри с крышкой", "Секундомер"
                                      ],
                                      "picture": "Оборудование_мол_физ_лаб_10.json",
                                      "pictureDark": "mf_10_01_d.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Стаканы вверх донышками устанавливаются в верхнюю и нижнюю части чашки Петри для того, чтобы вода, образующаяся при таянии льда, не попала на поверхность стола.", "",
                                      "Одинаковые по размерам кубики или цилиндры льда одновременно помещают на донышки стаканов и включают секундомер."
                                      ],
                                      "picture":"Порядок_мол_физ_лаб_10_01.json",
                                      "pictureDark": "mf_10_01_d.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Убедитесь в том, что почти сразу же начинается таяние льда на алюминиевом стакане, тогда как лед, помещенный на пластиковый стакан, не тает совершенно. Это объясняется тем, что тепловодность алюминия более, чем в тысячу раз выше теплопроводности пластика.", "", 
                                      "Измерьте время, за которое лед, находящийся на алюминиевом стакане, полностью растает, а лед на пластиковом стакане только начнет таять.", "", 
                                      "Перенесите лед с пластикового стакана на алюминиевый и дождитесь, когда он растает. Измерьте время таяния."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_10_02.json",
                                      "pictureDark": "mf_10_02_d.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Повторите работу, перевернув стаканы и поместив лед на их донышки внутри стакана. ","", 
                                      "Убедитесь, что результат измерения мало изменится."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_10_03.json",
                                      "pictureDark": "mf_10_03_d.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Теоретически теплота, плавящая лед, находящийся на алюминиевом стакане, определяется по формуле: ",
                                      "##@@Q^_а^_ = (λ^_а^_ꞏ А^_а^_ꞏ t^_а^_ꞏ ΔT) / l^_а^_, ",
                                      "а на пластиковом стакане – по формуле: @@Q^_п^_ = (λ^_п^_ꞏ А^_п^_ꞏ t^_п^_ꞏ ΔT) / l^_п^_@@.",  "", 
                                      "В формулах λ^_а^_ и λ^_п^_ - коэффициенты теплопроводности алюминия и пластика соотносятся как 1000 к 1, то есть поступления тепла воздуха комнатной температуры (20°С) к участку донышка, охлажденного льдом почти до 0°С, происходит почти в 1000 раз легче при прочих равных условиях:", "", 
                                      "Аа = Ап (поперечное сечение проводника тепла) и lа = lп (длина проводника тепла). ", "", 
                                      "Из анализа формул следует, что время таяния льда, помещенного на алюминиевое донышко, будет примерно в 1000 раз меньше по сравнению с временем таяния льда на пластиковом донышке."
                                      ]
                                  }
                              ]
                      },
                      {
                        "labId":"lab11",
                        "labName": "Исследование уравнения теплового баланса",
                        "labPictureFolder": "01-physics/02-molecule_termodyn/lab11/",
                            "labPages": [
                                {
                                    "title": "ЛАБОРАТОРНАЯ РАБОТА N11",
                                    "comment": [
                                      "Исследование уравнения теплового баланса "
                                    ]
                                  },
                                  {
                                    "title": "ЦЕЛЬ РАБОТЫ",
                                    "comment": [
                                      "Экспериментальная проверка равенства количества теплоты, ", 
                                      "полученного и отданного при смешивании воды разной температуры "
                                    ]
                                  },
                                  {
                                    "title": "ОБОРУДОВАНИЕ",
                                    "comment": [
                                      "Термометр", "Калориметр", "Экстрактор", "Мерный цилиндр", "Весы", "Емкость для воды"
                                      ],
                                      "picture": "oborudovanie_mf_11_d.json"
                                  },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "Количество теплоты обозначают буквой Q и измеряют, как работу и энергию, в джоулях (Дж). Внутренняя энергия тела может изменяться не только в результате совершаемой работы, но и вследствие теплообмена. При тепловом контакте тел внутренняя энергия одного из них может увеличиваться, а другого – уменьшаться. В этом случае говорят о тепловом потоке от одного тела к другому. Количеством теплоты Q, полученным телом, называют изменение внутренней энергии тела в результате теплообмена.", "",
                                      "Выведем формулу для расчета количества теплоты, необходимого для нагревания некоторого вещества массой m на разность температур:", "", 
                                      "##@@∆Т = Т^_конеч^_ - Т^_нач^_.", "", 
                                      "где Т^_нач^_-начальная температура вещества, Т^_конеч^_-его конечная температура."
                                      ]
                                  },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "Чтобы нагреть некоторое вещество массой 1 кг на 1°С, необходимо затратить количество теплоты, равное удельной теплоемкости данного вещества, то есть в данном случае: ", 
                                      "##@@Q = с", "", 
                                      "Если масса нагреваемого вещества в m раз больше, то и необходимое количество теплоты также в m раз больше: ",
                                      "##@@Q = cm", "", 
                                      "Аналогично, если разность температур вещества не 1°C, а в ∆Т = Т^_конеч^_ - Т^_нач^_ раз больше, то и теплоты понадобится ∆Т = Т^_конеч^_ - Т^_нач^_ раз больше. "
                                      ]
                                  },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "Количество теплоты, получаемое веществом при нагревании, прямо пропорционально удельной теплоемкости вещества, его массе и разности температур, то есть:","", 
                                      "##@@Q = cm∆Т или Q = cm (Т^_конеч^_ - Т^_нач^_)", "", 
                                      "Для определения количеств теплоты Q, которыми обменяются тела при теплообмене, необходимо знать их массу, начальную и конечную температуру.", "", 
                                      "В условиях данного опыта теплообмен будет проходить между тремя телами: стаканом калориметра, налитой в него водой комнатной температуры и горячей водой, добавленной из внешнего сосуда. "
                                      ]
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Для начала измерьте массу @@m^_ст^_@@ стакана калориметра с помощью весов. ",  "", 
                                      "Определите удельную теплоемкость стакана @@c^_ст^_@@ по таблице считая, что он сделан из алюминия."
                                      ],
                                      "picture":"Порядок_мол_физ_лаб_11_01.json",
                                      "pictureDark": "mf_11_01.json" 
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Налейте в мерный цилиндр 75 мл воды комнатной температуры. ",  "", 
                                      "Зная плотность воды ρ, можно вычислить её массу ", 
                                      "##@@m^_хол^_ = ρ ꞏ V^_хол^_"
                                      ],
                                      "picture":"Порядок_мол_физ_лаб_11_02.json" 
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Перелейте воду в калориметр, и через несколько минут измерьте её температуру Т^_1^_ (это также будет являться начальной температурой для стакана калориметра)."
                                      ],
                                      "picture":"Порядок_мол_физ_лаб_11_03.json" 
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Залейте в мерный цилиндр 75 мл горячей воды и измерьте ее температуру Т^_гор^_."
                                      ],
                                      "picture":"Порядок_мол_физ_лаб_11_04.json" 
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Перелейте воду в калориметр, и через несколько минут измерьте её температуру Т^_2^_ смеси (это также будет являться начальной температурой для стакана калориметра)."
                                      ],
                                      "picture":"Порядок_мол_физ_лаб_11_05.json" 
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Зная плотность воды ρ, можно вычислить её массу: ",
                                      "##@@m^_гор^_ = ρꞏV^_гор^_.", "", 
                                      "Запишите уравнения для количеств теплоты трех тел:",
                                      "@@Q^_1^_ = c^_ст^_ꞏm^_ст^_ (T^_2^_ - T^_1^_)@@ – теплота, которую получил алюминиевый стакан, ",
                                      "@@Q^_2^_ = c^_в^_ꞏm^_хол^_ (T^_2^_ - T^_1^_)@@ -теплота, которую получила холодная вода, ",
                                      "@@Q^_3^_ = c^_в^_ꞏm^_гор^_ (T^_2^_ - T^_гор^_)@@ – теплота, которую отдала горячая вода. ", "", 
                                      "Величина Q^_3^_ ˂ 0, что означает, что горячая вода отдала тепло. ", 
                                      "Тогда можно написать уравнение теплового баланса: ",
                                      "##@@Q^_1^_ + Q^_2^_ = Q^_3^_,",
                                      "##@@(T^_2^_ - T^_1^_) ꞏ (c^_ст^_ꞏm^_ст^_ + с^_в^_ꞏρꞏV^_хол^_) = с^_в^_ꞏρ (V - V^_хол^_) T^_2^_ - T^_гор^_. ", "", 
                                      "Чтобы проверить верность этого уравнения достаточно выразить удельную теплоемкость алюминия @@с^_ст^_@@ и сравнить с табличным значением. ", 
                                      "Если они одинаковы, уравнение теплового баланса можно считать справедливым:", 
                                      "##@@с^_ст^_ = с^_в^_ꞏρ ((V - V^_хол^_ ) / m^_ст^_  ꞏ (Т^_2^_ - Т^_гор^_) / ((Т^_2^_-Т^_1^_ )) - V^_хол^_ / m^_ст^_ )"
                                      ]
                                  }
                                            
                              ]
                      },
                      {
                        "labId":"lab12",
                        "labName": "Наблюдение роста кристаллов ",
                        "labPictureFolder": "01-physics/02-molecule_termodyn/lab12/",
                            "labPages": [
                                {
                                    "title": "ЛАБОРАТОРНАЯ РАБОТА N12",
                                    "comment": [
                                      "Наблюдение роста кристаллов "
                                    ]
                                  },
                                  {
                                    "title": "ЦЕЛЬ РАБОТЫ",
                                    "comment": [
                                      "Наблюдение растворения (плавления) кристаллического", 
                                      "вещества в воде и наблюдение его кристаллизации при",
                                      "медленном испарении воды при комнатной температуре"
                                    ]
                                  },
                                  {
                                    "title": "ОБОРУДОВАНИЕ",
                                    "comment": [
                                      "Чашка Петри", "Натриевая соль", "Калориметр", "Экстрактор", "Термометр", 
                                      "Стеклянная палочка", "Сахарный песок"
                                      ],
                                      "picture": "Оборудование_мол_физ_лаб_12.json",
                                      "pictureDark": "oborudovanie_mf_12_d.json"
                                  },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "Кристаллизация (затвердевание) вещества это переход вещества из жидкого состояния в твердое. Кристаллизация происходит при определенной температуре, совпадающей с температурой плавления.", "",
                                      "Кристаллизация начинается при достижении некоторого предельного условия, например, переохлаждения жидкости или пересыщения пара, когда практически мгновенно возникает множество мелких кристалликов центров кристаллизации. Кристаллизация всегда сопровождается выделением энергии, то есть вещество теряет теплоту. При этом его внутренняя энергия уменьшается.", "", 
                                      "Процесс, обратный кристаллизации, называется плавлением. При этом твердое вещество переходит в жидкую форму - плавится.", "", 
                                      "Для наблюдения процессов плавления и кристаллизации используется натриевая соль (вещество розового цвета) и песок сахарный (вещество белого цвета)."
                                      ]
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Залейте в стакан калориметра воду (2/3 высоты стакана) температурой 70°С и закройте крышку.",
                                      "Измерьте температуру воды термометром."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_12_01.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "В основание чашки Петри засыпьте небольшое количество натриевой соли.","", 
                                      "На стакан калориметра установите экстрактор."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_12_02.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Залейте соль в чашке Петри небольшим объемом горячей воды и как можно тщательнее перемешайте раствор палочкой стеклянной до растворения всех кристаллов натриевой соли. Осторожно перелейте полученный раствор (расплав) в крышку чашки Петри.",  "", 
                                      "Согласуйте с учителем место, где приготовленный раствор (расплав) может быть размещен до полного удаления воды за счет высыхания вещества и кристаллизации исследуемого вещества.", "", 
                                      "Наблюдайте через 5-6 дней образовавшиеся кристаллы на том месте, где ранее была растворена соль в воде."
                                      ],
                                      "picture":"Порядок_мол_физ_лаб_12_03.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Дождитесь, когда вся жидкость затвердеет, раздробите соль и пересыпьте ее обратно в пакетик.",  "", 
                                      "Чашку Петри тщательно промойте под проточной водой.", "", 
                                      "Проделайте аналогичные операции с сахарным песком.", "", 
                                      "В результате растворения (расплава) сахарного песка в воде и его кристаллизации Вы получите выращенные кристаллы сахара."
                                      ],
                                      "picture":"Порядок_мол_физ_лаб_12_04.json"
                                  }
                                            
                              ]
                      },
                      {
                        "labId":"lab13",
                        "labName": "Наблюдение плавления и кристаллизации вещества при нагревании и охлаждении",
                        "labPictureFolder": "01-physics/02-molecule_termodyn/lab13/",
                            "labPages": [
                                {
                                    "title": "ЛАБОРАТОРНАЯ РАБОТА N13",
                                    "comment": [
                                      "Наблюдение плавления и кристаллизации вещества",
                                      "при нагревании и охлаждении"
                                    ]
                                  },
                                  {
                                    "title": "ЦЕЛЬ РАБОТЫ",
                                    "comment": [
                                      "Наблюдение процесса перехода кристаллического вещества", 
                                      "из твердого состояния в жидкое и обратно"
                                    ]
                                  },
                                  {
                                    "title": "ОБОРУДОВАНИЕ",
                                    "comment": [
                                      "Пробирка с парафином", "Калориметр", "Экстрактор", "Термометр", 
                                      "Палочка стеклянная", "Мерный стакан", "Пинцет"
                                      ],
                                      "picture": "Оборудование_мол_физ_лаб_13.json",
                                      "pictureDark": "oborudovanie_mf_13_d.json"
                                  },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "Плавление- это переход вещества из твердого состояния в жидкое. Этот процесс происходит при превышении определенной температуры, постоянной для каждого вещества.", "",
                                      "Кристаллизация (затвердевание) - это обратный процесс перехода вещества из жидкого состояния в твердое.", "", 
                                      "Кристаллизация происходит при снижении температуры ниже постоянной температуры, совпадающей с температурой плавления. Таким образом, любое кристаллическое вещество находится в твердом состоянии, если его температура ниже температуры плавления, и переходит в жидкое состояние при температуре выше температуры плавления.", "", 
                                      "Для наблюдения процессов плавления и кристаллизации лабораторной работе используется парафин (вещество зеленого цвета, помещенное в пробирку)."
                                      ]
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Налейте в стакан калориметра воду температурой немного выше 70°С.",
                                      "Измерьте ее температуру термометром.",
                                      "Установите экстрактор в стакан калориметра."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_13_01.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Опустите в воду пробирку с парафином. Дождитесь, когда парафин полностью расплавится. Этот процесс займет около 3 минут.","", 
                                      "Визуально убедитесь, использую стеклянную палочку, что вещество в пробирке полностью расплавилось-перешло в жидкую фазу."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_13_02.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "С помощью бумажной салфетки снимите остатки воды с поверхности термометра и опустите его в пробирку с парафином.",  "", 
                                      "Измерьте температуру расплава.", 
                                      "Температура расплава должна быть около 70°С."
                                      ],
                                      "picture":"Порядок_мол_физ_лаб_13_03.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Используя экстрактор, извлеките стакан калориметра вместе с пробиркой из калориметра.",  "", 
                                      "Перенесите пробирку с расплавленным парафином и термометром в мерный стакан.",
                                      "Установите в калориметр мерный стакан.",
                                      "Убедитесь, что парафин все еще расплавлен- термометр легко перемещается в его объеме.",
                                      "Наблюдайте остывание и уменьшение температуры парафина. Дождитесь температуры кристаллизации, при которой термометр будет зафиксирован в объеме парафина.",
                                      "Это произойдет при температуре около 55°C. Проверьте это стеклянной палочкой."
                                      ],
                                      "picture":"Порядок_мол_физ_лаб_13_04.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Извлеките пробирку с термометром из мерного сткана и убедитесь в кристаллизации парафина.", 
                                      "Верните пробирку с отвердевшим парафином и термометром в калориметр с алюминиевым стаканом.", "", 
                                      "Залейте в калориметр воду температурой немного выше 70°С."
                                      ],
                                      "picture":"Порядок_мол_физ_лаб_13_05.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Убедитесь с помощью стеклянной палочки в том, что парафин вновь расплавился и извлеките из него термометр.", "", 
                                      "Аккуратно снимите остатки застывшего парафина с поверхности термометра.",
                                      "Слейте остывшую воду из калориметра."
                                      ],
                                      "picture":"Порядок_мол_физ_лаб_13_06.json"
                                  }
                                            
                              ]
                      },
                      {
                        "labId":"lab14",
                        "labName": "Исследование эффективности работы нагревателя ",
                        "labPictureFolder": "01-physics/02-molecule_termodyn/lab14/",
                            "labPages": [
                                {
                                    "title": "ЛАБОРАТОРНАЯ РАБОТА N14",
                                    "comment": [
                                      "Исследование эффективности работы нагревателя "
                                    ]
                                  },
                                  {
                                    "title": "ЦЕЛЬ РАБОТЫ",
                                    "comment": [
                                      "Экспериментально определить КПД спиртовки"
                                    ]
                                  },
                                  {
                                    "title": "ОБОРУДОВАНИЕ",
                                    "comment": [
                                      "Термометр", "Мерный цилиндр", "Пробирка", "Штатив с муфтой и лапкой", 
                                      "Спиртовка", "Весы", "Емкость для воды"
                                      ],
                                      "picture": "Оборудование_мол_физ_лаб_14.json",
                                      "pictureDark": "oborudovanie_mf_14_d.json"
                                  },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "Коэффициентом полезного действия η является отношение теплоты Q^_в^_, пошедшей на нагревание воды к количеству теплоты Q^_с^_, выделенной спиртовкой при сгорании спирта, выраженное в процентах:", "",
                                      "##@@η = (Q^_в^_ : Q^_с^_) ꞏ100 %", "", 
                                      "В данной лабораторной работе исследуемым нагревателем является спиртовка, а нагреваемым телом служит вода в пробирке. Предлагается определить количество теплоты, которое выделилось при сжигании спирта, и количество теплоты, затраченное на нагревание воды в пробирке. "
                                      ]
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Залейте в мерный цилиндр 10 мл воды комнатной температуры Т^_0^_."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_14_01.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Пробирку зафиксируйте в лапке штатива. ","", 
                                      "Перелейте воду в пробирку."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_14_02.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "С помощью весов измерьте массу спиртовки М^_0^_, в которую уже налит спирт."
                                      ],
                                      "picture":"mf_14_03.json"
                                  },
                                  {
                                    "title": "ВНИМАНИЕ",
                                    "comment": [
                                      "При подогреве содержимого в пробирке опасайтесь закипания и выброса содержимого из пробирки! Для этого:",
                                      "- отрегулируйте фитиль спиртовки так, чтобы он выступал на 1-2 мм из пробки спиртовки и пламя спиртовки не касалось самой пробирки;", 
                                      "- направьте пробирку в сторону от себя, чтобы не задеть возможным выбросом окружающих и какие-либо предметы;", 
                                      "- между пробиркой и спиртовкой оставьте место для крышки спиртовки, чтобы погасить ею пламя и закрыть спиртовку;", 
                                      "- гасите спиртовку крышкой быстро, не задевая пламя спиртовки и саму спиртовку (предварительно, не зажигая спиртовку, потренируйтесь снимать и одевать на нее крышку);",
                                      "-спиртовку лучше зажечь в стороне от пробирки, а затем пододвинуть ее под пробирку.", "", 
                                      "Признаки кипения: появление маленьких пузырьков в пробирке.", "" , 
                                      "##@@При ожогах немедленно обратитесь за помощью к преподавателю!"
                                      ]
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Поместите спиртовку под пробирку и зажгите фитиль."
                                      ],
                                      "picture":"Порядок_мол_физ_лаб_14_04.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Как только появятся признаки кипения воды- погасите спиртовку.",
                                      "Измерьте температуру воды Т^_к^_ сразу после погашения спиртовки.", "", 
                                      "Рассчитайте количество теплоты Q^_в^_, которое получила вода:",
                                      "##@@Q^_в^_ = с^_в^_ꞏm^_в^_ꞏ(Т^_к^_ - Т^_0^_)."
                                      ],
                                      "picture":"Порядок_мол_физ_лаб_14_05.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Измерьте на весах массу спиртовки М.",
                                      "Определите массу спирта, затраченного на нагревание воды:",
                                      "##@@М^_с^_ = М^_0^_ - М.", "", 
                                      "Рассчитайте количество теплоты, выделенное сгоревшим спиртом:", 
                                      "##@@Q^_c^_ = qꞏM^_c^_,",
                                      "где q = 30.6ꞏ1000000 Дж/кг - удельная теплота сгорания спирта.", "", 
                                      "По полученным данным определите КПД спиртовки в процентах по формуле:",
                                      "##@@n = (Q^_в^_/Q^_с^_) ꞏ 100% = ((с^_в^_ꞏm^_в^_ꞏ(Т^_к^_ - Е^_0^_)) / q) ꞏ М^_с^_ ꞏ 100%.",
                                      "При расчетах не забудьте перевести массу М^_с^_ из грамм в килограммы."
                                      ],
                                      "picture":"Порядок_мол_физ_лаб_14_06.json",
                                      "pictureDark": "mf_14_06_d.json"
                                  }
                              ]
                      },
                      {
                        "labId":"lab15",
                        "labName": "Наблюдение за переходом твердого аморфного тела в жидкое состояние и отвердеванием аморфного вещества",
                        "labPictureFolder": "01-physics/02-molecule_termodyn/lab15/",
                            "labPages": [
                                {
                                    "title": "ЛАБОРАТОРНАЯ РАБОТА N15",
                                    "comment": [
                                      "Наблюдение за переходом твердого аморфного тела в жидкое",
                                      "состояние и отвердеванием аморфного вещества"
                                    ]
                                  },
                                  {
                                    "title": "ЦЕЛЬ РАБОТЫ",
                                    "comment": [
                                      "Подтвердить утверждение о том, что у аморфных веществ отсутствует ", 
                                      "резкая граница перехода из жидкого состояния в твердое"
                                    ]
                                  },
                                  {
                                    "title": "ОБОРУДОВАНИЕ",
                                    "comment": [
                                      "Пробирка с канифолью", "Калориметр", "Экстрактор", "Термометр", 
                                      "Палочка стеклянная", "Пинцет", "Мерный стакан"
                                      ],
                                      "picture": "Оборудование_мол_физ_лаб_15.json",
                                      "pictureDark": "oborudovanie_mf_15_d.json"
                                  },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "Аморфные вещества (в отличие от кристаллических) не имеют определенной температуры плавления — они не плавятся, а размягчаются.",
                                      "При нагревании кусок стекла, например, сначала становится из твердого мягким, его легко можно гнуть или растягивать. При более высокой температуре кусок начинает менять свою форму под действием собственной тяжести. По мере нагревания густая вязкая масса принимает форму того сосуда, в котором лежит. Эта масса сначала густая, как мед, затем — как сметана и, наконец, становится почти такой же маловязкой жидкостью, как вода. Однако указать определенную температуру перехода твердого тела в жидкое здесь невозможно, поскольку ее нет. ",
                                      "Причины этого лежат в коренном отличии строения аморфных тел от строения кристаллических. Атомы в аморфных телах расположены беспорядочно. Аморфные тела по своему строению напоминают жидкости. Уже в твердом стекле атомы расположены беспорядочно. Значит, повышение температуры стекла лишь увеличивает размах колебаний его молекул, дает им постепенно все большую и большую свободу перемещения. Поэтому стекло размягчается постепенно и не обнаруживает резкого перехода «твердое—жидкое», характерного для перехода от расположения молекул в строгом порядке к беспорядочному. "
                                      ]
                                  },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "Для выполнения работы используется пробирка с канифолью.", "", 
                                      "Канифоль заметно теряет вязкость при температуре ~70°С, и наоборот, при температуре ~50°С она становится вязким настолько, что формально может считаться твердым телом.", "", 
                                      "Поэтому опыт рекомендуется проводить в диапазоне температур 40-80°С."
                                      ]
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Налейте в стакан калориметра горячую воду (2/3 по высоте) температурой немного выше 70°С.",
                                      "Измерьте ее температуру термометром."
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_15_01.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Растопите канифоль. Опустите в воду пробирку с канифолью. Дождитесь, когда канифоль полность станет жидкой. Этот процесс займет около 3 минут.",  "", 
                                      "Визуально убедитесь, используя стеклянную палочку, что вещество в пробирке перешло в жидкую фазу." 
                                      ],
                                      "picture":"Порядок_мол_физ_лаб_15_02.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "С помощью бумажной салфетки снимите остатки воды с поверхности термометра и опустите его в пробирку с жидкой канифолью.",  "", 
                                      "Температура должна быть около 60°С."
                                      ],
                                      "picture":"Порядок_мол_физ_лаб_15_03.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Используя экстрактор, извлеките стакан калориметра вместе с пробиркой из калориметра. Перенесите пробирку с затвердевающей канифолью и термометром в мерный стакан.", "", 
                                      "Установите в калориметр мерный стакан. Убедитесь, что канифоль все еще жидкая- термометр легко перемещается в ее объеме.",
                                      "Наблюдайте остывание и уменьшение температуры канифоли.",
                                      "Дождитесь температуры затвердевания, при которой термометр будет зафиксирован в объеме канифоли.",
                                      "Это произойдет при температуре около 55°С.",
                                      "Проверьте это стеклянной палочкой."
                                      ],
                                      "picture":"Порядок_мол_физ_лаб_15_04.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Извлеките пробирку с термометром из мерного стакана и убедитесь в затвердевании канифоли.", 
                                      "Верните пробирку с отвердевшей канифолью и термометром в калориметр с алюминиевым стаканом.",
                                      "Залейте в калориметр горячую воду с температурой немного выше 70°С."
                                      ],
                                      "picture":"Порядок_мол_физ_лаб_15_05.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Убедитесь с помощью стеклянной палочки в том, что канифоль вновь расплавилась и извлеките из нее термометр.", "",  
                                      "Аккуратно снимите остатки застывшей канифоли с поверхности термометра. Слейте остывшую воду из калориметра.", "", 
                                      "Проанализируйте температуры отвердевания и расплавления канифоли.", "",
                                      "Убедитесь, что у аморфных тел нет четкой границы перехода из жидкого состояния в твердое."
                                      ],
                                      "picture":"Порядок_мол_физ_лаб_15_06.json"
                                  }
                              ]
                      },
                      {
                        "labId":"lab16",
                        "labName": "Измерение модуля Юнга резины ",
                        "labPictureFolder": "01-physics/02-molecule_termodyn/lab16/",
                            "labPages": [
                                {
                                    "title": "ЛАБОРАТОРНАЯ РАБОТА N16",
                                    "comment": [
                                      "Измерение модуля Юнга резины "
                                    ]
                                  },
                                  {
                                    "title": "ЦЕЛЬ РАБОТЫ",
                                    "comment": [
                                      "Исследование упругих свойств резины при деформациях "
                                    ]
                                  },
                                  {
                                    "title": "ОБОРУДОВАНИЕ",
                                    "comment": [
                                      "Резиновый жгут", "Штатив с муфтой и лапкой", "Стальной цилиндр", "Рулетка", "Весы"
                                      ],
                                      "picture": "Оборудование_мол_физ_лаб_16.json",
                                      "pictureDark": "oborudovanie_mf_16_d.json"
                                  },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "Простейшим видом деформации является деформация растяжения или сжатия. Ее можно характеризовать абсолютным удлинением ∆l, возникающим под действием внешней силы F.", "",
                                        "Связь между ∆l и F зависит не только от механических свойств вещества, но и от геометрических размеров тела (его толщины и длины). Отношение абсолютного удлинения ∆l к первоначальной длине l образца называется относительным удлинением или относительной деформацией: @@ε = ∆l / l .", "", 
                                      "При растяжении ε ˃ 0, при сжатии ε˂0. Если принять направление внешней силы, стремящейся удлинить образец, за положительное, то F ˃ 0, при деформации растяжения и F ˂ 0 – при сжатии. ",
                                      "Отношение модуля внешней силы F к площади S сечения тела называется механическим напряжением: ",
                                      "##@@σ = F / S"
                                      ]
                                  },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "За единицу механического напряжения в СИ принят Па (Паскаль). Механическое напряжение измеряется в единицах давления. ",
                                      "Зависимость между σ и ε является одной из важнейших характеристик механических свойств твердых тел. При малых деформациях (обычно существенно меньших 1%) связь между σ и ε оказывается линейной. При этом при снятии напряжения деформация исчезает.",
                                      "Такая деформация называется упругой. ", 
                                      "Максимальное значение σ и ε, при котором сохраняется линейная связь между σ и ε, называется пределом пропорциональности. На линейном участке выполняется закон Гука:",
                                      "##@@ε = σ / Е",
                                      "Коэффициент Е в этом соотношении называется модулем Юнга."
                                      ]
                                  },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "Объектом изучения в данной работе является резиновый жгут, который подвергается упругой продольной деформации под действием силы тяжести подвешенного груза. Для определения значения модуля Юнга Е необходимо измерить напряжение Ϭ и относительное удлинение ε."
                                      ]
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Определите на весах массу стального цилиндра, который будет использован в качестве нагрузки."
                                      ],
                                      "picture":"mf_16_01.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Определите площадь поперечного сечения жгута S^_0^_ до деформации. Для этого с помощью шкалы рулетки измерьте размеры а и б. ",  "", 
                                      "Тогда в условиях нашего эксперимента механическое напряжение будет рассчитано по формуле: ",
                                      "##@@σ = F / S^_0^_ = mg / (aꞏб),", "", 
                                      "где @@F = mg@@ – величина растягивающей силы, которая равна силе тяжести груза, а S^_0^_ = аꞏб – площадь поперечного сечения жгута до деформации."
                                      ],
                                      "picture":"mf_16_02.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Закрепите резиновый жгут на лапке штатива и подвесьте на нем стальной цилиндр.",  "", 
                                      "Площадь поперечного сечения жгута S в деформированном состоянии определяют по площади его сечения S^_0^_ в исходном состоянии. Так как объем жгута не изменяется, то имеет место равенство: ", "", 
                                      "##@@S^_0^_ ꞏ L^_0^_ = Sꞏl, ",
                                      "откуда следует : ",
                                      "##@@S = S^_0^_ꞏl^_0^_ / l", "", 
                                      "Относительное удлинение ε вычислите по формуле:", 
                                      "##@@ε = Δl / l,", 
                                      "где @@Δl = l - l^_0^_@@ – разность начальной и конечной длины жгута. ", "", 
                                      "Рассчитайте модуль Юнга по формуле:", 
                                      "##@@Е = σ / ε = (mg / аб) / (Δl / l)."
                                      ],
                                      "picture":"Порядок_мол_физ_лаб_16_03.json"
                                  }
                                            
                              ]
                      },
                      {
                        "labId":"lab17",
                        "labName": "Измерение коэффициента поверхностного натяжения жидкости ",
                        "labPictureFolder": "01-physics/02-molecule_termodyn/lab17/",
                            "labPages": [
                                {
                                    "title": "ЛАБОРАТОРНАЯ РАБОТА N17",
                                    "comment": [
                                      "Измерение коэффициента поверхностного натяжения жидкости "
                                    ]
                                  },
                                  {
                                    "title": "ЦЕЛЬ РАБОТЫ",
                                    "comment": [
                                      "Экспериментальное определение коэффициента",
                                      "поверхностного натяжения воды",
                                      "сталагмометрическим методом"
                                    ]
                                  },
                                  {
                                    "title": "ОБОРУДОВАНИЕ",
                                    "comment": [
                                      "Мерный стакан", "Капельница", "Весы", "Емкость для воды"
                                      ],
                                      "picture": "Оборудование_мол_физ_лаб_17.json",
                                      "pictureDark": "oborudovanie_mf_17_d.json"
                                  },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "Из механики известно, что равновесным состояниям системы соответствует минимальное значение ее потенциальной энергии. Отсюда следует, что свободная поверхность жидкости стремится сократить свою площадь. По этой причине свободная капля жидкости принимает шарообразную форму. Жидкость ведет себя так, как будто по касательной к ее поверхности действуют силы, сокращающие (стягивающие) эту поверхность. Эти силы называются силами поверхностного натяжения. ", "",
                                      "Наличие сил поверхностного натяжения делает поверхность жидкости похожей на упругую растянутую пленку, с той только разницей, что упругие силы в пленке зависят от площади ее поверхности (т. е. от того, как пленка деформирована), а силы поверхностного натяжения не зависят от площади поверхности жидкости. "
                                      ]
                                  },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "Таким образом, коэффициент поверхностного натяжения @@σ@@ может быть определен как модуль силы поверхностного натяжения, действующей на единицу длины линии, ограничивающей поверхность. ",
                                      "Метод счета капель считается самым простым способом измерения коэффициента поверхностного натяжения с технической точки зрения. В основе лежит закон, согласно которому вес капли, отрывающейся от пипетки, пропорционален коэффициенту поверхностного натяжения жидкости @@σ@@  и радиусу пипетки @@r@@, т. е.: ",
                                      "##@@σ = mg / (2πr)", 
                                      "где @@m@@ – масса одной капли, @@r@@ – радиус отверстия флакона-капельницы. "
                                      ]
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "С помощью весов определим массу пустого стакана M^_0^_. "
                                      ],
                                      "picture": "mf_17_01.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Так как технически сложно измерить массу одной капли, опыт проводится измерением общей массы известного множества капель.",
                                      "В капельницу налейте воду из емкости для воды.", "", 
                                      "В стакан массой М^_0^_ накапайте из капельницы 100 капель воды комнатной температуры."
                                      ],
                                      "picture":"Порядок_мол_физ_лаб_17_02.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "После этого определите массу стакана с водой M. ",  "", 
                                      "По разнице масс @@∆M = M - M^_0^_@@ находят массу одной капли как: ",
                                      "##@@m = (M - M^_0^_) / 100.", 
                                      "Радиус отверстия флакона капельницы r-0.0003 м.", "", 
                                      "По результатам измерений определите коэффициент поверхностного натяжения σ по формуле: ",
                                      "##@@σ=(g / (2πr)) ꞏ (M - M^_0^_) / 100."
                                      ],
                                      "picture":"mf_17_03.json"
                                  }
                              ]
                      },
                      {
                        "labId":"lab18",
                        "labName": "Исследование эффекта капиллярности",
                        "labPictureFolder": "01-physics/02-molecule_termodyn/lab18/",
                            "labPages": [
                                {
                                    "title": "ЛАБОРАТОРНАЯ РАБОТА N18",
                                    "comment": [
                                      "Исследование эффекта капиллярности"
                                    ]
                                  },
                                  {
                                    "title": "ЦЕЛЬ РАБОТЫ",
                                    "comment": [
                                      "Экспериментальное определение радиуса капиллярной трубки"
                                    ]
                                  },
                                  {
                                    "title": "ОБОРУДОВАНИЕ",
                                    "comment": [
                                      "Мерный стакан", "Рулетка", "Калориметр", "Емкость для воды", "Капиллярная трубка"
                                      ],
                                      "picture": "Оборудование_мол_физ_лаб_18.json",
                                      "pictureDark": "oborudovanie_mf_18_d.json"
                                  },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "Вблизи границы между жидкостью, твердым телом и газом форма свободной поверхности жидкости зависит от сил взаимодействия молекул жидкости с молекулами твердого тела (взаимодействием с молекулами газа (или пара) можно пренебречь). Если эти силы больше сил взаимодействия между молекулами самой жидкости, то жидкость смачивает поверхность твердого тела. В этом случае жидкость подходит к поверхности твердого тела под некоторым острым углом θ, характерным для данной пары: жидкость – твердое тело. Угол θ называется краевым углом. ", "",
                                      "Если силы взаимодействия между молекулами жидкости превосходят силы их взаимодействия с молекулами твердого тела, то краевой угол θ оказывается тупым. В этом случае говорят, что жидкость не смачивает поверхность твердого тела. При полном смачивании θ = 0, при полном несмачивании θ = 180°. "
                                      ]
                                  },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "Капиллярными явлениями называют подъем или опускание жидкости в трубках малого диаметра – капиллярах. Смачивающие жидкости поднимаются по капиллярам, несмачивающие – опускаются. ",
                                      "Вода практически полностью смачивает чистую поверхность стекла. Наоборот, ртуть полностью не смачивает стеклянную поверхность. Поэтому уровень ртути в стеклянном капилляре опускается ниже уровня в сосуде."
                                      ]
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Радиус капилляра можно определить исходя из понятия плотности. Если в трубке радиуса r находится жидкость плотности ρ, то её объем равен:",
                                      "##@@V = m / ρ",
                                      "где m – масса жидкости, а ρ - ее плотность.", "", 
                                      "При использовании капилляра круглого сечения получим, что:",
                                      "##@@V = 𝛑r^^2^^h,",
                                      "где h – высота столба жидкости. ", "", 
                                      "Приравняв правые части этих уравнений получим:",
                                      "##@@m:ρ = 𝛑r^^2^^h,",
                                      "и радиус капилляра будет равен:",
                                      "##@@r = √(m / (πρh)).",
                                      "Для более точных результатов капиллярная трубка должна быть тщательно промыта перед опытом. "
                                      ],
                                      "picture": "mf_18_01.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "С помощью весов определим массу пустого стакана M^_0^_. "
                                      ],
                                      "picture":"mf_18_02.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Налейте в мерный стакан воду из емкости для воды и погрузите капиллярную трубку в воду в мерном стакане до предела. После того, как вода ее заполнит, пальцем зажмите верхнее отверстие и извлеките трубку из воды. Освободите зажатое отверстие.", 
                                      "Избыток воды сольется, в трубке останется только та часть, которая удерживается поверхностным натяжением."
                                      ],
                                      "picture":"Порядок_мол_физ_лаб_18_03.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Приложив капиллярную трубку к шкале рулетки измерьте с точностью до миллиметра длину h^_i^_ столба воды в ней.",  "", 
                                      "Осторожно выдуйте воду из капиллярной трубки в стакан калориметра.", "", 
                                      "Для повышения точности измерения массы воды в капиллярной трубке повторите погружение 5 раз, измеряя высоту каждого столба h^_i^_ и выдувая воду в стакан калориметра.", "", 
                                      "Определите массу М стакана калориметра с водой."
                                      ],
                                      "picture":"Порядок_мол_физ_лаб_18_04.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "По разнице масс @@∆M = M - M^_0^_@@ найдите массу воды, которая скопилась в стакане после пяти опытов:", 
                                      "##@@m = ∆M = M - M^_0^_", "", 
                                      "Определите сумму высот столбов как @@h = Σh^_i^_@@.", "", 
                                      "По результатам измерений определите радиус капилляра r по формуле:",
                                      "##@@r = √((M - M^_0^_) / (πρΣh^_i^_))"
                                      ],
                                      "picture":"mf_18_05.json"
                                  }
                                            
                              ]
                      },
                      {
                        "labId":"lab19",
                        "labName": "Измерение коэффициента поверхностного натяжения жидкости с помощью капилляра ",
                        "labPictureFolder": "01-physics/02-molecule_termodyn/lab19/",
                            "labPages": [
                                {
                                    "title": "ЛАБОРАТОРНАЯ РАБОТА N19",
                                    "comment": [
                                      "Измерение коэффициента поверхностного натяжения жидкости с помощью капилляра "
                                    ]
                                  },
                                  {
                                    "title": "ЦЕЛЬ РАБОТЫ",
                                    "comment": [
                                      "Экспериментальное определение коэффициента поверхностного",
                                       "натяжения воды капиллярным методом "
                                    ]
                                  },
                                  {
                                    "title": "ОБОРУДОВАНИЕ",
                                    "comment": [
                                      "Мерный стакан", "Рулетка", "Штатив с муфтой и лапкой", "Емкость с водой", 
                                      "Капиллярная трубка"
                                      ],
                                      "picture": "Оборудование_мол_физ_лаб_19.json",
                                      "pictureDark": "oborudovanie_mf_19_d.json"
                                  },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "Капиллярный метод измерения коэффициента поверхностного натяжения, как следует из названия, основан на капиллярном эффекте. Если определить высоту h столба жидкости в капилляре радиуса r при полном смачивании, то коэффициент поверхностного натяжения σ можно рассчитать по формуле: ", "",
                                      "##@@σ = (r/2)ꞏρgh", 
                                      "где ρ-плотность исследуемой жидкости."
                                      ]
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Для более точных результатов капиллярная трубка должна быть тщательно промыта перед опытом. ", "", 
                                      "Мерный стакан заполните на 3/4 высоты водой.", "",
                                      "Поместите в него капиллярную трубку. Заметьте, как в ней поднялась вода по капилляру."
                                      ],
                                      "picture":"Порядок_мол_физ_лаб_19_01.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Установите мерный стакан под штатив так, чтобы закрепленная на лапке штатива лента рулетки была опущена вниз и доходила до поверхности воды в мерном стакане.",
                                      "Вплотную к нему разместите миллиметровую шкалу рулетки, которую закрепите в лапке штатива так, чтобы нулевое деление шкалы совпадало с поверхностью воды в мерном стакане (шкала должна быть направлена вверх).", 
                                      "Капиллярную трубку, удерживая за один конец рукой, погрузите несколько раз в воду, чтобы смочить её внутренний канал. Затем свободный конец трубки снова опустите в воду и, приблизив её к шкале рулетки, измерьте высоту h столба жидкости в капилляре. "
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_19_02.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "По результатам измерений определите коэффициент поверхностного натяжения σ по формуле: ",
                                      "##@@σ^_изм^_=(r/2)ρgh",
                                      "Значение радиуса капилляра r возьмите из предыдущей лабораторной работы. "
                                      ],
                                      "picture":"Порядок_мол_физ_лаб_19_03.json"
                                  }
                              ]
                      },
                      {
                        "labId":"lab20",
                        "labName": "Измерение коэффициента поверхностного натяжения при различных температурах жидкости ",
                        "labPictureFolder": "01-physics/02-molecule_termodyn/lab20/",
                            "labPages": [
                                {
                                    "title": "ЛАБОРАТОРНАЯ РАБОТА N20",
                                    "comment": [
                                      "Измерение коэффициента поверхностного натяжения",
                                      " при различных температурах жидкости "
                                    ]
                                  },
                                  {
                                    "title": "ЦЕЛЬ РАБОТЫ",
                                    "comment": [
                                      "Экспериментальное определение зависимости ",
                                      "коэффициента поверхностного натяжения ",
                                      "от температуры жидкости "
                                    ]
                                  },
                                  {
                                    "title": "ОБОРУДОВАНИЕ",
                                    "comment": [
                                      "Мерный стакан", "Рулетка", "Штатив с муфтой и лапкой", "Емкость для воды", "Капиллярная трубка", "Термометр"
                                      ],
                                      "picture": "Оборудование_мол_физ_лаб_20.json",
                                      "pictureDark": "oborudovanie_mf_20_d.json"
                                  },
                                  {
                                    "title": "КРАТКОЕ ОПИСАНИЕ",
                                    "comment": [
                                      "Из общих соображений легко догадаться, что с повышением температуры коэффициент поверхностного натяжения должен уменьшаться. Это следует из того, что при критической температуре он должен быть равен нулю, так как при этой температуре исчезает разница между жидкостью и ее паром, а значит, исчезает и поверхность, разделяющая обе фазы. Однако точный вид зависимости коэффициента поверхностного натяжения от температуры не может быть установлен теоретически. Приближенно зависимость коэффициента поверхностного натяжения от температуры выражается следующим равенством:", 
                                      "##@@σ = КТ,", 
                                      "где К – постоянный коэффициент, зависящий от молекулярного состава жидкости и её плотности, для воды К ≈ 3ꞏ10^^-3^^°С^^-1^^."
                                      ]
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Для измерения коэффициента поверхностного натяжения воспользуйтесь одним из двух предложенных ранее способов (капиллярный и сталагмометрический). ", "",
                                      "Капиллярный метод.",
                                      "При комнатное температуре. Для более точных результатов капиллярная трубка должна быть тщательно промыта перед опытом.", "",  
                                      "Мерный стакан заполните на 3/4 высоты водой. Поместите в него капиллярную трубку. Заметьте, как в ней поднялась вода по капилляру."
                                      ],
                                      "picture":"Порядок_мол_физ_лаб_20_01.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Установите мерный стакан под штатив так, чтобы закрепленная на лапке штатива лента рулетки была опущена вниз и доходила до поверхности воды в мерном стакане.",
                                      "Вплотную к нему разместите миллиметровую шкалу рулетки, которую закрепите в лапке штатива так, чтобы нулевое деление шкалы совпадало с поверхностью воды в мерном стакане (шкала должна быть направлена вверх).", 
                                      "Капиллярную трубку, удерживая за один конец рукой, погрузите несколько раз в воду, чтобы смочить её внутренний канал. Затем свободный конец трубки снова опустите в воду и, приблизив её к шкале рулетки, измерьте высоту h столба жидкости в капилляре. "
                                      ],
                                      "picture": "Порядок_мол_физ_лаб_20_02.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "По результатам измерений определите коэффициент поверхностного натяжения σ по формуле: ",
                                      "##@@σ^_изм^_ = (r/2)ρgh",
                                      "Значение радиуса капилляра r возьмите из лабораторной работы №18. "
                                      ],
                                      "picture":"Порядок_мол_физ_лаб_20_03.json"
                                  },
                                  {
                                    "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                    "comment": [
                                      "Повторите измерения с использованием теплой воды (+40°С и +60°С)", 
                                      "На основе полученных данных постройте графики зависимости коэффициента поверхностного натяжения σ от температуры Т.", "",
                                      "В результате должна получиться убывающая линейная зависимость.", "", 
                                      "Проведите эксперимент, изменив молекулярный состав жидкости, например, добавив в воду сахар или соль.",
                                      "Определите, как при этом изменяется коэффициент поверхностного натяжения."
                                      ],
                                      "picture":"Порядок_мол_физ_лаб_20_04.json"
                                  }           
                              ]
                      }
                  ]
              }, {
                  "sectionName": "Электродинамика",                
                  "sectionId": "03-electrodynamics", 
                  "labList": [
                    {
                      "labId":"lab01",
                      "labName": "Сборка электрической цепи и измерение силы тока на ее различных участках",
                      "labPictureFolder": "01-physics/03-electrodynamics/lab01/",
                          "labPages": [
                              {
                                  "title": " ЛАБОРАТОРНАЯ РАБОТА N1 ",
                                  "comment": [
                                    "Сборка электрической цепи и измерение силы тока",
                                    "на ее различных участках"
                                  ]
                                },
                                {
                                  "title": "ЦЕЛЬ РАБОТЫ",
                                  "comment": [
                                    "Изучить последовательную электрическую цепь и измерить токи во всех ее сечениях"
                                  ]
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Для плавного регулирования тока или напряжения используются переменные резисторы, сопротивление которых можно плавно изменять - реостаты и потенциометры. ",
                                    "Электрические схемы реостата и потенциометра показаны на рисунке."
                                    ],
                                    "picture": "Порядок_эл_дин_лаб_раб_01_01.json",
                                    "pictureDark": "ed_01_01_d.json"
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Реостат представляет собой проволочный резистор, выполненный из тонкой проволоки высокого удельного сопротивления, намотанной на каркас линейной или кольцевой формы, и токосъемника.", "",
                                    "Токосъемник механически связан с ручкой потенциометра. При вращении ручки токосъемник перемещается по поверхности намотки, сохраняя электрический контакт с нею. Токосъемник выведен на центральное гнездо 1, а один из выводов намотки на гнездо 2.", "",
                                    " Сопротивление между гнездами 1 и 2 при перемещении токосъемника изменяется от 0 до полного сопротивления намотки."
                                    ],
                                    "picture": "Порядок_эл_дин_лаб_раб_01_02.json",
                                    "pictureDark": "ed_01_02_d.json"
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Потенциометр выполнен аналогично, но наружу выведены оба конца намотки - гнезда 1 и 3, и токосъемник - гнездо 2.", "", 
                                    "В работе используется потенциометр R3, намотка которого выведена из гнезда 11 и 13, а токосъемник - на гнездо 12.", "", 
                                    "Следует запомнить, что при вращении ручки потенциометра R3 по часовой стрелке сопротивление между гнездами 11 и 12 уменьшается, а сопротивление между гнездами 12 и 13 увеличивается. При этом сумма этих сопротивлений остается равной 10 Ом."
                                    ],
                                    "picture": "Порядок_эл_дин_лаб_раб_01_03.json",
                                    "pictureDark": "ed_01_03_d.json"
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Соберите электрическую цепь."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_01_04.json",
                                    "pictureDark": "ed_01_04_d.json"
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Тщательно проверьте правильность сборки.", "", 
                                    "Для этого перечислите все ее участки и элементы от начала цепи и до ее конца, воспользовавшись рисунком: гнездо+ИП-гнездо-РА1-РА1-гнездо-РА1-гнездо 5-ключ К-гнезда 6-11-левая часть потенциометра R3-гнезда 12-10-резистор R2-гнездо 9-гнездо-ИП."
                                    ],
                                    "picture": "Порядок_эл_дин_лаб_раб_01_05.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Убедитесь, что предел измерения миллиамперметра РА1 и полярность его включения в цепь выбраны верно. Ручку потенциометра R^_3^_ поверните против часовой стрелки до упора, при этом его сопротивление между гнездами 11 и 12 станет максимальным и равным 10 Ом.", "", 
                                    "Замкните ключ К. В цепи появится ток I^_1^_, который будет измерен миллиамперметром РА. Осторожно вращая ручку потенциометра по часовой стрелке установите ток в цепи 0,25 А.", "", 
                                    "Укажите значение тока I^_1^_ в таблице."
                                    ],
                                    "picture": "Порядок_эл_дин_лаб_раб_01_06.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    ],
                                    "table":[
                                      "Ток I^_1^_, А|Ток I^_2^_, А|Ток I^_3^_, А|Ток I^_4^_, А",
                                      "0,25|||",
                                      "0,35|||",
                                      "0,45|||"
                                    ]
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Разомкните ключ К.", "", 
                                    "Повторите измерения токов I^_2^_, I^_3^_, I^_4^_ в цепи для вариантов сборки, показанных на рисунке, не изменяя положения ручки потенциометра R3.",
                                    "Результаты измерений запишите в таблицу."
                                    ],
                                    "picture": "Порядок_эл_дин_лаб_раб_01_07.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Для измерения тока I^_2^_ соберите схему."
                                    ],
                                    "picture": "Порядок_эл_дин_лаб_раб_01_08.json"
                                },  
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Для измерения тока I^_3^_ соберите схему."
                                    ],
                                    "picture": "Порядок_эл_дин_лаб_раб_01_09.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Для измерения тока I^_4^_ соберите схему."
                                    ],
                                    "picture": "Порядок_эл_дин_лаб_раб_01_10.json"
                                },
                                {
                                  "title": "ЗАДАНИЕ",
                                  "comment": [
                                    "Аналогично выполните измерения для токов в цепи 0,35 А и 0,45 А.",
                                    "Убедитесь, что токи, измеренные во всех сечениях последовательной цепи, однаковы."
                                    ]
                                }
                            ]
                    },
                    {
                      "labId":"lab02",
                      "labName": "Измерение напряжений на различных участках электрической цепи",
                      "labPictureFolder": "01-physics/03-electrodynamics/lab02/",
                          "labPages": [
                              {
                                  "title": " ЛАБОРАТОРНАЯ РАБОТА N2 ",
                                  "comment": [
                                    "Измерение напряжений на различных участках",
                                    "электрической цепи"
                                  ]
                                },
                                {
                                  "title": "ЦЕЛЬ РАБОТЫ",
                                  "comment": [
                                    "Убедиться в том, что сумма напряжений на последовательно включенных участках электрической цепи равна напряжению источника питания на всей цепи, а полное сопротивление равно сумме сопротивлений ее участков."
                                  ]
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Соберите электрическую цепь.",
                                    "Она состоит из последовательно включенных резисторов R2 и R3.", "", 
                                    "Поверните ось потенциометра R3 до упора против часовой стрелки."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_02_01.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Замкните ключ К.", "",
                                    "Потенциометром R3 установите ток в цепи, равный 0,25А.", "",
                                    "Поочередно подключая вольтметр PV к гнездам 9-10 и 11-12, измерьте напряжения U^_2^_ и U^_3^_ на резисторах R2 и R3.", "", 
                                    "Результаты измерений запишите в таблицу."
                                    ],
                                    "picture": "Порядок_эл_дин_лаб_раб_02_02.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Вычислите сумму этих напряжений и убедитесь, что результат равен выходному напряжению источника питания Uип."
                                    ],
                                    "table":[
                                      "Ток в цепи, А|U^_3^_, В|U^_2^_, В|U, В",
                                      "0,25|R3=|R2=|R1=",
                                      "0,35|||",
                                      "0,45|||"
                                    ]
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Измерьте выходное напряжение источника питания при том токе, при котором выполняются измерения напряжений на резисторах цепи, подключая вольтметр непосредственно к гнездам источника питания с соблюдением полярности.",  
                                    "Результаты измерений запишите в таблицу.",
                                    "Пользуясь данными таблицы рассчитайте сопротивление резисторов R2 и R3 для одного из значений тока и результаты расчета запишите в таблицу."
                                    ],
                                    "picture": "Порядок_эл_дин_лаб_раб_02_03.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Как следует из опыта, напряжение от источника питания распределяется между резисторами цепи, что можно записать в виде уравнения:",
                                    "##@@U^_ИП^_ = UR1 + UR2 + UR3.", "", 
                                    "Перепишем формулу через ток в цепи I и значения сопротивлений ее участков:",
                                    "##@@I×RΣ = I×R1 + I×R2 + I×R3. ", "", 
                                    "Поскольку ток в любом сечении рассматриваемой цепи один и тот же, выражение можно сократить на общий множитель I. В результате получится формула для вычисления полного сопротивления цепи:",
                                    "##@@RΣ = R1 + R2 + R3,",
                                    "где RΣ - полное или суммарное сопротивление последовательной электрической цепи."
                                    ]
                                },  
                                {
                                  "title": "ВЫВОД",
                                  "comment": [
                                    "Сумма напряжений на последовательно включенных участках электрической цепи равна напряжению источника питания на всей цепи.",
                                    "Полное сопротивление последовательной электрической цепи равно сумме сопротивлений ее участков."
                                    ]
                                }
                            ]
                    },
                    {
                      "labId":"lab03",
                      "labName": "Исследование зависимости напряжения на проводнике от силы тока в нем",
                      "labPictureFolder": "01-physics/03-electrodynamics/lab03/",
                          "labPages": [
                              {
                                  "title": " ЛАБОРАТОРНАЯ РАБОТА N3 ",
                                  "comment": [
                                    "Исследование зависимости напряжения на проводнике",
                                    "от силы тока в нем"
                                  ]
                                },
                                {
                                  "title": "ЦЕЛЬ РАБОТЫ",
                                  "comment": [
                                    "Убедиться в том, что напряжение на концах проводника пропорционально току, протекающему через него, то есть напряжение на проводнике увеличивается во столько же раз, во сколько увеличивается ток через него и наоборот"
                                  ]
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Соберите электрическую цепь."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_03_01.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Ручку потенциометра R3 поверните против часовой стрелки. Включите ключом К ток в цепи.", "",
                                    "Измерьте значение этого тока и напряжение на резисторе R2.", "",
                                    "Полученные данные запишите в таблицу."
                                    ],
                                    "picture": "Порядок_эл_дин_лаб_раб_03_02.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    ],
                                    "table":[
                                      "Ток, А|Напряжение, В|Сопротивление, Ом",
                                      "0,25||",
                                      "0,35||",
                                      "0,45||"
                                    ]
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Осторожно увеличьте ток в цепи до 0,35 А и измерьте напряжение на резисторе R2.",  
                                    "Результаты измерений запишите в таблицу."
                                    ],
                                    "picture": "Порядок_эл_дин_лаб_раб_03_03.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Аналогичные измерения выполните для тока 0,45 А.",
                                    "По данным из таблицы рассчитайте сопротивление резистора R2 для трех значений тока через него. Убедитесь, что напряжение на резисторе пропорционально току в нем.", "", 
                                    "Ясно, что расчет даст один и тот же результат, что свидетельствует о пропорциональной связи между напряжением на резисторе и током через него: с увеличением напряжения увеличивается ток, и наоборот."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_03_04.json"
                                },  
                                {
                                  "title": "ВЫВОД",
                                  "comment": [
                                    "Напряжение на концах проводника пропорционально току, протекующему через него."
                                    ]
                                }
                            ]
                    },
                    {
                      "labId":"lab04",
                      "labName": "Регулирование силы тока в электрической цепи",
                      "labPictureFolder": "01-physics/03-electrodynamics/lab04/",
                          "labPages": [
                              {
                                  "title": " ЛАБОРАТОРНАЯ РАБОТА N4 ",
                                  "comment": [
                                    "Регулирование силы тока в электрической цепи"
                                  ]
                                },
                                {
                                  "title": "ЦЕЛЬ РАБОТЫ",
                                  "comment": [
                                    "Изучить способ плавного изменения тока от источника питания"
                                  ]
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Соберите электрическую цепь."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_04_01.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Ручку потенциометра R3 поверните против часовой стрелки.",
                                    "В этом положении сопротивление потенциометра будет максимальным и равным 10 Ом, а ток в цепи будет близок к 0,25 А."
                                    ],
                                    "picture": "Порядок_эл_дин_лаб_раб_04_02.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "При вращении ручки по часовой стрелке сопротивление цепи будет уменьшаться, а ток увеличиваться.", "", 
                                    "Так можно регулировать ток в пределах от 0,25 А, когда сопротивление потенциометра равно 10 Ом, и до максимального значения, которое может быть обеспечено источником питания.", "", 
                                    "Однако в режиме больших токов работать нельзя, поскольку батареи ИП быстро разрядятся, а резистор R3 перегреется и может выйти из строя."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_04_03.json"
                                },
                                {
                                  "title": "ВЫВОД",
                                  "comment": [
                                    "При уменьшении общего спротивления цепи увеличивается ток в ней, и наоборот."
                                    ]
                                }
                            ]
                    },
                    {
                      "labId":"lab05",
                      "labName": "Регулирование напряжения в электрической цепи",
                      "labPictureFolder": "01-physics/03-electrodynamics/lab05/",
                          "labPages": [
                              {
                                  "title": " ЛАБОРАТОРНАЯ РАБОТА N5 ",
                                  "comment": [
                                    "Регулирование напряжения в электрической цепи"
                                  ]
                                },
                                {
                                  "title": "ЦЕЛЬ РАБОТЫ",
                                  "comment": [
                                    "Рассмотреть способ плавного изменения напряжения в электрической цепи"
                                  ]
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Соберите электрическую цепь.",
                                    "Поверните ручку потенциометра R3 до упора против часовой стрелки."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_05_01.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Замкните ключ К.",
                                    "Вольтметр покажет выходное напряжение источника питания 0 В, а в цепи установите ток, значение которого определяется отношением напряжения источника питания 4,5 В и сопротивлением резистора R3 между гнездами 11 и 13, равному 10 Ом.", "",
                                    "Отсюда ток в цепи: I = 4,5В / 10Ом = 0,45А.", "", 
                                    "Этот ток будет измерен амперметром РА1, а напряжение между гнездами 12 и 13, измеренное вольтметром PV, будет равно 0 В."
                                    ],
                                    "picture": "Порядок_эл_дин_лаб_раб_05_02.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Плавно вращая ручку потенциометра по часовой стрелке обратите внимание, что напряжение, которое измеряет вольтметр PV, плавно изменяется в пределах от 0В до 4,5В.", "", 
                                    "При этом сопротивление части потенциометра между гнездами 11 и 12 будет уменьшаться и в итоге станет равным 0."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_05_03.json"
                                },
                                {
                                  "title": "ВЫВОД",
                                  "comment": [
                                    "Напряжение на элементе цепи изменяется плавно, если плавно изменяется его сопротивление."
                                    ]
                                }
                            ]
                    },
                    {
                      "labId":"lab06",
                      "labName": "Измерение сопротивления с помощью амперметра и вольтметра",
                      "labPictureFolder": "01-physics/03-electrodynamics/lab06/",
                          "labPages": [
                              {
                                  "title": " ЛАБОРАТОРНАЯ РАБОТА N6 ",
                                  "comment": [
                                    "Измерение сопротивления с помощью амперметра и вольтметра"
                                  ]
                                },
                                {
                                  "title": "ЦЕЛЬ РАБОТЫ",
                                  "comment": [
                                    "Рассмотреть один из способов измерения сопротивления резистора с помощью двух измерительных приборов ─ амперметра и вольтметра"
                                  ]
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Соберите электрическую цепь.",
                                    "Ручку потенциометра R3 поверните по часовой стрелке до упора."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_06_01.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Замкните ключ К.", "",
                                    "В цепи появится ток 0,45А, а напряжение между гнездами 12 и 13 будет равно 4,5 В.", "",
                                    "Поворачивая ручку потенциометра R3 против часовой стрелки убедитесь, что напряжение между гнездами 12 и 13 будет уменьшаться.", "", 
                                    "Одновременно будет уменьшаться сопротивление части потенциометра R3 между этими гнездами."
                                    ],
                                    "picture": "Порядок_эл_дин_лаб_раб_06_02.json"
                                },
                                {
                                  "title": "ЗАДАНИЕ",
                                  "comment": [
                                    "Самостоятельно рассчитайте сопротивление между гнездами 12 и 13 для нескольких углов поворта оси потенциометра R3.", "", 
                                    "Убедитесь, что для расчета сопротивления резистора, находящегося в любой электрической схеме, достаточно знать напряжение на нем и ток, протекающий через него."
                                    ]
                                },
                                {
                                  "title": "ВЫВОД",
                                  "comment": [
                                    "Сопротивление резистора измеряют с помощью двух измерительных приборов- амперметра и вольтметра."
                                    ]
                                }
                            ]
                    },
                    {
                      "labId":"lab07",
                      "labName": "Изучение последовательного соединения проводников",
                      "labPictureFolder": "01-physics/03-electrodynamics/lab07/",
                          "labPages": [
                              {
                                  "title": " ЛАБОРАТОРНАЯ РАБОТА N7 ",
                                  "comment": [
                                    "Изучение последовательного соединения проводников"
                                  ]
                                },
                                {
                                  "title": "ЦЕЛЬ РАБОТЫ",
                                  "comment": [
                                    "Рассмотреть схему, состоящую из трех резисторов, включенных последовательно по отношению к источнику питания. Определить правила расчета полного сопротивления такой цепи"
                                  ]
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Соберите электрическую цепь.",
                                    "Ручку потенциометра R3 поверните против часовой стрелки до упора. Замкните ключ К.", "", 
                                    "Вращая ручку потенциометра R3 установите ток в цепи 0,1 А.", "", 
                                    "Измерьте вольтметром PV напряжения на всех трех резисторах.", "", 
                                    "Результаты измерений запишите в таблицу."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_07_01.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Сумма напряжений на участках цепи, равна напряжению на всей цепи:",
                                    "##@@UR1 + UR2 + UR3 = UΣ.", "",
                                    "Через значение тока в цепи и сопротивления резисторов это уравнение принимает вид:", 
                                    "##@@I х R1 + I х R2 + I х R3 = I х RΣ.", "", 
                                    "Удалив общий множитель I, уравнение принимает вид:",
                                    "##@@R1 + R2 + R3 = RΣ,",
                                    "что означает, что полное сопротивление цепи, состоящей из последовательно включенных резисторов, равно сумме сопротивлений этих резисторов."
                                    ],
                                    "table":[
                                      "U1, В|U2, В|U3, В|UΣ, В",
                                      " | | | ",
                                      "R1=|R2=|R3=|RΣ="
                                    ]
                                },  
                                {
                                  "title": "ВЫВОД",
                                  "comment": [
                                    "Полное сопротивление цепи последовательно включенных резисторов равно сумме сопротивлений этих резисторов."
                                    ]
                                }
                            ]
                    },
                    {
                      "labId":"lab08",
                      "labName": "Изучение параллельного соединения проводников",
                      "labPictureFolder": "01-physics/03-electrodynamics/lab08/",
                          "labPages": [
                              {
                                  "title": " ЛАБОРАТОРНАЯ РАБОТА N8 ",
                                  "comment": [
                                    "Изучение параллельного соединения проводников"
                                  ]
                                },
                                {
                                  "title": "ЦЕЛЬ РАБОТЫ",
                                  "comment": [
                                    "Рассмотреть схему, состоящую из двух резисторов, включенных параллельно по отношению к источнику питания, наблюдать работу такой цепи и определить правила расчета ее полного сопротивления"
                                  ]
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Соберите электрическую цепь."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_08_01.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Замкните ключ К. Измерьте напряжение U^_изм^_ на резисторах вольтметром PV и сразу же разомкните ключ К.", "",
                                    "Рассчитайте токи через резисторы помня, что ток через каждый параллельно включенный резистор определяется напряжением на нем и сопротивлением этого резистора:", 
                                    "##@@I3 = U^_ип^_: R3 = U^_изм^_/10 Ом,",
                                    "##@@I2 = U^_ип^_: R2 = U^_изм^_/8,2 Ом.", "", 
                                    "Ток IΣ от источника питания ИП должен быть равен сумме полученных токов:",
                                    "##@@IΣ = I^_3^_+I^_2^_ = (U^_изм^_/R3) + (U^_изм^_/R2)"
                                    ],
                                    "picture": "Порядок_эл_дин_лаб_раб_08_02.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Замкните ключ К и быстро измерьте ток от источника питания ИП, используя амперметр с пределом измерения 3 А.",
                                    "Этот ток представляет собой сумму токов IΣ, протекающих через резисторы.",
                                    "Рассчитайте полное сопротивление двух параллельно включенных резисторов. Для этого перепишите предыдущее уравнение через приложенное к резисторам напряжение и их сопротивления:",
                                    "##@@U^_ип^_ / RΣ = (U^_ип^_ / R3) + (U^_ип^_ / R2)."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_08_03.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Сократив на одинаковый параметр Uип все члены уравнения, получим формулу для вычисления полного сопротивления параллельно включенных резисторов:",  
                                    "##@@1 / RΣ = 1 / R3 + 1 / R2.","", 
                                    "Преобразовав эту формулу получим удобный ее вид:",
                                    "##@@RΣ = (R3ꞏR2) / (R3 + R2).","", 
                                    "Эта формула позволяет рассчитать полное сопротивление двух параллельно включенных резисторов."
                                    ],
                                    "picture": "Порядок_эл_дин_лаб_раб_08_04.json"
                                }, 
                                {
                                  "title": "ВЫВОД",
                                  "comment": [
                                    "Полное сопротивление двух параллельно включенных резисторов равно произведению сопротивлений этих резисторов, деленному на их сумму."
                                    ]
                                }
                            ]
                    },
                    {
                      "labId":"lab09",
                      "labName": "Исследование смешанного соединения проводников (первый вариант схемы)",
                      "labPictureFolder": "01-physics/03-electrodynamics/lab09/",
                          "labPages": [
                              {
                                  "title": " ЛАБОРАТОРНАЯ РАБОТА N9 ",
                                  "comment": [
                                    "Исследование смешанного соединения проводников",
                                    "(первый вариант схемы)"
                                  ]
                                },
                                {
                                  "title": "ЦЕЛЬ РАБОТЫ",
                                  "comment": [
                                    "Рассмотреть схему, имеющую цепь, состоящую из двух последовательно включенных резисторов, параллельно которым подключен третий резистор, способный плавно изменять сопротивление всей цепи и, следовательно, ток от источника питания"
                                  ]
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Соберите схему."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_09_01.json",
                                    "pictureDark": "ed_09_01_d.json"
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Учитывая, что резисторы R1 и R2 постоянные и могут быть объединены в один, то допустимо использовать только один резистор R2.", "",
                                    "Тогда допустимо собрать установку."
                                    ],
                                    "picture": "Порядок_эл_дин_лаб_раб_09_02.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Ручку регулирования сопртивления резистора R3 поверните против часовой стрелки до упора.", "", 
                                    "При этом сопротивление этого резистора между гнездами 11 и 12 станет максимальным и равным 10 Ом.", "", 
                                    "Замкните ключ К и измерьте ток в цепи."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_09_03.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Очень осторожно поворачивайте ручку потенциометра R3 по часовой стрелке.", "",   
                                    "Обратите внимание на плавное увеличение тока от источника питания по амперметру РА2.", "", 
                                    "Это явление объясняется тем, что полное сопротивление цепи при уменьшении сопротивления резистора R3, шунтирующего оба резистора, уменьшается."
                                    ],
                                    "picture": "Порядок_эл_дин_лаб_раб_09_04.json"
                                },  
                                {
                                  "title": "ЗАДАНИЕ",
                                  "comment": [
                                    "Сделайте выводы по проделанной работе."
                                    ]
                                }
                            ]
                    },
                    {
                      "labId":"lab10",
                      "labName": "Исследование смешанного соединения проводников (второй вариант схемы)",
                      "labPictureFolder": "01-physics/03-electrodynamics/lab10/",
                          "labPages": [
                              {
                                  "title": " ЛАБОРАТОРНАЯ РАБОТА N10 ",
                                  "comment": [
                                    "Исследование смешанного соединения проводников",
                                    "(второй вариант схемы)"
                                  ]
                                },
                                {
                                  "title": "ЦЕЛЬ РАБОТЫ",
                                  "comment": [
                                    "Рассмотреть схему, имеющую цепь, состоящую из двух последовательно включенных резисторов, третий резистор подключен параллельно к одному из них и способен плавно изменять сопротивление всей цепи и, следовательно, ток от источника питания"
                                  ]
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Соберите схему."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_10_01.json",
                                    "pictureDark": "ed_10_01_d.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Для этого соберите установку, представленную на рисунке."
                                    ],
                                    "picture": "Порядок_эл_дин_лаб_раб_10_02.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Ручку потенциометра R3 поверните против часовой стрелки до упора.",
                                    "Замкните ключ К и измерьте ток в цепи."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_10_03.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Осторожно поворачивайте ручку потенциометра R3 по часовой стрелке.", "", 
                                    "Обратите внимание на плавное увеличение тока от источника питания по амперметру РА2.", "" ,
                                    "Это явление объясняется тем, что полное сопротивление цепи при уменьшении сопротивления резистора R3 уменьшается, но теперь шунтируется только один резистор R2."
                                    ],
                                    "picture": "Порядок_эл_дин_лаб_раб_10_04.json"
                                }, 
                                {
                                  "title": "ЗАДАНИЕ",
                                  "comment": [
                                    "Зарисуйте схему и сделайте выводы по проделанной работе."
                                    ]
                                }
                            ]
                    },
                    {
                      "labId":"lab11",
                      "labName": "Измерение ЭДС и внутреннего сопротивления источника тока",
                      "labPictureFolder": "01-physics/03-electrodynamics/lab11/",
                          "labPages": [
                              {
                                  "title": " ЛАБОРАТОРНАЯ РАБОТА N11 ",
                                  "comment": [
                                    "Измерение ЭДС и внутреннего сопротивления источника тока"
                                  ]
                                },
                                {
                                  "title": "ЦЕЛЬ РАБОТЫ",
                                  "comment": [
                                    "Изучить полную электрическую цепь, измерить ЭДС источника питания и его внутреннее сопротивление, а также исследовать влияние внутреннего сопротивления источника на его работу при различных выходных токах"
                                  ]
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Измерьте напряжение источника питания.",
                                    "Для этого соберите схему."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_11_01.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Занесите показания в таблицу."
                                    ],
                                    "table":[
                                      "Ток, А|0|0,35|0,5",
                                      "Напряжение ИП, В|||"
                                    ]
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "ЭДС источника питания измеряется вольтметром PV. Это измерение тем точнее, чем выше сопротивление вольтметра для тока, который потребляется вольтметром при измерении. Самое точное измерение будет в том случае, если ток, потребляемый вольтметром, равен 0.", "", 
                                    "Соберите схему."
                                    ],
                                    "picture": "Порядок_эл_дин_лаб_раб_11_02.json",
                                    "pictureDark": "ed_11_02_d.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Соберите установку."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_11_03.json"
                                },  
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Поверните ручку потенциометра R3 против часовой стрелки до упора.", "", 
                                    "При этом сопротивление потенциометра между гнездами 11 и 12 станет максимальным и равным 10 Ом. Замкните ключ.", "", 
                                    "Установите ток I в цепи равный 0,35 А, измерьте напряжение на выходе иисточника питания и занесите показания в таблицу."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_11_04.json"
                                },  
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Вращая ручку потенциометра по часовой стрелке доведите ток в цепи до 0,5 А, вновь измерьте напряжение источника питания и занесите показания в таблицу."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_11_05.json"
                                },
                                {
                                  "title": "ВЫВОД",
                                  "comment": [
                                    "Физически внутреннее сопротивление любого источника питания представляет собой сумму безвозвратных потерь источника при его работе. К потерям в реальной электрической цепи любого устройства относятся:",
                                    "- потери в проводах, по которым протекает ток, вследствие чего происходит их нагревание;",
                                    "- потери на перемагничивание магнитопровода трансформатора;",
                                    "- потери на вихревые токи в окружающих металлических элементах, вызванные переменными магнитными потоками рассеивания и т. д.", "", 
                                    "Действие внутреннего сопротивления проявляется в виде понижения напряжения на нагрузке при увеличении тока через нее."
                                    ]
                                }
                            ]
                    },
                    {
                      "labId":"lab12",
                      "labName": "Исследование лампы накаливания",
                      "labPictureFolder": "01-physics/03-electrodynamics/lab12/",
                          "labPages": [
                              {
                                  "title": " ЛАБОРАТОРНАЯ РАБОТА N12 ",
                                  "comment": [
                                    "Исследование лампы накаливания"
                                  ]
                                },
                                {
                                  "title": "ЦЕЛЬ РАБОТЫ",
                                  "comment": [
                                    "Исследовать маломощную лампу накаливания, свечение которой обеспечивается накаленной нитью, выполненной из металла с высоким удельным сопротивлением, способного выдерживать высокие температуры. Пронаблюдать зависимость ее сопротивления от протекающего через нее тока"
                                  ]
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Соберите схему."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_12_02.json",
                                    "pictureDark": "ed_12_02_d.json"
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Соберите установку."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_12_03.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Поверните ручку потенциометра R3 против часовой стрелки до упора.", 
                                    "Замкните ключ К. В цепи появится ток.", "", 
                                    "Потенциометром R3 установите его равным 0,4 А.", "", 
                                    "Измерив ток, запишите его значение в таблицу."
                                    ],
                                    "picture": "Порядок_эл_дин_лаб_раб_12_04.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                  ],
                                    "table":[
                                      "Ток, А|0,4|0,45|0,5|0,55|0,6",
                                      "Напряжение, В|||||"
                                    ]
                                },  
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Осторожно вращая ручку потенциометра R3 по часовой стрелке установите ряд токов через лампу в соответствии с данными таблицы, измеряя при этом напряжение на лампе.", "", 
                                    "Результаты измерений запишите в таблицу."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_12_05.json"
                                },  
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Постройте вольтамперную характеристику лампы накаливания- зависимость тока в лампе от напряжения на ней, пользуясь данными таблицы в координатных осях, показанных на графике."
                                    ],
                                    "picture":"График_эл_дин_лаб_раб_12_02.json",
                                    "pictureDark": "ed_graph_12_02_d.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Примерный график может быть построен следующим образом:"
                                    ],
                                    "picture":"График_эл_дин_лаб_раб_12_03.json",
                                    "pictureDark": "ed_graph_12_03_d.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Вычислите сопротивление нити для двух участков вольтамперной характеристики- в ее начале и конце.",
                                    "На начальном участке графика выбираем интервал токов ∆I^_1^_ (от 0 до 0,4 А), а на конечном- от ∆I^_2^_ (от 0,55 до 0,6 А).", "", 
                                    "Определяем по вольтамперной характеристике соответствующие интервалы напряжения на лампе- ∆U1 и ∆U^_2^_, являющимися проекциями этих интервалов на ось U.", "", 
                                    "Сопротивление нити на участках вольтамперной характеристики определяем как отношение прироста напряжения на лампе к соответствующему приросту тока R = ∆U / ∆I.","", 
                                    "Пользуясь полученными данными, вычислите сопротивления нити на двух участках и убедитесь, что сопротивление лампы при увеличении тока через нее сопровождается увеличением яркости свечения, мощности рассеивания и сопротивления."
                                    ]
                                },
                                {
                                  "title": "ВЫВОД",
                                  "comment": [
                                    "Элементы электрических цепей, сопротивление которых зависит от рассеиваемой мощности, имеют нелинейную вольтамперную характеристику и называются нелинейными.",
                                    "Вольтамперная характеристика лампы накаливания показывает, что с увеличением напряжения на ней увеличивается ток, яркость свечения и сопротивление нити накаливания."
                                    ]
                                }
                            ]
                    },
                    {
                      "labId":"lab13",
                      "labName": "Исследование лампы накаливания. Измерение работы и мощности электрического тока",
                      "labPictureFolder": "01-physics/03-electrodynamics/lab13/",
                          "labPages": [
                              {
                                  "title": " ЛАБОРАТОРНАЯ РАБОТА N13 ",
                                  "comment": [
                                    "Исследование лампы накаливания.",
                                    "Измерение работы и мощности электрического тока"
                                  ]
                                },
                                {
                                  "title": "ЦЕЛЬ РАБОТЫ",
                                  "comment": [
                                    "Исследовать маломощную лампу накаливания, свечение которой обеспечивается накаленной нитью, выполненной из металла с высоким удельным сопротивлением, способного выдерживать высокие температуры. Пронаблюдать зависимость ее сопротивления от протекающего через нее тока"
                                  ]
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Соберите схему."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_13_01.json",
                                    "pictureDark": "ed_13_01_d.json"
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Соберите установку."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_13_02.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Поверните ручку потенциометра R3 против часовой стрелки до упора.", 
                                    "Замкните ключ К. В цепи появится ток.", "", 
                                    "Потенциометром R3 установите его равным 0,4 А.", "", 
                                    "Измерив ток, запишите его значение в таблицу."
                                    ],
                                    "picture": "Порядок_эл_дин_лаб_раб_13_03.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                  ],
                                    "table":[
                                      "Ток, А|0,4|0,45|0,5|0,55|0,6",
                                      "Напряжение, В|||||"
                                    ]
                                },  
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Осторожно вращая ручку потенциометра R3 по часовой стрелке, установите ряд токов через лампу в соответствии с данными таблицы, измеряя при этом напряжение на лампе.", "", 
                                    "Результаты измерений запишите в таблицу."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_13_04.json"
                                },  
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Постройте вольтамперную характеристику лампы накаливания- зависимость тока в лампе от напряжения на ней, пользуясь данными таблицы в координатных осях, показанных на графике."
                                    ],
                                    "picture":"График_эл_дин_лаб_раб_13_01.json",
                                    "pictureDark": "ed_graph_13_01_d.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Примерный график может быть построен следующим образом:"
                                    ],
                                    "picture":"График_эл_дин_лаб_раб_13_02.json",
                                    "pictureDark": "ed_graph_13_02_d.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "По данным таблицы рассчитайте мощность, рассеиваемую на лампе во всем диапазоне токов и напряжений по формуле @@P = UI.", 
                                    "Результаты расчетов запишите в таблицу."
                                    ],
                                    "table":[
                                      "Ток, А|0,4|0,45|0,5|0,55|0,6",
                                      "Напряжение, В|||||",
                                      "Мощность, Вт|||||"
                                    ]
                                },
                                {
                                  "title": "ВЫВОД",
                                  "comment": [
                                    "Мощность, рассеиваемая на лампе, увеличивается при увеличении напряжения и тока. При этом увеличивается яркость свечения нити."
                                    ]
                                }
                            ]
                    },
                    {
                      "labId":"lab14",
                      "labName": "Исследование полупроводникового диода",
                      "labPictureFolder": "01-physics/03-electrodynamics/lab14/",
                          "labPages": [
                              {
                                  "title": " ЛАБОРАТОРНАЯ РАБОТА N14 ",
                                  "comment": [
                                    "Исследование полупроводникового диода"
                                  ]
                                },
                                {
                                  "title": "ЦЕЛЬ РАБОТЫ",
                                  "comment": [
                                    "Подтвердить одностороннюю проводимость полупроводникового диода, построить прямую ветвь его вольтамперной характеристики, измерить напряжение, при котором диод переходит в открытое состояние"
                                  ]
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "На рисунке показан диод, включенный по отношению к внешнему источнику в прямом направлении. В цепи появится прямой ток, направление которого обозначено стрелкой от анода к катоду (от «+» к «–»). Значение тока будет ограничено полным сопротивлением всей цепи. Сопротивление диода для прямого тока невелико и мало изменяется с изменением прямого тока, а падение напряжения на нем для разных типов выпрямительных диодов лежит в пределах от 0,25 В до 0,8 В при токе, не превышающем предельно допустимого значения. "
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_14_01.json",
                                    "pictureDark": "ed_14_01_d.json"
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Соберите установку.",
                                    "Ручку потенциометра R3 поверните против часовой стрелки до упора."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_14_02.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Замкните ключ К.", "", 
                                    "В цепи диода и лампы появится ток.", 
                                    "Осторожно вращайте ручку потенциометра R3 по часовой стрелке, увеличивая прямой ток.",
                                    "Убедитесь, что диод включен в цепь в прямом направлении, как показано на рисунке.",
                                    "Диод при таком включении ток пропускает, о чем свидетельствует горящая лампа."
                                    ],
                                    "picture": "Порядок_эл_дин_лаб_раб_14_03.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Соберите следующую установку."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_14_04.json"
                                },  
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Замкните ключ К.", "", 
                                    "Проследите путь возможного тока от +U^_ип^_ до -U^_ип^_.", "", 
                                    "Лампа гореть не будет, поскольку по пути придется встретить диод VD, включенный в обратном направлении, а при таком включении диод ток не проводит."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_14_05.json"
                                },  
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Соберите схему."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_14_06.json",
                                    "pictureDark": "ed_14_06_d.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Для этого соберите установку. Ручку потенциометра R3 поверните против часовой стрелки до упора.",
                                    "Замкните ключ К."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_14_07.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Очень осторожно поворачивайте ручку потенциометра R3 по часовой стрелке.",
                                    "Необходимо поймать момент, при котором начнет увеличиваться прямой ток диода, а напряжение на нем достигнет приблизительно 0,7 В. С этой точки начинается прямая ветвь вольтамперной характеристики выпрямительного кремниевого диода и быстрый рост тока при увеличении напряжения на диоде.",
                                    "Продолжая вращать ручку потенциометра по часовой стрелке, установите ток через диод 0,6 А и измерьте напряжение на нем."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_14_08.json"
                                },
                                {
                                  "title": "ЗАДАНИЕ",
                                  "comment": [
                                    "Постройте полную вольтамперную характеристику диода.",
                                    "Приблизительно она показана на рисунке. Этого вполне достаточно, чтобы оценить поведение диода на прямой ветви характеристики.",
                                    "Снять обратную ветвь не удастся- для этого нет соответствующего оборудования."
                                    ],
                                    "picture":"Задание_эл_дин_раб_14.json"
                                }
                            ]
                    },
                    {
                      "labId":"lab15",
                      "labName": "Наблюдение магнитного поля линейного проводника с током",
                      "labPictureFolder": "01-physics/03-electrodynamics/lab15/",
                          "labPages": [
                              {
                                  "title": " ЛАБОРАТОРНАЯ РАБОТА N15 ",
                                  "comment": [
                                    "Наблюдение магнитного поля линейного проводника с током"
                                  ]
                                },
                                {
                                  "title": "ЦЕЛЬ РАБОТЫ",
                                  "comment": [
                                    "Рассмотреть взаимодействие магнитной стрелки компаса с магнитным полем линейного проводника с током"
                                  ]
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Соберите схему опыта с учетом параллельности проводника с током стрелке компаса.",
                                    "Ручку потенциометра R3 поверните против часовой стрелки до упора.", "", 
                                    "Замкните ключ К.",
                                    "Осторожно вращая ручку потенциометра, установите ток 3 А и сразу же разомкните ключ К.", 
                                    "В дальнейшем при выполнении опыта положение ручки потенциометра не изменяйте."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_15_01.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Провод, соединяющий гнезда -ИП и 12, положите на компас параллельно стрелке.", "", 
                                    "Замкните ключ К и сразу же разомкните, успев заметить, что стрелка компаса под действием магнитного поля, возникшего вокруг линейного проводника с током, повернулась в положение, перпендикулярное проводу.",
                                    "Запомните это положение."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_15_02.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Поменяйте местами вилки соединительного провода источника питания. Также поменяйте местами вилки соединительного провода амперметра.", "", 
                                    "Повторите опыт для тока противоположного направления.",
                                    "Убедитесь, что стрелка компаса развернется на 180 градусов, что объясняется изменением направления силовых линий магнитного поля на противоположное."
                                    ],
                                    "picture": "Порядок_эл_дин_лаб_раб_15_03.json"
                                },
                                {
                                  "title": "ЗАДАНИЕ",
                                  "comment": [
                                    "Сделайте выводы о взаимодействии поля проводника с током и компасом."
                                    ]
                                }
                            ]
                    },
                    {
                      "labId":"lab16",
                      "labName": "Наблюдение магнитного поля катушки с током и ферромагнитным сердечником",
                      "labPictureFolder": "01-physics/03-electrodynamics/lab16/",
                          "labPages": [
                              {
                                  "title": " ЛАБОРАТОРНАЯ РАБОТА N16 ",
                                  "comment": [
                                    "Наблюдение магнитного поля катушки с током и ферромагнитным сердечником"
                                  ]
                                },
                                {
                                  "title": "ЦЕЛЬ РАБОТЫ",
                                  "comment": [
                                    "Пронаблюдать магнитное поле катушки с током без сердечника и с сердечником"
                                  ]
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Соберите установку.",
                                    "Ручку потенциометра R3 поверните против часовой стрелки до упора.", "", 
                                    "Замкните ключ К.",
                                    "Осторожно вращая ручку потенциометра, установите ток 0,6 А.", 
                                    "В дальнейшем при выполнении опыта положение ручки потенциометра не изменяйте."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_16_01.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Замкните ключ К и поднесите компас к катушке.", "", 
                                    "Убедитесь, что магнитное поле, возникшее в катушке, сориентирует стрелку в осевом направлении.", "",
                                    "Разомкните ключ К."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_16_02.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Поместите в корпус катушки короткий стальной стержень. Замкните ключ К.", "", 
                                    "Убедитесь, что стержень примагнитился.", "",
                                    "Поднесите компас и наблюдайте ориентирующее действие поля на стрелку компаса."
                                    ],
                                    "picture": "Порядок_эл_дин_лаб_раб_16_04.json"
                                },
                                {
                                  "title": "ЗАДАНИЕ",
                                  "comment": [
                                    "Повторите опыт, изменив направление тока в катушке.",
                                    "Для этого поменяйте местами вилки соединительных проводов источника питания, а также вилки соединительных проводов амперметра и повторите опыт для тока противоположного направления.", "", 
                                    "Убедитесь, что стрелка компаса развернется на 180 градусов, что объясняется изменением направления силовых линий магнитного поля на противоположное."
                                    ]
                                }
                            ]
                    },
                    {
                      "labId":"lab17",
                      "labName": "Наблюдение магнитного поля кольцевого тока",
                      "labPictureFolder": "01-physics/03-electrodynamics/lab17/",
                          "labPages": [
                              {
                                  "title": " ЛАБОРАТОРНАЯ РАБОТА N17 ",
                                  "comment": [
                                    "Наблюдение магнитного поля кольцевого тока"
                                  ]
                                },
                                {
                                  "title": "ЦЕЛЬ РАБОТЫ",
                                  "comment": [
                                    "Рассмотреть взаимодействие магнитной стрелки компаса с магнитным полем кольцевого проводника с током"
                                  ]
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Соберите установку.", "",
                                    "Из соединительного провода образуйте виток.", "", 
                                    "Ручку потенциометра R3 поверните против часовой стрелки до упора. Замкните ключ К.",
                                    "Осторожно вращая ручку потенциометра по часовой стрелке, установите ток 3 А и сразу же разомкните ключ.", "",
                                    "В дальнейшем при выполнении опыта положение ручки потенциометра не изменяйте."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_17_01.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Замкните ключ К.", "", 
                                    "Убедитесь, что магнитное поле витка с током ориентирует стрелку."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_17_02.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Измените направление тока в витке на противоположное и убедитесь, что стрелка ориентируется в противоположном направлении."
                                    ],
                                    "picture": "Порядок_эл_дин_лаб_раб_17_03.json"
                                }
                            ]
                    },
                    {
                      "labId":"lab18",
                      "labName": "Наблюдение явления взаимоиндукции",
                      "labPictureFolder": "01-physics/03-electrodynamics/lab18/",
                          "labPages": [
                              {
                                  "title": " ЛАБОРАТОРНАЯ РАБОТА N18 ",
                                  "comment": [
                                    "Наблюдение явления взаимоиндукции"
                                  ]
                                },
                                {
                                  "title": "ЦЕЛЬ РАБОТЫ",
                                  "comment": [
                                    "Наблюдение явления взаимоиндукции в виде передачи электрической энергии от одной катушки к другой через магнитное поле"
                                  ]
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Соберите установку."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_18_01.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Замкните ключ К.", "", 
                                    "В первой катушке и вокруг нее появится магнитное поле, пронизывающее витки второй катушки, замкнутой миллиамперметром РА1.", "",
                                    "Стрелка миллиамперметра сначала отклонится на 1-2 деления шкалы, затем отклонится в противоположную сторону и вернется на нулевую отметку шкалы.", "", 
                                    "Первое отклонение произойдет из-за тока наведенной ЭДС взаимоиндукции, второе (противоположное) - из-за тока разряда катушки индуктивности."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_18_02.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Если направление тока в первичной катушке изменить на обратное, первое отклонение стрелки миллиамперметра произойдет в обратном направлении.", "", 
                                    "Замкните магнитопровод пластинкой из стали и повторите опыт.", "",
                                    "Убедитесь, что при замкнутом магнитопроводе взаимодействие катушек будет заметнее."
                                    ],
                                    "picture": "Порядок_эл_дин_лаб_раб_18_03.json"
                                }
                            ]
                    },
                    {
                      "labId":"lab19",
                      "labName": "Наблюдение действия трансформатора",
                      "labPictureFolder": "01-physics/03-electrodynamics/lab19/",
                          "labPages": [
                              {
                                  "title": " ЛАБОРАТОРНАЯ РАБОТА N19 ",
                                  "comment": [
                                    "Наблюдение действия трансформатора"
                                  ]
                                },
                                {
                                  "title": "ЦЕЛЬ РАБОТЫ",
                                  "comment": [
                                    "Пронаблюдать явление взаимоиндукции в виде передачи электрической энергии от одной катушки к другой через магнитное поле с перемыканием торцов катушек пластинкой из ферромагнитного материала"
                                  ]
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Соберите установку.",
                                    "Ручку потенциометра R3 поверните против часовой стрелки до упора.", "", 
                                    "Замкните ключ К.",
                                    "Осторожно вращая ручку потенциометра по часовой стрелке, установите ток 3 А и сразу же разомкните ключ.", 
                                    "В дальнейшем при выполнении опыта положение ручки потенциометра не изменяйте."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_19_01.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Замкните ключ К.", "", 
                                    "В первой катушке и вокруг нее появится магнитное поле, пронизывающее витки второй катушки, замкнутой миллиамперметром РА1.", "",
                                    "Стрелка миллиамперметра сначала отклонится на 1-2 деления шкалы, затем отклонится в противоположную сторону и вернется на нулевую отметку шкалы.", "", 
                                    "Первое отклонение произойдет из-за тока наведенной ЭДС взаимоиндукции, второе (противоположное) - из-за тока разряда катушки индуктивности."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_19_02.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Если направление тока в первичной катушке изменить на обратное, первое отклонение стрелки миллиамперметра произойдет в обратном направлении.", "", 
                                    "Замкните магнитопровод пластинкой из стали и повторите опыт.", "",
                                    "Убедитесь, что при замкнутом магнитопроводе взаимодействие катушек будет заметнее."
                                    ],
                                    "picture": "Порядок_эл_дин_лаб_раб_19_03.json"
                                },
                                {
                                  "title": "ВЫВОД",
                                  "comment": [
                                    "При перемыкании торцов катушек пластинкой из ферромагнитного материала передача электрической энергии заметно увеличивается."
                                    ]
                                }
                            ]
                    },
                    {
                      "labId":"lab20",
                      "labName": "Определение полюса немаркированного магнита",
                      "labPictureFolder": "01-physics/03-electrodynamics/lab20/",
                          "labPages": [
                              {
                                  "title": " ЛАБОРАТОРНАЯ РАБОТА N20 ",
                                  "comment": [
                                    "Определение полюса немаркированного магнита"
                                  ]
                                },
                                {
                                  "title": "ЦЕЛЬ РАБОТЫ",
                                  "comment": [
                                    "Определение полюса немаркированного магнита"
                                  ]
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Один из двух полосовых магнитов в комплекте оборудования не маркирован.",
                                    "Для определения полярности его намагниченности (маркировки) используйте компас в качестве простейшего индикатора.", "", 
                                    "Если к концу магнита притянется северный полюс стрелки можно сделать вывод, что это @@южный полюс@@. ", 
                                    "И наоборот- южный полюс стрелки притянется к @@северному полюсу магнита@@."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_20_01.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Для маркировки немаркированного магнита можно также использовать второй магнит, но маркированный.", "", 
                                    "Полюс немаркированного магнита имеет противоположную полярность, если притягивается к концу магнита известной полярности, то есть магниты испытывают взаимное притяжение (если их полюса противоположны): северный полюс будет притягиваться к южному, а южный к северному."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_20_02.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Если же сблизить магниты одинаковыми полюсами, удастся ощутить их взаимное отталкивание, что говорит о том, что полюса магнитов одинаковые."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_20_03.json"
                                }
                            ]
                    },
                    {
                      "labId":"lab21",
                      "labName": "Сборка и испытание электромагнита",
                      "labPictureFolder": "01-physics/03-electrodynamics/lab21/",
                          "labPages": [
                              {
                                  "title": " ЛАБОРАТОРНАЯ РАБОТА N21 ",
                                  "comment": [
                                    "Сборка и испытание электромагнита"
                                  ]
                                },
                                {
                                  "title": "ЦЕЛЬ РАБОТЫ",
                                  "comment": [
                                    "Сборка и испытание электромагнита"
                                  ]
                                },
                                {
                                  "title": "ПОДГОТОВКА К РАБОТЕ",
                                  "comment": [
                                    "Соберите установку."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_21_01.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Ручку потенциометра R3 поверните против часовой стрелки до упора. Замкните ключ К.", "", 
                                    "Осторожно вращая ручку потенциометра R3 по часовой стрелке, установите ток 3 А и сразу же разомкните ключ.", "",
                                    "В дальнейшем при выполнении опыта положение ручки потенциометра не изменяйте."
                                    ],
                                    "picture":"Порядок_эл_дин_лаб_раб_21_02.json"
                                },
                                {
                                  "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                                  "comment": [
                                    "Замкните ключ К и поднесите пластинку к торцам магнитопровода. Пластинка будет прочно притянута.", "", 
                                    "Разомкните ключ К. Пластинка упадет. Так работает электромагнит.", "",
                                    "Условием нормальной работы будет плаcтинка, изготовленная из низколегированной стали, которая почти не сохраняет остаточную намагниченность.", "", 
                                    "Лучшим материалом для пластинки является трансформаторная сталь, которая практически не намагничивается."
                                    ],
                                    "picture": "Порядок_эл_дин_лаб_раб_21_03.json"
                                }
                            ]
                    }
                  ]
              }, {
                  "sectionName": "Оптика",
                 "sectionId": "04-optics", 
                 "labList": [
                  {
                    "labId":"lab01",
                    "labName": "Исследование явления отражения света",
                    "labPictureFolder": "01-physics/04-optics/lab01/",
                    "labPages": [
                      {
                        "title": " ЛАБОРАТОРНАЯ РАБОТА N1 ",
                        "comment": [
                          "Исследование явления отражения света"
                        ]
                      },
                      {
                        "title": "ЦЕЛЬ РАБОТЫ",
                        "comment": [
                          "Установить зависимость угла отражения света от угла падения света"
                        ]
                      },
                      {
                        "title": "ОБОРУДОВАНИЕ",
                        "comment": [
                          "Коврик", "3 булавки", "Линейка", "Зеркало", "Транспортир", "Остро заточенный карандаш", "Лист бумаги"
                          ],
                          "picture": "Оборудование_оптика_лаб_01.json"
                      },
                      {
                        "title": "ПОДГОТОВКА К РАБОТЕ",
                        "comment": [
                          "Измерьте угол падения света от предмета и угол отражения изображения этого предмета от зеркала.", 
                          "Сопоставьте их между собой.", "",
                          "Для этого приготовьте коврик и положите поверх него чистый лист белой бумаги без проколов."
                          ],
                          "picture":"Краткое_описание_оптика_лаб_01_01.json"
                      },
                      {
                        "title": "ПОДГОТОВКА К РАБОТЕ",
                        "comment": [
                          "Проведите прямую линию - линию размещения зеркала на листе белой бумаги около середины листа бумаги.", "", 
                          "Зеркало разместите поверх листа белой бумаги так, чтобы его ближайшая плоскость совпала с линией на листе бумаги."
                          ],
                          "picture": "Краткое_описание_оптика_лаб_01_02.json"
                      },
                      {
                        "title": "ПОДГОТОВКА К РАБОТЕ",
                        "comment": [
                          "Положение зеркала не изменяйте. Относительно него будут производиться измерения, позволяющие геометрически определить углы падения и отражения света от предмета.", "",
                          "Булавку воткните через бумагу в коврик так, чтобы она была вплотную к зеркалу ближе к середине этого зеркала."
                          ],
                          "picture":"Краткое_описание_оптика_лаб_01_03.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Вторую булавку воткните правее и ближе к себе - она будет являться предметом, чье отражение будем наблюдать в зеркале."
                          ],
                          "picture":"Краткое_описание_оптика_лаб_01_04.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Измените взгляд на зеркало или переверните коврик так, чтобы булавка, являющаяся точкой отражения в зеркале, совпала с изображением предмета в зеркале.", 
                          "Через эти две булавки можно провести линию падения света от предмета на зеркало.", "", 
                          "Добавьте булавку, которая будет определять угол отражения света от предмета в точке отражения в зеркале.", 
                          "Для этого воткните еще одну булавку так, чтобы она перекрывала отражение предмета, совпадающего с булавкой в точке отражения в зеркале."
                          ],
                          "picture":"Краткое_описание_оптика_лаб_01_05.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Через воткнутые булавки можно провести и второую прямую, которая пересечется с точкой отражения в зекале.", 
                          "Удалите булавки и зеркало с листа бумаги и проведите прямые линии от точки отражения в зеркале через точки предмета и отражения в зеркале.", "", 
                          "Наложите транспортир на линию отражения в зеркале в точке отражения и заметьте, под каким углом расположены линии падения и отражения света по отношению к линии зеркала." 
                          ],
                          "picture":"Краткое_описание_оптика_лаб_01_06.json"
                      },
                      {
                        "title": "ЗАДАНИЕ",
                        "comment": [
                          "Поскольку точка предмета для отражения в зеркале была выбрана произвольно и точка отражения в зеркале тоже была выбрана произвольно, то, выбирая любое место для определения угла отражения и угла падения, в итоге Вы получите равенство угла падения углу отражения.", 
                          "Повторите определение угла падения и угла отражения при других положениях предмета, выбрав произвольно точку отражения в зеркале 3-4 раза."
                          ],
                          "picture":"Краткое_описание_оптика_лаб_01_07.json"
                      }                    
                    ]
                  },
                  {
                    "labId":"lab02",
                    "labName": "Построение изображения предмета в плоском зеркале",
                    "labPictureFolder": "01-physics/04-optics/lab02/",
                    "labPages": [
                      {
                        "title": " ЛАБОРАТОРНАЯ РАБОТА N2 ",
                        "comment": [
                          "Построение изображения предмета в плоском зеркале"
                        ]
                      },
                      {
                        "title": "ЦЕЛЬ РАБОТЫ",
                        "comment": [
                          "Установить соотношение расстояний от предмета до зеркала и от зеркала до мнимого изображения предмета"
                        ]
                      },
                      {
                        "title": "ОБОРУДОВАНИЕ",
                        "comment": [
                          "Коврик", "4 булавки", "Линейка", "Зеркало", "Остро заточенный карандаш", "Лист бумаги"
                          ],
                          "picture": "Оборудование_оптика_лаб_02.json"
                      },
                      {
                        "title": "ПОДГОТОВКА К РАБОТЕ",
                        "comment": [
                          "Измерьте расстояние от мнимого изображения предмета до зеркала и сопоставьте его с расстоянием от реального предмета до зеркала.", 
                          "Для этого приготовьте коврик и положите поверх него чистый без проколов лист белой бумаги. Зеркало разместите поверх листа белой бумаги так, чтобы его ближайшая плоскость была ближе к вам, чем середина листа бумаги, чтобы за ним можно было построить мнимое изображение предмета, находящегося перед зеркалом."
                          ],
                          "picture":"Краткое_описание_оптика_лаб_02_01.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Начертите линию вдоль ближайшей грани зеркала, относительно которой будет определено расстояние до мнимого изображения предмета. Положение зеркала зафиксируйте и не изменяйте.",
                          "Относительно него будут производиться измерения, позволяющие геометрически определить по пересечению линий положение мнимого предмета."
                          ],
                          "picture": "Краткое_описание_оптика_лаб_02_02.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Булавку, являющуюся предметом, чье мнимое изображение будем определять, воткните через бумагу в коврик так, чтобы она была в 10–15 мм от зеркала. "
                          ],
                          "picture":"Краткое_описание_оптика_лаб_02_03.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Вторую булавку воткните ближе к поверхности зеркала на 5–7 мм и левее первой булавки . Измените взгляд на зеркало так, чтобы отражения булавок совпали в зеркале."
                          ],
                          "picture":"Краткое_описание_оптика_лаб_02_04.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Третью булавку воткните в коврик через бумагу на 10–15 мм дальше от первой булавки по отношению к зеркалу так, чтобы она совпала с отражением первой и второй булавки в зеркале."
                          ],
                          "picture":"Краткое_описание_оптика_лаб_02_05.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Через воткнутые булавки можно провести прямую, которая пересечется с мнимым изображение предмета (булавки) за зеркалом.", 
                          "Проведите вторую прямую к мнимому изображению. Для этого выдерните первую вторую булавку (ближе к зеркалу) и воткните ее немного правее на 15-20 мм вдоль зеркала."
                          ],
                          "picture":"Краткое_описание_оптика_лаб_02_06.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Поверните аккуратно коврик и измените взгляд на зеркало так, чтобы положение этой булавки совпало с изображением предмета, точно также выдерните вторую булавку и в 10-15 мм от первой булавки воткните в коврик вторую булавку.", 
                          "Теперь первая и вторая булавки совпадают с изображением предмета в зеркале."
                          ],
                          "picture":"Краткое_описание_оптика_лаб_02_07.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Положение предметов на коврике будет выглядеть следующим образом. Через воткнутые булавки можно провести и вторую прямую, которая пересечется с мнимым изображением предмета за зеркалом."
                          ],
                          "picture":"Краткое_описание_оптика_лаб_02_08.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Проведите прямую через два освободившихся отверстия до возможного положения мнимого изображения предмета."
                          ],
                          "picture":"Краткое_описание_оптика_лаб_02_09.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Удалите первую и вторую булавки, освободив два вторых отверстия. ", 
                          "Проведите через них вторую прямую до пересечения с первой прямой."
                          ],
                          "picture":"Краткое_описание_оптика_лаб_02_10.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Удалите третью булавку и через отверстие от нее проведите третью прямую до пересечения двух проведенных прямых."
                          ],
                          "picture":"Краткое_описание_оптика_лаб_02_11.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Определите расстояние от линии зеркала до точки пересечения трех прямых – точки мнимого изображения предмета L^_1^_ и расстояние от предмета до линии зеркала L^_2^_ с помощью линейки. Убедитесь, что L^_1^_ = L^_2^_.",
                          "Таким образом, мы измерили расстояние от зеркала до мнимого изображения предмета L = L^_1^_ + L^_2^_."
                          ],
                          "picture":"Краткое_описание_оптика_лаб_02_12.json"
                      }
                    ]
                  },
                  {
                    "labId":"lab03",
                    "labName": "Измерение фокусного расстояния собирающей линзы ",
                    "labPictureFolder": "01-physics/04-optics/lab03/",
                    "labPages": [
                      {
                        "title": " ЛАБОРАТОРНАЯ РАБОТА N3 ",
                        "comment": [
                          "Измерение фокусного расстояния собирающей линзы "
                        ]
                      },
                      {
                        "title": "ЦЕЛЬ РАБОТЫ",
                        "comment": [
                          "Выполнить измерение фокусного расстояния собирающей линзы при освещении ее удаленным источником света"
                        ]
                      },
                      {
                        "title": "ОБОРУДОВАНИЕ",
                        "comment": [
                          "Источник питания", "Соединительные провода", "Оптическая скамья", 
                          "Собирающая линза Л^_2^_ (50 мм) на рейтере", "Рассеивающая линза Л^_3^_ (-75 мм) на рейтере", 
                          "Источник света на рейтере", "Экран", "Коврик"
                          ],
                          "picture": "oborudovanie_optics_03.json",
                          "pictureDark": "oborudovanie_03d.json"
                      },
                      {
                        "title": "ПОДГОТОВКА К РАБОТЕ",
                        "comment": [
                          "Подключите источник света соединительными проводами к источнику питания. На источнике питания установите его максимальное напряжение. ", 
                          "Включите тумблером на источнике питания искусственный источник света. "
                          ],
                          "picture":"optics_03_02.json"
                      },
                      {
                        "title": "ПОДГОТОВКА К РАБОТЕ",
                        "comment": [
                          "Расположите источник света, собирающую линзу Л^_2^_, рассеивающую линзу Л^_3^_ и экран на оптической скамье так, как показано на рисунке.", "", 
                          "Расположение объектов на скамье: источник света – 2 см, рассеивающая линза – 16 см, экран - 39 см, собирающая линза – 34 см."
                          ],
                          "picture": "optics_03_03.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "На экране вы увидите размытое изображение, внутри которого находится контрастное сфокусированное изображение.", "",
                          "Сместите собирающую линзу к экрану на несколько сантиметров, а затем сместите от экрана, от первоначального положения тоже на несколько сантиметров. Изображение теряет контрастноть и начинает размываться, начинается отрыв внутреннего контрасного изображения от размытого.",
                          "Самая высокая контрастность размытого изображения и его минимальный размер при контрастном внутреннем изображении и может соответствовать положению собирающей линзы в фокусе от экрана."
                          ],
                          "picture":"optics_03_04.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Повторите перемещения для собирающей линзы и проанализируйте изображения на экране при разных ее положениях (например, рассеивающая линза – 4 см, 8 см). ",
                          "При самом четком изображении можно определить фокус собирающей линзы."
                          ]
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Для определения фокусного расстояния собирающей линзы можно использовать и другие источники света, например лампы дневного света на потолке помещения.", "",
                          "Соберите установку с использованием коврика так, чтобы лампа дневного света на потолке помещения через собирающую линзу отразилась на экране."
                          ],
                          "picture":"optics_03_05.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "В фокусе линзы вы увидите четкое изображение источника света на экране. Смещение линзы от фокусного расстояния приводит к размытию изображения на экране. ", 
                          "Таким образом можно проверить правильность определения фокусного расстояния собирающей линзы Л^_2^_."
                          ],
                          "picture":"optics_03_06.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "При использовании другого удаленного источника света, например, лампы дневного света на потолке помещения, можно тоже определить фокусное расстояние собирающей линзы.",
                          "Соберите установку с использованием коврика так, чтобы лампа дневного света на потолке помещения через собирающую линзу отразилась на экране."
                          ],
                          "picture":"optics_03_07.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Сместите собирающую линзу от фокусного расстояния и наблюдайте размытие изображения на экране.",
                          "Таким образом еще раз подтверждается правильность способа определения фокусного расстояния линзы Л^_2^_."
                          ],
                          "picture":"optics_03_08.json"
                      }                  
                    ]
                  },
                  {
                    "labId":"lab04",
                    "labName": "Измерение оптической силы собирающей линзы",
                    "labPictureFolder": "01-physics/04-optics/lab04/",
                    "labPages": [
                      {
                        "title": " ЛАБОРАТОРНАЯ РАБОТА N4 ",
                        "comment": [
                          "Измерение оптической силы собирающей линзы"
                        ]
                      },
                      {
                        "title": "ЦЕЛЬ РАБОТЫ",
                        "comment": [
                          "Выполнить измерение оптической силы линзы (собирающей линзы), основанное на использовании понятия «фокус линзы»"
                        ]
                      },
                      {
                        "title": "ОБОРУДОВАНИЕ",
                        "comment": [
                          "Источник питания", "Соединительные провода", "Оптическая скамья", "Собирающая линза Л^_2^_ (50 мм) на рейтере", "Собирающая линза Л^_1^_ (100 мм) на рейтере", "Рейтер с источником света", "Экран", "Слайд-рамка на магнитных держателях", "Полоска белой бумаги", "Канцелярский зажим для крепления бумаги"
                          ],
                          "picture": "oborudovanie_04.json",
                          "pictureDark": "oborudovanie_04djson"
                      },
                      {
                        "title": "ПОДГОТОВКА К РАБОТЕ",
                        "comment": [
                          "Подключите источник света соединительными проводами к источнику питания. На источнике питания установите его максимальное напряжение.", 
                          "Включите тумблером на источнике питания искусственный источник света."
                          ],
                          "picture":"optics_04_01.json"
                      },
                      {
                        "title": "ПОДГОТОВКА К РАБОТЕ",
                        "comment": [
                          "На экран установите полоску белой бумаги шириной ~ 4 см, длиной ~ 10 см так, чтобы она заходила за края экрана и крепилась отогнутыми краями. Для надежности закрепите ее канцелярским зажимом для крепления бумаги.", "", 
                          "Расположите источник света, собирающую линзу Л^_2^_ и экран на оптической скамье так, как показано на рисунке.",
                          "Расположение объектов на скамье: источник света – 2 см, рассеивающая линза Л^_2^_ – 7 см, экран - 39 см. ", 
                          "Рамку-слайд на магнитных держателях установите на источник света, найдя отверстие в рамке по ее центру. При креплении ориентируйтесь на пунктиры, показывающие положение центра излучения источника света."
                          ],
                          "picture": "optics_04_02.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Установите фокус линзы Л^_2^_. Для этого перемещайте линзу Л^_2^_ вдоль скамьи, изменяя ее положение пока изображение на бумаге, закрепленной на экране, не будет изменяться в диаметре.",
                          "После линзы свет распространяется пучком, не меняющим свой диаметр."
                          ],
                          "picture":"optics_04_03.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Добившись неизменности диаметра изображения на экране при перемещении вдоль скамьи, подбирая положение на скамье собирающей линзы, измерьте расстояние от источника света (2 см) до собирающей линзы Л^_2^_ (7 см). Это и будет фокусное расстояние линзы F.",
                          ]
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Переведите это расстояние в метры и рассчитайте оптическую силу линзы по формуле:",
                          "##@@D = 1 / F, дтпр (диоптрия)"
                          ],
                          "picture":"optics_04_04.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Поменяйте собирающую линзу Л^_2^_ на Л^_1^_.",
                          "Расположение объектов на скамье: источник света – 2 см, собирающая линза Л^_1^_ – 11,8 см, экран - 39 см)."
                          ],
                          "picture":"optics_04_05.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Повторите приведенные ранее действия по нахождению фокусного расстояния."
                          ],
                          "picture":"optics_04_06.json"
                      }                 
                    ]
                  },
                  {
                    "labId":"lab05",
                    "labName": "Измерение фокусного расстояния собирающей линзы с использованием формуле линзы",
                    "labPictureFolder": "01-physics/04-optics/lab05/",
                    "labPages": [
                      {
                        "title": " ЛАБОРАТОРНАЯ РАБОТА N5 ",
                        "comment": [
                          "Измерение фокусного расстояния собирающей линзы с использованием формуле линзы"
                        ]
                      },
                      {
                        "title": "ЦЕЛЬ РАБОТЫ",
                        "comment": [
                          "Выполнить измерение фокусного расстояния собирающей линзы с использованием соотношения фокусного расстояния",
                          " с расстоянием между линзой и предметом, а также расстоянием между линзой и изображением"
                        ]
                      },
                      {
                        "title": "ОБОРУДОВАНИЕ",
                        "comment": [
                          "Источник питания", "Соединительные провода", "Оптическая скамья", "Собирающая линза Л^_2^_ (50 мм) на рейтере", "Рейтер для рамок", "Источник света на рейтере", "Экран", "Слайд-рамка ", "Полоска белой бумаги", "Канцелярский зажим для крепления бумаги"
                          ],
                          "picture": "oborudovanie_05.json",
                          "pictureDark": "oborudovanie_05d.json"
                      },
                      {
                        "title": "ПОДГОТОВКА К РАБОТЕ",
                        "comment": [
                          "Подключите источник света соединительными проводами к источнику питания 0…7,5 В и включите его тумблером на нем. Установите максимальное напряжение на его выходе его регулятором.", 
                          "На экран установите полоску белой бумаги так, чтобы она заходила за края экрана и крепилась отогнутыми краями. Для надежности закрепите ее канцелярским зажимом.", "",
                          "Расположите на оптической скамье источник света на рейтере, рейтер для рамок с закрепленной на ней рамкой, собирающую линзу Л^_2^_ и экран так, как показано на рисунке.",
                          "Расположение объектов на скамье: источник света – 2 см, рейтер для рамок с рамкой слайдом – 8 см, собирающая линза Л^_2^_ – 14,5 см, экран - 30 см.  "
                          ],
                          "picture":"optics_05_01.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Изменением положения собирающей линзы добейтесь сфокусированного изображения на экране.", "", 
                          "Зафиксируйте положение рейтера с собирающей линзой (это точка отсчета для определения перемещения линзы по скамье, 14,5 см). ",
                          "После этого переместите собирающую линзу в сторону экрана до получения уменьшенного сфокусированного изображения предмета."
                          ],
                          "picture": "optics_05_02.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Зафиксируйте положение рейтера с собирающей линзой (это точка отсчета для определения перемещения линзы по скамье, 14,5 см). После этого переместите собирающую линзу в сторону экрана до получения уменьшенного сфокусированного изображения предмета.", "",
                          "Зафиксируйте положение собирающей линзой на рейтере (это точка для определения перемещения линзы по скамье, 23 см)."
                          ],
                          "picture":"optics_05_03.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Определите длину скамьи от рамки-слайда на рейтере до экрана – l:",
                          "##@@l = 30 – 8 = 22 (см),","", 
                          "Определите перемещение линзы по скамье - S:",
                          "##@@S = 23–14,5 = 8,5 (см).", "", 
                          "Определите фокусное расстояние собирающей линзы на основе формулы линзы:",
                          "##@@1/f = (2 / (l - S)) + (2 / (l + S)."
                          ]
                      }                    
                    ]
                  },
                  {
                    "labId":"lab06",
                    "labName": "Измерение фокусного расстояния рассеивающей линзы",
                    "labPictureFolder": "01-physics/04-optics/lab06/",
                    "labPages": [
                      {
                        "title": " ЛАБОРАТОРНАЯ РАБОТА N6 ",
                        "comment": [
                          "Измерение фокусного расстояния рассеивающей линзы"
                        ]
                      },
                      {
                        "title": "ЦЕЛЬ РАБОТЫ",
                        "comment": [
                          "Выполнить измерение фокусного расстояния рассеивающей линзы"
                        ]
                      },
                      {
                        "title": "ОБОРУДОВАНИЕ",
                        "comment": [
                          "Источник питания", "Соединительные провода", "Оптическая скамья", "Собирающая линза Л^_2^_ (50 мм) на рейтере", "Рассеивающая линза Л^_3^_ (-75 мм) на рейтере", "Источник света на рейтере", "Экран", "Слайд-рамка ", "Полоска белой бумаги", "Канцелярский зажим для крепления бумаги"
                          ],
                          "picture": "oborudovanie_06.json",
                          "pictureDark": "oborudovanie_06d.json"
                      },
                      {
                        "title": "ПОДГОТОВКА К РАБОТЕ",
                        "comment": [
                          "Подключите источник света соединительными проводами к источнику питания 0…7,5 В и включите его тумблером на нем. Установите максимальное напряжение на его выходе его регулятором.", 
                          "На экран установите полоску белой бумаги так, чтобы она заходила за края экрана и крепилась отогнутыми краями. Для надежности закрепите ее канцелярским зажимом.", "",
                          "Расположите источник света на рейтере, собирающую линзу Л^_2^_ на рейтере и экран так, как показано на рисунке.",
                          "Расположение объектов на скамье: источник света – 2 см, собирающая линза Л^_2^_ – 16, экран - 24 см. "
                          ],
                          "picture":"optics_06_01.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Рамку-слайд на магнитных держателях установите на рейтер с источником света так, как показано на рисунке.", "", 
                          "Найдите отверстие в рамке по ее центру. При креплении рамки-слайда ориентируйтесь на пунктиры, показывающие положение центра излучения источника света на рейтере, чтобы разместить отверстие в рамке по центру источника света."
                          ],
                          "picture": "optics_06_02.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Перемещая собирающую линзу, получите на экране четкое изображение источника света. ", "",
                          "Зафиксируйте положение рейтера с экраном."
                          ],
                          "picture":"optics_06_03.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Поместите на скамью рассеивающую линзу так, как приведено на рисунке. ",
                          "Это точка отсчета для определения расстояния до первого фокусного расстояния S’ = f и до второго фокусного расстояния S” = d.","", 
                          "Отодвигая рейтер с экраном добейтесь четкого изображения источника света на экране.",
                          "Зафиксируйте положение h рейтера с экраном на скамье."
                          ],
                          "picture":"optics_06_04.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Определите расстояние от рассеивающей линзы до зафиксированного первого положения экрана:",
                          "##@@f = S’ – h ", 
                          "Пример: f = 24–20 = 4 (см)", "",
                          "Расстояние от рассеивающей линзы до зафиксированного второго положения экрана:",
                          "##Пример: d = 29-20 = 9 (см).", "",
                          "Используя ранее приведенную формулу из лабораторной работы №5, определите фокус рассеивающей линзы и оптическую силу рассеивающей линзы:",
                          "##@@-D = -1 / F = (1 / d) - (1 / F)"
                          ]
                      }                  
                    ]
                  },
                  {
                    "labId":"lab07",
                    "labName": "Получение изображения с помощью собирающей линзы",
                    "labPictureFolder": "01-physics/04-optics/lab07/",
                    "labPages": [
                      {
                        "title": " ЛАБОРАТОРНАЯ РАБОТА N7 ",
                        "comment": [
                          "Получение изображения с помощью собирающей линзы"
                        ]
                      },
                      {
                        "title": "ЦЕЛЬ РАБОТЫ",
                        "comment": [
                          "Исследование зависимости размера изображения от расстояния от линзы до экрана"
                        ]
                      },
                      {
                        "title": "ОБОРУДОВАНИЕ",
                        "comment": [
                          "Источник питания", "Соединительные провода", "Оптическая скамья", "Собирающая линза Л^_2^_ (50 мм) на рейтере",
                          "Рейтер для рамок", "Источник света на рейтере", "Экран", "Слайд-рамка ", 
                          "Полоска белой бумаги", "Канцелярский зажим для крепления бумаги"
                          ],
                          "picture": "oborudovanie_07.json",
                          "pictureDark": "oborudovanie_07d.json"
                      },
                      {
                        "title": "ПОДГОТОВКА К РАБОТЕ",
                        "comment": [
                          "Подключите источник света соединительными проводами к источнику питания 0…7,5 В и включите его тумблером на нем. Установите максимальное напряжение на его выходе его регулятором.", 
                          "На рейтер с экраном установите полоску белой бумаги так, чтобы она заходила за края экрана и крепилась отогнутыми краями. Для надежности закрепите ее канцелярским зажимом.", "",
                          "Расположите на оптической скамье источник света на рейтере, рейтер для рамок с закрепленной на ней слайд-рамкой, собирающую линзу Л^_2^_ на рейтере и экран так, как показано на рисунке.",
                          "Расположение объектов на скамье: источник света – 2 см, рейтер для рамок с рамкой слайдом – 8 см, собирающая линза Л^_2^_ – 14,5 см, экран - 30 см."
                          ],
                          "picture":"optics_07_01.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Фокусное расстояние линзы Л^_2^_ известно, оно составляет 50 мм, построение изображение удобнее начать с положения, когда предмет расположен на расстоянии от линзы в более чем два раза дальше, чем ее фокусное расстояние: d > 2F.", "", 
                          "Изменением положения собирающей линзы добейтесь сфокусированного изображения перевернутой буквы F на экране."
                          ],
                          "picture": "optics_07_02.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Согласно формуле тонкой линзы, расстояние от предмета до линзы b, расстояние от изображения до линзы и фокусное расстояние F связаны соотношением:", 
                          "##@@1 / d + 1 / f = 1 / F, ",
                          "где d – расстояние от линзы до объекта, F – фокусное расстояние, f - расстояние от линзы до изображения.", "", 
                          "Линейные размеры предмета и изображения связаны с расстояниями от предмета и изображения до линзы соотношением:",
                          "##@@H / h = f / d", "", 
                          "Таким образом, при приближении предмета к линзе размер изображения будет увеличиваться. Уменьшите расстояние между предметом и линзой:",
                          "##@@d = 2F."
                          ],
                          "picture":"lab07w04-graph.jpg"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "При этом видно, что изображение на экране будет действительное, равное, перевернутое, а расстояние до сфокусированного изображения будет увеличиваться при уменьшении расстояния от предмета до линзы."
                          ],
                          "picture":"optics_07_04.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Сократите расстояние между предметом и линзой:",
                          "##@@F > d > 2F.","", 
                          "На экране при этом Вы увидите при сфокусированном изображении увеличение изображения. "
                          ],
                          "picture":"optics_07_05.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Свойства изображения представлены в таблице:"
                          ],
                          "table":[
                            "d|Свойство изображения",
                            "F>d>2F|Действительное, увеличенное, перевернутое",
                            "d=2F|Действительное, равное, перевернутое", 
                            "d>2F|Действительное, уменьшенное, перевернутое"
                          ]
                      }                
                    ]
                  },
                  {
                    "labId":"lab08",
                    "labName": "Экспериментальное исследование формулы линзы",
                    "labPictureFolder": "01-physics/04-optics/lab08/",
                    "labPages": [
                      {
                        "title": " ЛАБОРАТОРНАЯ РАБОТА N8 ",
                        "comment": [
                          "Экспериментальное исследование формулы линзы"
                        ]
                      },
                      {
                        "title": "ЦЕЛЬ РАБОТЫ",
                        "comment": [
                          "Экспериментально подтвердить справедливость формулы линзы для нескольких значений расстояния от предмета до линзы"
                        ]
                      },
                      {
                        "title": "ОБОРУДОВАНИЕ",
                        "comment": [
                          "Источник питания", "Соединительные провода", "Оптическая скамья", 
                          "Собирающая линза Л^_2^_ (50 мм) на рейтере", "Рейтер для рамок", 
                          "Источник света на рейтере", "Экран", "Слайд-рамка ", "Полоска белой бумаги", 
                          "Канцелярский зажим для крепления бумаги"
                          ],
                          "picture": "oborudovanie_08.json",
                          "pictureDark": "oborudovanie_08d.json"
                      },
                      {
                        "title": "ПОДГОТОВКА К РАБОТЕ",
                        "comment": [
                          "Подключите источник света соединительными проводами к источнику питания 0…7,5 В и включите его тумблером на нем. Установите максимальное напряжение на его выходе его регулятором.", 
                          "На рейтер с экраном установите полоску белой бумаги так, чтобы она заходила за края экрана и крепилась отогнутыми краями. Для надежности закрепите ее канцелярским зажимом. ", "",
                          "Расположите на оптической скамье рейтер с источником света, рейтер для рамок с закрепленной на ней рамкой, собирающую линзу Л^_2^_ и экран.",
                          "Расположение объектов на скамье: источник света – 2 см, рейтер для рамок с рамкой слайдом – 7 см, собирающая линза Л^_2^_ – 18 см, экран – 26,2 см."
                          ],
                          "picture":"optics_08_02.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Обратите внимание на размеры предмета «F». Изображение будет либо больше, либо равно, либо меньше этого предмета. ", "", 
                          "Так как вы знаете фокусное расстояние линзы Л^_2^_, оно составляет 50 мм, то построение изображения удобнее начать с положения, когда предмет расположен на расстоянии от линзы в более чем два раза дальше, чем ее фокусное расстояние, то есть при d = 11 см: d > 2F."
                          ],
                          "picture": "optics_08_03.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Изменением положения собирающей линзы добейтесь сфокусированного изображения на экране. ", "",
                          "На рисунке видно, что изображение на экране меньше, чем предмет на рамке-слайде. Собранные данные по расстоянию предмета до линзы и от линзы до экрана занесите в таблицу."
                          ],
                          "picture":"optics_08_04.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          ],
                          "table":[
                            "++Расстояние от линзы до объекта d, см|6|7|8|9|10|11",
                            "++Расстояние от линзы до изображения f, см|26|15|13|10,2|10|8,2",
                            "++Фокусное расстояние F, см|4,9|4,8|4,95|4,8|5|4,7"
                          ]
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Уменьшите расстояние между предметом и линзой (d = 10 см): ", 
                          "##@@d=2F.",
                          "При этом видно, что изображение на экране будет действительное, равное, перевернутое. А расстояние до сфокусированного изображения будет увеличиваться при уменьшении расстояния от предмета до линзы.",
                          "Занесите данные о расстоянии от линзы до предмета и до экрана в таблицу."
                          ],
                          "picture":"optics_08_06.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Сократите расстояние между предметом и линзой на 1 см (d = 8 см).",
                          "На экране при этом вы увидите при сфокусированном изображении увеличение изображения. ",
                          "Занесите данные о расстоянии от линзы до предмета и до экрана в таблицу."
                          ],
                          "picture":"optics_08_07.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Сократите расстояние между предметом и линзой на 1 см (d = 7 см).",
                          "Занесите данные о расстоянии от линзы до предмета и до экрана в таблицу."
                          ],
                          "picture":"optics_08_08.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Сократите расстояние между предметом и линзой на 1 см (d = 6 см).",
                          "Занесите данные о расстоянии от линзы до предмета и до экрана в таблицу."
                          ],
                          "picture":"optics_08_09.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "По данным, занесенным в таблицу, определите значение фокусного расстояния собирающей линзы по формуле:",
                          "##@@1 / d + 1 / f = 1 / F"
                          ]
                      }
                    ]
                  },
                  {
                    "labId":"lab09",
                    "labName": "Экспериментальное исследование размеров изображений предметов, даваемых линзами",
                    "labPictureFolder": "01-physics/04-optics/lab09/",
                    "labPages": [
                      {
                        "title": " ЛАБОРАТОРНАЯ РАБОТА N9 ",
                        "comment": [
                          "Экспериментальное исследование размеров изображений предметов, даваемых линзами"
                        ]
                      },
                      {
                        "title": "ЦЕЛЬ РАБОТЫ",
                        "comment": [
                          "Экспериментально исследовать зависимость размеров изображений, даваемых линзами для нескольких значений расстояния от предмета до линзы"
                        ]
                      },
                      {
                        "title": "ОБОРУДОВАНИЕ",
                        "comment": [
                          "Источник питания", "Соединительные провода", "Оптическая скамья", 
                          "Собирающая линза Л^_2^_ (50 мм) на рейтере", 
                          "Рассеивающая линза Л^_3^_ (-75 мм) на рейтере", "Источник света на рейтере", "Экран", "Слайд-рамка ", 
                          "Полоска белой бумаги", "Канцелярский зажим для крепления бумаги",
                          "Подставка", "Рейтер для рамок"
                          ],
                          "picture": "oborudovanie_09.json",
                          "pictureDark": "oborudovanie_09d.json"
                      },
                      {
                        "title": "ПОДГОТОВКА К РАБОТЕ",
                        "comment": [
                          "Подключите источник света соединительными проводами к источнику питания 0…7,5 В и включите его тумблером на нем. Установите максимальное напряжение на его выходе его регулятором.", 
                          "На рейтер с экраном установите полоску белой бумаги так, чтобы она заходила за края экрана и крепилась отогнутыми краями. Для надежности закрепите ее канцелярским зажимом. ", "",
                          "Определите, какие изображения позволяет получать собирающая линза. ",
                          "Расположите на оптической скамье источник света на рейтере, рейтер для рамок с закрепленной на ней рамкой, собирающую линзу Л^_2^_ на рейтере и экран так, как показано на рисунке (расположение объектов на скамье: источник света – 2 см, рейтер для рамок с рамкой слайдом – 7 см, собирающая линза Л^_2^_ – 18 см, экран – 26,2 см)."
                          ],
                          "picture":"optics_09_02.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Обратите внимание на размеры предмета «F».", 
                          "Изображение будет либо больше, либо равно, либо меньше этого предмета. ",
                          "Так как вы знаете фокусное расстояние линзы Л^_2^_, оно составляет 50 мм, то построение изображения удобнее начать с положения, когда предмет расположен на расстоянии от линзы в более чем два раза дальше, чем ее фокусное расстояние, то есть при d = 11 см: d>2F."
                          ],
                          "picture": "optics_09_03.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Изменением положения собирающей линзы добейтесь сфокусированного изображения на экране.", "",
                          "Видно, что изображение на экране меньше, чем предмет на рамке-слайде.",
                          "Собранные данные по расстоянию предмета до линзы и от линзы до экрана занесите в таблицу."
                          ],
                          "picture":"optics_09_04.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          ],
                          "table":[
                            "++Расстояние от линзы до объекта d, см|6|7|8|9|10|11",
                            "++Расстояние от линзы до изображения f, см|26|15|13|10,2|10|8,2",
                            "++Фокусное расстояние   F, см|4,9|4,8|4,95|4,8|5|4,7"
                          ]
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Уменьшите расстояние между предметом и линзой (d = 10 см): ",
                          "##@@d = 2F.",
                          "При этом видно, что изображение на экране будет действительное, равное, перевернутое. А расстояние до сфокусированного изображения будет увеличиваться при уменьшении расстояния от предмета до линзы.",
                          "Занесите данные о расстоянии от линзы до предмета и до экрана в таблицу."
                          ],
                          "picture":"optics_09_06.json",
                          "pictureDark":"optics_09_06d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Сократите расстояние между предметом и линзой (d = 9 см): 2F> d> F.", "",
                          "На экране при этом вы увидите его действительное, увеличенное и перевёрнутое изображение, и оно будет находиться за двойным фокусным расстоянием линзы.",
                          "Занесите данные о расстоянии от линзы до предмета и до экрана в таблицу."
                          ],
                          "picture":"optics_09_07.json",
                          "pictureDark":"optics_09_07d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Сократите расстояние между предметом и линзой (d = 8 см).", "",
                          "На экране при этом вы увидите при сфокусированном изображении увеличение изображения.",
                          "Занесите данные о расстоянии от линзы до предмета и до экрана в таблицу."
                          ],
                          "picture":"optics_09_08.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Сократите расстояние между предметом и линзой (d = 7 см и d = 6 см).", "",
                          "Занесите данные о расстоянии от линзы до предмета и до экрана в таблицу.", "",
                          "По данным, занесенным в таблицу, определите значение фокусного расстояния собирающей линзы по формуле:",
                          "##@@1 / d + 1 / f = 1 / F", "", 
                          "Если предмет поместить между фокусом и линзой, т. е. d < F, то его изображение на экране не получится."
                          ],
                          "picture":"optics_09_09.json"
                      }
                    ]
                  },
                  {
                    "labId":"lab10",
                    "labName": " Измерение увеличения лупы",
                    "labPictureFolder": "01-physics/04-optics/lab10/",
                    "labPages": [
                      {
                        "title": " ЛАБОРАТОРНАЯ РАБОТА N10 ",
                        "comment": [
                          " Измерение увеличения лупы "
                        ]
                      },
                      {
                        "title": "ЦЕЛЬ РАБОТЫ",
                        "comment": [
                          "Познакомиться с формированием собирающей линзой мнимых изображений предметов и ",
                          " с использованием собирающей линзы в качестве лупы. Измерить угловое увеличение лупы"
                        ]
                      },
                      {
                        "title": "ОБОРУДОВАНИЕ",
                        "comment": [
                          "Оптическая скамья", "Собирающая линза Л^_1^_ (100 мм) на рейтере", "Собирающая линза Л^_2^_ (50 мм) на рейтере", "Экран", "Линейка (2 шт.)", "Подставка", "Лупа (160 мм)", "Штатив с муфтой и лапкой", "Полоска миллиметровой бумаги (4ꞏ10 см)", "Канцелярский зажим для крепления бумаги"
                          ],
                          "picture": "lab10w01.jpg",
                          "pictureDark": "oborudovanie_10d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Измерьте максимальное увеличение линзы при неискаженном изображении клеток миллиметровой бумаги: ", 
                          "##@@Г^_макс^_ = n / N,", 
                          "где: N – количество делений, видимых через линзу, n – количество делений, наблюдаемых невооруженным глазом.", "", 
                          "Установите на подставке скамью, линзу Л^_1^_, экран, обернутый миллиметровой бумагой. Причем линзу Л^_1^_ установите на краю скамьи, а рейтер с экраном установите на расстоянии измеренного фокусного расстояния F^_Л1^_."
                          ]
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Наблюдая через линзу с расстояния 25 см миллиметровую бумагу, перемещайте экран до положения, в котором будут видна неискаженная миллиметровая бумага.", "", 
                          "Зафиксируйте также расстояние наилучшего зрения D^_Л1^_ (9 см)."
                          ]
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          " С расстояния 25 см определите во сколько раз произошло увеличение миллиметровой бумаги соотнеся размер неискаженной миллиметровой бумаге к размеру на линейке, приложенной к линзе на скамье.", "",
                          " Измерьте количество неискаженных миллиметровых клеток, видимых через линзу – N (10), и количество миллиметров по линейке, совпадающее с изображением этих миллиметровых клеток – n (16). Определите их отношение по формуле:",
                          "##@@Г^_макс^_ = n / N.", "",
                          "В нашем случае это равно отношению: Г = 16 /10 = 1,6 (раз)."
                          ]
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Для линзы Л^_2^_: Установите на подставке скамью, линзу Л^_2^_, экран, обернутым миллиметровой бумагой.", "", 
                          "Причем линзу Л^_2^_ установите на краю скамьи, экран установите на расстоянии измеренного фокусного расстояния F^_Л2^_."
                          ],
                          "picture": ""
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Наблюдая через линзу с расстояния 25 см миллиметровую бумагу, перемещайте экран до положения, в котором будут видна неискаженная миллиметровая бумага.", "",
                          "Зафиксируйте также расстояние наилучшего зрения D^_Л2^_."
                          ],
                          "picture": ""
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "С расстояния 25 см определите, во сколько раз произошло увеличение миллиметровой бумаги, соотнеся размер неискаженной миллиметровой бумаге к размеру на линейке, приложенной к линзе на скамье.", "",
                          "Измерьте количество неискаженных миллиметровых клеток, видимых через линзу – N (5), и количество миллиметров по линейке, совпадающее с изображением этих миллиметровых клеток – n (8). Определите их отношение по формуле:", "",
                          "##@@Г^_макс^_ = n / N.", "",
                          "В нашем случае это равно отношению: Г = 8 /5 = 1,6 (раз). "
                          ],
                          "picture": ""
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Для лупы: Установите на подставке скамью, экран, обернутый миллиметровой бумагой.", "",
                          "Лупу установите в лапке штатива так, чтобы она оказалась на точке отсчета шкалы скамьи, поднятой на подставке. Причем, в определенном ранее фокусном расстоянии лупы F^_лупы^_ (16 см), установите экран, обернутый миллиметровой бумагой."
                          ],
                        "picture": ""
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Наблюдайте миллиметровую бумагу через лупу, и для лучшей оценки увеличения ее линзой, поставьте перед линзой линейку.", "",
                          "Перемещая экран, добейтесь максимального числа неискаженных миллиметровых квадратиков миллиметровой бумаги, видимых через лупу с расстояния 25 см. Для лучшей оценки увеличения поставьте линейку перед экраном с миллиметровой бумагой и линейку перед самой лупой.", "",
                          "Сопоставьте число миллиметров на линейке, видимых через лупу с тем же самым числом миллиметров на линейке, поставленных перед лупой."
                          ],
                          "picture": ""
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Используя формулу для определения максимального увеличения линзы при неискаженном изображении клеток миллиметровой бумаги для линейки, определите:", "",
                          "##@@Г^_макс^_ = n / N.", "",
                          "где: N – количество делений линейки, видимых через линзу, n – количество делений линейки, наблюдаемых невооруженным глазом.", "",
                          "В нашем случае при N = 20 мм, n = 32 мм.", "", 
                          "То есть Г^_лупы^_ = 32 / 20 = 1,6 (раз).", "",
                          "Зафиксируйте также расстояние наилучшего зрения D^_лупы^_ (7 см). "
                          ],
                          "picture": ""
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Вычислите увеличение линз и лупы, которые они должны давать в соответствии с формулой: ",
                          "##@@Г = D/F,",
                          "где D - расстояние наилучшего зрения (25 см) от предмета, F - фокусное расстояние линзы (лупы).", "", 
                          "Для линзы Л^_1^_: Ранее измеренные величины:", 
                          "##@@D^_Л1^_ = 9 см, F^_Л1^_ = 10 см", 
                          "##@@Г^_Л1^_ = 25/10 = 2,5 (раз)", "", 
                          "Для линзы Л^_2^_: Ранее измеренные величины: ", 
                          "##@@D^_Л2^_ = 4 см, F^_Л2^_ = 5 см",
                          "##@@Г^_Л2^_ = 25/5 = 5 (раз)", "", 
                          "Для лупы: Ранее измеренные величины:",
                          "##@@D^_лупы^_ = 7 см, F^_лупы^_ = 16 см",
                          "##@@Г^_лупы^_ = 25/16 = 1,6 (раз)."
                          ]
                      }  
                    ]
                  },
                  {
                    "labId":"lab11",
                    "labName": " Сборка модели трубы Кеплера ",
                    "labPictureFolder": "01-physics/04-optics/lab11/",
                    "labPages": [
                      {
                        "title": " ЛАБОРАТОРНАЯ РАБОТА N11 ",
                        "comment": [
                          " Сборка модели трубы Кеплера "
                        ]
                      },
                      {
                        "title": "ЦЕЛЬ РАБОТЫ",
                        "comment": [
                          "Понять принцип действия астрономического телескопа-рефрактора (зрительной трубы Кеплера) ",
                          " и измерить на опыте угловое увеличение модели телескопа"
                        ]
                      },
                      {
                        "title": "ОБОРУДОВАНИЕ",
                        "comment": [
                          "Собирающая линза Л^_2^_ (50 мм) на рейтере (короткофокусная линза)", "Собирающая линза Л^_1^_ (100 мм) на рейтере (длиннофокусная линза)", "Оптическая скамья", "Подставка", "Линейка", "Штатив с муфтой и лапкой", "Экран"
                          ],
                          "picture": "oborudovanie_11.json",
                          "pictureDark": "oborudovanie_11d.json"
                      },
                      {
                        "title": "ПОДГОТОВКА К РАБОТЕ",
                        "comment": [
                          "Для построения макета зрительной трубы Кеплера (телескопа-рефрактора) на скамье установите две собирающие линзы в держателях.", 
                          "Линза с меньшим фокусным расстоянием (Л^_2^_) служит окуляром. Поместите ее у самого края скамьи, чтобы было удобно приблизить трубу окуляром к глазу. ", 
                          "Вторая собирающая линза длиннофокусная (Л^_1^_) (объектив) устанавливается на расстоянии примерно равном сумме фокусных расстояний обеих линз. В данном случае", 
                          "##@@L = F^_1^_ + F^_2^_ = 10 + 5 = 15 см.",
                          " При таком расположении линз падающие на объектив почти параллельные лучи, исходящие из какой-либо точки удаленного предмета, выходят из окуляра также параллельным пучком. Это значит, что резкие изображения удаленных предметов будут формироваться зрительной трубой на сетчатке ненапряженного глаза (аккомодированного на бесконечность). Более точно положение объектива определите опытным путем при наблюдении через трубу удаленных предметов. "
                          ],
                          "picture":"optics_11_01.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Установите окуляр (Л^_2^_) и объектив (Л^_1^_) на скамью. ",
                          "Линейку закрепите в лапке штатива.",
                          "Скамью с линзами аккуратно установите на подставку и направьте ее на линейку. ", "", 
                          "Установку для наблюдения увеличенного объекта через «трубу Кеплера» - линейку, соберите так, чтобы удобно было подбирать положение объектива."
                          ],
                          "picture": "optics_11_02.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          " Наблюдайте через объектив и окуляр линейку и, изменяя положение окуляра, добейтесь сфокусированного и увеличенного перевернутого изображения линейки."
                          ],
                          "picture":"optics_11_03.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Сопоставьте изображение в окуляре с линейкой, закрепленной в лапке штатива. Угловое увеличение Г трубы Кеплера равно отношению фокусных расстояний объектива f^_1^_ и окуляра f^_2^_: ",
                          "##@@Г = f^_1^_ / f^_2^_.",
                          "В данном случае угловое увеличение определяется отношением ранее измеренных фокусных расстояний F^_2^_ и F^_1^_. ",
                          "##@@Г = F^_2^_ /F^_1^_ = 10/5 = 2.", "", 
                          "Отношение размера наблюдаемого через трубу изображения к размеру наблюдаемого невооруженным глазом изображения, дает искомое угловое увеличение зрительной трубы. Рисунок наглядно показывает увеличение объекта порядка 2 раз."
                          ],
                          "picture":"optics_11_04.json"
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            "Отношение размера, наблюдаемого через трубу изображения к размеру, наблюдаемого невооруженным глазом изображения, дает искомое угловое увеличение зрительной трубы.",
                            "Фото наглядно показывает увеличение объекта порядка 2 раз."
                          ],
                          "picture":"optics_11_05.json"
                        } 
                    ]
                  },
                  {
                    "labId":"lab12",
                    "labName": "  Сборка модели трубы Галилея ",
                    "labPictureFolder": "01-physics/04-optics/lab12/",
                    "labPages": [
                      {
                        "title": " ЛАБОРАТОРНАЯ РАБОТА N12 ",
                        "comment": [
                          " Сборка модели трубы Галилея "
                        ]
                      },
                      {
                        "title": "ЦЕЛЬ РАБОТЫ",
                        "comment": [
                          "Понять принцип действия зрительной трубы Галилея и измерить на опыте ее угловое увеличение"
                        ]
                      },
                      {
                        "title": "ОБОРУДОВАНИЕ",
                        "comment": [
                          "Собирающая линза Л^_1^_ (100 мм) на рейтере (длиннофокусная линза)", "Рассеивающая линза Л^_3^_ (-75 мм) на рейтере", "Оптическая скамья", "Подставка", "Линейка", "Штатив с муфтой и лапкой", "Экран"
                          ],
                          "picture": "oborudovanie_12.json",
                          "pictureDark": "oborudovanie_12d.json"
                      },
                      {
                        "title": "ПОДГОТОВКА К РАБОТЕ",
                        "comment": [
                          "Измерьте фокусные расстояния собирающей линзы. ", 
                          "Для этого на оптической скамье установите экран, собирающую линзу Л^_1^_ на рейтере (длиннофокусную).", 
                          "Установите скамью на лапку штатива так, чтобы удаленный источник света – лампа дневного света на потолке через линзу сфокусировался на экране при изменении положения линзы.", "", 
                          "Измерьте расстояние от линзы до экрана. Это и будет фокусное расстояние длиннофокусной линзы Л^_1^_. Ее фокусное расстояние F^_1^_ = 10 см."
                          ],
                          "picture":"optics_12_01.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Измерьте после этого фокусное расстояние рассеивающей линзы Л^_3^_.",
                          "Для этого закрепите линейку на лапке штатива, рассеивающую линзу установите окуляром у самого края скамьи так, чтобы было удобно приблизить скамью с линзой к глазу. Собирающую линзу Л^_1^_ установите на скамью объективом, а рассеивающую линзу Л^_3^_ окуляром так, чтобы через обе линзы можно было отчетливо наблюдать удаленные предметы, например удаленный источник света на потолке. Для этого закрепите скамью с линзами в лапке штатива для наблюдения удаленного источника света."
                          ],
                          "picture": "optics_12_02.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Для получения четких изображений удаленных предметов расстояние между объективом и окуляром должно быть равно разности фокусных расстояний объектива и окуляра. ", 
                          "В таком случае входящие в объектив трубы параллельные лучи, исходящие от какой-либо точки удаленного предмета, выходят из окуляра тоже параллельным пучком, и наблюдение ведется ненапряженным глазом. ",
                          "Наблюдается прямое увеличенное изображение предмета. Наблюдая удаленный источник света перемещением линзы Л^_1^_ добейтесь сфокусированного его изображения."
                          ],
                          "picture":"optics_12_03.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Зафиксируйте при этом расстояние между линзами. Это и есть величина F, необходимая для определения фокусного расстояния рассеивающей линзы F^_2^_.",
                          "##@@F = 2,5 см.",
                          "Таким образом фокусное расстояние рассеивающей линзы:",
                          "##@@F^_2^_ = - (F^_1^_ - F) = - (100 - 25) = -75 мм."
                          ],
                          "picture":"optics_12_04.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Определите угловое увеличение трубы Галилея. Для этого закрепите линейку в лапке штатива, рассеивающую линзу Л^_3^_ на скамье закрепите как окуляр, а собирающую линзу Л^_1^_ – как объектив."
                          ],
                          "picture":"optics_12_05.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Разместите скамью с линзами на подставке для наблюдения за линейкой на штативе.",
                          "Изменяя положение собирающей линзы Л^_1^_ добейтесь сфокусированного увеличенного прямого изображения линейки."
                          ],
                          "picture":"optics_12_06.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Угловое увеличение Г трубы Галилея равно отношению фокусных расстояний объектива f^_1^_ и окуляра f^_2^_: ",
                          "##@@Г = f^_1^_ / f^_2^_.",
                          "В данном случае угловое увеличение определяется отношением ранее измеренных фокусных расстояний F^_2^_ и F^_1^_. ",
                          "##@@Г = F^_2^_  / F^_1^_ = 100 / 75 = 1,33.","", 
                          "Отношение размера наблюдаемого через трубу изображения к размеру наблюдаемого невооруженным глазом изображения дает искомое угловое увеличение зрительной трубы."
                          ]
                      }
                    ]
                  },
                  {
                    "labId":"lab13",
                    "labName": " Сборка модели микроскопа ",
                    "labPictureFolder": "01-physics/04-optics/lab13/",
                    "labPages": [
                      {
                        "title": " ЛАБОРАТОРНАЯ РАБОТА N13 ",
                        "comment": [
                          " Сборка модели микроскопа "
                        ]
                      },
                      {
                        "title": "ЦЕЛЬ РАБОТЫ",
                        "comment": [
                          "Понять принцип действия микроскопа, собрать макет микроскопа и измерить на опыте его угловое увеличение"
                        ]
                      },
                      {
                        "title": "ОБОРУДОВАНИЕ",
                        "comment": [
                          "Собирающая линза Л^_2^_ (50 мм) на рейтере (короткофокусная линза)", "Собирающая линза Л^_1^_ (100 мм) на рейтере (длиннофокусная линза)", "Оптическая скамья", "Подставка", "Экран", "Рейтер для рамок", "Слайд-рамка", "Линейка", "Штатив с муфтой и лапкой"
                          ],
                          "picture": "oborudovanie_13.json",
                          "pictureDark": "oborudovanie_13d.json"
                      },
                      {
                        "title": "ПОДГОТОВКА К РАБОТЕ",
                        "comment": [
                          "Измерьте фокусные расстояния собирающих линз. ", 
                          "Для этого на оптической скамье установите экран, линзу Л^_2^_ (короткофокусную). Установите скамью на лапку штатива так, чтобы удаленный источник света – лампа дневного света на потолке через линзу сфокусировался на экране.", 
                          "Зафиксируйте ее положение и определите ее фокусное расстояние F^_1^_ = 5 см. "
                          ],
                          "picture":"optics_13_01.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Аналогично измерьте фокусное расстояние длиннофокусной линзы Л^_1^_.", 
                          "Зафиксируйте ее положение и определите ее фокусное расстояние F^_2^_ = 10 см. Измерьте видимый внутренний диаметр линзы Л^_1^_ – окуляра для определения коэффициента увеличения микроскопа (Оно равно 2,7 см)."
                          ],
                          "picture": "optics_13_02.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          " Установите скамью на подставку. На нее установите рейтеры с линзами Л^_1^_ и Л^_2^_. Линза с большим фокусным расстоянием (Л^_1^_) служит окуляром. Поместите ее у самого края скамьи, чтобы было удобно приблизить трубу окуляром к глазу. ", "",
                          " Вторая собирающая линза короткофокусная Л^_2^_ (объектив) устанавливается на расстоянии L, примерно равном сумме фокусных расстояний обеих линз. В данном случае:",
                          "##@@L = F^_1^_ + F^_2^_ = 10 + 5 = 15 см.",
                          "Держатель для рамок устанавливают на расстоянии немного большем, чем фокусное расстояние линзы объектива. По центру держателя для рамок устанавливают рамку-слайд."
                          ],
                          "picture":"optics_13_03.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Наблюдая через линзы шкалу рамки-слайда, установите рамку-слайд так, чтобы ее центр с символом «О» находился посередине линзы – окуляра. "
                          ],
                          "picture":"optics_13_04.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Перемещениями рейтера для рамки с рамкой добейтесь сфокусированного и максимально увеличенного изображения шкалы, имеющейся на рамке-слайде.",
                          "Угловое увеличение микроскопа определяется как отношение увеличенного изображения предмета к известному не увеличенному размеру предмета."
                          ],
                          "picture":"optics_13_05.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Видимая часть рамки до увеличения составляет 10 мм. Отнесенное к ней изображение в окуляре, измеренное ранее, составляет 27 мм.",
                          "Следовательно угловое увеличение микроскопа:", 
                          "##@@Г = 27/10 (мм) = 2,7 раза."
                        ],
                        "picture":"optics_13_06.json"
                      }  
                    ]
                  },
                  {
                    "labId":"lab14",
                    "labName": " Исследование явления дисперсии ",
                    "labPictureFolder": "01-physics/04-optics/lab14/",
                    "labPages": [
                      {
                        "title": " ЛАБОРАТОРНАЯ РАБОТА N14 ",
                        "comment": [
                          " Исследование явления дисперсии "
                        ]
                      },
                      {
                        "title": "ЦЕЛЬ РАБОТЫ",
                        "comment": [
                          "Исследовать возможность получения дисперсионных спектров, исследовать свойства дисперсионного спектра"
                        ]
                      },
                      {
                        "title": "ОБОРУДОВАНИЕ",
                        "comment": [
                          "Оптическая скамья", "Собирающая линза Л^_2^_ (50 мм) на рейтере", "Источник питания", "Соединительные провода", "Источник света на рейтере", "Рейтер с щелевым отверстием", "Плоскопараллельная пластина", "Подставка", "Лист бумаги", "Пенал с крышкой"
                          ],
                          "picture": "oborudovanie_14.json",
                          "pictureDark": "oborudovanie_14d.json"
                      },
                      {
                        "title": "ПОДГОТОВКА К РАБОТЕ",
                        "comment": [
                          "Соберите установку.", 
                          "На скамью установите источник света на рейтере, рейтер со щелью и линзу Л^_2^_ на рейтере.", 
                          "Перед линзой на подставке установите плоскопараллельную стеклянную пластину так, чтобы свет от источника света через рейтер со щелью и линзу попадал на пластину в виде узкой вертикальной полосы. ", 
                          "Перед пластиной на подставке установите пенал с крышкой боковой поверхностью перпендикулярно к скамье.",
                          "Лист бумаги установите на боковой поверхности корпуса в виде экрана. "
                          ],
                          "picture":"optics_14_01.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Расположите источник света, рассеивающую линзу и экран так, как показано на рисунке (расположение объектов на скамье: источник света – 28 см, рейтер со щелью – 33 см, рассеивающая линза – 40 см). ", "", 
                          "Подключите источник света соединительными проводами к источнику питания 0…7,5 В и включите его тумблером на нем. Установите максимальное напряжение на его выходе его регулятором. ",
                          "Перемещая рейтер со щелью добейтесь появления на боковой поверхности пенала с крышкой сфокусированного изображения узкой вертикальной полосы света, падающую на большую плоскость плоскопараллельной пластины. ",
                          "Поворачивая пластину вокруг вертикальной оси перед линзой добейтесь появления свечения цветной полосы максимально широкой, позволяющей детально рассмотреть цвета окраски этой полосы."
                          ],
                          "picture": "optics_14_02.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          " Более точно располагая пластину на подставке, добейтесь получения изображения радужной полосы наиболее вертикального положения, чтобы можно было по горизонтали рассмотреть цвета, ее составляющие."
                          ],
                          "picture":"optics_14_03.json"
                      },
                      {
                        "title": "ЗАДАНИЕ",
                        "comment": [
                          "- Установите, соответствует ли полученная спектральная картина утверждению о том, что в спектре видимого света можно выделить семь основных цветов.",
                          "- Определите, в какой последовательности чередуются цвета.",
                          "- Определите, в какие цвета окрашены границы спектра?",
                          "- Установите, какая из спектральных составляющих светового пучка отклонена пластиной наиболее сильно относительно первоначального направления его распространения.", 
                          "- Установите, в какую сторону отклонился световой пучок, пройдя сквозь пластину, по отношению к преломляющему углу.",
                          "- Сравните ширину красного и фиолетового участков спектра.",
                          "- Сделайте заключение о справедливости утверждения о том, что в спектре, полученном благодаря явлению дисперсии, участки, соответствующие основным цветам, имеют разную ширину. Причем наиболее сжат участок красного цвета, наиболее растянут – фиолетовый. "
                          ],
                          "picture":"optics_14_04.json"
                      }  
                    ]
                  },
                  {
                    "labId":"lab15",
                    "labName": " Наблюдение дифракции света ",
                    "labPictureFolder": "01-physics/04-optics/lab15/",
                    "labPages": [
                      {
                        "title": " ЛАБОРАТОРНАЯ РАБОТА N15 ",
                        "comment": [
                          " Наблюдение дифракции света "
                        ]
                      },
                      {
                        "title": "ЦЕЛЬ РАБОТЫ",
                        "comment": [
                          "Исследовать на опыте явление дифракции световых волн на щели с прямыми краями"
                        ]
                      },
                      {
                        "title": "ОБОРУДОВАНИЕ",
                        "comment": [
                          "Оптическая скамья", "Источник питания", "Источник света на рейтере", "Соединительные провода", "Рейтер для рамок", "Слайд-рамка", "Рамка с прорезями и отверстиями", "Подставка"
                          ],
                          "picture": "oborudovanie_15.json",
                          "pictureDark": "oborudovanie_15d.json"
                      },
                      {
                        "title": "ПОДГОТОВКА К РАБОТЕ",
                        "comment": [
                          "Соберите установку для формирования дифракционной картины. ", 
                          "Для этого подключите источник света на рейтере соединительными проводами к источнику питания 0…7,5 В и включите его тумблером на нем. Установите максимальное напряжение на его выходе его регулятором.", 
                          "Рамку-слайд на магнитных держателях установите на источник света так, чтобы свет проходил через ее центральную щель. Установите на рейтер для рамок рамку с прорезями и отверстиями, используя ее магнитные держатели.", 
                          "Расположите источник света так, как показано на рисунке (расположение объектов на скамье: источник света с рамкой-слайдом – 20 см, рейтер для рамки с рамкой с прорезями и отверстиями – 39 см), установите скамью на подставку."
                          ],
                          "picture":"optics_15_01.json",
                          "pictureDark":"optics_15_01d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Рамка с прорезями и отверстиями содержит в нижнем ряду три вертикальных щели. ", 
                          "Для опыта используйте одиночные щели центральную и правую, причем правая имеет меньшую ширину, чем ширина центральной щели."
                          ],
                          "picture": "optics_15_02.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          " Установите рамку с прорезями и отверстиями на рейтер для рамок так, чтобы наблюдать за щелью в рамке-слайде можно было с нижней правой щели и нижней центральной щели.",
                          " Через центральную щель, как через окуляр, наблюдайте светящуюся щель на рамке - слайде. Если она сливается в яркое пятно, то регулятором – потенциометром на источнике питания 0…7,5 уменьшите напряжение на источнике света, тем самым, уменьшите яркость свечения щели до получения наибольшей разборчивости дифракционной картины. Через щель вы увидите, как светящаяся щель слайд-рамки воспринимается как набор светящихся полос с темными промежутками между ними – минимумами дифракционной картины. ",
                          " Яркость и размеры полос уменьшаются от центра по горизонтали к краям дифракционной картины. Центральная часть имеет большую яркость и ширину в двое большую, чем остальные максимумы. "
                          ],
                          "picture":"optics_15_03.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Наблюдайте дифракционную картину через правую нижнюю щель рамки (она имеет меньшую ширину)."
                          ],
                          "picture":"optics_15_04.json"
                      }  
                    ]
                  },
                  {
                    "labId":"lab16",
                    "labName": " Наблюдение интерференции света ",
                    "labPictureFolder": "01-physics/04-optics/lab16/",
                    "labPages": [
                      {
                        "title": " ЛАБОРАТОРНАЯ РАБОТА N16 ",
                        "comment": [
                          " Наблюдение интерференции света "
                        ]
                      },
                      {
                        "title": "ЦЕЛЬ РАБОТЫ",
                        "comment": [
                          "Исследовать на опыте явление интерференции световых волн, используя двойную щель в непрозрачном экране"
                        ]
                      },
                      {
                        "title": "ОБОРУДОВАНИЕ",
                        "comment": [
                          "Оптическая скамья", "Источник питания", "Источник света на рейтере", "Соединительные провода", "Рейтер для рамок", "Слайд-рамка", "Рамка с прорезями и отверстиями", "Подставка"
                          ],
                          "picture": "oborudovanie_16.json",
                          "pictureDark": "oborudovanie_16d.json"
                      },
                      {
                        "title": "ПОДГОТОВКА К РАБОТЕ",
                        "comment": [
                          "Соберите установку для формирования интерференционной картины.", 
                          "Для этого подключите источник света соединительными проводами к источнику питания 0…7,5 В и включите его тумблером. Установите максимальное напряжение на его выходе регулятором. ", 
                          "Рамку-слайд на магнитных держателях установите на источник света так, чтобы свет проходил через ее центральную щель. ", 
                          "Установите на рейтер для рамок рамку с прорезями и отверстиями, используя ее магнитные держатели.",
                          "Расположите источник света так, как показано на рисунке."
                          ],
                          "picture":"optics_16_01.json",
                          "pictureDark":"optics_16_01d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Рамка с прорезями и отверстиями содержит в нижнем ряду три вертикальных щели, причем левая из них двойная щель."
                          ],
                          "picture": "optics_16_02.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          " Установите рамку со щелями на рейтер для рамок так, чтобы наблюдать за щелью в рамке-слайде можно было с нижней левой двойной щели. Через двойную щель, как через окуляр, наблюдайте светящуюся щель на рамке-слайде. Если она сливается в яркое пятно, то регулятором – потенциометром на источнике питания 0…7,5 убавьте напряжение на источнике света, тем самым, уменьшив яркость свечения щели до получения наибольшей разборчивости интерференционной картины.",
                          " Через двойную щель вы увидите, как светящаяся щель воспринимается как набор светящихся полос с темными промежутками между ними – минимумами интерференционной картины. ",
                          " Яркость и размеры полос уменьшаются от центра по горизонтали к краям интерференционной картины. Центральные интерференционные максимумы имеют одинаковую ширину. "
                          ],
                          "picture":"optics_16_03.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Наблюдайте интерференцию через нижнюю центральную одиночную щель рамки."
                          ],
                          "picture":"optics_16_04.json"
                      }
                      ,
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "В увеличенном состоянии вы увидите интерференцию на одиночной центральной щели.", "", 
                          "Сравните интерференционную картину на двойной щели с картиной, которую дает одиночная щель. "
                        ]
                      } 
                    ]
                  },
                  {
                    "labId":"lab17",
                    "labName": " Измерение длины волны с помощью дифракционной решетки ",
                    "labPictureFolder": "01-physics/04-optics/lab17/",
                    "labPages": [
                      {
                        "title": " ЛАБОРАТОРНАЯ РАБОТА N17 ",
                        "comment": [
                          " Измерение длины волны с помощью дифракционной решетки "
                        ]
                      },
                      {
                        "title": "ЦЕЛЬ РАБОТЫ",
                        "comment": [
                          "Исследовать на опыте явление многолучевой интерференции световых волн, используя дифракционную ",
                          "решетку с известным расстоянием между штрихами, измерить длину световой волны"
                        ]
                      },
                      {
                        "title": "ОБОРУДОВАНИЕ",
                        "comment": [
                          "Оптическая скамья", "Источник питания", "Источник света на рейтере", "Соединительные провода", "Рейтер для рамок", "Слайд-рамка", "Рамка с дифракционными решетками (100 и 600 штр/мм)", "Подставка"
                          ],
                          "picture": "oborudovanie_17.json",
                          "pictureDark": "oborudovanie_17d.json"
                      },
                      {
                        "title": "ПОДГОТОВКА К РАБОТЕ",
                        "comment": [
                          "Соберите установку для формирования мнимой дифракционной картины и определения длины волны с использованием дифракционной решетки. ", 
                          "Для этого подключите источник света соединительными проводами к источнику питания 0…7,5 В и включите его тумблером. Установите максимальное напряжение на его выходе регулятором.", 
                          "Рамку-слайд на магнитных держателях установите на источник света так, чтобы свет проходил через ее центральную щель. Установите на рейтер для рамок рамку с дифракционными решетками, используя ее магнитные держатели. ", 
                          "Расположите источник света так, как показано на рисунке  (расположение объектов на скамье: источник света с рамкой-слайдом – 35,5 см, рейтер для рамки с рамкой с дифракционными решетками – 39 см), скамью установите на подставку."
                          ],
                          "picture":"optics_17_01.json",
                          "pictureDark":"optics_17_01d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Рамка с дифракционными решетками состоит из двух горизонтально расположенных дифракционных решеток. Наиболее прозрачная – справа имеет 600 штр/мм, наименее прозрачная – 100 штр/мм."
                          ],
                          "picture": "optics_17_02.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          " Установите рамку с дифракционными решетками так, чтобы только правая часть рамки – дифракционная решетка с 600 штр/мм создавала мнимую дифракцию – проецирование дифракционных линий на рамке-слайде, как на экране. ",
                          " Через дифракционную решетку на рамке посмотрите на источник света – щель на рамке слайде, и найдите отражение на ней отражения щели, разложенные в мнимые дифракционные линии.",
                          " В данном случае имеется два отражения (дифракционные спектры) – справа и слева от источника света – щели."
                          ],
                          "picture":"optics_17_06.json",
                          "pictureDark":"optics_17_06d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "На рисунке показана схема эксперимента (вид сверху). ",
                          "Наблюдатель располагается слева за дифракционной решеткой. Его взгляд сквозь зону решетки, содержащую 600 линий на миллиметр, направляется на прорезь в линейке, совпадающую с нулем шкалы, нанесенной на линейку.",
                          "Одновременно различается деления шкалы и расположенные на ее фоне интерференционные всплески оранжевого цвета, первые из которых находятся на расстояниях ± х симметрично по обе стороны от щели, вторые, более бледные, на расстояниях ± 2х и так далее -  теоретически до состояния, при котором угол φ станет равным 90º."
                          ],
                          "picture":"optics_17_07.json",
                          "pictureDark":"optics_17_07d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Для расчета длины волны излучения λ используют формулу (1):", 
                          "##@@λ = d · sin φ : m, (1)",
                          "где d – постоянная дифракционной решетки, равная общей ширине прозрачной и непрозрачной полос, выраженной в миллиметрах. Для нашего случая: d = 1 мм: 600 = 0,0016 мм;",
                          "m – 1, 2, 3… – порядки дифракционных максимумов.",
                          "Для максимумов, ближайших к щели, m = 1. Максимумы второго и следующих порядков рассмотреть сложно из-за ограниченной яркости источника света и ограниченности ширины экрана. Хотя, если посмотреть правее вдоль шкалы рамки-слайда, то можно увидеть кроме максимума первого порядка второй максимум второго порядка."
                          ]
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "На рисунке (вид сверху) условно показана дифракция (расщепление) белого света на семь цветов спектра. ", 
                          "Для примера, выберем оранжевый цвет в спектре дифракции. Как видно, оранжевый цвет спектра находится на удалении от щели ~ 14мм."
                          ],
                          "picture":"lab17w07.jpg"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                           "Излучение источника света представляет собой белый свет, содержащий все длины волн, перекрывающие диапазон видимого света 740...380 нм. Этот диапазон принято разделять на семь поддиапазонов по цвету средней длины волны:", 
                           "740... 625 нм - красный цвет;",
                           "625... 590 нм - оранжевый цвет;",
                           "590... 565 нм - желтый цвет;",
                           "565... 500 нм - зеленый цвет; ",
                           "500... 485 нм - голубой цвет;",
                           "485... 440 нм - синий цвет;", 
                           "440... 380 нм - фиолетовый цвет.", 
                           "На рисунке показан полученный расчетом результат дифракции белого света на шкале."
                            ],
                            "picture":"lab17w08.jpg"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Функцию sin φ для вычисления длины волны рассчитывают по формуле:", 
                          "##@@sin φ = х / √(x^^2^^+L^^2^^ ),",
                          "где х – отклонение первого дифракционного максимума от середины щели до середины его изображения, х ~ ± 14 мм;",
                          "L – расстояние от дифракционной решетки до шкалы: (L = 39–35,5 см = 3,5 см).", "",
                          "Учитывая, что sin φ = 14:√(14^^2^^+35^^2^^ ) = 0,371,",
                          "d = 1 мм: 600 = 0,0016 мм, ",
                          "то длина волны: ",
                          "λ = 0,0000016(м)·0,371 = 0,000000594(м) = 594 нм, что почти соответствует оранжевой части спектра."
                          ]
                      },
                      {
                        "title": "ЗАДАНИЕ",
                        "comment": [
                           "Самостоятельно рассчитайте длину света для оранжевой части спектра, меняя расстояние дифракционной решетки от источника света и учитывая расстояние от середины щели источника света до требуемого цвета в спектре свечения в первом максимуме дифракции.  ", 
                           "Для сравнения с дифракционной решеткой, имеющей 600 штр/мм, рассмотрите дифракционную картину, создаваемую дифракционной решеткой, имеющей 100 штр/мм, передвинув вправо рамку с дифракционными решетками так, чтобы граница между рамками совпала с центром щели источника света. Справой стороны вы будете наблюдать дифракцию, создаваемую рамкой, имеющую 600 штр/мм. А слева от щели дифракцию, создаваемую рамкой имеющую 100 штр/мм. ", "",
                           "Увеличивая расстояние от рамки с дифракционной решеткой до щели источника света, передвигая источник света с рамкой-слайдом, более детально рассмотрите каждый из максимумов дифракции, и, передвинув рамку с решетками так, чтобы можно было наблюдать щель источника света только через рамку с 100 штр/мм.", "",
                           "Такая дифракционная картина с использованием дифракционной решетки у Вас получится при расположении источника света с рамкой – слайдом и рейтера для рамок с дифракционными решетками, при расположении: источник света – 20 см, рейтер для рамок – 39 см. "
                            ]
                      },
                      {
                        "title": "ЗАДАНИЕ",
                        "comment": [
                           "Рассчитайте длину волны для оранжевой части спектра.",
                           "Для этого воспользуйтесь методикой, как и в случае с дифракционной решеткой с 600 штр/мм. ",
                           "Для расчета длины волны излучения λ используют формулу (1):",
                           "##@@λ = d · sin φ / m,  (1)",
                           "где d – постоянная дифракционной решетки, равная общей ширине прозрачной и непрозрачной полос, выраженной в миллиметрах. Для нашего случая: d = 1 мм: 100 = 0,01 мм;",
                           "Функцию sin φ для вычисления длины волны рассчитывают по формуле (2):",
                           "##@@sin φ = х / √(x^2+L^2 )  (2)",
                           "где х – отклонение первого дифракционного максимума от середины щели до середины его изображения, х ~ ± 11 мм;",
                           "L – расстояние от дифракционной решетки до шкалы: L = 39 – 20 см = 190 (мм).",
                           "Учитывая, что sin φ = 14 / √(11^2+190^2 ) = 0,0578, ",
                           "то длина волны: λ= 0,00001 (м)·0,0578 = 0,000000579(м) = 579 нм, что почти соответствует оранжевой части спектра.",
                           "Таким образом, Вы определили длину волны света с использованием дифракционной решетки."
                            ]
                      }
                    ]
                  },
                  {
                    "labId":"lab18",
                    "labName": " Наблюдение дифракции световой волны на круглом отверстии ",
                    "labPictureFolder": "01-physics/04-optics/lab18/",
                    "labPages": [
                      {
                        "title": " ЛАБОРАТОРНАЯ РАБОТА N18 ",
                        "comment": [
                          " Наблюдение дифракции световой волны на круглом отверстии "
                        ]
                      },
                      {
                        "title": "ЦЕЛЬ РАБОТЫ",
                        "comment": [
                          "Исследовать явление дифракции световой волны на круглом отверстии"
                        ]
                      },
                      {
                        "title": "ОБОРУДОВАНИЕ",
                        "comment": [
                          "Оптическая скамья", "Источник питания", "Источник света на рейтере", "Соединительные провода", "Рейтер для рамок", "Слайд-рамка", "Рамка с прорезями и отверстиями", "Подставка", "Собирающая линза Л^_2^_ (50 мм) на рейтере"
                          ],
                          "picture": "oborudovanie_18.json",
                          "pictureDark": "oborudovanie_18d.json"
                      },
                      {
                        "title": "ПОДГОТОВКА К РАБОТЕ",
                        "comment": [
                          "Соберите установку для формирования дифракционной картины.", 
                          "Для этого подключите источник света соединительными проводами к источнику питания 0…7,5 В и включите его тумблером. Установите максимальное напряжение на его выходе регулятором. ", 
                          "Рамку-слайд на магнитных держателях установите на источник света так, чтобы свет проходил через круглое точечное отверстие в ее центральной части. ", 
                          "Установите на рейтер для рамок рамку с прорезями и отверстиями, используя ее магнитные держатели так, чтобы ряд с круглыми отверстиями оказался внизу."
                          ],
                          "picture":"optics_18_01.json",
                          "pictureDark":"optics_18_01d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Расположите источник света с рамкой-слайдом, рейтер для рамок с рамкой с прорезями и отверстиями и линзу Л^_2^_ на рейтере так, как показано на рисунке."
                          ],
                          "picture": "optics_18_02.json",
                          "pictureDark":"optics_18_02d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Через линзу, как через лупу, наблюдайте через отверстие меньшего диаметра изображение светящегося отверстия. "
                          ],
                          "picture": "optics_18_03.json",
                          "pictureDark":"optics_18_03d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Перемещая рейтер для рамок с рамкой со щелями вдоль скамьи, добейтесь четкого изображения круглого отверстия."
                          ],
                          "picture":"optics_18_04.json",
                          "pictureDark":"optics_18_04d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Перемещая рейтер для рамок с рамкой со щелями из плоскости сопряжения с сетчаткой глаза, получите его расположение, при котором в отверстии укладывается наибольшее количество зон Френеля. Наблюдайте при этом, как дифракционная картина принимает характерную кольцевую структуру." 
                          ]
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Для изменения числа зон используйте другое отверстие. При этом, не меняя расположение элементов на скамье, используйте другое отверстие на рамке со щелями. Диаметры отверстий на рамке со щелями выполнены так, чтобы при замене отверстий чередование дифракционных максимумов и минимумов менялось на противоположное." 
                          ]
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Комментируя изменения, фактически, при наблюдении через линзу при увеличении числа зон Френеля происходит изменение дифракционной картины." 
                          ]
                      }
                    ]
                  },
                  {
                    "labId":"lab19",
                    "labName": " Исследование явления преломления света ",
                    "labPictureFolder": "01-physics/04-optics/lab19/",
                    "labPages": [
                      {
                        "title": " ЛАБОРАТОРНАЯ РАБОТА N19 ",
                        "comment": [
                          " Исследование явления преломления света "
                        ]
                      },
                      {
                        "title": "ЦЕЛЬ РАБОТЫ",
                        "comment": [
                          "Установить экспериментально то, что отношение синусов углов падения и преломления есть величина ",
                          "постоянная для двух данных сред при различных углах падения света"
                        ]
                      },
                      {
                        "title": "ОБОРУДОВАНИЕ",
                        "comment": [
                          "4 булавки", "Транспортир", "Пластина с параллельными гранями", "Линейка", "Лист бумаги", "Коврик", "Остро заточенный карандаш", 
                          ],
                          "picture": "oborudovanie_19.json",
                          "pictureDark": "oborudovanie_19d.json"
                      },
                      {
                        "title": "ПОДГОТОВКА К РАБОТЕ",
                        "comment": [
                          "Приготовьте коврик и положите поверх него чистый без проколов лист белой бумаги.", 
                          "Пластину с параллельными гранями разместите приблизительно посередине листа бумаги, большим основанием вниз. Аккуратно обведите пластину с параллельными гранями карандашом (шариковой ручкой). Старайтесь не сдвигать ее за пределы ее контура. ", 
                          "Начнём с исследования при большем угле падения света.", 
                          "Начертите прямую линию длиной 7–9 см под углом 20-30 градусов в 5-7 миллиметрах от правого края верхней плоскости пластины."
                          ],
                          "picture":"optics_19_01.json",
                          "pictureDark":"optics_19_01d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "По краям этой линии воткните по одной булавке строго вертикально, чтобы одна булавка могла закрывать другую."
                          ],
                          "picture": "optics_19_02.json",
                          "pictureDark":"optics_19_02d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Перенесите взгляд на торец пластины с противоположной стороны установки булавок так, чтобы через боковую поверхность были видны воткнутые булавки. Поверните коврик так, чтобы одна булавка закрывала собой другую булавку."
                          ],
                          "picture":"optics_19_03.json",
                          "pictureDark":"optics_19_03d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Воткните булавку в коврик со стороны большего основания в том месте, где визуально обе ранее воткнутые булавки перекрыли одна другую вплотную к пластине.",
                          "Воткните еще булавку в коврик аналогично предыдущей, но ближе к себе так, чтобы все четыре булавки оказались на одной линии, но ближе к краю коврика. Положение объектов на коврике будет выглядеть как показано на рисунке. "
                          ],
                          "picture":"optics_19_04.json",
                          "pictureDark":"optics_19_04d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Удалите все булавки и пластину и соедините все точки там, где до этого были булавки.", "", 
                          "В точке, где верхняя линия касается контура пластины, нарисуйте перпендикуляр, проходящий через контур пластины так, чтобы транспортиром можно было измерить пересечения перпендикуляра с линиями света."
                          ],
                          "picture":"optics_19_05.json",
                          "pictureDark":"optics_19_05d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Измерьте транспортиром значение угла падения (между линией падающего света и перпендикуляром) (α) и угла отражения (между линией преломленного света и перпендикуляром) (β). Получив значения углов падения и отражения, вычислите их синусы по таблице.", 
                          "Отношения их синусов представляет собой коэффициент преломления двух сред - воздух/пластина или просто коэффициент преломления пластины (n):",
                          "##@@n = sin α / sin β,",
                          "В нашем случае:",
                          "##@@α = (148–90) =58°, β = (124–90) =34°.",
                          "Их синусы:",
                          "##@@sin 58° = 0,848, sin34° = 0,559.",
                          "Отношение синусов:",
                          "##@@m = 0,848 / 0,559 = 1,51."
                          ],
                          "picture":"optics_19_06.json",
                          "pictureDark":"optics_19_06d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Исследования при меньшем угле падения света. Повторите действия, приведенные ранее, с меньшим углом наклона прямой к перпендикуляру. ", 
                          "Для этого поместите транспортир на лист бумаги и воткнув булавку так, чтобы можно было провести линию от неё до точки падения света при меньшем угле падения, чем в предыдущем исследовании."
                          ],
                          "picture":"optics_19_07.json",
                          "pictureDark":"optics_19_07d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Уберите транспортир и верните пластину в её контур. Воткните вторую булавку в прежнюю точку падения света на пластину."
                          ],
                          "picture":"optics_19_08.json",
                          "pictureDark":"optics_19_08d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Перенесите взгляд на торец пластины с противоположной стороны установки булавок так, чтобы через боковую поверхность были видны воткнутые булавки. Поверните коврик так, чтобы одна булавка закрывала собой другую булавку."
                          ],
                          "picture":"optics_19_09.json",
                          "pictureDark":"optics_19_09d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Воткните булавку в коврик со стороны большего основания в том месте, где визуально обе ранее воткнутые булавки перекрыли одна другую вплотную к пластине.",
                          "Воткните еще булавку в коврик аналогично предыдущей, но ближе к себе так, чтобы все четыре булавки оказались на одной линии, но ближе к краю коврика. Положение объектов на коврике будет выглядеть как показано на рисунке."
                          ],
                          "picture":"optics_19_10.json",
                          "pictureDark":"optics_19_10d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Удалите все булавки и пластину и соедините все точки там, где до этого были булавки.",
                          "Измерьте транспортиром значение угла падения (между линией падающего света и перпендикуляром) (α) и угла отражения (между линией преломленного света и перпендикуляром) (β).",
                          "Получив значения углов падения и отражения, вычислите их синусы по таблице. Отношения их синусов представляет собой коэффициент преломления двух сред - воздух/пластина или просто коэффициент преломления пластины (n^_1^_):",
                          "##@@n^_1^_ = sin α^_1^_ ⁄sin β^_1^_.",
                          "В нашем случае: α^_1^_ = (130-90) =40°, β^_1^_= (116-90) = 25,5°.",
                          "Их синусы: sin 40° = 0,64, sin 25,5° = 0,43.",
                          "Отношение синусов: n^_1^_ = 0,64/ 0,43 = 1,5."
                          ]
                      },
                      {
                        "title": "ВЫВОД",
                        "comment": [
                          "Два измерения углов падения и преломления подтверждают, что отношения синусов углов падения к синусам углов преломления есть величина постоянная и не зависит от углов падения света."
                          ]
                      }
                    ]
                  },
                  {
                    "labId":"lab20",
                    "labName": " Исследование явления преломления вещества ",
                    "labPictureFolder": "01-physics/04-optics/lab20/",
                    "labPages": [
                      {
                        "title": " ЛАБОРАТОРНАЯ РАБОТА N20 ",
                        "comment": [
                          " Исследование явления преломления вещества "
                        ]
                      },
                      {
                        "title": "ЦЕЛЬ РАБОТЫ",
                        "comment": [
                          "Установить экспериментально то, что отношение синусов углов падения и преломления",
                          " есть величина постоянная для двух данных сред"
                        ]
                      },
                      {
                        "title": "ОБОРУДОВАНИЕ",
                        "comment": [
                          "4 булавки", "Транспортир", "Пластина с параллельными гранями", "Линейка", "Лист бумаги", "Коврик", "Остро заточенный карандаш или шариковая ручка"
                          ],
                          "picture": "oborudovanie_20.json",
                          "pictureDark": "oborudovanie_20d.json"
                      },
                      {
                        "title": "ПОДГОТОВКА К РАБОТЕ",
                        "comment": [
                          "Приготовьте коврик и положите поверх него чистый без проколов лист белой бумаги. Пластину с параллельными гранями разместите приблизительно посередине листа бумаги большим основанием вниз.", 
                          "Аккуратно обведите пластину с параллельными гранями карандашом (шариковой ручкой). ", 
                          "Старайтесь не сдвигать ее за пределы ее контура. Начертите прямую линию длиной 7–9 см под углом 20-30 градусов в 5-7 миллиметрах от правого края верхней плоскости пластины."
                          ],
                          "picture":"optics_20_01.json",
                          "pictureDark":"optics_20_01d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "По краям этой линии воткните по одной булавке строго вертикально, чтобы одна булавка могла закрывать другую."
                          ],
                          "picture": "optics_20_02.json",
                          "pictureDark":"optics_20_02d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Перенесите взгляд на торец пластины с противоположной стороны установки булавок так, чтобы через боковую поверхность были видны воткнутые булавки. Поверните коврик так, чтобы одна булавка закрывала собой другую булавку."
                          ],
                          "picture":"optics_20_03.json",
                          "pictureDark":"optics_20_03d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Воткните булавку в коврик со стороны большего основания в том месте, где визуально обе ранее воткнутые булавки перекрыли одна другую вплотную к пластине.",
                          "Воткните еще булавку в коврик аналогично предыдущей, но ближе к себе так, чтобы все четыре булавки оказались на одной линии, но ближе к краю коврика. ",
                          "Положение объектов на коврике будет выглядеть как на рисунке."
                          ],
                          "picture":"optics_20_04.json",
                          "pictureDark":"optics_20_04d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Удалите булавки и пластину и соедините прямыми линиями точки, где были булавки.", "", 
                          "В точке, где верхняя линия касается контура пластины, нарисуйте перпендикуляр, проходящий через контур пластины так, чтобы транспортиром можно было измерить пересечения перпендикуляра с линиями света."
                          ],
                          "picture":"optics_20_05.json",
                          "pictureDark":"optics_20_05d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Измерьте транспортиром значение угла падения (α) и угла отражения (β).", "", 
                          "Получив значения углов падения и отражения, вычислите их синусы по таблице. "
                          ]
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Отношения их синусов представляет собой коэффициент преломления двух сред   - воздух/пластина или просто коэффициент преломления пластины - n  :",
                          "##@@n = sin α/sin β,", 
                          "В нашем случае: α = (148–90) = 58°, β = (124–90) =34°.",
                          "Их синусы: sin58° = 0,848, sin34° = 0,55.",
                          "В нашем случае отношение синусов: n = 0,848 / 0,559 = 1,51"
                          ]
                      }
                    ]
                  },
                  {
                    "labId":"lab21",
                    "labName": " Исследование явления полного внутреннего отражения света ",
                    "labPictureFolder": "01-physics/04-optics/lab21/",
                    "labPages": [
                      {
                        "title": " ЛАБОРАТОРНАЯ РАБОТА N21 ",
                        "comment": [
                          " Исследование явления полного внутреннего отражения света "
                        ]
                      },
                      {
                        "title": "ЦЕЛЬ РАБОТЫ",
                        "comment": [
                          "Исследование полного внутреннего отражения света при падении на границу оптически менее плотной среды;",
                          " подтвердить правильность закона преломления света"
                        ]
                      },
                      {
                        "title": "ОБОРУДОВАНИЕ",
                        "comment": [
                          "4 булавки", "Транспортир", "Пластина с параллельными гранями", "Линейка", "Лист бумаги", "Коврик", "Остро заточенный карандаш или шариковая ручка"
                          ],
                          "picture": "oborudovanie_21.json",
                          "pictureDark": "oborudovanie_21d.json"
                      },
                      {
                        "title": "ПОДГОТОВКА К РАБОТЕ",
                        "comment": [
                          "Приготовьте коврик и положите поверх него чистый без проколов лист белой бумаги. На нём начертите вертикальную линию, вдоль которой будет падать свет на пластину с параллельными гранями и воткните в нее две булавки по ее краям. ", 
                          "Пластину с параллельными гранями расположите на листе бумаги большим основанием к низу так, чтобы боковая грань с меньшим углом к большему основанию оказались справа.", 
                          "Пластину аккуратно обведите карандашом (шариковой ручкой). Контур должен совпасть с вколотой булавкой."
                          ],
                          "picture":"optics_21_02.json",
                          "pictureDark":"optics_21_02d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Перенесите взгляд на большую боковую поверхность пластины.",
                          "Наблюдайте при этом отсутствие видимости булавок через пластину. "
                          ],
                          "picture": "optics_21_03.json",
                          "pictureDark":"optics_21_03d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Поверните коврик так, чтобы через боковую поверхность было видно вколотые булавки, причём одна булавка закрывала собой другую.",
                          "Вплотную к пластине воткните третью булавку так, чтобы через боковую поверхность она закрывала две ранее воткнутые булавки.",
                          "Ближе к краю коврика воткните четвертую булавку так, чтобы она перекрывала собой все три ранее воткнутые булавки."
                          ],
                          "picture":"optics_21_04.json",
                          "pictureDark":"optics_21_04d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Удалите вколотые третью и четвертую булавки и соедините прямой линией точки, где были булавки.",
                          "Перенося плавно взгляд вправо вдоль боковой поверхности пластины обратите внимание на тот факт, что начинают пропадать изображения булавок на боковой поверхности пластины. Остановите свой взгляд на том месте, где начнет исчезать последняя булавка. В этом месте с боковой стороны вплотную к пластине воткните булавку. ",
                          "Воткните еще одну булавку ближе к краю коврика так, чтобы она закрывала собой последнюю вколотую булавку и ту булавку, которую та собой закрывала. Положение объектов на коврике будет выглядеть следующим образом."
                          ],
                          "picture":"optics_21_05.json",
                          "pictureDark":"optics_21_05d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Разверните к себе коврик с объектами противоположной стороной (на 180°) и обратите внимание на то, что две булавки, воткнутые последними, оказались с противоположной стороны пластины и вплотную воткнутую к пластине булавку видно через боковую поверхность пластины."
                          ],
                          "picture":"optics_21_06.json",
                          "pictureDark":"optics_21_06d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Разверните к себе коврик так, чтобы через боковую поверхность стало видно последнюю начерченную линию и две булавки по ее краям. Удалите две первые булавки (те, которые были указаны вначале работы).",
                          "Воткните одну из этих булавок так, чтобы она перекрывала две булавки и линию между ними, видимые в боковой поверхности пластины."
                          ],
                          "picture":"optics_21_07.json",
                          "pictureDark":"optics_21_07d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Воткните булавку вплотную к пластине так, чтобы она перекрывалась ранее воткнутой булавкой."
                          ],
                          "picture":"optics_21_08.json",
                          "pictureDark":"optics_21_08d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Удалите примыкающие к пластине булавки и саму пластину и соедините прямыми линиями точки, где были булавки попарно с точками, в которые воткнуты оставшиеся булавки. В результате у вас должен получиться чертёж."
                          ],
                          "picture":"optics_21_09.json",
                          "pictureDark":"optics_21_09d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Определите исходя из чертежа внутренний угол преломления света внутри пластины при падении света от предмета (дальняя булавка) к плоскости падения света в точке падения света, при котором свет вышел в точку, в которую была воткнута ближняя булавка. Именно при этом угле преломления произошло полное отражение света, проходившего от дальней булавки к ближней булавке.",
                          "Для этого продлите линию преломленного света внутри пластины так, чтобы можно было транспортиром определить ф^_изм^_, необходимого для определения ф^_1^_ - угла отражения (угол полного отражения)."
                          ],
                          "picture":"optics_21_10.json",
                          "pictureDark":"optics_21_10d.json"
                      },
                      {
                        "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                        "comment": [
                          "Измерьте транспортиром угол преломления. ",
                          "Используя принцип обратимости световых лучей, можно считать угол преломления углом падения, при котором происходит полное внутреннее отражение. Измерив угол ф^_изм^_ = 134°, измерьте угол падения света:",
                          "##@@φ^_1^_ = φ^_изм^_ - 90° = 134° - 90° = 46°",
                          "Учитывая, что пластина из стекла, а на границе стекло – воздух (n^_1^_ ≈ 1,5 и n^_2^_≈1) предельный угол полного отражения ф^_max^_ ≈ 42°, что подтверждает правильность закона преломления света."
                          ]
                      }
                    ]
                  }
                 ]
              }, {
                  "sectionName": "Квантовые явления",
                  "sectionId": "05-quantum_phenomena", 
                  "labList": [
                    {
                      "labId":"lab01",
                      "labName": "Наблюдение сплошного и линейчатого спектров излучения",
                      "labPictureFolder": "01-physics/05-quantum_phenomena/lab01/",
                      "labPages": [
                        {
                          "title": " ЛАБОРАТОРНАЯ РАБОТА N1 ",
                          "comment": [
                            "Наблюдение сплошного и линейчатого спектров излучения"
                          ]
                        },
                        {
                          "title": "ЦЕЛЬ РАБОТЫ",
                          "comment": [
                            "Изучение спектров излучения газа (неона), металла (вольфрама) и полупроводниковой структуры (лазерный диод) с помощью дифракционной решетки"
                          ]
                        },
                        {
                          "title": "ОБОРУДОВАНИЕ",
                          "comment": [
                            "Прибор для наблюдения дифракционных спектров", "Дифракционная решетка", "Неоновая лампа в корпусе", 
                            "Лампа накаливания в корпусе", "Светоизлучающий диод в корпусе", 
                            "Фильтры R (красный), G (зеленый), B (синий)", "Источник питания", 
                            "Линейка с прорезью и шкалой", "Опора для приведения прибора в рабочее состояние", 
                            "Соединительный провод (2 шт.)"
                            ],
                            "picture": "Оборудование_квант_явл_лаб_1.json",
                            "pictureDark": "qp_oborudovanie_01_d.json"
                        },
                        {
                          "title": "КРАТКОЕ ОПИСАНИЕ",
                          "comment": [
                            "На рисунке показана схема эксперимента (вид сверху). Наблюдатель располагается слева за дифракционной решеткой. Его взгляд сквозь зону решетки, содержащую 75 линий на миллиметр, направляется на прорезь в линейке, совпадающую с нулем шкалы, нанесенной на линейку.", 
                            "Одновременно различаются деления шкалы и расположенные на ее фоне интерференционные всплески оранжевого цвета, первые из которых находятся на расстояниях ±х симметрично по обе стороны от щели, вторые, более бледные, на расстояниях ±2х, и так далее - теоретически до состояния, при котором угол φ станет равным 90°."
                            ],
                            "picture":"Краткое_описание_квант_явл_лаб_01.json",
                            "pictureDark": "qp_01_01_d.json"
                        },
                        {
                          "title": "КРАТКОЕ ОПИСАНИЕ",
                          "comment": [
                            "Для расчета длины волны излучения λ используют формулу:", 
                            "##@@λ = ±(d∙sinφ)/m      /1/ ",
                            "где d - постоянная дифракционной решетки, равная общей ширине прозрачной и непрозрачной полос, выраженной в миллиметрах. Для нашего случая:", 
                            "##@@d = 1 мм : 75- 0,0133 мм;",
                            "m - 1,2,3...- порядки дифракционных максимумов. Для максимумов, ближайших к щели, m=1.",
                            "Максимумы второго и следующих порядков рассмотреть сложно из-за ограниченной яркости источника света. Функцию sin φ для вычисления длины волны рассчитывают по формуле:",
                            "##@@sinφ = x / √(x^^2^^+L^^2^^ )     /2/",
                            "где х - отклонение первого дифракционного максимума от середины щели до середины его изображения (х = ± 12,5 мм);",
                            "L - расстояние от дифракционной решетки до шкалы (L = 285 мм). "
                            ]
                        },
                        {
                          "title": "КРАТКОЕ ОПИСАНИЕ",
                          "comment": [
                            "Прибор для наблюдения дифракционных спектров состоит из Т-образного основания 1, выполненного из алюминиевого проката квадратного сечения с продольными пазами, служащими для крепления внешних устройств, а также для объединения отрезков труб в пространственные конструкции.",
                            "Длина продольного элемента равна 300 мм, поперечного - 100 мм. Наружные торцы элементов основания закрыты заглушками 2."
                            ],
                            "picture":"Краткое_описание_квант_явл_лаб_01(рис2).json",
                            "pictureDark": "qp_01_03_d.json"
                          },
                        {
                          "title": "КРАТКОЕ ОПИСАНИЕ",
                          "comment": [
                            "Слева в продольной направляющей установлен опорный стержень 3, который при выполнении опытов используется для приведения основания в наклонное положение, удобное для визуального наблюдения.",
                            "Вдоль продольной направляющей с небольшим трением перемещаются две опоры 4 и 5. На опоре 4 с помощью магнита крепится дифракционная решетка, а на опоре 5 фиксируется линейка с прорезью и шкалой.",
                            "Платформа 6 служит для устойчивого и точного размещения одного из трех источников света: неоновой лампы, лампы накаливания или светоизлучающего диода.",
                            "На рис. 3 показан прибор для наблюдения дифракции, приведенный в рабочее состояние. Дифракционная решетка 1 крепится на подвижной опоре 4 (см. рис. 2), что позволяет смещать ее вдоль продольной направляющей в нужное положение.",
                            "Шкала с прорезью 2 своим выступом в нижней части крепится в прямоугольном отверстии опоры 5 (см. рис. 2)."
                            ]
                        },
                        {
                          "title": "КРАТКОЕ ОПИСАНИЕ",
                          "comment": [
                            "Блоки осветителей 3 поочередно устанавливаются на платформу 6 (см. рис. 3) так, чтобы луч от элемента, излучающий свет, был параллелен основанию и попадал на нужный участок дифракционной решетки, а затем в глаз наблюдателя.", 
                            "Направление взгляда наблюдателя условно показано в виде рисунка глаза."
                            ],
                            "picture":"Краткое_описание_квант_явл_лаб_05.json",
                            "pictureDark": "qp_01_05_d.json"
                        },
                        {
                          "title": "КРАТКОЕ ОПИСАНИЕ",
                          "comment": [
                            "На рисунке 4 показаны дифракционная решетка (а) и фильтры (б)."
                            ],
                            "picture":"Краткое_описание_квант_явл_лаб_06.json",
                            "pictureDark": "qp_01_06_d.json"
                        },
                        {
                          "title": "КРАТКОЕ ОПИСАНИЕ",
                          "comment": [
                            "Рабочая зона решетки разбита на четыре участка с числом линий на 1 мм 50, 75, 300 и 600.", 
                            "Каждая линия состоит из двух линий - прозрачной для света и непрозрачной. Располагаются линии вертикально для рис. 4 (а). Именно в такой ориентации решетка должна устанавливаться в прибор. В лабораторной работе удобно использовать зону с числом линий 75. Следовательно, прибор должен быть отрегулирован так, чтобы центральный луч света проходил через эту зону.",
                            "Регулировка достигается установкой решетки на рабочую позицию относительно наблюдателя в положении, показанном на рис. 4 (а). Уточненное положение решетки получают ее сдвигом в горизонтальном направлении до совпадения линии наблюдения со щелью в линейке. При этом дифракционная картина должна быть симметрична относительно щели."
                            ]
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            "##@@Наблюдение спектра излучения неоновой лампы и определение длины волны неона", "", 
                            "Соберите прибор для наблюдения дифракционных спектров как показано на рис. 3. Линейку с прорезью и шкалой закрепите нижним выступом в прямоугольном отверстии опоры 5 (см. рис. 2). Убедитесь, что при взгляде вдоль продольной направляющей хорошо видны деления шкалы линейки. Дифракционную решетку закрепите на магните подвижной опоры 4 (см. рис. 2) и сместите ее в поперечном направлении так, чтобы прорезь в шкале линейки была видна сквозь зону решетки, имеющую 75 линий на мм.", "", 
                            "Позади шкалы установите блок осветителя «Неоновая лампа» и подключите его к источнику переменного напряжения 5 В.", "", 
                            "Откорректируйте положение блока так, чтобы сквозь прорезь линейки был хорошо виден источник света."
                            ]
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [],
                            "picture":"Краткое_описание_квант_явл_лаб_09.json",
                            "pictureDark": "qp_01_09_d.json"
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            "По обе стороны от центрального максимума появится спектр излучения неона (рис. 6). Определите по шкале расстояния от щели до центров первых и вторых максимумов, т.е. ±х и ±2х. При отклонении измеренных значений от показанных на рис. 6, откорректируйте рисунок, убедившись в правильности сборки прибора. Постарайтесь при этих измерениях сохранять угол между линейной и продольной направляющей как можно ближе к 90°.",
                            "Запишите полученные данные в отчет. Измерьте расстояние L от дифракционной решетки до линейки. Воспользовавшись формулой 2, рассчитайте sinф, используя параметр x, полученный ранее.",
                            "Зарисуйте дифракционный спектр неоновой лампы - рис. 6. Этот спектр получен расчетным путем."
                            ],
                            "picture":"Краткое_описание_квант_явл_лаб_10.json",
                            "pictureDark": "qp_01_10_d.json"
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            "На рис. 7 показано фото реального дифракционного спектра неоновой лампы. Обратите внимание на присутствие в спектральных максимумах красной и желтой составляющих. Это явление объясняется тем, что неоновое свечение содержит не только оранжевые линии, но также по обе стороны расположенные красные и желтые.",
                            "По формуле 1 определите длину волны излучения неоновой лампы.",
                            "Из опыта следует, что неоновая лампа имеет линейчатый спектр излучения, длина волны которого, рассчитанная по формуле 1, находится в пределах диапазона 590... 625 нм и соответствует длине волны одной из линий оранжевого цвета."
                            ],
                            "picture":"Краткое_описание_квант_явл_лаб_11.json",
                            "pictureDark": "qp_01_11_d.json"
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            "##@@Наблюдение спектра излучения лампы накаливания", "", 
                            "Позади шкалы установите блок «Лампа накаливания» и подключите его к источнику постоянного напряжения. На рис. 8 (вид сверху) условно показана дифракция (расщепление) белого света на семь цветов спектра. ",
                            "Как видно, оранжевый цвет спектра находится на том же удалении от щели, что и при дифракции волны от неоновой лампы (см. рис. 6 и 7)."
                            ],
                            "picture":"Краткое_описание_квант_явл_лаб_12.json",
                            "pictureDark": "qp_01_12_d.json"
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            "Излучение лампы накаливания представляет собой белый свет, содержащий все длины волн, перекрывающие диапазон видимого света 740...380 нм. Этот диапазон принято разделять на семь поддиапазонов по цвету средней длины волны:", 
                            "740... 625 нм - красный цвет;",
                            "625... 590 нм - оранжевый цвет;",
                            "590... 565 нм - желтый цвет;",
                            "565... 500 нм - зеленый цвет; ",
                            "500... 485 нм - голубой цвет;",
                            "485... 440 нм - синий цвет;", 
                            "440... 380 нм - фиолетовый цвет.", 
                            "На рис. 9 показан полученный расчетом результат дифракции белого света на шкале."
                            ]
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [],
                            "picture":"Краткое_описание_квант_явл_лаб_14.json",
                            "pictureDark": "qp_01_14_d.json"
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            "На рис. 10 показан результат дифракции белого света, наблюдаемый сквозь дифракционную решетку.", "", 
                            "На рисунке видно, что боковые максимумы содержат полный спектр белого света."
                            ],
                            "picture":"Краткое_описание_квант_явл_лаб_15.json",
                            "pictureDark": "qp_01_15_d.json"
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            "Для выделения отдельных спектральных линий используются фильтры- устройства, обладающие избирательным пропусканием одного цвета и подавлением остальных цветов.", "", 
                            "На рис. 11 и 12 показаны спектры лампы накаливания, прошедшие через красный фильтр."
                            ],
                            "picture":"Краткое_описание_квант_явл_лаб_16.json",
                            "pictureDark": "qp_01_16_d.json"
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            "На рис. 13 и 14 представлены спектры излучения лампы накаливания, прошедшие через зеленый фильтр."
                            ],
                            "picture":"Краткое_описание_квант_явл_лаб_17.json",
                            "pictureDark": "qp_01_17_d.json"
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            "На рис. 15 и 16 представлены спектры излучения лампы накаливания, прошедшие через синий фильтр."
                            ],
                            "picture":"Краткое_описание_квант_явл_лаб_18.json",
                            "pictureDark": "qp_01_18_d.json"
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            "##@@Наблюдение спектра излучения светодиода",
                            "Позади линейки установите блок «Светодиод» и подключите его к источнику постоянного напряжения 5В."
                            ],
                            "picture":"Краткое_описание_квант_явл_лаб_19.json",
                            "pictureDark": "qp_01_19_d.json"
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            "На рисунках 18 и 19 показаны спектры излучения красного света, расчетный и реальный соответственно.",
                            ""
                            ],
                            "picture":"Краткое_описание_квант_явл_лаб_20.json",
                            "pictureDark": "qp_01_20_d.json"
                        }                      
                      ]
                    }, {
                      "labId":"lab02",
                      "labName": "Измерение фонового радиоактивного излучения",
                      "labPictureFolder": "01-physics/05-quantum_phenomena/lab02/",
                      "labPages": [
                        {
                          "title": " ЛАБОРАТОРНАЯ РАБОТА N2 ",
                          "comment": [
                            "Измерение фонового радиоактивного излучения"
                          ]
                        },
                        {
                          "title": "ЦЕЛЬ РАБОТЫ",
                          "comment": [
                            "Измерение фонового радиоактивного излучения с помощью счетчика Гейгера"
                          ]
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            "Подключите датчик Гейгера к компьютеру с помощью USB-кабеля.", 
                            "В окне программы нажмите Пуск для начала измерений.",
                            "В течение 1 минуты регистрируйте радиационный фон R^_k^_ по показаниям датчика N^_k^_ как: ",
                            "##@@R^_k^_ = 1/N^_k^_.",
                            "Вычислите среднее значение в указанном интервале времени:",
                            "##@@R^_ср^_ = 1 / (n ∑R^_k^_),",
                            "где n - число измерений за 1 минуту (рекомендуется взять 5 значений)."
                            ]
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            "Повторите измерения m раз с интервалом в 2–3 минуты.", 
                            "В каждом из опытов определите среднее значение R^_ср i^_.",
                            "Окончательный результат измерений представьте в виде:", 
                            "##@@R = 1 / (m ∑R^_ср i^_)"
                            ]
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            ],
                            "picture":"qp_02_03.json"
                        }                      
                      ]                    
                    }, {
                      "labId":"lab03",
                      "labName": " Исследование устройства полупроводникового фотоэлемента",
                      "labPictureFolder": "01-physics/05-quantum_phenomena/lab03/",
                      "labPages": [
                        {
                          "title": " ЛАБОРАТОРНАЯ РАБОТА N3 ",
                          "comment": [
                            " Исследование устройства полупроводникового фотоэлемента"
                          ]
                        },
                        {
                          "title": "ЦЕЛЬ РАБОТЫ",
                          "comment": [
                            "Ознакомиться с устройством и действием полупроводникового фотоэлемента; исследовать зависимость напряжения на выводах фотоэлемента от его освещённости"
                          ]
                        },
                        {
                          "title": "ОБОРУДОВАНИЕ",
                          "comment": [
                            "Полупроводниковый фотоэлемент в корпусе", "Лампа накаливания в корпусе", "Источник питания", "Вольтметр", 
                            "Соединительный провод (2 шт.)"
                            ],
                            "picture": "Оборудование_квант_явл_лаб_3.json",
                            "pictureDark": "qp_oborudovanie_03_d.json"
                          },
                        {
                          "title": "ПОДГОТОВКА К РАБОТЕ",
                          "comment": [
                            "Работа проводится в два этапа.", 
                            "На первом этапе необходимо экспериментально установить наличие у фотоэлемента p-n перехода.",
                            "В ходе второго этапа определяется на качественном уровне зависимость напряжения на выводах фотоэлемента от интенсивности попадающего на него светового потока.","",
                            "Для выполнения первого этапа работы с помощью соединительных проводов соберите электрическую цепь."
                            ],
                            "picture":"Краткое_описание_квант_явл_лаб_03_01.json",
                            "pictureDark": "qp_03_01_d.json"
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            "В этой цепи последовательно с источником тока соединен фотоэлемент и лампа накаливания. Собранную электрическую цепь подключите к лабораторному источнику питания так, чтобы к фотоэлементу было приложено напряжение в обратной полярности.", 
                            "Положительный вывод фотоэлемента помечен на корпусе знаком «+». Фотоэлемент полностью закройте от света. Убедитесь, что лампа не горит. Затем фотоэлемент подключите к выпрямителю в прямой полярности и наблюдайте свечение лампы накаливания. На основании наблюдений сделайте вывод об односторонней проводимости фотоэлемента."
                            ],
                            "picture":"Краткое_описание_квант_явл_лаб_03_02.json",
                            "pictureDark": "qp_03_02_d.json"
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            "Для выполнения второго этапа работы соберите схему."
                            ],
                            "picture":"Краткое_описание_квант_явл_лаб_03_03.json",
                            "pictureDark": "qp_03_03_d.json"
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            "К выводам фотоэлемента подключите вольтметр.",
                            "Лампу накаливания подключите к источнику питания.",
                            "Установив лампу вплотную к фотоэлементу, определите максимальное значение напряжения, возникающее на его выводах."
                            ],
                            "picture":"Порядок_квант_явл_лаб_раб_03_01_1.json"
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            "Таблица для занесения результатов."
                            ],
                            "table":[
                              "Uxx, В||||||||||",
                              "L, см||||||||||"
                            ]
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            "Медленно отодвигая лампу от фотоэлемента наблюдайте изменение интенсивности освещения его поверхности и соответствующие изменения показаний вольтметра. ",
                            "По итогам измерений определите зависимость напряжения холостого хода фотоэлемента от расстояния до источника освещения.",
                            "Результаты измерений с шагом в 0,5 см занесите в таблицу."
                            ],
                            "picture":"Порядок_квант_явл_лаб_раб_03_01_2.json"
                        }
                      ]
                    }, 
                    {
                      "labId":"lab04",
                      "labName": "Изучение волновых свойств излучения светодиода",
                      "labPictureFolder": "01-physics/05-quantum_phenomena/lab04/",
                      "labPages": [
                        {
                          "title": " ЛАБОРАТОРНАЯ РАБОТА N4 ",
                          "comment": [
                            "Изучение волновых свойств излучения светодиода"
                          ]
                        },
                        {
                          "title": "ЦЕЛЬ РАБОТЫ",
                          "comment": [
                            "Определение длины волны излучения светодиода"
                          ]
                        },
                        {
                          "title": "ОБОРУДОВАНИЕ",
                          "comment": [
                            "Светодиод", "Дифракционная решетка", "Источник постоянного тока", "Линейка с прорезью и шкалой", 
                            "Опора для приведения прибора в рабочее состояние", "Вольтметр" 
                            ],
                            "picture": "Оборудование_квант_явл_лаб_4.json",
                            "pictureDark": "qp_oborudovanie_04_d.json"
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            "В соответствии с лабораторной работой №1 соберите рабочую установку для проведения опыта.", 
                            "Подключите светодиод к источнику постоянного тока."
                            ],
                            "picture":"Порядок_квант_явл_лаб_04_01.json",
                            "pictureDark": "qp_04_01_d.json"
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            "Для расчета длины волны излучения λ используют формулу:", 
                                  "##@@λ = ±(d∙sinφ) / m",
                                  "где φ - угол, под которым наблюдается максимум m-порядка, этот угол можно найти зная расстояние L от решетки до щели и смещение их максимума относительно центра щели:", 
                                  "##@@sinφ = x/√(x^^2^^+L^^2^^)",
                                  "m - 1, 2, 3...- порядки дифракционных максимумов. Для максимумов, ближайших к щели, m=1.",
                                  "d = 1 / N - постоянная дифракционной решетки, равная общей ширине прозрачной и непрозрачной полос, выраженной в миллиметрах (число линий на 1 мм);",
                                  "х - отклонение первого дифракционного максимума от середины щели до середины его изображения (х=± 13,5 мм);",
                                  "L - расстояние от дифракционной решетки до шкалы (L = 285 мм). "
                            ]
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            "Дифракционная решетка имеет 4 сектора с разными N.",
                            "Тогда выражение для длины волны примет вид:",
                            "##@@λ = ±1  mNꞏx / √(x^^2^^ + L^^2^^)",
                            "Знак «+» в формуле указывает, что исследуемый максимум находится справа от щели, знак «-» что слева от щели.",
                            "Для более точного проведения работы рекомендуется уменьшить яркость освещения в помещении.",
                            "После включения источника света откалибруйте установку, чтобы источник света располагался вплотную к щели и ровно посередине. ",
                            "Наблюдая дифракционную картину излучения светодиода определите длину волны для разных максимумов m по формуле, измерив величины x и L, и зная d."
                            ]
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            "Для каждого максимума проведите серию измерений, меняя расстояние L и используя различные дифракционные решетки."
                            ],
                            "picture":"Порядок_квант_явл_лаб_04_03.json",
                            "pictureDark": "qp_04_03_d.json"
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            "Измеренные значения запишите в таблицу."
                            ],
                            "picture":"Порядок_квант_явл_лаб_04_04.json",
                            "pictureDark": "qp_04_04_d.json"
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            ],
                            "table":[
                              "№|Порядок максимума m|N, 1/мм|Смещение х, м|Расстояние L, м|Длина волны λ, м",
                              "1| ||||",
                              "2| ||||",
                              "3| ||||",
                              "4| ||||",
                              "5| ||||"
                            ]
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            "В каждом случае рассчитайте погрешность длины волны ∆λ.",
                            "Вычислите среднее значение для определнного цвета после серии из n измерений λ^_ср^_ = 1/n ꞏ Σλ^_i^_.",
                            "Тогда абсолютная погрешность будет равна:",
                            "**lab04formula01.JPG",
                            "где: ",
                            "∆х - погрешность измерения смещения, равная половине цены деления линейки;",
                            "∆L - погрешность измерения расстояния, равная половине цены деления измерительной рулетки.", "", 
                            "Итоговое значение для длины волны примет вид:",
                            "##@@λ = λ^_ср^_ ± ∆λ"
                            ]
                        }                      
                      ]                    
                    }, {
                      "labId":"lab05",
                      "labName": "Изучение квантовых свойств излучения светодиода",
                      "labPictureFolder": "01-physics/05-quantum_phenomena/lab05/",
                      "labPages": [
                        {
                          "title": " ЛАБОРАТОРНАЯ РАБОТА N5 ",
                          "comment": [
                            "Изучение квантовых свойств излучения светодиода"
                          ]
                        },
                        {
                          "title": "ЦЕЛЬ РАБОТЫ",
                          "comment": [
                            "Определение постоянной Планка"
                          ]
                        },
                        {
                          "title": "ОБОРУДОВАНИЕ",
                          "comment": [
                            "Светодиод", "Панель из комплекта «Электродинамика»", "Вольтметр", "Амперметр", "Источник питания"
                            ],
                            "picture": "Оборудование_квант_явл_лаб_5.json",
                            "pictureDark": "qp_oborudovanie_05_d.json"
                        },
                        {
                          "title": "ПОДГОТОВКА К РАБОТЕ",
                          "comment": [
                            "Соберите схему."
                            ],
                            "picture":"Порядок_квант_явл_лаб_05_01.json",
                            "pictureDark": "qp_05_01_d.json"
                          },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            "Для этого соберите установку."
                            ],
                            "picture":"Порядок_квант_явл_лаб_раб_05_01.json"
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            "Ручку потенциометра R^_3^_ поверните против часовой стрелки до упора.",
                            "Замкните ключ К.",
                            "Начните поворачивать ручку потенциометра по часовой стрелке.",
                            "Осторожно вращая ручку потенциометра R^_3^_ по часовой стрелке, наблюдайте плавное увеличение прямого напряжения на диоде. ",
                            "Необходимо поймать момент, при котором появится прямой ток диода и ток начнет быстро возрастать.",
                            "В данном случае этот ток может находится в диапазоне 0,3...0,4 мА. При этом напряжение на диоде достигнет 1,7...1,75 В."
                            ],
                            "picture":"Порядок_квант_явл_лаб_раб_05_02.json"
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            "С этой точки начинается прямая ветвь вольтамперной характеристики диода. ",
                            "Продолжая вращать ручку потенциометра по часовой стрелке установите ток на миллиамперметре 6 мА и измерьте напряжение на диоде с резистором.", "", 
                            "По измеренным данным постройте график вольтамперной характеристики диода. Полная вольтамперная характеристика диода будет выглядеть приблизительно следующим образом."
                            ],
                            "picture":"Порядок_квант_явл_лаб_раб_05_03.json"
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            "Этого вполне достаточно, чтобы оценить поведение диода на прямой ветви характеристики."
                            ],
                            "picture":"Порядок_квант_явл_лаб_05_04.json",
                            "pictureDark": "qp_05_04_d.json"
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            "Учитывая падение напряжения на балластном резисторе светодиода 300 Ом, истинное напряжение на диоде окажется ниже на 0,0003...0,0004ꞏ91 Ом, то есть приблизительно на 0,105 В меньше измеренного вольтметром, то есть 1,75-0,105 = 1,645 В.", "",
                            "При увеличении напряжения с шагом 0,1 В записывайте показания миллиамперметра в таблицу."
                            ],
                            "table":[
                              "U, В|||||||",
                              "I, мА|||||||"
                            ]
                        },
                        {
                          "title": "ПОРЯДОК ВЫПОЛНЕНИЯ",
                          "comment": [
                            "По полученным экспериментальным значениям постройте вольтамперную характеристику светодиода (зависимость силы тока I от напряжения U) и по ней определите контактную разность потенциалов U^_k^_, проведя к вольтамперной характреристике светодиода касательную до пересечения ее с осью абсцисс (осью напряжений).", "",
                            "Данные по длине волны λ излучения светодиода используйте из лабораторной работы №4.",
                            "С помощью экспериментально полученных значений определите величину постоянной Планка h^_изм^_ по формуле.",
                            "Рассчитайте погрешность измерений как:",
                            "**lab05formula01.jpg",
                            "где ∆U = 5% - погрешность измерения вольтемтра; ∆λ - погрешность измерения длины волны; e = 1,6ꞏ10^^-19^^Кл - заряд электрона; c = 3ꞏ10^^8^^м/с - скорость света в вакууме.",
                            "Окончательный вид для постоянной Планка:",
                            "##@@h = h^_изм^_ ± ∆h.",
                            "Сравните полученное значение с табличным."
                            ]
                        }
                      ]
                    } 
                  ]
              }
          ]
      }
  ]
}

export default labsList;

//JSON Editors:
//https://jsoneditoronline.org/
//vsc
