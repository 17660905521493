import React, {useState, useEffect} from "react";
import {toast} from "react-toastify";
import Modal from "../../Components/Modal";
import {piApi} from "../../api/api";
import {Button, Input, Tooltip} from "../ui";
import {METHOD, UNIT_RELATION, getSystems, getSwitcherHasLabel, getCategories} from './pi_utils';
import { getDropdown } from "../ui/utils/gen_utils";
import {storageClass} from "../../redux/slices/storage";
import {loadPIUnits} from "../../redux/slices/lists";
import {useDispatch, useSelector} from "react-redux";
import "./PInvest.scss";

const PIShowUnitCreateDlg = ({showDlg, setShowDlg}) => {
  const [unitName, setUnitName] = useState('');
  const [unitSymbol, setUnitSymbol] = useState('');
  const [unitDescription, setUnitDescription] = useState('');
  const [unitRelativeValue, setUnitRelativeValue] = useState('');
  const [unitRelativeUnitId, setUnitRelativeUnitId] = useState(-1);
  const [unitList, setUnitList] = useState([]); //метр, километр, аршин...
  //
  const [categoryId, setCategoryId] = useState(-1);
  const [categoryName, setCategoryName] = useState("");
  const [categoryMethod, setCategoryMethod] = useState(METHOD.LIST);
  const [categoryList, setCategoryList] = useState([]); //время, длина ...
  //
  const [systemId, setSystemId] = useState(-1); ////используется при выборе из списка
  const [systemName, setSystemName] = useState(""); //название новой добавляемой пользователем системы измерений
  const [systemMethod, setSystemMethod] = useState(METHOD.LIST);  //true - выбор type из списка, false - ввод новоой system
  const [systemList, setSystemList] = useState([]); //СИ, ...


  const documentClass = useSelector(storageClass);
  const {lists: {pi}} = useSelector(state => state);
  const dispatch = useDispatch();

  useEffect(() => {
    setUnitName('');
    setUnitSymbol('');
    setUnitDescription('');
    setUnitRelativeValue('');
    setUnitRelativeUnitId(-1);
    setUnitList([]);
    setCategoryId(-1);
    setCategoryName("");
    setCategoryMethod(METHOD.LIST);
    setCategoryList([]);
    setSystemId(-1);
    setSystemName("");
    setSystemMethod(METHOD.LIST);
    setSystemList([]);
  }, [showDlg]);

  useEffect(() => {
    if (!pi) return;
    setCategoryList(getCategories(pi.quants));
    setSystemList(getSystems(pi.systems));
  }, [pi]);

  useEffect(() => {
    const uList = [];
    for (let [value, unit] of Object.entries(pi.units)) {
      if (unit.quant === categoryId && unit.system === systemId) { // && unit.relation === UNIT_RELATION.BASIC
        uList.push({label: unit.name, value: unit._id});
      }
    }

    const units = uList.sort((a, b) => a.label.localeCompare(b.label));
    units.unshift({label: 'Выберите измеряемую величину', value: -1});
    setUnitList(units);
  }, [pi, categoryId, systemId]);

  const handleChangeUnitName = v => {
    if (v.length <= 20) setUnitName(v)
  };
  const handleChangeUnitSymbol = v => {
    if (v.length <= 8) setUnitSymbol(v)
  };
  const handleChangeUnitDescription = v => {
    setUnitDescription(v)
  };
  const handleChangeUnitRelativeValue = v => {
    if (!isNaN(Number(v))) setUnitRelativeValue(Number(v));
  };
  const handleChangeUnitRelativeUnitId = v => {
    setUnitRelativeUnitId(v)
  };
  const handleChangeCategoryId = v => {
    setCategoryId(v)
  };
  const handleCategoryMethod = (value) => {
    if (value === categoryMethod) return;
    setCategoryMethod(value); //reset method
    setCategoryName(''); //initialize
    setCategoryId(-1); //initialize
  };

  const handleChangeSystemId = v => {
    setSystemId(v)
  };
  const handleSystemMethod = (value) => {
    if (value === systemMethod) return;
    setSystemMethod(value); //reset method
    setSystemName(''); //initialize
    setSystemId(-1); //initialize
  };

  const checkData = () => {
    let warning = undefined;
    if (!unitName.trim()) warning = 'Введите название единицы измерения.';
    else if (!unitSymbol.trim()) warning = 'Введите обозначение.';
    else if (categoryMethod === METHOD.MANUAL && !categoryName.trim()) warning = 'Введите измеряемую величину.';
    else if (categoryMethod === METHOD.LIST && categoryId === -1) warning = 'Выберите измеряемую величину.';
    else if (systemMethod === METHOD.MANUAL && !systemName.trim()) warning = 'Введите название системы измерения.';
    else if (systemMethod === METHOD.LIST && systemId === -1) warning = 'Выберите название системы измерения.';
    else if (categoryMethod === METHOD.LIST && !unitRelativeValue) {
      if (unitList.length > 1)
        warning = 'Введите значение единицы измерения.';
      else
        warning = 'Единицы выбранной вами категории отсутствют в данной системе измерений.';
    } else if (categoryMethod === METHOD.LIST && unitRelativeUnitId === -1) warning = 'Выберите базовую единицу измерения.';
    if (warning) toast.warn(warning);
    return !warning;
  };

  const addCategory = async () => {
    const category = {
      name: categoryName.trim(),
      sign: '',
      room: documentClass._id,
    };
    piApi.addQuant(category).then((result) => setCategoryId(result._id));
  };

  const addSystem = async () => {
    const system = {
      name: systemName.trim(),
      room: documentClass._id,
    };
    await piApi.addSystem(system).then((result) => setSystemId(result._id));
  };

  const addDependecy = async (to) => {
    const dependency = {
      formula: unitRelativeValue,
      from: unitRelativeUnitId,
      to,
      room: documentClass._id,
    };
    await piApi.addDependency(dependency);
  }

  const addUnit = async () => {
    if (categoryId === -1 || systemId === -1) return;

    const unit = {
      name: unitName.trim(),
      sign: unitSymbol.trim(),
      desc: unitDescription.trim(),
      quant: categoryId,
      system: systemId,
      relation: categoryMethod === METHOD.MANUAL ? UNIT_RELATION.BASIC : unitRelativeValue > 1 ? UNIT_RELATION.UP : UNIT_RELATION.DOWN,
      room: documentClass._id,
    };

    return await piApi.addUnit(unit);
  };

  const handleSaveUnit = async () => {
    if (!checkData()) return;
    try {
      if (categoryMethod === METHOD.MANUAL) await addCategory();
      if (systemMethod === METHOD.MANUAL) await addSystem();
      const result = await addUnit();
      console.log(result)
      if (categoryMethod === METHOD.LIST) {
        await addDependecy(result._id)
      }

      setShowDlg(false);
      toast.success("Новая единица измерения '" + unitName.trim() + "' создана.");
			dispatch(loadPIUnits(documentClass._id))
    } catch (err) {
      toast.error(err.response.data.errors);
    }
  };

  const getRelationToAnotherValue = () => {
    if (categoryId === -1 || systemId === -1 || unitName.trim() === '')
      return (
        <>
          <div className="cor-net__row row-content">
            Введите название новой единицы измерения,
            а также выберите категорию измеряемой величины и систему измерения,
            чтобы установить отношение к другим единицам измерения.
          </div>
          <div className="cor-net__row row-content">
            Только в категории СИ есть все категории измерения.
            В остальных системах количество категорий очень ограничено.
          </div>
          <div className="cor-net__row row-content">
            Например, вы хотите ввести новую единицу измерения в категории Давление в системе СИ.
            Выбрав категогорию Давление и систему СИ, вы обнаружите список из 4 единиц давления, через
            любую их которых вы можете выразить вашу новую единицу измерения.
          </div>
          <div className="cor-net__row row-content">
            Но если для той же цели вы вместо системы СИ выберете, например, Старорусскую систему мер,
            то вы не сможете выразить вашу новую единицу давления через единицы измерений этой системы,
            поскольку в ней есть единицы только из категорий Длина, Масса и Площадь.
          </div>
        </>
      );

    if (unitList.length === 1) {
      const system = systemList.find(item => item.value === systemId);
      const systemName = system?.label;
      const category = categoryList.find(item => item.value === categoryId);
      const msg1 = category ? "Единицы измерения из категории '" + category.label + "' " :
        "Единицы измерения из выбранной вами категории ";

      return (
        <div className="cor-net__row row-content">
          {msg1} отсутствуют в системе измерения {systemName}
        </div>
      );
    }

    return (
      <div className="cor-net__row">
        <div className="cor-net__col col-1">
          <div className="cor-net__label mb_0">Отношение к другим единицам измерения</div>
        </div>
        <div className="cor-net__col">
          <div className="cor-net__label mb_0"> {unitName} =</div>
        </div>
        <div className="cor-net__col col-4">
          <Input value={unitRelativeValue} placeholder={'Значение'}
                 onInput={e => handleChangeUnitRelativeValue(e.target.value)}/>
        </div>
        <div className="cor-net__col">
          <div className="cor-net__label mb_0">*</div>
        </div>
        <div className="cor-net__col col-grow">
          {getDropdown(unitList, unitRelativeUnitId, value => handleChangeUnitRelativeUnitId(value), '', '', 'ur01')}
        </div>
      </div>
    );
  };

  const setModalContent = () => {
    return (
      <>
        <div className="modal__close" onClick={() => setShowDlg(false)}></div>
        <div className="modal__message">Создание единицы измерения</div>
        <div className="modal__body pi-modalCreatingMeasure">
          <div className="modal__row flex">
            <div className="modal__col">
              <div className="cor-net__row">
                <div className="cor-net__col col-grow">
                  <div className="cor-net__label">Новая единица измерения <Tooltip position="right">Пример:
                    метр</Tooltip></div>
                  <Input
                    value={unitName}
                    placeholder={'Введите название единицы измерения'}
                    onInput={e => handleChangeUnitName(e.target.value)}/>
                </div>
              </div>
              <div className="cor-net__row">
                <div className="cor-net__col col-grow">
                  <div className="cor-net__label">Обозначение <Tooltip position="right">Пример: м</Tooltip></div>
                  <Input value={unitSymbol} placeholder={'Введите обозначение'}
                         onInput={e => handleChangeUnitSymbol(e.target.value)}/>
                </div>
              </div>

              <div className="cor-net__row">
                <div className="cor-net__col col-grow">
                  <div className="cor-net__label flex jc-between">
                    <span>Категория измеряемых величин &nbsp;<Tooltip
                      position="right">Пример: категория 'Время'</Tooltip></span>
                    {getSwitcherHasLabel('Выбрать категорию из списка|Ввести новую категорию', categoryMethod, () => handleCategoryMethod(!categoryMethod))}
                  </div>
                  {categoryMethod === METHOD.LIST ?
                    getDropdown(categoryList, categoryId, value => handleChangeCategoryId(value), '', '', 'qnt01')
                    :
                    <Input value={categoryName} placeholder={'Введите новую категорию'}
                           onInput={e => setCategoryName(e.target.value)}/>
                  }
                </div>
              </div>

              <div className="cor-net__row">
                <div className="cor-net__col col-grow">
                  <div className="cor-net__label flex jc-between">

                    <span>Системы измерения &nbsp;<Tooltip position="right">пример: СИ</Tooltip></span>
                    {getSwitcherHasLabel(' Выбрать систему измерения из списка|Ввести новую систему измерения', systemMethod, () => handleSystemMethod(!systemMethod))}
                  </div>

                  {systemMethod === METHOD.LIST ?
                    getDropdown(systemList, systemId, value => handleChangeSystemId(value), '', '', 'st01')
                    :
                    <Input value={systemName} placeholder={'Введите название системы измерения'}
                           onInput={e => setSystemName(e.target.value)}/>
                  }
                </div>
              </div>

              <div className="cor-net__row">
                <div className="cor-net__col col-grow">
                  <div className="cor-net__label">Текстовое описание</div>
                  <textarea key={'ta01'} placeholder={'Введите описание'}
                            value={unitDescription} onInput={e => handleChangeUnitDescription(e.target.value)}/>
                </div>
              </div>
            </div>
            <div className="modal__col">
              {getRelationToAnotherValue()}
            </div>
          </div>

        </div>
        <div className="modal__action">
          <Button color="primary" size="medium" onClick={handleSaveUnit}>Сохранить</Button>
          <Button color="primary" size="medium" border={true} onClick={() => setShowDlg(false)}>Отменить</Button>
        </div>
      </>
    );
  };

  if (!showDlg) {
    return false;
  }

  return (
    <Modal visible={showDlg} content={setModalContent()} size={'xl'}/>
  )
}
export default PIShowUnitCreateDlg;