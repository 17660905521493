import React, {useState} from 'react';
import "./SettingsIcon.scss";
import {Icon} from '../../ui';
import {printUserRole, printUserRoleGen} from "../../../helpers/text";
import {isAuth} from "../../../helpers/auth";
import FeaturesModal from "./FeaturesModal";

const SettingsIcon = (props) => {
  const [modal, toggleModal] = useState(false);

  return (
    <>
      <div className="cor_settingsIcon">
        {isAuth().role < 3 &&
        <div className="cor_settingsIcon__btn tp tp_down" data-tp="Лицензии" onClick={() => toggleModal(true)}>
          <Icon name="license"/>
        </div>}
        <div className="cor_settingsIcon__btn tp tp_down" data-tp={isAuth().role > 1 ? "Профиль" : "Устройства"}
             onClick={() => isAuth().role > 1 ?
          props.showUserSettings({showModal: true, role: isAuth().role, user: isAuth()}) :
          props.showVideoSettings({showModal: true})}>
          <Icon name="gear"/>
        </div>
        {isAuth().role < 3 &&
          <div className="cor_settingsIcon__btn tp tp_down"
               data-tp={"Скачать Инструкцию " + printUserRoleGen(isAuth().role)}
               onClick={() => window.open("/cor_" + (printUserRole(isAuth().role).toLowerCase()) + ".docx")}>
            <Icon name="dock"/>
          </div>}
      </div>
      {modal && <FeaturesModal modal={modal} toggleModal={toggleModal}/>}
    </>
  )
};
export default SettingsIcon;
