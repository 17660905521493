const initialState = {
    timer: 0,
    stopwatch: 0,
    settings: 'mm:ss:SSS',
    timerModal: false
};

const timerReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'SET_TIMER':
            return {
                ...state,
                timer: action.payload,
            };
            break;

        case 'SET_STOPWATCH':
            return {
                ...state,
                stopwatch: action.payload,
            };
            break;

        case 'SET_TIMER_SETTINGS':
            return {
                ...state,
                settings: action.payload,
            };
            break;

        case 'TOGGLE_TIMER_MODAL':
            return {
                ...state,
                timerModal: action.payload,
            };
            break;

        default:
            return state;
    };
};

export default timerReducer;
