import React, {useState, useEffect} from 'react';
import { Button, Modal } from 'rlabui';
import { UNIT_DETAIL_LIST } from './unitDetailList';

const UnitDetailInfo = ({ showUnitInfo, setShowUnitInfo, unitInfo, setUnitInfo }) => {
	const [unit, setUnit] = useState(undefined);
	const [quantity, setQuantity] = useState(undefined);
	const [unitData, setUnitData] = useState(undefined);

    useEffect(() => {
        if (!unitInfo) return;
        setUnit(unitInfo.unit);
        setQuantity(unitInfo.quantity);
        const data = UNIT_DETAIL_LIST.find(item => item.quantity.toLowerCase() === unitInfo.quantity.toLowerCase() && 
            item.unit === unitInfo.unit);
        setUnitData(data);
	}, [unitInfo, showUnitInfo]);

    const doSetShowUnitInfo = () => {
        setUnitInfo(undefined);
        setShowUnitInfo(false);
    };

    return (
        <Modal
            visible={showUnitInfo}
            size="sm"
        >
            <Modal.Head modalClose={doSetShowUnitInfo} />
            <Modal.Body>
                <ul className="cor-net__list">
                    <li>
                        <span>Eдиница измерения:</span>
                        <b>{unitData ? unitData.quantity : quantity}</b>
                    </li>
                    <li>
                        <span>Обозначение:</span>
                        <b>{unitData ? unitData.unit : unit}</b>
                    </li>
                    <li>
                        <span>Система измерения:</span>
                        <b>{unitData ? unitData.system : '-'}</b>
                    </li>
                    <li>
                        <span>Отношение к другим единицам измерения:</span>
                        <b>{unitData?.rel ? unitData.rel : 'нет'}</b>
                    </li>
                    <li>
                        <span>Описание:</span>
                        <b>{unitData?.desc ? unitData.desc : 'нет'}</b>
                    </li>
                </ul>
            </Modal.Body>
            <Modal.Footer align="right">
                <Button onClick={doSetShowUnitInfo} border={true}>Закрыть</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UnitDetailInfo;
