import {getLSInfo, hasLSInfo, setLSInfo, removeLSInfo} from "../ui/utils/ServerInfo";

//Notebook Local Storage
const NB_STORAGE = 'NB_STORAGE';
export const getNbStorage = () => getLSInfo(NB_STORAGE);
export const hasNbStorage = () => hasLSInfo(NB_STORAGE);
export const setNbStorage = (info) => {
    setLSInfo(NB_STORAGE, info);
};
export const removeNbStorage = () => {
    removeLSInfo(NB_STORAGE);
};
