import React, {useState} from "react";
import socket from "../../socket";
import { toast } from "react-toastify";
import {authenticate} from "../../helpers/auth";
import {useDispatch} from "react-redux";
import {reloadClass, reloadOrg, reloadUser} from "../../redux/slices/storage";
import {resetLists} from "../../redux/slices/lists";
import {authApi} from "../../api/api";
import "./scss/style.scss"
import { Button, Icon, Tabs } from "../ui";
import { Checkbox, Modal } from 'rlabui';

import {FEATURES} from "../../api/config";
import ThemeSwitcher from '../template/ThemeSwitcher';
import FontResize from '../template/FontResize';
import logo from './img/logo.png';
import footerLogo from './img/footerLogo.png';
import bg from './img/bg.webp';
import tab_0 from './img/tab_1.webp';
import tab_1 from './img/tab_2.webp';
import tab_2 from './img/tab_3.webp';
import tab_3 from './img/tab_4.webp';
import tab_4 from './img/tab_5.webp';


const Login = ({ history }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    login: "",
    password: "",
  });
  const {login, password} = formData;
  const handleChange = (text) => (e) => {
    setFormData({...formData, [text]: e.target.value});
  };

  const modules = [
    {id: 0, name: "Цифровая лабораторная среда"},
    {id: 1, name: "Образовательная платформа"},
    {id: 2, name: "База знаний"},
    {id: 3, name: "Модуль коммуникаций"},
    {id: 4, name: "Модуль администрирования"},
  ];

  const [tabId, setTabId] = useState(0);
  const [check, setCheck] = useState(true);
  const [modal, setModal] = useState(false);

  const renderTab = () => {
    switch (tabId) {
      case 0:
        return (<>
         <div className="corModules__img">
            <img src={tab_0} alt="Цифровая лабораторная среда" />
          </div>
          <div className="corModules__title">Цифровая лабораторная среда</div>
          <div className="corModules__content">
            <p>для осуществления практических исследований по различным предметам. Позволяющая сохранять результаты исследований, анализировать их, делиться результатами с другими образовательными учреждениями, организовывать исследовательские коллаборации и управлять совместными исследованиями в группе образовательных учреждений.</p>
          </div>
        </>)

      case 1:
        return (<>
         <div className="corModules__img">
            <img src={tab_1} alt="Образовательная платформа" />
          </div>
          <div className="corModules__title">Образовательная платформа</div>
          <div className="corModules__content">
            <p>Образовательная платформа с широким набором инструментов для сопровождения учебного процесса.</p>
            <ul>
              <li>Редактор образовательного контента</li>
              <li>Система рейтингов и оценки результатов обучающихся в онлайн режиме</li>
              <li>Редактор тестов</li>
              <li>Обучающие игры, набор технологий для сопровождения дистанционного образовательного процесса.</li>
              <li>Система виртуальных расчетных моделей по различным темам обучения</li>
            </ul>
          </div>
        </>)
      case 2:
        return (<>
         <div className="corModules__img">
            <img src={tab_2} alt="База знаний" />
          </div>
          <div className="corModules__title">База знаний</div>
          <div className="corModules__content">
            <p>Постоянно пополняемая База знаний, методических пособий и контента, сопровождающего образовательный процесс.</p>
          </div>
        </>)
      case 3:
        return (<>
         <div className="corModules__img">
            <img src={tab_3} alt="Модуль коммуникаций" />
          </div>
          <div className="corModules__title">Модуль коммуникаций</div>
          <div className="corModules__content">
            <p>Встроенные чаты, сервис видеоконференций с функциями демонстрации экрана и трансляциями измерений в онлайн режиме.</p>
          </div>
        </>)
      case 4:
        return (<>
         <div className="corModules__img">
            <img src={tab_4} alt="Модуль администрирования" />
          </div>
          <div className="corModules__title">Модуль администрирования</div>
          <div className="corModules__content">
            <p>Позволяет формировать структуру образовательного учреждения: учебные программы, классы и группы обучения, профили преподавателей и обучающихся, создавать расписания и образовательные маршруты.</p>
          </div>
        </>)
        
      default:
        break;
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (login && password) {
      authApi.login(formData)
      .then((res) => {
        authenticate(res, () => {
          setFormData({
            ...formData,
            login: "",
            password: "",
          });

          dispatch(reloadUser());
          dispatch(reloadOrg());
          dispatch(reloadClass());
          dispatch(resetLists());
          toast.dismiss();
          socket.emit("BE-new-login", res.user._id);
          history.push("/");
        });
      })
      .catch((err) => {
        setFormData({
          ...formData,
          login: "",
          password: "",
        });
        toast.error(err.response.data.errors);
      });
    } else {
      toast.error("Заполните все поля");
    }
  };

  const navData = [
    {title: 'Цифровая лаборатория', icon: 'integral', onClick: () => {window.location.href = "/app_rlab/"} },
    {title: 'Расчетные модели', icon: 'calculator', onClick: ()=> history.push(FEATURES.cm.to) },
    {title: 'Нейро лаборатория', icon: 'union', onClick: () => {window.location.href = "/app_neuro/"} },
  ]

  const navItems = navData.map(({title, icon, onClick }, index) => {
    return (
      <div className="firstScreen__nav_item" onClick={onClick} key={"navItem" + index}>
        <div className="firstScreen__nav_top">
          <Icon name={icon} />
        </div>
        <div className="firstScreen__nav_bottom">
          <span>{title}</span>
          <p><Icon name="caret" /></p>
        </div>
      </div>
    )
  })

  return (
      <>
        <div className="login-wrapper">
          <header className="login-header">
            <div className="login-header__content">
              <div className="login-header__left">
                <div className="login-header__logo">
                  <img src={logo} alt="logo" />
                </div>
              </div>
              <div className="login-header__center">
                <a href="tel:88001017447" className="login-header__phone">
                  <Icon name="phone" />
                  <span>8 (800) 101 74 47</span>
                </a>
              </div>
              <div className="login-header__right">
                <ThemeSwitcher/>
                <FontResize/>
                {/* <a href="#" className="login-header__link">
                  <Icon name="log-out" />
                  <span>Вход</span>
                </a> */}
              </div>

            </div>
          </header>

          <main className="login-main">

            <section className="section firstScreen">

              <div className="firstScreen__bg">
                <img src={bg} alt="" />
              </div>

              <div className="container_center">
                <div className="firstScreen__wrap">
                  <div className="firstScreen__content">
                    <h1 className="firstScreen__title">Инновационный<br></br> информационный сервис COR</h1>
                    <div className="firstScreen__descriptions">Интегрирует в себе обширную базу знаний и методических
                      пособий, инструменты для осуществления практической учебной деятельности, научную среду для
                      совместных исследований и коллабораций, цифровую учебную платформу, сопровождающую учебный процесс
                      как в очной, так и в дистанционной формах обучения.
                    </div>

                    <div className="firstScreen__nav">
                      {navItems}
                    </div>
                    
                  </div>
                  <div className="firstScreen__form">
                      <form className="login-form" onSubmit={handleSubmit}>
                        <div className="login-title">Личный кабинет</div>
                        <div className="login-form__row">
                          <input
                            type="login"
                            placeholder="Ваш логин"
                            onChange={handleChange("login")}
                            value={login}
                            autoComplete="on"
                          />
                        </div>

                        <div className="login-form__row">
                          <input
                            type="password"
                            placeholder="Ваш пароль"
                            onChange={handleChange("password")}
                            value={password}
                            autoComplete="on"
                          />
                        </div>

                        <div className="login-form__row">
                          <Button type="submit" color="primary" disabled={!check}>Войти</Button>
                        </div>
                        <div className="login-form__row login-form__row_full">
                          <div className="login-form__privacy">
                            <Checkbox
                              className="login-form__privacy"
                              checked={check}
                              onChange={() => setCheck(!check)}
                            /> 
                            <p>Прочитал(-а) Пользовательское соглашение и соглашаюсь с <span onClick={() => setModal(true)}>политикой обработки персональных данных платформы «COR.ROBICLAB</span></p>
                          </div>
                        </div>
                      </form>
                  </div>
                </div>
              </div>
            </section>

            <section className="section corModules">
              <div className="container_center">
                <h2 className="section__title">Основные функциональные модули информационного сервиса COR.ROBICLAB:</h2>
                <div className="corModules__wrap">
                  <div className="corModules__left">
                    <Tabs className="corModules__tabs">
                      {modules.map((item, i) => (
                            <Tabs.Item key={i} active={item.id === tabId} onClick={() => setTabId(item.id)}>
                                <span>{item.name}</span>
                            </Tabs.Item>))}
                    </Tabs>
                    <div className="corModules__label">Информационный сервис COR.ROBICLAB позволяет формировать высокоэффективный образовательный процесс, с высокой мотиваций учащихся и максимальной реализацией творческого потенциала преподавателей – в любом формате обучения от очного до дистанционного.</div>
                  </div>

                  <div className="corModules__right">
                   {renderTab()}
                  </div>

                </div>

              </div>
            </section>

            <section className="section instructions" >
              <div className="container_center">
                <h2 className="section__title">Инструкции по работе с информационным сервисом COR.ROBICLAB</h2>
                <div className="instructions__list">
                  <a href="/подключение_к_информационному_сервису_cor_robiclab.docx" className="instructions__item">
                    <Icon name="task"/>
                    <span>Подключение к Информационному сервису COR.ROBICLAB.docx</span>
                  </a>
                  <a href="/информация_о_поддержании_жизненого_цикла_информационного_сервиса_cor_robiclab.docx" className="instructions__item">
                    <Icon name="task"/>
                    <span>Описание жизненного цикла, поддержки и обслуживания информационного сервиса.docx</span>
                  </a>
                  <a href="/руководство_пользователя_информационного_сервиса_cor_robiclab.docx" className="instructions__item">
                    <Icon name="task"/>
                    <span>Руководство пользователя Информационного сервиса COR.ROBICLAB.docx</span>
                  </a>
                  <a href="/описание_функциональных_характеристик_экземпляра_программного_обеспечения.docx" className="instructions__item">
                    <Icon name="task"/>
                    <span>Описание функциональных характеристик экземпляра программного обеспечения.docx</span>
                  </a>
                  <a href="/cor_политика_обработки_пс.docx" className="instructions__item">
                    <Icon name="task"/>
                    <span>Политика обработки персональных данных пользователей платформы COR.ROBICLAB.docx</span>
                  </a>
                </div>
              </div>
            </section>
          </main>

          <footer className="login-footer">
            <div className="login-footer__top">
              <div className="login-footer__row">
                <div className="login-footer__logo">
                  <img src={footerLogo} alt="logo" />
                </div>
                <div className="login-footer__info">
                  <span className="login-footer__label">Служба технической поддержки:</span>
                  <div className="login-footer__list">
                    <span className="login-footer__item">
                      <Icon name="calendar"/> 
                      <span>пн-пт с 10:00 – 18:00</span>
                    </span>
                    <a href="tel:88001017447" className="login-footer__item">
                      <Icon name="phone"/>
                      <span>8 (800) 101 74 47</span>
                    </a>
                    <a href="mailto:info@robiclab.ru" className="login-footer__item">
                      <Icon name="email"/>
                      <span>info@robiclab.ru</span>
                    </a>
                  </div>

                </div>
              </div>
            </div>

            <div className="login-footer__main">
              <div className="login-footer__row">
                <span>По вопросам приобретения и стоимости программного продукта, а так же доступа к информационному сервису просим обращаться<br></br> по телефону: <a href="tel:88001017447">8 (800) 101 74 47</a> или по электронной почте <a href="mailto:info@robiclab.ru">info@robiclab.ru</a>.</span>
              </div>
              
            </div>

            <div className="login-footer__bottom">
              <div className="login-footer__row">
                <a href="#">Политика конфиденциальности</a>
                <span>© ООО "Робиклаб" 2011 - 2024</span>
                <span>Все права защищены</span>
              </div>
            </div>
          </footer>

          <Modal visible={modal} size="lg">
              <Modal.Head modalClose={() => setModal(false)} title="Пользовательское соглашение"/>
              <Modal.Body className="personalData scrolledBox">
                <>
                  <h1 style={{textAlign: "center"}}>СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ пользователей Платформы «COR.ROBICLAB»</h1>
                  <p>Настоящим, в соответствии с Федеральным законом от 27 июля 2006 г. N 152-ФЗ «О персональных данных» (далее - Закон о персональных данных) и ознакомившись с условиями Политики обработки персональных данных пользователей платформы «COR.ROBICLAB», постоянно размещенного по адресу URL: https://cor.robiclab.ru/ и условиями Лицензионного (пользовательского) соглашения, постоянно размещенного адресу URL: https://cor.robiclab.ru/, <b>даю конкретное, предметное, информированное, сознательное и однозначное согласие на обработку своих персональных данных Обществу с ограниченной ответственностью «РОБИКЛАБ»</b> (ОГРН: 1177847234817, ИНН: 7810697745), расположенному по адресу: 196006, город Санкт-Петербург, Парковая ул., д. 3 литер А, помещ. 70, (далее – <b>«Оператор»</b>) <b>в целях и на условиях, изложенных в Политике обработки персональных данных «COR.ROBICLAB» и Лицензионном (пользовательском) соглашении «COR.ROBICLAB».</b></p>
                  <p><b>1.	Сведения об Операторе:</b></p>
                  <p>Общество с ограниченной ответственностью «РОБИКЛАБ», адрес места нахождения: 196006, город Санкт-Петербург, Парковая ул., д. 3 литер а, помещ. 70 ИНН: 7810697745; ОГРН: 1177847234817</p>
                  <p><b>2.	Сведения об информационных ресурсах Оператора:</b></p>
                  <p>Платформа – это образовательный информационный сервис, зарегистрированный в качестве программы для ЭВМ под наименованием «COR.ROBICLAB», предназначенный для сопровождения образовательного процесса в учреждениях начального, среднего, среднего специального, высшего, а также дополнительного образования. Интегрирует в себе обширную базу знаний и методических пособий, инструменты для осуществления практической учебной деятельности, научную среду для совместных исследований и коллабораций, цифровую учебную платформу, сопровождающую учебный процесс как в очной, так и в дистанционной формах обучения.</p>
                  <p>Доступ к Платформе осуществляется удаленно с использованием сети «Интернет» по адресу (URL): «https://cor.robiclab.ru/»</p>
                  <p><b>3.	Цели обработки персональных данных</b></p>
                  <p>3.1. 	Обеспечение доступа к функционалу Платформы и его использования, что включает в себя следующее:</p>
                  <p><b>-Регистрация и авторизация учетной записи на Платформе:</b> Обработка данных, необходимых для регистрации учетной записи и авторизации Пользователей на Платформе, а также для обеспечения непрерывного и безопасного доступа к функционалу Платформы.</p>
                  <p><b>-Управление учетными записями и настройками:</b> Сохранение и управление пользовательскими настройками и предпочтениями для предоставления персонализированного контента и улучшения взаимодействия с Платформой.</p>
                  <p><b>-Коммуникация с пользователями:</b> Отправка уведомлений, сообщений, новостей и иной информации, связанной с использованием Платформы, а также ответы на запросы пользователей через службу поддержки.</p>
                  <p><b>-Анализ и улучшение Платформы:</b> Сбор и анализ данных о взаимодействии пользователей с Платформой для улучшения качества предоставляемых услуг, разработки новых функций и оптимизации пользовательского опыта.</p>
                  <p><b>-Исполнение правовых и нормативных требований:</b> Обработка данных для выполнения требований действующего законодательства РФ, включая соблюдение налоговых, бухгалтерских и других нормативных обязательств.</p>
                  <p><b>-Обеспечение безопасности:</b> Обработка данных для предотвращения мошенничества, защиты пользователей и Платформы от несанкционированного доступа и других неправомерных действий.</p>
                  <p><b>4.	Состав обрабатываемых персональных данных:</b></p>
                  <p><b>Пользователь (Субъект персональных данных) дает Оператору согласие на обработку свободно, своей волей и в своем интересе следующих персональных данных </b></p>
                  <p>-фамилия, имя и отчество (при наличии);</p>
                  <p>-пароль от учетной записи Платформы;</p>
                  <p>-логин от учетной записи Платформы;</p>
                  <p>-адрес электронной почты;</p>
                  <p>-контактный номер телефона;</p>
                  <p>-принадлежность к образовательной организации (учреждению), класс (курс);</p>
                  <p>-данные, которые автоматически передаются сервисам Платформы в процессе их использования с помощью установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, данные cookie-файлов, информация о программе, с помощью которой осуществляется доступ к Платформе, технические характеристики оборудования и программного обеспечения, используемых Пользователем, дата и время доступа к Платформе, геопозиция и иная подобная информация. </p>
                  <p><b>5.	Условия обработки персональных данных:</b></p>
                  <p>5.1.  	Обработка персональных данных Оператором осуществляется автоматизированным способом обработки персональных данных с передачей полученной информации по информационно-телекоммуникационным сетям или без таковой.</p>
              
                  <p><b>6.	Права и обязанности Пользователя</b></p>
                  <p>Пользователь имеет право:</p>
                  <p>6.1 	Получать информацию о своих персональных данных, обрабатываемых Оператором;</p>
                  <p>6.2. 	Требовать исправления, блокирования или уничтожения своих персональных данных, если они являются неточными, незаконно полученными или более не необходимыми для заявленных целей обработки;</p>
                  <p>6.3.  	Отозвать свое согласие на обработку персональных данных в любое время;</p>
                  <p>6.4.	Возражать против обработки персональных данных в случаях, предусмотренных законодательством;</p>
                  <p>6.5. 	Обращаться в компетентные органы для защиты своих прав.</p>
                  <p>Пользователь обязан:</p>
                  <p>6.6	Пользователь обязан предоставлять Оператору только достоверные и актуальные персональные данные и своевременно обновлять их в случае изменений.</p>
                  <p>6.7.	Пользователь обязан соблюдать условия использования Платформы, включая требования, касающиеся безопасности учетной записи и конфиденциальности данных.</p>
                  <p>6.8.	Пользователь обязан обеспечивать конфиденциальность своих учетных данных (логина и пароля) и не передавать их третьим лицам. В случае утраты или подозрения на несанкционированное использование учетных данных, пользователь обязан немедленно уведомить об этом Оператора.</p>
                  <p>6.9.	Пользователь обязан соблюдать действующее законодательство при использовании Платформы и предоставлении персональных данных.</p>
                  <p>6.10	Пользователь обязан не предоставлять Оператору персональные данные третьих лиц без их согласия, за исключением случаев, предусмотренных законодательством.</p>
                  <p><b>7.	Срок действия согласия:</b></p>
                  <p>7.1.	Настоящее согласие действует до достижения целей обработки персональных данных или до его отзыва субъектом персональных данных.</p>
                  <p><b>Я уведомлен(а), что имею право отозвать настоящее согласие в любое время, направив письменное уведомление по адресу оператора: 196006, город Санкт-Петербург, Парковая ул., д. 3 литер а, помещ. 70, либо по электронной почте:  cor@robiclab.ru. </b></p>
                  <p><b>Отзыв согласия не влияет на законность обработки данных, осуществленной до момента отзыва.</b></p>
                  <p><b>8.	Контактная информация Оператора</b></p>
                  <p>ИНН: 7810697745 КПП: 781001001 </p>
                  <p>Р/с:   40702810555160000251 </p>
                  <p>Банк: 044030653 СЕВЕРО-ЗАПАДНЫЙ БАНК ПАО СБЕРБАНК </p>
                  <p>БИК: 044030653 </p>
                  <p>К/с: 30101810500000000653 </p>
                  <p><b>Юридический адрес:</b> 196006, Санкт-Петербург г, Парковая ул, дом 3, литер А, помещение 70 </p>
                  <p><b>Телефон:</b> +7 (800)-101-74-47 </p>
                  <p><b>Эл. почта:</b> cor@robiclab.ru</p>
                </>

              </Modal.Body>

              <Modal.Footer align="left">
                <Button color="primary" onClick={() => setModal(false)}>Я уведомлен(а)</Button>
              </Modal.Footer>
          </Modal>

  
        </div>
      </>
  );
};

export default Login;
