import React, { useEffect, useState, memo, useCallback, useRef } from 'react';
import { GraduateChart } from './';
import Icon from '../Icon';
import { Modal, Dropdown, Button, InputNumber, Input } from 'rlabui';
import { ProgressBar } from 'react-loader-spinner';
import './style.scss';

const PIN_CODE = '1234';
let tgvCount = 0;
let rgtCount = 0;
let sendTimeout = null;

const Graduation = (props) => {
    const { port, modal, sensorsList, command, sendCommand, setGraduationModal, deviceResponse, setDeviceResponse, setGraduate, graduate, writer, getCurrentValue, isOscilloscope } = props;
    const [list, setList] = useState([]);
    const [currentUnit, setCurrentUnit] = useState('');
    const [sensorsIndex, setSensorsIndex] = useState(0);
    const [sensorNum, setSensorNum] = useState();
    const [sensorName, setSensorName] = useState(0);
    const [tgv, setTgv] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [readeSens, setReadeSens] = useState(false);
    const [graduateData, setGraduateData] = useState([]);
    const [graduateSelected, setGraduateSelected] = useState(null);
    const [graduateDataSaved, setGraduateDataSaved] = useState([[], [], [], [], []]);
    const [graduateDataPresaved, setGraduateDataPresaved] = useState([]);
    const [currentRow, setCurrentRow] = useState();
    const [message, setMessage] = useState();
    const [showPIN, setShowPIN] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [wgtString, setWgtString] = useState('');
    const [writerSelected, setWriterSelected] = useState(Object.keys(writer)[0]);
    const [currentValue, setCurrentValue] = useState('');
    const [autocomplete, setAutocomplete] = useState(false);
    const [resetModal, toggleResetModal] = useState(false);
    const [taMod, setTaMod] = useState(null);
    const [isSingle, setSingle] = useState(false);
    const [pinValue, setPinValue] = useState('');
    const taInterval = useRef();

    useEffect(() => {
        !port && setTaMod(null);
        rgtCount = 0;
    }, [port]);

    useEffect(() => {
        if (sensorsList?.length && sensorsList[sensorsIndex]) {
            const { inf, single, unicName, deviceKey } = sensorsList[sensorsIndex];
            setTaMod(inf ? 'ta ' : '');
            setSingle(single || false);
            tgvCount = 0;
            setWriterSelected(deviceKey);
        }
    }, [sensorsIndex, sensorsList, setSingle, setTaMod]);

    // useEffect(() => {
    //     console.log('command', command);
    // }, [command]);

    useEffect(() => {
        if (!writer) {
            setWriterSelected(Object.keys(writer)[0]);
        }
    }, [writer.length]);

    useEffect(() => {
        // console.log('writerSelected', writerSelected);

        if (list?.length) {
            // console.log('modal', modal !== false);
            // console.log('tgv', tgv);
            // console.log('taMod', taMod);
            // console.log('sensorNum', typeof sensorNum === 'number');
            if (modal !== false && writerSelected && typeof sensorNum === 'number' && taMod !== null && tgv) {
                sendGraduableTableResponse();
            }
        }
        // eslint-disable-next-line
    }, [writerSelected, sendCommand, tgv, modal, list, taMod, sensorNum]);

    useEffect(() => {
        if (!port) return;

        if (modal === false) {
            // console.log('modal close');
            !isOscilloscope && tgv && sendCommand('tgv 1');
            setTgv(false);
            setGraduate(false);
            setGraduateDataSaved([[], [], [], [], []]);
            setGraduateDataPresaved([]);
            setList([]);
            setRowCount(0);
            setShowLoader(false);
            // clearInterval(taInterval.current);
        } else {
            // console.log('modal open');
            setTgv(true);
            getSensorsList();
            setGraduateData([]);
            if (modal === true) {
                const { sensNum, deviceKey, unit } = sensorsList?.length && sensorsList[0];
                setWriterSelected(deviceKey);
                setCurrentUnit(unit);
                setSensorNum(sensNum);
                setSensorsIndex([0]);
            } else {
                const sensInd = sensorsList.findIndex((item) => item.num === modal);
                if (sensInd !== -1) {
                    setSensorsIndex(sensInd);
                }
                setSensorNum(modal);
            }
            !tgv && sendCommand('tgv 0');
            sendCommand('inf');
        }
        // eslint-disable-next-line
    }, [modal, sensorsList]);

    useEffect(() => {
        if (typeof sensorsIndex === 'number' && sensorsList[sensorsIndex]) {
            // console.log('Sensor change');
            const { sensNum, deviceKey, name, unit } = sensorsList[sensorsIndex];
            setRowCount(0);
            setGraduateData([]);
            setGraduateSelected(0);
            setGraduateDataSaved([[], [], [], [], []]);
            setGraduateDataPresaved([]);
            setSensorNum(sensNum);
            setSensorName(name);
            setCurrentUnit(unit);
            sendGraduableTableResponse();
            setWriterSelected(deviceKey);
        }
    }, [sensorsIndex, isSingle, sendCommand, sensorsList, sensorNum]);

    useEffect(() => {
        // console.log('rowCount', rowCount);
        // console.log('graduateData', graduateData);
        if (rowCount) {
            let rows = [];
            for (var i = 0; i < rowCount; i++) {
                if (!graduateData[i]) {
                    rows[i] = { old: '', new: '' };
                } else {
                    rows[i] = graduateData[i];
                }
            }
            setGraduateData(rows);
        } else {
            graduateData.length && setGraduateData([]);
            setCurrentRow();
        }
        // eslint-disable-next-line
    }, [rowCount, sensorsIndex]);

    useEffect(() => {
        // console.log('graduateDataSaved', graduateDataSaved);
        if (graduateSelected >= 0 && graduateDataSaved[graduateSelected]?.length) {
            const num = typeof sensorNum === 'number' ? sensorNum : sensorsList[0].sensNum;
            const resp = [`rgt`, `ch${num}`, ...graduateDataSaved[graduateSelected]];
            setRowCount(0);
            setGraduateData([]);
            updateGraduateData(resp);
            setGraduateDataPresaved([]);
        }
    }, [graduateSelected, graduateDataSaved, sensorNum, sensorsList]);

    useEffect(() => {
        if (command.length) {
            if (command.includes('rgt')) {
                setShowLoader(true);
            }
            sendCommand(command);
        }
    }, [command, sendCommand]);

    useEffect(() => {
        if (autocomplete) {
            getCurrentValue(sensorsIndex).then((e) => {
                setCurrentValue(e);
                autocompleteData(e);
            });
            setAutocomplete(false);
        }
        // eslint-disable-next-line
    }, [autocomplete, getCurrentValue, sensorsIndex]);

    useEffect(() => {
        if (taMod === 'ta ' && graduateData.length) {
            const saved = graduateDataSaved[graduateSelected] || [];
            const current = [];
            graduateData?.forEach((e) => {
                current.push(e.old);
                current.push(e.new);
            });
            if (current?.join() !== saved?.join()) {
                // console.log(current);
                setGraduateDataPresaved(current);
            }
        }
    }, [graduateData]);

    useEffect(() => {
        const responses = ['rgt', 'cgt', 'tgv', 'wgt'];
        const currentResp = responses.find((e) => deviceResponse.includes(e));
        // console.log('response', deviceResponse);
        // console.log('currentResp', currentResp);
        if (currentResp) {
            const str = deviceResponse.replace(/\r\n.*/, '');
            const resp = str.split(' ').filter((i) => i);
            const chName = sensorsList[sensorsIndex]?.name;
            // console.log('currentResp', str);

            switch (currentResp) {
                case 'tgv':
                    if (modal === false) return;
                    // console.log('tgv str', str, taMod);
                    if (str.includes('tgv error 0')) {
                        // состояние записи / запись измерений
                        // sendGraduableTableResponse();
                        setTgv(true);
                    }

                    if (str.includes('tgv error -1')) {
                        // console.log(str);
                        // sendGraduableTableResponse();
                        if (tgvCount < 5) {
                            let txt = 'tgv ';
                            if (modal !== false) {
                                txt += '0  key' + writerSelected;
                            } else {
                                txt += '1 key' + writerSelected;
                            }
                            setTgv(false);
                            !isOscilloscope && sendCommand(txt);
                            // console.log(tgvCount);

                            tgvCount++;
                        }
                        // setMessage(`Не удалось включить вывод неградуированных значений. Закройте окно и повторите попытку или переподключите устройство`);
                        // ошибка записи измерений
                    }
                    break;
                case 'rgt':
                    if (modal === false) return;
                    rowCount && setRowCount(0);

                    if (str === 'rgt error -1 ') {
                        // ошибка чтения
                        // Отпрапвить повторно
                        // console.log(rgtCount);

                        if (rgtCount < 2) {
                            setTaMod('');
                            sendGraduableTableResponse();
                            rgtCount++;
                            // } else {
                            //     showLoader && setShowLoader(false);
                        }
                    }

                    if (resp.length >= 2) {
                        if (str.includes(`rgt ch${sensorNum} error -1`)) {
                            if (rgtCount < 2) {
                                setTaMod('');
                                sendGraduableTableResponse();
                                rgtCount++;
                                // } else {
                                //     showLoader && setShowLoader(false);
                            }
                            // showLoader && setShowLoader(false);
                            // return;
                        }
                        if (resp[2]?.charAt(0) === 't') {
                            // console.log(resp);
                            const ind = +resp[2].replace('t', '');
                            let data = [...resp];
                            if (typeof ind === 'number' && !isNaN(ind) && data.length) {
                                data.splice(0, 3);
                                // console.log('');
                                // console.log(graduateDataSaved);
                                // console.log(data);
                                // let saved = [...graduateDataSaved];
                                // saved[ind] = data;
                                // console.log(saved);
                                // setGraduateDataSaved(saved);
                                setGraduateDataSaved((e) =>
                                    e.map((e, i) => {
                                        if (i === ind) {
                                            return data;
                                        }
                                        if (e) {
                                            return e;
                                        } else {
                                            return [];
                                        }
                                    })
                                );
                            }
                        } else {
                            // console.log('resp', resp);
                            // setTaMod(false);
                            setGraduateSelected(0);
                            updateGraduateData(resp);
                        }
                    }
                    // showLoader && setShowLoader(false);
                    break;
                case 'cgt':
                    if (modal === false) return;
                    if (str.includes(`cgt ch${sensorNum} error 0`)) {
                        // состояние записи / запись измерений
                        // sendGraduableTableResponse();
                        setMessage(`Текущая градуировочная таблица сброшена`);
                        setRowCount(0);
                        setGraduateData([]);
                    }
                    break;
                case 'wgt':
                    // console.log(str);

                    if (modal === false) return;
                    if (str.includes(`wgt ch${sensorNum} error 0`)) {
                        // чтение датчика
                        sendGraduableTableResponse();
                        setMessage(`Градуировочная таблица для датчика ${chName} успешно записана`);
                    }

                    if (str.includes('wgt error -1') || str.includes(`wgt ch${sensorNum} error -1`)) {
                        setMessage(`Ошибка записи градуировочной таблицы для датчика ${chName}`);
                    }
                    break;
                default:
                    break;
            }

            const checkDevice = sensorsList.filter((item) => item.unicName === resp[0]);

            if (checkDevice) {
                // Данные от датчика
                const dataOnly = deviceResponse.replace(resp[0], '');
                const arr = dataOnly.split(' ').filter((n) => n);
                const currens = [];
                let index = 0;
                let ch, val;
                arr.forEach((item, i) => {
                    if (i % 2) {
                        val = item.replace('!', '');
                        if (!currens[index]) {
                            currens[index] = {};
                        }
                        currens[index][ch] = val;
                        if (ch === 'ch' + sensorsList[sensorsIndex]?.sensNum) {
                            if (readeSens) {
                                const readed = [...graduateData];
                                if (!readed[currentRow]) {
                                    readed[currentRow] = { old: '', new: '' };
                                }
                                readed[currentRow].old = val;
                            }
                        }
                        index++;
                    } else {
                        ch = item;
                    }
                });
            }
            graduate && setGraduate(false);
            readeSens && setReadeSens(false);
            // deviceResponse.length && setDeviceResponse('');
            // sendCommand('');
        }
        showLoader && setShowLoader(false);
    }, [
        deviceResponse,
        currentRow,
        graduate,
        graduateData,
        graduateDataSaved,
        isSingle,
        readeSens,
        sendCommand,
        sensorNum,
        sensorName,
        sensorsIndex,
        sensorsList,
        setDeviceResponse,
        setGraduate,
        taMod,
        writerSelected,
        modal,
    ]);

    const sendGraduableTableResponse = () => {
        clearTimeout(sendTimeout);
        setShowLoader(true);
        // console.log('');
        // console.log('rgtCount', rgtCount);
        // console.log('taMod', taMod);
        // console.log('sensorNum', sensorNum);
        sendTimeout = setTimeout(() => {
            if (isSingle) {
                sendCommand(`rgt`);
            } else {
                if (taMod === 'ta ') {
                    if (rgtCount > 1) {
                        sendCommand(`rgt ch${sensorNum} key${writerSelected}`);
                        // rgtCount = 0;
                    } else {
                        sendCommand(`rgt ch${sensorNum} ${taMod || ''}key${writerSelected}`);
                    }
                }
                if (taMod === '') {
                    sendCommand(`rgt ch${sensorNum} key${writerSelected}`);
                }
            }
            setShowLoader(false);
        }, 300);
    };

    const updateGraduateData = (resp) => {
        // console.log(1, resp);
        // console.log(1, sensorNum);
        if (resp.length > 2 && resp[1].includes('ch')) {
            // console.log(2, resp);
            const dataLenght = resp.length - 2;
            const data = resp.slice(-dataLenght);
            const table = [];
            let index = 0;
            data.forEach((item, i) => {
                if (!table[index]) {
                    table[index] = { old: '', new: '' };
                }
                if (i % 2) {
                    table[index].new = '';
                    table[index].new = item;
                    index++;
                } else {
                    table[index].old = '';
                    table[index].old = item;
                }
            });
            setRowCount(+(dataLenght / 2).toFixed());
            setGraduateData(table);
        }
    };

    const getSensorsList = () => {
        // console.log(list);
        const arr = [...list];
        sensorsList?.forEach((item, i) => {
            arr[i] = {
                label: item.name,
                value: item.num,
            };
            if (i === 0) {
                setSensorsIndex(i);
            }
        });
        setList(arr);
    };

    const setNewGarduation = useCallback(() => {
        // console.log(setNewGarduation);
        let str = `wgt ch${sensorsList[sensorsIndex].sensNum} `;
        let clean = false;
        graduateData.forEach((item, i) => {
            if (item.new === '') {
                clean = true;
            } else {
                str += item.old + ' ' + item.new + ' ';
            }
        });

        if (clean) {
            setMessage('Необходимо заполнить все поля созданные для записи');
        } else {
            setShowPIN(true);
            setWgtString(str);
        }
    }, [graduateData, sensorsList, sensorsIndex]);

    const renderSensorsList = () => {
        return (
            <div className="form__row">
                <div className="legend">Датчик</div>
                <div className="graduation__inline">
                    <Dropdown
                        value={list[sensorsIndex]?.label ? list[sensorsIndex].label : 'Выберите канал'}
                        clicked={true}
                    >
                        {list?.map(({ label, value }, i) => (
                            <Dropdown.Item
                                key={'list' + i}
                                onClick={() => {
                                    setSensorNum(value);
                                    setSensorsIndex(i);
                                    setGraduateData([]);
                                }}
                            >
                                {label}
                            </Dropdown.Item>
                        ))}
                    </Dropdown>
                    <Icon
                        name="download"
                        title="Прочитать градуировочную таблицу"
                        onClick={() => {
                            sendCommand('');
                            sendGraduableTableResponse();
                        }}
                    />
                    {/* <Swicher
                        title="Режим чтения нескольких таблиц для одного канала"
                        checked={taMod}
                        onChange={() => setTaMod(!taMod)}
                    /> */}
                </div>
            </div>
        );
    };

    const renderSavedGraduationsList = () => {
        return (
            // modal &&
            graduateDataSaved[0].length > 0 &&
            rowCount > 0 && (
                <div className="form__row">
                    <div className="legend">Градуировочные таблицы</div>
                    <div className="graduation__inline">
                        <Dropdown
                            value={
                                graduateSelected >= 0 && graduateDataSaved[graduateSelected]
                                    ? // ? `Градуировочная таблица ${graduateSelected + 1}`
                                      `Таблица №${graduateSelected + 1} (${graduateDataSaved[graduateSelected].join(', ')})`
                                    : 'Выберите сохраненную калибровку'
                            }
                        >
                            {graduateDataSaved?.map((item, i) => {
                                const label = `Таблица №${i + 1}`;
                                if (item?.length) {
                                    return (
                                        <Dropdown.Item
                                            key={i + graduateSelected}
                                            onClick={() => {
                                                setGraduateSelected(i);
                                            }}
                                        >
                                            {label} ({item.join(', ')})
                                        </Dropdown.Item>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </Dropdown>
                        <span>
                            {graduateDataSaved.length}
                            <small>/5</small>
                        </span>
                    </div>
                </div>
            )
        );
    };

    const setNewValue = (e) => {
        const { value } = e.target;
        // console.log(value);

        const index = getRowIndex(e.target.closest('.graduation__row'));

        setGraduateData((data) => {
            if (data[index]) {
                data[index].new = value;
            }
            return [...data];
        });
    };

    const setOldValue = (e) => {
        const { value } = e.target;

        const index = getRowIndex(e.target.closest('.graduation__row'));

        setGraduateData((data) => {
            if (data[index]) {
                data[index].old = value;
            }
            return [...data];
        });
    };

    function getRowIndex(el) {
        let children = el.parentNode.childNodes,
            i = 0;

        for (; i < children.length; i++) {
            if (children[i] === el) {
                // console.log(i);
                return i - 1;
            }
        }
        return -1;
    }

    const autocompleteData = (val) => {
        setCurrentRow(+currentRow);
        setGraduateData((data) => {
            data[currentRow].old = val;
            data[currentRow].new = '';
            return [...data];
        });
    };

    // const deleteRow = (index) => {
    //     const arr = [...graduateData].filter((e, i) => i !== index);
    //     setGraduateData((e) => e.filter((e, i) => i !== index));
    // };

    const checkPin = () => {
        if (pinValue + '' === PIN_CODE) {
            setShowPIN(false);
            if (isSingle) {
                sendCommand(wgtString);
            } else {
                sendCommand(wgtString + ` key${writerSelected}`);
            }
            setGraduateSelected(0);
        } else {
            setMessage('PIN-код введен не правильно');
        }
    };

    const resetGraduationTable = () => {
        sendCommand(`cgt key${writerSelected}`);
        setRowCount(0);
        setGraduateSelected([]);
        setTimeout(() => {
            toggleResetModal(false);
        }, 100);
    };

    return (
        <>
            <Modal
                visible={modal || typeof modal === 'number'}
                className="graduation__modal"
                size={'sm'}
            >
                <Modal.Head
                    modalClose={() => setGraduationModal(false)}
                    title="Настройка градуировки каналов"
                ></Modal.Head>
                <Modal.Body>
                    {renderSensorsList()}
                    {renderSavedGraduationsList()}
                    <GraduateChart props={{ saved: graduateDataSaved[graduateSelected], unit: currentUnit, presaved: graduateDataPresaved, name: sensorName, num: sensorNum }} />
                    <div className="graduation">
                        <div className="graduation__rezult">
                            {graduateData.length && rowCount > 0 ? (
                                <div className="graduation__row graduation__row_head">
                                    <span>
                                        <span className="graduation__label">Номер</span>
                                        {/* <span className="graduation__sort">
                                                        <Icon
                                                            className={'up'}
                                                            name={'arrow-sort'}
                                                        />
                                                        <Icon
                                                            className={'down'}
                                                            name={'arrow-sort'}
                                                        />
                                                    </span> */}
                                    </span>
                                    <span>
                                        <span className="graduation__label">Значение датчика</span>
                                    </span>
                                    <span>
                                        <span className="graduation__label">Точное значение</span>
                                    </span>
                                    <span>
                                        <span className="graduation__label">Действия</span>
                                    </span>
                                </div>
                            ) : (
                                ''
                            )}
                            {showLoader && (
                                <ProgressBar
                                    height="40"
                                    width="100%"
                                    ariaLabel="progress-bar-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="progress-bar-wrapper"
                                    borderColor="#3FA9F5"
                                    barColor="#3FA9F5"
                                />
                            )}
                            {graduateData?.map((item, i) => {
                                return (
                                    <div
                                        key={'row' + i + graduateSelected}
                                        className="graduation__row"
                                    >
                                        <span>
                                            <span className="graduation__num">{i + 1}</span>
                                        </span>
                                        <span>
                                            <InputNumber
                                                className="field"
                                                key={'old' + i + graduateSelected}
                                                value={item.old ? +item.old : ''}
                                                data-index={i}
                                                onChange={setOldValue}
                                                autoComplete="none"
                                            />
                                        </span>
                                        <span>
                                            <InputNumber
                                                className="field"
                                                key={'new' + i + graduateSelected}
                                                value={item.new ? +item.new : ''}
                                                data-index={i}
                                                onChange={setNewValue}
                                                autoComplete="none"
                                            />
                                        </span>
                                        <span className="graduation__actions">
                                            <Button
                                                size="small"
                                                border="true"
                                                onClick={() => {
                                                    setCurrentRow(i);
                                                    setAutocomplete(true);
                                                }}
                                            >
                                                Авто
                                            </Button>
                                            <Icon
                                                name="trash"
                                                onClick={() => {
                                                    setRowCount((e) => e - 1);
                                                    setGraduateData((e) => e.filter((e, ind) => ind !== i));
                                                }}
                                            />
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => {
                            setRowCount((e) => (e !== 5 ? e + 1 : e));
                        }}
                    >
                        <Icon name="plus" /> Добавить
                    </Button>
                    <Button
                        border={true}
                        onClick={() => {
                            setNewGarduation();
                        }}
                    >
                        Применить
                    </Button>
                    <Button
                        link={true}
                        color="danger"
                        onClick={() => toggleResetModal(true)}
                    >
                        Сброс
                    </Button>
                </Modal.Footer>
            </Modal>

            {showPIN && (
                <Modal
                    visible={showPIN}
                    size={'xs'}
                >
                    <Modal.Head
                        modalClose={() => setShowPIN(false)}
                        title="Введите PIN-код"
                    ></Modal.Head>
                    <Modal.Body className="pin">
                        <Input
                            type="password"
                            value={pinValue}
                            className="pin__field"
                            autoComplete="false"
                            onInput={({ target: { value } }) => setPinValue(value)}
                        />
                        <Button onClick={() => checkPin()}>Применить</Button>
                    </Modal.Body>
                </Modal>
            )}

            {resetModal && (
                <Modal
                    visible={resetModal}
                    size={'xs'}
                >
                    <Modal.Head
                        modalClose={() => toggleResetModal(false)}
                        title="Хотите сбросить градуировочные таблицы?"
                    ></Modal.Head>
                    <Modal.Footer>
                        <Button onClick={resetGraduationTable}>Сбросить</Button>
                        <Button
                            border={true}
                            onClick={() => toggleResetModal(false)}
                        >
                            Отмена
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {message && (
                <Modal visible={message}>
                    <Modal.Head modalClose={() => setMessage()}></Modal.Head>
                    <Modal.Body>{message}</Modal.Body>
                </Modal>
            )}
        </>
    );
};

export default memo(Graduation);
