import { delSensorsBundles, updateSensorsBundles, setSensorsBundleModalDel } from '../../../actions/sensors';
import { addChartDisabled, deleteChartDisabled, setChartMarkerDelete, delChartMarkers } from '../../../actions/chart';

export const mapStateToProps = (state) => {
    return {
        bundles: state.sensors.bundles,
        bundlesModalDel: state.sensors.bundlesModalDel,
        disabled: state.chart.disabled,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    delSensorsBundles: (key) => dispatch(delSensorsBundles(key)),
    updateSensorsBundles: (item) => dispatch(updateSensorsBundles(item)),
    setSensorsBundleModalDel: (bool) => dispatch(setSensorsBundleModalDel(bool)),
    addChartDisabled: (id) => dispatch(addChartDisabled(id)),
    deleteChartDisabled: (id) => dispatch(deleteChartDisabled(id)),
    delChartMarkers: (id) => dispatch(delChartMarkers(id)),
});
