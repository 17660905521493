import { setPaused, setPlay } from '../../actions/actions';
import { sendCommand } from '../../actions/port';
import { setSensorData, setSensorImported, setSensorWritedList } from '../../actions/sensors';

export const mapStateToProps = (state) => {
    return {
        port: state.port.open,
        played: state.actions.played,
        paused: state.actions.paused,
        writeStatus: state.writer.writeStatus,
        // command: state.port.command,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setPlay: (bool) => dispatch(setPlay(bool)),
    setPaused: (bool) => dispatch(setPaused(bool)),
    sendCommand: (str) => dispatch(sendCommand(str)),
    setSensorData: (arr) => dispatch(setSensorData(arr)),
    setSensorImported: (arr) => dispatch(setSensorImported(arr)),
    setSensorWritedList: (arr) => dispatch(setSensorWritedList(arr)),
});
