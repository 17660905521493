import React, {useEffect, useState} from 'react';
import Grid from '../ui/Grid';
import "./Grid.scss";
import {useDispatch, useSelector} from "react-redux";
import {Icon, Input} from "../ui";
import {loadDevices} from "../../redux/slices/admin";
import {deviceApi} from "../../api/api";
import {toast} from "react-toastify";
import Modal from "../Modal";

const DeviceGrid = (props) => {
    const dispatch = useDispatch();
    const [text,setText] = useState({});
    const [confirm, setConfirm] = useState({show: false});

    const {admin: {devices}} = useSelector(state => state);

    useEffect(() => {
        dispatch(loadDevices())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function uploadFirmware(file, deviceId) {
        deviceApi.addFirmware(deviceId, file).then(data => {
            dispatch(loadDevices());
        })
        .catch((err) => {
            toast.error(err.response.data.errors);
        });
    }

    function updateFirmware(deviceId, firmwareId) {
        deviceApi.updateFirmware(deviceId, firmwareId, text[firmwareId]).then(data => {
            dispatch(loadDevices());
        }).catch((err) => {
            toast.error(err.response.data.errors);
        });
    }

    const deleteCancel = () => setConfirm({show: false});
    const deleteConfirm = async () => {
        await deleteFirmware();
        setConfirm({show: false});
    };

    async function deleteFirmware() {
        const {deviceId, firmwareId} = confirm;
        await deviceApi.deleteFirmware(deviceId, firmwareId).then(() => {
            dispatch(loadDevices());
        }).catch((err) => {
            toast.error(err.response.data.errors);
        });
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) deleteCancel();
        };
        if (confirm.show)
            window.addEventListener('keydown', close);
        return () => window.removeEventListener('keydown', close);
    }, [confirm.show]);

    return (<>
        <Grid className="corGrid">
            {devices.filter(s=>s.key.toLowerCase().includes(props.search.toLowerCase()) ||
                s.name.toLowerCase().includes(props.search.toLowerCase())).map((d, i) => (
                <Grid.Col className="corGrid__col lg" key={i}>
                    <div className="corGrid__name">
                        <span>{d.key}</span>
                        <div className="corGrid__action">
                        <Icon name="gear" onClick={() => props.showDeviceSettings(
                            {showModal: true, allowDelete: true, device: d})}/>
                        </div>
                    </div>
                    <div className="corGrid__body">
                        <div className="corGrid__desc">{d.name}</div>
                    </div>
                    <div className="corGrid__firmwares">
                            {d.firmwares.map((f, j) => (
                                    <div key={j} className="corGrid__firmwares_item">
                                        <Input className={"corGrid__firmwares_item_name"+(text[f._id]&&text[f._id]!==f.name?" changed":"")}
                                          value={text[f._id]??f.name} onInput={e => setText(t=>({...t,[f._id]:e.target.value}))}
                                          onKeyPress={e => { if (e.key === 'Enter') updateFirmware(d._id, f._id)}}
                                        />
                                        <span className="corGrid__firmwares_item_hash" title={f.hash}>{f.hash}</span>
                                        <Icon name="save" onClick={() => updateFirmware(d._id, f._id)}/>
                                        <Icon name="trash" onClick={() => setConfirm({show:true, deviceId:d._id, firmwareId:f._id})}/>
                                    </div>
                            ))}
                            <div key={-1} className="corGrid__firmwares_item">
                                <label>Добавить прошивку
                                <input
                                    type="file"
                                    accept=".rdf"
                                    onChange={(e) => uploadFirmware(e.target.files[0], d._id)}
                                />
                                </label>
                            </div>
                    </div>
                </Grid.Col>
            ))}
            <Grid.Col className="corGrid__col lg newMember" key={-1}>
                <div className="corGrid__name">
                    <span></span>
                    <div className="corGrid__action">
                    <Icon name="delivery" onClick={() => props.showDeviceSettings(
                        {showModal:true, isAdd:true })}
                          text=" Добавить Устройство"/>
                    </div>
                </div>
                <div className="corGrid__body">
                    <div className="corGrid__desc">...</div>
                </div>
                <div className="corGrid__firmwares">
                    <div className="corGrid__firmwares_item">
                    </div>
                </div>
            </Grid.Col>
        </Grid>
        <Modal visible={confirm.show} content={
            <>
                <div className="modal__message">Точно удалить?</div>
                <div className="modal__action">
                    <button
                        className="cor_btn"
                        onClick={deleteCancel}
                    >Отменить
                    </button>
                    <button
                        className="cor_btn cor_btn_danger"
                        onClick={deleteConfirm}
                    >Удалить
                    </button>
                </div>
            </>
        } size={'xs'}/></>
    )
};
export default DeviceGrid;
