import { connectWebWorker } from './connectWorker';
import { oscilloWorker } from './oscilloscopWorker';

class WebWorker {
    constructor(worker) {
        const code = worker.toString();
        const blob = new Blob(['(' + code + ')()']);
        return new Worker(URL.createObjectURL(blob));
    }
}

const connectWorker = new WebWorker(connectWebWorker);
const oscilloscopWorker = new WebWorker(oscilloWorker);

export { connectWorker, oscilloscopWorker };
