import React, {useContext, useEffect, useRef, useState} from 'react';
import {Grid, Icon, InputRange} from '../ui';
import VideoCard from "../Video/VideoCard";
import {fullName, isAuth, profilePicture} from "../../helpers/auth";
import {VideoContext} from "../Video/VideoContext";
import {useSelector} from "react-redux";
import {storageOptions} from "../../redux/slices/storage";
import "./VideoGrid.scss";

const VideoGrid = (props) => {
    const {
        stream,
        localStream,
        remoteStream,
        showLocal,
        showRemote,
        hasStreamServer,
        users,
        globalVolume,
        isTalking,
        gainStreams,
        videoStreams,
        setVideoStreams,
    } = useContext(VideoContext);
    const {toggle} = useSelector(state => state);
    const {userVolumes, gridOptions} = useSelector(storageOptions);
    const [expanded, setExpanded] = useState(null);
    const [streamVolume, setStreamVolume] = useState(1);
    const volumeRefs = useRef([]);

    useEffect(() => {
        if (expanded && expanded !== isAuth()._id && expanded !== "stream" && users.filter(u => expanded === u.peerId) < 1) {
            setExpanded(null);
        }
    }, [expanded, users]);

    useEffect(() => {
        if (expanded === "stream" && !showLocal && showRemote < 1) {
            setExpanded(null);
        }
    }, [expanded, showLocal, showRemote]);


    const expandMyScreen = (e) => {
        if (e.detail !== 2) return;
        if (!document.fullscreenElement) {
            if (props.video && !props.screen) {
                e.currentTarget.requestFullscreen()
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
    };

    const expandStream = (e) => {
        if (e.detail !== 2) return;
        if (!document.fullscreenElement) {
            e.currentTarget.requestFullscreen()
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
    };

    const expandScreen = (e, p) => {
        if (e.detail !== 2) return;
        if (!document.fullscreenElement) {
            if (videoStreams[p.peerId]?.showStream) {
                e.currentTarget.requestFullscreen()
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
    };

    const toggleTrack = (peerId, isVideo) => {
        if (videoStreams[peerId]) {
            const track = getTrack(peerId, isVideo);
            track.enabled = !track.enabled;
            setVideoStreams(videos => ({...videos}));
        }
    };

    const changeVolume = (user, volume) => {
        props.setUserVolumes({...userVolumes, [user.user._id]: volume});
        const streamId = getStreamId(user.peerId);
        if (streamId && gainStreams[streamId]) {
            gainStreams[streamId].gainNode.gain.value = Math.min(Math.max(globalVolume.current * volume, 0), 2);
            getTrack(user.peerId, false).enabled = volume > 0;
        }
    };

    const clearVolume = (e, user) => {
        changeVolume(user, 1);
        volumeRefs.current[user.peerId]?.setValue(1);
    };

    const changeStreamVolume = (value)=> {
        setStreamVolume(value);
        volumeRefs.current["stream"]?.setValue(value);
    }

    const getStreamId = (peerId)  => videoStreams[peerId]?.streamID?.id;

    const getTrack = (peerId, isVideo) => {
        return videoStreams[peerId] ? (isVideo ? videoStreams[peerId].streamID.getVideoTracks()[0]
            : videoStreams[peerId].streamID.getAudioTracks()[0]) : {enabled: false};
    };

    const getVolume = (user) => {
        return (userVolumes && userVolumes[user.user._id]) ? userVolumes[user.user._id] : 1;
    };

    const showProfile = (id) => props.history.push('/profile' + (id ? '/' + id : ''));

    const roleIcon = (role) => role > 0 ? "teacher" : "student";

    const renderPicture = (user) => user.picture ?
        <img src={profilePicture(user.picture)} alt={user.name}/> :
        <Icon name={roleIcon(user.role)}/>;

    return (
        <Grid className={"videoGrid" + (gridOptions?.video?.gridSize?" " + gridOptions.video.gridSize:"") + (expanded?" expanded":"")}
        // columns={props.gridCol}
        >
            {(showLocal || showRemote!==0) && <Grid.Col className={"videoGrid__col"+(expanded?(expanded === "stream"?" expanded":" hidden"):"")} key="-2">
                <div className="videoGrid__prev">
                    <div className="videoGrid__expand" onClick={()=>setExpanded(expanded?null:"stream")}>
                        <Icon name={expanded?"minimize":"full-screen"}/>
                    </div>
                    <section className="videoGrid__screen" onClick={expandStream}>
                        <div className="videoGrid__screen__title"></div>
                        <VideoCard key={0} stream={showLocal ? localStream : remoteStream}
                                   showStream={showLocal || showRemote !== 0}
                                   muted={!hasStreamServer || showRemote === 0}
                                   volume={streamVolume}/>
                    </section>
                </div>
                <div className={"videoGrid__name"}>
                    <span className='link'>Общий Экран</span>
                    {!showLocal && <span className='controls'>
                        <span className='controls__item controls__item_volume'>
                            <Icon name={streamVolume > 0?"volume":"mute"}
                                  className={"on"}/>
                            <div className="volume">
                               <Icon name="volume" className={streamVolume > 0?"on":""}
                                      onClick={() => changeStreamVolume(1)}/>
                                <InputRange orientation="vertical" min={0} max={1} step={0.01} ref={r=>volumeRefs.current['stream']=r}
                                            onChange={e =>setStreamVolume(e.target.value)} onDoubleClick={(e)=>changeStreamVolume(1)}
                                            value={streamVolume}/>
                                <Icon name="mute" className={streamVolume > 0?"on":""}
                                      onClick={() => changeStreamVolume(0)}/>
                            </div>
                        </span>
                    </span>}
                </div>
            </Grid.Col>}
            <Grid.Col className={"videoGrid__col"+(expanded?(expanded === isAuth()._id?" expanded":" hidden"):"")} key="-1">
                <div className="videoGrid__prev">
                    {(props.screen || props.video || isAuth().picture) && <div className="videoGrid__role" onClick={() => showProfile()}>
                        <Icon name={roleIcon(isAuth().role)}/>
                    </div>}
                    <div className="videoGrid__expand" onClick={()=>setExpanded(expanded?null:isAuth()._id)}>
                        <Icon name={expanded?"minimize":"full-screen"}/>
                    </div>
                    <div className="videoGrid__picture">
                        {renderPicture(isAuth())}
                    </div>
                    <section className="videoGrid__screen" onClick={expandMyScreen}>
                        <div className="videoGrid__screen__title">{isAuth().name}</div>
                        <VideoCard key={0} stream={stream} showStream={toggle.video} muted={true}/>
                    </section>
                </div>
                <div className={isAuth().role > 0 ? "videoGrid__name teacher isYou" : "videoGrid__name isYou" } >
                    <span className="link" onClick={() => showProfile()}>Вы <span className='link_desc'>({fullName(isAuth())})</span></span>
                    <span className='controls'>
                        <span className='controls__item'>
                            <Icon name={props.screen?"monitor":"monitor-off"} className={props.screen?"on":""}
                                onClick={()=>props.toggleScreen(!props.screen)} title="Экран"/>
                        </span>
                        <span className='controls__item'>
                            <Icon name={props.video?"video":"video-off"} className={props.video?"on":""}
                                onClick={()=>props.toggleVideo(!props.video)} title="Камера"/>
                        </span>
                        <span className='controls__item'>
                            <Icon name={props.audio?"microphone":"microphone-mute"} className={props.audio?"on":""}
                                onClick={()=>props.toggleAudio(!props.audio)} title="Микрофон"/>
                        </span>
                      </span>
                </div>
            </Grid.Col>
            {users?.filter(u => u.user._id !== isAuth()._id).sort((a, b) => b.user.role - a.user.role).map((p, i) => (
                <Grid.Col
                    className={"videoGrid__col" +(expanded?(expanded === p.peerId?" expanded":" hidden"):"")
                      + (isTalking?.[videoStreams[p.peerId]?.streamID?.id] ? " talk" : "")}
                    key={i}>
                    <div className="videoGrid__prev">
                        {(p.user.options?.screen || p.user.options?.video || p.user.picture) &&
                          <div className="videoGrid__role" onClick={() => showProfile(p.user._id)}>
                            <Icon name={roleIcon(p.user.role)}/>
                        </div>}
                        <div className="videoGrid__expand" onClick={()=>setExpanded(expanded?null:p.peerId)}>
                            <Icon name={expanded?"minimize":"full-screen"}/>
                        </div>
                        <div className="videoGrid__picture">
                            {renderPicture(p.user)}
                        </div>
                        <div className="videoGrid__screen"
                             onClick={(e) => expandScreen(e, p)}>
                            <div className="videoGrid__screen__title">{p.user.name}</div>
                            <VideoCard stream={videoStreams[p.peerId]?.streamID} muted={true}
                                       showStream={videoStreams[p.peerId]?.showStream}
                            />
                        </div>
                    </div>
                    <div className={p.user.role > 0 ? "videoGrid__name teacher" : "videoGrid__name"}>
                      <span className='link' onClick={() => showProfile(p.user._id)}>{fullName(p.user)}</span>
                      <span className='controls'>
                        <span className='controls__item'>
                            <Icon name={p.user.options?.screen?"monitor":(p.user.options?.video?"video":"video-off")}
                                className={getTrack(p.peerId,true).enabled?((p.user.options?.video || p.user.options?.screen)?"on":""):"off"}
                                onClick={() => toggleTrack(p.peerId,true)}/>
                        </span>
                        <span className='controls__item'>
                            <Icon name={p.user.options?.audio?"microphone":"microphone-mute"}
                                className={getTrack(p.peerId,false).enabled?(p.user.options?.audio?"on":""):"off"}
                                onClick={() => toggleTrack(p.peerId,false)}/>
                        </span>
                        <span className='controls__item controls__item_volume'>
                            <Icon name={p.user.options?.audio?"volume":"mute"}
                                className={getTrack(p.peerId,false).enabled?(p.user.options?.audio?"on":""):"off"}
                                onClick={() => toggleTrack(p.peerId,false)}/>
                            <div className="volume">
                                <Icon name="volume" className={getTrack(p.peerId,false).enabled?(p.user.options?.audio?"on":""):"off"}
                                    onClick={() => toggleTrack(p.peerId,false)}/>
                                <InputRange
                                    orientation={gridOptions?.video?.gridSize === 'videoGrid_tile' ? 'horizontal' : 'vertical'}
                                    min={-0.01} 
                                    max={2} 
                                    step={0.01} 
                                    ref={r=>volumeRefs.current[p.peerId]=r}
                                    onChange={e =>changeVolume(p, e.target.value)} onDoubleClick={(e)=>clearVolume(e, p)}
                                    value={getVolume(p)}
                                />
                                <Icon name="mute" className={getTrack(p.peerId,false).enabled?(p.user.options?.audio?"on":""):"off"}
                                    onClick={() => toggleTrack(p.peerId,false)}/>
                            </div>
                        </span>
                      </span>
                    </div>
                </Grid.Col>
            ))}
        </Grid>
    )
};
export default VideoGrid;
