import {showNameSettings} from "../../actions/actions";

export const mapStateToProps = (state) => {
    return ({
    });
};

export const mapDispatchToProps = (dispatch) => ({
    showNameSettings: (obj) => dispatch(showNameSettings(obj)),
});
