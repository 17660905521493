//image picker: https://imagecolorpicker.com/
import Complex from "complex.js";
import html2canvas from "html2canvas";
import { Button, Icon, InputRange } from '../ui';
import { getRoundValue } from '../ui/utils/gen_utils';
import { calcModelInfoApi} from "../../api/api";
import {ContentHead, Content, ContentBody} from '../template/ContentParts';
import "./CalcModel.scss";
import ss from '../../rlab/src/components/PlayPause/PlayPause.module.scss';
import {toast} from "react-toastify";

export const CALC_MODEL = {
  MAGNETIC_FIELD: 1, 
  LENSE: 2,
  DIFFRACTION: 3,
  INTERFERENCE: 4,
  CAPACITOR: 5,
  INDUCTANCE: 6,
  OSC_CIRCUIT: 7,
  RESONANCE: 8,
  MECHANIC_MOVE: 9,
};

export const ELDYN_EXP_TYPE = {
  CAPACITOR: 1, //'Конденсатор'
  INDUCTANCE: 2, //'Индуктивность'
  OSC_CIRCUIT: 3, //'Колеб. контур'
  RESONANCE: 4, //'Резонанс'
}

export const DEMO_OPTION = {
  REAL_TIME: 1, 
  SPEED_10SEC: 2,
  SPEED_30SEC: 3,
};

export const EXP_STATE = {
	NOT_STARTED: 0,
	STARTED: 1,
	STOPPED: 2,
	CONTINUED: 3, 
	FINISHED: 4,
};

//Расчетная модель «Магнитное поле катушки»
export const SCALEPOS = {
  left: 1,
  right: 2,
  top: 3,
  bottom: 4,
  horiz: 5,
  vert: 6
};

export const SAVE_DATA_TYPE = {
  XLS: 1,
  PROFILE: 2,
};

export const FREQUENCY_IN_MSEC = 100;

export const burdenOptions = [ 
	{label: 'Пуля', value: 1, weight: 0.023, diameter: 0.016},
	{label: 'Мяч', value: 2, weight: 0.375, diameter: 0.2},
	{label: 'Камень', value: 3, weight: 0.33, diameter: 0.05},
	{label: 'Ядро', value: 4, weight: 12, diameter: 0.15},
];

export const GraphicOpts = {
	gapTop: 0,
	gapHoriz: 55,
	gapBottom: 33,
	gapRight: 8,
	horizStrokeNUM: 7,
	vertStrokeNUM: 5,
	scaleLargeSize: 6,
	scaleSmallSize: 3,
	smallStrokeNum: 4,
  scaleLineColor: "#848C94",
  scaleLineOpacity: 0.8,
  scaleLineWidth: 1,
  solenoidColor: "#00B5FF",
  mouseCrossColor: "red",
  magneticArrowColor: "blue",
  magneticDarkArrowColor: "#c71d1d",
  ropeColor: "#FFD74B",
  ropeOpacity: 1,
  ropeWidth: 1.2,
  selectionLineColor: "#97DFFB",
  selectionLineOpacity: 1,
  ellipseLineColor: 'grey', //"#DCDCE8",
  ellipseLineOpacity: 0.35,
  ellipseDarkLineColor: "#505050", // "grey",
  ellipseDarkLineOpacity: 1,
  ellipseLineWidth: 1,
  plusMinusColor: "red",
  gridColor: '#E6EFF4',
  gridDarkColor: '#47484D',
  gridOpacity: 1,
  gridWidth: 1,
  lenseLegendColor: "#00B5FF",
  lenseCoordLegendTextColor: "black",
  lenseDarkCoordLegendTextColor: "#E8E9ED",
  lenseBorderColor: "#00B5FF",
  lenseBkndColor: "white", 
  lenseDarkBkndColor: "#282B34", 
  lenseBorderOpacity: 1,
  lenseBorderWidth: 1,
  lenseInnerColor: "#00B5FF",
  lenseInnerOpacity: 0.1,
  lenseRayColor: "#00B5FF",
  lenseRedRayColor: "red",
  lenseRayOpacity: 1,
  lenseRayWidth: 1,
  diffractionInnerBoxColor: "black",
  diffractionInnerBoxBorderColor: "black",
  diffractionInnerBoxOpacity: 0.9,
  diffractionTextLegendColor: "black",
  diffractionDarkTextLegendColor: "#E8E9ED",
  diffractionBkndLegendColor: "white",
  diffractionDarkBkndLegendColor: "black",
  diffractionLegendBorderColor: "grey",
  diffractionLegendOpacity: 1,
  elDynLegendBkndColor: "white",
  elDynLegendDarkBkndColor: "black",
  elDynLegendBorderColor: "grey",
  elDynLegendOpacity: 1,
  elDynSmallStrokeNum: 0,

  voltageColor: '#00DA98', //'green',
  currentColor: '#9647FB', //'red', => violet
  voltResColor: '#00DA98', //'green',
  voltIndColor: 'red',
  voltCapColor: '#9647FB', //violet
  timeColor: '#8C8F9B', //'grey',
  strokeColor: 'grey',
  extBoxColor: 'grey',
  innerBoxColor: 'grey',
  backgroundColor: '#F9FCFE', //'grey', //with opacity 0.05
  darkBackroundColor: '#282B34',
  backgroundOpacity: 1, //0.05,
  legendCircleRadius: 4,
  legendCircleBorder: 'black',

  heightScaleColor: 'grey',
  distanceScaleColor: 'grey',
  curveColors: ['#F75656', '#00DA98', '#9647FB'],
  curveCircleRadius: 5,
  curveTangentLength: 10,
};

export const getCalcModelNameByType = type => {
  if (type === CALC_MODEL.MAGNETIC_FIELD) return 'Магнитное поле катушки';
  if (type === CALC_MODEL.LENSE) return 'Фокусное расстояние линзы';
  if (type === CALC_MODEL.DIFFRACTION) return 'Дифракция';
  if (type === CALC_MODEL.INTERFERENCE) return 'Интерференция';
  if (type === CALC_MODEL.CAPACITOR) return 'Электродинамика > Конденсатор';
  if (type === CALC_MODEL.INDUCTANCE) return 'Электродинамика > Индуктивность';
  if (type === CALC_MODEL.OSC_CIRCUIT) return 'Электродинамика > Колебательный контур';
  if (type === CALC_MODEL.RESONANCE) return 'Резонанс';
  if (type === CALC_MODEL.MECHANIC_MOVE) return 'Механические явления';
  return '-Расчетная модель-';
};

export const  getModeColorOpts = (isLightMode) => { //getModeColorOpts(isLightMode).magneticArrowColor
  const opts = {
    calcModelBknd: isLightMode ? GraphicOpts.backgroundColor : GraphicOpts.darkBackroundColor,
    gridColor: isLightMode ? GraphicOpts.gridColor : GraphicOpts.gridDarkColor,

    lenseColor: isLightMode ? GraphicOpts.lenseCoordLegendTextColor : GraphicOpts.lenseDarkCoordLegendTextColor,
    lenseBkndColor: isLightMode ? GraphicOpts.lenseBkndColor : GraphicOpts.lenseDarkBkndColor,

    diffrTextLegendColor: isLightMode ? GraphicOpts.diffractionTextLegendColor : GraphicOpts.diffractionDarkTextLegendColor,
    diffrBkndLegendColor: isLightMode ? GraphicOpts.diffractionBkndLegendColor : GraphicOpts.diffractionDarkBkndLegendColor,

    elDynLegendBkndColor: isLightMode ? GraphicOpts.elDynLegendBkndColor : GraphicOpts.elDynLegendDarkBkndColor,
    ellipseLineColor: isLightMode ? GraphicOpts.ellipseLineColor : GraphicOpts.ellipseDarkLineColor,

    magneticArrowColor:  isLightMode ? GraphicOpts.magneticArrowColor : GraphicOpts.magneticDarkArrowColor,
    ellipseLineOpacity: isLightMode ? GraphicOpts.ellipseLineOpacity : GraphicOpts.ellipseDarkLineOpacity,
  };

  return opts;
};

export const getLegendColor = _isLightMode => _isLightMode ? 'black' : 'white';

//Расчетная модель «Конденсатор»
export const capacitanceProcess = (_resistance, _voltage, _capacitance, _isCurrentEnabled, _voltageList, _currentList) => {
  const cap = _capacitance / 1000000.0; //!!!из мкФ в Ф!!!
  const tau = _resistance * cap;
  let uc = 0;
  let ic = 0;
  let time = 0;
  let i = 0;

  while (true) {
    time = tau < 0.0006 ? i / 1000000.0 : i / 10000.0;
    const ttExp = Math.exp(-time / tau);
    let canSaveData = false;

    if (_isCurrentEnabled) { //Charge
      uc = _voltage * (1 - ttExp);
      ic = (_voltage / _resistance) * ttExp;
      if (uc < _voltage * 0.95) canSaveData = true;
    } else { //Discharge
      uc = _voltage * ttExp;
      ic = -(_voltage / _resistance) * ttExp;
      if (uc > 0.1) canSaveData = true;
    }

    if (canSaveData) {
      _voltageList.push([time, uc]);
      _currentList.push([time, ic]);
      i++;
    } else 
      break;
  }

  return time; //3 tau ?
};

export const inductanceProcess = (resistance, voltage, inductance, _isCurrentEnabled, _voltageList, _currentList) => {
    const tau = inductance / resistance;
    const iInit = voltage / resistance;
    let UCoil = 0;
    let ICoil = 0;
    let time = 0;
    let i = 0;
    const THRESHOLD = 0.0001;//0.1;

    //console.log('resistance=',resistance, ' voltage=', voltage, ' inductance=', inductance, ' _isCurrentEnabled=', _isCurrentEnabled, ' tau=', tau);

    while (true)
    {
        if (tau < 0.0006) time = i / 1000000.0; // для очень быстрых процессов (маленькая емкость)
        else time = i / 10000.0;

        const timeTau = Math.exp(-time / tau);

        if (_isCurrentEnabled) {
          UCoil = voltage * timeTau;
          ICoil = iInit * (1 - timeTau);
        } else {
          UCoil = -resistance * iInit * timeTau;
          ICoil = iInit * timeTau;
        }

        let beSave = false;
        if (_isCurrentEnabled && UCoil > THRESHOLD)
          beSave = true;
        else if (!_isCurrentEnabled && ICoil > THRESHOLD)
          beSave = true;

        //if ((_isCurrentEnabled && UCoil > THRESHOLD) || (!_isCurrentEnabled && ICoil > THRESHOLD)) {
        if (beSave) {
          _voltageList.push([time, UCoil]);
          _currentList.push([time, ICoil]);
        }
        else {
          //console.log('i=', i);
          break;
        }

        if (resistance < 100)
          i += 10; //increase step if resistance is small otherwise the size of output arrays is about 200k-500k
        else
          i++;
      }
  
    return time;
}

//Расчетная модель «Колебательный контур»
export const oscillateProcces = (_voltage, _capacitance, _inductance, _resistance, voltageLine, currentLine) => {
  //examples: https://github.com/infusion/Complex.js 
  //const x = new Complex(2, 1); const y = new Complex(-2, 3);	const z = x.add(y); const z2 =  Complex(z).sqrt();
  /* В, мкФ, мГн, Ом */
  const cap = _capacitance / 1000000.; // из мкФ и Ф
  const ind = _inductance / 1000.;    // из мГн в Гн
  const res = _resistance;
  let currentT, voltageT;
  let A1 = 0;
  let A2 = 0;
  let time = 0;
  let tau = 0;

  let p1 = new Complex(0, 0); //Complex p1 = 0;
  let p2 = new Complex(0, 0); //Complex p2 = 0;
  
  const discriminant = Math.pow(res, 2) - (4 * ind / cap);
  if (discriminant > 0) {
    p1 = new Complex((-res + Math.sqrt(discriminant)) / 2 * ind, 0);
    p2 = new Complex((-res - Math.sqrt(discriminant)) / 2 * ind, 0);
    A2 = _voltage * p1.re / (p1.re - p2.re);
    A1 = _voltage - A2;
  }

  if (discriminant < 0) {
    p1 = new Complex(-res / (2 * ind), Math.sqrt(Math.abs(discriminant)) / (2 * ind));
    p2 = new Complex(-res / (2 * ind), -Math.sqrt(Math.abs(discriminant)) / (2 * ind));
    A1 = _voltage;
    A2 = A1 * p1.re / p1.im;
  }

  if (discriminant === 0) {
    p1 = new Complex(-res / 2 * ind, 0); // случай такой сверху
  }
  // тау = 1 / декр.затухания. - время затухания в 2.7 раза
  // декремент затухания  = p1.re
  tau = Math.abs(1.0 / p1.re);
  let wt1im, wt1re, wt2re, wt1imSin, wt1imCos, wt1reExp, wt2reExp;
  let arrSize = tau * 5000;
  if (arrSize > 1000) arrSize = 1000;

  for (let t = 0; t < arrSize; t++) {
    time = t / 1000.0;
    wt1im = p1.im * time;
    wt1re = p1.re * time;
    wt2re = p2.re * time;

    if (discriminant < 0)
    {
      wt1imCos = Math.cos(wt1im);
      wt1imSin = Math.sin(wt1im);
      const p1Exp = Math.exp(-Math.abs(p1.re) * time);
      const a1a2 = A1 * wt1imCos + A2 * wt1imSin; 
      voltageT = a1a2 * p1Exp;
      currentT = p1Exp * (((A2 * wt1imCos - A1 * wt1imSin) * p1.im) - a1a2 * p1.re) * cap * (-1);
    }
    else
    {
      wt1reExp = Math.exp(wt1re);
      wt2reExp = Math.exp(wt2re);
      voltageT = A1 * wt1reExp + A2 * wt2reExp;
      currentT = (A1 * p1.re * wt1reExp + A2 * p2.re * wt2reExp) * cap * (-1);
    } 
    
    voltageLine.push([time, voltageT]);
    currentLine.push([time, currentT]);
  }

  //0 - время колебаний 3 тау, 1 - период колебаний
  const resultTime = [3 * tau, (2 * Math.PI) / p1.im];
  return resultTime;
}

export const diffractionProcess = (POS_NUMBER, waveLength, slotPeriod, slotNumber) => {
  const diffractionLines = [];
  for (let i = 0; i < 100; i++) diffractionLines.push([]);

  const lambda = waveLength; 
  const d = slotPeriod  / 1000.0;  //миллиметры в метры 
  const b = 0.01 / 1000.0;  //миллиметры в метры
  const N = slotNumber;

  const k = 2.0 * Math.PI * 1000000000 / lambda;
  const L = 1;
  const graphData = [];

  for (let i = -POS_NUMBER/2; i < POS_NUMBER/2; i++) {
    let x = i / 40000.0;  //  -5мм  +5мм в метрах
    const arg = 0.5 * k * x / L;
    const arg1 = arg * b;
    const arg2 = arg * d;
    const sinArg1 = Math.pow(Math.sin(arg1) / arg1, 2);
    const sinArg2 = Math.pow(Math.sin(N * arg2) / Math.sin(arg2), 2);

    let intensity = sinArg1 * sinArg2;
    if (intensity > 99 || i === 0) intensity = 99;
    let intI = Math.floor(intensity);

    x *= 1000; //в миллиметры для графиков
    diffractionLines[intI].push(x);
    graphData.push([x, intensity]);  // первый столбец - расстояние, второй - интенсивность 
  }

  return [diffractionLines, graphData];
};

export const interferenceProcessBothSlots = (_waveLength, _slotSpacing) => {
  const lambda = _waveLength;
  const d = _slotSpacing / 1000.0;  //миллиметры в метры
  let k = 2.0 * Math.PI * 1000000000/ lambda;
  const L = 1;

  const interferenceLines = [];
  for (let i = 0; i < 100; i++) interferenceLines.push([]);
  const graphData = [];
          
  for (let i = -200; i <200; i ++) {
    let y = i / 40000.0;  //  -5мм  +5мм в метрах
    const delta = (d * y) / L;
    let intensity = 2 * (1 + Math.cos(k * delta)); // max = 4;
    intensity = intensity * 24;
    y *= 1000; //в милиметры для графиков
    let intI = Math.floor(intensity);

    interferenceLines[intI].push(y);
    graphData.push([y, intensity]);  // первый столбец - расстояние, второй - интенсивность 
  }
  
  return [interferenceLines, graphData];
};

export const interferenceProcessOneSlot = (_waveLength, _slotWidth, _slotSpacing) => {
  const lambda = _waveLength;
  let b = _slotWidth;
  b = b / 1000000.0;  //микрометры в метры
  const slotSpace = _slotSpacing;

  let intensity = 0;
  const k = 2.0 * Math.PI * 1000000000 / lambda;
  const L = 1;
  const interferenceLines = [];

  for (let i = 0; i < 100; i++) interferenceLines.push([]);
  const graphData = [];

  for (let i = -200; i < 200 + slotSpace / 2.0; i++) {
    let y = i / 1000;  //  -200мм  +200мм в метрах - 40 times wider than for 2 slots!
    let x = (0.5 * k * b * y) / L;
    if (x === 0) intensity = 1;
    else intensity = Math.abs(Math.sin(x)/x); // max = 4;
    intensity = intensity * 99;
    y *= 1000; //в милиметры для графиков
    y -= slotSpace / 2.0;

    let intI = Math.floor(intensity);
    interferenceLines[intI].push(y);
    graphData.push([y, intensity]);  // первый столбец - расстояние, второй - интенсивность 
  }

  return [interferenceLines, graphData];
};

export const resonanceProcess = (_frequency, _commonResistance, _capacitance, _inductance, _coilResistance, _voltage, 
  voltResistor, voltInducor, voltCapacitor) => {
  const R = _commonResistance;
  const C = _capacitance / 1000000000.0;    // из нФ в Ф
  const L = _inductance / 1000000.0;        // из мкГн в Гн
  const Zind = _coilResistance / 1000.0;  // из мОм в Ом
  const U = _voltage; //param.amplitude;
  const w = _frequency * 2 * Math.PI * 1000; // приходит в кГц

  const XL = w * L;
  const XC = 1 / (w * C);
  const I = U / (Math.sqrt(   Math.pow(R, 2) + Math.pow(XL - XC, 2) + Math.sqrt(Math.pow(Zind, 2))  ));

  const fi = Math.atan((XL - XC)/R);

  const amplOnElements = {
    frequency: _frequency,	//частота
    current: I, 			// ток
    uResistor: I * R, 		// напряжение на резисторе
    uCapacitor: I * XC, 	// напряжение на конденсаторе
    uInducor: I * XL, 		// напряжение на катушке
  };

  let t = 0;
  let vResistor, vCapacitor, vInducor;

  for (let i = 0; i < 5000; i++) {
    t = i / (_frequency * 1000000.0);
    const wtFi = w * t - fi;

    vResistor = I * R * Math.sin(wtFi);
    vCapacitor = I * Math.sin(wtFi - 0.5 * Math.PI) * XC;
    vInducor = I * (XL * Math.sin(wtFi + 0.5 * Math.PI) + Zind);

    t *= 1000;
    voltResistor.push([t, vResistor]);
    voltCapacitor.push([t, vCapacitor]);
    voltInducor.push([t, vInducor]); //voltInducor.push([t, Math.round(vInducor, 3)]);
  }

  return amplOnElements;
};


//==================================
export const checkRange = range => {
  if (Math.abs(range[1] - range[0]) > 0.001) return range;
  if (Math.abs(range[1]) < 0.001) return [-1, 1];
  if (range[1] > 0) return [0, 2*range[1]];
  return [2*range[1], 0];
};

export const getSvgWithHeightWidth = (svgHeight, svgWidth, obj, key = undefined) => {
  const svg = (
    <svg height={svgHeight} width={svgWidth} key={!key ? Math.random().toString() : key}>
      {obj}
    </svg>
  );
  return svg;
};

export const getPolygonPointArray = (centerX, centerY, varX2, varY2, dX, dY) => {
  const points = [
    [centerX - dX - varX2, centerY + dY + varY2],
    [centerX -      varX2, centerY + dY + varY2],
    [centerX -      varX2, centerY +      varY2],
    [centerX +      varX2, centerY +      varY2],
    [centerX +      varX2, centerY + dY + varY2],
    [centerX + dX + varX2, centerY + dY + varY2],
    //
    [centerX + dX + varX2, centerY - dY - varY2],
    [centerX +      varX2, centerY - dY - varY2],
    [centerX +      varX2, centerY -      varY2],
    [centerX -      varX2, centerY -      varY2],
    [centerX -      varX2, centerY - dY - varY2],
    [centerX - dX - varX2, centerY - dY - varY2],
  ];
  return points;
};

const findLeftTrianglePoint = (ARROW_SIZE_ALONG, ARROW_SIZE_OPPOSITE, A, B) => {
  const t_long = ARROW_SIZE_ALONG * Math.cos(Math.PI/6);
  const t_short = ARROW_SIZE_OPPOSITE * Math.cos(Math.PI/6);
  const v_ab = [B[0] - A[0], B[1] - A[1]];
  const mod = Math.sqrt(Math.pow(v_ab[0], 2) + Math.pow(v_ab[1], 2));
  const unit = [v_ab[0] / mod, v_ab[1] / mod];
  const v_bn = [- t_long * unit[0], - t_long * unit[1]];
  // Order is reversed and one negated on purpose to obtain the perpendicular
  const v_nc = [-t_short * unit[1], t_short * unit[0]];
  const v_bc = [v_bn[0] + v_nc[0], v_bn[1] + v_nc[1]];
  return [B[0] + v_bc[0], B[1] + v_bc[1]];
  }
  
  const findRightTrianglePoint = (ARROW_SIZE_ALONG, ARROW_SIZE_OPPOSITE, A, B) => {
  const t_long = ARROW_SIZE_ALONG * Math.cos(Math.PI/6);
  const t_short = ARROW_SIZE_OPPOSITE * Math.cos(Math.PI/6);
  const v_ab = [B[0] - A[0], B[1] - A[1]];
  const mod = Math.sqrt(Math.pow(v_ab[0], 2) + Math.pow(v_ab[1], 2));
  const unit = [v_ab[0] / mod, v_ab[1] / mod];
  const v_bn = [- t_long * unit[0], - t_long * unit[1]];
  // Order is reversed and THE OTHER negated on purpose to obtain the opposite
  const v_nd = [t_short * unit[1], -t_short * unit[0]];
  const v_bd = [v_bn[0] + v_nd[0], v_bn[1] + v_nd[1]];
  return [B[0] + v_bd[0], B[1] + v_bd[1]];
};

export const checkInput = (val, min, max) => {
  let value = Number(val);
  if (!isNaN(value)) {
    if (value < min) value = min;
    else if (value > max) value = max;
    return value;
  }
  return null;
};

export const getGrid = (scaleArr, HorizVert, gapHoriz, gapTop, width, height, color, strokeWidth, opacity) => {
  const isHoriz = HorizVert === SCALEPOS.horiz;
  if (isHoriz)
    return <path key={"grid01"} d={scaleArr.filter(item => item.isLargeStroke)
    .map(item => 'M ' + item.strokePos[0] + ' ' + gapTop + ' L ' + item.strokePos[0] + ' ' + (gapTop+height) + ' ')
    .join(' ')} fill="none" stroke={color} strokeWidth={strokeWidth} opacity={opacity} />;
  else 
    return  <path key={"grid02"} d={scaleArr.filter(item => item.isLargeStroke)
    .map(item => 'M ' + gapHoriz + ' ' + item.strokePos[1] + ' L ' + (gapHoriz+width) + ' ' + item.strokePos[1] + ' ')
    .join(' ')} fill="none" stroke={color} strokeWidth={strokeWidth} opacity={opacity} />;
};

export const hasNan = (list) => {
  for (let i=0; i < list.length; i++) 
    if (isNaN(list[i])) return false;
  return true;
};

export const getLine = (x0, y0, x1, y1, color, strokeWidth, keyAdd, opacity=1) => {
  const points = getLinePoints(x0, y0, x1, y1);
  return <path key={"line"+keyAdd} d={points} 
    stroke={color} strokeWidth={strokeWidth} opacity={opacity} />;
  // return <polyline key={"polylinex"+keyAdd} points={points} stroke={color} 
  //   fill="transparent" strokeWidth={strokeWidth} opacity={opacity} />;
};

export const getCurve = (xList, yList, color, strokeWidth, keyAdd, opacity=1) => {
  const points = yList.map((item, ind)=> xList[ind] + ' ' + item + ' ').join(" ");
  return <polyline key={"polylinex"+keyAdd} points={points} stroke={color} 
    fill="transparent" strokeWidth={strokeWidth} opacity={opacity} />;
};

export const getDashedCurve = (xList, yList, color, strokeWidth, keyAdd, strokeDasharray = '10 5', opacity=1) => {
  const points = yList.map((item, ind)=> xList[ind] + ' ' + item + ' ').join(" ");
  return <polyline key={"dashpolylinex"+keyAdd} points={points} stroke={color} 
    fill="transparent" strokeWidth={strokeWidth} 
    strokeDasharray={strokeDasharray} opacity={opacity} />;
};

export const getCurveByArr = (xyList, color, strokeWidth, keyAdd) => {
  const xList = xyList.map(item => item[0]);
  const yList = xyList.map(item => item[1]);
  return getCurve(xList, yList, color, strokeWidth, keyAdd);
};

export const getStrokes = (strokeArr, strokeColor, strokeWidth, opacity, keyAdd) => {
  //REMOVED ALL STROKES according to design:
  // return <path key={"strokeArr01"+keyAdd} d={strokeArr.map(item => item.line).join(' ')} fill="none" 
  //   stroke={strokeColor} strokeWidth={strokeWidth} opacity={opacity} />;
  return undefined;
};
export const getScaleStrokes = (strokeArr, strokeColor, strokeWidth, opacity, keyAdd) => {
  return <path key={"strokeArr01"+keyAdd} d={strokeArr.map(item => item.line).join(' ')} fill="none" 
    stroke={strokeColor} strokeWidth={strokeWidth} opacity={opacity} />;
};
//SCALEPOS
export const getScaleValues = (strokesArr, hvScale, LeftRight, textColor, key, supressFloat = false, floatNumbers, clName) => {
  const isHorizScale = hvScale === SCALEPOS.horiz;
  const isLeft = LeftRight === SCALEPOS.left;
  const mainStrokes = strokesArr.filter(item => item.isLargeStroke);
  const leftShift = 38; //23;
  const rightShift = 4;
  const topShift = 5;
  const bottomShift = 17;
  const horizLeftShift = 10;

  const arr = [];

  const xArr = [];
  const yArr = [];
  let hasDuplicates = false;
  for (let i = 0; i < mainStrokes.length; i ++) {  
    let x = mainStrokes[i].strokePos[0];
    let y = mainStrokes[i].strokePos[1];
    if (isHorizScale) {
      x -= horizLeftShift;
      y += bottomShift;
    } else {
      if (isLeft)			
        x -= leftShift;
      else
        x += rightShift;
      y += topShift;
    }
    xArr.push(x);
    yArr.push(y);
  }

  for (let i = 0; i < mainStrokes.length-1; i ++) 
    if (xArr[i] === xArr[i+1] && yArr[i] === yArr[i+1])
      hasDuplicates = true;

  for (let i = 0; i < mainStrokes.length; i ++) {
    const x = xArr[i];
    const y = yArr[i];

    if (isNaN(x)) {
      debugger;
    }

    const power = mainStrokes[i].power;
    const key1 = hasDuplicates ? (''+ Math.random()) : ('' + x + y);
    const className = "calcModel__svgScale " + (clName ? clName : '');
    if (power >= -3) {
      const digits = supressFloat ? 0 : floatNumbers !== undefined ? floatNumbers :  2 + (power < -1 ? -power-1 : 0);
      const value = mainStrokes[i].value;
      const sValue = value.toFixed(digits);
      const text = <text key={"text00"+key+key1} x={x} y={y} className={className} fill={textColor}>{sValue}</text>;
      arr.push(text);
    } else {
      const value = mainStrokes[i].multipliedValue.toFixed(supressFloat ? 0 : 1);
      const text = <text x={x} y={y} key={"text01"+key+key1} className={className} fill={textColor}>{value}</text>;
      arr.push(text);
      const text2 = <text x={x+12} y={y} key={"text02"+key+key1} className={className} fill={textColor}>* 10</text>;
      arr.push(text2);
      const text3 = <text x={x+24} y={y-6} key={"text03"+key+key1} className={className} fill={textColor}>{power}</text>;
      arr.push(text3);
    }
  }

  return arr;
};

export const getAxis = (x1, y1, width, height, color, strokeWidth, opacity) => { 
  return <rect key={"rect01"+x1+y1} x={x1} y={y1} width={width} height={height} 
    stroke={color} fill={color} strokeWidth={strokeWidth} opacity={opacity} />;
};

export const getDashedAxis = (x1, y1, width, height, color, strokeWidth, opacity, strokeDasharray = '20 6') => {
  return <line key={"rect01"+x1+y1}  x1={x1} y1={y1} x2={x1+width} y2={y1+height} 
    strokeDasharray={strokeDasharray}  stroke={color} fill={color} strokeWidth={strokeWidth} opacity={opacity} 
  />;
};

const saveStrokeInfo = (scaleValue, strokeArr, isHorizScale, isLeft, currValue, min, max, shift, size, 
    strokeFrom, strokeSize, 
    isLargeStroke, power, multiplier) => {
  const val = getStrokePos(isHorizScale, currValue, min, max, shift, size);
  let strokePos;
  if (isHorizScale) {
    strokePos = [val, strokeFrom, val, strokeFrom + strokeSize];
  } else {
    strokePos = [strokeFrom, val, strokeFrom + (isLeft ? -strokeSize : +strokeSize), val];
  }

  strokeArr.push({
    strokePos: strokePos,
    isLargeStroke: isLargeStroke, //!!scaleValue,
    value: isLargeStroke ? scaleValue : undefined, //!!scaleValue
    multipliedValue: isLargeStroke ? scaleValue / multiplier : undefined,
    power: power,
    multiplier: multiplier,
    line: getLinePoints(strokePos[0], strokePos[1], strokePos[2], strokePos[3])
  });
};

export const getScaleData = (scaleHorVert, LeftRight, largeStrokeNUM, min, max, 
  shift, //horiz: xPos0, vert: yPos0 
  size, //horiz: width, vert: height
  strokeFrom, //horiz: yPos0, vert: xPos0 => horiz: [xPos0, yPos0, xPos0 + (larg/small)strokeSize]
  largeStrokeSize, 
  smallStrokeSize, smallStrokeNum) => {
  const isHorizScale =  scaleHorVert === SCALEPOS.horiz;
  const isLeft = LeftRight === SCALEPOS.left;
  const [scaleList, power, multiplier] = getScale(min, max, largeStrokeNUM);
  let strokeArr = [];
  
    //1. Add large strokes:
    for (let i = 0; i < scaleList.length; i ++) {
      saveStrokeInfo(scaleList[i], strokeArr, isHorizScale, isLeft, scaleList[i], min, max, 
        shift, size, strokeFrom, largeStrokeSize, 
        true, power, multiplier);
    }

  if (smallStrokeNum === 0) { // if no small strokes we can complete
    return strokeArr;
  }

  //2. Add small strokes:
  const sz = (scaleList[1] - scaleList[0]) / smallStrokeNum;
  let currPos = scaleList[0];
  let isReady = false;

  while (!isReady) {
    if (currPos + sz <= max) {
      currPos += sz;
      saveStrokeInfo(null, strokeArr, isHorizScale, isLeft, currPos, min, max, 
        shift, size, strokeFrom, smallStrokeSize, 
        false, power, multiplier);
    } else {
      isReady = true;
    }
  }

  currPos = scaleList[0];
  isReady = false;

  while (!isReady) {
    if (currPos - sz >= min) {
      currPos -= sz;
      saveStrokeInfo(null, strokeArr, isHorizScale, isLeft, currPos, min, max, 
        shift, size, strokeFrom, smallStrokeSize, 
        false, power, multiplier);
    } else {
      isReady = true;
    }
  }

  return strokeArr;
};

const getStrokePos = (isHorizScale, val, min, max, shift, multiplier) => {
  if (isHorizScale)
    return shift + multiplier * (Number(val) - Number(min)) / (max - min);
  else
    return shift + multiplier * (Number(max) - Number(val)) / (max - min);
};

const getLinePoints = (x1, y1, x2, y2) => {
  return 'M ' + x1 + ' ' + y1 + ' L ' + x2 + ' ' + y2 + ' ';
};

const getScale = (minVal, maxVal, minScaleNum) => {
  if (minVal === maxVal) {
    return [[maxVal - 1, maxVal, maxVal + 1], 0];
  }

  let val1 = 0.0;
  let val2 = 0.0;
  let power = 0;
  let _minVal = minVal;
  let _maxVal = maxVal;
  let foundInt = false;
  let multiplier = 1.0;

  if (Math.abs(_minVal) >= 10 || Math.abs(_maxVal) >= 10) {
    while (!foundInt) {
      _minVal *= 0.1;
      _maxVal *= 0.1;
      power ++;
      multiplier *= 10;
      if (Math.abs(_minVal) < 10 && Math.abs(_maxVal) < 10) {
        foundInt = true;
      }
    }
  } else if (Math.abs(_minVal) < 1 && Math.abs(_maxVal) <= 1) {
    while (!foundInt) {
      _minVal *= 10;
      _maxVal *= 10;
      power --;
      multiplier *= 0.1;
      if (Math.abs(_minVal) >= 1 || Math.abs(_maxVal) >= 1) {
        foundInt = true;
      }
    }
  } 

  val1 = Number((Math.ceil(_minVal * 100) / 100.0).toFixed(3));
  val2 = Number((Math.floor(_maxVal * 100) / 100.0).toFixed(3));
  if (val1 === val2) {
    val1 = Number(_minVal.toFixed(4));
    val2 = Number(_maxVal.toFixed(4));
  }

  const vals = [val1];
  for (let i = 1; i < minScaleNum - 1; i ++) {
    let val = (val1 + i * (val2 - val1) / (minScaleNum - 1));
    if (isNaN(val)) {
      console.log('val=', val, ' xxx');
      debugger;
    }
    val = Number(val.toFixed(2));
    vals.push(val);
  }
  vals.push(val2);

  let finalArr = [];
  finalArr = vals.map(item => item * multiplier);
  return [finalArr, power, multiplier];
};

export const getBackground = (width, height, opacity, color) => {
  return <rect key="rect01" x="0" y="0" width={width} height={height} stroke={color} fill={color} opacity={opacity} strokeWidth="0"/>;
};

export const getBorder = (width, height, color, key="rect01", opacity= 1.0) => {
  return <rect key={"rect02"+width+height+key} x="0" y="0" width={width} height={height} stroke={color} fill="transparent" strokeWidth="1" opacity={opacity} />;
};
export const getInnerBorder = (x, y, width, height, color, key="rect02") => {
  return <rect key={"rect02"+width+height+key} x={x} y={y} width={width} height={height} stroke={color} fill="transparent" strokeWidth="1"/>;
};
export const getColoredInnerBox = (x, y, width, height, bkndColor, borderColor, opacity, key="rect03") => {
  return <rect key={"rect02"+width+height+key} x={x} y={y} width={width} height={height} stroke={borderColor} fill={bkndColor} opacity={opacity} strokeWidth="1"/>;
};
export const getScaleLine = (x, y, width, height, color, key="line01") => {
  return <rect key={"line"+width+height+key} x={x} y={y} width={width} height={height} stroke={color} fill={color} strokeWidth="1"/>;
}
export const getLegendBox = (x, y, width, height, fillColor, strokeColor, key="box01") => {
  return <rect key={"box"+width+height+key} x={x} y={y} width={width} height={height} stroke={strokeColor} fill={fillColor} strokeWidth="1"/>;
}
export const getCircle = (cx, cy, radius, fillColor, strokeColor, opacity, strokeWidth=1, key="circle01") => {
  const circle = <circle cx={cx} cy={cy} r={radius} stroke={strokeColor} fill={fillColor} 
    opacity={opacity} strokeWidth={strokeWidth} key={"circle01"+key} />
  return circle;
}
export const transInnerCoordsToScreenCoords = (xRANGE, yRANGE, _svgWidth, _svgHeight, x, y) => {
  const xRatio = _svgWidth / (xRANGE[1] - xRANGE[0]);
  const yRatio = _svgHeight / (yRANGE[1] - yRANGE[0]);
  return [x * xRatio, y * yRatio];
};

export const getScaleTitle = (position, x, y, text, color, key, className = "calcModel__svgLegend") => {
  if (position === SCALEPOS.horiz) {
    return <text key={"axisbottom"+key} x={x} y={y} className={className} fill={color}>{text}</text>;
  } else if (position === SCALEPOS.left) {
    const transfromLeft = "translate(" + x + "," + y + ") rotate(-90)";
    return <text key={"axisleft"+key} transform={transfromLeft} 
                              className={className} fill={color}>{text}</text>;
  } else if (position === SCALEPOS.right) {
		const transfromRight = "translate(" + x + "," + y + ") rotate(-90)";
		return <text key={"axisright"+key} transform={transfromRight} 
  		                        className={className} fill={color}>{text}</text>;
  }
  return undefined;
};

export const getRopeLinesArray = (_centerX, _centerY, varX2, varY2, loopNumPerMm, coilLength, 
  ropeDY, ropeStartDY, dY) => {
  let loopNum = Math.round(loopNumPerMm * coilLength / 40);
  if (loopNum < 1) loopNum = 1;

  const points = [];
  for (let i = 0; i < loopNum; i ++) {
    let y0 = _centerY + varY2 + ropeDY;
    if (i === 0) {
      y0 += ropeStartDY + dY;
    }
    const x0 = _centerX - varX2 + 2 * i * varX2 / (loopNum + 1);
    const y1 = _centerY - varY2 - ropeDY;
    const x1 = _centerX - varX2 + 2 * (i + 1) * varX2 / (loopNum + 1);

    points.push([x0, y0]);
    points.push([x1, y1]);
  }

  const xf0 = _centerX - varX2 + 2 * loopNum * varX2 / (loopNum + 1);
  const yf0 = _centerY - varY2 - ropeDY;
  const xf1 = _centerX + varX2;
  const yf1 = _centerY + varY2 + ropeDY + ropeStartDY + dY;

  const kCoef = (xf0 - xf1) / (yf0 - yf1);
  const x =  xf0 + (2 * varY2 + 2 * ropeDY) * kCoef;
  
  points.push([x, _centerY + varY2]);
  points.push([xf1, yf1]);

  return points;
};

export const getArrow = (arrowAlongSize, arrowOppositeSize, pos0, pos1) => {
  const [xLeft, yLeft] = findLeftTrianglePoint(arrowAlongSize, arrowOppositeSize, pos0, pos1);
  const [xRight, yRight] = findRightTrianglePoint(arrowAlongSize, arrowOppositeSize, pos0, pos1);

  return ` M ${xLeft} ${yLeft} L ${pos1[0]} ${pos1[1]} L ${xRight} ${yRight} `;
};

const getGaussianLength = (x1, y1, _centerX, _centerY, _currentPower, _coilLength) => {
  let R2 = (x1 - _centerX) * (x1 - _centerX) + (y1 - _centerY) * (y1 - _centerY);
  const coefA = 30000;
  const coefB = 10000;
  R2 = R2 / coefA;
  const eR2 = Math.pow(Math.E, -R2);
  const LI = _currentPower * _coilLength / coefB; //max 75
  let LIER2 = LI * eR2;
  return LIER2;
};

export const getTangent0  = (_ellPars, x1, y1, _centerX, _centerY, _currentPower, _coilLength, len) => {
  const min = 30; 
  const max = 150; 

  const a =_ellPars.ellRA;
  const b =_ellPars.ellRB * (y1 > _centerY ? +1 : -1);
  const a2 = a * a;
  const b2 = b * b;

  const L = getGaussianLength(x1, y1, _centerX, _centerY, _currentPower, _coilLength, min, max);
  const L2 = L > max ? max : L < min ? min : L;

  let signX;
  const isTop = b > 0;
  if ((isTop && y1 < _centerY + b) || (!isTop && y1 > _centerY +b))
    signX = +1;		
  else 
    signX = -1;
  let dX = L2 * signX;
  const coefK = b2 / (y1-_centerY - b) * (1 - (x1+dX-_centerX)*(x1+dX-_centerX)/a2);
  const coefB = _centerY + b;
  let y2 = coefK + coefB;

  const isInside = _ellPars.isCursorInsideSolenoid;
  if (isInside && dX < 0) 
    dX = -dX;

  return [[x1,y1], [x1+dX, y2]];
};

const getSigns = (isTop, diffY) => {
  return isTop ? (diffY ?  [-1,1] : [1,-1]) : (diffY ? [1,1] : [-1,-1]);;
};

export const getEllipseTangent  = (_ellPars, _x1, y1, _centerX, _centerY, 
      _currentPower, _coilLength, len) => {
    //(x1+dx - cx)**2 / a2 + (y1+dy - cy - b) ** 2/ b2 = 1 => 
    //dy = -y1 + cy + b    +/-    sqrt(b2 * (1 - (x1+dx-cx)**2 * a2))
    const min = 30; 
    const max = 150; 

    const a =_ellPars.ellRA;
    const b =_ellPars.ellRB * (y1 > _centerY ? +1 : -1);
    const a2 = a * a;
    const b2 = b * b;

    const DELTA_X = 0.0005;
    let x1;
    if (Math.abs(_x1 - _centerX -a) <= DELTA_X) {
      x1 = _x1 + 3 * DELTA_X;
    } else {
      x1 = _x1;
    }

    let L = Math.pow(len[0]*len[0] + len[1]*len[1], 0.5); //old method
    L = getGaussianLength(x1, y1, _centerX, _centerY, _currentPower, _coilLength);
    const L2 = L > max ? max : L < min ? min : L;

    const [signX, signY] = getSigns(b > 0, y1 - (_centerY + b) > 0);

    let dX = DELTA_X * signX;

    const varX = x1 + dX - _centerX;
    const aX = b2 * (1 - varX * varX / a2);
    const aX2 = signY * Math.pow(aX, 0.5);
    let dY = -y1 + _centerY + b + aX2;

    const dXYL = Math.pow(dX * dX + dY * dY, 0.5);
    const ratioL = L2 / dXYL;
    dX = dX * ratioL;
    dY = dY * ratioL;

    const isInside = _ellPars.isCursorInsideSolenoid;
    if (isInside && dX < 0) 
      dX = -dX;

    return [[x1,y1], [x1+dX, y1+dY]];
};

export const getMagneticFieldAsWholeEllipses = (_centerX, _centerY, _radiusX, _radiusY, index, 
  isBottom, color, opacity, arr) => {
    if (_radiusX === undefined || _radiusY === undefined)  
      return undefined;    
    // if (isTop) {
  // 	let strArrows = `M ${_centerX} ${_centerY} a ${_radiusX} ${_radiusY} 0 1,` + (isBottom? '1': '0') +  ` 1,0`;
  // 	arr.push(<path key={"magn01" +index} d={strArrows} fill="transparent" stroke={color} strokeWidth={1} opacity={opacity} />);
  // } else {

    let strArrows = `M ${_centerX} ${_centerY} a ${_radiusX} ${_radiusY} 0 1,` + (!isBottom? '1': '0') +  ` 1,0`;
    const path = <path key={"magn02"+index} d={strArrows} fill="transparent" 
      stroke={color} strokeWidth={1} opacity={opacity} />;
    arr.push(path);
  //}
};

export const getMagneticFieldAsLinesWithArrows = (_centerX, _centerY, radiusX, radiusY, index, isTop, showAllEllipse, _isLightMode, arr) => {
  //http://xahlee.info/js/svg_path_ellipse_arc.html
  //https://francisco.io/blog/algebra-and-javascript-drawing-an-arrow/
  //https://byjus.com/jee/equation-of-tangent-to-ellipse/ - как найти касательную к эллипсу
  //0 1,1 1,0 - top; 0 1,0 1,0-bottom

  const ARROW_SIZE_ALONG = 8;
  const ARROW_SIZE_OPPOSITE = 2;
  const SHOW_ARROW_NUMBER = 40;
  const ARROW_FREQUENCY = 5;

  const directions = [[1, 1], [1, -1], [-1, 1], [-1, -1]];
  const pieces = [10, 20, 30, 40];
  const pieceNumber = pieces[index];
  const angle = Math.PI / pieceNumber;
  const gap = 0.2;
  const angle2 = angle * (1 - gap);

  for (let k = 0; k < 4; k ++) {
    const isRightPart = directions[k][0];		// +1 for right part of ellipse; -1 for left part of ellipse
    const isTopEllipse = directions[k][1];// -1 from top elipse; +1 for bottom ellipse
    const isForward = isRightPart !== isTopEllipse;

    for (let i = 0; i < pieceNumber; i ++) {
      if (showAllEllipse || 
        (i < SHOW_ARROW_NUMBER && isForward) || 
        (i >= pieceNumber - SHOW_ARROW_NUMBER -1 && !isForward)) {
        const x0 = _centerX + radiusX * Math.sin(-isTopEllipse * angle * i) * isRightPart;
        const y0 = _centerY + radiusY * Math.cos(-isTopEllipse * angle * i) * isRightPart + radiusY * isTopEllipse;
        const x = _centerX + radiusX * Math.sin(-isTopEllipse * (angle * i + angle2)) * isRightPart;
        const y = _centerY + radiusY * Math.cos(-isTopEllipse * (angle * i + angle2)) * isRightPart + radiusY * isTopEllipse;

        //let strArrow = `M ${x0} ${y0} A ${radiusX} ${radiusY} 0 0,` + (isTop? '1': '0') +  ` ${x} ${y} `;
        let strArrow = `M ${x0} ${y0} L ${x} ${y} `;
        const isShowArrow = Math.floor(i / ARROW_FREQUENCY) * ARROW_FREQUENCY === i;

        if (isShowArrow)
          strArrow += getArrow(ARROW_SIZE_ALONG, ARROW_SIZE_OPPOSITE, [x0, y0], [x, y]); 

        const path = <path key={"showell01"+k+i+index+radiusX+radiusY} d={strArrow} fill="transparent" 
                  stroke={getModeColorOpts(_isLightMode).ellipseLineColor}  
                  strokeWidth={GraphicOpts.ellipseLineWidth} 
                  opacity={getModeColorOpts(_isLightMode).ellipseLineOpacity} 
                />
          arr.push(path);
      }
    }
  }
};

export const getPlus = (x, y, lineSize) => {
  return 'M ' + (x - lineSize) + ' ' + y +              ' L ' + (x + lineSize) + ' ' + y + ' ' + 
  'M ' +  x + ' ' +             (y - lineSize) + ' L ' + x + ' ' + (y + lineSize) + ' ';
};

export const getMinus = (x, y, lineSize) => {
  return 'M ' + (x - lineSize) + ' ' + y +              ' L ' + (x + lineSize) + ' ' + y + ' ';
};

export const getTimeUnit = (_timeUnit) => {
  return _timeUnit === 1 ? 'сек' : 'мс'; //TIME_UNIT_SEC
};

export const getMinMax = (list) => {
  let min = list[0];
  let max = min;
  for (let i = 0; i < list.length; i ++) {
    if (list[i] > max) max = list[i];
    if (list[i] < min) min = list[i];
  }

  if (min !== max) 
    return [min, max];

  if (min === 0) 
    return [-1, +1];

  if (min > 0) 
    return [0, 2 * max];
  else // if (min < 0) 
    return [2 * min, 0];
};

export const getB = (_z, _ro, _coilLength, _coilRadius, _windingNumber, _currentPower) => {
	const mu0 = 1.25664 * 0.001 * 0.001; //Гн/м (Н/А2)
  const z = _z * 0.001;
  const ro = _ro * 0.001;
  const coilLength = _coilLength * 0.001;
  const coilRadius = _coilRadius * 0.001;

	const zml2 = z - coilLength * 0.5;
	const zpl2 = z + coilLength * 0.5;
	const zml2Square = zml2 * zml2;
	const zpl2Square = zpl2 * zpl2;
	const roSquare = ro * ro;
	const radiusSquare = coilRadius * coilRadius;
	const radiusRo = radiusSquare + 2 * coilRadius * ro;

	//1 - Br:
	//mu0 * N * I * ro / 4. //coilRadius is in mm
	const coefBr = mu0 * _windingNumber * (0.001 * _currentPower) * radiusSquare * ro * 0.25;

	const brElem1 = zml2Square + roSquare + radiusRo;
	const brElem2 = zpl2Square + roSquare + radiusRo;
	const brElem1Sqt = Math.pow(brElem1, -1.5);
	const brElem2Sqt = Math.pow(brElem2, -1.5);
	let By = coefBr * (brElem1Sqt - brElem2Sqt);

	//2 - Bz:
	//mu0 * N * I / 2
	const coefBz = mu0 * _windingNumber * (0.001 * _currentPower) * 0.5;

	const bzRatio1 = zpl2Square + radiusSquare;
	const bzElem1 = zpl2 * Math.pow(bzRatio1, -0.5);
	const bzRatio2 = zml2Square + radiusSquare;
	const bzElem2 = zml2 & Math.pow(bzRatio2, -0.5);
	let Bx = coefBz * (bzElem1 - bzElem2);
  Bx *= 1000000; //мТл => нТл
  By *= 1000000; //мТл => нТл
	return [Bx, By];
};

export const getBSize = (B, _BxMinMax, _ByMinMax) => {
  const findLength = (bVal, minMax, _minSize, _maxSize) => {
    let res;
    if (bVal >= 0) {
      res = minMax[1] !== 0 ? _minSize + bVal / minMax[1] * (_maxSize - _minSize) : _minSize;
    } else {
      res = -1 * (minMax[0] !== 0 ? _minSize + bVal / minMax[0] * (_maxSize - _minSize) : _minSize);
    }
    return Math.abs(res) <= _maxSize ? res : _maxSize * (res >= 0 ? 1 : -1);
  };
  const minSize = 14;
  const maxSize = 60;
  const [Bx, By] = B;
  
  let BxLength = findLength(Bx, _BxMinMax, 0, maxSize);
  let ByLength = findLength(By, _ByMinMax, 0, maxSize);

  if (Math.abs(BxLength) < minSize && Math.abs(ByLength) < minSize) {
    if (Math.abs(BxLength) > Math.abs(ByLength)) {
      BxLength = minSize * (BxLength > 0 ? 1 : -1);
      const coef = Math.abs(By) / Math.abs(Bx);
      ByLength = coef * minSize * (ByLength > 0 ? 1 : -1);
    }
    else {
      ByLength = minSize * (ByLength > 0 ? 1 : -1);
      const coef = Math.abs(Bx) / Math.abs(By);
      BxLength = coef * minSize * (BxLength > 0 ? 1 : -1);
    }
  }

  return [BxLength, ByLength];
};


export const getSvgMouseCrossCursor = (_cursorX, _cursorY, svgId = 'mainSvg') => {
  const rect = getSvgRect(svgId);
  if (!rect) return '';

  const lineSize = 8;
  const x = _cursorX - rect.left;
  const y = _cursorY - rect.top;
  let strPoints = getPlus(x, y, lineSize);
  return <path key={"svgmouse01"} d={strPoints} fill={GraphicOpts.mouseCrossColor} stroke={GraphicOpts.mouseCrossColor} strokeWidth={1}/>;
};

export const getMarkerInfo = (xRange, yRange, _coilLength, _coilRadius, _windingNumber, _currentPower, 
  _svgWidth, _svgHeight, _cursorX, _cursorY) => {
  const rect = getSvgRect();
  if (!rect) return {currSvgX: 0, currSvgY: 0, Bx: 0, By: 0};

  const xy = getLenseInfo(xRange, yRange, _svgWidth, _svgHeight, _cursorX, _cursorY);

  const [Bx, By] = getB(xy.currSvgX, xy.currSvgY, _coilLength, _coilRadius, _windingNumber, _currentPower);
  return {currSvgX: xy.currSvgX, currSvgY: xy.currSvgY, Bx: Bx, By: By};
};

export const getLenseInfo = (xRange, yRange, _svgWidth, _svgHeight, _cursorX, _cursorY) => {
  const rect = getSvgRect();
  if (!rect) return {currSvgX: 0, currSvgY: 0};

  const coefX = (xRange[1] - xRange[0]) / _svgWidth;
  const coefY = (yRange[1] - yRange[0]) / _svgHeight;
  const currSvgX = _cursorX < rect.left ? xRange[0] : _cursorX > rect.right ? xRange[1] : xRange[0] + (_cursorX  - rect.left) * coefX;
  const currSvgY = _cursorY < rect.top ? yRange[1] : _cursorY > rect.bottom ? yRange[0] : yRange[1] - (_cursorY  - rect.top) * coefY;

  return {currSvgX: currSvgX, currSvgY: currSvgY};
};

export const getArrowImage = (_isLightMode, markerArrow) => {
  const ARROW_SIZE_ALONG = 8;
  const ARROW_SIZE_OPPOSITE = 2;
  const pos0 = markerArrow[0];
  const pos1 = markerArrow[1];
  const line = 'M ' + pos0[0] + ' ' + pos0[1] + ' L ' + pos1[0] + ' ' + pos1[1];
  const arrow = getArrow(ARROW_SIZE_ALONG, ARROW_SIZE_OPPOSITE, [pos0[0], pos0[1]], [pos1[0], pos1[1]]); 
  const col = getModeColorOpts(_isLightMode).magneticArrowColor;
  return <path key={"linearr01"} d={line + arrow} fill={col} stroke={col} strokeWidth={2}/>;
};

export const getSvgRect = (svgId = 'mainSvg') => {
	const elem = document.getElementById(svgId);
	return elem ? elem.getBoundingClientRect() : undefined;
};

export const getCoordLegend = (svgData, _mouseCoordInfo, _focalValue, showFocusInfoInLegend, _isLightMode) => {
  const xText= !!_mouseCoordInfo ? "X=" + getRoundValue(_mouseCoordInfo.currSvgX, 2) + ' мм' : "";
  const yText= !!_mouseCoordInfo ? "Y=" + getRoundValue(_mouseCoordInfo.currSvgY, 2) + ' мм' : "";
  const focusText = showFocusInfoInLegend ? 'Фокус=' + getRoundValue(_focalValue, 2) + ' мм' : '';
  const legendLEFT = GraphicOpts.gapHoriz/2;
  const legendTOP = GraphicOpts.gapTop + 20;
  const legendWIDTH = !showFocusInfoInLegend ? 180 : 280;
  const legendHEIGHT = 20;

  const lenseBgndColor = getModeColorOpts(_isLightMode).lenseBkndColor;
  const lenseTxtColor = getModeColorOpts(_isLightMode).lenseColor;	
  const legendBackground = getColoredInnerBox(legendLEFT-10, legendTOP - 10, legendWIDTH, legendHEIGHT, 
    lenseBgndColor, lenseBgndColor, 1, "bknd01");
  svgData.push(legendBackground);

  svgData.push(getScaleTitle(SCALEPOS.horiz, legendLEFT, legendTOP+4, xText, lenseTxtColor, "leftkey11", "calcModel__svgLegendBold"));
  svgData.push(getScaleTitle(SCALEPOS.horiz, legendLEFT + 100, legendTOP+4, yText, lenseTxtColor, "rkey11", "calcModel__svgLegendBold"));
  svgData.push(getScaleTitle(SCALEPOS.horiz, legendLEFT + 190, legendTOP+4, focusText, lenseTxtColor, "rkey12", "calcModel__svgLegendBold"));
};

const experimentDescriptionDocs = {
  1: 'Магнитное поле катушки',
  2: 'Фокусное расстояние линзы',
  3: 'Дифракционная решетка',
  4: 'Интерференция света в схеме Юнга',
  5: 'Электродинамика. Конденсатор',
  6: 'Электродинамика. Индуктивность',
  7: 'Электродинамика. Колебательный контур',
  8: 'Резонанс',
  9: 'Механические явления',
};

export const getHeaderBox = (_history, expIndex, isInnerCM, title, title2 = undefined) => {
  const doc = "/CalcModels/Documents/" + experimentDescriptionDocs[Number(expIndex)] + ".docx";

  return (
      <ContentHead className="сalculation_models_head" title={!title2 ? title : title2}>
        <span>
          <a href={doc} download className="cor_btn cor_btn_extraSmall cor_btn_primary cor_btn_border cor_btn_icon_left"
            onClick={() => toast.success("Документ с описанием экспериментов загружен.")}>
              <Icon name="file-word"/>
              Скачать документацию
          </a>
        </span>
        {!isInnerCM &&
          <span>
            <Button back={true} onClick={_history.goBack} /> 
          </span>
        }
      </ContentHead>
  );
};

const getEnabledBoxCoordPoint = (val, width, min, max) => {
  if (val < min + width) {
    return min;
  } else if (val > max - width) {
    return max - 2 * width;
  } else {
    return val - width;
  }
};

const getEnabledTextCoordPoint = (point, boxSize, textSize, xMin, xMax, yMin, yMax, ind, pointArr) => {
  const checkArr = (arr, textPoint, boxSize, textSize, ind) => {
    const res = arr.filter((item, index) => index !== ind && 
      (Math.abs(item[0] - textPoint[0]) <= textSize || Math.abs(item[1] - textPoint[1]) <= textSize)
    );
    return res.length === 0;
  };

  const [pX, pY] = point;
  let checkPoint = [pX, pY-boxSize-textSize];
  if (checkPoint[1] > yMin && checkArr(pointArr, checkPoint, boxSize, textSize, ind)) {
    return [pX, pY-boxSize];
  }

  checkPoint = [pX, pY+2*boxSize+textSize+1];
  if (checkPoint[1] < yMax && checkArr(pointArr, checkPoint, boxSize, textSize, ind)) {
    return [pX, pY+2*boxSize+2*textSize+1];
  }
  checkPoint = [pX-boxSize-textSize, pY];
  if (checkPoint[0] > xMin && checkArr(pointArr, checkPoint, boxSize, textSize, ind)) {
    return checkPoint;
  }
  checkPoint = [pX+boxSize+1, pY];
  return checkPoint;
};

export const getResultVisualization = (xList, yList, isEnabledList, width, height, boxSize, left, top, 
  borderColor, isEnabledColor, isDisabledColor, key) => {
  const boxCoords = [];
  for (let i = 0; i < xList.length; i ++) {
    boxCoords.push([
      getEnabledBoxCoordPoint(xList[i], boxSize, left, left + width),
      getEnabledBoxCoordPoint(yList[i], boxSize, top, top + height)
    ]);
  }

  const textCoords = [];
  for (let i = 0; i < boxCoords.length; i ++) {
    textCoords.push(
      getEnabledTextCoordPoint(boxCoords[i], boxSize, boxSize, 
        left, left + width, top, top + height, i, boxCoords));
  }

  const boxes = [];
  for (let i = 0; i < boxCoords.length; i ++) {
    const box= <rect key={"coord01"+i+key} x={boxCoords[i][0]} y={boxCoords[i][1]} 
      width={2*boxSize} height={2*boxSize} 
      stroke={borderColor} strokeWidth="1" 
      fill={!!isEnabledList && isEnabledList[i] ? isEnabledColor : isDisabledColor}
    />;
    
    boxes.push(box);
  }

  const texts = [];
  for (let i = 0; i < textCoords.length; i ++) {
    const text = <text key={"coord02"+i+key} x={textCoords[i][0]} y={textCoords[i][1]} 
      className="calcModel__svgBoxText" fill={GraphicOpts.timeColor}>{i+1}</text>;
    texts.push(text);
  }

  return [boxes, texts];
};

export const transInnerCrdToScreenCrd = (screenSize, _svgRange, x) => {
  const xRatio = screenSize / (_svgRange[1] - _svgRange[0]);
  return x * xRatio;
};

export const transInnerXYtoScreenXY = (screenSize, _svgXRange, _svgYRange, x, y) => {
  const screenX = transInnerCrdToScreenCrd(screenSize[0], _svgXRange, x);
  const screenY = transInnerCrdToScreenCrd(screenSize[1], _svgYRange, y);
  return [screenX, screenY];
};

export const rgb = (r, g, b) => {
  return ["rgb(",r,",",g,",",b,")"].join("");
}

/**
https://gist.github.com/mjackson/5311256
 * Converts an RGB color value to HSV. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSV_color_space.
 * Assumes r, g, and b are contained in the set [0, 255] and
 * returns h, s, and v in the set [0, 1].
 *
 * @param   Number  r       The red color value
 * @param   Number  g       The green color value
 * @param   Number  b       The blue color value
 * @return  Array           The HSV representation
 */
export const rgbToHsv = (_r, _g, _b) => {
  const r = _r / 255; 
  const g = _g / 255; 
  const b = _b / 255;

  var max = Math.max(r, g, b), min = Math.min(r, g, b);
  var h, s, v = max;

  var d = max - min;
  s = max === 0 ? 0 : d / max;

  if (max === min) {
    h = 0; // achromatic
  } else {
    if (max === r)
      h = (g - b) / d + (g < b ? 6 : 0);
    else if (max === g)
      h = (b - r) / d + 2;
    else 
      h = (r - g) / d + 4;

    h /= 6;
  }

  return [ h, s, v ];
}

export const getRGBByWaveLength = (lambda) => {
	//https://csharp.hotexamples.com/ru/examples/OxyPlot/OxyColor/FromHsv/php-oxycolor-fromhsv-method-examples.html
	// let hue = ((lambda - 650) * 270) / (-250) / 270;
	let hue = (650 - lambda) * 0.004;
	return hsvToRgb(hue, 1, 1);
};

export const getRGBFromArrayByWaveLength = (lambda) => {
  //image picker: https://imagecolorpicker.com/
  const violets = ["#e67eee", "#ce70f1", "#b563f1", "#a359f5", "#7d44f6", "#5830fa", "#4828f9", "#2018fc"];
  const blues = ["#130bfd", "#0b0efd", "#1d24f6", "#3a47f7", "#475af4", "#5878f1", "#6984fd", "#7b99ec"];
  const lightBlues = ["#84a5ec", "#91b5ea", "#a4d3d9", "#90c9bf", "#74bb9a", "#64b384", "#58ad74", "#48a35e"];
  const greens = ["#349a46", "#259332", "#0e8613", "#088400", "#2c9600", "#47a300", "#63b101", "#6eb913"];
  const yellows = ["#8bc600", "#9cce00", "#68dc00", "#eff800", "#fef900", "#fff301", "#ffec00", "#ffe100"];
  const oranges = ["#fedc00", "#fecf00", "#ffc301", "#ffba00", "#ffb001", "#ffa900", "#ff9b01", "#ff8001"];
  const reds = ["#ff8001", "#ff7301", "#ff5d00", "#ff4a00", "#fe3900", "#fe2200", "#ff1600", "#fe0100"];
  
  const colorArray = violets.concat(blues).concat(lightBlues).concat(greens).concat(yellows).concat(oranges).concat(reds);

  const val = (lambda > 740 ? 740 : lambda < 380 ? 380 : lambda) - 380;
  const len = colorArray.length;

  let ind = Math.round((len + 0.) / 360 * val);
  if (ind > len-1) ind = len-1;
  const col =  colorArray[ind];
  return col;
};

/**
 * Converts an HSV color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSV_color_space.
 * Assumes h, s, and v are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   Number  h       The hue
 * @param   Number  s       The saturation
 * @param   Number  v       The value
 * @return  Array           The RGB representation
 */
export const hsvToRgb = (h, s, v) => {
  var r, g, b;

  var i = Math.floor(h * 6);
  var f = h * 6 - i;
  var p = v * (1 - s);
  var q = v * (1 - f * s);
  var t = v * (1 - (1 - f) * s);

  switch (i % 6) {
    case 0: [r, g, b] = [v, t, p]; break;
    case 1: [r, g, b] = [q, v, p]; break;
    case 2: [r, g, b] = [p, v, t]; break;
    case 3: [r, g, b] = [p, q, v]; break;
    case 4: [r, g, b] = [t, p, v]; break;
    case 5: 
    default:
      [r, g, b] = [v, p, q]; break;
  }

  const [colR, colG, colB] = [Math.floor(r * 255), Math.floor(g * 255), Math.floor(b * 255)];
  return rgb(colR, colG, colB);
}

export const getScreenAreaPictureById = async (id, doActions) => {
  const screenshot = document.getElementById(id);

  html2canvas(
      screenshot, 
      {
        useCORS: true, 
        allowTaint: true,
        //foreignObjectRendering: true, //don't use it!
      })
        .then((canvas) => {
          const base64image = canvas.toDataURL("image/png");
          doActions(base64image);
      })
      .catch((err) => {
          console.log('html2canvas error. id=', id, ' error=',  err);
      }) 
      ;
};

// export const captureScreenshot = async (doActions) => {
//   //https://phppot.com/javascript/capture-screenshot-javascript/#:~:text=1)%20Using%20the%20html2canvas%20JavaScript%20library&text=It%20sets%20the%20target%20to,the%20screenshot%20to%20the%20server.
//   //call in handleSaveExperiment: captureScreenshot(img => setSvg(img)); //save image
//       const canvas = document.createElement("canvas");
//       const context = canvas.getContext("2d");
//       const screenshot = document.createElement("screenshot");
//       try {
//           const captureStream = await navigator.mediaDevices.getDisplayMedia();
//           screenshot.srcObject = captureStream;
//           context.drawImage(screenshot, 0, 0, window.width, window.height);
//           const base64image = canvas.toDataURL("image/png");
//           captureStream.getTracks().forEach(track => track.stop());
//           doActions(base64image);
//       } catch (err) {
//           console.error("Error: " + err);
//       }
//   };

export const getTimeStringByTime = time => {
  let [secs, msecs] = getRoundValue(time, 3).toString().split('.');
  msecs = !msecs ? '000' : msecs.length < 2 ? msecs + '00' : msecs.length < 3 ? msecs + '0' : msecs.substring(0, 3);
  let iSecs = parseInt(secs);
  const iMins = Math.floor(iSecs/60);
  iSecs -= iMins * 60;
  let mins = iMins.toString();
  secs = iSecs.toString();
  return (mins.length < 2 ? '0' + mins : mins) + ':' + (secs.length < 2 ? '0' + secs : secs) + ':' + msecs;
};

export 	const printDiv = (ids, title, params, cssList) => {
  const a = window.open('', '', 'height=2000, width=2000');
  if (!a) return null;

  a.document.write('<html>');
  a.document.write('<head>');

  //a.document.write('<link href="./CalcModel.scss" rel="stylesheet">');
  if (cssList && cssList.length > 0) {
    for (let i = 0; i < cssList.length; i ++)
      a.document.write('<link href="' +cssList[i]+ '" rel="stylesheet">');
  }

  a.document.write('<style type="text/css" media="print">');
  a.document.write('@page { size: landscape; }');
  a.document.write('</style>');

  a.document.write('</head>');

  a.document.write('<body> <h1>' + title +'</h1><br>');

  //HTML content:
  for (let i = 0; i < ids.length; i ++) {
    const divContents = document.getElementById(ids[i]).innerHTML;
    a.document.write(divContents);
  }

  a.document.write('<br><br>');

  //params:
  for (let i = 0; i < params.length; i ++) {
    const par = params[i];
    a.document.write('<div>' + par[0] + '<b>' + par[1] + '</b></div>');
  }
  
  a.document.write('</body></html>');
  a.document.close();

  a.print();
}	

export const getExportCB = (_handleProfile, _handleExportExperiment, isInnerCM) => {
  return (
    <div className="calcModel__docs__drop">
      <div className="calcModel__docs__toggle calcModel__docs__item">
        <Icon name="log-out-circle" />
        <span>Экспорт</span>
      </div>
      <div className="calcModel__docs__list">
        {isInnerCM && 
        <button className="calcModel__docs__item" onClick={_handleProfile}>
          <Icon name="dock" />
          <span>В профиль</span>
        </button>
        }
        <button className="calcModel__docs__item" onClick={_handleExportExperiment}>
          <Icon name="file-excel" />
          <span>XLS</span>
        </button>
      </div>
    </div>
     );		
};

export const getInputRange = opt => {
  // const opt = {
  //   value: waveLength,
  //   ref: waveLengthRef,
  //   handleChange: handleWaveLength,
  //   min: WAVE_LENGTH_RANGE[0],
  //   max: WAVE_LENGTH_RANGE[1],
  //   step: 1,
  //   id: id,                  //only for magnetic field
  //   currValue: currentPower, //only for magnetic field
  //   unlock: unlockFunc,      //only for magnetic field
  //   setValue: setWaveLength,
  //   className: "calcModel__inputRange1 calcModel__rainbowStripeUp",
  //   key: 'light01',
  //   handleInput: v, //2nd par for handleChange if needed
  //   disabled: false,
  //   width: svgWidth,
  //   height: svgHeight,
  //   showBar: false,
  //   orientation: 'vertical'
  // };

  // const doStep = (setValue, value, step, sign) =>  {
  //   setValue(value + step * sign);  // one step to the right or to the left
  // };
  const handleStep = (_opt, sign) => {
    // if ((sign > 0 && _opt.value + _opt.step <= _opt.max) || (sign < 0 && _opt.value - _opt.step >= _opt.min)) {
    //   const value = !!_opt.currValue ? _opt.currValue : _opt.value;
    //   doStep(_opt.setValue, value, _opt.step, sign);
    //   const newValue = value + _opt.step * sign;
    //   _opt.ref?.current?.setValue(newValue);
    // } 

    if (_opt.disabled !== undefined && _opt.disabled) return;

    const value = !!_opt.currValue ? _opt.currValue : _opt.value;
    let newValue;
    if (sign > 0) {
      newValue = value + _opt.step <= _opt.max ? value + _opt.step : _opt.max;
    } else {
      newValue = value - _opt.step >= _opt.min ? value - _opt.step : _opt.min;
    }
    _opt.setValue(newValue);
    _opt.ref?.current?.setValue(newValue);
  };

  let style = undefined;
  if (opt.width) style = {widht: opt.width};
  else if (opt.height) style = {height: opt.height};
  
   //<div style={style} className={opt.className !== undefined ? opt.className : 'calcModel__inputRange'}>
    return (
      <div style={style} className={opt.className !== undefined ? 'calcModel__inputRange' : 'calcModel__inputRange'}>
        <div className="calcModel__stretch">
          <div className="calcModel__fullInputRangeEdge" onClick={() => handleStep(opt, -1)}>
            <Icon name="minus" />
          </div>
          <div id={opt.id} className={"calcModel__fullInputRangeCenter " + (opt.className !== undefined ? opt.className : '')} >
            <InputRange 
              value={opt.value} 
              ref={opt.ref}
              onChange={e => {opt.handleChange(e, opt.handleInput)}} 
              min={opt.min} 
              max={opt.max} 
              step={opt.step} 
              showBar={opt.showBar !== undefined ? opt.showBar : true} 
              orientation={opt.orientation !== undefined ? opt.orientation : 'horizontal'} 
              disabled={opt.disabled !== undefined ? opt.disabled : false}
            />
          </div>
          <div className="calcModel__fullInputRangeEdge" onClick={() => handleStep(opt, +1)}>
            <Icon name="plus" />
          </div>
        </div>
      </div>
    );
};

export const clearTimer = (_timerId, _setTimerId) => {
  clearInterval(_timerId);
  _setTimerId(undefined);
};

export const workProgress = (_setRefreshCount) => { 
  _setRefreshCount(cnt => cnt + 1);
};

export const doNextProgressStep = (_played, _setStartPlay, _paused, _setPaused, _expState, _setExpState, _refreshCount, _setRefreshCount, _timerId, _setTimerId) => {
    if (_played && !_paused) { //stop
      _setPaused(true);  
    } else { //continue
      _setPaused(false);
      !_played && _setStartPlay(true);
    }

    const next = _expState === EXP_STATE.NOT_STARTED ? EXP_STATE.STARTED : 
      (_expState === EXP_STATE.STARTED || _expState === EXP_STATE.CONTINUED) ? EXP_STATE.STOPPED :  
      _expState === EXP_STATE.STOPPED ? EXP_STATE.CONTINUED : EXP_STATE.STOPPED;
      _setExpState(next);
  
    if (next ===  EXP_STATE.STARTED || next === EXP_STATE.CONTINUED) {
      const _tmId = setInterval(() => workProgress(_setRefreshCount), FREQUENCY_IN_MSEC);
      _setTimerId(_tmId);
    } else if (next === EXP_STATE.STOPPED) {
      clearTimer(_timerId, _setTimerId);
    } 
  };

export const getPlayPauseExperiment = (_isLightMode, _startPlay, _played, _paused, _currProgressInSec, handleFinish, handlePlayPause) => {
  const stopBtnStyle = _isLightMode ? {} : {color: 'white'};

  return (
    <div className={ss.root}>
      <button 
        className="icon_stop" 
        type="button" 
        title="Стоп" 
        style={stopBtnStyle}
        disabled={!_played} 
        onClick={handleFinish}
      ></button>

      <button 
        className={_paused ? ss.playPause + ' active' : ss.playPause}
        type="button" 
        title="Пуск/пауза"
        style={{ display: !_played && _paused ? 'none' : 'flex' }} 
        onClick={handlePlayPause}
      >
        <i className={_played && !_paused ? 'icon_pause' : 'icon_play'}></i>

        {/* {_startPlay && (
          <div className={ss.loader}>
              <Watch height="36" width="36" radius="40" color="#b8bccc" ariaLabel="" visible={true} />
          </div>
        )} */}
      </button>

      <span className={ss.root__progressInSec}>{_currProgressInSec}</span>
      
    </div>
  );
};

export const getCalcModels = (handleOpenCalcModel, isLightMode, history, isInnerCM) => {
	const modelsArr = [
		{imgName: '01_Magnetic_field', title: 'Магнитное поле катушки'},
		{imgName: '02_Lense', title: 'Линза'},
		{imgName: '03_Diffraction', title: 'Дифракция'},
		{imgName: '04_Interference', title: 'Интерференция'},
		{imgName: '05_ElDyn_Capacitor', title: 'Электродинамика > Конденсатор'},
		{imgName: '06_ElDyn_Inductance', title: 'Электродинамика > Индуктивность'},
		{imgName: '07_ElDyn_OscCircuit', title: 'Электродинамика > Колебательный контур'},
		{imgName: '08_ElDyn_Resonance', title: 'Резонанс'},
    {imgName: '09_Mechanic_Move', title: 'Механические явления'},
	];

    return ( 
		<>
			<ContentHead title="Расчетные модели" >
        {!isInnerCM && <Button back={true} onClick={history.goBack} /> }
      </ContentHead>
			<Content>
				<ContentBody>
					<div className="calcModel__initPageList">
						{modelsArr.map(({imgName, title}, ind) => (
							<div className="calcModel__initPageElem"  key={'top'+ind}>
								<div className="calcModel__initPageImage" onClick={() => handleOpenCalcModel(ind + 1)}>
									<img 
										src={"/CalcModels/SplashScreens/" + imgName + (isLightMode ? "_light" : "_black") + ".jpg"} 
										alt='' 
										key={imgName+ind} />
								</div>
								<div className="calcModel__initPageBottom"  key={'bottom'+ind}>
									<div className="calcModel__initPageTitle" key={'title'+ind}>
										{ind + 1}. {title}
									</div>
									<button className="calcModel__initPageLink" onClick={() => handleOpenCalcModel(ind + 1)} key={'btn'+ind}>
									</button>
								</div>
							</div>))
						}
					</div>
				</ContentBody>
			</Content>
		</>
    );
};

///// Print and Excel - change bknd: 
export const canStartAction = (_isLightMode, _dispatch, _reloadOption, _setIsLightModeChanged, _setMakeAction) => {
  if (!_isLightMode) {
    _dispatch(_reloadOption('theme', 'light'));
    _setIsLightModeChanged(true);
    setTimeout(() => {_setMakeAction(true);}, 100);
  } else {
    _setMakeAction(true);
  }
};

export const completeAction = (_dispatch, _setMakeAction, _isLightModeChanged, _setIsLightModeChanged, _reloadOption) => {
  _setMakeAction(false);	
  if (_isLightModeChanged) {
    _dispatch(_reloadOption('theme', 'dark'));
    _setIsLightModeChanged(false);
  }
};

///// --- calcModelInfoApi ---------------------------------------------------------------------------
export const addCalcModelInfo = async (calcModelInfoData) => {
  const result = await calcModelInfoApi.addCalcModelInfo(calcModelInfoData)
      .then (res => res)
      .catch (err => console.log('AFTER adding - err=', err))
  return result;
};

export const getCalcModelInfoById = async (id) => {
  const result = await calcModelInfoApi.getCalcModelInfoById(id)
      .then (res => res)
      .catch(err => console.log('err=', err));
  return result;
}

	//Расчетная модель Мехянические явления
//Mechanical move: fill Curve Params:
export const getCurrentParams = (_velocity, _angle, _height, _calcOptionId, _burdenOptionId) => {
  const burden = burdenOptions.find(item => item.value === _burdenOptionId);
  return {
    velocity: _velocity, 
    angle: _angle, 
    height: _height, 
    calcOptionId: _calcOptionId, 
    burdenOptionId: _burdenOptionId, 
    //calculated options:
    weight: burden.weight, 
    diameter: burden.diameter, 
    isWithAir: _calcOptionId === 1, 
    name: burden.label
  };
};

export const getCurveValues = (curve) => {
  const getNRoDM = (curve) => {
    const boId = curve.burdenOptionId;
    const n = boId === 1 ? 0.012 : //пуля
          boId === 2 ? 0.055 : //мяч
          boId === 3 ? 0.1 : //камень	
          0.06;			   //ядро
    return 0.5 * n * AIR_RO * curve.diameter * curve.diameter / curve.weight;
  };

  const AIR_RO = 1.2255;
  const g = 9.81;
  const a = 0.5 * AIR_RO * (curve.velocity * curve.diameter)**2 / curve.weight;
  const angle = 0.5 * Math.PI * curve.angle / 90;
  
  return {
    a: a,
    g: 9.81,
    ga: g + (curve.isWithAir ? a : 0),
    K: getNRoDM(curve),
    v0x: curve.velocity * Math.cos(angle),
    v0y: curve.velocity * Math.sin(angle),
  };
};

export const getXYByTime = (_curveParam, cv, t) => {
  const v0x = cv.v0x;
  const v0y = cv.v0y;
  let x, y;

  if (!_curveParam.isWithAir) {
    x = v0x * t;
    y = _curveParam.height + v0y * t - 0.5 * cv.ga * t * t;
  } else {
    const K = cv.K;
    const g = cv.g;

    const v0xt = v0x * t;
    x = v0xt - 0.5 * K * v0xt * v0xt;
    
    y = _curveParam.height + v0y * t -
        0.5 * (g + 0.25 * K * v0y * v0y) * t * t;
  }
  
  return [x, y];
};

const getXYByCursorX = (_curveParam, _curveData, cursorX) => {
  const svgR = document.getElementById('mainSvg')?.getBoundingClientRect();
  const width = svgR.width - 2 * GraphicOpts.gapHoriz;

  const cv = getCurveValues(_curveParam);
  const v0x = cv.v0x;
  const v0y = cv.v0y;
  const g = cv.g;
  const K = cv.K;
  const h0 = _curveParam.height;
  const cX = cursorX - GraphicOpts.gapHoriz - svgR.left;
  const maxX = _curveData.maxValues[0]
  let x, y;
  
  if (!_curveParam.isWithAir) {
    x = cX / width * maxX;
    const xv0x = x / v0x;
    y = h0 + v0y * xv0x - 0.5 * g * xv0x ** 2;
  } else {
    const R = 0.5 * (g + 0.25 * K * v0y * v0y);
    const kv0x = K * v0x;
    x = cX / width * maxX;
    const x1 = 1 - Math.sqrt(1-2*K*x);
    y = h0 + v0y * x1 / kv0x - R * x1**2 / (kv0x)**2;
  };

  return [x, y > 0 ? y : 0];
};

export const getFlightTimeWithoutAir = _curveParam => {
  //y(t) = h0 + voy*t - 1/2 (g+a) * t**2 = 0
  const cv = getCurveValues(_curveParam);
  const h0 = _curveParam.height;

  const p = -2 * cv.v0y / cv.ga;
  const q = -2 * h0 / cv.ga;

  const p2 = 0.5 * p;
   const time = -p2 + Math.sqrt(p2 * p2 - q);
  return time;
};
export const getFlightTimeWithtAir = _curveParam => {
  //y(t) = h0 + voy*t - 1/2 (g+1/4 * K * v0y**2) * t**2 = 0
  const cv = getCurveValues(_curveParam);
  const K = cv.K;
  const v0y = cv.v0y;
  const h0 = _curveParam.height;

  const B = cv.g + 0.25 * K * v0y * v0y; //знаменатель
  const p = -2 * v0y / B;
  const q = -2 * h0 / B;
  const p2 = 0.5 * p;
  const time = -p2 + Math.sqrt(p2 * p2 - q);
  return time;
}

export const getParabolaTangent = (_curveParams, cv, time, x0, y0, xSize, ySize) => {
  const getTangentCoefWithoutAir = (cv, time) => {
    /* Description:
      x(t) = v0x * t;  y(t) = MAX_HEIGHT - (h0 + v0y * t - g * t**2 /2 ) =>
      t = x / v0x,    y(x) = MAX_HEIGHT - (h0 + x * (v0y / v0x) - g * (x / v0x) ** 2 / 2 )
      y'(x) = - v0y /v0x + g * x / v0x**2
    */
    const v0x = cv.v0x;
    const v0y = cv.v0y
    const x = v0x * time;
    const tangent_coef = - v0y / v0x + cv.ga * x / (v0x * v0x);
    return tangent_coef; //производная
  };
  const getTangentCoefWithAir = (cv, time) => {
    /* Description:
      K = 1/2 * n Ro d**2/m
      ax = Kvx**2, ay = k vy**2 where vx = v0x, vy = 1/2 v0y => ax = K v0x**2, ay = 1/4 K v0y**2
      x(t) = v0x * t - ax * t**2 / 2; y(t) = h0 + v0y t - 1/2 (g + ay) t **2 =>
      x(t) = v0x * t - (Kv0x**2) * t**2 / 2; y(t) = h0 + v0y t - 1/2 (g + 1/4 K v0y**2) t**2
      R = 1/2 (g + 1/4 K v0y**2) => y(t) = h0 + v0y t - R t**2

      t**2 - 2/ (K v0x) * t+ 2/(kv0x**2) *x = 0 => t = 1/(Kv0x) * (1 - Sqrt(1-2Kx)) - берем меньший корень
      
      y(x) = h0 - v0y/ (K v0x) * (1 - Sqrt(1-2Kx)) - R * (K v0x)**2 * (1-Sqrt(1-2Kx))**2
      Раскрываем и убираем свободные члены - 
      y1(x) = 1/(K v0x) * (   2Rx/v0x + (2R/(K v0x) - v0y) * Sqrt(1-2Kx)   )
      y'1(x) = 1/(K v0x) * (  2R/v0x  + (v0y - 2R/(K v0x)) * K/Sqrt(1-2Kx)  )
    */
    const v0x = cv.v0x;
    const v0y = cv.v0y;
    const K = cv.K;
    const R = 0.5 * (cv.g + 0.25 * K * v0y * v0y);
    const kv0x = K * v0x;

    const v0xt = v0x * time;
    const x = v0xt - 0.5 * K * v0xt * v0xt;

    const tangent_coef = (2*R /v0x + (v0y - 2*R/kv0x) * K /Math.sqrt(1-2*K*x))/kv0x;
    return -1 * tangent_coef; //производная  (-1 поскольку вертикальная ось идет сверху вниз)
  }

  const coef = 0.05;
  const len = xSize * coef; //len ** 2 = dX ** 2 + dY ** 2, dY = k dX => (1+k**2) x**2 = len **2
  const k = !_curveParams.isWithAir ? getTangentCoefWithoutAir(cv, time) : getTangentCoefWithAir(cv, time);
  let dx = len / Math.sqrt(1 + k * k);

  let dy = -1 * k * dx; // -1 для учета направления коррдинат
  if (y0 + dy < 0) {
    dy = -y0;
    dx = Math.abs(dy) / k;
  }

  return [x0 + dx, y0 + dy];
};
