import React, {useState, useEffect, useRef, forwardRef, useImperativeHandle} from 'react';
import "./InputRange.scss";

const InputRange = forwardRef((props, ref) => {
    const { min = 0, max = 100, step = 1, value = 0, disabled = false,
        onChange = null, onDoubleClick = null, orientation = 'horizontal', showBar = true }
        = props;
    const defaultStyle = {'--min': min, '--max': max, '--val': value};
    const [currentVal, setCurrentVal] = useState(value);
    const [currentClass, setCurrentClass] = useState("inputRange");
    const [currentStyle, setCurrentStyle] = useState();
    const inputRef = useRef(null);
    const wrapRef = useRef(null);

    useImperativeHandle(ref, () => ({
        setValue(val) {
            setCurrentVal(val);
            showBar && inputRef.current.style.setProperty('--val', val);
        }
    }));

    useEffect(() => {
        window.addEventListener('resize', resizeVerticalRange);
        return () => {
            window.removeEventListener('resize', resizeVerticalRange);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        resizeVerticalRange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[inputRef, wrapRef]);

    useEffect(() => {
        setCurrentVal(value);
        if (showBar) {
            const style = {
                '--min': min, '--max': max, '--val': value
            }
            setCurrentStyle(style);
        }
    },[max, min, showBar, value]);
    
    useEffect(() => {
        if (showBar) {
            setCurrentStyle(defaultStyle);
            setCurrentClass("inputRange");
        } else {
            setCurrentStyle()
            setCurrentClass(e => e + ' no-bar')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[showBar]);
    
    const resizeVerticalRange = (e) => {
        if (orientation === 'vertical') {
            const wrap = wrapRef.current;
            const input = inputRef.current;
            if (input && wrap) {
                if (input.style.width !== '') {
                    input.style.width = 0 / 16  + 'em';
                }
                setTimeout(() => {
                    input.style.width = wrap.offsetHeight / 16 + 'em';
                }, 100);
            }
        }
    };

    return (
        <div 
            ref={wrapRef} 
            className={orientation === 'horizontal' ? "inputRange__wrap" : "inputRange__wrap vertical"}
            >
            <input
                ref={inputRef}
                type="range"
                className={currentClass}
                orient={orientation}
                disabled={disabled}
                min={min}
                max={max}
                step={step}
                value={currentVal}
                style={currentStyle}
                onChange={onChange}
                onDoubleClick={onDoubleClick}
                onInput={({target}) => {
                    setCurrentVal(target.value);
                    showBar && target.style.setProperty('--val', target.value);
                }}
            />
        </div>
    )
});
export default InputRange;
