import React from 'react';
import ss from './FilterByDate.module.scss';
import DateTimePicker from '../DatePicker/DateTimePicker';

const FilterByDate = ({
    startDate,
    setStartDate,
    finishDate,
    setFinishDate
}) => {
    return (
        <div className={ss.root}>
            <div className={ss.root__item}>
                <div className={ss.root__label}>Дата с</div>
                <div className={ss.root__input}>
                    <DateTimePicker
                        startDate={startDate} 
                        setStartDate={setStartDate} 
                    />
                </div>
            </div>

            <div className={ss.root__item}>
                <div className={ss.root__label}>По</div>
                <div className={ss.root__input}>
                    <DateTimePicker 
                        startDate={finishDate} 
                        setStartDate={setFinishDate} 
                    />
                </div>
            </div>
        </div>
    )
};
export default FilterByDate;

