import { useState } from 'react';
import Sticker from './Sticker';
import { useDispatch } from 'react-redux';
import { updateCard } from '../../../redux/slices/trello';

const Stickers = ({ card, stickersData, isModal }) => {
  const dispatch = useDispatch();

  const [isVisiblePriority, setIsVisiblePriority] = useState(false);
  const [isVisibleType, setIsVisibleType] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(false);

  const handleOpenSubmenu = (item) => {
    if (item.subitems) {
      setOpenSubmenu((prevState) =>
        prevState === item.name ? true : item.name
      );
    }
  };

  const handleCloseSubmenu = (item) => {
    if (item.subitems) {
      setOpenSubmenu((prevState) =>
        prevState === item.name ? false : item.name
      );
    }
  };

  const addSticker = (sticker, data) => {
    if (sticker.role === 0) {
      if (!card.users.includes(sticker._id)) {
        const newA = [...card.users, sticker._id];
        dispatch(updateCard(card._id, 'users', newA));
      } else {
        const updatedArr = card.users.filter((item) => item !== sticker._id);
        dispatch(updateCard(card._id, 'users', updatedArr));
      }
    } else if (sticker.group === 'date') {
      dispatch(updateCard(card._id, 'deadline', data));
    } else if (sticker.group === 'priority') {
      dispatch(
        updateCard(card._id, 'priority', sticker.priority, 'activeTask')
      );
    }
  };

  stickersData.priority.function = () => {
    setIsVisiblePriority(!isVisiblePriority);
  };

  stickersData.type.function = () => {
    setIsVisibleType(!isVisibleType);
  };

  return (
    <>
      <div className={isModal ? 'stickers stickers__modal' : 'stickers'}>
        {Object.keys(stickersData).map((key) => {
          const sticker = stickersData[key];
          return (
            <div
              key={sticker.name}
              className={'stickers__item'}
              onMouseEnter={() => handleOpenSubmenu(sticker)}
              onMouseLeave={() => handleCloseSubmenu(sticker)}
            >
              <Sticker sticker={sticker} />
              {sticker?.subitems && openSubmenu === sticker.name && (
                <div
                  className={
                    openSubmenu
                      ? 'stickers__submenu'
                      : 'stickers__submenu closed'
                  }
                >
                  {sticker?.subitems?.map((substicker) =>
                    substicker.role === 0 ? (
                      <label htmlFor={substicker._id} key={substicker.name}>
                        <input
                          id={substicker._id}
                          type="checkbox"
                          onChange={() => addSticker(substicker)}
                          checked={card.users.includes(substicker)}
                        />
                        <span>{`${substicker.name} ${substicker.name2}`}</span>
                      </label>
                    ) : substicker.group === 'date' ? (
                      <div key={substicker.name}>
                        <input
                          type="date"
                          value={card.deadline || ''}
                          onChange={(e) => addSticker(substicker)}
                        />
                      </div>
                    ) : (
                      <Sticker
                        key={substicker.name}
                        sticker={substicker}
                        onClick={() => addSticker(substicker)}
                      />
                    )
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Stickers;
