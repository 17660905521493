import React, { useState, useEffect } from 'react';
import {toast} from "react-toastify";
import Icon from '../Icon';
import { Modal, Button } from 'rlabui';
import ss from './ModalBattery.module.scss';

const defaultBatteryLowLevel = 29;

const ModalBattery = ({ setDeviceBatteryModal, modal, battery, deviceList }) => {
    const [devices, setDevices] = useState([]);
    const [needCharge, setNeedCharge] = useState(false);

    useEffect(() => {
        for (const key in battery) {
            if (battery[key] && Object.keys(battery[key])?.length) {
                const { value, chardged } = battery[key];
                if (value <= defaultBatteryLowLevel && !chardged) {
                    setNeedCharge(true);
                }
                if (value > defaultBatteryLowLevel) {
                    setNeedCharge(false);
                }
                if (chardged) {
                    setNeedCharge(false);
                }
            }
        }
    }, [battery]);

    useEffect(() => {
        if (needCharge) {
            // console.log('needCharge');
            toast.warn('Заряд батареи меньше 30%');
        }
    }, [needCharge]);

    useEffect(() => {
        const keys = Object.keys(deviceList);
        const arr = [];
        keys?.forEach((key) => {
            const {
                attributes: { name },
            } = deviceList[key];
            const percent = battery && battery[key] ? battery[key].value : null;
            const chardged = battery && battery[key] ? battery[key].chardged : null;
            arr.push({ name, percent, chardged });
        });
        setDevices(arr);
    }, [deviceList, battery]);

    const renderBatLevel = (level, isChardge) => {
        if (!level) return;

        let iconName = 'battery-min';
        let iconClass = 'danger';
        let iconText = 'Требуется зарядка!';
        if (isChardge) {
            iconText = 'Батарея заряжается';
            iconClass = 'success';
            iconName = 'battery-charge';
        } else {
            if (level > 66) {
                iconText = 'Батарея заряжена';
                iconClass = 'success';
                iconName = 'battery-full';
            }
            if (level >= 30 && level <= 66) {
                iconText = 'Средний заряд';
                iconClass = 'success';
                iconName = 'battery-middle';
            }
        }
        return (
            <>
                <span className={iconClass}>
                    <Icon name={iconName} /> {iconText}
                </span>
                <span>
                    Осталось: <b>{level}%</b>
                </span>
            </>
        );
    };

    return (
        <Modal visible={modal}>
            <Modal.Head
                modalClose={() => setDeviceBatteryModal(false)}
                title="Подключенные датчики"
            ></Modal.Head>
            <Modal.Body>
                <div className={ss.root}>
                    {devices.length > 0
                        ? devices?.map(({ name, chardged, percent }, i) => (
                              <div
                                  key={i}
                                  className={ss.item}
                              >
                                  <div className={ss.left}>
                                      <Icon name="speed-dashboard" />
                                      <div className={ss.name}>
                                          <span>{name}</span>
                                      </div>
                                  </div>
                                  <div className={ss.right}>{renderBatLevel(percent, chardged)}</div>
                              </div>
                          ))
                        : 'Нет подключенных устройств'}
                </div>
            </Modal.Body>
            <Modal.Footer align="right">
                <Button
                    border={true}
                    onClick={() => setDeviceBatteryModal(false)}
                >
                    Закрыть
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default ModalBattery;
