import React, { useState, useEffect } from 'react';
import { getFormattedDate } from './utils';
import {toast} from "react-toastify";
import { Modal, Button, Input, Textarea } from 'rlabui';

const VideoEditorXlsxExport = (props) => {
    const { showModal, setShowModal, fileName, saveXLSX } = props;
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        setTitle(fileName);
    }, [fileName]);

    const confirmYes = () => {
        const fullFileName = (!!title ? title : fileName) + ' ' + getFormattedDate(new Date(), 'ymd_hm');
        saveXLSX(fullFileName, description);
        toast.success('Результаты сохранены в ' + fullFileName + ' .');
        setShowModal(false);
    };

    const handleTitle = value => {
        if (value.length < 70)
            setTitle(value);
    };
    const handleDescription = value => {
        if (value.length < 200)
            setDescription(value);
    };

    return (
        <Modal visible={showModal}>
            <Modal.Head
                modalClose={() => setShowModal(false)}
                title="Сохранить эксперимент"
            ></Modal.Head>
            <Modal.Body>
                <div className="form">
                    <div className="form__row">
                        <div className="legend">Название</div>
                        <Input
                            value={title}
                            onInput={(e) => handleTitle(e.target.value)}
                        />
                    </div>

                    <div className="form__row">
                        <div className="legend">Описание</div>
                        <Textarea
                            autoComplete="off"
                            value={description}
                            name="description"
                            placeholder="Опишите эксперимент"
                            rows="4"
                            onChange={(e) => handleDescription(e.target.value)}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={confirmYes}>Да</Button>
                <Button
                    border={true}
                    onClick={() => setShowModal(false)}
                >
                    Нет
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default VideoEditorXlsxExport;
