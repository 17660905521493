import { getRelativePositionLeft, getRelativePositionTop, timeToSecond } from '../utils';

export const cursorPlugin = () => {
    return {
        hooks: {
            ready: (u) => {
                const {
                    key,
                    isChildren,
                    cursor: { setCursorSync },
                } = u;
                u.over.addEventListener('mousemove', (e) => moveCursor(u, e));
                u.over.addEventListener('mouseleave', () => {
                    setCursorSync({ key: isChildren ? isChildren : key, data: [] });
                    removeCursor(u);
                });
            },
            setCursor: (u) => {},
        },
    };
};

export const moveCursor = (u, e) => {
    const {
        key,
        over,
        isChildren,
        annotations: { hide },
        cursor: { setCursorSync, x, y, syncPos },
    } = u;

    if (hide) return false;

    const cursorEnabled = x && y;
    let left = syncPos[0];
    let top = syncPos[1];

    if (e && e.type === 'mousemove') {
        cursorEnabled && setCursorSync({ key: isChildren ? isChildren : key, data: [getRelativePositionLeft(u, e), getRelativePositionTop(u, e)] });
    }

    let xLine = over.querySelector('.u-cursor-x');
    let yLine = over.querySelector('.u-cursor-y');

    if (!xLine) {
        xLine = document.createElement('div');
        xLine.className = 'u-cursor-x';
        over.appendChild(xLine);
    }

    if (!yLine) {
        yLine = document.createElement('div');
        yLine.className = 'u-cursor-y';
        over.appendChild(yLine);
    }

    if (cursorEnabled && left !== -10 && top !== -10) {
        xLine.style.display = 'block';
        yLine.style.display = 'block';
    } else {
        xLine.style.display = 'none';
        yLine.style.display = 'none';
        return;
    }

    if (xLine && yLine && cursorEnabled) {
        xLine.dataset.value = timeToSecond(u.posToVal(left, 'x'));
        yLine.dataset.value = u.posToVal(top, 'y').toFixed(3);

        xLine.style.transform = `translate(${left}px, 0)`;
        yLine.style.transform = `translate(0, ${top}px)`;
    }
};

export const removeCursor = (u) => {
    const { over } = u;
    const xLine = over.querySelector('.u-cursor-x');
    const yLine = over.querySelector('.u-cursor-y');

    if (xLine) {
        xLine.style.display = 'none';
    }

    if (yLine) {
        yLine.style.display = 'none';
    }

    u.cursor.top = null;
    u.cursor.left = null;
};
