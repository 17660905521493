import { setExportData, setExportModal, setFileExtension } from '../../actions/export';

export const mapStateToProps = (nstate) => {
    return {
        modal: nstate.nexport.modal,
        played: nstate.napp.played,
        records: nstate.nsensor.records,
        markers: nstate.nchart.marker,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setExportModal: (str) => dispatch(setExportModal(str)),
    setExportData: (obj) => dispatch(setExportData(obj)),
    setFileExtension: (str) => dispatch(setFileExtension(str)),
});
