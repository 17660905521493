import { setPaused, setPlay } from '../../actions/app';
import { sendCommand } from '../../actions/port';
import { resetSensorData } from '../../actions/sensor';
import { setStopwatch } from '../../actions/timer';

export const mapStateToProps = (nstate) => {
    return {
        port: nstate.nport.open,
        command: nstate.nport.command,
        played: nstate.napp.played,
        paused: nstate.napp.paused,
        // writeStatus: nstate.nwriter.writeStatus,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setPlay: (bool) => dispatch(setPlay(bool)),
    setPaused: (bool) => dispatch(setPaused(bool)),
    sendCommand: (str) => dispatch(sendCommand(str)),
    resetSensorData: () => dispatch(resetSensorData()),
    setStopwatch: (miliseconds) => dispatch(setStopwatch(miliseconds)),
});
