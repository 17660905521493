import React, { useState, useEffect, useCallback } from "react";
import {storageClass} from "../../redux/slices/storage";
import { isAuth } from "../../helpers/auth";
import {onlineTestApi, onlineTestInfoApi} from "../../api/api";
import { getOnlineTestById, getQuestionTypes } from "./ot_utils";
import {useSelector} from "react-redux";
import OnlineTestCreateQuestions from "./OnlineTestCreateQuestions";
import ModalCommentDlg  from "../ui/ModalDialogs/ModalCommentDlg";
import ModalConfirmDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import OnlineTestCreateGeneral from "./OnlineTestCreateGeneral";
import {toast} from "react-toastify";
import { Back, Content, ContentBody, ContentHead } from "../template/ContentParts";

export const taskTypes = {
	TASK_TYPE_TESTING: 0,
}

export const completionTestTypes = {
	COMPLETE_TEST_TYPE_SAVE_DATA: 0,
}

const OnlineTestAddEdit = (props) => {
    const [testTitle, setTestTitle] = useState('');
    const [description, setDescription] = useState('');
    const [timeLimitInMinutes, setTimeLimitInMinutes] = useState(5);
    const [timeLimitEnabled, setTimeLimitEnabled] = useState(false);
    const [isRandomOrder, setIsRandomOrder] = useState(true);
    const [isAutoEstimateType, setIsAutoEstimateType] = useState(false);
    const [taskTypeList, setTaskTypeList] = useState([]);
    const [taskType, setTaskType] = useState(taskTypes.TASK_TYPE_TESTING);
    const [completionTypeList, setCompletionTypeList] = useState([]);
    const [completionType, setCompletionType] = useState(completionTestTypes.COMPLETE_TEST_TYPE_SAVE_DATA);
    const [questionTypeList, setQuestionTypeList] = useState([]);
    const [onlineTestId, setOnlineTestId] = useState('');
    const [showInfoComment, setShowInfoComment] = useState(false);
    const [infoComment, setInfoComment] = useState('');
    const [shouldSave, setShouldSave] = useState(false);
    const [wantFinish, setWantFinish] = useState(false);
    const [confirmFinish, setConfirmFinish] = useState(false);
    const [showCompleteDlg, setShowCompleteDlg] = useState(false);
    const [showGeneral, setShowGeneral] = useState(true);
    const [questionList, setQuestionList] = useState([]);
    const [updateQList, setUpdateQList] = useState(null);
    const [isRunningEditQuestions, setIsRunningEditQuestions] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [isTemplate, setIsTemplate] = useState(false);
    const [objVersion, setObjVersion] = useState(undefined);
	const [isSaved, setIsSaved] = useState(false);
	
	const isSA = isAuth().role === 3; //superadmin
    const documentClass = useSelector(storageClass);

	const checkTestUsage = async (_onlineTestId) => {
		const result = await onlineTestInfoApi.getAllOnlineTestInfosByTest(_onlineTestId);
		if (result && result.data.length > 0) {
			setInfoComment("Этот тест уже использовался в тестировании учеников. Добавление или удаление вопросов может привести к искажениям результов тестирования. Рекомендуем создать новый тест.");
			setShowInfoComment(true);
		}
	};

	useEffect(() => {
		const fetchData = async (_onlineTestId) => {
			const data = await getOnlineTestById(_onlineTestId);

			//1. get general info
			setTestTitle(data.title);
			setDescription(data.description);
			setTimeLimitInMinutes(data.timeLimitInMinutes !== 0 ? data.timeLimitInMinutes : 5);
			setTimeLimitEnabled(data.timeLimitEnabled);
			setIsRandomOrder(data.isRandomOrder);
			setTaskType(data.taskType);
			setCompletionType(data.completionType);
			setIsAutoEstimateType(!!data.isAutoEstimateType ? data.isAutoEstimateType : false);
			setQuestionTypeList(getQuestionTypes(data.questionTypes));
			setQuestionList(data.questions);
			setIsEditable(isAuth().role === 3 || !!data.owner);
			setIsTemplate(isAuth().role === 3 || !data.owner);
            setObjVersion(data.__v);
		};

		setTaskTypeList([{name: 'Тестирование', id: taskTypes.TASK_TYPE_TESTING}]);
		setCompletionTypeList([{label: 'Зафиксировать результат и завершить тестирование', value: completionTestTypes.COMPLETE_TEST_TYPE_SAVE_DATA}]);

		if (!props.onlineTestId) return;
		setOnlineTestId(props.onlineTestId);
		if (props.onlineTestId === "0") {
			setQuestionTypeList(getQuestionTypes(''));
			setIsEditable(true);
			setIsTemplate(isAuth().role === 3);
		} else {
			fetchData(props.onlineTestId);
			if (isAuth().role < 3)
				checkTestUsage(props.onlineTestId); //check if students already answered the test:
		} 
    }, [props.onlineTestId]);

	useEffect(() => {
		if (wantFinish && (!shouldSave || confirmFinish)) {
			setShowCompleteDlg(false);
			props.history.push(isAuth().role < 3 ? '/practicum/ot' : '/ot');
		}
    }, [confirmFinish, shouldSave, props.history, wantFinish]);

    const handleBack = () => {
		goBack();  //< Выйти
	};

    const goBack = () => {
		if (showGeneral) {
			setWantFinish(true);
			setShowCompleteDlg(shouldSave);
		} else 
			setShowGeneral(true);
	};

    const handleConfirmFinishNo = () => {
		setWantFinish(false);
		setShowCompleteDlg(false);
	};

    const handleConfirmFinishYes = () => {
		setConfirmFinish(true);
		setShowCompleteDlg(false);
	};
	
	const handleSaveAndExit = () => {
		doSave();
		goBack();
	};

	const getSaveData = useCallback(() => {
		const onlineTestData = {
			title: testTitle,
			description: description,
			taskType: taskType,
			completionType: completionType,
			timeLimitInMinutes: timeLimitInMinutes,
			timeLimitEnabled: timeLimitEnabled,
			isRandomOrder: isRandomOrder,
			isAutoEstimateType: isAutoEstimateType,
			questionTypes: questionTypeList.map(item => item.value ? 1 : 0).join(''),
			questions: updateQList  ? updateQList : questionList,
		};
		if (isAuth().role < 3) onlineTestData.room = documentClass._id;
		if (onlineTestId !== "0") {
			onlineTestData.__v = objVersion;
		}
		return onlineTestData;
    }, [completionType, description, documentClass._id, isAutoEstimateType, isRandomOrder, 
		objVersion, updateQList, questionTypeList, taskType, testTitle, timeLimitEnabled, 
		timeLimitInMinutes, onlineTestId, questionList]);

    const doSave = useCallback(() => {
		const saveData = async () => {
			setShouldSave(false);
			const onlineTestData = getSaveData();
			setUpdateQList(null);

			if (onlineTestId === "0") {
				const result = await onlineTestApi.addOnlineTest(onlineTestData);
				setOnlineTestId(result._id);
				setObjVersion(result.__v);
				setQuestionList(result.questions);
				setIsSaved(true);
			} else {			
				await onlineTestApi.updateOnlineTestById(onlineTestId, onlineTestData)
				.then((result) => {
					//console.log('CHANGES SAVED')
					setObjVersion(result.__v);
					setQuestionList(result.questions);
					setIsSaved(true);
				}).catch((err) => {
					toast.error(err.response.data.errors);
				});
			}
		};
	
		saveData();
    }, [getSaveData, onlineTestId]);
	
	useEffect(() => {
		if (!shouldSave) return;
		doSave();
    }, [shouldSave, doSave]);

	//=========================================================
	if (!onlineTestId) {
		return <></>;
	}

    return (
	    <>
			{showGeneral && (
				<>
					<ContentHead flex={false}>
						<Back onClick={handleBack} icon="back" margin="bottom" />
						<div className="cor_content__title">Добавление задания</div>
					</ContentHead>
					<Content>
						<ContentBody>
							<OnlineTestCreateGeneral 
								testTitle={testTitle}
								setTestTitle={setTestTitle}
								description={description}
								setDescription={setDescription}
								taskType={taskType}
								setTaskType={setTaskType}
								taskTypeList={taskTypeList}
								setTaskTypeList={setTaskTypeList}
								timeLimitInMinutes={timeLimitInMinutes}
								setTimeLimitInMinutes={setTimeLimitInMinutes}
								timeLimitEnabled={timeLimitEnabled}
								setTimeLimitEnabled={setTimeLimitEnabled}
								completionType={completionType}
								setCompletionType={setCompletionType}
								completionTypeList={completionTypeList}
								setCompletionTypeList={setCompletionTypeList}
								isRandomOrder={isRandomOrder}
								setIsRandomOrder={setIsRandomOrder}
								isAutoEstimateType={isAutoEstimateType}
								setIsAutoEstimateType={setIsAutoEstimateType}
								questionList={questionList}
								questionTypeList={questionTypeList}
								setQuestionTypeList={setQuestionTypeList}
								showGeneral={showGeneral}
								setShowGeneral={setShowGeneral}
								handleSaveAndExit={handleSaveAndExit}
								onlineTestId={onlineTestId}
								isRunningEditQuestions={isRunningEditQuestions}
								setIsRunningEditQuestions={setIsRunningEditQuestions}
								shouldSave={shouldSave}
								setShouldSave={setShouldSave}
								isEditable={isEditable}
							/>
						</ContentBody>
					</Content>
				</>
			)}

			{!showGeneral && (
				<OnlineTestCreateQuestions 
					onlineTestId={onlineTestId}
					setShowGeneral={setShowGeneral}
					taskTypeList={taskTypeList}
					taskType={taskType}
					questionList={questionList}
					setUpdateQList={setUpdateQList}
					enabledQuestionTypes={questionTypeList.filter(item => item.value)}
					setShouldSave={setShouldSave}
					isEditable={isEditable}
					isSA={isSA}
					isTemplate={isTemplate}
					isSaved={isSaved} 
					setIsSaved={setIsSaved}
				/>
			)}

			{showInfoComment &&
    	    <ModalCommentDlg
        	    showComment={showInfoComment}
				setShowComment={setShowInfoComment}
				comment={infoComment}
	        />
			}

			{showCompleteDlg &&
			<ModalConfirmDialog
				showConfirmDlg={showCompleteDlg} 
				handleNo={handleConfirmFinishNo}
				question={'Имеются несохраненные данные. Вы действительно хотите завершить тест и выйти?'}
				handleYes={handleConfirmFinishYes}
			/>
		}
        </>
    )
}

export default OnlineTestAddEdit;
