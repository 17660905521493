export const setChartX = (str) => ({ type: 'SET_NCHART_X', payload: str });
export const setChartY = (str) => ({ type: 'SET_NCHART_Y', payload: str });
export const setChartView = (str) => ({ type: 'SET_NCHART_VIEW', payload: str });
export const setChartStyle = (obj) => ({ type: 'SET_NCHART_STYLE', payload: obj });
export const setChartMode = (str) => ({ type: 'SET_NCHART_MODE', payload: str });
export const setChartSignalMode = (str) => ({ type: 'SET_NCHART_SIGNALMODE', payload: str });
export const setChartSettingsModal = (bool) => ({ type: 'SET_NCHART_SETTINGS_MODAL', payload: bool });
export const setChartMarkers = (obj) => ({ type: 'SET_NCHART_MARKERS', payload: obj });
export const delChartMarkers = (obj) => ({ type: 'DEL_NCHART_MARKERS', payload: obj });
export const setChartXRanges = (obj) => ({ type: 'SET_NCHART_XRANGES', payload: obj });
export const setChartMarkerAdd = (bool) => ({ type: 'SET_NCHART_MARKER_ADD', payload: bool });
export const setChartMarkerDelete = (bool) => ({ type: 'SET_NCHART_MARKER_DELETE', payload: bool });
export const clearChartMarkerModal = (bool) => ({ type: 'CLEAR_NCHART_MARKER_MOADL', payload: bool });
export const setChartRanges = (bool) => ({ type: 'SET_NCHART_RANGES', payload: bool });
export const setChartMarkerDeleteAll = (bool) => ({ type: 'SET_NCHART_MARKER_DELETE_ALL', payload: bool });
export const addChartDisabled = (id) => ({ type: 'ADD_NCHART_DISABLED', payload: id });
export const deleteChartDisabled = (id) => ({ type: 'DELETE_NCHART_DISABLED', payload: id });
export const setChartOffsetRec = (bool) => ({ type: 'SET_NCHART_OFFSET_REC', payload: bool });
export const setCursorSync = (obj) => ({ type: 'SET_NCHART_CURSOR_SYNC', payload: obj });
