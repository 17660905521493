import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { connectWorker, oscilloscopWorker } from '../../webWorkers/WebWorker';
import { BLE } from '@awesome-cordova-plugins/ble';

const Connection = (props) => {
    // console.log('Connect props ===', props);
    const { isAndroid } = window;
    // console.log(window.Ionic);
    const {
        demo,
        demoSettings,
        port,
        portData,
        connectionType,
        connectionLoss,
        devices,
        devicesList,
        devicesConnected,
        deviceResponse,
        devicesCount,
        played,
        paused,
        sensor,
        sensorList,
        sensorsEnabled,
        frequency,
        writer,
        // writerSelected,
        command,
        sensorReading,
        timer,
        graduate,
        responsesArray,
        oscilloscopeSettings,
        isOscilloscope,
        isHiSpeed,
        forceUpdate,
        sensorsUnits,
        gradOffset,
        abscissa,

        setPaused,
        addDevicesList,
        removeDevicesList,
        setDeviceConnectedCount,
        getDevicesList,
        setDevicesBattery,
        getUnitsList,
        setSensorFrequency,
        setSensorList,
        setSensorData,
        setSensorReading,
        setSensorAsideData,
        setSensorWritedList,
        setSensorWritedData,
        // clearSensorsEnabled,
        delSensorsEnabled,
        sendCommand,
        setDeviceResponse,
        // checkHiSpeed,
        checkOscilloscope,
        setOscilloscopeSettings,
        setForceUpdate,
        // removeDisconnectedDevice,
        enabledDemoMode,
        readDemoSensors,
        setDemoSettings,
    } = props;

    // const [fakePortOpen, setFakePortOpen] = useState(false);
    const [waiting, setWaiting] = useState(true);
    const [devicesInf, setDevicesInf] = useState([]);
    const [worker, setWorker] = useState(null);
    const demoUpdate = useRef();

    useEffect(() => {
        if (!devices.length) {
            getDevicesList();
            getUnitsList();
        }
    }, []);

    useEffect(() => {
        if (port) {
            setTimeout(() => {
                setWaiting(false);
            }, 1000);
        }
    }, [port]);

    useEffect(() => {
        if (demo?.length) {
            if (!port) {
                enabledDemoMode();
            } else {
                clearInterval(demoUpdate.current);
                demoUpdate.current = setInterval(() => {
                    readDemoSensors();
                }, 50);
            }
        } else {
            clearInterval(demoUpdate.current);
        }
        !port && setDevicesInf([]);
    }, [demo, readDemoSensors, port]);

    useEffect(() => {
        // console.log(devicesInf);
        const devices = Object.values(devicesList);

        if (devicesInf?.length && devices?.length) {
            devices.forEach((device) => {
                const { unicName, id } = device;
                const checkInf = devicesInf.filter((inf) => inf.key === unicName)[0];
                if (checkInf) {
                    device.inf = checkInf;
                    addDevicesList({ key: id, data: device });
                }
            });
        }
    }, [devicesInf, devicesList.length]);

    useEffect(() => {
        if (isOscilloscope) {
            setWorker(oscilloscopWorker);
        } else {
            setWorker(connectWorker);
        }
    }, [isOscilloscope]);

    useEffect(() => {
        // console.log('connectionLoss', connectionLoss);
        if (connectionLoss && played) {
            setPaused(true);
        }
        if (!connectionLoss && paused) {
            writeCommand('acq 1');
        }
    }, [connectionLoss]);

    useEffect(() => {
        if (!played) {
            worker?.postMessage({ getSaved: true, played, paused });
        }
    }, [played]);

    useEffect(() => {
        if (paused) {
            worker?.postMessage({ getSaved: true, played, paused });
        }
    }, [paused]);

    useEffect(() => {
        if (!port) {
            setSensorList([]);
            worker?.postMessage({ reset: true });
        }
    }, [port]);

    useEffect(() => {
        const freq = { ...frequency };
        sensorList?.forEach((item) => {
            if (!freq[item.num]) freq[item.num] = 10;
        });
        setSensorFrequency(freq);
    }, [sensorList]);

    useEffect(() => {
        if (writer) {
            const keys = Object.keys(writer);
            sendCommand('inf key' + keys[keys.length - 1]);
        }
    }, [writer, sensorList]);

    useEffect(() => {
        if (command.length) {
            writeCommand(command);
        }
    }, [command]);

    useEffect(() => {
        const connectedKeys = Object.keys(devicesConnected);
        const listKeys = Object.keys(devicesList);

        if (port && connectedKeys.length < listKeys.length) {
            const connectedSet = new Set(connectedKeys);
            const diffKey = listKeys.filter((e) => !connectedSet.has(e));

            removeDevicesList({ key: diffKey });
            delSensorsEnabled({ key: diffKey });
            setDeviceConnectedCount(connectedKeys.length);
        }
        // Сохраняем устройства в local storage
        if (listKeys?.length) {
            const saved = localStorage.getItem('devicesHistory');
            if (saved) {
                // Получаем сохраненные значения
                const devices = JSON.parse(saved !== 'null' ? saved : '{}');
                const connected = saveConnecnted();
                connected && !demo.length && localStorage.setItem('devicesHistory', JSON.stringify(Object.assign(devices, connected)));
            } else {
                // Если нет ключа, создадим пустой
                localStorage.setItem('devicesHistory', '{}');
            }

            function saveConnecnted() {
                // Пропускаем сохранение в деморежиме
                if (demo.length) return;
                const obj = {};
                for (const key in devicesList) {
                    const {
                        id,
                        unicName,
                        attributes: { name, key: deviceKey },
                    } = devicesList[key];

                    // console.log(id);
                    let isUsb = false;

                    if (id.includes('usb')) {
                        isUsb = true;
                    }

                    const curKey = isUsb ? unicName : id;

                    obj[curKey] = {
                        id,
                        key: unicName,
                        name,
                        connectType: id.includes('usb') ? 'usb' : 'bt',
                        deviceKey,
                        connected: 'off',
                        battery: '-',
                        signal: 1,
                        serial: '',
                    };
                }
                return obj;
            }
        }
    }, [devicesConnected, devicesList, demo]);

    const processMessage = (e) => {
        const { channel = [], data = [], aside = [], reading, writedList = [], writedData = [], oscilloSettings = {}, played, saved, battery } = e.data;

        battery !== null && setDevicesBattery(battery);

        if (reading) {
            setSensorWritedList([...writedList]);
            setSensorWritedData([...writedData]);
        }

        if (saved) {
            console.log(data);

            setSensorData([...data]);
        } else {
            if (channel) {
                // console.log(channel);
                setSensorList(channel);
            }
        }

        if (played && !paused) {
            setSensorData([...data]);
        }

        !saved && setSensorAsideData([...aside]);

        if (oscilloSettings) {
            setOscilloscopeSettings(oscilloSettings);
        } else {
            setOscilloscopeSettings(null);
        }
    };

    useEffect(() => {
        worker?.addEventListener('message', processMessage);
        return () => {
            worker?.removeEventListener('message', processMessage);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [worker]);

    useEffect(() => {
        // console.log('Connection deviceResponse', deviceResponse);
        if (deviceResponse.includes('rcc')) {
        }
        if (deviceResponse.includes('wcc')) {
        }
        if (deviceResponse.includes('wcc error 0')) {
        }
        if (deviceResponse.includes('rlg')) {
            const resp = deviceResponse.split(' ');
            // console.log(resp);
            if (!isNaN(+resp[1])) {
                if (+resp[1] > 0) {
                    setSensorReading(true);
                } else {
                    setSensorReading(false);
                    toast.info('Нет записанных данных');
                }
            }
            if (sensorReading && +resp[2] < 0) {
                toast.warn('Ошибка чтения сохраненных данных');
                setSensorReading(false);
            }
            if (deviceResponse.includes('rlg error 0')) {
                setSensorReading(false);
            }
        }
        if (deviceResponse.length) {
            setDeviceResponse('');
        }
    }, [deviceResponse]);

    useEffect(() => {
        // console.log(devicesList);
        if (Object.keys(devicesList)?.length) {
            const firstKey = Object.keys(devicesList)[0];

            if (firstKey) {
                // Устанавливаем Высокочастотный датчик
                // if (hiSpeedSensors.filter((e) => e === devicesList[firstKey]?.attributes?.name).length) {
                //     // checkHiSpeed(true);
                // } else {
                //     // checkHiSpeed(false);
                // }

                // Проверка осциллографа
                if (devicesList[firstKey]?.attributes?.name === 'Осциллограф') {
                    checkOscilloscope(true);
                } else {
                    checkOscilloscope(false);
                }
            }

            if (!sensorList.length && connectionType === 'usb') {
                // Ускоряем первое подключение одиночного датчика
                let list = [];
                // const channels = devicesList[firstKey].getElementsByTagName('channels')[0];
                const {
                    attributes: { name, key, roundDigit, unit },
                    unicName,
                    single,
                } = devicesList[firstKey];

                const deviceKey = key + 0;
                // console.log(channels);

                if (single) {
                    list.push({
                        name,
                        quantity: '',
                        unit,
                        defaultUnit: unit,
                        roundDigit,
                        num: 0,
                        value: [],
                        sensNum: 0,
                        modes: [],
                        // graduable,
                        frequency: 10,
                        unicName,
                        deviceKey,
                        single,
                        markers: {},
                        inf: undefined,
                    });
                    setSensorList(list);
                }
            }
        }
    }, [devicesList]);

    useEffect(() => {
        if (!port || !sensor.length) return;
        const current = JSON.stringify(devicesList);
        const unicName = sensor.split(' ')[0];
        const currentName = unicName.split('-')[0];
        const readedSensor = sensor.split(' ')[1]?.charAt(0) === 't';
        // console.log(1, sensor);
        const responsName = responsesArray.filter((item) => item === currentName)[0];
        const isSensor = devices.filter((item) => item.attributes.key === currentName)[0];

        // console.log(devicesList);
        if (sensor.includes('usbChanged')) {
            // console.log(sensor);
            // Object.values(devicesList).forEach((device) => {
            //     // console.log(device);
            // });
            return;
        }

        if (responsName || graduate) {
            if (responsName === 'inf') {
                if (sensor.split(' ')[2] === '-1') {
                    sendCommand('inf key' + sensorList[sensorList.length - 1].deviceKey);
                } else {
                    try {
                        const inf = JSON.parse(sensor.split(' ')[1]);

                        setDevicesInf((i) => [...i, inf]);
                    } catch (err) {}
                }
            }
            setDeviceResponse(sensor.replace(/\r\n.*/, '').replace('  ', ' '));
            return;
        }

        const obj = JSON.parse(
            JSON.stringify({
                current,
                sensor,
                // fakePortOpen,
                frequency,
                timer,
                reading: sensorReading,
                isOscilloscope,
                isHiSpeed,
                played,
                paused,
                sensorsUnits,
                gradOffset,
                getSaved: false,
                idle: !played,
                abscissa,
                sensorList,
                devicesInf,
            })
        );

        if (isSensor && !waiting) {
            if (devicesCount != Object.keys(devicesList).length) {
                addNewDevice(currentName, unicName, isSensor);
                return;
            }
            if (forceUpdate) {
                worker?.postMessage(obj);
                setForceUpdate(false);
                return;
            }
            if (sensorReading || readedSensor) {
                worker?.postMessage(obj);
            } else {
                if (played && !paused) {
                    worker?.postMessage(obj);
                }
                if (!played) {
                    worker?.postMessage(obj);
                }
            }
        }
    }, [sensor, sensorsUnits, gradOffset]);

    const addNewDevice = (name, unic, sensor) => {
        // const checkDevice = devices.filter((device) => device.attributes.key === name)[0];
        // console.log(checkDevice);
        if (sensor) {
            const keys = Object.keys(devicesConnected);
            const deviceesKeys = Object.keys(devicesList);
            keys.every((key) => {
                if (deviceesKeys?.length) {
                    const checkKey = deviceesKeys.filter((item) => item === key)[0];
                    const checkSens = Object.values(devicesList).filter((item) => item.attributes.key === name)[0];
                    if (!checkKey && !checkSens) {
                        sensor.unicName = unic;
                        sensor.id = key;
                        addDevicesList({ key, data: sensor });
                        return false;
                    }
                } else {
                    sensor.unicName = unic;
                    sensor.id = key;
                    addDevicesList({ key, data: sensor });
                    return false;
                }
                return true;
            });
        }
    };

    const writeCommand = async (str) => {
        if (str === '') return;
        let comand = str;
        let wKey;
        // console.log(str);

        if (str.includes('key')) {
            const k = str.split(' key');
            wKey = k[k.length - 1];
            comand = k[0] + '\r\n';
        }
        try {
            if (connectionType === 'usb') {
                // for (const key in writer) {

                const write = isAndroid ? portData[0] : writer.usb0?.getWriter();
                if (isAndroid) {
                    setTimeout(() => {
                        write.writeSerial({ data: comand }).catch((err) => console.log(err));
                    }, 200);
                } else {
                    await write
                        ?.write(new TextEncoder().encode(comand))
                        .then((e) => {
                            // console.log('Write comand', comand);
                            // console.log('Write completed', e);
                            sendCommand('');
                        })
                        .catch((e) => {
                            // console.log('Write error', e);
                            setTimeout(() => {
                                // console.log('Resend comand error', comand);
                                sendCommand(comand);
                            }, 500);
                        });
                }
                if (!isAndroid) {
                    write?.releaseLock();
                }
                // }
            }
            if (connectionType === 'bt') {
                if (isAndroid) {
                    const data = stringToBytes(comand);
                    // console.log(comand);
                    // console.log(writer);

                    for (const key in devicesConnected) {
                        // console.log(writer[key]);
                        const service = writer[key]?.service;
                        const characteristic = writer[key]?.characteristic;
                        if (service && characteristic) {
                            setTimeout(() => {
                                BLE.write(
                                    key,
                                    service,
                                    characteristic,
                                    data,
                                    (e) => {
                                        console.log('write ble', e);
                                    },
                                    (err) => {
                                        console.log('write ble err', err);
                                    }
                                );
                            }, 200);
                        }
                    }

                    function stringToBytes(string) {
                        var array = new Uint8Array(string.length);
                        for (var i = 0, l = string.length; i < l; i++) {
                            array[i] = string.charCodeAt(i);
                        }
                        return array.buffer;
                    }
                } else {
                    for (const key of Object.keys(writer)) {
                        const wr = writer[key];
                        const {
                            device: { name, gatt },
                        } = wr.service;

                        if (gatt?.connected) {
                            if (wKey) {
                                // console.log(wKey, key);

                                wKey === key &&
                                    (await wr
                                        ?.writeValue(new TextEncoder().encode(comand))
                                        .then(() => {
                                            // console.log('Success send bt ', comand);
                                            sendCommand('');
                                        })
                                        .catch((e) => {
                                            // console.log(comand, e);
                                            if (e.message === 'GATT operation already in progress.') {
                                                // toast.warn('Не удалось прочитать данные. Переподключите устройство и попробуйте снова.');
                                                setTimeout(() => {
                                                    // console.log('Resend comand error', str);
                                                    sendCommand(str);
                                                }, 500);
                                            }
                                        }));
                            } else {
                                await wr
                                    ?.writeValue(new TextEncoder().encode(comand))
                                    .then(() => {
                                        // console.log('Success send bt comand');
                                        sendCommand('');
                                    })
                                    .catch((e) => {
                                        // console.log(comand, e);
                                        // resendComand(wr, comand);
                                        if (e.message === 'GATT operation already in progress.') {
                                            // toast.warn('Не удалось прочитать данные. Переподключите устройство и попробуйте снова.');
                                            setTimeout(() => {
                                                // console.log('Resend comand error', str);
                                                sendCommand(str);
                                            }, 500);
                                        }
                                    });
                            }
                        }
                    }
                }
            }
        } catch (e) {
            console.log(e.message);
            // !isAndroid && toast.warn('Ошибка отправки команды датчику');
            sendCommand(str);
        }
        if (demo.length) {
            // console.log(demoSettings);
            // console.log(str);
            demo.forEach((key) => {
                if (str.includes('rcc')) {
                    setDemoSettings({ key, value: { rcc: demoSettings[key].rcc } });
                    setDeviceResponse('rcc ' + demoSettings[key].rcc);
                }
                if (str.includes('wcc')) {
                    // console.log(str);
                    let wccData = str.split(' ').slice(1, -1).join(' ');
                    setDemoSettings({ key, value: { rcc: wccData } });
                    setDeviceResponse('rcc ' + wccData);
                }
            });
        }
    };

    return false;
};

export default Connection;
