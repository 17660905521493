import React, {useState, useEffect} from 'react';
import Modal from "../../Components/Modal";
import { Button, Input } from '../ui';
import { getDropdown } from "../ui/utils/gen_utils";
import {toast} from "react-toastify";
import {METHOD, getSwitcherHasLabel} from "./pi_utils";
import {useDispatch, useSelector} from "react-redux";
import {loadPIObjects} from "../../redux/slices/lists";
import {storageClass} from "../../redux/slices/storage";

const PIOneObjectPropDlg = ({showDlg, setShowDlg, handleAddProp}) => {
    const dispatch = useDispatch();
    const {lists: {pi}} = useSelector(state => state);
    const documentClass = useSelector(storageClass);

	const [propName, setPropName] = useState(""); //текущее значение свойства
	const [propNameMethod, setPropNameMethod] = useState(true);  //true - выбор type из списка, false - ввод нового type
	const [propNameId, setPropNameId] = useState(-1); //используется при выборе из списка
	const [uniquePropNames, setUniquePropNames] = useState([]); //список уникальных свойств//
	const [propValue, setPropValue] = useState('');

    useEffect(() => {
        dispatch(loadPIObjects(documentClass._id));
    }, [dispatch, documentClass._id]);

    useEffect(() => {
        if (!showDlg) return;
        setPropName('');
        setPropValue('');
        setPropNameId(-1);

        const list = [];
        list.push({label: 'Выберите свойство', value: -1});
        for (let [value, label] of Object.entries(pi.properties)) {
            list.push({label: label, value: value});
        }

        const names = list.sort((a, b) => a.label.localeCompare(b.label));
        setUniquePropNames(names);
    }, [showDlg, pi]);

    const handlePropMethod = (value) => {
        setPropNameMethod(value); //reset method
        setPropName(''); //initialize
    };

    const handlePropNameById = (id) => {
        setPropNameId(id);
        setPropName(uniquePropNames.find(item => item.value === id).label);
    };

    const handleSave = () => {
		const checkData = () => {
			let warning = undefined;
			if (propNameMethod === METHOD.LIST && propNameId === -1) warning = 'Выберите свойство из списка.';
			else if (propNameMethod === METHOD.MANUAL && !propName.trim()) warning = 'Введите название нового свойства.';
			else if (!propValue) warning = 'Введите значение свойства.';
			if (warning) toast.warn(warning);
			return !warning;
		};

        if (!checkData()) return;

        handleAddProp(propNameMethod, propNameId, propName, propValue);
        setShowDlg(false);
    };
    
    const setModalContent = () => {
        return (
            <>
                <div className="modal__close" onClick={() => setShowDlg(false)}></div>
                <div className="modal__message">Добавьте свойство объекта</div>
                <div className="modal__body">
                    <div className="cor-net__row">
                        <div className="cor-net__col col-grow">
                            <div className="cor-net__label">{getSwitcherHasLabel('Выбрать свойство из списка|Ввести новое свойство', propNameMethod, () => handlePropMethod(!propNameMethod))}</div>
                            {propNameMethod ?
                                getDropdown(uniquePropNames, propNameId, value => handlePropNameById(value), '', '', 'tp01')
                            :
                                <Input key={'inp11'}
                                    value={propName} 
                                    placeholder={'Введите название свойства'}
                                    onInput={e => setPropName(e.target.value)}
                            />}
                        </div>
                    </div> 
                    <div className="cor-net__row">
                        <div className="cor-net__col col-grow">
                            <div className="cor-net__label">Значение</div>
                            <Input 
                                value={propValue} 
                                onInput={e => setPropValue(e.target.value)} 
                                placeholder={'Введите значение свойства'}
                            />
                        </div>
                    </div>
                </div>
                <div className="modal__action">
                    <Button color="primary" border={false} onClick={() => handleSave()}>Сохранить</Button>
                    <Button color="primary" border={true} onClick={() => setShowDlg(false)}>Отменить</Button>
                </div>
            </>
        )
    }

    if (!showDlg) {
        return false;
    }
    
    return (
        <Modal visible={showDlg} content={setModalContent()} size={'md'} />
    )
};

export default PIOneObjectPropDlg;
