import { addChartDisabled, deleteChartDisabled } from '../../../actions/chart';

export const mapStateToProps = (state) => {
    return {
        port: state.port.open,
        sensorImported: state.sensors.imported,
        sensorsEnabled: state.sensors.enabled,
        sensorsDisabled: state.chart.disabled,

        devicesList: state.devices.list,
        isOscilloscope: state.oscilloscope.isOscilloscope,
        // deviceName: state.devices.device.name,
        // currentSensors: state.sensors.current,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    addChartDisabled: (num) => dispatch(addChartDisabled(num)),
    deleteChartDisabled: (num) => dispatch(deleteChartDisabled(num)),
});
