import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from './redux';
import TutorialSectionsG from "./TutorialSections";
import TutorialBoardGridG from "./TutorialBoardGrid";
import TutorialBoardEditG from "./TutorialBoardEdit";

const TutorialSections = connect(mapStateToProps,mapDispatchToProps)(TutorialSectionsG);
const TutorialBoardGrid = connect(mapStateToProps,mapDispatchToProps)(TutorialBoardGridG);
const TutorialBoardEdit = connect(mapStateToProps,mapDispatchToProps)(TutorialBoardEditG);

export { TutorialSections, TutorialBoardGrid, TutorialBoardEdit }
