import React, {useEffect, useState} from 'react';
import Modal from '../Modal';
import {useDispatch, useSelector} from "react-redux";
import {reloadClass, storageClass, storageOrg} from "../../redux/slices/storage";
import {AddclassApi, classApi} from "../../api/api";
import {fetchclass } from "../../redux/slices/classsline";
import {useFormik} from "formik";
import * as Yup from "yup";
import {toast} from "react-toastify";
import "./Settings.scss";
import socket from "../../socket";

const ClassSettings = (props) => {
    const dispatch = useDispatch();
    const documentOrg = useSelector(storageOrg);
    const documentClass = useSelector(storageClass);
    const [showConfirm, setShowConfirm] = useState(false);

    const deleteClass = async () => {
        try {
            await classApi.deleteClass(props.class._id);
            dispatch(fetchclass(documentOrg._id));
            handleClose();
        } catch (err) {
            console.log(err);
        }
    };

    const deleteCancel = () => setShowConfirm(false);
    const deleteConfirm = async () => {
        await deleteClass();
        setShowConfirm(false);
    };

    const handleClose = () => {
        formik.resetForm();
        props.showClassSettings({showModal: false});
    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) showConfirm ? deleteCancel() : handleClose();
        };
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showConfirm]);

    let error = {visible: false, message: ""};
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            className: props.class.className,
            classLevel: props.class.classLevel,
            classDescription: props.class.classDescription,
        }, validationSchema: Yup.object({
            className: Yup.string().required("Введите имя класса"),
            classLevel: props.isAdmin?Yup.number("Введите число").required("Введите количество мест"): null,
            classDescription: Yup.string().required("Введите описание класса"),
        }), onSubmit: async (formData) => {
            try {
                if (props.isAdd) {
                    const data = {
                        className: formData.className,
                        classLevel: formData.classLevel,
                        classDescription: formData.classDescription,
                        classOwner: props.owner,
                    };

                    await AddclassApi.addClass(data).then(() => {
                        dispatch(fetchclass(documentOrg._id));
                        handleClose();
                    }).catch((err) => {
                        toast.error(err.response.data.errors);
                    });
                } else {
                    const data = {
                        className: formData.className,
                        classDescription: formData.classDescription,
                    };
                    if (props.isAdmin) {
                        data.classLevel = formData.classLevel;
                    }

                    await AddclassApi.updateClass(props.class._id, data).then((updated) => {
                        dispatch(fetchclass(documentOrg._id));
                        if (documentClass._id === updated._id ) dispatch(reloadClass(updated));
                        socket.emit('BE-refresh-db', {roomId:updated._id, type:'class', id:updated._id});
                        handleClose();
                    }).catch((err) => {
                        toast.error(err.response.data.errors);
                    });
                }
            } catch (err) {
                error = {
                    visible: true, message: JSON.stringify(err.errors, null, 2),
                };
                console.log(error);
            }
        },
    });

    return (
        <>
            <Modal visible={props.showModal} content={
                <form onSubmit={formik.handleSubmit}>
                    <div className="modal__close" onClick={handleClose}/>
                    <div className="modal__message">{props.isAdd ? "Добавить" : "Редактировать"} класс</div>
                    <div className="modal__body">
                        <div key="name" className="cor_settings_vert">
                            <label htmlFor="name">Имя класса</label>
                            <div className="form_error">{formik.errors.className}</div>
                            <input
                                autoComplete="off"
                                placeholder="Имя класса"
                                className={formik.errors.className?"form_error":""}
                                name="className"
                                onChange={formik.handleChange}
                                value={formik.values.className}
                            />
                        </div>
                        {props.isAdmin &&
                        <div key="login" className="cor_settings_vert">
                            <label htmlFor="login">Количество мест</label>
                            <div className="form_error">{formik.errors.classLevel}</div>
                            <input
                                autoComplete="off"
                                placeholder="Количество мест"
                                className={formik.errors.classLevel?"form_error":""}
                                name="classLevel"
                                type="number"
                                onChange={formik.handleChange}
                                value={formik.values.classLevel}
                            />
                        </div>}
                        <div key="email" className="cor_settings_vert">
                            <label htmlFor="email">Описание класса</label>
                            <div className="form_error">{formik.errors.classDescription}</div>
                            <textarea
                                autoComplete="off"
                                placeholder="Описание класса"
                                name="classDescription"
                                rows="4"
                                className={formik.errors.classDescription?"form_error":""}
                                onChange={formik.handleChange}
                                value={formik.values.classDescription}
                            />
                        </div>
                    </div>
                    <div className="modal__action">
                        {props.allowDelete && <button
                            className="cor_btn cor_btn_danger"
                            type="button"
                            onClick={() => setShowConfirm(true)}
                        >Удалить</button>}
                        <div style={{width: "50%"}}/>
                        <button
                            className="cor_btn cor_btn_primary"
                            type="submit"
                        >{props.isAdd ? "Добавить" : "Сохранить"}</button>
                    </div>
                </form>
            }/>
            <Modal visible={showConfirm} content={
                <>
                    <div className="modal__message">Точно удалить?</div>
                    <div className="modal__action">
                        <button
                            className="cor_btn"
                            onClick={deleteCancel}
                        >Отменить
                        </button>
                        <button
                            className="cor_btn cor_btn_danger"
                            onClick={deleteConfirm}
                        >Удалить
                        </button>
                    </div>
                </>
            } size={'xs'}/>
        </>
    )
};

export default ClassSettings;