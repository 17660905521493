import './AddInput.scss';

const AddInput = ({
  newData,
  setNewData,
  placeholder,
  className,
  handleAdd,
}) => {
  return (
    <input
      autoFocus
      type="text"
      value={newData}
      onChange={(e) => setNewData(e.target.value)}
      onKeyDown={(event) => {
        if (event.key === 'Enter' && handleAdd) {
          handleAdd();
        }
      }}
      className={`add_input ${className}`}
      placeholder={placeholder}
    />
  );
};

export default AddInput;
