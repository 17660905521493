import React, { useState, useEffect } from 'react';
import {mapDispatchToProps, mapStateToProps} from "./redux";
import parse from 'html-react-parser';
import { HexColorPicker } from 'react-colorful';
import {connect} from "react-redux";
import { Dropdown, Range, Modal, Button } from 'rlabui';
import { Dropdown as DropdownColor } from 'rlabui';

const INTER = {
    bars: 'bars',
    linear: 'linear',
    basis: 'basis',
    bundle: 'bundle',
    cardinal: 'cardinal',
    catmull: 'catmull room',
    monotone: 'monotone',
    natural: 'natural',
    step: 'step',
    stepafter: 'step after',
    stepbefore: 'step before',
};

const ChartOptions = (props) => {
    const {modal, setModal, styles, setStyles} = props;
    const [newStyles, setNewStyles] = useState(styles);
    const [axe, setAxe] = useState(styles.axe);
    const [selected, setSelected] = useState(1);
    const [lineWeight, setLineWeight] = useState(styles[1].weight);
    const [lineColor, setLineColor] = useState(styles[1].color);
    const [pointWeight, setPointWeight] = useState(styles[1].point.width);
    const [pointColor, setPointColor] = useState(styles[1].point.color);
    const [interpolation, setInterpolation] = useState(styles[1].interpolation);

    const changeLineStyle = (save = false) => {
        const updated = [...newStyles];
        updated[selected] = {
            axe,
            weight: +lineWeight,
            color: lineColor,
            point: {
                width: pointWeight,
                color: pointColor,
            },
            interpolation,
        }
        setNewStyles(updated);
        if (save) setStyles(updated);
    };

    useEffect(() => {
        setAxe(styles[selected].axe);
        setLineWeight(styles[selected].weight);
        setLineColor(styles[selected].color);
        setPointWeight(styles[selected].point.width);
        setPointColor(styles[selected].point.color);
        setInterpolation(styles[selected].interpolation);
    }, [styles, selected]);

    return (
      modal && (
        <Modal visible={modal} size="sm">
            <Modal.Head modalClose={() => setModal(false)} title='Настройки отображения' />
            <Modal.Body>
                <div className="modalSettings__body">
                    <div className="modalSettings__col">
                        <div className="modalSettings__label">Выбор графика</div>
                        <Dropdown
                            clicked={true}
                            value={axe}
                        >
                            {styles.map((s, i) => (i > 0 &&
                                <Dropdown.Item
                                key={'line' + i}
                                onClick={() => {changeLineStyle();setSelected(i);}}>
                                    {s.axe}
                                </Dropdown.Item>
                            ))}
                        </Dropdown>
                    </div>
                    <div className="modalSettings__col">
                        <div className="modalSettings__label">Цвет линии</div>
                        <DropdownColor
                            className="notscroll colors"
                            clicked={true}
                            value={lineColor ? parse(`<span style="color: ` + lineColor + `"></span> ${lineColor}`) : 'Выберите цвет линии'}
                        >
                            <DropdownColor.Item>
                                <HexColorPicker
                                    color={lineColor}
                                    onChange={(color) => setLineColor(color)}
                                />
                            </DropdownColor.Item>
                        </DropdownColor>
                    </div>
                    <div className="modalSettings__col">
                        <div className="modalSettings__label">
                            Толщина линии
                            <span className="modalSettings__label_val">{lineWeight}px</span>
                        </div>
                        <div className="modalSettings__range">
                            <Range
                                min="0"
                                max="10"
                                step="1"
                                value={lineWeight}
                                onChange={({target}) => {
                                    const {value} = target;
                                    setLineWeight(+value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="modalSettings__col">
                        <div className="modalSettings__label">Цвет точек</div>
                        <DropdownColor
                            className="notscroll colors"
                            clicked={true}
                            value={pointColor ? parse(`<span style="color: ` + pointColor + `"></span> ${pointColor}`) : 'Выберите цвет точек'}
                        >
                            <DropdownColor.Item>
                                <HexColorPicker
                                    color={pointColor}
                                    onChange={(color) => setPointColor(color)}
                                />
                            </DropdownColor.Item>
                        </DropdownColor>
                    </div>
                    <div className="modalSettings__col">
                        <div
                            className="modalSettings__label">Толщина {interpolation === 'bars' ? 'столбцов' : 'точек'}
                            <span className="modalSettings__label_val">{pointWeight}px</span>
                        </div>
                        <div className="modalSettings__range">
                            <Range
                                min="0"
                                max="10"
                                step="1"
                                value={pointWeight}
                                onChange={({target}) => {
                                    const {value} = target;
                                    setPointWeight(+value);
                                }}
                            />
                        </div>
                    </div>
                    {
                        <div className="modalSettings__col">
                            <div className="modalSettings__label">Интерполяция</div>
                            <div className="modalSettings__range">
                                <Dropdown value={interpolation}
                                            clicked={true}>
                                    {Object.entries(INTER).map(([value, label]) => {
                                        return (
                                            <Dropdown.Item key={value} onClick={() => setInterpolation(value)}>
                                                {label}
                                            </Dropdown.Item>
                                        );
                                    })}
                                </Dropdown>
                            </div>
                        </div>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => changeLineStyle(true)}>Применить</Button>
                <Button onClick={() => setModal(false)} border={true}>Закрыть</Button>
            </Modal.Footer>
        </Modal>
      )
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartOptions);
