import React, { useState } from 'react';
import ss from '../AnalogDevice.module.scss';

const DigigtalHorizontal = ({ props: { theme, color, size, value, max, min, avg, roundDigit, unit } }) => {
    const [valSize] = useState(size * 0.0045 > 1.5 ? 1.5 + 'em' : size * 0.0045 + 'em');

    return (
        <div
            className={ss.digigtalH}
            style={{ height: size * 0.92 }}
        >
            <div
                className={ss.digigtalH__gauge}
                style={{ fontSize: size > 300 ? size / 300 + 'em' : '1em', borderColor: color }}
            >
                <div
                    className={ss.digigtalH__val}
                    style={{ fontSize: valSize }}
                >
                    {(value || value === 0 ? value : min).toFixed(roundDigit)} {unit}
                </div>
                <div className={ss.digigtalH__avg}>
                    Среднее значение {avg?.toFixed(roundDigit)} {unit}
                </div>
            </div>
        </div>
    );
};
export default DigigtalHorizontal;
