import React, { useState } from 'react';
import "./Switcher.scss";

const Switcher = ({onChange, checked = false, disabled = false, className = "", id}) => {
    const [isCheck, toggleCheck] = useState(checked);
    return (
        <input
            type="checkbox"
            className={"cor_switcher " + className}
            onChange={onChange}
            checked={checked}
            disabled={disabled}
            id={id}
            onClick={() => toggleCheck(!isCheck)}
        />
    )
};
export default Switcher;
