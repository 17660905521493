import React from 'react';
import { Button, Modal } from 'rlabui';

const ConfirmDlg = (props) => {
    const { showConfirmDlg, handleNo, question, handleYes } = props;

    return (
        <Modal
            className="print"
            visible={showConfirmDlg}
            size={'xs'}
        >
            <Modal.Head
                modalClose={handleNo}
                title={question}
            ></Modal.Head>
            <Modal.Footer>
                <Button onClick={handleYes}>Да</Button>
                <Button
                    border={true}
                    onClick={handleNo}
                >
                    Нет
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default ConfirmDlg;
