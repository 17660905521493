import { setPaused, setPlay } from '../../actions/actions';
import { setTimer, setTimerSettings, setStopwatch } from '../../actions/timer';

export const mapStateToProps = (state) => {
    return {
        played: state.actions.played,
        paused: state.actions.paused,
        stopwatch: state.timer.stopwatch,
        settings: state.timer.settings,
        updateTime: state.sensors.item,
        isHiSpeed: state.sensors.isHiSpeed,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setPlay: (bool) => dispatch(setPlay(bool)),
    setPaused: (bool) => dispatch(setPaused(bool)),
    setTimer: (miliseconds) => dispatch(setTimer(miliseconds)),
    setStopwatch: (miliseconds) => dispatch(setStopwatch(miliseconds)),
    setTimerSettings: (format) => dispatch(setTimerSettings(format)),
});
