import React, {useState, useEffect} from 'react';
import Modal from "../../../Components/Modal";

const ModalInputDialog = ({ showDlg, setShowDlg, title, comment, commentInd, handleSaveText}) => {
    const [text, setText] = useState('');

    useEffect(() => {
        setText(comment);
    }, [comment]);

    const confirmYes = () => {
        handleSaveText(text.trim(), commentInd);
        setShowDlg(false);        
    };

    const handleNo = () => {
        setShowDlg(false);        
    };

    const setModalContent = () => {
        return (
            <div className="print">
                <div className="modal__close" onClick={handleNo}></div>
                <div className="">{title}</div>
                <div className="">
	    		    <input className="" value={text} onChange={e => setText(e.target.value)} />
                </div>

                <div className="modal__action">
                    <button className="btn" onClick={confirmYes}>Сохранить</button>
                    <button className="btn btn_border" onClick={handleNo}>Отменить</button>
                </div>
            </div>
        )
    }   

    if (!showDlg) {
        return null;
    }

    return (
        <Modal visible={showDlg} content={setModalContent()} size={'md'} />
    )
};

export default ModalInputDialog;
