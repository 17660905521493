const initialState = {
    modal: null,
    fileExtension: null,
    data: null,
};

const exportReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_NEXPORT_MODAL':
            return {
                ...state,
                modal: action.payload,
            };

        case 'SET_NFILE_EXTENSION':
            return {
                ...state,
                fileExtension: action.payload,
            };

        case 'SET_NEXPORT_DATA':
            return {
                ...state,
                data: action.payload,
            };

        default:
            return state;
    }
};

export default exportReducer;
