import React from 'react';
import {Modal, Button} from 'rlabui';

const ModalCongratsDlg = (props) => {
    const { showModal, handleLeaveLabWork, workType, isComplete } = props;

    const getMsg = () => {
        let msg;
        if (isComplete) {
            msg = workType === 0 ? 'Поздравляем! Вы завершили онлайн тест.' : 
                'Поздравляем! Вы завершили лабораторную работу.';
        } else {
            msg = workType === 0 ? 'К сожалению, время выполнения онлайн теста закончилось. Ваши результаты сохранены.' : 
                'К сожалению, время выполнения лабораторной работы закончилось. Ваши результаты сохранены.';
        }
        return msg;
    };

    if (!showModal) {
        return false;
    }

    return (
        <Modal visible={showModal} size="xs">
            <Modal.Head modalClose={handleLeaveLabWork} title={getMsg()} />
            <Modal.Body>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleLeaveLabWork}>Закрыть</Button>
            </Modal.Footer>
        </Modal>
    )
};

export default ModalCongratsDlg;
