export const setChartType = (chart, styles, index) => {
    if (!chart.data.datasets.length) return;

    let current = chart.options.elements.line;
    if (index !== undefined) {
        current = chart.data.datasets[index];
    }

    if (styles) {
        switch (styles.type) {
            case 'solid':
                current.borderDash = [];
                current.borderDashOffset = 0;
                break;
            case 'dashed':
                current.borderDash = [styles.weight*5,styles.weight*5];
                current.borderDashOffset = 0;
                break;
            case 'dotted':
                current.borderDash = [styles.weight,styles.weight];
                current.borderDashOffset = styles.weight;
                break;
            case 'dotteddash':
                current.borderDash = [styles.weight*5,styles.weight,styles.weight,styles.weight];
                current.borderDashOffset = 0;
                break;
            case 'dotteddash2':
                current.borderDash = [styles.weight*5,styles.weight,styles.weight,styles.weight,styles.weight,styles.weight];
                current.borderDashOffset = 0;
                break;
            default:
        }
    }
}
