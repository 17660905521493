import React from 'react';
import "../Content.scss";

const ContentBody = ({
    children,
    className = '',
    scrolled = true,
    padding = true,
    id = '',
    // ref = ''
}) => {
    let contentBodyClass = 'cor_content__body';
    if (!padding) {
        contentBodyClass += ' p_0'
    }
    if (className) {
        contentBodyClass += " " + className;
    }
    return (
        <div 
            className={contentBodyClass} 
            // bsStyle={id ? 'success' : undefined}
            {...(id ? {id: id} : {})}
            // {...(ref ? {ref: ref} : {})}
            >
            {
            scrolled ?
                <div className="cor_content__scrolled">
                    { children }
                </div> 
                :
                <>
                    { children }
                </>
                
            }
        </div>
    )
};
export default ContentBody;
