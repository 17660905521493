import React from 'react';
import "../Content.scss";

const ContentSubHeader = ({children, className = ''}) => {
    return (
        <div className={"cor_content__subheader " + className}>
            { children }
        </div>
    )
};
export default ContentSubHeader;
