import { setChartMarkerDeleteAll, setChartView, clearChartMarkerModal, toggleChartParametric, setChartMarkers } from '../../actions/chart';
import { setScenarioId, loadScenario, setScenarioInfo, setGraphFile } from '../../actions/scenario';
import { setSensorRecords } from '../../actions/sensors';

export const listStateToProps = (state) => {
    return {
        port: state.port.open,
        sensorList: state.sensors.list,
        sensorWrited: state.sensors.writed,
        sensorImported: state.sensors.imported,
        sensorsBundles: state.sensors.bundles,
        sensorsUnits: state.sensors.units,
        sorting: state.sensors.sorting,

        chartMarkers: state.chart.marker,
        deleteAllMarkers: state.chart.markers.deleteAll,

        clearModal: state.chart.markers.clearModal,

        view: state.chart.settings.view,
        chartDisabled: state.chart.disabled,

        sensorReading: state.reader.sensorReading,

        played: state.actions.played,
        isMobile: state.actions.isMobile,
        mobileView: state.actions.mobileView,
        parametricMode: state.chart.parametric,
        lissague: state.chart.lissague,

        isCor: state.actions.isCor,
        scenarioList: state.scenario.list,
        scenarioId: state.scenario.scenarioId,
        scenario: state.scenario.scenario,
        scenarioInfo: state.scenario.scenarioInfo,
        infoId: state.scenario.infoId,
    };
};

export const listDispatchToProps = (dispatch) => ({
    setChartView: (str) => dispatch(setChartView(str)),
    setChartMarkerDeleteAll: (bool) => dispatch(setChartMarkerDeleteAll(bool)),
    clearChartMarkerModal: (bool) => dispatch(clearChartMarkerModal(bool)),
    toggleChartParametric: (list) => dispatch(toggleChartParametric(list)),
    setChartMarkers: (list) => dispatch(setChartMarkers(list)),
    setScenarioId: (id) => dispatch(setScenarioId(id)),
    loadScenario: () => dispatch(loadScenario()),
    setScenarioInfo: (info) => dispatch(setScenarioInfo(info)),
    setGraphFile: (info) => dispatch(setGraphFile(info)),
    setSensorRecords: (arr) => dispatch(setSensorRecords(arr)),
});
