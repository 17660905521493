// import {  } from '../../actions/';

export const mapStateToProps = (nstate) => {
    return {
        data: nstate.nsensor.data.ABPMeter,
        timer: nstate.ntimer.timer,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    // setPlay: (bool) => dispatch(setPlay(bool)),
});
