import React, {useState, useEffect} from 'react';
import Modal from "../../Components/Modal";
import {Button} from '../ui/';
import {isAuth} from "../../helpers/auth";
import {InputLimit} from '../ui';
import {getSubjectRPNameById, subjName} from './nb_utils';
import {toast} from "react-toastify";
import { Input } from '../ui';
import "../OnlineTest/OnlineTestCreate.scss";

const MAX_NAME_LENGTH = 30;
const MAX_ITEM_LENGTH = 60;

const NotebookSelectHomeWorkDlg = ({showModal, subjectId, initName, initItem,
        doCancelDlg, doSetShowSelectHomeWorkDlg}) => {
    const [homeWorkName, setHomeWorkName] = useState('');
    const [homeWorkItem, setHomeWorkItem] = useState('');
    const [isNew, setIsNew] = useState(true);

    useEffect(() => {
        setHomeWorkName(initName);
        setHomeWorkItem(initItem);
        setIsNew(!initName);
	}, [initName, initItem]);

    const handleSelectedSubjectInfo = () => {
        if (!homeWorkName.trim()) 
            toast.warn("Введите название."); 
        else if (!homeWorkItem.trim()) 
            toast.warn("Введите тему."); 
        else
            doSetShowSelectHomeWorkDlg(homeWorkName, homeWorkItem);
    };
    
	const handleChangeName = (value) => { 
        if (value.length <= MAX_NAME_LENGTH)
		    setHomeWorkName(value);
	};
    
	const handleChangeItem = (value) => { 
        if (value.length <= MAX_ITEM_LENGTH)
		    setHomeWorkItem(value);
	};

    const setModalContent = () => {
        const rpName = !isNaN(subjectId) ? getSubjectRPNameById(subjectId) : '';

        return (
            <div className="modal__content">
                <div className="modal__close" onClick={doCancelDlg}></div>
                <div className="modal__message">
                    {isNew ? 
                        'Создание ' + (isAuth().role < 3 ? "задания" : "шаблона") + ' по ' + rpName
                        :
                        'Копирование задания'
                    }
                </div>

                <div className="modal__body">
                    <div className="modal__row">
                        <div className="modal__label">Название задания</div>
                        <InputLimit
                            value={homeWorkName} 
                            placeholder={'Пример названия задания: Работа по ' + rpName}
                            onInput={e => handleChangeName(e.target.value)} 
                            max={MAX_NAME_LENGTH}
				    	/>
                    </div>

                    <div className="modal__row">
                        <div className="modal__label">Тема задания</div>
                        <InputLimit
                            value={homeWorkItem} 
                            placeholder={'Пример названия темы: Линейные уравнения'}
                            onInput={e => handleChangeItem(e.target.value)} 
                            max={MAX_ITEM_LENGTH}
				    	/>
                    </div>
                </div>

                <div className="modal__action">
                    <Button color="primary" size = "extraSmall" border={true} onClick={doCancelDlg}>
                        Отменить
                    </Button>
                    <Button color="primary" size = "extraSmall" onClick={handleSelectedSubjectInfo} >
                        {(isNew ? 'Создать ' : 'Сохранить ') + subjName()}
                    </Button>
                </div>
            </div>
        )
    }

    if (!showModal) return false;

    return (
        <Modal visible={showModal} content={setModalContent()} size={'md'} />
    )
};

export default NotebookSelectHomeWorkDlg;
