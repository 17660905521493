import React, { useEffect, useRef } from 'react';
import './CheckInternet.scss';

const CheckInternet = ({ devices, getDevicesList }) => {
    const interval = useRef(null);

    useEffect(() => {
        if (devices.length) {
            interval && clearInterval(interval.current);
        } else {
            interval.current = setInterval(() => {
                getDevicesList();
            }, 2000);
        }
        return () => clearInterval(interval.current);
    }, [devices, getDevicesList]);

    return <div className="checkInternet">Для работы приложения требуется подключение к интернету</div>;
};
export default CheckInternet;
