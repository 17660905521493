import React, { useEffect, useState, memo, useRef } from 'react';
import { Button, Dropdown, Modal, Checkbox } from 'rlabui';
import { Watch } from 'react-loader-spinner';
import ss from './SensorsSettings.module.scss';

const SensorsSettings = (props) => {
    const { port, modal, setSensorsModal, devicesList, deviceResponse, setDeviceResponse, setSensorReading, isReading, sendCommand, toggleWritter, writerSelected, demo, demoSettings } = props;
    const [allSensors, setAllSensors] = useState([]);
    const [isSingle, setSingle] = useState(false);
    const [enabledList, setEnabledList] = useState([]);
    const sendRef = useRef();

    useEffect(() => {
        if (!port) {
            setAllSensors([]);
            setSingle(false);
        }
    }, [port]);

    useEffect(() => {
        if (modal) {
            if (writerSelected) {
                sendCommand('rcc key' + writerSelected);
            } else {
                const keys = Object.keys(devicesList);

                if (keys.length) {
                    toggleWritter(keys[0]);
                }
            }
            // console.log('upd', writerSelected);
            if (devicesList[writerSelected]?.single) {
                setSingle(true);
                setAllSensors(devicesList[writerSelected]);
            } else {
                setAllSensors(devicesList[writerSelected]?.getElementsByTagName('channels')[0].children);
            }
        }
    }, [modal, devicesList.length, sendCommand, writerSelected]);

    useEffect(() => {
        if (modal && deviceResponse.length) {
            // console.log('deviceResponse', deviceResponse);
            const str = deviceResponse.replace(/\r\n.*/, '');
            const resp = str.split(' ').filter((i) => i);
            const commands = ['rlg', 'wlg', 'rcc', 'wcc'];
            const currentComand = commands.filter((e) => deviceResponse.includes(e))[0];

            if (resp?.length && currentComand) {
                switch (currentComand) {
                    case 'rlg':
                        if (isReading && str === 'wcc error 0') {
                            setSensorReading(false);
                        }
                        if (resp[1] === 'error') {
                            setSensorReading(false);
                        }
                        break;
                    case 'wlg': // запись измерений
                        break;
                    case 'rcc': // запись измерений
                        const channels = resp.slice(1).map(Number);
                        // console.log(resp.join(' '));

                        for (const key in devicesList) {
                            const sens = devicesList[key]?.getElementsByTagName('channels')[0]?.children;
                            // console.log(sens);
                            // console.log(channels);
                            if (key === writerSelected && channels?.length === sens?.length) {
                                setAllSensors(sens);
                                setEnabledList(channels);
                            }
                        }
                        break;
                    case 'wcc': // запись измерений
                        // console.log(str);
                        if (str.includes('wcc error 0')) {
                            sendCommand('rcc key' + writerSelected);
                        }
                        break;
                    default:
                }
                setDeviceResponse('');
            }
        }
    }, [deviceResponse, modal, devicesList, isReading, sendCommand, setDeviceResponse, setSensorReading, writerSelected]);

    const changeEnabledState = (index) => {
        clearTimeout(sendRef.current);
        const arr = [...enabledList];
        arr.forEach((item, i) => {
            if (index === i) {
                arr[i] = item ? 0 : 1;
            }
        });

        const str = 'wcc ' + arr.join(' ');

        setEnabledList(arr);
        sendRef.current = setTimeout(() => sendCommand(str + ' key' + writerSelected), 500);
    };

    const renderAllSensors = () => {
        // console.log(allSensors);
        if (isSingle) {
            return (
                <div className={ss.list + ' scrolledBox'}>
                    <ul>
                        <li>
                            <Checkbox
                                label={allSensors?.attributes.name}
                                disabled={isSingle}
                                checked={true}
                            />
                        </li>
                    </ul>
                </div>
            );
        } else {
            return (
                <div className={ss.list + ' scrolledBox'}>
                    <ul>
                        {modal &&
                            allSensors?.map((item, i) => {
                                const enabled = enabledList.filter((el, k) => k === i);
                                return (
                                    <li key={i}>
                                        <Checkbox
                                            label={item.attributes.name}
                                            checked={isSingle ? true : enabled[0]}
                                            disabled={isSingle}
                                            onChange={() => {
                                                // console.log(i);
                                                changeEnabledState(i);
                                            }}
                                        />
                                    </li>
                                );
                            })}
                    </ul>
                </div>
            );
        }
    };

    const renderDevicesList = () => {
        return modal && (Object.keys(enabledList).length || isSingle) ? (
            <>
                <div className={ss.head}>
                    <Dropdown value={devicesList[writerSelected]?.attributes?.name}>
                        {Object.keys(devicesList).map((key) => {
                            const { name } = devicesList[key]?.attributes;
                            return (
                                <Dropdown.Item
                                    key={key}
                                    onClick={() => toggleWritter(key)}
                                >
                                    {name}
                                </Dropdown.Item>
                            );
                        })}
                    </Dropdown>
                </div>
                <div className={ss.content}>{renderAllSensors()}</div>
            </>
        ) : (
            <div>
                {port ? (
                    <div className={ss.loader}>
                        <Watch
                            height="30"
                            width="30"
                            radius="36"
                            color="#3FA9F5"
                            ariaLabel="watch-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                ) : (
                    'Нет подключенных устройств'
                )}
            </div>
        );
    };

    return (
        <Modal visible={modal}>
            <Modal.Head
                title="Установка каналов датчика"
                modalClose={() => setSensorsModal(false)}
            ></Modal.Head>
            <Modal.Body>{renderDevicesList()}</Modal.Body>
            <Modal.Footer align="left">
                <Button
                    border={true}
                    onClick={() => setSensorsModal(false)}
                >
                    Закрыть
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default memo(SensorsSettings);
