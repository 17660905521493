import { setSensorVisualized } from '../../../actions/sensor';

export const mapStateToProps = (nstate) => {
    return {
        sensors: nstate.nsensor.list,
        imported: nstate.nsensor.imported,
        visualized: nstate.nsensor.visualized,
        disabled: nstate.nchart.disabled,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setSensorVisualized: (key) => dispatch(setSensorVisualized(key)),
});
