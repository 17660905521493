import React, { useState, useEffect } from 'react';
import Chart from '../Chart';
import { Dropdown, Modal, Button } from 'rlabui';
import SignalMode from '../template/SignalMode';
import ss from './Charts.module.scss';

const chartsView = [
    { label: 'Все графики', value: 'all' },
    { label: 'Один график', value: 'one' },
    { label: 'Два графика', value: 'two' },
    { label: 'Три графика', value: 'three' },
    { label: 'Четыре графика', value: 'four' },
];

const Charts = (props) => {
    const {
        view,
        mobileView,
        visualized,
        isMobile,
        sensorList,
        sensorChannels,
        sensorImported,
        clearChartMarkerModal,
        clearModal,
        chartDisabled,
        setChartView,
        setChartMarkerDeleteAll,
        // setSensorRecords,
        setSensorVisualized,
    } = props;

    const [currentView, setCurrentView] = useState(view);
    const [sensors, setSensors] = useState([]);

    useEffect(() => {
        if (visualized) {
            if (sensorList.length > 1 || sensorImported.length) {
                try {
                    const list = [...sensorList, ...sensorImported].filter((e) => e.key.includes(visualized) && !e.key.includes('Button'))[0];
                    const channels = list?.channels || [];

                    const all = [list, ...channels]?.filter((e) => !chartDisabled.includes(e.key));
                    setSensors(all);
                } catch (err) {
                    setSensorVisualized();
                }
            }
        } else {
            const all = [...sensorImported, ...sensorList]?.filter((e) => !chartDisabled.includes(e.key) && !e.key.includes('Button'));
            setSensors(all);
        }
    }, [sensorList, sensorChannels, sensorImported, visualized, view, chartDisabled, setSensorVisualized]);

    useEffect(() => {
        if (visualized) {
            setChartView('all');
        } else {
            setChartView(currentView);
        }
    }, [visualized, currentView, setChartView]);

    useEffect(() => {
        if (!clearModal) {
            setChartMarkerDeleteAll(false);
        }
    }, [clearModal, setChartMarkerDeleteAll]);

    const swichChartView = (value) => {
        setChartView(value);
        !visualized && setCurrentView(value);
    };

    const renderHeader = () => {
        return (
            sensors.length > 0 &&
            !isMobile && (
                <div className={ss.head}>
                    <div className={ss.title}>{visualized ? sensors[0].quantity : 'Графики значений с датчиков'}</div>
                    <div className={ss.settings}>
                        <SignalMode />
                        <label>Режим отображения</label>
                        <div>
                            <Dropdown
                                checkbox={false}
                                value={chartsView.filter((item) => item.value === view)[0]?.label}
                                dropPosition="right"
                            >
                                {chartsView.map(({ label, value }, i) => (
                                    <Dropdown.Item
                                        key={i}
                                        className={value === view ? 'selected' : ''}
                                        onClick={() => swichChartView(value)}
                                    >
                                        {label}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown>
                        </div>
                    </div>
                </div>
            )
        );
    };

    return (
        <>
            <div className={isMobile ? (mobileView === 'chart' ? ss.wrap : ss.wrap + ' ' + ss.wrapCollapsed) : ss.wrap}>
                {renderHeader()}
                <div className={`${ss.root} ${ss[view]} ${sensors.length ? ss['items' + sensors.length] : ''}`}>
                    {sensors?.map((item, i) => (
                        <Chart
                            index={i}
                            key={item.imported ? item.key + i : item.key}
                            sensor={item}
                        />
                    ))}
                </div>
            </div>
            <Modal
                visible={clearModal}
                size={'xs'}
            >
                <Modal.Head
                    title=""
                    modalClose={() => {
                        setChartMarkerDeleteAll(false);
                        clearChartMarkerModal(false);
                    }}
                />
                <Modal.Body>Вы действительно хотите удалить все метки?</Modal.Body>
                <Modal.Footer>
                    <Button
                        className="btn"
                        onClick={() => {
                            setChartMarkerDeleteAll(true);
                            clearChartMarkerModal(false);
                        }}
                    >
                        Удалить
                    </Button>
                    <Button
                        className="btn btn_border"
                        onClick={() => {
                            setChartMarkerDeleteAll(false);
                            clearChartMarkerModal(false);
                        }}
                    >
                        Отмена
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Charts;
