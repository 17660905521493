import React, {useEffect} from "react";
import {Tabs} from "../ui";
import PIShowUnits from './PIShowUnits';
import PIShowLinks from './PIShowLinks';
import PIShowFundamentConsts from './PIShowFundamentConsts';
import PIShowSiPrefixes from './PIShowSiPrefixes';
import {Content, ContentHeader} from "../template/ContentParts";
import "../CalcModels/CalcModel.scss";

const tabs = {
  units: 'Единицы измерений',
  links: 'Связь между единицами',
  const: 'Фундаментальные постоянные',
  si: 'Приставки СИ',
};

const PInvestUnits = (props) => {
  const selectedTab = props.location.state?.tab ?? 'units';

  const handleSelectTab = (ind) => props.history.push({pathname: '/pi/unit', state: {tab: ind}});

	return (
    <Content>
      <ContentHeader>
        <Tabs>
          {Object.entries(tabs).map(([id, name]) =>
            <Tabs.Item key={id} active={selectedTab === id} onClick={() => handleSelectTab(id)}>{name}</Tabs.Item>
          )}
        </Tabs>
      </ContentHeader>
      {{
        units: <PIShowUnits {...props} />,
        links: <PIShowLinks {...props} />,
        const: <PIShowFundamentConsts {...props} />,
        si: <PIShowSiPrefixes {...props} />,
      }[selectedTab]}
    </Content>
  )
}

export default PInvestUnits;