import React from 'react';
import { ReactComponent as AddCardButton } from '../../../assets/icons/add-card-button.svg';
import './StickersButton.scss';

const StickersButton = ({ onShowStickers, title, children, icon }) => {
  return (
    <button className="stickers__button" onClick={onShowStickers}>
      {icon ? icon : <AddCardButton className="add_button__svg" />}
      <span className="stickers__title">{title}</span>
      {children}
    </button>
  );
};

export default StickersButton;
