import { getRelativePositionLeft, getXValue, getXPosition } from '../utils';

let markerId = null,
    isBox = false;

export const annotationsPlugin = () => {
    return {
        hooks: {
            ready: (u) => {
                let {
                    annotations: { hide },
                    over,
                    root,
                } = u;

                // Добавление метки по готовности графика
                !hide && drawRange(u);

                // Обработка событий клика по графику
                root.addEventListener('click', (e) => {
                    const { target } = e;
                    const {
                        annotations: {
                            markers,
                            markerActions: { add, del },
                            setMarkers,
                            hide,
                        },
                        key,
                        isChildren,
                    } = u;

                    if (isChildren && hide) return false;

                    const noMarkers = !markers || Object.values(markers)?.length < 2;
                    if (e.button === 0) {
                        const xpos = getRelativePositionLeft(u, e);

                        if (noMarkers && add) {
                            console.log('add marker');
                            setNewRange(u, xpos);
                        }
                        if (!noMarkers && del) {
                            if (target.classList.contains('u-marker-x') || target.classList.contains('u-range-x')) {
                                setMarkers({ key, data: {} });
                            }
                        }
                    }
                });

                // Начинаем перетаскивание если нажали на маркер или диапазон
                over.addEventListener('mousedown', (e) => {
                    const {
                        annotations: { hide },
                        isChildren,
                    } = u;
                    if (isChildren && hide) return false;
                    const { target, button } = e;
                    if (button === 0) {
                        if (!isBox && target.closest('.u-marker-x')) {
                            const key = target.closest('.u-marker-x').id;
                            markerId = key;
                            target.style.cursor = 'grabbing';
                            document.body.style.cursor = 'grabbing';
                        }
                        if (!markerId && target.closest('.u-range-x')) {
                            isBox = true;
                            target.style.cursor = 'grabbing';
                            document.body.style.cursor = 'grabbing';
                        }
                    }
                });

                // Отменяем перетаскивание диапазона и сохраняем его
                over.addEventListener('mouseup', (e) => {
                    const {
                        annotations: { hide, markers },
                        isChildren,
                    } = u;
                    if (isChildren && hide) return false;
                    const { button } = e;
                    if (button === 0) {
                        if (markerId || isBox) {
                            const box = markers['box1'];
                            saveRange(u, box.min, box.max);
                            over.querySelector('.u-marker-x-start').style.cursor = '';
                            over.querySelector('.u-marker-x-end').style.cursor = '';
                            over.querySelector('#box1').style.cursor = '';
                        }
                        markerId = null;
                        isBox = false;
                        document.body.style.cursor = '';
                    }
                });

                over.addEventListener('mouseleave', (e) => {
                    const {
                        annotations: { hide, markers },
                        isChildren,
                    } = u;
                    if (isChildren && hide) return false;
                    if (e.button === 0) {
                        if (markerId || isBox) {
                            const box = markers['box1'];
                            saveRange(u, box.min, box.max);
                            over.querySelector('.u-marker-x-start').style.cursor = '';
                            over.querySelector('.u-marker-x-end').style.cursor = '';
                            over.querySelector('#box1').style.cursor = '';
                        }
                        markerId = null;
                        isBox = false;
                        document.body.style.cursor = '';
                    }
                });

                // Перетаскивание маркера/диапазона
                over.addEventListener('mousemove', (e) => {
                    const {
                        annotations: { markers },
                        isChildren,
                    } = u;

                    const xpos = getRelativePositionLeft(u, e);

                    if (isChildren) return false;

                    if (e.button === 0) {
                        if (markerId || isBox) {
                            const currentVals = Object.values(markers)
                                .filter((m) => m.resizer)
                                .map((m) => m.value)
                                .sort((a, b) => a - b);

                            if (markerId?.length) {
                                const newValue = getXValue(xpos, u);

                                if (markerId?.includes('start')) {
                                    if (Math.abs(newValue - currentVals[1]) < 100) {
                                        markerId = null;
                                    } else {
                                        requestAnimationFrame((e) => draw(u, [newValue, currentVals[1]]));
                                    }
                                }

                                if (markerId?.includes('end')) {
                                    if (Math.abs(currentVals[0] - newValue) < 100) {
                                        markerId = null;
                                    } else {
                                        requestAnimationFrame((e) => draw(u, [currentVals[0], newValue]));
                                    }
                                }
                            }
                            if (isBox) {
                                const range = (currentVals[1] - currentVals[0]) / 2;
                                const center = getXValue(xpos, u);
                                draw(u, [center - range, center + range]);
                            }
                        }
                    }
                });
            },
        },
    };
};

export function drawRange(u) {
    if (!u) return;

    const {
        annotations: { markers, hide },
    } = u;

    if (hide) return;

    if (!markers || Object.values(markers)?.length < 2) {
        deleteRange(u);
    } else {
        const values = Object.values(markers)
            .filter((m) => m.resizer)
            .map((m) => m.value)
            .sort((a, b) => a - b);

        draw(u, values);
    }
}

const setNewRange = (u, xpos) => {
    const start = getXValue(xpos - 100, u);
    const end = getXValue(xpos + 100, u);

    draw(u, [start, end]);
    saveRange(u, start, end);
};

function saveRange(u, start, end) {
    const {
        key,
        annotations: { setMarkers, markers },
        data: series,
        isChildren,
    } = u;

    if (series?.length > 1 && !isChildren) {
        const copy = { ...markers };

        const times = series[0];
        const values = series[1];
        const minIndex = times.findIndex((e) => e >= start);
        const maxIndex = times.findLastIndex((e) => e <= end);

        const startValue = values[minIndex > 0 ? minIndex : 0];
        const endValue = values[maxIndex > 0 ? maxIndex : values.length - 1];

        copy['box1start'] = {
            type: 'line',
            id: 'box1start',
            label: start,
            yValue: startValue,
            value: start,
            resizer: true,
        };

        copy['box1end'] = {
            type: 'line',
            id: 'box1end',
            label: end,
            yValue: endValue,
            value: end,
            resizer: true,
        };

        copy['box1'] = {
            type: 'box',
            id: 'box1',
            yMin: startValue,
            yMax: endValue,
            min: start,
            max: end,
        };

        setMarkers({ key, data: copy });
    }
}

function draw(u, values) {
    const {
        over,
        annotations: { markers },
    } = u;

    let start, end, box, startValue, endValue, startId, endId, boxId;

    const resizers = over.querySelectorAll('.u-marker-x');
    if (resizers.length) {
        start = over.querySelector('.u-marker-x-start');
        end = over.querySelector('.u-marker-x-end');
        startId = start.id;
        endId = end.id;
    }

    startValue = Math.round(values[0]);
    endValue = Math.round(values[1]);
    box = over.querySelector('.u-range-x');

    if (startValue && endValue) {
        if (box) {
            boxId = box.id;
        }

        if (!start) {
            start = document.createElement('div');
            start.classList.add('u-marker-x');
            start.classList.add('u-marker-x-start');
            start.id = `box1start`;
            over.appendChild(start);
        }

        if (!end) {
            end = document.createElement('div');
            end.classList.add('u-marker-x');
            end.classList.add('u-marker-x-end');
            end.id = `box1end`;
            over.appendChild(end);
        }

        if (!box) {
            box = document.createElement('div');
            box.classList.add('u-range-x');
            box.id = 'box1';
            over.appendChild(box);
        }

        if (markers[startId]) {
            markers[startId].value = startValue;
            markers[startId].label = startValue;
        }

        if (markers[endId]) {
            markers[endId].value = endValue;
            markers[endId].label = endValue;
        }

        if (markers[boxId]) {
            markers[boxId].min = startValue;
            markers[boxId].max = endValue;
            markers[boxId].yMin = u.posToVal(getXPosition(startValue, u), 'y');
            markers[boxId].yMax = u.posToVal(getXPosition(endValue, u), 'y');
        }

        end.style.left = `${getXPosition(endValue, u)}px`;
        start.style.left = `${getXPosition(startValue, u)}px`;
        box.style.left = `${getXPosition(startValue, u) + 1}px`;
        box.style.width = `${getXPosition(endValue, u) - getXPosition(startValue, u) + 1}px`;

        saveRange(u, values[0], values[1]);
    }
}

const deleteRange = (u) => {
    u.over.querySelectorAll('.u-marker-x,.u-range-x').forEach((el) => el.remove());
};
