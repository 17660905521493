import React from 'react';
import socket from "../../socket";
import {toast} from "react-toastify";
import {Icon} from "../ui";
import {rlabApi} from "../../api/api";
import {fullName, isAuth} from "../../helpers/auth";
import {useSelector} from "react-redux";
import {printShortDate} from "../../helpers/text";
import {storageClass} from "../../redux/slices/storage";
import "./RlabTable.scss";

const RlabTable = (props) => {
    const documentClass = useSelector(storageClass);

    async function share(r, v) {
        await rlabApi.update(r._id, {isShared: v});
        props.refresh();
        socket.emit('BE-refresh-db', {roomId:documentClass._id, type:'rlab', id:r._id});
    }

    function copyUrl(r) {
        navigator.clipboard.writeText(window?.location.protocol + '//' + window?.location.host + "/rlab/" + r._id);
        toast.success("Ссылка скопирована в буфер");
    }

    return (
        <table className="cor_rlabTable cor_table">
            <thead>
            <tr key={-1}>
                <th className="cor_rlabTable__name">Название Эксперимента</th>
                <th className="cor_rlabTable__date">Время и дата</th>
                <th className="cor_rlabTable__owner">Автор</th>
                <th className="cor_rlabTable__desc">Описание</th>
                <th className="cor_rlabTable__link">Действия с ссылками</th>
                <th className="cor_rlabTable__edit"></th>
            </tr>
            </thead>
            <tbody>
            {props.rlabs?.map((rlab, i) => (
                <tr key={i}>
                    <td className="link" onClick={()=>props.history.push("/rlab/" + rlab._id)}>{rlab.name}</td>
                    <td>{printShortDate(rlab.startedAt)}</td>
                    <td className="link"
                        onClick={()=>rlab.owner&&props.history.push("/profile"+(rlab.owner._id === isAuth()._id ?"":"/"+rlab.owner._id))}>
                        {rlab.owner?._id === isAuth()._id ?"Вы":fullName(rlab.owner)}</td>
                    <td className="editablePopup"><textarea readOnly rows={1} value={rlab.desc}
                        onFocus={e=>{e.target.rows=parseInt(e.target.scrollHeight>e.target.clientHeight?7:1);e.target.style.zIndex = 2;}}
                        onBlur={e=>{e.target.rows=1;e.target.style.zIndex = 1;}}
                    ></textarea></td>
                    {rlab.owner?._id === isAuth()._id ?
                        rlab.isShared > 0 ? <td>
                                <Icon name="copy-paste" onClick={() => copyUrl(rlab)}
                                      title="Скопировать" text="Скопировать"/>
                                <Icon className="icon_trash" onClick={() => share(rlab, 0)} title="Удалить ссылку"/>
                            </td>
                            : <td className="inactive">
                                <Icon name="attachment" onClick={() => share(rlab, 1)}
                                      className="inactive" text="Создать ссылку"/>
                            </td>
                        :  rlab.isShared > 0?<td>
                            <Icon name="copy-paste" onClick={() => copyUrl(rlab)}
                                                    title="Скопировать" text="Скопировать"/></td>
                            :<td className="inactive">нет ссылки</td>}
                    {rlab.owner?._id === isAuth()._id ?<td>
                        {rlab.isShared < 2 &&
                            <Icon name="user" onClick={() => share(rlab, 2)} title="Только по ссылке"/>}
                        {rlab.isShared === 2 &&
                            <Icon name="user-group" onClick={() => share(rlab, 1)} title="Видно всему классу"/>}
                        <Icon name="edit"
                              onClick={() => props.showRlabSettings({showModal: true, allowDelete: true, rlab})}
                              title="Редактировать"/>
                    </td>:<td/>}
                </tr>
            ))}
            </tbody>
        </table>
    )
};
export default RlabTable;
