const initialState = {
    writeStatus: false,
    writerSelected: 0,
};

const writerReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_NWRITE_STATUS':
            return {
                ...state,
                writeStatus: action.payload,
            };

        case 'TOGGLE_NWRITER':
            return {
                ...state,
                writerSelected: action.payload,
            };
        default:
            return state;
    }
};

export default writerReducer;
