const initialState = {
    data: {},
    isReading: false,
    sensorReading: false,
};

const readerReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_READER':
            return {
                ...state,
                data: action.payload,
            };

        case 'SET_READING':
            return {
                ...state,
                isReading: action.payload,
            };

        case 'SET_SENSOR_READING':
            return {
                ...state,
                sensorReading: action.payload,
            };

        default:
            return state;
    }
};

export default readerReducer;
