import React, {useState, useEffect} from 'react';
import Modal from "../../Components/Modal";
import { Button, Input, Icon } from '../ui';
import {toast} from "react-toastify";
import PIOneDragAndDropList from "./PIOneDragAndDropList";
import PIImageHelpDlg from "./PIImageHelpDlg";
import { getDropdown, chkTextWordSize } from "../ui/utils/gen_utils";
import {parse} from 'equation-parser';
import {defaultVariables} from 'equation-resolver';
import { USE_FORMULA, COL_TYPE, getColumnTypeList, getPhysicalUnitById, getPhysicalUnitList} from "./pi_utils";
import {useDispatch, useSelector} from "react-redux";
import {loadPIUnits} from "../../redux/slices/lists";
import {storageClass} from "../../redux/slices/storage";
import "./PInvest.scss";

const COLUMN_LIMIT = 10;

const PICreateEditTableColumnDlg = ({showDlg, setShowDlg, selectedTable, updateColumnsRows}) => {
	const [columnList, setColumnList] = useState([]);
	const [rowList, setRowList] = useState([]);
	const [currColumn, setCurrColumn] = useState(undefined);
	const [tableTitle, setTableTitle] = useState(undefined);
    const [isColumnChanged, setIsColumnChanged] = useState(false);
    const [editColumnInd, setEditColumnInd] = useState(-1);
    const [showHelpDlg, setShowHelpDlg] = useState(false);

	const dispatch = useDispatch();
	const {lists: {pi}} = useSelector(state => state);
	const documentClass = useSelector(storageClass);

    const getInitColumn = () => {
        return  {
            title: '', //name
            unit: null, //id
            type: COL_TYPE.UNDEFINED,
            formula: ''
        };
    };

    useEffect(() => {
		if (!showDlg || !selectedTable) return;
        setTableTitle(selectedTable.title);
        //не показываем первую колонку. В конце надо восстановить ее.
        const cols = selectedTable.properties.filter((item, ind) => ind !== 0);
        setColumnList(cols.map(item => ({...item})));
        setRowList([...selectedTable.rows.map(item => [...item])]);
	}, [showDlg, selectedTable]);

    useEffect(() => {
		dispatch(loadPIUnits(documentClass._id));
	}, [dispatch, documentClass._id]);

    useEffect(() => {
        setCurrColumn(getInitColumn());
    }, [showDlg]);

    const checkColumn = col => {
        let msg = '';
        if (col.title === '') msg = 'Введите название колонки.';
        else if (col.type === COL_TYPE.UNDEFINED) msg = 'Выберите тип поля.';
        //else if (col.unit === null && (col.type === COL_TYPE.NUMBER || col.type === COL_TYPE.FORMULA)) msg = 'Выберите единицу измерения.';
        else if (col.type === COL_TYPE.FORMULA && col.formula === '') msg = 'Введите формулу.'; //ЗДЕСЬ ТАКЖЕ НАДО ПРОВЕРЯТЬ КОРРЕКТНОСТЬ ФОРМУЛЫ!!!!!

        if (msg) toast.warn(msg);
        return !msg;
    };

    const getTypeName = (dtId) => {
        const res = getColumnTypeList(true).find(item => item.value === dtId);
        return res ? res.label : '';
    }

    const handleChangeColTitle = (value) => {
        if (value.length > 31) return;
        const val = chkTextWordSize(15, value);
        const col = {...currColumn, title: val};
        setCurrColumn(col);
        setIsColumnChanged(true);
    };

    const handleChangeColType = value => {
        const col = {...currColumn, type: value};
        setCurrColumn(col);
        setIsColumnChanged(true);
    };

    const handleChangeColUnit = value => {
        const col = {...currColumn, unit: value};
        setCurrColumn(col);
        setIsColumnChanged(true);
    };

    const handleChangeColFormula = value => {
        if (value.length > 100) return;
        const col = {...currColumn, formula: value};
        setCurrColumn(col);
        setIsColumnChanged(true);
    };

    const handleDeleteColumn = (deleteInd) => {
        const cols = [...columnList];
        const c = 'c' + (deleteInd+1);

        //проверяем, используется ли удаляемая колонка в формулах
        for (let i = 0; i < columnList.length; i ++) {
            const col = columnList[i];

            if (col.type === 'formula' && col.formula.toLowerCase().includes(c)) {
                const msg = 'Колонка, которую вы удаляете, используется в формуле в колонке C' + (i+1) + 
                    '. Предварительно удалите колонку C' + (i+1);
                toast.warn(msg);
                return;
            }
        }

        //уменьшаем С-индекс во всех формулах, если они используют колонки после удаляемой
        for (let i = 0; i < columnList.length; i ++) {
            const col = columnList[i];
            if (col.type === 'formula') {
                const formula = col.formula.toLowerCase();
                for (let k = deleteInd + 2; k < columnList.length; k ++) {
                    const searchC = 'c' + k;
                    if (formula.includes(searchC))
                        col.formula = formula.replaceAll(searchC, 'c' + (k-1));
                }
            }
        }

        //удаляем колонку
        cols.splice(deleteInd, 1); //delete 1 elem
        setColumnList(cols);
        const rows = [...rowList];
        //удаляем данные этой колонки из таблицы. так как скрыли первую колонку, нужно добавлять +1
        const newRows = rows.map(row => row.filter((item, ind) => ind !== deleteInd+1));
        setRowList(newRows);
    };

    
    const handleMoveColumn = (ind, isDesc) => {
        if (isDesc && ind === columnList.length - 1) return;
        if (!isDesc && ind === 0) return;

        const newInd = isDesc ? ind + 1 : ind - 1;
        const indC = 'c' + (ind+1);
        const newIndC = 'c' + (newInd+1);
        let cols = [...columnList];
        for (let i = 0; i < cols.length; i ++)
            if (cols[i].type === COL_TYPE.FORMULA) {
                let f = cols[i].formula.toLowerCase();
                if (f.includes(indC) && f.includes(newIndC)) {
                    f = f.replaceAll(indC, '_X_');
                    f = f.replaceAll(newIndC, indC);
                    cols[i].formula = f.replaceAll('_X_', newIndC);
                } else if (f.includes(indC)) {
                    cols[i].formula = f.replaceAll(indC, newIndC);
                } else if (f.includes(newIndC)) {
                    cols[i].formula = f.replaceAll(newIndC, indC);
                }
            }

        const _x = cols[ind];
        cols[ind] = cols[newInd];
        cols[newInd] = _x;
        setColumnList(cols);

        const rows = [...rowList];
        const newRows = [];
        for (let i = 0; i < rows.length; i ++) {
            const row = rows[i];
            const _x = row[ind+1];
            row[ind+1] = row[newInd+1];
            row[newInd+1] = _x;
            newRows.push(row);
        }
        setRowList(newRows);
    };

    const handleEditColumn = ind => {
        setEditColumnInd(ind);
        setCurrColumn(columnList[ind]);
    };

    const handleCancelColumnChange = (isNewColumn) => {
        if (!isNewColumn) 
            setEditColumnInd(-1);
        setIsColumnChanged(false);
        setCurrColumn(getInitColumn());
    };

    const checkFuncArgNum = (formula, func) => {
        const _formula = formula.toLowerCase();
        if (!_formula.includes(func)) return true;
        const arr = _formula.split(')');
        for (let i = 0; i < arr.length; i ++) {
            if (arr[i].includes(func+'(')) {
                const inS = func+'(';
                const s = arr[i].slice(inS.length);
                if (!s.includes(',')) {
                    const msg = func === 'pow' || func === 'round' ? 
                        'Функция ' + func + ' должна содержать 2 аргумента.' : 
                        'Функция ' + func + ' должна содержать не менее 2 аргументов.'; 
                    toast.warn(msg);
                    return false;
                }
            }
        }
        return true;
    };
    const checkFuncCorrectArgs = (formula) => {
        const _formula = formula.toLowerCase();
        for (let i = 0; i < columnList.length; i ++) {
            const c = 'c' + (i + 1);
            if (_formula.includes(c)) {
                const col = columnList[i];
                const type = col.type;
                if (type !== COL_TYPE.NUMBER) {
                    const msg = 'Ваша формула ссылается на нечисловую колонку C' + (i+1) + '.';
                    toast.warn(msg);
                    return false;
                } 
            }
        }
        return true;
    };
    const checkFormulaCorrect = (formula) => {
        let usedC = [];
        if (formula.includes('π')) formula = formula.replaceAll('π', '3.141592653');

        for (let i = 0; i < columnList.length; i ++) {
            const c = 'c' + (i+1);
            if (formula.toLowerCase().includes(c))
                usedC.push(c);
        }

        if (usedC.length === 0) return true;
        //get various combinations of c-parameters:
        const PARS = [-10000, -1, 0, 1, 10000];
        let checkArr = PARS.map(item => [item]);
        for (let i = 1; i < usedC.length; i ++) {
            const size = checkArr.length;
            const checkArr2 = [];
            for (let k = 0; k < size; k ++) {
                for (let m = 0; m < PARS.length; m ++) {    
                    const arr = [...checkArr[k]];
                    arr.push(PARS[m]);
                    checkArr2.push(arr);
                }
            }
            checkArr = checkArr2;
            //console.log('checkArr=', checkArr)
        }
        
        //check if any cobinaiton of c-parameters provides correct result:
        for (let i = 0; i < checkArr.length; i ++) {
            const arr = checkArr[i];
            let f = formula.toLowerCase();
            for (let k = 0; k < arr.length; k ++) {
                f = f.replaceAll(usedC[k], arr[k]);
            }
            const equation = parse(f, null, { variables: defaultVariables });
            if (equation.type !== "parser-error")
                return true;
        }
        toast.warn('Формула содержит ошибку.');
        return false; // no combination found
    };
    const checkFuncs = (col) => {
        const formula = col.formula;
        if (formula) { //check formula:
            if (!checkFuncArgNum(formula, 'pow')) return false;
            if (!checkFuncCorrectArgs(formula)) return false;
            if (!checkFormulaCorrect(formula)) return false;
        }
        return true;
    };

    const completeAddColumn = () => {
        const col = {...currColumn};
        if (col.type !== COL_TYPE.NUMBER && col.type !== COL_TYPE.FORMULA)
            col.unit = null;
        col.formula = col.formula.trim().replaceAll('С', 'C').replaceAll('с', 'c'); //rus c => eng c

        if (!checkColumn(col)) return;
        if (!checkFuncs(col)) return;

        setIsColumnChanged(false);

        //добавляем новую колонку        
        const cols = [...columnList];
        cols.push({...col});
        setColumnList(cols);

        //добавим новую колонку ко всем рядам этой таблицы
        const rows = [...rowList];
        const newRows = [];
        for (let i = 0; i < rows.length; i ++) {
            const row = rows[i];
            row.push('');
            newRows.push(row);
        }

        if (newRows.length === 0) {
            //добавляем колонку для индексов и для первого значения
            const row = [];
            row.push(1);
            row.push('');
            newRows.push(row);
        }

        setRowList(newRows);
        setCurrColumn(getInitColumn()); //инициализируем снова
    };

    const completeEditColumn = () => {
        //save changes
        const col = {...columnList[editColumnInd]};
        col.title = currColumn.title;
        col.unit = currColumn.unit;
        col.formula = currColumn.formula.trim().replaceAll('С', 'C').replaceAll('с', 'c'); //rus c => eng c

        if (!checkColumn(col)) return;
        if (!checkFuncs(col)) return;

        const cols = columnList.map((item, ind) => ind !== editColumnInd ? item : col);
        setColumnList(cols);
        setEditColumnInd(-1);
        setIsColumnChanged(false);
        setCurrColumn(getInitColumn()); //инициализируем снова
    };

    const cleanup = () => {
        setColumnList([]);
        setRowList([]);
        setIsColumnChanged(false);
        setEditColumnInd(-1);
        setShowDlg(false);
    };

    const handleCancelDlg = () => {
        cleanup();
    };

    const isColumnInInitState = () => {
        return currColumn.title === '' && currColumn.unit === null && currColumn.type === COL_TYPE.UNDEFINED;
    };

    const handleSaveColumns = () => {
        if (isColumnChanged && !isColumnInInitState()) {
            toast.warn('Завершите редактирование колонки и добавьте ее или отмените изменения.');
            return;
        } 

        if (columnList.length === 0) {
            toast.warn('Добавьте по крафней мере одну колонку.');
            return;
        }

        //восстановим первую колонку:
        columnList.unshift(selectedTable.properties[0]);
        //сохраним:
        updateColumnsRows(columnList, rowList);
        cleanup();
    };

    const getColumnInAddEditState = (isNewColumn) => {
        const res = (
            <>
                <td key={'col1'}>C{columnList.length + 1}</td>

                <td key={'col2'}>  
                    <Input className="" key={'inp01'}
                        value={currColumn?.title} placeholder={'Введите название колонки'}
                        onInput={e => handleChangeColTitle(e.target.value)}
                    />
                </td>

                <td key={'col3'}>
                    {isNewColumn ?
                    getDropdown(getColumnTypeList(columnList.length > 0), currColumn?.type, value => handleChangeColType(value), '', '', 'dtp01')
                    :
                    getTypeName(currColumn.type)
                    }
                </td>
                
                <td key={'col4'}>
                    {(currColumn.type === COL_TYPE.UNDEFINED || currColumn.type === COL_TYPE.NUMBER || currColumn.type === COL_TYPE.FORMULA) && 
                    getDropdown(getPhysicalUnitList(pi), currColumn.unit, value => handleChangeColUnit(value), '', '', 'unt01')}
                </td>

                {USE_FORMULA &&<td key={'col5'}></td>}

                <td  key={'col6'} className='pi-table__editButtons'>  
                    <Icon name={isNewColumn ? "plus_bold" : "save"} onClick={isNewColumn ? completeAddColumn : completeEditColumn}/>&nbsp;
                    {isNewColumn && !isColumnInInitState() &&
                        <Icon name="close" onClick={() => setCurrColumn(getInitColumn())}/>
                    }
                    {!isNewColumn && <Icon name="close" onClick={() => handleCancelColumnChange(isNewColumn)}/>}
                </td>
            </>
        );

        return res;
    };

    const getRows = () => {
        const addF = USE_FORMULA ? 'F' : '';
        return (
            columnList.map((col, ind) => (
                <tr key={'col'+ind}>
                    {editColumnInd === ind ? 
                        getColumnInAddEditState(false)
                    :
                    <>
                        <td key={'td0'+ind} className={'pi-table_edit__num'}>{(USE_FORMULA ? 'C' : '') + (ind+1)}</td>
                        <td key={'td1'+ind} className={'pi-table_edit__title'+addF}>{col.title}</td>
                        <td key={'td2'+ind} className={'pi-table_edit__type'+addF}>{getTypeName(col.type)}</td>
                        <td key={'td3'+ind} className={'pi-table_edit__unit'+addF}>{getPhysicalUnitById(pi, col.unit)}</td>
                        {USE_FORMULA && <td key={'td4'+ind} className='pi-table_edit__formula'>{col.formula}</td>}
                        <td key={'td5'+ind} className='pi-table__editButtons pi-table_edit__action'>
                            <Icon name="show-more" onClick={() => handleMoveColumn(ind, true)}/>
                            <Icon name="show-less" onClick={() => handleMoveColumn(ind, false)}/>
                            <Icon name="brush" onClick={() => handleEditColumn(ind)}/>
                            <Icon name="trash" onClick={() => handleDeleteColumn(ind)}/>
                        </td>
                    </>
                    }
                </tr>
            ))       
        );
    };

    const setModalContent = () => {
        if (!currColumn) return <></>;
        const addF = USE_FORMULA ? 'F' : '';

        return (
            <>
                <div className="modal__message">
                    Сформировать колонки таблицы{tableTitle && (': "'+ tableTitle+ '"')}
                    <Icon name="info" className="pi__modal_help" onClick={() => setShowHelpDlg(true)} />
                </div>
                <div className="modal__close" onClick={handleCancelDlg}></div>
                <div className="modal__body">
                    <table className="cor_table pi-table pi-table_edit">
                        <thead>
                            <tr key={-1}>
                                <th key='th0' className={'pi-table_edit__num'}>№</th>
                                <th key='th1' className={'pi-table_edit__title'+addF}>Название колонки</th>
                                <th key='th2' className={'pi-table_edit__type'+addF}>Тип данных</th>
                                <th key='th3' className={'pi-table_edit__unit'+addF}>Числовая единица измерения</th>
                                {USE_FORMULA && <th key='th4' className='pi-table_edit__formula'>Формула</th>}
                                <th key='th5' className='pi-table__editButtons pi-table_edit__action'>Действия</th>
                            </tr>
                        </thead>
                        <tbody>
                            <PIOneDragAndDropList
                                elems={columnList}
                                setElems={setColumnList}
                                keyVal={'table'}
                                list={getRows()}
                                isDraggingLocked={true || editColumnInd !== -1}
                            />
                
                            {editColumnInd === -1 && columnList.length < COLUMN_LIMIT  ? 
                                <tr key='rr0'>{getColumnInAddEditState(true)}</tr> 
                            : 
                                <tr className='empty'></tr>
                            }
                        </tbody>
                    </table>
                </div>

                {currColumn.type === COL_TYPE.FORMULA &&
                <div className="cor-net__row" key={'form01'}>
                    <div className="cor-net__col col-grow">
                        <div className="cor-net__label">Формула</div>
                        <Input 
                            value={currColumn.formula} 
                            placeholder={'Введите формулу, например, 2 * C1 + C2. Здесь C1 - значение поля в 1-ой колонке, C2 - во 2-ой.'}
                            onInput={e => handleChangeColFormula(e.target.value)}
                        />
                    </div>
                </div>
                }

                <div className="modal__action">
                    <Button color="primary" border={false} onClick={() => handleSaveColumns()}>Сохранить</Button>
                    <Button color="primary" border={true} onClick={handleCancelDlg}>Отменить</Button>
                </div>

                {showHelpDlg && 
                <PIImageHelpDlg 
                    showModal={showHelpDlg}
                    setShowModal={setShowHelpDlg}
                />
                }
            </>
        )
    }

    if (!showDlg) {
        return false;
    }
    
    return (
        <Modal visible={showDlg} content={setModalContent()} size={USE_FORMULA ? 'xl' : 'lg'} />
    )
};

export default PICreateEditTableColumnDlg;
