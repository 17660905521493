export const chartColors = {
    marker: {
        line: 'rgba(0,0,0,0.5)',
        // line: '#BEC9D3',
        color: '#000000',
        background: '#FFFFFF',
        border: '#BEC9D3',
        dark: {
            line: 'rgba(255,255,255,1)',
            // line: '#464F57',
            color: '#FFFFFF',
            background: '#363A45',
            border: '#4A4F5B',
        },
        table: {
            color: '#F75656',
        },
        cursor: {
            line: '#F75656',
            color: '#000000',
            background: '#FFFFFF',
            border: '#F75656',
            dark: {
                line: '#F75656',
                color: '#FFFFFF',
                background: '#363A45',
                border: '#F75656',
            },
        },
    },
    line: {},
    boxes: {
        chart: {
            box1: 'rgba(129, 184, 249, 0.2)',
            box2: 'rgba(128, 249, 212, 0.2)',
            box3: 'rgba(225, 128, 249, 0.2)',
            box4: 'rgba(249, 222, 128, 0.2)',
        },
        table: {
            box1: '#81B8F9',
            box2: '#80F9D4',
            box3: '#E180F9',
            box4: '#F9DE80',
        },
    },
    grid: {
        dark: '#343640',
        light: '#E8E8E8',
    },
    tick: '#8C8F9B',
    bg: {
        dark: '#282B33',
        light: '#FFFFFF',
    },

    // tickColor: '#8C8F9B',
};

export default chartColors;
