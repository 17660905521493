import  Icon  from '../../Icon';
import ss from './SensorView.module.scss';
import logoSrc from './sensorLogo.svg';
import logoSrcDark from './sensorLogoDark.svg';

export const logo = () => {
    return (
        <div className={ss.root__logo}>
            <img src={logoSrc} alt="logo" />
            <img src={logoSrcDark} alt="logo" />
        </div>
    );
}; 

export const list = () => {
    return (
        <div className={ss.list}>
            <div className={ss.list__item}>
                <Icon name="mathematics" />
            </div>
            <div className={ss.list__item}>
                <Icon name="ecology" />
            </div>
            <div className={ss.list__item}>
                <Icon name="phisic" />
            </div>
            <div className={ss.list__item}>
                <Icon name="Icon-place" />
            </div>
            <div className={ss.list__item}>
                <Icon name="biology" />
            </div>
            <div className={ss.list__item}>
                <Icon name="chemistry" />
            </div>
        </div>
    );
}; 

export const info = () => {
    return (
        <div className={ss.info}>
            <div className={ss.info__item}>
                <div className={ss.info__icon + ' ' + ss.info__iconCollumn}>
                    <div className={ss.info__icon_top}>
                        <Icon name="bluetooth" />
                    </div>
                    <div className={ss.info__icon_bottom}>
                        <Icon name="battery-middle" />
                    </div>
                </div>
            </div>
            <div className={ss.info__item}>
                <div className={ss.info__icon}>
                    <Icon name="flash" />
                </div>
            </div>
        </div>
    );
}; 

export const charging = () => {
    return (
        <div className={`${ss.icon} ${ss.bottom}`}>
            <div className={ss.icon__title}>ПИТАНИЕ</div>
            <div className={ss.icon__wrap}>
                <div className={ss.icon__label}>Usb</div>
                <div className={ss.icon__list}>
                    <div className={ss.icon__view}>
                        <Icon name="usb_connect" />
                    </div>
                </div>
                <div className={ss.icon__label}>Зарядка</div>
            </div>
        </div>
    );
}; 

export const mode = () => {
    return (
        <div className={`${ss.icon} ${ss.rightBottom}`}>
            <div className={ss.icon__title}>ВКЛ./<br></br>ВЫКЛ.</div>
            <div className={ss.icon__wrap}>
                <div className={ss.icon__list}>
                    <div className={ss.icon__view}>
                        <Icon name="wireless" />
                    </div>
                </div>
                <div className={ss.icon__label}>Беспроводной<br></br>режим</div>
            </div>
        </div>
    );
}; 

export const computer = () => {
    return (
        <div className={`${ss.icon} ${ss.rightCenter}`}>
            <div className={ss.icon__title}>USB</div>
            <div className={ss.icon__wrap}>
                <div className={ss.icon__list}>
                    <div className={ss.icon__view}>
                        <Icon name="mac" />
                    </div>
                </div>
                <div className={ss.icon__label}>Компьютер</div>
            </div>
        </div>
    );
}; 

export const logging = () => {
    return (
        <div className={`${ss.icon} ${ss.logging}`}>
            <div className={ss.icon__title}>Л</div>
            <div className={ss.icon__wrap}>
                <div className={ss.icon__label}>Логирование</div>
                <div className={ss.icon__view}>
                    <Icon name="diskette" />
                </div>
            </div>
            <div className={ss.icon__line}></div>
        </div>
    );
}; 