import React from 'react';
import "../Content.scss";

const ContentWrap = ({children, className = ''}) => {
    return (
        <div className={"cor_content__wrap " + className}>
            { children }
        </div>
    )
};
export default ContentWrap;
