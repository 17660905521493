import React, {useState, useEffect} from "react";
import Footer from '../Footer';
import LabWorkShow from '../../practicum/LabWorkShow';
import OnlineTestExecute from '../../OnlineTest/OnlineTestExecute';
import {PRACTICUM_TEST_OPT, getPracticumTestInfo} from "../../ui/utils/ServerInfo";
import {isAuth} from "../../../helpers/auth";
import {ContentWrap} from "../ContentParts";
import {useSelector} from "react-redux";
import { getOnlineTestInfoByRunId  } from "../../OnlineTest/ot_utils";
import {storageOrg} from "../../../redux/slices/storage";
import {hasFeature} from "../../../helpers/auth";
import DashboardLicense from "./DashboardLicense";
import "./Dashboard.scss";
import "../../practicum/TutorialSelectTab.scss";

export const DashboardTestExecute = (props) => {
	const [otSettings, setOtSettings] = useState(undefined);
  const documentOrg = useSelector(storageOrg);

  const isLW = props.location.pathname === '/lw';
  const isOT = props.location.pathname === '/ot';

	useEffect(() => {
		const getOtInfoId = async () => {
			const result = await getOnlineTestInfoByRunId(isAuth()._id, ti.testRunId);

      const _otSettings = {
        onlineTestId: ti.labWorkId,
        onlineTestInfoId: result[0]._id,
        testRunId: ti.testRunId, //?
        startTime: ti.startTime,
        timeLimitInMinutes: ti.timeLimitInMinutes,
        timeLimitEnabled: ti.timeLimitEnabled,
        isAutoEstimateType: ti.isAutoEstimateType,
        isRandomOrder: ti.isRandomOrder
      };

      setOtSettings(_otSettings);
		};

    if (!isOT) return;
    const ti = getPracticumTestInfo();
		if (!!ti) {
			getOtInfoId();
		}
	}, [isOT]);
  
  if (!(hasFeature(documentOrg, PRACTICUM_TEST_OPT.OT) || hasFeature(documentOrg, PRACTICUM_TEST_OPT.LW)))
    return <DashboardLicense {...props}/>;

  if (isLW) {
    return (
      <ContentWrap>
        <LabWorkShow {...props} />
        <Footer {...props}/>
      </ContentWrap>
    );
  }

  if (isOT) {
    return (
      <ContentWrap>
        <OnlineTestExecute {...props} otSettings={otSettings} />
        <Footer {...props}/>
      </ContentWrap>
    );
  }

  return <></>;
}

export default DashboardTestExecute;
