import { setChartSettingsModal, setChartStyle } from '../../actions/chart';
import { setSensorAbscissa } from '../../actions/sensors';

export const mapStateToProps = (state) => {
    return {
        port: state.port.open,
        sensorList: state.sensors.list,
        sensorImported: state.sensors.imported,
        sensorWrited: state.sensors.writed,
        sensorAbscissa: state.sensors.abscissa,
        chartStyle: state.chart.style,
        chartColors: state.chart.settings.colors,
        devicesList: state.devices.list,
        // view: state.chart.settings.view,
        modal: state.chart.settings.modal,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setChartStyle: (obj) => dispatch(setChartStyle(obj)),
    setSensorAbscissa: (obj) => dispatch(setSensorAbscissa(obj)),
    setChartSettingsModal: (item) => dispatch(setChartSettingsModal(item)),
});
