import React from 'react';
import { ReactComponent as AddCardButton } from '../../../assets/icons/add-card-button.svg';
import './AddButton.scss';

const AddButton = ({
  isVisible,
  handleShow,
  handleAdd,
  title,
  className,
  svgClassName,
}) => {
  return (
    <>
      {!isVisible ? (
        <button className={`${className ? `${className} add_button` : 'add_button'}`} onClick={handleShow}>
          <span className="add_button__title">{title}</span>
          <AddCardButton className={`${svgClassName || 'add_button__svg'}`} />
        </button>
      ) : (
        <div className="add_button__form">
          <button
            className="add_button__control add_button__submit"
            onClick={handleAdd}
          >
            {title}
          </button>
          <button
            className="add_button__control add_button__cancel"
            onClick={handleShow}
          />
        </div>
      )}
    </>
  );
};

export default AddButton;
