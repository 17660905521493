import React, {useEffect, useState} from 'react';
import Modal from '../Modal';
import "./Settings.scss";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {storageClass} from "../../redux/slices/storage";

const RlabSensors = (props) => {
    const documentClass = useSelector(storageClass);
    const [enabled, setEnabled] = useState({});

    const getSensors = (key) => {
        if (!props.devicesList[key])
            return [];
        if (props.devicesList[key].single)
            return [props.devicesList[key]];
        return props.devicesList[key].getElementsByTagName('channels')[0].children;
    }

    const handleClose = () => {
        props.showRlabSensors({showModal: false})
    };

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) handleClose();
        };
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onChangeDevice(ev, key) {
        const sensors = props.sensorsList.filter(s=>s.deviceKey === key).map(s=>s.sensNum);
        setEnabled((e)=>
        {
            return {...e, [props.devicesList[key].unicName]:(ev.checked)?sensors:[]}
        });
    }

    function onChangeChannel(ev, key, ch) {
        setEnabled((e)=>
        {
            const name = props.devicesList[key].unicName;
            e[name] = e[name]?e[name].filter(c=>c!==ch):[];
            if (ev.checked)
                e[name].push(ch);
            return {...e}
        });
    }

    function createUrl() {
        navigator.clipboard.writeText(window?.location.protocol + '//' + window?.location.host +
            "/rlab/" + documentClass._id + "/"+ encodeURIComponent(btoa(JSON.stringify(enabled))));
        handleClose();
        toast.success("Ссылка скопирована в буфер");
    }

    return (
        <>
            <Modal size="md" visible={props.showModal} content={
                <>
                    <div className="modal__close" onClick={handleClose}/>
                    <div className="modal__message">Выбор датчиков</div>
                    <div className="modal__body cor_settings_hor2">
                        {Object.keys(props.devicesList??{}).map((key) =>
                            <div className="cor_settings_vert2" key={key}>
                                <label>
                                    <input type="checkbox"
                                        onChange={(e)=>onChangeDevice(e.target, key)}
                                           checked={props.sensorsList.filter(s=>s.deviceKey === key).length
                                               === enabled[props.devicesList[key].unicName]?.length}
                                    />{props.devicesList[key].attributes['name']}</label>
                                <ul>
                                    {getSensors(key).map((item, i) =>
                                        <li key={i}>
                                            {props.sensorsList.filter(s=>s.deviceKey === key&&s.sensNum===i).length>0 &&
                                                <label>
                                                <input type="checkbox"
                                                       onChange= {(e)=>onChangeChannel(e.target, key, i)}
                                                       checked={enabled[props.devicesList[key].unicName]?.includes(i)}
                                                />
                                                {item.attributes.name}
                                            </label>}
                                        </li>
                                    )}
                                </ul>
                            </div>)}
                    </div>
                    <div className="modal__action">
                        <button className="cor_btn cor_btn_primary" onClick={()=>createUrl()}>
                            Создать и скопировать ссылку
                        </button>
                    </div>
                </>
            }/>
        </>
    )
};

export default RlabSensors;