import React, { useState, useEffect } from 'react';
import Icon from '../Icon';
import { getImageNumber, getFrameDataList } from './utils';
import './VideoEditor.scss';

const VideoEditorItem = ({ props }) => {
    const { videoList, frame, index, selectedFrameInd, imageSize, shouldSaveFrame, handleSelectImage, handleDeleteImage, handleDeleteMeasure } = props;
    const [show, toggleShow] = useState(true);
    const [imgSize, setImgSize] = useState([1, 1]);
    const idImage = 'idImage' + index;

    useEffect(() => {
        const elem = document.getElementById(idImage);
        const rect = elem?.getBoundingClientRect();
        if (!rect.width || !rect.height) return;
        const ratio = Math.min(imageSize[0] / rect.width, imageSize[1] / rect.height);
        const size = [rect.width / ratio, rect.height / ratio];
        if (!size[0] || !size[1]) return; //i added it for frameExportData. there is a strnage refresh
        setImgSize(size);
    }, [idImage, imageSize]);

    //
    useEffect(() => {
        if (shouldSaveFrame) toggleShow(false);
    }, [shouldSaveFrame]);

    return (
        <div
            key={'sp01' + index}
            className="videoEditorList__item"
        >
            <div className="videoEditorList__head">
                <div className="videoEditorList__count">{getImageNumber(index)}</div>
                <div
                    className="videoEditorList__action"
                    onClick={() => handleDeleteImage(index)}
                >
                    <Icon name={'trash'} />
                </div>
                <div
                    className="videoEditorList__action"
                    onClick={() => toggleShow(!show)}
                >
                    <Icon
                        name={'toggler'}
                        className={show && 'active'}
                    />
                </div>
            </div>
            <div className={show ? 'videoEditorList__body active' : 'videoEditorList__body'}>
                <div
                    id={idImage}
                    className="videoEditorList__img"
                >
                    {/* При использовании frame.frameExportData (для показа измерений) вместо frame.frameData почему-то вылетает */}
                    {/* Возможно, надо ждать, когда updatedExportImage === undefined, иначе ждать. НЕТ. что-то другое */}
                    <img
                        key={index}
                        width={imgSize[0]}
                        height={imgSize[1]}
                        src={frame.frameData}
                        alt={'Кадр'}
                        onClick={() => handleSelectImage(index)}
                    />
                </div>
                <div className="videoEditorList__props">{getFrameDataList(frame, selectedFrameInd, handleDeleteMeasure, videoList[frame.videoInd])}</div>
                {/* <div className="videoEditorList__del" onClick={() => handleDeleteAllFrameMeasures(index)} >
                    <Icon name="trash" />
                    <span>Удалить все объекты в кадре</span>
                </div> */}
            </div>
        </div>
    );
};
export default VideoEditorItem;
