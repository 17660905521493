//https://github.com/apache/echarts
//official site: https://echarts.apache.org/en/download.html
//https://echarts.apache.org/examples/en/#chart-type-pie
//options --- https://echarts.apache.org/en/option.html#title.textAlign
//example --- https://echarts.apache.org/examples/en/editor.html?c=pie-borderRadius
import React, {useState, useEffect} from 'react';
import EChart from "../ui/Chart/EChart";

const ProfilePieChart = ({isLightMode, scores}) => {
    const [currOption, setCurrOption] = useState(undefined);
    const [textColor, setTextColor] = useState(undefined); 
    const [bkndColor, setBkndColor] = useState(undefined);

    useEffect(() => {
      setTextColor(isLightMode ? 'black' : 'white');
      setBkndColor(isLightMode ? 'white' : 'black');
    }, [isLightMode]);
  
    const getScoreSum = _scores => {
      let _scoreSum = 0;
      for (let i = 0; i < _scores.length; i ++)
        _scoreSum += _scores[i];
      return _scoreSum;
    };
    
    const getScoreList = (_scores, scoreNumber, _textColor, _bkndColor) => {
        const scoreList = [];
        for (let i = 0; i < _scores.length; i ++) {
            const scorePct = scoreNumber > 0 ? (_scores[i] / scoreNumber * 100).toFixed(1) : 0;
            const scoreValue = i < 4 ? 'Оценка ' + (5-i) : 'Работа не оценена';
            scoreList.push({ 
              value: _scores[i], 
              name: scoreValue + ' (' + scorePct + '%)' ,
              tooltip: {
                  backgroundColor: _bkndColor, //CHANGE WHEN dark mode
                  textStyle: {
                      color: _textColor, //CHANGE WHEN dark mode
                  }
              },
            });
        }
        return scoreList;
    };

    const getAverageScoreNumber = (_scores) => {
        let scoreNumber = 0;
        let scoreTotalScoreValue = 0;

        for (let i = 0; i < _scores.length - 1; i ++) { //non-estimated results are not taken into account
            scoreNumber += _scores[i];
            scoreTotalScoreValue += (5 - i) * _scores[i];
        }
        return scoreNumber > 0 ? (scoreTotalScoreValue / scoreNumber).toFixed(1) : 0;
    };

    const getCurrOption = (_averageScoreNumber, _scoreList, _textColor) => {
        const option = {
            tooltip: {
                trigger: 'item',
                className: 'EChartTooltip',
            },
            //legend: {  top: '5%',     bottom: 'right', show: true,  align: 'right', orient: 'vertical',   },
            legend: { 
                orient: 'vertical', 
                right: 'left', 
                textStyle: {
                    color: _textColor, //CHANGE when dark mode
                },
            },
            series: [
            {
                name: 'Средний балл ' + _averageScoreNumber,
                type: 'pie',
                radius: ['60%', '90%'],
                left: -200, 
                top: 0,
                itemStyle: {
                    borderRadius: 2,
                    borderColor: '#fff',
                    borderWidth: 2,
                },
                label: {
                    show: false, //true, - //removed center label
                    position: 'center',
                    color: _textColor, //CHANGE WHEN dark mode
                },
                emphasis: {
                    label: {
                        show: true, //всплывающая подсказка
                        fontSize: 12,
                        fontWeight: 'bold',
                      }
                },
                labelLine: {
                    show: false, //true, 
                    length: 10,
                },
                data: _scoreList,
            }
          ]
        };
        
        return option;
    };

    useEffect(() => {
        const scoreSum = getScoreSum(scores);
        if (scoreSum) {
          const scoreList = getScoreList(scores, scoreSum, textColor, bkndColor);
          const averageScoreNumber = getAverageScoreNumber(scores);
          const _currOption = getCurrOption(averageScoreNumber, scoreList, textColor);
          setCurrOption(_currOption);
        }
	}, [scores, textColor, bkndColor]);

    return (
      <>
        {currOption &&
        <EChart
          option={currOption} 
          width={28.75} 
          height={11.25}
        />
        }
      </>
    )
};

export default ProfilePieChart;
