import React from 'react';
import { LIST_COLORS } from '../List/ListColors';

const CardUsersList = ({
  card,
  cardUsers,
  listCurrentColor,
  slicedUsers,
  cardUsersLength,
  isModal,
}) => {
  return (
    <ul className="card_users__wrapper" key={`${card?._id}`}>
      {cardUsers?.length <= cardUsersLength
        ? cardUsers?.map((user, index) => (
            <li
              className="card_users__item"
              key={`${user._id}-1`}
              style={{
                [isModal ? 'left' : 'right']: `${
                  isModal ? index * 26 : (cardUsers?.length - 1 - index) * 26
                }%`,
                zIndex: index,
                backgroundColor:
                  listCurrentColor?.menuColor || LIST_COLORS[0]?.menuColor,
                color: 'white',
              }}
            >
              {user?.name?.toUpperCase().slice(0, 1)}
            </li>
          ))
        : slicedUsers?.push({
            name: cardUsers?.length + 1 - cardUsersLength,
            style: {
              backgroundColor: '#666F7E',
              width: '1.75em',
              color: 'white',
            },
          }) &&
          slicedUsers?.map((user, index) => (
            <>
              <li
                className="card_users__item"
                key={user?._id}
                style={{
                  [isModal ? 'left' : 'right']: `${
                    isModal
                      ? index * 26
                      : (slicedUsers?.length - 1 - index) * 26
                  }%`,
                  color: 'white',
                  zIndex: index,
                  backgroundColor:
                    index === slicedUsers?.length - 1
                      ? user?.style?.backgroundColor
                      : listCurrentColor?.menuColor ||
                        LIST_COLORS[0]?.menuColor,
                  width:
                    index === slicedUsers?.length - 1 && user?.style?.width,
                }}
              >
                {index === slicedUsers?.length - 1
                  ? `+${user?.name}`
                  : user?.name?.toString().toUpperCase().slice(0, 1)}
              </li>
            </>
          ))}
    </ul>
  );
};

export default CardUsersList;
