import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  users: [],
  sensors: false,
};

const optionsSlice = createSlice({
  name: "options",
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    removeUser: (state, action) => {
      state.users = state.users.filter(u => u._id !== action.payload._id);
    },
    setSensors: (state, action) => {
      state.sensors = action.payload;
    },
  },
});

export const optionsUsers = (state) => {
  return state.options.users;
};

export const optionsSensors = (state) => {
  return state.options.sensors;
};

export const {
  setUsers,
  removeUser,
  setSensors,
} = optionsSlice.actions;
export default optionsSlice.reducer;
