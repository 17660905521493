import React from 'react';
import {Icon, Notify, Tooltip} from "../index";
import ss from "./SectionTabs.module.scss";

const SectionTabs = ({history, tabs}) => {
  return (
    <div className={ss.sections}>
      {tabs.map((tab, key) =>
        <div 
            key={key} 
            className={tab.disabled?ss.disabled:ss.tab} 
            onClick={() => !tab.disabled && history.push(tab.to)}>
          <div>
            <div className={ss.icon}>
              <Icon name={tab.icon}/>
            </div>
            <div className={ss.name}>{tab.name}</div>
          </div>
          {tab.tooltip && 
            <Tooltip 
              className={ss.tooltip} 
              position='custom'>
                {tab.tooltip}
            </Tooltip>
          }
          <Notify value={tab.notify} className={ss.notify} />
        </div>
      )}
    </div>
  )
};

export default SectionTabs;
