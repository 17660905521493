import React from 'react';
import { Icon } from '../../ui';
import { Dropdown } from 'rlabui';
import parse from 'html-react-parser';
import ss from './SignalMode.module.scss';

const signals = [
    { label: 'Временной', icon: 'timer', value: 0 },
    { label: 'Одиночный', icon: 'pulse', value: 1 },
    { label: 'Двойной', icon: 'double-chart', value: 2 },
];

const SignalMode = ({ mod, setMode }) => {
    return (
        <>
            <label>Режим сигнала</label>
            <div className={ss.signalMode}>
                <Dropdown value={signals[mod]?.label ? parse(`<i class="icon_${signals[mod].icon}"></i> ${signals[mod].label}`) : 'Выберите режим'}>
                    {signals?.map(({ value, label, icon }) => (
                        <Dropdown.Item
                            key={value}
                            onClick={() => setMode(value)}
                        >
                            <Icon name={icon} /> {label}
                        </Dropdown.Item>
                    ))}
                </Dropdown>
            </div>
        </>
    );
};
export default SignalMode;
