const initialState = {
    port: null,
    open: false,
    command: '',
    stream: null,
    reader: null,
    writer: null,
    streamClose: null,
    channels: [],
    connectionType: null,
};

const portReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_NPORT':
            return {
                ...state,
                port: action.payload,
            };

        case 'OPEN_NPORT':
            return {
                ...state,
                open: action.payload,
            };

        case 'SET_NPORT_READER':
            return {
                ...state,
                reader: action.payload,
            };

        case 'SET_NPORT_STREAM':
            return {
                ...state,
                stream: action.payload,
            };

        case 'SET_NPORT_WRITER':
            return {
                ...state,
                writer: action.payload,
            };

        case 'SET_NPORT_STREAM_CLOSE':
            return {
                ...state,
                streamClose: action.payload,
            };

        case 'SET_NPORT_CHANNEL':
            const setChannels = () => {
                let index = action.payload.index;
                let channels = [...state.channels];
                if (action.payload.data >= 0) {
                    channels[index] = action.payload.data;
                } else {
                    delete channels[index];
                }
                return channels;
            };
            return {
                ...state,
                channels: setChannels(),
            };

        case 'SEND_NCOMMAND':
            return {
                ...state,
                command: action.payload,
            };

        case 'SET_NPORT_CONNECTION_TYPE':
            return {
                ...state,
                connectionType: action.payload,
            };

        default:
            return state;
    }
};

export default portReducer;
