export const oscilloWorker = () => {
    let counters = {},
        currentTime = {},
        currentVal = [],
        channelsList = {},
        savedData = {},
        asideData = {},
        numsCheck = {},
        battery = {},
        time = 0,
        data,
        aside,
        channel,
        writedList,
        writedData,
        oscilloSettings,
        skip = false;

    /* eslint-disable-next-line no-restricted-globals */
    self.addEventListener('message', (ev) => {
        if (!ev && !ev.data.sensor) return;
        const { current, sensor, timer, reading, graduate, isOscilloscope, played, reset, paused, getSaved, sensorsUnits, sensorList } = ev.data;

        const stream = played && !paused;
        time = timer;

        // Получаем сохраненные данные
        if (getSaved) {
            sendData();
        }

        // Сброс значений (пока не знаю зачем, но вдруг пригодится)
        if (reset) {
            postMessage({ channel: [], data: [] });
            return;
        }

        // Парсинг осциллографа
        try {
            if (!sensor?.length) return;
            // Разбор данных о подключенных устройствах
            const devices = JSON.parse(current);
            let devicesKeys = [...Object.keys(devices)];

            // Сохраняем массив для осциллографа
            let oscilloArr = sensor.split('ch').filter((c, i) => i !== 0);
            // Сохраняем настройки осциллографа
            oscilloSettings = {};
            let params = sensor
                .split('ch')[0]
                .split(' ')
                .filter((item) => item);

            for (var item of params) {
                let fLetter = item.charAt(0);
                let value = +item.replace(fLetter, '');
                switch (fLetter) {
                    case 'a':
                        oscilloSettings['rawMin'] = +value;
                        break;
                    case 'b':
                        oscilloSettings['rawMax'] = +value;
                        break;
                    case 'c':
                        oscilloSettings['ch'] = value;
                        break;
                    case 'f':
                        oscilloSettings['freq'] = value / 1000;
                        break;
                    case 'n':
                        oscilloSettings['cnum'] = value;
                        break;
                    case 'v':
                        oscilloSettings['min'] = +value;
                        break;
                    case 'w':
                        oscilloSettings['max'] = +value;
                        break;
                    default:
                }
            }
            // console.log(oscilloSettings);

            // Если нет данных в сохраненных создаем пустые массивы
            for (const key of devicesKeys) {
                if (!channelsList[key]) {
                    channelsList[key] = [];
                }
                if (!counters[key]) {
                    counters[key] = [];
                }
                if (!currentVal[key]) {
                    currentVal[key] = [];
                }
                if (!currentTime[key]) {
                    currentTime[key] = [];
                }
                if (!savedData[key]) {
                    savedData[key] = [];
                }
                if (!asideData[key]) {
                    asideData[key] = [];
                }
                if (!numsCheck[key]) {
                    numsCheck[key] = [];
                }
            }

            const deviceKey = devicesKeys[0];
            const { graduable, single, children: devChild, unicName } = devices[deviceKey];

            oscilloArr.forEach((sens, i) => {
                // console.log(sens);
                const value = [];
                let parse = sens
                    .split(' ')
                    .filter((v) => v)
                    .map((e) => Number(e));
                let modesArr = [];
                let num = parse.shift();
                let index = num;
                let val = parse;

                // Сброс сохраненных значений
                savedData[deviceKey][i] = [];
                counters[deviceKey][i] = 1;
                currentTime[deviceKey][i] = 0;
                if (!currentVal[deviceKey][i]) {
                    currentVal[deviceKey][i] = +val.at(-1);
                }

                // Доп опции для осциллографа
                devices[deviceKey].children[0].children.forEach((item) => {
                    // console.log(item);
                    const modeData = [];
                    const { name: modeName } = item.attributes;
                    item.children.forEach((mod, i) => modeData.push(mod.attributes.name));
                    modesArr.push({
                        modeName,
                        modeData,
                    });
                });

                let cropped,
                    range = 10;
                const { rawMin, rawMax, min, max, freq } = oscilloSettings;
                const numParse = parse.map(Number);
                const coeffK = (max - min) / (rawMax - rawMin);
                const coeffB = min - ((max - min) / (rawMax - rawMin)) * rawMin;
                const maxVal = Math.max.apply(null, numParse) - range;

                // Подрезка массива по верхнему значению для того чтобы убрать визуальный эффект накопления данных
                const findex = numParse.findIndex((e) => e < maxVal + range && e > maxVal - range);
                cropped = numParse.slice(findex);
                // Без подрезки
                // cropped = val;

                // пропускаем отправку если данных слишком мало
                if (cropped.length < numParse.length / 4) {
                    skip = true;
                    return;
                }

                for (let k in cropped) {
                    if (cropped[k]) {
                        const rawVal = cropped[k] * coeffK + coeffB;
                        value.push({ y: rawVal, x: k / freq, count: counters[i]++, num });
                    }
                }

                let deviceChannels = devChild?.filter((ch) => ch.name === 'channels')[0]?.children;
                // console.log(deviceChannels);

                const {
                    attributes: { name, quantity, unit, roundDigit },
                } = deviceChannels[index];

                // Формируем элемент sensorList
                let markers = {};
                let sensValue = [];
                sensorList?.forEach((sensor) => {
                    if (sensor.num === num) {
                        markers = sensor.markers || {};
                        sensValue = sensor.value || [];
                    }
                });
                const obj = {
                    name,
                    quantity,
                    unit: sensorsUnits[num]?.name ? sensorsUnits[num].name : unit,
                    roundDigit,
                    markers,
                    num: num,
                    value: sensValue,
                    sensNum: num,
                    modes: modesArr,
                    graduable,
                    // frequency: frequency[pseudoNum],
                    unicName,
                    deviceKey,
                    single,
                };

                // Сщхранем полученное для отправки
                channelsList[deviceKey][i] = obj;
                currentTime[deviceKey][i] = time;

                savedData[deviceKey][i] = value;
                asideData[deviceKey][i] = [value.at(-1)];
            });

            sendData();
        } catch (err) {
            console.log('oscilloscop worker error: ', err);
        }

        function sendData() {
            channel = [];
            aside = [];
            data = [];
            writedList = [];
            writedData = [];
            let saved = false;
            if (getSaved) {
                saved = true;
            }
            Object.values(channelsList).forEach((device) => {
                for (const i in device) {
                    const { deviceKey, frequency } = device[i];

                    if (deviceKey.includes('readed')) {
                        writedList = [...writedList, channelsList[deviceKey][i]];
                        writedData = [...writedData, savedData[deviceKey][i]];
                    } else {
                        channel = [...channel, channelsList[deviceKey][i]];
                        if (isOscilloscope) {
                            data = [...data, savedData[deviceKey][i]];
                        } else {
                            data = stream ? [...data, savedData[deviceKey][i].slice(-(50 + frequency))] : [...data, savedData[deviceKey][i]];
                        }
                    }
                    aside = [...aside, asideData[deviceKey][i]];

                    if (!played && !reading) {
                        counters[deviceKey][i] = 1;
                        currentTime[deviceKey][i] = 0;
                    }
                }
            });

            !skip && postMessage({ channel, data, aside, writedList, writedData, reading, graduate, played, saved, oscilloSettings, battery });
            skip = false;
            return;
        }
    });
};
