import React, {useState, useEffect} from "react";
import PICreateEditTableColumnDlg from "./PICreateEditTableColumnDlg";
import PIOneInputCell from './PIOneInputCell';
import {loadPIFiles} from "./pi_utils";
import {storageClass} from "../../redux/slices/storage";
import {useDispatch, useSelector} from "react-redux";
import {loadPIUnits} from "../../redux/slices/lists";
import PIOneName from './PIOneName';
import { COL_TYPE, getHeaderCell, getViewTableContent } from "./pi_utils";
import { Icon } from '../ui';

const PIOneTable = ({isEdit, uid, id, workMeasurement, setWorkMeasurement, tableIndex,
        doDeleteHideSection, setShowImageDlg, setShownFile, workPlots, handleSelectGraph}) => {
    const [showCreateEditTableColumnDlg, setShowCreateEditTableColumnDlg] = useState(false);
	const [selectedTableInd, setSelectedTableInd] = useState(-1); 
	const {lists: {pi, files}} = useSelector(state => state);
	const dispatch = useDispatch();
	const documentClass = useSelector(storageClass);

    useEffect(() => {
        loadPIFiles(dispatch);
    }, [dispatch]);

    useEffect(() => {
		dispatch(loadPIUnits(documentClass._id));
	}, [dispatch, documentClass._id]);

    useEffect(() => {
        if (!workMeasurement || workMeasurement.rows.length > 0) return;
        const wm = {...workMeasurement, rows: [[1,'','']]};
        setWorkMeasurement(tableIndex, wm);
    }, [tableIndex, workMeasurement, setWorkMeasurement]);

    const updateTableName = (isSave, name) => {
        if (isSave) {
            const wm = {...workMeasurement, title: name};
            setWorkMeasurement(tableIndex, wm);
        }
    };
    const updateColumnsRows = (cols, rows) => {
        const wm = {...workMeasurement, properties: cols, rows: rows};
        setWorkMeasurement(tableIndex, wm);
    };

    const handleSaveValue = (cellType, cellValue, cellUniqueIndex) => {
        const [_tableIndex, rowIndex, cellIndex] = cellUniqueIndex.split('|').map(item => Number(item));
        const wm = {...workMeasurement};
        const row = wm.rows[rowIndex];
        row[cellIndex] = cellType === COL_TYPE.TEXT ? cellValue.trim() : cellValue;
        setWorkMeasurement(tableIndex, wm);
    };

    const getCellIndex = (_tableIndex, _rowIndex, _cellIndex) => ""+_tableIndex+"|"+_rowIndex+"|"+_cellIndex;

    const doIndexReplace = (_header, rowInd, rowsAfter, isUp) => {
        const shift = isUp ? +1 : -1;
        //увеличим/уменьшим нумерацию рядов
        for (let i = 0; i < rowsAfter.length; i ++) {
            rowsAfter[i][0] = Number(rowsAfter[i][0]) + shift;
        }

        //сдвинем индексы в формулах вверх/вниз из-за вставки/удаления rowInd-ряда
        // for (let i = 0; i < _header.length; i ++) {
        //     const col = _header[i];
        //     if (col.type === COL_TYPE.FORMULA) {
        //         for (let k = 0; k < rowsAfter.length; k ++) {
        //             let cell = rowsAfter[k];
        //             if (cell.includes('C'+(rowInd+1))) cell = cell.replaceAll('C'+(rowInd+1), 'C' + (rowInd + 1 + shift));
        //         }
        //     }
        // }
    };

    const handleShowFile = (f) => {
        if (!f) return;
        setShowImageDlg(true);
        setShownFile(f);
    };

    const handleAddRow = (_header, rowInd) => {
        const wm = {...workMeasurement};
        const rowsBefore = [...wm.rows.slice(0, rowInd+1)];
        const newRow = Array(wm.rows[0].length).fill('');
        newRow[0] = rowInd + 2;
        const rowsAfter =[...wm.rows.slice(rowInd+1)];
        doIndexReplace(_header, rowInd, rowsAfter, true);

        wm.rows = [...rowsBefore, newRow, ...rowsAfter];
        setWorkMeasurement(tableIndex, wm);
    };
    const handleDeleteRow = (_header, rowInd) => {
        const wm = {...workMeasurement};
       
        const rowsBefore = [...wm.rows.slice(0, rowInd)];
        const rowsAfter =[...wm.rows.slice(rowInd+1)];
        doIndexReplace(_header, rowInd, rowsAfter, false);
        wm.rows = [...rowsBefore, ...rowsAfter];
        setWorkMeasurement(tableIndex, wm);
    };

    const handleClearRow = (row, rowInd) => {
        const emptyRow = row.map((item, ind) => ind === 0 ? item : '');
        const wm = {...workMeasurement};
        wm.rows = wm.rows.map((item, ind) => ind !== rowInd ? item : emptyRow);
        setWorkMeasurement(tableIndex, wm);
    };
    
    const getEditButtons = (_header, row, rowInd, rowsNum) => {
        return (
            <div className="pi-table__editButtons" key={'eb01'+tableIndex+rowInd}>
			    <Icon name="plus_bold" onClick={() => handleAddRow(_header, rowInd)} key={'ei01'+tableIndex+rowInd} />
                <Icon name="close" onClick={() => handleClearRow(row, rowInd)} key={'ei02'+tableIndex+rowInd} />
                {rowsNum > 1 &&
                    <Icon name="trash" onClick={() => handleDeleteRow(_header, rowInd)} key={'ei03'+tableIndex+rowInd} />
                }
            </div>            
        );
    };

    const handleTableOver = (tblIndex) => {
        if (selectedTableInd !== tblIndex) {
            setSelectedTableInd(tblIndex);
        }
    };

    const getEditTable = (_tableIndex) => {
        const _header = workMeasurement.properties;
        const _rows = workMeasurement.rows;
        const tableContent = (
            <table 
                    onFocus={() => handleTableOver(_tableIndex)} 
                    onBlur={() => handleTableOver(-1)} 
                    key={'lbl01'+_tableIndex}   
                    className={"cor_table pi-table pi-table_edit " + 
                        (selectedTableInd === _tableIndex ? "active" : "")}>
                <thead key={'hth01'+_tableIndex}>
                     <tr key={'tr00'+_tableIndex}>
                        {_header?.map((item, cellInd) => 
                            <th key={'th01'+_tableIndex+cellInd}>
                                {cellInd === 0 ? '№' : getHeaderCell(tableIndex, item.title, _header[cellInd], cellInd, pi)}
                            </th>)
                        }
                         <th key={'th00'+_tableIndex}>
                            <Icon name="brush" onClick={() => setShowCreateEditTableColumnDlg(true)}/>
                        </th>
                    </tr> 
                </thead>
                <tbody key={'tbd01'+_tableIndex}>
                    {_rows?.map((row, rowInd) => (<tr key={'trr01'+_tableIndex+rowInd}>
                        {row.map((cell, cellInd) => (
                            <td key={'trd03'+_tableIndex+rowInd+cellInd}>
                                <PIOneInputCell 
                                    cellUniqueInd={getCellIndex(tableIndex, rowInd, cellInd)}
                                    cellType={_header[cellInd].type}
                                    cellValue={cell}
                                    handleSaveValue={handleSaveValue}
                                    setShowImageDlg={setShowImageDlg}
						            setShownFile={setShownFile}
                                    rows={workMeasurement.rows}
                                    cols={workMeasurement.properties}
                                />
                            </td>))}
                            <td key={'tdd01'+_tableIndex+rowInd}>{getEditButtons(_header, row, rowInd, _rows.length)}</td>
                    </tr>))}
                </tbody>
            </table>
        );
    
		return (
        <>
            {workMeasurement?.title && <PIOneName
                isEdit={isEdit} 
                initName={workMeasurement.title} 
                setEditedName={updateTableName} 
                uniqueIndex={_tableIndex}
                doDeleteHideSection={doDeleteHideSection} 
                isTable={true}
                workPlots={workPlots}
                tableUid={uid}
                handleSelectGraph={handleSelectGraph}
            />}
            {tableContent}
        </>);
	}

    const getViewTable = () => {
        const _header = workMeasurement.properties; //column options
        const _rows = workMeasurement.rows;
        return (
            <>
                <div className="cor-net__title" key={'tname01'+tableIndex}>{workMeasurement?.title}</div>
                <div>
                    {getViewTableContent(files, _header, _rows, tableIndex, handleShowFile, pi)}
                </div>
            </>
        );
    };

    return (
        <>
            <div id={id}>
                {isEdit ? getEditTable() : getViewTable()}
            </div>

            {showCreateEditTableColumnDlg &&
            <PICreateEditTableColumnDlg
				showDlg={showCreateEditTableColumnDlg}
				setShowDlg={setShowCreateEditTableColumnDlg}
				selectedTable={workMeasurement}
                updateColumnsRows={updateColumnsRows}
			/>}
        </>
    );
}

export default PIOneTable;
