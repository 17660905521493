export const getNoises = () => {
    const getFullName = (name, isWaf=true) => "/Instrument/VoltageGeneratorSounds/" + name + (isWaf ? ".wav" : '.mp3'); 
    return [
      {value: '', label: 'Выберите звук для реверберации'},
      {value: getFullName('concert-crowd', false), label: 'Шум на концерте'},
      {value: getFullName('africa-toto'), label: 'Африка'},
      {value: getFullName('around_the_world-atc'), label: 'Вокруг мира'},
      {value: getFullName('babble'), label: 'Шум улицы'},
      {value: getFullName('beautiful_life-ace_of_base'), label: 'Прекрасная жизнь'},
      {value: getFullName('evangeline-matthew_sweet'), label: 'Музыка'},
      {value: getFullName('hrn06'), label: 'Гудок автомобиля'},
      {value: getFullName('sf2_fi4'), label: 'Разговор о телефону'},
      {value: getFullName('sf3_n2H'), label: 'Шум в ресторане'},
      {value: getFullName('srn06'), label: 'Сирена'},
      {value: getFullName('train'), label: 'Шум поезда'},
    ];
  };
  export const getNoiseById = id => getNoises().find(item => item.value === id);
  
  export const getEffects = () => {
    return [
      {value: '', label: 'Звук без изменений'},
      {value: 'distortion', label: 'Искажение'},
      {value: 'biquad', label: 'усиление басов'},
      {value: 'delay', label: 'Эхо'},
      //{value: 'convolver', label: 'Реверберация'},
    ];
  };
  export const getEffectById = id => getEffects().find(item => item.value === id);

  export const getEffectShowMethods = () => {
    return [
      {value: 'sinewave', label: 'Волна'},
      {value: 'frequencybars', label: 'Гистограмма'},
      {value: 'off', label: 'Скрыть'},
    ]; 
  };
  export const getEffectShowMethodById = id => getEffectShowMethods().find(item => item.value === id);

  export const createEchoDelayEffect = (audioContext, frequency) => {
    const delay = audioContext.createDelay(1);
    const dryNode = audioContext.createGain();
    const wetNode = audioContext.createGain();
    const mixer = audioContext.createGain();
    const filter = audioContext.createBiquadFilter();

    delay.delayTime.value = 0.75;
    dryNode.gain.value = 1;
    wetNode.gain.value = 0;
    filter.frequency.value = frequency;
    filter.type = "highpass";
    return {
      apply() {
        wetNode.gain.setValueAtTime(0.75, audioContext.currentTime);
      },
      discard() {
        wetNode.gain.setValueAtTime(0, audioContext.currentTime);
      },
      isApplied() {
        return wetNode.gain.value > 0;
      },
      placeBetween(inputNode, outputNode) {
        inputNode.connect(delay);
        delay.connect(wetNode);
        wetNode.connect(filter);
        filter.connect(delay);

        inputNode.connect(dryNode);
        dryNode.connect(mixer);
        wetNode.connect(mixer);
        mixer.connect(outputNode);
      },
    };
  }

    // Distortion curve for the waveshaper
  // http://stackoverflow.com/questions/22312841/waveshaper-node-in-webaudio-how-to-emulate-distortion
  export const makeDistortionCurve = (amount) => {
    let k = typeof amount === "number" ? amount : 50,
      n_samples = 44100,
      curve = new Float32Array(n_samples),
      deg = Math.PI / 180,
      i = 0,
      x;
    for (; i < n_samples; ++i) {
      x = (i * 2) / n_samples - 1;
      curve[i] = ((3 + k) * x * 20 * deg) / (Math.PI + k * Math.abs(x));
    }
    return curve;
  }

  export const getConvolver = async (_audioCtx, _noiseId, setConvolver, setIsValueChanged) => {
      const _convolver = _audioCtx.createConvolver();
      try { // Grab audio track via fetch() for convolver node
        const noise = getNoiseById(_noiseId);
        const path = noise.value;
        if (path) {//"https://mdn.github.io/voice-change-o-matic/audio/concert-crowd.ogg"
          const response = await fetch(path);
          const arrayBuffer = await response.arrayBuffer();
        //console.log('path=', path, ' arrayBuffer=', arrayBuffer.length);
        const decodedAudio = await _audioCtx.decodeAudioData(arrayBuffer);
          _convolver.buffer = decodedAudio;
          setConvolver(_convolver);
        } else {
          setConvolver(null);
        }
        setIsValueChanged(true); //convolver changed
      } catch (error) {
          console.error(`Unable to fetch the audio file: ${error.name} Error: ${error.message}`);
      }
  };
