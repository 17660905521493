//USE THIS COLOR PALETTE:
//https://www.npmjs.com/package/react-color-palette

import React, {useState, useEffect, useRef} from 'react';
//import { SketchPicker } from 'react-color'; //react color palette
import { getDropdown } from "../ui/utils/gen_utils";
import Modal from "../../Components/Modal";
import "./Instruments.scss";
import { Button } from '../ui';

const ShowSimpleFigureDialog = (props) => {
    const { showModal, setShowModal } = props;
    const [selectedForm, setSelectedForm] = useState(0);
    const [selectedColor, setSelectedColor] = useState(0);
    const [svgWidth, setSvgWidth] = useState(undefined);
    const [isFullScreenMode, setIsFullScreenMode] = useState(false);
    const [svgImage, setSvgImage] = useState(undefined);
	const [resize, setResize] = useState(0);
    const refMainBox = useRef();
    const refMainSvg = useRef();

  useEffect(() => {
    const handleResize = e => setResize(e[0].contentBoxSize[0].inlineSize);
    const observer = new ResizeObserver(handleResize);
    observer.observe(refMainBox.current);
    return () => {
      observer.disconnect();
    };
  }, []);

	useEffect(() => {
        if (!refMainSvg || !refMainSvg.current) return;

		const width = refMainSvg.current.offsetWidth;
		setSvgWidth(width);
	}, [resize]);

	useEffect(() => {
        if (isFullScreenMode) {
    		document.addEventListener("click", handleClickEvent);
	    	document.addEventListener("keydown", handleClickEvent);
        } else {
            document.removeEventListener("click", handleClickEvent);
            document.removeEventListener("keydown", handleClickEvent);
    
        }
	}, [isFullScreenMode]);

	const handleSelectedForm = (value) => { 
		setSelectedForm(parseInt(value));
	};
	const handleSelectedColor = (value) => { 
		setSelectedColor(parseInt(value));
	};

    const handleSetFullScreen = () => {
        setIsFullScreenMode(true);
		const fullScreenElem = document.getElementById("idFulScreen");
        fullScreenElem.requestFullscreen();
    };

    const handleClickEvent = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
            setIsFullScreenMode(false);
        }
    };

    const getForms = () => { 
        return [
            {label: 'Треугольник', value: 0},
            {label: 'Квадрат', value: 1},
            {label: 'Круг', value: 2},
        ];
    }
    const getColors = () => { 
        return [
            {label: 'Белый', value: 0},
            {label: 'Красный', value: 1},
            {label: 'Синий', value: 2},
        ];
    }

    const getTriangle = (_svgWidth,  _svgHeight, figureSize, yShift, color) => {
        const cX = _svgWidth/2;
        const cY = _svgHeight/2 + yShift;
        const xTop = cX;
        const yTop = cY - 0.67 * figureSize;
        const x = figureSize * 2 * 0.57; //сторона треугольника
        const yBottom = cY + 0.33 * figureSize;
        const xLeft = cX - 0.5 * x;
        const yLeft = yBottom;
        const xRight = cX + 0.5 * x;
        const yRight = yBottom;
        const d = "" + xTop + " " + yTop + " " + xLeft + " " + yLeft + " " + xRight + " " + yRight;

        return <polyline points={d} stroke={color} fill={color}  />;
    };
    const getCircle = (_svgWidth,  _svgHeight, figureSize, color) => {
        const cX = _svgWidth / 2;
        const cY = _svgHeight / 2;
        const circle = <circle cx={cX} cy={cY} r={figureSize} fill={color} stroke={color} />;
        return circle;
    };
    const getSquare = (_svgWidth,  _svgHeight, figureSize, color) => {
        const cX = _svgWidth / 2;
        const cY = _svgHeight / 2;
        const square = <rect x={cX - figureSize/2} y={cY-figureSize/2} width={figureSize} height={figureSize} fill={color} />;
        return square;
    };

	useEffect(() => {
        if (!svgWidth) return undefined;
        const svgHeight = !isFullScreenMode ? 200 : 600;
        const size = !isFullScreenMode ? 100 : 300;
        const triangleYShift = 15;

        const color = selectedColor === 0 ? "white" : selectedColor === 1 ? "red" : "blue";

        const circle = getCircle(svgWidth, svgHeight, size/2, color);
        const square = getSquare(svgWidth, svgHeight, size, color);
        const triangle = getTriangle(svgWidth, svgHeight, size, triangleYShift, color);

        const figure = selectedForm === 0 ? triangle : selectedForm === 1 ? square : circle;

        const img =     
            <svg width={svgWidth} height={svgHeight} viewBox={"0 0 " + svgWidth + " " + svgHeight} fill="black" xmlns="http://www.w3.org/2000/svg" key='cap01'>
                <rect x="0" y="0" width={svgWidth} height={svgHeight} fill="black"/>
                {figure}
            </svg>;
        setSvgImage(img);
	}, [isFullScreenMode, selectedColor, selectedForm, svgWidth]);

    const setModalContent = () => {
        return (
            <div id="idTopElement" className="print" >
                <div className="modal__close" onClick={() => setShowModal(false)}></div>
                <div className="modal__message">Запуск проекции</div>

                <div id="MainBox" ref={refMainBox} className="instruments__stretch">
                    <div className="instruments__flexColumn">
                        <div className="instruments__Row">Форма объекта</div>
                        <div className="instruments__Row">
                            {getDropdown(getForms(), selectedForm, handleSelectedForm, 
								'otCreate__Select otCreate__SelectValues', 'otCreate__DropDownItem', 'form01')}
                        </div>    
                    </div>

                    <div className="instruments__flexColumn">
                        <div className="instruments__Row">Цвет объекта</div>
                        <div className="instruments__Row">
                            {getDropdown(getColors(), selectedColor, handleSelectedColor, 
								'otCreate__Select otCreate__SelectValues', 'otCreate__DropDownItem', 'color01')}
                        </div>
                    </div>
                </div>

                {/* <div>
                    <span>
                        <div className='instruments__Color' style={{backgroundColor: myCol.hex}}>Эксперимент</div>
                    </span>
                    <span>
                        <SketchPicker color={ myCol } onChangeComplete={ setMyCol }/>
                    </span>
                </div> */}

                <div className="instruments__preview">Предпросмотр</div>

                <div id="idFulScreen" ref={refMainSvg} className={"instruments__svg"}>
                    {svgImage}
                </div>
                <div className="modal__action">
                    <Button
                        children = "Запустить проекцию"
                        color = "primary"
                        onClick = {handleSetFullScreen}
                    />
                    <Button
                        children = "Отмена"
                        border = {true}
                        color = "primary"
                        onClick = {() => setShowModal(false)}
                    />
                </div>
            </div>
        )
    }

    if (!showModal) {
        return false;
    }

    return (
        <Modal visible={showModal} content={setModalContent()} size={'md'} />
    )
};

export default ShowSimpleFigureDialog;
    //https://thewebfosters.com/add-icons-html-select-options/
//  <select class="selectpicker">
//   <option data-content={<i className={"instruments__circle instruments__circleColor" + ind}></i>Option1}></option></select> 
//
// <>    <span className={"instruments__circle instruments__circleColor" + ind}>&nbsp;</span>
//<span className="instruments__circleText">{item.color}</span>                                         </> 


//https://habr.com/ru/articles/184926/   - full screen
