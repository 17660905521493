import Grid from '../../ui/Grid';
import { Icon, Tooltip } from '../../ui';
import {CALC_MODEL, ELDYN_EXP_TYPE} from '../../CalcModels/cm_utils';
import {onlineTestInfoApi, notebookInfoApi} from "../../../api/api";
import CoilMagneticField from '../../CalcModels/CalcModel_CoilMagneticField';
import {isAuth} from "../../../helpers/auth";
import {WS_TYPE} from "../../PhysicalInvest/pi_utils";
import {getAllNotebookInfoByOwner, getNotebookById} from '../../Notebook/nb_utils';
import {isNbTaskStarted} from "../../Notebook/task_utils";
import Lense from '../../CalcModels/CalcModel_Lense';
import Diffraction from '../../CalcModels/CalcModel_Diffraction';
import Interference from '../../CalcModels/CalcModel_Interference';
import Electrodynamics from '../../CalcModels/CalcModel_Electrodynamics';
import MechanicMove from '../../CalcModels/CalcModel_MechanicMove';
import {base64ToArrayBuffer, uploadFile, doDeleteFileByName} from '../../ui/utils/gen_utils';
import {loadFiles} from "../../../redux/slices/lists";

const VIDEOEDITOR_PREFIX = 'VideoEditor%2F';

export const uploadVEFile = (_files, fileData, setLoadedFileName) => {
	const setUploadProgress = () => {};
	const cleanedBase64 = fileData.base64.substring(22);

	const file = new File(
		[base64ToArrayBuffer(cleanedBase64)],
		fileData.name, 
		{ type: 'image/jpg' }
	);
	
	uploadFile(VIDEOEDITOR_PREFIX, _files, file, setLoadedFileName, setUploadProgress);
}
export const deleteVEFileByName = async (dispatch, fileName) => {
	await doDeleteFileByName(VIDEOEDITOR_PREFIX, dispatch, fileName);
};
export const loadVEFiles = dispatch => {
	dispatch(loadFiles(VIDEOEDITOR_PREFIX));
}

export const getSavedVideoDataByStorageFile = (file, _savedVideoDataList) => {
	if (!_savedVideoDataList || _savedVideoDataList.length === 0) return null;
	const storageVideoName = file.Key.split('/')[1];
	const vd = _savedVideoDataList.find(item => item.storageVideoName === storageVideoName);
	return vd;
};

export const getStorageVideoName = file => {
	const storageVideoName = file.Key.split('/')[1];
	return storageVideoName;
};

export const getFrameNumber =  (file, _savedVideoDataList) => {
	const vd = getSavedVideoDataByStorageFile(file, _savedVideoDataList);
	return vd ? vd.frames.length : 0;
}

export const getMeasureNumber =  (file, _savedVideoDataList) => {
	const vd = getSavedVideoDataByStorageFile(file, _savedVideoDataList);
	if (!vd) return 0;
	let measureNum = 0;
	for (let i = 0; i < vd.frames.length; i ++)
		measureNum += vd.frames[i].measures.length;
	return measureNum;
}

export const doGetVideos = (foundFiles, savedVideoDataList, hadleSelectVideo, handleDeleteVideo) => {
	return (
		<Grid className="corGrid"  key='grid01'>
			{foundFiles.map((f, i) => (
				<div key={'dir01'+i}>
					 <Grid.Col key={'dir02'+i}>
						<div className="corGrid__body otCreate__imageBody otCreate__imageBodyBknd" key={'mid01'+i}>
							<div key={"v02"+i} className='otCreate__videoExecVideo'>
								<video src={f.Url} controls={true} width="300" height="200" key={"v03"+i} ></video>
							</div>
						</div>
		
						<div className="otCreate__imageTitle" key={'bot01'+i}>
							<span className='otCreate__imageVariant otCreate__imagePointer' key={'bot04'+i}>
								{getStorageVideoName(f)}
							</span>
							<span className='otCreate__imageVariant otCreate__imagePointer' key={'bot05'+i}>
								&nbsp;&nbsp;
								<Icon name="download" onClick={() => hadleSelectVideo(f)} key={'bot06'+i}/>
							</span>
							<span className='otCreate__imageVariant otCreate__imagePointer' key={'bot15'+i}>
								&nbsp;&nbsp; 
								<Icon name="trash" onClick={() => handleDeleteVideo(f)} key={'bot16'+i}/>
							</span>
						</div>

						<div className="otCreate__imageVariant otCreate__imagePointer" key={'bot07'+i}>
							Количество кадров {getFrameNumber(f, savedVideoDataList)}
						</div>

						<div className="otCreate__imageVariant otCreate__imagePointer" key={'bot08'+i}>
							Количество измерений {getMeasureNumber(f, savedVideoDataList)}
						</div>
					</Grid.Col>
				</div>
			))}
		</Grid> 
	);
};

export const getCurrCalcModel = (props, id, isLightMode, cmInfoId, isInnerCM) => {
	const modelProps = {
		...props,
		expIndex: id, 
		isLightMode: isLightMode,
		cmInfoId: cmInfoId,
		isInnerCM: isInnerCM
	};

	return (
	   <>
			{id === CALC_MODEL.MAGNETIC_FIELD && <CoilMagneticField  {...modelProps} />}
			{id === CALC_MODEL.LENSE && <Lense {...modelProps} />}
			{id === CALC_MODEL.DIFFRACTION && <Diffraction {...modelProps} />}
			{id === CALC_MODEL.INTERFERENCE && <Interference {...modelProps} />}
			{id === CALC_MODEL.CAPACITOR && (<Electrodynamics initExperimentType={ELDYN_EXP_TYPE.CAPACITOR} {...modelProps} />)}
			{id === CALC_MODEL.INDUCTANCE && (<Electrodynamics initExperimentType={ELDYN_EXP_TYPE.INDUCTANCE} {...modelProps} />)}
			{id === CALC_MODEL.OSC_CIRCUIT && (<Electrodynamics initExperimentType={ELDYN_EXP_TYPE.OSC_CIRCUIT} {...modelProps} />)}
			{id === CALC_MODEL.RESONANCE && (<Electrodynamics initExperimentType={ELDYN_EXP_TYPE.RESONANCE} {...modelProps} />)}
			{id === CALC_MODEL.MECHANIC_MOVE && (<MechanicMove isLightMode={isLightMode} {...modelProps} />)}
		</>
	);
};

export const getSectionTab = (index, name, iconName, tooltip, _handleSelectTab, isUsed = true) => {
	let classNames = 'InitSectionTabs__tab';
	if (!isUsed) {
	  classNames += ' disabled';
	}
  
	return (
	  	<div className={classNames} 
			onClick={() => isUsed ? _handleSelectTab(index) : {}} 
			key={'st01'+index}
		>
			<div className="InitSectionTabs__content" key={'st02'+index}>
				<div className="InitSectionTabs__iconBox" key={'st03'+index}>
					<Icon name={iconName} className="InitSectionTabs__icon" key={'st04'+index}/>
				</div>
				<div className="InitSectionTabs__name" key={'st05'+index}>{name}</div>
		  	</div>
  
		  	<Tooltip className="InitSectionTabs__tooltip" position='custom'>{tooltip}</Tooltip>
	  </div>
	);
};
  
export const getOtPiInfoCounters = async (classId, researches) => {
	const getAllTeacherNonCheckedOtTasks = async (room) => {
		const result = await onlineTestInfoApi.getAllTeacherNonCheckedOtTasks(room);
    	const data = result.data.filter(item => item.answers.length > 0);
    	return data.length;
	};

	const getTeacherNonCheckedPiTasks = async (res) => {
    	return res?.length > 0 ? res?.length : null;
	};

	let otValue = null;
	let piValue = null;

	if (isAuth().role > 0) {
	  	otValue = await getAllTeacherNonCheckedOtTasks(classId);
	  	piValue = await getTeacherNonCheckedPiTasks(researches);
	} else {
	  	const studentResearches = researches.filter(item => item.owner._id === isAuth()._id);
	  	piValue = await getTeacherNonCheckedPiTasks(studentResearches);
	}

	return [otValue, piValue];
};

export const getPrInfoCounter = async (classId, researches) => {
	const status = isAuth().role > 0 ? WS_TYPE.CHECK_IN_PROGRESS : WS_TYPE.WORK;
	const _researches = researches.filter(item => item.status === status);
	const [otValue, piValue] = await getOtPiInfoCounters(classId, _researches);
	let sum = 0;
	if (otValue) sum += otValue;
	if (piValue) sum += piValue;
	return sum;
};

export const getNbInfoCounter = async (classId) => {
	const getStartedNbTaskCounter = async () => {
		const result = await getAllNotebookInfoByOwner(isAuth()._id);
		const nbis = result.data;
		let counter = 0;

		for (let i = 0; i < nbis.length; i ++) {
			const nbi = nbis[i];
			const nb = await getNotebookById(nbi.notebook);
			const [isStarted] = isNbTaskStarted(nb, nbi);
			if (isStarted) counter ++;
		}

		return counter;
	}

	if (isAuth().role > 0) {
		//non-checked results:
		const result = await notebookInfoApi.getAllNonCheckedNbi(classId);
		return result.data.length > 0 ? result.data.length : null;
	} else {
		//started nb tasks:
		return await getStartedNbTaskCounter();
	}
};
