import chartColors from '../../../styles/charts';
import uPlot from 'uplot';
import { ComplexArray } from 'jsfft';

export const timeToSecond = (ms) => {
    return +(ms / 1000).toFixed(3);
};

export const getRelativePositionLeft = (u, e) => {
    if (!u) return;
    const { left } = u?.over.getBoundingClientRect();
    return Math.round(e.clientX - left);
};

export const getRelativePositionTop = (u, e) => {
    if (!u) return;
    const { top } = u?.over.getBoundingClientRect();
    return Math.round(e.clientY - top);
};

export const getXValue = (pos, u) => {
    return Math.round(u.posToVal(pos, 'x'));
};

export const getYValue = (pos, u) => {
    return Math.round(u.posToVal(pos, 'y'));
};

export const getXPosition = (value, u) => {
    return Math.round(u.valToPos(value, 'x'));
};

export const fnOrSelf = (v) => (typeof v == 'function' ? v : () => v);

export const updateChartOptions = ({ chart, markerMode, weight, color, pointWidth, pointColor, theme, showGrid, isZoom, interpolation, bars, record, xHandle, yHandle, markers, markerActions }) => {
    if (!chart) return;
    let paths;

    switch (interpolation) {
        case 'linear':
            paths = uPlot.paths.linear({ alignGaps: -1 });
            break;

        case 'bundle':
            paths = uPlot.paths.spline({ alignGaps: 2 });
            break;

        case 'cardinal':
            paths = uPlot.paths.spline({ alignGaps: -1 });
            break;

        case 'step':
            paths = uPlot.paths.stepped({ alignGaps: 0 });
            break;

        case 'stepafter':
            paths = uPlot.paths.stepped({ alignGaps: -1 });
            break;

        case 'stepbefore':
            paths = uPlot.paths.stepped({ alignGaps: 1 });
            break;

        default:
            paths = uPlot.paths.spline();
            break;
    }

    chart.zoomPan = { xHandle, yHandle };
    chart.series.forEach((s, i) => {
        if (i === 0) return;
        chart.series[i] = {
            ...s,
            stroke: fnOrSelf(color),
            width: weight,
            paths: bars ? uPlot.paths.bars(bars) : paths,
            points: {
                ...chart.series[i].points,
                show: fnOrSelf(bars ? false : pointWidth),
                size: pointWidth || 0,
                fill: fnOrSelf(pointColor),
                stroke: fnOrSelf(pointColor),
            },
        };
    });

    chart.axes.forEach((a, i) => {
        chart.axes[i] = {
            ...a,
            grid: {
                ...chart.axes[i].grid,
                stroke: fnOrSelf(theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light),
                show: showGrid,
            },
            ticks: {
                ...chart.axes[i].ticks,
                stroke: fnOrSelf(theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light),
                show: showGrid,
            },
        };
    });

    chart.cursor.show = markerMode === 'cursor';
    chart.cursor.x = markerMode === 'cursor';
    chart.cursor.y = markerMode === 'cursor';
    chart.cursor.drag.x = isZoom;
    chart.setCursor({ ...chart.cursor });

    chart.annotations.markerActions = markerActions;
    chart.annotations.markers = markers || {};

    // console.log(markers);

    chart.redraw(false, true);

    // setOpts(
    //     (opt) =>
    //         (opt = {
    //             ...opt,
    //             cursor: {
    //                 ...opt.cursor,
    //                 show: !record,
    //                 x: markerMode === 'cursor',
    //                 y: markerMode === 'cursor',
    //                 drag: {
    //                     ...opt.cursor.drag,
    //                     x: isZoom,
    //                 },
    //             },
    //         })
    // );
};

export const movingAverage = (samples, window = 5) => {
    if (!samples || samples.length < window) {
        return [];
    }

    let index = window - 1;
    const length = samples.length + 1;

    const simpleMovingAverages = [];

    while (++index < length) {
        const windowSlice = samples.slice(index - window, index);
        const sum = windowSlice.reduce((prev, curr) => prev + curr, 0);
        simpleMovingAverages.push(sum / window);
    }

    return simpleMovingAverages;
};

export const findAllPulseTimes = (sampleTimes, samples, raiseThres = 0.2) => {
    let times = [];
    if (samples.length < 2) {
        return times;
    }
    let sampleTimeCur = sampleTimes[0];
    let sampleTimePrev = sampleTimes[0];
    let sampleCur = samples[0];
    let samplePrev = samples[0];
    let raiseStartIndex = -1;
    let raiseStartSample = 0.0;
    let raiseEndSample = 0.0;
    for (let i = 0; i < samples.length; i++) {
        sampleTimePrev = sampleTimeCur;
        sampleTimeCur = sampleTimes[i];
        samplePrev = sampleCur;
        sampleCur = samples[i];
        if (sampleCur > samplePrev) {
            if (raiseStartIndex === -1) {
                raiseStartIndex = i - 1;
                raiseStartSample = samplePrev;
            }
        } else if (raiseStartIndex >= 0) {
            raiseStartIndex = -1;
            raiseEndSample = samplePrev;
            if (raiseEndSample - raiseStartSample >= raiseThres) {
                times.push(sampleTimePrev);
            }
        }
    }
    return times;
};

/**
 * Sliced priod
 */
let filterPeriod = 512;
/**
 * Initializes a windowed Hann (wHan) array of size `filterPeriod`.
 *
 * @param {number} filterPeriod - The size of the wHan array.
 * @returns {Array} - An array of windowed Hann weights.
 *
 * @example
 * const wHanArray = initializeWindowedHann(512);
 * console.log(wHanArray); // Output: [0.05, 0.15, 0.25, 0.15, 0.05, ..., 0.05]
 */
function initializeWindowedHann(filterPeriod) {
    const wHan = [];
    for (let i = 0; i < filterPeriod; i++) {
        wHan[i] = 0.5 * (1 - Math.cos((2 * Math.PI * i) / filterPeriod));
    }
    return wHan;
}
const wHan = initializeWindowedHann(filterPeriod);

/**
 * Function to slice an array into smaller chunks of a specified size.
 *
 * @param {Array} arr - The input array to be sliced.
 * @param {number} chunkSize - The size of each chunk.
 * @returns {Array} - An array of chunks, where each chunk is a sub-array of the input array.
 *
 * @example
 * const inputArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
 * const chunkedArray = sliceIntoPeriod(inputArray, 3);
 * console.log(chunkedArray); // Output: [[1, 2, 3], [4, 5, 6], [7, 8, 9], [10]]
 */
// function sliceIntoPeriod(arr, chunkSize) {
//     const res = [];
//     for (let i = 0; i < arr.length; i += chunkSize) {
//         const chunk = arr.slice(i, i + chunkSize);
//         res.push(chunk);
//     }
//     return res;
// }

/**
 * Function to get the filtered data from the input data and filters.
 *
 * @param {Array[]} arr - The input data, where each element is an array of time and corresponding values.
 * @param {Object} filters - The filters to apply to the input data, including 'filter' (lower, upper, bandpass, reject), 'minFreq', and 'maxFreq'.
 * @returns {Array[]} - An array of two arrays, where the first array contains the time values and the second array contains the filtered values.
 *
 * @example
 * const inputData = [[1, 2, 3, 4, 5], [6, 7, 8, 9, 10]];
 * const filters = {
 *     filter: 'lower',
 *     minFreq: 1000,
 *     maxFreq: 2000
 * };
 * const filteredData = getFilteredData(inputData, filters);
 * console.log(filteredData); // Output: [[1, 2, 3, 4, 5], [0.0, 0.0, 0.0, 0.0, 0.0]]
 */
export const getFilteredData = (arr, filters) => {
    const filtered = [[], []];

    if (filters === null || !arr?.length) return filtered;

    const { filter, minFreq, maxFreq } = filters;
    const times = arr[0];
    const vals = arr[1];
    const fd = ((times.length - 1) / (times.at(-1) - times[0])) * 1000;
    filterPeriod = Math.max(Math.round(Math.pow(2, Math.floor(Math.log2(fd * 4)))), 128);
    const iFreqMin = (minFreq / fd) * filterPeriod;
    const iFreqMax = (maxFreq / fd) * filterPeriod;
    // console.log('fd', fd);
    // console.log('filterPeriod', filterPeriod);
    if (isNaN(filterPeriod) || times.length < filterPeriod) return arr;

    let filterFun = (elem, i, n) => {};
    switch (filter) {
        case 'lower':
            filterFun = (elem, i, n) => {
                if (i <= n / 2 && i > iFreqMax) {
                    elem.real = 0;
                    elem.imag = 0;
                }
                if (i > n / 2 && i < n - 1 - iFreqMax) {
                    elem.real = 0;
                    elem.imag = 0;
                }
            };
            break;
        case 'upper':
            filterFun = (elem, i, n) => {
                if (i <= n / 2 && i < iFreqMin) {
                    elem.real = 0;
                    elem.imag = 0;
                }
                if (i > n / 2 && i > n - 1 - iFreqMin) {
                    elem.real = 0;
                    elem.imag = 0;
                }
            };
            break;
        case 'bandpass':
            filterFun = (elem, i, n) => {
                if (i <= n / 2 && (i < iFreqMin || i > iFreqMax)) {
                    elem.real = 0;
                    elem.imag = 0;
                }
                if (i > n / 2 && (i > n - 1 - iFreqMin || i < n - 1 - iFreqMax)) {
                    elem.real = 0;
                    elem.imag = 0;
                }
            };
            break;
        case 'reject':
            filterFun = (elem, i, n) => {
                if (i <= n / 2 && i >= iFreqMin && i <= iFreqMax) {
                    elem.real = 0;
                    elem.imag = 0;
                }
                if (i > n / 2 && i <= n - 1 - iFreqMin && i >= n - 1 - iFreqMax) {
                    elem.real = 0;
                    elem.imag = 0;
                }
            };
            break;
        default:
            break;
    }

    // const segment = times.length % filterPeriod;
    // const segmentArr = new Array(segment).fill(0);
    // const sliced = times.length - segment;

    // filtered[0] = times.slice(0, sliced);
    filtered[1] = new Array(times.length).fill(0);
    // console.log(sliced);
    // filtered[1] = new Array(sliced).fill(0);
    // filtered[1] = new Array(sliced).fill(0);

    filtered[0] = times;
    // filtered[1] = new Array(times.length - (times.length % filterPeriod)).fill(0);

    // console.log(filtered[1]);

    for (let iLeft = 0; iLeft <= filtered[1].length - filterPeriod; iLeft += filterPeriod / 2) {
        const valsComplex = new ComplexArray(vals.slice(iLeft, iLeft + filterPeriod));
        const spectrum = valsComplex.FFT();
        const spectrumFilt = spectrum.map(filterFun);
        const chunkFiltered = spectrumFilt.InvFFT().real;
        // console.log(chunkFiltered);
        if (iLeft < filterPeriod / 2) {
            for (let i = 0; i < filterPeriod / 2; i++) {
                // console.log(1);
                filtered[1][iLeft + i] += chunkFiltered[i];
            }
            for (let i = filterPeriod / 2; i < filterPeriod; i++) {
                // console.log(2);
                filtered[1][iLeft + i] += chunkFiltered[i] * wHan[i];
            }
        } else if (iLeft >= filtered[1].length - filterPeriod) {
            for (let i = 0; i < filterPeriod / 2; i++) {
                // console.log(3);
                filtered[1][iLeft + i] += chunkFiltered[i] * wHan[i];
            }
            for (let i = filterPeriod / 2; i < filterPeriod; i++) {
                // console.log(4);
                filtered[1][iLeft + i] += chunkFiltered[i];
            }
        } else {
            for (let i = 0; i < filterPeriod; i++) {
                // console.log(5);
                filtered[1][iLeft + i] += chunkFiltered[i] * wHan[i];
            }
        }
    }

    // filterPeriod = 128;

    // for (let iLeft = 0; iLeft <= segmentArr.length - filterPeriod; iLeft += filterPeriod / 2) {
    //     const valsComplex = new ComplexArray(vals.slice(-segment).slice(iLeft, iLeft + filterPeriod));
    //     const spectrum = valsComplex.FFT();
    //     const spectrumFilt = spectrum.map(filterFun);
    //     const chunkFiltered = spectrumFilt.InvFFT().real;
    //     // console.log(chunkFiltered);
    //     if (iLeft < filterPeriod / 2) {
    //         for (let i = 0; i < filterPeriod / 2; i++) {
    //             // console.log(1);
    //             segmentArr[iLeft + i] += chunkFiltered[i];
    //         }
    //         for (let i = filterPeriod / 2; i < filterPeriod; i++) {
    //             // console.log(2);
    //             segmentArr[iLeft + i] += chunkFiltered[i] * wHan[i];
    //         }
    //     } else if (iLeft >= segmentArr.length - filterPeriod) {
    //         for (let i = 0; i < filterPeriod / 2; i++) {
    //             // console.log(3);
    //             segmentArr[iLeft + i] += chunkFiltered[i] * wHan[i];
    //         }
    //         for (let i = filterPeriod / 2; i < filterPeriod; i++) {
    //             // console.log(4);
    //             segmentArr[iLeft + i] += chunkFiltered[i];
    //         }
    //     } else {
    //         for (let i = 0; i < filterPeriod; i++) {
    //             // console.log(5);
    //             segmentArr[iLeft + i] += chunkFiltered[i] * wHan[i];
    //         }
    //     }
    // }

    // filtered[1] = [...filtered[1].slice(0, sliced), ...segmentArr];

    return filtered;
};
