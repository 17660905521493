import React, {useState}  from 'react';
import Modal from "../../Components/Modal";
import {Icon} from '../ui/';
import "../OnlineTest/OnlineTestCreate.scss";

const helpImage = 'tableDesc';//1.png';
const maxPageNum = 3;

const PIImageHelpDlg = ({ showModal, setShowModal }) => {
    const [previewModalSize, setPreviewModalSize] = useState("lg");
    const [pageNum, setPageNum] = useState(1);

    const handleChangePage = direct => {
        if (direct === -1 && pageNum > 1)
            setPageNum(pageNum - 1);
        else if (direct === +1 && pageNum < 3)
            setPageNum(pageNum + 1);
    };

    const setModalContent = () => {
        return (
            <div className="browsing">
                <div className="browsing__header">
                    <div className="browsing__title">
                        Просмотр
                    </div>
                    <div className="browsing__action">
                        <Icon name={previewModalSize === "lg" ? "grid_xl" : "minimize"}
                            onClick={() => setPreviewModalSize(previewModalSize === "lg" ? "browsing" : "lg")}/>
                        <Icon name="close" onClick={() => setShowModal(false)}/>
                    </div>
                </div>
                <div className="browsing__main">
                    <div className="browsing__img">
                        <img src={'/PhysicalInvest/'+ helpImage + pageNum + '.png'} alt=""/>
                    </div>
                </div>
                <div className="browsing__footer">
                    <Icon name="arrow-round-prev" onClick={() => handleChangePage(-1)}/>
                    {' Страница ' + pageNum + ' из ' + maxPageNum + ' '}
                    <Icon name="arrow-round-next" onClick={() => handleChangePage(+1)}/>
                </div>
            </div>
        )
    }

    if (!showModal) {
        return false;
    }

    return (
        <Modal visible={showModal} content={setModalContent()} size={previewModalSize} />
    )

};

export default PIImageHelpDlg;

