const initialState = {
    modal: false,
    options: undefined,
};

const videoReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_VIDEOEDITOR_MODAL':
            return {
                ...state,
                modal: action.payload,
            };

        case 'SET_VIDEOEDITOR_OPTIONS':
            return {
                ...state,
                options: action.payload,
            };

        default:
            return state;
    }
};

export default videoReducer;
