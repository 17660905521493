import React, { useState, useEffect, useCallback, useRef } from "react";
import { Icon, Trash } from '../ui';
import {Button} from 'rlabui';
import {useSelector, useDispatch} from "react-redux";
import { isAuth } from "../../helpers/auth";
import htmlParser from "html-react-parser";
import {getDropdown, getFiles} from "../ui/utils/gen_utils";
import {QUESTION_TYPES, UNDEFINED_QUESTION_TYPE, MAX_DESC_LENGTH, FORMAT_TYPE, 
    addQuestion, replaceQuestion, getOneOption,
    loadOTFiles, uploadOTFile, deleteOTFileByName, clearAllQuestionAttachments,
    getQuestionTypeList, hasNoErrors, getCurrentQuestion, } from "./ot_utils";
import ModalConfirmDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import ImageDlg from "../ui/ModalDialogs/ImageDlg";
import ModalConfirmChangeTypeDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import ModalConfirmLeavePageDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import Constructor1RestoreSequence from "./Constructor1RestoreSequence";
import Constructor2OneAnswer from "./Constructor2OneAnswer";
import Constructor3NonVerbal from "./Constructor3NonVerbal";
import {toast} from "react-toastify";
import { Back, Content, ContentBody, ContentFooter, ContentHead, ContentHeader } from "../template/ContentParts";
import ss from './OnlineTestCreateQuestions.module.scss';

const OnlineTestCreateQuestions = (props) => {
    const [currQuestionNum, setCurrQuestionNum] = useState(0);
    const [nextQuestionNum, setNextQuestionNum] = useState(0);
    const [questionName, setQuestionName] = useState("");
    const [qIllustration, setQIllustration] = useState("");
    const [currQuestionType, setCurrQuestionType] = useState(UNDEFINED_QUESTION_TYPE);
    const [desirableQuestionType, setDesirableQuestionType] = useState(UNDEFINED_QUESTION_TYPE);
	const [dataError, setDataError] = useState('');
    const [currAnswerOptions, setCurrAnswerOptions] = useState([]);
    const [currCorrectOptions, setCurrCorrectOptions] = useState('');
    const [changedData, setChangedData] = useState(false);
    const [isNewQuestion, setIsNewQuestion] = useState(props.questionList.length === 0);
    const [showConfirmDeleteDlg, setShowConfirmDeleteDlg] = useState(false);
    const [showConfirmChangeTypeDlg, setShowConfirmChangeTypeDlg] = useState(false);
    const [showConfirmLeavePageDlg, setShowConfirmLeavePageDlg] = useState(false);
    const [loadedFileName, setLoadedFileName] = useState('');
    const [showPictureDlg, setShowPictureDlg] = useState(false);
    const [fileUrl, setFileUrl] = useState('');
    const [currFormatType, setCurrFormatType] = useState(FORMAT_TYPE.IMAGE);
    const [isInit, setIsInit] = useState(false);
    const [reinitQuestion, setReinitQuestion] = useState(false); //to be used???

    const isSA = props.isSA;
    const isTemplate = props.isTemplate;
    const {lists: {files}} = useSelector(state => state);
    const dispatch = useDispatch();
	const changeNameCnt = useRef(0);

    useEffect(() => {
        if (!isTemplate)
            loadOTFiles(dispatch);
    }, [dispatch, loadedFileName, isTemplate]);

    useEffect(() => {
        if (isInit) return;
        setIsInit(true);
        if (!isNewQuestion)
            setQuestionOpts(props.questionList[0]);
    }, [isInit, props.questionList, isNewQuestion]);

    useEffect(() => {
        if (props.questionList.length > 0) return;
        cleanupQuestionOpts();
    }, [props.questionList]);

    const cleanupQuestionOpts = () => {
        setIsNewQuestion(true);
        setQuestionName('');
        setCurrQuestionType(UNDEFINED_QUESTION_TYPE);
        setQIllustration('');
        setCurrAnswerOptions([]);
        setCurrCorrectOptions('');
    };

    const setQuestionOpts = (question) => {
        setQuestionName(question.questionName);
        setCurrQuestionType(Number(question.questionType));
        setQIllustration(question.illustration ? JSON.parse(question.illustration) : '');
        setCurrAnswerOptions(question.answerOptions);
        setCurrCorrectOptions(question.correctOptions);
    };

    const getQuestion = useCallback(() => {
        const question = {
            questionName: questionName,
            questionType: currQuestionType,
            illustration: qIllustration ? JSON.stringify(qIllustration) : undefined,
            answerOptions: currAnswerOptions,
            correctOptions: currCorrectOptions,
        };
        return question;
    }, [currAnswerOptions, currCorrectOptions, currQuestionType, qIllustration, questionName]);

    const lockUpdateQList = useCallback((qList) => {
        props.setUpdateQList(qList);
        props.setShouldSave(true);
        setChangedData(false);
    }, [props]);

    const canSaveQuestion = useCallback(() => {
        return hasNoErrors(isNewQuestion, questionName, currQuestionType, dataError);
    }, [currQuestionType, dataError, isNewQuestion, questionName]);

    useEffect(() => {
        if (!props.isSaved || props.questionList.length === 0) return;

        props.setIsSaved(false);

        if (nextQuestionNum !== currQuestionNum) {
            setCurrQuestionNum(nextQuestionNum); //curr and next должны совпадать
        }

        setQuestionOpts(props.questionList[nextQuestionNum]);
    }, [props, nextQuestionNum, currQuestionNum]);

    const handleSaveQuestion = () => {
        if (!canSaveQuestion()) return;

        const question = getQuestion();

        const newList = isNewQuestion ? 
            addQuestion(props.questionList, question, currQuestionNum) : 
            replaceQuestion(props.questionList, question, currQuestionNum);            
        lockUpdateQList(newList);

        if (isNewQuestion) {
            setNextQuestionNum(newList.length === 1 ? 0 : currQuestionNum + 1);
            setIsNewQuestion(false);
        } else {
            setNextQuestionNum(currQuestionNum); //curr and next должны совпадать
        }
        toast.success('Изменения сохранены.');
    };

    const handlePrevNext = dir => {
        if (!canSaveQuestion()) return;
        let next = dir === 1 && currQuestionNum < props.questionList.length - 1 ? currQuestionNum + 1 : 
                   dir === -1 && currQuestionNum > 0 ? currQuestionNum - 1 : 
                   currQuestionNum;

        if (next !== currQuestionNum) {
            if (changedData && props.isEditable) {
                //save changes
                setChangedData(false);
                const question = getQuestion();
                const newList = replaceQuestion(props.questionList, question, currQuestionNum);
                lockUpdateQList(newList);
                setNextQuestionNum(next); //request to go to next question
                if (changedData && changeNameCnt.current > 1) {
                    toast.success('Изменения сохранены.');
                }
            } else{
                setCurrQuestionNum(next); //go to next question
                setQuestionOpts(props.questionList[next]);
            }
        }
        changeNameCnt.current = 0;
	};
    
    const handleBack = () => {
        if (!changedData || !props.isEditable) handleLeavePageYes();
        else if (changedData) setShowConfirmLeavePageDlg(true);
        else if (!isNewQuestion) props.setShowGeneral(true);
	};

    const handleLeavePageYes = () => {
        setIsNewQuestion(false);
        setShowConfirmLeavePageDlg(false);
        props.setShowGeneral(true);
    };

    const handleAddQuestion = () => {
        if (isNewQuestion) {
            if (hasNoErrors(isNewQuestion, questionName, currQuestionType, dataError)) {
                toast.warn("Сохраните введенный вопрос и перейдите к добавлению следующего.");
            }
        } else { //clean up to prepare for new question
            cleanupQuestionOpts();
            setIsNewQuestion(true); //initialize a new question
        }
	};

    const handleDeleteQuestionYes = () => {
		setShowConfirmDeleteDlg(false);
        if (isAuth().role < 3)
		    clearAllQuestionAttachments(props.questionList[currQuestionNum], props.onlineTestId, dispatch);

        const questions = props.questionList.filter((item, index) => currQuestionNum !== index);
        lockUpdateQList(questions);
        if (currQuestionNum > 0) setNextQuestionNum(currQuestionNum - 1);
	};
	
    const handleChangeQuestionName = value => {
        setQuestionName(value);
        if (changeNameCnt.current > 0) {
            setChangedData(true);
        }
        changeNameCnt.current ++;
    };

    const handleChangeQuestionIllustration = (value) => {
        setQIllustration(value);
        setChangedData(true);
    };

    const handleQuestionType = value => {
        const qType = Number(value);

        if (currQuestionType === UNDEFINED_QUESTION_TYPE) {
            //select a type
            setCurrQuestionType(qType);
            setChangedData(true);
        }
        else {
            //open a dialog to get confirmation to change the type
            setShowConfirmChangeTypeDlg(true);
            setDesirableQuestionType(qType);
        }
    };
    const handleClearQuestionTypeYes = () => {////////////////////////
        /////only for new questions
		setShowConfirmChangeTypeDlg(false);
        setCurrQuestionType(desirableQuestionType);
        setDesirableQuestionType(UNDEFINED_QUESTION_TYPE);
        //
        // if (!isNewQuestion) {
        //     setReinitQuestion(true);
        // }
    };

    const getButtonDisabled = (criteria) => (criteria ? false : true);

    //step 1 of 2: get file
    const handleChangeIllustration = (event, _files) => {
        if (!isTemplate) {
            if (qIllustration) deleteOTFileByName(dispatch, qIllustration.name);
            uploadOTFile(_files, event.target.files[0], setLoadedFileName);
        } else {
            setLoadedFileName(event.target.files[0]); //keep the file name and content
        }
        event.target.value = '';
    };
    //step 2 of 2: save file name (SA) or file (teacher)
    useEffect(() => {
        if (!loadedFileName) return;
        if (!isTemplate) {
            handleChangeQuestionIllustration({name: loadedFileName, illFileType: currFormatType});
            setLoadedFileName('');
        } else {
            const reader = new FileReader();
            reader.onload = (event) => {
                //DB will keep the file data: name and content
                const fileOpts = {name: loadedFileName.name, illFileType: currFormatType, content: event.target.result};
                handleChangeQuestionIllustration(fileOpts);
                setLoadedFileName('');
            };
            reader.readAsDataURL(loadedFileName); //if isTemplate the param contains the file (not just its name)
        }
    }, [isTemplate, loadedFileName, currFormatType]);

    const handleDeleteIllustrationFile = () => {
        if (!isTemplate)
            deleteOTFileByName(dispatch, qIllustration.name);
        handleChangeQuestionIllustration('');
    };

    const getQuestionTypeName = qType => 
        getQuestionTypeList(isTemplate, props.enabledQuestionTypes).find(item => item.value === qType).label;

    const getIllustration = () => {
        const getFormatList = () => {
            const list = [];
            list.push({label: 'Изображение', value: FORMAT_TYPE.IMAGE});
            if (!isTemplate)
                list.push({label: 'Видео', value: FORMAT_TYPE.VIDEO});
            list.push({label: 'Аудио', value: FORMAT_TYPE.AUDIO});
            return list;
        };


        const getAcceptableFormat = () => {
            if (currFormatType === FORMAT_TYPE.IMAGE) return "image/*";
            if (currFormatType === FORMAT_TYPE.VIDEO) return "video/*";
            return "audio/*";
        };

        const handleShowPicture = (_file) => {
            setFileUrl(_file);
            setShowPictureDlg(true);
        };
    
        const foundFiles = !qIllustration ? [] : !isTemplate ? 
            getFiles(files, [{name: qIllustration.name}]) : [qIllustration];

        let content;
        const ifType = Number(qIllustration.illFileType);

        if (foundFiles.length > 0) {
            content = !isTemplate ? foundFiles[0].Url : qIllustration.content;
        }
        
        return (
            <>
                {ifType || !props.isEditable ? <></> :
                    <div className="cor-net__row">
                        <div className="cor-net__col col-grow">
                            <div className="cor-net__label">Добавьте к вопросу</div>
                            {getDropdown(getFormatList(), currFormatType, v => setCurrFormatType(v), '', '', 'ft01')}
                        </div>
                        <div className="cor-net__col">
                            <div className="cor-net__label hide"></div>
                            <label className={ss.addOption} key='lbl01'>
                                <Icon name="plus" key='lbl02' />Добавить
                                <input type="file" accept={getAcceptableFormat()} onChange={(e) => handleChangeIllustration(e, files)} />
                            </label>
                        </div>
                    </div>
                }

                {qIllustration && foundFiles.length > 0 && (
                    <div className={ss.preview}>
                        {ifType === FORMAT_TYPE.IMAGE && 
                        <div className={ss.preview__view}>
                            <img 
                                src={content} alt=''
                                onClick={() => handleShowPicture(!isTemplate ? foundFiles[0] : JSON.stringify(foundFiles[0]))} 
                            />
                            {(!isTemplate || isSA) && 
                            <Trash onClick={() => handleDeleteIllustrationFile()} bg={true} className={ss.preview__trash} />}
                        </div>
                        }
                        {ifType === FORMAT_TYPE.VIDEO && !isSA &&
                        <div className={ss.preview__view}>
                           <video src={content} controls={true}></video>
                 
                            {(!isTemplate || isSA) && 
                            <Trash onClick={() => handleDeleteIllustrationFile()} bg={true} className={ss.preview__trash} />}
                        </div>
                        }
                        {ifType === FORMAT_TYPE.AUDIO && 
                        <div className={ss.preview__audio}>
                            <audio src={content} volume={0} controls={true} width="100" height="30" />
                            {(!isTemplate || isSA) && <Trash onClick={() => handleDeleteIllustrationFile()} />}
                        </div>
                        }
                    </div>
                )}
            </>
        );
    };

    return (
	    <>
            <ContentHead flex={false}>
                <Back onClick={handleBack} icon="back" margin="bottom" text="Вернуться в редактор для сохранения теста" />
                <div className="cor_content__title">Добавление задания: {props.taskTypeList[props.taskType].name}</div>
            </ContentHead>

            <Content>
                <ContentHeader flex={true}>
                    <>{isNewQuestion ? 'Новый вопрос' : ('Вопрос ' + (props.questionList.length > 0 ? (currQuestionNum+1) : currQuestionNum)) + ' / ' + props.questionList.length}</>

                    {props.isEditable &&
                    <Button onClick={handleAddQuestion} icon={true} border={true}>
                        <Icon name="plus_bold" />
                        Добавить новый вопрос
                    </Button>}
                </ContentHeader>
                <ContentBody>
                    {isNewQuestion || props.questionList.length > 0 ? 
                    (
                        <div className={ss.root}>
                            <div className="cor-net__section border">
                                <div className="cor-net__row">
                                    <div className="cor-net__col col-2">
                                        <div className={!isNewQuestion ? "cor-net__title" : "cor-net__label"}>
                                            Тип вопроса{!isNewQuestion && ': ' + getQuestionTypeName(currQuestionType)}
                                        </div>
                                        {isNewQuestion &&
                                        <div>
                                            {getDropdown(
                                                getQuestionTypeList(isTemplate, props.enabledQuestionTypes), 
                                                currQuestionType, handleQuestionType, 
                                                '', '', 'quest01')}
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div className="cor-net__row">
                                    <div className={ss.question}>
                                        <div className={ss.question__title}>
                                            {props.isEditable ?
                                            getOneOption(0, 'Вопрос', questionName, 
                                                handleChangeQuestionName, null, MAX_DESC_LENGTH)
                                            : 
                                            htmlParser(questionName)
                                            }
                                        </div>
                                        <div className={ss.question__file}>
                                            {getIllustration()}
                                        </div>
                                    </div>
                                  
                                </div>
                                
                            </div>

                            {currQuestionType !== UNDEFINED_QUESTION_TYPE && !props.isSaved && (
                            <>
                                {(
                                currQuestionType === QUESTION_TYPES.CORRECT_IMAGE_SEQUENCE ||
                                currQuestionType === QUESTION_TYPES.CORRECT_WORD_SEQUENCE) && 
                                    <Constructor1RestoreSequence
                                        currQuestion={getCurrentQuestion(props.questionList, isNewQuestion, currQuestionNum)} 
                                        setDataError={setDataError}
                                        currAnswerOptions={currAnswerOptions}
                                        setCurrAnswerOptions={setCurrAnswerOptions}
                                        currCorrectOptions={currCorrectOptions} //we keep the order in the correctOptions field
                                        setCurrCorrectOptions={setCurrCorrectOptions} //we keep the order in the correctOptions field
                                        questionType={Number(currQuestionType)}
                                        setChangedData={setChangedData}
                                        onlineTestId={props.onlineTestId}
                                        isEditable={props.isEditable}
                                        isTemplate={isTemplate}
                                    />
                                }
        
                                {(currQuestionType === QUESTION_TYPES.CORRECT_ANSWER_ONE || 
                                currQuestionType === QUESTION_TYPES.CORRECT_ANSWER_MANY ||
                                currQuestionType === QUESTION_TYPES.COMPLETE_SENTENCE ||
                                currQuestionType === QUESTION_TYPES.VIDEO ||
                                currQuestionType === QUESTION_TYPES.FREE_WRITING) && 
                                    <Constructor2OneAnswer 
                                        currQuestionNum={currQuestionNum}
                                        currQuestionType={Number(currQuestionType)}
                                        questionList={props.questionList}
                                        isNewQuestion={isNewQuestion}
                                        reinitQuestion={reinitQuestion}
                                        setReinitQuestion={setReinitQuestion}
                                        dataError={dataError}
                                        setDataError={setDataError}
                                        setCurrAnswerOptions={setCurrAnswerOptions}
                                        setCurrCorrectOptions={setCurrCorrectOptions}
                                        changedData={changedData}
                                        setChangedData={setChangedData}
                                        onlineTestId={props.onlineTestId}
                                        isEditable={props.isEditable}
                                        isTemplate={isTemplate}
                                    />
                                }
                                {(currQuestionType === QUESTION_TYPES.NON_VERBAL ||
                                  currQuestionType === QUESTION_TYPES.AUDIO) &&
                                    <Constructor3NonVerbal
                                        currQuestionNum={currQuestionNum}
                                        currQuestionType={Number(currQuestionType)}
                                        questionList={props.questionList}
                                        isNewQuestion={isNewQuestion}
                                        setDataError={setDataError}
                                        setCurrAnswerOptions={setCurrAnswerOptions}
                                        setCurrCorrectOptions={setCurrCorrectOptions}
                                        changedData={changedData}
                                        setChangedData={setChangedData}
                                        onlineTestId={props.onlineTestId}
                                        isEditable={props.isEditable}
                                        isTemplate={isTemplate}
                                    />
                                }
                            </>
                            )}

                        </div>
                    )
                    : 
                        <></>
                    }
                </ContentBody>
                <ContentFooter className={ss.nav}>
                    {props.isEditable ?
                        <div>
                            <button
                                type="button"
                                onClick={handleSaveQuestion}
                                className={ss.btn}
                                disabled={getButtonDisabled(isNewQuestion || changedData)}
                            >
                                <Icon name="save" />
                                Сохранить вопрос
                            </button>
                        </div>
                        :
                        <span></span>
                    }

                    <div>
                        <button
                            type="button"
                            onClick={() => handlePrevNext(-1)}
                            className={ss.btn}
                            disabled={getButtonDisabled(currQuestionNum > 0 && !isNewQuestion)}
                        >
                            <Icon name="arrow-round-prev" />
                            Предыдущий вопрос
                        </button>
                    </div>

                    <div>
                        <button
                            type="button"
                            onClick={() => handlePrevNext(+1)}
                            className={ss.btn}
                            disabled={getButtonDisabled(currQuestionNum < props.questionList.length - 1 && !isNewQuestion)}
                        >
                            Следующий вопрос
                            <Icon name="arrow-round-next" />
                        </button>
                    </div>

                    {props.isEditable ?
                        <div>
                            <button
                                type="button"
                                onClick={ () => props.questionList.length ? setShowConfirmDeleteDlg(true) : {} }
                                className={ss.btn}
                                disabled={getButtonDisabled(props.questionList.length && !isNewQuestion)}
                            >
                                <Icon name="delete" />
                                Удалить вопрос
                            </button>
                        </div>
                        :
                        <span></span>
                    }
                </ContentFooter>
            </Content>

            {showConfirmDeleteDlg &&
			<ModalConfirmDialog
				showConfirmDlg={showConfirmDeleteDlg} 
				question={'Вы действительно хотите удалить этот вопрос?'}
				handleYes={handleDeleteQuestionYes}
				handleNo={() => setShowConfirmDeleteDlg(false)}
			/>
            }
            {showConfirmChangeTypeDlg && 
			<ModalConfirmChangeTypeDialog
				showConfirmDlg={showConfirmChangeTypeDlg} 
				question={'При изменении типа вопроса введенные данные будут потеряны. Продолжить?'}
				handleYes={handleClearQuestionTypeYes}
				handleNo={() => setShowConfirmChangeTypeDlg(false)}
			/>
            }
            {showConfirmLeavePageDlg &&
            <ModalConfirmLeavePageDialog
                showConfirmDlg={showConfirmLeavePageDlg} 
                question={'При выходе несохраненные данные будут потеряны. Продолжить?'}
                handleYes={handleLeavePageYes}
                handleNo={() => setShowConfirmLeavePageDlg(false)}
            /> 
            }

            {showPictureDlg &&
                <ImageDlg
                    showModal={showPictureDlg}
                    setShowModal={setShowPictureDlg}
                    file={fileUrl}
                />
            }
        </>
    )
}

export default OnlineTestCreateQuestions;
