export const wheelZoomPlugin = ({factor = 0.9}) => {
  let xUMin, xUMax, yUMin, yUMax, xRange, yRange;
  return {
    hooks: {
      ready: (u) => {
        u.root.addEventListener('wheel', wheel);

        function wheel(e) {
          e.preventDefault();
          const {target} = e;
          const {xHandle, yHandle} = u.zoomPan;
          if (!xHandle && !yHandle) return;

          const x = target.classList.contains('x-axis');
          const y = target.classList.contains('y-axis');
          const area = target.classList.contains('u-over');

          xUMin = u.scales.x.min;
          xUMax = u.scales.x.max;
          yUMin = u.scales.y.min;
          yUMax = u.scales.y.max;
          xRange = xUMax - xUMin;
          yRange = yUMax - yUMin;

          let xHover = x || (area && !y);
          let yHover = y || (area && !x);
          let rect = u.over.getBoundingClientRect();
          let rootRect = u.root.getBoundingClientRect();
          let {left, top} = u.cursor;
          let leftPct = left / rect.width;
          let btmPct = 1 - top / rect.height;

          if (xHover) {
            left = e.clientX - rootRect.x;
            leftPct = left / rect.width;
          }
          if (yHover) {
            top = e.clientY - rootRect.y;
            btmPct = 1 - top / rect.height;
          }

          let xVal = u.posToVal(left, 'x');
          let yVal = u.posToVal(top, 'y');
          let nxRange = e.deltaY < 0 ? xRange * factor : xRange / factor;
          let nxMin = xVal - leftPct * nxRange;
          let nxMax = nxMin + nxRange;
          let nyRange = e.deltaY < 0 ? yRange * factor : yRange / factor;
          let nyMin = yVal - btmPct * nyRange;
          let nyMax = nyMin + nyRange;

          if ((x || area) && xHandle) {
            u.setScale('x', {min: nxMin, max: nxMax});
          }

          if ((y || area) && yHandle) {
            u.axes.forEach((a, i) => i > 0 && u.setScale(a.scale, {min: nyMin, max: nyMax}));
          }
        }
      },
    },
  };
};
