import React from 'react';
import Modal from "../../Components/Modal";
import { Button } from '../ui';
import '../../rlab/src/components/VideoEditor/VideoEditor.scss';

const PIDrawToolDetailsDlg = ({showDlg, setShowDlg}) => {
      const getInstruction = () => { //it contains the instruction in the right side:
        const instructionList = [
            // 'Для того, чтобы делать измерения корректно, вам нужно установить размер кадра и единицы измерения.',
            // 'В качестве Начальной величины установлен 1 мм, но вы можете установить шкалу также в микронах, сантиметрах, метрах и километрах.',
            'Если изображение было сделано ранее, то необходимо вписать его заведомо известный размер.',
            'Если изображение необходимо получить с цифрового микроскопа, то выполняются следующие операции:',
            'Установите ПО для цифрового микроскопа с помощью носителя из комплекта поставки микроскопа.Подключите микроскоп к устройству через USB – порт.',
            'Откройте приложение для цифрового микроскопа и убедитесь, что изображение с него идет.Снимите с камеры микроскопа защитную крышку, установите микроскоп камерой вертикально вплотную на калибровочный круг карточки из комплекта поставки.',
            'Добейтесь четкого изображения шкалы, изменяя кратность фокусировки колесиком на микроскопе.Установите микроскоп в начале шкалы для расчета размера кадра (количество делений шкалы, находящееся в кадре).',
            'Впишите полученное значения размера кадра. Для смены единицы измерения размера кадра необходимо выбрать её во всплывающем списке.Учтите, что для последующих измерений нельзя менять фокусировку микроскопа, это приведет к сбою калибровки, что впоследствии скажется на точности показаний.',
            'В приложении для цифрового микроскопа сделайте снимок и загрузите его.',
        ];
        
        return (
            <>
                {instructionList.map((item, ind) => {
                    return (
                        <div className="modal__row" key={'in01'+ind}>{item}</div>
                    );
                })}
            </>
        );
    };

    const setModalContent = () => {
        return (
            <div>
                <div className="modal__close" onClick={() => setShowDlg(false)}></div>
                <div className="modal__message">Установка размера изображения</div>
                <div className="modal__body">
                    {getInstruction()}
                </div>
                <div className="modal__action modal__action_end">
                    <Button color="primary" border={true} onClick={() => setShowDlg(false)}>Закрыть</Button>
                </div>
            </div>
        )
    }

    if (!showDlg) {
        return false;
    }
    
    return (
        <Modal visible={showDlg} content={setModalContent()} size={'lg'} />
    )
};

export default PIDrawToolDetailsDlg;
