import React, { useState } from 'react';
import { Icon } from '../../ui';
import ss from './Ranges.module.scss';
import MarkersRezults from '../../MarkersRezults';
import ArtPressure from '../ArtPressure';

const { panel, hide, visible, head, title, body, toggle, icon } = ss;

const Ranges = ({ ranges, isArtPressure }) => {
    const [show, setShow] = useState(true);

    return (
        <div className={ranges || isArtPressure ? (show ? visible : panel) : hide}>
            <div className={head}>
                <div
                    className={toggle}
                    onClick={() => setShow(!show)}
                >
                    <Icon
                        name="toggler"
                        className={icon}
                    />
                </div>
                <div className={title}>{isArtPressure ? 'Показания АД' : 'Диапазоны'}</div>
            </div>
            <div className={body}>
                {isArtPressure && <ArtPressure />}
                <MarkersRezults />
            </div>
        </div>
    );
};
export default Ranges;
