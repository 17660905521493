import React, {useEffect, useState} from 'react';
import {fullName, isAuth} from "../../../helpers/auth";
import './Header.scss';
import ThemeSwitcher from '../ThemeSwitcher';
import FontResize from '../FontResize';
import Fullscreen from '../Fullscreen';
import SettingsIcon from '../SettingsIcon';
import {useDispatch, useSelector} from "react-redux";
import {
  reloadUser,
  storageClass,
  storageOrg,
  storageUser
} from "../../../redux/slices/storage";
import {printUserRole} from "../../../helpers/text";
import CorVersion from "./CorVersion";
import {Icon} from "../../ui";
import {ClassSettings, StorageSettings, UserPassword, UserSettings, NameSettings} from "../../Settings";
import {newsApi, userApi} from "../../../api/api";

const HeaderBar = (props) => {
  const dispatch = useDispatch();
  const documentClass = useSelector(storageClass);
  const documentUser = useSelector(storageUser);
  const documentOrg = useSelector(storageOrg);
  const [modal, toggleModal] = useState(false);
  const [versionList, setVersionList] = useState([]);

  useEffect(() => {
    if (isAuth().role === 2 && isAuth().status !== 2) {
      setTimeout(() => props.showUserSettings({showModal: true, role: 2, isReg: true, user: {_id: isAuth()._id}}, 1000));
    } else {
      newsApi.getByType(0).then((vl) => {
        setVersionList(vl);
        if (vl.length > 0 && isAuth().role < 3 && isAuth().custom?.corVersion !== vl[0].name) {
          userApi.update(isAuth()._id, {custom: {corVersion: vl[0].name}})
          .then((updated) => dispatch(reloadUser(updated)));
          setTimeout(() => toggleModal(true), 1000);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (<header className="cor_header">
    <div className="cor_header__content">
      <div className="cor_header__left">
        <div className="cor_header__logo" onClick={() => props.history.push('/')}>
          <img src='/logo_w.svg' alt=""/>
        </div>
        <div className="cor_header__ver tp tp_down" data-tp="Текущая версия" onClick={() => toggleModal(true)}>
          {versionList[0]?.name ?? "V 0.0.0"}
        </div>
      </div>
      <div className="cor_header__center">
        {documentUser.role > 1 && <div><Icon name="user-place"/> {printUserRole(documentUser.role)}</div>}
        {documentUser.role < 3 && <div><Icon name="school"/> Школа №{documentOrg.name}</div>}
        {documentUser.role < 3 && <div><Icon name="user-group"/> Класс {documentClass?.className}</div>}
        {documentUser.role < 2 &&
          <div><Icon name={documentUser.role > 0 ? "teacher" : "student"}
          /> {printUserRole(documentUser.role)} {fullName(documentUser)}</div>}
      </div>
      <div className="cor_header__right">
        <ThemeSwitcher/>
        <SettingsIcon/>
        <FontResize/>
        <Fullscreen/>
      </div>
    </div>
    <UserSettings/>
    <UserPassword/>
    <ClassSettings/>
    <StorageSettings/>
    <NameSettings/>
    <CorVersion modal={modal} toggleModal={toggleModal} versionList={versionList}/>
  </header>)
};
export default HeaderBar;
