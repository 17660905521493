import React, {useState, useEffect} from "react";
import { Textarea } from 'rlabui';

const ScenarioOneAnswer = ({cellType, cellInd, cellValue, handleSaveValue}) => {
	const [currValue, setCurrValue] = useState(cellValue !== null ? cellValue : '');

    useEffect(() => {
        setCurrValue(cellValue !== null ? cellValue : '');
    }, [cellValue]);

    const handleChangeValue = (currValue) => {
        const SIZE = 200;
        const value = currValue.length <= SIZE ? currValue : currValue.substring(0, SIZE);

        setCurrValue(value);
        handleSaveValue(cellType, value, cellInd);
    };

    return (
        <div key={'cl01'+cellInd} className='' >
                <Textarea
                    id={'ta'+cellInd}
                    key={'ta'+cellInd} 
                    type={'text'}
                    value={currValue} 
                    rows={12}
                    placeholder="Впишите ваш ответ в это поле"
                    onChange={e => handleChangeValue(e.target.value)}
                /> 
		</div>
    );
}

export default ScenarioOneAnswer; 