import React from 'react';
import ss from './AboutModal.module.scss';
import { Button, Modal } from 'rlabui';

const AboutModal = ({ modal, setAboutModal }) => {
    return (
        <Modal
            visible={modal}
            size="xs"
        >
            <Modal.Head
                title="О программе"
                modalClose={() => setAboutModal(false)}
            />
            <Modal.Body>
                <ul className={ss.list}>
                    <li>
                        <b>Версия программы:</b>
                        <span>{window.version}</span>
                    </li>
                    <li>
                        <b>Веб-сайт:</b>
                        <a
                            href="https://robiclab.ru"
                            target="blank"
                        >
                            robiclab.ru
                        </a>
                    </li>
                    <li>
                        <b>Техническая поддержка:</b>
                        <a href="mailto: help@Robiclab.ru">help@Robiclab.ru</a>
                    </li>
                    <li>
                        <b>Телефон:</b>
                        <a href="tel: 8 (800) 101-74-47">8 (800) 101-74-47</a>
                    </li>
                    <li>
                        <a
                            href={window.instruction}
                            target="blank"
                        >
                            Скачать инструкцию
                        </a>
                    </li>
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setAboutModal(false)}>Закрыть</Button>
            </Modal.Footer>
        </Modal>
    );
};
export default AboutModal;
