import { useState, cloneElement } from 'react';
import './Menu.scss';

const Menu = ({ menuRef, items, isOpenMenu, setIsOpenMenu, id, activeColor, style, activeCard }) => {
  const [openSubmenu, setOpenSubmenu] = useState(false);

  const handleOpenSubmenu = (item) => {
    if (item.subitems) {
      setOpenSubmenu(true);
    }
  };

  const handleCloseSubmenu = (item) => {
    if (item.subitems) {
      setOpenSubmenu(false);
    }
  };

  return (
    <div className={isOpenMenu ? 'menu open' : 'menu'} ref={menuRef} style={style}>
      {Object.keys(items).map((key) => {
        const item = items[key];

        if (item.component) {
          const Component = item.component;
          return cloneElement(<Component key={key}/>, { id, activeColor, setIsOpenMenu, activeCard });
        }

        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
              item.function && item.function();
            }}
            className={item.isDisabled ? 'menu__item disabled' : 'menu__item'}
            key={key}
            onMouseEnter={() => handleOpenSubmenu(item)}
            onMouseLeave={() => handleCloseSubmenu(item)}
          >
            {item.icon}
            {item.title && <span className="menu__title">{item.title}</span>}
            {item.additionalIcon && <div>{item.additionalIcon}</div>}
            {item.subitems && (
              <div
                className={openSubmenu ? 'submenu menu' : 'submenu closed menu'}
              >
                {Object.keys(item.subitems).map((key) => {
                  const subitem = item.subitems[key];
                  return (
                    <div
                      onClick={subitem.function}
                      className="submenu__item menu__item"
                      key={subitem.title}
                    >
                      {subitem.icon}
                      <span className="submenu__title">{subitem.title}</span>
                      {subitem.additionalIcon && (
                        <div className="disabled">{subitem.additionalIcon}</div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Menu;
