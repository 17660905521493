import React from 'react';
import { Icon } from '../../../ui';


const FooterButtonBack = ({  
    className = "", 
    disabled = false,
    onClick = null,
    title = "Назад",
    icon = "arrow-round-prev",
    
   
}) => {

    let btnClass = "";
    if (className) {
        btnClass += " " + className;
    }

    return (
        <button
            className={"cor_footerNav__back" + btnClass}
            onClick={onClick}
            type="button"
            disabled={disabled}
        >
            <Icon name={icon} />
            <div className="cor_footerNav__label">{title}</div>
        </button>


    )
}

export default FooterButtonBack;