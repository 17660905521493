import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Modal, Button } from 'rlabui';
import ss from './ModalPrint.module.scss';
import "../../practicum/LabWorkShow.scss";
import "../../PhysicalInvest/PInvest.scss";

const ModalPrint = ({ showPrint, printData, closePrint }) => {
    const printRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
      });

    if (!showPrint) {
        return <></>;
    }

    return (
        <Modal visible={showPrint} size="lg">
            <Modal.Head modalClose={closePrint} title='Печать' />
            <Modal.Body className={ss.root}>
                <div 
                    className={ss.preview} 
                    ref={printRef}
                >
                    { printData }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handlePrint}>Печать</Button>
                <Button onClick={closePrint} border={true}>Закрыть</Button>
            </Modal.Footer>
        </Modal>
    )
};

export default ModalPrint;
//recommendations: https://blog.logrocket.com/using-react-to-print-generate-printable-document/

//how to hide preview - https://stackoverflow.com/questions/68751490/electron-print-iframe-given-reference-to-it
//method 2 : https://dev.to/ebereplenty/printing-in-react-made-easy-with-react-to-print-4b3k
//size of printging pages: https://www.w3.org/TR/WD-css2/page.html