import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {reloadClass, storageClass, storageOrg, storageUser} from "../../redux/slices/storage";
import {fetchclass, fetchUsersByOrg, selectclass} from "../../redux/slices/classsline";
import {isAuth} from "../../helpers/auth";
import {classApi} from "../../api/api";
import {Tabs} from '../ui';
import "./ChangeClass.scss";

const ChangeClass = (props) => {
    const documentOrg = useSelector(storageOrg);
    const documentUser = useSelector(storageUser);
    const documentClass = useSelector(storageClass);
    const dispatch = useDispatch();
    const [classs] = useSelector(selectclass);
    const [roomId, setRoomId] = useState(documentClass._id);

    useEffect(() => {
        dispatch(fetchUsersByOrg(documentOrg._id))
        dispatch(fetchclass(documentUser._id));
    }, [dispatch, documentOrg._id, documentUser._id]);

    useEffect(() => setRoomId(documentClass._id), [documentClass._id]);

    const selectClass = async (classSelected) => {
        if (isAuth().role === 3) return;
        if (roomId !== classSelected) {
            if (roomId) props.changeClass({from:roomId});
            const res = await classApi.getclassById(classSelected);
            setRoomId(res._id);
            dispatch(reloadClass(res));
        }
    };

    return (
      <div className="changeClass">
            <Tabs>
                {classs?.map((co, i) => (
                  <Tabs.Item key={i} active={co._id === roomId} onClick={() => selectClass(co._id)}>
                      <span>{co.className}</span>
                  </Tabs.Item>))}
            </Tabs>
      </div>
    )
};
export default ChangeClass;
