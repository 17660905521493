import React, {useEffect, useState} from "react";
import {userApi} from "../../../api/api";
import {fetchclass, fetchUsersByOrg, selectclass, selectusers,} from "../../../redux/slices/classsline";
import {storageOptions} from "../../../redux/slices/storage";
import {connect, useDispatch, useSelector} from "react-redux";
import {isAuth} from "../../../helpers/auth";
import {storageClass, storageOrg} from "../../../redux/slices/storage";
import {loadUserRlabs} from "../../../redux/slices/lists";
import {Button, Icon, Tabs} from "../../ui";
import Profile from "../../Profile";
import Footer from "../Footer";
import ProfilePieChart from "../../ProfileTables/ProfilePieChart";
import ProfileLabWorksTable from "../../ProfileTables/ProfileLabWorksTable";
import ProfileOnlineTestsTable from "../../ProfileTables/ProfileOnlineTestsTable";
import ProfileNotebooksTable from "../../ProfileTables/ProfileNotebooksTable";
import ProfileCalcModelInfoTable from "../../ProfileTables/ProfileCalcModelInfoTable";
import ProfileVideoEditorTable from "../../ProfileTables/ProfileVideoEditorTable";
import ProfileVirtLabTable from "../../ProfileTables/ProfileVirtLabTable";
import ProfileScenarioTable from "../../ProfileTables/ProfileScenarioTable";
import {MembersGrid} from "../../Grids";
import {mapDispatchToProps, mapStateToProps} from "./redux";
import {UserSelect} from "../../Settings";
import { Content, ContentBody, ContentHeader, ContentSection, ContentSubHeader, ContentWrap } from "../ContentParts";
import "./Dashboard.scss";
import {FEATURES} from "../../../api/config";

export const PRACTICUM_SECTION = {
    ONLINE_TESTS: 0,
    LAB_WORKS: 1,
    NOTEBOOK: 2,
    CALC_MODELS: 3,
    VIDEO_ANALYSIS: 4,
    VIRT_LAB: 5,
    DIGITAL_LAB: 6
};

export const DashboardProfile = (props) => {
    const dispatch = useDispatch();
    const documentOrg = useSelector(storageOrg);
    const documentClass = useSelector(storageClass);
    const [profile, setProfile] = useState(props.match?.params.id?null:isAuth());
    const [scores, setScores] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const [tabIdTeachers, setTabIdTeachers] = useState(0);
    const [search, setSearch] = useState('');
    const [classs] = useSelector(selectclass);
    const [usersList] = useSelector(selectusers);
    const {theme} = useSelector(storageOptions);
	const isLightMode = theme !== "dark";

    const tabs = [
        {name: "Онлайн тесты"},
        {name: "Лабораторные работы"},
        {name: "Рабочая тетрадь"},
        {name: "Расчетные модели"},
        {name: "Видео анализ"},
        {name: "Виртуальная лаборатория"},
        {name: "Цифровая лаборатория"},
    ];

    const tabsForTeachers = [
        {id: 0, name: "Ученики"},
        {id: 1, name: "Информация"},
    ];

    useEffect(() => {
        dispatch(fetchclass(isAuth()._id));
        dispatch(fetchUsersByOrg(documentOrg._id))
    }, [dispatch, documentOrg._id]);

    useEffect(() => {
        if (props.match?.params.id) {
            userApi.get(props.match?.params.id).then((data) => setProfile(data));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.match?.params.id]);

    useEffect(() => {
        const opt = props.match?.params.opt;
        if (opt !== undefined)
            setSelectedTab(parseInt(opt));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile, props.match?.params.opt]);

    const doSetSelectedTab = (i) => {
        setScores([]);
        setSelectedTab(i);
    };

    const getRlabs = () => {
        dispatch(loadUserRlabs(documentClass._id, profile._id));
    };

    useEffect(() => {
        if (profile?.role === 0) {
            getRlabs();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile]);

    const getTabs = () => {
        return (
            <Tabs>
                {tabs?.map((t, i) => (
                    <Tabs.Item key={i} active={i === selectedTab} onClick={() => doSetSelectedTab(i)}>
                        <span>{t.name}</span>
                   </Tabs.Item>))}
            </Tabs>
        );
    };

    const getTabsForTeachers = () => {
        return (
            <Tabs>
                {tabsForTeachers.map((item, i) => (
                    <Tabs.Item key={i} active={item.id === tabIdTeachers} onClick={() => setTabIdTeachers(item.id)}>
                        <span>{item.name}</span>
                    </Tabs.Item>))}
            </Tabs>
        );
    };

    return (
        <ContentWrap>
            <ContentSection className="flex">
                {profile && <Profile profile={profile} setProfile={setProfile} isEdit={isEdit} setIsEdit={setIsEdit}/>}
                {(selectedTab === PRACTICUM_SECTION.ONLINE_TESTS || 
                  selectedTab === PRACTICUM_SECTION.LAB_WORKS || 
                  selectedTab === PRACTICUM_SECTION.NOTEBOOK || 
                  selectedTab === PRACTICUM_SECTION.DIGITAL_LAB) && !isEdit &&
                    <ProfilePieChart isLightMode={isLightMode} scores={scores} />
                }
            </ContentSection>
          
            <Content background={profile && !isEdit && profile.role !== 2}>
                {profile && !isEdit && profile.role !== 2 &&
                    <>
                        {profile.role === 0 &&
                            <ContentHeader>
                                {getTabs()}
                            </ContentHeader>
                        }

                        {!isEdit && profile.role === 1 && profile._id === isAuth()._id && 
                            <>
                                <ContentHeader className="teacher">
                                    {getTabsForTeachers()}
                                </ContentHeader>

                                {tabIdTeachers === 0 && 
                                    <ContentSubHeader>
                                        <div className="cor-net__row">
                                            <div className="cor-net__col">
                                                <div className="cor-net__title mb_0">Ученики</div>
                                            </div>
                                            <div className="cor-net__col col-icon col-6">
                                                <input className="filter" value={search}  placeholder="Введите имя"
                                                        onChange={(e)=>setSearch(e.target.value)} />
                                                <Icon name="search" />
                                            </div>
                                            <div className="cor-net__col col-6">
                                                <Button
                                                    color="primary"
                                                    onClick={() => props.showUserSelect({showModal: true, role: 0})}
                                                >
                                                    Добавить учеников из базы
                                                </Button>
                                            </div>
                                            <div className="cor-net__col col-6">
                                                <Button
                                                    color="primary"
                                                    border={true}
                                                    className="cor_btn_icon_left"
                                                    onClick={() => props.showUserSettings(
                                                        {showModal: true, role: 0, isAdd: true, user: {city: documentOrg.city}, owner: documentOrg._id})}
                                                >
                                                    Создать Ученика
                                                </Button>
                                        
                                            </div>
                                        </div>
                                    </ContentSubHeader>
                                }
                                {tabIdTeachers === 1 && profile.role === 1 &&
                                    <ContentSubHeader>
                                        {getTabs()}
                                    </ContentSubHeader>
                                }
                            </>
                        }

                        <ContentBody>
                            {!isEdit && profile.role === 1 && profile._id === isAuth()._id && tabIdTeachers === 0 ?
                                <MembersGrid disabled={false} usersList={usersList.filter(u => u.role === 0 &&
                                ([u.name, u.name2, u.name3, u.login, u.email]).some(s => s?.toLowerCase().includes(search.toLowerCase())) &&
                                classs.filter(c => c._id === documentClass._id)[0]?.classUsers.includes(u._id))}
                                onTitleClick={(u) => props.history.push(FEATURES.profile.to + '/' + u._id)}
                                />
                            :
                                <>
                                    {selectedTab === PRACTICUM_SECTION.ONLINE_TESTS && <ProfileOnlineTestsTable profile={profile} setScores={setScores} />}
                                    {selectedTab === PRACTICUM_SECTION.LAB_WORKS && <ProfileLabWorksTable profile={profile} setScores={setScores} />}
                                    {selectedTab === PRACTICUM_SECTION.NOTEBOOK && <ProfileNotebooksTable profile={profile} setScores={setScores} />}
                                    {selectedTab === PRACTICUM_SECTION.CALC_MODELS && <ProfileCalcModelInfoTable profile={profile} history={props.history} />}
                                    {selectedTab === PRACTICUM_SECTION.VIDEO_ANALYSIS && <ProfileVideoEditorTable profile={profile} history={props.history} />}
                                    {selectedTab === PRACTICUM_SECTION.VIRT_LAB && <ProfileVirtLabTable profile={profile} />}
                                    {selectedTab === PRACTICUM_SECTION.DIGITAL_LAB && <ProfileScenarioTable profile={profile} setScores={setScores} />}
                                </>
                            }
                        </ContentBody>
                    </>
                }
            </Content>
            <Footer {...props}/>
            <UserSelect/>
        </ContentWrap>
    );
}
export default connect(mapStateToProps, mapDispatchToProps) (DashboardProfile)