import React from "react";
import { FilterByText, Tabs, FilterByDate } from "../ui";
import "../ReviewTest/LabWorkSelect.scss";

//Выбираем текстовый фильтр и временной диапазон 
const SelectNotebookFilterOptions = ({tabs, shownNotebooks, setShownNotebooks,
			textPrompt, testFilter, setTestFilter, 
			startDate, setStartDate, finishDate, setFinishDate, 
	}) => {

    const handleSelectTab = (type) => {
        setShownNotebooks(type);
    };

	return (
	<div className="cor-net">
		<div className="cor-net__section">
			<div className="cor-net__row">

				<div className="cor-net__col col-4">
					<Tabs>
						{tabs.map(tab => (
							<Tabs.Item 
								active={shownNotebooks === tab.tabId} 
								onClick={() => handleSelectTab(tab.tabId)} 
								key={'tab'+ tab.tabId}
							>
								{tab.tabName}
							</Tabs.Item>)
						)}
					</Tabs>
				</div>

				<div className="cor-net__col col-grow"></div>

				<div className="cor-net__col col-5">
					<FilterByText 
						placeholder={textPrompt}
						value={testFilter}
						setSearch={setTestFilter}
					/>
				</div>

				<div className="cor-net__col col-grow"></div>

				<div className="cor-net__col">
					<FilterByDate 
						startDate={startDate} 
						setStartDate={setStartDate} 
						finishDate={finishDate} 
						setFinishDate={setFinishDate} 
					/>
				</div>

			</div>
		</div>
	</div>
	);
}

export default SelectNotebookFilterOptions;
