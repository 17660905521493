import React from 'react';
import { Modal, Button } from 'rlabui';

const ModalFinishStudentTestDialog = ({ showConfirmDlg, handleNo, handleYes}) => {

    if (!showConfirmDlg) {
        return false;
    }

    return (
        <Modal visible={showConfirmDlg} size={'sm'}>
            <Modal.Head modalClose={handleNo} title='Завершить тестирование' />
            <Modal.Body>Вы уверены, что хотите завершить тестирование?</Modal.Body>
            <Modal.Footer>
                <Button onClick={handleYes} border={true}>Да, завершить</Button>
                <Button onClick={handleNo}>Вернуться к тестированию</Button>
            </Modal.Footer>
        </Modal>
    )
};

export default ModalFinishStudentTestDialog;