import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {storageClass} from "../../redux/slices/storage";
import {printOnlyDate} from "../../helpers/text";
import ProfileVideoEditorDetailsDlg from "./ProfileVideoEditorDetailsDlg";
import Table from "../ui/Table/Table";
import {videoEditorApi} from "../../api/api";

const ProfileVideoEditorTable = ({profile, history}) => {
	const [videoEditorData, setVideoEditorData] = useState([]);
	const [showVideoEditorDlg, setShowVideoEditorDlg] = useState(false);
	const [selectedVEId, setSelectedVEId] = useState(undefined);
	const [isDeleted, setIsDeleted] = useState(false);

	const documentClass = useSelector(storageClass);

    useEffect(() => {
		const handleServerData = (veData) => {
			return veData.map(item => ({
				expName: item.videoName,
				frameNumber: item.frames.length,
				date: printOnlyDate(item.updatedAt),
				actions: '',
				id: item._id,
			}));
		};

        const fetchData = async () => {
            const result = await videoEditorApi.getAllVideoEditorsByOwner(profile._id);
			setVideoEditorData(handleServerData(result.data));
        }

		fetchData();
		if (isDeleted) setIsDeleted(false);
    },[documentClass._id, profile._id, isDeleted]);

	const handleOpenVEDetails = async (id) => {
		setShowVideoEditorDlg(true);
		setSelectedVEId(id);
	};

	const handleRequestDeleteTest = id => {
		videoEditorApi.deleteVideoEditorById(id).then (res => setIsDeleted(true));
	};

	const getTableHeader = () => {
		return [ 
			{column: 'Название эксперимента', name: 'expName', style: { width: '40%'} }, 
			{column: 'Количество кадров', name: 'frameNumber', style: { width: '25%'} }, 
			{column: 'Дата', name: 'date', style: { width: '25%'} }, 
			{column: 'Действия', name: 'actions', style: { width: '25%'} }, 
			{column: 'id', name: 'id', style: { width: '0%'} }, 
		];
	};

    return ( 
		<div>
			<Table
				table={{
					header: getTableHeader(),
					data: videoEditorData, 
				}}
				person={{
					handlePerson: handleOpenVEDetails,
					isCellLink: true,
				}}
				sort={{
					hasSorting: true, 
					initSortInd: -3,
				}}
				actions={{
					handleDelete: id => handleRequestDeleteTest(id),
				}}
			/>
			
			{showVideoEditorDlg &&
			<ProfileVideoEditorDetailsDlg 
				showDlg={showVideoEditorDlg}
				setShowDlg={setShowVideoEditorDlg}
				selectedVEId={selectedVEId}
			/>
			}
		</div>
    )
}

export default ProfileVideoEditorTable;
