import { setGraduationModal } from '../../actions/sensors';
import { sendCommand } from '../../actions/port';
import { setDeviceResponse } from '../../actions/actions';
import { setGraduate } from '../../actions/graduation';
import { getCurrentValue } from './getCurrentValue';

export const mapStateToProps = (state) => {
    return {
        port: state.port.open,
        command: state.port.command,
        played: state.actions.played,
        deviceResponse: state.actions.response,
        modal: state.sensors.graduationModal,
        sensorsList: state.sensors.list,
        graduate: state.graduation.graduate,
        writer: state.writer.data,
        isOscilloscope: state.oscilloscope.isOscilloscope,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setGraduationModal: (bool) => dispatch(setGraduationModal(bool)),
    setGraduate: (bool) => dispatch(setGraduate(bool)),
    sendCommand: (str) => dispatch(sendCommand(str)),
    setDeviceResponse: (str) => dispatch(setDeviceResponse(str)),
    getCurrentValue: (ind) => dispatch(getCurrentValue(ind)),
});
