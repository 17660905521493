import  { useState , useEffect } from 'react'

export const DateTime = () => {
    const [date,setDate] = useState(new Date());
    useEffect(() => {
        const timer = setInterval(() => setDate(new Date()), 1000)
        return () => clearInterval(timer);
    });

    return(
      date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', second:'2-digit', hour12: false})
    ) 
}

export default DateTime