import React from 'react';
import { Modal, Button } from 'rlabui';
import Instructions from '../../Instructions';
import ss from './CompanyInfo.module.scss';

const CompanyInfo = ({ modal, showModalInfo, needUpdate, source }) => {
    const { version: ver, date } = source;

    return (
        <Modal
            visible={modal}
            size="xs"
        >
            <Modal.Head
                modalClose={() => showModalInfo(false)}
                title="О программе"
            ></Modal.Head>
            <Modal.Body>
                <ul className={ss.root}>
                    <li>
                        <b>Версия программы:</b>
                        <span>{window.version + '.' + ver || 0}</span>
                    </li>
                    <li>
                        <b>Веб-сайт:</b>
                        <a
                            href="https://robiclab.ru"
                            target="blank"
                        >
                            robiclab.ru
                        </a>
                    </li>
                    <li>
                        <b>Техническая поддержка:</b>
                        <a href="mailto: help@Robiclab.ru">help@Robiclab.ru</a>
                    </li>
                    <li>
                        <b>Телефон:</b>
                        <a href="tel: 8 (800) 101-74-47">8 (800) 101-74-47</a>
                    </li>
                    {ver && date && (
                        <li>
                            <b>Версия/дата source.xml:</b>
                            <span>
                                V.{ver || 0} / {date || 0}
                            </span>
                        </li>
                    )}
                    <Instructions />
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className="btn"
                    onClick={() => showModalInfo(false)}
                >
                    Закрыть
                </Button>
                {needUpdate && (
                    <Button
                        className="btn"
                        onClick={() => {
                            caches?.keys().then(function (names) {
                                for (let name of names) caches.delete(name);
                            });
                            window.location.reload(true);
                        }}
                    >
                        Обновить
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};
export default CompanyInfo;
