import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from './redux';
import { channelDispatchToProps, channelStateToProps } from './reduxChannel';
import Range from './OscilloSettings';
import Channel from './OscilloChannel';

const OscilloSettings = connect(mapStateToProps, mapDispatchToProps)(Range);
const OscilloChannel = connect(channelStateToProps, channelDispatchToProps)(Channel);

export { OscilloSettings, OscilloChannel };
