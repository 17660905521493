import React, { useEffect, useState, useCallback, useRef } from 'react';
import { saveAs } from 'file-saver';
import Moment from 'moment';
import { jsPDF } from 'jspdf';
import C2S from 'canvas2svg';
import { Chart, LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, BarController, BarElement } from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { getRelativePosition } from 'chart.js/helpers';
import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';
import chartColors from '../../styles/charts';
// https://github.com/DuartBreedt/chartjs-plugin-draggable
import ChartjsDraggablePlugin from 'chartjs-plugin-draggable';
import ChartMini from './ChartMini';
import ChartComment from '../ChartComment';
import { showCursorLine, showBoxOnHoverChart, setLineAnnotation, deleteBox, resetViewArea } from './functions';
import Icon from '../Icon';
import { Modal, Range, Dropdown, Switcher } from 'rlabui';
import AnalogDevice from '../AnalogDevice';
import scales_def from '../AnalogDevice/scales_def';
import ss from './Chart.module.scss';

Chart.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, zoomPlugin, annotationPlugin, ChartjsDraggablePlugin, BarController, BarElement);

let isDrag = false;
// let yAxisMax;
// let yAxisMin;

const chartViewsList = [
    { name: 'line', icon: 'linear', title: 'Линейная диаграмма' },
    { name: 'bar', icon: 'bar', title: 'Столбчатая диаграмма' },
    { name: 'analog', icon: 'analog-color', title: 'Аналоговые датчики' },
];

const matematicFunctionsList = [
    { name: 'cleared', title: 'Сбросить' },
    { name: 'inclined', title: 'Наклонная' },
    { name: 'linear', title: 'Линейная' },
    { name: 'quadratic', title: 'Квадратичная регрессия' },
    { name: 'furie', title: 'Преобразование Фурье' },
    { name: 'derivative', title: 'Производная' },
    { name: 'integral', title: 'Интеграл' },
];

const saveList = [
    { name: 'parital', icon: 'eye', title: 'Выборочные' },
    { name: 'jpeg', icon: 'file-image', title: '.jpg' },
    { name: 'png', icon: 'file-image', title: '.png' },
    { name: 'svg', icon: 'file-svg', title: '.svg' },
    { name: 'pdf', icon: 'file-pdf', title: '.pdf' },
];

const ChartArea = (props) => {
    const {
        index,
        data,
        writedData,
        // sensorWrited,
        // sensorImported,
        port,
        played,
        paused,
        view,
        legendMode,
        markerMode,
        markerActions,
        rangeActions,
        sensor,
        sensor: { value },
        chartMarkers,
        setChartMarkerAdd,
        setChartMarkerDelete,
        setChartMarkers,
        // delChartMarkers,
        // setChartView,
        chartStyle,
        defaultColors,
        theme,
        isOscilloscope,
        oscilloChartSettings: { channels: oscilloChannels },
        // oscilloDefaultChartSettings: { channels: oscilloDefaultChannels },
        isHiSpeed,
        // sensorReading,
        // frequency,
        deleteAllMarkers,
        // isMobile,
        // writed,
        // sensorsUnits,
        toggleChartParametric,
        modalPrint,
        // parametricMode,
        // toggleChartLissague,
        abscissa,
        saveParitalModal,
        setSeiesLength,
        seriesLength,
    } = props;

    // console.log(seriesLength);

    const { num, name, unit, frequency, roundDigit, deviceKey } = sensor;
    const isDigital = Object.keys(scales_def).filter((key) => key === name)[0];

    const {
        xMin: oscXMin,
        xMax: oscXMax,
        xBias: oscXBias,
        yBias: oscYBias,
        yMin: oscYMin,
        yMax: oscYMax,
    } = isOscilloscope ? oscilloChannels[index] : { xMin: 0, xMax: 25, xBias: 0, yBias: 0, yMin: -100, yMax: 100 };

    const defaultColor = defaultColors[index];
    const { weight, color, point, interpolation } = chartStyle[num]
        ? chartStyle[num]
        : {
              weight: 2,
              color: defaultColor.val,
              point: {
                  width: 0,
                  color: defaultColor.val,
              },
              interpolation: 'basis',
          };
    // const color = chartStyle[num]?.color ? chartStyle[num].color : defaultColors[index];

    // const [series, setSeries] = useState(saved[index]);
    const [chart, setChart] = useState();
    const [hoveredMarker, setHoveredMarker] = useState();
    const [dragging, setDragging] = useState(false);
    const [draggingMarker, setDraggingMarker] = useState(null);
    const [xMax, setXMax] = useState(10000);
    const [xMin, setXMin] = useState(0);
    const [labelX, setLabelX] = useState(0);
    const [labelY, setLabelY] = useState(0);
    const [panned, setPanned] = useState(0);
    // const [sliceLen, setSliceLen] = useState(50);
    const [isChartArea, togleChartArea] = useState(false);
    const [chanelPrefix, setChannelPrefix] = useState();
    const [showMiniChart, toggleMiniChart] = useState(false);
    const [isZoom, setZoom] = useState(false);
    const [xState, setXstate] = useState(played && !paused ? false : true);
    const [yState, setYstate] = useState(played && !paused ? false : true);
    const [autoTracking, setAutoTracking] = useState(true);
    const [hoverTime, setHoverTime] = useState(0);
    const [hoverValue, setHoverValue] = useState(0);
    const [hoverMinMax, setHoverMinMax] = useState([]);
    const [handlePointMode, setHandlePointMode] = useState(false);
    const [handlePoint, setHandlePoint] = useState(false);
    const [abscissaLabel, setAbscissaLabel] = useState('');
    const [chartView, setChartView] = useState('line');
    const [currentFunction, setCurrentFunction] = useState('cleared');
    const [saveName, setSaveName] = useState();
    const savedSeries = typeof num === 'string' ? (num.includes('loc') ? writedData[index] : value) : value;
    const [series, setSeries] = useState((typeof num === 'number' ? data?.filter((e) => e[0]?.num === num)[0] || [] : savedSeries) || []);

    // Android
    const { isAndroid } = window;
    const isAnalog = chartView === 'analog';
    const [editMarker, setEditMarker] = useState(false);

    const chartRef = useRef();
    const clearRef = useRef();
    // const xRef = useRef(null);
    // const yRef = useRef(null);
    const sliced = 50 + frequency;

    // console.log(num, data);
    // const dataoffsetIndex = sensorImported.length + sensorWrited.length;

    // console.log(data);

    // const series = (typeof num === 'number' ? data?.filter((e) => e[0]?.num === num)[0] || [] : savedSeries) || [];

    let stepped;
    let cubicInterpolationMode;
    let tension;
    switch (chartStyle[num]?.interpolation) {
        case 'linear':
            stepped = false;
            cubicInterpolationMode = 'default';
            tension = 0;
            break;

        case 'natural':
            stepped = false;
            cubicInterpolationMode = 'default';
            tension = 0.1;
            break;

        case 'basis':
            stepped = false;
            cubicInterpolationMode = 'default';
            tension = 0.3;
            break;

        case 'bundle':
            stepped = false;
            cubicInterpolationMode = 'default';
            tension = 0.4;
            break;

        case 'cardinal':
            stepped = false;
            tension = 0.6;
            cubicInterpolationMode = 'default';
            break;

        case 'catmull':
            stepped = false;
            tension = 0.8;
            cubicInterpolationMode = 'default';
            break;

        case 'step':
            stepped = 'middle';
            break;

        case 'stepbefore':
            stepped = 'before';
            break;

        case 'stepafter':
            stepped = 'after';
            break;

        case 'monotone':
            stepped = false;
            cubicInterpolationMode = 'monotone';
            break;

        default:
            stepped = false;
            cubicInterpolationMode = 'default';
            tension = 0.3;
            break;
    }

    const initialOptions = {
        maintainAspectRatio: false,
        responsive: true,
        animation: false,
        // animation: {
        //     duration: 0,
        //     easing: 'linear',
        // },
        parsing: false,
        normalized: true,
        legend: {
            display: false,
        },
        interaction: {
            includeInvisible: false,
        },
        layout: {
            padding: {
                top: 0,
                bottom: 0,
                left: 3,
                right: 5,
            },
        },
        elements: {
            line: {
                // скругление линии
                tension: isOscilloscope || isHiSpeed ? 0 : tension,
                borderColor: color ? color : defaultColor,
                backgroundColor: color ? color : defaultColor,
                borderWidth: weight || 0,
                spanGaps: true,
                // Interpolation
                cubicInterpolationMode,
                stepped,
                // order: 2,
            },
            bar: {
                backgroundColor: color ? color : defaultColor,
                borderWidth: 0,
                // clip: { left: 0, top: 10, right: 0, bottom: 0 },
                inflateAmount: weight * 0.5 || 'auto',
                barPercentage: 1,
                categoryPercentage: 1,
                // order: 1,
            },
            point: {
                radius: point?.width && !isHiSpeed ? point.width : 0,
                pointBackgroundColor: point?.color ? point.color : defaultColor,
            },
        },
        tooltips: {
            enabled: false,
        },
        title: {
            display: false,
        },
        hover: {
            mode: 'xy',
            intersect: false,
        },
        plugins: {
            decimation: {
                enabled: true,
                samples: 100,
                threshold: 1000,
                algorithm: 'lttb',
            },
            datalabels: {
                display: false,
            },
            annotation: {
                drawTime: 'afterDatasetsDraw',
                animations: {
                    numbers: {
                        properties: [],
                    },
                },
                annotations: {},
            },
            zoom: {
                pan: {
                    enabled: isOscilloscope ? false : true,
                    mode: played && !paused ? '' : 'xy',
                    onPanStart: (chart) => {
                        setPanned(true);
                    },
                    onPanComplete: (chart) => {
                        setPanned(false);
                        setChartMarkerAdd(false);
                        setChartMarkerDelete(false);
                    },
                    onPan: (chart) => setPanned(true),
                },
                zoom: {
                    scaleMode: 'xy',
                    mode: played && !paused ? '' : 'xy',
                    // threshold: 0.01,
                    // sensitivity: 0.01,
                    // speed: 0.01,
                    wheel: {
                        enabled: isOscilloscope ? false : true,
                    },
                    pinch: {
                        enabled: true,
                    },
                    drag: {
                        enabled: !played && isZoom ? true : false,
                        modifierKey: 'shift',
                        backgroundColor: theme === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
                        borderColor: theme === 'dark' ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.3)',
                    },
                    rangeMin: {
                        x: null,
                        y: null,
                    },
                    rangeMax: {
                        x: null,
                        y: null,
                    },
                    limits: {
                        yAxis: { minRange: 10 },
                        xAxis: { minRange: 10 },
                    },
                    onZoom: ({ chart }) => {},
                    onZoomStart: ({ chart }) => {},
                    onZoomComplete: function ({ chart }) {},
                },
            },
            themeBackgroundColor: {
                color: theme === 'dark' ? '#282B33' : '#ffffff',
            },
        },
        scales: {
            xAxis: {
                // offset: true,
                type: 'linear',
                min: isOscilloscope ? oscXMin : xMin,
                max: isOscilloscope ? oscXMax : xMax,
                ticks: {
                    display: isOscilloscope ? false : true,
                    // display: true,
                    count: 10,
                    maxTicksLimit: 10,
                    color: chartColors.tick,
                    // autoSkip: false,
                    font: {
                        size: isAndroid ? 6 : 10,
                    },
                    major: {
                        enabled: true,
                    },
                    callback: (value, i, values) => {
                        return +(value / 1000).toFixed(2);
                    },
                },
                grid: {
                    display: true,
                    // display: false,
                    color: theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light,
                    borderColor: theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light,
                    tickColor: 'transparent',
                    tickLength: 2,
                },
                title: {
                    display: true,
                    text: 'время, сек',
                    color: isOscilloscope ? 'transparent' : color ? color : defaultColor,
                    font: {
                        size: isAndroid ? 8 : 11,
                    },
                },
            },
            yAxis: {
                beginAtZero: false,
                // beginAtZero: chartView === 'bar',
                type: 'linear',
                min: isOscilloscope ? oscYMin : null,
                max: isOscilloscope ? oscYMax : null,
                ticks: {
                    maxTicksLimit: 10,
                    color: chartColors.tick,
                    font: {
                        size: isAndroid ? 6 : 10,
                    },
                    enabled: true,
                    callback: (value) => {
                        return value === 0 ? 0 : +value.toFixed(Math.abs(+roundDigit));
                    },
                },
                grid: {
                    // display: false,
                    borderColor: theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light,
                    color: theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light,
                    tickColor: 'transparent',
                    tickLength: 0,
                },
                title: {
                    display: true,
                    text: `${name} ${unit} ${abscissaLabel}`,
                    color: color ? color : defaultColor,
                    fontWeight: 'bold',
                    font: {
                        size: isAndroid ? 8 : 11,
                    },
                    padding: {
                        bottom: 13,
                    },
                },
            },
        },
        onHover: (evt, elements, chart) => {
            setHoverEvent(evt, theme);
        },
    };

    const round = (value, decimals = 2) => {
        return Number(Math.round(Number(`${value}e${decimals}`)) + `e-${decimals}`);
    };

    const plugins = [
        {
            id: 'eventCatcher',
            beforeEvent(chart, args) {
                const { event, inChartArea } = args;
                if (event.type === 'mousemove') {
                    togleChartArea(inChartArea);
                }
                if (event.type === 'mouseout') {
                    togleChartArea(false);
                }
                if (event.type === 'whell') {
                    setMatematicFunction(event, chart);
                }
            },
        },
        {
            id: 'themeBackgroundColor',
            beforeDraw: (chart, args, options) => {
                const { ctx } = chart;
                ctx.save();
                ctx.globalCompositeOperation = 'destination-over';
                ctx.fillStyle = options.color || '#FFFFFF';
                ctx.fillRect(0, 0, chart.width, chart.height);
                ctx.restore();
            },
        },
    ];

    const [options, setOptions] = useState(initialOptions);

    const [datasets, setDatasets] = useState([{ data: series }]);

    useEffect(() => {
        const onKeyDown = (e) => {
            const { key, target } = e;
            const isTextField = target.nodeName === 'INPUT' || target.nodeName === 'TEXTAREA';
            if (!isTextField) {
                e.preventDefault();
                e.stopPropagation();
                if (key === 'Shift') setZoom(true);
                if (key === ' ') setHandlePoint(true);
            }
        };
        const onKeyUp = (e) => {
            const { key, target } = e;
            const isTextField = target.nodeName === 'INPUT' || target.nodeName === 'TEXTAREA';
            if (!isTextField) {
                e.preventDefault();
                e.stopPropagation();
                if (key === 'Shift') {
                    setZoom(false);
                }
                if (key === ' ') setHandlePoint(false);
            }
        };
        document.addEventListener('keydown', onKeyDown);
        document.addEventListener('keyup', onKeyUp);
        if (series?.length) {
            // console.log(series);
            const valsArr = series?.map((s) => s.y);
            const min = Math.min(...valsArr);
            const max = Math.max(...valsArr);
            setHoverMinMax([min, max]);
        }
        return () => {
            // setDatasets([]);
            document.removeEventListener('keydown', onKeyDown);
            document.removeEventListener('keyup', onKeyUp);
        };
    }, []);

    useEffect(() => {
        if (writedData) {
            const data = writedData.filter((e) => e[0]?.num === num)[0];
            if (data) {
                setSeries(data);
            }
        }
    }, [writedData]);

    useEffect(() => {
        const newSer = (typeof num === 'number' ? data?.filter((e) => e[0]?.num === num)[0] || [] : savedSeries) || [];
        setSeries(newSer);
    }, [data]);

    useEffect(() => {
        if (series.length) {
            setSeiesLength({ num, data: true });
        } else {
            setSeiesLength({ num, data: false });
            setCurrentFunction('cleared');
        }
    }, [series.length, setSeiesLength, setCurrentFunction]);

    useEffect(() => {
        setDatasets([{ data: series }]);
    }, [series, setDatasets]);

    useEffect(() => {
        if (handlePointMode && handlePoint && played) {
            const index = series[series.length - 2]?.count;
            index >= 0 && setLineAnnotation({ chart, index, value: +value, roundDigit, arr: series, theme, isDrag, setHoveredMarker, setChartMarkers, sensors: [sensor], setEditMarker });
        }
    }, [handlePointMode, played, handlePoint]);

    useEffect(() => {
        // console.log(xRef.current);
        if (chart) {
            const zoom = chart.options.plugins.zoom;
            const x = xState ? 'x' : '';
            const y = yState ? 'y' : '';
            if (isZoom) {
                isDrag = false;
                zoom.pan.enabled = false;
                zoom.zoom.drag.enabled = true;
                zoom.zoom.mode = 'x';
            } else {
                zoom.pan.enabled = true;
                zoom.zoom.drag.enabled = false;
                zoom.zoom.mode = x + y;
            }
            chart.update();
        }
    }, [isZoom]);

    useEffect(() => {
        if (chartRef.current && chartView) {
            if (chartView === 'analog') {
                setChart(null);
                setOptions({});
            } else {
                setChart(chartRef.current);
                setOptions(initialOptions);
            }
        }
    }, [chartRef, chartView, setChart, setOptions]);

    useEffect(() => {
        if (abscissa[num]) {
            setAbscissaLabel(abscissa[num]?.label.split('(')[1].replace(')', ''));
        } else {
            setAbscissaLabel('');
        }
    }, [abscissa]);

    useEffect(() => {
        if (chart) {
            if (saveName) {
                // chart.update();
                const visibled = chart.scales.xAxis.ticks;
                const date = new Date();
                const fName = `${name}_${Moment(date).format('yyMMD-hhmmss')}`;
                const pdf = new jsPDF();
                // console.log(chart, chartRef);
                let fileType = 'image/' + saveName,
                    blob = chart.toBase64Image(fileType);

                if (saveName === 'parital') {
                    const xVals = visibled?.map((el) => el.value);
                    const minTime = Math.min(...xVals);
                    const maxTime = Math.max(...xVals);
                    saveParitalModal({ minTime, maxTime, deviceKey, deviceNum: num, format: saveName });
                } else {
                    if (blob) {
                        switch (saveName) {
                            case 'pdf':
                                fileType = 'application/pdf';
                                pdf.addImage(chart.canvas.toDataURL('image/png'), 'PNG', 0, 0);
                                break;

                            case 'svg':
                                fileType = 'image/svg+xml';
                                let ctx = new C2S(chart.width, chart.height);
                                ctx.drawImage(chart.canvas, 0, 0, chart.width, chart.height);
                                const svg = ctx.getSerializedSvg(true);
                                // console.log(ctx);
                                console.log(ctx.getSvg());
                                // console.dir(svg);
                                blob = new Blob([svg], { type: fileType });
                                break;

                            default:
                                break;
                        }
                        if (saveName === 'pdf') {
                            pdf.save(fName + '.' + saveName);
                        } else {
                            saveAs(blob, fName + '.' + saveName, { type: fileType });
                        }
                    }
                }
                setSaveName();
            } else {
                // chart.update();
            }
        }
    }, [saveName, chart, name]);

    useEffect(() => {
        if (chart && isOscilloscope && oscilloChannels[index]) {
            chart.options.scales.yAxis.min = oscYMin;
            chart.options.scales.yAxis.max = oscYMax;
            chart.options.scales.xAxis.min = oscXMin;
            chart.options.scales.xAxis.max = oscXMax;
            !isAnalog && chart.update();
        }
    }, [oscXMax, oscXMin, oscYMax, oscYMin, oscXBias, oscYBias]);

    useEffect(() => {
        // console.log(chartMarkers);
        if (chart && Object.keys(chartMarkers).length && chartMarkers[num]) {
            // console.log(series?.length);
            if (series?.length > 1) {
                const keys = Object.keys(chartMarkers[num]).length;
                const marks = chart.config.options.plugins.annotation.annotations;
                if (keys) {
                    // console.log('chartMarkers', chartMarkers[num]);
                    const markers = chartMarkers[num];
                    for (let key in markers) {
                        const { type, value, id, resizer, backgroundColor, xMin, xMax } = markers[key];
                        if (!marks[id]) {
                            if (type === 'line' && value && !resizer) {
                                // console.log(markers[key]);
                                const index = +id.replace('line', '');
                                setLineAnnotation({ chart, index, value: +value, roundDigit, arr: series, theme, isDrag, setHoveredMarker, setChartMarkers, sensors: [sensor], setEditMarker });
                            }

                            if (type === 'box' && xMin && xMax) {
                                // console.log(markers[key]);
                                setBoxAnnotation(id, xMin, xMax, backgroundColor);
                            }
                        }
                    }
                }
            } else {
                // chart.options.plugins.annotation.annotations = {};
                // typeof num !== 'string' && setChartMarkers({ num, data: {} });
            }
        }
    }, [chartMarkers, chart]);

    useEffect(() => {
        if (chart) {
            chart.options.plugins.annotation.annotations = chartMarkers[num] ? chartMarkers[num] : {};
            !isAnalog && chart?.update();
        }
    }, [chartMarkers]);

    useEffect(() => {
        if (chart && num) {
            if (typeof num === 'number') {
                setChannelPrefix('');
            } else {
                if (num.includes('loc')) {
                    setChannelPrefix('(Лог)');
                } else {
                    setChannelPrefix('(Имп)');
                }
            }
        }
    }, [num, chart]);

    useEffect(() => {
        if (chart) {
            chart.options.scales.yAxis.title.text = `${name} ${unit} ${chanelPrefix ? chanelPrefix : ''} ${abscissaLabel}`;
            !isAnalog && chart.update();
        }
    }, [chanelPrefix, unit, abscissaLabel]);

    useEffect(() => {
        if (chart) {
            if (view === 'four') {
                chart.options.scales.xAxis.ticks.count = 5;
            } else {
                chart.options.scales.xAxis.ticks.count = 10;
            }
        }
    }, [chart, view]);

    useEffect(() => {
        if (chart) {
            // Grid and ticks
            chart.options.scales.xAxis.grid.borderColor = theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light;
            chart.options.scales.xAxis.grid.color = theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light;

            chart.options.scales.yAxis.grid.borderColor = theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light;
            chart.options.scales.yAxis.grid.color = theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light;

            // Markers
            const markers = chart.config.options.plugins.annotation.annotations;
            for (let key in markers) {
                const mark = markers[key];

                if (mark && mark.type === 'line' && !mark.resizer) {
                    mark.borderColor = theme === 'dark' && !modalPrint ? chartColors.marker.dark.line : chartColors.marker.line;
                    mark.label.backgroundColor = theme === 'dark' ? chartColors.marker.dark.background : chartColors.marker.background;
                    mark.label.borderColor = theme === 'dark' ? chartColors.marker.dark.border : chartColors.marker.border;
                    mark.label.color = theme === 'dark' ? chartColors.marker.dark.color : chartColors.marker.color;
                }
            }

            chart.options.plugins.themeBackgroundColor.color = theme === 'dark' && '#282B33';

            !isAnalog && chart.update();
        }
    }, [theme, modalPrint]);

    useEffect(() => {
        if (chart) {
            if (color) {
                if (chartView === 'bar') {
                    chart.options.elements.bar.backgroundColor = color ? color : defaultColor;
                    chart.options.elements.bar.inflateAmount = weight * 0.5 || 'auto';
                    // chart.options.elements.bar.barPercentage = weight ? weight / 10 : 1;
                    // chart.options.elements.bar.categoryPercentage = weight ? weight / 10 : 1;
                } else {
                    chart.options.elements.line.borderColor = color;
                    chart.options.elements.line.borderWidth = weight ? weight : 0;
                }
                chart.options.scales.xAxis.title.color = color;
                chart.options.scales.yAxis.title.color = color;
            }
            if (point) {
                chart.options.elements.point.pointRadius = point.width > 0 ? point.width : 0;
                chart.options.elements.point.pointBackgroundColor = point.color;
            }
            chart.options.elements.line.borderWidth = weight ? weight : 0;
            if (interpolation) {
                // interpolation
                switch (interpolation) {
                    case 'linear':
                        chart.options.elements.line.stepped = false;
                        chart.options.elements.line.cubicInterpolationMode = 'default';
                        chart.options.elements.line.tension = 0;
                        break;

                    case 'natural':
                        chart.options.elements.line.stepped = false;
                        chart.options.elements.line.cubicInterpolationMode = 'default';
                        chart.options.elements.line.tension = 0.1;
                        break;

                    case 'basis':
                        chart.options.elements.line.stepped = false;
                        chart.options.elements.line.cubicInterpolationMode = 'default';
                        chart.options.elements.line.tension = 0.3;
                        break;

                    case 'bundle':
                        chart.options.elements.line.stepped = false;
                        chart.options.elements.line.cubicInterpolationMode = 'default';
                        chart.options.elements.line.tension = 0.4;
                        break;

                    case 'cardinal':
                        chart.options.elements.line.stepped = false;
                        chart.options.elements.line.tension = 0.6;
                        chart.options.elements.line.cubicInterpolationMode = 'default';
                        break;

                    case 'catmull':
                        chart.options.elements.line.stepped = false;
                        chart.options.elements.line.tension = 0.8;
                        chart.options.elements.line.cubicInterpolationMode = 'default';
                        break;

                    case 'step':
                        chart.options.elements.line.stepped = 'middle';
                        break;

                    case 'stepbefore':
                        chart.options.elements.line.stepped = 'before';
                        break;

                    case 'stepafter':
                        chart.options.elements.line.stepped = 'after';
                        break;

                    case 'monotone':
                        chart.options.elements.line.stepped = false;
                        chart.options.elements.line.cubicInterpolationMode = 'monotone';
                        break;

                    default:
                        chart.options.elements.line.stepped = false;
                        chart.options.elements.line.cubicInterpolationMode = 'default';
                        chart.options.elements.line.tension = 0.3;
                        break;
                }
            }
            !isAnalog && chart.update();
        }
    }, [chartStyle]);

    useEffect(() => {
        if (deleteAllMarkers) {
            removeAllMarker();
        }
    }, [deleteAllMarkers]);

    useEffect(() => {
        if (chart) {
            const markers = chart.config.options.plugins.annotation.annotations;
            delete markers['xLine'];
            delete markers['yLine'];
            delete markers['hovered'];
            chart.options.onHover = (evt) => setHoverEvent(evt, theme);
            if (!isChartArea) {
                chart.options.plugins.zoom.zoom.scaleMode = 'xy';
            } else {
                // console.log('enter');
                chart.options.plugins.zoom.zoom.scaleMode = null;
            }
            !isAnalog && chart?.update();
        }
    }, [isChartArea, chart, theme]);

    useEffect(() => {
        if (chart) {
            if (dragging && draggingMarker && !hoveredMarker) {
                chart.options.plugins.zoom.pan.enabled = false;
                setChartMarkerAdd(false);
            } else {
                chart.options.plugins.zoom.pan.enabled = true;
            }
            !isAnalog && chart?.update();
        }
    }, [dragging, draggingMarker]);

    useEffect(() => {
        setMatematicFunction();
    }, [currentFunction]);

    useEffect(() => {
        if (chart) {
            if (played) {
                setXMin(0);
                setXMax(10000);
                if (typeof num !== 'string') {
                    removeAllMarker();
                    chart.data.datasets[0].data = [];
                    chart.options.plugins.annotation.annotations = {};
                }
                const minMaxArr = [];

                series?.forEach((el, i) => {
                    el.y && minMaxArr.push(el.y);
                });
                toggleMiniChart(false);
            }
        }
    }, [played]);

    useEffect(() => {
        if (chart) {
            if (played && !paused) {
                // chart.options.scales.yAxis.min = null;
                // chart.options.scales.yAxis.max = null;
                if (!autoTracking) {
                    chart.options.plugins.zoom.zoom.mode = 'xy';
                    chart.options.plugins.zoom.pan.mode = 'xy';
                    setXstate(true);
                    setYstate(true);
                } else {
                    setXstate(false);
                    setYstate(false);
                    chart.options.plugins.zoom.zoom.mode = '';
                    chart.options.plugins.zoom.pan.mode = '';
                }
            } else {
                setXstate(true);
                setYstate(true);
                chart.options.plugins.zoom.zoom.mode = 'xy';
                chart.options.plugins.zoom.pan.mode = 'xy';
                const valsArr = series.map((s) => s.y);
                const min = Math.min(...valsArr);
                const max = Math.max(...valsArr);
                setHoverMinMax([min, max]);
            }
            !isAnalog && chart.update();
        }
    }, [played, paused, autoTracking]);

    useEffect(() => {
        if (chart) {
            if (series?.length) {
                // !seriesLength && setSeiesLength(true);
                let min = chart.scales.xAxis.min,
                    max = chart.scales.xAxis.max;

                if (isOscilloscope) {
                    min = oscXMin;
                    max = oscXMax;
                    chart.options.scales.yAxis.min = oscYMin;
                    chart.options.scales.yAxis.max = oscYMax;
                } else {
                    if (autoTracking) {
                        min = series?.slice(-sliced)[0]?.x;
                        max = series?.[series.length - 1]?.x + 300;
                    }
                }
                if (played && !paused) {
                    chart.options.scales.xAxis.max = max;
                    chart.options.scales.xAxis.min = min;
                    !isAnalog && chart.update();
                }
            }
        }
    }, [series, played, paused, autoTracking]);

    const setMatematicFunction = (e, graph) => {
        const cchart = graph ? graph : chart;
        if (cchart && datasets[0]?.data?.length > 1 && !played) {
            // let pos = { x: 0, y: 0 };
            // if (e) {
            //     pos = getRelativePosition(e, cchart);
            // }
            // const xPos = cchart.scales.xAxis.getValueForPixel(pos.x);
            // const yPos = cchart.scales.yAxis.getValueForPixel(pos.y);
            // console.log(xPos, yPos);
            // console.log(cchart);
            const { min: xmin, max: xmax } = cchart?._metasets[0]?.iScale?._range;
            let copy = [...datasets];
            const cropped = copy[0].data.filter((e) => e.x >= xmin && e.x <= xmax);
            const t = cropped.map((e) => e.x);
            const y = cropped.map((e) => e.y);
            let Y, ts;

            if (!copy[1]) {
                copy[1] = {
                    type: 'line',
                    backgroundColor: 'red',
                    borderColor: 'red',
                    borderWidth: 3,
                    pointRadius: 0,
                    // order: 10,
                    data: [],
                };
            }

            switch (currentFunction) {
                case 'inclined':
                    ts = cropped.map((e) => e.x / 1000);
                    Y = getInclined(ts, y, (xmin + xmax) / 2 / 1000);
                    if (Y) {
                        copy[1].data = [
                            { x: cropped[0].x, y: Y[0] },
                            { x: cropped.at(-1).x, y: Y.at(-1) },
                        ];
                    }
                    break;

                case 'linear':
                    Y = getLinear(t, y);
                    if (Y) {
                        copy[1].data = [
                            { x: cropped[0].x, y: Y[0] },
                            { x: cropped.at(-1).x, y: Y.at(-1) },
                        ];
                    }
                    break;

                case 'quadratic':
                    Y = getQRegression(t, y);
                    copy[1].data = [];

                    Y?.forEach((e, i) => {
                        copy[1].data.push({ x: t[i], y: e });
                    });
                    break;

                case 'furie':
                    Y = getFurie(y);
                    copy[1].data = [];
                    Y?.shift();
                    let T = t.filter((e, i) => i % 2 === 0);

                    Y?.forEach((e, i) => {
                        copy[1].data.push({ x: T[i + 1], y: e });
                    });
                    break;

                case 'derivative':
                    ts = cropped.map((e) => e.x / 1000);
                    Y = getDerivative(ts, y);
                    copy[1].data = [];
                    Y?.forEach((e, i) => {
                        copy[1].data.push({ x: t[i], y: e });
                    });
                    break;

                case 'integral':
                    ts = cropped.map((e) => e.x / 1000);
                    Y = getIntgral(ts, y);
                    copy[1].data = [];

                    Y?.forEach((e, i) => {
                        copy[1].data.push({ x: t[i], y: e });
                    });
                    break;

                default:
                    copy[1] && copy.pop();
                    break;
            }
            setDatasets(copy);
            cchart.update();
        }
    };

    const getInclined = (t, y, T) => {
        let N = t.length;
        let d = new Array(N);
        let Y = new Array(N);

        //нахождение индекса точки i0 по значению T
        let i0 = 0;
        if (T >= t[N - 1]) {
            i0 = N - 1;
        } else if (T >= t[0]) {
            for (i0 = 0; i0 < N - 1; i0++) {
                if (t[i0] <= T && t[i0 + 1] > T) break;
            }
        }

        //производная в нулевой точке
        d[0] = (-3 * y[0] + 4 * y[1] - y[2]) / (t[2] - t[0]);
        //производная в промежуточных точках
        for (let i = 1; i < N - 1; i++) {
            d[i] = (y[i + 1] - y[i - 1]) / (t[i + 1] - t[i - 1]);
        }
        //производная в последней точке
        d[N - 1] = (y[N - 3] - 4 * y[N - 2] + 3 * y[N - 1]) / (t[N - 1] - t[N - 3]);

        let a = d[i0] + ((T - t[i0]) / (t[i0 + 1] - t[i0])) * (d[i0 + 1] - d[i0]);
        let v = y[i0] + ((T - t[i0]) / (t[i0 + 1] - t[i0])) * (y[i0 + 1] - y[i0]);

        for (let i = 0; i < N; i++) {
            Y[i] = v + a * (t[i] - T);
        }

        return Y;
    };

    const getLinear = (t, y) => {
        let st = t.reduce((acc, val) => acc + val, 0);
        let sy = y.reduce((acc, val) => acc + val, 0);
        let sty = t.map((val, index) => val * y[index]).reduce((acc, val) => acc + val, 0);
        let stt = t.map((val) => val * val).reduce((acc, val) => acc + val, 0);
        let N = t.length;
        let b = (N * sty - st * sy) / (N * stt - st * st);
        let a = (sy - b * st) / N;
        return t.map((val) => b * val + a);
    };

    const getFurie = (y) => {
        const N = y.length;
        let W = (2 * Math.PI) / N;
        let reY = new Array(N);
        let imY = new Array(N);
        let Y = new Array(N);

        for (let i = 0; i < N; i++) {
            reY[i] = 0;
            imY[i] = 0;
            for (let n = 0; n < N; n++) {
                reY[i] += y[n] * Math.cos(W * i * n);
                imY[i] += -y[n] * Math.sin(W * i * n);
            }
            Y[i] = Math.sqrt(reY[i] * reY[i] + imY[i] * imY[i]);
        }
        return Y.slice(0, Y.length / 2);
    };

    const getDerivative = (t, y) => {
        let N = t.length;
        let d = new Array(N);

        //производная в нулевой точке
        d[0] = (-3 * y[0] + 4 * y[1] - y[2]) / (t[2] - t[0]);
        //производная в промежуточных точках
        for (let i = 1; i < N - 1; i++) {
            d[i] = (y[i + 1] - y[i - 1]) / (t[i + 1] - t[i - 1]);
        }
        //производная в последней точке
        d[N - 1] = (y[N - 3] - 4 * y[N - 2] + 3 * y[N - 1]) / (t[N - 1] - t[N - 3]);

        return d;
    };

    const getIntgral = (t, y) => {
        let N = t.length;
        let s = [];
        let Y = [];
        let dy = getDerivative(t, y);
        s[0] = ((t[0] - t[0 - 1]) * (y[0 - 1] + y[0])) / 2 + ((t[0] - t[0 - 1]) * (t[0] - t[0 - 1]) * (dy[0 - 1] - dy[0])) / 12;
        Y[0] = 0;

        for (var k = 1; k < N; k++) {
            s[k] = ((t[k] - t[k - 1]) * (y[k - 1] + y[k])) / 2 + ((t[k] - t[k - 1]) * (t[k] - t[k - 1]) * (dy[k - 1] - dy[k])) / 12;
            Y[k] = Y[k - 1] + s[k];
        }
        return Y;
    };

    const getQRegression = (t, y) => {
        const N = t.length;
        let A00 = N;
        let A01 = t.reduce((acc, curr) => acc + curr, 0);
        let A02 = t.reduce((acc, curr) => acc + curr * curr, 0);
        let A10 = A01;
        let A11 = A02;
        let A12 = t.reduce((acc, curr) => acc + curr * curr * curr, 0);
        let A20 = A11;
        let A21 = A12;
        let A22 = t.reduce((acc, curr) => acc + curr * curr * curr * curr, 0);
        let b0 = y.reduce((acc, curr) => acc + curr, 0);
        let b1 = t.reduce((acc, curr, i) => acc + curr * y[i], 0);
        let b2 = t.reduce((acc, curr, i) => acc + curr * curr * y[i], 0);
        let detA = A00 * A11 * A22 + A01 * A12 * A20 + A10 * A21 * A02 - A02 * A11 * A20 - A01 * A10 * A22 - A12 * A21 * A00;
        let B00 = (A11 * A22 - A12 * A21) / detA;
        let B01 = (-A01 * A22 + A02 * A21) / detA;
        let B02 = (A01 * A12 - A02 * A11) / detA;
        let B10 = (-A10 * A22 + A12 * A20) / detA;
        let B11 = (A00 * A22 - A02 * A20) / detA;
        let B12 = (-A00 * A12 + A02 * A10) / detA;
        let B20 = (A10 * A21 - A11 * A20) / detA;
        let B21 = (-A00 * A21 + A01 * A20) / detA;
        let B22 = (A00 * A11 - A01 * A10) / detA;
        let c0 = B00 * b0 + B01 * b1 + B02 * b2;
        let c1 = B10 * b0 + B11 * b1 + B12 * b2;
        let c2 = B20 * b0 + B21 * b1 + B22 * b2;

        return t.map((ti) => c0 + c1 * ti + c2 * ti * ti);
    };

    const setBoxAnnotation = (key, min, max, color) => {
        // if (data[0].value.length <= 1) return;
        const markers = chart.config.options.plugins.annotation.annotations;
        let minInd = series?.findIndex((e) => e.x >= min);
        let maxInd = series?.findIndex((e) => e.x >= max);
        const start = key + 'start';
        const end = key + 'end';
        markers[start] = {
            type: 'line',
            resizer: true,
            borderWidth: 5,
            borderColor: 'transparent',
            scaleID: 'xAxis',
            yScaleID: null,
            value: min,
            draggable: true,
            drawTime: 'afterDraw',
            enter: function (context, event) {
                if (isAndroid) return;
                document.body.style.cursor = 'col-resize';
                const { id } = context;
                setHoveredMarker(id);
            },
            leave: function (context, event) {
                document.body.style.cursor = '';
                setHoveredMarker();
            },
            onDragStart: function (event, value) {},
            onDrag: function (event, value) {
                if (isAndroid) return;
                const min = series[0].x;
                const boxMax = chart.options.plugins.annotation.annotations[key].xMax - 500;
                const {
                    subject: {
                        config: { id },
                    },
                } = event;
                chart.options.plugins.zoom.pan.enabled = false;
                if (value < min) {
                    chart.options.plugins.annotation.annotations[id].value = min;
                } else if (value > boxMax) {
                    chart.options.plugins.annotation.annotations[id].value = boxMax;
                } else {
                    chart.options.plugins.annotation.annotations[key].xMin = value < min ? min : value;
                }
            },
            onDragEnd: function (event, value) {
                if (isAndroid) return;
                chart.options.plugins.zoom.pan.enabled = true;
                minInd = series?.findIndex((e) => e.x >= value);
                chart.options.plugins.annotation.annotations[key].yMinValue = minInd >= 0 ? series[minInd].y : series[0].y;
                setChartMarkers({ num, data: chart.config.options.plugins.annotation.annotations });
            },
        };
        markers[key] = {
            type: 'box',
            id: key,
            xScaleID: 'xAxis',
            yScaleID: 'yAxis',
            backgroundColor: color,
            borderWidth: 0,
            xMax: max ? max : series[series.length - 1],
            xMin: min ? min : 0,
            yMinValue: minInd >= 0 ? series[minInd].y : series[0].y,
            yMaxValue: maxInd >= 0 ? series[maxInd].y : series[series.length - 1].y,
            enter: (context, event) => {
                if (!isDrag) {
                    setEditMarker(key);
                    if (isAndroid) return;
                    setDraggingMarker(key);
                }
            },
            leave: (context, event) => {
                if (!isDrag) {
                    setDraggingMarker(null);
                }
            },
        };
        markers[end] = {
            type: 'line',
            borderWidth: 5,
            resizer: true,
            borderColor: 'transparent',
            scaleID: 'xAxis',
            yScaleID: null,
            value: max,
            draggable: true,
            drawTime: 'afterDraw',
            enter: function (context, event) {
                if (isAndroid) return;
                document.body.style.cursor = 'col-resize';
                const { id } = context;
                setHoveredMarker(id);
            },
            leave: function (context, event) {
                if (isAndroid) return;
                document.body.style.cursor = '';
                setHoveredMarker();
            },
            onDragStart: function (event, value) {
                // const { subject: {config: {id}} } = event;
            },
            onDrag: function (event, value) {
                if (isAndroid) return;
                const max = series[series.length - 1].x;
                const boxMin = chart.options.plugins.annotation.annotations[key].xMin + 0.5;
                const {
                    subject: {
                        config: { id },
                    },
                } = event;
                chart.options.plugins.zoom.pan.enabled = false;
                if (value > max) {
                    chart.options.plugins.annotation.annotations[id].value = max;
                } else if (value < boxMin) {
                    chart.options.plugins.annotation.annotations[id].value = boxMin;
                } else {
                    chart.options.plugins.annotation.annotations[key].xMax = value > max ? max : value;
                }
            },
            onDragEnd: function (event, value) {
                if (isAndroid) return;
                chart.options.plugins.zoom.pan.enabled = true;
                maxInd = series?.findIndex((e) => e.x >= value);
                chart.options.plugins.annotation.annotations[key].yMaxValue = maxInd >= 0 ? series[maxInd].y : series[series.length - 1].y;
                // console.log(key);
                setChartMarkers({ num, data: chart.config.options.plugins.annotation.annotations });
            },
        };
        chart.options.plugins.annotation.annotations = markers;
        // console.log(markers);
        setChartMarkers({ num, data: { ...markers } });
    };

    const addRemoveMarker = (event, key) => {
        // console.log(event);
        if (series[series.length - 1]?.x === 0) {
            return;
        }
        if (panned) {
            setPanned(false);
            return;
        }
        // console.log(key);
        const markers = chart.config.options.plugins.annotation.annotations;
        if (markerActions.del && key) {
            if (markers[key] && markers[key].type === 'line' && !markers[key].resizer) {
                delete markers[key];
                setHoveredMarker();
            }
        }
        if (markerActions.add) {
            const canvasPosition = getRelativePosition(event, chart);
            const dataX = chart.scales.xAxis.getValueForPixel(canvasPosition.x);

            const index = series.findIndex((e) => e.x >= dataX);

            if (markerMode === 'arbitrary' || markerMode === 'cursor') {
                setLineAnnotation({ chart, index, value: null, roundDigit, arr: series, theme, isDrag, setHoveredMarker, setChartMarkers, sensors: [sensor], setEditMarker });
            }
        }
        chart.options.plugins.annotation.annotations = markers;
        setChartMarkers({ num, data: markers });
    };

    const removeAllMarker = () => {
        if (chart) {
            const data = {};
            setChartMarkers({ num, data });
            chart.options.plugins.annotation.annotations = data;
            !isAnalog && chart.update();
        }
    };

    const setHoverEvent = (evt, theme) => {
        if (!chart || series?.length < 2) return;
        if (played && !paused) return;

        // console.log(evt.target);

        const markers = chart.config.options.plugins.annotation.annotations;
        // console.log(markers);

        const canvasPosition = getRelativePosition(evt, chart);
        // console.log(canvasPosition);
        const dataX = chart.scales.xAxis.getValueForPixel(canvasPosition.x);
        const dataY = chart.scales.yAxis.getValueForPixel(canvasPosition.y);
        // console.log(dataX);

        setHoverTime((dataX / 1000).toFixed(2));
        setHoverValue(dataY.toFixed(roundDigit));

        if (markerMode === 'cursor') {
            try {
                if (markers['xLine']) {
                    const xLine = markers['xLine'];
                    xLine.display = true;
                    xLine.value = dataX;
                    xLine.label.content = (dataX / 1000).toFixed(2);
                }
                if (markers['yLine']) {
                    const yLine = markers['yLine'];
                    yLine.display = true;
                    yLine.value = dataY;
                    yLine.label.content = dataY.toFixed(roundDigit);
                }
                paused && chart.update();
            } catch (e) {
                // console.log(e.message);
            } finally {
            }
        }

        setLabelX((dataX / 1000).toFixed(3));
        setLabelY(dataY.toFixed(roundDigit));

        try {
            if (markers['hovered']) {
                markers['hovered'].xMin = dataX - 1;
                markers['hovered'].xMax = dataX + 1;
            }
        } catch (e) {
            console.log(e.message);
        }

        switch (markerMode) {
            case 'cursor':
                if (!markers.xLine) {
                    showCursorLine(chart, theme, 'yAxis');
                }
                break;
            case 'range1':
                if (!markers.box1 && !markerActions.del) {
                    showBoxOnHoverChart(chart, evt, 'box1', dataX, chartColors.boxes.chart.box1, series, setBoxAnnotation);
                }
                break;
            case 'range2':
                if (!markers.box2 && !markerActions.del) {
                    showBoxOnHoverChart(chart, evt, 'box2', dataX, chartColors.boxes.chart.box2, series, setBoxAnnotation);
                }
                break;
            case 'range3':
                if (!markers.box3 && !markerActions.del) {
                    showBoxOnHoverChart(chart, evt, 'box3', dataX, chartColors.boxes.chart.box3, series, setBoxAnnotation);
                }
                break;
            case 'range4':
                if (!markers.box4 && !markerActions.del) {
                    showBoxOnHoverChart(chart, evt, 'box4', dataX, chartColors.boxes.chart.box4, series, setBoxAnnotation);
                }
                break;
            default:
                delete markers['xLine'];
                delete markers['yLine'];
                delete markers['hovered'];
        }

        // console.log(rangeActions.del);
        if (dragging && !rangeActions.del) {
            draggingBox(evt, draggingMarker);
        }
    };

    const draggingBox = (e, key) => {
        // console.log(e.type);
        if (dragging && key) {
            const min = series[0].x;
            const max = series[series.length - 1].x;
            const markers = chart.config.options.plugins.annotation.annotations;
            const canvasPosition = getRelativePosition(e, chart);
            const dataX = chart.scales.xAxis.getValueForPixel(canvasPosition.x);
            // console.log(dataX);

            // if (!markers[key]) return;
            if (markers && markers[key] && markers[key].type === 'box') {
                const offset = (markers[key].xMax - markers[key].xMin) / 2;
                const start = key + 'start';
                const end = key + 'end';
                if (!rangeActions.del && isChartArea) {
                    const minValue = dataX - offset < min ? min : dataX - offset;
                    const maxValue = dataX + offset > max ? max : dataX + offset;

                    markers[start].value = minValue;
                    markers[key].xMin = minValue;
                    markers[key].xMax = maxValue;
                    markers[end].value = maxValue;

                    chart.options.plugins.annotation.annotations = markers;
                    // chart.update();
                }
                setChartMarkers({ num, data: markers });
                // setChartMarkers({ num, data: chart.config.options.plugins.annotation.annotations });
            } else {
                setDragging(false);
                isDrag = false;
                setDraggingMarker(null);
            }
        }
    };

    const setLeaveEvent = () => {
        if (played && !paused && !chart) return;
        const markers = chart.config.options.plugins.annotation.annotations;
        clearTimeout(clearRef.current);
        clearRef.current = setTimeout(function () {
            try {
                delete markers['hovered'];
                delete markers['xLine'];
                delete markers['yLine'];
            } catch (e) {
                console.log(e.message);
            } finally {
                document.body.style.cursor = 'default';
                chart.options.plugins.annotation.annotations = markers;
                !isAnalog && chart?.update();
                // setChartMarkers({ num, data: chart.config.options.plugins.annotation.annotations });
            }
        }, 100);
    };

    const changeCoursor = (key) => {
        if (played && !paused && !isChartArea && !chart && !chart.config) return;
        // console.log(chart.config);
        const markers = chart?.config?.options?.plugins.annotation.annotations;
        if (!dragging) {
            if (key && markers[key]) {
                if (markerActions.del && !dragging) {
                    if (markers[key].type === 'line') {
                        if (markers[key].resizer) {
                            document.body.style.cursor = 'col-resize';
                        } else {
                            document.body.style.cursor = 'no-drop';
                        }
                    }
                } else {
                    if (markers[key].type === 'line') document.body.style.cursor = 'col-resize';
                }
            } else {
                document.body.style.cursor = 'default';
            }
        }
        if (draggingMarker) {
            if (rangeActions.del) {
                document.body.style.cursor = 'no-drop';
            } else {
                document.body.style.cursor = 'grab';
            }
            if (dragging) {
                document.body.style.cursor = 'grabbing';
            }
        }
    };

    const setLabeles = useCallback(() => {
        // if ((!played || paused) && data.length) {
        // return <div className="chart__data"><div>Время: {labelX} c <br/>{name}: {labelY} {unit}</div></div>;
        // }
    }, [labelX, labelY, played, paused]);

    const clickMarkersEvents = (e) => {
        if (markerActions.add || markerActions.del) {
            addRemoveMarker(e, hoveredMarker);
        }
        if (draggingMarker && rangeActions.del) {
            deleteBox(chart, draggingMarker, setChartMarkers, num);
            // chart.update();
            setDraggingMarker(null);
        }
        isDrag = false;
    };

    const handleCheckX = (e) => {
        const { checked } = e.target;
    };

    useEffect(() => {
        const zoom = chart?.options.plugins.zoom;
        if (zoom && !isAnalog) {
            if (!xState) {
                if (zoom.zoom.mode === 'xy') {
                    zoom.zoom.mode = 'y';
                } else {
                    zoom.zoom.mode = '';
                    played && chart?.resetZoom();
                }
                if (zoom.pan.mode === 'xy') {
                    zoom.pan.mode = 'y';
                } else {
                    zoom.pan.mode = '';
                    played && chart?.resetZoom();
                }
            } else {
                if (zoom.zoom.mode === 'y') {
                    zoom.zoom.mode = 'xy';
                } else {
                    zoom.zoom.mode = 'x';
                }
                if (zoom.pan.mode === 'y') {
                    zoom.pan.mode = 'xy';
                } else {
                    zoom.pan.mode = 'x';
                }
            }
            !isAnalog && chart?.update();
        }
    }, [xState, chart, played]);

    useEffect(() => {
        const zoom = chart?.options.plugins.zoom;
        if (zoom && !isAnalog) {
            if (!yState) {
                if (zoom.zoom.mode === 'xy') {
                    zoom.zoom.mode = 'x';
                } else {
                    zoom.zoom.mode = '';
                    played && chart?.resetZoom();
                }
                if (zoom.pan.mode === 'xy') {
                    zoom.pan.mode = 'x';
                } else {
                    zoom.pan.mode = '';
                    played && chart?.resetZoom();
                }
            } else {
                if (zoom.zoom.mode === 'x') {
                    zoom.zoom.mode = 'xy';
                } else {
                    zoom.zoom.mode = 'y';
                }
                if (zoom.pan.mode === 'x') {
                    zoom.pan.mode = 'xy';
                } else {
                    zoom.pan.mode = 'y';
                }
            }
            chart?.update();
        }
    }, [yState, chart, played]);

    const renderEditMarkerModalContent = (num) => {
        return (
            <div className={ss.editMarker}>
                <Modal.Head
                    modalClose={() => setEditMarker(false)}
                    title={'Редактировать ' + editMarker?.includes('box') ? 'диапазон' : 'маркер'}
                ></Modal.Head>
                <Modal.Body>
                    {Object.values(chartMarkers[num]).map((marker) => {
                        if (marker.type === 'box' && marker.id === editMarker) {
                            const { xMin, xMax } = marker;
                            return (
                                <div className="editMarker__fields">
                                    <div className="editMarker__field">
                                        <div className="editMarker__label">Мин. {Math.round(xMin)} мс.</div>
                                        <Range
                                            min={series[0]?.x}
                                            max={xMax}
                                            value={xMin}
                                            onChange={({ target }) => {
                                                const { value } = target;
                                                const updMarker = { ...chartMarkers[num] };
                                                const start = editMarker + 'start';
                                                updMarker[editMarker].xMin = value;
                                                updMarker[start].value = value;
                                                setChartMarkers({ num: editMarker, data: updMarker });
                                                setChartMarkerAdd(false);
                                                setChartMarkerDelete(false);
                                            }}
                                        />
                                    </div>
                                    <div className="editMarker__field">
                                        <div className="editMarker__label">Макс. {Math.round(xMax)} мс.</div>
                                        <Range
                                            min={xMin}
                                            max={series[series.length - 1]?.x}
                                            value={xMax}
                                            onChange={({ target }) => {
                                                const { value } = target;
                                                const updMarker = { ...chartMarkers[num] };
                                                const end = editMarker + 'end';
                                                updMarker[editMarker].xMax = value;
                                                updMarker[end].value = value;
                                                setChartMarkers({ num, data: updMarker });
                                                setChartMarkerAdd(false);
                                                setChartMarkerDelete(false);
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        }
                        if (marker.type === 'line' && marker.id === editMarker) {
                            const { value } = marker;
                            return (
                                <div className="editMarker__fields">
                                    <div className="editMarker__field">
                                        <div className="editMarker__label">Текущеее значение {Math.round(value)} мс.</div>
                                        <Range
                                            min={series[0]?.x}
                                            max={series[series.length - 1]?.x}
                                            value={value}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                const updMarker = { ...chartMarkers[num] };
                                                const xArr = series.map((item) => item.x);
                                                const closest = xArr.sort((a, b) => Math.abs(value - a) - Math.abs(value - b))[0];
                                                updMarker[editMarker].value = closest;
                                                updMarker[editMarker].label.content = (closest / 1000).toFixed(3);
                                                setChartMarkers({ num, data: updMarker });
                                                setChartMarkerAdd(false);
                                                setChartMarkerDelete(false);
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        }
                        // }
                    })}
                </Modal.Body>
            </div>
        );
    };

    const renderChartView = useCallback(() => {
        switch (chartView) {
            case 'bar':
                return (
                    <Bar
                        ref={chartRef}
                        options={options}
                        data={{ datasets }}
                        plugins={plugins}
                        onMouseDown={(e) => {
                            if (!markerActions.add) {
                                setDragging(true);
                                isDrag = true;
                            }
                        }}
                        onTouchStart={(e) => {
                            if (!markerActions.add) {
                                setDragging(true);
                                isDrag = true;
                            }
                        }}
                        onMouseUp={(e) => {
                            setDragging(false);
                            isDrag = false;
                        }}
                        // onTouchEnd={(e) => {
                        //     setDragging(false);
                        //     isDrag = false;
                        //     setLeaveEvent();
                        //     clickMarkersEvents(e);
                        // }}
                        onMouseEnter={(e) => {
                            // togleChartArea(true);
                            // setMatematicFunction(e);
                        }}
                        onMouseOut={(e) => {
                            if (chart) {
                                setDragging(false);
                                isDrag = false;
                                setLeaveEvent();
                            }
                            // togleChartArea(false);
                            // changeCoursor();
                        }}
                        onMouseMove={(e) => {
                            setMatematicFunction(e);
                            if (chart) {
                                changeCoursor(hoveredMarker);
                                if (dragging) {
                                    draggingBox(e, draggingMarker);
                                }
                            }
                        }}
                        onWheel={(e) => {
                            setMatematicFunction(e);
                        }}
                        // onTouchMove={(e) => {
                        //     if (dragging) {
                        //         draggingBox(e, draggingMarker);
                        //     }
                        // }}
                        onClick={(e) => clickMarkersEvents(e)}
                    />
                );

            case 'analog':
                return <AnalogDevice sensor={sensor} />;

            default:
                return (
                    <Line
                        ref={chartRef}
                        options={options}
                        data={{ datasets }}
                        plugins={plugins}
                        onMouseDown={(e) => {
                            if (!markerActions.add) {
                                setDragging(true);
                                isDrag = true;
                            }
                        }}
                        onTouchStart={(e) => {
                            if (!markerActions.add) {
                                setDragging(true);
                                isDrag = true;
                            }
                        }}
                        onMouseUp={(e) => {
                            setDragging(false);
                            isDrag = false;
                        }}
                        // onTouchEnd={(e) => {
                        //     setDragging(false);
                        //     isDrag = false;
                        //     setLeaveEvent();
                        //     clickMarkersEvents(e);
                        // }}
                        onMouseEnter={(e) => {
                            // togleChartArea(true);
                            // setMatematicFunction(e);
                        }}
                        onMouseOut={(e) => {
                            if (chart) {
                                setDragging(false);
                                isDrag = false;
                                setLeaveEvent();
                            }
                            // togleChartArea(false);
                            // changeCoursor();
                        }}
                        onMouseMove={(e) => {
                            setMatematicFunction(e);
                            if (chart) {
                                changeCoursor(hoveredMarker);
                                if (dragging) {
                                    draggingBox(e, draggingMarker);
                                }
                            }
                        }}
                        onWheel={(e) => {
                            setMatematicFunction(e);
                        }}
                        // onTouchMove={(e) => {
                        //     if (dragging) {
                        //         draggingBox(e, draggingMarker);
                        //     }
                        // }}
                        onClick={(e) => clickMarkersEvents(e)}
                    />
                );
        }
    }, [chartView, datasets, chart, dragging, hoveredMarker, draggingMarker, options, plugins]);

    return (
        <>
            <div className={ss.root}>
                <div className={ss.head}>
                    <div className={ss.left}>
                        <div className={ss.legend}>
                            <div
                                className={ss.color}
                                style={{
                                    background: color ? color : defaultColor,
                                }}
                            ></div>
                            <span>
                                {name} {chanelPrefix} {abscissaLabel && `(${abscissaLabel})`}
                            </span>
                        </div>
                    </div>
                    <div
                        className={ss.action + ' ' + ss.group}
                        data-learn="26,27,28,29,30,31,32,33"
                        data-learn-position="left"
                    >
                        {!isAndroid && chartView !== 'analog' && (
                            <div className={ss.iconGroup + ' ' + ss.saveList}>
                                <span data-learn-border="33">
                                    <Dropdown
                                        value={
                                            <Icon
                                                name={'save'}
                                                title={'Сохранить'}
                                            />
                                        }
                                        clicked={true}
                                        disabled={!seriesLength || played}
                                        // dropPosition="right"
                                    >
                                        {saveList.map(({ name, title, icon }) => (
                                            <Dropdown.Item
                                                key={name}
                                                onClick={() => setSaveName(name)}
                                            >
                                                <Icon name={icon} />
                                                <span>{title}</span>
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown>
                                </span>
                                {!isOscilloscope && (
                                    <>
                                        <span data-learn-border="30">
                                            <Icon
                                                tag="button"
                                                name="point"
                                                className={handlePointMode && ss.iconActive}
                                                title="Режим ручного снятия параметров"
                                                disabled={played || paused}
                                                onClick={() => setHandlePointMode(!handlePointMode)}
                                            />
                                        </span>
                                        {/* <Icon
                                    name="lissague"
                                    title="Фигуры Лиссажу"
                                    onClick={() => {
                                        if (!parametricMode) {
                                            toggleChartParametric([sensor, { name: '', num: '' }]);
                                        }
                                        toggleChartLissague(true);
                                    }}
                                /> */}
                                        <Icon
                                            name="scrap"
                                            title="Параметрические оси"
                                            onClick={() => toggleChartParametric([sensor, { name: '', num: '' }])}
                                        />
                                    </>
                                )}
                                <span data-learn-border="28">
                                    <Icon
                                        tag="button"
                                        name="zoom"
                                        className={isZoom && ss.iconActive}
                                        title="Масштабирование фрагмента. Нажать Shift"
                                        disabled={played}
                                        // onClick={() => setZoom(!isZoom)}
                                    />
                                </span>
                                <span data-learn-border="29">
                                    <Icon
                                        tag="button"
                                        name="chart-mob"
                                        className={showMiniChart && ss.iconActive}
                                        title="Просмотр фрагмента"
                                        disabled={played || isHiSpeed || !series.length}
                                        onClick={() => toggleMiniChart(!showMiniChart)}
                                    />
                                </span>
                            </div>
                        )}
                        <div className={ss.group}>
                            {chartView !== 'analog' && (
                                <span data-learn-border="31">
                                    <Dropdown
                                        value={
                                            <Icon
                                                name={matematicFunctionsList.filter((e) => e.name === currentFunction)[0]?.name}
                                                title={currentFunction === 'cleared' ? 'Выберите функцию' : matematicFunctionsList.filter((e) => e.name === currentFunction)[0]?.title}
                                            >
                                                <span className={'path1'}></span>
                                                <span className={'path2'}></span>
                                                <span className={'path3'}></span>
                                            </Icon>
                                        }
                                        clicked={true}
                                        disabled={!seriesLength || played}
                                        dropPosition="right"
                                    >
                                        {matematicFunctionsList.map(({ name, title }) => {
                                            return (
                                                name !== currentFunction && (
                                                    <Dropdown.Item
                                                        key={name}
                                                        onClick={() => setCurrentFunction(name)}
                                                    >
                                                        <Icon name={name}>
                                                            <span className={'path1'}></span>
                                                            <span className={'path2'}></span>
                                                            <span className={'path3'}></span>
                                                        </Icon>
                                                        <span>{title}</span>
                                                    </Dropdown.Item>
                                                )
                                            );
                                        })}
                                    </Dropdown>
                                </span>
                            )}
                            <span data-learn-border="32">
                                <Dropdown
                                    value={
                                        <Icon name={chartViewsList.filter((e) => e.name === chartView)[0]?.icon}>
                                            <span className={'path1'}></span>
                                            <span className={'path2'}></span>
                                        </Icon>
                                    }
                                    title={'Вид отображения'}
                                    clicked={true}
                                    disabled={(played && !paused) || isHiSpeed}
                                    dropPosition="right"
                                >
                                    {chartViewsList.map(({ icon, name, title }) => {
                                        if (name === 'analog' && !isDigital) return;
                                        return (
                                            <Dropdown.Item
                                                key={name}
                                                onClick={() => setChartView(name)}
                                            >
                                                <Icon name={icon}>
                                                    <span className={'path1'}></span>
                                                    <span className={'path2'}></span>
                                                </Icon>
                                                <span>{title}</span>
                                            </Dropdown.Item>
                                        );
                                    })}
                                </Dropdown>
                            </span>

                            {/* <Icon
                                tag="button"
                                name="cleared"
                                className={chartView === 'line' && 'active'}
                                title="Линейная диаграмма"
                                disabled={played || isHiSpeed}
                                onClick={() => setChartView('line')}
                            >
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </Icon> */}
                            {/* <Icon
                                tag="button"
                                name="bars"
                                className={chartView === 'bar' && 'active'}
                                title="Столбчатая диаграмма"
                                disabled={played || isHiSpeed}
                                onClick={() => setChartView('bar')}
                            />
                            <Icon
                                tag="button"
                                name="speed-dashboard"
                                className={chartView === 'analog' && 'active'}
                                title="Аналоговые датчики"
                                disabled={!isDigital}
                                onClick={() => setChartView('analog')}
                            /> */}
                        </div>
                        <span data-learn-border="27">
                            <ChartComment num={num} />
                        </span>
                        {!isAnalog && (
                            <span data-learn-border="26">
                                <Icon
                                    tag="button"
                                    name="capture"
                                    title="Полный масштаб"
                                    disabled={played}
                                    onClick={() => resetViewArea(chart, series)}
                                />
                            </span>
                        )}
                    </div>
                </div>
                <div className={ss.wrap}>
                    {setLabeles()}
                    <div
                        className={chartView === 'analog' ? ss.big + ' ' + ss.big_center : ss.big}
                        id={chartView !== 'analog' && deviceKey + num}
                        data-learn="9"
                        data-learn-border="9"
                        data-learn-position="center"
                    >
                        {renderChartView()}
                        {(!played || paused) && legendMode && isChartArea && (
                            <div className={ss.tootltip}>
                                <div className={ss.tootltipRow}>
                                    <label>Время</label> <span>{hoverTime}c</span>
                                </div>
                                <div className={ss.tootltipRow}>
                                    <label>{name}</label>{' '}
                                    <span>
                                        {hoverValue}
                                        {unit}
                                    </span>
                                </div>
                                <div className={ss.tootltipFoot}>
                                    <div className={ss.tootltipCol}>
                                        <label>Мин</label>
                                        <span>
                                            {hoverMinMax[0]} {unit}
                                        </span>
                                    </div>
                                    <div className={ss.tootltipCol}>
                                        <label>Макс</label>
                                        <span>
                                            {hoverMinMax[1]} {unit}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {showMiniChart && !isHiSpeed && chartView !== 'analog' && (
                        <ChartMini
                            chart={chart}
                            played={played}
                            paused={paused}
                            series={series}
                            theme={theme}
                            tension={tension}
                            color={color}
                            defaultColor={defaultColor}
                            weight={weight}
                            cubicInterpolationMode={cubicInterpolationMode}
                            stepped={stepped}
                            roundDigit={roundDigit}
                        />
                    )}
                </div>
                {!isOscilloscope && chartView !== 'analog' && (
                    <div className={ss.foot}>
                        <div
                            className={ss.scalesZoom}
                            data-learn="24"
                            data-learn-border="24"
                            data-learn-position="top"
                        >
                            <label title="Масштабирование по оси Х">
                                <span>X</span>
                                <Switcher
                                    checked={xState}
                                    onChange={() => setXstate(!xState)}
                                />
                            </label>
                            <label title="Масштабирование по оси Y">
                                <span>Y</span>
                                <Switcher
                                    checked={yState}
                                    onChange={() => setYstate(!yState)}
                                />
                            </label>
                        </div>
                        <div
                            data-learn="25"
                            data-learn-border="25"
                            data-learn-position="top"
                            className={ss.autoChart}
                        >
                            <label>
                                <span
                                    title="Выключить автоотслеживание"
                                    onClick={() => setAutoTracking(!autoTracking)}
                                >
                                    <Icon name={'not-auto-zoom'} />
                                </span>
                                <Switcher
                                    checked={autoTracking}
                                    onChange={() => setAutoTracking(!autoTracking)}
                                />
                                <span title="Включить автоотслеживание">
                                    <Icon name={'auto-zoom'} />
                                </span>
                            </label>
                        </div>
                    </div>
                )}
            </div>
            {isAndroid && editMarker && !markerActions.add && !markerActions.del && (
                <Modal
                    visible={editMarker}
                    size={'xs'}
                >
                    {renderEditMarkerModalContent(num)}
                </Modal>
            )}
        </>
    );
};

export default ChartArea;
