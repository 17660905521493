const initialState = {
    item: '',
    list: [],
    bundles: [],
    bundlesModalDel: false,
    sorting: 'all',
    data: [],
    asideData: [],
    writedData: [],
    enabled: {},
    writed: [],
    records: [],
    imported: [],
    tablePrint: [],
    tableSorting: false,
    frequency: {},
    mode: {},
    modal: false,
    modalPrint: false,
    graduationModal: false,
    isHiSpeed: false,
    measunits: {},
    gradOffset: {},
    units: {},
    abscissa: {},
    summaryModal: false,
};

const sensorsReducer = (state = initialState, action) => {
    if (action.type === 'SET_SENSOR') {
        let item = action.payload;
        // console.log(state.item);
        return {
            ...state,
            item,
        };
    }

    if (action.type === 'SET_MEASUNITS') {
        return {
            ...state,
            measunits: action.payload,
        };
    }

    if (action.type === 'SET_SENSOR_UNIT') {
        let units = { ...state.units };
        units[action.payload.key] = action.payload.unit;
        return {
            ...state,
            units,
        };
    }

    if (action.type === 'SET_SENSOR_ABSCISSA') {
        let abscissa = { ...state.abscissa };
        abscissa[action.payload.key] = action.payload.data;
        return {
            ...state,
            abscissa,
        };
    }

    if (action.type === 'SET_SENSOR_GRAD_OFFSET') {
        let gradOffset = { ...state.gradOffset };
        gradOffset[action.payload.key] = action.payload.data;
        return {
            ...state,
            gradOffset,
        };
    }

    if (action.type === 'ADD_SENSORS_ENABLED') {
        let enabled = { ...state.enabled };
        enabled[action.payload.key] = action.payload.count;
        // console.log(state.item);
        return {
            ...state,
            enabled,
        };
    }

    if (action.type === 'DEL_SENSORS_ENABLED') {
        let enabled = { ...state.enabled };
        delete enabled[action.payload.key];
        // console.log(state.item);
        return {
            ...state,
            enabled,
        };
    }

    if (action.type === 'CLEAR_SENSORS_ENABLED') {
        // console.log(state.item);
        return {
            ...state,
            enabled: {},
        };
    }

    if (action.type === 'ADD_SENSORS_BUNDLES') {
        let bundles = { ...state.bundles };
        bundles[action.payload.key] = action.payload.data;
        return {
            ...state,
            bundles,
        };
    }

    if (action.type === 'DEL_SENSORS_BUNDLES') {
        let bundles = { ...state.bundles };
        delete bundles[action.payload];
        return {
            ...state,
            bundles,
        };
    }

    if (action.type === 'UPDATE_SENSORS_BUNDLES') {
        let bundles = { ...state.bundles };
        // delete bundles[action.payload.key];
        bundles[action.payload.key].name = action.payload.name;
        return {
            ...state,
            bundles,
        };
    }

    if (action.type === 'SET_SENSORS_BUNDLE_MODAL_DEL') {
        // console.log(state.item);
        return {
            ...state,
            bundlesModalDel: action.payload,
        };
    }

    if (action.type === 'SET_SENSORS_MODAL') {
        // console.log(state.item);
        return {
            ...state,
            modal: action.payload,
        };
    }

    if (action.type === 'SET_PRINT_MODAL') {
        // console.log(state.item);
        return {
            ...state,
            modalPrint: action.payload,
        };
    }

    if (action.type === 'SET_GRADUATION_MODAL') {
        // console.log(state.item);
        return {
            ...state,
            graduationModal: action.payload,
        };
    }

    if (action.type === 'SET_SENSOR_LIST') {
        // console.log(state.list);
        return {
            ...state,
            list: action.payload,
        };
    }

    if (action.type === 'SET_SENSOR_DATA') {
        // console.log(state.list);
        return {
            ...state,
            data: action.payload,
        };
    }

    if (action.type === 'SET_SENSOR_ASIDE_DATA') {
        // console.log(state.list);
        return {
            ...state,
            asideData: action.payload,
        };
    }

    if (action.type === 'SET_SENSOR_WRITED_DATA') {
        // console.log(state.list);
        return {
            ...state,
            writedData: action.payload,
        };
    }

    if (action.type === 'SET_SENSOR_WRITED_LIST') {
        // console.log(state.list);
        return {
            ...state,
            writed: action.payload,
        };
    }

    if (action.type === 'SET_SENSOR_RECORDS') {
        // console.log(state.list);
        return {
            ...state,
            records: action.payload,
        };
    }

    if (action.type === 'SET_SENSOR_IMPORTED') {
        // console.log(state.list);
        return {
            ...state,
            imported: action.payload,
        };
    }

    if (action.type === 'SET_SENSOR_TABLE_PRINT') {
        // console.log(state.table);
        return {
            ...state,
            tablePrint: action.payload,
        };
    }

    if (action.type === 'SET_SENSOR_TABLE_SORTING') {
        // console.log(state.table);
        return {
            ...state,
            tableSorting: action.payload,
        };
    }

    if (action.type === 'SET_SENSORS_SORTING') {
        // console.log(state.table);
        return {
            ...state,
            sorting: action.payload,
        };
    }

    if (action.type === 'SET_SENSOR_MODE') {
        const index = action.payload.id;
        return {
            ...state,
            mode: {
                ...state.mode,
                [index]: action.payload.data,
            },
        };
    }

    if (action.type === 'SET_SENSOR_FREQUENCY') {
        // console.log(state.frequency);
        return {
            ...state,
            frequency: action.payload,
        };
    }

    if (action.type === 'CHECK_HISPEED') {
        return {
            ...state,
            isHiSpeed: action.payload,
        };
    }

    if (action.type === 'SET_SENSOR_SUMMARY_MODAL') {
        return {
            ...state,
            summaryModal: action.payload,
        };
    }

    return state;
};

export default sensorsReducer;
