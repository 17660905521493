import React from 'react';
import './NewModals.scss';

const NewModalsButton = ({type, onClick, title, className}) => {
  return (
      <div className={`new_modals__button ${className} ${type}`} onClick={onClick}>{title}</div>
  );
}

export default NewModalsButton;
