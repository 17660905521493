export const resetViewArea = (chart, data, parametric = false) => {
    chart?.resetZoom();
    if (parametric) {
        const { xMin, xMax, yMin, yMax } = parametric;
        if (xMin) chart.options.scales.xAxis.min = xMin;
        if (xMax) chart.options.scales.xAxis.max = xMax;
        if (yMin) chart.options.scales.yAxis.min = yMin;
        if (yMax) chart.options.scales.yAxis.max = yMax;
    } else {
        let min, max;

        if (data[0]?.data) {
            const vals = data[0].data;
            min = vals[0]?.x;
            max = vals[vals.length - 1]?.x;
        } else {
            min = data[0]?.x;
            max = data[data.length - 1]?.x;
        }

        chart.options.scales.xAxis.min = min || 0;
        chart.options.scales.xAxis.offset = false;
        // chart.options.scales.xAxis.left = 0;
        chart.options.scales.xAxis.max = max || 10000;
    }
    chart.update();
};
