import { setVideoEditorModal, setVideoEditorOptions } from '../../actions/videoeditor';

export const mapStateToProps = (state) => {
    return {
        modal: state.videoeditor?.modal ? state.videoeditor.modal : false,
        options: state.videoeditor?.options ? state.videoeditor.options : undefined,
        isCor: state.actions.isCor,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setVideoEditorModal: (bool) => dispatch(setVideoEditorModal(bool)),
    setVideoEditorOptions: (options) => dispatch(setVideoEditorOptions(options)),
});
