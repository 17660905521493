import { setSensorRecords, setSensorTablePrint, setSensorTableSorting, setSensorsSummaryModal } from '../../actions/sensors';

export const mapStateToProps = (state) => {
    return {
        port: state.port.open,
        played: state.actions.played,
        paused: state.actions.paused,
        isMobile: state.actions.isMobile,
        mobileView: state.actions.mobileView,
        bundlesList: state.sensors.bundles,
        sensorList: state.sensors.list,
        sensorData: state.sensors.data,
        writedData: state.sensors.writedData,
        sensorsWrited: state.sensors.writed,
        sensorImported: state.sensors.imported,
        tableSorting: state.sensors.tableSorting,
        markers: state.chart.marker,
        chartDisabled: state.chart.disabled,
        sensorReading: state.reader.sensorReading,
        chartStyle: state.chart.style,
        defaultColors: state.chart.settings.colors,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setSensorRecords: (list) => dispatch(setSensorRecords(list)),
    setSensorTablePrint: (table) => dispatch(setSensorTablePrint(table)),
    setSensorTableSorting: (bool) => dispatch(setSensorTableSorting(bool)),
    setSensorsSummaryModal: (bool) => dispatch(setSensorsSummaryModal(bool)),
});
