import {showCurrTime, showRemainingTime} from "../ui/utils/gen_utils";
import { Button, Icon } from '../ui';
import {isAuth} from "../../helpers/auth";
import {Back, ContentHead, ContentHeader} from "../template/ContentParts";
import {onlineTestInfoApi, activeLabWorkApi, vrLabInfoApi} from "../../api/api";
import {PRACTICUM_TEST_TYPE, hasPracticumTestInfo, getPracticumTestInfo, 
    removePracticumTestInfo} from "../ui/utils/ServerInfo";
import socket from "../../socket";

export const getActiveTest = async (teacherIds, documentClassId, _setActiveTest) => {
    //проверяем базу - есть ли там запущенные тесты. Если есть, достаем инфу
    const result = await activeLabWorkApi.getActiveLabWorkByTeachers(teacherIds, documentClassId);
    _setActiveTest(result.data);
};

export const stopActiveTest = (roomId) => {
    //учитель завершает тест
    const clearActiveTest = async (owner, room, workId) => {
        await activeLabWorkApi.deleteActiveLabWork(owner, room, workId)
            .then (res => res)
            .catch(err => console.log('err=', err));
    };
    
    if (isAuth().role > 0 && hasPracticumTestInfo()) {
        const info = getPracticumTestInfo();
        const id = !info ? '' : info.labWorkId;
        clearActiveTest(isAuth()._id, roomId, id);
        removePracticumTestInfo();
        socket.emit('BE-stop-test', {roomId: roomId, test: {testStop: true}});
    };
};

export const checkRunStudentTest = async (testInfo, setCanRunStudentTest) => {
    const owner = isAuth()._id;
    if (testInfo.testType === PRACTICUM_TEST_TYPE.ONLINE_TEST) {
        await onlineTestInfoApi.getOnlineTestInfoByRunId(owner, testInfo.testRunId)
            .then (res => {
                const data = res.data;
                if (data.length > 0 && !data[0].isComplete) {
                    setCanRunStudentTest(testInfo);
                }
            })
            .catch(err => {console.log('err=', err);}
        );
    } else if (testInfo.testType === PRACTICUM_TEST_TYPE.LAB_WORK) {
        setCanRunStudentTest(testInfo);
    } else if (testInfo.testType === PRACTICUM_TEST_TYPE.LW_OPTIC_GAME) {
        //don't open vr test if it is already passed:
        const [subjId, sectId, labId] = testInfo.labWorkId.split('|');
        const vrLabInd = Number(labId.substring(labId.length -2));
        const result = await vrLabInfoApi.getVrLabInfoByOwnerIdByVrLabInd(owner, vrLabInd);
        if (result.data.length === 0 || !result.data[0].isComplete) {
            setCanRunStudentTest(testInfo);
        }
    }
};        

export const checkTestTime = (testStartTime, timeLimitInMinutes) => {
    const MAX_TEST_LENGTH_IN_MSEC = 2 * 60 * 60 * 1000; // 2 hours
    const maxDifference = timeLimitInMinutes > 0 ? (timeLimitInMinutes * 60 * 1000) : MAX_TEST_LENGTH_IN_MSEC;
    const startTestTime = new Date(testStartTime);
    const currStartTime = new Date();
    const diffInMsec = currStartTime.getTime() - startTestTime.getTime();
    return diffInMsec < maxDifference;
};

export const getTopHeader = (title, handleStopWork, testType, hasNonCheckedAnswers = false) => {
    const name1 = testType !== PRACTICUM_TEST_TYPE.LW_OPTIC_GAME ? 'тестирования' : 'игры';
    const name2 = testType !== PRACTICUM_TEST_TYPE.LW_OPTIC_GAME ? 'тестирование' : 'игру';
    
    return (
		<>
            <ContentHead>
                <div className="cor_content__title">
                    Результаты {name1} {!!title ? 'по предмету ' + title : '' }
                </div>

                {hasNonCheckedAnswers && 
                    <span className="cor_content__status">
                        <Icon name="warning"/>
                        <span>Необходима проверка вручную!</span>
                    </span>          
                }

                {!!handleStopWork && (
                    <Button size="extraSmall" color="primary" onClick={handleStopWork}>
                        Завершить {name2}
                    </Button>
                )}
            </ContentHead>
		</>
    );
};

export const getSelectedRunTopHeader = (isLabWork, title, testDate, handleHistory, where) => {
    return (
        <ContentHead flex={false}>
            <Back 
                onClick={handleHistory} 
                text={where}
                icon="back" 
                margin="bottom"
            />
            <div className="cor-net__row mt_0">
                <div className="cor-net__col col-grow">
                    <div className="cor_content__title">
                        {isLabWork ? 'Результаты тестирования ' + title : 'Результаты проведения онлайн теста ' + title}
                    </div>
                </div>
                <div className="cor-net__col">
                    <div className="cor_content__title">Дата тестирования {testDate}</div>
                </div>
            </div>
        </ContentHead>
    );
};

export const getHeader = (name, chapter, timeLimitEnabled, timeLimitInMinutes, currTestTime, startTime) => {
    return (
        <ContentHeader flex={true}>
            <div className="cor_content__header_title">{name}</div>
            <div className="cor_content__header_description">{!!chapter ? 'Раздел ' + chapter : ''}</div>
            {!!startTime && (
                <>
                    {
                    timeLimitEnabled ? showRemainingTime(currTestTime, startTime, 60 * timeLimitInMinutes, true) 
                    : 
                    showCurrTime(currTestTime, startTime)
                    }
                </>
            )}
        </ContentHeader>
    );
};

export const getMinMaxDatesInArray = dates => {
    let minInd = 0;
    let maxInd = 0;
    for (let i = 0; i < dates.length; i++) {
        if (dates[minInd].valueOf > dates[i].valueOf) minInd = i;
        if (dates[maxInd].valueOf < dates[i].valueOf) maxInd = i;
    }
    return [dates[minInd].date, dates[maxInd].date];
};

export const shouldReorderDates = (startDate, finishDate) => {
    if (!startDate || !finishDate) return false;
    if ((new Date(startDate)).valueOf() <= (new Date(finishDate)).valueOf()) return false;
    return true
};

export const getExceptionCellStyle = (item) => {
    if (item === 'Готово') return {color: 'green'};
    if (item === 'Онлайн') return {color: 'green'};
    if (item === 'Необходима проверка') return {color: '#C96203'};
    if (item === 'Проверено') return {color: 'green'};
    if (item === 'Тест не пройден') return {color: '#C96203'};

    return {};
};
