const MIN_GOOD_SCORE = 50;
const SCORE_NAMES = '5|5-|4+|4|4-|3+|3|3-|2'; 
const SCORE_VALUES = '94|89|84|79|74|69|59|49|1'; //|0
const SCORE_TRADITIONAL_NAMES = 'Отлично|Отлично|Хорошо|Хорошо|Хорошо|Удовлетворительно|Удовлетворительно|Удовлетворительно|Неудовлетворительно'; 
const ONLY_2345 = '89|74|49|1'; //0 means the score is not defined //'89|74|49|0'; 
export const SCORE_MANUAL_CHECK = -1;
export const SCORE_BE_EVALUATED_LATER = -2;

export const getMinGoodScore = () => MIN_GOOD_SCORE;
export const getScoreValues = () => SCORE_VALUES.split('|').map(item => Number(item));
export const getScoreNames = () => SCORE_NAMES.split('|');
export const getTraditionalScoreNames = () => SCORE_TRADITIONAL_NAMES.split('|');

export const calcScoreValue = (answeredNumber, totalNumber, nonCheckedAnswerNumber = 0) => {
    if (nonCheckedAnswerNumber) {
        return 'Ручная проверка';
    }

    if (totalNumber === 0) return 1; //1 соответствует оценке '2'

    return Math.floor(answeredNumber / totalNumber * 100); //вычислить по кол-ву правильных ответов
};

export const getScoreName = (scoreValue) => {
    const values = getScoreValues();
    const scoreNames = getScoreNames();

    if (scoreValue === SCORE_MANUAL_CHECK || scoreValue === SCORE_MANUAL_CHECK.toString()) 
        return 'Не проверено';

    if (!scoreValue || scoreValue === '0') 
        return 'Не сдано';

    for (let i = 0; i < values.length - 1; i ++)
        if (scoreValue >= values[i]) return scoreNames[i];
    return scoreNames[values.length - 1];
};

export const getTableScoreList = (list, score) => {
    const scores = [...list];
    const thresholds = ONLY_2345.split('|').map(item => Number(item));

    if (score >= thresholds[0]) scores[0] ++; //кол-во оценок 5
    else if (score >= thresholds[1]) scores[1] ++; //кол-во оценок 4
    else if (score >= thresholds[2]) scores[2] ++; //кол-во оценок 3
    else if (score >= thresholds[3]) scores[3] ++; //кол-во оценок 2
    else scores[4] ++; //кол-во 'нет оценки'
    return scores;
};

export const getScoreCbList = () => {
    const values = getScoreValues();
    const names = getScoreNames();
    const list = [];

    list.push({label: 'Нет оценки', value: ''}); //Поставить оценку
    for (let i = 0; i < values.length; i ++)
        list.push({label: names[i], value: values[i]});
    return list;
};

export const getScoreValue = (scoreValue) => {
    if (!scoreValue) return ''; //нет оценки 
    const values = getScoreValues();

    for (let i = 0; i < values.length - 1; i ++)
        if (Number(scoreValue) >= values[i]) {
            return values[i];
        }

    return values[values.length - 1];
};

