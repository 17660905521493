import React from 'react';
import Grid from '../ui/Grid';
import ss from './OrgGrid.module.scss';
import {fetchclass, fetchOrgs, fetchUsersByOrg, selectorgs} from "../../redux/slices/classsline";
import {useDispatch, useSelector} from "react-redux";
import {Icon} from "../ui";
import {reloadOrg} from "../../redux/slices/storage";
import {FEATURES} from "../../api/config";
import {hasFeature, profilePicture} from "../../helpers/auth";
import {userApi} from "../../api/api";
import {toast} from "react-toastify";

const OrgGrid = (props) => {
    const dispatch = useDispatch();
    const [orgList] = useSelector(selectorgs);

    const selectOrg = (org)=> {
        dispatch(reloadOrg(org));
        dispatch(fetchUsersByOrg(org._id))
        dispatch(fetchclass(org._id));
        props.history.push({pathname: '/', state: {org: org._id}});
    }

    const changeOrg = (org)=> {
        dispatch(fetchclass(org._id));
        props.showUserSettings({showModal: true, user: org, role: 2, allowDelete: true})
    }

    const freeze = async (org) => {
        const status = org.status ? -org.status : -1;
        userApi.update(org._id, {status})
        .then(() => dispatch(fetchOrgs()))
        .catch((err) => toast.error(err.response.data.message));
    };

    const renderPicture = (pict, name) => pict ? <img src={profilePicture(pict)} alt={name}/> : <Icon name='school'/>;

    return (
        <Grid className={ss.root}>
            {orgList.filter(o => (o.name.toLowerCase().includes(props.search.toLowerCase()) ||
              (''+o.uid).includes(props.search.toLowerCase()) ) &&
              (!props.active || (props.active === 1 ? Math.abs(o.status) === 2 : Math.abs(o.status) !== 2))
            ).map((org, i) => (
              <Grid.Col className={ss.item} key={i}>
                  <div className={ss.caption} onClick={() => selectOrg(org)}>
                      <div className={ss.img}>
                          {renderPicture(org.picture, org.name)}
                      </div>
                      <div className={ss.title}>Школа №{org.name}</div>
                  </div>
                  <ul className={ss.info}>
                      <li className={ss.info__item}>
                          <span>UID</span>
                          <span>{org.uid ?? "-"}</span>
                      </li>
                      <li className={ss.info__item}>
                          <span>Лицензии</span>
                          <span>{Object.entries(FEATURES).filter(([k, v]) => v.name && hasFeature(org, k)).length}</span>
                      </li>
                  </ul>
                  <div className={ss.action}>
                      {Math.abs(org.status) === 2 ?
                        <Icon
                          className={ss.status_active}
                        name="user-check"
                        tp="Активирована"
                      /> :
                      <Icon
                        className={ss.status_inactive}
                        name="question-circle"
                        tp="Не Активирована"
                      />}
                    <Icon
                        className={ss.action__item} 
                        name="gear" 
                        tp="Настройка"
                        onClick={() => changeOrg(org)}
                    />
                    <Icon
                      className={org.status < 0 ? ss.action__item_active : ss.action__item}
                      onClick={() => freeze(org)}
                      name="snowflake"
                        tp="Заморозить"
                    />
                </div>

              </Grid.Col>
            ))}
        </Grid>
    )
};
export default OrgGrid;
