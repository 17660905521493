import { addSensorsBundles, delSensorsBundles, setSensorsSorting } from '../../../actions/sensors';

export const mapStateToProps = (state) => {
    return {
        sensorList: state.sensors.list,
        bundles: state.sensors.bundles,
        sorting: state.sensors.sorting,
        devices: state.devices.count,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    addSensorsBundles: (item) => dispatch(addSensorsBundles(item)),
    delSensorsBundles: (item) => dispatch(delSensorsBundles(item)),
    setSensorsSorting: (str) => dispatch(setSensorsSorting(str)),
});
