import { setGraduationModal, setSensorsModal } from '../../../actions/sensors';
import { setChartSettingsModal } from '../../../actions/chart';
import { toggleTimerModal } from '../../../actions/timer';
import { setDeviceBatteryModal } from '../../../actions/devices';
import { setFirmwareModal } from '../../../actions/firmware';

export const mapStateToProps = (state) => {
    return {
        port: state.port.open,
        connectionType: state.port.connectionType,
        connectionCount: state.devices.count,
        played: state.actions.played,
        isMobile: state.actions.isMobile,
        imported: state.sensors.imported,
        isOscilloscope: state.oscilloscope.isOscilloscope,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setChartSettingsModal: (bool) => dispatch(setChartSettingsModal(bool)),
    setSensorsModal: (bool) => dispatch(setSensorsModal(bool)),
    setGraduationModal: (bool) => dispatch(setGraduationModal(bool)),
    toggleTimerModal: (bool) => dispatch(toggleTimerModal(bool)),
    setDeviceBatteryModal: (bool) => dispatch(setDeviceBatteryModal(bool)),
    setFirmwareModal: (bool) => dispatch(setFirmwareModal(bool)),
});
