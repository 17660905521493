import React from "react";

import DashboardRlabAnon from "../template/Dashboard/DashboardRlabAnon";
import {Route} from "react-router-dom";
import DashboardCalcModels from "../template/Dashboard/DashboardCalcModels";

function Home(props) {
    return (<>
        <main className="cor_main">
            <Route exact path="/rlab">
                <DashboardRlabAnon {...props}/>
            </Route>
            <Route exact path="/rlab/:id/:opt?">
                <DashboardRlabAnon {...props}/>
            </Route>
            <Route exact path="/cm/:id?/:opt?">
                <DashboardCalcModels {...props} />
            </Route>
        </main>
    </>);
}

export default Home;
