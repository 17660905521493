//https://www.sciencedirect.com/science/article/pii/S0168900222002455#sec6  - 6.3
//The finite solenoid contains information about its two radii, length, number of wires, the current in the wires, and the posistion of the center of the tube 
export const getInitMagneticFieldData = (R1, alfa, N, current, L, Z1, Z2) => {
    const testArrays = (k_arr, lambda_arr, mu_arr, nu_arr) => {
        const kLengh = k_arr.length;
        if (lambda_arr.length !== kLengh || mu_arr.length !== kLengh || nu_arr.length !== kLengh) {
            console.log("===wrong array length");
        }
    };

	const McDOrder = 4;	// number of terms to use in the McDonald model
    const R2 = R1 * (1 + alfa);

	const k_arr = [3, -1, -1];
	const lambda_arr = [1, 3, 3];
	const mu_arr = [1, 2, 3];
	const nu_arr = [1, 2, 1];
	const Ti_arr = [2, 2, 2];

    const mu0 = 1.25664 * 0.001 * 0.001; //Гн/м (Н/А2)
	const Na = 2 * McDOrder + 2; 	// the number of derivatives needed to calculate the requested order (McDOrder)
	let NT; // the total number of terms in a_n. calculated.
	const L_2 = 0.5 * L;
	let C = mu0 * current * N / (2 * L * (R2 - R1));
	const z_power_needed =3 + (Na - 2);
    const a_power_needed = 3 + 2 * (Na - 2);
    const b_power_needed = 2 + (Na - 2);

    NT = 0; // the total number of terms contributed by the 2nd to the n'th order
    for(let n = 2; n < Na; n ++){
        NT += Math.pow(3, n-1); // the number of terms contributed by the n'th order
    }

	// Each term gives rise to another 3 terms for the next order. Calculate the remaining parameters:
	let NTAcc_i = 0; 				// placeholder for the accumulated number of terms to the (n_i)'th order (for n_i > 1)
	for (let n_i = 2; n_i < Na; n_i++){ 	// for the 2nd and the remaining orders
		const NT_im1 = Math.pow(3, n_i-2);		// the number of terms associated with the (n_i-1)'th order
		const NT_i = Math.pow(3, n_i-1); 		// the number of terms associated with the (n_i)'th order			
				
		if (n_i > 2) {					// The parameters for the 2nd derivative have already been calculated, so skip this step if n_i = 2
			for (let i = 0; i < NT_im1; i++) { 		// Loop over all terms in the previous derivative (parent terms) and calc the terms derived from them
				const P_i = NTAcc_i - NT_im1 + i; 	// Parent index. The index of the term that gives rise to the next 3 terms	
						
				k_arr.push(k_arr[P_i] * lambda_arr[P_i]);	
				lambda_arr.push(lambda_arr[P_i] - 1);
				mu_arr.push(mu_arr[P_i]);
				nu_arr.push(nu_arr[P_i]);
				Ti_arr.push(n_i);
						
				k_arr.push(-k_arr[P_i] * nu_arr[P_i]);	
				lambda_arr.push(lambda_arr[P_i] + 1); 	
				mu_arr.push(mu_arr[P_i] + 1); 	
				nu_arr.push(nu_arr[P_i] + 1);
				Ti_arr.push(n_i);
						
				k_arr.push(-k_arr[P_i]*mu_arr[P_i]);	
				lambda_arr.push(lambda_arr[P_i] + 1); 	
				mu_arr.push(mu_arr[P_i] + 2); 
				nu_arr.push(nu_arr[P_i]);
				Ti_arr.push(n_i);				
			}
		}
		
		NTAcc_i += NT_i; // The parameters of the terms of the (n_i)'th order are now calculated
	}

    testArrays(k_arr, lambda_arr, mu_arr, nu_arr);
   
    let i = 0;
    while (i < k_arr.length){
        const k_i = k_arr[i];
        const lambda_i = lambda_arr[i];
        const mu_i = mu_arr[i];
        const nu_i = nu_arr[i];
        const Ti_i = Ti_arr[i];
        
        if(k_i === 0) { //arr.splice(i, 1)
            k_arr.splice(i, 1); 	// delete this term since the coefficient is 0
            lambda_arr.splice(i, 1);
            mu_arr.splice(i, 1);
            nu_arr.splice(i, 1);
            Ti_arr.splice(i, 1);			
        } else {
            let j = i + 1; // an index to scan throught the remaining elements

            while (j < k_arr.length) {
                if(lambda_arr[j] === lambda_i && mu_arr[j] === mu_i && nu_arr[j] === nu_i && Ti_arr[j] === Ti_i) {
                    k_arr[i] += k_arr[j]; 	// the two terms are the same and belong to the same order. Add their front factors together...
                    k_arr.splice(j, 1); 	// ... and delete the matched element
                    lambda_arr.splice(j, 1);
                    mu_arr.splice(j, 1);
                    nu_arr.splice(j, 1);
                    Ti_arr.splice(j, 1);
                }else {
                    j += 1;
                }				
            }

            i += 1;
        }
    }

    testArrays(k_arr, lambda_arr, mu_arr, nu_arr);
    NT = k_arr.length;

    const initMagneticFieldData = {
        R1: R1, 
        R2: R2, 
        N: N, 
        current: current, 
        L: L,
        mu0: mu0,
        Na: Na,
        NT: NT,
        L_2: L_2,
        C: C,
        Z1: Z1,
        Z2: Z2,
        z_power_needed: z_power_needed,
        a_power_needed: a_power_needed, 
        b_power_needed: b_power_needed,
        k_arr: k_arr,
        lambda_arr: lambda_arr,
        mu_arr: mu_arr,
        nu_arr: nu_arr,
        Ti_arr: Ti_arr
    };

    return initMagneticFieldData;
};

const getA0 = (z, R, imfd) => {
    const getEmptyArr = n => {
        const arr = [];
        for (let i = 0; i < n; i ++) arr.push(0);
        return arr;
    };
	// This function calculates the the a_n terms for a given z and R. 
	// The derivatives of a_0 have been precalculated by the class constructor, which
	// was instantiated to be able to go to a given order. The requested order has to
	// be below this various outcommented debugging code has been left here
	
	const z2 = z * z;
	const R2 = R * R;
	const A = Math.sqrt(R2 + z2);
	const B = A + R;    
	const b = 1/B;   
	const a = 1/A;
	const logB = Math.log(B);

    const a0_array = getEmptyArr(imfd.Na);
	a0_array[0] = z * logB; // z*ln(sqrt(R2+z2)+z)
	a0_array[1] = logB + z2 * a * b;
	
	const z_powers = getEmptyArr(imfd.z_power_needed);
	const a_powers = getEmptyArr(imfd.a_power_needed);
	const b_powers = getEmptyArr(imfd.b_power_needed);
		
	for(let i = 0; i < imfd.z_power_needed; i++){
		if (i === 0) z_powers[i] = 1;
		else z_powers[i] = z_powers[i-1] * z;
	}

	for(let i = 0; i < imfd.a_power_needed; i++){
		if (i === 0) a_powers[i] = 1;
		else a_powers[i] = a_powers[i-1] * a;
	}

	for(let i = 0; i < imfd.b_power_needed; i++){
		if (i === 0) b_powers[i] = 1;
		else b_powers[i] = b_powers[i-1] * b;
	}
		
	for(let i = 0; i < imfd.NT; i++) {
		a0_array[imfd.Ti_arr[i]] += 
            imfd.k_arr[i] * z_powers[imfd.lambda_arr[i]] * a_powers[imfd.mu_arr[i]] * b_powers[imfd.nu_arr[i]];
	}

    return a0_array;
}

//export const getB = (cylP, BCylVec, Z1, Z2, imfd) => {
export const getB2 = (xy, imfd) => {
	/* Calculates the magnet field in BCylVec at the cylindrical coordinate cylVec for a finite solenoid of radius R and total current I using the method described by the McDonald model
	 * 
	 * @param tube 			the tube creating the magnetic field
	 * @param cylP 			the cylindrical coordinate of interest where the magnetfield is calculated
	 * @param BCylVec 		the magnetfield in cylP being calulated in cylindrical coordinates
	 * @param McDSupport	this function uses a class to precalculate the derivatives of the McD model. This is FASTER AND BETTER
	 */
	
    if (!imfd) return [0, 0];

	// Coordinates
	// const rho = cylP[0];
	// const z1 = cylP[2] - Z1; // define coordinates relative to the position of the solenoid
	// const z2 = cylP[2] - Z2;	
	const z1 = xy[0] - imfd.Z1; // define coordinates relative to the position of the solenoid
	const z2 = xy[0] - imfd.Z2;
	const rho = xy[1];

	const an11 = getA0(z1, imfd.R1, imfd);   // array to hold terms with z1 and R1
	const an12 = getA0(z1, imfd.R2, imfd);   // array to hold terms with z1 and R2
	const an21 = getA0(z2, imfd.R1, imfd);   // array to hold terms with z2 and R1
	const an22 = getA0(z2, imfd.R2, imfd);   // array to hold terms with z2 and R2
	
	// Preparing terms for loop
	let constZTerm = 1;	
	let constRTerm = - (rho / 2);
	let B_z = an12[0] - an11[0] - an22[0] + an21[0];
	let B_rho = constRTerm * (an12[1] - an11[1] - an22[1] + an21[1]);
	
	// Looping over the series
	for (let n = 1; n <= imfd.McDOrder; n++){ 
		constZTerm *=  (-1) * Math.pow(rho / 2, 2) / Math.pow(n, 2);
        let k = 2 * n;
		B_z += constZTerm * (an12[k] - an11[k] - an22[k] + an21[k]);

        k = 2 * n + 1;
		constRTerm *= (-1) * Math.pow(rho / 2, 2) * (n) / ((n + 1) * Math.pow(n, 2));
		B_rho += constRTerm * (an12[k] - an11[k] - an22[k] + an21[k]);
	}
	
	// Preparing result
	B_rho *= imfd.C;
	B_z *= imfd.C;
	
	// Result
	// BCylVec[0] = B_rho;
	// BCylVec[1] = 0.0;
	// BCylVec[2] = B_z;
    const [Bx, By] = [B_z, B_rho];
    return [Bx, By];
};
