import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {isAuth} from '../helpers/auth';
import Page404 from "../Components/home/404";

const PrivateRoute = ({comp: Component, compAdmin: ComponentAdmin, compAnon: ComponentAnon, ...rest}) => (
  <Route {...rest} render={props =>
    isAuth() ? (isAuth().role === 3 ? (ComponentAdmin ? <ComponentAdmin {...props} /> : <Page404 {...props} />)
        : (Component ? <Component {...props} /> : <Page404 {...props} />))
      : (ComponentAnon ? <ComponentAnon {...props} />
        : <Redirect to={{pathname: '/login', state: {from: props.location}}}/>)
  }/>
);

export default PrivateRoute;