import React, { useEffect, useRef, useState } from "react";
import "./Dashboard.scss";
import DndList from "../../Tracker/Dnd/DndList";
import HeaderMenu from "../../Tracker/HeaderMenu/HeaderMenu";
import { ReactComponent as AddGroup } from "../../../assets/icons/add-card-button.svg";
import Modal from "../../Modal";
import NewSectionModal from "../../Tracker/NewModals/NewSectionModal";
import { useDispatch, useSelector } from "react-redux";
import { deleteColumn, updateActiveGroup } from "../../../redux/slices/trello";
import { Icon } from "../../ui";
import Menu from "../../Tracker/Menu/Menu";
import RenameSectionModal from "../../Tracker/NewModals/RenameSectionModal";
import PopupMenu from "../../Tracker/Menu/PopupMenu";
import { storageClass } from "../../../redux/slices/storage";
import CardUsersList from "../../Tracker/CardUsersList/CardUsersList";
import UsersListModal from "../../Tracker/NewModals/UsersListModal";

const DashboardTracker = (props) => {
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isModalSection, setIsModalSection] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isOpenRenameModal, setIsOpenRenameModal] = useState(false);
  const [isOpenStudentsModal, setIsOpenStudentsModal] = useState(false);

  const documentClass = useSelector(storageClass);

  const activeSection = useSelector((state) => state.trelloSlice.activeGroup);
  const columns = useSelector((state) => state.trelloSlice.columns);
  const groups = columns.filter((column) => !column.parent);

  const scrolledContainer = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!activeSection?._id) {
      dispatch(updateActiveGroup(groups[0]));
    }
  }, [activeSection, dispatch, groups]);

  const handleMouseDown = (e) => {
    if (e.target.className === "dnd__container") {
      setIsMouseDown(true);
      setStartX(e.pageX - e.currentTarget.offsetLeft);
      setScrollLeft(e.currentTarget.scrollLeft);
    }
  };

  const handleMouseLeave = () => {
    setIsMouseDown(false);
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  const handleMouseMove = (e) => {
    if (!isMouseDown) return;
    e.preventDefault();
    if (e.target.className === "dnd__container") {
      const x = e.pageX - e.currentTarget.offsetLeft;
      const walk = (x - startX) * 1;
      e.currentTarget.scrollLeft = scrollLeft - walk;
    }
  };

  const getListMenu = () => ({
    rename: {
      title: "Переименовать",
      // icon: <Rename />,
    },

    delete: {
      title: "Удалить",
      // icon: <Delete />,
    },
  });

  const LIST_MENU = getListMenu();

  const deleteList = (columnId) => {
    dispatch(deleteColumn(columnId));
    dispatch(updateActiveGroup(groups[0]));
  };

  LIST_MENU.delete.function = () => {
    deleteList(activeSection?._id);
  };

  LIST_MENU.rename.function = () => {
    setIsOpenRenameModal(true);
    setIsMenuOpen(false);
  };

  const menuRef = useRef(null);
  const btnMenuRef = useRef(null);

  const handleClickOutsideMenu = (event) => {
    if (
      menuRef.current &&
      !btnMenuRef.current.contains(event.target) &&
      !menuRef.current.contains(event.target)
    ) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideMenu);
    return () => {
      document.removeEventListener('click', handleClickOutsideMenu);
    };
  }, [menuRef, btnMenuRef]);

  return (
    <div className="cor_dashboard">
      <div className="cor_dashboard__content">
        <div className="cor_dashboard__class_header">
          <div className="cor_dashboard__class_header__info">
            <div className="cor_dashboard__class_header__section__class">
              <div className="cor_dashboard__class_header__title">Класс: </div>
              <div className="cor_dashboard__class_header__class_button">
                {documentClass?.className}
              </div>
            </div>
            <div className="cor_dashboard__class_header__section__students" onClick={() => setIsOpenStudentsModal(true)}>
              <CardUsersList
                // btnAddUsersRef={btnAddUsersRef}
                card={activeSection}
                // handleClick={handleOpenAddUsers}
                cardUsers={documentClass?.classUsers}
                // listCurrentColor={listCurrentColor}
                // LIST_COLORS={LIST_COLORS}
                slicedUsers={documentClass?.classUsers?.slice(0, 4)}
                cardUsersLength={5}
              />

              <Icon
                name="gear"
                onClick={() => console.log()}
                style={{ cursor: "pointer" }}
              />

            </div>
          </div>

          <div className="cor_dashboard__class_header__sections">
            <div className="cor_dashboard__class_header__sections_items_container">
              <div className="cor_dashboard__class_header__sections_items">
                {groups.map((group) => (
                  <div
                    className={`${
                      activeSection?._id === group?._id
                        ? "cor_dashboard__class_header__section active"
                        : "cor_dashboard__class_header__section"
                    }`}
                    key={group?._id}
                    onClick={() => dispatch(updateActiveGroup(group))}
                  >
                    {group?.name}
                  </div>
                ))}
              </div>
              <AddGroup
                fill="#7C8195"
                style={{ cursor: "pointer" }}
                onClick={() => setIsModalSection(true)}
              />
            </div>
            <div className="cor_dashboard__class_header__section_menu">
              <Icon
                name="gear"
                btnRef={btnMenuRef}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                style={{ cursor: "pointer" }}
              />
              <PopupMenu
                items={{ ...LIST_MENU }}
                menuRef={menuRef}
                isOpenMenu={isMenuOpen}
                setIsOpenMenu={setIsMenuOpen}
                id={activeSection?._id}
                // activeColor={item?.color}
              />
            </div>
          </div>
        </div>
        <div className="cor_dashboardBox">
          <HeaderMenu />
          <div className="cor_dashboardBox__body">
            <div
              ref={scrolledContainer}
              className="cor_dashboardBox__scrolled"
              onMouseDown={handleMouseDown}
              onMouseLeave={handleMouseLeave}
              onMouseUp={handleMouseUp}
              onMouseMove={handleMouseMove}
            >
              <DndList scrolledContainer={scrolledContainer} {...props} />
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={isModalSection}
        content={
          <NewSectionModal onCloseModal={() => setIsModalSection(false)} />
        }
      />
      <Modal
        visible={isOpenRenameModal}
        content={
          <RenameSectionModal
            onCloseModal={() => setIsOpenRenameModal(false)}
            activeSection={activeSection}
          />
        }
      />
      <Modal
        visible={isOpenStudentsModal}
        content={
          <UsersListModal
            onCloseModal={() => setIsOpenStudentsModal(false)}
            activeSection={activeSection}
          />
        }
      />
    </div>
  );
};

export default DashboardTracker;
