import React, { useState, useEffect } from "react";
import Table from "../ui/Table/Table";
import {Icon} from '../ui';
import {getClassStudents} from "../ui/utils/gen_utils";
import {onlineTestInfoApi} from "../../api/api";
import {printOnlyDate} from "../../helpers/text";
import {FEATURES} from "../../api/config";
import { QUESTION_TYPES, getOnlineTestById, updateOnlineTestInfoScoreById, 
	getNonCheckAnswerList } from "../OnlineTest/ot_utils";
import {getHeader, getExceptionCellStyle} from "./rt_utils";
import ViewStudentOnlineTestResultDlg from "./ViewStudentOnlineTestResultDlg";
import {PRACTICUM_SECTION}  from "../template/Dashboard/DashboardProfile";
import {onlineTestRunApi} from "../../api/api";
import {ContentHead, Content, ContentBody, Back} from "../template/ContentParts";
import {useSelector, useDispatch} from "react-redux";
import {isAuth} from "../../helpers/auth";
import {storageClass, storageOrg} from "../../redux/slices/storage";
import {fetchclass} from "../../redux/slices/classsline";
import "./ReviewActiveLabWork.scss";

const ViewSelectedOnlineTestRun = ({ history, selectedTestRunId }) => {
	const [onlineTestId, setOnlineTestId] = useState(undefined);
	const [onlineTestData, setOnlineTestData] = useState(undefined);
	const [studentResults, setStudentResults] = useState([]);
	const [studentData, setStudentData] = useState([]);
	const [isUpdated, setIsUpdated] = useState(false);
	const [testRunDetails, setTestRunDetails] = useState(null);
	const [showDetailstDlg, setShowDetailstDlg] = useState(false);
	const [selectedSudentId, setSelectedSudentId] = useState(undefined);
	const [hasNonCheckedAnswers, setHasNonCheckedAnswers] = useState(false);
	
    const documentOrg = useSelector(storageOrg);
	const documentClass = useSelector(storageClass);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchclass(isAuth()._id));
    }, [dispatch, documentOrg._id]);

	useEffect(() => {
		const fetchData = async () => {
			let result = await onlineTestRunApi.getOnlineTestRunById(selectedTestRunId);
			setOnlineTestId(result.onlineTest);
			setTestRunDetails(result);

			result = await onlineTestInfoApi.getAllClassUserOnlineTestInfosByRunId(selectedTestRunId);
			if (result.data) setStudentResults(result.data);
			if (isUpdated) setIsUpdated(false);
		};

		fetchData();
	},[selectedTestRunId, isUpdated]);

	useEffect(() => {
		if (!onlineTestId) return;

		const fetchData = async (_onlineTestId) => {
			const result = await getOnlineTestById(_onlineTestId);
			setOnlineTestData(result);
		};

		fetchData(onlineTestId);
    }, [onlineTestId]);

	useEffect(() => {
		if (!onlineTestData || studentResults.length === 0) return;
		const hasBeCheckedAnswers = onlineTestData.questions.filter(item => Number(item.questionType) === QUESTION_TYPES.FREE_WRITING);
		if (hasBeCheckedAnswers.length === 0) return;

		let isFoundNonCheckedAnswers = false;
		for (let i = 0; i < studentResults.length && !isFoundNonCheckedAnswers; i ++) {
			const testInfo = studentResults[i];
        	isFoundNonCheckedAnswers = getNonCheckAnswerList(onlineTestData, testInfo).length > 0;
		}

		if (isFoundNonCheckedAnswers)
			setHasNonCheckedAnswers(true);
    }, [onlineTestData, studentResults]);


	useEffect(() => {
		if (studentResults.length === 0 || !onlineTestData) return;
		const classStudents = getClassStudents(documentClass.classUsers); //contain full name and id of each student

		const list = [];
		for (let i = 0; i < classStudents.length; i ++) {
			const student = classStudents[i];
			const studentInfo = studentResults.find(item => item.owner === student.owner);

			const answerNumber = studentInfo ? studentInfo.answers.length : 0;
			const correctAnswerNumber = studentInfo ? studentInfo.answers.filter(item => item.isCorrect === 1).length : 0;
			const questionNumber = onlineTestData.questions.length;
			const isComplete = studentInfo ? studentInfo.isComplete : false;
			const nonCheckedAnswersStatus = studentInfo && studentInfo.answers.length ? 
				(getNonCheckAnswerList(onlineTestData, studentInfo).length > 0 ? 1 : 0) : -1;
			const status = nonCheckedAnswersStatus === 1 ? 'Необходима проверка' : 
						   nonCheckedAnswersStatus === 0 ? 'Проверено' :
						   correctAnswerNumber === 0 ? 'Тест не пройден' : '';
	
			const item = {
				name: student.name, 
				progress: isComplete ? 'Готово' : answerNumber + ' из ' + questionNumber,
				result: correctAnswerNumber + ' из ' + questionNumber,
				status: status,
				score: studentInfo?.score ? studentInfo.score.toString() : '',
				actions: '',
				id: student.owner + '|' + (studentInfo ? studentInfo._id : ''),
				personPict: student.personPict
			};
			list.push(item);
		}

		setStudentData(list);
	},[documentClass.classUsers, studentResults, onlineTestData]);

	const specialCellStyle = (item, rowInd, colInd, row) => {
		return getExceptionCellStyle(item);
    };

	const getPersonId = (idArr) => idArr.split('|')[0];
	const getOnlineTestInfoId = (idArr) => idArr.split('|')[1];

	const handlePerson = idArr => {
		history.push(FEATURES['profile'].to + '/' + getPersonId(idArr) + '/' + PRACTICUM_SECTION.ONLINE_TESTS);
	};

	const handleRunningTestDetails = idArr => {  //click to open the test result of the student
		const userId = getPersonId(idArr);
		setSelectedSudentId(userId);
		setShowDetailstDlg(true);
	};

	const handleScoreUpdate = async (score, idArr) => {
		const onlineTestInfoId = getOnlineTestInfoId(idArr);
		const scoreVal = score !== '' ? Number(score) : null;
		const result = await updateOnlineTestInfoScoreById(onlineTestInfoId, scoreVal);

		if (result) {
			setIsUpdated(true);
		}
	};

	const getTableHeader = () => {
		if (hasNonCheckedAnswers) {
			return [
				{column: 'Имя ученика', name: 'name', style: { width: '15%'}},
				{column: 'Ответы', name: 'progress', style: { width: '15%'}},
				{column: 'Правильные ответы', name: 'result', style: { width: '15%'}},
				{column: 'Статус', name: 'status', style: { width: '20%'}},
				{column: 'Оценка учителя', name: 'score', style: { width: '15%'}},
				{column: 'Действия', name: 'actions', style: { width: '20%'}},
				{column: 'id', name: 'id', style: { width: '0%'}},
			];
		} else {
			return [
				{column: 'Имя ученика', name: 'name', style: { width: '20%'}},
				{column: 'Ответы', name: 'progress', style: { width: '20%'}},
				{column: 'Правильные ответы', name: 'result', style: { width: '20%'}},
				{column: 'Оценка учителя', name: 'score', style: { width: '20%'}},
				{column: 'Действия', name: 'actions', style: { width: '20%'}},
				{column: 'id', name: 'id', style: { width: '0%'}},
			];
		}
	};

    return (
        <>
			<ContentHead flex={false}>
				<Back 
					onClick={()=> history.push('/reviewtest/ot')} 
					text="Перейти ко всем результатам" 
					icon="back" margin="bottom"
				/>
				<div className="cor-net__row mt_0">
					<div className="cor-net__col col-grow">
						<div className="cor_content__title">{'Результаты проведения онлайн теста ' + onlineTestData?.title}</div>
					</div>
					{testRunDetails &&
					<div className="cor-net__col">
						<div className="cor_content__title">Дата тестирования {printOnlyDate(testRunDetails.createdAt)}</div>
					</div>}
				</div>
	        </ContentHead>

			<Content>
				{getHeader('Описание: ' + onlineTestData?.description, '', false, 0, undefined, undefined)}
				<ContentBody>
					<Table 
						table={{header: getTableHeader(), 
							data: studentData, 
							handleTableDataUpdate: handleScoreUpdate, 
							specialCellStyle: specialCellStyle}}
						sort={{
							hasSorting: true, 
							initSortInd: 1
						}}
						person={{handlePerson: handlePerson}}
						actions={{
							handleShowDetails: idArr => handleRunningTestDetails(idArr),
							textShowDetails: 'Смотреть результаты',
						}}								
						canEditScore={true}
					/>
				</ContentBody>
			</Content>

			{showDetailstDlg &&
			<ViewStudentOnlineTestResultDlg 
				  showDlg={showDetailstDlg}
				  setShowDlg={setShowDetailstDlg}
				  selectedSudentId={selectedSudentId}
				  onlineTestId={onlineTestId}
				  selectedTestRunId={selectedTestRunId}
				  setIsUpdated={setIsUpdated}
			  />
			}
        </>
    );
};

export default ViewSelectedOnlineTestRun;
