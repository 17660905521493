import React, { useState, useEffect, useRef } from "react";
import {useSelector, useDispatch} from "react-redux";
import labsList from '../practicum/subjectList';
import {storageClass, storageOrg} from "../../redux/slices/storage";
import {fetchUsersByOrg, fetchclass} from "../../redux/slices/classsline";
import {isAuth} from "../../helpers/auth";
import {toast} from "react-toastify";
import ModalConfirmDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import Table from "../ui/Table/Table";
import {getClassStudents, initializeTimeCounter} from "../ui/utils/gen_utils";
import {formLabWorkId, getLabWorkArraysAndNames} from "../practicum/lw_utils";
import {PRACTICUM_SECTION}  from "../template/Dashboard/DashboardProfile";
import {labWorkInfoApi} from "../../api/api";
import {FEATURES} from "../../api/config";
import {optionsUsers} from "../../redux/slices/options";
import {getTopHeader, getHeader, getExceptionCellStyle, stopActiveTest} from "./rt_utils";
import {getScoreValue} from '../ui/utils/score_utils';
import {PRACTICUM_TEST_TYPE, getPracticumTestInfo, hasPracticumTestInfo} from "../ui/utils/ServerInfo";
import { Content, ContentBody, ContentHead } from "../template/ContentParts";
import "./ReviewActiveLabWork.scss";
import "../practicum/LabWorkShow.scss";

const ReviewActiveLabWork = (props) => {
    const [subjectId, setSubjectId] = useState('');
    const [sectionId, setSectionId] = useState('');
    const [labId, setLabId] = useState('');
    const [startTime, setStartTime] = useState(undefined);
    const [currTestTime, setCurrTestTime] = useState();
    const [labWork, setLabWork] = useState(undefined);
	const [lwRunId, setLwRunId] = useState(undefined);
	const [testTableData, setTestTableData] = useState([]);
    const [showStopTestDlg, setShowStopTestDlg] = useState(false);
	const [confirmAnswer, setConfirmAnswer] = useState(false);
	
	const timerIdRef = useRef(undefined);
	const labWorkId = useRef('');
	const subjectName = useRef('');
	const sectionName = useRef('');
	const labWorkIndex = useRef(undefined);
	const documentOrg = useSelector(storageOrg);
	const documentClass = useSelector(storageClass);
	const onlineUsers = useSelector(optionsUsers);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchUsersByOrg(documentOrg._id));
        dispatch(fetchclass(isAuth()._id));
    }, [dispatch, documentOrg._id]);

  	useEffect(() => {
		if (!hasPracticumTestInfo()) return;

		const lwi = getPracticumTestInfo();
		const ids = lwi.labWorkId.split('|');
		setSubjectId(ids[0]);
		setSectionId(ids[1]);
		setLabId(ids[2]);
		setLwRunId(lwi.testRunId);
		setStartTime(lwi.startTime);
	}, []);

	useEffect(() => {
		timerIdRef.current = initializeTimeCounter(1, setCurrTestTime);//refresh in 1 sec
		return () => {clearInterval(timerIdRef.current);};
    }, []);

	useEffect(() => {
		if (!subjectId || !sectionId || !labId) return;
		labWorkId.current = formLabWorkId(subjectId, sectionId, labId);

		const laboratoryList = labsList.subjectList;
		const [subj, sect, lab] = getLabWorkArraysAndNames(laboratoryList, subjectId, sectionId, labId);
		subjectName.current = subj.subjectName;
		sectionName.current = sect.sectionName;
		setLabWork(lab);
		labWorkIndex.current = sect.labList.indexOf(lab);
	}, [subjectId, sectionId, labId]);

    useEffect(() => {
		if (!labWork) return;

        const fetchData = async () => {
			const students = getClassStudents(documentClass.classUsers);

			await labWorkInfoApi.getClassUserLabWorkInfosByRunId(lwRunId)
				.then((result) => {
					const data = result.data;

					const onlineStudents = onlineUsers.filter(item => item.role === 0);
					const list = [];
		
					//include student data from server about the test:
					for (let i = 0; i < students.length; i ++) {
						const student = students[i];
						const info = data?.find(item => item.owner === student.owner);
						const pageInd = info ? info.pageInd : 0;
						let progress = info && info.isComplete ? 'Готово' : ((!info ? '0' : '' + (pageInd+1)) + ' из ' + labWork.labPages.length);
						const score = info && info.score !== null ? getScoreValue(info.score) : '';
		
						const item = {
							name: student.name,
							progress: progress,
							score: score,
							online: onlineStudents.find(item => item._id === student.owner) ? 'Онлайн' : 'Офлайн',
							id: student.owner,
							personPict: student.personPict
						};
		
						list.push(item);
					}
		
					setTestTableData(list);
		
            	}).catch((err) => {
                	toast.error(err.response.data.errors);
            	});
        }

		fetchData();
    },[documentClass.classUsers, labWork, lwRunId, onlineUsers, currTestTime]);

	useEffect(() => {
		if (!confirmAnswer) return;
		stopActiveTest(documentClass._id);
		setShowStopTestDlg(false);
		props.history.push('/reviewtest/lw/' + lwRunId);
	},[confirmAnswer, dispatch, documentClass._id, lwRunId, props.history]);

	const handleStopWork = () => {
		setShowStopTestDlg(true);
	};

    const handleYes = () => {
		setConfirmAnswer(true);
		setShowStopTestDlg(false);
	};
	
    const specialCellStyle = (item, rowInd, colInd, row) => {
		return getExceptionCellStyle(item);
    };

	const handlePerson = (userId) => {
		props.history.push(FEATURES['profile'].to + '/' + userId + '/' + PRACTICUM_SECTION.LAB_WORKS);
	}; 
	
	const getTableHeader = () => {
		const header = [
			{column: 'Имя ученика', name: 'name', style: { width: '25%'}},
			{column: 'В сети', name: 'online', style: { width: '25%'}},
			{column: 'Прогресс выполнения', name: 'progress', style: { width: '25%'}},
			{column: 'Оценка', name: 'score', style: { width: '25%'}},
			{column: 'id', name: 'id', style: { width: '0%'}},
		];
		return header;
	};

	if (!labWork) {
		return (
			<>
				<ContentHead title="Тест для класса не установлен." /> 
				<Content background={false} />
			</>
		);
	}

    return (
		<>
			{getTopHeader(subjectName.current, handleStopWork, PRACTICUM_TEST_TYPE.LAB_WORK)}
			<Content>
				{getHeader(labWork.labName, sectionName.current, false, 0, currTestTime, startTime)}
				<ContentBody>
					<Table 
						table={{
							header: getTableHeader(), 
							data: testTableData, 
							specialCellStyle: specialCellStyle
						}}
						sort={{
							hasSorting: true, 
							initSortInd: 1
						}}
						person={{handlePerson: handlePerson}}
						canEditScore={false}
					/>	
				</ContentBody>
			</Content>

			{showStopTestDlg &&
			<ModalConfirmDialog
				showConfirmDlg={showStopTestDlg} 
				handleNo={() => setShowStopTestDlg(false)}
				question={'Вы действительно хотите завершить лабораторную работу?'}
				handleYes={handleYes}
			/>
			}
		</>					
    );
};

export default ReviewActiveLabWork;
