import React, {useEffect, useState} from "react";
import {ClassGrid} from "../../Grids";
import {FilterByText, Icon} from "../../ui";
import {fetchclass, fetchUsersByOrg} from "../../../redux/slices/classsline";
import {useDispatch, useSelector} from "react-redux";
import {storageOrg} from "../../../redux/slices/storage";
import { Content, ContentBody, ContentHead, ContentHeader, ContentSubHeader, ContentWrap } from "../ContentParts";

export const DashboardClass = (props) => {
    const dispatch = useDispatch();
    const documentOrg = useSelector(storageOrg);
    const [search, setSearch] = useState('');

    useEffect(() => {
        dispatch(fetchclass(documentOrg._id));
        dispatch(fetchUsersByOrg(documentOrg._id))
    }, [dispatch, documentOrg._id]);

    return (
 
        <ContentWrap>
            <Content>
                <ContentHeader>
                    <div className="cor-net__row">
                        <div className="cor-net__col col-8">
                            <div className="cor_content__title">Классы</div>
                        </div>
                        <div className="cor-net__col col-6">
                            <FilterByText 
                                placeholder="Введите название класса"
                                value={search}
                                setSearch={setSearch}
                            />
                        </div>
                    </div>
                </ContentHeader>
                <ContentBody>
                    <ClassGrid search={search} {...props}/>
                </ContentBody>
            </Content>
        </ContentWrap>
       
    );
}
export default DashboardClass