export const setFirmwareModal = (bool) => ({ type: 'SET_FIRMWARE_MODAL', payload: bool });
export const setFirmwareList = (arr) => ({ type: 'SET_FIRMWARE_LIST', payload: arr });
export const setFirmwareConnection = (bool) => ({ type: 'SET_FIRMWARE_CONNECTION', payload: bool });
export const setFirmwareTerminal = (str) => ({ type: 'SET_FIRMWARE_TERMINAL', payload: str });
export const setFirmwareError = (str) => ({ type: 'SET_FIRMWARE_ERROR', payload: str });
export const setFirmwareSuccess = (str) => ({ type: 'SET_FIRMWARE_SUCCESS', payload: str });
export const setFirmwareProgress = (num) => ({ type: 'SET_FIRMWARE_PROGRESS', payload: num });
export const setFirmwareSize = (num) => ({ type: 'SET_FIRMWARE_SIZE', payload: num });
export const setFirmwareNeedUpdate = (bool) => ({ type: 'SET_FIRMWARE_NEEDUPDATE', payload: bool });
export const setFirmwareNewVersion = (str) => ({ type: 'SET_FIRMWARE_NEWVERSION', payload: str });
