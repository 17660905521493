import { setProgrammMode, startProgramm } from '../../commands/firmware';
import { setFirmwareList, setFirmwareModal, setFirmwareNewVersion, setFirmwareTerminal, setFirmwareError, setFirmwareNeedUpdate } from '../../actions/firmware';
import { sendCommand } from '../../actions/port';

export const mapStateToProps = (state) => {
    return {
        port: state.port.open,
        modal: state.firmware.modal,
        terminal: state.firmware.terminal,
        progress: state.firmware.progress,
        error: state.firmware.error,
        success: state.firmware.success,
        size: state.firmware.size,
        connection: state.firmware.connection,
        devices: state.devices.list,
        deviceResponse: state.actions.response,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setFirmwareList: (arr) => dispatch(setFirmwareList(arr)),
    setFirmwareModal: (bool) => dispatch(setFirmwareModal(bool)),
    setFirmwareNeedUpdate: (bool) => dispatch(setFirmwareNeedUpdate(bool)),
    setFirmwareTerminal: (str) => dispatch(setFirmwareTerminal(str)),
    setFirmwareError: (str) => dispatch(setFirmwareError(str)),
    setFirmwareNewVersion: (str) => dispatch(setFirmwareNewVersion(str)),
    sendCommand: (str) => dispatch(sendCommand(str)),
    setProgrammMode: (fn) => dispatch(setProgrammMode(fn)),
    startProgramm: (files) => dispatch(startProgramm(files)),
});
