import { setSensorsModal } from '../../actions/sensors';
import { sendCommand } from '../../actions/port';
import { setDeviceResponse, setForceUpdate } from '../../actions/actions';
import { setSensorReading } from '../../actions/reader';
import { toggleWritter } from '../../actions/writer';
import { setDemoSettings } from '../../actions/demo';
// import { btWriteCommand } from '../../commands/bluetooth';

export const mapStateToProps = (state) => {
    return {
        port: state.port.open,
        connectionType: state.port.connectionType,
        command: state.port.command,
        // writedCommand: state.port.command,
        // played: state.actions.played,
        deviceResponse: state.actions.response,
        modal: state.sensors.modal,
        devicesList: state.devices.list,
        devices: state.devices.all,
        sensorsList: state.sensors.list,
        enabledList: state.sensors.enabled,
        isReading: state.reader.sensorReading,
        writerSelected: state.writer.writerSelected,
        demo: state.demo.demo,
        demoSettings: state.demo.settings,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setSensorsModal: (bool) => dispatch(setSensorsModal(bool)),
    setSensorReading: (bool) => dispatch(setSensorReading(bool)),
    sendCommand: (str) => dispatch(sendCommand(str)),
    setDeviceResponse: (str) => dispatch(setDeviceResponse(str)),
    toggleWritter: (nomber) => dispatch(toggleWritter(nomber)),
    setForceUpdate: (bool) => dispatch(setForceUpdate(bool)),
});
