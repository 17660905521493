import React, {useState, useEffect} from 'react';
import {printOnlyDate} from "../../helpers/text";
import {getFiles} from '../ui/utils/gen_utils';
import {QUESTIONS, QUESTION_TYPES, getNonCheckAnswerList, loadOTFiles, 
    getOnlineTestById, getOnlineTestInfoByRunId, updateOnlineTestInfoById} from "../OnlineTest/ot_utils";
import {userApi} from "../../api/api";
import { getDropdown } from "../ui/utils/gen_utils";
import { isAuth } from "../../helpers/auth";
import {useSelector, useDispatch} from "react-redux";
import {fullName} from "../../helpers/auth";
import htmlParser from "html-react-parser";
import {storageClass} from "../../redux/slices/storage";
import {SCORE_MANUAL_CHECK, getScoreCbList, getScoreValue, getScoreName} from '../ui/utils/score_utils';
import { Icon } from '../ui';
import { Button, Modal } from 'rlabui';
import resStyles from '../ReviewTest/Results.module.scss';


const ViewStudentOnlineTestResultDlg = ({ showDlg, setShowDlg, selectedSudentId, onlineTestId, selectedTestRunId, setIsUpdated }) => {
    const [profile, setProfile] = useState(undefined);
    const [onlineTest, setOnlineTest] = useState(undefined);
    const [otInfo, setOtInfo] = useState(undefined);
    const [updatedOtInfo, setUpdatedOtInfo] = useState(undefined);
    const [recalcOtInfo, setRecalcOtInfo] = useState(false);
    const [answers, setAnswers] = useState([]);
    const [nonCheckAnswerList, setNonCheckAnswerList] = useState([]);

    const documentClass = useSelector(storageClass);
    const {lists: {files}} = useSelector(state => state);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!selectedSudentId) return;
        userApi.get(selectedSudentId).then((data) => setProfile(data));
    }, [selectedSudentId]);

    useEffect(() => {
        loadOTFiles(dispatch);
    }, [dispatch]);

    useEffect(() => {
        const getOnlineTest = async () => {
            const result = await getOnlineTestById(onlineTestId);
            setOnlineTest(result);
        }
        
        if (!onlineTestId) return;
        getOnlineTest();
    }, [onlineTestId]);

	useEffect(() => {
		const getOtStudentInfo = async (owner, runId) => {
			const result = await getOnlineTestInfoByRunId(owner, runId);
            if (result && result.length > 0) {
                setOtInfo(result[0]);
            }
		};
        
        if (selectedSudentId && selectedTestRunId)
            getOtStudentInfo(selectedSudentId, selectedTestRunId);
        setRecalcOtInfo(false);
	}, [documentClass._id, selectedSudentId, selectedTestRunId, recalcOtInfo, showDlg]);

	useEffect(() => { //check if there are unchecked answers.
        if (!onlineTest || !otInfo) return;
        const nonCheckedAnswers = getNonCheckAnswerList(onlineTest, otInfo);

        setNonCheckAnswerList(nonCheckedAnswers);
	}, [onlineTest, otInfo]);

    useEffect(() => { //teacher checked free writing => save updated data
        const saveData = async () => {
            await updateOnlineTestInfoById(otInfo._id, updatedOtInfo);
            setRecalcOtInfo(true);
        };

        if (!updatedOtInfo) return;
        saveData();
        setUpdatedOtInfo(undefined);
	}, [otInfo, updatedOtInfo]);
        
    useEffect(() => {
        const getQuestionTypeById = id => QUESTIONS.find(item => item.id === Number(id)).name;
        if(!profile || !onlineTest || !otInfo) return;

        const getAnswer = (question, info) => {
            const qType = Number(question.questionType);
            if (qType === QUESTION_TYPES.CORRECT_ANSWER_ONE || 
                qType === QUESTION_TYPES.VIDEO) {
                    return question.answerOptions[Number(info.answer)];
            }

            if (qType === QUESTION_TYPES.CORRECT_WORD_SEQUENCE) {
                const words = question.answerOptions;
                const seq = info.answer.split('|');
                return seq.map(item => words[Number(item)]);
            }

            if (qType === QUESTION_TYPES.COMPLETE_SENTENCE) {
                const words = question.answerOptions;
                return words[Number(info.answer)];
            }
            
            if (qType === QUESTION_TYPES.CORRECT_ANSWER_MANY) {
                const words = question.answerOptions;
                const correctOpts  = question.correctOptions.split('|');
                const seq = info.answer.split('|');
                const list = [];
                for (let i = 0; i < seq.length; i ++) {
                    const res = correctOpts.find(item => item === seq[i]);
                    list.push({'word': words[Number(seq[i])], 'isCorrect': !!res});
                }
                return list;
            }

            if (qType === QUESTION_TYPES.AUDIO) {
                const audios = question.answerOptions;
                const answerInd = info.answer;
                const urlList = getFiles(files, [audios[Number(answerInd)]].map(item => ({name: item}))).map(item => item.Url);
                return urlList[0];
            }

            if (qType === QUESTION_TYPES.NON_VERBAL) {
                const images = question.answerOptions;
                const answerInd = info.answer;
                const urlList = getFiles(files, [images[Number(answerInd)]].map(item => ({name: item}))).map(item => item.Url);
                return urlList[0];
            }

            if (qType === QUESTION_TYPES.CORRECT_IMAGE_SEQUENCE) {
                const images = question.answerOptions;
                const seq = info.answer.split('|');
                const orderedImages = seq.map(item => images[Number(item)]);
                const urlList = getFiles(files, orderedImages.map(item => ({name: item}))).map(item => item.Url);
                return urlList;
            }

            if (qType === QUESTION_TYPES.FREE_WRITING) {
                return info.answer;
            }
            return '';
        };

        const getAudioTrackInd = (question, info) => {
            if (Number(question.questionType) === QUESTION_TYPES.AUDIO) {
                return Number(info.answer);
            }
            return '';
        };

        const _answers = [];
        const answersInfo = otInfo.answers;

        for (let i = 0; i < onlineTest.questions.length; i ++) {
            const question = onlineTest.questions[i];
            const answer = {
                questionNumber: i + 1,
                questionText: question.questionName,
                questionType: question.questionType,
                questionTypeName: getQuestionTypeById(question.questionType),
                answerType: i < answersInfo.length ? answersInfo[i].isCorrect  : undefined, //it can be +1, 0 (incorrect), -1 (not estimated by teacher), undefined (no answer)
                answerTypeName: i < answersInfo.length ? (answersInfo[i].isCorrect ? 'верно' : 'неверно')  : 'нет ответа',
                answerColorType: i < answersInfo.length ? (answersInfo[i].isCorrect ? 1 : 0)  : -1,
                textAnswer: i < answersInfo.length ? getAnswer(question, answersInfo[i]) : '',
                hasAnswer: i < answersInfo.length,
                audioTrackInd:  i < answersInfo.length ? getAudioTrackInd(question, answersInfo[i]) : '',
            }
            _answers.push(answer);
        }

        setAnswers(_answers);
	}, [profile, onlineTest, otInfo, files]);

    const handleClose = () => {
        setIsUpdated(true);
        setShowDlg(false);
    };

    const handleRB = (ind, isCorrect) => {
        const answersInfo = [...otInfo.answers];
        answersInfo[ind].isCorrect = isCorrect;
        let score = otInfo.score;

        if (answersInfo.filter(item => item.isCorrect === -1).length === 0) {
            const num = answersInfo.length;
            const correctNum = answersInfo.filter(item => item.isCorrect === 1).length;
            score = correctNum > 0 ? (correctNum / num) * 100 : 1;
        }
        
        const otInfoData = {
            answers: answersInfo,
            isComplete: otInfo.isComplete,
            score: score
        };

        setUpdatedOtInfo(otInfoData);
    };

    const handleScore = value => {
        const otInfoData = {
            answers: otInfo.answers,
            isComplete: otInfo.isComplete,
            score: value !== '' ? Number(value) : null,
        };

        setUpdatedOtInfo(otInfoData);
    };

    const getScoreCell = () => {
        if (!otInfo) return <></>;

        const value = !!otInfo.score && otInfo.score !== SCORE_MANUAL_CHECK ? otInfo.score : '';
            
		return getDropdown(getScoreCbList(), getScoreValue(value), val => handleScore(val), 
				'', '', 'score01', "top");
	};

    const hasNoAnswer = (item, ind, key) => <div className={resStyles.answer__text} key={key+ind}><span className='error'>Нет ответа</span></div>;

    const getTopPart = () => {
        return (
            <div className={resStyles.info}>
                <div className={resStyles.row}>
                    <span className={resStyles.col}>Имя ученика</span>
                    <span className={resStyles.col}>{fullName(profile)}</span>    
                </div>
                <div className={resStyles.row}>
                    <span className={resStyles.col}>Название теста</span>
                    <span className={resStyles.col}>{onlineTest?.title}</span>    
                </div>
                <div className={resStyles.row}>
                    <span className={resStyles.col}>Дата проведения теста</span>
                    <span className={resStyles.col}>{printOnlyDate(otInfo?.createdAt)}</span>    
                </div>
                <div className={resStyles.row}>
                    <span className={resStyles.col}>Количество ответов</span>
                    <span className={resStyles.col}>{otInfo?.answers?.length } из { onlineTest?.questions.length}</span>    
                </div>
                <div className={resStyles.row}>
                    <span className={resStyles.col}>Количество правильных ответов</span>
                    <span className={resStyles.col}>{otInfo?.answers?.filter(item => item.isCorrect === 1).length}  из { onlineTest?.questions.length}</span>
                </div>
                <div className={resStyles.row}>
                    <span className={resStyles.col}>Количество ответов ожидает проверки</span>
                    <span className={resStyles.col}>{otInfo?.answers?.filter(item => item.isCorrect === -1).length}  из { onlineTest?.questions.length}</span>
                </div>
                <div className={resStyles.row}>
                    <span className={resStyles.col}>Оценка</span>
                    <span className={resStyles.col}>{otInfo?.score ? getScoreName(otInfo.score) : 'не выставлена'}</span>    
                </div>    
            </div>
        );
    };

    const getAnswers2 = () => {
        return (
            <div>
                {answers.map((item, ind) => 
                    <div key={'an'+ind}>{getOneAnswer(item, ind)}
                </div>)}
            </div>
        );
    }; 
    const getOneAnswer = (item, ind) => {
        return (
            <div className={resStyles.answer__item + ' ' +
                (Number(item.questionType) === QUESTION_TYPES.FREE_WRITING && 
                    item.answerType === -1 && resStyles.answer__item_warning)} 
                key={'item01'+ind}
            >
                
                {Number(item.questionType) === QUESTION_TYPES.FREE_WRITING && 
                    item.answerType === -1 &&
                    <div className={resStyles.status}>
                        <Icon name="warning"/>
                        Необходима проверка!
                    </div>
                }

                <div className={resStyles.answer__number} key={'num01'+ind}>
                    Вопрос № <span></span>
                </div>

                <div className={resStyles.answer__content} key={'cont01'+ind}>
                    <div className={resStyles.answer__row} key={'row01'+ind}>
                        <div className={resStyles.answer__label} key={'label01'+ind}>
                            Тип вопроса
                        </div>
                        <div className={resStyles.answer__text} key={'qtype01'+ind}>
                            <span>{item.questionTypeName}</span>
                        </div>
                    </div>
                    <div className={resStyles.answer__row} key={'row02'+ind}>
                        <div className={resStyles.answer__label} key={'qtext01'+ind}>
                            Вопрос
                        </div>
                        <div className={resStyles.answer__text} key={'qanswer01'+ind}>
                            <span>{htmlParser(item.questionText)}</span> 
                        </div>
                    </div>

                    <div className={resStyles.answer__row} key={'row03'+ind}>
                        <div className={resStyles.answer__label} key={'row03a1'+ind}>
                            Ответ
                        </div>

                        {!item.hasAnswer ? 
                            hasNoAnswer(item, ind, 'empty01')
                        :
                        <>
                            {(Number(item.questionType) === QUESTION_TYPES.COMPLETE_SENTENCE) &&
                            <div className={resStyles.answer__text} key={'row03a2'+ind}>
                                {<span>{item.textAnswer}</span>}
                            </div>
                            }

                            {(Number(item.questionType) === QUESTION_TYPES.CORRECT_ANSWER_ONE ||
                              Number(item.questionType) === QUESTION_TYPES.VIDEO) &&
                            <div className={resStyles.answer__text} key={'row03a3'+ind}>
                                {<span key={'row03a4'+ind}>{htmlParser(item.textAnswer)}</span>}
                            </div>
                            }

                            {(Number(item.questionType) === QUESTION_TYPES.CORRECT_WORD_SEQUENCE) &&
                            <div className={resStyles.answer__sequence} key={'seq01'+ind}>
                                {item.textAnswer.map((item, i) => <span key={'seqa'+ind + i}>{item}</span>)}
                            </div>
                            }

                            {(Number(item.questionType) === QUESTION_TYPES.CORRECT_ANSWER_MANY) &&
                            <div className={resStyles.answer__sequence} key={'many01'+ind}>
                                {item.textAnswer.map((item, i) => 
                                    <span className={item.isCorrect ? 'success' : 'error'} 
                                    key={'many02'+ind + i}>
                                        {htmlParser(item.word)}
                                    </span>
                                )}
                            </div>
                            }

                            {(Number(item.questionType) === QUESTION_TYPES.AUDIO) &&
                                <div className={resStyles.answer__audio} key={'audio01'+ind}>
                                    <audio src={item.textAnswer} controls key={"audio02"+ind} />
                                    <div className={resStyles.answer__audio_name}>Трек № {item.audioTrackInd+1}</div>
                                </div>
                            }

                            {(Number(item.questionType) === QUESTION_TYPES.NON_VERBAL) &&
                                <div className={resStyles.answer__row_grid} key={'nonverb01'+ind}>
                                    <div className={resStyles.answer__col} key={'nonverb02'+ind}>
                                        {<img src={item.textAnswer}  alt="" key={"nonverb03"+ind} />}
                                    </div>
                                </div>
                            }

                            {(Number(item.questionType) === QUESTION_TYPES.CORRECT_IMAGE_SEQUENCE) && (
                                <div className={resStyles.answer__row_grid} key={'imgseq01'+ind}>
                                    {item.textAnswer && item.textAnswer.map((img, i) => 
                                        <div className={resStyles.answer__col} key={'imgseq02'+ind+i}>
                                            {<img src={img}  alt="" key={"imgseq03"+ind+i} />}
                                        </div>
                                    )}
                                </div>
                            )}

                            {Number(item.questionType) === QUESTION_TYPES.FREE_WRITING && 
                                <div className={resStyles.answer__text}>
                                    <span>{item.textAnswer}</span>
                                </div>
                            }
                        </>
                        }
                    </div>

                    {item.hasAnswer &&
                    <div className={resStyles.answer__row} key={'row04'+ind}>
                        <div className={resStyles.answer__label} key={'res01'+ind}>
                            Результат
                        </div>
                        {Number(item.questionType) !== QUESTION_TYPES.FREE_WRITING ?
                            <div className={resStyles.answer__text} key={'res02'+ind}>
                                <span className={item.answerType === 1 ? 'success' : 'error'}>
                                    {item.answerType === 1 ? 'Верно' : 'Неверно'}
                                </span>
                            </div>
                        :
                            <div className={resStyles.answer__options}>
                                <label className={resStyles.answer__option}>
                                    <input type="radio" name={"test"+ind}
                                        checked={item.answerType === 1 ? 'checked' : ''}
                                        onChange={() => handleRB(ind, 1)}></input>
                                    <span>Верно</span>
                                </label>
                                <label className={resStyles.answer__option}>
                                    <input type="radio" name={"test"+ind}
                                        checked={item.answerType === 0 ? 'checked' : ''}
                                        onChange={() => handleRB(ind, 0)}></input>
                                    <span>Неверно</span>
                                </label>
                            </div>
                        }
                    </div>
                    }
                </div>
            </div>
        );
    }

    if (!showDlg) {
        return null;
    }

    return (
        <Modal className={resStyles.root} visible={showDlg} size={'lg'} >
            <Modal.Head className={resStyles.head} modalClose={handleClose} title="Результаты онлайн тестирования">
                {nonCheckAnswerList.length > 0 &&
                    <div className={resStyles.top}>
                        <div className={resStyles.status}>
                            <Icon name="warning"/>
                            <span>Необходима проверка!</span>
                            <div className={resStyles.checking}>
                            Вопрос №
                                <ul>
                                    {nonCheckAnswerList.map((item, ind) => <li key={'t11'+ind}>{item+1}</li>)}
                                </ul>
                            </div>
                        </div>
                    </div>   
                }
                {getTopPart()}
                <div className={resStyles.label}>Вопросы</div>
            </Modal.Head>
            <Modal.Body>
                <div className={resStyles.main} key={'t10'}>
                    <div className={resStyles.answer}>
                        <div className={resStyles.answer__list}>
                            {getAnswers2()}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="cor-net__row">
                    {isAuth().role > 0 &&
                    <div className="cor-net__col col-2">
                        {otInfo?.answers?.length > 0 ? getScoreCell() : 'Тест не пройден'}
                     </div>
                    }
                    <div className="cor-net__col col-2">
                        <Button color="primary" onClick={handleClose}>Закрыть</Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
};

export default ViewStudentOnlineTestResultDlg;
