import React, { useState, useEffect, useRef } from "react";
import {useSelector, useDispatch} from "react-redux";
import {storageClass, storageOrg} from "../../redux/slices/storage";
import {fetchUsersByOrg, fetchclass} from "../../redux/slices/classsline";
import {isAuth} from "../../helpers/auth";
import {getClassStudents, initializeTimeCounter} from "../ui/utils/gen_utils";
import {getLabWorkArraysAndNames} from "../practicum/lw_utils";
import ModalConfirmDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import {vrLabInfoApi} from "../../api/api";
import labsList from '../practicum/subjectList';
import Table from "../ui/Table/Table";
import {FEATURES} from "../../api/config";
import {PRACTICUM_SECTION}  from "../template/Dashboard/DashboardProfile";
import {optionsUsers} from "../../redux/slices/options";
import {getTopHeader, getHeader, getExceptionCellStyle, stopActiveTest} from "./rt_utils";
import {PRACTICUM_TEST_TYPE, getPracticumTestInfo, hasPracticumTestInfo} from "../ui/utils/ServerInfo";
import { Content, ContentBody, ContentHead } from "../template/ContentParts";
import "./ReviewActiveLabWork.scss";
import "../practicum/LabWorkShow.scss";

const ReviewActiveVrLabWork = (props) => {
    const [startTime, setStartTime] = useState(undefined);
    const [currTestTime, setCurrTestTime] = useState();
    const [subjectName, setSubjectName] = useState('');
    const [sectionName, setSectionName] = useState('');
    const [labWorkName, setLabWorkName] = useState(undefined);
    const [vrLabInd, setVrLabInd] = useState(-1);
	const [tableData, setTableData] = useState([]);
    const [showStopTestDlg, setShowStopTestDlg] = useState(false);
	const [confirmAnswer, setConfirmAnswer] = useState(false);
	
    const documentOrg = useSelector(storageOrg);
	const documentClass = useSelector(storageClass);
	const onlineUsers = useSelector(optionsUsers);
    const dispatch = useDispatch();
	const timerIdRef = useRef(undefined);

    useEffect(() => {
        dispatch(fetchUsersByOrg(documentOrg._id));
        dispatch(fetchclass(isAuth()._id));
    }, [dispatch, documentOrg._id]);

	useEffect(() => {
		if (!hasPracticumTestInfo()) return;
		const labWork = getPracticumTestInfo();
		const [subjId, sectId, labId] = labWork.labWorkId.split('|');
		setVrLabInd(Number(labId.substring(labId.length -2)));
		setStartTime(labWork.startTime);

		const [subj, sect, lab] = getLabWorkArraysAndNames(labsList.subjectList, subjId, sectId, labId);
		setSubjectName(subj.subjectName);
		setSectionName(sect.sectionName);
		setLabWorkName(lab.labName);
	}, []);

	useEffect(() => {
		timerIdRef.current = initializeTimeCounter(1, setCurrTestTime);
		return () => {clearInterval(timerIdRef.current);};
    }, []);

    useEffect(() => {
		const handleServerData = (students, data) => {
			const list = [];
			const onlineStudents = onlineUsers.filter(item => item.role === 0);

			//include student data from server about the test:
			for (let i = 0; i < students.length; i ++) {
				const student = students[i];
				const info = data.length > 0 ? data.find(item => item.owner === student.owner) : undefined;
				const progress = info ? (info.step ? info.step : 0) + ' из ' + 
					(info.stepAmount ? info.stepAmount : 0) : '';
				const isReady = info ? info.isComplete : false;
				const isOnline = onlineStudents.find(item => item._id === student.owner);

				const obj = {
					name: student.name,
					online: isOnline ? 'Онлайн' : 'Офлайн',
					progress: progress,
					result: isReady ? 'Готов' : (isOnline || progress) ? 'В работе' : 'Не начат',
					id: student.owner,
					personPict: student.personPict
				}

				list.push(obj);
			}

			return list;
		};

        const fetchData = async () => {
			const students = getClassStudents(documentClass.classUsers);
			//console.log('vrLabInd=', vrLabInd);
            const result = await vrLabInfoApi.getAllVrLabInfosByClassStudentsByVrLabInd(documentClass._id, vrLabInd);
			//console.log('result.data=', result.data);
			const list = handleServerData(students, result.data);
			setTableData(list);
        }

		if (vrLabInd === -1)  return;
		fetchData();
    },[documentClass.classUsers, onlineUsers, currTestTime, documentClass._id, startTime, vrLabInd]);

	useEffect(() => {
		if (confirmAnswer) {
			stopActiveTest(documentClass._id);
			setShowStopTestDlg(false);
			props.history.push('/practicum/lw');
		}
	},[confirmAnswer, documentClass._id, props.history]);

	const handleStopWork = () => {
		setShowStopTestDlg(true);
	};

    const handleYes = () => {
		setConfirmAnswer(true);
		setShowStopTestDlg(false);
	};
	
    const specialCellStyle = (item, rowInd, colInd, row) => getExceptionCellStyle(item);

	const handlePerson = (userId) => {
		props.history.push(FEATURES['profile'].to + '/' + userId + '/' + PRACTICUM_SECTION.VIRT_LAB);
	}; 

	const getTableHeader = () => {
		const header = [
			{column: 'Имя ученика', name: 'name', style: { width: '40%'}},
			{column: 'В сети', name: 'online', style: { width: '20%'}},
			{column: 'Прогресс выполнения', name: 'progress', style: { width: '20%'}},
			{column: 'Результат', name: 'result', style: { width: '20%'}},
			{column: 'id', name: 'id', style: { width: '0%'}},
		];
		return header;
	};

	if (!labWorkName) {
		return (
			<>
				<ContentHead title="Игра для класса не установлена." /> 
				<Content background={false} />
			</>
		);
	}

    return (
		<>
			{getTopHeader(subjectName, handleStopWork, PRACTICUM_TEST_TYPE.LW_OPTIC_GAME)}
			<Content>
				{getHeader(labWorkName, sectionName, false, 0, currTestTime, startTime)}
				<ContentBody>
					<Table 
						table={{
							header: getTableHeader(), 
							data: tableData, 
							specialCellStyle: specialCellStyle
						}}
						sort={{
							hasSorting: true, 
							initSortInd: 1
						}}
						person={{handlePerson: handlePerson}}
					/>	
				</ContentBody>
			</Content>

			{showStopTestDlg &&
			<ModalConfirmDialog
				showConfirmDlg={showStopTestDlg} 
				handleNo={() => setShowStopTestDlg(false)}
				question={'Вы действительно хотите завершить игру?'}
				handleYes={handleYes}
			/>
			}
		</>					
    );
};

export default ReviewActiveVrLabWork;
