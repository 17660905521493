import React, { useEffect, useState } from 'react';
import { Modal, Button, Checkbox } from 'rlabui';
import ss from './DemoModal.module.scss';

const DemoModal = ({ demo, port, modal, sensors, setDemo, setDemoModal, resetReduxState, setDemoLearn, learn }) => {
    const [list, setList] = useState([]);

    const setDemoSensor = (key) => {
        const copy = [...list];
        const index = copy.findIndex((x) => x === key);
        if (index === -1) {
            copy.push(key);
        } else {
            delete copy[index];
        }
        // resetReduxState();
        setDemo([]);
        setList([key]);
    };

    // useEffect(() => {
    //     !modal && setList([]);
    // }, [modal]);

    // useEffect(() => {
    //     console.log(list);
    // }, [list]);

    return (
        <Modal
            visible={modal}
            className={ss.root}
            size="xs"
        >
            <Modal.Head
                title="Выберите датчик для демо-режима"
                modalClose={() => setDemoModal(false)}
            />
            <Modal.Body>
                <ul className={ss.list}>
                    {sensors.map(({ name, key }) => (
                        <li
                            key={key}
                            className={ss.item}
                            // onClick={() => {
                            //     // resetReduxState();
                            //     setDemo(key);
                            //     setDemoModal(false);
                            // }}
                        >
                            <Checkbox
                                label={name}
                                disabled={port}
                                checked={list?.find((e) => e === key)}
                                onChange={() => setDemoSensor(key)}
                            />
                        </li>
                    ))}
                </ul>
            </Modal.Body>
            <Modal.Footer className={ss.footer}>
                <Button
                    onClick={() => {
                        if (demo?.length && port) {
                            setDemo([]);
                            resetReduxState();
                        } else {
                            setDemo(list);
                            setDemoModal(false);
                        }
                    }}
                >
                    {demo?.length && port ? 'Остановить' : 'Продолжить'}
                </Button>
                <Button
                    border={true}
                    onClick={() => setDemoModal(false)}
                >
                    Закрыть
                </Button>
                {!window.isAndroid && (
                    <div className={ss.learn}>
                        <Checkbox
                            label="Включить режим обучения"
                            checked={learn}
                            onChange={() => setDemoLearn(!learn)}
                        />
                    </div>
                )}
                <div className={ss.video}>
                    Вы также можете посмотреть{' '}
                    <a
                        href="https://robiclab.ru/spravochnyj-czentr/#onlinewindows"
                        target="blank"
                    >
                        специальное видео
                    </a>{' '}
                    по работе в системе Цифровой лаборатории
                </div>
            </Modal.Footer>
        </Modal>
    );
};
export default DemoModal;
