import React from "react";
import { Content, ContentHeader, ContentWrap } from "../ContentParts";
import Footer from "../Footer";
import "./Dashboard.scss";

export const DashboardLicense = (props) => {
    return (
      <ContentWrap>
          <Content>
              <ContentHeader>Не куплено</ContentHeader>
          </Content>
          <Footer  {...props}/>
      </ContentWrap>
    );
};

export default DashboardLicense

