import { setChartSettingsModal, setChartStyle } from '../../../actions/chart';

export const mapStateToProps = (nstate) => {
    return {
        port: nstate.nport.open,
        sensorList: nstate.nsensor.list,
        sensorChannels: nstate.nsensor.channels,
        sensorImported: nstate.nsensor.imported,
        visualized: nstate.nsensor.visualized,
        chartStyle: nstate.nchart.style,
        chartColors: nstate.nchart.settings.colors,
        devicesList: nstate.ndevice.list,
        // view: nstate.nchart.settings.view,
        modal: nstate.nchart.settings.modal,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setChartStyle: (obj) => dispatch(setChartStyle(obj)),
    setChartSettingsModal: (item) => dispatch(setChartSettingsModal(item)),
});
