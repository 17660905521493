import { setChartMarkerDeleteAll, setChartView, clearChartMarkerModal } from '../../actions/chart';
import { setSensorRecords, setSensorVisualized } from '../../actions/sensor';

export const listStateToProps = (nstate) => {
    return {
        sensorList: nstate.nsensor.list,
        sensorChannels: nstate.nsensor.channels,
        visualized: nstate.nsensor.visualized,
        sensorImported: nstate.nsensor.imported,

        clearModal: nstate.nchart.markers.clearModal,

        view: nstate.nchart.settings.view,
        chartDisabled: nstate.nchart.disabled,

        isMobile: nstate.napp.isMobile,
        mobileView: nstate.napp.mobileView,
    };
};

export const listDispatchToProps = (dispatch) => ({
    setChartView: (str) => dispatch(setChartView(str)),
    setChartMarkerDeleteAll: (bool) => dispatch(setChartMarkerDeleteAll(bool)),
    clearChartMarkerModal: (bool) => dispatch(clearChartMarkerModal(bool)),
    setSensorRecords: (arr) => dispatch(setSensorRecords(arr)),
    setSensorVisualized: (key) => dispatch(setSensorVisualized(key)),
});
