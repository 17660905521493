import React, {useState, useEffect} from "react";
import { Button, Icon } from '../ui';
import {isAuth} from "../../helpers/auth";
import ss from './Scenarios.module.scss';
import preview from '../../rlab/src/components/Scenario/ScenarioRunDlg.module.scss';

const ScenarioPage4Graphs = ({isTemplate, scGraph, setScGraph}) => {
    const handleAddJson = (event) => {
        const file = event.target.files[0];
  
        const reader = new FileReader();
        reader.onload = function (e) {
          const graph = {
            json: e.target.result,
            graphName: file.name.toLowerCase(),
          };
          setScGraph(graph);
        };
        reader.readAsText(file);
      }

    const handleAddSavedExperiment = () => {};

    const handleDeleteGraph = () => {
        setScGraph(undefined);
    };

    const getImportFromComputer = () => {
        return (
            <label key={'lbl01'} className={ss.btnFile}>
                <span>Импортировать JSON файл с компьютера</span>
                <input type="file" accept={".json"} onChange={(e) => handleAddJson(e)} />
            </label>
        );
    };

    const getImportFromDB = () => {
        return (
            <Button className={ss.btnFile} onClick={handleAddSavedExperiment}>
                Импортировать из сохраненных экспериментов 
            </Button>
        );
    };

    return (
        <div className={ss.root}>
            <div className="cor-net grow" key={'sind30'}>
                <div className="cor-net__section" key={'sind32'} disabled={isTemplate && isAuth().role < 3}>
                    {!scGraph?.graphName ? 
                        <div className={"cor-net__row"} key={'sind34'}>
                            <div className="cor-net__col" key={'sind36'}>
                                {getImportFromComputer()}
                            </div>
                            {/* <div className="cor-net__col" key={'sind37'}>
                                {getImportFromDB()}
                            </div> */}
                        </div>
                    :
                        <div className={"cor-net__row"} key={'sind42'}>
                            <div className={ss.downloadFile} key={'sind10'}>
                                <span>
                                    {scGraph.graphName}
                                </span>
                                <p onClick={handleDeleteGraph}>
                                    <Icon name={'close'} key={'sind46'} />
                                </p>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className={ss.preview__sticky}>
                <div className={ss.preview__title}>Предварительный просмотр</div>
                <div className={ss.preview}>
                    <div className={preview.open}>
                        <div className={preview.toggle}>
                            <b>Графики и таблицы</b>
                        </div>
                        <div className={preview.body}>
                            <div className={preview.content}>
                        
                                <div className={preview.content__scrolled}>
                                    {scGraph ?
                                        <div className={preview.doc}>
                                            {/* <Icon name="file" /> */}
                                            <span className={preview.doc__title}>{scGraph.graphName}</span>
                                        </div>
                                    :
                                        <div className={preview.btn}>
                                            <Button color="primary" disabled={true}>
                                                Импортировать график
                                            </Button>
                                        </div>
                                    }
                                </div>
                                
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ScenarioPage4Graphs; 
