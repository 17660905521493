import { NB_SUBJECT } from './nb_utils';
import { mathTemplateList} from './templatesMath';
import { physicsTemplateList} from './templatesPhysics';

/*
СПЕЦИАЛЬНРЫЕ СИМВОЛЫ:
@0, @1, ...  - параметры
@+0, ...    параметры, которые писать обязательно
$0, $1, ... - знаки
$-0, $-1, ... - знаки, которые игнорируются, если число после него положительное

^+   - поднять текст наполовину до ^. или до конца строки
^-   - опустить текст наполовину до ^. или до конца строки

Пример:
formulas: ['@0x0^+2^+ $1@1x1 $2@2 = 0'], //$1 means sign + or -
2x2 + 7x + 3 = 0  => @0x^+2^+ $1  @1x   @2 = 0 (например @0 = +2, @1 = +7, @2 = +3")
task: Найти корни квадратного уравнения.
find: x0, x1;
params: 3
complexity: 0       x = a 'simple'        a = [-10, 10]
complexity: 1       x = a / b             a = [-10, 10]       b = [-4, 4]
complexity: 2       x = 'ratio' (a / b)   a = [-20, 20]       b = [-20, 20]
*/


export const getTemplatesBySubjectId = (subjectId) => {
    if (subjectId === NB_SUBJECT.MATH)
        return mathTemplateList;

    if (subjectId === NB_SUBJECT.PHYSICS)
        return physicsTemplateList;

    return {templates: [], templateTypes: []};
};

//4slxcn237m
//c0s773c8c6
//032mvcglcw
//hcyrlvmxn5
//34ndencxbdsk2sdm32d

//http://mateshanov.ru/generator.php - Матешанов
//http://www.itmathrepetitor.ru/7-klass-lineynye-uravneniya-i-tekstovye/
//https://bootsector.livejournal.com/43436.html - distance on photo
//<span>&#8730;</span><span className='nb__sqrt' >xxx</span>