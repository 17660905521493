import React, { useState, useRef, useEffect } from 'react';
import parse from 'html-react-parser';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { Button, Checkbox, InputNumber, Modal } from 'rlabui';
import ss from './ModalPrint.module.scss';

const ModalPrint = (props) => {
    const { modal, records, markers, setPrintModal } = props;
    const [printArr, setPrintArr] = useState([]);
    const [currentSensor, setCurrentSensor] = useState('');
    const [printTable, setPrintTable] = useState(true);
    const [printMarkers, setPrintMarkers] = useState(true);
    const [font, setFont] = useState(16);
    const printRef = useRef();

    useEffect(() => {
        if (modal && records) {
            setCurrentSensor(Object.keys(records)[0]);
            setPrintArr(Object.values(records));
        }
        if (!modal) {
            setPrintArr([]);
        }
    }, [modal, records]);

    const setModalContent = () => {
        return (
            <>
                <div className={ss.head}>
                    <div className={ss.settings}>
                        <div className={ss.item}>
                            <label className={ss.label}>Шрифт: </label>
                            <InputNumber
                                step="1"
                                min="0"
                                max="24"
                                value={font}
                                onChange={() => setFont(font)}
                                plus={() => setFont(font + 1)}
                                minus={() => setFont(font - 1)}
                            />
                        </div>

                        <div className={ss.item}>
                            <Checkbox
                                label="Таблица"
                                readOnly={true}
                                checked={printTable}
                                onChange={() => setPrintTable(!printTable)}
                            />
                        </div>

                        <div className={ss.item}>
                            <Checkbox
                                label="Маркеры"
                                readOnly={true}
                                checked={printMarkers}
                                onChange={() => setPrintMarkers(!printMarkers)}
                            />
                        </div>
                    </div>
                </div>
                <div className={ss.tabs}>
                    {printArr?.length > 0 &&
                        printArr.map(({ name, key }) => {
                            return (
                                <div
                                    className={currentSensor === key ? ss.tabsItem + ' ' + ss.tabsItemActive : ss.tabsItem}
                                    key={key}
                                    onClick={() => setCurrentSensor(key)}
                                >
                                    {key.includes('imp') ? name + ' Имп.' : name}
                                </div>
                            );
                        })}
                </div>
                <div
                    ref={printRef}
                    className={ss.body + ' scrolledBox'}
                    style={{ fontSize: font + 'px' }}
                >
                    {modal && renderPrintTable()}
                </div>
            </>
        );
    };

    const renderPrintTable = () => {
        if (printArr.length) {
            const sensor = printArr.filter((data) => data.key === currentSensor)[0];
            if (!sensor) return;

            const { name, unit, key: sensKey, value, roundDigit } = sensor;
            let index = sensKey;
            const markArr = [];
            const marks = markers[index];
            let table = '<table>';

            if (value.length) {
                for (var key in marks) {
                    const marker = marks[key];
                    if (marker.type === 'box') {
                        const min = marker.min / 1000;
                        const max = marker.max / 1000;
                        const yMin = marker.yMin;
                        const yMax = marker.yMax;
                        markArr.push({ time: min, value: yMin.toFixed(roundDigit || 2) });
                        markArr.push({ time: max, value: yMax.toFixed(roundDigit || 2) });
                    }
                }

                if (markArr.length && printMarkers) {
                    table += `<tr>
                    <td>Диапазон</td>
                    <td></td>
                    <td></td>
                </tr>`;

                    table += `<tr>
                    <td>№</td>
                    <td>Время,сек</td>
                    <td>${name}${unit ? ', ' + unit : ''}</td>
                </tr>`;

                    markArr.forEach((item, i) => {
                        const { time, value } = item;
                        table += `<tr>
                        <td>${i + 1}</td>
                        <td>${(time / 1000).toFixed(3)}</td>
                        <td>${value}</td>
                    </tr>`;
                    });
                }

                if (printTable) {
                    table += `<tr>
                    <td>Таблица</td>
                    <td></td>
                    <td></td>
                </tr>`;

                    table += `<tr>
                    <td>№</td>
                    <td>Время,сек</td>
                    <td>${name}${unit ? ', ' + unit : ''}</td>
                </tr>`;

                    value[0].forEach((item, i) => {
                        const x = item;
                        const y = value[1][i];

                        table += `<tr>
                        <td>${++i}</td>
                        <td>${(x / 1000).toFixed(3)}</td>
                        <td>${y.toFixed(roundDigit !== undefined ? roundDigit : 3)}</td>
                    </tr>`;
                    });
                }

                table += '</table>';
                return parse(table);
            } else {
                return 'Нет дaнных';
            }
        } else {
            return 'Нет дaнных';
        }
    };

    if (!modal) {
        return false;
    }

    return (
        modal && (
            <Modal
                visible={modal}
                size={'md'}
            >
                <Modal.Head
                    title="Печать"
                    modalClose={() => setPrintModal(false)}
                />
                <Modal.Body>{setModalContent()}</Modal.Body>
                <Modal.Footer>
                    <ReactToPrint content={() => printRef.current}>
                        <PrintContextConsumer>{({ handlePrint }) => <Button onClick={handlePrint}>Печать</Button>}</PrintContextConsumer>
                    </ReactToPrint>
                    <Button
                        border={true}
                        onClick={() => setPrintModal(false)}
                    >
                        Отменить
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    );
};

export default ModalPrint;
