import { setSensorsSummaryModal } from '../../actions/sensors';

export const mapStateToProps = (state) => {
    return {
        modal: state.sensors.summaryModal,
        data: state.sensors.data,
        writedData: state.sensors.writedData,
        sensors: state.sensors.list,
        imported: state.sensors.imported,
        writed: state.sensors.writed,
        markers: state.chart.marker,
        played: state.actions.played,
        paused: state.actions.paused,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setSensorsSummaryModal: (bool) => dispatch(setSensorsSummaryModal(bool)),
});
