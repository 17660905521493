const initialState = {
    list: [],
    terminal: '',
    modal: false,
    connection: false,
    progress: 0,
    error: null,
    success: null,
    size: null,
    needUpdate: false,
    newVersion: '',
};

const firmwareReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_FIRMWARE_LIST':
            return {
                ...state,
                list: action.payload,
            };

        case 'SET_FIRMWARE_CONNECTION':
            return {
                ...state,
                connection: action.payload,
            };

        case 'SET_FIRMWARE_MODAL':
            return {
                ...state,
                modal: action.payload,
            };

        case 'SET_FIRMWARE_TERMINAL':
            return {
                ...state,
                terminal: action.payload,
            };

        case 'SET_FIRMWARE_PROGRESS':
            return {
                ...state,
                progress: action.payload,
            };

        case 'SET_FIRMWARE_ERROR':
            return {
                ...state,
                error: action.payload,
            };

        case 'SET_FIRMWARE_SUCCESS':
            return {
                ...state,
                success: action.payload,
            };

        case 'SET_FIRMWARE_SIZE':
            return {
                ...state,
                size: action.payload,
            };

        case 'SET_FIRMWARE_NEEDUPDATE':
            return {
                ...state,
                needUpdate: action.payload,
            };

        case 'SET_FIRMWARE_NEWVERSION':
            return {
                ...state,
                newVersion: action.payload,
            };

        default:
            return state;
    }
};

export default firmwareReducer;
