import React, { useEffect, useState } from 'react';
import { Icon } from '../ui';
import ss from './MarkersRezults.module.scss';

const MarkersRezults = ({ markers, setChartRanges, sensorsList, importList, delChartMarkers }) => {
    const [rows, setRows] = useState({});

    useEffect(() => {
        const obj = {};

        Object.keys(markers).forEach((key, i) => {
            const marksArr = Object.values(markers[key]).filter((marker) => marker.resizer);
            // marksArr;
            const sensor = [...sensorsList, ...importList].filter((sens) => sens.key === key)[0];

            if (sensor && marksArr.length === 2) {
                const { name, unit, color, roundDigit } = sensor;
                const vals = marksArr.map(({ yValue, value }) => {
                    return { y: yValue, x: value };
                });
                const min = vals[0] || 0;
                const max = vals[1] || 0;
                const avg = (max.y + min.y) / 2;
                const difference = max.x - min.x;

                obj[key] = { name, unit, key, color, roundDigit, min, max, avg, difference };
            }
        });

        setRows(obj);
    }, [markers, sensorsList, importList]);

    useEffect(() => {
        if (rows && Object.keys(rows)?.length) {
            setChartRanges(true);
        } else {
            setChartRanges(false);
        }
    }, [rows, setChartRanges]);

    const setDifferenseTime = (ms) => {
        const declination = [
            ['миллисекунда', 'миллисекунды', 'миллисекунд'],
            ['секунда', 'секунды', 'секунд'],
            ['минута', 'минуты', 'минут'],
        ];
        if (ms < 1000) {
            return `0 секунд ${decOfNum(Math.floor(ms), declination[0])}`;
        }
        if (ms >= 1000 && ms < 1000 * 60) {
            return `${decOfNum(Math.floor(ms / 1000), declination[1])} ${decOfNum(Math.floor(ms % 1000), declination[0])}`;
        }
        if (ms >= 1000 * 60) {
            const minute = Math.floor(ms / 60000);
            const seccond = Math.floor((ms % (60000 * minute)) / 1000);
            return `${decOfNum(minute, declination[2])} ${decOfNum(seccond, declination[1])}`;
        }
    };

    const decOfNum = (value, words) => {
        let num = value % 10;
        let text = words[2];
        if (num > 1 && num < 5) text = words[1];
        if (num === 1) text = words[0];
        if (value > 10 && value < 20) text = words[2];
        return `${value} ${text}`;
    };

    return (
        Object.keys(rows)?.length > 0 && (
            <div className={ss.list}>
                {Object.values(rows).map(({ name, unit, color, key, min, max, avg, difference, roundDigit }) => (
                    <div
                        className={ss.item}
                        key={key}
                    >
                        <div className={ss.head}>
                            <div className={ss.info}>
                                <div
                                    className={ss.color}
                                    style={{ background: color }}
                                ></div>
                                <div className={ss.title}>{key.includes('imp') ? name + ' Имп.' : name}</div>
                            </div>
                            <div
                                className={ss.del}
                                onClick={() => delChartMarkers(key)}
                            >
                                <Icon name="trash" />
                            </div>
                        </div>
                        <div className={ss.body}>
                            <div className={ss.col}>
                                <div className={ss.label}>
                                    <span>№1</span>
                                </div>
                                <div className={ss.value}>
                                    {min.y?.toFixed(roundDigit) || 0} {unit}
                                </div>
                            </div>
                            <div className={ss.col}>
                                <div className={ss.label}>
                                    <span>№2</span>
                                </div>
                                <div className={ss.value}>
                                    {max.y?.toFixed(roundDigit) || 0} {unit}
                                </div>
                            </div>
                            <div className={ss.avg}>
                                <div className={ss.label}>
                                    <span>Среднее значение</span>
                                </div>
                                <div className={ss.value}>
                                    {avg.toFixed(roundDigit)} {unit}
                                </div>
                            </div>
                            <div className={ss.row}>
                                <div className={ss.label}>Время между отметками</div>
                                <div className={ss.value}>{setDifferenseTime(difference)}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )
    );
};
export default MarkersRezults;
