import React, { useEffect, useState, memo, useCallback } from 'react';
import { Sensor } from './';
import { OscilloSettings } from '../OscilloSettings';
import { Bundles } from '../Bundles/AsideBundles';
import ss from './Sensors.module.scss';

const Sensors = (props) => {
    const { sensorList, sensorImported, sensorWrited, sensorsBundles, isOscilloscope, sorting, chartDisabled } = props;

    const [visible, setVisible] = useState(false);
    const isSensors = sorting === 'all' || sorting === 'sensors';
    const isBundles = sorting === 'all' || sorting === 'bundles';

    useEffect(() => {
        if (sensorList?.length || sensorImported?.length) {
            setVisible(true);
        }
    }, [sensorList, sensorImported]);

    const renderSensors = () => {
        const sensors = [...sensorWrited, ...sensorImported, ...sensorList];
        // console.log('chartDisabled', chartDisabled);

        return sensors.map((item, i) => {
            const { unicName, num, deviceKey } = item;

            // const disabled = chartDisabled.find((ch) => ch === num);
            return (
                <Sensor
                    sensor={item}
                    key={deviceKey + unicName + num}
                    index={i}
                />
            );
        });
    };

    return (
        <div className={ss.sensors}>
            {visible && isSensors && renderSensors()}
            {visible &&
                isBundles &&
                Object.values(sensorsBundles)?.map((item) => {
                    const { key } = item;
                    return (
                        <Bundles
                            bundle={item}
                            key={key}
                        />
                    );
                })}
            {isOscilloscope && <OscilloSettings />}
        </div>
    );
};

export default memo(Sensors);
