import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'rlabui';
import { getDropdown } from '../VideoEditor/utils';
import '../VideoEditor/VideoEditor.scss';

const ScenarioChoiceDlg = ({ showModal, setShowModal, scenarioList, handleScenario }) => {
    const [scenarioId, setScenarioId] = useState(-1);
    const [dropScenarioList, setDropScenarioList] = useState([]);

    useEffect(() => {
        if (scenarioList.length === 0) return;
        const list = scenarioList.map((item) => ({ value: item.id, label: item.scenario }));
        setDropScenarioList(list);
        setScenarioId(scenarioList[0].id);
    }, [scenarioList]);

    return (
        <Modal
            visible={showModal}
            size={'xs'}
        >
            <Modal.Head
                modalClose={() => setShowModal(false)}
                title="Выберите доступный сценарий "
            ></Modal.Head>
            <Modal.Body>
                {getDropdown(dropScenarioList, scenarioId, setScenarioId, '', '', 'sc01')}
                <div className="videoEditor__cameraSpace" />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    color="primary"
                    onClick={() => handleScenario(scenarioId)}
                >
                    Запустить сценарий
                </Button>
                <Button
                    color="primary"
                    border={true}
                    onClick={() => setShowModal(false)}
                >
                    Отменить
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ScenarioChoiceDlg;
