import { ReactComponent as Create } from '../../../assets/icons/card-menu/01_create.svg';
import { ReactComponent as Check } from '../../../assets/icons/card-menu/02_check.svg';
import { ReactComponent as Rename } from '../../../assets/icons/card-menu/03_rename.svg';
import { ReactComponent as Remove } from '../../../assets/icons/card-menu/04_remove.svg';
import { ReactComponent as Archive } from '../../../assets/icons/card-menu/05_archive.svg';
import { ReactComponent as Copy } from '../../../assets/icons/card-menu/06_copy.svg';
import { ReactComponent as CopyLink } from '../../../assets/icons/card-menu/07_copy-link.svg';
import { ReactComponent as Select } from '../../../assets/icons/card-menu/08_select.svg';
import { ReactComponent as Upload } from '../../../assets/icons/card-menu/09_upload.svg';
import { ReactComponent as Delete } from '../../../assets/icons/card-menu/10_delete.svg';
import { ReactComponent as ArchiveAll } from '../../../assets/icons/card-menu/11_archive-all.svg';
import { ReactComponent as ArchiveCompleted } from '../../../assets/icons/card-menu/12_archive-completed.svg';
import { ReactComponent as Sort } from '../../../assets/icons/card-menu/13_sort.svg';
import { ReactComponent as Calendar } from '../../../assets/icons/card-menu/14_calendar.svg';
import { ReactComponent as User } from '../../../assets/icons/card-menu/15_user.svg';
import { ReactComponent as Date } from '../../../assets/icons/card-menu/16_date.svg';
import { ReactComponent as Priority } from '../../../assets/icons/card-menu/17_priority.svg';
import { ReactComponent as DropDownMenu } from '../../../assets/icons/card-menu/dd.svg';
import ColorMenuItem from './ColorMenuItem';

export const getListMenu = () => ({
  rename: {
    title: 'Переименовать',
    icon: <Rename />,
  },
  archiveAll: {
    title: 'Архивировать все задачи',
    icon: <Archive />,
    // isDisabled: true,
  },
  archiveCompleted: {
    title: 'Архивировать выполненные',
    icon: <ArchiveCompleted />,
    isDisabled: true,
  },
  remove: {
    title: 'Переместить',
    icon: <Remove />,
    isDisabled: true,
  },
  duplicate: {
    title: 'Дублировать',
    icon: <Copy />,
    isDisabled: true,
  },
  sortBy: {
    title: 'Сортировать задачи по',
    icon: <Sort />,
    additionalIcon: <DropDownMenu />,
    // isDisabled: true,
    subitems: {
      deadline: {
        title: 'Дедлайну',
        icon: <Calendar />,
        additionalIcon: <Sort />,
        field: 'deadline',
      },
      user: {
        title: 'Исполнителю',
        icon: <User />,
        // field: 'owner',
      },
      createDate: {
        title: 'Дате создания',
        icon: <Date />,
        additionalIcon: <Sort />,
        field: 'created',
      },
      completion: {
        title: 'Выполненности',
        icon: <Check />,
        additionalIcon: <Sort />,
      },
      taskName: {
        title: 'Названию задачи',
        icon: <Rename />,
      },
      priority: {
        title: 'Приоритет',
        icon: <Priority />,
        additionalIcon: <Sort />,
        field: 'priority',
      },
    },
  },
  color: {
    component: ColorMenuItem,
  },

  delete: {
    title: 'Удалить',
    icon: <Delete />,
    // isDisabled: true,
  },
});
