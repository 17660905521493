import React, { useState, useEffect } from "react";
import {getFiles} from '../ui/utils/gen_utils';
import { QUESTION_TYPES, STATUS, getOneCorrectAnswer, getNonVerbalOptionFieldList,
    loadOTFiles, uploadOTFile, getMaxOfArray, deleteOTFileByName } from "./ot_utils";
import ImageDlg from "../ui/ModalDialogs/ImageDlg";
import { isAuth } from "../../helpers/auth";
import {useDispatch, useSelector} from "react-redux";
import { Icon, Warning } from '../ui';
import ss from './OnlineTestCreateQuestions.module.scss';

const Constructor3NonVerbal = ({
        questionList, currQuestionType, currQuestionNum, isNewQuestion, 
        changedData, setChangedData, setCurrAnswerOptions, setCurrCorrectOptions, 
        setDataError, isTemplate, onlineTestId, isEditable}) => {
    const [correctOption, setCorrectOption] = useState('0'); 
    const [answerList, setAnswerList] = useState([]);
    const [status, setStatus] = useState(0);
    const [currQuestion, setCurrQuestion] = useState(undefined);
    const [showPictureDlg, setShowPictureDlg] = useState(false);
    const [fileUrl, setFileUrl] = useState('');
    const [loadedFileName, setLoadedFileName] = useState('');

    const minAnswerNum = 2;
    const dispatch = useDispatch();
    const {lists: {files}} = useSelector(state => state);

    const isNonVerbalAnswer = qType => qType === QUESTION_TYPES.NON_VERBAL;
    const isAudio = qType => qType === QUESTION_TYPES.AUDIO;

    useEffect(() => {
        if (!isTemplate) loadOTFiles(dispatch);
    }, [dispatch, loadedFileName, currQuestionNum, isTemplate, changedData]);

    useEffect(() => {
        const question = isNewQuestion ? undefined : questionList[currQuestionNum];
        setCurrQuestion(question);
        setStatus(STATUS.INIT);
    }, [questionList, currQuestionNum, isNewQuestion]); //currQuestionNum, isNewQuestion, questionList

    useEffect(() => {
        if (status !== STATUS.INIT) return;
        if (isNewQuestion) {
            setAnswerList([]);
            setCorrectOption('0');
        }
        setStatus(STATUS.FILL);
    }, [status, isNewQuestion]);

    // === 1 of 2 useEffects: INITIAL
    useEffect(() => {
        if (status !== STATUS.FILL) return;
        if (currQuestion) {
            setCorrectOption(currQuestion.correctOptions);
        
            const answers = currQuestion.answerOptions.map((fileName, ind) => 
                ({name: fileName, id: ind, correct: false}));
            setAnswerList(answers);
        }

        setStatus(STATUS.USE);
    }, [currQuestionNum, status, currQuestion]);

    // === 2 of 2 useEffects: //save changes (modified is true)
    useEffect(() => {
        if (status !== STATUS.USE) return;

        const errorMsg = answerList.length < minAnswerNum ? 'Количество предлагаемых ответов должно быть не меньше ' + minAnswerNum + '.' : '';
        setDataError(errorMsg);
        if (errorMsg) return;

        let changed = false;
        if (!currQuestion || currQuestion.correctOptions !== correctOption) {
            setCurrCorrectOptions(correctOption);
            changed = true;
        }

        const aOpts = answerList.map(item => item.name);
        if (!currQuestion || aOpts.length !== currQuestion.answerOptions.length || 
            aOpts.filter((item, ind) => item !== currQuestion.answerOptions[ind]).length > 0) {
                setCurrAnswerOptions(aOpts);
                changed = true;
        }
        
        if (changed && !isNewQuestion) {
            setChangedData(true); //только для редактируемого вопроса. Новый вопрос вопрос сохраняется по кнопке
        }
    }, [correctOption, answerList, currQuestionType, setDataError, setChangedData, isNewQuestion,
        currQuestion, setCurrAnswerOptions, setCurrCorrectOptions, status]);

    const handleDeleteImageAnswerOption = async (_answerList, _setAnswerList, _dispatch, ind) => {
        if (isAuth().role < 3) {
            const fileName = _answerList[ind].name;
            deleteOTFileByName(dispatch, fileName);
        }
        const answers = _answerList.filter((item, index) => index !== ind);
        _setAnswerList(answers);
    };
    
    //step 1 of 2: get file
    const handleAddAnswerOption = (event, _files, file) => {
        if (!isTemplate) {
            uploadOTFile(_files, file, setLoadedFileName);
        } else {
            setLoadedFileName(file); //keep the file name and content
        }

        event.target.value = '';
    };

    //step 2 of 2: save file (SA) name or file (teacher)
    useEffect(() => {
        if (!loadedFileName) return;
        const answers = [...answerList];
        const ids = answerList.map((item, ind) => item.id);
        const maxId = ids.length > 0 ? getMaxOfArray(ids) : -1;

        if (!isTemplate) {
            answers.push({name: loadedFileName, id: maxId + 1, correct: false});
            setAnswerList(answers);
            setLoadedFileName('');
        } else {
            const reader = new FileReader();
            reader.onload = (event) => {
                //DB will keep the file data: name and content
                const fileOpts = {name: loadedFileName.name, content: event.target.result};
                setLoadedFileName('');
                const fileData = JSON.stringify(fileOpts);
                answers.push({name: fileData, id: maxId + 1, correct: false});
                setAnswerList(answers);
            };
            reader.readAsDataURL(loadedFileName); //if isTemplate the param contains the file (not just its name)
        }
    }, [answerList, loadedFileName, isTemplate]);
    
    const doSetCorrectOption = v => {
        setCorrectOption(v);
    };
    
	const handleShowPicture = (_file) => {
		setFileUrl(_file);
		setShowPictureDlg(true);
	};

    const getAudioList = (_isTemplate, _files, _lostRefNumber, 
        _answerList, _setAnswerList, _minAnswerNum, 
        _dispatch, _acceptableFileFormat) => {

        const foundFiles = !_isTemplate ? getFiles(_files, _answerList) : _answerList;
    
        return (
            <div className="cor-net__section border mt_lg">
                <div className="cor-net__title">Варианты ответа (вопрос должен содержать {_minAnswerNum} и более вариантов)</div>

                {_lostRefNumber > 0 && 
                <div className="cor-net__row">
                    <div className="cor-net__col col-3">
                        <Warning>Добавьте еще {_lostRefNumber === 1 ? '1 вариант': _lostRefNumber + ' варианта'}</Warning>
                    </div>
                </div>}

                {foundFiles.map((f, i) => (
                <div className="cor-net__row" key={'cnt01'+i}>
                    <div className="cor-net__col" key={'top01'+i}>
                        <div className="cor-net__label flex" key={'top02'+i}>
                            Вариант {i+1}
                            <Icon 
                                className={ss.trash}
                                name="trash" 
                                onClick={() => handleDeleteImageAnswerOption(
                                    _answerList, _setAnswerList, _dispatch, i)} 
                                key={'top03'+i} 
                            />
                        </div>
                        <div className={ss.audio}>
                            <audio src={!isTemplate ? f.Url : JSON.parse(f.name).content} 
                                volume={0} controls={true} width="100" height="30" key={'mid04'+i} 
                            />
                        </div>
                    </div>
                </div>
                ))}

                <div className="cor-net__row">
                    <label className={ss.addOption} key='lbl01'>
                        <Icon name="plus" key='lbl02' />Добавить вариант
                        <input type="file" accept={_acceptableFileFormat} 
                            onChange={(e) => handleAddAnswerOption(e, _files, e.target.files[0])} 
                            key='lbl03' 
                        />
                    </label>
                </div>
            </div>
        );
    };
   
    return (
        <>
            {/* Top part: answer options */}
            {!isAudio(currQuestionType) && 
            getNonVerbalOptionFieldList(isTemplate, files, 
                !currQuestion ? 0 : minAnswerNum - answerList.length, 
                answerList, setAnswerList, minAnswerNum, 
                dispatch, "image/*", 
                handleAddAnswerOption, handleDeleteImageAnswerOption, handleShowPicture)}

            {isAudio(currQuestionType) && 
            getAudioList(isTemplate, files, 
                !currQuestion ? 0 : minAnswerNum - answerList.length, 
                answerList, setAnswerList, minAnswerNum, 
                dispatch, "audio/*")}

            {/* Bottom part: select a correct answer */}
            {answerList.length > 0 && 
                getOneCorrectAnswer(answerList, correctOption, doSetCorrectOption, isEditable)}

            {showPictureDlg &&
			<ImageDlg
				showModal={showPictureDlg}
				setShowModal={setShowPictureDlg}
				file={fileUrl}
			/>
            }
        </>
    )
}
 
export default Constructor3NonVerbal;
