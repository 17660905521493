// import {  } from '../../actions/';

export const mapStateToProps = (state) => {
    return {
        port: state.port.open,
        isMobile: state.actions.isMobile,
    }
}

export const mapDispatchToProps = (dispatch) => ({})
