import React, {useState, useEffect} from "react";
import { Back, Content, ContentBody, ContentHead, ContentTitle, ContentWrap } from "../ContentParts";
import Footer from '../Footer';
import {connect} from "react-redux";
import {mapScenarioStateToProps, mapScenarioDispatchToProps} from "./redux";
import {getSectionTab} from './db_utils';
import {getDevices} from "../../RlabScenarios/sc_utils";
import ScenarioList from "../../RlabScenarios/ScenarioList";
import ScenarioResultList from "../../RlabScenarios/ScenarioResultList";
import ScenarioOne from "../../RlabScenarios/ScenarioOne";
import {isAuth} from "../../../helpers/auth";
import {autoconnectReconnectSerialPort} from "../../../rlab/src/commands/device";
import {useSelector, useDispatch} from "react-redux";
import {setPlay} from '../../../rlab/src/actions/actions';
import "./Dashboard.scss";

const DashboardScenarios = (props) => { //called from template\Footer\RlabNav.js
  const [devices, setDevices] = useState([]);
  const [isAutoConnect, setIsAutoConnect] = useState(false);
  const {port: {data: portData}} = useSelector(state => state);
  const dispatch = useDispatch();

  useEffect(() => { //1 - only for superadmin
    if (isAuth().role < 3 || isAutoConnect) return;
    if ( Object.keys(portData).length === 0) {
        setIsAutoConnect(true);
        dispatch(autoconnectReconnectSerialPort);
    }
    return () => {
      if (isAuth().role === 3) {
        dispatch(setPlay(false));
      }
    }
  }, [dispatch, portData, props.getDevicesList, isAutoConnect]);

  useEffect(() => { //get device list
    if (props.devicesAll.length > 0) return;
    props.getDevicesList();
  }, [props]);

  useEffect(() => { //3 - only for superadmin
    if (isAuth().role < 3) return;
    if (!props.sensor || props.devicesAll.length === 0) return;
    
    console.log('1 - sensor=', props.sensor);
    console.log('2 - devices=', props.devicesAll.length);

    const key = props.sensor.split(' ')[0];
    //console.log('3 - key=', key);

    const key2 = key.split('-')[0];
    console.log('4 - key2=', key2);

    const device = props.devicesAll.find(item => item.attributes.key === key2);
    if (device) {
      const sensorObj = {key: key2, name: device.attributes.name};
      //console.log('5 - device=', device, ' sensorObj=', sensorObj);
      //send the list to rlab/components/Connection/Connection: see UE with JSON (..sensorList)
      props.setSensorList([sensorObj]);
    } else {
      console.log('6 - датчик не найден!');
    }
  }, [props]);

  useEffect(() => {
    const _devices = getDevices(props.devicesAll);
    if (_devices.length > 0) {
      setDevices(_devices);
    }
	}, [props.devicesAll]);
  
  return (
      <ContentWrap>
        {{
          'list': <ScenarioList {...props} devices={devices} />,
          'one': <ScenarioOne {...props} devices={devices} />,
          'result': <ScenarioResultList {...props} />,
          'template': <ScenarioResultList {...props} />,
          'opt': <>
            <ContentHead flex={false}>
              <Back onClick={() => props.history.goBack()} icon="back" margin="bottom"/>
              <ContentTitle>Сценарии</ContentTitle>
            </ContentHead>
            <Content background={false}>
              <ContentBody padding={false}>
                <div className="InitSectionTabs">
                  {getSectionTab(0, 'Мои сценарии >', 'script', 'Создание, просмотр и запуск сценариев', () => props.history.push('/rlsc/list'))}
                  {getSectionTab(1, 'Сценарии на проверке >', 'script-review', 'Проверка запущенных сценариев', () => props.history.push('/rlsc/result'))}
                </div>
              </ContentBody>
            </Content>
          </>
        }[props.match.params.opt]}
        <Footer {...props}/>
      </ContentWrap>
    );
}

export default  connect(mapScenarioStateToProps, mapScenarioDispatchToProps)(DashboardScenarios);