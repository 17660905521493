// import {  } from '../../actions/';

import { resetReduxState } from '../../../actions/actions';
import { setDemo, setDemoModal, setDemoLearn } from '../../../actions/demo';

export const mapStateToProps = (state) => {
    return {
        port: state.port.open,
        demo: state.demo.demo,
        modal: state.demo.modal,
        learn: state.demo.learn,
        sensors: state.demo.sensors,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setDemo: (bool) => dispatch(setDemo(bool)),
    setDemoModal: (bool) => dispatch(setDemoModal(bool)),
    resetReduxState: () => dispatch(resetReduxState()),
    setDemoLearn: (bool) => dispatch(setDemoLearn(bool)),
});
