import React, { useEffect, useState, useCallback, useRef, forwardRef } from 'react';
import { Chart, LineController, LineElement, PointElement, LinearScale, Title, CategoryScale } from 'chart.js';
// import { Line } from 'react-chartjs-2';
// import { getRelativePosition } from 'chart.js/helpers';
import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';
import chartColors from '../../styles/charts';
// https://github.com/DuartBreedt/chartjs-plugin-draggable
import ChartjsDraggablePlugin from 'chartjs-plugin-draggable';
import Icon from '../Icon';
import { Range } from 'rlabui';

Chart.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, zoomPlugin, annotationPlugin, ChartjsDraggablePlugin);

// let isDrag = false;
// let yAxisMax;
// let yAxisMin;
// let saved = [];
// let timeoutArea = null;
// let inChartArea;

const ChartArea = (props) => {
    const { data, writedData, sensorWrited, sensorImported, played, paused, sensors, chartStyle, defaultColors, theme, toggleChartParametric, toggleChartLissague } = props;

    const index = 0;

    const xSensor = sensors[0];
    const ySensor = sensors[1];

    const [chanelPrefix, setChannelPrefix] = useState();
    const [grid, setGrid] = useState(true);
    const chartRef = useRef();
    const wrapRef = useRef();
    const [xSlider, setXSlider] = useState(1);
    const [ySlider, setYSlider] = useState(1);
    const [radiusSlider, setRadiusSlider] = useState(200);
    const [deltaSlider, setDeltaSlider] = useState(1);
    const [zoom, setZoom] = useState(1);
    const [zoomView, setZoomView] = useState(false);
    const [ctx, setCtx] = useState(null);
    const [ctxWidth, setCtxWidth] = useState(null);
    const [ctxHeight, setCtxHeight] = useState(null);
    const [dataX, setDataX] = useState(null);
    const [dataY, setDataY] = useState(null);
    const [isXLoc, setXLoc] = useState(false);
    const [isYLoc, setYLoc] = useState(false);
    const [isXImp, setXImp] = useState(false);
    const [isYImp, setYImp] = useState(false);

    const { num } = ySensor;
    const { num: xNum } = xSensor;

    const defaultColor = defaultColors[index];
    const { weight, color } = chartStyle[num]
        ? chartStyle[num]
        : {
              weight: 2,
              color: defaultColor.val,
              point: {
                  width: 0,
                  color: defaultColor.val,
              },
              interpolation: 'basis',
          };

    let xs, ys;

    useEffect(() => {
        requestAnimationFrame(updateFrame);
    }, []);

    useEffect(() => {
        if (chartRef) {
            setCtx(chartRef.current.getContext('2d'));

            chartRef.current.addEventListener('wheel', handleZoom);
        }
        function handleZoom(e) {
            const { deltaY } = e;
            setZoomView(true);
            if (deltaY > 0) {
                setZoom((c) => (c -= 0.1));
            } else {
                setZoom((c) => (c += 0.1));
            }
            if (window.wheelTimeout) {
                window.clearTimeout(window.wheelTimeout);
            }

            window.wheelTimeout = window.setTimeout(function () {
                setZoomView(false);
            }, 500);
        }
    }, [chartRef]);

    useEffect(() => {
        if (wrapRef) {
            setCtxWidth(wrapRef?.current.offsetWidth);
            setCtxHeight(wrapRef?.current.offsetHeight);
        }
    }, [wrapRef]);

    useEffect(() => {
        setXImp(false);
        setYImp(false);
        setXLoc(false);
        setYLoc(false);
        if (typeof num === 'string') {
            if (num.includes('loc')) {
                setYImp(false);
                setYLoc(num);
            } else {
                setYImp(num);
                setYLoc(false);
            }
        }
        if (typeof xNum === 'string') {
            if (xNum.includes('loc')) {
                setXImp(false);
                setXLoc(xNum);
            } else {
                setXImp(xNum);
                setXLoc(false);
            }
        }
    }, [num, xNum]);

    useEffect(() => {
        typeof xNum === 'number' &&
            setDataX(
                data
                    ?.filter((el) => el.at(-1)?.num === xNum)[0]
                    ?.map((e) => e.y)
                    .at(-1)
            );
        typeof num === 'number' &&
            setDataY(
                data
                    ?.filter((el) => el.at(-1)?.num === num)[0]
                    ?.map((e) => e.y)
                    .at(-1)
            );
    }, [data]);

    useEffect(() => {
        if (sensorWrited.length) {
            // console.log(isXLoc, isYLoc);
            isXLoc && setDataX(writedData?.filter((e) => e[0].num === isXLoc)[0]?.at(-1)?.y);
            isYLoc && setDataY(writedData?.filter((e) => e[0].num === isYLoc)[0]?.at(-1)?.y);
        }
    }, [sensorWrited, isXLoc, isYLoc]);

    useEffect(() => {
        if (sensorImported.length) {
            // console.log(isXImp, isYImp);
            isXImp && setDataX(sensorImported?.filter((e) => e.num === isXImp)[0]?.value?.at(-1)?.y);
            isYImp && setDataY(sensorImported?.filter((e) => e.num === isYImp)[0]?.value?.at(-1)?.y);
        }
    }, [sensorImported, isXImp, isYImp]);

    useEffect(() => {
        requestAnimationFrame(updateFrame);
    }, [grid]);

    useEffect(() => {
        // console.log(dataX, dataY);
        if (dataX && dataY) {
            requestAnimationFrame(updateFrame);
        }
    }, [dataX, dataY]);

    useEffect(() => {
        if (!played || paused) {
            requestAnimationFrame(updateFrame);
        }
    }, [xSlider, ySlider, radiusSlider, deltaSlider, color, weight, zoom, theme]);

    const updateFrame = () => {
        if (ctx) {
            // Очистить canvas
            ctx.clearRect(0, 0, ctxWidth, ctxHeight);

            const ticks = 6;
            const padding = 5;
            const chartHeight = ctxHeight - padding;
            const chartWidth = ctxWidth - padding;
            const offsetHeight = chartHeight / ticks;
            const offsetWidth = chartWidth / ticks;

            if (grid) {
                // Цвет для рисования
                ctx.strokeStyle = theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light;
                ctx.lineWidth = 1;
                // Цикл для отображения значений по Y
                for (let i = 0; i < ticks; i++) {
                    ctx.fillStyle = chartColors.tick;
                    ctx.beginPath();
                    ctx.moveTo(padding - 5, i * offsetHeight);
                    ctx.lineTo(chartWidth + padding, i * offsetHeight);
                    ctx.stroke();
                }

                // Цикл для отображения значений по X
                for (let i = 0; i < ticks + 1; i++) {
                    ctx.beginPath();
                    ctx.moveTo(i * offsetWidth + padding, 0);
                    ctx.lineTo(i * offsetWidth + padding, chartHeight + 5);
                    ctx.stroke();
                }
            }

            // Фигура
            ctx.strokeStyle = color;
            ctx.lineWidth = weight;
            ctx.beginPath();
            for (let t = 0; t <= 2 * Math.PI; t += Math.PI / 500) {
                let x = radiusSlider * dataX * Math.sin(xSlider * t + deltaSlider) * zoom;
                let y = radiusSlider * dataY * Math.cos(ySlider * t - deltaSlider) * zoom;
                if (xs && ys) {
                    ctx.moveTo(ctxWidth / 2 + xs, ctxHeight / 2 - ys);
                }
                ctx.lineTo(ctxWidth / 2 + x, ctxHeight / 2 - y);
                xs = x;
                ys = y;
            }
            // Рисуем
            ctx.stroke();

            if (grid) {
                // Заглушки
                ctx.strokeStyle = theme === 'dark' ? chartColors.bg.dark : chartColors.grid.bg;
                ctx.lineWidth = padding + 5;
                ctx.beginPath();
                ctx.moveTo(0, 0);
                ctx.lineTo(0, chartHeight);
                ctx.lineTo(ctxWidth, chartHeight);
                ctx.stroke();

                // Оси
                ctx.strokeStyle = theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light;
                ctx.lineWidth = 2.0; // Ширина линии
                ctx.beginPath(); // Запускает путь
                ctx.moveTo(padding, 0); // Указываем начальный путь
                ctx.lineTo(padding, chartHeight); // Перемешаем указатель
                ctx.lineTo(ctxWidth, chartHeight); // Ещё раз перемешаем указатель
                ctx.stroke(); // Делаем контур
            }
        }
    };

    return (
        <div className="chart">
            <div className="chart__head">
                <div className="chart__left">
                    {sensors.map((sens, i) => {
                        const { name, num } = sens;
                        if (!chartStyle[num]) return;
                        const { color } = chartStyle[num];
                        if ((num || num === 0) && name && color) {
                            return (
                                <div
                                    className="chart__legend"
                                    key={i}
                                >
                                    <div
                                        className="chart__color"
                                        style={{
                                            background: color ? color : defaultColor,
                                        }}
                                    ></div>
                                    <span>
                                        {name} {chanelPrefix}
                                    </span>
                                </div>
                            );
                        }
                    })}
                </div>
                <div className="chart__action">
                    <Icon
                        name="grids"
                        title={grid ? 'Выключить сетку' : 'Включить сетку'}
                        className={grid ? 'active' : ''}
                        onClick={() => setGrid(!grid)}
                    />
                    <Icon
                        name="lissague"
                        title="Фигуры Лиссажу"
                        className="active"
                        onClick={() => toggleChartLissague(false)}
                    />
                    <Icon
                        name="broken"
                        title="Параметрические оси"
                        className="active"
                        onClick={() => toggleChartParametric()}
                    />
                    {/* <Icon
                        tag="button"
                        name="zoom"
                        title="Масштабирование фрагмента. Нажать Shift"
                        disabled={true}
                    />
                    <Icon
                        tag="button"
                        name="chart-mob"
                        title="Просмотр фрагмента"
                        disabled={true}
                    />
                    <Icon
                        className="cl_icon_capture"
                        title="Сбросить масштабирование"
                        onClick={() => setZoom(1)}
                    /> */}
                </div>
            </div>
            <div className="chart__wrap">
                <div
                    className="chart__big chart__parametric"
                    ref={wrapRef}
                >
                    <canvas
                        ref={chartRef}
                        width={chartRef?.current?.parentNode.offsetWidth}
                        height={chartRef?.current?.parentNode.offsetHeight}
                    ></canvas>
                    {zoomView && <div className="chart__zoom">{Math.round(zoom * 100)}%</div>}
                </div>
                <div className="chart__settings">
                    <div className="chart__settingsCol">
                        <label className="chart__settingsLabel">Радиус</label> <span className="chart__settingsProp">{radiusSlider / 20}</span>
                        <Range
                            min={0}
                            max={2000}
                            step={1}
                            value={radiusSlider}
                            onChange={({ target: { value } }) => setRadiusSlider(+value)}
                        />
                    </div>
                    <div className="chart__settingsCol">
                        <label className="chart__settingsLabel">Y</label> <span className="chart__settingsProp">{ySlider}</span>
                        <Range
                            min={0}
                            max={10}
                            step={0.1}
                            value={ySlider}
                            onChange={({ target: { value } }) => setYSlider(+value)}
                        />
                    </div>
                    <div className="chart__settingsCol">
                        <label className="chart__settingsLabel">X</label> <span className="chart__settingsProp">{xSlider}</span>
                        <Range
                            min={0}
                            max={10}
                            step={0.1}
                            value={xSlider}
                            onChange={({ target: { value } }) => setXSlider(+value)}
                        />
                    </div>
                    <div className="chart__settingsCol">
                        <label className="chart__settingsLabel">ẟ</label> <span className="chart__settingsProp">{deltaSlider}</span>
                        <Range
                            min={-2}
                            max={2}
                            step={0.01}
                            value={deltaSlider}
                            onChange={({ target: { value } }) => setDeltaSlider(+value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChartArea;
