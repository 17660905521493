//Taken from https://www.npmjs.com/package/react-datepicker
//Examples: https://reactdatepicker.com/#example-default
import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateTimePicker.scss";

//NOTE: Times will be displayed at 30-minute intervals by default (default configurable via timeIntervals prop)

import ru from 'date-fns/locale/ru';
registerLocale('ru', ru);

const DateTimePicker = ({ startDate, setStartDate, minDate=null, maxDate=null, showTimeSelect=false, id=null, className = ""  }) => {
	return (
		<div>
			<DatePicker 
				id={id}
				className={className}
				placeholderText="Выберите дату"
				selected={startDate} 
				onChange={(date) => setStartDate(date)} 
				closeOnScroll={true}
				showTimeSelect={showTimeSelect} 
				dateFormat='PP'//'dd.MM.yyyy' //"PP" - words //"Pp" - with time
				locale="ru"
				minDate={minDate}
				maxDate={maxDate}
			/>
		</div>
	);
}

export default DateTimePicker;
