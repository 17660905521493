import React, {useState, useEffect} from "react";
import {piApi} from "../../api/api";
import PIOneName from './PIOneName';
import {OBJECT_TAB, INVEST_MODE_TAB, loadPIFiles, getObjectView} from "./pi_utils";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {storageClass} from "../../redux/slices/storage";
import {loadPIObjects} from "../../redux/slices/lists";

const PIOneObject = ({isEdit, //is edit mode (true = проведение, false - просмотр)
        uid, id, invModeTabInd, workObject, setWorkObject, objectIndex, 
        doDeleteHideSection, setShowImageDlg, setShownFile, isTemplate}) => {
    const [commonObjName, setCommonObjName] = useState(undefined);
	const [objName, setObjName] = useState("");
	const [objDescription, setObjDescription] = useState("");
	const [objProps, setObjProps] = useState([]);
    const [objFiles, setObjFiles] = useState([]);
	const [objType, setObjType] = useState(""); //текущее значение типа
	const [objGroup, setObjGroup] = useState("");
	const [objTabInd, setObjTabInd] = useState(OBJECT_TAB.PROPS); //если нужно, можно добавить изменение этого индекса парентом (в случае печати всехх табов)

	const documentClass = useSelector(storageClass);
	const {lists: {pi, files}} = useSelector(state => state);
	const dispatch = useDispatch();

    useEffect(() => {
        const readSelectedObject = async (id) => {
            const obj = await piApi.getObjectById(id);
            setObjName(obj.name);
            setObjDescription(obj.desc);
            setObjGroup(obj.group);
            setObjType(obj.type);
            setObjProps(!obj.properties ? [] : obj.properties);
            setObjFiles(obj.files.map(item => ({name: item})));
            //setIsEditEnabled(obj.owner || isAuth().role === 3); //объекты здесь не редактируются
        };

        if (!workObject) return;
        readSelectedObject(workObject.id);
        setCommonObjName(workObject.title);
        loadPIFiles(dispatch);
    }, [workObject, dispatch]);
    useEffect(() => {
		dispatch(loadPIObjects(documentClass._id));
	}, [dispatch, documentClass._id]);

    const handleShowImage = (file) => {
        if (!file) return;
        setShowImageDlg(true);
        setShownFile(file);
    };

    const handleDownloadFile = () => {
        toast.success("Файл загружен.");
    };

    const updateCommonObjName = (isSave, name) => {
        if (isSave) {
            const wc = {...workObject};
            wc.title = name;
            setWorkObject(objectIndex, wc);
        }
    };

    return (
        <div id={id}>
            <PIOneName
                isEdit={invModeTabInd !== INVEST_MODE_TAB.VIEW} 
                initName={commonObjName} 
                setEditedName={updateCommonObjName} 
                uniqueIndex={objectIndex}
                doDeleteHideSection={doDeleteHideSection} 
            />

            {getObjectView(isTemplate, false, objName, objType, objGroup, objDescription, objProps, 
                    pi.properties, objTabInd, setObjTabInd, () => {}, 
                    files, 
                    isTemplate ? objFiles.map(item => item.name) : objFiles, 
                    () => {}, () => {}, () => {}, handleShowImage, handleDownloadFile)
            }
        </div>
    );
}

export default PIOneObject;