import React, { useEffect, useState } from 'react';
import { Modal, Checkbox, Button } from 'rlabui';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Buffer } from 'buffer';
import Moment from 'moment';
import ss from './ExportSettingsModal.module.scss';

const ExportSettingsModal = ({ modal, played, records, markers, setExportModal }) => {
    const [options, setOptions] = useState([]);
    const [isChildren, setChildren] = useState(false);
    const [isMarkers, setMarkers] = useState(false);
    useEffect(() => {
        console.log(modal);
        if (modal && !played) {
            console.log(modal, records, markers);
            const newOpts = [];
            Object.values(records).forEach(({ key }) => {
                if (!isNaN(+key.at(-1))) {
                    newOpts.push('children');
                }
            });
            Object.values(markers).forEach(() => newOpts.push('markers'));

            setOptions([...new Set(newOpts)]);
        } else {
            setOptions([]);
            setChildren(false);
            setMarkers(false);
            setExportModal(false);
        }
    }, [modal, records, markers, played, setExportModal]);

    const exportToFile = async () => {
        const marksArr = [];
        const date = new Date();
        const fName = Moment(date).format('yyMMD-hhmmss');
        let worksheet;

        for (const rkey in records) {
            const { num, key } = records[rkey];
            let ch = num;
            if (typeof ch === 'string') {
                ch = +num.substr(3);
            }
            marksArr.push(markers[key]);
        }

        const marks = [];
        const ranges = [];
        const headers = [];
        let isMarkers = false;
        let isRanges = false;

        marksArr?.forEach((marker, i) => {
            const rkey = Object.keys(records)[i];
            const channel = records[rkey];
            const { name, unit, num } = channel;
            const mRow = [];
            const rRow = [];

            let ch = num;
            if (typeof num === 'string') {
                ch = +num.substr(3);
            }

            headers.push('№', 'Время,сек.', `${ch}. ${name},${unit}`, '');

            let rangeCounter = 1;
            let marksCounter = 1;

            for (var key in marker) {
                const mark = marker[key];
                // console.log(mark);

                if (mark.type === 'line' && !mark.resizer) {
                    mRow.push([marksCounter, (mark.value / 1000).toFixed(3), mark.yValue, null]);
                    isMarkers = true;
                    marksCounter++;
                }

                if (mark.type === 'box') {
                    const { max, min, yMin, yMax } = mark;

                    rRow.push([rangeCounter, (min / 1000).toFixed(3), yMin, null]);
                    rangeCounter++;
                    rRow.push([rangeCounter, (max / 1000).toFixed(3), yMax, null]);
                    isRanges = true;
                    rangeCounter++;
                }
            }

            marks.push(mRow);
            ranges.push(rRow);
        });

        const setMarkersRow = (arr, type) => {
            if (!worksheet) {
                worksheet = XLSX.utils.aoa_to_sheet([[type]]);
            } else {
                XLSX.utils.sheet_add_aoa(worksheet, [[type]], { origin: -1 });
            }

            XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: -1 });
            const lengths = arr.map((a) => a.length);
            const index = lengths.indexOf(Math.max(...lengths));
            let rRow = [];

            for (let i = 0; i < arr[index].length; i++) {
                for (const item of arr) {
                    if (item.length >= i + 1) {
                        rRow = [...rRow, ...item[i]];
                    } else {
                        rRow = [...rRow, ...[null, null, null, null]];
                    }
                }
                XLSX.utils.sheet_add_aoa(worksheet, [rRow], { origin: -1 });
                rRow = [];
            }
        };

        if (ranges.length && isRanges) {
            setMarkersRow(ranges, 'Диапазоны');
        }

        if (marks.length && isMarkers) {
            setMarkersRow(marks, 'Маркеры');
        }

        if (!worksheet) {
            worksheet = XLSX.utils.aoa_to_sheet([['Таблицы']]);
        } else {
            XLSX.utils.sheet_add_aoa(worksheet, [['Таблицы']], { origin: -1 });
        }

        let rowLen = 0;

        const setTableRow = () => {
            const tableTitle = [];

            for (const key in records) {
                const { value } = records[key];
                if (isChildren) {
                    if (value?.length && rowLen < value[0].length) {
                        rowLen = value[0].length;
                    }
                } else {
                    if (isNaN(+key.at(-1))) {
                        if (value?.length && rowLen < value[0].length) {
                            rowLen = value[0].length;
                        }
                    }
                }
            }

            if (rowLen) {
                let count = 1;
                for (let i = 0; i < rowLen; i++) {
                    const tableRow = [];

                    for (let key in records) {
                        const { num, name, unit, value } = records[key];

                        if (value?.length) {
                            if (isChildren) {
                                pushDataRows(i, num, name, unit, value, tableRow, count);
                            } else {
                                if (isNaN(+key.at(-1))) {
                                    pushDataRows(i, num, name, unit, value, tableRow, count);
                                }
                            }
                        }
                    }

                    count++;

                    if (i === 0) {
                        XLSX.utils.sheet_add_aoa(worksheet, [tableTitle], { origin: -1 });
                    }

                    XLSX.utils.sheet_add_aoa(worksheet, [tableRow], { origin: -1 });
                }
            }

            function pushDataRows(i, num, name, unit, value, tableRow, count) {
                if (i === 0) {
                    tableTitle.push(`№`, `Время,сек.`, `${num}. ${name},${unit}`, '');
                }

                if (!isNaN(value[0][i]) && !isNaN(value[1][i])) {
                    let x = value[0][i];
                    let y = value[1][i];

                    if (typeof x === 'number' && typeof y === 'number') {
                        tableRow.push(count, x, y, '');
                    }
                } else {
                    tableRow.push('', '', '', '');
                }
            }
        };

        setTableRow();

        if (rowLen) {
            const options = { autoBom: true };
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            let dataBuffer = XLSX.write(workbook, { bookType: modal, type: 'array' });
            let fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

            if (modal === 'csv') {
                fileType = 'text/csv;charset=utf-8';
                const csv = XLSX.utils.sheet_to_csv(worksheet, { FS: ';' });
                const encoded = Buffer.from('\uFEFF' + csv, 'utf8');
                dataBuffer = new Int8Array(encoded);
            }

            options.type = fileType;
            const blob = new Blob([dataBuffer], { type: fileType });
            FileSaver.saveAs(blob, fName + '.' + modal, options);
        }
    };

    return modal ? (
        <Modal visible={modal}>
            <Modal.Head
                title={`Настроки экспорта в .${modal}`}
                modalClose={() => setExportModal()}
            />
            <Modal.Body>
                <div className={ss.root}>
                    {options.map((e, i) => {
                        if (e === 'children') {
                            return (
                                <>
                                    <div
                                        className={ss.row}
                                        key={i}
                                    >
                                        <Checkbox
                                            label="Экспортировать дочерние графики"
                                            checked={isChildren}
                                            onChange={() => setChildren(!isChildren)}
                                        />
                                    </div>
                                </>
                            );
                        } else if (e === 'markers') {
                            return (
                                <>
                                    <div
                                        className={ss.row}
                                        key={i}
                                    >
                                        <Checkbox
                                            label="Экспортировать дипазоны"
                                            checked={isMarkers}
                                            onChange={() => setMarkers(!isMarkers)}
                                        />
                                    </div>
                                </>
                            );
                        } else {
                            return false;
                        }
                    })}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => exportToFile()}>Экспортировать</Button>
                <Button
                    border={true}
                    onClick={() => setExportModal()}
                >
                    Отмена
                </Button>
            </Modal.Footer>
        </Modal>
    ) : null;
};
export default ExportSettingsModal;
