export const setSensor = (item) => ({ type: 'SET_SENSOR', payload: item });
export const setMeasunits = (arr) => ({ type: 'SET_MEASUNITS', payload: arr });
export const setSensorsModal = (bool) => ({ type: 'SET_SENSORS_MODAL', payload: bool });
export const setPrintModal = (bool) => ({ type: 'SET_PRINT_MODAL', payload: bool });
export const setGraduationModal = (bool) => ({ type: 'SET_GRADUATION_MODAL', payload: bool });
export const setSensorList = (list) => ({ type: 'SET_SENSOR_LIST', payload: list });
export const setSensorData = (data) => ({ type: 'SET_SENSOR_DATA', payload: data });
export const setSensorWritedData = (data) => ({ type: 'SET_SENSOR_WRITED_DATA', payload: data });
export const setSensorUnit = (data) => ({ type: 'SET_SENSOR_UNIT', payload: data });
export const setSensorAbscissa = (data) => ({ type: 'SET_SENSOR_ABSCISSA', payload: data });
export const setSensorGradOffset = (data) => ({ type: 'SET_SENSOR_GRAD_OFFSET', payload: data });
export const setSensorAsideData = (data) => ({ type: 'SET_SENSOR_ASIDE_DATA', payload: data });
export const setSensorWritedList = (list) => ({ type: 'SET_SENSOR_WRITED_LIST', payload: list });
export const setSensorRecords = (records) => ({ type: 'SET_SENSOR_RECORDS', payload: records });
export const setSensorImported = (sensors) => ({ type: 'SET_SENSOR_IMPORTED', payload: sensors });
export const setSensorTablePrint = (table) => ({ type: 'SET_SENSOR_TABLE_PRINT', payload: table });
export const setSensorTableSorting = (bool) => ({ type: 'SET_SENSOR_TABLE_SORTING', payload: bool });
export const setSensorFrequency = (obj) => ({ type: 'SET_SENSOR_FREQUENCY', payload: obj });
export const setSensorMode = (obj) => ({ type: 'SET_SENSOR_MODE', payload: obj });
export const checkHiSpeed = (bool) => ({ type: 'CHECK_HISPEED', payload: bool });
export const addSensorsEnabled = (num) => ({ type: 'ADD_SENSORS_ENABLED', payload: num });
export const delSensorsEnabled = (index) => ({ type: 'DEL_SENSORS_ENABLED', payload: index });
export const addSensorsBundles = (item) => ({ type: 'ADD_SENSORS_BUNDLES', payload: item });
export const delSensorsBundles = (item) => ({ type: 'DEL_SENSORS_BUNDLES', payload: item });
export const updateSensorsBundles = (item) => ({ type: 'UPDATE_SENSORS_BUNDLES', payload: item });
export const setSensorsBundleModalDel = (bool) => ({ type: 'SET_SENSORS_BUNDLE_MODAL_DEL', payload: bool });
export const clearSensorsEnabled = () => ({ type: 'CLEAR_SENSORS_ENABLED' });
export const setSensorsSorting = (str) => ({ type: 'SET_SENSORS_SORTING', payload: str });
export const setSensorsSummaryModal = (bool) => ({ type: 'SET_SENSOR_SUMMARY_MODAL', payload: bool });
