import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {storageClass} from "../../redux/slices/storage";
import {enabledEditScore} from "../practicum/lw_utils";
import {notebookInfoApi} from "../../api/api";
import {printOnlyDate} from "../../helpers/text";
import {isAuth} from "../../helpers/auth";
import ModalConfirmDialog from "../ui/ModalDialogs/ModalConfirmDialog";
import Table from "../ui/Table/Table";
import {getTableScoreList} from '../ui/utils/score_utils';
import {updateNotebookInfoScoreById, getNotebookById, getAllNotebookInfoByOwner} from "../Notebook/nb_utils";
import NotebooksViewtResultByTeacherDlg from '../Notebook/NotebooksViewtResultByTeacherDlg';

const ProfileNotebooksTable = ({profile, setScores}) => {
	const [tableData, setTableData] = useState([]);
	const [scoreUpdated, setScoreUpdated] = useState(false);
	const [canEditScore, setCanEditScore] = useState(false);
	const [showNbiResultDlg, setShowNbiResultDlg] = useState(false);
	const [selectedIds, setSelectedIds] = useState('');
	const [idBeDeleted, setIdBeDeleted] = useState(undefined);
	const [deletionConfirmed, setDeletionConfirmed] = useState(false);
    const [showConfirmDlg, setShowConfirmDlg] = useState(false);

	const documentClass = useSelector(storageClass);

	useEffect(() => {
		setCanEditScore(enabledEditScore(isAuth(), documentClass.classUsers));
	},[documentClass.classUsers]);

    useEffect(() => {
        const fetchData = async () => {
            const result = await getAllNotebookInfoByOwner(profile._id);

			const list = [];
			let scores = [0, 0, 0, 0, 0];

			if (result && result.data.length > 0) {
				const nbis = result.data;

				for (let i = 0; i < nbis.length; i ++) {
					const nbi = nbis[i];
					const notebookId = nbi.notebook;
					const notebookData = await getNotebookById(notebookId);
					const correctAnswerNumber = nbi.tasks.filter(item => item.isCorrect === 1).length;
					const totalNumber = nbi.tasks.length;
					const answerNumber = nbi.tasks.filter(item => item.isCorrect !== -1).length;
					const isComplete = nbi.isComplete;
					const execution = answerNumber === 0 ? 'Не приступал' : !isComplete ? 'Не завершено' : 'Сдано досрочно';
	
					const row = {
						titleLink: notebookData.titles.homeWorkName,
						date: nbi.startDate ? printOnlyDate(nbi.startDate) : '',
						solvedTasks: '' + correctAnswerNumber + ' из ' + totalNumber,
						execution: execution,
						score: nbi.score,
						actions: '',
						id: nbi.owner + '|' + nbi._id 
					};
	
					list.push(row);
					scores = getTableScoreList(scores, nbi.score);
				}
            } 

			setScores(scores);
			setTableData(list);
			if (scoreUpdated) setScoreUpdated(false);
		}

		fetchData();
    },[documentClass._id, profile._id, scoreUpdated, setScores, canEditScore, showNbiResultDlg]);

	const getNotebookInfoId = ids => ids.split('|')[1];

	const handleStudentTableDataUpdate = async (score, ids) => { //ids = owner_id + '|' + nbi_id
		const notebookInfoId = getNotebookInfoId(ids); //ids.split('|')[1];
		const scoreVal = score !== '' ? Number(score) : null;
		await updateNotebookInfoScoreById(notebookInfoId, {score: scoreVal}); //set score
		setScoreUpdated(true);
	};

	const handleShowDetails = ids => {
        setSelectedIds(ids);
        setShowNbiResultDlg(true);
	};

	useEffect(() => {
		if (!deletionConfirmed) return;
		notebookInfoApi.deleteNotebookInfoById(getNotebookInfoId(idBeDeleted))
		.then(res => {
			setDeletionConfirmed(false);
			setIdBeDeleted(undefined);
			setScoreUpdated(true);
		});
	}, [deletionConfirmed, idBeDeleted]);
	const handleDelete = id => {
		setIdBeDeleted(id);
		setShowConfirmDlg(true);
	};
	const handleDeleteNo = () => {
		setShowConfirmDlg(false);
		setIdBeDeleted(undefined);
	};
	const handleDeleteYes = () => {
		setShowConfirmDlg(false);
		setDeletionConfirmed(true);
	};

	const canDeleteIDs = () => {
		if (isAuth().role === 0) return [];
		return tableData.map(item=> item.id);
	};

	const getTableHeader = () => {
		if (isAuth().role === 0)
			return [ 
				{column: 'Название работы', name: 'titleLink', style: { width: '30%'} }, 
				{column: 'Решенные задачи', name: 'solvedTasks', style: { width: '20%'} }, 
				{column: 'Выполнение', name: 'execution', style: { width: '15%'} }, 
				{column: 'Дата', name: 'date', style: { width: '20%'} }, 
				{column: 'Оценка учителя', name: 'score', style: { width: '15%'} }, 
				{column: 'id', name: 'id', style: { width: '0%'} }, 
			];

		return [ 
			{column: 'Название работы', name: 'titleLink', style: { width: '20%'} }, 
			{column: 'Решенные задачи', name: 'solvedTasks', style: { width: '15%'} }, 
			{column: 'Выполнение', name: 'execution', style: { width: '15%'} }, 
			{column: 'Дата', name: 'date', style: { width: '15%'} }, 
			{column: 'Оценка учителя', name: 'score', style: { width: '15%'} }, 
			{column: 'Действия', name: 'actions', style: { width: '20%'} }, 
			{column: 'id', name: 'id', style: { width: '0%'} }, 
		];

	};

    return ( 
		<div>
			<Table 
				table={{
					header: getTableHeader(), 
					data: tableData, 
					handleTableDataUpdate: handleStudentTableDataUpdate, 
				}}
				sort={{
					hasSorting: true, 
					initSortInd: -4,
				}}
				link={{
					handleLink: id => handleShowDetails(id),
				}}
				actions={{
					handleDelete: ids => handleDelete(ids),
					canDeleteIDs: canDeleteIDs(),
				}}
				canEditScore={canEditScore}
			/>

			{showNbiResultDlg &&
			<NotebooksViewtResultByTeacherDlg
				showDlg={showNbiResultDlg}
				setShowDlg={setShowNbiResultDlg}
				selectedIds={selectedIds}
				setScoreUpdated={setScoreUpdated}
			/>
			}
			{showConfirmDlg &&
			<ModalConfirmDialog
				showConfirmDlg={showConfirmDlg} 
				handleNo={handleDeleteNo}
				handleYes={handleDeleteYes}
				question={'Вы действительно хотите удалить эту работу?'}
			/>
			}
		</div>
    )
}

export default ProfileNotebooksTable;
