import React, { useState } from 'react';
import { Modal, Button, InputNumber } from 'rlabui';
import ss from './TimerModal.module.scss';

const TimerModal = ({ setStopwatch, modal, toggleTimerModal }) => {
    const [hours, setHours] = useState(0);
    const [minutes, setMinute] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const clearStopwatch = () => {
        toggleTimerModal(false);
        setStopwatch(0);
        setHours(0);
        setMinute(0);
        setSeconds(0);
    };

    const setModalContent = () => (
        <div className={ss.root}>
            <div className={ss.item}>
                <div className={ss.label}>Часы</div>
                <InputNumber
                    type="number"
                    step={1}
                    min="0"
                    max="24"
                    value={hours < 10 ? '0' + hours : hours}
                    onChange={() => setHours(hours)}
                    plus={() => setHours((hours) => (hours < 24 ? ++hours : 0))}
                    minus={() => setHours((hours) => (hours > 0 ? --hours : 24))}
                />
            </div>
            <div className={ss.item}>
                <div className={ss.label}>Минуты</div>
                <InputNumber
                    type="number"
                    step={1}
                    min="0"
                    max="60"
                    value={minutes < 10 ? '0' + minutes : minutes}
                    onChange={() => setMinute(minutes)}
                    plus={() => setMinute((minutes) => (minutes < 60 ? ++minutes : 0))}
                    minus={() => setMinute((minutes) => (minutes > 0 ? --minutes : 0))}
                />
            </div>
            <div className={ss.item}>
                <div className={ss.label}>Секунды</div>
                <InputNumber
                    type="number"
                    step={1}
                    min="0"
                    max="60"
                    value={seconds < 10 ? '0' + seconds : seconds}
                    onChange={() => setSeconds(seconds)}
                    plus={() => setSeconds((seconds) => (seconds < 60 ? ++seconds : 0))}
                    minus={() => setSeconds((seconds) => (seconds > 0 ? --seconds : 0))}
                />
            </div>
        </div>
    );

    return (
        <Modal
            visible={modal}
            size={'xs'}
        >
            <Modal.Head
                title="Таймер"
                modalClose={clearStopwatch}
            ></Modal.Head>
            <Modal.Body>{setModalContent()}</Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={() => {
                        let time = 0;
                        if (hours) {
                            time += hours * 3600000;
                        }
                        if (minutes) {
                            time += minutes * 60000;
                        }
                        if (seconds) {
                            time += seconds * 1000;
                        }
                        setStopwatch(time);
                        toggleTimerModal(false);
                    }}
                >
                    Установить
                </Button>
                <Button
                    border={true}
                    onClick={() => clearStopwatch()}
                >
                    Отмена
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default TimerModal;
