import { createSlice } from "@reduxjs/toolkit";
import { classApi } from "../../api/api";

let initialState = {
  class: [],
  users: [],
  orgs: [],
  errors: "",
};

const classlice = createSlice({
  name: "class",
  initialState,
  reducers: {
    getClasss: (state, action) => {
      state.class = action.payload;
    },
    getUsers: (state, action) => {
      state.users = action.payload;
    },
    getOrgs: (state, action) => {
      state.orgs = action.payload;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
  },
});
export const fetchclass = (iduser) => async (dispatch) => {
  if (!iduser) return;
  const res = classApi.getclassByOrg(iduser);
  res.then((data) => {
    dispatch(getClasss(data));
  });
};

export const fetchUsersByOrg = (id) => async (dispatch) => {
  if (!id) return;
  const res = classApi.getUsersByOrg(id);
  res.then((data) => {
    dispatch(getUsers(data));
  });
};

export const fetchOrgs = () => async (dispatch) => {
  const res = classApi.getOrgsAll();
  res.then((data) => {
    dispatch(getOrgs(data));
  });
};

export const selectclass = (state) => {
  return [state.classs.class, state.classs.errors];
};
export const selectusers = (state) => {
  return [state.classs.users, state.classs.errors];
};
export const selectorgs = (state) => {
  return [state.classs.orgs, state.classs.errors];
};

export const {
  getClasss,
  getUsers,
  getOrgs,
} = classlice.actions;
export default classlice.reducer;
