import React, {useEffect} from "react";
import socket from "../../../socket";
import Footer from '../Footer';
import VideoGrid from "../../VideoGrid";
import DashboardFooter from "./DashboardFooter";
import "./Dashboard.scss";
import {useSelector} from "react-redux";
import { Content, ContentBody, ContentFooter, ContentWrap } from "../ContentParts";

const sizeList = {
  'videoGrid_sm' : [ 'маленький', 'grid_sm' ],
  '' : ['средний', 'grid' ],
  'videoGrid_md' : [ 'большой', 'grid_lg' ],
  'videoGrid_lg' : [ 'огромный', 'grid_xl' ],
  'videoGrid_tile' : ['плитка', 'tile' ],
}

export const DashboardVideo = (props) => {
  const {cactions: {changeClass}} = useSelector(state => state);

  useEffect(() => {
    if (changeClass.to) {
      socket.emit("BE-join-video", {roomId: changeClass.to});
    }
  }, [changeClass]);

  return (
    <ContentWrap>
      <Content>
        <ContentBody><VideoGrid {...props}/></ContentBody>
        <ContentFooter className="videoGridFooter">
          <DashboardFooter control="video" sizeList={sizeList}/>
        </ContentFooter>
      </Content>
      <Footer {...props}/>
    </ContentWrap>
  );
}
export default DashboardVideo