import XMLParser from 'react-xml-parser';
import { setAllDevices } from '../actions/device';

// const devicesFile = process.env.REACT_APP_API_URL + '/files/neuro2.xml';
const devicesFile = process.env.PUBLIC_URL + '/neuro.xml';

export const getDevicesList = () => async (dispatch) => {
    await fetch(devicesFile)
        .then((res) => res.text())
        .then((data) => {
            const xml = new XMLParser().parseFromString(data);
            const arr = xml.getElementsByTagName('device');
            arr.forEach((item) => {
                let single = false;

                if (!item.children.length || item.children[0].name !== 'channels') {
                    single = true;
                }

                item.single = single;
                item.hightFreq = item.attributes.hightFreq;
            });

            dispatch(setAllDevices(arr));
        })
        .catch(() => {});
};
