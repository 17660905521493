import React, {useState, useEffect, useRef} from "react";
import {useSelector, useDispatch} from "react-redux";
import {isAuth, fullName} from "../../helpers/auth";
import {storageClass, storageOrg} from "../../redux/slices/storage";
import {fetchclass} from "../../redux/slices/classsline";
import Table from "../ui/Table/Table";
import {FEATURES} from "../../api/config";
import {getClassStudents, initializeTimeCounter} from "../ui/utils/gen_utils";
import {getNotebookById, getAllOwnersNotebookInfoByNotebookId, updateNotebookInfoScoreById} from "./nb_utils";
import {PRACTICUM_SECTION}  from "../template/Dashboard/DashboardProfile";
import {printOnlyDate} from "../../helpers/text";
import NotebooksViewtResultByTeacherDlg from './NotebooksViewtResultByTeacherDlg';
import { ContentBody, ContentHead, Content, Back } from "../template/ContentParts";
import "../template/Dashboard/Dashboard.scss";
import nb from "./Notebooks.module.scss";

const SPEC_TEXT = {
    NOT_EXECUTED: 'Не приступал',
    IN_PROGRESS: 'Не завершено',
    EXECUTED: 'Выполнено',
};

const NotebookReviewSelectedResult = ({history, notebookId}) => {
    const [notebookData, setNotebookData] = useState(undefined);
	const [notebookSortedTableData, setNotebookSortedTableData] = useState([]);
	const [scoreUpdated, setScoreUpdated] = useState(false);
	const [showNbiResultDlg, setShowNbiResultDlg] = useState(false);
	const [selectedIds, setSelectedIds] = useState('');
    const [currTestTime, setCurrTestTime] = useState(undefined);
	const timerIdRef = useRef();

    const documentOrg = useSelector(storageOrg);
	const documentClass = useSelector(storageClass);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchclass(isAuth()._id));
    }, [dispatch, documentOrg._id]);

	useEffect(() => {
		timerIdRef.current = initializeTimeCounter(3, setCurrTestTime); //refresh each sec
		return () => {clearInterval(timerIdRef.current);};
    }, []);

	useEffect(() => {
        const fetchData = async () => {
            const nb = await getNotebookById(notebookId);
            setNotebookData(nb);

            const samples = nb.taskSamples;
            let totalNumber = 0;
            for (let i = 0; i < samples.length; i ++) 
                totalNumber += samples[i].groupAmount;

            const result = await getAllOwnersNotebookInfoByNotebookId(notebookId);
            const classStudents = getClassStudents(documentClass.classUsers);

            const list = [];
            for (let i = 0; i < result.data.length; i ++) {
                const nbi = result.data[i];
                const student = classStudents.find(item => item.owner === nbi.owner);
                
                if (student) {
                    const todayDate = new Date();
                    const startDate = new Date(nb.run.runFullDate);
                    const finishDate = new Date(nb.run.completeFullDate);
                    const status = todayDate < finishDate ? 'Активная' : 'Завершенная';
                    const answerNumber = nbi.tasks.filter(item => item.isCorrect !== -1).length;
                    const correctAnswerNumber = nbi.tasks.filter(item => item.isCorrect === 1).length;
                    const execution = answerNumber === 0 ? SPEC_TEXT.NOT_EXECUTED : 
                        !nbi.isComplete ? SPEC_TEXT.IN_PROGRESS : SPEC_TEXT.EXECUTED;

                    const record = {
                        name: fullName(student), 
                        status: status,
                        dateStart: printOnlyDate(startDate),
                        dateFinish: printOnlyDate(finishDate),
                        answeredTasks: '' + answerNumber + ' из ' + totalNumber,
                        solvedTasks: '' + correctAnswerNumber + ' из ' + totalNumber,
                        execution: execution,
                        score: nbi.score,
                        actions: '',
                        id: nbi.owner + '|' + nbi._id,
                        personPict: student.personPict
                    };

                    list.push(record);
                }
            }

			setNotebookSortedTableData(list);
            if (scoreUpdated)
                setScoreUpdated(false);
        }

		fetchData();
	},[notebookId, documentClass.classUsers, scoreUpdated]);

	const handlePerson = idArr => {
		history.push(FEATURES.profile.to + '/' + getPersonId(idArr) + '/' + PRACTICUM_SECTION.NOTEBOOK);
	};

	const handleShowDetails = ids => {
        setSelectedIds(ids);
        setShowNbiResultDlg(true);
	};

    const getPersonId = (idArr) => idArr.split('|')[0];
	const getNotebookInfoId = (idArr) => idArr.split('|')[1];

	const handleScoreUpdate = (value, idArr) => {
        const doUpdateScore = async  () => {
            const notebookInfoId = getNotebookInfoId(idArr);
            const score = value !== '' ? Number(value) : null;
            await updateNotebookInfoScoreById(notebookInfoId, {score: score});
			setScoreUpdated(true);
        };
        doUpdateScore();
	};

    const specialCellStyle = (item, rowInd, colInd, row) => {
        if (item === SPEC_TEXT.NOT_EXECUTED) return {color: 'red'};
        if (item === SPEC_TEXT.IN_PROGRESS) return {color: '#C96203'};
        if (item === SPEC_TEXT.EXECUTED) return {color: 'green'};
        return {};
    };

    const getTableHeader = () => {
		return [
    			{column: 'ФИО ученика', name: 'name', style: { width: '16%'} }, 
	    		{column: 'Статус', name: 'status', style: { width: '10%'} }, 
		    	{column: 'Дата начала', name: 'dateStart', style: { width: '10%'} }, 
			    {column: 'Дата окончания', name: 'dateFinish', style: { width: '10%'} }, 
    			{column: 'Отвеченные задачи', name: 'answeredTasks', style: { width: '10%'} }, 
    			{column: 'Решенные задачи', name: 'solvedTasks', style: { width: '10%'} }, 
	    		{column: 'Выполнение', name: 'execution', style: { width: '11%'} }, 
		    	{column: 'Оценка', name: 'score', style: { width: '7%'} }, 
			    {column: 'Действия', name: 'actions', style: { width: '16%'} }, 
			    {column: 'id', name: 'id', style: { width: '0%'} }, 
            ];
	};

    const getHomeWorkTable = () => {
		return (
		<div className={nb.nb__table}>
			<Table 
				table={{
                    header: getTableHeader(), 
					data: notebookSortedTableData,
                    handleTableDataUpdate: handleScoreUpdate, 
                    specialCellStyle: specialCellStyle
				}}
				sort={{
                    hasSorting: true, 
                    initSortInd: 1,
                }}
				person={{handlePerson: handlePerson}}
				actions={{
					handleShowDetails: id => handleShowDetails(id),
					textShowDetails: 'Смотреть результат',
				}}
				canEditScore={() => true}
			/>
		</div>
		);
    };

    return  (
        <>
            <ContentHead flex={false}>
                <Back onClick={()=> history.goBack()} text="Вернуться ко всем заданиям" icon="back" margin="bottom" />
                <div className="cor_content__title">Просмотр результатов задания {notebookData?.titles?.homeWorkName}</div>
            </ContentHead>
            
            <Content>
                <ContentBody>
                    {getHomeWorkTable()}
                </ContentBody>
            </Content>
  
            {showNbiResultDlg && 
			<NotebooksViewtResultByTeacherDlg
				showDlg={showNbiResultDlg}
				setShowDlg={setShowNbiResultDlg}
				selectedIds={selectedIds}
			    setScoreUpdated={setScoreUpdated}
			/>
            }
        </>
    );

}

export default NotebookReviewSelectedResult;
