import React, {useState, useEffect} from 'react';
import Modal from "../../Components/Modal";
import { Button } from '../ui';
import {toast} from "react-toastify";
import Table from "../ui/Table/Table";

const PISelectGraphDlg = ({showDlg, setShowDlg, workPlots, tableUid, handleSelectGraph}) => {
	const [investObjects, setInvestObjects] = useState([]);

    useEffect(() => {
        if (!workPlots) return;
        const list = workPlots.map(item => ({name: item.title, id: item.uid + '|0'}));
        list.unshift({name: 'Новый график', id: '-1|0'});
        setInvestObjects(list);
    }, [workPlots]);

    const handleSwitchRow = (selectedId) => {
		const list = [];
        const selId = selectedId.split('|')[0];

		for (let i = 0; i < investObjects.length; i ++) {
			let obj = investObjects[i];
            let obj_ids = obj.id.split('|');
            const currId = obj_ids[0];
            obj.id = currId + (currId === selId ? '|1' : '|0');
			list.push(obj);
		}

		setInvestObjects(list);
    };

    const getTable = () => {
		const header = [
			{column: 'Название графика', name: 'name', style: { width: '80%'} }, 
			{column: 'Действия', name: 'actions', style: { width: '20%'} }, 
			{column: 'id', name: 'id', style: { width: '0%'} }, 
        ];

        return (
            <Table 
                className="pi__objectList"
                table={{
                    header: header, 
                    data: investObjects
                }}
                actions={{
                    handleSwitch: ids => handleSwitchRow(ids),
                }}
            />
        );        
    };

    const handleSave = () => {
        const saveIds = [];
		for (let i = 0; i < investObjects.length; i ++) {
            const ids = investObjects[i].id;
			const arr = ids.split('|');
            if (arr[1] === '1')
                saveIds.push(arr[0]);
		}

        if (saveIds.length === 0)
            toast.error('Выберите график.');
        else {
            handleSelectGraph(Number(tableUid), Number(saveIds[0]));
            setShowDlg(false);
        }
    };
    
    const setModalContent = () => {
        return (
            <>
                <div className="modal__close" onClick={() => setShowDlg(false)}></div>
                <div className="modal__message">
                    {'Выберите график'}
                </div>
                <div className="modal__body has_scrolled">
                    <div className="modal__scrolled">
                        {getTable()}
                    </div>
                </div>
                <div className="modal__action">
                    <Button color="primary" border={false} onClick={() => handleSave()}>Выбрать</Button>
                    <Button color="primary" border={true} onClick={() => setShowDlg(false)}>Отменить</Button>
                </div>
            </>
        )
    }

    if (!showDlg) {
        return false;
    }
    
    return (
        <Modal visible={showDlg} content={setModalContent()} size={'lg'} />
    )
};

export default PISelectGraphDlg;
