import { getSerialPortsList, serialPortsDisconnect } from '../../commands/port';
import { btConnect } from '../../commands/bluetooth';
import { setPortOpen } from '../../actions/port';

export const mapStateToProps = (nstate) => {
    return {
        played: nstate.napp.played,
        port: nstate.nport.open,
        connectionType: nstate.nport.connectionType,
        sensorsList: nstate.nsensor.list,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setPortOpen: (bool) => dispatch(setPortOpen(bool)),
    serialPortsDisconnect: (port) => dispatch(serialPortsDisconnect(port)),
    btConnect: (port) => dispatch(btConnect(port)),
    getSerialPortsList: (number) => dispatch(getSerialPortsList(number)),
});
