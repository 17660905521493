import React, {useEffect} from 'react';
import Modal from '../Modal';
import socket from "../../socket";
import {FEATURES} from "../../api/config";
import {fullName} from "../../helpers/auth";

const RlabNotification = (props) => {

    useEffect(() => {
        socket.on("FE-sensor-start", ({owner}) => {
            if (owner && !(FEATURES.rlab.alt.slice(0,2).includes(props.match?.path))) {
                props.showRlabNotification({showModal: true, owner});
            }
        });

        return () => {
            socket.off("FE-sensor-start");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    function navigateRlab() {
        props.history.push(FEATURES.rlab.alt[0]);
        handleClose();
    }

    const handleClose = () => {
        props.showRlabNotification({showModal: false})
    };

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) handleClose();
        };
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Modal visible={props.showModal} content={
            <>
                <div className="modal__close" onClick={handleClose}/>
                <div className="modal__message">{fullName(props.owner)} начал трансляцию датчиков</div>
                <div className="modal__action">
                    <button className="cor_btn cor_btn_primary" onClick={() => navigateRlab()}>
                        Перейти в Цифровую Лабораторию
                    </button>
                </div>
            </>
        }/>
    )
};

export default RlabNotification;