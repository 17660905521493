import { createSlice } from "@reduxjs/toolkit";
import {deviceApi} from "../../api/api";

let initialState = {
  devices: [],
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setDevices: (state, action) => {
      state.devices = action.payload;
    },
  },
});

export const loadDevices = () => async (dispatch) => {
  deviceApi.get().then((data) => dispatch(setDevices(data)));
};

export const {
  setDevices,
} = adminSlice.actions;
export default adminSlice.reducer;
