import React, { useEffect, useState } from 'react';
import ss from '../AnalogDevice.module.scss';

const Lamp = ({ props }) => {
    const { theme, color, size, value, max, min, num } = props;
    const [brightness, setBrigtness] = useState(0);
    useEffect(() => {
        // console.log(value);
        if (typeof value === 'number') {
            const interval = max - min;
            const coeff = value - min;
            const perc = (coeff * 100) / interval;
            setBrigtness(perc > 100 ? 1 : perc < 0 ? 0 : perc / 100);
        } else {
            setBrigtness(0);
        }
    }, [min, max, value]);

    return (
        <div
            className={ss.lamp}
            style={{ height: size * 0.92, width: size * 0.6 }}
        >
            <svg
                viewBox="-5 -5 127 193"
                width={size * 0.6}
                height={size * 0.95}
            >
                <path
                    d="M110.274 89.05C109.311 90.7379 108.324 92.436 107.33 94.1451C103.847 100.136 100.286 106.261 97.3946 112.554C93.8084 120.358 91.2092 128.503 91.0212 137.133H29.2051C28.8439 121.308 19.9849 107.145 11.6828 93.8731C11.0689 92.8916 10.458 91.9149 9.85375 90.9428C4.24302 81.916 1 71.2488 1 59.8167C1 27.3281 27.1963 1 59.5 1C91.8037 1 118 27.3281 118 59.8167C118 70.4598 115.189 80.4396 110.274 89.05Z"
                    fill={color}
                    fillOpacity="0.5"
                    stroke={color}
                    strokeWidth="2"
                    filter={'url(#brightness' + num + ')'}
                />
                <path
                    d="M29.2168 136.667H91.0106V175C91.0106 179.971 86.9812 184 82.0106 184H38.2168C33.2462 184 29.2168 179.971 29.2168 175V136.667Z"
                    fill={theme === 'dark' ? '#1E1F25' : '#D7E1E8'}
                    stroke={theme === 'dark' ? '#6B6E80' : '#D7E1E8'}
                    strokeWidth="2"
                />

                <filter id={'brightness' + num}>
                    <feComponentTransfer>
                        <feFuncR
                            type="linear"
                            slope={brightness}
                        ></feFuncR>
                        <feFuncG
                            type="linear"
                            slope={brightness}
                        ></feFuncG>
                        <feFuncB
                            type="linear"
                            slope={brightness}
                        ></feFuncB>
                    </feComponentTransfer>
                    <feDropShadow
                        dx="0"
                        dy="0"
                        stdDeviation="3"
                        floodColor={color}
                        floodOpacity={brightness}
                    />
                </filter>
            </svg>
            <div
                className={ss.lamp__val}
                style={{ fontSize: size * 0.004 + 'em' }}
            >
                <span>{isNaN(brightness) ? 'Err' : (brightness * 100)?.toFixed(1) + '%'}</span>
            </div>
        </div>
    );
};
export default Lamp;
