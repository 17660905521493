import { setPrintModal } from '../../../actions/import';

export const mapStateToProps = (nstate) => {
    return {
        markers: nstate.nchart.marker,
        records: nstate.nsensor.records,
        modal: nstate.nimport.print,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setPrintModal: (bool) => dispatch(setPrintModal(bool)),
});
