import React from 'react';
import TabsItem from './TabsItem';
import "./Tabs.scss";

const Tabs = ({ children, className = '', border = true, }) => {
    let tabClass = 'tabs';
    if (className) {
        tabClass += ' ' + className;
    }
    if (!border) {
        tabClass += ' no_border'
    }
    return (
        <div className={tabClass}>{ children }</div>
    )
};

Tabs.Item = TabsItem;

export default Tabs;
