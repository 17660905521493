import React, {useState} from "react";
import {connect} from "react-redux";
import {mapDispatchToProps, mapStateToProps} from "./redux";
import {DeviceGrid} from "../../Grids";
import {DeviceSettings} from "../../Settings";
import "./Dashboard.scss";
import {Icon} from "../../ui";
import { Content, ContentBody, ContentHead, ContentHeader, ContentWrap } from "../ContentParts";

export const DashboardDevice = (props) => {
    const [search, setSearch] = useState('');

    return (
    <>
        <ContentWrap>
            <Content>
                <ContentHeader>
                    <div className="cor-net__row">
                        <div className="cor-net__col">
                            <div className="cor-net__title mb_0">Устройства</div>
                        </div>
                        <div className="cor-net__col col-icon col-3">
                            <input className="filter" value={search} onChange={(e)=>setSearch(e.target.value)}/>
                            <Icon name="back-round" onClick={()=>setSearch('')}/>
                        </div>
                    </div>
                </ContentHeader>
                <ContentBody>
                    <DeviceGrid {...props} search={search}/>
                </ContentBody>
            </Content>
        </ContentWrap>
        <DeviceSettings/>
    </>);
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDevice)

