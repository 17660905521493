import { setForceUpdate } from '../../../actions/actions';

export const mapStateToProps = (state) => {
    return {
        port: state.port.open,
        theme: state.actions.theme,
        isMobile: state.actions.isMobile,
        mobileView: state.actions.mobileView,
        sensorImported: state.sensors.imported,
        isOscilloscope: state.oscilloscope.isOscilloscope,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setForceUpdate: () => dispatch(setForceUpdate()),
});
