import React from 'react';
import { ReactComponent as OptionsIcon } from '../../../assets/icons/options-icon.svg';
import './OptionsButton.scss';

const OptionsButton = ({handleOpenMenu, isOpenMenu, btnMenuRef}) => {
  return (
      <button ref={btnMenuRef} className="options__button" onClick={handleOpenMenu}>
          <OptionsIcon className={isOpenMenu ? 'options__button_svg open' : 'options__button_svg'} />
        </button>
  );
}

export default OptionsButton;
