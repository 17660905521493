import React from 'react';
import GridCol from './GridCol';
import "./Grid.scss";

const Grid = ({children, columns = null, className = null}) => {
  return (
    <div className={"grid" +
      (columns ? " grid_" + columns : "") +
      (className ? " " + className : "")
    }>{children}</div>
  )
};

Grid.Col = GridCol;

export default Grid;
