import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {storageClass} from "../../redux/slices/storage";
import {printOnlyDate} from "../../helpers/text";
import {initializeTimeCounter} from "../ui/utils/gen_utils";
import {enabledEditScore} from "../practicum/lw_utils";
import {isAuth} from "../../helpers/auth";
import {getTableScoreList} from '../ui/utils/score_utils';
import ViewStudentOnlineTestResultDlg from "../ReviewTest/ViewStudentOnlineTestResultDlg";
import Table from "../ui/Table/Table";
import {onlineTestInfoApi} from "../../api/api";
import {getOnlineTestById, updateOnlineTestInfoScoreById} from "../OnlineTest/ot_utils";

const ProfileOnlineTestsTable = ({profile, setScores}) => {
	const [tableData, setTableData] = useState([]);
	const [iIsUpdated, setIsUpdated] = useState(false);
	const [canEditScore, setCanEditScore] = useState(false);
	const [showOnlineTestResultDlg, setShowOnlineTestResultDlg] = useState(false);
	const [onlineTestId, setOnlineTestId] = useState(undefined);
	const [selectedTestRunId, setSelectedTestRunId] = useState(undefined);
    const [currTestTime, setCurrTestTime] = useState();
	const timerIdRef = useRef(undefined);
	const documentClass = useSelector(storageClass);
	
	useEffect(() => {
		timerIdRef.current = initializeTimeCounter(3, setCurrTestTime);
		return () => {clearInterval(timerIdRef.current);};
    }, []);

	useEffect(() => {
		setCanEditScore(enabledEditScore(isAuth(), documentClass.classUsers));
	},[documentClass.classUsers]);

    useEffect(() => {
		const handleServerData = async (oti) => {
			const onlineTestList = [];
			let scores = [0, 0, 0, 0, 0];

			for (let i = 0; i < oti.length; i ++) {
				const res = oti[i];
				const onlineTestId = res.onlineTest;
				const data = await getOnlineTestById(onlineTestId);
				const answers = res.answers.filter(item => item.isCorrect !== -1).length;
				const correctResultNumber = res.answers.filter(item => item.isCorrect === 1).length;
				const testNumber = res.answers.length;

				if (data) { //avoid orphan records
					onlineTestList.push({
						titleLink: data.title, 
						date: printOnlyDate(res.createdAt), 
						answers: answers +  ' из ' + testNumber, 
						result: correctResultNumber + ' из ' + testNumber, 
						score: res.score,
						id: res.otrun + '|' + res._id + '|' + onlineTestId
					});
	
					scores = getTableScoreList(scores, res.score);
				}
			}

			setScores(scores);
		 	return onlineTestList;
		};

        const fetchData = async () => {
            const result = await onlineTestInfoApi.getAllOnlineTestInfosByOwner(profile._id, documentClass._id);

			const studentTests = await handleServerData(result.data);
			setTableData(studentTests);
			if (iIsUpdated) setIsUpdated(false);
        }

		fetchData();
    },[documentClass._id, profile._id, iIsUpdated, setScores, canEditScore, currTestTime]);

	const getOtRunId = row_id => row_id.split('|')[0];
	const getOtInfoId = row_id => row_id.split('|')[1];
	const getOtId = row_id => row_id.split('|')[2];

	const handleStudentTableDataUpdate = async (score, row_id) => {
		const scoreVal = score !== '' ? Number(score) : null;
		const onlineTestInfoId = getOtInfoId(row_id);
		await updateOnlineTestInfoScoreById(onlineTestInfoId, scoreVal);
		setIsUpdated(true);
	};

	const handleOnlineTestLink = row_id => {
		setShowOnlineTestResultDlg(true);
		setOnlineTestId(getOtId(row_id));
		setSelectedTestRunId(getOtRunId(row_id));
	};

	const getTableHeader = () => {
		return [ 
			{column: 'Название теста', name: 'titleLink', style: { width: '30%'} }, 
			{column: 'Дата', name: 'date', style: { width: '15%'} }, 
			{column: 'Ответы', name: 'answers', style: { width: '20%'} }, 
			{column: 'Правильные ответы', name: 'result', style: { width: '20%'} }, 
			{column: 'Оценка учителя', name: 'score', style: { width: '15%'} }, 
			{column: 'id', name: 'id', style: { width: '0%'} }, 
		];
	};

    return ( 
		<div>
			<Table 
				table={{
					header: getTableHeader(), 
					data: tableData, 
					handleTableDataUpdate: handleStudentTableDataUpdate, 
				}}
				link={{
					handleLink: id => handleOnlineTestLink(id),
				}}
				sort={{
					hasSorting: true, 
					initSortInd: -2,
				}}
				canEditScore={canEditScore}
			/>

			{showOnlineTestResultDlg &&
			<ViewStudentOnlineTestResultDlg 
				showDlg={showOnlineTestResultDlg}
				setShowDlg={setShowOnlineTestResultDlg}
				selectedSudentId={profile._id}
				onlineTestId={onlineTestId}
				selectedTestRunId={selectedTestRunId}
				setIsUpdated={setIsUpdated}
			/>
			}
		</div>
    )
}

export default ProfileOnlineTestsTable;
