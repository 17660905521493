import { useState, cloneElement } from 'react';
import './Menu.scss';

const PopupMenu = ({ menuRef, items, isOpenMenu, setIsOpenMenu, id, activeColor, style, activeCard }) => {

  return (
    <div className={isOpenMenu ? 'menu open' : 'menu'} ref={menuRef} style={style}>
      {Object.keys(items).map((key) => {
        const item = items[key];

        if (item.component) {
          const Component = item.component;
          return cloneElement(<Component key={key}/>, { id, activeColor, setIsOpenMenu, activeCard });
        }

        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
              item.function && item.function();
            }}
            className={item.isDisabled ? 'popup_menu__item disabled' : 'popup_menu__item'}
            key={key}
          >
            {item.icon}
            {item.title && <span className="menu__title">{item.title}</span>}
            {item.additionalIcon && <div>{item.additionalIcon}</div>}
          </div>
        );
      })}
    </div>
  );
};

export default PopupMenu;
