import { setMobileView } from "../../../actions/actions"

export const mapStateToProps = (state) => {
    return {
        port: state.port.open,
        mobileView: state.actions.mobileView,
    }
}

export const mapDispatchToProps = (dispatch) => ({
    setMobileView: (str) => dispatch(setMobileView(str)),
})
