const initialState = {
    theme: 'light',
    played: false,
    paused: false,
    response: '',
    forceUpdate: false,
    modalDisconnect: false,
    isMobile: false,
    mobileView: '',
    modalInfo: false,
    isCor: false,
    needUpdate: false,
    source: { version: '', date: '' },
};

const actionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_THEME':
            return {
                ...state,
                theme: action.payload,
            };

        case 'SET_COR':
            return {
                ...state,
                isCor: action.payload,
            };

        case 'SET_NEED_UPDATE':
            return {
                ...state,
                needUpdate: action.payload,
            };

        case 'SHOW_MODAL_DISCONNECT':
            return {
                ...state,
                modalDisconnect: action.payload,
            };

        case 'SHOW_MODAL_INFO':
            return {
                ...state,
                modalInfo: action.payload,
            };

        case 'SET_FORCE_UPDATE':
            return {
                ...state,
                forceUpdate: action.payload,
            };

        case 'SET_PLAY':
            return {
                ...state,
                played: action.payload,
            };

        case 'SET_PAUSE':
            return {
                ...state,
                paused: action.payload,
            };

        case 'SET_DEVICE_RESPONSE':
            return {
                ...state,
                response: action.payload,
            };

        case 'SET_MOBILE':
            return {
                ...state,
                isMobile: action.payload,
            };

        case 'SET_MOBILE_VIEW':
            return {
                ...state,
                mobileView: action.payload,
            };

        case 'SET_SOURCE':
            return {
                ...state,
                source: action.payload,
            };

        default:
            return state;
    }
};

export default actionsReducer;
