import React, { useState, useEffect, useRef } from 'react';
import Speedometer, { Needle, Marks } from 'react-speedometer';
import ss from './EMGMeter.module.scss';

const tickMin = 0.08;
const tickMax = 0.28;
const tickLen = 20;

const EmgMeter = ({ timer, value, visualized }) => {
    const [show, setShow] = useState(false);
    const [val, setVal] = useState(tickMin);
    const [width, setWidth] = useState(200);
    const [height, setHeight] = useState(200);
    const wrapRef = useRef();

    useEffect(() => {
        const wrap = wrapRef.current;
        if (!wrap) return;
        const observer = new ResizeObserver(updateSize);
        observer.observe(wrap);
        return () => observer.disconnect(); // eslint-disable-next-line
    }, [visualized, wrapRef, show]);

    useEffect(() => {
        updateSize();
    }, [show]);

    useEffect(() => {
        if (visualized === 'EMGMeter') {
            setShow(true);
        } else {
            setShow(false);
        }
    }, [visualized]);

    useEffect(() => {
        if (show) {
            if (value) {
                const maxVal = Math.max(...value?.slice(-50));
                setVal(maxVal || tickMax);
            } else {
                setVal(tickMax);
            }
        }
    }, [timer, value, setVal, show]);

    const updateSize = () => {
        const width = wrapRef?.current?.offsetWidth || null;
        if (width) {
            setWidth(Math.round(width));
            setHeight(Math.round(width));
        }
    };

    return (
        show && (
            <div className={ss.root}>
                <div className={ss.wrap}>
                    <div
                        className={ss.svg}
                        ref={wrapRef}
                    >
                        <Speedometer
                            min={tickMin}
                            max={tickMax}
                            width={width}
                            height={height}
                            value={val}
                            fontFamily="squada-one"
                            accentColor="#00B5FF"
                        >
                            <Needle
                                baseOffset={0}
                                color="#00B5FF"
                                circleColor="transparent"
                            />
                            <Marks
                                step={(tickMax - tickMin) / tickLen}
                                lineColor="#00B5FF"
                                lineSize={width / 15}
                                lineCap="square"
                                fontSize={0}
                            />
                        </Speedometer>
                    </div>
                </div>
                {typeof val === 'number' && <div className={ss.val}>{val <= tickMin ? 0 : val}</div>}
                {typeof val === 'number' && <div className={ss.center}>g</div>}
            </div>
        )
    );
};
export default EmgMeter;
