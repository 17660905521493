import React, {useState, useEffect} from "react";
import { isAuth } from '../../helpers/auth';
import {useSelector} from "react-redux";
import {storageClass} from "../../redux/slices/storage";
import NotebookStudentHomeWorkExecutePages from "./NotebookStudentHomeWorkExecutePages";
import NotebookStudentHomeWorkExecuteAside from "./NotebookStudentHomeWorkExecuteAside";
import { getNotebookInfoData, shuffle, getTaskAnswerOptions } from './task_utils';
import {getNotebookById, getNotebookInfoById, updateNotebookInfoScoreById} from "./nb_utils";
import {SCORE_MANUAL_CHECK, calcScoreValue} from '../ui/utils/score_utils';
import {notebookInfoApi} from "../../api/api";
import Footer from "../template/Footer/Footer";
import { ContentWrap } from "../template/ContentParts";
import "../template/Dashboard/Dashboard.scss";
import "./Notebooks.scss";

const NotebookStudentHomeWorkExecute = (props) => {
	const [notebookData, setNotebookData] = useState(undefined);
    const [notebookInfoId, setNotebookInfoId] = useState(null);
	const [notebookInfoData, setNotebookInfoData] = useState(undefined);
	const [currPage, setCurrPage] = useState(0);
    const [findNonAnsweredTask, setFindNonAnsweredTask] = useState(false);
    const [taskPages, setTaskPages] = useState([]);
    const [timeLimitInMinutes, setTimeLimitInMinutes] = useState(0);
    const [timeLimitEnabled, setTimeLimitEnabled] = useState(false);
    const [actualSpentTime, setActualSpentTime] = useState(0);
    const [workErrorLimit, setWorkErrorLimit] = useState(-1);
    
    const documentClass = useSelector(storageClass);

    //1 - get Notebook and Notebook Info data:
    useEffect(() => {
        const getNotebookData = async (ids) => {
            const [_notebookId, _notebookInfoId] = ids.split('|'); 
    
			const nbData = await getNotebookById(_notebookId);

            let nbiData;
            if (_notebookInfoId) { //reaL test
			    nbiData = await getNotebookInfoById(_notebookInfoId);
            } else { //teacher preview
                nbData.settings.isGenerateUniqueTasks = true;
                nbiData = getNotebookInfoData(_notebookId, nbData, null, documentClass._id, isAuth()._id);
            }

            setNotebookData(nbData);
            setTimeLimitEnabled(nbData.settings.isTimeLimited);
            setTimeLimitInMinutes(nbData.settings.timeLimitMinutes);

            setNotebookInfoId(_notebookInfoId);
			setNotebookInfoData(nbiData);
            setActualSpentTime(nbiData.actualSpentTime);
            setWorkErrorLimit(nbiData.userWorkErrorLimit);
            setFindNonAnsweredTask(true); //request to reorder task pages
        };

        if (!props.ids) return;
        getNotebookData(props.ids);
    }, [documentClass._id, props.ids]);

    const saveNotebookInfoTaskData = async (_notebookInfoId, _workErrorLimit, _tasks) => {
        await notebookInfoApi.updateNotebookInfoUserWorkErrorLimitById(_notebookInfoId, 
            {userWorkErrorLimit: _workErrorLimit});

        await notebookInfoApi.updateNotebookInfoTaskDataById(_notebookInfoId, _tasks);
    };
    const saveNotebookInfoScore = async (_notebookInfoId, score) => {
        await updateNotebookInfoScoreById(_notebookInfoId, {score: score});
    };
    const saveNotebookInfoComplete = async (_notebookInfoId) => { 
        await notebookInfoApi.updateNotebookInfoIsCompleteById(_notebookInfoId); //id (info Id), no body
    };
    
    //2 - form task pages:
    useEffect(() => { //prepare the list of task pages
        if (!notebookInfoData || notebookInfoData.tasks.length === 0) return;

        const _taskPages = [];

        for (let i = 0; i < notebookInfoData.tasks.length; i++) {
            const task = notebookInfoData.tasks[i];

             //ЭТО ОСНОВНОЕ
            _taskPages.push({
                ind: i,
                task: task,
                reorderedOptions: shuffle(getTaskAnswerOptions(task)),
            });
        }

        setTaskPages(_taskPages);
    }, [notebookInfoData, notebookData]);

    //3 - do task reordering and find non-answered page: 
    useEffect(() => { 
        if (taskPages.length === 0 || !findNonAnsweredTask || !notebookData) return;
        setFindNonAnsweredTask(false);

        //2) find a page that is not answered yet.
        let pageIndToAnswer = -1;
        let found = false;
        for (let i = 0; i < taskPages.length && !found; i ++) {
            if (taskPages[i].task.isCorrect === -1) {
                pageIndToAnswer = i;
                found = true;
            }
        }

        pageIndToAnswer = found ? pageIndToAnswer : taskPages.length;
        setCurrPage(pageIndToAnswer);
    }, [taskPages, findNonAnsweredTask, notebookData, notebookInfoData]);

    //4 - save tasks with updated results + score + completion:
    useEffect(() => { //update tasks
        if (!notebookData || !notebookInfoId || taskPages.length === 0) return;
        let tasks = taskPages.map(item => item.task);
        tasks = tasks.sort((a,b) => a.taskInd - b.taskInd);
    
        saveNotebookInfoTaskData(notebookInfoId, workErrorLimit, tasks);

        if (notebookData.settings.isAutoScore) {
            const correctAnswerNumber = tasks.filter(item => item.isCorrect === 1).length;
            const score = calcScoreValue(correctAnswerNumber, tasks.length);
            saveNotebookInfoScore (notebookInfoId, score);
        } else {
            saveNotebookInfoScore (notebookInfoId, SCORE_MANUAL_CHECK);
        }

        if (currPage === taskPages.length) {
            saveNotebookInfoComplete(notebookInfoId);
        }
    }, [taskPages, notebookInfoId, notebookData, currPage, workErrorLimit]);    

    return (
            <>
                <ContentWrap>
                    <NotebookStudentHomeWorkExecutePages {...props} 
                        notebookData={notebookData} 
                        notebookInfoId={notebookInfoId}
                        notebookInfoData={notebookInfoData}
                        taskPages={taskPages}
                        setTaskPages={setTaskPages}
                        currPage={currPage}
                        setCurrPage={setCurrPage}
                        timeLimitEnabled={timeLimitEnabled}
                        timeLimitInMinutes={timeLimitInMinutes}
                        actualSpentTime={actualSpentTime}
                        workErrorLimit={workErrorLimit}
                        setWorkErrorLimit={setWorkErrorLimit}
                        history={props.history}
                    />
                    <Footer {...props} opt={props.opt} />
                </ContentWrap>
       
                {currPage < taskPages.length &&
                <div className="cor_aside nb__aside">
                    <NotebookStudentHomeWorkExecuteAside {...props} 
                        currPage={currPage}
                        taskPages={taskPages}
                        notebookData={notebookData}
                        workErrorLimit={workErrorLimit}
                        history={props.history}
                    />
                </div>
                }
            </>
    );
  
}

export default NotebookStudentHomeWorkExecute;
