import React from 'react';
import "./Input.scss";

const Input = (props) => {
    return (<input
        className={"inputType " + props.className}
        disabled={props.disabled}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        onKeyPress={props.onKeyPress}
        onInput={props.onInput}
        title={props.title}
    />)
};
export default Input;
