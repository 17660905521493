const initialState = {
    data: {},
    open: false,
    command: '',
    connectionType: null,
    connectionLoss: false,
    responses: ['gm', 'gm0', 'gm1', 'gm2', 'gm3', 'sm', 'sm0', 'sm1', 'sm2', 'sm3', 'rcc', 'wcc', 'tgv', 'rgt', 'wgt', 'cgt', 'rlg', 'wlg', 'acc', 'acq', 'inf'],
};

const portReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_PORT':
            return {
                ...state,
                data: action.payload,
            };
        case 'TOGGLE_PORT':
            return {
                ...state,
                open: action.payload,
            };
        case 'SEND_COMMAND':
            return {
                ...state,
                command: action.payload,
            };
        case 'SET_CONNECTION_TYPE':
            return {
                ...state,
                connectionType: action.payload,
            };
        case 'SET_CONNECTION_LOSS':
            return {
                ...state,
                connectionLoss: action.payload,
            };
        default:
            return state;
    }
};

export default portReducer;
