import React, {useState, useEffect} from "react";
import { Back, Content, ContentBody, ContentFooter, ContentHead, ContentHeader, ContentTitle } from "../template/ContentParts";
import { Tabs } from '../ui';
import { Button } from 'rlabui';
import { ATTACH_TYPE, SCENARIO_STEP, deleteRlsFileByName, getScenarioTabs, fillScenarioData } from './sc_utils';
import ScenarioPage1Description from './ScenarioPage1Description';
import ScenarioPage2Items from './ScenarioPage2Items';
import ScenarioPage3Questions from './ScenarioPage3Questions';
import ScenarioPage4Graphs from './ScenarioPage4Graphs';
import ScenarioPage5Composition from './ScenarioPage5Composition';
import {rlsApi} from "../../api/api";
import {useSelector, useDispatch} from "react-redux";
import {toast} from "react-toastify";
import {storageClass} from "../../redux/slices/storage";
import {isAuth} from "../../helpers/auth";
import "../template/Dashboard/Dashboard.scss";

const ScenarioOne = (props) => {
    const {history, devices, sensor, port} = props; //usbConnectReconnect, btConnect, connectionType, 
    const [scenarioId, setScenarioId] = useState(undefined);
    const [tabInd, setTabInd] = useState(SCENARIO_STEP.DESC);
    const [isTemplate, setIsTemplate] = useState(false);
    const [scName, setScName] = useState('');
    const [scDescription, setScDescription] = useState('');
    const [scSensorId, setScSensorId] = useState('');
    const [scSensorName, setScSensorName] = useState('');
    const [scQuestions, setScQuestions] = useState([]);
    const [scItems, setScItems] = useState([]);
    const [scGraph, setScGraph] = useState({json: '', graphName: ''});
    const [scComposition, setScComposition] = useState({
        name: '', description: '', attachmentType: ATTACH_TYPE.EMPTY, attachmentName: ''});
    const [objVersion, setObjVersion] = useState(undefined);
    const [images, setImages] = useState([]);
    const [isUpdate, setIsUpdate] = useState(false);
    const [canClose, setCanClose] = useState(false);

    const documentClass = useSelector(storageClass);
    const dispatch = useDispatch();

    useEffect(() => {
        setScenarioId(props.match.params.id ? props.match.params.id : undefined);
    }, [props.match.params.id]);

    useEffect(() => {
        if (canClose && !scSensorName) {
            setCanClose(false);
            history.goBack();
        }
    }, [canClose, scSensorName, history]);

    const handleSelectTab = (ind) => {
        setTabInd(ind);
    };

	useEffect(() => {
		const loadScenarioData = async (_scenarioId) => {
			const result = await rlsApi.getScenarioById(_scenarioId);
            console.log('scenario = ', result);
			if (result) {
                setIsTemplate(!result.owner);
                setScName(result.name);
                setScDescription(result.description);
                setScSensorId(result.sensorId !== '' ? result.sensorId : '');
                setScSensorName(result.sensorName !== '' ? result.sensorName : '');
                setScQuestions(result.questions);
                setScItems(result.items);
                setScGraph(result.graph);
                setScComposition(result.composition);
                setObjVersion(result.__v);

                const f = result.items.filter(item => item.attachmentName)
                    .map(item => ({name: item.attachmentName, isNew: false, isAdded: false, isDeleted: false}));
                if (result.composition?.attachmentType === ATTACH_TYPE.IMAGE && result.composition?.attachmentName)
                    f.push({name: result.composition?.attachmentName, 
                        isNew: false, isAdded: false, isDeleted: false});
                    setImages(f);
                if (isUpdate) setIsUpdate(false);
			}
		};

        if (scenarioId) {
            loadScenarioData(scenarioId);
        }
	},[scenarioId, isUpdate]);

    const addScenario = async (scenario) => {
        rlsApi.addScenario(scenario)
        .then((result)=>{
            setScenarioId(result._id);
            setObjVersion(result.__v);
            toast.success("Сценарий '" + scName + "' сохранен.");
            if (tabInd === SCENARIO_STEP.COMPOSITION) 
                history.goBack();
            else 
                setIsUpdate(true);
        }).catch((err) => {
            toast.error(err.response.data.errors);
        });
    };

    const updateScenario = async (_scenarioId, scenario) => {
        rlsApi.updateScenarioById(_scenarioId, scenario)
        .then((result)=>{
            setObjVersion(result.__v);
            toast.success("Сценарий '" + scName + "' сохранен.");
            if (tabInd === SCENARIO_STEP.COMPOSITION) 
                history.goBack();
            else 
                setIsUpdate(true);
        }).catch((err) => {
            toast.error(err.response.data.errors);
        });
    };

    const checkItemName = () => {
        const emptyNameItem = scItems.find(item => item.name.trim() === '');
        if (emptyNameItem) {
            const itemIndex = scItems.indexOf(emptyNameItem);
            toast.warn("Введите название пункта " + (itemIndex+1));
            return false;
        }
        return true;
    };

    const handleBack = () => {
        for (const item of images) {
            if (item.isAdded)
                deleteRlsFileByName(dispatch, item.name);
        }
        setScenarioId(undefined);
        setScName('');
        setScSensorId('');
        setScSensorName('');
        setCanClose(true);
    };

    const handleSave = () => {
        if (isTemplate && isAuth().role < 3) {
            history.goBack();
            return;
        }

        if (scName.trim() === '') {
            toast.warn("Введите название сценария");
            return;
        }
        if (!checkItemName()) return;

        const scenario = fillScenarioData(documentClass, scName, scDescription, 
            scSensorId, scSensorName, scQuestions, scItems, scGraph, scComposition);

        for (const item of images) {
            if (item.isDeleted)
                deleteRlsFileByName(dispatch, item.name);
        }
        
        if (!scenarioId) {
            if (!scenario.sensorName) 
                toast.warn("Подключите датчик.");
            else 
                addScenario(scenario);
        } else {
            scenario.__v = objVersion;
            updateScenario(scenarioId, scenario);
        }
    };

    return  (
        <>
            <ContentHead flex={false}>
                <Back onClick={() => handleBack()} icon="back" margin="bottom"/>
                <ContentTitle>Создание сценариев</ContentTitle>
            </ContentHead>
            <Content background={false} className="lr-p0">
                <ContentHeader>
                    <Tabs>
                        {getScenarioTabs().map(tab => (
                            <Tabs.Item active={tabInd === tab.tabId} onClick={() => handleSelectTab(tab.tabId)} 
                                key={'tab'+ tab.tabId}>
                                {tab.tabName}
                            </Tabs.Item>)
                        )}
                    </Tabs>
                </ContentHeader>
        
                <ContentBody>
                    {tabInd === SCENARIO_STEP.DESC &&
                        <ScenarioPage1Description
                            scenarioId={scenarioId}
                            isTemplate={isTemplate}
                            scName={scName}
                            setScName={setScName}
                            scDescription={scDescription}
                            setScDescription={setScDescription}
                            scSensorId={scSensorId }
                            setScSensorId={setScSensorId}
                            scSensorName={scSensorName }
                            setScSensorName={setScSensorName}
                            devices={devices}
                            sensor={sensor}
                            port={port}
                        />
                    }

                    {tabInd === SCENARIO_STEP.ITEMS &&
                        <ScenarioPage2Items
                            isTemplate={isTemplate}
                            scItems={scItems}
                            setScItems={setScItems}
                            checkItemName={checkItemName}
                            images={images} 
                            setImages={setImages}
                        />
                    }

                    {tabInd === SCENARIO_STEP.QUESTIONS &&
                        <ScenarioPage3Questions
                            isTemplate={isTemplate}
                            scQuestions={scQuestions}
                            setScQuestions={setScQuestions}
                        />
                    }

                    {tabInd === SCENARIO_STEP.GRAPHS &&
                        <ScenarioPage4Graphs  
                            isTemplate={isTemplate}
                            scGraph={scGraph}
                            setScGraph={setScGraph}
                        />
                    }
                    
                    {tabInd === SCENARIO_STEP.COMPOSITION &&
                        <ScenarioPage5Composition 
                            isTemplate={isTemplate}
                            scComposition={scComposition}
                            setScComposition={setScComposition}
                            images={images} 
                            setImages={setImages}
                        />
                    }
                </ContentBody>

                <ContentFooter className="jc-end">
                    <Button color="primary" onClick={() => handleSave()}>
                        {isTemplate && isAuth().role < 3 ? 
                            'Выйти'
                        : 
                            'Сохранить сценарий' + (tabInd === SCENARIO_STEP.COMPOSITION ? ' и выйти' : '')
                        }
                    </Button>
                </ContentFooter>
            </Content>
        </>
    );
}

export default ScenarioOne;
