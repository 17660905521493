export const setSensorResp = (str) => ({ type: 'SET_NSENSOR_RESP', payload: str });
export const setSensorData = (data) => ({ type: 'SET_NSENSOR_DATA', payload: data });
export const resetSensorData = () => ({ type: 'RESET_NSENSOR_DATA' });
export const setSensorValues = (data) => ({ type: 'SET_NSENSOR_VALUE', payload: data });
export const setSensorIntervals = (data) => ({ type: 'SET_NSENSOR_INTERVALS', payload: data });
export const setSensorList = (list) => ({ type: 'SET_NSENSOR_LIST', payload: list });
export const setSensorRecords = (list) => ({ type: 'SET_NSENSOR_RECORDS', payload: list });
export const setSensorImported = (list) => ({ type: 'SET_NSENSOR_IMPORTED', payload: list });
export const setSensorVisualized = (key) => ({ type: 'SET_NSENSOR_VISUALIZED', payload: key });
export const addSensorsEnabled = (num) => ({ type: 'ADD_NSENSORS_ENABLED', payload: num });
export const delSensorsEnabled = (index) => ({ type: 'DEL_NSENSORS_ENABLED', payload: index });
export const addSensorsFilters = (obj) => ({ type: 'ADD_NSENSORS_FILTERS', payload: obj });
export const delSensorsFilters = (key) => ({ type: 'DEL_NSENSORS_FILTERS', payload: key });
export const setSensorsChannels = (key) => ({ type: 'SET_NSENSORS_CHANNELS', payload: key });
export const setArtPressure = (bool) => ({ type: 'SET_NART_PRESSURE', payload: bool });
