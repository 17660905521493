import React from 'react';
import "../Content.scss";

const ContentSection = ({
    children, 
    className = '',
    background = true,
}) => {
    let sectionClass = 'cor_content__section';
    if (!background) {
        sectionClass += ' hide_bg'
    }
    if (className) {
        sectionClass += " " + className;
    }
    return (
        <div className={sectionClass}>
            {children}
        </div>
    )
};
export default ContentSection;
