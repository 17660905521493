import React, { useState, useEffect } from 'react';
import DropdownItem from './DropdownItem';
import './Dropdown.scss';
import Icon from "../Icon";

const Dropdown = ({ children, dropPosition = '', disabled = false, checkbox = false,
                      className = '', value = 'Выберите элемент', icon= null,
                      clicked = false }) => {
    const [open, setOpen] = useState(false);
    const [currentClass, setCurrentClass] = useState('cor_dropdown');

    useEffect(() => {
        let classStr = 'cor_dropdown';
        if (open) {
            classStr += ' open';
        }
        if (disabled) {
            classStr += ' disabled';
        }
        if (className) {
            classStr += ' ' + className;
        }
        if (checkbox) {
            classStr += ' cor_dropdown__check';
        }
        if (dropPosition) {
            classStr += ' ' + dropPosition;
        }
        setCurrentClass(classStr);
    }, [open, className, checkbox, disabled, dropPosition]);

    return (
        <div
            className={currentClass}
            // onMouseEnter={() => !open && !clicked && setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            onClick={() => (clicked ? setOpen(true) : setOpen(!open))}
        >
            <div className="cor_dropdown__label">
                {icon && <Icon name={icon}/>}
                <span>{value}</span>
            </div>
            <div className="cor_dropdown__drop">
                <ul>{children}</ul>
            </div>
        </div>
    );
};

Dropdown.Item = DropdownItem;

export default Dropdown;
