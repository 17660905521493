import React, {useEffect, useState} from 'react';
import {AddclassApi, userApi} from "../../api/api";
import {fetchclass, fetchUsersByOrg, selectclass} from "../../redux/slices/classsline";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {storageClass, storageOrg} from "../../redux/slices/storage";
import {Icon} from "../ui";
import {fullName, isAuth, profilePicture} from "../../helpers/auth";
import {printUserRole, printUserRoleGen} from "../../helpers/text";
import Grid from '../ui/Grid';
import ss from './MembersGrid.module.scss';
import { Modal, Button } from 'rlabui';

const MembersGrid = (props) => {
    const dispatch = useDispatch();
    const documentOrg = useSelector(storageOrg);
    const documentClass = useSelector(storageClass);
    const [classs] = useSelector(selectclass);
    const [showClass, setShowClass] = useState(null);
    const [showDelete, setShowDelete] = useState(null);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(null);

    const userClass = (cl, uid, del) => {
        try {
            (del ? AddclassApi.removeUserFromClass(cl, uid) : AddclassApi.addUserToClass(cl, uid))
            .then(() => dispatch(fetchclass(isAuth()._id)))
            .catch((err) => toast.error(err.response.data.message));
        } catch (error) {
            console.log(error);
        }
    };

    const deleteUser = async (u, force) => {
        try {
            const removeClasses = classs.filter(c => c.classUsers?.includes(u._id)).map(c => c._id);
            await AddclassApi.removeUserFromClass(removeClasses.join('|'), u._id);
            const status = u.status ? (u.status < 0 ? u.status : -u.status) : -1;
            (force ? userApi.delete(u._id) : userApi.update(u._id, {status}))
            .then(() => {
                dispatch(fetchclass(isAuth()._id));
                dispatch(fetchUsersByOrg(documentOrg._id));
            })
            .catch((err) => toast.error(err.response.data.message));
        } catch (error) {
            console.log(error);
        }
    };

    const restoreUser = async (u) => {
        try {
            const status = u.status ? (u.status > 0 ? u.status : -u.status) : 1;
            userApi.update(u._id, {status})
            .then(() => {
                dispatch(fetchclass(isAuth()._id));
                dispatch(fetchUsersByOrg(documentOrg._id));
            })
            .catch((err) => toast.error(err.response.data.message));
        } catch (error) {
            console.log(error);
        }
    };

    const deleteConfirm = async (user, force) => {
        await deleteUser(user, force);
        handleClose();
    };

    const classConfirm = async () => {
        userClass(documentClass._id, showClass._id, true)
        handleClose();
    };

    const handleClose = () => {
        setShowClass(null);
        setShowDelete(null);
        setShowDeleteConfirm(null);
    };

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) handleClose();
        };
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderPicture = (u) => u.picture ? <img src={profilePicture(u.picture)} alt={fullName(u)}/> : <Icon name='user'/>;

    return (
        <>
            <Grid className={ss.root}>
                {props.usersList.map((u, i) => (
                    <Grid.Col className={(u.status !== -1 ? ss.item : ss.item_blocked)} key={i}>
                        <div className={ss.head}>
                            <span className={props.onTitleClick?ss.link:ss.name}
                                  onClick={() => props.onTitleClick?.(u)}>{u.name} {u.name3}<br/>{u.name2}</span>
                            <div className={ss.action}>
                                <ul className={ss.action__list}>
                                    {props.select &&
                                        <li className={ss.action__item}>
                                            <input type="checkbox" name="users" value={u._id} onChange={props.select.handleChange}/>
                                        </li>
                                    }
                                    {(!props.disabled || isAuth()._id === u._id) &&
                                        <li className={ss.action__item}>
                                            <Icon 
                                                name="gear" 
                                                tp="Настройка"
                                                onClick={() => props.showUserSettings({showModal: true, role: u.role, user: u, allowDelete: isAuth()._id !== u._id})}
                                            />

                                        </li>
                                    }
                                </ul>
                               
                                {u.status === -1 &&
                                <div className={ss.action__snowflake}>
                                    <Icon 
                                        name="snowflake"
                                        onClick={() => restoreUser(u)}
                                        tp={"Разморозить " + printUserRoleGen(u.role).toLowerCase()}
                                    />
                                </div>
                                }
                            </div>
                        </div>

                        <div className={ss.main}>
                            <div className={ss.content}>
                                <div className={ss.info}>
                                    <div className={ss.role}>{printUserRole(u.role)}</div>
                                    <div className={ss.picture}>
                                        {renderPicture(u)}
                                    </div>
                                </div>
                                <ul className={ss.list}>
                                    <li className={ss.list__item}>
                                        <span>Логин</span>
                                        <span>{u.login}</span>
                                    </li>
                                    <li className={ss.list__item}>
                                        <span>Е-mail</span>
                                        <span>{u.email}</span>
                                    </li>
                                    <li className={ss.list__item}>
                                        <span>Классы</span>
                                        <span>{classs.filter(c => c.classUsers.includes(u._id)).map(c => c.className).join(';')}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        {!props.disabled && props.delete &&
                        <div className={ss.bottom}>
                            {u.status < 0 ?
                                <>
                                    <div className={ss.bottom__link}>
                                        <Icon 
                                            name="back-round" 
                                            onClick={() => restoreUser(u)}
                                            text={" Разморозить " + printUserRoleGen(u.role).toLowerCase()}>
                                        </Icon>
                                    </div>
                                    
                                    <div className={`${ss.bottom__link} ${ss.forever}`}>
                                        <Icon 
                                            name="trash" 
                                            onClick={() => setShowDeleteConfirm(u)}
                                            text={" Удалить навсегда " + printUserRoleGen(u.role).toLowerCase()}>
                                        </Icon>
                                    </div>
                                   
                                </>
                                : 
                                <div className={ss.bottom__link}>
                                    <Icon 
                                        name="delete" 
                                        onClick={() => setShowDelete(u)}
                                        text={" Удалить " + printUserRoleGen(u.role).toLowerCase()}>
                                    </Icon>
                                </div>
                            }
                        </div>}

                        {!props.disabled && !props.delete && u.status !== -1 &&
                        <div className={ss.bottom}>
                            {classs.filter(c => c._id === documentClass._id)[0]?.classUsers.includes(u._id) ?
                                <div className={ss.bottom__link}>
                                    <Icon 
                                        name="delete" 
                                        onClick={() => setShowClass(u)}
                                        text={" Исключить из класса " + documentClass.className}>
                                    </Icon>
                                </div>
                                :
                                <div className={ss.bottom__link}>
                                    <Icon 
                                        name="users-profile" 
                                        onClick={() => userClass(documentClass._id, u._id, false)}
                                        text={" Добавить в класс " + documentClass.className}>
                                    </Icon>
                                </div>
                            }
                        </div>}

                    </Grid.Col>
                ))}
            </Grid>

            <Modal visible={showClass} size="xs">
                <Modal.Head modalClose={handleClose} title={"Исключить " + printUserRoleGen(showClass?.role).toLowerCase() +"?"} />
                <Modal.Body className="lhn">
                    Вы уверены, что хотите исключить из
                        класса {printUserRoleGen(showClass?.role).toLowerCase()} {fullName(showClass)}?
                        <br/>Вы сможете добавить его обратно.
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={()=>classConfirm()}>Да, исключить</Button>
                    <Button onClick={handleClose} border={true}>Нет, отменить</Button>
                </Modal.Footer>
            </Modal>

            <Modal visible={showDelete} size="xs">
                <Modal.Head modalClose={handleClose} title={"Удалить " + printUserRoleGen(showDelete?.role).toLowerCase() + "?"} />
                <Modal.Body className={ss.modal__text}>
                    Вы можете временно заморозить профиль {printUserRoleGen(showDelete?.role).toLowerCase()} или удалить его навсегда.
                        В первом варианте профиль перестанет быть активным, но его можно будет восстановить
                </Modal.Body>
                <Modal.Footer className={ss.modal__column}>
                    <Button onClick={()=>deleteConfirm(showDelete, false)}>Заморозить учетную запись учителя</Button>
                    <Button onClick={()=>deleteConfirm(showDelete, true)} color="danger" >Удалить учетную запись учителя</Button>
                    <Button onClick={handleClose} border={true}>Нет, отменить</Button>
                </Modal.Footer>
            </Modal>

            <Modal visible={showDeleteConfirm} size="xs">
                <Modal.Head modalClose={handleClose} title='Точно удалить?' />
                <Modal.Body className={ss.modal__text}>
                    Вы уверены, что хотите безвозвратно удалить {printUserRoleGen(showDeleteConfirm?.role).toLowerCase()} {fullName(showDeleteConfirm)}?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={()=>deleteConfirm(showDeleteConfirm, true)} color="danger">Да, удалить</Button>
                    <Button onClick={handleClose}>Нет, отменить</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};
export default MembersGrid;
