import {
    showUserSettings,
    showUserSelect,
    showClassSettings,
    showRlabSettings,
    showDeviceSettings,
    showVideoSettings,
    showRlabSensors,
    showFileSettings,
    showUserPassword,
    showStorageSettings,
    showNameSettings,
} from "../../actions/actions";
import {toggleSilence} from "../../actions/toggle";
import {reloadOption} from "../../redux/slices/storage";

export const mapUserSettingsStateToProps = (state) => {
    return (
      state.cactions.userSettings
    );
};

export const mapUserSelectStateToProps = (state) => {
    return (
      state.cactions.userSelect
    );
};

export const mapUserPasswordStateToProps = (state) => {
    return (
      state.cactions.userPassword
    );
};

export const mapClassStateToProps = (state) => {
    return (
        state.cactions.classSettings
    );
};

export const mapRlabStateToProps = (state) => {
    return (
        state.cactions.rlabSettings
    );
};

export const mapDeviceStateToProps = (state) => {
    return (
        state.cactions.deviceSettings
    );
};

export const mapVideoStateToProps = (state) => {
    return (
        state.cactions.videoSettings
    );
};

export const mapRlabSensorsStateToProps = (state) => {
    return (
        state.cactions.rlabSensors
    );
};

export const mapFileStateToProps = (state) => {
    return (
      state.cactions.fileSettings
    );
};

export const mapStorageStateToProps = (state) => {
    return (
      state.cactions.storageSettings
    );
};

export const mapNameStateToProps = (state) => {
    return (
      state.cactions.nameSettings
    );
};

export const mapUserSettingsDispatchToProps = (dispatch) => ({
    showUserSettings: (obj) => dispatch(showUserSettings(obj)),
    toggleSilence: (bool) => dispatch(toggleSilence(bool)),
});

export const mapUserSelectDispatchToProps = (dispatch) => ({
    showUserSelect: (obj) => dispatch(showUserSelect(obj)),
});

export const mapUserPasswordDispatchToProps = (dispatch) => ({
    showUserPassword: (obj) => dispatch(showUserPassword(obj)),
});

export const mapClassDispatchToProps = (dispatch) => ({
    showClassSettings: (obj) => dispatch(showClassSettings(obj)),
});

export const mapRlabDispatchToProps = (dispatch) => ({
    showRlabSettings: (obj) => dispatch(showRlabSettings(obj)),
});

export const mapDeviceDispatchToProps = (dispatch) => ({
    showDeviceSettings: (obj) => dispatch(showDeviceSettings(obj)),
});
export const mapVideoDispatchToProps = (dispatch) => ({
    showVideoSettings: (obj) => dispatch(showVideoSettings(obj)),
    setVideoOptions: (obj) => dispatch(reloadOption('video',obj)),
});

export const mapRlabSensorsDispatchToProps = (dispatch) => ({
    showRlabSensors: (obj) => dispatch(showRlabSensors(obj)),
});

export const mapFileDispatchToProps = (dispatch) => ({
    showFileSettings: (obj) => dispatch(showFileSettings(obj)),
});

export const mapStorageDispatchToProps = (dispatch) => ({
    showStorageSettings: (obj) => dispatch(showStorageSettings(obj)),
});

export const mapNameDispatchToProps = (dispatch) => ({
    showNameSettings: (obj) => dispatch(showNameSettings(obj)),
});
