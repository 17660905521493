import chartColors from '../../../styles/charts';

export const setLineAnnotation = ({ chart, index, value, roundDigit, arr, theme, isDrag, setHoveredMarker, setChartMarkers, sensors, setEditMarker, bundles }) => {
    let data = arr?.length ? [...arr] : arr;
    if (sensors?.length > 1) {
        data = arr[0]?.data ? [...arr[0].data] : [...arr[0]];
    }

    const isSingle = sensors.length === 1;

    let dataInd = data[index] ? data[index].x : data.filter((e) => e.count === index)[0]?.x;
    if (data?.length <= 2 || !dataInd) return;

    // console.log(i, data);
    // console.log(data[i - 1]);
    // console.log(isSingle);

    const key = 'line' + index;
    const marks = chart.config.options.plugins.annotation.annotations;
    const val = value ? +(value / 1000).toFixed(3) : (dataInd / 1000).toFixed(roundDigit);
    const yValue = data[index]?.y || 0;

    // console.log(val);
    marks[key] = {
        type: 'line',
        borderWidth: 2,
        borderColor: theme === 'dark' ? chartColors.marker.dark.line : chartColors.marker.line,
        label: {
            enabled: true,
            position: 'end',
            content: val === 0 ? '0' : val,
            backgroundColor: theme === 'dark' ? chartColors.marker.dark.background : chartColors.marker.background,
            borderColor: theme === 'dark' ? chartColors.marker.dark.border : chartColors.marker.border,
            color: theme === 'dark' ? chartColors.marker.dark.color : chartColors.marker.color,
            borderWidth: 1,
            yAdjust: -6,
            borderRadius: 2,
            padding: {
                x: 9,
                y: 6,
            },
            font: {
                size: 10,
                weight: 400,
                lineHeight: 1.2,
            },
        },
        scaleID: 'xAxis',
        yScaleID: null,
        value: value ? value : dataInd,
        yValue,
        draggable: true,
        enter: (context, event) => {
            if (!isDrag) {
                if (context.id !== context.element.options.id) {
                    context.id = context.element.options.id;
                }
                setEditMarker(context.id);
                setHoveredMarker(context.id);
            }
        },
        leave: (context, event) => {
            if (!isDrag) {
                // setEditMarker(false);
                setHoveredMarker();
            }
        },
        onDragStart: (event, value) => {
            chart.options.plugins.zoom.pan.enabled = false;
            chart.update();
        },
        onDrag: (event, value) => {
            const cData = event.subject.chart.config._config.data.datasets[0].data;
            const min = cData[0].x;
            const max = cData[cData.length - 1].x;
            const {
                subject: {
                    config: { id },
                },
            } = event;
            if (value > max) {
                chart.options.plugins.annotation.annotations[id].value = max;
            }
            if (value < min) {
                chart.options.plugins.annotation.annotations[id].value = min;
            }
            chart.options.plugins.zoom.pan.enabled = false;
            event.subject.config.label.content = +(value / 1000).toFixed(3);
        },
        onDragEnd: (event, value) => {
            const cData = event.subject.chart.config._config.data.datasets[0].data;
            checkLinePosition(event, value, cData, roundDigit);
            chart.options.plugins.zoom.pan.enabled = true;
            chart.update();
            if (bundles || isSingle) {
                const num = bundles ? bundles : sensors[0].num;
                setChartMarkers({ num, data: chart.config.options.plugins.annotation.annotations });
            } else {
                sensors?.forEach(({ num }) => {
                    setChartMarkers({ num, data: chart.config.options.plugins.annotation.annotations });
                });
            }
        },
    };
    chart.options.plugins.annotation.annotations = marks;
    chart.update();
    // console.log(marks);
    if (bundles || isSingle) {
        const num = bundles ? bundles : sensors[0].num;
        setChartMarkers({ num, data: chart.config.options.plugins.annotation.annotations });
    } else {
        sensors?.forEach(({ num }) => {
            setChartMarkers({ num, data: chart.config.options.plugins.annotation.annotations });
        });
    }
    // return marks;
    // setMarkerChanged((markersChanged) => markersChanged + 1);
};

const checkLinePosition = (event, value, data, roundDigit) => {
    // const marks = chart.config.options.plugins.annotation.annotations;
    let {
        subject: {
            chart: {
                config: {
                    options: {
                        plugins: {
                            annotation: { annotations: marks },
                        },
                    },
                },
            },
            config: line,
            chart,
        },
    } = event;
    const currentId = line.id;
    const xArr = data.map((item) => item.x);
    const closest = xArr.sort((a, b) => Math.abs(value - a) - Math.abs(value - b))[0];
    let index;
    data.forEach((item, i) => {
        if (item.x === closest) {
            // console.log(item.x, i);
            index = i;
        }
    });
    const key = 'line' + index;
    line.value = closest;
    line.yValue = data[index].y;
    line.label.content = +closest !== 0 ? +(closest / 1000).toFixed(3) : '0';
    line.id = key;
    delete marks[currentId];
    marks[key] = line;
    chart.options.plugins.annotation.annotations = marks;

    return marks;
    // setChartMarkers({ ...marks });
    // setMarkerChanged((markersChanged) => markersChanged + 1);
};
