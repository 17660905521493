import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { storageApi } from '../../../api/api';
import axios from 'axios';
import { loadFiles } from '../../../redux/slices/lists';
import { toast } from 'react-toastify';
import { updateCard } from '../../../redux/slices/trello';

const UploadCoverInput = (props) => {
  const dispatch = useDispatch();

  const [uploadFile, setUploadFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const {
    lists: { files },
  } = useSelector((state) => state);

  const prefix = props?.match?.params.key ?? 'tracker%2F';

  const printFile = (name) => name.split('/').pop();
  const checkName = (name) => files.some((f) => printFile(f.Key) === name);
  function getName(name) {
    let newName = name;
    const ext = name.lastIndexOf('.');
    let i = 0;
    while (checkName(newName)) {
      i = i + 1;
      newName = name.slice(0, ext) + ' (' + i + ')' + name.slice(ext);
    }
    return newName;
  }

  const upload = async () => {
    const uploadUrl = await storageApi.getUploadUrl(
      prefix + getName(uploadFile.name)
    );
    axios
      .put(uploadUrl, uploadFile, {
        headers: { 'Content-Type': uploadFile.type },
        onUploadProgress: (e) =>
          setUploadProgress(
            Math.min(Math.round((e.loaded * 100) / e.total), 100)
          ),
      })
      .then(() => {
        dispatch(loadFiles(prefix));
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  
  console.log('uploadFile', uploadFile)
  console.log('files', files)

  useEffect(() => {
    dispatch(loadFiles(prefix));
  }, [dispatch, prefix]);

  useEffect(() => {
    if (uploadFile) {
      upload();
    }
  }, [uploadFile]);

  useEffect(() => {
    const currentUploadFile = files?.find((f) => f?.Key === `tracker/${uploadFile?.name}`);
    if (currentUploadFile && uploadFile) {
      dispatch(
        updateCard(
          props.activeTaskId,
          'attachments',
          currentUploadFile?.Url,
          props.isActiveTask && 'activeTask'
        )
      );
      setUploadFile(null);
      
      if (props.isMenuButton) {
        props.setIsOpenMenu(false);
      }
    }
  }, [props, files, uploadFile?.name, uploadFile]);

  return (
    <input
      id="upload"
      type="file"
      className="modal_task__upload"
      onChange={(e) => {
        setUploadFile(e.target.files[0]);
        setUploadProgress(0);
        e.target.value = '';
      }}
    />
  );
};

export default UploadCoverInput;
