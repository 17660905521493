import React, { useState, useEffect } from 'react';
import Icon from '../../Icon';
import { Switcher, Input } from 'rlabui';
import ss from './AsideBundles.module.scss';

const AsideBundles = ({ bundle, setSensorsBundleModalDel, updateSensorsBundles, addChartDisabled, deleteChartDisabled, delChartMarkers, disabled }) => {
    const { name, key, list, imported } = bundle;
    const [show, setShow] = useState(true);
    const [legend, setLegend] = useState('');
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        if (!edit && legend) {
            updateSensorsBundles({ key, name: legend });
        }
    }, [legend, edit]);

    return (
        <>
            <div className={show ? ss.rootOpen : ss.root}>
                <div className={ss.head}>
                    <Switcher
                        className={ss.switcher}
                        checked={!disabled.find((e) => e === key)}
                        onChange={() => {
                            if (disabled.find((e) => e === key)) {
                                deleteChartDisabled(key);
                            } else {
                                addChartDisabled(key);
                            }
                        }}
                    />
                    <div className={ss.legend}>
                        {!edit && <Icon name="chain" />}
                        {edit ? (
                            <>
                                <Input
                                    value={legend}
                                    onKeyDown={({ key }) => {
                                        if (key === 'Enter') setEdit(false);
                                    }}
                                    onInput={({ target }) => {
                                        if (target.value.length <= 25) {
                                            setLegend(target.value);
                                        } else {
                                            return false;
                                        }
                                    }}
                                />
                                <span className={ss.count}>{legend.length}/25</span>
                            </>
                        ) : (
                            <div className={ss.title}>
                                {imported ? '(Имп) ' : ''}
                                {legend ? legend : name}
                            </div>
                        )}
                        <Icon
                            name={edit ? 'check' : 'edit'}
                            onClick={() => setEdit(!edit)}
                        />
                    </div>
                    <div className={ss.action}>
                        <Icon
                            name="trash"
                            onClick={() => {
                                setSensorsBundleModalDel(key);
                                delChartMarkers(key);
                            }}
                        />
                        <Icon
                            name="caret"
                            onClick={() => setShow(!show)}
                        />
                    </div>
                </div>
                <div className={ss.body}>
                    {list?.map(
                        (item, k) => (
                            <div
                                className={ss.item}
                                key={k}
                            >
                                {item.name}
                            </div>
                        )
                        // <Sensor
                        //     sensor={item}
                        //     key={item.num}
                        //     index={k}
                        // />
                    )}
                </div>
            </div>
        </>
    );
};
export default AsideBundles;
