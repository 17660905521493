import React, { useEffect, useRef, useState } from 'react';
import { Modal, Input, Dropdown, Checkbox, Button } from 'rlabui';
// import { updateBtConnected } from '../../commands/bluetooth';
// import { updateBtConnected } from '../../commands/bluetooth';
import ss from './ConnectedSensorsModal.module.scss';
import Icon from '../Icon';
import {toast} from "react-toastify";

const connectTypeListArr = [
    { name: 'Все', value: 'all' },
    { name: 'Usb', value: 'usb' },
    { name: 'Bluetooth', value: 'bt' },
    { name: 'Сеть', value: 'net' },
];

const connectStatusListArr = [
    { name: 'Все', value: 'all' },
    { name: 'Доступен', value: 'on' },
    { name: 'Подключен', value: 'connected' },
    { name: 'Не доступен', value: 'off' },
];

// let devicesHistory = JSON.parse(localStorage.getItem('devicesHistory'));

const ConnectedSensorsModal = ({ modal, setDeviceHistoryModal, connectionType, devicesCount, battery, devicesList, disconnectUsb, disconnectBt, connectBt }) => {
    const [search, setSearch] = useState('');
    const [connectType, setConnectType] = useState('all');
    const [connectStatus, setConnectStatus] = useState('all');
    const [history, setHistory] = useState();
    const [list, setList] = useState([]);
    const [posible, setPosible] = useState([]);
    const [rssi, setRssi] = useState({});
    const updateRef = useRef();

    useEffect(() => {
        return () => clearInterval(updateRef.current);
    }, []);

    useEffect(() => {
        if (modal) {
            updateRef.current = setInterval(() => {
                updatePosibleSensorsConnectionList();
            }, 1000);
        } else {
            clearInterval(updateRef.current);
            posible.forEach((device) => {
                if (device.watchingAdvertisements) {
                    device.removeEventListener('advertisementreceived', advResponseEvent);
                }
            });
            // setList([]);
        }
    }, [modal]);

    // useEffect(() => {
    //     console.log(rssi);
    // }, [rssi]);

    useEffect(() => {
        // console.log(devicesCount);
        if (!modal) return;

        const hist = localStorage.getItem('devicesHistory');

        if (hist) {
            const saved = JSON.parse(hist);
            if (modal) {
                for (const key in saved) {
                    const connected = devicesList[key];
                    if (connected) {
                        saved[key].connected = 'connected';
                        saved[key].serial = connected?.inf?.den ? connected.inf.den : '';
                    }
                    if (devicesCount === 0) {
                        saved[key].connected = 'off';
                    }
                }
                setHistory(saved);
                localStorage.setItem('devicesHistory', JSON.stringify(saved));
            }
        } else {
            localStorage.setItem('devicesHistory', '{}');
        }
    }, [devicesCount, modal, posible, devicesList]);

    useEffect(() => {
        if (posible?.length && modal) {
            const saved = JSON.parse(localStorage.getItem('devicesHistory'));
            if (saved) {
                // Получаем сохраненные значения
                for (const key in saved) {
                    if (saved[key]) {
                        const sensor = saved[key];
                        const id = sensor?.id;
                        const charge = id && battery ? battery[id] : undefined;

                        // Переключаем состояние подключения и уровень заряда батареи
                        if (id.includes('usb')) {
                            const usbConnected = Object.values(devicesList).filter((e) => e.unicName === key)[0];
                            if (usbConnected) {
                                sensor.connected = 'connected';
                                if (charge?.value) {
                                    const { value, chardged } = charge;
                                    sensor.battery = value;
                                    sensor.chardged = chardged;
                                } else {
                                    sensor.battery = '-';
                                }
                            } else {
                                sensor.connected = 'off';
                                sensor.battery = '-';
                            }
                        } else {
                            const checkPosible = posible.find((e) => e?.id === id);
                            const checkConnected = Object.keys(devicesList).find((e) => e === checkPosible?.id);

                            if (checkPosible) {
                                // console.log('');
                                // console.log(sensor);
                                if (checkPosible.gatt.connected && checkPosible.id === checkConnected) {
                                    sensor.connected = 'connected';
                                } else {
                                    if (!checkPosible.gatt.connected) {
                                        checkPosible
                                            .watchAdvertisements()
                                            .then(() => {
                                                checkPosible.addEventListener('advertisementreceived', (e) => advResponseEvent(e, checkPosible.id));
                                            })
                                            .catch((err) => {
                                                console.log(err);
                                            });
                                        checkPosible.gatt.connect().catch((err) => {
                                            // console.log(err);
                                        });
                                    }
                                    // sensor.connected = 'on';
                                    sensor.connected = checkPosible.gatt.connected ? 'on' : 'off';
                                }

                                if (sensor.connected === 'on') {
                                    // connectBt(checkPosible);
                                    // requestBluetoothDevice(checkPosible);
                                }
                            } else {
                                sensor.connected = 'off';
                            }

                            if (charge?.value) {
                                const { value, chardged } = charge;
                                sensor.battery = value;
                                sensor.chardged = chardged;
                            } else {
                                sensor.battery = '-';
                            }
                        }
                    }
                }

                // Чистим дубли
                for (const key in saved) {
                    if (Object.keys(saved).filter((e) => key.includes(e)).length > 1) {
                        delete saved[key];
                    }
                }

                setHistory(saved);
                localStorage.setItem('devicesHistory', JSON.stringify(saved));
            }
        }
    }, [posible, battery, connectionType, battery, modal]);

    useEffect(() => {
        if (history && modal) {
            let newList = [];

            for (const key in history) newList.push(history[key]);

            const lowerStr = search?.toLowerCase();
            if (lowerStr?.length)
                newList = newList.filter(
                    ({ name, deviceKey, serial }) => name.toLowerCase().includes(lowerStr) || deviceKey.toLowerCase().includes(lowerStr) || serial?.toLowerCase().includes(lowerStr)
                );

            if (connectType !== 'all') newList = newList.filter((e) => e.connectType === connectType);

            if (connectStatus !== 'all') newList = newList.filter((e) => e.connected === connectStatus);

            setList([...newList]);
        }
    }, [history, search, connectType, connectStatus, modal]);

    const updatePosibleSensorsConnectionList = async () => {
        const newList = [];

        await navigator.bluetooth
            .getDevices()
            .then((devices) => {
                // console.log(devices);
                devices.forEach((device) => {
                    if (typeof device.gatt.connected === 'boolean') newList.push(device);
                });
            })
            .catch((err) => {
                console.log(err);
            });

        await navigator.serial
            .getPorts()
            .then((ports) => {
                ports.forEach((port) => newList.push(port));
            })
            .catch((err) => {
                // console.log('port connect err', err);
            });
        // console.log(newList);
        modal && setPosible(newList);
    };

    const showConnectState = (connect, type) => {
        if (connect === 'on') {
            return 'Доступен';
        } else if (connect === 'off') {
            return <span style={{ color: '#A5A5A5' }}>Не доступен</span>;
        } else {
            return type === connectionType ? <span style={{ color: '#0EC60A' }}>Подключен</span> : <span style={{ color: '#A5A5A5' }}>Не доступен</span>;
        }
    };

    const showBatteryState = (connect, battery, chardged) => {
        let percent = '-',
            icon;

        if (connect && typeof battery === 'number') {
            percent = `${battery}%`;
            if (battery <= 30) {
                icon = 'battery-min';
            }
            if (battery > 30) {
                icon = 'battery-middle';
            }
            if (battery > 80) {
                icon = 'battery-full';
            }
            if (chardged) {
                icon = 'battery-charge';
            }
        }
        return (
            <span className={ss.tableCharge}>
                {icon && <Icon name={icon}></Icon>} {percent}
            </span>
        );
    };

    const handleRemoveDevice = (key, id) => {
        const saved = JSON.parse(localStorage.getItem('devicesHistory'));
        if (key === 'all') {
            posible.forEach((device) => device?.forget());
            setHistory({});
            localStorage.setItem('devicesHistory', JSON.stringify({}));
        } else {
            const removed = posible.find((e) => e.id === id);
            console.log(key, saved);
            if (saved) {
                connectType === 'usb' ? delete saved[key] : delete saved[id];
                setHistory(saved);
                localStorage.setItem('devicesHistory', JSON.stringify(saved));
            }

            if (removed) {
                // console.log(removed);
                removed.forget();
            }
        }
    };

    const handleConnectDisconnect = (connected, type, id) => {
        if (connected == 'connected') {
            if (type === 'usb') {
                disconnectUsb();
            } else {
                const device = posible.find((list) => list.id === id);
                device ? disconnectBt(device) : disconnectBt();
            }
        } else {
            if (type === 'usb') {
                // connectUsb();
                toast.warn('Устройство не доступно');
            } else {
                const device = posible.find((list) => list.id === id);
                device ? connectBt(device) : connectBt();
            }
        }
    };

    function advResponseEvent(e) {
        if (e) {
            const {
                rssi: rss,
                device: { id, name },
            } = e;
            setRssi((e) => {
                e[id] = Math.abs(rss) > 100 ? 100 : Math.abs(rss);
                return e;
            });
        }
    }

    return (
        <Modal
            visible={modal}
            className={ss.root}
            size="lg"
        >
            <Modal.Head
                title="Список подключенных  датчиков"
                modalClose={() => setDeviceHistoryModal(false)}
            ></Modal.Head>
            <Modal.Body>
                <div className={ss.head}>
                    <div className={ss.headItem}>
                        <Input
                            type="text"
                            placeholder="Введите название или серийный номер"
                            value={search}
                            onChange={({ target: { value } }) => {
                                setSearch(value);
                            }}
                        ></Input>
                    </div>
                    <div className={ss.headItem}>
                        <div className={ss.headLabel}>Тип датчика</div>
                        <Dropdown value={connectTypeListArr.find((e) => e.value === connectType)?.name || 'Все'}>
                            {connectTypeListArr.map(({ name, value }, i) => {
                                return (
                                    <Dropdown.Item
                                        key={value + i}
                                        onClick={() => setConnectType(value)}
                                    >
                                        {name}
                                    </Dropdown.Item>
                                );
                            })}
                        </Dropdown>
                    </div>
                    <div className={ss.headItem}>
                        <div className={ss.headLabel}>Статус</div>
                        <Dropdown value={connectStatusListArr.find((e) => e.value === connectStatus)?.name || 'Все'}>
                            {connectStatusListArr.map(({ name, value }, i) => {
                                return (
                                    <Dropdown.Item
                                        key={value + i}
                                        onClick={() => setConnectStatus(value)}
                                    >
                                        {name}
                                    </Dropdown.Item>
                                );
                            })}
                        </Dropdown>
                    </div>
                </div>
                <div className={ss.table}>
                    <table>
                        <thead>
                            <tr>
                                {/* <th>ID</th> */}
                                <th>
                                    <span>Название</span>
                                </th>
                                <th>
                                    <span>Серийный номер</span>
                                </th>
                                <th>
                                    <span>Уровень сигнала</span>
                                </th>
                                <th>
                                    <span>Заряд</span>
                                </th>
                                <th>
                                    <span>Статус</span>
                                </th>
                                <th>
                                    <span>Действия</span>
                                    {/* <Icon
                                        name="trash"
                                        onClick={() => handleRemoveDevice('all')}
                                    /> */}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map(({ key, id, name, signal, deviceKey, connected, connectType, battery, serial, chardged }, i) => {
                                const isConnect = connected === 'connected' && connectType === connectionType;
                                const trKey = `${deviceKey}${id}${serial}`;
                                return (
                                    <tr key={trKey}>
                                        {/* <td>{id}</td> */}
                                        <td>
                                            <div className={ss.tableName}>
                                                <Icon name={connectType === 'bt' ? 'bluetooth' : 'usb'} />
                                                <span>{name}</span>
                                            </div>
                                        </td>
                                        <td>{serial || deviceKey}</td>
                                        <td>
                                            {isConnect ? (
                                                signal === 1 ? (
                                                    connectType === 'bt' ? (
                                                        rssi[id] ? (
                                                            rssi[id] + '%'
                                                        ) : (
                                                            'Хороший'
                                                        )
                                                    ) : (
                                                        'Хороший'
                                                    )
                                                ) : (
                                                    <span style={{ color: '#A5A5A5' }}>Плохой</span>
                                                )
                                            ) : connectType === 'bt' && connected === 'on' ? (
                                                rssi[id] ? (
                                                    rssi[id] + '%'
                                                ) : (
                                                    'Хороший'
                                                )
                                            ) : (
                                                'Отсутствует'
                                            )}
                                        </td>
                                        <td>{showBatteryState(isConnect, battery, chardged)}</td>
                                        <td>{showConnectState(connected, connectType)}</td>
                                        <td>
                                            <div className={ss.tableAction}>
                                                <button
                                                    disabled={connectType === 'usb' || connected === 'off'}
                                                    className={ss.tableConnection}
                                                    onClick={() => handleConnectDisconnect(connected, connectType, id)}
                                                >
                                                    <Icon
                                                        name={isConnect ? 'minus' : 'plus'}
                                                        className={ss.tablePM}
                                                    />
                                                    {isConnect ? 'Отключить' : 'Подключить'}
                                                </button>
                                                {/* {connectType === 'bt' && (
                                                    <Checkbox
                                                        title={'Автоподключение'}
                                                        checked={autoconnect.filter((e) => e === id).length}
                                                        onChange={(e) => {
                                                            let list = [...autoconnect];
                                                            if (e.target.checked) {
                                                                list.push(id);
                                                            } else {
                                                                list = autoconnect.filter((e) => e !== id);
                                                            }
                                                            setAutoconnect(list);
                                                        }}
                                                    />
                                                )} */}
                                                <Icon
                                                    tag="button"
                                                    disabled={isConnect}
                                                    name={'trash'}
                                                    onClick={() => !isConnect && handleRemoveDevice(key, id)}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
            <Modal.Footer className={ss.foot}>
                {/* <div className={ss.footLabel}>Подключить новое устройство</div> */}
                <div className={ss.footIcons}>
                    <div
                        className={ss.btn}
                        onClick={() => connectBt()}
                    >
                        <Icon
                            tag="button"
                            title={'Добавить новый'}
                            name={'plus'}
                        />
                        <span className={ss.footLabel}>Добавить новый</span>
                    </div>
                    <div
                        className={ss.btn}
                        onClick={() => connectionType === 'bt' && disconnectBt()}
                    >
                        <Icon
                            tag="button"
                            title={'Отключить все'}
                            disabled={!connectionType || connectionType !== 'bt'}
                            name={'minus'}
                            className={connectionType === 'bt' && 'active'}
                        />
                        <span className={ss.footLabel}>Отключить все</span>
                    </div>

                    {/* <Icon
                        name={'usb'}
                        className={connectionType === 'usb' && 'active'}
                        onClick={() => (connectionType === 'usb' ? disconnectUsb() : connectUsb())}
                        >
                        {connectionType === 'usb' && devicesCount > 0 && <span>{devicesCount}</span>}
                        </Icon> */}
                </div>

                <div
                    className={ss.btn}
                    onClick={() => handleRemoveDevice('all')}
                >
                    <Icon
                        tag="button"
                        disabled={connectionType}
                        title={'Очистить список'}
                        name={'close'}
                    />
                    <span className={ss.footLabel}>Удалить все</span>
                </div>
                {/* {connectionType === 'bt' && devicesCount && <span>{devicesCount}</span>} */}
            </Modal.Footer>
        </Modal>
    );
};
export default ConnectedSensorsModal;
