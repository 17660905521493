export const FEATURES = {
    video: {icon: 'menu', to: '/', role: [0, 1], title:'Основная страница'},
    class: {icon: 'menu', to: '/', role: [2, 3], title:'Классы'},
    firmware: {icon: 'download', to: '/firmware', role: [3], title:'Прошивки'},
    news: {icon: 'info', to: '/news', alt: ['/news/:type?/:id?'], role: [3], title:'Новости'},
    teachers: {icon:'teacher', to: '/teachers', role: [2], title:'Учителя'},
    students: {icon:'student', to: '/students', role: [2], title:'Ученики'},
    practicum: {icon: 'microscope', to: '/practicum', alt:['/practicum/:opt?/:id?', '/reviewtest/:opt/:id?'], role: [0,1], title:'Практикум',
        sub: ['ot', 'lw', 'cm', 'pi'],
    },
    ot: {icon: 'checkmark-list', to: '/ot', role: [3], name: 'Онлайн тесты', title: 'Онлайн тесты', tooltip: 'Создание Онлайн тестов, их проведение и оценка результатов'},
    lw: {icon: 'beaker', to: '/lw', role: [], name: 'Лабораторные работы', title: 'Лабораторные работы', tooltip: 'Демонстрация лабораторных работ, тестирование и оценка результатов'},
    cm: {icon: 'calculator', to: '/cm', role: [], alt:['/cm/:id?/:opt?'], title: 'Расчетные модели', tooltip: 'Демонстрация действия расчётных моделей'},
    pi: {icon: 'search-earth', to: '/pi/res', role: [3], alt:['/pi/:opt/:id?'], name: 'Исследования', title: 'Исследования', tooltip: 'Демонстрация физических исследований'},
    tracker: {icon: 'pen-ruler', to: '/trello', role: [0, 1], name:'Управление проектами', title:'Управление проектами'},
    whiteboard: {icon: 'edit', to: '/whiteboard', role: [0, 1], name:'Доска', title:'Доска'},
    tutorial: {icon: 'board', to: '/tutorial', alt:['/tutorial/:opt?/:sid?/:bid?'], role: [0, 1, 3], name:'Пособия', title:'Интерактивные пособия'},
    notebook: {icon: 'notes', to: '/notebooks/0', alt:['/notebooks/:opt/:id?'], role: [0, 1, 3], name:'Рабочая Тетрадь', title:'Рабочая Тетрадь'},
    instruments: {icon: 'protractor', to: '/instruments', alt:['/instrument/:id', '/instruments/:id?'], role: [0, 1], name:'Инструментарий', title:'Инструментарий'},
    storage: {icon: 'folder', to: '/storage', alt: ['/storage/:key?'], role: [1], name:'Картотека', title:'Картотека'},
    vrlab: {icon: 'rocket', to: '/vrlab', alt: ['/vrlab/:id?'], role: [0, 1], name:'Виртуальная Лаборатория', title:'Виртуальная Лаборатория'},
    rlab: {icon: 'transmission', to: '/rlabSelect', alt: ['/rlab', '/rlab/:id/:opt?', '/rlabExport', '/rlabNeuro'], role: [0, 1], name:'Лаборатории', title:'Лаборатории',
        sub: ['rlsc'],
    },
    rlsc: {icon: 'script', to: '/rlsc/list', alt: ['/rlsc/:opt/:id?'], role: [3], title: 'Сценарии'},
    profile: {icon: 'user', to: '/profile', alt:['/profile/:id/:opt?'], role: [0, 1, 2], title:'Профиль'},
};
