import React from 'react'

const DropdownItem = ({ children, className = '', onClick = null }) => {
    return (
        <li
            className={className}
            onClick={onClick}
        >
            {children}
        </li>
    )
}
export default DropdownItem
