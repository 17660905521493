import { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './DndList.scss';
import List from '../List/List';
import Card from '../Card/Card';
import AddButton from '../AddButton/AddButton';
import AddInput from '../AddInput/AddInput';
import socket from '../../../socket';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewColumn,
  getActiveTask,
  getArchiveCards,
  getCards,
  getColumns,
  reorderCards,
  reorderColumns,
  updateCard,
  updateColumn,
} from '../../../redux/slices/trello';
import { storageClass, storageUser } from '../../../redux/slices/storage';
import { fetchclass } from '../../../redux/slices/classsline';
import Modal from '../../Modal';
import ModalTask from '../Modal/ModalTask';
import NewStickerModal from '../NewModals/NewStickerModal';
import { storageApi } from '../../../api/api';
import axios from 'axios';
import { loadFiles } from '../../../redux/slices/lists';
import { toast } from 'react-toastify';

const DndList = (props) => {
  const dispatch = useDispatch();

  const scrolledContainer = props.scrolledContainer
  const documentUser = useSelector(storageUser);
  const documentClass = useSelector(storageClass);
  const activeSection = useSelector((state) => state.trelloSlice.activeGroup);
  const columnsDatas = useSelector((state) => state.trelloSlice.columns);
  const columnsData = columnsDatas.filter(column => column.parent === activeSection?._id);
  const cardsDatas = useSelector((state) => state.trelloSlice.cards);
  const itemsFilterData = useSelector((state) => state.trelloSlice.filteredItems);

  const [newColumnData, setNewColumnData] = useState('');
  const [isVisibleTextarea, setIsVisibleTextarea] = useState(false);
  const [columns, setColumns] = useState(columnsData);
  const [isModalTask, setIsModalTask] = useState(false);

  const [isModalSticker, setIsModalSticker] = useState(false);

  let cardsData;
  let archiveCardsData;

  if (itemsFilterData.length !== 0) {
    cardsData = cardsDatas
      ?.filter((card) => card?.status >= 0)
      .filter((card) =>
        card?.users?.some((user) =>
          itemsFilterData?.some(
            (selectedUser) =>
              selectedUser?._id === user ||
              selectedUser.priority === card.priority
          )
        )
      );

    archiveCardsData = cardsDatas
      .filter((card) => card?.status < 0)
      .filter((card) =>
        card?.users?.some((user) =>
          itemsFilterData?.some(
            (selectedUser) =>
              selectedUser?._id === user ||
              selectedUser.priority === card.priority
          )
        )
      );
  } else {
    cardsData = cardsDatas?.filter((card) => card?.status >= 0);
    archiveCardsData = cardsDatas.filter((card) => card?.status < 0);
  }

  const handleShowTextarea = (e) => {
    setIsVisibleTextarea(!isVisibleTextarea);

    if (isVisibleTextarea) {
      setNewColumnData('');
    }
  };

  useEffect(() => {
    socket.on('FE-refresh-db', ({ type }) => {
      if (type === 'trackerGroup') {
        dispatch(getColumns(documentClass?._id));
      }
      if (type === 'trackerTask') {
        dispatch(getCards(documentClass?._id));
      }
    });
    return () => {
      socket.off('FE-refresh-db');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentClass]);

  useEffect(() => {
    dispatch(fetchclass(documentUser?._id));
  }, [dispatch, documentUser?._id]);

  useEffect(() => {
    if (documentClass?._id) {
      dispatch(getColumns(documentClass?._id));
      dispatch(getCards(documentClass?._id));
      dispatch(getArchiveCards(documentClass?._id));
    }
  }, [dispatch, documentClass]);

  const handleDragEnd = (result) => {
    const { source, destination, type } = result;

    if (itemsFilterData.length !== 0) {
      return;
    }

    if (!destination) {
      return;
    }

    if (type === 'list') {
      const listsCopy = Array.from(columnsData);
      const [removed] = listsCopy.splice(source.index, 1);
      listsCopy.splice(destination.index, 0, removed);
      dispatch(reorderColumns(listsCopy));
      dispatch(updateColumn(removed._id, 'order', destination.index));
    }

    if (type === 'card') {
      const sourceList = columnsData.find(
        (list) => list._id === source.droppableId
      );
      const destinationList = columnsData.find(
        (list) => list._id === destination.droppableId
      );
      if (sourceList === destinationList) {
        const cardsCopy = Array.from(cardsData);

        const sourceCards = cardsCopy.filter(
          (card) => card.group === source.droppableId
        );
        const sourceCardsCopy = [...sourceCards];
        const otherCards = cardsCopy.filter(
          (card) => card.group !== source.droppableId
        );

        const [removed] = sourceCardsCopy.splice(source.index, 1);

        sourceCards.splice(source.index, 1);
        sourceCards.splice(destination.index, 0, removed);

        const updatedCardsOrder = sourceCards.map((card, index) => {
          return {
            ...card,
            order: index,
          };
        });

        const updatedCards = otherCards.concat(updatedCardsOrder);

        dispatch(reorderCards(updatedCards));
        dispatch(updateCard(removed._id, 'order', destination.index));
      } else if (sourceList && destinationList) {
        const cardsCopy = Array.from(cardsData); // копия массива карточек

        const sourceCards = cardsCopy.filter(
          (card) => card.group === source.droppableId
        ); // отфильтрованный массив карточек в списке ИЗ которого перетаскиваются карточки
        const sourceCardsCopy = [...sourceCards];
        const otherCards = cardsCopy.filter(
          (card) => card.group !== source.droppableId
        ); // остальные карточки, не находящиеся в списке из которого перетаскиваю

        const [removed] = sourceCardsCopy.splice(source.index, 1);

        const updatedRemoved = { ...removed, group: destinationList._id };

        sourceCards.splice(source.index, 1);
        otherCards.splice(destination.index, 0, updatedRemoved);

        const updatedCardsOrder = sourceCards.map((card, index) => {
          return {
            ...card,
            order: index,
          };
        });

        const updatedOtherCardsOrder = otherCards.map((card, index) => {
          return {
            ...card,
            order: index,
          };
        });

        const updatedCards = updatedOtherCardsOrder.concat(updatedCardsOrder);

        dispatch(reorderCards(updatedCards));
        dispatch(updateCard(removed._id, 'order', destination.index));
      }
    }
  };

  const handleAddNewColumn = () => {
    setIsVisibleTextarea(true);
    if (newColumnData) {
      const newColumn = {
        name: newColumnData,
        order: columnsData.length,
        room: documentClass?._id,
        parent: activeSection?._id,
      };

      dispatch(addNewColumn(newColumn, documentClass?._id));

      setNewColumnData('');
      setIsVisibleTextarea(false);
    }
  };


  // const [uploadFile, setUploadFile] = useState(null);
  // const [activeTask, setActiveTask] = useState(null);
  // const [uploadProgress, setUploadProgress] = useState(0);
  // const {
  //   lists: { files },
  // } = useSelector((state) => state);

  // const prefix = props?.match?.params.key ?? '%2F';

  // // const activeTask = useSelector((state) => state.trelloSlice.activeTask);


  // const printFile = (name) => name.split('/').pop();
  // const checkName = (name) => files.some((f) => printFile(f.Key) === name);
  // function getName(name) {
  //   let newName = name;
  //   const ext = name.lastIndexOf('.');
  //   let i = 0;
  //   while (checkName(newName)) {
  //     i = i + 1;
  //     newName = name.slice(0, ext) + ' (' + i + ')' + name.slice(ext);
  //   }
  //   return newName;
  // }

  // const upload = async () => {
  //   const uploadUrl = await storageApi.getUploadUrl(
  //     prefix + getName(uploadFile.name)
  //   );
  //   axios
  //     .put(uploadUrl, uploadFile, {
  //       headers: { 'Content-Type': uploadFile.type },
  //       onUploadProgress: (e) =>
  //         setUploadProgress(
  //           Math.min(Math.round((e.loaded * 100) / e.total), 100)
  //         ),
  //     })
  //     .then(() => {
  //       dispatch(loadFiles(prefix));
  //     })
  //     .catch((err) => {
  //       toast.error(err);
  //     });
  // };

  // useEffect(() => {
  //   dispatch(loadFiles(prefix));
  // }, [dispatch, prefix]);

  // useEffect(() => {
  //   if (uploadFile) {
  //     upload();
  //   }
  // }, [uploadFile]);

  // useEffect(() => {
  //   const currentUploadFile = files?.find((f) => f?.Key === uploadFile?.name);
  //   if (currentUploadFile && uploadFile) {
  //     dispatch(
  //       updateCard(
  //         activeTask,
  //         'attachments',
  //         currentUploadFile?.Url,
  //         'activeTask'
  //       )
  //     );
  //     setUploadFile(null);
  //   }
  // }, [activeTask, files, uploadFile?.name]);

  // const u = (e, id) => {
  //   setUploadFile(e.target.files[0]);
  //                 setUploadProgress(0);
  //                 e.target.value = '';
  //                 setActiveTask(id)
  // }  
  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="columns" direction="horizontal" type="list">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="dnd__container"
            >
              {columnsData?.map((column, columnIndex) => (
                <Draggable
                  draggableId={column?._id}
                  index={columnIndex}
                  key={column?._id}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <List
                        item={column}
                        scrolledContainer={scrolledContainer}
                        archiveCards={archiveCardsData}
                      >
                        <Droppable droppableId={column?._id} type="card">
                          {(provided, snapshot) => {
                            const listCards = cardsData?.filter(
                              (card) => card?.group === column?._id
                            );
                            return (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className={`${
                                  snapshot.isDraggingOver &&
                                  listCards?.length === 0
                                    ? 'dnd__card_container_hover'
                                    : listCards?.length === 0
                                    ? 'dnd__card_container_hide'
                                    : ''
                                } droppable-container`}
                              >
                                {cardsData
                                  ?.filter(
                                    (card) => card?.group === column?._id
                                  )
                                  .map((card, cardIndex) => (
                                    <Draggable
                                      draggableId={card?._id}
                                      index={cardIndex}
                                      key={card?._id}
                                    >
                                      {(provided) => (
                                        <div
                                          className="card"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <Card
                                          // u={u}
                                            card={card}
                                            column={column}
                                            setColumns={setColumns}
                                            openModal={() => {
                                              setIsModalTask(true);
                                              dispatch(getActiveTask(card._id));
                                            }}
                                          />
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                {provided.placeholder}
                              </div>
                            );
                          }}
                        </Droppable>
                      </List>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}

              <div className={isVisibleTextarea ? 'dnd__add_column' : ''}>
                {isVisibleTextarea && (
                  <AddInput
                    newData={newColumnData}
                    setNewData={setNewColumnData}
                    handleAdd={handleAddNewColumn}
                  />
                )}

                <AddButton
                  className="dnd__add_column__button"
                  svgClassName="dnd__add_column__svg"
                  title={'Добавить колонку'}
                  isVisible={isVisibleTextarea}
                  handleShow={handleShowTextarea}
                  handleAdd={handleAddNewColumn}
                />
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div onClick={() => setIsModalSticker(true)}>BUTTON MODAL</div>
      <Modal
        visible={isModalTask}
        content={<ModalTask onCloseModal={() => setIsModalTask(false)} {...props} />}
      />
      <Modal
        visible={isModalSticker}
        content={<NewStickerModal onCloseModal={() => setIsModalSticker(false)} />}
      />
    </>
  );
};

export default DndList;
