import { toggleTimerModal, setStopwatch } from '../../../actions/timer';

export const mapStateToProps = (nstate) => {
    return {
        modal: nstate.ntimer.timerModal,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    toggleTimerModal: (bool) => dispatch(toggleTimerModal(bool)),
    setStopwatch: (millisec) => dispatch(setStopwatch(millisec)),
});
