import React, { useState } from 'react';
import { Modal } from 'rlabui';
import { VideoEditor } from './';

const VideoEditorModal = ({ modal, setVideoEditorModal }) => {
    const [initVideo, setInitVideo] = useState(false);

    const doCloseVideoEditor = () => {
        setInitVideo(true);
        setVideoEditorModal(false);
    };

    return (
        <Modal
            className="videoEditor__modal"
            visible={modal}
            size="fullscreen"
        >
            <Modal.Head
                modalClose={doCloseVideoEditor}
                title="Работа с видео"
            ></Modal.Head>
            <Modal.Body>
                <VideoEditor
                    initVideo={initVideo}
                    setInitVideo={setInitVideo}
                />
            </Modal.Body>
        </Modal>
    );
};
export default VideoEditorModal;
