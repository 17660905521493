import React, { useEffect, useState, useRef } from 'react';
import chartColors from '../../styles/charts';
import { Icon } from '../ui';
import { Switcher } from 'rlabui';
import uPlot from 'uplot';
// import UplotReact from 'uplot-react';
import { wheelZoomPlugin, panPlugin, cursorPlugin, annotationsPlugin } from './plugins';
import { drawRange } from './plugins/annotationsPlugin';
import { moveCursor, removeCursor } from './plugins/cursorPlugin';
import { findAllPulseTimes, movingAverage, updateChartOptions, getFilteredData, fnOrSelf, getRelativePositionLeft } from './utils';
import { ComplexArray } from 'jsfft';
import 'uplot/dist/uPlot.min.css';
import ss from './Chart.module.scss';
import './uplot.scss';

const defaultTimeMax = 15000;
let handleXMin, handleXMax;

const barOpt = {
    size: [1, 150],
    radius: 0.05,
    disp: {
        y0: {
            values: (u) => u.data[1],
        },
        fill: {
            values: (u) => u.data[1].map((v, i) => (i === 0 ? '#69E091' : '#F55A5A')),
        },
        stroke: {
            values: (u) => u.data[1].map((v, i) => (i === 0 ? '#69E091' : '#F55A5A')),
        },
    },
};

const Chart = ({
    index,
    data,
    played,
    paused,
    // view,
    sensor,
    sensorImported,
    chartStyle,
    defaultColors,
    theme,
    markerMode,
    // chartDisabled,
    markerActions,
    markers,
    setMarkers,
    delMarkers,
    visualized,
    setSensorRecords,
    filters,
    xRanges,
    setXRanges,
    offsetRec,
    setOffsetRec,
    setCursorSync,
    syncCursor,
}) => {
    const { num, name, unit, roundDigit, key, parent, imported: isImport } = sensor;
    const defaultColor = defaultColors[index];
    const {
        weight,
        color,
        point: { width: pointWidth, color: pointColor },
        interpolation,
    } = chartStyle[key]
        ? chartStyle[key]
        : {
              weight: 1,
              color: sensor.color,
              point: {
                  width: 0,
                  color: sensor.color,
              },
              interpolation: 'basis',
          };

    const record = played && !paused;
    const isSpectr = name?.includes('Спектр');
    const isEEGAB = name?.includes('EEGAB');
    const isSignal = name?.includes('Сигнал') || name?.includes('Кнопка');
    const currKey = parent ? parent : key;
    const currXRange = xRanges[currKey];
    const currMarkersRange = markers[currKey] ? markers[currKey] : null;
    const syncPos = !isEEGAB && !isSpectr && syncCursor[currKey];
    // const syncPos = !isEEGAB && !isSpectr && !parent && syncCursor[currKey];
    // const isCursor = markerMode === 'cursor';

    const [chart, setChart] = useState(null);
    const [series, setSeries] = useState(isImport ? sensor.value : []);
    const [showGrid, setShowGrid] = useState(!isEEGAB && !isSpectr);
    const [xHandle, setXHandle] = useState(true);
    const [yHandle, setYHandle] = useState(true);
    const [chanelPrefix, setChannelPreffix] = useState();
    const [isZoom, setZoom] = useState(false);
    const [yMin, setYMin] = useState(-1);
    const [yMax, setYMax] = useState(1);
    const [screen, setScreen] = useState(currXRange?.length ? currXRange[1] : defaultTimeMax);
    const [resetViewArea, setResetViewArea] = useState(false);
    const wrapRef = useRef();
    // const chartRef = useRef();

    const importVals = visualized ? sensorImported.find((e) => e.key === visualized)?.value : sensor.value;
    const importButtonVals = visualized ? sensorImported.find((e) => e.key === 'impButton')?.value : sensor.value;

    const opts = {
        // const [opts, setOpts] = useState({
        title: false,
        padding: [5, 10, 10, 2],
        width: wrapRef?.current?.offsetWidth || 0,
        height: wrapRef?.current?.offsetHeight || 0,
        legend: {
            show: false,
        },
        cursor: {
            bind: {
                dblclick: () => null,
                // Масштабирование мышкой через иконку
                mousedown: (u, targ, handler) => {
                    return (e) => {
                        const { target, button } = e;
                        if (target.classList.contains('u-range-x') || target.classList.contains('u-marker-x')) return;
                        if (button === 0) {
                            // console.log('1');
                            if (u.cursor.drag.x) {
                                handleXMin = u.posToVal(getRelativePositionLeft(u, e), 'x');
                                // drawRange(u);
                            }
                            handler(e);
                        }
                    };
                },
                mouseup: (u, targ, handler) => {
                    const {
                        cursor,
                        scales: { x, y },
                    } = u;
                    if (handleXMax !== handleXMin) {
                        // console.log('2');
                        return (e) => {
                            if (e.button === 0) {
                                if (cursor.drag.x) {
                                    handleXMax = u.posToVal(getRelativePositionLeft(u, e), 'x');
                                }
                                u.setScale('y', { min: y.min, max: y.max });
                                u.setScale('x', { min: x.min, max: x.max });
                                if (handleXMin && handleXMin) {
                                    if (handleXMax > handleXMin) {
                                        u.setScale('x', { min: handleXMin, max: handleXMax });
                                        !parent && setXRanges({ key: currKey, data: [handleXMin, handleXMax] });
                                    } else {
                                        u.setScale('x', { min: handleXMax, max: handleXMin });
                                        !parent && setXRanges({ key: currKey, data: [handleXMax, handleXMin] });
                                    }
                                }
                                handleXMax = null;
                                handleXMin = null;
                                handler(e);
                            }
                        };
                    } else {
                        return handler;
                    }
                },
            },
            points: {
                show: () => null,
            },
            drag: {
                y: false,
                x: false,
                click: drawRange,
            },
            x: false,
            y: false,
            setCursorSync,
            syncPos,
            // sync: {
            //     key: 'sync',
            //     setSeries: false,
            // },
        },
        series: [
            {
                label: 'Время',
                scale: 'x',
            },
            {
                label: `${name} ${unit}`,
                scale: 'y',
                points: {
                    show: fnOrSelf(isEEGAB ? false : pointWidth),
                    size: pointWidth ? pointWidth : 0,
                    fill: fnOrSelf(pointColor),
                    stroke: fnOrSelf(pointColor),
                },
                paths: isEEGAB ? uPlot.paths.bars(barOpt) : uPlot.paths.spline(), // interpolation
                stroke: fnOrSelf(color),
                fill: fnOrSelf(isEEGAB ? color : null),
                width: weight,
            },
        ],
        axes: [
            {
                scale: 'x',
                font: '8px Inter',
                size: 20,
                stroke: chartColors.tick,
                space: 70,
                values: (self, ticks) => ticks.map((rawValue) => (isEEGAB ? (rawValue === 0 ? 'альфа' : rawValue === 1 ? 'бета' : '') : isSpectr ? rawValue : +(rawValue / 1000).toFixed(3))),
                grid: {
                    show: showGrid,
                    stroke: theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light,
                    width: 1,
                },
                ticks: {
                    show: showGrid,
                    stroke: theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light,
                    width: 1,
                    size: 4,
                },
            },
            {
                show: true,
                scale: 'y',
                font: '8px Inter',
                size: 30,
                stroke: chartColors.tick,
                space: 50,
                values: (self, ticks) => ticks.map((e) => +e.toFixed(roundDigit)),
                grid: {
                    show: true,
                    stroke: theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light,
                    width: 1,
                    dash: [],
                },
                ticks: {
                    show: true,
                    stroke: theme === 'dark' ? chartColors.grid.dark : chartColors.grid.light,
                    width: 1,
                    dash: [],
                    size: 4,
                },
            },
        ],
        plugins: [wheelZoomPlugin({ setXRanges, setYMax, setYMin }), panPlugin({ setXRanges, setYMax, setYMin }), cursorPlugin(), annotationsPlugin()],
        scales: {
            x: {
                // time: false,
                auto: false,
                // auto: false,
                min: currXRange ? currXRange[0] : 0,
                max: currXRange ? currXRange[1] : defaultTimeMax,
                // range: currXRange || [0, defaultTimeMax],
            },
            y: {
                // auto: played,
                auto: false,
                min: isSignal ? -1.5 : yMin,
                max: isSignal ? 1.5 : yMax,
                // range: () => [yMin, yMax],
                // range: (u, min, max) => [min, max],
            },
        },
    };

    useEffect(() => {
        const wrap = wrapRef?.current;
        if (!wrap || !chart) return;

        let observer = new ResizeObserver((e) => updateChartSize(e, chart));
        observer.observe(wrap);

        return () => {
            setChart(null);
            observer?.disconnect();
        };
        // eslint-disable-next-line
    }, [wrapRef, chart]);

    useEffect(() => {
        if (chart) {
            chart.setSize({ width: wrapRef.current.offsetWidth, height: wrapRef.current.offsetHeight });
            updateVisibleData();
        } else {
            createChart();
        }
        // eslint-disable-next-line
    }, [chart]);

    useEffect(() => {
        if (chart) chart.actions = { played, paused };
        createChart();
        // eslint-disable-next-line
    }, [played, paused]);

    useEffect(() => {
        if (chart) {
            if (syncPos?.length) {
                chart.cursor.syncPos = syncPos;
                moveCursor(chart);
            } else {
                chart.cursor.syncPos = [];
                removeCursor(chart);
            }
        }
    }, [chart, syncPos, parent]);

    useEffect(() => {
        if (typeof num === 'string') {
            num.includes('imp') && setChannelPreffix('Имп.');
        }
    }, [num]);

    useEffect(() => {
        if (played) {
            setScreen(defaultTimeMax);
            // setSeries([]);
            setYMin(-1);
            setYMax(1);
            !parent && setXRanges({ key, data: [0, defaultTimeMax] });
            chart?.setData([[], []], true);
            chart?.setScale('x', { min: 0, max: defaultTimeMax });
            if (isSignal) {
                chart?.setScale('y', { min: -1.5, max: 1.5 });
            } else {
                chart?.setScale('y', { min: -1, max: 1 });
            }
            !isImport && delMarkers(key);
        }
        // Fix series linter
        // eslint-disable-next-line
    }, [played, key, isImport, isSignal]);

    useEffect(() => {
        if (chart && markerActions.deleteAll) {
            delMarkers(currKey);
        }
    }, [markerActions, delMarkers, currKey, chart]);

    useEffect(() => {
        chart &&
            updateChartOptions({
                chart,
                markerMode,
                weight,
                color,
                pointWidth,
                pointColor,
                theme,
                showGrid,
                isZoom,
                interpolation,
                bars: isEEGAB ? barOpt : false,
                record,
                xHandle,
                yHandle,
                markers: !isSpectr && !isEEGAB ? markers[currKey] : null,
                markerActions,
            });
        // Fix barOpt Maximum update
        // eslint-disable-next-line
    }, [markerMode, weight, color, interpolation, theme, showGrid, isZoom, pointWidth, pointColor, isEEGAB, xHandle, yHandle, markers, markerActions, record, chart]);

    useEffect(() => {
        // console.log(data[currKey]);

        if (!played && (!data[currKey] || !data[currKey].length)) {
            console.log('reset data');
            // setYMin(-1);
            // setYMax(1);
            // !parent && setXRanges({ key, data: [0, defaultTimeMax] });
            chart?.setData([[], []], true);
            // chart?.setScale('x', { min: 0, max: defaultTimeMax });
            setSeries([]);
            // setSensorRecords({ key, data: {} });
        }
        if (data) {
            updateVisibleData();
        }
        // Можно переделать, но потянет за собой переделку под useCallback кучу функций.
        // Что в свою очередь может снизить точность показаний и общую производительность.
        // eslint-disable-next-line
    }, [data, filters, played, paused]);

    useEffect(() => {
        if (isEEGAB || isSpectr) {
            updateVisibleData();
        } // eslint-disable-next-line
    }, [currXRange, currMarkersRange, isEEGAB, isSpectr]);

    useEffect(() => {
        // console.log(currMarkersRange);
        if (chart) {
            chart.annotations.markers = currMarkersRange;
            // console.log(parent);

            // if (currMarkersRange) {
            // } else {
            //     chart.annotations.markers = {};
            // }
            parent && drawRange(chart);
        }
    }, [chart, currMarkersRange, parent]);

    useEffect(() => {
        if (!played || paused) {
            setSensorRecords({ key, data: { name, roundDigit, key, unit, num: index, value: data[key] ? data[key] : series } });
        } // eslint-disable-next-line
    }, [series, setSensorRecords, name, num, key, unit, index, played, paused, isImport]);

    useEffect(() => {
        const range = xRanges[parent && !isSpectr && !isEEGAB ? parent : key];
        if (range && parent && !isSpectr && !isEEGAB) {
            chart?.setScale('y', { min: yMin, max: yMax });
            chart?.setScale('x', { min: range[0], max: range[1] });
        }
        // eslint-disable-next-line
    }, [xRanges, key, isEEGAB, isSpectr, parent]);

    const setLabeles = () => (
        <div
            className={ss.name}
            style={{ color }}
        >
            {name + ' ' + unit}
        </div>
    );

    const updateChartSize = (e, chart) => {
        const { width, height } = e[0].contentRect;
        if (chart) {
            chart.setSize({ width, height });
            drawRange(chart);
        }
    };

    const createChart = () => {
        const oldChart = wrapRef?.current?.querySelector('.uplot');
        oldChart && wrapRef?.current?.removeChild(oldChart);

        const uplot = new uPlot(opts, [], wrapRef.current);
        // Доп параметры для взаимодействия с графиком
        uplot.key = key;
        uplot.parentData = isImport ? importVals : data[currKey];
        uplot.isChildren = parent;
        uplot.annotations = {
            markers: markers[parent && !isSpectr && !isEEGAB ? parent : key],
            markerActions,
            setMarkers: !parent ? setMarkers : () => null,
            hide: isEEGAB || isSpectr,
        };
        uplot.zoomPan = {
            xHandle,
            yHandle,
        };
        uplot.actions = {
            played,
            paused,
        };

        setChart(uplot);
    };

    useEffect(() => {
        if (chart && resetViewArea) {
            const x = chart.data[0] || [];
            const y = chart.data[1] || [];
            updateChartView({ x, y });
        }
        // eslint-disable-next-line
    }, [resetViewArea, chart]);

    const updateVisibleData = () => {
        let currArr = isImport ? importVals : data[visualized ? visualized : key];

        if (filters[key]) {
            currArr = getFilteredData(currArr, filters[key]);
        }

        if ((isSpectr || isEEGAB) && currArr?.length && currArr[0]?.length > 10) {
            const box = markers[visualized]['box1'];
            const range = box ? [box.min, box.max] : xRanges[visualized];
            const minIndex = currArr[0].findIndex((e) => e >= range[0]);
            const maxIndex = currArr[0].findLastIndex((e) => e <= range[1]);
            currArr = currArr.map((e) => e.slice(minIndex, maxIndex));
            currArr = currArr[0].length >= 512 ? currArr : [[], []];
        }

        // Фикс отрисовки шкал при сбросе старого массива
        if (!currArr || !currArr[0]) {
            setYMin(-1);
            setYMax(1);
            // setSeries([]);
            return;
        } else {
            switch (name) {
                case 'Спектр':
                    setSpectrData(currArr);
                    break;

                case 'Тахограмма':
                    setTahogrammData(currArr);
                    break;

                case 'Пульс':
                    setPulseData(currArr);
                    break;

                case 'Сигнал':
                    setSignalData();
                    break;

                case 'Кнопка':
                    setSignalData();
                    break;

                case 'EEGAB':
                    setEEGABData(currArr);
                    break;

                default:
                    setDefaultData(currArr);
                    break;
            }
        }
        // eslint-disable-next-line
    };

    const setDefaultData = async (arr) => {
        updateChartView({ x: arr[0], y: arr[1] });
    };

    const setTahogrammData = (arr) => {
        const times = findAllPulseTimes(arr[0], arr[1], 0.6);
        const y = movingAverage(times.map((t, i) => +((times[i + 1] - t) / 1000).toFixed(3)).filter((e) => e));
        const x = movingAverage(times.map((t, i) => times[i + 1]).filter((e) => e));
        updateChartView({ x, y });
    };

    const setPulseData = (arr) => {
        const times = findAllPulseTimes(arr[0], arr[1], 0.8);
        const y = movingAverage(times.map((t, i) => (60 * 1000) / (times[i + 1] - t)).filter((e) => e));
        const x = movingAverage(times.map((t, i) => times[i + 1]).filter((e) => e));
        updateChartView({ x, y });
    };

    const setSignalData = () => {
        const arr = isImport ? importButtonVals : data.Button;
        arr?.length && updateChartView({ x: arr[0], y: arr[1] });
    };

    const setSpectrData = (arr) => {
        const { x, y } = getSpectr(arr);
        if (x && y) {
            updateChartView({ x, y });
        }
    };

    const setEEGABData = (arr) => {
        const { x, y } = getSpectr(arr);
        if (x?.length && y?.length) {
            const freqMax = Math.max(...x);
            const alfa = EEGrhythm(y, freqMax, 8, 13);
            const betta = EEGrhythm(y, freqMax, 13, 30);

            // console.log(alfa, betta);
            if (typeof alfa === 'number' && typeof betta === 'number') {
                updateChartView({ y: [alfa, betta], x: [0, 1] });
            }
        }
    };

    const EEGrhythm = (spectrum, freqMax, freqStart, freqEnd) => {
        const iStart = Math.ceil((freqStart / freqMax) * spectrum.length);
        const iEnd = Math.ceil((freqEnd / freqMax) * spectrum.length);
        let rhythm = 0;
        let sum = 0;

        for (let i = iStart; i < iEnd; i++) {
            if (spectrum[i]) {
                rhythm += spectrum[i] * spectrum[i];
                sum += spectrum[i];
            }
        }

        if (sum !== 0 && rhythm) {
            rhythm /= sum;
            return rhythm;
        }
    };

    const getSpectr = (arr) => {
        let times = arr[0];
        let vals = arr[1];
        let period = 2048;
        if (times.length <= 2048) {
            period = 1024;
        }
        if (times.length <= 1024) {
            period = 512;
        }
        if (times.length <= 512) {
            period = 0;
        }
        if (period) {
            const xData = times.slice(-period);
            const xRange = xData.at(-1) - xData[0];
            const x = xData.map((t, i) => (i / xRange) * 1000).slice(0, period / 2);
            const data = new ComplexArray(vals.slice(-period));
            const [...y] = data
                .FFT()
                .magnitude()
                .slice(0, period / 2)
                .map((e) => e / period);

            // isEEGAB && console.log(times, vals, data);
            // isEEGAB && console.log(x, y);
            return { x, y };
        } else {
            return { x: null, y: null };
        }
    };

    const updateChartView = ({ y, x }) => {
        let xMin, xMax;

        if (played) {
            if (offsetRec) {
                xMin = x.at(-1) >= defaultTimeMax ? x.at(-1) - defaultTimeMax : 0;
                xMax = x.at(-1) >= defaultTimeMax ? x.at(-1) : defaultTimeMax;
            } else {
                if (x.at(-1) >= screen) {
                    setScreen(screen + defaultTimeMax);
                }
                xMin = x.at(-1) >= defaultTimeMax ? screen - defaultTimeMax : 0;
                xMax = x.at(-1) >= screen ? x.at(-1) : screen;
            }
        } else {
            if (resetViewArea) {
                xMin = 0;
                xMax = x.at(-1);
                setResetViewArea(false);
            } else {
                xMin = (currXRange && currXRange[0]) || 0;
                xMax = (currXRange && currXRange[1]) || defaultTimeMax;
            }
        }

        let minIndex;
        if (isEEGAB || isSpectr) {
            minIndex = 0;
        } else {
            minIndex = x.findIndex((e) => e >= (isSpectr || isEEGAB ? 0 : xMin));
        }
        const yVis = y.length > 150000 ? y.filter((e, i) => i % 4 === 0).slice(minIndex) : y.slice(minIndex);
        let min = Math.min(...yVis);
        let max = Math.max(...yVis);
        let yMin = min === 0 ? -1 : min;
        let yMax = max === 0 ? 1 : max;
        const range = yMax - yMin;
        const valPadding = (range !== 0 ? range : 1) / 2; // Коэффициент масштабирования

        if (isSpectr || isEEGAB) {
            if (isEEGAB) {
                xMin = -0.5;
                xMax = 1.5;
                yMin = 0;
                yMax = yMax + valPadding;
            }
            if (isSpectr) {
                xMin = x[0];
                xMax = x.at(-1) + valPadding;
            }
        } else {
            xMax += offsetRec ? 200 : 0;
            if ((yMax === 0 && yMin === 0) || (isNaN(yMin) && isNaN(yMax))) {
                yMax = 1;
                yMin = -1;
            } else {
                if (filters[key]) {
                    const { maxFreq, minFreq } = filters[key];
                    const fRange = Math.abs(maxFreq - minFreq) / 1.1;
                    yMax = (fRange > 6 ? fRange / 2 : fRange * 2) || 1;
                    yMin = -(fRange > 6 ? fRange / 2 : fRange * 2) || -1;
                    // yMax = filters[key].maxFreq + (valPadding !== -Infinity ? valPadding : 0);
                    // yMin = -filters[key].maxFreq - (valPadding !== -Infinity ? valPadding : 0);
                } else {
                    yMax = yMax + valPadding;
                    yMin = yMin - valPadding;
                }
            }
        }

        if (isSignal) {
            yMax = 1.5;
            yMin = -1.5;
        }
        // console.log(xMin, xMax);
        // console.log(yMin, yMax);

        const animate = () => {
            chart?.batch(() => {
                chart?.setData([x, y], true);
                chart?.setScale('x', { min: xMin, max: xMax });
                chart?.setScale('y', { min: yMin, max: yMax });
            });
            drawRange(chart);
        };
        requestAnimationFrame(animate);

        if (isSpectr || isEEGAB) {
            setXRanges({ key, data: [xMin, xMax] });
        } else {
            !parent && setXRanges({ key: currKey, data: [xMin, xMax] });
        }
        !played || (paused && setSeries([x, y]));
    };

    return (
        <div className={ss.root}>
            <div className={ss.head}>
                <div className={ss.left}>
                    <div className={ss.legend}>
                        <div
                            className={ss.color}
                            style={{
                                background: color ? color : defaultColor,
                            }}
                        ></div>
                        <span>
                            {name} {chanelPrefix || ''}
                        </span>
                    </div>
                </div>
                <div className={ss.action}>
                    {!parent && (
                        <>
                            <Icon
                                tag="button"
                                name="timer"
                                className={offsetRec && ss.btnActive}
                                title="Смещение графика во время записи"
                                onClick={() => setOffsetRec(!offsetRec)}
                            />
                            <Icon
                                tag="button"
                                name="zoom"
                                className={isZoom && ss.btnActive}
                                title="Масштабировние фрагмента. Нажать Shift"
                                disabled={record}
                                onClick={() => setZoom(!isZoom)}
                            />
                            <Icon
                                tag="button"
                                name="capture"
                                disabled={record}
                                title="Полный масштаб"
                                onClick={() => !resetViewArea && setResetViewArea(true)}
                            />
                        </>
                    )}
                </div>
            </div>
            <div className={ss.wrap}>
                {setLabeles()}
                <div
                    className={ss.big}
                    ref={wrapRef}
                ></div>
            </div>
            <div className={ss.foot}>
                <div className={ss.axswicher}>
                    {!parent && (
                        <>
                            <label>
                                <span>X</span>
                                <Switcher
                                    checked={xHandle}
                                    onChange={() => setXHandle(!xHandle)}
                                />
                            </label>
                            <label>
                                <span>Y</span>
                                <Switcher
                                    checked={yHandle}
                                    onChange={() => setYHandle(!yHandle)}
                                />
                            </label>
                        </>
                    )}
                </div>
                <div className={ss.xlabel}>{isEEGAB ? '' : 'время сек.'}</div>
                <div className={ss.axgrid}>
                    <label>
                        <span>Сетка</span>
                        <Switcher
                            checked={showGrid}
                            onChange={() => setShowGrid(!showGrid)}
                        />
                    </label>
                </div>
            </div>
        </div>
    );
};

export default Chart;
