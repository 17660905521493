import React from 'react';
import Charts from '../../Charts';
import Aside from '../Aside';
import Ranges from '../Ranges';
import EmptyScreen from '../EmptyScreen';
import Styles from './Dashboard.module.scss';

const Dashboard = ({ sensors, imported }) => {
    // console.log(devices);
    return (
        <div className={Styles.dashboard}>
            {sensors.length || imported.length ? (
                <>
                    <Aside />
                    <Charts />
                    <Ranges />
                </>
            ) : (
                <>
                    <EmptyScreen />
                </>
            )}
        </div>
    );
};
export default Dashboard;
