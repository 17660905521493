import React, {useState, useEffect} from "react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const PIOneDragAndDropList = ({elems, setElems, keyVal, list, isDraggingLocked = false}) => {
    const handleDragEnd = (result) => {
        const { source, destination, type } = result;
        if (!destination) return;
    
        if (type === 'list') {
            const listCopy = Array.from(elems);
            const [moved] = listCopy.splice(source.index, 1);
            listCopy.splice(destination.index, 0, moved);
            setElems(listCopy);
        }
      };

    if (isDraggingLocked) {
      return list;
    }
    
    return (
        <>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="columns" direction="vertical" type="list">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className=""
              >
                {list.map((item, ind) => (
                  <Draggable
                    draggableId={'' + ind}
                    index={ind}
                    key={keyVal + ind}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                       {item}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </>
    );
}

export default PIOneDragAndDropList;