import React, {useEffect, useRef, useState} from 'react';
import parser from 'html-react-parser';
import {newsApi} from "../../../api/api";
import ss from '../../../rlab/src/components/Template/VersionModal/VersionModalItem.module.scss';

const CorVersionItem = ({item, isOpen}) => {
  const {_id, name} = item;
  const [open, setOpen] = useState(isOpen);
  const [height, setHeight] = useState(0);
  const [content, setContent] = useState('');
  const contentRef = useRef();

  useEffect(() => {
    if (open && content.length < 1) {
      newsApi.getById(_id).then(data => {
        setContent(data.content ?? '');
        setHeight(contentRef.current.scrollHeight);
      });
    } else {
      setHeight(open ? contentRef.current.scrollHeight : 0);
    }
  }, [_id, content, open]);

  const toggleVersion = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  return (
    <div key={_id} className={open ? ss.item + ' open' : ss.item}>
      <div className={ss.title}>
        <h3>{name}</h3>
        <a href={"#" + _id} className={ss.more} onClick={toggleVersion}>Подробности</a>
      </div>
      <div className={ss.content} ref={contentRef} style={{maxHeight: height}}>
        {parser(content)}
        <a href={"#" + _id} className={ss.more} onClick={toggleVersion}>Свернуть</a>
      </div>
    </div>);
};
export default CorVersionItem;
