import React, { useState, useEffect } from 'react';
import ss from './ScenarioRunDlg.module.scss';
import ScenarioOneAnswer from './ScenarioOneAnswer';
import htmlParser from "html-react-parser";
import { Button } from 'rlabui';
import Icon from '../Icon';
import { SensorViewBiology, SensorViewChemistry, SensorViewPhysics, 
    SensorViewUniversal, SensorViewOne }  from "../Template/SensorsView";

const PAGE = {
	DESC: 1,
	COMPOSITION: 2,
	GRAPHS: 3,
	ITEMS: 4,
	QUESTIONS: 5,
    REPORT: 6
};
const ScenarioRunDlg = ({showModal, isOpen, setIsOpen, scenario, 
    testPage, setTestPage, handleScenarioInfo, setGraphFile}) => {
    const [title, setTitle] = useState('');
    const [report, setReport] = useState({answer: ''});
    const [answers, setAnswers] = useState([]);

    useEffect(() => {
        const _title = testPage === PAGE.DESC ? 'Описание сценария' : 
                       testPage === PAGE.COMPOSITION ? 'Схема сборки' : 
                       testPage === PAGE.GRAPHS ? 'Графики и таблицы' : 
                       testPage === PAGE.ITEMS ? 'Шаги' : 
                       testPage === PAGE.QUESTIONS ? 'Контрольные вопросы' : 
                       testPage === PAGE.REPORT ? 'Отчет' : 
                       '';
        setTitle(_title);
        if (answers.length === 0 && scenario) {
            const a = scenario.questions.map(item => ({answer: ''}));
            setAnswers(a);
        }
    }, [testPage, scenario, answers]);

    const handleGo = step => {
        if (step >= 1 && step <= 6)
            setTestPage(step);
    };

    const handleStartAgain = () => {
        handleGo(1);
        setReport('');
        setAnswers([]);
    };

    const handleSaveValue = (cellType, currValue, cellInd) => {
        const LEN = 200;
        const value = currValue.length < LEN ? currValue : currValue.substring(0, LEN);

        if (cellType === 'report') {
            setReport({answer: value});
        } else { //'answer'
            const list = [...answers];            
            list[cellInd].answer = value;
            setAnswers(list);
        }
    };

    const handleDownloadGraph = () => {
        const data = {
            name: scenario.graph.graphName,
            data: scenario.graph.json,
          };

        setGraphFile(data);
    };

    if (!showModal) return <></>;

    return (
        <div className={isOpen ? ss.open : ss.root}>
            <div className={ss.toggle} onClick={() => setIsOpen(!isOpen)}>
                {testPage > 0 && 
                    <div className={ss.toggle__preview}>
                        <Icon name="script" />
                        <div className={ss.pagination__numbers}>
                            <span className={ss.pagination__current}>{testPage}</span>
                            <span>/</span>
                            <span>6</span>
                        </div>
                    </div>
                }
                <div className={ss.toggle__content}>
                    <b>{title}</b>
                </div>
                <div className={ss.toggle__caret}>
                    <Icon name="caret"></Icon>
                </div>
            </div>

            {isOpen && 
                <div className={ss.body}>
                    {testPage === PAGE.DESC && 
                    <div className={ss.content}>
                        <div className={ss.content__scrolled}>
                            <div className={ss.title}>
                                {scenario?.name}
                            </div>
                            <div className={ss.description}>
                                {scenario?.description ? htmlParser(scenario?.description) : ''}
                            </div>
                        </div>
                    </div>
                    }

                    {testPage === PAGE.COMPOSITION && 
                    <div className={ss.content}>
                        <div className={ss.content__scrolled}>
                            <div className={ss.title}>
                                {scenario?.composition?.name}
                            </div>
                            <div className={ss.description}>
                                {scenario?.composition?.description ? htmlParser(scenario?.composition?.description) : ''}
                            </div>

                            {scenario?.composition?.attachmentType === 2 && 
                                <div className={ss.img}>
                                    <img src={scenario.composition.fileUrl} alt='' />
                                </div>
                            }
                            {scenario?.composition?.attachmentType === 1 &&
                                <div className={ss.sensor}>
                                    {scenario.composition.attachmentName === 'Biology' && <SensorViewBiology />}
                                    {scenario.composition.attachmentName === 'Chemistry' && <SensorViewChemistry />}
                                    {scenario.composition.attachmentName === 'Physics' && <SensorViewPhysics />}
                                    {scenario.composition.attachmentName === 'Universal' && <SensorViewUniversal />}
                                    {scenario.composition.attachmentName === 'One' && <SensorViewOne />}
                                </div>
                            }
                        </div>
                    </div> 
                    }
                   
                   {testPage === PAGE.GRAPHS && 
                    <div className={ss.content}>
                        {scenario.graph?.json && 
                        <div className={ss.content__scrolled}>
                            <div className={ss.doc}>
                               <Icon name="file-word" />
                               <span className={ss.doc__title}>{scenario.graph.graphName}</span>
                            </div>
                            <div className={ss.btn}>
                                <Button color="primary" onClick={() => handleDownloadGraph()}>
                                    Импортировать график
                                </Button>
                            </div>
                        </div>
                        }
                    </div> 
                    }
                   
                   {testPage === PAGE.ITEMS && 
                    <div className={ss.content}>
                        <div className={ss.content__scrolled}>
                            <div className={ss.questions}>
                            {
                            scenario.items.map((item, ind) => 
                                <div className={ss.questions__item}  key={'it1'+ind}>
                                    <div className={ss.questions__title}  key={'it2'+ind}>
                                        Шаг {ind+1}.
                                    </div>
                                    <div className={ss.questions__title} key={'it3'+ind}>
                                        {item.name}
                                    </div>
                                    <div className={ss.description} key={'it4'+ind}>
                                        {item.description ? htmlParser(item.description) : ''}
                                    </div>
                                    <div className={ss.img} key={'it5'+ind}>
                                        <img src={item.fileUrl} alt='' />
                                    </div>
                                </div>
                            )}
                            </div>
                        </div>
                    </div> 
                    }

                    {testPage === PAGE.QUESTIONS && 
                    <div className={ss.content}>
                        <div className={ss.content__scrolled}>
                            <div className={ss.questions}>
                            {
                            scenario?.questions?.map((item, ind) => 
                                <div className={ss.questions__item} key={'q1'+ind}>
                                    <div className={ss.questions__title} key={'q2'+ind}>
                                        {ind+1}. {item} 
                                    </div>
                                    <div className={ss.questions__input} key={'q3'+ind}>
                                        <span className={ss.questions__label}>Ваш ответ</span>
                                        <div key={'q4'+ind}>
                                            <ScenarioOneAnswer 
                                                cellInd={ind}
                                                cellType={'answer'}
                                                cellValue={answers[ind].answer}
                                                handleSaveValue={handleSaveValue}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            </div>
                        </div>
                    </div>               
                    }

                    {testPage === PAGE.REPORT && 
                    <div className={ss.content}>
                        <div className={ss.content__scrolled}>
                            <div className={ss.questions}>

                            <div className={ss.questions__item}>
                                    <div className={ss.questions__title}>Напишите отчет о проделанной работе</div>
                                    <div className={ss.questions__input}>
                                        <span className={ss.questions__label}>Ваш ответ</span>
                                        <ScenarioOneAnswer 
                                            cellInd={0}
                                            cellType={'report'}
                                            cellValue={report.answer}
                                            handleSaveValue={handleSaveValue}
                                        />
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                    }

                    {testPage > 0 && 
                        <div className={ss.bottom}>
                                {testPage < 6 ?
                                    <div className={ss.nav}>
                                        <button className={ss.next} type="button" onClick={() => handleGo(testPage+1) }>
                                            Перейти к следующему этапу<Icon name="toggler" />
                                        </button>
                                    </div>
                                    : 
                                    <div className={ss.btns}>
                                        <Button border={true} onClick={handleStartAgain}>
                                            Начать заново
                                        </Button>
                                        <Button onClick={() => handleScenarioInfo(report, answers)}>
                                            Завершить и зафиксировать результат
                                        </Button>
                                    </div>
                                }
                         
                     
                                <div className={ss.pagination}>
                                    <Button 
                                        disabled={testPage === 1}
                                        onClick={() => handleGo(testPage-1)}>
                                        <Icon name="arrow-round-prev" />
                                    </Button>
                                    <div className={ss.pagination__numbers}>
                                        <span className={ss.pagination__current}>{testPage}</span>
                                        <span>из</span>
                                        <span>6</span>
                                    </div>
                                    <Button 
                                        disabled={testPage === 6}
                                        onClick={() => handleGo(testPage+1) }>
                                        <Icon name="arrow-round-next" />
                                    </Button>
                                </div>
                        </div>
                     

                    }
                
                </div>
            }
        </div>
    )
};

export default ScenarioRunDlg;
