import React, {useState} from 'react';
import { Icon } from '../ui';
import "./ConnectSettings.scss";
import socket from "../../socket";
import {fullName, isAuth} from "../../helpers/auth";
import {toast} from "react-toastify";

const ConnectSettings = (props) => {
    const [raiseDisabled, setRaiseDisabled] = useState(0);

    const toggleSilence = () => {
        const mode = !props.silence;
        props.toggleSilence(mode);
        socket.emit('BE-toggle-silence', {roomId: props.room._id, mode, user: isAuth()});
    };

    const raiseDisabledTimer = (timer) => {
        if (timer > 0) {
            setRaiseDisabled(--timer);
            if (timer > 0) setTimeout(() => raiseDisabledTimer(timer), 1000);
        }
    }

    const raiseHand = () => {
        if (raiseDisabled === 0) {
            raiseDisabledTimer(10);
            socket.emit('BE-raise-hand', {roomId: props.room._id, user: isAuth()});
            toast.info(fullName(isAuth()) + " поднял руку");
        }
    };

    return (
        <div className="connectSettings">
            {isAuth().role === 2 &&
                <div className={"connectSettings__item" + (props.silence ? " active danger" : "")}>
                    <Icon name="silence"/>
                    {props.aside &&<span>Режим тишины</span>}
                </div>}
            {isAuth().role === 1 &&
                <div className={"connectSettings__item link" + (props.silence ? " active danger" : "")}
                     onClick={toggleSilence}>
                    <Icon name="silence"/>
                    {props.aside &&<span>Режим тишины</span>}
                </div>}
            {isAuth().role === 0 &&
                <div className={"connectSettings__item link" + (raiseDisabled > 0 ? "" : " active") + (props.silence ? " danger" : "")}
                     onClick={raiseHand}>
                    <Icon name="hand"/>
                    {props.aside &&<span>Поднять руку {raiseDisabled > 0 ? "(" + raiseDisabled + ")" : ""}</span>}
                </div>}
        </div>
    )
};
export default ConnectSettings;
