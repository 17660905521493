import React from 'react';
import './modal.scss';

const Modal = (props) => {
    const {visible, content, size} = props;
    return (
      <div className={visible ? "modal open" : "modal"}>
          <div className={size ? `modal__dialog modal__dialog_${size}` : "modal__dialog"}>{content}</div>
      </div>
    )
};
export default Modal;
