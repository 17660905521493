import { toast } from 'react-toastify';
import { setStream, setStreamClosed } from '../actions/stream';
import { disconnect, connect } from './device';
import { setSensor, checkHiSpeed } from '../actions/sensors';
import { setConnectionType, setConnectionLoss, togglePort } from '../actions/port';
import { setDeviceConnectedLoader, setDeviceReconnect } from '../actions/devices';
import { setReading, setSensorReading } from '../actions/reader';
import { LineBreakTransformer } from './utils/line-break-transform';
// import { resetReduxState } from '../actions/actions';

const textDecoder = new TextDecoder('utf-8');
// const usbVendorId = [
//     0x0403, 0x6001, 0x6015, 0x03eb, 0x2044, 0x2341, 0x0001, 0x0010, 0x003b, 0x003f, 0x0042, 0x0043, 0x0044, 0x8036, 0x8037, 0x16c0, 0x0483, 0x1eaf, 0x0004, 0x10c4, 0xea60, 0xea70, 0xea71, 0xea80,
//     0x067b, 0x2303, 0x1a86, 0x7523,
// ];

let usbDispatch = () => null;
let freqInterval;
let freqPerSec = 0;
let hightFreqMode = false;

let buffer = '';
let bufferCheck = '';
let sub = '';
let refresh = 0;
let readingNow = false;
let isOscilloscope = false;
let oscilloSplit;
// const { isAndroid } = window;

export const connectDisconnect = (serial) => async (dispatch, getState) => {
    usbDispatch = dispatch;
    const {
        port: { open, connectionType },
    } = getState();

    if (open) {
        if (connectionType) {
            dispatch(setReading(false));
            // console.log('Disconnect');
            dispatch(disconnect());
        } else {
            dispatch(usbConnectReconnect(serial));
        }
    } else {
        dispatch(usbConnectReconnect(serial));
    }
};

export const usbConnectReconnect = (serial) => async (dispatch) => {
    if (serial) {
        // serial.readable = { isAndroid: true, getReader: () => serial };
        // serial.writable = { isAndroid: true, getWriter: () => serial };

        await serial
            .connectedDevices()
            .then((connected) => {
                // console.log(JSON.stringify(connected));
                dispatch(setConnectionType('usb'));
                dispatch(connect(serial, connected.devices?.[0]));
                dispatch(setDeviceConnectedLoader(false));
            })
            .catch((e) => {
                console.log(e);
                if (e === 'No device found!') {
                    toast.error('Проверьте подключение датчика к устройству и исправность датчика и провода.', 'Датчик не подключён!');
                }
                dispatch(setDeviceConnectedLoader(false));
            });
    } else {
        await navigator.serial
            .requestPort()
            .then((selectedPort) => {
                // console.log(selectedPort);
                // dispatch(setPort([selectedPort]));
                dispatch(setConnectionType('usb'));
                dispatch(connect(selectedPort));
                dispatch(setDeviceConnectedLoader(false));
            })
            .catch((error) => {
                console.log('Connection error: ' + error);
                if (error.message.includes('No port selected')) {
                    toast.info('Не выбран датчик для подключения');
                } else {
                    toast.error('Произошла ошибка при подключении');
                }
                dispatch(setDeviceConnectedLoader(false));
            });
    }
};

export const reading = () => async (dispatch, getState) => {
    // const state = getState();
    const { isAndroid } = window;
    // console.log(isAndroid);

    const {
        reader: { isReading },
        port: { data },
    } = getState();

    let readableStreamClosed;

    const textDecoder = new TextDecoderStream('UTF-8');

    const stream = isAndroid ? data[0] : textDecoder.readable.pipeThrough(new TransformStream(new LineBreakTransformer())).getReader();

    // const stream = isAndroid ? data[0] : data[0]?.readable?.getReader();
    // console.log(1, stream);

    if (stream) {
        await dispatch(setStream([stream]));

        // console.log(2, stream);
        // try {
        if (isAndroid) {
            console.log('subscribe', data[0]);
            // data[0]?.registerReadCallback((e) => dispatch(processRead(e)), error);
            stream.addListener('data', (e) => {
                dispatch(processRead(e.data));
            });
        } else {
            readableStreamClosed = data[0]?.readable?.pipeTo(textDecoder.writable);
            await dispatch(setStreamClosed(readableStreamClosed));
            // console.log(3, stream);
            stream
                .read()
                .then((e) => {
                    // console.log(4, stream);
                    // console.log(e);
                    dispatch(processRead(e));
                })
                .catch((e) => {
                    // console.log(5, stream);
                    // console.log(e);
                    if (!isAndroid) stream.releaseLock();
                    error(e);
                });
        }
        // } catch (e) {
        //     console.log('reading catch error === ', e);
        //     toast.info('Датчик отключен');
        //     buffer = '';
        //     dispatch(setConnectionLoss(false));
        // } finally {
        //     console.log('reading final');
        //     buffer = '';
        //     if (!isAndroid) stream?.releaseLock();
        //     dispatch(disconnect());
        // }
    }
};

function error(e) {
    console.log('reading catch while error ===', e);
    toast.error('Потеряно соединение с датчиком');
    usbDispatch(setConnectionLoss(true));
    // dispatch(setDeviceConnectedCount(0));
    usbDispatch(setConnectionType(null));
}

let hightSpeedBuffer = [];

const processRead = (e) => async (dispatch, getState) => {
    const { isAndroid } = window;

    // console.log(e);
    let { done, value } = e;
    const {
        port: { data, responses, connectionType },
        stream: { data: portStream },
        sensors: { isHiSpeed, list: sensorsList },
        devices: { list: devices, all: allDevices },
    } = getState();

    const stream = isAndroid ? data[0] : portStream[0];
    dispatch(setConnectionLoss(false));
    const keys = Object.values(devices).map((e) => e.attributes?.key);

    if (done) {
        if (!isAndroid) stream.releaseLock();
        clearInterval(freqInterval);
        freqInterval = null;
    } else {
        const val = isAndroid ? e : value;
        // sub = isAndroid ? val : textDecoder?.decode(val);
        sub = val;
        buffer += sub;
        // console.log(val);

        if (buffer.includes('Brownout detector was triggered')) {
            toast.warn('Проблема с подключением (низкий заряд батареи или проблемы с USB)', 'Ошибка чтения данных');
            console.log(buffer);
            buffer = '';
            stream.releaseLock();
            dispatch(disconnect());
            return;
        }
        if (buffer.includes('�')) {
            buffer = buffer.replace('�', ' ');
        }

        if (buffer.includes('\x03') || buffer.includes('@')) {
            console.log(buffer);
            buffer = '';
            // console.log(buffer);
            // stream.releaseLock();
            // return stream.read().then((e) => dispatch(processRead(e)));
            toast.warn('Переподключите датчик для дальнейшего использования', 'Ошибка чтения данных');
            dispatch(connectDisconnect());
            // dispatch(disconnect());
            // return;
        }

        if (buffer.includes('\r\n')) {
            const bufferArr = buffer.split(' ')[0];
            const checkResponse = responses.filter((r) => r === bufferArr)[0];

            // Проверка смены подключенного по usb датчика
            if (connectionType === 'usb' && sensorsList?.length && !checkResponse) {
                const checkConnectedDevice = keys.map((e) => bufferArr.includes(e))[0];
                if (!checkConnectedDevice) {
                    const checkKeys = allDevices.filter((e) => e.attributes.key === bufferArr?.split('-')[0])[0];
                    if (checkKeys) {
                        dispatch(setDeviceReconnect(true));
                        return;
                    }
                }
            }

            if (buffer.includes('osc2c5f4v3s') || buffer.includes('osc2c4f4v')) {
                isOscilloscope = true;
                oscilloSplit = buffer.split('\r\n');
            }

            if (buffer.includes('rlg')) {
                const checkReadStart = buffer.split(' ');
                readingNow = true;
                if (!isNaN(+checkReadStart[1])) {
                    if (+checkReadStart[1] === 0) {
                        readingNow = false;
                        dispatch(setSensorReading(false));
                    } else {
                        dispatch(setSensorReading(true));
                    }
                    // console.log(checkReadStart[1]);
                }
            }

            if (readingNow && buffer.includes('rlg error')) {
                const resp = buffer.replace('\r\n', '').split(' ');
                if (+resp[2] < 0) {
                    dispatch(setSensorReading(false));
                    readingNow = false;
                    toast.warn('Ошибка чтения сохраненных данных');
                }
            }

            if (readingNow) {
                bufferCheck += buffer;
            }

            if (readingNow && bufferCheck.includes('rlg error 0')) {
                dispatch(setSensorReading(false));
                try {
                    const arr = bufferCheck.split('\r\n');
                    async function sendWritted(arr) {
                        let i = 1;
                        for await (const item of arr) {
                            if (!item.includes('rlg')) {
                                dispatch(setSensor(item + ' ' + (checkResponse ? '' : refresh++)));
                                if (i === arr.length) {
                                    toast.success('Чтение сохраненных данных завершено');
                                }
                            } else {
                                dispatch(setSensor(item));
                            }
                            i++;
                        }
                    }
                    sendWritted(arr);
                } catch (e) {
                    console.log('Reading usb error ===', e);
                    readingNow = false;
                } finally {
                    bufferCheck = '';
                    readingNow = false;
                }
            } else {
                if (!readingNow) {
                    if (isOscilloscope) {
                        dispatch(setSensor(oscilloSplit[0]));
                    } else {
                        // console.log(buffer);
                        const splited = buffer.split('\r\n').filter((e) => e);
                        if (splited?.length) {
                            // console.log(buffer);
                            if (isHiSpeed) {
                                hightSpeedBuffer.push(...splited);
                                if (hightSpeedBuffer.length > 10) {
                                    dispatch(sendHiSpeedData(hightSpeedBuffer));
                                    hightSpeedBuffer = [];
                                }
                            } else {
                                dispatch(setSensor(splited[0] + ' ' + (checkResponse ? '' : refresh++)));
                            }
                            freqPerSec += splited.length;
                        }

                        if (!freqInterval) {
                            freqInterval = setInterval(() => {
                                // console.log('freqPerSec', freqPerSec);
                                if (freqPerSec > 80) {
                                    dispatch(checkHiSpeed(true));
                                } else {
                                    dispatch(checkHiSpeed(false));
                                }
                                freqPerSec = 0;
                            }, 1000);
                        }
                    }
                }
            }

            if (isOscilloscope) {
                buffer = oscilloSplit[1];
            } else {
                if (isHiSpeed) {
                    buffer = buffer.split('\r\n').at(-1);
                } else {
                    buffer = '';
                }
            }
        }

        if (isAndroid) {
            // return stream.read((e) => dispatch(processRead(e)), error);
            // return stream.registerReadCallback().subscribe((e) => dispatch(processRead(e)), error);
        } else {
            return stream.read().then((e) => dispatch(processRead(e)));
        }
    }
};

let semyArrEcg = [];
const sendHiSpeedData = (arr, checkResponse) => async (dispatch, getState) => {
    const {
        // port: { responses },
        // stream: { data: portStream },
        // actions: { played, paused },
        // sensors: { list: sensorsList },
        timer: { timer },
    } = getState();

    // const sensKey = sensorsList[0].unicName;
    // console.log(sensKey);
    // let str = '';
    // if (played && !paused) {
    //     // semyArrEcg = [...semyArrEcg, ...arr];
    //     // // console.log(semyArrEcg);
    //     // if (semyArrEcg.length >= 20) {
    //     //     // for await (const item of semyArrEcg) {
    //     //     //     const val = item.split(' ')[1];
    //     //     //     if (item.length && item.includes(sensKey) && !isNaN(+val)) {
    //     //     //         // freqPerSec++;
    //     //     //         // if (freqPerSec > 200) {
    //     //     //         //     str = item + '\r\n';
    //     //     //         // } else {
    //     //     //         str = item + '\r\n';
    //     //     //         // }
    //     //     //     }
    //     //     //     // dispatch(setSensor(str + '\r\n ' + (checkResponse ? '' : refresh++)));
    //     //     // }
    //     //     semyArrEcg = [];
    //     // }
    // } else {
    //     // if (!sensorsList.length) {
    //     //     refresh = 0;
    //     //     // dispatch(setSensor(arr[0] + '\r\n ' + (checkResponse ? '' : refresh++)));
    //     //     semyArrEcg = [];
    //     // }
    // }
    // dispatch(setSensor(played ? str : arr[0] + '\r\n ' + (checkResponse ? '' : refresh++)));
    // console.log(timer);
    // console.log(arr[0]);
    dispatch(setSensor(arr[0] + '\r\n ' + (checkResponse ? '' : timer)));
};
