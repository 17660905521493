import React, {useState, useEffect} from 'react';
import {toast} from "react-toastify";
import { getDropdown } from "../ui/utils/gen_utils";
import { getUniqueGroups } from "./pi_utils";
import Table from "../ui/Table/Table";
import {useDispatch, useSelector} from "react-redux";
import {loadPIObjects} from "../../redux/slices/lists";
import {storageClass} from "../../redux/slices/storage";
import { Modal, Button, Input } from 'rlabui';
import ss from './PISelectObjectDlg.module.scss';

const PISelectObjectDlg = ({showDlg, setShowDlg, objectIds, handleAddObjects}) => {
	const [uniqueObjectGroups, setUniqueObjectGroups] = useState([]);
	const [testFilter, setTestFilter] = useState('');
	const [selectedGroupId, setSelectedGroupId] = useState(-1); //фильтрация по группе
	const [investObjects, setInvestObjects] = useState([]);

    const dispatch = useDispatch();
    const {lists: {pi}} = useSelector(state => state);
    const documentClass = useSelector(storageClass);

      useEffect(() => {
        setUniqueObjectGroups(getUniqueGroups(pi.objects));
    }, [pi.objects]);

    useEffect(() => {
        setUniqueObjectGroups([]);
        setTestFilter('');
        setSelectedGroupId(-1);
        setInvestObjects([]);
    
        dispatch(loadPIObjects(documentClass._id));
      }, [dispatch, documentClass._id, showDlg]);

    useEffect(() => {
        if (!showDlg) return;

        //find all objects. The objects will refer to the list of the unique groups:
        const objects = getObjectsWithUniqueGroups(pi, uniqueObjectGroups, objectIds);

        setSelectedGroupId(-1);
        setInvestObjects(objects);
    }, [showDlg, uniqueObjectGroups, objectIds, pi]);

    const getFilteredObjects = () => {
        let filteredObjs = investObjects.map(item => ({
            name: item.label,
            group: item.group, 
            actions: '',
            id: item.id, 
        }));

        if (testFilter) {
            filteredObjs = filteredObjs.filter(item => item.name.toLowerCase().includes(testFilter.trim().toLowerCase()));
        }

        if (selectedGroupId !== -1) {
            const group = uniqueObjectGroups.find(item => item.value === selectedGroupId)
            const groupName = group.label;
            filteredObjs = filteredObjs.filter(item => item.group === groupName);
        }

        return filteredObjs;
    };

    const getObjectsWithUniqueGroups = (pi, uniqueGroups, selObjectIds) => {
        //find all objects. The objects will refer to the list of the unique groups:
        const _objects = pi.objects.map(item => ({label: item.name, id: item._id, group: item.group}));
        const objects = [];
        
        for (let i = 0; i < _objects.length; i ++) {
            //take an object:
            const object = _objects[i];
            const elem = uniqueGroups.find(item => item.label === object.group);
            const groupId = elem ? elem.value : -1;
            const label = elem ? elem.label : '';
    
            //set reference to its unique group:
            objects.push({
                label: object.label, 
                id: object.id + '|' + (selObjectIds.find(item => item.id === object.id) ? 1 : 0), 
                group: label, 
                groupId: groupId
            });
        }
        return objects;
    };
    
    const handleSwitchRow = (selectedId) => {
		const list = [];
        const selId = selectedId.split('|')[0];

		for (let i = 0; i < investObjects.length; i ++) {
			let obj = investObjects[i];
            let obj_ids = obj.id.split('|');
            const currId = obj_ids[0];

            if (currId === selId) {
                const state = obj_ids[1];
                //переключаем состояние выбранного объекта
                obj.id = currId + (state === '0' ? '|1' : '|0');
            } 

			list.push(obj);
		}

		setInvestObjects(list);
    };

    const getTableHeader = () => {
		const header = [
			{column: 'Название объекта', name: 'name', style: { width: '45%'} }, 
			{column: 'Группа', name: 'group', style: { width: '30%'} }, 
			{column: 'Объект', name: 'actions', style: { width: '25%'} }, 
			{column: 'id', name: 'id', style: { width: '0%'} }, 
        ];

		return header;
	};

    const getTable = () => {
        return (
            <Table 
                className="pi__objectList"
                table={{
                    header: getTableHeader(), 
                    data: getFilteredObjects()
                }}
                actions={{
                    handleSwitch: ids => handleSwitchRow(ids),
                }}
            />
        );        
    };

    const handleSave = () => {
        const saveIds = [];
		for (let i = 0; i < investObjects.length; i ++) {
            const ids = investObjects[i].id;
			const arr = ids.split('|');
            if (arr[1] === '1')
                saveIds.push(arr[0]);
		}

        if (saveIds.length === 0)
            toast.error('Выберите объекты.');
        else {
            handleAddObjects(saveIds); //сейчас выбираем каждый раз только один объект
            setShowDlg(false);
        }
    };
    
    if (!showDlg) {
        return false;
    }
    
    return (
        <Modal visible={showDlg} size="lg">
            <Modal.Head modalClose={() => setShowDlg(false)} title='Выберите объект' />
            <div className="cor-net__row">
                <div className="cor-net__col col-2">
                    <div className="cor-net__label">Поиск объекта</div>
                    <Input className="pi__goalInput" value={testFilter} onInput={e => setTestFilter(e.target.value)} />
                </div>
                <div className="cor-net__col col-2">  
                    <div className="cor-net__label">Фильтр по группе</div>
                    {getDropdown(uniqueObjectGroups, selectedGroupId, value => setSelectedGroupId(value), '', '', 'grp01')}
                </div>
            </div>
            <Modal.Body className={ss.modalBody}>
                {getTable()}
            </Modal.Body>
            <Modal.Footer>
                <Button color="primary" border={false} onClick={() => handleSave()}>Сохранить</Button>
                <Button color="primary" border={true} onClick={() => setShowDlg(false)}>Отменить</Button>
            </Modal.Footer>
        </Modal>
    )
};

export default PISelectObjectDlg;
