import React, {useEffect, useState} from 'react';
import {Unity, useUnityContext} from "react-unity-webgl";
import {isAuth} from "../../helpers/auth";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {storageClass} from "../../redux/slices/storage";
import {vrLabInfoApi} from "../../api/api";
import ss from "./VrLab.module.css";

const vrDir = "/vr-lab/StandBuild/";
const vrBuild = vrDir + "Build/StandBuild";

const VrLab = (props) => {
    const [currVrLabInd, setCurrVrLabInd] = useState(props.match.params.id);
    const [vrLabId, setVrLabId] = useState(undefined);
    const [vrLabStepAmount, setVrLabStepAmount] = useState(0);
    const documentClass = useSelector(storageClass);

    const {unityProvider, loadingProgression, isLoaded,
        addEventListener, removeEventListener, sendMessage,
        UNSAFE__detachAndUnloadImmediate: detachAndUnloadImmediate
    } = useUnityContext({
        loaderUrl: vrBuild + ".loader.js",
        dataUrl: vrBuild + ".data",
        frameworkUrl: vrBuild + ".framework.js",
        codeUrl: vrBuild + ".wasm",
        streamingAssetsUrl: vrDir + "StreamingAssets",
    });

    const getVrLabData = async (_currVrLabInd, documentClassId, _sendMessage) => {
        const result = await vrLabInfoApi.getVrLabInfoByOwnerIdByVrLabInd(isAuth()._id, _currVrLabInd)
        .then((res) => {
            if (res.data.length > 1) console.log('getVrLabData: owner=', isAuth()._id, ' game ind=', _currVrLabInd, 'game records=', res.data.length);
            return res;
        }).catch((err) => {
            toast.error(err.response.data.errors);
        });

        let info;
        let stepState = '';
        if (result && result.data.length > 0) {
            info = result.data[0];
            if (info.stepState) stepState = info.stepState;
            setVrLabStepAmount(info.stepAmount ? Number(info.stepAmount) : 0);
        } else {
            const data = {
                room: documentClassId,
                vrLabInd: _currVrLabInd,
                stepAmount: 0,
                step: 0,
                stepState: '',
                isComplete: false,
            }

            info = await vrLabInfoApi.addVrLabInfo(data).then((res) => {
                return res;
            }).catch((err) => {
                toast.error(err.response.data.errors);
            });
        }

        setVrLabId(info._id);
        const str = '' + _currVrLabInd + ' @#$' + (stepState ? stepState:  '');
        _sendMessage('BridgeMediator', 'OpenLabWork', str);
    };

    const doUpdate = async (_vrLabId, _setIsUpdated, obj, isState = false) => {
        if (!isState) {
            await vrLabInfoApi.updateVrLabInfoById(_vrLabId, obj)
            .catch((err) => {
                toast.error(err.response.data.errors);
            });
        } else {
            await vrLabInfoApi.updateVrLabInfoStateById(_vrLabId, obj)
            .catch((err) => {
                toast.error(err.response.data.errors);
            });
        }
   };

    const handleGoBack = () => props.history.goBack();
    const handleButtonQuitLabWorkPressed = () => props.history.push('/vrlab');

    const handleReload = async (newVrLabInd) => {
        setCurrVrLabInd(Number(newVrLabInd));
        await getVrLabData(Number(newVrLabInd), documentClass._id, sendMessage);
    };
    const handleStepAmount = async (amount) => {
        await doUpdate(vrLabId, {stepAmount: amount}, 'handleStepAmount');
        setVrLabStepAmount(amount);
    };
    const handleStepCompleted = async (step) => {
        const data = {step: Number(step), isComplete: false};
        await doUpdate(vrLabId, data, 'handleStepCompleted');
    };
    const handleOnDataSent = async (stepState) => {
        await doUpdate(vrLabId, {stepState: stepState}, 'handleOnDataSent', true);
    };
    const handleLabWorkCompleted = async () => {
        await doUpdate(vrLabId, {stepState: ''}, 'lastStepClear', true);
        const data = {step: vrLabStepAmount, isComplete: true};
        await doUpdate(vrLabId, data, 'handleLabWorkCompleted');
    };

    useEffect(() => {
        addEventListener("OnReloadLabWork", handleReload);
        addEventListener("OnStepAmount", handleStepAmount);
        addEventListener("OnStepCompleted", handleStepCompleted);
        addEventListener("OnDataSent", handleOnDataSent);
        addEventListener("OnLabWorkCompleted", handleLabWorkCompleted);
        addEventListener("OnButtonBackToCorePressed", handleGoBack);
        addEventListener("OnButtonQuitLabWorkPressed", handleButtonQuitLabWorkPressed);

        return () => {
            removeEventListener("OnReloadLabWork", handleReload);
            removeEventListener("OnStepAmount", handleStepAmount);
            removeEventListener("OnStepCompleted", handleStepCompleted);
            removeEventListener("OnDataSent", handleOnDataSent);
            removeEventListener("OnLabWorkCompleted", handleLabWorkCompleted);
            removeEventListener("OnButtonBackToCorePressed", handleGoBack);
            removeEventListener("OnButtonQuitLabWorkPressed", handleButtonQuitLabWorkPressed);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!isLoaded) return;
        if (currVrLabInd) {
            //1. запускается у ученика при запуске тестирования учителем
            //2. запускает учитель в Демо при выборе конкретной работы
            getVrLabData(Number(currVrLabInd), documentClass._id, sendMessage);
        } else {
            //запускает учитель или ученик по нажатию иконки Ракета (конкретная работа не выбрана)
        }
    }, [isLoaded, sendMessage, currVrLabInd, documentClass._id]);

    useEffect(() => {
        return () => {
            detachAndUnloadImmediate().catch();
        }
    }, [detachAndUnloadImmediate]);

    return (<>
        {!isLoaded &&
          <div className="cor_dashboardBox__top">
              Загрузка... {Math.round(loadingProgression * 100)}%
          </div>}
        <div className={ss.vrlab}>
            <Unity unityProvider={unityProvider}/>
        </div>
    </>);
};
export default VrLab;

/*
1. запуск конкретной работы у класса - в лаб.работах, оптика в режиме тестирования 
  в этом случае для каждого ученмка запускается - 
  передается sendMessage('CustomBridge', 'OpenLabWork', Number(_currVrLabInd), stepState);
2. запуск конкретной работы у учителя - то же что в пункте 1.
3. учитель и ученик могут запустить игру по нажатию иконки Ракета. В этом случае ничего не передается.
*/
