import React from 'react';
import "../Content.scss";

const ContentDesc = ({children, className = '', desc}) => {
    return (
        <div className={"cor_content__desc_wrap " + className}>
            {desc && <div className="cor_content__desc">{desc}</div>}
            {children}
        </div>
    )
};
export default ContentDesc;
