import { setEventLoader } from '../../actions/app';
import { setSensorImported } from '../../actions/sensor';
import { setPrintModal } from '../../actions/import';
import { setChartMarkers } from '../../actions/chart';
import { sendCommand } from '../../actions/port';
import { setFile } from '../../actions/import';
import { setExportModal, setExportData, setFileExtension } from '../../actions/export';

export const mapStateToProps = (nstate) => {
    return {
        port: nstate.nport.open,
        played: nstate.napp.played,
        devices: nstate.ndevice.all,
        records: nstate.nsensor.records,
        colors: nstate.nchart.settings.colors,
        file: nstate.nimport.file,
        fileExtension: nstate.nimport.fileExtension,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setSensorImported: (arr) => dispatch(setSensorImported(arr)),
    setChartMarkers: (obj) => dispatch(setChartMarkers(obj)),
    sendCommand: (str) => dispatch(sendCommand(str)),
    setPrintModal: (bool) => dispatch(setPrintModal(bool)),
    setFile: (str) => dispatch(setFile(str)),
    setFileExtension: (str) => dispatch(setFileExtension(str)),
    setExportModal: (str) => dispatch(setExportModal(str)),
    setExportData: (obj) => dispatch(setExportData(obj)),
    setEventLoader: (text) => dispatch(setEventLoader(text)),
});
