import { ReactComponent as User } from '../../../assets/icons/card-menu/15_user.svg';
import { ReactComponent as Calendar } from '../../../assets/icons/card-menu/14_calendar.svg';
import { ReactComponent as Chart } from '../../../assets/icons/chart.svg';
import { ReactComponent as Homework } from '../../../assets/icons/house.svg';
import { ReactComponent as LabWork } from '../../../assets/icons/lab.svg';

export const PRIORITY_DATA = [
  {
    name: 'important',
    title: 'Важно!',
    icon: <Chart style={{ stroke: '#F35151' }} />,
    group: 'priority',
    priority: 1,
  },
  {
    name: 'urgently',
    title: 'Срочно',
    icon: <Chart style={{ stroke: '#F3C651' }} />,
    group: 'priority',
    priority: 2,
  },
  {
    name: 'normal',
    title: 'Нормально',
    icon: <Chart style={{ stroke: '#41cb47' }} />,
    group: 'priority',
    priority: 3,
  },
  {
    name: 'notImportant',
    title: 'Не важно',
    icon: <Chart style={{ stroke: '#a7b0b8' }} />,
    group: 'priority',
    priority: 4,
  },
];

export const TYPE_DATA = [
  {
    name: 'homework',
    title: 'Домашняя работа',
    icon: <Homework style={{ stroke: '#ce5a92' }} />,
    group: 'type',
  },
  {
    name: 'labWork',
    title: 'Лабораторная работа',
    icon: <LabWork />,
    group: 'type',
  },
];
export const USERS_DATA = [
  {
    name: 'user1',
    title: 'User 1',
    // icon: <Homework style={{ stroke: '#ce5a92' }} />,
    group: 'user',
  },
  {
    name: 'user2',
    title: 'User 2',
    // icon: <LabWork />,
    group: 'user',
  },
];

export const STICKERS_DATA = {
  // executor: {
  //   name: 'executor',
  //   title: 'Исполнитель',
  //   icon: <User />,
  //   // subitems: USERS_DATA,
  // },
  deadline: {
    name: 'deadline',
    title: 'Дедлайн',
    icon: <Calendar />,
    subitems: [{ group: 'date', el: <input type="date" />, name: 'date' }],
  },
  priority: {
    name: 'priority',
    title: 'Приоритет',
    icon: <Chart style={{ stroke: '#595C6C' }} />,
    subitems: PRIORITY_DATA,
  },
  type: {
    name: 'type',
    title: 'Тип работ',
    icon: <Homework style={{ stroke: '#595C6C' }} />,
    subitems: TYPE_DATA,
  },
};

export const STICKERS_MODAL_DATA = {
  priority: {
    name: 'priority',
    title: 'Приоритет',
    icon: <Chart style={{ stroke: '#595C6C' }} />,
    subitems: PRIORITY_DATA,
  },
  type: {
    name: 'type',
    title: 'Тип работ',
    icon: <Homework style={{ stroke: '#595C6C' }} />,
    subitems: TYPE_DATA,
  },
};
