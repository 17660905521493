import React, { useEffect, useState } from 'react';
import { RadialGauge } from 'react-canvas-gauges';
import ss from '../AnalogDevice.module.scss';

const ticksVerticalCount = 28;
const AnalogVertical = ({ props: { theme, size, value, max, min, avg, roundDigit, color, unit, ticks: tCount } }) => {
    const [ticks, setTicks] = useState([]);
    const [minor, setMinor] = useState(0);
    // console.log(value?.toFixed(roundDigit).length);

    useEffect(() => {
        if (typeof min === 'number' && typeof max === 'number') {
            const ticksArr = [];
            const count = Math.round(ticksVerticalCount / max.toFixed(roundDigit).length);
            if (!tCount) {
                // console.log((min / max) * 100);
                const step = (max - min) / count;
                // console.log(min, max, step);
                for (let i = 0; i <= count; i++) {
                    ticksArr.push(+(min + i * step).toFixed(roundDigit));
                }
            } else {
                const step = (max - min) / tCount;
                // console.log(min, max, step);
                for (let i = 0; i <= tCount; i++) {
                    ticksArr.push(+(min + i * step).toFixed(roundDigit));
                }
            }
            setTicks(ticksArr);
            setMinor(count);
        }
    }, [min, max, tCount, roundDigit]);

    return (
        <div className={ss.analogV}>
            <RadialGauge
                width={size * 0.92}
                height={size * 0.92}
                value={value || value === 0 ? value : min}
                units={false}
                minValue={min}
                maxValue={max}
                // Plate
                colorPlate="transparent"
                // Ticks
                majorTicksDec={roundDigit}
                majorTicks={ticks}
                colorMajorTicks={color}
                minorTicks={minor}
                strokeTicks={false}
                highlights={false}
                numbersMargin={2}
                ticksAngle={180}
                startAngle={0}
                // Numbers
                colorNumbers={theme === 'dark' ? '#fff' : '#000'}
                fontNumbers="Inter"
                fontNumbersWeight="bold"
                // Border
                borderOuterWidth={size * 0.025}
                colorBorderOuter={color}
                colorBorderOuterEnd={color}
                borderMiddleWidth={0}
                borderInnerWidth={0}
                borderShadowWidth={0}
                // ValueBox
                valueBox={false}
                // Needle
                needleShadow={false}
                needleStart={0}
                // needleEnd={75}
                colorNeedle={theme === 'dark' ? '#fff' : '#000'}
                colorNeedleEnd={theme === 'dark' ? '#fff' : '#000'}
            />
            <div className={ss.analogR__round}>
                <span style={{ fontSize: size * 0.003 + 'em' }}>{unit}</span>
            </div>
            <div
                className={ss.analogV__val}
                style={{ fontSize: size * 0.0032 + 'em', width: '5em' }}
            >
                {(value || value === 0 ? value : min)?.toFixed(roundDigit)}
            </div>
        </div>
    );
};
export default AnalogVertical;
