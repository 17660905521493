import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {mapDispatchToProps, mapStateToProps} from "./redux";
import "./Dashboard.scss";
import TextEditor from "../../ui/TextEditor/TextEditor";
import {Button, Icon, Tabs} from "../../ui";
import {deviceApi, newsApi} from "../../../api/api";
import {toast} from "react-toastify";
import {ContentFooter, ContentHead} from "../ContentParts";
import ContentWrap from "../ContentParts/ContentWrap";
import Content from "../ContentParts/Content";
import ContentBody from "../ContentParts/ContentBody";
import {useFormik} from "formik";
import * as Yup from "yup";
import "../../Settings/Settings.scss";
import Modal from "../../Modal";
import XMLParser from 'react-xml-parser';
import {useDropzone} from "react-dropzone";

const newsTypes = ["COR.ROBICLAB","Цифровая Лаборатория","Neuro"];
const devicesFile = process.env.PUBLIC_URL + '/source.xml';

export const DashboardDevice = (props) => {
    const [content, setContent] = useState('');
    const [status, setStatus] = useState(null);
    const [dbNews, setDbNews] = useState([]);
    const [dbNewsId, setDbNewsId] = useState(null);
    const [showConfirm, setShowConfirm] = useState(false);
    const [source, setSource] = useState({version: "не", date: "загружен"});
    const [sourceRlab, setSourceRlab] = useState({});

    const newsType = ()=>parseInt(props.match.params.type ?? '0', 0);
    const newsIndex = ()=>parseInt(props.match.params.id ?? '0', 0);

    const {inputRef, getInputProps} = useDropzone({
        noClick: true, noKeyboard: true,
        accept: {'application/xml':['.xml']},
        onDrop: files => {
            deviceApi.saveSource(files[0]).then((data)=>setSource(data));
            inputRef.current.value = "";
        },
    });

    useEffect(() => {
        loadNews().then();
        if (props.match.params.type === '1') {
            deviceApi.getSource().then((data)=>setSource(data));
            loadSource(devicesFile);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.match.params.type]);

    useEffect(() => {
        setDbNewsId(dbNews[newsIndex()]?._id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.match.params.id]);

    useEffect(() => {
        if (dbNewsId) {
            newsApi.getById(dbNewsId).then((data) => {
                setContent(data?.content);
                setStatus(data?.status);
            });
        } else {
            setContent('');
            setStatus(null);
        }
    }, [dbNewsId]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: dbNews[newsIndex()]?.name ?? '',
        }, validationSchema: Yup.object({
            name: Yup.string().required("Введите Название"),
        }), onSubmit: async (formData) => {
            saveNews(formData.name);
        },
    });

    const deleteCancel = () => setShowConfirm(false);
    const deleteConfirm = () =>
        newsApi.delete(dbNewsId).then(() => {
            setShowConfirm(false);
            setDbNews(n => n.filter((d) => d._id !== dbNewsId));
            if (newsIndex() === 0){
                setDbNewsId(dbNews[1]?._id);
            } else {
                navigate(newsType(), 0);
            }
        });

    const loadNews = async () =>
      newsApi.getAdminByType(newsType()).then((data) => {
          setDbNews(data);
          setDbNewsId(data[newsIndex()]?._id);
      });

    const loadSource = (file) =>
      fetch(file, {mode: 'no-cors'})
      .then((res) => res.text()).then((data) => {
          try {
              const xml = new XMLParser().parseFromString(data);
              const code = xml.getElementsByTagName('code')[0];
              const date = xml.getElementsByTagName('date')[0];
              setSourceRlab({version: code.value, date: date.value});
          } catch (err) {
              console.log(err.message)
          }
      });

    const navigate = (type, id) => {
        props.history.push("/news/" + type + "/" + (id??""));
    }

    const saveNews = (name)=> {
        if (dbNewsId) {
            newsApi.update(dbNewsId, {name, content: content})
            .then((result) => {
                dbNews[newsIndex()] = result;
                setDbNews([...dbNews])
                toast.success("Cохранено");
            }).catch((err) => {
                if (err.response.status === 413) {
                    toast.error("Превышен размер новости 5Mb");
                } else {
                    toast.error(err.response.data.errors);
                }
            });
        } else {
            newsApi.add({name, content: content, type: newsType()})
            .then((result) => {
                setDbNews([result,...dbNews]);
                navigate(newsType(), 0);
                toast.success("Добавлено");
            }).catch((err) => {
                if (err.response.status === 413) {
                    toast.error("Превышен размер новости 5Mb");
                } else {
                    toast.error(err.response.data.errors);
                }
            });
        }
    };

    const publish = ()=> {
        if (!dbNewsId) return;

        newsApi.update(dbNewsId, {status: status?0:1})
        .then((result) => {
            dbNews[newsIndex()] = result;
            setDbNews([...dbNews])
            setStatus(result.status);
            toast.success(result.status?"Опубликивано":"Черновик");
        }).catch((err) => {
                toast.error(err.response.data.errors);
        });
    };


    return (<>
          <ContentWrap>
              <ContentHead>
                  <Tabs>
                      <Tabs.Item key="-1" active={!dbNewsId} onClick={() => navigate(newsType(), -1)}>
                          <Icon name="plus"/>
                      </Tabs.Item>
                      {dbNews?.map((n, i) => (
                        <Tabs.Item key={i} active={i === newsIndex()}
                                   onClick={() => navigate(newsType(), i)}>{n.name}</Tabs.Item>)
                      )}
                      {dbNewsId && <Tabs.Item key="-2" onClick={() => setShowConfirm(true)}>
                          <Icon name="trash"/>
                      </Tabs.Item>}
                  </Tabs>
                  {newsType() === 1 &&
                    <div className="cor_dashboard__head_file"><label>source.xml<input type="file" accept=".xml" {...getInputProps()}/>
                    </label> {source.version}/{source.date} {source.path &&<a href={process.env.REACT_APP_API_URL+"/" + source.path} download>
                    <Icon name="download"/>
                    </a>} [rlab: {sourceRlab.version}/{sourceRlab.date}]</div>}
                  <Tabs>{newsTypes?.map((n, i) => (
                    <Tabs.Item key={i} active={i === newsType()} onClick={() => navigate(i)}>{n}</Tabs.Item>)
                  )}
                  </Tabs>
              </ContentHead>
              <Content background={false} className="cor-net__row ai-start">
              <ContentBody padding={false} scrolled={false} className={"cor-net__col col-2"}>
                      <TextEditor value={content} setValue={setContent} video={true}
                                  height='32em'/>
                  </ContentBody>
                  <ContentBody padding={false} scrolled={true} className={"cor-net__col col-2"}>
                      <div className="cor_dashboardBox__subTitle">Просмотр</div>
                      <div className="cor_te__preview"
                           dangerouslySetInnerHTML={{__html: content}}/>
                  </ContentBody>
          </Content>
          <ContentFooter>
              <form onSubmit={formik.handleSubmit}>
                  <div className="cor_settings cor_settings_hor4">
                  <input
                        autoComplete="off"
                        placeholder="Название"
                        name="name"
                        className={formik.errors.name ? "form_error" : ""}
                        onChange={formik.handleChange}
                        value={formik.values.name}
                      />
                      <div className="form_error">{formik.errors.name}</div>
                      <Button className="cor_dashboardBox__buttonSel" type="submit">
                          {dbNewsId ? "Сохранить" : "Добавить"}
                      </Button>
                      {dbNewsId && <Button className="cor_dashboardBox__buttonSel" onClick={publish}>
                          {status ? "Черновик" : "Опубликовать"}
                      </Button>}
                  </div>
              </form>
              </ContentFooter>
          </ContentWrap>
          <Modal visible={showConfirm} content={
              <>
                  <div className="modal__message">Точно удалить {dbNews[newsIndex()]?.name}?</div>
                  <div className="modal__action">
                      <button className="cor_btn" onClick={deleteCancel}>Отменить</button>
                      <button className="cor_btn cor_btn_danger" onClick={deleteConfirm}>Удалить Навсегда</button>
                  </div>
              </>
          } size={'xs'}/>
      </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDevice)