import React, { useState, useEffect } from 'react';
import { Sensors } from '../../Sensors';
import { Dropdown, Checkbox } from 'rlabui';
import SensorsBundles from '../../Bundles/SensorsBundles';

import ss from './Aside.module.scss';

const Aside = (props) => {
    const { port, sensorImported, devicesList, addChartDisabled, deleteChartDisabled, isOscilloscope } = props;
    const [aside, toggleAside] = useState(false);
    const [visible, setVisible] = useState(false);
    const [enabledList, setEnabledList] = useState([]);
    const [isSingle, setSingle] = useState(false);
    const [connectedValues, setConnectedValues] = useState(Object.values(devicesList));

    // console.log('Aside');

    useEffect(() => {
        if (port || sensorImported.length) {
            setVisible(true);
            toggleAside(true);
        }
        if (!port) {
            setEnabledList([]);
        }
    }, [port, sensorImported]);

    useEffect(() => {
        // console.log(devicesList);
        const keys = Object.keys(devicesList);
        const connected = Object.values(devicesList);
        if (connected[0]?.single) {
            setSingle(true);
        } else {
            setSingle(false);
        }
        setConnectedValues(connected);
        // if (connected?.length > 1) {
        // } else {
        //     setConnectedValues(connected?.length);
        // }
        keys?.forEach((el, i) => {
            if (!enabledList[i]) {
                setEnabledList([...enabledList, { id: el, enable: true }]);
            }
        });
        if (keys.length < enabledList.length) {
            const keysSet = new Set(keys);
            const diffKey = enabledList.filter((item) => keysSet.has(item))[0];

            if (diffKey) {
                setEnabledList((e) => {
                    e = e.filter((el) => el.id !== diffKey.id);
                    return e;
                });
            }
        }
    }, [devicesList, enabledList]);

    useEffect(() => {
        // console.log(enabledList);

        enabledList?.forEach((el, ind) => {
            const { id } = el;
            const sensors = devicesList[id]?.getElementsByTagName('channel');
            sensors?.forEach((sens, i) => {
                const num = ind > 0 ? ind + 20 + i : i;
                if (el.enable) {
                    deleteChartDisabled(num);
                } else {
                    addChartDisabled(num);
                }
            });
        });
    }, [enabledList, addChartDisabled, deleteChartDisabled, devicesList, connectedValues]);

    const setTitleList = () => {
        return aside && connectedValues.length > 1 ? (
            <Dropdown
                value={'Датчики ' + connectedValues.length + ' шт.'}
                dropPosition="left"
                multy={true}
                checkbox={true}
                clicked={true}
                className={ss.dropTitles}
            >
                {connectedValues.map((item, i) => {
                    let name = item.attributes.name;
                    const checked = enabledList.filter((el) => el.id === item.id)[0]?.enable;
                    const nameArr = name.split(' ');
                    if (typeof +nameArr[nameArr.length - 1] === 'number') {
                        nameArr.pop();
                        name = nameArr.join(' ');
                    }
                    return (
                        <Dropdown.Item
                            key={item.id}
                            className={checked && 'selected'}
                            onClick={() =>
                                setEnabledList((e) =>
                                    e.map((el) => {
                                        if (el.id === item.id) {
                                            return { ...el, enable: !checked };
                                        } else {
                                            return { ...el };
                                        }
                                    })
                                )
                            }
                        >
                            {name}
                        </Dropdown.Item>
                    );
                })}
            </Dropdown>
        ) : (
            <div className={ss.title}>Датчики</div>
        );
    };

    return (
        <aside className={visible && aside ? ss.root + ' desktop' : ss.collapse + ' desktop'}>
            <div className={ss.actions}>
                {setTitleList()}
                {!isOscilloscope && !isSingle && <SensorsBundles />}
                <div
                    title="Скрыть"
                    onClick={() => toggleAside(!aside)}
                    className={visible && aside ? ss.toggle + ' cl_icon_toggler' : ss.toggleInactive + ' cl_icon_sensors'}
                    data-learn="34,35,36"
                    data-learn-border="36"
                    data-learn-position="right"
                ></div>
            </div>
            <div className={visible && aside ? ss.sensors : ss.sensors + ' ' + ss.hidden}>
                <div className={ss.scroll}>
                    <Sensors />
                </div>
            </div>
        </aside>
    );
};

export default Aside;
