import { createSlice } from "@reduxjs/toolkit";
import {piApi, rlabApi, storageApi} from "../../api/api";

let initialState = {
  rlabs: [],
  files: [],
  dirs: [],
  pi: {
    objects: [],
    researches: [],
    properties: {},
    systems: {},
    quants: {},
    units: [],
    dependencies: [],
  },
  storage: {
    uid: 0,
    du: {},
    quota: {},
    error: {}
  },
};

const listsSlice = createSlice({
  name: "lists",
  initialState,
  reducers: {
    reset: () => initialState,
    setRlabs: (state, action) => {
      state.rlabs = action.payload;
    },
    setFiles: (state, action) => {
      state.files = action.payload;
    },
    setDirs: (state, action) => {
      state.dirs = action.payload;
    },
    setPIObjects: (state, action) => {
      state.pi.objects = action.payload;
    },
    setPIProperties: (state, action) => {
      state.pi.properties = Object.fromEntries(action.payload.map(x => [x._id, x.name]));
    },
    setPIResearches: (state, action) => {
      state.pi.researches = action.payload;
    },
    setPISystems: (state, action) => {
      state.pi.systems = Object.fromEntries(action.payload.map(x => [x._id, {name:x.name, owner: x.owner}]));
    },
    setPIQuants: (state, action) => {
      state.pi.quants = Object.fromEntries(action.payload.map(x => [x._id, {name:x.name, sign: x.sign}]));
    },
    setPIUnits: (state, action) => {
      state.pi.units = action.payload;
    },
    setPIDependencies: (state, action) => {
      state.pi.dependencies = action.payload;
    },
    setStorage: (state, action) => {
      state.storage = action.payload;
    },
  },
});

export const resetLists = () => async (dispatch) => {
  dispatch(reset());
};

export const loadRlabs = (room, uid) => async (dispatch) => {
  rlabApi.getByRoom(room, uid).then((data) => dispatch(setRlabs(data)));
};

export const loadUserRlabs = (room, uid) => async (dispatch) => {
  rlabApi.getByUser(room, uid).then((data) => dispatch(setRlabs(data)));
}

export const loadFiles = (key)=> async(dispatch)=>{
  storageApi.getFiles(key).then(res=>{
    dispatch(setDirs(res.d));
    dispatch(setFiles(res.f));
  }).catch(e=>console.log(e));
}

export const loadPIObjects = (room) => async (dispatch, getState) => {
  await piApi.getProperties(room).then((data) => dispatch(setPIProperties(data)));
  await piApi.getObjects(room).then((data) => dispatch(setPIObjects(data)));
}

export const loadPIResearches = (room, status) => async (dispatch) => {
  await piApi.getResearches(room, status).then((data) => dispatch(setPIResearches(data)));
}

export const loadPIUnits = (room) => async (dispatch, getState) => {
  await Promise.all([
    piApi.getSystems(room).then((data) => dispatch(setPISystems(data))),
    piApi.getQuants(room).then((data) => dispatch(setPIQuants(data))),
    piApi.getUnits(room).then((data) => dispatch(setPIUnits(data))),
    piApi.getDependencies(room).then((data) => dispatch(setPIDependencies(data))),
  ])
}

export const loadStorage = (uid) => async (dispatch) => {
  storageApi.getBucket(uid).then((du) => {
    storageApi.getQuota(uid).then(quota => dispatch(setStorage({uid, du, quota, error: {}})));
  }).catch(err => {
    dispatch(setStorage({uid, du: {}, quota: {}, error: err.response.data}));
  });
}

export const {
  reset,
  setRlabs,
  setFiles,
  setDirs,
  setPIObjects,
  setPIProperties,
  setPIResearches,
  setPISystems,
  setPIQuants,
  setPIUnits,
  setPIDependencies,
  setStorage,
} = listsSlice.actions;
export default listsSlice.reducer;
