import React, { useEffect, useState } from 'react';
import { LinearGauge } from 'react-canvas-gauges';
import ss from '../AnalogDevice.module.scss';

const ticksLinearVerticalCount = 4;
const LinearVertical = ({ props: { theme, width, height, value, max, min, avg, roundDigit, color, unit, ticks: tCount } }) => {
    const [ticks, setTicks] = useState([]);
    const [ticksOffset, setTicksOffset] = useState(1);
    const valSize = height * 0.0045 > 2.5 ? 2.5 + 'em' : height * 0.005 + 'em';
    const borderSize = height * 0.0015 > 0.8 ? 0.8 + 'em' : height * 0.0015 + 'em';
    const [maxWidth, setMaxWidth] = useState(100);

    useEffect(() => {
        if (typeof min === 'number' && typeof max === 'number') {
            const ticksArr = [];
            // const count = Math.round(ticksLinearVerticalCount / max.toFixed(roundDigit).length);
            if (!tCount) {
                // console.log((min / max) * 100);
                const step = (max - min) / ticksLinearVerticalCount;
                // console.log(min, max, step);
                for (let i = 0; i <= ticksLinearVerticalCount; i++) {
                    ticksArr.push(+(min + i * step).toFixed(roundDigit));
                }
            } else {
                const step = (max - min) / tCount;
                // console.log(min, max, step);
                for (let i = 0; i <= tCount; i++) {
                    ticksArr.push(+(min + i * step).toFixed(roundDigit));
                }
            }
            setTicks(ticksArr);
            setTicksOffset(-(max.toFixed().length + 1));
        }
    }, [min, max, tCount, roundDigit]);

    useEffect(() => {
        if (width >= height) {
            if (height * 0.5 > width / 3) {
                setMaxWidth(width / 3);
            } else {
                setMaxWidth(height * 0.5);
            }
        } else {
            setMaxWidth(width * 0.6);
        }
    }, [width, height]);

    return (
        <div
            className={ss.linearV}
            style={{ borderWidth: borderSize, borderColor: color, width: maxWidth * 2.2, height: height * 0.92 }}
        >
            <div
                className={ss.linearV__val}
                style={{ fontSize: valSize }}
            >
                {(value || value === 0 ? value : min)?.toFixed(roundDigit)} {unit}
            </div>

            <div
                className={ss.linearV__gauge}
                style={{ width: maxWidth, marginRight: -(height * 0.15) }}
            >
                <LinearGauge
                    width={maxWidth}
                    // exactTicks={false}
                    height={height * 0.85}
                    minValue={min}
                    maxValue={max}
                    value={value || value === 0 ? value : min}
                    units={false}
                    // listeners={
                    // 	value: function(new, old) {
                    // 		console.log(new, old);
                    // 	}
                    // }
                    // Plate
                    colorPlate="transparent"
                    // Ticks
                    majorTicks={ticks}
                    colorMajorTicks={color}
                    colorMinorTicks={color}
                    minorTicks={5}
                    // majorTicksInt={0}
                    majorTicksDec={roundDigit}
                    strokeTicks={false}
                    highlights={false}
                    tickSide="left"
                    // Numbers
                    colorNumbers={theme === 'dark' ? '#fff' : '#000'}
                    fontNumbers="Inter"
                    fontNumbersWeight="bold"
                    fontNumbersSize={18}
                    numberSide="left"
                    numbersMargin={ticksOffset}
                    // Border
                    borderOuterWidth={0}
                    borderMiddleWidth={0}
                    borderInnerWidth={0}
                    borderShadowWidth={0}
                    // ValueBox
                    valueBox={false}
                    // Bar
                    barBeginCircle={false}
                    colorBar={theme === 'dark' ? '#373940' : '#ddd'}
                    colorBarEnd={theme === 'dark' ? '#373940' : '#ddd'}
                    colorBarProgress={color}
                    colorBarProgressEnd={color}
                    // Needle
                    needle={false}
                />
            </div>
        </div>
    );
};
export default LinearVertical;
