import React from 'react';
import './style.scss';

const Icon = ({ tag = 'i', name, className, title, onClick, disabled = false }) => {
    const Tag = tag;
    let currentClass = 'icon_' + name;
    if (className) {
        currentClass += ' ' + className;
    }
    return (
        <Tag
            title={title}
            onClick={onClick}
            className={currentClass}
            disabled={disabled}
        ></Tag>
    );
};
export default Icon;
