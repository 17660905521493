import React, {useState, useEffect, useCallback} from "react";
import { Icon, Button, Tooltip } from '../ui';
import { isAuth } from '../../helpers/auth';
import { getNbCheckBox, getDropdown, getSwitcher } from "../ui/utils/gen_utils";
import {toast} from "react-toastify";
import { NB_STATES, //NB_SCORE_SCALE_TYPES, NB_SCORING_TYPES, getEstimateThresholdList,
    //NB_AUTO_ESTIMATES, NB_AUTO_ESTIMATE100_THRESHOLD, 
    getHourList, getMinuteList, getErrorLimits, isDisabled } from './nb_utils';
import ScoreCalcTableDlg from "./ScoreCalcTableDlg";
import "../template/Dashboard/Dashboard.scss";
import nb from "./Notebooks.module.scss";

const NotebookAddSettings = ({setNbState, isEditable, homeWorkSettings, setHomeWorkSettings, isDisabledTemplate,
        isSettingChanged, setIsSettingChanged, forceSaveSettings, setForceSaveSettings, setForceComplete}) => {
    const [isTimeLimited, setIsTimeLimited] = useState(true);
    const [limitHours, setLimitHours] = useState(3);
    const [limitMinutes, setLimitMinutes] = useState(0);
    const [isGenerateUniqueTasks, setIsGenerateUniqueTasks] = useState(true);
    const [isRandomOrder, setIsRandomOrder] = useState(true);
    const [isErrorNumberLimit, setIsErrorNumberLimit] = useState(true);
    const [taskErrorLimit, setTaskErrorLimit] = useState(3);
    const [workErrorLimit, setWorkErrorLimit] = useState(10);
    //const [scoreScaleType, setScoreScaleType] = useState(NB_SCORE_SCALE_TYPES.SCORE_SCALE_100);
    //const [scoringType, setScoringType] = useState(NB_SCORING_TYPES.SCORING_CORRECT_NUM);
    const [isAutoScore, setIsAutoScore] = useState(true);
    //const [autoEstimateList, setAutoEstimateList] = useState(NB_AUTO_ESTIMATES);
    //const [autoEstimate100Threshold, setAutoEstimate100Threshold] = useState(NB_AUTO_ESTIMATE100_THRESHOLD);
    const [showHowToCalcDlg, setShowHowToCalcDlg] = useState(false);

	useEffect(() => {
        if (!homeWorkSettings) return;
        setIsTimeLimited(homeWorkSettings.isTimeLimited);
        const hours = Math.floor((homeWorkSettings.timeLimitMinutes / 60));
        const mins = homeWorkSettings.timeLimitMinutes - hours * 60;
        setLimitHours(hours);
        setLimitMinutes(mins);
        setIsRandomOrder(homeWorkSettings.isRandomOrder);
        setIsErrorNumberLimit(homeWorkSettings.isErrorNumberLimit);
        setTaskErrorLimit(homeWorkSettings.taskErrorLimit);
        setWorkErrorLimit(homeWorkSettings.workErrorLimit);
        setIsGenerateUniqueTasks(homeWorkSettings.isGenerateUniqueTasks);
        setIsAutoScore(homeWorkSettings.isAutoScore);
        //setAutoEstimateList(homeWorkSettings.autoEstimateList);
        //setScoreScaleType(homeWorkSettings.scoreScaleType);
        //setScoringType(homeWorkSettings.scoringType);
        //setAutoEstimate100Threshold(homeWorkSettings.autoEstimate100Threshold);
	},[homeWorkSettings]);

    const handleSaveSettings = useCallback(() => {
        if (!isSettingChanged && isAuth().role < 3) return true;
        if (isTimeLimited &&  limitHours === 0 && limitMinutes === 0) {
            toast.warn("Установите время выполнения задания."); 
            return false;
        }

        const settings = {
            isGenerateUniqueTasks: isGenerateUniqueTasks, 
            isTimeLimited: isTimeLimited, 
            timeLimitMinutes: limitHours * 60 + limitMinutes,
            isRandomOrder: isRandomOrder,
            isErrorNumberLimit: isErrorNumberLimit,
            taskErrorLimit: taskErrorLimit,
            workErrorLimit: workErrorLimit,
            isAutoScore: isAutoScore,
            //scoreScaleType: scoreScaleType,
            //scoringType: scoringType,
            //autoEstimateList: autoEstimateList,
            //autoEstimate100Threshold: autoEstimate100Threshold,
        };
        
        setHomeWorkSettings(settings);
        setIsSettingChanged(false);
        return true;
    }, [isAutoScore, isGenerateUniqueTasks,
        isErrorNumberLimit, isRandomOrder, isSettingChanged, 
        isTimeLimited, setHomeWorkSettings, setIsSettingChanged, 
        taskErrorLimit, limitHours, limitMinutes, workErrorLimit,
    ]); //scoringType, scoreScaleType, autoEstimateList, autoEstimate100Threshold

    useEffect(() => {
        if (!forceSaveSettings) return;
        handleSaveSettings();
        setForceSaveSettings(false);
    },[forceSaveSettings, handleSaveSettings, setForceSaveSettings]);
    
	const handlePrevStep = () => {
        const canChangeStep = handleSaveSettings();
        if (canChangeStep)
            setNbState(NB_STATES.PAGE);
    };

	const handleNextStep = () => {
        const canChangeStep = handleSaveSettings();
        if (canChangeStep)
            setNbState(NB_STATES.RUN);
    };

    const doSetIsGenerateUniqueTasks = val => {setIsGenerateUniqueTasks(val); setIsSettingChanged(true);};
    const doSetIsTimeLimited = val => {setIsTimeLimited(val); setIsSettingChanged(true);};
    const doSetLimitHours = val => {setLimitHours(val); setIsSettingChanged(true); if (val === 0 && limitMinutes === 0) setLimitMinutes(5);};
    const doSetLimitMinutes = val => {setLimitMinutes(val); setIsSettingChanged(true);};
    //const doSetAutoEstimate100Threshold = val => {setAutoEstimate100Threshold(val); setIsSettingChanged(true);};
    const doSetIsRandomOrder = val => {setIsRandomOrder(val); setIsSettingChanged(true);};
    const doSetIsErrorNumberLimit = val => {setIsErrorNumberLimit(val); setIsSettingChanged(true);};
    const doSetTaskErrorLimit = val => {setTaskErrorLimit(val); setIsSettingChanged(true);};
    const doSetWEorkErrorLimit = val => {setWorkErrorLimit(val); setIsSettingChanged(true);};
    const doSetIsAutoScore = val => {setIsAutoScore(val); setIsSettingChanged(true);};
    
    const handleSaveInStore = () => {
        const canSaveChanges = handleSaveSettings();
        if (canSaveChanges) {
            setForceComplete(true); //сохранить изменения и закрыть
        }
    };

    return  (
        <>
            <div className={nb.nb__body}>
                <div className="cor-net">
                    {/* start section */}
                    <div className="cor-net__section">
                        <div className="cor-net__title">Настройки</div>

                        <div className="cor-net__row">
                            <div className="cor-net__subtitle">Временные рамки</div>
                        </div>
                        <div className={"cor-net__row " + isDisabled(isEditable)}>
                            {getNbCheckBox(isTimeLimited, doSetIsTimeLimited, 'timelimit', 'Включить ограничение по времени на выполнение задания')}
                        </div>
                        <div className={"cor-net__row " + isDisabled(isEditable && isTimeLimited)}>
                            <div className="cor-net__col col-10">
                                <div className="cor-net__label">Часы</div>
                                {getDropdown(getHourList(), limitHours, value => doSetLimitHours(value), '', '', 'tskhr01')}
                            </div>
                            <div className="cor-net__col col-10">
                                <div className="cor-net__label">Минуты</div>
                                {getDropdown(getMinuteList(), limitMinutes, value => doSetLimitMinutes(value), '', '', 'tskmin01')}
                            </div>
                        </div>

                    </div>
                    {/* end section */}
                    
                    {/* start section */}
                    <div className="cor-net__section">
                        <div className="cor-net__row">
                            <div className="cor-net__col col-flex">
                                <div className="cor-net__subtitle">Автоматическая генерация задач для учеников</div>
                                {/* position: bottom, top, left, right */}
                                <Tooltip position='right'>
                                    <div>Выберите генерацию задач: </div>
                                    <div>Индивидуальная - индивидуальные задачи у каждого ученика, </div>
                                    <div>разные задачи у всех учеников класса;</div> 
                                    <div>Общая - одинаковые задачи у всех учеников класса.</div>
                                </Tooltip>
                            </div>
                            {/* <div className="doing" key={'st060'}>
                              <Icon name="info" onClick={e => e.stopPropagation()} key={'st070'} />
                               <div className="doing__item" key={'st080'}>{'comment'}</div>
                            </div> */}
                        </div>
                        <div className={"cor-net__row " + isDisabled(isEditable)}>
                            {getSwitcher('Индивидуальная|Общая', isGenerateUniqueTasks, () => doSetIsGenerateUniqueTasks(!isGenerateUniqueTasks))}
                        </div>
                    </div>
                    

                    {/* start section */}
                    <div className="cor-net__section">
                        <div className="cor-net__row">
                            <div className="cor-net__subtitle">Свойства вопросов</div>
                        </div>

                        <div className={"cor-net__row " + isDisabled(isEditable)}>
                            {getNbCheckBox(isRandomOrder, doSetIsRandomOrder, 'questrandom', 'Случайный порядок вопросов')}
                        </div>
                    </div>
                    {/* end section */}

                    {/* start section */}
                    <div className="cor-net__section">
                        <div className="cor-net__row">
                            <div className="cor-net__subtitle">Попытки и ошибки</div>
                        </div>

                        <div className={"cor-net__row " + isDisabled(isEditable)}>
                            {getNbCheckBox(isErrorNumberLimit, doSetIsErrorNumberLimit, 'errorlimit', 'Установить количество допустимых ошибок')}
                        </div>
                        
                        <div className={"cor-net__row " + isDisabled(isEditable && isErrorNumberLimit)}>
                            <div className="cor-net__col col-6">
                                <div className="cor-net__label">Лимит ошибок на одну задачу</div>
                                {getDropdown(getErrorLimits(false), taskErrorLimit, value => doSetTaskErrorLimit(value), '', '', 'tsklim01', 'top')}
                            </div>
                            <div className="cor-net__col col-6">
                                <div className="cor-net__label">Лимит ошибок на все задачи</div>
                                {getDropdown(getErrorLimits(true), workErrorLimit, value => doSetWEorkErrorLimit(value), '', '', 'tsklim011', 'top')}
                            </div>
                        </div>
                    </div>
                    {/* end section */}

                    {/* start section */}
                    <div className="cor-net__section">
                        <div className="cor-net__row">
                            <div className="cor-net__subtitle">Выставление оценок</div>
                        </div>

                        <div className={"cor-net__row " + isDisabled(isEditable)}>
                            {getNbCheckBox(isAutoScore, doSetIsAutoScore, 'autoscore', 'Автоматическая оценка результатов системой')}
                        </div>

                        {/* {scoreScaleType === NB_SCORE_SCALE_TYPES.SCORE_SCALE_100 &&
                            <div className={"cor-net__row " + isDisabled(isEditable)}>
                                <div className="cor-net__col col-4">
                                    <div className="cor-net__label">Минимальное положительное значение</div>
                                    {getDropdown(getEstimateThresholdList(), autoEstimate100Threshold, value => doSetAutoEstimate100Threshold(value), '', '', 'ball01')}
                                </div>
                            </div>
                        } */}

                        <div className={"cor-net__row"}>
                            <div className="cor-net__reload" onClick={() => setShowHowToCalcDlg(true)}>
                                <Icon name={'reload'}/>
                                <span>Ознакомиться с оценочной системой</span>
                            </div>
                        </div>

                    </div>
                    {/* end section */}
                </div>
            </div>

            <div className={nb.nb__footer}>
                <div className={nb.nb__footer_row}>
                    <div className={nb.nb__footer_col}>
                    <Button color="secondary" size = "medium" onClick={handlePrevStep} >
                            {isAuth().role === 3 || !isDisabledTemplate ? 
                                'Вернуться к созданию задач' : 'Вернуться к задачам'}
                        </Button>
                    </div>

                    {isAuth().role < 3 && !isDisabledTemplate && 
                    <div className={nb.nb__footer_col}>
                        <Button color="primary" size="medium" onClick={handleNextStep} >
                            Завершить и перейти к запуску
                        </Button>
                    </div>
                    }
                    {isAuth().role === 3 && 
                    <div className={nb.nb__footer_col}>
                        <Button className="cor_btn_icon_left" color="primary" size="medium" 
                            border={true} onClick={handleSaveInStore}>
                            <Icon name="save"/>
                            Сохранить шаблон в каталоге
                        </Button>
                    </div>
                    }
                </div>
            </div>

            {showHowToCalcDlg &&
			<ScoreCalcTableDlg
				showHowToCalcDlg={showHowToCalcDlg} 
				setShowHowToCalcDlg={setShowHowToCalcDlg}
			/>
            }
        </>
    );
}

export default NotebookAddSettings;
