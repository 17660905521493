import React from "react";
import { Icon, Input } from '../ui';
import {isAuth} from "../../helpers/auth";
import ss from './Scenarios.module.scss';

const ScenarioOneQuestion = ({itemIndex, isTemplate, scQuestion, handleItemChange, handleDeleteItem}) => {
    const isDisabledTemplate = isTemplate && isAuth().role < 3;

    const handleQuestion = v => {
        handleItemChange(itemIndex, v);
    };

    return (
    <div className="cor-net__section" key={'sind30'+itemIndex}>
        <div className="cor-net__title" key={'sind31'+itemIndex}>Контрольный вопрос {(itemIndex+1)}</div>

        <div className={"cor-net__row"} key={'sind34'+itemIndex}>
            <div className="cor-net__col col-3" >
                <Input className="modal__input" key={'sind04'+itemIndex}
                    value={scQuestion} 
                    disabled={isDisabledTemplate}
                    placeholder={'Введите вопрос'} 
                    onInput={e => handleQuestion(e.target.value)} 
                />
            </div>
        </div>
        
        {!isDisabledTemplate && 
        <div className={"cor-net__row"} key={'sind50'+itemIndex}>
            <div className="cor-net__col" key={'sind51'+itemIndex}>
                <div 
                    className={ss.delete} 
                    key={'sind52'+itemIndex}
                    disabled={isDisabledTemplate}
                    onClick={() => handleDeleteItem(itemIndex)}>
                    Удалить вопрос
                </div>
            </div>
        </div>
        }

    </div>
    );
}

export default ScenarioOneQuestion; 
