import React from 'react';
import Icon from "../Icon";
import ss from './Trash.module.scss';

const Trash = (props) => {
    const {
        className = "",
        onClick = null,
        disabled = false,
        bg = false,
        size = '',
    } = props;

    let tarshClass = ss.root;
    if (bg) tarshClass += ' ' + ss.bg;
    if (size) tarshClass += ' ' + ss.small;

    if (className) tarshClass += ' ' + className;

    return (
        <button
            type="button"
            disabled={disabled}
            onClick={onClick}
            className={tarshClass}
            >
            <Icon name="trash"/>
        </button>
    )
};
export default Trash;
