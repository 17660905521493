// import { useDispatch } from 'react-redux';
import {toast} from "react-toastify";
import { resetReduxState } from '../actions/actions';
import { togglePort, setConnectionType, sendCommand, setConnectionLoss } from '../actions/port';
import { addWriter, removeWriter } from '../actions/writer';
import { setSensor } from '../actions/sensors';
import { setReader, setSensorReading } from '../actions/reader';
import { setAndroidBTModal, setAndroidBTList, setAndroidBTCount } from '../actions/android';
// import {} from "../actions/actions"
import { setDeviceConnectedCount, addDevicesConnected, removeDevicesConnected, setDeviceConnectedLoader, removeDevicesList } from '../actions/devices';

import { BLE } from '@awesome-cordova-plugins/ble';

// console.log(window);

const services = [1027, 24577, 24597, 24597, 1003, 8260, 9025, 1, 4, 16, 59, 63, 66, 67, 68, 32822, 32823, 5824, 5824, 1155, 7855, 4292, 60000, 60016, 60017, 60032, 60032, 1659, 8963, 6790, 29987];
const textDecoder = new TextDecoder('utf-8');
let posibleDevices = [];
let port = false;
// Кэш объекта выбранного устройства
let deviceCache = {};
let devicesValues = {};

// Кэш объекта характеристики
let characteristicRead = {};
// Кэш объекта характеристики для записи
let characteristicWrite = {};

let serviceCache = {};
let serverCache = {};
let refresh = 0;

// Redux dispatch
let btDispatch = () => null;
let respNameArr = [];

const HM10_SERIAL_UUID = '0000ffe0-0000-1000-8000-00805f9b34fb';
const HM10_READ_UUID = '0000ffe1-0000-1000-8000-00805f9b34fb';
const HM10_WRITE_UUID = '0000ffe1-0000-1000-8000-00805f9b34fb';

const ESP32_SERIAL_UUID = '6e400001-b5a3-f393-e0a9-e50e24dcca9e';
const ESP32_READ_UUID = '6e400003-b5a3-f393-e0a9-e50e24dcca9e';
const ESP32_WRITE_UUID = '6e400002-b5a3-f393-e0a9-e50e24dcca9e';

// Запустить выбор Bluetooth устройства и подключиться к выбранному
export const btConnect = (device) => async (dispatch, getState) => {
    const {
        port: { open, responses },
    } = getState();
    const state = getState();
    // console.log(state);
    let connected;
    btDispatch = dispatch;
    port = open;
    respNameArr = responses;

    btDispatch(setDeviceConnectedLoader(true));
    if (window.isAndroid) {
        BLE.isEnabled()
            .then((e) => scanBLE())
            .catch(async (err) => {
                console.log('isEnabled err', err);
                await BLE.enable()
                    .then(() => scanBLE())
                    .catch((err) => {
                        console.log(err.message);
                    });
            });

        btDispatch(setDeviceConnectedLoader(false));
    } else {
        return requestBluetoothDevice(device)
            .then((device) => {
                connected = device.name;
                return connectDeviceAndCacheCharacteristic(device);
            })
            .then((characteristic) => {
                // console.log('characteristic', characteristic);
                dispatch(btStartNotifications(characteristic));
            })
            .catch((error) => {
                console.log(error);
                switch (error.message) {
                    case 'Bluetooth adapter not available.':
                        toast.warn('Bluetooth-адаптер недоступен');
                        break;
                    case 'User cancelled the requestDevice() chooser.':
                        toast.warn('Не выбрано устройство');
                        removeDisconnectedDevice(connected);
                        break;
                    default:
                        if (connected?.name) {
                            toast.error('Соединение с ' + connected.name + ' прервано');
                            removeDisconnectedDevice(connected);
                        } else {
                            toast.error('Соединение прервано');
                            // btDispatch(togglePort(false));
                        }
                }
                btDispatch(setDeviceConnectedLoader(false));
            });
    }
};

// Отключиться от подключенного устройства
export const btDisconnect = (device) => async (dispatch, getState) => {
    const {
        devices: { connected },
    } = getState();
    // const { devices } = getState();
    const { isAndroid } = window;

    btDispatch = dispatch;
    // console.log('btDisconnect deviceCache ===', deviceCache);
    // console.log(devices);

    if (isAndroid) {
        // const key = Object.keys(connected)[0];
        for (const key in connected) {
            // console.log(key);
            await BLE.disconnect(key)
                .then((e) => {
                    // console.log('disconnect', e);
                    btDispatch(removeDevicesConnected(key));
                    btDispatch(removeDevicesList({ key }));
                    btDispatch(removeWriter(key));
                    port && toast.info('Датчик отключен');
                    btDispatch(setConnectionType(null));
                    btDispatch(setDeviceConnectedCount(0));
                    btDispatch(setReader({}));

                    btDispatch(resetReduxState());
                })
                .catch((err) => {
                    console.log('disconnect err', err);
                });
        }
    } else {
        if (device) {
            // console.log(device);
            singleDeviceDisconnect(device, dispatch);
        } else {
            allDeviceDisconnect(dispatch);
        }
    }
    // console.log(state);
};

// Подключение к определенному устройству, получение сервиса и характеристики
const connectDeviceAndCacheCharacteristic = (device) => {
    let key = device.id;

    if (!key) {
        toast.error('Ошибка соединения');
        return false;
    }

    return device.gatt
        .connect()
        .then((server) => {
            serverCache[key] = server;
            return server.getPrimaryService(HM10_SERIAL_UUID);
        })
        .catch((err) => {
            return serverCache[key].getPrimaryService(ESP32_SERIAL_UUID);
        })
        .then((service) => {
            serviceCache[key] = service;
            return serviceCache[key].getCharacteristic(HM10_READ_UUID);
        })
        .catch((err) => {
            return serviceCache[key].getCharacteristic(ESP32_READ_UUID);
        })
        .then((readCharacteristic) => {
            characteristicRead[key] = readCharacteristic;
            btDispatch(setReader(characteristicRead));
            return serviceCache[key].getCharacteristic(HM10_WRITE_UUID);
        })
        .catch((err) => {
            return serviceCache[key].getCharacteristic(ESP32_WRITE_UUID);
        })
        .then((writeCharacteristic) => {
            characteristicWrite[key] = writeCharacteristic;
            btDispatch(addWriter({ key, data: writeCharacteristic }));
            btDispatch(sendCommand('rcc'));
            return characteristicRead[key];
        });
};

// Включение получения уведомлений об изменении характеристики
const btStartNotifications = (characteristic) => async (dispatch) => {
    const {
        service: { device },
    } = characteristic;
    const key = device.id;
    try {
        return characteristic
            .startNotifications()
            .then(() => {
                try {
                    characteristic.addEventListener('characteristicvaluechanged', (e) => dispatch(btCharacteristicValueChanged(e)));
                    dispatch(addDevicesConnected({ key: key, data: device }));
                    dispatch(setDeviceConnectedCount(Object.keys(deviceCache).length));
                    dispatch(togglePort(true));
                    dispatch(setConnectionType('bt'));
                    dispatch(setDeviceConnectedLoader(false));
                    toast.success(`Соединение c ${device.name} установлено`);
                } catch (e) {
                    console.log('startNotifications error', e);
                }
            })
            .catch((err) => {
                // console.log('btStartNotifications characteristic', characteristic);
                console.log('btStartNotifications error', err);
                toast.error(`Ошибка соединения c ${device.name}`);
                delete deviceCache[key];
                dispatch(removeDevicesConnected({ key: key, data: device }));
                dispatch(setDeviceConnectedCount(Object.keys(deviceCache).length));
                dispatch(setDeviceConnectedLoader(false));
                // btDisconnect();
            });
    } catch (e) {
        console.log(e);
        if (e.message.includes('GATT Server is disconnected')) {
            connectDeviceAndCacheCharacteristic(device);
        }
        // return e;
    }
};

// Получение данных
const btCharacteristicValueChanged =
    ({ target }) =>
    async (dispatch, useState) => {
        const {
            service: { device },
        } = target;
        const {
            port: { connectionLoss },
        } = useState();
        try {
            connectionLoss && dispatch(setConnectionLoss(false));
            const value = textDecoder.decode(target.value);
            const key = device.id;
            // console.log(value);

            parceBTData(key, value);
        } catch (e) {
            console.log('Bluetooth expectation error', e);
        }
    };

export const connectBTFromAndroid = (device) => {
    // const list = [];
    // console.log('scan rez', device);
    // if (device.name) {
    //     list.push(device);
    // }
    // if (device.name === 'Biologia') {
    btDispatch(addDevicesConnected({ key: device.id, data: device }));
    btDispatch(setDeviceConnectedCount(1));
    btDispatch(togglePort(true));
    btDispatch(setConnectionType('bt'));
    btDispatch(setDeviceConnectedLoader(false));

    BLE.connect(device.id).subscribe(
        (sens) => {
            console.log('BLE.connect', sens);
            const key = sens.id;
            const characteristics = sens.characteristics;
            const charRead = characteristics.find((e) => e.properties.find((w) => w === 'Notify'));
            const charWrite = characteristics.find((e) => e.properties.find((w) => w === 'Write'));
            characteristicRead[key] = charRead;
            characteristicWrite[key] = charWrite;
            btDispatch(addWriter({ key, data: charWrite }));
            // console.log(charRead);
            BLE.startNotification(device.id, charRead.service, charRead.characteristic).subscribe((data) => {
                const value = textDecoder.decode(data[0]);
                parceBTData(device.name, value);
            });
        },
        (err) => {
            console.log(JSON.stringify(err.message));
        }
    );
    // }
    // return list;
};

const scanBLE = () => {
    const curList = [];
    BLE.scan([], 1).subscribe(
        (device) => {
            if (device.name) {
                curList.push(device);
            }
            btDispatch(setAndroidBTCount(curList.length));
            btDispatch(setAndroidBTList(curList));
        },
        (err) => {
            console.log('scan err', err);
        }
    );
    btDispatch(setAndroidBTModal(true));
};

const singleDeviceDisconnect = async (device, dispatch) => {
    // console.log(device.id);
    const key = device.id;
    try {
        if (characteristicRead[key]) {
            await characteristicRead[key].stopNotifications();
            await characteristicRead[key].removeEventListener('characteristicvaluechanged', (e) => dispatch(btCharacteristicValueChanged(e)));
            // console.log('bluetooth characteristicvaluechanged remove listener');
        }
    } catch (e) {
        console.log('disconnect characteristicRead error', e);
    }

    characteristicRead[key] && delete characteristicRead[key];
    dispatch(setReader(characteristicRead));

    characteristicWrite[key] && delete characteristicWrite[key];
    dispatch(removeWriter({ key, data: characteristicWrite[key] }));

    if (deviceCache[key]?.gatt.connected) {
        try {
            // deviceCache[key].watchAdvertisements({ signal: false });
            // await deviceCache[key].addEventListener('advertisementreceived', advResponseEvent);
            await deviceCache[key].removeEventListener('gattserverdisconnected', handleDisconnection);
            await deviceCache[key].gatt.disconnect();
            // console.log('"' + deviceCache[key].name + '" bluetooth device disconnected');
            dispatch(removeDevicesConnected(key));
            dispatch(removeDevicesList({ key }));
        } catch (e) {
            console.log('disconnect device error', e);
        }
    }

    deviceCache[key] && delete deviceCache[key];
    devicesValues[key] && delete devicesValues[key];

    const devivcesCount = Object.keys(deviceCache)?.length;

    devivcesCount && serviceCache[key] && delete serviceCache[key];
    devivcesCount && serverCache[key] && delete serverCache[key];

    dispatch(setDeviceConnectedCount(devivcesCount));

    if (devivcesCount === 0) {
        dispatch(resetReduxState());
    }
};

const allDeviceDisconnect = async (dispatch) => {
    dispatch(togglePort(false));

    if (Object.keys(characteristicRead)?.length) {
        for (const key in characteristicRead) {
            try {
                if (characteristicRead[key]) {
                    await characteristicRead[key].removeEventListener('characteristicvaluechanged', (e) => dispatch(btCharacteristicValueChanged(e)));
                    await characteristicRead[key].stopNotifications();
                    // console.log('bluetooth characteristicvaluechanged remove listener');
                    dispatch(removeWriter({ key, data: characteristicWrite[key] }));
                }
            } catch (e) {
                console.log('disconnect characteristicRead error', e);
            }
        }
        characteristicRead = {};
    }

    if (Object.keys(characteristicWrite)?.length) {
        characteristicWrite = {};
        // console.log('bluetooth remove characteristicWrite');
    }

    if (Object.keys(serviceCache)?.length) {
        serviceCache = {};
        // console.log('bluetooth remove serviceCache');
    }

    if (Object.keys(serverCache)?.length) {
        serverCache = {};
        // console.log('bluetooth remove serverCache');
    }

    if (Object.keys(deviceCache)?.length) {
        for (const key in deviceCache) {
            if (deviceCache[key].gatt.connected) {
                try {
                    // deviceCache[key].watchAdvertisements({ signal: false });
                    // await deviceCache[key].addEventListener('advertisementreceived', advResponseEvent);
                    await deviceCache[key].removeEventListener('gattserverdisconnected', handleDisconnection);
                    await deviceCache[key].gatt.disconnect();
                    // console.log('"' + deviceCache[key].name + '" bluetooth device disconnected');
                    btDispatch(removeDevicesConnected(key));
                    btDispatch(removeDevicesList({ key }));
                } catch (e) {
                    console.log('disconnect device error', e);
                }
            }
        }
        deviceCache = {};
    }

    devicesValues = {};
    respNameArr = [];
    refresh = 0;

    port && toast.info('Датчик отключен');
    btDispatch(setConnectionType(null));
    btDispatch(setDeviceConnectedCount(0));
    btDispatch(setReader({}));

    btDispatch(resetReduxState());
};

function parceBTData(key, value) {
    // console.log(value);
    if (devicesValues[key] === undefined) devicesValues[key] = '';
    devicesValues[key] += value;
    if (devicesValues[key].includes('\n')) {
        // console.log(devicesValues[key]);
        // if (devicesValues[key]?.includes('rlg')) {
        //     const checkReadStart = devicesValues[key].split(' ');
        //     if (!isNaN(+checkReadStart[1])) {
        //         console.log(checkReadStart[1]);
        //         btDispatch(setSensorReading(true));
        //     }
        // }
        const arr = devicesValues[key].split('\r\n');
        if (arr[0]?.length) {
            const respName = respNameArr.filter((item) => item === arr[0].split(' ')[0])[0];
            // console.log(arr[0] + ' ' + (respName ? '' : refresh++));
            // console.log(arr[0]);
            btDispatch(setSensor(arr[0] + ' ' + (respName ? '' : refresh++)));
            devicesValues[key] = arr[1] ? arr[1] : '';
        }
    }
}

// Обработчик разъединения
const handleDisconnection = (event) => {
    let device = event.target;
    // console.log(device);
    // if (device.gatt.connected) {
    // toast.warn('Соединение потеряно с '+device.name+'. Переподключите устройство');
    try {
        btDispatch(setConnectionLoss(true));
        removeDisconnectedDevice(device);
    } catch (e) {
        console.log('handleDisconnection', e);
    }
    // }
};

export const removeDisconnectedDevice = (device) => {
    // console.log('removeDisconnectedDevice', device);
    if (device) {
        const key = device.id;
        // const deviceLenght = Object.keys(deviceCache).length

        if (key) {
            btDispatch(removeWriter({ key, data: characteristicWrite[key] }));
            btDispatch(removeDevicesConnected(key));

            Object.keys(characteristicRead).forEach((removed) => {
                characteristicRead[removed]?.removeEventListener('characteristicvaluechanged', (e) => btDispatch(btCharacteristicValueChanged(e)));
                if (key !== removed) {
                    // btStartNotifications(characteristicRead[removed]);
                    characteristicRead[removed].addEventListener('characteristicvaluechanged', (e) => btDispatch(btCharacteristicValueChanged(e)));
                }
            });

            delete characteristicRead[key];
            delete deviceCache[key];
            delete devicesValues[key];
            delete serverCache[key];
            delete serviceCache[key];
            delete characteristicWrite[key];

            btDispatch(setReader(characteristicRead));
            // btDispatch(setDeviceConnected(deviceCache));
            btDispatch(setDeviceConnectedCount(Object.keys(deviceCache).length));
            device?.gatt?.connected && device.gatt.disconnect();
        }

        btDispatch(setDeviceConnectedLoader(false));
        if (!Object.keys(deviceCache).length) {
            // btDispatch(togglePort(false));
            btDispatch(setConnectionType(null));
            btDispatch(setDeviceConnectedCount(0));
            btDispatch(setReader({}));
            // btDispatch(resetReduxState());
        }
    }
};

export const autoconnectBtDevice = async () => {
    if (!navigator.bluetooth) {
        toast.warn(
            'Включите экспериментальные возможности. Подробности "https://github.com/WebBluetoothCG/web-bluetooth/blob/main/implementation-status.md"',
            'Ваш браузер не поддерживает Bluetooth API.'
        );
        btDispatch(togglePort(false));
        btDispatch(setConnectionType(null));
        return false;
    }

    await navigator.bluetooth
        .getDevices()
        .then((devices) => {
            console.log(devices);
            devices.forEach((device) => {
                device.connected && requestBluetoothDevice(device);
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

// Запрос выбора Bluetooth устройства
export const requestBluetoothDevice = async (device) => {
    if (!navigator.bluetooth) {
        toast.warn(
            'Включите экспериментальные возможности. Подробности "https://github.com/WebBluetoothCG/web-bluetooth/blob/main/implementation-status.md"',
            'Ваш браузер не поддерживает Bluetooth API.'
        );
        btDispatch(togglePort(false));
        btDispatch(setConnectionType(null));
        return false;
    }

    const abortController = new AbortController();

    // let scan = await navigator.bluetooth.requestLEScan({
    //     acceptAllAdvertisements: true, // This is optional.
    //     // listenOnlyGrantedDevices: true,
    // });

    // navigator.bluetooth.addEventListener('advertisementreceived', advResponseEvent);

    // scan.stop();

    if (device) {
        const devicePromise = new Promise((resolve, reject) => {
            resolve(device);
        });

        // console.log(device);

        return devicePromise.then(async (device) => {
            const deviceName = device.name;
            const key = device.id;
            deviceCache[key] = device;
            toast.info(`Подключаемся к ${deviceName}`);
            await device.addEventListener('gattserverdisconnected', handleDisconnection);
            // await device.watchAdvertisements();
            // await device
            //     .watchAdvertisements()
            //     .then((e) => console.log(e))
            //     .catch((err) => console(err));
            // await device.addEventListener('advertisementreceived', advResponseEvent);
            return device;
        });
    } else {
        return navigator.bluetooth
            .requestDevice({
                acceptAllDevices: true,
                optionalServices: [HM10_SERIAL_UUID, ESP32_SERIAL_UUID],
            })
            .then(async (device) => {
                const deviceName = device.name;
                const key = device.id;
                deviceCache[key] = device;
                toast.info(`Подключаемся к ${deviceName}`);
                await device.addEventListener('gattserverdisconnected', handleDisconnection);
                // await device.addEventListener('advertisementreceived', advResponseEvent);
                // await device.watchAdvertisements();
                // device.watchAdvertisements().then(() => device);
                return device;
            });
    }
};

// function advResponseEvent(event) {
//     console.log('advResponseEvent', event);
// }

// const checkConnectedDevice = async () => {
//     try {
//         await navigator.bluetooth
//             .getDevices()
//             .then((devices) => {
//                 // console.log(devices);
//                 posibleDevices = devices;
//                 devices.forEach((device) => {
//                     if (device?.gatt?.connected) {
//                         // deviceCache.push(device);
//                     }
//                 });
//             })
//             .catch((err) => {
//                 console.log(err);
//             });

//         // console.log(deviceCache)

//         if (deviceCache.length) {
//         }
//     } catch (e) {}
// };
// checkConnectedDevice();
