import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {isAuth} from "../../../helpers/auth";
import {fetchclass, fetchUsersByOrg, selectusers} from "../../../redux/slices/classsline";
import {mapDispatchToProps, mapStateToProps} from "./redux";
import {printUserRoleGen, printUserRoleMul} from "../../../helpers/text";
import {MembersGrid} from "../../Grids";
import Footer from "../Footer";
import "./Dashboard.scss";
import {FEATURES} from "../../../api/config";
import {storageOrg} from "../../../redux/slices/storage";
import {Dropdown, FilterByText, Icon} from "../../ui";
import { Content, ContentBody, ContentHeader, ContentWrap } from "../ContentParts";
import { Button } from 'rlabui';
import DropdownItem from "../../ui/Dropdown/DropdownItem";

export const DashboardMembers = (props) => {
    const dispatch = useDispatch();
    const role = props.match.path === FEATURES.teachers.to ? 1: 0;
    const documentOrg = useSelector(storageOrg);
    const [usersList] = useSelector(selectusers);
    const [search, setSearch] = useState('');

    useEffect(() => {
        dispatch(fetchclass(documentOrg._id));
        dispatch(fetchUsersByOrg(documentOrg._id))
    }, [dispatch, documentOrg._id]);

    // TEST DATA, отобразил через Dropdown импортированный из ui, отобразить тестовые данные через Dropdown импортированный из rlabui не получилось у меня
    const testClasses = [
        {label: 'Все', key: 'all'},
        {label: '7A', key: '7A'},
        {label: '8C', key: '8C'},
    ];
    const testSort = [
        {label: 'По алфавиту (А-Я)', key: 'bottom'},
        {label: 'По алфавиту (Я-А)', key: 'top'},
    ];
    const testActivity = [
        {label: 'Все', key: 'all'},
        {label: 'Другой вариант', key: 'other'},
    ];
    // END TEST DATA

    return (
        <ContentWrap>
            <Content>
                <ContentHeader>
                    <div className="cor-net__row">
                        <div className="cor-net__col col-8">
                            <div className="cor_content__title">{printUserRoleMul(role)}</div>
                        </div>

                        <div className="cor-net__col col-6">
                            <FilterByText 
                                placeholder="Введите имя"
                                value={search}
                                setSearch={setSearch}
                            />
                        </div>
                     
                        <div className="cor-net__label mb_0">Классы</div>
                        <div className="cor-net__col col-8">
							<Dropdown value={testClasses[0].label}  >
								{testClasses.map(item => {
									const {label, key} = item;
									return <DropdownItem key={key}>{label}</DropdownItem>
								})}
							</Dropdown>
                        </div>

                        <div className="cor-net__label mb_0">Сортировка</div>
                        <div className="cor-net__col col-8">
							<Dropdown value={testSort[0].label}  >
								{testSort.map(item => {
									const {label, key} = item;
									return <DropdownItem key={key}>{label}</DropdownItem>
								})}
							</Dropdown>
                        </div>

                        <div className="cor-net__label mb_0">Активность</div>
                        <div className="cor-net__col col-8">
							<Dropdown value={testActivity[0].label}  >
								{testActivity.map(item => {
									const {label, key} = item;
									return <DropdownItem key={key}>{label}</DropdownItem>
								})}
							</Dropdown>
                        </div>

                        <div className="cor-net__col col-grow"></div>
                        <div className="cor-net__col">
                            <Button
                                border={true}
                                size="small"
                                onClick={() => props.showUserSettings({showModal: true, role, isAdd: true, user: {city: documentOrg.city}, owner: documentOrg._id})}
                            >
                                + Добавить {printUserRoleGen(role).toLowerCase()}
                            </Button>
                        </div>
                    </div>
                </ContentHeader>
                <ContentBody>
                    <MembersGrid 
                        disabled={isAuth().role < 2 && role === 1} 
                        delete="true" 
                        usersList={ usersList.filter(u => u.role === role &&
                                ([u.name,u.name2,u.name3,u.login, u.email]).some(s=>s?.toLowerCase().includes(search.toLowerCase()))
                        )}
                    />
                </ContentBody>
            </Content>
            <Footer {...props}/>
        </ContentWrap>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardMembers)