import React, { useState } from 'react';
import { Modal, Button, InputNumber } from 'rlabui';
import ss from './TimerModal.module.scss';

const TimerModal = (props) => {
    const { setStopwatch, modal, toggleTimerModal } = props;
    const [hours, setHours] = useState(0);
    const [minutes, setMinute] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const clearStopwatch = () => {
        toggleTimerModal(false);
        setStopwatch();
        setHours(0);
        setMinute(0);
        setSeconds(0);
    };

    const setModalContent = () => (
        <>
            <Modal.Head
                modalClose={() => clearStopwatch()}
                title="Таймер"
            ></Modal.Head>
            <Modal.Body>
                <div className={ss.root}>
                    <div className={ss.item}>
                        <div className={ss.legend}>Часы</div>
                        <div className={ss.number}>
                            <InputNumber
                                step={1}
                                min="0"
                                max="24"
                                plus={() => setHours((hours) => (hours < 24 ? ++hours : 0))}
                                minus={() => setHours((hours) => (hours > 0 ? --hours : 24))}
                                value={hours < 10 ? hours : hours}
                                onChange={({ target: { value } }) => setHours(value <= 24 && value > 0 ? (value ? value : '') : 0)}
                            />
                        </div>
                    </div>
                    <div className={ss.item}>
                        <div className={ss.legend}>Минуты</div>
                        <div className={ss.number}>
                            <InputNumber
                                step={1}
                                min="0"
                                max="60"
                                plus={() => setMinute((minutes) => (minutes < 60 ? ++minutes : 0))}
                                minus={() => setMinute((minutes) => (minutes > 0 ? --minutes : 60))}
                                value={minutes && minutes < 10 ? minutes : minutes}
                                onChange={({ target: { value } }) => setMinute(value)}
                            />
                        </div>
                    </div>
                    <div className={ss.item}>
                        <div className={ss.legend}>Секунды</div>
                        <div className={ss.number}>
                            <InputNumber
                                step={1}
                                min="0"
                                max="60"
                                plus={() => setSeconds((seconds) => (seconds < 60 ? ++seconds : 0))}
                                minus={() => setSeconds((seconds) => (seconds > 0 ? --seconds : 60))}
                                value={seconds < 10 ? seconds : seconds}
                                onChange={({ target: { value } }) => setSeconds(value)}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer align="left">
                <Button
                    onClick={() => {
                        let time = 0;
                        if (hours) {
                            time += hours * 3600000;
                        }
                        if (minutes) {
                            time += minutes * 60000;
                        }
                        if (seconds) {
                            time += seconds * 1000;
                        }
                        setStopwatch(time);
                        toggleTimerModal(false);
                    }}
                >
                    Установить
                </Button>
                <Button
                    border={true}
                    onClick={() => clearStopwatch()}
                >
                    Отмена
                </Button>
            </Modal.Footer>
        </>
    );

    return (
        <Modal
            visible={modal}
            size={'xs'}
        >
            {setModalContent()}
        </Modal>
    );
};
export default TimerModal;
