import {showUserSettings, showUserPassword, showStorageSettings} from "../../actions/actions";

export const mapStateToProps = (state) => {
    return ({
    });
};

export const mapDispatchToProps = (dispatch) => ({
    showUserSettings: (obj) => dispatch(showUserSettings(obj)),
    showUserPassword: (obj) => dispatch(showUserPassword(obj)),
    showStorageSettings: (obj) => dispatch(showStorageSettings(obj)),
});
