import { setChartSignalMode } from '../../../actions/chart';

export const mapStateToProps = (nstate) => {
    return {
        mod: nstate.nchart.signalMode,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setMode: (num) => dispatch(setChartSignalMode(num)),
});
