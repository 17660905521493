export const LIST_COLORS = [
  { menuColor: '#FF7089', listBackgroundColor: '#FDD7DE' },
  { menuColor: '#FF984E', listBackgroundColor: '#FFE9D9' },
  { menuColor: '#FFF94A', listBackgroundColor: '#FDFCDD' },
  { menuColor: '#97EC55', listBackgroundColor: '#E4F0DA' },
  { menuColor: '#49EFF6', listBackgroundColor: '#E3FAFB' },
  { menuColor: '#33BEDD', listBackgroundColor: '#DBF6FC' },
  { menuColor: '#4A96EB', listBackgroundColor: '#E8F0F9' },
  { menuColor: '#5942E9', listBackgroundColor: '#EFECFE' },
  { menuColor: '#CC2FEB', listBackgroundColor: '#FBECFE' },
];