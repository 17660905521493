import React, { useState } from 'react';
import ss from './ConnectionButtons.module.scss';
import fss from '../template/Footer/Footer.module.scss';
import { Modal, Button } from 'rlabui';

const ConnectionButtons = ({ played, port, sensorsList, getSerialPortsList, serialPortsDisconnect, connectionType, btConnect }) => {
    const [modal, toggleModal] = useState(false);

    return (
        <>
            <div className={fss.group + ' ' + ss.connectionButtons}>
                <div className={fss.label}>
                    Источник <br />
                    cигнала
                </div>
                <div className={fss.btns}>
                    <button
                        className={port && connectionType === 'usb' ? ss.button + ' icon_usb active' : ss.button + ' icon_usb'}
                        // title={port ? 'Отключить' : 'Подключить'}
                        disabled={played || connectionType === 'bt'}
                        onClick={() => {
                            if (port) {
                                toggleModal(true);
                            } else {
                                getSerialPortsList();
                            }
                        }}
                    >
                        {port && connectionType === 'usb' && sensorsList.length > 0 && <span>{sensorsList.length}</span>}
                    </button>
                    <button
                        className={port && connectionType === 'bt' ? ss.button + ' icon_bluetooth active' : ss.button + ' icon_bluetooth'}
                        // title={port ? 'Отключить' : 'Подключить'}
                        disabled={played || connectionType === 'usb'}
                        onClick={() => {
                            if (port) {
                                toggleModal(true);
                            } else {
                                btConnect();
                            }
                        }}
                    >
                        {port && connectionType === 'bt' && sensorsList.length > 0 && <span>{sensorsList.length}</span>}
                    </button>
                </div>
                <Modal
                    visible={modal}
                    size={'xs'}
                >
                    <Modal.Head
                        title="Отключить устройство"
                        modalClose={() => toggleModal(false)}
                    />
                    <Modal.Body>Вы действительно хотите отключить устройство?</Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() => {
                                switch (connectionType) {
                                    case 'bt':
                                        break;
                                    case 'usb':
                                        serialPortsDisconnect();
                                        break;
                                    default:
                                        break;
                                }
                                toggleModal(false);
                            }}
                        >
                            Да
                        </Button>
                        <Button
                            className={'btn_border'}
                            onClick={() => toggleModal(false)}
                        >
                            Нет
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
};
export default ConnectionButtons;
