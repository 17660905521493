import React, { useEffect, useState } from 'react';
import './NewModals.scss';
import NewModalsButton from './NewModalsButton';
import { useDispatch, useSelector } from 'react-redux';
import { addNewColumn, updateColumn } from '../../../redux/slices/trello';
import { storageClass } from '../../../redux/slices/storage';
import socket from '../../../socket';

const RenameSectionModal = ({ onCloseModal, activeSection }) => {
  const dispatch = useDispatch();

  const documentClass = useSelector(storageClass);
  
  const [newSectionName, setNewSectionName] = useState('');

  useEffect(() => {
    setNewSectionName(activeSection?.name);
  }, [activeSection?.name])
  
  const renameSection = () => {
    const newName = {
      newSectionName,
    };
    if (newSectionName) {
      dispatch(updateColumn(activeSection?._id, 'name', newSectionName));
      socket.emit('BE-refresh-db', {
        roomId: documentClass[0]?._id,
        type: 'trackerGroup',
        id: activeSection?._id,
        data: newName,
      });
      onCloseModal();
    }
  };

  return (
    <div className="new_modals">
      <h2 className="new_modals__title">Переименование раздела</h2>
      <div className="new_modals__content">
        <div className="new_modals__section">
          <div className="new_modals__section_item">
            <div className="new_modals__section_title">Название раздела</div>
            <input
              autoFocus
              type="text"
              value={newSectionName}
              placeholder="Придумайте название для раздела"
              onChange={(e) => setNewSectionName(e.target.value)}
              className="new_modals__section_input"
            />
          </div>
        </div>
        <div className="new_modals__section">
          <div className="new_modals__section_buttons">
            <NewModalsButton
              type="add"
              title="Переименовать раздел"
              onClick={renameSection}
            />
            <NewModalsButton
              type="cancel"
              title="Отмена"
              onClick={() => onCloseModal()}
            />
          </div>
        </div>
      </div>
      <span
        className="modal_task__close"
        style={{ top: 0, right: 0 }}
        onClick={() => onCloseModal()}
      />
    </div>
  );
};

export default RenameSectionModal;
