import { setSensorList, setSensorRecords } from '../../actions/sensor';
import { setChartMarkerAdd, setChartMarkerDelete, setChartMarkerDeleteAll, setChartMarkers, delChartMarkers, setChartXRanges, setChartOffsetRec, setCursorSync } from '../../actions/chart';

export const mapStateToProps = (nstate) => {
    return {
        port: nstate.nport.open,
        data: nstate.nsensor.data,
        timeUpdate: nstate.ntimer.timeUpdate,
        sensorImported: nstate.nsensor.imported,
        sensorRecords: nstate.nsensor.records,
        filters: nstate.nsensor.filters,
        visualized: nstate.nsensor.visualized,
        timeIntervals: nstate.nsensor.intervals,

        view: nstate.nchart.settings.view,
        offsetRec: nstate.nchart.offsetRec,
        markerActions: nstate.nchart.markers,
        markerMode: nstate.nchart.mode,
        syncCursor: nstate.nchart.cursor,
        rangeActions: nstate.nchart.range,
        xRanges: nstate.nchart.xRanges,
        markers: nstate.nchart.marker,
        defaultColors: nstate.nchart.settings.colors,
        chartStyle: nstate.nchart.style,
        chartDisabled: nstate.nchart.disabled,
        deleteAllMarkers: nstate.nchart.markers.deleteAll,
        devicesList: nstate.ndevice.list,
        played: nstate.napp.played,
        paused: nstate.napp.paused,
        theme: nstate.napp.theme,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    setSensorList: (list) => dispatch(setSensorList(list)),
    setSensorRecords: (list) => dispatch(setSensorRecords(list)),
    setMarkers: (obj) => dispatch(setChartMarkers(obj)),
    setXRanges: (obj) => dispatch(setChartXRanges(obj)),
    setChartMarkerAdd: (bool) => dispatch(setChartMarkerAdd(bool)),
    setChartMarkerDelete: (bool) => dispatch(setChartMarkerDelete(bool)),
    setChartMarkerDeleteAll: (bool) => dispatch(setChartMarkerDeleteAll(bool)),
    delMarkers: (key) => dispatch(delChartMarkers(key)),
    setOffsetRec: (bool) => dispatch(setChartOffsetRec(bool)),
    setCursorSync: (obj) => dispatch(setCursorSync(obj)),
});
