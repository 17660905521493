import {toast} from "react-toastify";
import { setPort, setPortOpen, sendCommand, setPortReader, setPortWriter, setPortStream, setConnectionType, setPortStreamClose } from '../actions/port';
import { setSensorVisualized, setSensorData } from '../actions/sensor';
// import { updateTime } from '../actions/timer';
import { LineBreakTransformer } from './utils/line-break-transform';
import { connectWorker } from '../workers/WebWorker';

import { setSensorResp } from '../actions/sensor';
import { resetReduxState } from '../actions/app';

// const textDecoder = new TextDecoder();
const textEncoder = new TextEncoder();
// let respBuffer = '';
let refreshCount = 0;
let freqPerSec = 0;
let isHightSpeed = false;
let freqInterval;
let oldTime = 0;
let savedData = {};
let buttonPressed = false;
let readableStreamClosed;
let cpu = navigator.hardwareConcurrency;
// let workerDispatch;

export const autoConnectPort = () => async (dispatch, getState) => {
    const {
        nport: { port },
    } = getState();
    if (!port) {
        await navigator.serial.getPorts().then((ports) => {
            dispatch(connect(ports));
        });
        navigator.serial.addEventListener('connect', (e) => {
            navigator.serial.getPorts().then((ports) => {
                dispatch(connect(ports));
            });
        });
    }
};

export const getSerialPortsList = () => async (dispatch, getState) => {
    const ports = [];
    const {
        nport: { port },
    } = getState();

    if (port) return;

    await navigator.serial
        .requestPort()
        .then((port) => (ports[0] = port))
        .catch((error) => {
            if (error.message.includes('No port selected by the user')) {
                toast.info('Не выбранно устройство для подключения');
            } else {
                toast.error('Произошла ошибка при подключении');
            }
        });

    dispatch(connect(ports));
};

export const connect = (ports) => async (dispatch, getState) => {
    const {
        nport: { port },
    } = getState();
    // workerDispatch = dispatch();

    if (ports.length && !port) {
        dispatch(setPort(ports[0]));
        if (ports.length > 0) {
            dispatch(serialPortOpen(ports[0]));
        }
        navigator.serial.addEventListener('disconnect', (e) => {
            dispatch(resetReduxState());
        });
    } else {
        dispatch(resetReduxState());
        navigator.serial.removeEventListener('connect', (e) => null);
        navigator.serial.removeEventListener('disconnect', (e) => null);
    }
};

const serialPortOpen = (port) => async (dispatch) => {
    await port
        .open({ baudRate: 115200 })
        .then(() => {
            // console.log('port open');
            connectWorker?.addEventListener('message', (e) => dispatch(processMessage(e)));
            dispatch(setPortOpen(true));
            dispatch(setConnectionType('usb'));
            dispatch(setPortReader(port.readable));
            dispatch(setPortWriter(port.writable));
            dispatch(sendCommand('key'));
            dispatch(serialPortReading(port));
        })
        .catch((e) => {
            console.log('Port open error', e);

            // DOMException: Failed to execute 'open' on 'SerialPort': Failed to open serial port.
            // sudo usermod -a -G dialout $USER
        });
};

const serialPortReading = (port) => async (dispatch) => {
    if (!port) return;

    const textDecoder = new TextDecoderStream('UTF-8');
    const stream = textDecoder.readable.pipeThrough(new TransformStream(new LineBreakTransformer())).getReader();
    readableStreamClosed = port?.readable?.pipeTo(textDecoder.writable);

    // console.log(port);
    // console.log(readableStreamClosed);
    dispatch(setPort(port));
    // const stream = port.readable.getReader();
    dispatch(setPortStream(stream));
    dispatch(setPortStreamClose(readableStreamClosed));
    dispatch(sendCommand('acq 0'));
    setInterval(() => {
        // console.log('Частота', freqPerSec);
        if (freqPerSec > 100) {
            isHightSpeed = true;
        } else {
            isHightSpeed = false;
        }
        freqPerSec = 0;
    }, 1000);

    try {
        await stream.read().then((e) => dispatch(process(e)));
        if (!port) {
            dispatch(setPortOpen(false));
            dispatch(setConnectionType());
        }
    } catch (err) {
        console.log(err);
        try {
            readableStreamClosed?.catch(() => {});
            stream?.relaseLock();
        } catch (e) {
            console.log(e);
        }
    }
};

const processMessage = (e) => async (dispatch, getState) => {
    const { data, response } = e.data;
    const {
        // ntimer: { timer },
        napp: { played, paused },
    } = getState();

    if (played) {
        if (paused) {
            dispatch(setSensorResp(response));
        } else {
            data && dispatch(setSensorData(data));
            dispatch(setSensorResp(++refreshCount));
        }
    } else {
        refreshCount = 0;
        response?.length && dispatch(setSensorResp(response));
    }
};

// const workerSender = (data) => {
//     console.log(data);
// };

const process =
    ({ done, value }) =>
    async (dispatch, getState) => {
        const state = getState();
        const {
            nport: { stream, streamClosed },
            ndevice: { connected },
            nsensor: { list },
            napp: { played, paused },
            ntimer: { timer },
            nchart: { signalMode },
        } = state;

        // console.log(stream);
        // console.log(done, value);

        if (done) {
            await streamClosed?.catch(() => {});
            try {
                await stream?.relaseLock();
            } catch (e) {}
            clearInterval(freqInterval);

            return;
        } else {
            // Примерный расчет частоты
            freqPerSec++;

            connectWorker?.postMessage({ value, played, paused, timer, signalMode, list, connected, isHightSpeed, cpu });
            // const arr = value.split('\r\n').filter((e) => e);
            // if (played) {
            //     dispatch(sendSensorsData(arr));
            //     // Примерный расчет частоты
            //     // freqPerSec += arr.length;
            //     // setTimeout(() => {
            //     //     console.log('Частота', freqPerSec);
            //     // }, 1000);
            // } else {
            //     const checkConnected = connected?.filter((e) => arr[0].includes(e));
            //     if (list?.length > arr[0].split(' ').length && checkConnected.length) {
            //         dispatch(setSensorVisualized());
            //     }
            //     refreshCount = 0;
            //     // console.log(arr[0]);
            //     // dispatch(setSensorResp(arr[0]));
            //     oldTime = 0;
            // }

            return stream.read().then((e) => dispatch(process(e)));
        }
    };

export const sendSensorResponse = (arr) => async (dispatch, getState) => {
    const {
        ndevice: { connected },
        nsensor: { list },
        napp: { played },
    } = getState();

    if (played) {
        dispatch(sendSensorsData(arr));
        // Примерный расчет частоты
        // freqPerSec += arr.length;
        // setTimeout(() => {
        //     console.log('Частота', freqPerSec);
        // }, 1000);
    } else {
        const checkConnected = connected?.filter((e) => arr[0].includes(e));
        if (list?.length > arr[0].split(' ').length && checkConnected.length) {
            dispatch(setSensorVisualized());
        }
        refreshCount = 0;
        console.log(arr[0]);
        // dispatch(setSensorResp(arr[0]));
        oldTime = 0;
    }
};

export const sendSensorsData = (arr) => async (dispatch, getState) => {
    const {
        ndevice: { connected },
        ntimer: { timer },
        nsensor: { list },
        nchart: { signalMode },
        napp: { paused },
    } = getState();

    if (!list.length) return;

    if (oldTime === 0 && timer === 0) {
        // Чистим сохраненный объект
        for (const key in savedData) {
            delete savedData[key];
        }
        // Отправляем первое значение при времени 0 (PS: как правило приходит сразу несколько точек)
        connected.forEach((key, i) => {
            savedData[key] = [[0], [!isNaN(+arr[i]) ? +arr[i] : 0]];
        });
    } else {
        if (paused) return;
        // console.log(timer);
        // const offset = arrLenght ? (timer - oldTime) / arrLenght : 0;
        arr.forEach((str, i) => {
            const data = str.split(' ');
            // console.log(data);
            // const newTime = oldTime + offset * i;
            // console.log('Time', newTime);
            if (data.length === list.length) {
                connected.forEach((key, k) => {
                    const val = +data[k];
                    if (typeof val === 'number' && !isNaN(val)) {
                        if (key !== 'Button') {
                            savedData[key][1].push(val);
                            // savedData[key][0] = range(0, timer, savedData[key][1].length);
                        } else {
                            // Обновляем сигнал кнопки
                            if (val) {
                                if (!buttonPressed) {
                                    buttonPressed = true;
                                    updateSignalData(signalMode, 1);
                                    return;
                                }
                            } else {
                                if (buttonPressed) {
                                    buttonPressed = false;
                                    updateSignalData(signalMode, 0);
                                    return;
                                }
                            }
                            updateSignalData(signalMode, 0);
                        }
                    }
                    // Подрезка данных за пределами 300 секунд
                    // if (savedData[key][0].at(-1) - savedData[key][0][0] >= 300 * 1000) {
                    //     savedData[key][0].shift();
                    //     savedData[key][1].shift();
                    // }
                });
            }
        });
    }
    oldTime = timer;

    // console.log(savedData);

    for (const key in savedData) {
        if (savedData[key][1].length) {
            savedData[key][0].push(timer);
            // savedData[key][0] = range(0, timer, savedData[key][1].length);
        }
    }

    // !paused && dispatch(updateTime(refreshCount++));
    !paused && dispatch(setSensorResp(refreshCount++));
    // !paused && dispatch(setSensorData(savedData));
};

// const range = (begin, end, points) => {
//     if (begin !== end) {
//         return [begin, ...between(begin, end, points), end];
//     } else if (Number.isFinite(begin)) {
//         return [begin]; // singleton set
//     } else throw new Error('Endpoints must be finite');
// };

// const between = (begin, end, points = 1) => {
//     if (!Number.isFinite(begin) || !Number.isFinite(end) || !Number.isFinite(points)) {
//         throw new Error('All arguments must be finite');
//     }
//     const set = [];
//     // Skip if an open interval does not exist
//     if (begin !== end) {
//         const step = (end - begin) / (points + 1);
//         for (let i = 0; i < points; i++) {
//             set[i] = Math.round(begin + (i + 1) * step);
//         }
//     }
//     return set;
// };

export const serialPortsDisconnect = () => async (dispatch, getState) => {
    const {
        nport: { port, reader, stream, streamClose },
    } = getState();

    try {
        try {
            await stream?.cancel();
        } catch (e) {}

        await streamClose?.catch(() => {});

        if (reader?.locked) {
            await reader.cancel();
        }

        await port?.close().catch((e) => {
            console.log(e);
            toast.info('Произошла ошибка при отключении. Переподключите устройство для повторного использования');
        });

        dispatch(setPortWriter(null));
        dispatch(setPortOpen(false));
        dispatch(setConnectionType());
        dispatch(resetReduxState());
        connectWorker?.removeEventListener('message', processMessage);
    } catch (e) {
        console.log('diconnect error', e);
    }
};

export const serialPortWrite = (str) => async (dispatch, getState) => {
    if (!str) return;
    const {
        nport: { writer },
    } = getState();
    if (writer && !writer.locked) {
        const write = writer?.getWriter();
        await write?.write(textEncoder.encode(str)).catch();
        write?.releaseLock();
    }
};

function updateSignalData(signalMode, press) {
    const vals = savedData?.Button[1];
    const signalPrev = vals?.at(-1);
    let val = 0;

    switch (signalMode) {
        case 1:
            // Одиночный
            if (press && !signalPrev) {
                val = 1;
            } else {
                val = 0;
            }
            savedData.Button[1].push(val);
            break;

        case 2:
            // Двойной
            const last = vals?.findLast((e) => e !== 0);
            if (press && last === -1) {
                val = 1;
            }
            if (press && last === 1) {
                val = -1;
            }
            savedData.Button[1].push(val);
            break;

        default:
            // Временной
            if (press && signalPrev) {
                savedData.Button[1].push(0);
            } else if (press && !signalPrev) {
                savedData.Button[1].push(1);
            } else {
                savedData.Button[1].push(signalPrev);
            }
            break;
    }
}
