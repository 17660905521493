import React, { useEffect, useRef, useState } from 'react';
import Icon from '../Icon';
import { Input } from 'rlabui';
import ChartCommentItem from './ChartCommentItem';
import './ChartComment.scss';

const ChartComment = ({ setChartComment, comment, num, played, seriesLength, setCommentImageModal }) => {
    const [open, toggleOpen] = useState(false);
    const [current, setCurrent] = useState(comment[num]);
    const [text, setText] = useState('');
    const [img, setImg] = useState();
    const [imgName, setImgName] = useState();
    const [cover, setCover] = useState();
    const [disabled, setDisabled] = useState(seriesLength[num]);
    const [count, setCount] = useState(0);
    const fileRef = useRef();

    useEffect(() => {
        if (img) {
            const reader = new FileReader();
            reader.readAsDataURL(img);
            reader.onload = () => setCover(reader.result);
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        }
    }, [img]);

    useEffect(() => {
        current?.length && setChartComment({ num, data: current });
        setCount(current?.length);
    }, [current, setCount]);

    useEffect(() => {
        setCurrent(comment[num]);
    }, [comment, num, setCurrent]);

    useEffect(() => {
        if (played) {
            setCurrent([]);
            toggleOpen(false);
        }
    }, [played]);

    useEffect(() => {
        setDisabled(seriesLength[num] || (typeof num === 'string' && num.includes('bundle')));
        if (!seriesLength[num]) {
            toggleOpen(false);
        }
    }, [seriesLength, num]);

    const setNewComment = () => {
        if (text || img) {
            const date = new Date();
            const data = { date, message: text, img };
            setCurrent((e) => (e?.length ? (e = [...e, data]) : (e = [data])));
            setText('');
            setImg();
            setImgName();
            setCover();
        }
    };

    return (
        <div className="chartComment">
            <Icon
                tag="button"
                name="comment"
                title="Комментарий"
                disabled={!disabled}
                onClick={() => toggleOpen(true)}
            >
                {count > 0 && <span>{count}</span>}
            </Icon>

            {open && (
                <div className="chartComment__drop">
                    <div className="chartComment__head">
                        <div className="chartComment__title">
                            <Icon name="comment" />
                            <span>Комментарии</span>
                        </div>
                        <Icon
                            name="close"
                            onClick={() => toggleOpen(false)}
                        />
                    </div>
                    <div className="chartComment__body">
                        {current?.length ? (
                            current?.map((item, i) => (
                                <ChartCommentItem
                                    key={i}
                                    props={{ item, current, setCurrent, setCommentImageModal }}
                                />
                            ))
                        ) : (
                            <div className="chartComment__item">
                                <div className="chartComment__text">Нет комментариев</div>
                            </div>
                        )}
                    </div>
                    {cover && (
                        <div className="chartComment__loaded">
                            <div className="chartComment__cover">
                                <img
                                    src={cover}
                                    alt=""
                                />
                            </div>
                            <span>{imgName}</span>
                            <Icon
                                name="close"
                                onClick={() => {
                                    setImg();
                                    setImgName();
                                    setCover();
                                    fileRef.current.value = '';
                                }}
                            />
                        </div>
                    )}
                    <div className="chartComment__foot">
                        <div className="chartComment__field">
                            <Input
                                value={text}
                                placeholder="Добавить комментарий"
                                onChange={(e) => setText(e.target.value)}
                                onKeyDown={({ key }) => {
                                    key === 'Enter' && setNewComment();
                                }}
                                autoComplete="false"
                            />
                        </div>
                        <label className="chartComment__file">
                            <Icon name="picture" />
                            <input
                                ref={fileRef}
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                    setImg(e.target?.files[0]);
                                    setImgName(e.target?.files[0]?.name);
                                }}
                            />
                        </label>
                        <Icon
                            name="enter"
                            onClick={setNewComment}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChartComment;
