import React  from "react";
import Footer from "../Footer";
import { Content, ContentBody, ContentHead, ContentWrap } from "../ContentParts";
import SectionTabs from "../../ui/SectionTabs";
import "./Dashboard.scss";
import {FEATURES} from "../../../api/config";

const tabs = [
  {name: "Цифровые лаборатории >", icon: "integral", tooltip: '', to: FEATURES.rlab.alt[0], notify: 0 },
  {name: "Нейро лаб >", icon: "union", tooltip: '', to: FEATURES.rlab.alt[3], notify: 0 },
]

export const DashboardRlabSelect = (props) => {
  return (
      <ContentWrap>
        <ContentHead title="Лаборатории" />
        <Content background={false}>
          <ContentBody padding={false} scrolled={false}>
              <SectionTabs {...props} tabs={tabs}/>
          </ContentBody>
        </Content>
        <Footer {...props}/>
      </ContentWrap>
  );
};

export default DashboardRlabSelect