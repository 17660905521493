import Peer from "peerjs";

const [pProto,,pServer] = process.env.REACT_APP_PEER_URL.split('/');
const [pServerHost, pServerPort] = pServer.split(':');
const pServerSecure = pProto === 'https:';

const peer = new Peer({
  host: pServerHost,
  secure: pServerSecure,
  port: pServerPort || (pServerSecure ? 443 : 80),
  path: '/peerjs',
});

export default peer;
