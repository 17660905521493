import React from 'react';

const TabsItem = ({children, className = "", active = false, onClick = null}) => {
    const activeClass = active ? " active" : "";
    const customClass = className ? " " + className : "";
    return <div
        className={"tabs__item" + activeClass + customClass}
        onClick={onClick}
        >
        {children}
    </div>
};

export default TabsItem;
