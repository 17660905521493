export const resetReduxState = (bool) => ({ type: 'RESET_REDUX_STATE', payload: bool });
export const setTheme = (bool) => ({ type: 'SET_THEME', payload: bool });
export const setPlay = (bool) => ({ type: 'SET_PLAY', payload: bool });
export const setForceUpdate = (bool) => ({ type: 'SET_FORCE_UPDATE', payload: bool });
export const setPaused = (bool) => ({ type: 'SET_PAUSE', payload: bool });
export const setDeviceResponse = (str) => ({ type: 'SET_DEVICE_RESPONSE', payload: str });
export const showModalDisconnect = (bool) => ({ type: 'SHOW_MODAL_DISCONNECT', payload: bool });
export const showModalInfo = (bool) => ({ type: 'SHOW_MODAL_INFO', payload: bool });
export const setMobile = (bool) => ({ type: 'SET_MOBILE', payload: bool });
export const setMobileView = (str) => ({ type: 'SET_MOBILE_VIEW', payload: str });
export const setCor = (bool) => ({ type: 'SET_COR', payload: bool });
export const setNeedUpdate = (bool) => ({ type: 'SET_NEED_UPDATE', payload: bool });
export const setSource = (bool) => ({ type: 'SET_SOURCE', payload: bool });
