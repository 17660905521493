import React, { useEffect, useState, useRef } from 'react';
import * as Excel from 'exceljs';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Buffer } from 'buffer';
import Icon from '../Icon';
import Moment from 'moment';
import { Button } from 'rlabui';
import chartStyles from '../../styles/charts';
import ss from './ImportExport.module.scss';

const ImportExport = (props) => {
    const { isAndroid, cordova } = window;

    const {
        port,
        theme,
        records,
        sensorsImport,
        played,
        setSensorImported,
        addSensorsBundles,
        setChartView,
        chartView,
        setChartMarkers,
        sendCommand,
        setPrintModal,
        isReading,
        colors,

        writeStatus,
        setWriteStatus,

        file,
        setFile,
        fileExtension,
        setFileExtension,
        isOscilloscope,

        isCor,
        graphFile,
        setGraphFile,
    } = props;

    const [recordTime, setRecordTime] = useState(0);
    const [disabledPrint, setDisabledPrint] = useState(true);
    const timerRef = useRef();
    const csvRef = useRef();
    const xlsRef = useRef();
    const txtRef = useRef();
    const jsonRef = useRef();

    useEffect(() => {
        if (writeStatus) {
            const timer = new Date().valueOf();
            timerRef.current = setInterval(function () {
                setRecordTime(new Date().valueOf() - timer);
            }, 1000);
        } else {
            clearInterval(timerRef.current);
        }
    }, [writeStatus]);

    useEffect(() => {
        if (fileExtension && records.length) {
            exportToFile();
            setSensorImported([]);
            setFileExtension();
        }
        // eslint-disable-next-line
    }, [fileExtension, setSensorImported, setFileExtension, records]);

    useEffect(() => {
        if (file) {
            importFromFile(file);
        }
        // eslint-disable-next-line
    }, [file]);

    useEffect(() => {
        if (!isCor) return;
        if (graphFile) {
            loadData(graphFile.name, graphFile.data);
            setGraphFile(undefined);
        }
    }, [isCor, graphFile, setGraphFile]);

    useEffect(() => {
        if (played) {
            // setSensorImported([]);
            xlsRef.current.value = null;
            csvRef.current.value = null;
            txtRef.current.value = null;
            jsonRef.current.value = null;
            setFile('');
            setFileExtension();
        }
    }, [played, setFile, setFileExtension]);

    useEffect(() => {
        if (played || port || sensorsImport.length) {
            setDisabledPrint(false);
        } else {
            setDisabledPrint(true);
        }
    }, [played, sensorsImport, port, setDisabledPrint]);

    const exportToFile = async () => {
        const date = new Date();
        const fName = Moment(date).format('yyMMD-hhmmss');
        // console.log(XLSX);
        const workbook = new Excel.Workbook();

        let worksheet,
            worksheets = {},
            charts = {},
            dataBuffer,
            fileType;

        if (fileExtension === 'json') {
            // fileType = 'text/' + fileExtension + ';charset=utf-8';
            fileType = 'text/plain;charset=utf-8';
            // fileType = 'application/json;charset=utf-8';
            // const csv = XLSX.utils.sheet_to_csv(worksheet, { FS: ';' });
            // const encoded = Buffer.from('\uFEFF' + JSON.stringify(records), 'utf8');
            // dataBuffer = new Int8Array(encoded);
            dataBuffer = JSON.stringify(records, null, 2);
        } else {
            // Подготовка данных для таблиц
            records.forEach((item) => {
                if (item?.name) {
                    // console.log(item);
                    // Одиночные датчики
                    const { value, markers, deviceKey, sensNum, name, unit, num } = item;
                    const key = deviceKey + sensNum;
                    worksheets[key] = { key, deviceKey, name, rows: [], markers: [], ranges: [], bundles: false };

                    if (fileExtension === 'xlsx') {
                        const wrap = document.getElementById(deviceKey + num);
                        const canvas = wrap.querySelector('canvas');
                        charts[key] = canvas.toDataURL('image/png');
                    }

                    setMarkersAndRanges(markers, num, name, unit, key, value);

                    // Значения таблицы
                    value?.forEach(({ count, x, y, num }, i) => {
                        let newRow = [];
                        if (i === 0) {
                            newRow.push(['Таблица']);
                            newRow.push([
                                '№',
                                'Время,сек.',
                                `${typeof num === 'string' ? num?.replace('loc', '').replace('imp', '') : num}. ${deviceKey === 'readed' ? name + ' (Лог)' : name}, ${unit}`,
                                null,
                                // charts[num],
                            ]);
                        }
                        newRow.push([count, x, y, null]);
                        worksheets[key].rows.push(...newRow);
                    });
                } else {
                    // Связки
                    Object.keys(item).forEach((key) => {
                        if (fileExtension === 'xlsx') {
                            const wrap = document.getElementById(key);
                            const canvas = wrap.querySelector('canvas');
                            charts[key] = canvas.toDataURL('image/png');
                        }
                    });

                    for (const key in item) {
                        const { markers, list } = item[key];
                        const name = 'Канал ' + key.replace('bundle', '');
                        let itemRows = [];
                        let itemMarkers = [];
                        let itemRanges = [];
                        worksheets[key] = { key, deviceKey: key, name, rows: [], markers: [], ranges: [], bundles: true };

                        list.forEach(({ value, name, unit, num }, ind) => {
                            const { marks, range } = setMarkersAndRanges(markers, num, name, unit, key, value, true);

                            if (marks.length) {
                                if (!itemMarkers[ind]) {
                                    itemMarkers[ind] = [];
                                }
                                itemMarkers[ind].push(...marks);
                            }

                            if (range.length) {
                                if (!itemRanges[ind]) {
                                    itemRanges[ind] = [];
                                }
                                itemRanges[ind].push(...range);
                            }

                            let offset = 2;
                            itemRows[0] = ['Таблица'];

                            if (!itemRows[1]) {
                                itemRows[1] = [];
                            }

                            itemRows[1] = [...itemRows[1], '№', 'Время,сек.', `${num}. ${name},${unit}`, null];

                            value.forEach(({ count, x, y }, i) => {
                                const ind = offset + i;
                                if (!itemRows[ind]) {
                                    itemRows[ind] = [];
                                }
                                if (i === 0) {
                                    itemRows[ind] = [...itemRows[ind], count, x, y, null];
                                } else {
                                    itemRows[ind] = [...itemRows[ind], count, x, y, null];
                                }
                            });
                        });

                        worksheets[key].rows.push(...itemRows);
                        worksheets[key].markers.push(...itemMarkers);
                        worksheets[key].ranges.push(...itemRanges);
                    }
                }
            });

            // Готовим сводную таблицу
            const allRows = [];
            const allMarkers = [];
            const allRanges = [];

            // console.log(worksheets);
            for (const ws in worksheets) {
                const { rows, bundles, markers, ranges } = worksheets[ws];
                if (!bundles) {
                    allMarkers.push(markers);
                    allRanges.push(ranges);
                    allRows.push(rows);
                }
            }

            // Собираем сводную таблицу с маркерами и диапазонами
            if (allMarkers.length) {
                setPivotTabel(allMarkers);
            }

            if (allRanges.length) {
                setPivotTabel(allRanges);
            }

            if (allRows.length) {
                setPivotTabel(allRows);
            }

            // Записываем данные в сводную таблицу

            // Готовим таблицы для отдельных страниц файла
            for (const ws in worksheets) {
                // console.log(worksheets[ws]);
                const { name, rows, markers, ranges, key, deviceKey } = worksheets[ws];
                // console.log(ws, num);
                const sheetName = deviceKey === 'readed' ? name + ' (Лог)' : name;
                let sheet;
                if (!workbook.getWorksheet(sheetName)) {
                    sheet = workbook.addWorksheet(sheetName, {
                        pageSetup: { fitToPage: true },
                    });
                }

                if (key.includes('bundle')) {
                    const mRow = splitManyRows(markers);
                    const rRow = splitManyRows(ranges);
                    [...mRow, ...rRow, ...rows].forEach((row) => {
                        sheet.addRow(row);
                    });
                } else {
                    [...markers, ...ranges, ...rows].forEach((row) => {
                        sheet.addRow(row);
                    });
                }

                if (charts[key]) {
                    // console.log(charts[key]);
                    const image = workbook.addImage({
                        base64: charts[key],
                        extension: 'png',
                    });

                    sheet.addImage(image, {
                        tl: {
                            col: rows[2]?.length,
                            row: 2,
                        },
                        ext: {
                            width: 500,
                            height: 200,
                        },
                    });
                }
            }

            dataBuffer = await workbook.xlsx.writeBuffer();
            fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

            if (fileExtension === 'csv' || fileExtension === 'txt') {
                // TODO: доработать запись в csv файл постранично
                fileType = 'text/' + fileExtension + ';charset=utf-8';
                dataBuffer = await workbook.csv.writeBuffer({
                    formatterOptions: {
                        delimiter: ';',
                        writeBOM: true,
                        quote: false,
                    },
                });
            }
        }

        const blob = new Blob([dataBuffer], { type: fileType });

        if (isAndroid) {
            cordova.plugins.saveDialog.saveFile(blob, fName + '.' + fileExtension).catch((err) => {
                console.log('saveFile err', err);
            });
        } else {
            // Сохраняем файл
            saveAs(blob, fName + '.' + fileExtension, { type: fileType });
        }

        function setMarkersAndRanges(markers, num, name, unit, key, data, bundles = false) {
            const mRows = [];
            const rRows = [];
            let mCount = 1,
                rCount = 1;

            // Маркеры и диапазоны
            for (const key in markers) {
                const marker = markers[key];

                if (marker.type === 'line' && !marker.resizer) {
                    const { value, yValue } = marker;
                    if (!mRows.length) {
                        mRows.push(['Маркеры']);
                        mRows.push(['№', 'Время,сек.', `${num}. ${name},${unit}`, null]);
                    }
                    mRows.push([mCount++, Math.round(value) / 1000, yValue, null]);
                }
                if (marker.type === 'box') {
                    const { xMin, xMax } = marker;
                    const range = data.filter((e) => e.x >= xMin && e.x <= xMax);
                    const yMin = range[0].y;
                    const yMax = range.at(-1).y;

                    if (!rRows.length) {
                        rRows.push(['Диапазоны']);
                        rRows.push(['№', 'Время,сек.', `${num}. ${name},${unit}`, null]);
                    }
                    rRows.push([rCount++, Math.round(xMin) / 1000, yMin, null]);
                    rRows.push([rCount++, Math.round(xMax) / 1000, yMax, null]);
                }
            }

            mCount = 1;
            rCount = 1;

            if (bundles) {
                return { marks: mRows, range: rRows };
            } else {
                if (mRows.length) {
                    worksheets[key].markers.push(...mRows);
                }

                if (rRows.length) {
                    worksheets[key].ranges.push(...rRows);
                }
            }
        }

        function setPivotTabel(rows) {
            const arr = splitManyRows(rows);
            let sheet = workbook.getWorksheet('Сводная таблица');
            if (!sheet) {
                sheet = workbook.addWorksheet('Сводная таблица');
            }
            arr.forEach((row) => {
                sheet.addRow(row);
            });
        }

        function splitManyRows(rows) {
            const arr = [];
            rows.forEach((row) => {
                row.forEach((el, i) => {
                    if (!arr[i]) {
                        arr[i] = [];
                    }
                    if (i === 0) {
                        arr[i] = el;
                    } else {
                        arr[i].push(...el);
                    }
                });
            });
            return arr;
        }
    };

    const loadData = async (fileName, data) => {
        let markers = {};
        let imported = [];
        let bundles = [];
        let bundleCount = 1;
        const markersKeys = [];

        if (fileName.includes('.json')) {
            const jsonData = JSON.parse(data);

            jsonData.forEach((item, i) => {
                const itemKyes = Object.keys(item);
                let copy = { ...item };

                if (itemKyes[0]?.includes('bundle')) {
                    for (const bKey in item) {
                        const bundle = { ...item[bKey] };
                        const bInd = +bKey.replace('bundle', '');
                        const mKey = 'bundlei' + bInd;
                        bundle.list.forEach((el) => {
                            const key = 'imp' + el.num;
                            el.num = key;
                            el.imported = true;
                        });
                        bundle.key = mKey;
                        bundle.imported = true;
                        bundles.push(bundle);
                        markers[mKey] = bundle.markers;
                        // console.log(bundle);
                    }
                } else {
                    if (item?.name) {
                        const { num } = item;
                        const impKey = 'imp' + num;
                        copy.imported = true;
                        copy.num = impKey;
                        copy.value = item.value.map(({ x, y }, i) => {
                            return { x, y, key: impKey, num: impKey, count: i + 1 };
                        });
                        imported.push(copy);
                        markers[impKey] = copy.markers;
                    }
                }
            });
        } else {
            const readedData = XLSX.read(data, { type: 'binary' });
            const wsnames = readedData.SheetNames;

            wsnames.forEach((name) => {
                const isTable = name === 'Сводная таблица' || name === 'Sheet1';
                const isBundles = name.includes('Канал');
                if (isTable || isBundles) {
                    const defaultColCount = 4;
                    const ws = readedData.Sheets[name];

                    /* Convert array to json*/
                    const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });

                    let isMark,
                        isRange,
                        isValue,
                        channels = [],
                        tableRanges = [],
                        bundleKey = isBundles ? 'bundlei' + bundleCount : '';

                    isBundles && bundles.push({ name: name, key: bundleKey, imported: true, list: [] });

                    dataParse.forEach((row) => {
                        switch (row[0]) {
                            case 'Маркеры':
                                isMark = true;
                                isRange = false;
                                isValue = false;
                                break;

                            case 'Диапазоны':
                                isMark = false;
                                isRange = true;
                                isValue = false;
                                break;

                            case 'Таблица':
                                isMark = false;
                                isRange = false;
                                isValue = true;
                                break;

                            default:
                                break;
                        }

                        // Метки
                        if (isMark) {
                            if (row[0] === '№') {
                                crateMarkersEmpty(row);
                            }
                            if (typeof row[0] === 'number') {
                                // console.log(keys);
                                updateMarkersItem(row, markersKeys);
                            }
                        }

                        // Диапазоны
                        if (isRange) {
                            if (row[0] === '№') {
                                crateMarkersEmpty(row);
                            }
                            if (typeof row[0] === 'number') {
                                const sliced = slicedRow(row);
                                tableRanges.push(sliced);
                            }
                        }

                        if (isValue) {
                            // Опистание датчиков
                            if (row[0] === '№') {
                                createImportedSensor(row);
                            }

                            // Данные датчика
                            if (typeof row[0] === 'number') {
                                slicedRow(row).forEach((el, i) => {
                                    if (isBundles) {
                                        bundles[bundleCount - 1].list[i]?.value.push({ x: +el[1], y: +el[2], count: +el[0], key: channels[i], num: channels[i] });
                                    } else {
                                        imported[i]?.value?.push({ x: +el[1], y: +el[2], count: +el[0], key: channels[i], num: channels[i] });
                                    }
                                });
                            }
                        }
                    });

                    tableRanges.length && updateRangesItem(tableRanges, markersKeys);

                    function createImportedSensor(row) {
                        slicedRow(row).forEach((el, i) => {
                            const info = el[2].split(',');

                            let name = info[0].split('.')[1].trim(),
                                num = 'imp' + info[0].split('.')[0],
                                key = name.replace(/ /g, '_') + num,
                                color = colors[i].val,
                                value = [],
                                sensNum = num,
                                unit = info[1];

                            channels.push(num);

                            const sensor = {
                                unicName: key,
                                name,
                                key,
                                color,
                                num,
                                sensNum,
                                unit,
                                value,
                                imported: true,
                            };

                            if (isBundles) {
                                bundles[bundleCount - 1].list[i] = sensor;
                            } else {
                                imported[i] = sensor;
                            }

                            // console.log(imported[i]);
                        });
                    }

                    function crateMarkersEmpty(row) {
                        slicedRow(row).forEach((e) => {
                            const pfx = isBundles ? 'bundlei' : 'imp';
                            const num = pfx + (isBundles ? bundleCount : e[2].split('.')[0]);
                            const checkKey = markersKeys.filter((e) => e === num)[0];

                            if (!markers[num]) {
                                markers[num] = {};
                            }

                            if (!checkKey) {
                                markersKeys.push(num);
                            }
                        });
                    }

                    function updateMarkersItem(row, keys) {
                        // console.log(keys);
                        slicedRow(row).forEach((el, i) => {
                            // console.log(keys[i]);
                            let num = bundleKey ? bundleKey : keys[i];
                            const value = +el[1] * 1000;
                            const key = 'line' + Math.round(value / 100);

                            const marker = {
                                type: 'line',
                                id: key,
                                borderWidth: 2,
                                borderColor: theme === 'dark' ? chartStyles.marker.dark.line : chartStyles.marker.line,
                                label: {
                                    enabled: true,
                                    position: 'end',
                                    content: el[1],
                                    backgroundColor: theme === 'dark' ? chartStyles.marker.dark.background : chartStyles.marker.background,
                                    borderColor: theme === 'dark' ? chartStyles.marker.dark.border : chartStyles.marker.border,
                                    color: theme === 'dark' ? chartStyles.marker.dark.color : chartStyles.marker.color,
                                    borderWidth: 2,
                                    yAdjust: -8,
                                    borderRadius: 3,
                                    padding: {
                                        x: 9,
                                        y: 6,
                                    },
                                    font: {
                                        size: 10,
                                        weight: 400,
                                        lineHeight: 1.2,
                                    },
                                },
                                scaleID: 'xAxis',
                                yScaleID: null,
                                draggable: true,
                                value,
                            };

                            if (!markers[num]) {
                                markers[num] = {};
                            }

                            markers[num][key] = marker;
                        });
                    }

                    function updateRangesItem(array, keys) {
                        let index = 1;
                        let range = {};

                        array.forEach((row, i) => {
                            let box = 'box' + index;
                            let start = 'box' + index + 'start';
                            let end = 'box' + index + 'end';

                            if (i % 2 === 0) {
                                row.forEach((e, k) => {
                                    const num = bundleKey ? bundleKey : keys[k];
                                    if (!range[num]) {
                                        range[num] = {};
                                    }

                                    range[num][box] = {
                                        type: 'box',
                                        id: box,
                                        backgroundColor: chartStyles.boxes.chart[box],
                                        borderWidth: 0,
                                        xMin: +e[1] * 1000,
                                    };

                                    range[num][start] = {
                                        type: 'line',
                                        id: start,
                                        resizer: true,
                                        borderWidth: 5,
                                        borderColor: 'transparent',
                                        scaleID: 'xAxis',
                                        yScaleID: null,
                                        draggable: true,
                                        value: +e[1] * 1000,
                                    };
                                });
                            } else {
                                row.forEach((e, k) => {
                                    const num = bundleKey ? bundleKey : keys[k];
                                    if (!range[num]) {
                                        range[num] = {};
                                    }

                                    range[num][end] = {
                                        type: 'line',
                                        id: end,
                                        resizer: true,
                                        borderWidth: 5,
                                        borderColor: 'transparent',
                                        scaleID: 'xAxis',
                                        yScaleID: null,
                                        draggable: true,
                                        value: +e[1] * 1000,
                                    };

                                    range[num][box].xMax = +e[1] * 1000;
                                });
                                index++;
                            }
                        });

                        for (const num in range) {
                            markers[num] = { ...markers[num], ...range[num] };
                        }
                    }

                    function slicedRow(row) {
                        const sliced = [];
                        for (let i = 0; i < row.length; i += defaultColCount) {
                            sliced.push(row.slice(i, i + defaultColCount));
                        }
                        return sliced;
                    }

                    isBundles && bundleCount++;
                }
            });
        }

        if (Object.keys(markers).length) {
            // console.log(markers);
            for (const key in markers) {
                setChartMarkers({ num: key, data: markers[key] });
            }
        }

        if (bundles.length) {
            // console.log(bundles);
            bundles.forEach((e) => {
                addSensorsBundles({ key: e.key, data: e });
            });
        }

        if (imported.length) {
            // console.log(imported);
            // sendAllMarkers();
            setSensorImported(imported);
        }
    };

    const importFromFile = async (file) => {
        const reader = new FileReader();
        setSensorImported([]);

        // Переключение из мульти-моноосей для сохранения позиций маркеров и диапазонов
        if (chartView === 'multy' || chartView === 'mono') {
            setChartView('two');
        }

        if (file.name.includes('.json')) {
            reader.readAsText(file);
        } else {
            reader.readAsArrayBuffer(file);
        }

        reader.onload = async (e) => {
            //const data = e.target.result;
            loadData(file.name, e.target.result);
        };

        xlsRef.current.value = null;
        csvRef.current.value = null;
        txtRef.current.value = null;
        jsonRef.current.value = null;
        setFileExtension();
        setFile('');
    };

    return (
        <div className={ss.root}>
            {writeStatus ? (
                <button
                    className={`${ss.toggle} ${ss.item} ${ss.stop}`}
                    onClick={() => {
                        sendCommand('wlg 0');
                        setWriteStatus(false);
                    }}
                >
                    <Icon name="stop" />
                    <span>
                        Стоп
                        <em>{Moment(recordTime).utcOffset(0).format('H:mm:ss')}</em>
                    </span>
                </button>
            ) : (
                <div
                    className={isOscilloscope ? ss.dropDisable : ss.drop}
                    data-learn="38"
                    data-learn-border="38"
                    data-learn-position="left"
                >
                    <div className={`${ss.toggle} ${ss.item}`}>
                        <Icon name="import" />
                        <span>Импорт</span>
                    </div>
                    <div className={ss.list}>
                        {!isOscilloscope && (
                            <button
                                className={ss.item}
                                disabled={played || !port}
                                onClick={() => {
                                    sendCommand('wlg 1');
                                    setWriteStatus(true);
                                }}
                            >
                                <Icon name="record" />
                                <span>Запись</span>
                            </button>
                        )}
                        <label
                            className={ss.item}
                            disabled={played}
                        >
                            <Icon name="xls" />
                            <span>XLS</span>
                            <input
                                ref={xlsRef}
                                accept=".xls,.xlsx"
                                type="file"
                                onChange={(e) => setFile(e.target.files[0])}
                            />
                        </label>
                        <label className={ss.item}>
                            <Icon name="csv" />
                            <span>CSV</span>
                            <input
                                ref={csvRef}
                                disabled={played}
                                accept=".csv"
                                type="file"
                                onChange={(e) => setFile(e.target.files[0])}
                            />
                        </label>
                        <label className={ss.item}>
                            <Icon name="txt" />
                            <span>TXT</span>
                            <input
                                ref={txtRef}
                                disabled={played}
                                accept=".txt"
                                type="file"
                                onChange={(e) => setFile(e.target.files[0])}
                            />
                        </label>
                        <label className={ss.item}>
                            <Icon name="json" />
                            <span>JSON</span>
                            <input
                                ref={jsonRef}
                                disabled={played}
                                accept=".json"
                                type="file"
                                onChange={(e) => setFile(e.target.files[0])}
                            />
                        </label>
                    </div>
                </div>
            )}
            <div
                className={isOscilloscope ? ss.dropDisable : ss.drop}
                data-learn="37"
                data-learn-border="37"
                data-learn-position="left"
            >
                <div className={`${ss.toggle} ${ss.item}`}>
                    <Icon name="export" />
                    <span>Экспорт</span>
                </div>
                <div className={ss.list}>
                    {!isOscilloscope && (
                        <button
                            className={ss.item}
                            disabled={played || !port}
                            onClick={() => {
                                sendCommand('rlg ' + (isReading ? 0 : 1));
                            }}
                        >
                            <Icon name="log" />
                            <span>Лог</span>
                        </button>
                    )}
                    <button
                        className={ss.item}
                        disabled={played || !port}
                        onClick={() => setFileExtension('xlsx')}
                    >
                        <Icon name="xls" />
                        <span>XLS</span>
                    </button>
                    <button
                        className={ss.item}
                        disabled={played || !port}
                        onClick={() => setFileExtension('csv')}
                    >
                        <Icon name="csv" />
                        <span>CSV</span>
                    </button>
                    <button
                        className={ss.item}
                        disabled={played || !port}
                        onClick={() => setFileExtension('txt')}
                    >
                        <Icon name="txt" />
                        <span>TXT</span>
                    </button>
                    <button
                        className={ss.item}
                        disabled={played || !port}
                        onClick={() => setFileExtension('json')}
                    >
                        <Icon name="json" />
                        <span>JSON</span>
                    </button>
                </div>
            </div>
            <span
                data-learn="39"
                data-learn-border="39"
                data-learn-position="top"
            >
                <Icon
                    tag="button"
                    name="printer"
                    title="Печать"
                    disabled={disabledPrint}
                    className={ss.print}
                    onClick={() => setPrintModal(true)}
                />
            </span>
        </div>
    );
};
export default ImportExport;
