// import {  } from '../../actions/';

export const mapStateToProps = (nstate) => {
    return {
        sensorList: nstate.nsensor.list,
        importList: nstate.nsensor.imported,
        sensorChannels: nstate.nsensor.channels,
        visualized: nstate.nsensor.visualized,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    // setPlay: (bool) => dispatch(setPlay(bool)),
});
