import React, { useEffect, useState } from 'react';
import ss from '../../MarkersRezults/MarkersRezults.module.scss';

const ARTPRESSURE_OSC_ARR_SIZE = 50;
const ARTPRESSURE_OSC_AVG_COUNT = 5;
const ARTPRESSURE_DETECT_START = 150;
const ARTPRESSURE_DETECT_START_DELAY = 1500;
const ARTPRESSURE_DETECT_START_DERIV = -5;
const ARTPRESSURE_DETECT_END = 50;
const ARTPRESSURE_OSC_MAG_DETECT = 0.5;

const ARTPRESSURE_REL_OSC_MAG_SYS = 0.5;
const ARTPRESSURE_REL_OSC_MAG_DIA = 0.8;

const oscillation_detection_mode = {
    rising_start: 0,
    rising_peak: 1,
    rising_end: 2,
};

let sys_pressure = 0;
let dia_pressure = 0;
let cur_pulse = 0;
let cur_time = 0;
let prev_time = 0;
// let pressure = 0;
// let prev_pressure = 0;
let max_point = 0;
let max_point_time = 0;
let derivative_val = 0;
let max_point_search_flag = true;
let derivative_tracking_flag = false;
let detect_oscillation_flag = false;
let oscillation_arr = [];
let oscillation_arr_size = 0;
let detectionMode = 0;
let p_oscillation = { peak_time: 0, begin_pressure: 0, peak_pressure: 0 };

const ArtPressure = ({ data, timer }) => {
    const [pressure, setPressure] = useState(data?.length ? data[1][0] : 0);
    const [prevPressure, setPrevPressure] = useState(0);
    const [sistolic, setSistolic] = useState(0);
    const [diastolic, setDiastolic] = useState(0);
    const [pulse, setPulse] = useState('-');
    // const [detectionMode, setDetectionMode] = useState(0);

    useEffect(() => {
        if (data?.length > 1) {
            measure(timer, data[1].at(-1));
        } else {
            setPressure(0);
            setSistolic(0);
            setDiastolic(0);
            setPulse('-');

            reset();
        } // eslint-disable-next-line
    }, [timer, data]);

    const reset = async () => {
        max_point = 0;
        max_point_time = 0;
        max_point_search_flag = true;
        derivative_val = 0;
        derivative_tracking_flag = false;
        detect_oscillation_flag = false;
        p_oscillation = { peak_time: 0, begin_pressure: 0, peak_pressure: 0 };
        oscillation_arr_size = 0;
        // setPressure(0);
        // setSistolic(0);
        // setDiastolic(0);
        // setPulse(0);
        for (let i = 0; i < ARTPRESSURE_OSC_ARR_SIZE; i++) {
            oscillation_arr[i] = { peak_time: 0, begin_pressure: 0, peak_pressure: 0 };
        }
        // console.log(oscillation_arr);
    };

    // useEffect(() => {
    //     console.log(pressure, prevPressure);
    // }, [pressure, prevPressure]);

    // useEffect(() => {
    //     console.log('detectionMode', detectionMode);
    // }, [detectionMode]);

    const measure = async (new_meas_time, new_meas_value) => {
        prev_time = cur_time;
        cur_time = new_meas_time;
        setPrevPressure(pressure);
        setPressure(new_meas_value);

        if (max_point_search_flag) {
            // console.log('measure 1');
            await max_point_search();
        }

        if (derivative_tracking_flag) {
            // console.log('measure 2');
            await derivative_tracking();
        }

        if (derivative_val <= ARTPRESSURE_DETECT_START_DERIV && !detect_oscillation_flag) {
            // console.log('measure 3');
            reset();
            detect_oscillation_flag = true;
            sys_pressure = 1;
            dia_pressure = 1;
            cur_pulse = 1;
            setSistolic(1);
            setDiastolic(1);
            setPulse(1);
        }

        await detect_oscillation();

        if (pressure <= ARTPRESSURE_DETECT_END && detect_oscillation_flag) {
            // console.log('measure 4');
            detect_oscillation_flag = false;
            derivative_val = 0;
            derivative_tracking_flag = false;
            // show_oscillations();
            await oscillation_analysis(oscillation_arr, oscillation_arr_size, sys_pressure, dia_pressure, cur_pulse);
            await reset();
        }
    };

    const detect_oscillation = async () => {
        if (!detect_oscillation_flag || oscillation_arr_size >= ARTPRESSURE_OSC_ARR_SIZE) return;
        switch (detectionMode) {
            case oscillation_detection_mode.rising_start:
                if (p_oscillation.begin_pressure === 0) {
                    p_oscillation.begin_pressure = pressure;
                    return;
                }
                if (pressure <= p_oscillation.begin_pressure) {
                    p_oscillation.begin_pressure = pressure;
                } else {
                    detectionMode = oscillation_detection_mode.rising_peak;
                    p_oscillation.peak_pressure = pressure;
                    p_oscillation.peak_time = cur_time;
                }
                break;
            case oscillation_detection_mode.rising_peak:
                if (pressure > p_oscillation.peak_pressure) {
                    p_oscillation.peak_pressure = pressure;
                } else {
                    if (p_oscillation.peak_pressure - p_oscillation.begin_pressure >= ARTPRESSURE_OSC_MAG_DETECT) {
                        detectionMode = oscillation_detection_mode.rising_end;
                    } else {
                        detectionMode = oscillation_detection_mode.rising_start;
                        p_oscillation.begin_pressure = pressure;
                        p_oscillation.peak_pressure = 0;
                        p_oscillation.peak_time = 0;
                    }
                }
                break;
            case oscillation_detection_mode.rising_end:
                if (oscillation_arr_size < ARTPRESSURE_OSC_ARR_SIZE) {
                    detectionMode = oscillation_detection_mode.rising_start;
                    console.log('p_oscillation', p_oscillation);
                    oscillation_arr[oscillation_arr_size].peak_time = p_oscillation.peak_time;
                    oscillation_arr[oscillation_arr_size].begin_pressure = p_oscillation.begin_pressure;
                    oscillation_arr[oscillation_arr_size].peak_pressure = p_oscillation.peak_pressure;
                    // console.log(oscillation_arr_size, p_oscillation);
                    oscillation_arr_size++;
                    // p_oscillation++;
                    p_oscillation.begin_pressure = 0;
                    p_oscillation.peak_pressure = 0;
                    p_oscillation.peak_time = 0;
                }
                break;
            default:
                break;
        }
        if (oscillation_arr_size >= ARTPRESSURE_OSC_ARR_SIZE) {
            oscillation_arr_size = 0;
        }
        // console.log(detectionMode, oscillation_arr);
    };

    const derivative_tracking = async () => {
        if (prevPressure > 0) {
            //при вычислении производной время переводится из миллисекунд в секунды
            derivative_val = ((pressure - prevPressure) / (cur_time - prev_time)) * 1e3;
        }
    };

    const max_point_search = async () => {
        // console.log(max_point, pressure, ARTPRESSURE_DETECT_START);
        if (max_point < pressure && pressure > ARTPRESSURE_DETECT_START) {
            max_point = pressure;
            max_point_time = cur_time;
            // console.log('max_point_search 1', max_point, max_point_time);
        }
        // console.log(cur_time, max_point_time, ARTPRESSURE_DETECT_START_DELAY);
        if (cur_time - max_point_time >= ARTPRESSURE_DETECT_START_DELAY && max_point_time !== 0) {
            max_point_search_flag = false;
            derivative_tracking_flag = true;
            // console.log('max_point_search 2', max_point, max_point_time);
        }
    };

    // const map = (x, in_min, in_max, out_min, out_max) => {
    //     return ((x - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
    // };

    const running_average_osc_magnitude = (oscillation_arr, oscillation_arr_size, index, avg_count) => {
        let start_index = Math.max(index - avg_count / 2, 0);
        let end_index = Math.min(index - avg_count / 2 + avg_count, oscillation_arr_size);
        // console.log(end_index, start_index, oscillation_arr);
        avg_count = end_index - start_index;
        let avg = 0;
        for (let i = Math.ceil(start_index); i < end_index; i++) {
            avg += oscillation_arr[i].peak_pressure - oscillation_arr[i].begin_pressure;
        }
        avg /= avg_count;
        // console.log('avg', avg);
        return avg;
    };

    const oscillation_analysis = async (oscillation_arr, oscillation_arr_size, sys_pressure, dia_pressure, cur_pulse) => {
        // console.log(oscillation_arr);
        // sys_pressure = 0;
        // dia_pressure = 0;
        // cur_pulse = 0;
        // setSistolic(0);
        // setDiastolic(0);
        // setPulse('-');
        console.log('oscillation_arr ===', oscillation_arr);
        if (oscillation_arr_size < 2 || oscillation_arr_size > ARTPRESSURE_OSC_ARR_SIZE) return;
        let max_osc_magnitude = 0,
            osc_magnitude_cur = 0,
            osc_magnitude_prev = 0,
            osc_pressure_cur = 0,
            osc_pressure_prev = 0,
            max_osc_magnitude_index = 0;

        // поиск максимальной осцилляции
        for (let i = 0; i < oscillation_arr_size; i++) {
            osc_magnitude_cur = running_average_osc_magnitude(oscillation_arr, oscillation_arr_size, i, ARTPRESSURE_OSC_AVG_COUNT);
            // console.log(osc_magnitude_cur);
            if (osc_magnitude_cur > max_osc_magnitude) {
                max_osc_magnitude = osc_magnitude_cur;
                max_osc_magnitude_index = i;
            }
        }
        let osc_magnitude_sys = max_osc_magnitude * ARTPRESSURE_REL_OSC_MAG_SYS;
        let osc_magnitude_dia = max_osc_magnitude * ARTPRESSURE_REL_OSC_MAG_DIA;
        //определение систолического давления
        osc_pressure_cur = 0;
        osc_magnitude_cur = 0;
        for (let i = 0; i <= max_osc_magnitude_index; i++) {
            osc_pressure_prev = osc_pressure_cur;
            osc_pressure_cur = oscillation_arr[i].begin_pressure;
            osc_magnitude_prev = osc_magnitude_cur;
            osc_magnitude_cur = running_average_osc_magnitude(oscillation_arr, oscillation_arr_size, i, ARTPRESSURE_OSC_AVG_COUNT);
            // console.log(osc_magnitude_cur);
            if (osc_magnitude_cur >= osc_magnitude_sys) {
                if (i === 0) {
                    sys_pressure = osc_pressure_cur;
                    break;
                } else if (osc_magnitude_prev < osc_magnitude_sys) {
                    sys_pressure = ((osc_magnitude_sys - osc_magnitude_prev) / (osc_magnitude_cur - osc_magnitude_prev)) * (osc_pressure_cur - osc_pressure_prev) + osc_pressure_prev;
                    break;
                }
            }
        }
        console.log('');
        console.log('систолического давления', osc_pressure_cur, osc_magnitude_cur);
        setSistolic(osc_pressure_cur);
        //определение диастoлического давления
        osc_pressure_cur = 0;
        osc_magnitude_cur = 0;
        for (let i = oscillation_arr_size - 1; i >= max_osc_magnitude_index; i--) {
            osc_pressure_prev = osc_pressure_cur;
            osc_pressure_cur = oscillation_arr[i].begin_pressure;
            osc_magnitude_prev = osc_magnitude_cur;
            osc_magnitude_cur = running_average_osc_magnitude(oscillation_arr, oscillation_arr_size, i, ARTPRESSURE_OSC_AVG_COUNT);
            if (osc_magnitude_cur >= osc_magnitude_dia) {
                if (i === oscillation_arr_size - 1) {
                    dia_pressure = osc_pressure_cur;
                    break;
                } else if (osc_magnitude_prev < osc_magnitude_dia) {
                    dia_pressure = ((osc_magnitude_dia - osc_magnitude_cur) / (osc_magnitude_prev - osc_magnitude_cur)) * (osc_pressure_prev - osc_pressure_cur) + osc_pressure_cur;
                    break;
                }
            }
        }
        console.log('диастoлического давления', osc_pressure_cur, osc_magnitude_cur);
        setDiastolic(osc_pressure_cur);
        //определение пульса
        let index;
        //массив количеств осцилляций по интервалам времени
        //начальный элемент 0.2-0.3 сек, конечный 1.9-2.0 сек, шаг 0.1 сек
        const COUNTS_SIZE = 18;
        const TIME_START = 0.2;
        const TIME_STEP = 0.1;
        let osc_pulse_counts_vs_time = new Array(COUNTS_SIZE).fill(0);
        let pulse_time = 0;
        //подсчёт колличеств точек по интервалам времени
        console.log('oscillation_arr', oscillation_arr);
        console.log('oscillation_arr_size', oscillation_arr_size);
        for (let i = 1; i < oscillation_arr_size; i++) {
            pulse_time = (oscillation_arr[i].peak_time - oscillation_arr[i - 1].peak_time) * 1e-3;
            index = Math.min(Math.max(0, Math.floor((pulse_time - TIME_START) / TIME_STEP)), COUNTS_SIZE - 1);
            osc_pulse_counts_vs_time[index]++;
        }
        console.log('osc_pulse_counts_vs_time', osc_pulse_counts_vs_time);
        //пооск интервала времени с первым по количеству осцилляций
        let index_max_pulse_count = 0;
        let max_pulse_count = osc_pulse_counts_vs_time[0];
        for (let i = 1; i < COUNTS_SIZE; i++) {
            if (osc_pulse_counts_vs_time[i] >= max_pulse_count) {
                max_pulse_count = osc_pulse_counts_vs_time[i];
                index_max_pulse_count = i;
            }
        }
        let pulse_count = 0;
        let pulse_reg = 0;
        for (let i = 1; i < oscillation_arr_size; i++) {
            pulse_time = (oscillation_arr[i].peak_time - oscillation_arr[i - 1].peak_time) * 1e-3;
            index = Math.min(Math.max(0, Math.floor((pulse_time - TIME_START) / TIME_STEP)), COUNTS_SIZE - 1);
            if (Math.abs(index - index_max_pulse_count) < 2) {
                pulse_reg += pulse_time;
                pulse_count++;
            }
        }
        console.log(pulse_count, pulse_reg);
        if (pulse_count > 0) {
            pulse_reg = (60 * pulse_count) / pulse_reg;
        }
        cur_pulse = pulse_reg / 20;
        console.log('пульс', cur_pulse);
        setPulse(cur_pulse > 40 && cur_pulse < 220 ? Math.round(cur_pulse) : '-');
    };

    return (
        <div className={ss.nohead}>
            <div className={ss.item}>
                <div className={ss.body}>
                    <div className={ss.row}>
                        <div className={ss.label}>Давление</div>
                        <div className={ss.value}>{pressure}</div>
                    </div>
                    <div className={ss.row}>
                        <div className={ss.label}>Систолическое давление</div>
                        <div className={ss.value}>{sistolic}</div>
                    </div>
                    <div className={ss.row}>
                        <div className={ss.label}>Диастолическое давление</div>
                        <div className={ss.value}>{diastolic}</div>
                    </div>
                    <div className={ss.row}>
                        <div className={ss.label}>Пульс</div>
                        <div className={ss.value}>{pulse}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ArtPressure;
