import React, { useState, useEffect, useRef } from "react";
import { Icon, Button } from '../ui';
import {toast} from "react-toastify";
import {FEATURES} from "../../api/config";
import ModalFinishStudentTestDlg from "../ui/ModalDialogs/ModalFinishStudentTestDlg";
import {showCurrTime, showRemainingTime, getRemainingTimeInMSec} from "../ui/utils/gen_utils";
import {drawFormula, getTaskAnswer, getRandomVariableNames, 
	getActualSelectedIndex, getLastAnswerIndex, getSelectedIindex} from "./task_utils";
import {NB_SAMPLE_TYPE} from './nb_utils';
import ScoreCalcTableDlg from "./ScoreCalcTableDlg";
import {PRACTICUM_SECTION}  from "../template/Dashboard/DashboardProfile";
import {notebookInfoApi} from "../../api/api";
import {calcScoreValue, getScoreName, getMinGoodScore} from '../ui/utils/score_utils';
import {getTemplatesBySubjectId} from "./templates";
import { Content, ContentBody, ContentFooter, ContentHeader } from "../template/ContentParts";
import "../practicum/LabWorkShow.scss";
import "../OnlineTest/OnlineTestCreate.scss";
import "./Notebooks.scss";
import nb from "./Notebooks.module.scss";

const NotebookStudentHomeWorkExecutePages = ({history, notebookInfoId, notebookInfoData, notebookData, 
		currPage, setCurrPage, taskPages, setTaskPages, timeLimitEnabled, timeLimitInMinutes, actualSpentTime, 
		workErrorLimit, setWorkErrorLimit}) => {
	const [templates, setTemplates] = useState([]);
    const [showCompleteTestDlg, setShowCompleteTestDlg] = useState(false);
    const [showHowToCalcDlg, setShowHowToCalcDlg] = useState(false);
	const [varNames, setVarNames] = useState(['x', 'y']);
	
    const [pageUserSelectedIndex, setPageUserSelectedIndex] = useState(null); //пользователь клукнул опцию, но еще не сохранил ответ
    const [pageSelectedIndex, setPageSelectedIndex] = useState(null); //пользователь сохранил выбранный ответ
    const [beChecked, setBeChecked] = useState(false);

    const [startTime, setStartTime] = useState(undefined);
    const [currTestTime, setCurrTestTime] = useState();
    const [isTestTimeFinished, setIsTestTimeFinished] = useState(false);
    const [secondCounter, setSecondCounter] = useState(0);
    const [canGoNextPage, setCanGoNextPage] = useState(false);

	const timerIdRef = useRef();

	useEffect(() => {
		const workProgress = () => { 
			setCurrTestTime(Date.now());
			setSecondCounter(secondCounter => secondCounter + 1);
		};
		timerIdRef.current = setInterval(workProgress, 1 * 1000); //refresh each sec
		setStartTime(new Date());
		return () => {clearInterval(timerIdRef.current);};
	},[]);

	useEffect(() => {
		if (!notebookData) return;
		const _subjectId = notebookData.titles.subjectId;
		setTemplates(getTemplatesBySubjectId(_subjectId).templates);
	},[notebookData]);

	useEffect(() => {
		if (!timeLimitEnabled) return;
		const remTime = getRemainingTimeInMSec(currTestTime, startTime, 60 * timeLimitInMinutes - actualSpentTime);
		const finished = remTime <= 0;
		if (finished)
			setIsTestTimeFinished(true);
    }, [currTestTime, startTime, timeLimitEnabled, timeLimitInMinutes, actualSpentTime]);

	const SAVE_ACTUAL_TIME_FREQUENCY = 5;

	const saveActualSpentTime = async (_notebookInfoId, newActualSpentTime) => { //id is NotebookInfo id, update actualSpentTime
		if (!_notebookInfoId) return; // for teacher preview
		await notebookInfoApi.updateNotebookInfoSpentTimeById(_notebookInfoId, 
			{actualSpentTime: newActualSpentTime});
	};

	useEffect(() => {
		if (!notebookData) return;
		if (notebookInfoId && Math.floor(secondCounter / SAVE_ACTUAL_TIME_FREQUENCY) * SAVE_ACTUAL_TIME_FREQUENCY === secondCounter) {
			const newActualSpentTime = actualSpentTime + secondCounter;
			saveActualSpentTime(notebookInfoId, newActualSpentTime);
		}

		if (notebookData.run) {
			const finishedTime = (new Date(notebookData.run.completeFullDate)) - (new Date());
			const finished = finishedTime <= 0;
			if (finished)
				setIsTestTimeFinished(true);
		}
	}, [secondCounter, actualSpentTime, notebookInfoId, notebookData]);

	useEffect(() => {
		setVarNames(getRandomVariableNames(2));
	}, [currPage]);

	useEffect(() => {
		if (!isTestTimeFinished || !notebookInfoId) return;
		saveActualSpentTime(notebookInfoId, timeLimitInMinutes * 60);
		toast.warn('Время выполнения задания закончилось.');
		clearInterval(timerIdRef.current);
		setCurrPage(taskPages.length);
	}, [setCurrPage, taskPages, isTestTimeFinished, timeLimitInMinutes, notebookInfoId]);

	useEffect(() => {
		if (pageSelectedIndex === null || !beChecked) return;

		const task = {...taskPages[currPage].task};
		const reorderedOptions = [...taskPages[currPage].reorderedOptions];
		let isChanged = false;
		let _canGoNextPage = false;
		let saveResults = true;

		if (pageSelectedIndex !== null) {
			for (let i = 0; i < reorderedOptions.length; i ++)
				reorderedOptions[i].isSelected = false;
			reorderedOptions[pageSelectedIndex].isSelected = true;
	
			const actualSelectedIndex = getActualSelectedIndex(reorderedOptions);
			const lastAnswerInd = getLastAnswerIndex(task.userAnswerHistory);
			let _workErrorLimit = workErrorLimit;

			if (actualSelectedIndex !== lastAnswerInd && actualSelectedIndex !== null) {
				if (task.userTaskErrorLimit === -1) {
					_canGoNextPage = true;
				} else if (task.userTaskErrorLimit > 0) {
					if (actualSelectedIndex === 0) {
						toast.success('Ответ верный');
						_canGoNextPage = true;
					} else {
						toast.error('Ответ неверный');
						task.userTaskErrorLimit --; 
						_workErrorLimit --; 
						if (_workErrorLimit === 0) {
							task.userTaskErrorLimit = 0; //закончили работу
						} else if (task.userTaskErrorLimit === 0) {
							 //закончили попытки для данной задачи
							if (_workErrorLimit > 0) //если остались еще попытки, переходим к следующей задаче
								_canGoNextPage = true;
						}
						setWorkErrorLimit(_workErrorLimit);
					}
				} else if (task.userTaskErrorLimit === 0){ //все попытки для данной задачи закончились - переходим дальше
					saveResults = false; //что бы пользователь не ввел, мы эти данные не будем учитывать
					if (_workErrorLimit > 0)
						_canGoNextPage = true;
				}

				if (saveResults) { //сохранять  результат только если пользователь не исчерпал попытки для данной задачи
					task.userAnswerHistory = (task.userAnswerHistory === '' ? '' : task.userAnswerHistory + '|') + actualSelectedIndex;
					task.isCorrect =  (actualSelectedIndex === 0) ? 1 : 0;
					isChanged = true;
				}
			} else if (actualSelectedIndex === lastAnswerInd && actualSelectedIndex !== null) {
				_canGoNextPage = true;
			}
		}

		if (isChanged) {
			const _taskPages = taskPages.map((item, ind) => ind !== currPage ? item : {
				task: task,
				reorderedOptions: reorderedOptions
			});
			setTaskPages(_taskPages); //it will update db
		}

		setBeChecked(false);
		if (_canGoNextPage)
			setCanGoNextPage(true);
	},[currPage, pageSelectedIndex, setTaskPages, taskPages, beChecked, workErrorLimit, setWorkErrorLimit]);

	const getTemplateWithVariableNames = (templates, id) => {
		const _selectedTemplate = templates.find(item => item.templateId === id);
		if (!_selectedTemplate) return undefined;
		return {..._selectedTemplate, varNames: varNames};
	};
	
	const solveEquationTask = pageNum => {
		if (!notebookData || !templates ||  pageNum >= taskPages.length) return <></>;

		const taskSample = notebookData.taskSamples.find(item => item._id === taskPages[pageNum].task.sampleId);
		const selectedTemplate = getTemplateWithVariableNames(templates, taskSample.templateId);
		if (!selectedTemplate) return <></>;

		const formulaInd = taskPages[pageNum].task.formulaInd;
		const formula = selectedTemplate.formulas[formulaInd];
		if (!formula) {
			debugger;
		}

		const task = taskPages[pageNum].task;
		const userTaskErrorLimit = task.userTaskErrorLimit;
		const teacherDescription = taskSample.teacherDescription;

		const taskText1 = selectedTemplate.sampleTypeId === NB_SAMPLE_TYPE.EQUATION ? 'Решите уравнение': 
						  selectedTemplate.sampleTypeId === NB_SAMPLE_TYPE.GROUPING ? 'Сгруппируйте' : 'Решите задачу';
		const taskText2 = selectedTemplate.sampleTypeId === NB_SAMPLE_TYPE.EQUATION ? 'Уравнение': 'Задача';

		return (
			<div className="cor-work__main">

				<div className="cor-work__label">
					<span>{taskText1} и выберите правильный ответ.</span>
				</div>
				<div className={nb.nb__commentBox}>
					{taskText2}: <b>{drawFormula(selectedTemplate, task.formula)}</b>
				</div>

				<div className="cor-work__tasks">
					{getRbBox(pageNum, selectedTemplate, userTaskErrorLimit, workErrorLimit)}
				</div>

				{teacherDescription &&
				<>
					<div className={nb.nb__commentBox}>Комментарий преподавателя</div>
					<div>
						<textarea className={nb.nb__commentText} autoComplete="off" value={teacherDescription} 
							name="description" rows="4" disabled />
					</div>
				</>
				}

				<div className="otCreate__nextBtnQuestChooseOne">
					{getNextQuestion(pageNum)}
				</div>
				
				{workErrorLimit > 0 && userTaskErrorLimit !== -1 && (
					<div className="cor-work__tasks">Оставшиеся попытки для данного примера: {userTaskErrorLimit}</div>
				)}
			</div>			
		);
	};

	const getRbBox = (pageNum, selectedTemplate, userTaskErrorLimit, workErrorLimit) => {
		const formulaInd = taskPages[pageNum].task.formulaInd;
		const reorderedOptions = taskPages[pageNum].reorderedOptions;
		const selectedInd = pageUserSelectedIndex !== null ? pageUserSelectedIndex : getSelectedIindex(reorderedOptions);
		const answerOptionsList = reorderedOptions.map(item => getTaskAnswer(selectedTemplate, formulaInd, item.option));
	
		return (
			answerOptionsList.map(
				(item, ind) => 
				<div key={"rbkey"+ind} className="cor-work__task">
					<input type="checkbox" 
						id={ind} 
						value={ind} 
						name={item}
						checked={ind === selectedInd} 
						disabled={userTaskErrorLimit === 0 || workErrorLimit === 0}
						onClick={(e) => handlePageSelectedIndex(Number(e.target.value))} 
						onChange={() => {}}
					>
					</input>
					<label htmlFor={ind}>{item}</label>
				</div>
			)
		);
	};

	const handlePageSelectedIndex = (index) => {
		setPageUserSelectedIndex(index);
		setPageSelectedIndex(null);		
		setBeChecked(true);
	};

	const handleStudentIsReadyToComplete = () => {
		setShowCompleteTestDlg(true);
	};

	const handleConfirmCompleteTestNo = () => { 
 		setShowCompleteTestDlg(false);
	};	

	const handleConfirmCompleteTestYes = () => { 
		setShowCompleteTestDlg(false);
		clearInterval(timerIdRef.current);
		history.push("/");
	};	

	const isStudentAnswered = pageNum => {
		if (pageNum === taskPages.length) return false;
		const userAnswerInd = getLastAnswerIndex(taskPages[pageNum].task.userAnswerHistory);
		////console.log('isStudentAnswered = ', userAnswerInd !== -1)
		return userAnswerInd !== -1;
	}
	
	const isResultPage = pageNum => pageNum === taskPages.length;

	const handlePrevQuestion = pageNum => {
		if (pageNum > 0 && pageNum < taskPages.length) {
			setPageSelectedIndex(null);
			setPageUserSelectedIndex(null);
			setCurrPage(pageNum - 1);
		}
	};

	const handleNextQuestion = (pageNum, beCompleted = false) => {
		setPageSelectedIndex(null);
		setPageUserSelectedIndex(null);
		setCurrPage(!beCompleted ? pageNum + 1 : taskPages.length);
	};

	useEffect(() => {
		if (!canGoNextPage) return;
		setPageSelectedIndex(null);
		setPageUserSelectedIndex(null);
		
		let nextPage;
		if (workErrorLimit === 0 || (workErrorLimit === -1 && currPage === taskPages.length - 1))
			nextPage = taskPages.length;
		else if (workErrorLimit > 0 && currPage === taskPages.length - 1)
			nextPage = taskPages.length - 1;
		else
			nextPage = currPage + 1;
		setCurrPage(nextPage);

		setCanGoNextPage(false);
	},[canGoNextPage, workErrorLimit, currPage, taskPages, setCurrPage]);

	const handleЫSelectAnswerOption = () => {
		setPageSelectedIndex(pageUserSelectedIndex);
	};

	const getNextQuestion = (pageNum) => {
		if (taskPages.length === 0 || isResultPage(pageNum))  return (<></>);

		return (
			<div className="otCreate__stretch1">
				{workErrorLimit === 0 ?
				<div className="nb__warning nb__warningSpace">
					Вы сделали максимальное количество допустимых ошибок. Результаты выполнения задания сохранены.
				</div>
				
				:

				<button onClick={() => handleЫSelectAnswerOption()} 
					className="otCreate__button otCreate__buttonNext otCreate__buttonSel"
					disabled={pageUserSelectedIndex === null}
				>
					{'Ответить'}
				</button>
				}

				{isStudentAnswered(pageNum) && pageNum === taskPages.length - 1 && 
				<button onClick={() => handleNextQuestion(pageNum, workErrorLimit === 0)} 
					className="otCreate__button otCreate__buttonNext otCreate__buttonSel">
					Смотреть результаты
				</button>
				}
			</div>
		);		
	};

    const getQuestionPage = pageNum => {
		return (
			<>
				{notebookData?.titles?.homeWorkItem &&
					<div className="cor-work__subject">Тема: <b>{notebookData?.titles?.homeWorkItem}</b></div>
				}

				{solveEquationTask(pageNum)}
			</>
		);
    }

	const getResultPage = () => {
		const getAutoScoreOpts = (isGood) => {
			const iconName = isGood ? 'info' : 'block-ban';
			const iconText = isGood ? 'Задание зачтено' : 'Задание не зачтено';
			const status = isGood ? 'good' : 'bad';
			const info = isGood ? 'Поздравляем! Вы закончили выполнение задания' : 'Вы закончили выполнение задания';
			return [iconName, iconText, status, info];
		};

		if (!notebookData) return <></>;
		if (currPage === taskPages.length && taskPages.length > 0) 
			clearInterval(timerIdRef.current);

		const tasks = taskPages.map(item => item.task);
		const correctAnswerNumber = tasks.filter(item => item.isCorrect === 1).length;
		let iconName, status, iconText, score, scoreName, info, isResultOk;

		if (notebookData.settings.isAutoScore) {
			score = calcScoreValue(correctAnswerNumber, tasks.length);
			scoreName = getScoreName(score);
			isResultOk = score >= getMinGoodScore();
			[iconName, iconText, status, info] = getAutoScoreOpts(isResultOk);
		} else {
			iconName = 'warning';
			iconText = 'Задание на проверке у преподавателя';
			status = "unknown";
			info = 'Вы закончили выполнение задания. Преподаватель проверит ваше задание и выставит оценку. Вы сможете увидеть вашу оценку в вашем профиле';
		}
		return (
			<div className="cor-work__result">
				<div className={"cor-work__result_caption " + status}>
					<div className="cor-work__result_icon">
						<Icon name={iconName} />
					</div>
					<div className="cor-work__result_title">{iconText}</div>
				</div>

				{notebookData.settings.isAutoScore && 
					<div className="cor-work__result_rating">
						Оценка: {scoreName}
					</div>
				}

				<div className="cor-work__result_info">{info}</div>

				{notebookData.settings.isAutoScore && 
					<div className="cor-work__result_methodology" onClick={handleHowToCalc}>
						Методика расчета оценки
					</div>
				}

				<div className="cor-work__result_buttons">
					<Button color="primary" size = "extraSmall" onClick={handleGoMain}>
						Вернуться в рабочую тетрадь
					</Button>
					<Button color="primary" border={true} size = "extraSmall" onClick={handleGoProfile}>
						Перейти в профиль
					</Button>
				</div>
			</div> 
			);
	};

	const handleHowToCalc = () => {
		setShowHowToCalcDlg(true);
	};
	const handleGoMain = () => {
		history.push(FEATURES['notebook'].to);
	};
	const handleGoProfile = () => {
		if (notebookInfoId)
			history.push(FEATURES.profile.to + '/' + notebookInfoData.owner._id + '/' + PRACTICUM_SECTION.NOTEBOOK);
		else 
			history.push(FEATURES.profile.to);
	};

	const getActionButtons = (pageNum) => {
		if (taskPages.length === 0)  return (<></>);

		//lock Next button if the student is not answered yet:
		const buttons = (
			<ContentFooter className="jc-center">
				{pageNum > 0 && pageNum < taskPages.length && (
					<div className="cor-work__btn" onClick={() => handlePrevQuestion(pageNum)} >
						<Icon name={'arrow-round-prev'}/>Предыдущий вопрос
					</div>
				)}

				{isStudentAnswered(pageNum) && 
					<div className="cor-work__btn" onClick={() =>handleNextQuestion(pageNum, workErrorLimit === 0)}>
						{pageNum < taskPages.length - 1 && workErrorLimit !== 0 ? 'Следующий вопрос' : 'Смотреть результаты'}
						<Icon name={'arrow-round-next'}/>
					</div>
				}

				{isResultPage(pageNum)  && 
					<div className="cor-work__btn" onClick={handleStudentIsReadyToComplete}>
						Завершить <Icon name={'check-round'}/>
					</div>
				}
			</ContentFooter>
		);
		return buttons; 
	};

    return (
		<>
		{currPage < taskPages.length ?
			<Content>
				<ContentHeader>
					<div className="cor-work__row col-w33">
						<div className="cor-work__col">
							Название задания: &nbsp;{notebookData?.titles?.homeWorkName}
						</div>
						<div className="cor-work__col">
							{ currPage < taskPages.length ? 
								<>Вопрос { currPage+1 } / {taskPages.length}</> 
								: 
								<>Результаты</>
							}
						</div>
						<div className="cor-work__col">
							<span>
								{timeLimitEnabled ? showRemainingTime(currTestTime, startTime, 60 * timeLimitInMinutes - actualSpentTime, true) : 
									showCurrTime(currTestTime, startTime)}
							</span>
						</div>
					</div>
				</ContentHeader>
				<ContentBody>
					{getQuestionPage(currPage)}
				</ContentBody>

				{getActionButtons(currPage)}

			</Content>

		:
			<div className="cor-work__result_wrap">
				{getResultPage()}
			</div>
		}

			{showCompleteTestDlg &&
			<ModalFinishStudentTestDlg
				showConfirmDlg={showCompleteTestDlg} 
				handleNo={handleConfirmCompleteTestNo}
				handleYes={handleConfirmCompleteTestYes}
			/>
			}
			
			{showHowToCalcDlg && 
			<ScoreCalcTableDlg
				showHowToCalcDlg={showHowToCalcDlg} 
				setShowHowToCalcDlg={setShowHowToCalcDlg}
			/>
			}
		</>
    )
}

export default NotebookStudentHomeWorkExecutePages;
