import React, { useState, useEffect } from 'react';
import { Dropdown } from 'rlabui';
import './style.scss';

const OscilloSettings = (props) => {
    const { defaultChartSettings, setOscilloscopeChartSettings, oscilloscopeSettings, sensorList, sendCommand, played, setPlay, setForceUpdate, isOscilloscope, response } = props;

    // console.log(sensorList[0].modes);
    const { modes } = sensorList.length ? sensorList[0] : [];

    const [currentRange, setCurrentRange] = useState({ min: -100, max: 100 });
    const [rangeValue, setRangeValue] = useState('-100...+100 В');

    const [collapsed, toggleCollapse] = useState(false);
    const [currentSync, setCurrentSync] = useState(0);

    const [options, setOptions] = useState([]);
    const [iSSending, setSending] = useState(false);
    const [isSingleSignal, setSingleSignal] = useState(false);

    useEffect(() => {
        if (isSingleSignal && played) {
            setTimeout(function () {
                setPlay(false);
            }, 100);
        }
        setSending(false);
    }, [isSingleSignal, played]);

    useEffect(() => {
        console.log(response);
    }, [response]);

    useEffect(() => {
        // console.log(oscilloscopeSettings);
        setSending(false);
        // !played && setForceUpdate(true);
    }, [oscilloscopeSettings]);

    useEffect(() => {
        // console.log(modes);
        // console.log(options);
        if (modes?.length && !options.length) {
            setOscilloscopeModes();
        }
    }, [modes, options]);

    useEffect(() => {
        const obj = { ...defaultChartSettings };
        const { min: yMin, max: yMax } = currentRange;

        for (var key in obj) {
            switch (key) {
                case 'yMin':
                    isOscilloscope && setOscilloscopeChartSettings({ id: 'yMin', data: yMin });
                    break;
                case 'yMax':
                    isOscilloscope && setOscilloscopeChartSettings({ id: 'yMax', data: yMax });
                    break;
                default:
            }
        }
    }, [currentRange]);

    const setOscilloscopeModes = () => {
        const opt = [...options];
        modes.forEach((mode, i) => {
            // console.log(mode);
            const { modeName, modeData } = mode;

            if (modeName !== 'Скорость подключения' && modeData) {
                if (!opt[i]) {
                    opt[i] = { name: modeName, opt: [] };
                }

                if (modeData && modeName) {
                    switch (modeName) {
                        case 'Частота дискретизации':
                            modeData.forEach((el, k) => {
                                // console.log(el);
                                opt[i].opt.push({ label: el, value: +el.split(' ')[0], id: k });
                            });
                            break;
                        case 'Диапазон напряжений':
                            modeData.forEach((el, k) => {
                                opt[i].opt.push({ label: el, value: -+el.split('...')[0], id: k });
                            });
                            break;
                        case 'Число выборок сигнала':
                            modeData.forEach((el, k) => {
                                opt[i].opt.push({ label: el, value: +el, id: k });
                            });
                            break;
                        default:
                            modeData.forEach((el, k) => {
                                opt[i].opt.push({ label: el, value: k, id: k });
                            });
                            sendCommand(`sm${i} 0`);
                    }
                }
            }
        });
        setOptions(opt);
    };

    return (
        <>
            <div className="oscilloSetts__title">Настройка каналов осциллографа</div>
            <div className="mode__status">
                Статус: <span>{iSSending ? 'запрос смены режима' : 'готово'}</span>
            </div>
            <div className="oscilloSetts">
                <div
                    className={collapsed ? 'oscilloSetts__head active' : 'oscilloSetts__head'}
                    onClick={() => toggleCollapse(!collapsed)}
                >
                    Общие настройки
                </div>
                {!collapsed && (
                    <div className="oscilloSetts__body oscilloModes">
                        <div className="mode">
                            {oscilloscopeSettings &&
                                options?.map((item, i) => {
                                    const { name, opt } = item;
                                    let value;
                                    switch (name) {
                                        case 'Частота дискретизации':
                                            value = oscilloscopeSettings.freq;
                                            break;
                                        case 'Диапазон напряжений':
                                            value = oscilloscopeSettings.max;
                                            break;
                                        case 'Число выборок сигнала':
                                            value = oscilloscopeSettings.cnum;
                                            break;
                                        case 'Вид синхронизации':
                                            value = currentSync;
                                            break;
                                        default:
                                            value = 0;
                                            break;
                                    }

                                    let current = opt.filter((o) => o.value === +value?.toFixed())[0];
                                    if (name === 'Диапазон напряжений') {
                                        current = { label: rangeValue, value: Math.abs(+rangeValue.split('...')[0]), id: 0 };
                                    }
                                    return (
                                        <div
                                            className="mode__item"
                                            key={'sets' + i}
                                        >
                                            <div className="mode__name">{name}</div>
                                            <Dropdown
                                                placeholder="Выберите режим"
                                                disabled={name === 'Вид синхронизации' && played}
                                                value={current && current.label}
                                                clicked={true}
                                                className={name === 'Число выборок сигнала' && 'top'}
                                            >
                                                {opt.map((item, k) => (
                                                    <Dropdown.Item
                                                        key={'set' + k}
                                                        onClick={() => {
                                                            setSending(true);
                                                            const { label, id, value } = opt[k];
                                                            sendCommand(`sm${i} ${id}`);
                                                            if (name === 'Диапазон напряжений') {
                                                                setCurrentRange({ min: -value, max: value });
                                                                setRangeValue(label);
                                                            }
                                                            if (name === 'Вид синхронизации') {
                                                                setCurrentSync(value);
                                                            }
                                                            if (label === 'Однократный') {
                                                                setSingleSignal(true);
                                                            } else {
                                                                setSingleSignal(false);
                                                            }
                                                            if (label === 'Однократный' || label === 'Автоматический' || label === 'Ожидающий') {
                                                                setTimeout(() => {
                                                                    setSending(false);
                                                                }, 100);
                                                            }
                                                        }}
                                                    >
                                                        {item.label}
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
export default OscilloSettings;
