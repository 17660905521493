const initialState = {
    settings: {
        x: 'tracking',
        y: 'autofit',
        colors: [
            { val: '#F35151', name: '#F35151' },
            { val: '#EF884E', name: '#EF884E' },
            { val: '#F9DE80', name: '#F9DE80' },
            { val: '#C2D83F', name: '#C2D83F' },
            { val: '#4EE4AE', name: '#4EE4AE' },
            { val: '#81F2F9', name: '#81F2F9' },
            { val: '#81B8F9', name: '#81B8F9' },
            { val: '#8B96FA', name: '#8B96FA' },
            { val: '#E180F9', name: '#E180F9' },
            { val: '#CE5A92', name: '#CE5A92' },
            { val: '#F35151', name: '#F35151' },
            { val: '#EF884E', name: '#EF884E' },
            { val: '#F9DE80', name: '#F9DE80' },
            { val: '#C2D83F', name: '#C2D83F' },
            { val: '#4EE4AE', name: '#4EE4AE' },
            { val: '#81F2F9', name: '#81F2F9' },
            { val: '#81B8F9', name: '#81B8F9' },
            { val: '#8B96FA', name: '#8B96FA' },
            { val: '#E180F9', name: '#E180F9' },
            { val: '#CE5A92', name: '#CE5A92' },
        ],
        line: {
            type: [],
            weight: [],
        },
        points: {
            weight: 0,
            color: 'transparent',
        },
        view: 'two', // one, two, three, four, multy, mono
        modal: false,
        interpolation: 'linear', // linear, basis, bundle, cardinal, catmull, monotonex, monotoney, natural, step, stepafter, stepbefore
    },
    disabled: [],
    style: {},
    mode: 'arbitrary',
    marker: {},
    xRanges: {},
    offsetRec: false,
    markers: {
        add: false,
        del: false,
        deleteAll: false,
        clearModal: false,
    },
    ranges: true,
    signalMode: 0,
    cursor: {},
};

const devicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_NCHART_X':
            return {
                ...state,
                settings: {
                    ...state.settings,
                    x: action.payload,
                },
            };

        case 'SET_NCHART_Y':
            return {
                ...state,
                settings: {
                    ...state.settings,
                    y: action.payload,
                },
            };

        case 'SET_NCHART_SETTINGS_MODAL':
            return {
                ...state,
                settings: {
                    ...state.settings,
                    modal: action.payload,
                },
            };

        case 'SET_NCHART_COLOR':
            const indexColor = action.payload.id;
            const colors = [...state.settings.colors];
            colors[indexColor] = action.payload.data;
            return {
                ...state,
                settings: {
                    ...state.settings,
                    colors,
                },
            };

        case 'SET_NCHART_LINE_TYPE':
            const indexType = action.payload.id;
            const type = [...state.settings.line.type];
            type[indexType] = action.payload.data;
            return {
                ...state,
                settings: {
                    ...state.settings,
                    line: {
                        ...state.settings.line,
                        type,
                    },
                },
            };

        case 'SET_NCHART_LINE_WEIGHT':
            const indexWeight = action.payload.id;
            const weight = [...state.settings.line.weight];
            weight[indexWeight] = action.payload.data;
            return {
                ...state,
                settings: {
                    ...state.settings,
                    line: {
                        ...state.settings.line,
                        weight,
                    },
                },
            };

        case 'SET_NCHART_VIEW':
            return {
                ...state,
                settings: {
                    ...state.settings,
                    view: action.payload,
                },
            };

        case 'SET_NCHART_MODE':
            return {
                ...state,
                mode: action.payload,
            };

        case 'SET_NCHART_OFFSET_REC':
            return {
                ...state,
                offsetRec: action.payload,
            };

        case 'SET_NCHART_SIGNALMODE':
            return {
                ...state,
                signalMode: action.payload,
            };

        case 'SET_NCHART_MARKERS':
            let key = action.payload.key;
            let all = { ...state.marker };
            all[key] = { ...action.payload.data };
            return {
                ...state,
                marker: all,
            };

        case 'DEL_NCHART_MARKERS':
            let deletet = { ...state.marker };
            deletet[action.payload] = {};
            return {
                ...state,
                marker: deletet,
            };

        case 'SET_NCHART_XRANGES':
            let chartKey = action.payload.key;
            let xRanges = { ...state.xRanges };
            xRanges[chartKey] = [...action.payload.data];
            return {
                ...state,
                xRanges,
            };

        case 'SET_NCHART_STYLE':
            return {
                ...state,
                style: action.payload,
            };

        case 'SET_NCHART_CURSOR_SYNC':
            let syncKey = action.payload.key;
            const cursor = { ...state.cursor };
            cursor[syncKey] = action.payload.data;
            return {
                ...state,
                cursor,
            };

        case 'SET_NCHART_MARKER_ADD':
            return {
                ...state,
                markers: {
                    ...state.markers,
                    add: action.payload,
                },
            };

        case 'SET_NCHART_MARKER_DELETE':
            return {
                ...state,
                markers: {
                    ...state.markers,
                    del: action.payload,
                },
            };

        case 'SET_NCHART_MARKER_DELETE_ALL':
            return {
                ...state,
                markers: {
                    ...state.markers,
                    deleteAll: action.payload,
                },
            };

        case 'CLEAR_NCHART_MARKER_MOADL':
            return {
                ...state,
                markers: {
                    ...state.markers,
                    clearModal: action.payload,
                },
            };

        case 'SET_NCHART_RANGES':
            return {
                ...state,
                ranges: action.payload,
            };

        case 'ADD_NCHART_DISABLED':
            return {
                ...state,
                disabled: [...state.disabled, action.payload],
            };

        case 'DELETE_NCHART_DISABLED':
            return {
                ...state,
                disabled: state.disabled.filter((ch) => ch !== action.payload),
            };

        default:
            return state;
    }
};

export default devicesReducer;
