import React, { useEffect, useState } from 'react';
import { Icon } from '../ui';
import ss from './MarkersSettings.module.scss';

const modeOptions = [
    { value: 1, type: 'range1', color: '#81B8F9' },
    { value: 2, type: 'range2', color: '#80F9D4' },
    { value: 3, type: 'range3', color: '#E180F9' },
    { value: 4, type: 'range4', color: '#F9DE80' },
];

const MarkersSettings = (props) => {
    const {
        port,
        imported,
        setChartMode,
        setChartMarkerAdd,
        setChartMarkerDelete,
        clearChartMarkerModal,
        settings: {
            markers: { add, del, deleteAll },
            mode,
        },
    } = props;

    const [modeVal, setModeVal] = useState(1);
    const [modeArr, setModeArr] = useState(modeOptions);

    useEffect(() => {
        if (deleteAll) {
            setChartMarkerAdd(false);
            setChartMarkerDelete(false);
        }
        if (add || del || deleteAll) {
            setModeArr([]);
            setModeVal(0);
            setChartMode('arbitrary');
        }
    }, [deleteAll, add, del, setChartMarkerAdd, setChartMarkerDelete, setChartMode]);

    useEffect(() => {
        if (!modeVal && !modeArr.length) {
            setModeArr(modeOptions);
            setModeVal(1);
        }
    }, [modeVal, modeArr]);

    return (
        <>
            <Icon
                tag="button"
                name="range-plus"
                className={add && ss.active}
                disabled={!port && !imported}
                title="Установить диапазон"
                onClick={() => {
                    setChartMarkerAdd(!add);
                    setChartMarkerDelete(false);
                    setChartMode('arbitrary');
                }}
            />
            <Icon
                tag="button"
                name="range-minus"
                className={del && ss.active}
                title="Удалить диапазон"
                disabled={!port && !imported}
                onClick={() => {
                    setChartMarkerDelete(!del);
                    setChartMarkerAdd(false);
                    setChartMode('arbitrary');
                }}
            />
            {/* <Icon
                tag="button"
                name="marker-plus"
                className={add && ss.active}
                disabled={!port}
                title="Установить маркеры"
                onClick={() => {
                    setChartMarkerAdd(!add);
                    setChartMarkerDelete(false);
                    setChartMode('arbitrary');
                }}
            />
            <Icon
                tag="button"
                name="marker-minus"
                className={del && ss.active}
                title="Удалить маркеры"
                disabled={!port}
                onClick={() => {
                    setChartMarkerDelete(!del);
                    setChartMarkerAdd(false);
                    setChartMode('arbitrary');
                }}
            /> */}
            <Icon
                tag="button"
                name="cursor-mode"
                className={mode === 'cursor' && ss.active}
                disabled={!port && !imported}
                title="Курсор"
                onClick={() => {
                    if (mode !== 'cursor') {
                        setChartMode('cursor');
                    } else {
                        setChartMode('arbitrary');
                    }
                }}
            />
            <Icon
                tag="button"
                name="trash"
                title="Очистить график"
                disabled={!port && !imported}
                onClick={() => clearChartMarkerModal(true)}
            />
        </>
    );
};

export default MarkersSettings;
