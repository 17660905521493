import React from 'react';
import { Modal, Button } from 'rlabui';
import ss from './ResetConfirmModal.module.scss';

const ResetConfirm = ({ modal, setResetModal, setSensorImported, resetSensorData, delChartMarkers, markers }) => {
    const clearCharts = () => {
        setSensorImported([]);
        resetSensorData();
        for (const key in { ...markers }) {
            delChartMarkers(key);
        }
        setResetModal(false);
    };

    return (
        <Modal
            visible={modal}
            size={'xs'}
            className={ss.root}
        >
            <Modal.Head
                title="Очистка записаных данных"
                modalClose={() => setResetModal(false)}
            ></Modal.Head>
            <Modal.Body>
                <p>Будут удалены все графики, маркеры и импортированные данные</p>
                <p>Вы действительно хотите очистить графики?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={clearCharts}>Да</Button>
                <Button
                    border={true}
                    onClick={() => setResetModal(false)}
                >
                    Нет
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default ResetConfirm;
