/*Зарезервированные имена колонок (заглавные или прописные)
	person - (только одна колонка с этим именем)
	actions - (только одна колонка с этим именем)
	properties - (только одна колонка с этим именем)
	link - (только одна колонка с этим именем)
	date - (date-колонка может содержить эти слова как часть названия, например, 
			могут быть колонки с именами  DateStart и DateFinish)
	score - (только одна колонка с этим именем)
*/	
import React, {useState, useEffect} from "react";
import { Icon } from '../';
import {getNbCheckBox, getDropdown} from "../utils/gen_utils";
import {SCORE_MANUAL_CHECK, SCORE_BE_EVALUATED_LATER, getScoreCbList, 
	getScoreValue, getScoreName} from '../utils/score_utils';
import {profilePicture} from "../../../helpers/auth";
import "../../OnlineTest/OnlineTestCreate.scss";
import "./Table.scss";
import "../../RlabTable/RlabTable.scss";
import ss from './Table.module.scss';

const TBL_ACTION_COLORS = {
	DEFAULT: 0,
	BLUE: 1,
	GREY: 2,
};

export const TBL_ICON_NAME = {
	RUN: 'play',
	PREVIEW: 'eye-no-eyelashes', //REPLACE!!
	EDIT: 'edit',
	COPY: 'copy-paste',
	SAVE: 'save',
	SAVE_TO_COMPUTER: 'download',
	ADD: 'eye-hide',
	REMOVE: 'eye-no-eyelashes',
	INFO: 'info',
	SHOW_DETAILS: 'folder',
	EXECUTE: '',
	DELETE: 'trash',
};

const ID = 'id';

const Table = ({table, sort, link, person, actions, canEditScore, extClassName = ''}) => {
    const [sortIndex, setSortIndex] = useState(undefined);
    const [sortedRows, setSortedRows] = useState([]);

	useEffect(() => {
		setSortedRows(table.data);
		const _sortIndex = sort && sort.hasSorting ? sort.initSortInd: undefined;
		setSortIndex(_sortIndex);
	}, [table.data, sort]);

	useEffect(() => {
		if (!sort || !sort.hasSorting) return;
		if (sortIndex === undefined || sortIndex === 0 || table.data.length <= 1) return;
		const ind = sortIndex > 0 ? sortIndex-1 : -sortIndex-1;
		if (ind >= table.header.length) return;

		const columnName = table.header[ind].name.toLowerCase();
		if (canEditScore && columnName.includes('score')) return; 
		const isDate = columnName.includes('date');
		const sortedName = table.header[ind].name;

		let arr;
		if (sortIndex > 0)
			arr = table.data.sort((a, b) => getSortingValue(a[sortedName], isDate).localeCompare(getSortingValue(b[sortedName], isDate)));
		else 
			arr = table.data.sort((b, a) => getSortingValue(a[sortedName], isDate).localeCompare(getSortingValue(b[sortedName], isDate)));
		setSortedRows(arr);
	}, [sort, sortIndex, canEditScore, table.header, table.data]);

	const getSortingValue = (val, _isDate) => {
		if (!val) return '';
		let res;
		if (_isDate) { //дата
			const arr = val.split(' ');
			const arr1 = arr[0].split('.');
			res = arr1[2] + arr1[1] + arr1[0]; //year + month + day
			if (arr.length > 1) {
				const arr2 = arr[1].split(':');
				res += arr2[0] + arr2[1]; //+ hour + minutes like 2023 11 09 15 18
			}
		} else {
			const arr = val.toString().split(' ');
			if (!isNaN(arr[0])) { //в начале стоит число, используем только его
				res = arr[0];
				for (let i = 0; i < 10 - arr[0].length; i ++) res = ' ' + res;
			} else {
				res = val; //строка
			}
		}
		return res;
	};
	
	const getStyledCellText = (text, style, key) => {
		const  isNumber = (value) => typeof value === 'number';
		const SUP = '^+';
		const SUB = '^-';
		let shift = 0;
		let arr;
	
		if (!isNumber(text) && text?.includes('<sup>')) {
			text = text.replaceAll('<sup>', SUP).replaceAll('</sup>', SUP);
		}
	
		if (!isNumber(text) && text?.includes(SUP)) { //item обязательно должен быть строкой
			shift = 1;
			arr = text.split(SUP);
		} else if (!isNumber(text) && text?.includes(SUB)) {
			shift = -1;
			arr = text.split(SUB);
		} else
			return <span key={'ab'+key} style={style}>{text}</span>;
	
		const list = [];
		for (let i = 0; i < arr.length; i ++)
			if (i%2 === 0)
				list.push(<span key={'cd'+key + 100 * i}>{arr[i]}</span>);
			else {
				if (shift === 1)
					list.push(<sup key={'form1'+i} >{arr[i]}</sup>);
				else 
					list.push(<sub key={'form2'+i} >{arr[i]}</sub>);
		   }
		
		return (
			<span style={style} key={'spn01'+key}>
				{list.map(item => item)}
			</span>
		);
	};
	
	const canHandleSorting = (ind) => !!sort && sort.hasSorting && !hasActionBtns(ind);
  
	const handleSorting = (ind) => {
		const ind1 = ind + 1;
		const _sortIndex = sortIndex === ind1 ? -sortIndex : sortIndex === -ind1 ? 0 : ind1;
		setSortIndex(_sortIndex);
	};

	const getSortIcon = (colInd, sortInd) => {
		const isTextHeader = !table.header[colInd].name.toLowerCase().includes('date');
		let icons = '';

		if (isTextHeader) {
			let iconName = "sort-text";
			let cn = 'sortText';
		 	if (Math.abs(sortInd) - 1 === colInd) {
				cn += " active";
				if (sortInd < 0) 
					iconName = "sort-text-desc";
			}
		 	icons = <Icon name={iconName} className={cn}  />;
		} else {
			let descClassName = "sortNumber";
			let ascClassName = "sortNumber";
			if (Math.abs(sortInd) - 1 === colInd) {
				if (sortInd > 0) {
					descClassName += " active";
				}
				else if (sortInd < 0) {
					ascClassName += " active";
				} 
			} 
	
			icons = <>
				<Icon name="sort-desc" className={descClassName} />
				<Icon name="sort-asc" className={ascClassName} />
			</>;
		}

		return (
			<div className='table__SortIcon'  >
				{icons}
			</div>);
	};
	const getKey = (prefix, rowInd, colInd) => prefix + rowInd + colInd;
	  
	const getActionIcons = (rowInd) => {
		if (!actions) return (<span></span>); //Должны быть action buttons
		const row = sortedRows[rowInd];
		const id = row[ID];

		const btnRun  =       (id) => getButton(id, actions.handleRun, actions.canRunIDs, TBL_ICON_NAME.RUN, 'Старт', actions.tooltipRun, actions.textRun, TBL_ACTION_COLORS.BLUE);
		const btnPreview =    (id) => getButton(id, actions.handlePreview, undefined, TBL_ICON_NAME.PREVIEW, 'Предпросмотр', actions.tooltipPreview);
		const btnEdit =       (id) => getButton(id, actions.handleEdit, actions.canEditIDs, TBL_ICON_NAME.EDIT, 'Редактировать', actions.tooltipEdit);
		const btnSave =       (id) => getButton(id, actions.handleSave, undefined, TBL_ICON_NAME.SAVE, 'Сохранить', actions.tooltipSave);
		const btnSaveToComp = (id) => getButton(id, actions.handleSaveToComputer, undefined, TBL_ICON_NAME.SAVE_TO_COMPUTER, 'Сохранить  на компьютер', actions.tooltipSaveToComputer);
		const btnInfo =       (id) => getButton(id, actions.handleInfo, actions.canInfoIDs, TBL_ICON_NAME.INFO, 'Информация', actions.tooltipInfo);
		const btnCopy =       (id) => getButton(id, actions.handleCopy, undefined, TBL_ICON_NAME.COPY, 'Копировать', actions.tooltipCopy);
		const btnShowDetails =(id) => getButton(id, actions.handleShowDetails, undefined, TBL_ICON_NAME.SHOW_DETAILS, 'xxx', actions.tooltipShowDetails, actions.textShowDetails, TBL_ACTION_COLORS.GREY);
		const btnExecuteTest =(id) => id ? getButton(id, actions.handleExecuteTest, undefined, TBL_ICON_NAME.EXECUTE, '', actions.tooltipExecuteTest, actions.textExecuteTest, TBL_ACTION_COLORS.BLUE) : <></>;
		const btnDelete =     (id) => getButton(id, actions.handleDelete, actions.canDeleteIDs, TBL_ICON_NAME.DELETE, 'Удалить', actions.tooltipDelete);
		const btnSwitch =     (ids, selectText = 'Добавить', unselectText = 'Скрыть') => {
			//ids should always have 2 parts: id and state: 1 - it is switched on, 0 - switched off
			//в doHandle игнорируем значение v и просто вызываем handleSwitch с текущим ids
			const doHandle = (v) => {actions.handleSwitch(ids);}; 
			if (!actions.handleSwitch || !ids) return <></>;
			const isSwitchedOn = ids.split('|')[1] !== '0';
			return getNbCheckBox(isSwitchedOn, doHandle, id);
		}

		const getButton = (id, handle, canHandleIds, iconName, defaultTooltip, customTooltip, 
				explanationText = '', isSpecCol = TBL_ACTION_COLORS.DEFAULT) => {		
			if (!handle) return <></>;

			if (actions.specialActionCriteria && !actions.specialActionCriteria(id, iconName)) {
				return <></>;
			}

			if (!!canHandleIds) {
				const found = canHandleIds.find(item => item === id);
				if (!found) return <></>;
			}

			const specActionColor = 
					isSpecCol === TBL_ACTION_COLORS.BLUE ? 'table__ActionIconBlue' : 
					isSpecCol === TBL_ACTION_COLORS.GREY ? 'table__ActionIconGrey' : 
					'';

			let tooltip = '';
			if (!explanationText) {
				tooltip = !!customTooltip ? customTooltip : defaultTooltip;
			}

			return (
				<div 
					className={'table__pointer' + (tooltip ? ' tp tp_top' : '')}
					onClick={() => handle(id)}
					data-tp={tooltip}
					>
					<Icon name={iconName} className={'table__ActionIcon ' + specActionColor} />
					{explanationText && <span className={specActionColor + ' table__ActionIconDecoration'}>
						{explanationText}
					</span>}
				</div>
			);
		};

		const actionStyle = actions.handleActionSyle ? actions.handleActionSyle(row, id) : {};
		return (
			<span className="table__actionsSpan" style={actionStyle}>
				{btnRun(id)}
				{btnPreview(id)}
				{btnEdit(id)}
				{btnInfo(id)}
				{btnCopy(id)}
				{btnSave(id)}
				{btnSaveToComp(id)}
				{btnExecuteTest(id)}
				{btnShowDetails(id)}
				{btnDelete(id)}
				{btnSwitch(id)}
			</span>
		);
	};

	const showColumn = (colInd) => {
		const header = table.header;
		const width = header[colInd]?.style?.width;
		return (width !== '0%');
	};
	
	const isLinkColumn = (columnName) => {
		if (!link) return false;
		return columnName.toLowerCase().includes('link'); //название колонки должно содержать слово 'link'
	}
	const getLinkCell = (rowInd, colInd, row, text) => {
		const userId = row[ID];
		return (
			<span key={getKey('lnk', rowInd, colInd)} className="table__LinkCol" 
				onClick={() => link.handleLink(userId)}>
					{getStyledCellText(text, {}, 'lk1'+rowInd+colInd)}
			</span>
		);
	};

	const isPropertyColumn = (columnName) => {
		return columnName.toLowerCase().includes('properties'); //название колонки должно содержать слово 'properties'
	} 
	const getPropertyCell = (rowInd, colInd, row, text) => {
		const textArr = text.split('|');
		return (
			<span key={getKey('prp', rowInd, colInd)} >
				{textArr.map(item => (<div>
					<b>
						{getOrdinaryCell(rowInd, colInd, row, item.split('/')[0], 'frst')} &nbsp;
					</b>
						{getOrdinaryCell(rowInd, colInd, row, item.split('/')[1], 'scnd')}
				</div>))}
			</span>
		);
	}; //

	const isPersonColumn = (colInd) => colInd === 0 && !!person;

	const getPersonCell = (rowInd, colInd, row, personName) => {
		const userId = row[ID];
		if (person.isCellLink) {
			return (
				<span 
					key={getKey('prs', rowInd, colInd)} 
					className="table__PersonCol" 
					onClick={() => person.handlePerson(userId)}>
					{personName}
				</span>
			);
		}

		return (
			<span 
				key={getKey('arr', rowInd, colInd)} 
				className={ss.personeCol} 
				onClick={() => person.handlePerson(userId)}>
				{!row.personPict ? 
					<Icon name="user-select" className={ss.personeCol__icon} />
				:
					<span className={ss.personeCol__picture}>
						<img src={profilePicture(row.personPict)} alt='' />
					</span>
				}
				<span className={ss.personeCol__name}>{personName}</span>
			</span>
		);
	};

	const hasActionBtns = colInd => table.header[colInd].name.toLowerCase().includes('actions');
	const isScore = colInd => table.header[colInd].name.toLowerCase().includes('score');
	const isEditScoreCell = colInd => isScore(colInd) && canEditScore;
	const isViewScoreCell = colInd => isScore(colInd) && !canEditScore;
	
	const getEditScoreCell = (value, row) => {
		const row_id = row[ID];

		if (!value || value === '0' || value === SCORE_MANUAL_CHECK || value === SCORE_MANUAL_CHECK.toString()) {
			return getScoreName(value);
		}
	
		return getDropdown(getScoreCbList(), getScoreValue(value), 
				!!table.handleTableDataUpdate ? val => table.handleTableDataUpdate(val, row_id) : {}, 
				'otCreate__Select otCreate__SelectValues9', 'otCreate__DropDownItem', 'score01', 
		 		"bottom", false, row_id);
	};

	const getViewScoreCell = (value, row) => {
		if (value === SCORE_BE_EVALUATED_LATER || value === SCORE_BE_EVALUATED_LATER.toString()) {
			return 'Поставьте оценку после завершения теста';
		}
		return getScoreName(value);
	};

	const isOrdinaryCell = (colInd) => 
		!isScore(colInd) && !hasActionBtns(colInd) && 
		!isPersonColumn(colInd) && !isLinkColumn(table.header[colInd].name) && 
		!isPropertyColumn(table.header[colInd].name);
	const getOrdinaryCell = (rowInd, colInd, row, text, key = '') => {
		const style = table.specialCellStyle ? table.specialCellStyle(text, rowInd, colInd, row) : {};
		return getStyledCellText(text, style, getKey('ord'+key, rowInd, colInd));
	};

	const getRow = (row, rowInd, fields) => {
		return (
		fields.map((fieldName, colInd) => showColumn(colInd) ? 
			<td key={getKey('fld', rowInd, colInd)} >
				{isPersonColumn(colInd) && getPersonCell(rowInd, colInd, row, row[fieldName])}
				{isLinkColumn(table.header[colInd].name) &&  getLinkCell(rowInd, colInd, row, row[fieldName])}
				{isPropertyColumn(table.header[colInd].name) && getPropertyCell(rowInd, colInd, row, row[fieldName])}
				{isOrdinaryCell(colInd) && getOrdinaryCell(rowInd, colInd, row, row[fieldName])}
				{isEditScoreCell(colInd) && getEditScoreCell(row[fieldName], row)}
				{isViewScoreCell(colInd) && getViewScoreCell(row[fieldName], row)}
				{hasActionBtns(colInd) && getActionIcons(rowInd)}
			</td>
			:
			<td key={getKey('zer', rowInd, colInd)} style={{width: 0, display: 'block', padding: 0}}></td>
		)			
		);
	};

	
	const getRowStyle = (_handleRowStyle, rowInd) => {
		if (!_handleRowStyle) return {};
		const style = _handleRowStyle(rowInd);
		return style;
	};

	const getRowClass = (rowClassPrefix, rowInd) => {
		let cn = "table__Row";
		if (!!rowClassPrefix)
			cn += ' table__Row' + rowClassPrefix;
		return cn;
	};

	if (!table.header || !sortedRows) return <></>;

	return (
        <table className={'cor_table ' + (extClassName ? extClassName : '')} key={'tbl'}>
			<thead  key={'thd'}>
				<tr key={'tr0'} className="table__Row0">
					{table.header.map((item, ind) => showColumn(ind) ? 
						<th key={getKey('hdr', 1, ind)} 
							style={table.header[ind].style}
							onClick={() => canHandleSorting(ind) ? handleSorting(ind) : {} }>
								<span className="table__SortCell" key={'thsp01'+ind}>
									<span key={'thspsp01'+ind}>{item.column}</span>
									{canHandleSorting(ind) ? getSortIcon(ind, sortIndex) : ''}
								</span>
						</th> 
					:
						<th key={getKey('hdz', 0, ind)} style={{width: 0, display: 'block', padding: 0}}></th>
					)}
				</tr>
		    </thead>

			<tbody key={'tbd'}> 
			{sortedRows.map((row, rowInd) => 
				<tr 
					key={getKey('srt', rowInd, 0)} 
					className={getRowClass(table.rowClassPrefix, rowInd)}
					style={getRowStyle(table.handleRowStyle, rowInd)}
				>
					{getRow(row, rowInd, table.header.map( obj => obj.name ))}
				</tr>
			)}
		    </tbody>

		</table>
	);
}

export default Table;
