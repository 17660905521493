import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {isAuth} from "../../helpers/auth";
import {Icon} from "../ui";
import {printShortDate, formatBytes} from "../../helpers/text";
import {loadFiles} from "../../redux/slices/lists";
import "./Grid.scss";
import Modal from "../Modal";

const StorageGrid = (props) => {
    const dispatch = useDispatch();
    const {lists: {files, dirs}} = useSelector(state => state);
    const [previewModal, setPreviewModal] = useState(false);
    const [previewModalSize, setPreviewModalSize] = useState("lg");
    const [previewModalImage, setPreviewModalImage] = useState({Key:"", Url:""});

    const prefix = props.match?.params.key ?? '%2F';
    const currentDir = () => decodeURIComponent(prefix);
    const upDir = () => currentDir().replace(/[^/]+\/$/, '');
    const printFile = (name) => name.split('/').pop();
    const printDir = (name) => name.split('/').slice(-2);

    const navigate = (dir, abs) => {
        props.history.push("/storage" + (dir === '/' ? '' : '/') +
          encodeURIComponent(abs ? (dir === '/' ? '' : dir) : currentDir() + dir));
    }

    const getExtension = (name) => {
        return name.lastIndexOf('.') > -1 ? name.split('.').pop().toLowerCase() : '';
    }

    const showPreview = (name) => ['gif', 'jpg', 'jpeg', 'png', 'svg', 'tiff'].includes(getExtension(name));

    const fileTypeIcon = (name) => {
        switch (getExtension(name)) {
            case "mp4":
                return "film";
            case "csv":
            case "xls":
            case "xlsx":
                return "file-excel";
            case "doc":
            case "docx":
                return "file-word";
            case "ppt":
            case "pptx":
                return "file-pp";
            default:
                return "file";
        }
    };

    const fileType = (name) => {
        switch (getExtension(name)) {
            case "mp4":
                return ".mp4";
            case "csv":
                return ".csv";
            case "xls":
                return ".xls";
            case "xlsx":
                return ".xlsx";
            case "doc":
                return ".doc";
            case "docx":
                return ".docx";
            case "ppt":
                return ".ppt";
            case "pptx":
                return ".pptx";
            case "svg":
                return ".svg";
            case "gif":
                return ".gif";
            case "png":
                return ".png";
            default:
                return ".file";
        }
    };

    useEffect(() => {
        dispatch(loadFiles(prefix));
    }, [dispatch, prefix]);

    const itemClick = (e, f) => {
        if (showPreview(printFile(f.Key))) {
            setPreviewModalImage(f);
            setPreviewModal(true);
        }
        else if (e.target.classList.contains('cor_icon') || e.target.parentNode.classList.contains('cor_icon') || e.target.classList.contains('cor-storage__drop')) {
            e.preventDefault();
        } else {
            window.open(f.Url, "_blank");
        }
    }

    return (<>
        {/* <div className="cor-storage__sorting">
            <div className="sorting">
                <div className="sorting__item caption">
                    <span>Название папки</span>
                    <Icon name="sort-text"/>
                </div>
                <div className="sorting__item date">
                    <span>Время и дата добавления</span>
                    <Icon name="sort"/>
                </div>
                <div className="sorting__item size">
                    <span>Объем</span>
                    <Icon name="sort"/>
                </div>
                <div className="sorting__item type">
                    <span>Файлов внутри</span>
                </div>
                <div className="sorting__item action">
                    <span>Действия </span>
                </div>
            </div>
        </div> */}

        <div className="cor-storage__list">
            {/* cor-storage folders */}
            {currentDir().length > 1 &&
              <div className="cor-storage__item" onClick={() => navigate(upDir(), true)} key={0}>
                  <div className="cor-storage__unit caption">
                      <Icon name="file-folder"/>
                      <span>..</span>
                  </div>
                  <div className="cor-storage__unit date"></div>
                  <div className="cor-storage__unit size"></div>
                  <div className="cor-storage__unit type"></div>
                  <div className="cor-storage__unit action"></div>
              </div>}
            {dirs.map((d, i) => (
              <div className="cor-storage__item" onClick={() => navigate(d.Prefix, true)} key={i}>
                  <div className="cor-storage__unit caption">
                      <Icon name="file-folder"/>
                      <span>{printDir(d.Prefix)}</span>
                  </div>
                  <div className="cor-storage__unit date">
                      <span className="cor-storage__label">Дата и время добавления</span>
                      <span>25.01.2023  9:51 </span>
                  </div>
                  <div className="cor-storage__unit size">
                      <span className="cor-storage__label">Объем</span>
                      <span>3 файла</span>
                  </div>
                  <div className="cor-storage__unit type"></div>
                  <div className="cor-storage__unit action"></div>
              </div>
            ))}

            {/* cor-storage files */}
            {files.filter(s => printFile(s.Key).toLowerCase().includes(props.search.toLowerCase())).map((f, i) => (
              <div className="cor-storage__item" key={i}>
                  <div className="cor-storage__unit caption" onClick={(e) => itemClick(e, f)} key={dirs.length + i}>
                      {showPreview(printFile(f.Key)) ? (
                        <div className="cor-storage__preview">
                            <img src={f.Url} alt=''/>
                        </div>
                      ) : (
                        <Icon name={fileTypeIcon(printFile(f.Key))}/>
                      )}
                      <span>{printFile(f.Key)}</span>
                  </div>
                  <div className="cor-storage__unit date">
                      <span className="cor-storage__label">Дата и время добавления</span>
                      <span>{printShortDate(f.LastModified)}</span>
                  </div>
                  <div className="cor-storage__unit size">
                      <span className="cor-storage__label">Объем</span>
                      <span>{formatBytes(f.Size)}</span>
                  </div>
                  <div className="cor-storage__unit type">
                      <span className="cor-storage__label">Тип файла</span>
                      <span>{fileType(printFile(f.Key))}</span>
                  </div>
                  <div className="cor-storage__unit action">
                      <div className="cor-storage__toggle"><Icon name="dots-menu"/></div>
                      <div className="cor-storage__drop">
                          <Icon
                            name="share"
                            onClick={() => window.open(f.Url, "_blank")}
                            text={<span>Поделиться</span>}
                            tp="Поделиться"
                          />
                          <Icon
                            name="edit"
                            onClick={() => props.showFileSettings({
                                showModal: true, allowDelete: isAuth().role > 0,
                                file: {key: f.Key, name: printFile(f.Key), prefix}
                            })}
                            text={<span>Переименовать</span>}
                            tp="Переименовать"
                          />
                          <Icon
                            name="trash"
                            onClick={() => props.showFileSettings({
                                showModal: true, allowDelete: isAuth().role > 0,
                                file: {key: f.Key, name: printFile(f.Key), prefix}
                            })}
                            text={<span>Удалить</span>}
                            tp="Удалить"
                          />
                      </div>
                  </div>
              </div>
            ))}
        </div>
        <Modal
          visible={previewModal}
          size={previewModalSize}
          content={
              <>
                  <div className="browsing">
                      <div className="browsing__header">
                          <div className="browsing__title">{printFile(previewModalImage.Key)}</div>
                          <div className="browsing__action">
                              <Icon name={previewModalSize === "lg" ? "grid_xl" : "minimize"}
                                    onClick={() => setPreviewModalSize(previewModalSize === "lg" ? "full" : "lg")}/>
                              <Icon name="close" onClick={() => setPreviewModal(false)}/>
                          </div>
                      </div>
                      <div className="browsing__main">
                          <div className="browsing__img">
                              <img src={previewModalImage.Url} alt=""/>
                          </div>
                      </div>
                  </div>
              </>}
        />
    </>);
};
export default StorageGrid;
